<div class="deck-preview flex-grow-1 d-flex p-3">
  <div class="deck-part main-deck" *ngIf="deckList.main.length > 0">
    <div class="h3">Main<span class="ms-3">{{ deckList.main.length }}</span></div>
    <div class="deck-sections-wrapper">
      <ng-container *ngFor="let typeSection of deckList.main | deckListParser : false : false; trackBy: cardTracker">
        <div class="deck-section">
          <div class="h6">
            <span class="me-3">{{ typeSection.cardCount }}</span>{{ typeSection.text }}
          </div>
          <div class="card-item d-flex align-items-center {{ editHoverClass }}" *ngFor="let card of typeSection.cards" (mouseenter)="onMouseEnter($event, card)">
            <div class="card-qty">{{ card.qty }}</div>
            <div class="card-name flex-grow-1">{{ card.name }}</div>

            <div class="card-actions d-flex align-items-center text-secondary" [style.left.px]="actionOffsetX">
              <div class="btn btn-sm btn-blank" ngbTooltip="Add a copy" container="body" (click)="addCopy(card.name, 'main')">
                <fa-icon [icon]="icons.faPlus"></fa-icon>
              </div>
              <div class="btn btn-sm btn-blank" ngbTooltip="Remove a copy" container="body" (click)="deleteCard(card.name, 'main')">
                <fa-icon [icon]="icons.faMinus"></fa-icon>
              </div>
              <div class="btn btn-sm btn-blank" ngbTooltip="Move to Sideboard" container="body" (click)="moveCard(card.name, 'main', 'sideboard')">
                <fa-icon [icon]="icons.faAngleRight"></fa-icon>
              </div>
              <div class="btn btn-sm btn-blank" ngbTooltip="Move to Maybeboard" container="body" (click)="moveCard(card.name, 'main', 'maybeboard')">
                <fa-icon [icon]="icons.faAngleDoubleRight"></fa-icon>
              </div>
            </div>

          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="deck-part sideboard" *ngIf="deckList.sideboard.length > 0">
    <div class="h3">Sideboard<span class="ms-3">{{ deckList.sideboard.length }}</span></div>
    <ng-container *ngFor="let typeSection of deckList.sideboard | deckListParser : true : false; trackBy: cardTracker">
      <div class="deck-section">
        <div class="card-item d-flex align-items-center text-secondary {{ editHoverClass }}" *ngFor="let card of typeSection.cards" (mouseenter)="onMouseEnter($event, card)">
          <div class="card-qty">{{ card.qty }}</div>
          <div class="card-name flex-grow-1">{{ card.name }}</div>

          <div class="card-actions d-flex align-items-center text-secondary" [style.left.px]="actionOffsetX">
            <div class="btn btn-sm btn-blank" ngbTooltip="Add a copy" container="body" (click)="addCopy(card.name, 'sideboard')">
              <fa-icon [icon]="icons.faPlus"></fa-icon>
            </div>
            <div class="btn btn-sm btn-blank" ngbTooltip="Remove a copy" container="body" (click)="deleteCard(card.name, 'sideboard')">
              <fa-icon [icon]="icons.faMinus"></fa-icon>
            </div>
            <div class="btn btn-sm btn-blank" ngbTooltip="Move to Main Deck" container="body" (click)="moveCard(card.name, 'sideboard', 'main')">
              <fa-icon [icon]="icons.faAngleLeft"></fa-icon>
            </div>
            <div class="btn btn-sm btn-blank" ngbTooltip="Move to Maybeboard" container="body" (click)="moveCard(card.name, 'sideboard', 'maybeboard')">
              <fa-icon [icon]="icons.faAngleRight"></fa-icon>
            </div>
          </div>

        </div>
      </div>
    </ng-container>
  </div>

  <div class="preview-maybeboard d-flex flex-column flex-grow-1 align-items-end">

    <div class="card-preview mb-5 {{ cardPreviewSetCode }}" *ngIf="cardPreviewer">
      <ng-container *ngIf="cardPreviewUrl && cardPreviewUrl !== null && cardPreviewUrl !== ''; else cardBack">
        <img [src]="cardPreviewUrl">
      </ng-container>
      <ng-template #cardBack>
        <img src="assets/card-back.jpg">
      </ng-template>
    </div>

    <div class="deck-part maybeboard" *ngIf="deckList.maybeboard.length > 0">
      <div class="h3">Maybeboard<span class="ms-3">{{ deckList.maybeboard.length }}</span></div>
      <ng-container *ngFor="let typeSection of deckList.maybeboard | deckListParser : false : true">
        <div class="deck-section">

          <div class="maybeboard card-item d-flex align-items-center text-secondary {{ editHoverClass }}" *ngFor="let card of typeSection.cards" (mouseenter)="onMouseEnter($event, card)">
            <div class="card-qty">{{ card.qty }}</div>
            <div class="card-name flex-grow-1">{{ card.name }}</div>

            <div class="card-actions d-flex align-items-center text-secondary" [style.left.px]="actionOffsetX">
              <div class="btn btn-sm btn-blank" ngbTooltip="Add a copy" container="body" (click)="addCopy(card.name, 'maybeboard')">
                <fa-icon [icon]="icons.faPlus"></fa-icon>
              </div>
              <div class="btn btn-sm btn-blank" ngbTooltip="Remove a copy" container="body" (click)="deleteCard(card.name, 'maybeboard')">
                <fa-icon [icon]="icons.faMinus"></fa-icon>
              </div>
              <div class="btn btn-sm btn-blank" ngbTooltip="Move to Main Deck" container="body" (click)="moveCard(card.name, 'maybeboard', 'main')">
                <fa-icon [icon]="icons.faAngleDoubleLeft"></fa-icon>
              </div>
              <div class="btn btn-sm btn-blank" ngbTooltip="Move to Sideboard" container="body" (click)="moveCard(card.name, 'maybeboard', 'maybeboard')">
                <fa-icon [icon]="icons.faAngleLeft"></fa-icon>
              </div>
            </div>

          </div>

        </div>
      </ng-container>
    </div>

  </div>
</div>

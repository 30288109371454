<div id="messageListScrollWrap"
  #scrollWrapper
  class="message-list-wrapper d-flex flex-column scrollable vertical w-100"
  (scroll)="onScroll($event)">
  <!-- [scrollTop]="scrollPositionBottom ? scrollWrapper.scrollHeight : null" -->

  <div class="group-information p-3 d-flex flex-column gap-3" *ngIf="group$ | async as group">
    <app-message-group-info [group]="group"></app-message-group-info>
  </div>

  <div class="p-3 position-relative" *ngIf="showArchiveMessage" [style.marginTop.rem]="-10">
    <div class="d-flex justify-content-end archived-hero">
      <img src="assets/overlays/hero-overlay.png" [style.height.rem]="10">
    </div>
    <div class="d-flex align-items-center w-100 p-3 border border-success border-opacity-50 rounded rounded-3">
      <div class="flex-grow-1 d-flex flex-column">
        <div class="fw-bold">Unlock all archived messages</div>
        <div class="text-medium">Become a hero by supporting Tolaria.app and get even more content and tools. <span class="text-primary cursor-pointer">Learn more</span></div>
      </div>
      <button class="btn btn-success" (click)="onStartSupporting()">Start supporting</button>
    </div>
  </div>

  <ng-container *ngIf="loadingMore$ | async">
    <div class="w-100 d-flex align-items-center justify-content-center p-2">
      <div class="spinner-border spinner-border-sm text-secondary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-container>
  
  <ng-container *ngIf="messages$ | async as all; else messageLoader">
  
    <ng-container *ngFor="let item of all; let i = index; trackBy: tracker">

      <ng-container *ngIf="item.showDateSeparator && !item.archived">
        <div [id]="item.dateSeparatorId" class="border-bottom border-secondary pt-3 mt-3" [style.opacity]="0.25"></div>
        <div ngbDropdown
          [style.height.rem]="2"
          [style.marginTop.rem]="-1"
          [style.zIndex]="1"
          [style.top.rem]="0.5"
          placement="bottom"
          container="body"
          class="position-sticky text-medium d-flex align-items-center justify-content-center mb-3">
          <div class="cursor-pointer px-2 p-1 border border-secondary border-opacity-25 rounded-pill d-flex align-items-center justify-content-center gap-1"
            ngbDropdownToggle
            style="background-color: var(--main-bg);"
            [style.minWidth.rem]="14">
            <app-timestamp-clock class="flex-grow-0 me-2 ms-2"
              [timestamp]="item.message.timestamp"
              displayType="date-long"
              [seconds]="true">
            </app-timestamp-clock>
          </div>
          <div ngbDropdownMenu class="text-medium">
            <button ngbDropdownItem [disabled]="true">Jump to...</button>
            <button ngbDropdownItem (click)="scrollToBottom(true)">Most recent</button>
            <button ngbDropdownItem (click)="scrollTo('yesterday')">Yesterday</button>
            <button ngbDropdownItem (click)="scrollTo('last-week')">Last week</button>
            <button ngbDropdownItem (click)="scrollTo('last-month')">Last month</button>
          </div>
        </div>
      </ng-container>

      <div #message [id]="item.docId" class="position-relative" [ngClass]="{ 'load-more-if-visible' : i < 20 && !item.showArchivedBlur, 'archived' : item.showArchivedBlur }">
        <ng-container [ngTemplateOutlet]="messageItem" [ngTemplateOutletContext]="{ item:item }"></ng-container>
      </div>

    </ng-container>
      
  </ng-container>
  
  <div class="dummy pb-3" [style.minHeight.rem]="2" #dummyScroller></div>

</div>

<ng-template #messageItem let-item="item">

  <ng-container [ngSwitch]="item.message.type">

    <app-chat-message *ngSwitchCase="'chat-message'" [msg]="item" (scrollTo)="onScollToEmitted($event)"></app-chat-message>

    <app-match-invitation *ngSwitchCase="'match-invitation'" [msg]="item"></app-match-invitation>

    <app-match-appointment *ngSwitchCase="'match-appointment'" [msg]="item"></app-match-appointment>

    <app-event-announcement *ngSwitchCase="'event-announcement'" [msg]="item"></app-event-announcement>

    <app-event-invitation *ngSwitchCase="'event-invitation'" [msg]="item"></app-event-invitation>

    <app-scryfall-image *ngSwitchCase="'scryfall-image'" [messageDoc]="item"></app-scryfall-image>

    <app-scryfall-rules *ngSwitchCase="'scryfall-rules'" [messageDoc]="item"></app-scryfall-rules>

  </ng-container>

</ng-template>
<ng-template #messageLoader>
  <div class="d-flex p-3 placeholder-glow gap-3" *ngFor="let item of messageLoad">
    <div class="placeholder rounded rounded-circle ms-2" style="width: 2.5rem; height: 2.5rem;"></div>
    <div class="d-flex flex-column flex-grow-1 gap-2">
      <div class="placeholder placeholder-lg rounded col-5"></div>
      <div class="placeholder rounded col-9"></div>
      <div class="placeholder rounded col-7"></div>
    </div>
  </div>
</ng-template>


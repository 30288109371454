import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-draft-rochester',
  templateUrl: './draft-rochester.component.html',
  styleUrls: ['./draft-rochester.component.css']
})
export class DraftRochesterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    const init = true;
  }

}

<div class="tolaria-picker picker-wrapper">

  <div class="picker-tabs">
    <ng-container *ngIf="emoji.tabs$ | async as tabs">
      <div *ngFor="let tab of tabs"
        class="picker-tab"
         (click)="emoji.selectTab(tab.category)"
         [ngClass]="{ 'selected' : tab.selected }">
        <fa-icon [icon]="tab.icon" [fixedWidth]="true"></fa-icon>
      </div>
    </ng-container>
  </div>

  <div class="picker-search" *ngIf="emoji.isSearch">
    <input [(ngModel)]="emoji.search"
      (ngModelChange)="emoji.updateSearchResult()"
      type="text"
      class="form-control form-control-sm"
      placeholder="Search all emojis">
  </div>

  <div class="picker-list">

    <ng-container *ngIf="emoji.isSearching; else emojiList">

      <ng-container *ngIf="emoji.searchResult$ | async as result">
        <div class="category-section search-result">
    
          <div class="category-header">Search result</div>
          
          <div class="icons d-flex flex-row flex-wrap">
            
            <ng-container *ngFor="let emoji of result">
  
              <div class="icon-wrapper"
                (click)="onSelect(emoji)"
                [attr.color-index]="emoji.colorindex"
                (mouseenter)="onSetPreview(emoji)"
                (mouseleave)="onClearPreview()">
                <img [src]="emoji.image">
              </div>
  
            </ng-container>
  
          </div>
          
        </div>
      </ng-container>

    </ng-container>
    <ng-template #emojiList>
      <ng-container *ngIf="emoji.emojis$ | async as categories">
  
        <ng-container *ngFor="let category of categories">
  
          <div class="category-section {{ category.category }}" *ngIf="emoji.selectedTab === category.category || emoji.isSearch">
  
            <div class="category-header" [innerText]="category.text"></div>
            
            <div class="icons d-flex flex-row flex-wrap">
              
              <ng-container *ngFor="let emoji of category.icons">
    
                <div class="icon-wrapper"
                  (click)="onSelect(emoji)"
                  [attr.color-index]="emoji.colorindex"
                  (mouseenter)="onSetPreview(emoji)"
                  (mouseleave)="onClearPreview()">
                  <img [src]="emoji.image">
                </div>
    
              </ng-container>
    
            </div>
            
          </div>
  
        </ng-container>
  
      </ng-container>
    </ng-template>

  </div>

  <div class="picker-preview">

    <div class="preview-row">
      <ng-container *ngIf="previewEmoji$ | async as preview; else addNew">
        <img class="preview-image" [src]="preview.image">
        <div class="preview-info">
          <div class="preview-name" [innerText]="preview.name"></div>
          <div class="preview-short" [innerText]="preview.shortcode"></div>
        </div>
      </ng-container>
      <ng-template #addNew>
        <button (click)="onNewEmoji()"
          class="btn btn-sm btn-outline-secondary text-nowrap"
          [disabled]="!emoji.isHero">
          Add Emoji
        </button>
        <div class="text-smaller text-secondary" *ngIf="!emoji.isHero">
          This feature is only available for heros of Tolaria, subscribe to a support tier to enable this feature.
        </div>
      </ng-template>
    </div>

  </div>

</div>
import { takeUntil } from 'rxjs/operators'
import { Observable, Subject, Subscription } from 'rxjs'
import { AuthService } from 'src/app/services'
import { Component, OnDestroy } from '@angular/core'
import {
  faSun, faMoon, faFont
} from '@fortawesome/free-solid-svg-icons'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore'
import { IUser } from 'tolaria-cloud-functions/src/_interfaces'

@Component({
  selector: 'app-apperance',
  templateUrl: './apperance.component.html',
  styleUrls: ['./apperance.component.css']
})
export class ApperanceComponent implements OnDestroy {
  faSun = faSun
  faMoon = faMoon
  faFont = faFont

  darkmode: boolean
  clock24: boolean
  textSize: string

  userSubscription: Subscription

  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()
  private userDoc: AngularFirestoreDocument<IUser>
  user$: Observable<IUser>

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService
  ) {
    this.userDoc = this.afs.collection('users').doc<IUser>(this.auth.user.uid)
    this.user$ = this.userDoc.valueChanges()
    this.user$.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((usr: IUser) => {
        this.darkmode = usr.settings.darkMode
        this.clock24 = usr.settings.clock24
        this.textSize = usr.settings.textSize
      })
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
  }

  changeSetting() {
    this.userDoc.update({
      settings: {
        darkMode: this.darkmode,
        clock24: this.clock24,
        textSize: this.textSize
      }
    })
  }

}

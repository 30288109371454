import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IEventTemplate, EventListingService } from '../../services/event-listing.service';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TimestampClockComponent } from 'src/app/components/app-structure/timestamp-clock/timestamp-clock.component';

@Component({
  selector: 'app-event-grid-item',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    TimestampClockComponent,
    RouterLink,
  ],
  templateUrl: './event-grid-item.component.html',
  styleUrls: ['./event-grid-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventGridItemComponent {
  @Input() event: IEventTemplate
  @Input() lobbyLink: boolean = true
  @Input() selectLink: boolean = false
  @Output() onEventSelected = new EventEmitter<IEventTemplate>()


  constructor(public readonly listing: EventListingService) {}

  public onSelect(): void {
    this.onEventSelected.emit(this.event)
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { IDraftSet } from 'tolaria-cloud-functions/src/_interfaces';

@Pipe({
  name: 'sets'
})
export class SetsPipe implements PipeTransform {

  transform(sets: IDraftSet[], options: { isExpansion?: boolean, isCore?: boolean, isCustom?: boolean, draftAble?: boolean }): IDraftSet[] {

    console.log(sets, options);

    if (options.draftAble) {
      console.log('set needs to be draftable', sets.filter(s => s.boosterSize !== null));
      sets = sets.filter(s => s.boosterSize !== null && s.boosterSize > 0);
    }

    if (options.isCustom) {
      return sets.filter(s => s.isCustom);
    }

    if (options.isCore) {
      return sets.filter(s => s.isCore);
    }

    if (options.isExpansion) {
      return sets.filter(s => s.isExpansion);
    }


    return sets;

  }

}

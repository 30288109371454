import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Component } from '@angular/core'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { BehaviorSubject } from 'rxjs'
import { IUser } from 'tolaria-cloud-functions/src/_interfaces'
import { AuthService } from 'src/app/services'
import { darkMode } from 'src/assets/font-icons/tolaria-dark-mode'
import { lightMode } from 'src/assets/font-icons/tolaria-light-mode'

@Component({
  selector: 'app-dark-mode-switch',
  templateUrl: './dark-mode-switch.component.html',
  styleUrls: ['./dark-mode-switch.component.css']
})
export class DarkmodeSwitchComponent {

  public userDoc$ = new BehaviorSubject<IUser>(null)

  constructor(private firestore: AngularFirestore, private auth: AuthService) {
    this.auth.user$.subscribe(user => { this.userDoc$.next(user) })
  }

  public toggleMode(uid: string, mode: boolean): void {
    this.firestore
      .collection('users')
      .doc(uid)
      .update({ 'settings.darkMode': mode })
  }

  public get modeClass(): string {
    return this.userDoc$.getValue().settings.darkMode ? 'dark' : 'light'
  }

  public get currentModeIcon(): IconDefinition {
    return this.userDoc$.getValue().settings.darkMode ? darkMode : lightMode
  }

}

<ng-container *ngIf="isMobile; else desktopView">

  <div class="card mobile max-width-100" style="position: sticky; top: 0;">

    <div class="card-body mobile no-hover">
      <div class="row">
        <div class="col">
          <div class="h5 card-title">Register as a player</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="muted-text" [innerHtml]="registrationText"></div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="form-group">
            <label>Email address</label>
            <input class="form-control {{ !userEmailNull ? 'text-secondary' : '' }}" [(ngModel)]="registrationForm.email"
              [disabled]="!userEmailNull">
          </div>
          <div class="form-group">
            <label>First name</label>
            <input class="form-control" [(ngModel)]="registrationForm.name.first">
          </div>
          <div class="form-group">
            <label>Last name</label>
            <input type="text" class="form-control" [(ngModel)]="registrationForm.name.last">
          </div>
          <div class="form-group">
            <label>Nick name</label>
            <input class="form-control" [(ngModel)]="registrationForm.name.nick">
          </div>
          <div class="form-group">
            <label>Phone</label>
            <input type="tel" class="form-control" [(ngModel)]="registrationForm.phone">
          </div>
          <div class="form-group">
            <label>Country</label>
            <select class="custom-select" (change)="changeCountry($event.target.value)">
              <option [value]="null">Select your country</option>
              <option [value]="i" *ngFor="let country of countries; let i = index"
                [selected]="country.name === registrationForm.country.name">{{ country.name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label>Time zone</label>
            <select class="custom-select" [(ngModel)]="registrationForm.timeZone">
              <option [ngValue]="timezone" *ngFor="let timezone of timeZones; let i = index">
                {{ timezone.UTC + ' | ' + timezone.olson }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="row">
        <div class="col-8">
          <div class="d-flex align-items-center">
            <div class="button-wrapper me-2" (click)="agreeToTermsAndConditions = !agreeToTermsAndConditions">
              <fa-icon [fixedWidth]="true" [icon]="faSquare" *ngIf="!agreeToTermsAndConditions"></fa-icon>
              <fa-icon [fixedWidth]="true" [icon]="faCheckSquare" *ngIf="agreeToTermsAndConditions"></fa-icon>
            </div>
            <div class="text-small flex-grow-1">I agree to the <span class="text-italic text-link"
                (click)="showInfo('termsOfUse')">terms of use</span> and the <span class="text-italic text-link"
                (click)="showInfo('privacyPolicy')">privacy policy</span></div>
          </div>
        </div>
        <div class="col-4">
          <button class="btn btn-sm btn-secondary float-end" (click)="registerAsPlayer()"
            [disabled]="registrationFormValidation()">Register</button>
        </div>
      </div>
    </div>

  </div>

</ng-container>
<ng-template #desktopView>
  <div class="card max-width-100" style="position: sticky; top: 0;">

    <div class="card-body no-hover">
      <div class="row">
        <div class="col">
          <div class="h5 card-title">Register as a player</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="muted-text" [innerHtml]="registrationText"></div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <div class="form-group">
            <label>Email address</label>
            <input class="form-control" [(ngModel)]="registrationForm.email" [disabled]="!userEmailNull">
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <div class="form-group">
            <label>First name</label>
            <input class="form-control" [(ngModel)]="registrationForm.name.first">
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Last name</label>
            <input type="text" class="form-control" [(ngModel)]="registrationForm.name.last">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Nick name</label>
            <input class="form-control" [(ngModel)]="registrationForm.name.nick">
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Phone</label>
            <input type="tel" class="form-control" [(ngModel)]="registrationForm.phone">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group" *ngIf="false">
            <label>Email</label>
            <input type="email" class="form-control" [(ngModel)]="registrationForm.email">
          </div>
          <div class="form-group">
            <label>Country</label>
            <select class="custom-select" (change)="changeCountry($event.target.value)">
              <option [value]="null">Select your country</option>
              <option [value]="i" *ngFor="let country of countries; let i = index"
                [selected]="country.name === registrationForm.country.name">{{ country.name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label>Time zone</label>
            <select class="custom-select" [(ngModel)]="registrationForm.timeZone">
              <option [value]="null">Select your time zone</option>
              <option [ngValue]="timezone" *ngFor="let timezone of timeZones; let i = index">
                {{ timezone.UTC + ' | ' + timezone.olson }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="row">
        <div class="col-8">
          <div class="d-flex align-items-center">
            <div class="button-wrapper me-2" (click)="agreeToTermsAndConditions = !agreeToTermsAndConditions">
              <fa-icon [fixedWidth]="true" [icon]="faSquare" *ngIf="!agreeToTermsAndConditions"></fa-icon>
              <fa-icon [fixedWidth]="true" [icon]="faCheckSquare" *ngIf="agreeToTermsAndConditions"></fa-icon>
            </div>
            <div class="text-small flex-grow-1">I agree to the <span class="text-italic text-link"
                (click)="showInfo('termsOfUse')">terms of use</span> and the <span class="text-italic text-link"
                (click)="showInfo('privacyPolicy')">privacy policy</span></div>
          </div>
        </div>
        <div class="col-4">
          <button class="btn btn-sm btn-secondary float-end" (click)="registerAsPlayer()"
            [disabled]="registrationFormValidation()">Register</button>
        </div>
      </div>
    </div>

  </div>
</ng-template>

import { Component, Input } from '@angular/core';
import { IBoosterCard } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-booster-card-info',
  templateUrl: './booster-card-info.component.html',
  styleUrls: ['./booster-card-info.component.scss']
})
export class BoosterCardInfoComponent {
  @Input() card: IBoosterCard;

  constructor() { }

}

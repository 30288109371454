import { takeUntil } from 'rxjs/operators';
import { TicketService } from 'src/app/services/ticket.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ITicket } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-manage-tickets',
  templateUrl: './manage-tickets.component.html',
  styleUrls: ['./manage-tickets.component.css']
})
export class ManageTicketsComponent implements OnInit, OnDestroy {

  public backIcon = faChevronLeft

  public tickets$: BehaviorSubject<ITicket[]> = new BehaviorSubject<ITicket[]>(null)
  private selectedTicket: ITicket = null
  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private ticketService: TicketService,
  ) { }

  ngOnInit(): void {
    this.ticketService.getMyTickets().pipe(takeUntil(this.componentDestroyed$)).subscribe(t => this.ticketsLoaded(t))
  }
  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  private ticketsLoaded(tickets: ITicket[]): void {
    if (this.selectedTicket) {
      for (let ticket of tickets) {
        if (ticket.docId === this.selectedTicket.docId) {
          ticket.selected = true
        }
        else {
          ticket.selected = false
        }
      }
    }
    this.tickets$.next(tickets)
  }

  public select(ticket: ITicket): void {
    this.selectedTicket = ticket
    const tickets = this.tickets$.getValue()
    for (let ticket of tickets) {
      if (ticket.docId === this.selectedTicket.docId) {
        ticket.selected = true
      }
      else {
        ticket.selected = false
      }
    }
    this.tickets$.next(tickets)
  }

}

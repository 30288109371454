<div class="d-flex {{ isHorizontal ? 'flex-row' : 'flex-column' }}" *ngIf="configDone">

  <!-- CAMERA -->
  <div ngbDropdown [placement]="menuPlacements" #cameraMenu="ngbDropdown" class="d-inline-block w-100 {{ dropDownMargin }}">

    <div class="btn-controller" [ngClass]="{ 'off' : (playerCamOn$ | async) === false }" id="dropdownCamera"
      (mouseenter)="cameraMenu.open(); openDropDown(); delayClose(microphoneMenu); delayClose(speakersMenu);"
      (mouseleave)="delayClose(cameraMenu);" (click)="toggleVideo()" ngbDropdownToggle>
      <fa-icon [fixedWidth]="true" [icon]="(playerCamOn$ | async) ? icons.faVideo : icons.faVideoSlash">
      </fa-icon>
    </div>

    <div ngbDropdownMenu aria-labelledby="dropdownCamera" class="custom-drop-down {{ dropDownMenuMargin }}"
      (mouseenter)="cameraMenu.open(); openDropDown();" (mouseleave)="cameraMenu.close()">
      <div class="d-flex align-items-center" ngbDropdownItem
        *ngFor="let device of this.connectedMediaDevices$ | async | mediaDevice: 'videoinput'"
        (click)="this.setVideoDevice(device.deviceId); $event.stopPropagation()">
        <fa-icon [fixedWidth]="true"
          [icon]="(selectedAudioInputDeviceId$ | async) === undefined ? icons.faCircle : device.deviceId === (selectedAudioInputDeviceId$ | async) ? icons.faCheckCircle : icons.faCircle"
          class="me-3"></fa-icon>
        <span class="label">{{ device.label }}</span>
      </div>
      <div class="separator"></div>
      <div class="d-flex align-items-center" ngbDropdownItem [ngClass]="{ 'text-red' : (playerCamOn$ | async) === false }"
        (click)="toggleVideo(); $event.stopPropagation()">
        {{ (playerCamOn$ | async) ? 'Turn camera off' : 'Turn camera on' }}
      </div>
    </div>

  </div>
  <!-- MICROPHONE -->
  <div ngbDropdown [placement]="menuPlacements" #microphoneMenu="ngbDropdown" class="d-inline-block w-100 {{ dropDownMargin }}">

    <div class="btn-controller" [ngClass]="{ 'off' : (playerMicOn$ | async) === false }" id="dropdownMicrophone"
      (mouseenter)="microphoneMenu.open(); openDropDown(); delayClose(cameraMenu); delayClose(speakersMenu);"
      (mouseleave)="delayClose(microphoneMenu);" (click)="toggleAudioInput()" ngbDropdownToggle>
      <fa-icon [fixedWidth]="true" [icon]="(playerMicOn$ | async) ? icons.faMicrophone : icons.faMicrophoneSlash">
      </fa-icon>
    </div>

    <div ngbDropdownMenu aria-labelledby="dropdownMicrophone" class="custom-drop-down {{ dropDownMenuMargin }}"
      (mouseenter)="microphoneMenu.open(); openDropDown()" (mouseleave)="microphoneMenu.close()">
      <div class="d-flex align-items-center" ngbDropdownItem
        *ngFor="let device of this.connectedMediaDevices$ | async | mediaDevice: 'audioinput'"
        (click)="this.setAudioInputDevice(device.deviceId); $event.stopPropagation()">
        <fa-icon [fixedWidth]="true"
          [icon]="(selectedAudioInputDeviceId$ | async) === undefined ? icons.faCircle : device.deviceId === (selectedAudioInputDeviceId$ | async) ? icons.faCheckCircle : icons.faCircle"
          class="me-3"></fa-icon>
        <span class="label">{{ device.label }}</span>
      </div>
      <div class="separator"></div>
      <div class="d-flex align-items-center" ngbDropdownItem [ngClass]="{ 'text-red' : (playerMicOn$ | async) === false }"
        (click)="toggleAudioInput(); $event.stopPropagation()">
        {{ (playerMicOn$ | async) ? 'Turn microphone off' : 'Turn microphone on' }}
      </div>
    </div>

  </div>
  <!-- SPEAKERS -->
  <div ngbDropdown [placement]="menuPlacements" #speakersMenu="ngbDropdown" class="d-inline-block w-100 {{ dropDownMargin }}">

    <div class="btn-controller" [ngClass]="{ 'off' : (audioOutputMuted$ | async) }" id="dropdownSpeakers"
      (mouseenter)="speakersMenu.open(); openDropDown(); delayClose(microphoneMenu); delayClose(cameraMenu);"
      (mouseleave)="delayClose(speakersMenu);" (click)="toggleAudioOutput()" ngbDropdownToggle>
      <fa-icon [fixedWidth]="true" [icon]="(audioOutputMuted$ | async) ? icons.facSpeakerSlashIcon : icons.faVolumeOff">
      </fa-icon>
    </div>

    <div ngbDropdownMenu aria-labelledby="dropdownSpeakers" class="custom-drop-down {{ dropDownMenuMargin }}"
      (mouseenter)="speakersMenu.open(); openDropDown()" (mouseleave)="speakersMenu.close()">
      <div class="d-flex align-items-center" ngbDropdownItem
        *ngFor="let device of this.connectedMediaDevices$ | async | mediaDevice: 'audiooutput'"
        (click)="this.setAudioOutputDevice(device.deviceId); $event.stopPropagation()">
        <fa-icon [fixedWidth]="true"
          [icon]="(selectedAudioOutputDeviceId$ | async) === undefined ? icons.faCircle : device.deviceId === (selectedAudioOutputDeviceId$ | async) ? icons.faCheckCircle : icons.faCircle"
          class="me-3"></fa-icon>
        <span class="label">{{ device.label }}</span>
      </div>
      <div class="separator"></div>
      <div class="d-flex align-items-center" ngbDropdownItem [ngClass]="{ 'text-red' : (audioOutputMuted$ | async) }"
        (click)="toggleAudioOutput(); $event.stopPropagation()">
        {{ (audioOutputMuted$ | async) ? 'Turn speaker on' : 'Turn speaker off' }}
      </div>
    </div>

  </div>

</div>

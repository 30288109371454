import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, Subject, Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { ClubsService, ClubImageSizes, ClubImageNames } from 'src/app/services/clubs.service';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { GlobalsService } from 'src/app/services';
import { IClub } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-club-management',
  templateUrl: './club-management.component.html',
  styleUrls: ['./club-management.component.css']
})
export class ClubManagementComponent implements OnInit {

  @Input() clubDocId: string;

  public club$: Observable<IClub>;
  public club: IClub;
  private originClub: IClub;

  public view = {
    details: true,
    players: false,
    images: false,
    admin: false
  }


  constructor(
    private activeModal: NgbActiveModal,
    private clubsService: ClubsService,
    private auth: AuthService,
    public globals: GlobalsService,
    private storage: AngularFireStorage,
  ) { }

  ngOnInit(): void {
    this.club$ = this.clubsService.getClub(this.clubDocId);
    this.club$.pipe(take(1)).subscribe(club => {
      this.club = JSON.parse(JSON.stringify(club));
      this.originClub = JSON.parse(JSON.stringify(club));
    });
  }

  public clearViews(): void {
    Object.keys(this.view).forEach(key => {
      this.view[key] = false;
    });
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public saveClubData(): void {

    if (this.uploadEnabled) {
      this.uploadImages()
        .then(() => {
          this.performSave();
        });
    }
    else {
      this.performSave();
    }

  }

  private performSave(): void {
    this.clubsService.saveClub(this.club)
      .then(response => {
        console.log(response);
        if (response.status) {
          this.activeModal.close();
        }
        else {
          // handle error
        }
      })
      .catch(() => console.log('error'));
  }

  // Players
  public addTolariaPlayer(playerDocId: string): void {
    if (this.club.memberDocIds.indexOf(playerDocId) === -1) {
      this.club.memberDocIds.push(playerDocId);
    }
  }
  public addResponsibleTolariaPlayer(playerDocId: string): void {
    if (this.club.responsiblePlayerDocIds.indexOf(playerDocId) === -1) {
      this.club.responsiblePlayerDocIds.push(playerDocId);
    }
  }
  public deletePlayer(playerDocId: string): void {
    this.club.memberDocIds.splice(this.club.memberDocIds.indexOf(playerDocId), 1);
  }
  public deleteResponsiblePlayer(playerDocId: string): void {
    this.club.responsiblePlayerDocIds.splice(this.club.responsiblePlayerDocIds.indexOf(playerDocId), 1);
  }

  // Images
  public imageChangedEvent: any = '';
  public croppedImage = {
    large: '',
    normal: '',
    small: '',
    thumb: ''
  }
  public selectedAspectRatio: number = 1;
  public maintainAspectRatio = true;
  public uploadEnabled = false;
  private componentWasDestroyed$ = new Subject<boolean>();

  public fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  public imageCropped(event: ImageCroppedEvent) {
    console.log(event);
    this.resizeImage(event, ClubImageSizes.LARGE).then(image => this.croppedImage.large = image);
    this.resizeImage(event, ClubImageSizes.NORMAL).then(image => this.croppedImage.normal = image);
    this.resizeImage(event, ClubImageSizes.SMALL).then(image => this.croppedImage.small = image);
    this.resizeImage(event, ClubImageSizes.THUMB).then(image => this.croppedImage.thumb = image);
  }
  public imageLoaded() {
    // show cropper
    this.uploadEnabled = true;
  }
  public cropperReady() {
    // cropper ready
  }
  public loadImageFailed() {
    // show message
  }
  private resizeImage(event: ImageCroppedEvent, size: number): Promise<string> {
    return new Promise((resolve) => {
      let img = new Image();
      img.src = event.base64;

      // width based resize
      const ratio = size / event.width;
      const resizeW = event.width * ratio;
      const resizeH = event.height * ratio;
      console.log(`Image size: ${event.width}x${event.height }`);
      console.log(`Aspect ratio: ${ratio }`);
      console.log(`Image size: ${resizeW}x${resizeH }`);

      img.onload = () => {
        let canvas = document.createElement('canvas');
        canvas.width = resizeW;
        canvas.height = resizeH;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, resizeW, resizeH);
        resolve(canvas.toDataURL());
      }
    })
  }
  private async uploadImages() {
    this.globals.isBusy.message = `Uploading images`;
    this.globals.isBusy.showMessage = true;
    this.globals.isBusy.status = true;

    let counter = 1;
    for await (const key of Object.keys(this.club.imageUris)) {
      console.log(`uploading image ${key}`);
      this.globals.isBusy.message = `Uploading images<br><br>Image: <b>${key}</b><br><span class="text-secondary">(${counter} of ${Object.keys(this.club.imageUris).length})</span>`;
      await this.saveImage(key, this.croppedImage[key]).then(() => console.log(`upload finished`));
      counter++;
    }

    this.globals.isBusy.message = '<h3>All done!</h3>';
    setTimeout(() => {
      this.globals.isBusy.status = false;
    }, 1500)
  }
  private async saveImage(name: ClubImageNames | string, image: string): Promise<boolean> {
    return new Promise((resolve) => {
      const filePath = `clubs/${this.club.docId}/${name}.png`;
      const ref = this.storage.ref(filePath);
      const task = ref.putString(image, 'data_url');
      console.log('crate task', task);
      const sub: Subscription = task
        .snapshotChanges()
        .pipe(
          takeUntil(this.componentWasDestroyed$),
          finalize(() => {
            console.log('task finalized, getting download url');
            const downloadUrl = ref.getDownloadURL();
            // store the download url as the avatar link for both user and player doc.
            downloadUrl.subscribe(async (url) => {
              this.globals.isBusy.message = 'Uploading done, updating the event banner url';
              console.log('downlaod url emitted');
              this.club.imageUris[name] = url;
              console.log(`ImageUris.${this.club.imageUris[name]} : ${this.club.imageUris[name]}`);
              sub.unsubscribe();
              resolve(true);
            });
          })
        ).subscribe();
    })
  }

  public get isAdmin(): boolean {
    return this.auth.user.role === 'admin';
  }

  public get formChanged(): boolean {
    return JSON.stringify(this.club) !== JSON.stringify(this.originClub) || this.uploadEnabled;
  }

  public get selectedAspectRatioString(): string {
    switch (this.selectedAspectRatio.toFixed(2)) {
      case '1.00':
        return '1:1';
      case '2.00':
        return '2:1';
      case '1.33':
        return '4/3';
      case '1.78':
        return '16/9';
    }
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { IPlayerSearch } from '../services/player.service';

@Pipe({
  name: 'playerSearch'
})
export class PlayerSearchPipe implements PipeTransform {

  transform(items: IPlayerSearch[], filter: string, selected?: boolean, refresh?: number): IPlayerSearch[] {
    if (items && selected) {
      return items.filter(p => p.selected);
    }
    else if (items) {
      return items.filter(p => p.searchString.toLowerCase().includes(filter.toLowerCase()));
    }
  }

}

<div class="d-flex align-items-center gap-2 flex-wrap flex-md-nowrap flex-grow-1">

  <div class="input-group flex-nowrap order-md-0 order-1">

    <input class="form-control form-control-sm rounded-2 rounded-md-0"
      type="text"
      [ngClass]="{ 'rounded-2': rounded }"
      [(ngModel)]="listing.filtering.search"
      (change)="listing.onSearchChanged()"
      placeholder="Search">

    <button class="btn btn-sm d-none d-lg-block"
      [ngClass]="{
        'btn-outline-secondary' : !listing.filtering.showPanel,
        'btn-secondary' : listing.filtering.showPanel
      }"
      (click)="listing.filtering.showPanel = !listing.filtering.showPanel">
      <fa-icon [icon]="filter"></fa-icon>
    </button>

  </div>

  <div class="btn-group flex-grow-1 flex-md-grow-0">
    <button class="btn btn-sm d-flex align-items-center"
        [ngClass]="currentRoute === 'grid' ? 'btn-secondary' : 'btn-outline-secondary'"
        [routerLink]="['/events', 'grid']">
        <fa-icon [icon]="grid" [fixedWidth]="true"></fa-icon>
        <span class="d-xxl-block d-md-none d-sm-block ms-2">Grid</span>
    </button>
    <button class="btn btn-sm d-flex align-items-center"
        [ngClass]="currentRoute === 'list' ? 'btn-secondary' : 'btn-outline-secondary'"
        [routerLink]="['/events', 'list']">
        <fa-icon [icon]="list" [fixedWidth]="true"></fa-icon>
        <span class="d-xxl-block d-md-none d-sm-block ms-2">List</span>
    </button>
    <button class="btn btn-sm d-flex align-items-center"
        [ngClass]="currentRoute === 'calendar' ? 'btn-secondary' : 'btn-outline-secondary'"
        [routerLink]="['/events', 'calendar']">
        <fa-icon [icon]="calendar" [fixedWidth]="true"></fa-icon>
        <span class="d-xxl-block d-md-none d-sm-block ms-2">Calendar</span>
    </button>
  </div>

  <button class="btn btn-sm btn-primary d-none d-lg-block" *ngIf="showCreateButton" (click)="createNewTournament()">Create</button>

</div>

import { Pipe, PipeTransform } from '@angular/core'
import { IEventDetails, IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces'

export interface IToAttendingPlayers {
  searchString: string
  showPaid: boolean
  event: IEventDetails
  showCheckedIn: boolean
}

@Pipe({
  name: 'toAttendingPlayers'
})
export class ToAttendingPlayersPipe implements PipeTransform {

  transform(players: IEventPlayerDetails[], config: IToAttendingPlayers): IEventPlayerDetails[] {

    // console.log(config)

    if (config) {
      let response = players.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      if (!config.showPaid) {
        response = response.filter(p =>
          config.event.details.registrationFee.paidBy[p.playerDocId] === undefined && config.event.details.registrationFee.paidByTicket[p.playerDocId] === undefined ||
          config.event.details.registrationFee.paidBy[p.playerDocId] !== undefined && config.event.details.registrationFee.paidBy[p.playerDocId].paid === false)
      }

      if (config.searchString !== '') {
        response = response.filter(p => p.name.toLowerCase().includes(config.searchString.toLowerCase()))
      }

      if (!config.showCheckedIn) {
        response = response.filter(p => !p.hasCheckedIn)
      }

      return response
    }

    return players.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
  }

}

<ng-container *ngIf="event$ | async as event">

  <ng-container *ngIf="!isSwissTeamEvent; else teamEvent">

    <ng-container *ngIf="standings$ | async as standings">

      <ng-container *ngIf="globals.isMobile">
        <div class="card-header mobile">

          <div class="row">

            <div class="col-1   text-no-wrap text-start ">#</div>
            <div class="col-5   text-no-wrap text-start ">Attendee</div>
            <div class="col-1-5 text-no-wrap text-end">Points</div>
            <div class="col-1-5 text-no-wrap text-end">OMW%</div>
            <div class="col-1-5 text-no-wrap text-end">GW%</div>
            <div class="col-1-5 text-no-wrap text-end">OGW%</div>

          </div>

        </div>
      </ng-container>

      <div class="standings-wrapper" [ngClass]="{ 'mobile' : globals.isMobile }">
        <ng-container *ngFor="let player of standings | standings : { showDroppedPlayers, searchString: filter }">
          <div class="row player p-2 m-0">

            <div class="col-1">
              {{ player.rank }}
              <span *ngIf="player.playoffMatchesPlayed > 0"
                class="float-end text-secondary"
                ngbTooltip="Playoff participant"
                container="body"
                placement="right">
                <fa-icon [icon]="playoff"></fa-icon>
              </span>
            </div>

            <div class="col-5 d-flex align-items-center">

              <div class="flex-grow-1">

                <span class="player-name {{ player.playerUid === auth.user.uid ? 'logged-in-user' : '' }}">

                  <ng-container *ngIf="player.playerDocId.includes('temp__'); else playerLink">
                    <span>{{ player.name }}</span>
                  </ng-container>
                  <ng-template #playerLink>
                    <app-player-display-name [playerDocId]="player.playerDocId" [name]="player.name"
                      (click)="$event.stopPropagation(); $event.preventDefault()"></app-player-display-name>
                  </ng-template>

                  <span *ngIf="isGroupEvent$ | async">
                    <span class="group-name ms-2">{{ player.playedInGroup.replace('GROUP ', '') }}</span>
                    <span class="text-secondary ms-1">({{ player.groupRank }})</span>
                  </span>

                  <span class="logged-in-user ms-1" *ngIf="player.playerUid === auth.user.uid">(You)</span>

                  <ng-container *ngIf="isGroupEvent$ | async">
                    <span class="float-end mx-1" ngbTooltip="Group Winner" container="body" *ngIf="player.groupRank === 1">🥇</span>
                  </ng-container>

                </span>

                <span class="drop-wrapper" *ngIf="player.dropped">dropped</span>

              </div>

              <div class="flex-grow-o d-flex align-items-center"
                ngbTooltip="Non-playoff match stats [Wins][Losses][Draws]" container="body">
                <span class="badge text-bg-dark ms-1">{{ player.matchesWon }}</span>
                <span class="badge text-bg-dark ms-1">{{ player.matchesLost }}</span>
                <span class="badge text-bg-dark ms-1">{{ player.matchesDrawn }}</span>
              </div>

            </div>

            <div class="col-1-5 text-end">{{ player.matchPoints }}<span class="text-secondary"> p</span></div>

            <div class="col-1-5 text-secondary text-end">
              {{ (player.opponentMatchWinPercentage * 100).toFixed(2) }}
              <span class="text-small text-secondary">%</span>
            </div>

            <div class="col-1-5 text-secondary text-end">
              {{ (player.gameWinPercentage * 100).toFixed(2) }}
              <span class="text-small text-secondary">%</span>
            </div>

            <div class="col-1-5 text-secondary text-end">
              {{ (player.opponentGameWinPercentage * 100).toFixed(2) }}
              <span class="text-small text-secondary">%</span>
            </div>

          </div>
        </ng-container>
      </div>

    </ng-container>

  </ng-container>

  <ng-template #teamEvent>
    <app-team-standings [eventDocId]="eventDocId"></app-team-standings>
  </ng-template>



</ng-container>

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, firstValueFrom } from 'rxjs';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { IMatchAvailabilityMeta } from 'tolaria-cloud-functions/src/_interfaces';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {

  private playerAvailability = new Map<string, Observable<IMatchAvailabilityMeta[]>>()

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly playersNames: PlayerNameService,
  ) { }

  getAvailability(playerDocId: string): Promise<IMatchAvailabilityMeta[]> {
    return new Promise(async (resolve) => {
      if (this.playerAvailability.get(playerDocId) === undefined) {
        let now = new Date().getTime() / 1000
        const observer = this.firestore.collection<IMatchAvailabilityMeta>('matchAvailability', ref => ref
          .where('playerDocId', '==', playerDocId)
          .where('timestampFrom', '>=', now)
        ).valueChanges()
        this.playerAvailability.set(playerDocId, observer)
      }
      let data = await firstValueFrom(this.playerAvailability.get(playerDocId))
      resolve(data)
    })
  }

  getMyAvailability(): Promise<IMatchAvailabilityMeta[]> {
    return new Promise(async (resolve) => {
      if (this.playerAvailability.get(this.playersNames.currentPlayersMini.id) === undefined) {
        let now = new Date().getTime() / 1000
        const observer = this.firestore.collection<IMatchAvailabilityMeta>('matchAvailability', ref => ref
          .where('playerDocId', '==', this.playersNames.currentPlayersMini.id)
          .where('timestampFrom', '>=', now)
        ).valueChanges()
        this.playerAvailability.set(this.playersNames.currentPlayersMini.id, observer)
      }
      let data = await firstValueFrom(this.playerAvailability.get(this.playersNames.currentPlayersMini.id))
      resolve(data)
    })
  }

}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PlayerDisplayNameComponent } from 'src/app/components';
import { TournamentManagementPlayersService } from 'src/app/private/tournament/services/tournament-management-players.service';
import { MessageItem } from '../../../services/message-list.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck, faEnvelopeOpen, faExternalLinkAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-event-invitation',
  standalone: true,
  imports: [
    CommonModule,
    PlayerDisplayNameComponent,
    FontAwesomeModule,
  ],
  templateUrl: './event-invitation.component.html',
  styleUrls: ['./event-invitation.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventInvitationComponent {
  @Input() set msg(data: MessageItem) {
    if (data.message.invitedPlayer && data.message.invitedPlayer.playerDocId === this.playerCollection.currentPlayersMini.id) {
      this.data = data
      this.data$.next(data)
    }
  }

  public data$ = new BehaviorSubject<MessageItem>(null)
  public data: MessageItem
  public changeResponse = false

  constructor(
    private readonly playerCollection: PlayerNameService,
    private readonly player: TournamentManagementPlayersService,
  ) {}

  public answer(accept: boolean) {
    if (accept) {
      this.player.acceptInvitation([this.data.message.invitedPlayer])
    }
    else {
      this.player.declineInvitation([this.data.message.invitedPlayer])
    }
    this.changeResponse = false
  }

  public get icon() {
    return {
      invite: faEnvelopeOpen,
      decline: faTimes,
      accept: faCheck,
      link: faExternalLinkAlt,
    }
  }

  public get hasAnswered() {
    if (this.data) {
      return this.data.message.invitedPlayer.answeredAt !== undefined && this.data.message.invitedPlayer.answeredAt !== null
    }
    return false
  }

}

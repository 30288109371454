<div class="message-wrapper d-flex gap-3 px-3 p-2 position-relative scryfall-bot">

  <div class="user-icon">
    <div class="icon-wrapper">
      <fa-icon [fixedWidth]="true" [icon]="faRobot"></fa-icon>
    </div>
  </div>

  <div class="message">
    <div class="header">
      <div class="scryfall-card-name"><a [href]="messageDoc.message.content.scryfallUri"
          target="_blank">{{ messageDoc.message.content.cardName }} <div class="symbol-text"
            [innerHTML]="messageDoc.message.content.manaCost | manaParser : { fontClass: 'text-smaller' }"></div></a></div>
      <div class="message-timestamp text-secondary" *ngIf="auth.user.settings.clock24">
        {{ getDate(messageDoc.message.timestamp) | date: 'HH:mm' }}</div>
      <div class="message-timestamp text-secondary" *ngIf="!auth.user.settings.clock24">
        {{ getDate(messageDoc.message.timestamp) | date: 'HH:mm a' }}</div>
      <div class="scryfall-set-name text-secondary">{{ messageDoc.message.content.setName }}</div>
    </div>
    <div class="message-wrapper mt-1">
      <div class="card-info">
        <div>{{ messageDoc.message.content.typeLine }}</div>
        <div class="card-oracle-text" [innerHTML]="messageDoc.message.content.oracleText | manaParser : { fontClass: 'text-smaller' }"></div>
        <div class="text-italic">{{ messageDoc.message.content.flavorText }}</div>
        <div *ngIf="messageDoc.message.content.power && messageDoc.message.content.toughness">
          {{ messageDoc.message.content.power }}/{{ messageDoc.message.content.toughness }}</div>
        <div *ngIf="messageDoc.message.content.loyalty">Loyalty: {{ messageDoc.message.content.loyalty }}</div>
      </div>
      <div class="message-content image {{ messageDoc.message.content.setCode }}">
        <img [src]="messageDoc.message.content.imageUrl">
      </div>
    </div>
  </div>
  
  <div class="suffix">
    <fa-icon [icon]="whisperMode" class="text-secondary mt-1" *ngIf="messageDoc.isWhispered"></fa-icon>
  </div>

</div>

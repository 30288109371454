import { ToastService } from './toast.service'
import { BehaviorSubject, EMPTY, Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { AuthService } from 'src/app/services/auth.service'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { v4 as uuidv4 } from 'node_modules/uuid'
import { IClub, IClubImages, IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces'

export enum ClubImageNames {
  LARGE = 'large',
  NORMAL = 'normal',
  SMALL = 'small',
  THUMB = 'thumb'
}
export enum ClubImageSizes {
  LARGE = 1024,
  NORMAL = 800,
  SMALL = 400,
  THUMB = 200
}

@Injectable({
  providedIn: 'root'
})
export class ClubsService {

  private clubsCollection = this.afs.collection<IClub>('clubs')

  private clubs$ = new BehaviorSubject<IClub[]>([])

  constructor(
    private auth: AuthService,
    private afs: AngularFirestore,
    private toastService: ToastService
  ) {
    this.getClubs().subscribe(i => this.clubs$.next(i))
  }

  public getClubs(): Observable<IClub[]> {
    return this.clubsCollection.valueChanges()
  }
  public getClub(clubDocId: string): Observable<IClub> {
    return this.clubsCollection.doc(clubDocId).valueChanges()
  }
  public getClubImages(clubDocId: string): Promise<IClubImages> {
    return new Promise((resolve) => {
      const clubs = this.clubsCollection.doc(clubDocId).get()
      clubs.subscribe(snap => {
        if (snap.exists) {
          const clubData = snap.data()
          resolve(clubData.imageUris)
        }
        else {
          resolve(null)
        }
      })
    })
  }
  public getClubByPlayerId(playerDocId: string): Promise<Observable<IClub>> {
    return new Promise(async (resolve) => {
      // get all clubs from the local behavior subject
      let clubs = this.clubs$.getValue()
      // find the first club where the player is a member
      let club = clubs.find(i => i.memberDocIds.includes(playerDocId))
      // check if clubs are loaded and that a club found with the player as a member
      if (clubs.length === 0 || club === undefined) {
        resolve(EMPTY)
      }
      // return an observable of the club document
      resolve(this.getClub(club.docId))
    })
  }
  public createClub(name: string, responsiblePlayerDocId: string): Promise<IPromiseResponse> {
    return new Promise((resolve, reject) => {
      const docId = uuidv4()
      const club: IClub = {
        docId,
        createdByUid: this.auth.user.uid,
        createdTimestamp: new Date().getTime(),
        updatedTimestamp: new Date().getTime(),
        updatedByPlayerDocId: this.auth.user.playerId,
        name,
        country: '',
        region: '',
        city: '',
        websiteUrl: '',
        info: '',
        imageUris: {
          thumb: '',
          small: '',
          normal: '',
          large: ''
        },
        responsiblePlayerDocIds: [
          responsiblePlayerDocId
        ],
        memberDocIds: [
          responsiblePlayerDocId
        ],
        longitude: null,
        latitude: null
      }
      this.afs.collection('clubs').doc(docId).set(club)
        .then(() => {
          this.toastService.show('Successfully created the club', { classname: 'success-toast', delay: 2000 })
          resolve({
            status: true,
            text: 'Successfully created the clubs'
          })
        })
        .catch((err) => {
          console.log(err)
          this.toastService.show('Error when trying to create the club', { classname: 'error-toast', delay: 6000 })
          resolve({
            status: false,
            text: 'Error when trying to create the club',
            data: err
          })
        })
    })
  }
  public saveClub(clubData: IClub): Promise<IPromiseResponse> {
    return new Promise((resolve) => {
      clubData.updatedByPlayerDocId = this.auth.user.playerId
      clubData.updatedTimestamp = new Date().getTime() / 1000
      this.clubsCollection.doc(clubData.docId).set(clubData)
        .then(() => {
          this.toastService.show('Successfully save the changes', { classname: 'success-toast', delay: 2000 })
          resolve({
            status: true,
            text: 'Successfully saved'
          })
        })
        .catch((err) => {
          console.log(err)
          this.toastService.show('Error when trying to save the changes', { classname: 'error-toast', delay: 6000 })
          resolve({
            status: false,
            text: 'Error when trying to save',
            data: err
          })
        })
    })
  }
}

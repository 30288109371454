import { takeUntil } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/config.service'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'

import { ToastService } from 'src/app/services/toast.service'
import { faCheckSquare, faSquare, faPlusCircle, faExternalLinkAlt, faTrashAlt, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore'
import { Observable, Subject, Subscription } from 'rxjs'
import { AuthService, CountryService, GlobalsService } from 'src/app/services'
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core'
import { ICountry, IPlayerDetails, IPlayerHandle, ITimeZone } from 'tolaria-cloud-functions/src/_interfaces'
import * as firestore from 'firebase/firestore'
import { AddPlayerHandleComponent } from '../add-player-handle/add-player-handle.component'
@Component({
  selector: 'app-you',
  templateUrl: './you.component.html',
  styleUrls: ['./you.component.css']
})
export class YouComponent implements OnInit, OnDestroy {

  @ViewChild('newEmailInput') newEmailInput: ElementRef

  private playerDocRef: AngularFirestoreDocument<IPlayerDetails>
  public playerDoc$: Observable<IPlayerDetails>
  public countries: Array<ICountry>
  public timeZones: Array<ITimeZone>
  public editMode = false
  public localPlayerDoc: IPlayerDetails
  private playerSubscription: Subscription
  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()

  public newEmailAddress: string = ''

  icons = {
    faCheckSquare,
    faSquare,
    faPlusCircle,
    faExternalLinkAlt,
    faTrashAlt,
    faExclamationTriangle,
  }
  handleIcons = this.configService.handleIcons

  private authProvider: string

  constructor(
    private auth: AuthService,
    private ctrs: CountryService,
    private afs: AngularFirestore,
    private toastService: ToastService,
    public globals: GlobalsService,
    private modalService: NgbModal,
    private configService: ConfigService
  ) {
    this.playerDocRef = this.afs.collection('players').doc<IPlayerDetails>(this.auth.user.playerId)
    this.playerDoc$ = this.playerDocRef.valueChanges()
    this.playerDoc$.pipe(takeUntil(this.componentDestroyed$)).subscribe((playerDoc) => {
      this.localPlayerDoc = playerDoc
      if (playerDoc.hasOwnProperty('address')) {
        this.localPlayerDoc.address = playerDoc.address
      }
      else {
        this.localPlayerDoc.address = {
          street: '',
          other: '',
          zipCode: null,
          city: '',
          region: ''
        }
      }
      // optional properties
      if (playerDoc.hasOwnProperty('emailIsPublic')) {
        this.localPlayerDoc.emailIsPublic = playerDoc.emailIsPublic
      }
      else {
        this.localPlayerDoc.emailIsPublic = false
      }
      if (playerDoc.hasOwnProperty('addressIsPublic')) {
        this.localPlayerDoc.addressIsPublic = playerDoc.addressIsPublic
      }
      else {
        this.localPlayerDoc.addressIsPublic = false
      }
      if (playerDoc.hasOwnProperty('phoneIsPublic')) {
        this.localPlayerDoc.phoneIsPublic = playerDoc.phoneIsPublic
      }
      else {
        this.localPlayerDoc.phoneIsPublic = false
      }
      if (playerDoc.hasOwnProperty('description')) {
        this.localPlayerDoc.description = playerDoc.description
      }
      else {
        this.localPlayerDoc.description = ''
      }
      console.log(playerDoc)
    })
  }

  private async getAuthProvider() {
    this.authProvider = await this.auth.getAuthProvider()
    console.log(this.authProvider)
  }

  ngOnInit(): void {
    this.countries = this.ctrs.getCountries()
    this.timeZones = this.ctrs.getTimeZones()
    this.getAuthProvider()
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  byValue(data1: any, data2: any) {
    if (data1 === null || data1 === undefined) return false
    if (data2 === null || data2 === undefined) return false
    return data1 === data2
  }
  byName(data1: any, data2: any) {
    if (data1 === null || data1 === undefined) return false
    if (data2 === null || data2 === undefined) return false
    return data1.name === data2.name
  }
  byOlson(data1: any, data2: any) {
    if (data1 === null || data1 === undefined) return false
    if (data2 === null || data2 === undefined) return false
    return data1.olson === data2.olson
  }

  saveSetting(player: IPlayerDetails) {
    this.playerDocRef
      .update(player)
      .then(() => {
        this.toastService.show('Profile data updated', { classname: 'success-toast', delay: 2000 })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  addHandle(): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'sm',
    }

    this.modalService.open(AddPlayerHandleComponent, modalOptions).result
      .then((handle) => {
        this.afs.collection('players').doc(this.localPlayerDoc.docId).update({
          handles: firestore.arrayUnion(handle)
        })
          .then(() => console.log(handle, 'deleted'))
          .catch((error) => console.log(error))
      }, () => {
        // dismissed
      })
  }

  deleteHandle(handle: IPlayerHandle): void {
    this.afs.collection('players').doc(this.localPlayerDoc.docId).update({
      handles: firestore.arrayRemove(handle)
    })
      .then(() => console.log(handle, 'deleted'))
      .catch((error) => console.log(error))
  }

  // changeCountry(index: number) {
  //   this.registrationForm.country.name = this.countries[index].name
  //   this.registrationForm.country.region = this.countries[index].region
  //   this.registrationForm.country.subRegion = this.countries[index].subregion
  //   this.registrationForm.country.code = this.countries[index].alpha2Code
  //   this.registrationForm.country.callingCode = this.countries[index].callingCodes[0]
  // }

  // changeTimeZone(index: number) {
  //   this.registrationForm.timeZone = this.timeZones[index]
  // }

  public get canChangeEmail(): boolean {
    if (this.authProvider === undefined || this.authProvider === null) {
      return false
    }
    return this.authProvider === 'password'
  }

  public changeEmail(): void {

    this.modalService.open(this.newEmailInput, {
      size: 'sm',
      centered: false,
      backdrop: true,
      keyboard: true,
      animation: true,
    }).result
      .then(
        (res) => {
          console.log('returned from the modal dialog', res, this.newEmailAddress)
          if (res) {
            this.auth.updateEmailAddress(this.newEmailAddress)
              .then((r) => console.log('email updated: ', r))
              .catch((e) => console.log(e))
          }
        },
        () => { })


  }

  public get emailValid(): boolean {
    return /^\w+([+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.newEmailAddress)
  }

}

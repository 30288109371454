import { Router } from '@angular/router';
import { AuthService, GlobalsService } from 'src/app/services';
import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'app-register-as-player',
  templateUrl: './register-as-player.component.html',
  styleUrls: ['./register-as-player.component.css']
})
export class RegisterAsPlayerComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private globals: GlobalsService,
    private router: Router
  ) {
    if (this.router.url.split('/')[1] === 'register' && this.auth.user.isRegisteredAsPlayer) {
      this.router.navigate(['dashboard']);
    }
  }
  @HostBinding('class.full-size') fullSize = true;

  ngOnInit(): void {
    // this.auth.user$.subscribe(user => {
    //   if (user.isRegisteredAsPlayer) {
    //     this.fullSize = false;
    //   }
    //   else {
    //     this.fullSize = true;
    //   }
    // });
    this.globals.routerLoaded = true;
  }

  public get isMobile() {
    return this.globals.isMobile;
  }

}

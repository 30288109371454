// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const stripeKey_TEST = 'pk_test_51JmQhhG1bIlPbCGkeeGCjr0Bi1Qq0i1CWKCgqco2cJ7tOT2OmPYSctLGSoiHTiDZdMIRTEqAMXomejUZO3TTzubn00cFIzP1vs'
const stripeKey = 'pk_live_51JmQhhG1bIlPbCGkIDYG25Be7hetqk79RKbFyFsVO1ce0e2knj5FHY5fruLEnZzrHMsnRpMGzzNgxcNTTuqLBr37007HEW6zt7'

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyD9L0t4F7VMqKZRAAR9nr5AYMbQsiA8b24',
    authDomain: 'tolaria-mtg.firebaseapp.com',
    databaseURL: 'https://tolaria-mtg.firebaseio.com',
    projectId: 'tolaria-mtg',
    storageBucket: 'tolaria-mtg.appspot.com',
    messagingSenderId: '948836046480',
    appId: '1:948836046480:web:5681572fd677b40210aa15',
    measurementId: 'G-K7NJ40QJS8',
  },
  stripeKey: stripeKey,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

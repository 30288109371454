import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject, combineLatest, takeUntil } from 'rxjs';
import { TimestampClockComponent } from 'src/app/components/app-structure/timestamp-clock/timestamp-clock.component';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { IMatchAppointment } from 'tolaria-cloud-functions/src/_interfaces';
import { MatchAppointmentHelperService } from '../../services/match-appointment-helper.service';

@Component({
  selector: 'app-match-appointment-item',
  templateUrl: './match-appointment-item.component.html',
  styleUrls: ['./match-appointment-item.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    TimestampClockComponent,
    NgbModule,
  ]
})
export class MatchAppointmentItemComponent implements OnDestroy {
  @Input() set appointment (val: IMatchAppointment) {
    this.data = val
    console.log(val)
    if (this._dataReady$.getValue() === false) {
      this._dataReady$.next(true)
    }
  }

  public componentReady$ = new BehaviorSubject<boolean>(false)
  public data: IMatchAppointment | null = null
  private _dataReady$ = new BehaviorSubject<boolean>(false)
  private destroyed$ = new Subject<boolean>()

  constructor(
    private readonly playerNames: PlayerNameService,
    private readonly helper: MatchAppointmentHelperService,
  ) {
    combineLatest([this._dataReady$, this.playerNames.serviceReady$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([d, p]) => {
        if (d !== undefined && p!== undefined && d !== null && p !== null && p !== false) {
          this.componentReady$.next(true)
        }
      })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  private get isReady(): boolean {
    return this.componentReady$.getValue()
  }


  public onAcceptPress(): void {
    this.helper.accept(this.data)
  }
  
  public onRejectPress(): void {
    this.helper.reject(this.data)
  }

  public onCancelPress(): void {
    this.helper.cancel(this.data)
  }


  public get statusClass(): string {
    if (this.isReady) {
      if (this.data.isAccepted)  { return 'text-success' }
      if (this.data.isRejected)  { return 'text-danger' }
    }
    return ''
  }

  public get statusText(): string {
    if (this.isReady) {
      if (this.data.isAccepted)  { return 'Accepted' }
      if (this.data.isRejected)  { return 'Rejected' }
      if (!this.data.isAccepted && !this.data.isRejected) { return 'Unread' }
    }
    return ''
  }

  public get isCancelled(): boolean {
    if (this.isReady) {
      return this.data.isCancelled
    }
    return false
  }

  public get canCancel(): boolean {
    if (this.isReady) {
      return this.data.playerDocId === this.playerNames.currentPlayersMini.id && !this.data.isCancelled
    }
    return false
  }

  public get canAccept(): boolean {
    if (this.isReady) {
      return this.data.opponentDocId === this.playerNames.currentPlayersMini.id && !this.data.isCancelled
    }
    return false
  }

  public get canReject(): boolean {
    if (this.isReady) {
      return this.data.opponentDocId === this.playerNames.currentPlayersMini.id && !this.data.isCancelled
    }
    return false
  }

  public get showRSVP(): boolean {
    if (this.isReady) {
      return this.data.opponentDocId === this.playerNames.currentPlayersMini.id && !this.data.isCancelled
    }
    return false
  }


}

import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import GenericScryfallResponse from 'scryfall-client/dist/models/generic-scryfall-response';
import List from 'scryfall-client/dist/models/list';
import { CardSearchService } from 'src/app/services/card-search.service';

@Component({
  selector: 'app-card-rulings',
  templateUrl: './card-rulings.component.html',
  styleUrls: ['./card-rulings.component.css']
})
export class CardRulingsComponent implements OnInit {
  @Input() id: string;
  public infoLoaded$ = new Subject<boolean>();

  public rulings: any;

  constructor(
    private cardSearch: CardSearchService
  ) {}

  ngOnInit(): void {
    this.cardSearch.getCardRulingById(this.id).then(rulings => {
      if (rulings !== null) {
        this.rulings = rulings;
      }
      this.infoLoaded$.next(true);
    });
  }

}

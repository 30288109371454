import { Component } from '@angular/core';

@Component({
  selector: 'app-event-to-deck-submission',
  templateUrl: './event-to-deck-submission.component.html',
  styleUrls: ['./event-to-deck-submission.component.css']
})
export class EventToDeckSubmissionComponent {

  constructor() { }

}

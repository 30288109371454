import { ToModalService } from './../to-modal.service'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { faChevronRight, faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces'
import { IEventDetails, IMatchData } from 'tolaria-cloud-functions/src/_interfaces'
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs'
import { EventService, PUSHTYPES } from 'src/app/services'

@Component({
  selector: 'app-event-actions-swiss',
  templateUrl: './event-actions-swiss.component.html',
  styleUrls: ['./event-actions-swiss.component.css']
})
export class EventActionsSwissComponent implements OnInit, OnDestroy {
  @Input() eventDoc$: Observable<IEventDetails>
  @Input() eventMatches$: Observable<IMatchData[]>

  faEllipsisH = faEllipsisH
  faChevronRight = faChevronRight

  public submenuOpen = false

  public event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  private matches$: BehaviorSubject<IMatchData[]> = new BehaviorSubject<IMatchData[]>(null)
  private destroyed$: Subject<boolean> = new Subject<boolean>()


  constructor(
    private eventService: EventService,
    private toModals: ToModalService
  ) { }

  ngOnInit(): void {
    this.eventDoc$.pipe(takeUntil(this.destroyed$)).subscribe(e => this.event$.next(e))
    this.eventMatches$.pipe(takeUntil(this.destroyed$)).subscribe(m => this.matches$.next(m))
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public openPlayerRegistration(event: IEventDetails): void {
    event.details.registrationOpen = true
    event.statusCode = 0
    event.statusText = this.eventService.statusText[0]
    this.eventService.updateEventData(event)
  }
  public async startEvent(event: IEventDetails) {

    // check if deck submission is active
    if (
      // deck list mandatory and players should be dropped if failed to submit
      event.details.hasOwnProperty('deckList') &&
      event.details.deckList !== undefined &&
      event.details.deckList &&
      event.details.dropPlayersWithoutSubmittedDeckOnStart ||
      // deck photo mandatory and players should be dropped if failed to submit
      event.details.hasOwnProperty('deckPhoto') &&
      event.details.deckPhoto !== undefined &&
      event.details.deckPhoto &&
      event.details.dropPlayersWithoutSubmittedDeckOnStart
    ) {
      // drop all players without deck photo uploaded or deck  listlinked
      await this.eventService.dropPlayersWithoutSubmittedDeck(event.docId, event.details.deckList, event.details.deckPhoto)
    }

    // start the event
    this.eventService.eventStart(event)
  }
  public reOpenRegistration(event: IEventDetails) {
    this.eventService.eventUpdateStatus(event, 0)
  }
  public pairRound(event: IEventDetails) {
    if (event.details.structure.swiss.teams) {
      this.eventService.pairSwissTeam(event.docId)
    }
    else {
      // this.eventService.eventPairSwissRound(event)
      this.eventService.pairSwiss(event.docId)
    }
  }
  public postPairings(event: IEventDetails) {
    this.eventService.eventUpdateStatus(event, 2).then(() => {
      this.eventService.eventSendPushAfterUpdate(event, PUSHTYPES.ROUND_PAIRINGS_POSTED)
    })
  }
  public startRound(event: IEventDetails) {
    this.eventService.eventStartSwissRound(event)
  }
  public endRound(event: IEventDetails) {
    this.eventService.endSwissRound(event)
  }
  public startPlayoff(event: IEventDetails) {
    this.eventService.eventUpdateStatus(event, 6, false)
  }
  public endEvent(event: IEventDetails) {
    this.eventService.calculateStats(event)
      .then((response: IPromiseResponse) => {
        // show message, error message persist longer
        if (response.status) {
          this.eventService.eventUpdateStatus(event, 8, false)
            .then((response2: IPromiseResponse) => console.log(response2))
        }
      })
  }
  public reCalculateStats(event: IEventDetails) {
    this.eventService.calculateStats(event)
  }
  public reopenEvent(event: IEventDetails) {
    const newStatus = 5
    this.eventService.eventUpdateStatus(event, newStatus, false)
      .then((response: IPromiseResponse) => console.log(response))
  }
  public openPlayoffConfigModal(eventDocId: string, isGroupEvent: boolean): void {
    this.toModals.openPlayoffConfigModal(eventDocId, isGroupEvent)
  }
  public openAnnouncementModal(eventDocId: string): void {
    this.toModals.openAnnouncementModal(eventDocId)
  }
  public openPayments(event$: Observable<IEventDetails>): void {
    this.toModals.openPayments(event$)
  }

  public get showEndRoundButton(): boolean {
    return this.event$.getValue().statusCode >= 3 && this.event$.getValue().statusCode <= 4
  }
  public get endRoundEnabled(): boolean {
    const event = this.event$.getValue()
    const matches = this.matches$.getValue()
    if (event !== null && matches !== null) {
      if (event.statusCode === 3 || event.statusCode === 4) {
        if (matches.filter(i => i.roundNumber === event.activeRound && !i.isReported && !i.deleted).length === 0) {
          return true
        }
      }
    }
    return false
  }

}

<div class="container d-flex p-3 align-items-center justify-content-between text-white" *ngIf="countDown !== undefined">

  <div class="player1 d-flex flex-column align-items-center">

    <div class="h3 mt-3">
      {{ isPlayer1 ? 'You' : 'Opponent' }}
    </div>

    <div #player1 class="flip-container" [ngClass]="{ 'hover' : showResult }">
      <div class="flipper">
        <div class="front">
          <!-- front content -->
          <div class="card-back">
            <img src="assets/card-back.jpg">
          </div>
        </div>
        <div class="back">
          <!-- back content -->
          <div class="card-back rock-lobster" *ngIf="player1get === 'RockLobster'">
            <img src="assets/rock-lobster.jpg">
          </div>
          <div class="card-back scrissors-lizard" *ngIf="player1get === 'ScissorsLizard'">
            <img src="assets/scissors-lizard.jpg">
          </div>
          <div class="card-back paper-tiger" *ngIf="player1get === 'PaperTiger'">
            <img src="assets/paper-tiger.jpg">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="counter-result d-flex flex-column align-items-center">
    <div class="m-3 counter text-jumbo" *ngIf="countDown === undefined || countDown > 0" [innerText]="countDown"></div>
    <div class="m-3 result text-larger" *ngIf="countDown !== undefined && countDown === 0">
      <!-- player 1 wins -->
      {{ isPlayer1 && player1wins ? 'You win' : !isPlayer1 && !player1wins ? 'You win' : 'Opponent wins' }}
    </div>
  </div>

  <div class="player2 d-flex flex-column align-items-center">

    <div class="h3 mt-3">
      {{ !isPlayer1 ? 'You' : 'Opponent' }}
    </div>

    <div #player2 class="flip-container inverted" [ngClass]="{ 'hover' : showResult }">
      <div class="flipper">
        <div class="front">
          <!-- front content -->
          <div class="card-back">
            <img src="assets/card-back.jpg">
          </div>
        </div>
        <div class="back">
          <!-- back content -->
          <div class="card-back rock-lobster" *ngIf="player2get === 'RockLobster'">
            <img src="assets/rock-lobster.jpg">
          </div>
          <div class="card-back scrissors-lizard" *ngIf="player2get === 'ScissorsLizard'">
            <img src="assets/scissors-lizard.jpg">
          </div>
          <div class="card-back paper-tiger" *ngIf="player2get === 'PaperTiger'">
            <img src="assets/paper-tiger.jpg">
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="bracket-wrapper">
  <div class="round-wrapper" *ngFor="let round of bracketMatrix">
    <div class="round-header">{{ getRoundHeaderText(round, bracketMatrix.length) }}</div>
    <ng-container *ngIf="!isTeamSwiss">
      <div class="match-wrapper" *ngFor="let matchInfo of round.matches">
        <app-event-bracket-match
          [matchInfo]="matchInfo"
          [eventDocId]="eventDocId"
          [isOrganizer]="isOrganizer"
          [pathToVictory]="selectedPathToVictory"
          (mouseover)="selectedPathToVictory = matchInfo"
          (mouseout)="selectedPathToVictory = null"></app-event-bracket-match>
      </div>
    </ng-container>
    <ng-container *ngIf="isTeamSwiss">
      <div class="match-wrapper" *ngFor="let matchInfo of round.matches">
        <app-team-bracket-match
          [matchInfo]="matchInfo"
          [eventDocId]="eventDocId"
          [isOrganizer]="isOrganizer"
          [pathToVictory]="selectedPathToVictory"
          [round]="round.round"
          (mouseover)="selectedPathToVictory = matchInfo"
          (mouseout)="selectedPathToVictory = null"></app-team-bracket-match>
      </div>
    </ng-container>
  </div>
</div>

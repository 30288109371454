<ng-container *ngIf="event$ | async as event">

  <div class="card-header d-flex align-items-center">

    <div class="flex-grow-1">Proceed with:</div>

    <div class="btn-group main-action-button-wrapper" ngbTooltip="Main action to proceed" container="body">

      <!-- MAIN ACTIONS -->
      <ng-container>

        <button class="btn btn-main" (click)="openPlayerRegistration(event)" *ngIf="event.statusCode === 14">
          Open registration
        </button>

        <button class="btn btn-main" (click)="startEvent(event)" *ngIf="event.statusCode === 0"
          [disabled]="event.playerDocIds.length < 4">
          Start event
        </button>

        <!-- SWISS SPECIFIC ACTIONS -->
        <button class="btn btn-main" (click)="pairRound(event)" *ngIf="event.statusCode === 1">
          Pair round
        </button>

        <button class="btn btn-main" (click)="postPairings(event)" *ngIf="event.statusCode === 13">
          Post pairings
        </button>

        <button class="btn btn-main" (click)="startRound(event)" *ngIf="event.statusCode === 2">
          Start round
        </button>

        <button class="btn btn-main" (click)="endRound(event)"
          *ngIf="showEndRoundButton"
          [disabled]="!endRoundEnabled">
          End round
        </button>

        <button class="btn btn-main" (click)="startPlayoff(event)"
          *ngIf="event.statusCode === 5 && event.details.structure.swiss.hasBracketAfterSwiss">
          Configure Playoff
        </button>

        <button class="btn btn-main" (click)="openPlayoffConfigModal(event.docId, event.details.structure.isGroup)"
          *ngIf="event.statusCode === 6 && event.details.structure.swiss.hasBracketAfterSwiss">
          Open Playoff Config
        </button>

        <button class="btn btn-main" (click)="endEvent(event)" *ngIf="event.statusCode === 5 && !event.details.structure.swiss.hasBracketAfterSwiss ||
        event.statusCode === 7 && event.details.structure.swiss.hasBracketAfterSwiss">
          End Event
        </button>

        <button class="btn btn-main" *ngIf="event.statusCode === 8" (click)="reopenEvent(event)">
          Reopen Event
        </button>

      </ng-container>

      <!-- DROPDOWN MENU -->
      <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group"
        aria-label="Button group with nested dropdown">

        <button class="btn btn-main dropdown-toggle-split" ngbDropdownToggle ngbTooltip="Secondary actions" container="body" placement="bottom">
          <fa-icon [icon]="faEllipsisH"></fa-icon>
        </button>

        <div class="dropdown-menu" ngbDropdownMenu>

          <!-- GENERAL -->
          <div class="d-flex align-items-center mt-3 mb-2 ms-3 cursor-pointer" (click)="submenuOpen = !submenuOpen">
            <fa-icon [icon]="faChevronRight" class="text-small text-secondary me-2 transition-all-25" [ngClass]="{ 'rotate-90deg' : submenuOpen }"></fa-icon>
            <span class="text-secondary flex-grow-1">General</span>
          </div>
  
          <ng-container *ngIf="submenuOpen">
            <button ngbDropdownItem (click)="openAnnouncementModal(event.docId)">
              Send Announcement
            </button>
  
            <button ngbDropdownItem (click)="reOpenRegistration(event)" [disabled]="event.statusCode !== 1">
              Reopen Registration
            </button>
  
            <button ngbDropdownItem (click)="reCalculateStats(event)"
              [disabled]="event.statusCode === 11 || event.statusCode < 2">
              Re-Calculate Stats
            </button>
  
            <app-print-pairings ngbDropdownItem [event]="event" [textButton]="true" buttonText="Print"></app-print-pairings>
  
            <button ngbDropdownItem (click)="openPayments(eventDoc$)" [disabled]="!event.details?.registrationFee && !event.details?.registrationFee?.active">
              Payments
            </button>
          </ng-container>

          <app-more-actions-swiss [eventDoc$]="eventDoc$"></app-more-actions-swiss>

          <app-event-actions-check-in [eventDoc$]="eventDoc$"></app-event-actions-check-in>

          <app-event-actions-admin [eventDoc$]="eventDoc$"></app-event-actions-admin>

        </div>

      </div>

    </div>

  </div>

</ng-container>

<div class="modal-header text-large">{{ title }}</div>
<div class="modal-header d-flex flex-column">

  <div class="view-selector d-flex align-items-center justify-content-start w-100" *ngIf="!selectionOnly">
    <button class="btn btn-sm btn-blank me-2" [ngClass]="{ 'selected' : showLinking }" (click)="selectLinking(true)">Link
      existing deck</button>
    <button class="btn btn-sm btn-blank" [ngClass]="{ 'selected' : !showLinking }"
      (click)="selectLinking(false)">Create new deck</button>
    <div class="flex-grow-1"></div>
    <button class="btn btn-sm btn-blank text-secondary" routerLink="/decks" (click)="dismiss()" *ngIf="!globals.isMobile">
      Decks
      <fa-icon [icon]="faExternalLinkAlt" [fixedWidth]="true" class="ms-1"></fa-icon>
    </button>
  </div>

  <div class="search-wrapper mt-3 w-100" *ngIf="showLinking">
    <input type="text" class="form-control form-control-sm w-100"
      placeholder="Start typing to filter decks by deck name or card name" [(ngModel)]="filterString">

    <div class="selected-deck-wrapper d-flex align-items-center mt-3" *ngIf="selectedDeck$ | async as selectedDeck">

      <button class="btn btn-sm btn-blank de-select-deck" (click)="selectDeck(null)">
        <fa-icon [fixedWidth]="true" [icon]="faTimesCircle"></fa-icon>
      </button>

      <div class="text-large text-secondary me-4" [innerHtml]="selectedDeck.name"></div>

      <div class="me-4 version-selector">
        <div class="btn-group" ngbDropdown role="group">
          <button class="btn btn-sm btn-dark round-left round-right" ngbDropdownToggle>
            <span [innerHtml]="selectedDeckVersionName" class="me-2"></span>
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button ngbDropdownItem (click)="selectDeckVersion(null)"><span class="text-secondary">origin</span></button>
            <button ngbDropdownItem (click)="selectDeckVersion(version)"
              *ngFor="let version of selectedDeck.versions">{{ version.versionName }}</button>
          </div>
        </div>
      </div>

      <button class="btn btn-sm btn-blank" [disabled]="selectedDeckListInvalid" (click)="openDeckListViewer()">
        <fa-icon [icon]="faListAlt" [fixedWidth]="true"></fa-icon>
      </button>
      <button class="btn btn-sm btn-blank" [disabled]="selectedDeckPhotoInvalid" (click)="openDeckPhotoViewer()">
        <fa-icon [icon]="faImage" [fixedWidth]="true"></fa-icon>
      </button>

      <ng-container *ngIf="selectedDeckValidity as validity">
        <div class="flex-grow-1 text-end ms-2 {{ validity.class }}">
          {{ validity.text }} <fa-icon [icon]="validity.icon" [fixedWidth]="true" class="ms-2"></fa-icon>
        </div>
      </ng-container>

    </div>
  </div>

</div>

<div class="modal-body p-0" [style.maxHeight.vh]="60">

  <ng-container *ngIf="showLinking; else newDeckRegistration">

    <div class="deck-list-selector d-flex flex-column">

      <div class="result-wrapper">
        <ng-container *ngIf="deckListItems$ | async as decks; else loader">
          <div class="list-item d-flex align-items-center cursor-pointer"
            *ngFor="let deck of decks | deckLinker : filterString" (click)="selectDeck(deck)">
            <span class="flex-grow-1">{{ deck.name }}</span>
            <span class="badge rounded-pill text-bg-secondary me-2" *ngIf="deck.versions?.length > 0"
              ngbTooltip="Number of versions" container="body">
              {{ deck.versions.length + 1 }}
            </span>
            <fa-icon [icon]="faListAlt" [fixedWidth]="true"
              class="me-2 {{ deck.deckListValid ? 'text-normal' : 'text-secondary' }}"
              [ngbTooltip]="deck.deckListValid ? 'Deck list available' : 'Deck list incomplete'" container="body"></fa-icon>
            <fa-icon [icon]="faImage" [fixedWidth]="true"
              class="me-2 {{ deck.deckPhotoValid ? 'text-normal' : 'text-secondary' }}"
              [ngbTooltip]="deck.deckPhotoValid ? 'Deck photo available' : 'Deck photo missing'" container="body"></fa-icon>
          </div>
        </ng-container>

        <ng-template #loader>
          <div class="d-flex flex-column gap-3 align-items-center justify-content-center p-5">
            <div class="spinner-border text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="text-secondary">Loading all your decks, please wait...</div>
          </div>
        </ng-template>

      </div>

    </div>

  </ng-container>

  <ng-template #newDeckRegistration>

    <ng-container *ngIf="newDeck">

      <div class="new-deck-wrapper">

        <div class="details-form p-3">
          <div class="form-group">
            <label class="text-secondary">Name</label>
            <input type="text" class="form-control form-control-sm w-100 {{ newDeck.name.length < 5 ? 'is-invalid' : 'is-valid' }}" [(ngModel)]="newDeck.name">
          </div>
          <div class="form-group mt-3">
            <label class="text-secondary">Description</label>
            <textarea rows="3" class="form-control form-control-sm w-100 {{ newDeck.description.length < 5 ? 'is-invalid' : 'is-valid' }}" [(ngModel)]="newDeck.description"></textarea>
          </div>
        </div>

        <div class="deck-list-importer">

          <div class="p-3">
            <label class="text-secondary d-flex align-items-center">
              <span class="">Deck Photo</span>
              <div class="flex-grow-1 text-end text-red" *ngIf="mandatoryDeckPhoto">* mandatory</div>
            </label>
            <app-image-cropper-uploader (base64)="croppedImage($event)"></app-image-cropper-uploader>
          </div>

          <div>
            <label class="px-3 text-secondary d-flex align-items-center">
              <span class="me-5 pe-2">Deck List</span>
              <span class="btn btn-sm btn-blank text-secondary ms-5" (click)="showRegistrationHelp = !showRegistrationHelp">
                <fa-icon [icon]="iconHelp" [fixedWidth]="true"></fa-icon>
              </span>
              <div class="flex-grow-1 text-end text-red" *ngIf="mandatoryDeckList">* mandatory</div>
            </label>
            <app-deck-list-importer
              [modal]="false"
              [showCardPreview]="false"
              [deckList]="newDeck"
              [showDeckPreviewHeader]="false"
              [showInputHeader]="false"
              [toggleHelp]="showRegistrationHelp"
              (deckListOutput)="loadDeckFromImporter($event)"></app-deck-list-importer>
          </div>
        </div>

      </div>

    </ng-container>

  </ng-template>


</div>

<div class="modal-footer">
  <!-- disableConfirmButton: {{ disableConfirmButton }} -->
  <button class="btn btn-sm btn-blank text-secondary" (click)="dismiss()">Cancel</button>
  <button class="btn btn-sm btn-blank" (click)="close()" [disabled]="disableConfirmButton">Submit</button>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ImagePreviewService } from 'src/app/private/_shared/services/image-preview.service';

@Component({
  selector: 'app-deck-image',
  templateUrl: './deck-image.component.html',
  styleUrls: ['./deck-image.component.css']
})
export class DeckImageComponent implements OnInit {

  @Input() imageUrl: string;

  public marginLeft: boolean = false;
  public showImage: boolean = false;

  constructor(
    private deviceService: DeviceDetectorService,
    private imagePreview: ImagePreviewService,
  ) {
  }

  ngOnInit(): void {
    this.marginLeft = this.deviceService.isMobile();
  }

  openImageViewer(): void {
    this.imagePreview.show({
      imageUris: [this.imageUrl]
    })
  }
}

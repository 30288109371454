import { CardSearchService } from './card-search.service'
import { ToastService } from './toast.service'
import { Router } from '@angular/router'
import * as firestore from 'firebase/firestore'
import { AuthService, EventService } from 'src/app/services'
import { finalize, map, take, tap, startWith, withLatestFrom } from 'rxjs/operators'
import { Observable, combineLatest, Subscription, BehaviorSubject, Subject } from 'rxjs'
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore'
import { Injectable } from '@angular/core'
import { v4 as uuidv4 } from 'node_modules/uuid'
import { AngularFireStorage } from '@angular/fire/compat/storage'
import _ from 'lodash'
import CardApiResponse from 'scryfall-client/dist/types/api/card'
import GenericScryfallResponse from 'scryfall-client/dist/models/generic-scryfall-response'
import List from 'scryfall-client/dist/models/list'
import { IInvitedPlayer, IMatchAppointment, IMatchData, IMessageDocument, IMessageGroupDocument, IMessageImage, IMessageReaction, IPromiseResponse, IReplyToMeta } from 'tolaria-cloud-functions/src/_interfaces'
import { PlayerNameService } from './players/player-name.service'

export enum MessageType {
  CHAT_MESSAGE = 'chat-message',
  EVENT_INVITATION = 'event-invitation',
  SCRYFALL_IMAGE = 'scryfall-image',
  SCRYFALL_RULES = 'scryfall-rules',
  EVENT_ANNOUNCEMENT = 'event-announcement',
  MATCH_INVITATION = 'match-invitation',
  MATCH_APPOINTMENT = 'match-appointment',
  MATCH_ROOM_ACTION = 'match-room-action'
}
export interface IMessageListItemPlayer {
  displayName: string
  playerDocId: string
  playerUid: string
  avatar: string
}
export interface IMessageListItem {
  isSingle: boolean
  chatName: string
  messageGroupDocId: string
  playerNames: string[]
  players?: Array<IMessageListItemPlayer>
  hasNewMessages: boolean
  latestMessageTimestamp: number
  latestMessagePreview: string
  isEventChat: boolean
  eventDocId: string | null
  routerLink: string
  eventEnded: boolean
}
export interface IMessageItem {
  message: IMessageDocument
  playerDisplayName?: string
  playerAvatar?: string
  playerDocId: string
  playerUid: string
  showHeader: boolean
  replyingTo: boolean
  docId?: string
  replyToMeta?: IReplyToMeta
  reactions?: IMessageReactionMeta[]
}
export interface IMessageReactionMeta {
  reaction: string
  playerDocIds: string[]
}
export interface IMessageOptions {
  matchChat?: boolean
  spectatorMode?: boolean
  matchDocId?: string
  matchInvitation?: boolean
  eventInvitation?: boolean
  invitedPlayer?: IInvitedPlayer
  matchDoc?: IMatchData
  whisperMode?: string
  mentionedPlayerDocIds?: Array<string>
  messageDocId?: string
  images?: IMessageImage[]
  replyTo?: IReplyToMeta
}
export interface ICardSearchParams {
  searchString: string
  searchRules: boolean
  searchSet: boolean
  setString: string
}

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  private messagesGroupCollection: AngularFirestoreCollection<IMessageGroupDocument>
  public messageGroupDocs$: Observable<IMessageGroupDocument[]>
  public serviceReady$ = new Subject<boolean>()
  // public playerMetaDocs$: Observable<IPlayerMeta[]>
  public messageGroups: Array<IMessageGroupDocument> = []
  public hasUnreadMessages$: Observable<boolean>
  public messageGroupDocList$: Observable<IMessageListItem[]>
  public replyToMessage$: BehaviorSubject<IMessageItem> = new BehaviorSubject<IMessageItem>(null)

  public autoScrollDown: boolean = true
  public showNewMessageNotis: boolean = false
  public loadingHistory: boolean = false
  public messageLimit$: BehaviorSubject<number> = new BehaviorSubject<number>(25)
  public messageLimitIncrementStep: number = 25

  private latestAnnouncementMessage: string = null
  private latestMessage: string = null

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private router: Router,
    private toastService: ToastService,
    private es: EventService,
    private storage: AngularFireStorage,
    private cardSearch: CardSearchService,
    private readonly playerNames: PlayerNameService,
  ) {
    this.playerNames.serviceReady$.subscribe((ready) => {
      if (ready) {
        console.log('MessagesService:: PlayerNameService now ready, lets init the MessagesService', ready)
        this.init()
      }
    })

  }

  private init(): void {

    this.messageGroupDocs$ = this.afs.collection<IMessageGroupDocument>('messageGroups', ref => ref
      .where('playerDocIds', 'array-contains', this.auth.user.playerId)
      .orderBy('latestMessage', 'desc')
    ).valueChanges()
    // this.playerMetaDocs$ = this.ps.getPlayersMetaData()
    this.hasUnreadMessages$ = this.messageGroupDocs$.pipe(map(messageGroups => {
      return messageGroups.filter(m => {
        if (m.playersLastVisit && m.playersLastVisit[this.auth.user.playerId] !== undefined) {
          return m.playersLastVisit[this.auth.user.playerId] < m.latestMessage
        }
        return false
      }).length > 0
    }))

    this.messageGroupDocList$ = combineLatest([this.messageGroupDocs$, this.es.events$]).pipe(
      map(([messageGroupDocs, events]) => {
        return messageGroupDocs.map((doc) => {
          const playerNames: string[] = doc.playerDocIds.filter(i => !i.includes('temp__')).map((pDocId) => {
            const p = this.playerNames.getPlayerById(pDocId)
            if (p !== undefined) {
              return p.name.display
            }
            else {
              console.log('Cannot find player with document id', pDocId)
            }
          })
          const listedPlayers: Array<IMessageListItemPlayer> = doc.playerDocIds.filter(i => !i.includes('temp__')).map((pDocId) => {
            const p = this.playerNames.getPlayerById(pDocId)
            if (p !== undefined) {
              return {
                playerUid: p.uid,
                playerDocId: p.id,
                displayName: p.name.display,
                avatar: p.avatar,
              }
            }
            else {
              console.log('Cannot find player with document id', pDocId)
            }
          })
          let chatName: string
          if (doc.name && doc.name !== '') {
            chatName = doc.name
          }
          else {
            chatName = this.chatNameParser(listedPlayers)
          }
          const hasNewMessages = doc.hasOwnProperty('playersLastVisit')
            && doc.playersLastVisit[this.auth.user.playerId] < doc.latestMessage || doc.hasOwnProperty('playersLastVisit')
            && doc.playersLastVisit[this.auth.user.playerId] === undefined

          let isEventChat = false
          let eventEnded = false
          if (doc.name !== undefined && doc.docId.includes('eventChatFor')) {
            isEventChat = true
            const eventDocId = doc.docId.match(/\[(.*)\]/i)[0]
            eventEnded = events.find(e => e.docId === eventDocId) ? events.find(e => e.docId === eventDocId).statusCode === 8 : false
          }
          return {
            isSingle: doc.isSingle,
            chatName,
            messageGroupDocId: doc.docId,
            playerNames,
            players: listedPlayers,
            hasNewMessages,
            latestMessagePreview: doc.latestMessagePreview,
            latestMessageTimestamp: doc.latestMessage,
            isEventChat,
            eventEnded
          } as IMessageListItem
        })
      })

    )


    // this.messageGroupDocList$ = combineLatest([this.messageGroupDocs$, this.playerMetaDocs$, this.es.events$]).pipe(
    //   map(([messageGroupDocs, players, events]) => {
    //     return messageGroupDocs.map((doc) => {
    //       const playerNames: string[] = doc.playerDocIds.map((pDocId) => {
    //         const p = players.find((player) => player.docId === pDocId)
    //         if (p) {
    //           if (pDocId.substr(0, 6) !== 'temp__') {
    //             return p.name
    //           }
    //           else {
    //             console.log(`${pDocId} is temporary, skipping`)
    //           }
    //         }
    //         else {
    //           console.log(`${pDocId} not found within players`)
    //         }
    //       })
    //       const listedPlayers: Array<IMessageListItemPlayer> = doc.playerDocIds.map((pDocId) => {
    //         const p = players.find((player) => player.docId === pDocId)
    //         if (p) {
    //           if (pDocId.substr(0, 6) !== 'temp__') {
    //             return {
    //               playerUid: p.uid,
    //               playerDocId: p.docId,
    //               displayName: p.name,
    //               avatar: p.avatarUrl
    //             }
    //           }
    //         }
    //         else {
    //           console.log(`${pDocId} not found within players`)
    //         }
    //       })
    //       let chatName: string
    //       if (doc.name && doc.name !== '') {
    //         chatName = doc.name
    //       }
    //       else {
    //         chatName = this.chatNameParser(listedPlayers)
    //       }
    //       const hasNewMessages = doc.hasOwnProperty('playersLastVisit')
    //         && doc.playersLastVisit[this.auth.user.playerId] < doc.latestMessage || doc.hasOwnProperty('playersLastVisit')
    //         && doc.playersLastVisit[this.auth.user.playerId] === undefined

    //       let isEventChat = false
    //       let eventEnded = false
    //       if (doc.name !== undefined && doc.docId.includes('eventChatFor')) {
    //         isEventChat = true
    //         const eventDocId = doc.docId.match(/\[(.*)\]/i)[0]
    //         eventEnded = events.find(e => e.docId === eventDocId) ? events.find(e => e.docId === eventDocId).statusCode === 8 : false
    //       }
    //       return {
    //         isSingle: doc.isSingle,
    //         chatName,
    //         messageGroupDocId: doc.docId,
    //         playerNames,
    //         players: listedPlayers,
    //         hasNewMessages,
    //         latestMessagePreview: doc.latestMessagePreview,
    //         latestMessageTimestamp: doc.latestMessage,
    //         isEventChat,
    //         eventEnded
    //       } as IMessageListItem
    //     })
    //   })
    // )

    this.serviceReady$.next(true)
  }

  public resetMessageLimit(): void {
    this.messageLimit$.next(50)
  }
  public getMessageGroups(): Observable<IMessageListItem[]> {
    // Create combined observable for the Message List
    return this.messageGroupDocs$.pipe(
      withLatestFrom(this.playerNames.serviceReady$, this.es.events$),
      tap(([messageGroupDocs, playerNamesReady, events]) => {
        if (messageGroupDocs !== null && playerNamesReady && events !== null) {
          console.log('MessagesService -> collections ready to be mapped')
        }
        else {
          console.log('MessagesService -> waiting for all collections before mapping can start')
        }
      }),
      map(([messageGroupDocs, playerNamesReady, events]) => {
        if (messageGroupDocs !== null && playerNamesReady && events !== null) {
          return messageGroupDocs.map((doc) => {
            const playerNames: string[] = doc.playerDocIds.filter(i => !i.includes('temp__')).map((pDocId) => {
              return this.playerNames.getPlayerById(pDocId).name.display
            })
            const listedPlayers: Array<IMessageListItemPlayer> = doc.playerDocIds.filter(i => !i.includes('temp__')).map((pDocId) => {
              const p = this.playerNames.getPlayerById(pDocId)
              return {
                playerUid: p.uid,
                playerDocId: p.id,
                displayName: p.name.display,
                avatar: p.avatar
              }
            })
            let chatName: string
            if (doc.name && doc.name !== '') {
              chatName = doc.name
            }
            else {
              chatName = this.chatNameParser(listedPlayers)
            }
            const hasNewMessages = doc.hasOwnProperty('playersLastVisit')
              && doc.playersLastVisit[this.auth.user.playerId] < doc.latestMessage || doc.hasOwnProperty('playersLastVisit')
              && doc.playersLastVisit[this.auth.user.playerId] === undefined

            let isEventChat = false
            let eventDocId = null
            let eventEnded = false
            let routerLink = ''
            if (doc.name !== undefined && doc.docId.includes('eventChatFor')) {
              const event = events.find(e => e.docId === eventDocId)
              isEventChat = true
              eventDocId = doc.docId.match(/\[(.*)\]/i)[1]
              routerLink = event
                ? '/tournament/' + eventDocId
                // ? '/event-lobby/' + eventDocId + '/' + event.details.name
                : 'Event Not Found'
              eventEnded = event
                ? event.statusCode === 8
                : false
            }
            const messageListItem: IMessageListItem = {
              isSingle: doc.isSingle,
              chatName,
              messageGroupDocId: doc.docId,
              playerNames,
              players: listedPlayers,
              hasNewMessages,
              isEventChat,
              eventDocId,
              eventEnded,
              routerLink,
              latestMessagePreview: doc.latestMessagePreview,
              latestMessageTimestamp: doc.latestMessage
            }
            return messageListItem
          })
        }
      })
    )
  }
  public async getImagesForMessageGroup(messageGroupDocId: string): Promise<string[]> {
    return new Promise((resolve) => {
      this.storage.ref(`message-attachments/${messageGroupDocId}`).listAll()
        .pipe(take(1))
        .subscribe(async (list) => {
          const imageUris: string[] = []
          for await (const item of list.items) {
            const url = await item.getDownloadURL()
            imageUris.push(url)
          }
          resolve(imageUris)
        })
    })
  }
  public getMessagesObservableForMatchChatByMessageGroupDocId(matchDocId: string): Observable<IMessageDocument[]> {
    return this.afs.collection<IMessageDocument>('messages', ref => ref
      .where('matchDocId', '==', matchDocId)
      .where('spectatorMode', '==', false)
      .where('whisperMode', '==', null)
    ).valueChanges()
  }
  public getMembersOfMessageGroup(messageGroupDocId: string) {
    const messageGroups$ = this.afs.collection<IMessageGroupDocument>('messageGroups', ref => ref
      .where('docId', '==', messageGroupDocId)
    ).valueChanges()
    return messageGroups$.pipe(
      withLatestFrom(this.playerNames.serviceReady$),
      map(([messageGroupDocs, playerNamesReady]) => {
        if (messageGroupDocs !== null && playerNamesReady) {
          const messageGroupDoc = messageGroupDocs.find(messageGroup => messageGroup.docId === messageGroupDocId)
          const listedPlayers: Array<IMessageListItemPlayer> = messageGroupDoc.playerDocIds.filter(i => !i.includes('temp__')).map((pDocId) => {
            const p = this.playerNames.getPlayerById(pDocId)
            return {
              playerUid: p.uid,
              playerDocId: p.id,
              displayName: p.name.display.replace(/ /g, '_'),
              avatar: p.avatar
            }
          })
          return listedPlayers
        }
      })
    )
  }
  public getMessages(messageGroupDocId: string, matchChat: boolean, spectatorMode: boolean, matchDocId: string): Observable<IMessageItem[]> {
    const announcements$ = this.getEventAnnouncements(messageGroupDocId)
    const messages$ = this.getNonAnnouncementMessages(messageGroupDocId, matchChat, matchDocId, spectatorMode)

    let latestPlayerDocId = ''
    let latestTimeStamp = 0
    return combineLatest([messages$, announcements$.pipe(startWith([])), this.playerNames.serviceReady$]).pipe(
      tap(([messages, announcements, playerNamesReady]) => {
        // check regular messages
        if (messages.length > 0) {
          const latestMessage = _.first(messages)
          if (this.latestMessage !== null && this.latestMessage !== latestMessage.docId) {
            // set the latest message locally
            this.latestMessage = latestMessage.docId
            if (!this.autoScrollDown) { this.showNewMessageNotis = true }
          }
          else if (this.latestMessage === null) { this.latestMessage = latestMessage.docId }
        }

        // check announcements
        if (announcements.length > 0) {
          // set automtic scrolling
          this.autoScrollDown = true
          const latestAnnouncement = _.first(announcements)
          if (this.latestAnnouncementMessage !== null && this.latestAnnouncementMessage !== latestAnnouncement.docId) {
            // set latest announcement locally to not send duplicate sounds
            this.latestAnnouncementMessage = latestAnnouncement.docId
            // open message panels
            this.es.eventViewSettings.messages = true
            this.es.eventViewSettings.matchRoomMessages = true
            // play sound
            const audio = new Audio('assets/new-announcement-chat.wav')
            audio.play()
          }
          else if (this.latestAnnouncementMessage === null) { this.latestAnnouncementMessage = latestAnnouncement.docId }
        }
      }),
      map(([messages, annoucements, playerNamesReady]) => {
        if (playerNamesReady !== null && playerNamesReady === true) {
          const allMessages: IMessageItem[] = _.concat(messages, annoucements).sort((a, b) => a.timestamp - b.timestamp)
            .map(doc => {
              let showHeader = latestPlayerDocId !== doc.playerDocId
              if (!showHeader && latestPlayerDocId === doc.playerDocId && (doc.timestamp - latestTimeStamp) > 600) {
                showHeader = true
              }

              const returnObj: IMessageItem = {
                playerDocId: doc.playerDocId,
                playerUid: doc.playerUid,
                playerDisplayName: null,
                playerAvatar: null,
                message: doc,
                showHeader,
                replyingTo: false,
                reactions: []
              }

              if (doc.replyToMeta !== undefined && doc.replyToMeta !== null) {
                returnObj.replyToMeta = doc.replyToMeta
                returnObj.showHeader = true
                returnObj.replyingTo = true
              }

              if (doc.reactions !== undefined && doc.reactions !== null) {
                Object.keys(doc.reactions).forEach((reaction) => {
                  returnObj.reactions.push({
                    reaction,
                    playerDocIds: doc.reactions[reaction]
                  })
                  returnObj.reactions.sort((a, b) =>
                    (a.reaction > b.reaction) ? -1 :
                      ((b.reaction > a.reaction) ? 1 : 0))
                })
              }

              if (doc.type === 'chat-message' || doc.type === 'match-invitation') {
                const player = this.playerNames.getPlayerById(doc.playerDocId)
                returnObj.playerDisplayName = player
                  ? player.name.display
                  : null
                returnObj.playerAvatar = player && player.avatar
                  ? player.avatar
                  : null
              }

              latestPlayerDocId = doc.playerDocId
              latestTimeStamp = doc.timestamp

              return returnObj
            })
          return allMessages.filter(d => d.message.whisperMode === null || d.message.whisperMode === this.auth.user.playerId || d.message.type === MessageType.EVENT_ANNOUNCEMENT || d.message.type === MessageType.MATCH_APPOINTMENT)
        }
      })
    )
  }
  private getNonAnnouncementMessages(messageGroupDocId: string, matchChat: boolean, matchDocId: string, spectatorMode: boolean): Observable<IMessageDocument[]> {
    let collectionRef: AngularFirestoreCollection<IMessageDocument>
    if (matchChat) {
      collectionRef = this.afs.collection<IMessageDocument>('messages', ref => ref
        .where('messageGroupDocId', '==', messageGroupDocId)
        .where('matchChat', '==', matchChat)
        .where('matchDocId', '==', matchDocId)
        .where('spectatorMode', '==', spectatorMode)
        .orderBy('timestamp', 'desc')
        .limit(this.messageLimit$.getValue())
      )
    }
    else {
      collectionRef = this.afs.collection<IMessageDocument>('messages', ref => ref
        .where('messageGroupDocId', '==', messageGroupDocId)
        .where('matchChat', '==', matchChat)
        .where('spectatorMode', '==', spectatorMode)
        .orderBy('timestamp', 'desc')
        .limit(this.messageLimit$.getValue())
      )
    }
    const actions$ = collectionRef.snapshotChanges().pipe(
      map((actions) => {
        const messages = actions
          .reverse()
          .map(action => {
            const doc = action.payload.doc.data() as IMessageDocument
            doc.docId = action.payload.doc.id
            return doc
          })
        return messages.filter(m => m.type !== 'event-announcement')
      })
    )

    return actions$
  }
  private getEventAnnouncements(messageGroupDocId: string): Observable<IMessageDocument[]> {
    const collectionRef = this.afs.collection<IMessageDocument>('messages', ref => ref
      .where('messageGroupDocId', '==', messageGroupDocId)
      .where('type', '==', 'event-announcement')
      .orderBy('timestamp', 'desc')
    )
    const actions$ = collectionRef.snapshotChanges().pipe(
      map((actions) => {
        const messages = actions
          .reverse()
          .map(action => {
            const doc = action.payload.doc.data() as IMessageDocument
            doc.docId = action.payload.doc.id
            return doc
          })
        return messages
      })
    )

    return actions$
  }
  public chatNameParser(players: Array<IMessageListItemPlayer>) {
    let parsedName = ''
    let additonalPlayers = 0
    let playersAddedToString = 0
    const maxLength = 30

    players.forEach((player) => {
      if (player.playerUid !== this.auth.user.uid) {
        if ((parsedName.length + player.displayName.length + 2) > maxLength) {
          additonalPlayers++
        }
        else {
          if (playersAddedToString > 0) {
            parsedName += ', '
          }
          playersAddedToString++
          parsedName += player.displayName
        }
      }
    })

    if (additonalPlayers > 0) {
      parsedName += ' and ' + additonalPlayers + ' more'
    }

    return parsedName
  }
  public checkIfMessageGroupExist(playerDocId: string, otherPlayerDocId: string): Promise<IMessageGroupDocument> {
    return new Promise((resolve, reject) => {
      this.messageGroupDocs$.pipe(
        map((messageGroupDocs) => {
          return messageGroupDocs.find(messageGroup =>
            messageGroup.isSingle === true &&
            messageGroup.playerDocIds.indexOf(playerDocId) > -1 &&
            messageGroup.playerDocIds.indexOf(otherPlayerDocId) > -1
          )
        }),
        take(1)
      ).subscribe((doc) => {
        resolve(doc)
      })
    })
  }
  public postMatchAppointment(appointment: IMatchAppointment) {
    this.checkIfMessageGroupExist(appointment.playerDocId, appointment.opponentDocId)
      .then((messageGroupDoc) => {
        if (messageGroupDoc === undefined) {
          // create message group and then post message
          this.createMessageGroup(this.auth.user.playerId, appointment.playerDocId === this.auth.user.playerId ? appointment.opponentDocId : appointment.playerDocId)
            .then((messageGroupDocId) => {
              // post the message
              this.createAppointmentMessage(messageGroupDocId, appointment)
            })
        }
        else {
          // post the message
          this.createAppointmentMessage(messageGroupDoc.docId, appointment)
        }
      })
  }
  private createAppointmentMessage(messageGroupDocId: string, appointment: IMatchAppointment) {
    const message = 'New match appointment proposal'
    const timestamp = firestore.Timestamp.now().seconds
    const messageDoc: IMessageDocument = {
      timestamp,
      messageGroupDocId,
      playerDocId: this.auth.user.playerId,
      playerUid: this.auth.user.uid,
      message,
      type: 'match-appointment',
      matchChat: false,
      spectatorMode: false,
      matchDocId: appointment.matchDocId,
      content: {
        appointmentDocId: appointment.docId
      },
      whisperMode: null,
      archived: false,
    }
    this.afs.collection('messages').add(messageDoc).then((doc) => {
      this.afs.collection('messageGroups').doc(messageDoc.messageGroupDocId)
        .update({
          latestMessagePreview: message.substr(0, 30),
          latestMessage: timestamp,
          [`playersLastVisit.${this.auth.user.playerId}`]: timestamp,
        })
    })
  }
  public postChatMessage(
    content: string,
    messageGroupDocId: string,
    type: MessageType,
    options: IMessageOptions
  ) {
    // set scroll top so that the new message posted will be visible, even if the user has scrolled up.
    this.autoScrollDown = true

    // check for cardname
    if (content.indexOf('[[') > -1 && content.indexOf(']]') > -1) {
      const cardNamesRaw = content.match(/\[\[.*?\]\]/g)
      const cards = []
      cardNamesRaw.forEach((cardName: string) => {
        const cardObj: ICardSearchParams = {
          searchString: cardName.match(/\[\[([^)]+)\]\]/)[1].trim(),
          setString: '',
          searchRules: false,
          searchSet: false
        }
        if (cardObj.searchString.indexOf('?') > -1) {
          cardObj.searchRules = true
          cardObj.searchString = cardObj.searchString.substring(1)
        }
        if (cardObj.searchString.indexOf('|') > -1) {
          cardObj.searchSet = true
          cardObj.setString = cardObj.searchString.substring(cardObj.searchString.lastIndexOf('|') + 1).trim()
          cardObj.searchString = cardObj.searchString.substring(0, cardObj.searchString.indexOf('|')).trim()
        }
        cards.push(cardObj)
      })

      cards.forEach(cardObj => {
        // fetch card data
        this.fetchScryfallData(cardObj, messageGroupDocId, options.matchChat, options.spectatorMode, options.matchDocId, options.whisperMode).then((response: any) => {
          if (response.status) { this.afs.collection('messages').add(response.message) }
        })
      })
    }

    const timestamp = firestore.Timestamp.now().seconds
    const messageDoc: IMessageDocument = {
      messageGroupDocId,
      timestamp,
      playerDocId: this.auth.user.playerId,
      playerUid: this.auth.user.uid,
      message: content,
      type,
      matchChat: options.matchChat === undefined ? false : options.matchChat,
      spectatorMode: options.spectatorMode === undefined ? false : options.spectatorMode,
      matchDocId: options.matchDocId === undefined ? '' : options.matchDocId,
      content: {
        matchDoc: options.matchDoc === undefined ? null : options.matchDoc
      },
      whisperMode: options.whisperMode === undefined ? null : options.whisperMode,
      mentionedPlayerDocIds: options.mentionedPlayerDocIds === undefined ? [] : options.mentionedPlayerDocIds,
      replyToMeta: options.replyTo === undefined ? null : options.replyTo,
      archived: false,
    }

    // check for message type and perform updates to the document before saving
    if (type === MessageType.MATCH_INVITATION) {
      messageDoc.matchDocId = options.matchDoc.docId
    }
    if (type === MessageType.EVENT_INVITATION) {
      messageDoc.invitedPlayer = options.invitedPlayer
    }
    if (type === MessageType.MATCH_ROOM_ACTION) {
      messageDoc.playerDocId = 'match-room-action'
      messageDoc.playerUid = 'match-room-action'
    }

    // if messageDocId is defined, set the document else just add
    if (options.messageDocId !== undefined) {
      this.afs.collection('messages').doc(options.messageDocId)
        .set(messageDoc)
        .then(() => {
          if (options.images && options.images.length > 0) {
            this.uploadMessageImages(options.images, messageGroupDocId, options.messageDocId)
          }
        })
        .catch((err) => console.log(err))
    }
    else {
      this.afs.collection<IMessageDocument>('messages').add(messageDoc)
        .then(docRef => {
          if (options.images && options.images.length > 0) {
            this.uploadMessageImages(options.images, messageGroupDocId, docRef.id)
          }
        })
        .catch((err) => console.log(err))
    }

    // REPLACED BY CLOUD FUNCTION message.onCreate
    // // update the message group with last message as long as the message is NOT match chat, match action, or event announcement, we don't care about those!
    // if (
    //   type !== MessageType.EVENT_ANNOUNCEMENT && type !== MessageType.MATCH_ROOM_ACTION && options.matchChat === false ||
    //   type !== MessageType.EVENT_ANNOUNCEMENT && type !== MessageType.MATCH_ROOM_ACTION && options.matchChat === undefined
    // ) {
    //   // if whisperMode is set, skip update as we do not want to preview whisper messages
    //   if (options.whisperMode !== undefined && options.whisperMode === null || options.whisperMode === undefined) {
    //     if (!messageDoc.messageGroupDocId.includes('cardOfTheDay')) {
    //       this.afs.collection('messageGroups').doc(messageDoc.messageGroupDocId)
    //         .update({
    //           latestMessage: timestamp,
    //           latestMessagePreview: content.substr(0, 30),
    //           [`playersLastVisit.${this.auth.user.playerId}`]: timestamp,
    //           playerDocIds: firestore.arrayUnion(this.auth.user.playerId)
    //         })
    //     }
    //   }
    // }
  }
  public uploadMessageImages(images: IMessageImage[], messageGroupDocId: string, messageDocId: string): void {
    images.forEach(image => {
      const filePath = `message-attachments/${messageGroupDocId}/${image.guid}.png`
      const ref = this.storage.ref(filePath)
      const task = ref.putString(image.base64, 'data_url')
      const taskSubscription: Subscription = task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            const downloadUrl = ref.getDownloadURL()
            // store the download url as the avatar link for both user and player doc.
            const urlSubscription: Subscription = downloadUrl.subscribe(async (url) => {
              // close the subscriptions
              taskSubscription.unsubscribe()
              urlSubscription.unsubscribe()
              // update the message document with the images
              this.afs.collection('messages').doc(messageDocId)
                .update({
                  images: firestore.arrayUnion({
                    guid: image.guid,
                    downloadUrl: url
                  })
                })
            })
          })
        ).subscribe()
    })
  }
  public postAnnouncementMessage(
    content: string,
    messageGroupDocId: string,
    matchChat: boolean,
    spectatorMode: boolean,
    matchDocId: string
  ): Promise<IPromiseResponse> {
    return new Promise(async (resolve) => {
      const timestamp = firestore.Timestamp.now().seconds
      const messageDoc: IMessageDocument = {
        docId: uuidv4(),
        timestamp,
        messageGroupDocId,
        playerDocId: 'event-announcement',
        playerUid: 'event-announcement',
        message: content,
        type: 'event-announcement',
        matchChat,
        spectatorMode,
        matchDocId,
        archived: false,
      }
      const batch = this.afs.firestore.batch()

      batch.set(this.afs.collection('messages').doc().ref, (messageDoc))
      batch.update(this.afs.collection('messageGroups').doc(messageDoc.messageGroupDocId).ref, {
        latestMessagePreview: content.substr(0, 30),
        latestMessage: timestamp
      })

      batch
        .commit()
        .then(() => resolve({ status: true, text: 'Successfully sent messages' }))
        .catch((e) => resolve({ status: false, text: e }))

    })
  }
  public openChatWithPlayer(playerDocId: string) {
    return new Promise((resolve, reject) => {

      this.checkIfMessageGroupExist(playerDocId, this.auth.user.playerId)
        .then((messageGroupDoc) => {
          // define action to take
          let action = 'create'
          if (messageGroupDoc !== undefined) {
            // message group present already, just open
            action = 'open'
          }

          switch (action) {
            case 'create':
              this.createMessageGroup(this.auth.user.playerId, playerDocId).then((newDocId) => {
                if (newDocId !== '') {
                  this.router.navigate(['messages', newDocId])
                }
                resolve({
                  status: true,
                  text: 'new messageGroup created',
                  data: newDocId
                })
              })

              break

            case 'open':
              this.router.navigate(['messages', messageGroupDoc.docId])
              resolve({
                status: true,
                text: 'messageGroup exists',
                data: messageGroupDoc.docId
              })
              break
          }

        })
    })
  }
  public async createMessageGroup(playerDocId: string, otherPlayerDocId: string): Promise<string> {
    const timestamp = firestore.Timestamp.now().seconds
    const messageGroupDoc: IMessageGroupDocument = {
      docId: uuidv4(),
      createdByUid: this.auth.user.uid,
      createdDate: timestamp,
      latestMessage: timestamp,
      latestMessagePreview: '',
      playerDocIds: [
        playerDocId,
        otherPlayerDocId
      ],
      isSingle: true
    }

    return this.afs.collection('messageGroups')
      .doc(messageGroupDoc.docId)
      .set(messageGroupDoc)
      .then(() => {
        return messageGroupDoc.docId
      })
      .catch(error => {
        this.toastService.show(error, { classname: 'error-toast', delay: 5000 })
        return ''
      })
  }
  public updateMessageGroupName(messageGroupDocId: string, name: string) {
    this.afs.collection('messageGroups').doc(messageGroupDocId)
      .update({
        name
      })
  }
  public leaveMessageGroup(messageGroupDocId: string) {
    this.afs.collection('messageGroups').doc(messageGroupDocId)
      .update({
        playerDocIds: firestore.arrayRemove(this.auth.user.playerId)
      })
  }
  public addPlayersToMessageGroup(playerDocIds: Array<string>, messageGroupDocId: string) {
    playerDocIds.forEach((playerDocId) => {
      this.afs.collection('messageGroups').doc(messageGroupDocId)
        .update({
          playerDocIds: firestore.arrayUnion(playerDocId),
          isSingle: false
        })
    })
  }
  public sendMatchInvitation(playerDocId: string, matchDoc: IMatchData) {
    return new Promise((resolve, reject) => {
      // check if players have a messageGroup
      this.openChatWithPlayer(playerDocId).then((res: IPromiseResponse) => {
        const messageGroupDocId = res.data
        this.postChatMessage(
          'match-invitation',
          messageGroupDocId,
          MessageType.MATCH_INVITATION,
          {
            matchDoc
          })
        resolve({
          status: true,
          text: 'message posted, navigating to the message group',
          data: messageGroupDocId
        })
      })
    })
  }
  public updateMessageContent(messageDocId: string, messageContent: string) {
    this.afs.collection('messages').doc(messageDocId)
      .update({
        message: messageContent,
        edited: true
      })
      .then(() => {
        this.toastService.show('Message updated', { classname: 'success-toast', delay: 1500 })
      })
      .catch(err => {
        this.toastService.show(err, { classname: 'error-toast', delay: 5000 })
      })
  }
  public deleteMessage(messageDocId: string) {
    this.afs.collection('messages').doc(messageDocId)
      .delete()
      .then(() => {
        this.toastService.show('Message deleted', { classname: 'success-toast', delay: 1500 })
      })
      .catch(err => {
        this.toastService.show(err, { classname: 'error-toast', delay: 5000 })
      })
  }
  private fetchScryfallData(
    cardObj: ICardSearchParams,
    messageGroupDocId: string,
    matchChat: boolean,
    spectatorMode: boolean,
    matchDocId: string,
    whisperMode: string = null
  ) {
    return new Promise((resolve, reject) => {

      console.log(cardObj)

      this.cardSearch.getCardByName(cardObj.searchString, cardObj.searchSet ? { setCode: cardObj.setString } : {})
        .then((card: CardApiResponse) => {
          if (cardObj.searchRules) {
            this.cardSearch.getCardRulingById(card.id)
              .then((rulings: List<GenericScryfallResponse>) => {
                const tempRules = {
                  data: []
                }
                rulings.forEach(r => {
                  tempRules.data.push({
                    comment: r.comment,
                    oracle_id: r.oracle_id,
                    published_at: r.published_at,
                    source: r.source,
                    object: r.object,
                  })
                })
                const message: IMessageDocument = {
                  timestamp: firestore.Timestamp.now().seconds,
                  messageGroupDocId,
                  playerDocId: 'scryfall-robot',
                  playerUid: 'scryfall-robot',
                  message: 'This is what I got for you!',
                  matchChat,
                  spectatorMode,
                  matchDocId,
                  content: {
                    cardName: card.name,
                    rulings: tempRules,
                    setCode: card.set,
                    setName: card.set_name,
                  },
                  type: 'scryfall-rules',
                  whisperMode,
                  archived: false,
                }
                resolve({
                  status: true,
                  message
                })
              })
          }
          else {
            const message: IMessageDocument = {
              timestamp: firestore.Timestamp.now().seconds,
              messageGroupDocId,
              playerDocId: 'scryfall-robot',
              playerUid: 'scryfall-robot',
              message: 'This is what I got for you!',
              matchChat,
              spectatorMode,
              matchDocId,
              content: {
                cardName: card.name,
                imageUrl: card.image_uris ? card.image_uris.normal : null,
                setCode: card.set,
                setName: card.set_name,
                typeLine: card.type_line,
                manaCost: card.mana_cost,
                oracleText: card.oracle_text ? card.oracle_text : null,
                scryfallUri: card.uri,
                flavorText: card.flavor_text ? card.flavor_text : null,
                power: card.power ? card.power : null,
                toughness: card.toughness ? card.toughness : null,
                loyalty: card.loyalty ? card.loyalty : null,
              },
              type: 'scryfall-image',
              whisperMode,
              archived: false,
            }
            resolve({
              status: true,
              message
            })
          }
        })
        .catch(() => {
          console.log('no card found')
          reject({
            status: false,
            message: 'error finding card'
          })
        })
    })
  }
  public updateEventInvitationMessage(invitedPlayer: IInvitedPlayer): Promise<boolean> {
    return new Promise((resolve) => {
      this.afs.collection('messages').doc(invitedPlayer.messageDocId)
        .update({
          invitedPlayer: invitedPlayer
        })
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          console.log(err)
          resolve(false)
        })
    })
  }
  public addReaction(messageDocId: string, reactionKey: string) {
    let update = {
      reactions: {} as IMessageReaction
    }
    update.reactions[reactionKey] = firestore.arrayUnion(this.auth.user.playerId)
    this.afs.collection('messages').doc(messageDocId).set(update, { merge: true })
  }
  public removeReaction(messageDocId: string, reactionKey: string) {
    let update = {
      reactions: {} as IMessageReaction
    }
    update.reactions[reactionKey] = firestore.arrayRemove(this.auth.user.playerId)
    this.afs.collection('messages').doc(messageDocId).set(update, { merge: true })
  }

}

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { EventCreationService } from './event-creation.service';

@Component({
  selector: 'app-new-event',
  templateUrl: './new-event.component.html',
  styleUrls: ['./new-event.component.css']
})
export class NewEventComponent implements OnInit {

  public event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  private _event: IEventDetails = null

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly eventCreation: EventCreationService,
  ) { }

  ngOnInit(): void {
    const event = this.eventCreation.newTournament()
    event.eventFormValid = false
    this._event = event
    this.event$.next(event)
  }

  public onEventDocumentFormChanged(event: IEventDetails): void {
    this._event = event
  }

  public onCancel(): void {
    this.modal.dismiss()
  }

  public onCreate(): void {
    if (this._event.eventFormValid) {
      delete this._event.eventFormValid

      this.eventCreation.createEvent(this._event)
        .then(() => this.modal.close(this._event.docId))
        .catch((e) => console.log(e))
      
    }
  }

  public get formInvalid(): boolean {
    return !this._event.eventFormValid
  }

}

<div class="text-center d-flex flex-column align-items-center h-100" #modalBody>
    
    <div class="p-3 text-secondary text-monospace mb-3">Player identification</div>
    
    <div class="d-flex flex-grow-1 justify-content-center flex-column">
        <canvas #qrCode [style.width.px]="qrSize.width" [style.height.px]="qrSize.height"></canvas>
    </div>

    <div class="mt-3 p-4 text-secondary w-100" (click)="modal.close()">Close</div>

</div>

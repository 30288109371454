import { BoosterCardInfoComponent } from './../booster-card-info/booster-card-info.component';
import { faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IBoosterCard } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-booster-card',
  templateUrl: './booster-card.component.html',
  styleUrls: ['./booster-card.component.scss']
})
export class BoosterCardComponent {
  @Input() onlyCard: boolean = false;
  @Input() card: IBoosterCard;
  @Input() zoomLevel: number;
  @Input() disabled: boolean;
  @Input() selected: boolean;
  @Output() cardPicked = new EventEmitter<boolean>();
  @Output() cardSelected = new EventEmitter<boolean>();

  public faCheckCircle = faCheckCircle;
  public faInfoCircle = faInfoCircle;

  constructor(private modalService: NgbModal) { }

  public openDetails(): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
    };

    const modalRef: NgbModalRef = this.modalService.open(BoosterCardInfoComponent, modalOptions);
    modalRef.componentInstance.card = this.card;
  }

  public pickCard(): void {
    this.cardPicked.emit(true);
  }

  public selectedCard(): void {
    this.cardSelected.emit(true);
  }
}

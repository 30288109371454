import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const darkMode: IconDefinition = {
  prefix: 'fac',
  iconName: 'dark-mode',
  icon: [
    450, // SVG view box width
    450, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M239.06,450h-27.25c-7.51-1-15.05-1.78-22.51-3.04-42.89-7.24-80.71-25.33-113.12-54.27C37.37,358.04,12.82,315.11,3.49,263.75c-1.48-8.17-2.34-16.46-3.49-24.69v-28.12c.27-1.42,.59-2.84,.8-4.27,1.36-9.25,2.16-18.62,4.13-27.73C15.98,127.77,42.09,85.58,82.16,52.15,112.86,26.53,148.05,10.14,187.57,3.41,195.62,2.04,203.73,1.13,211.82,0h17.58c2.8,2.22,6.29,3.96,8.25,6.77,4.58,6.58,1.87,14.05-6.35,19.45-71.33,46.82-93.12,139.67-50.02,213.17,33.04,56.35,100.22,87.13,164.37,75.51,26.33-4.77,49.87-15.46,70.86-31.97,5.58-4.39,11.62-4.66,16.64-1.14,5.03,3.52,6.94,9.58,4.51,16.04-30.52,81.11-87.88,131.09-173,148.62-8.41,1.73-17.05,2.4-25.58,3.56ZM450,142.38c-.58-.97-1.16-1.93-1.73-2.9-3.04-5.23-9.01-7.84-14.55-6.36-5.99,1.6-10.07,6.77-10.07,12.77,0,6.01,4.07,11.16,10.07,12.77,5.54,1.49,11.5-1.12,14.55-6.36,.57-.97,1.15-1.93,1.73-2.89v-7.03ZM291.79,39.71c-1.18,23.3-16.48,38.8-39.79,40.27-4.71,.3-8.45,2.32-10.94,6.32-5.55,8.91,.77,19.59,11.81,20.06,21.61,.93,38.02,17.05,38.88,38.2,.36,8.79,5.53,14.48,13.18,14.51,7.66,.02,12.91-5.68,13.26-14.42,.84-21,17.31-37.42,38.37-38.27,8.67-.35,14.4-5.7,14.31-13.36-.08-7.5-5.77-12.33-14.15-13.2-5.29-.55-10.87-1.56-15.57-3.88-14.62-7.2-22.14-19.44-22.96-35.72-.42-8.25-5.87-13.94-13.4-13.83-7.19,.1-12.61,5.67-13,13.33Zm131.82-.21c-.02-7.12-5.98-13.08-13.11-13.12-7.29-.03-13.38,6.15-13.23,13.44,.15,7.12,6.22,12.99,13.33,12.9,7.12-.09,13.03-6.1,13.01-13.22Z'
  ],
} as any;
import { IEventDetails } from './event.interfaces'

export interface IEventAnnouncementData {
  eventDocId: string;
  recipients: EventAnnouncementRecipientType;
  message: string;
}
export enum EventAnnouncementRecipientType {
  ATTENDEES = 'ATTENDEE',
  INVITEES = 'INVITTE',
  BOTH = 'BOTH',
}
export interface IDateTimeString {
  playerString: string
  utcString: string
  duration: string
}
export interface IMatchAvailabilityMeta {
  docId: string;
  timestampFrom: number;
  timestampTo: number;
  playerUid: string;
  playerDocId: string;
  note?: string;
  split?: boolean;
}
export interface IMatchAppointment {
  docId: string
  matchDocId: string
  eventDocId: string
  eventname?: string
  playerDocId: string
  playerUid: string
  opponentDocId: string
  opponentName?: string
  opponentHasRead: boolean
  timestampFrom: number
  timestampTo: number
  timestampAccepted: number | null
  timestampRejected: number | null
  timestampCancelled: number | null
  timestampCreated: number | null
  timestampRead: number | null
  isAccepted: boolean
  isRejected: boolean
  isCancelled: boolean
  playerDocIds: string[]
}
export interface IEventInvitationTemplateData {
  eventName: string
  eventDescription: string
  eventLocation: string
  eventOrganizerName: string
  eventDateTimeString: string
  eventType: string
  eventStructure: string
  eventFormat: string
  eventRuleset: string
  eventReprintPolicy: string
  eventAttendeeCap: string
  organizerMessage: string
  eventAcceptInvitationUrl: string
  eventDeclineInvitationUrl: string
  eventRegistrationFee: string
  subject?: string
}
export interface IMatchProposalTemplateData {
  subject: string
  inviteeDisplayName: string
  inviterDisplayName: string
  inviteeDateTimeString?: string
  utcDateTimeString?: string
  duration?: string
  buttonUrl?: string
  eventName?: string
  proposalAnswer?: string
}
export interface IEventReminderTemplateData {
  receiverEmail: string[]
  receiverName: string
  eventName: string
  eventLocation: string
  eventOrganizerName: string
  eventDateTimeString: string
  eventRegistrationFee: string
  eventType: string
  eventStructure: string
  eventFormat: string
  eventRuleset: string
  eventReprintPolicy: string
  eventAttendeeCap: string
}
export interface IMatchReminderTemplateData {
  playerDisplayName: string
  opponentDisplayName: string
  matchDateTimeString: string
  utcDateTimeString: string
  duration: string
  eventName: string
}
export interface ISendEmailData {
  userRole: UserRole,
  templateRef: EmailTemplate,
  subject: string
  body: string
  templateData: IEmailTemplateData
}
export enum UserRole {
  ADMIN = 'admin',
  USER = 'user',
  ORGANIZER = 'organizer',
  GUARDIAN = 'guardian'
}
export enum EmailTemplate {
  EVENT_INVITATION = 'd-f43681845186458fb16451214c25c062',
  EVENT_REMINDER_7DAYS = 'd-ab4211a80ed14a49b7678d5a633fa788',
  EVENT_REMINDER_TOMORROW = 'd-487a04aabfc342c39c6822985e8365aa',
  NEWS_LETTER = '',
  ORGANIZER_NEWS = 'd-c6340966f236498e94ec64e7a32e5f4e',
  MATCH_APPOINTMENT_PROPOSAL = 'd-a9dba6ede04f474a9440cb734506b3ac',
  MATCH_APPOINTMENT_PROPOSAL_ANWER = 'd-bab94fabc3dd42098aba6bff1ad84ccc',
  MATCH_APPOINTMENT_REMINDER = 'd-52554b85fe8e472d8b1cb41ab5b3cc09',
  EVENT_ANNOUNCEMENT = 'd-467447c1d2604f3ba23b7ac459974905',
}
export interface IEmailTemplateData {
  subject?: string
  htmlMessage?: string
  event?: IEventDetails,
  organizer?: {
    name?: string
    message?: string
  },
  eventInvitation?: IEventInvitationTemplateData,
  matchProposal?: IMatchProposalTemplateData
}

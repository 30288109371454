<div class="deck-info-wrapper p-3 d-flex">


  <div class="flex-grow-1" [style.minWidth.rem]="30">

    <div class="alert alert-warning" role="alert" *ngIf="isVersion">You are editing a version of this deck, changing the deck details are disabled.</div>

    <div class="form-row">
      <div class="col-6">
        <label class="text-secondary">Name</label>
      </div>
      <div class="col">
        <label class="text-secondary" for="deckFolderSelect">Folder</label>
      </div>
      <div class="col text-end">
        <label class="text-secondary">Private</label>
      </div>

    </div>
    <div class="form-row">
      <div class="col-6 align-items-center">
        <input type="text" class="form-control form-control-sm {{ isVersion || originDeck.isLocked ? 'text-secondary' : '' }}" placeholder="Deck name" [(ngModel)]="deck.name"
          (change)="updateDeck('name')" [disabled]="isVersion || originDeck.isLocked">

        <div class="form-group">
        </div>

      </div>
      <div class="col align-items-center">
        <ng-container *ngIf="folderList$ | async as folders">
          <div ngbDropdown>
            <button class="btn btn-sm btn-outline-secondary" id="dropdownDeckFolders" ngbDropdownToggle [disabled]="isVersion || originDeck.isLocked">
              {{ deckFolderName }}
            </button>

            <div ngbDropdownMenu aria-labelledby="dropdownDeckFolders">

              <button ngbDropdownItem (click)="createNewDeckFolder()">
                <span class="text-secondary text-italic">Create new folder</span>
              </button>

              <div class="dropdown-divider"></div>

              <button *ngFor="let folder of folders" ngbDropdownItem (click)="selectDeckFolder(folder.docId)">
                {{ folder.name }}
              </button>

            </div>

          </div>
        </ng-container>
      </div>
      <div class="col text-end align-items-center">
        <div class="form-check form-switch mt-2">
          <input type="checkbox" class="form-check-input" (change)="updateDeck('isPrivate')"
            [(ngModel)]="deck.isPrivate" id="isPrivate" [disabled]="isVersion || originDeck.isLocked">
          <label class="form-check-label" for="isPrivate"></label>
        </div>
      </div>

    </div>
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="text-secondary" for="deckDescription">Description</label>
          <textarea class="form-control {{ isVersion || originDeck.isLocked ? 'text-secondary' : '' }}" [(ngModel)]="originDeck.description" id="deckDescription" width="100%" rows="4"
            (change)="updateDeck('description')" [disabled]="isVersion || originDeck.isLocked"></textarea>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="flex-grow-2"></div>


<app-deck-graphs [deckList]="deck"></app-deck-graphs>

<ng-container g-container *ngIf="deckEventInfo$ | async as eventInfos">
  <div class="event-info-wrapper d-flex flex-column p-3 mb-5">
    <div class="text-large">Played in Events</div>
    <ng-container *ngFor="let eventInfo of eventInfos">
      <div class="event-info-item py-2 d-flex align-items-center" [routerLink]="eventInfo.routerLink">
        <span [style.minWidth.rem]="25">{{ eventInfo.eventName }}</span><span class="ms-2 text-secondary">version: {{ eventInfo.deckVersionPlayed }}</span>
      </div>
    </ng-container>
  </div>
</ng-container>



<ng-container *ngIf="canBeDeleted$ | async as canBeDeleted">
  <div class="d-inline danger-zone mt-5 p-3" [ngbTooltip]="canBeDeleted.text"
    placement="top" container="body">
    <button class="btn btn-sm btn-outline-danger" (click)="deleteDeck()"
      [disabled]="!canBeDeleted.status">{{ isVersion ? 'Delete Version' : 'Delete Deck' }}</button>
  </div>
</ng-container>



<ng-template #newDeckFolder let-modal>
  <div class="modal-header d-flex align-items-center">
    <span class="flex-grow-1">Create new Deck Folder</span>
  </div>
  <div class="modal-body">
    <input type="text" class="form-control fix-rounded-right" [(ngModel)]="newDeckFolderName"
      placeholder="Enter a folder name"
      [ngbTooltip]="newDeckFolderName.length < 4 ? 'Folder name needs to be at least 4 characters long' : 'All good!'"
      container="body"
      [ngClass]="{ 'is-invalid' : newDeckFolderName.length < 4, 'is-valid': newDeckFolderName.length >= 4 }">
  </div>
  <div class="modal-footer d-flex align-items-center">
    <div class="flex-grow-1"></div>
    <div class="btn btn-sm btn-blank text-red" (click)="modal.close()">Cancel</div>
    <button class="btn btn-sm btn-blank" (click)="modal.close(newDeckFolderName)"
      [disabled]="newDeckFolderName.length < 4">Create</button>
  </div>
</ng-template>

<ng-template #deleteDeckConfirmation let-modal>
  <div class="modal-header">Delete Deck?</div>
  <div class="moda-body p-3">
    Are you sure you want to delete the {{ isVersion ? 'versoin' : 'deck' }} <span class="text-italic text-bold">{{ deck.name }}</span>?
  </div>
  <div class="modal-footer d-flex align-items-center">
    <button class="btn btn-sm btn-blank text-secondary" (click)="modal.close()">Cancel</button>
    <button class="btn btn-sm btn-blank text-red" (click)="modal.close('delete')">Delete</button>
  </div>
</ng-template>

import Quill from 'quill'

const Parchment: any = Quill.import('parchment')

export class TolariaTournamentBlot extends Parchment.Embed {

  static blotName = 'tolaria-tournament'
  static tagName = 'tolaria-tournament'
  static className = 'ql-tolaria-tournament'

  static create(data: { docId: string }) {
    const node = super.create() as HTMLElement
    node.setAttribute('docid', String(data.docId))
    return node
  }

  static value(node: HTMLElement) {
    return {
      docId: node.getAttribute('docid'),
    }
  }

}

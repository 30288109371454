import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { MatchService } from 'src/app/services/match.service';
import { BehaviorSubject, combineLatest, Observable, take } from 'rxjs';
import { IEventDetails, IMatchData, IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, Input, OnInit, ViewChild, ElementRef, Pipe, PipeTransform } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { EventService } from 'src/app/services/event/event.service';
import { PlayerTieBreakers } from 'src/app/services/event/tiebreakers';

@Component({
  selector: 'app-print-pairings',
  templateUrl: './print-pairings.component.html',
  styleUrls: ['./print-pairings.component.css']
})
export class PrintPairingsComponent implements OnInit {
  @Input() event: IEventDetails;
  @Input() textButton: boolean;
  @Input() buttonText: string;

  @ViewChild('printDialog') printDialog: ElementRef;

  public matches$: Observable<IMatchData[]>;
  public players$: Observable<IEventPlayerDetails[]>;

  public opponentArrow = faChevronLeft;
  public playerArrow = faChevronRight;

  public printStyle = {
    body: {
      'color': 'black',
      'font-family': 'monospace',
    },
    table: {
      'font-family': 'monospace',
    },
    th: {
      'text-align': 'left'
    }
  }

  public print = {
    pairings: false,
    standings: false,
    matchSlips: false,
  }

  public selectedRound: number;

  public standings$: BehaviorSubject<IEventPlayerDetails[]> = new BehaviorSubject<IEventPlayerDetails[]>(null)

  constructor(
    private modalService: NgbModal,
    private matchService: MatchService,
    private eventService: EventService,
  ) {
  }

  ngOnInit() {
    this.matches$ = this.matchService.getMatchesForEventByEventId(this.event.docId);
    this.players$ = this.eventService.getEventPlayersByEventId(this.event.docId);

    const event = this.eventService.getEventById(this.event.docId)

    combineLatest([event, this.players$, this.matches$])
    .pipe(
      take(1),
      // takeUntil(this.componentDestroyed$)
    )
    .subscribe(([event, players, matches]) => {
      this.constructStandings(event, players, matches)
    })
  }

  public openPrintDialog(): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: false,
      keyboard: false,
      size: 'lg',
    };

    this.modalService.open(this.printDialog, modalOptions)
      .result
      .then(
        () => {

        },
        () => {

        });
  }

  public get hasNotingToPrint(): boolean {
    return document.getElementById('printOut') === null;
  }

  public get printOutViewText(): string {

    if (this.print.standings) {
      if (this.event.statusCode > 0 && this.event.statusCode < 4) {
        return `Standings after round ${this.event.activeRound - 1}`;
      }
      if (this.event.statusCode > 4 && this.event.statusCode !== 8) {
        return `Standings after round ${this.event.activeRound}`;
      }
      if (this.event.statusCode === 8) {
        return 'Final Standings';
      }
    }
    else if (this.print.matchSlips) {
      return `Round: ${this.selectedRound}`;
    }
    else {
      return `Pairings for round ${this.selectedRound}`;
    }

  }

  public get roundsToPlay(): number[] {
    return Array.from(Array(this.event.details.structure.swiss.roundsToPlay).keys());
  }

  private constructStandings(event: IEventDetails, players: IEventPlayerDetails[], matches: IMatchData[]): void {
    console.log({
      event,
      matches,
      players,
    })
    const playersData = this.eventService.__getExtendedPlayers(players, matches, event)
    console.log(playersData)
    const playersWithBreakers = PlayerTieBreakers.compute(playersData)
    console.log(playersWithBreakers)
    const playersSorted = PlayerTieBreakers.sort(playersWithBreakers)
    console.log(playersSorted)
    const playersRanked = PlayerTieBreakers.rank(playersSorted, event.details.structure.isGroup)
    console.log(playersRanked)

    this.standings$.next(playersRanked)
  }

}

export interface IPairingsPrintMeta {
  table: number;
  player: {
    name: string;
    points: number;
  }
  opponent: {
    name: string;
    points: number;
  }
}

@Pipe({
  name: 'pairingsPrint'
})
export class PairingsPrintPipe implements PipeTransform {
  transform(matches: IMatchData[], selectedRound: number): IPairingsPrintMeta[] {
    if (!matches || !selectedRound) { return [] }
    const pairings: IPairingsPrintMeta[] = [];
    const m1 = matches.filter(m => m.roundNumber === selectedRound);
    const m2 = matches.filter(m => m.roundNumber === selectedRound);
    m1.forEach(m => {
      const pairingsMeta: IPairingsPrintMeta = {
        table: m.tableNumber,
        player: {
          name: m.player1.displayName,
          points: m.player1.matchPoints,
        },
        opponent: {
          name: m.player2.displayName,
          points: m.player2.matchPoints,
        }
      }

      pairings.push(pairingsMeta);
    });
    m2.forEach(m => {
      const pairingsMeta: IPairingsPrintMeta = {
        table: m.tableNumber,
        player: {
          name: m.player2.displayName,
          points: m.player2.matchPoints,
        },
        opponent: {
          name: m.player1.displayName,
          points: m.player1.matchPoints,
        }
      }

      pairings.push(pairingsMeta);
    });
    return pairings.sort((a, b) => (a.player.name > b.player.name) ? 1 : ((b.player.name > a.player.name) ? -1 : 0));
  }
}
@Pipe({
  name: 'matchSlipPrint'
})
export class MatchSlipPrintPipe implements PipeTransform {
  transform(matches: IMatchData[], selectedRound: number): IPairingsPrintMeta[] {
    if (!matches || !selectedRound) { return [] }
    const pairings: IPairingsPrintMeta[] = [];
    const m1 = matches.filter(m => m.roundNumber === selectedRound);
    m1.forEach(m => {
      const pairingsMeta: IPairingsPrintMeta = {
        table: m.tableNumber,
        player: {
          name: m.player1.displayName,
          points: m.player1.matchPoints,
        },
        opponent: {
          name: m.player2.displayName,
          points: m.player2.matchPoints,
        }
      }

      pairings.push(pairingsMeta);
    });
    return pairings.sort((a, b) => a.table - b.table);
  }
}

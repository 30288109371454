import { Router } from '@angular/router';
import { NewEventComponent } from './new-event/new-event.component';
import { DialogsService } from './../../services/dialogs.service';
import { ToastService } from './../../services/toast.service';
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces';
import { AuthService, EventService, GlobalsService, IEventListItem } from 'src/app/services';

import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { faLock, faUnlock, faVideo, faFilter, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faCircle, faCheckSquare, faSquare, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { NgbDate, NgbCalendar, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.globals.hotkeysEnabled && event.key === 'c') {
      this.showNewEventForm();
    }
  }

  faLock = faLock;
  faUnlock = faUnlock;
  faVideo = faVideo;
  faCalendarPlus = faCalendarPlus;
  faCircle = faCircle;
  faCheckCircle = faCheckCircle;
  faSquare = faSquare;
  faCheckSquare = faCheckSquare;
  faFilter = faFilter;
  faCalendarAlt = faCalendarAlt;

  public eventList$: Observable<IEventListItem[]>;
  public pageNum = 1;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  showDatePicker = false;

  constructor(
    public globals: GlobalsService,
    public es: EventService,
    private auth: AuthService,
    private toastService: ToastService,
    private calendar: NgbCalendar,
    private dialogs: DialogsService,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getNext(this.fromDate, 'd', 180);

    console.log(this.es.filter);
    if (this.es.filter.dateFrom === '' && this.es.filter.dateTo === '') {
      this.es.filter.dateFrom = this.getDateString(this.fromDate);
      this.es.filter.dateTo = this.getDateString(this.toDate);
    }
    this.es.filter.dateRangeString = this.getDateString(this.fromDate) + ' < > ' + this.getDateString(this.toDate);
  }

  ngOnInit(): void {
    this.eventList$ = this.es.getEventList();
  }

  public resizeTooltip(event: any): void {
    document.documentElement.style.cssText = `--event-tooltip-width: ${event.target.clientWidth + 2}px !important;`
  }

  public showAvailabilityConfig(): void {
    // const modalOptions: NgbModalOptions = {
    //   centered: true,
    //   animation: true,
    //   backdrop: true,
    //   keyboard: true,
    //   size: 'xl',
    //   windowClass: 'match-availability-modal'
    // };

    // this.modalService.open(MatchAvailablityConfigComponent, modalOptions);
    this.router.navigate(['/matches', 'calendar'])
  }

  public eventTracker(index: number, item: IEventListItem) {
    return item.docId
  }

  public showNewEventForm() {
    // const modalOptions: NgbModalOptions = {
    //   centered: false,
    //   animation: true,
    //   backdrop: 'static',
    //   keyboard: true,
    //   size: 'xl',
    //   // windowClass: 'modal-full-size'
    // };
    // const modalRef: NgbModalRef = this.modalService.open(NewEventFormComponent, modalOptions);
    // modalRef.componentInstance.editMode = false;
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: 'static',
      keyboard: true,
      size: 'xl',
      // windowClass: 'modal-full-size'
    }
    const modalRef: NgbModalRef = this.modalService.open(NewEventComponent, modalOptions)
    modalRef.result.then(
      (documentId: string) => {
        this.toastService.show('Event created successfully, redirecting', { classname: 'success-toast' })
        setTimeout(() => { this.router.navigate(['event', documentId]) }, 1000)
      },
      () => {
        console.log('cancel pressed on the new tournament event creation dialog')
      })

  }

  public get isOrganizer(): boolean {
    return this.auth.user.role === 'admin' || this.auth.user.role === 'organizer';
  }

  public onDateSelection(date: NgbDate) {
    console.log('on date selection triggered');
    if (!this.fromDate && !this.toDate) {
      console.log('no date selected, setting fromDate');
      this.fromDate = date;
      this.es.filter.dateFrom = this.getDateString(this.fromDate);
    }
    else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      console.log('fromDate selected, setting toDate');
      this.toDate = date;
      this.es.filter.dateTo = this.getDateString(this.toDate);
      this.showDatePicker = false;
    }
    else {
      console.log('both dates selected, setting fromDate and clearing toDate');
      this.toDate = null;
      this.fromDate = date;
      this.es.filter.dateFrom = this.getDateString(this.fromDate);
    }
    this.es.filter.dateRangeString = this.getDateString(this.fromDate) + ' < > ' + this.getDateString(this.toDate);
    this.es.setDateIsFiltered();
  }
  public getDateString(date: NgbDate): string {
    return date.year + '.' + this.pad2(date.month) + '.' + this.pad2(date.day);
  }
  public pad2(n: number) { return n < 10 ? '0' + n : n; }

  public isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  public isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  parseDefaultBanner(format: string): string {
    return 'assets/banners/' + format.toLowerCase().replace(/ /g, '-') + '.default.jpg';
  }

  attendEvent(eventDocId: string) {
    this.es.addPlayerToEvent(eventDocId, this.auth.user.uid, this.auth.user.playerId, null)
      .then(response => {
        if (response.status) {
          this.toastService.show('You have been signed up to attend the event.', { classname: 'success-toast', delay: 2000 });
        }
        // this.eventList$ = this.es.getEventList();
      });
  }

  unattendEvent(eventDocId: string, eventName: string) {

    // open a confirmation dialog
    this.dialogs.openDialog(
      'Unattend event?',
      `You are about to unattend the event "${eventName}", are you sure?`,
      'ConfirmCancel'
    )
      .then((dialogResponse: any) => {

        console.log('Dialog Response, ', dialogResponse);

        if (dialogResponse.pressed !== 'cancel') {
          // call function to remove attending player
          this.es.removePlayerFromEvent(eventDocId, this.auth.user.playerId)
            .then((response: IPromiseResponse) => {
              if (response.status) {
                this.toastService.show('You are no longer attending the event.', { classname: 'error-toast', delay: 3000 });
              }
              // this.eventList$ = this.es.getEventList();
            });
        }

      });

  }

  log(text) {
    console.log(text);
  }

  public get isAdmin() {
    return this.auth.user.role === 'admin';
  }

}

<div class="p-3 d-flex flex-column gap-3">

  <ng-container *ngIf="ready$ | async; else loading">

    <div class="question d-flex flex-column gap-3">

      <div class="doto-800 text-center fs-1" *ngIf="questionCounter$ | async as counter">
        QUESTION {{ counter }}
      </div>

      <ng-container [ngSwitch]="state$ | async">

        <!-- NOT STARTED -->
        <ng-container *ngSwitchCase="'not_started'">

          <button class="btn btn-lg btn-primary" (click)="startQuiz()">Start Quiz!</button>

          <ng-container *ngTemplateOutlet="highscores"></ng-container>

        </ng-container>


        <!-- ANSWERING -->
        <ng-container *ngSwitchCase="'answering'">
          
          <ng-container *ngIf="question$ | async as question">

            <div class="question-image-wrapper position-relative rounded rounded-4">
              <img class="question-image {{ imageClass }} {{ question.type === 'artist' ? 'artist' : 'non-artist' }}" [src]="question.imageUrl">
              <div class="position-absolute bottom-0 start-50 translate-middle-x" [style.width.%]="105">
                <ng-container *ngIf="progressCounter$ | async as percentage">
                  <div class="progress" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" [style.width.%]="percentage"></div>
                  </div>
                </ng-container>
              </div>
            </div>
            
  
            <div class="">{{ question.question }}</div>
            
            <div *ngFor="let option of question.options"
              class="btn btn-lg btn-outline-secondary"
              (click)="answerQuestion(option)">
              <span *ngIf="['manaCost', 'color'].includes(question.type)" [innerHTML]="option | manaParser : { shadowed: true }"></span>
              <span *ngIf="!['manaCost', 'color'].includes(question.type)" [innerHTML]="option"></span>
            </div>
            
          </ng-container>

        </ng-container>
        

        <!-- CORRECT -->
        <ng-container *ngSwitchCase="'correct'">

          <ng-container *ngIf="question$ | async as question">

            <img class="rounded align-self-center {{ question.card.set === 'lea' ? 'rounded-5' : 'rounded-4' }} shadow"
              [style.width.vw]="55"
              [src]="question.card.image_uris.normal">
  
            <span class="text-center">You nailed it!</span>
            
            <span class="text-center" [style.fontSize.rem]="6">👍</span>
  
            <button class="btn btn-lg btn-primary" (click)="getQuestion()">Next question</button>

          </ng-container>

        </ng-container>
        

        <!-- INCORRECT -->
        <ng-container *ngSwitchCase="'incorrect'">

          <ng-container *ngIf="question$ | async as question">

            <img class="rounded align-self-center {{ question.card.set === 'lea' ? 'rounded-5' : 'rounded-4' }} shadow"
              [style.width.vw]="55"
              [src]="question.card.image_uris.normal">
  
            <ng-container *ngIf="result$ | async as result">
              <span class="text-center d-flex gap-1 align-self-center">
                <span>Sorry, the correct answer was</span>
                <span class="text-primary fw-bold" *ngIf="['manaCost', 'color'].includes(question.type)" [innerHTML]="question.correctAnswer | manaParser : { shadowed: true }"></span>
                <span class="text-primary fw-bold" *ngIf="!['manaCost', 'color'].includes(question.type)" [innerHTML]="question.correctAnswer"></span>
              </span>
              
              <span *ngIf="!result.highscore" class="text-center" [style.fontSize.rem]="6">😢</span>
              <span *ngIf="result.highscore" class="text-center" [style.fontSize.rem]="6">🥳</span>
              <span *ngIf="result.highscore" class="text-center doto-800" [style.fontSize.rem]="2">NEW HIGHSCORE!</span>

              <div class="align-self-center d-flex flex-column gap-2 rounded rounded-3 alert alert-info align-items-center">
                <span class="doto-800">ANSWER STREAK</span>
                <span class="doto-800" [style.fontSize.rem]="3.75">{{ result.correctAnswers }}</span>
              </div>
            </ng-container>
  
            <button class="btn btn-lg btn-primary" (click)="startQuiz()">Play again</button>

            <ng-container *ngTemplateOutlet="highscores"></ng-container>

          </ng-container>

        </ng-container>


      </ng-container>
        
      
    </div>
    
  </ng-container>

</div>

<ng-template #loading>

  <div class="d-flex flex-column align-items-center gap-3">
    <div class="spinner-border text-secondary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    Loading game components...
  </div>

</ng-template>

<ng-template #highscores>
  <div class="high-scores d-flex flex-column gap-3 align-items-center mt-5">

    <ng-container *ngIf="highScoreThisMonth | async as results">
      <div class="d-flex flex-column align-items-center gap-1">
        <div class="fs-3 doto-800 text-success">HIGHSCORE</div>
        <div class="">Current Month</div>
        <div class="text-small text-secondary" *ngIf="stats$ | async as stats">{{ stats.thisMonth.count }} quiz taken by {{ stats.thisMonth.uniqueUsers }} users</div>
      </div>
      <div class="list-group w-100">
        <button *ngFor="let score of results"
          type="button"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-start">
          <app-player-display-name [playerDocId]="score.playerDocId"></app-player-display-name>
          <span class="badge text-bg-primary rounded-pill">{{ score.result }}</span>
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="highScoreLastMonth | async as results">
      <div class="d-flex flex-column align-items-center gap-1">
        <div class="fs-3 mt-3 doto-800 text-warning">HIGHSCORE</div>
        <div class="">Last Month</div>
        <div class="text-small text-secondary" *ngIf="stats$ | async as stats">{{ stats.lastMonth.count }} quiz taken by {{ stats.lastMonth.uniqueUsers }} users</div>
      </div>
      <div class="list-group w-100">
        <button *ngFor="let score of results"
          type="button"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-start">
          <app-player-display-name [playerDocId]="score.playerDocId"></app-player-display-name>
          <span class="badge text-bg-primary rounded-pill">{{ score.result }}</span>
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="highScoreOverall | async as results">
      <div class="d-flex flex-column align-items-center gap-1">
        <div class="fs-3 mt-3 doto-800 text-primary">HIGHSCORE</div>
        <div class="">Overall</div>
        <div class="text-small text-secondary" *ngIf="stats$ | async as stats">{{ stats.overall.count }} quiz taken by {{ stats.overall.uniqueUsers }} users</div>
      </div>
      <div class="list-group w-100">
        <button *ngFor="let score of results"
          type="button"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-start">
          <app-player-display-name [playerDocId]="score.playerDocId"></app-player-display-name>
          <span class="badge text-bg-primary rounded-pill">{{ score.result }}</span>
        </button>
      </div>
    </ng-container>

  </div>
</ng-template>


<canvas id="confettiCanvase" class="easter-canvas hidden"></canvas>
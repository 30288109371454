import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCardBackFallback]'
})
export class CardBackFallbackDirective {

  @Input() fallBackUri: string;

  constructor(private eRef: ElementRef) { }

  @HostListener('error')
  loadFallbackOnError() {
    const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
    element.src = this.fallBackUri || 'assets/card-back.jpg'
  }

}

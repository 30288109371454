import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { MessagesService, AuthService, MessageType, GlobalsService } from 'src/app/services';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { faComments } from '@fortawesome/free-regular-svg-icons';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-quick-message',
  templateUrl: './quick-message.component.html',
  styleUrls: ['./quick-message.component.css'],
  standalone: true,
  imports: [
    FormsModule,
    FontAwesomeModule,
    CommonModule,
    RouterLink,
  ]
})
export class QuickMessageComponent implements OnInit {
  @Input() showChatLink: boolean = false;
  @Input() playerDocId: string;

  public message: string = '';
  public isBusy: boolean = false;

  public faPaperPlane = faPaperPlane;
  public chat = faComments

  private messageGroupDocId: string = ''

  constructor(
    private messagesService: MessagesService,
    private auth: AuthService,
    public globals: GlobalsService
  ) {}

  ngOnInit(): void {
    // this.messagesService.checkIfMessageGroupExist(this.auth.user.playerId, this.playerDocId)
    // .then((doc) => {
    //   if (doc !== undefined) {
    //     this.messageGroupDocId = doc.docId
    //   }
    //   else {
    //     this.messagesService.createMessageGroup(this.auth.user.playerId, this.playerDocId)
    //       .then(docId => this.messageGroupDocId = docId)
    //       .catch(err => console.log(err))
    //   }
    // })
  }

  public sendQuickMessage(): void {
    if (this.message !== '') {
      this.isBusy = true;
      this.messagesService.checkIfMessageGroupExist(this.auth.user.playerId, this.playerDocId)
        .then(doc => {
          if (doc !== undefined) {
            this.sendMessage(doc.docId);
          }
          else {
            this.messagesService.createMessageGroup(this.auth.user.playerId, this.playerDocId)
              .then(docId => this.sendMessage(docId))
              .catch(err => console.log(err));
          }
        })
        .catch(err => console.log(err));
    }
  }

  private sendMessage(messageGroupDocId: string): void {
    this.messagesService.postChatMessage(this.message, messageGroupDocId, MessageType.CHAT_MESSAGE, {});
    this.isBusy = false;
    this.message = '';
  }

  public get chatUrl() {
    if (this.messageGroupDocId === '') {
      return null
    }
    return ['/messages', this.messageGroupDocId]
  }


}

<ng-container *ngIf="event$ | async as event">

  <div class="card-header d-flex align-items-center">

    <div class="flex-grow-1">Proceed with:</div>

    <div class="btn-group main-action-button-wrapper" ngbTooltip="Main action to proceed" container="body">
      <!-- MAIN ACTIONS -->
      <ng-container>

        <button class="btn btn-main" (click)="openPlayerRegistration(event)" *ngIf="event.statusCode === 14">
          Open registration
        </button>

        <button class="btn btn-main" (click)="startEvent(event)" *ngIf="event.statusCode === 0">
          Start event
        </button>

        <!-- BATCH SPECIFIC ACTIONS -->
        <button class="btn btn-main" (click)="openNewBacthModal(event)" *ngIf="event.statusCode === 1">
          Add Batch
        </button>

        <button class="btn btn-main" (click)="startRound(event)" *ngIf="event.statusCode === 2">
          Start Batch
        </button>

        <button class="btn btn-main" (click)="endRound(event)"
          *ngIf="event.statusCode === 3 || event.statusCode === 4"
          [disabled]="!allMatchesReported">
          End batch
        </button>

        <button class="btn btn-main" (click)="startPlayoff(event)"
          *ngIf="event.statusCode === 12 && event.details.structure.batch.hasBracketAfterBatch">
          Configure Playoff
        </button>

        <button class="btn btn-main" (click)="openPlayoffConfigModal(event.docId, event.details.structure.isGroup)"
          *ngIf="event.statusCode === 6 && event.details.structure.batch.hasBracketAfterBatch">
          Open Playoff Config
        </button>

        <button class="btn btn-main" (click)="endEvent(event)" *ngIf="event.statusCode === 12 && !event.details.structure.batch.hasBracketAfterBatch ||
        event.statusCode === 7 && event.details.structure.batch.hasBracketAfterBatch">
          End Event
        </button>

        <button class="btn btn-main" (click)="reopenEvent(event)" *ngIf="event.statusCode === 8">
          Reopen Event
        </button>

      </ng-container>


      <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group"
        aria-label="Button group with nested dropdown">

        <button class="btn btn-main dropdown-toggle-split" ngbDropdownToggle ngbTooltip="Secondary actions" container="body" placement="bottom">
          <fa-icon [icon]="faEllipsisH"></fa-icon>
        </button>

        <div class="dropdown-menu" ngbDropdownMenu>

          <!-- GENERAL -->
          <div class="d-flex align-items-center mt-3 mb-2 ms-3 cursor-pointer" (click)="submenuOpen = !submenuOpen">
            <fa-icon [icon]="faChevronRight" class="text-small text-secondary me-2 transition-all-25" [ngClass]="{ 'rotate-90deg' : submenuOpen }"></fa-icon>
            <span class="text-secondary flex-grow-1">General</span>
          </div>

          <ng-container *ngIf="submenuOpen">

            <button ngbDropdownItem (click)="openAnnouncementModal(event.docId)">
              Send Announcement
            </button>

            <button ngbDropdownItem (click)="reOpenRegistration(event)" [disabled]="event.statusCode !== 1">
              Reopen Registration
            </button>

            <button ngbDropdownItem (click)="reCalculateStats(event)"
              [disabled]="event.statusCode === 11 || event.statusCode < 2">
              Re-Calculate Stats
            </button>

            <button ngbDropdownItem (click)="openPayments(eventDoc$)" [disabled]="!event.details?.registrationFee && !event.details?.registrationFee?.active">
              Payments
            </button>

          </ng-container>


          <app-more-actions-batch [eventDoc$]="eventDoc$"></app-more-actions-batch>

          <app-event-actions-check-in [eventDoc$]="eventDoc$"></app-event-actions-check-in>

          <app-event-actions-admin [eventDoc$]="eventDoc$"></app-event-actions-admin>

        </div>

      </div>

    </div>

  </div>

</ng-container>

import { ToastService } from 'src/app/services/toast.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IEventTeam } from './../../../../../../../tolaria-cloud-functions/src/_interfaces';
import { EventService } from '../../../../../services/event/event.service';
import { Component, Input, OnInit } from '@angular/core';
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { nanoid } from 'nanoid'
import { NgbModal, NgbModalOptions, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddPlayersComponent } from 'src/app/components/modals/add-players/add-players.component';
import { faExclamationTriangle, faGripHorizontal } from '@fortawesome/free-solid-svg-icons'
import { GlobalsService } from 'src/app/services';
import { BehaviorSubject, firstValueFrom } from 'rxjs';


interface IPlayerListItem {
  docId: string
  seat: string
  alreadyEnrolled: boolean
}

@Component({
  selector: 'app-add-new-team',
  templateUrl: './add-new-team.component.html',
  styleUrls: ['./add-new-team.component.css']
})
export class AddNewTeamComponent implements OnInit {
  @Input() event: IEventDetails
  @Input() teamData: IEventTeam = null
  @Input() isModal: boolean = false

  public grip = faGripHorizontal
  public warningIcon = faExclamationTriangle
  public errorText: string = ''
  public playerList$: BehaviorSubject<IPlayerListItem[]> = new BehaviorSubject<IPlayerListItem[]>(null)
  public grabStyle: 'grab' | 'grabbing' = 'grab'
  public localPlayerName: string = ''
  public showLocalPlayerInput: boolean = false

  public team: IEventTeam = {
    id: nanoid(),
    name: '',
    player: {
      a: null,
      b: null,
      c: null,
    },
    localPlayers: [],
    playerDocIds: [],
    dropped: false
  }

  constructor(
    private es: EventService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    public globals: GlobalsService,
  ) { }

  ngOnInit(): void {
    if (this.teamData) { this.initTeamEdit()}
    this.playerList$.subscribe(p => console.log('playeList emitted', p))
  }

  private async initTeamEdit() {

    // set team data
    this.team = JSON.parse(JSON.stringify(this.teamData))

    // remove players from event doc
    this.event.playerDocIds = this.event.playerDocIds.filter(i => !i.includes(this.team.player.a) && !i.includes(this.team.player.b) && !i.includes(this.team.player.c))

    // create player list
    this.updatePlayerList()

  }

  public closeModal(): void {
    this.activeModal.dismiss()
  }

  private resetTeamData(): void {

    this.team.id = nanoid()
    this.team.name = ''
    this.team.player = {
      a: null,
      b: null,
      c: null,
    }
    this.team.playerDocIds = []

  }

  private updatePlayerList(): void {

    const playerList: IPlayerListItem[] = []

    for (const p of this.team.playerDocIds) {

      console.log(p)

      const player: IPlayerListItem = {
        docId: p,
        seat: this.playerSeat(p),
        alreadyEnrolled: this.event.playerDocIds.includes(p)
      }

      console.log(player)

      playerList.push(player)

    }

    this.playerList$.next(playerList)
  }

  private clearSeatings(): void {
    Object.keys(this.team.player).forEach(k => this.team.player[k] = null)
  }

  public addTeam(): void {

    // clean up players
    for (const [key, val] of Object.entries(this.team.player)) {
      if (key === undefined || key === "undefined") {
        delete this.team.player[key]
      }
    }

    this.es.enrollTeam(this.event.docId, this.team)
      .then(success => {
        if (success) {
          this.resetTeamData()
        }
      })
  }

  public saveTeam(): void {

    // clean up players
    for (const [key, val] of Object.entries(this.team.player)) {
      if (key === undefined || key === "undefined") {
        delete this.team.player[key]
      }
    }

    const playersAdded = this.team.playerDocIds.filter(i => !this.teamData.playerDocIds.includes(i))
    const playersRemoved = this.teamData.playerDocIds.filter(i => !this.team.playerDocIds.includes(i))

    this.es.saveTeam(this.event.docId, this.team, playersAdded, playersRemoved)
      .then(success => {
        if (success) {
          this.activeModal.close()
        }
      })

  }

  public playerSeat(playerDocId: string): string {
    return Object.keys(this.team.player).find(key => this.team.player[key] === playerDocId)
      ? Object.keys(this.team.player).find(key => this.team.player[key] === playerDocId)
      : '?'
  }

  public onDrop(droppedPlayerDocId: string, newSlot: 'a' | 'b' | 'c'): void {
    if (this.event.statusCode > 0) {
      this.toastService.show('You are not allowed to change seating after event has started', { classname: 'error-toast', delay: 50000 })
      return
    }
    const droppedPlayersSlot = Object.keys(this.team.player).find(key => this.team.player[key] === droppedPlayerDocId)
    const newSlotHolder = this.team.player[newSlot]
    this.team.player[newSlot] = droppedPlayerDocId
    this.team.player[droppedPlayersSlot] = newSlotHolder

    this.updatePlayerList()
  }

  public openPlayerSelection(): void {

    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    };

    const modalRef = this.modalService.open(AddPlayersComponent, modalOptions);
    modalRef.componentInstance.playerDocIds = this.team.playerDocIds
    modalRef.componentInstance.maxSelection = 3

    modalRef.result
      .then(
        (playerDocIds: Array<string>) => {
            console.log('closed')
          console.log(playerDocIds)
          this.team.playerDocIds = playerDocIds
          this.clearSeatings()
          this.updatePlayerList()
        },
        () => {
          console.log('dismissed')
        }
      )
      .catch((err) => {
        // nothing
      });


  }

  public addLocalPlayer(): void {
    console.log(this.localPlayerName)
    if (this.localPlayerName === null || this.localPlayerName.length < 3) {
      this.toastService.show('You need to enter a player name', { classname: 'error-toast' })
      return
    }

    if (this.team.playerDocIds.length === 3) {
      this.toastService.show('Team is full, remove a player first if you want to add this one', { classname: 'error-toast' })
      return
    }

    this.team.playerDocIds.push(`temp__${this.localPlayerName.toLowerCase()}`)
    this.team.localPlayers.push({
      name: this.localPlayerName,
      id: `temp__${this.localPlayerName.toLowerCase()}`,
    })
    this.localPlayerName = ''
    this.updatePlayerList()
  }

  public setCursor(style: 'grab' | 'grabbing'): void {
    this.grabStyle = style
  }

  public getLocalPlayerName(id: string): string {
    const player = this.team.localPlayers.find(i => i.id === id)
    if (player) {
      return player.name
    }
    else {
      return 'Player name not found...'
    }
  }
  public getLocalPlayerInitials(id: string): string {
    const player = this.team.localPlayers.find(i => i.id === id)
    if (player) {
      return player.name.match(/\b(\w)/g).join('')
    }
    else {
      return 'Player name not found...'
    }
  }

  public get currentCursor(): 'grab' | 'grabbing' {
    return this.grabStyle
  }

  public get disabled(): boolean {
    this.errorText = ''

    if (this.event.statusCode > 0) {
      this.errorText = 'Event has started and new teams can not be enrolled'
      return true
    }

    if (this.team.name.length < 7) {
      this.errorText = 'Please enter a team name'
      return true
    }

    if (this.team.playerDocIds.length !== 3) {
      this.errorText = 'Please add exactly 3 players'
      return true
    }

    if (Object.values(this.team.player).splice(0, 3).filter(i => i === null).length > 0) {
      this.errorText = 'Assign a player to each seat'
      return true
    }

    for (const i of this.team.playerDocIds) {
      if (this.event.playerDocIds.includes(i)) {
        this.errorText = 'One or more player already enrolled in another team'
        return true
      }
    }


  }

  public get disablePlayerChange(): boolean {
    return this.event.statusCode > 0
  }

  public get disableSave(): boolean {

    if (this.team.name !== this.teamData.name) {
      return false
    }
    if (this.team.playerDocIds.filter(i => !this.teamData.playerDocIds.includes(i)).length > 0) {
      return false
    }
    if (JSON.stringify(this.team.player) !== JSON.stringify(this.teamData.player)) {
      return false
    }

    return true
  }

  public get disableLocalPlayerAdd(): boolean {
    return this.localPlayerName === null || this.localPlayerName.length < 3
      ? true
      : false
  }

}

import { IClub } from 'tolaria-cloud-functions/src/_interfaces';
import { Pipe, PipeTransform } from '@angular/core';
import { IClubCoords } from '../components/clubs/clubs.component';

@Pipe({
  name: 'clubFilter'
})
export class ClubPipe implements PipeTransform {

  transform(items: IClub[], filterValue: string, coords: IClubCoords = null, useCoords: boolean): IClub[] {
    if (useCoords) {
      if (coords.longitude !== null && coords.latitude !== null) {
        console.log('Filter on nearby clubs');
        items = items.filter(i => i.longitude >= coords.longitude - 2.5 && i.longitude <= coords.longitude + 2.5 &&
          i.latitude >= coords.latitude - 2.5 && i.latitude <= coords.latitude + 2.5)
      }
    }
    return items.filter(i => (i.name.toLowerCase() + i.region.toLowerCase() + i.city.toLowerCase() + i.country.toLowerCase()).includes(filterValue.toLowerCase()));
  }

}

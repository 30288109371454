import { Pipe, PipeTransform } from '@angular/core';
import { IMatchDataTemplate } from '../components';

export interface IPairingsPreviewTemplate {
  player: {
    name: string
    points: number
    id: string
  }
  opponent: {
    name: string
    points: number
    id: string
  }
  table: number
}

@Pipe({
  name: 'pairingsView'
})
export class PairingsViewPipe implements PipeTransform {

  transform(items: IMatchDataTemplate[], round: number): IPairingsPreviewTemplate[] {

    const playerNames: IPairingsPreviewTemplate[] = []

    for (const i of items.filter(i => i.roundNumber === round)) {
      if (i.player1.playerDocId !== '*** BYE ***' && i.player1.playerDocId !== '*** LOSS ***') {
        playerNames.push({
          player: {
            name: i.player1.displayName,
            points: i.player1.matchPoints,
            id: i.player1.playerDocId,
          },
          opponent: {
            name: i.player2.displayName,
            points: ['*** BYE ***', '*** LOSS ***'].includes(i.player2.playerDocId)
              ? 0
              : i.player2.matchPoints,
            id: i.player2.playerDocId,
          },
          table: i.tableNumber,
        })
      }
      if (i.player2.playerDocId !== '*** BYE ***' && i.player2.playerDocId !== '*** LOSS ***') {
        playerNames.push({
          player: {
            name: i.player2.displayName,
            points: i.player2.matchPoints,
            id: i.player2.playerDocId,
          },
          opponent: {
            name: i.player1.displayName,
            points: ['*** BYE ***', '*** LOSS ***'].includes(i.player1.playerDocId)
              ? 0
              : i.player1.matchPoints,
            id: i.player1.playerDocId,
          },
          table: i.tableNumber,
        })
      }
    }

    return playerNames.sort((a, b) => a.player.name.localeCompare(b.player.name))



  }

}

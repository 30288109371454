import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { nanoid } from 'nanoid'
import { IEventTeam } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-tournament-management-new-team',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
  template: `
  <div class="p-4 gap-4 rounded rounded-4 d-flex flex-column">
    <div class="d-flex align-items-center justify-content-between">
      <div class="text-larger">Add a new team</div>
      <button class="btn-close" (click)="onCancelPress()"></button>
    </div>
    <input #teamName
      class="form-control focus-ring focus-ring-primary"
      type="text"
      placeholder="Enter the name of the team"
      id="teamName"
      (keydown.enter)="onCreatePress()"
      [(ngModel)]="team.name">
    <div class="d-flex align-items-center justify-content-end gap-3">
        <button class="btn btn-blank focus-ring focus-ring-secondary"
          (click)="onCancelPress()">
          Cancel
        </button>
        <button class="btn {{ disabled ? 'btn-blank' : 'btn-primary' }} focus-ring focus-ring-primary"
          (click)="onCreatePress()"
          [disabled]="disabled">
          Create team
        </button>
    </div>
  </div>
  `,
  styleUrls: ['./tournament-management-new-team.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TournamentManagementNewTeamComponent implements OnInit {
  @ViewChild('teamName') teamName: ElementRef

  public team: IEventTeam = {
    id: nanoid(),
    name: '',
    player: {
      a: null,
      b: null,
      c: null,
    },
    localPlayers: [],
    playerDocIds: [],
    dropped: false
  }
  
  constructor(private readonly activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    setTimeout(() => this.teamName.nativeElement.focus(), 500)
  }

  public onCancelPress(): void {
    this.activeModal.dismiss()
  }

  public onCreatePress(): void {
    if (this.team.name.length < 3) {
      return
    }
    this.activeModal.close(this.team)
  }

  public get disabled(): boolean {
    return this.team.name.length < 3
  }

}

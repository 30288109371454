<ng-container *ngIf="decks$ | async as decks; else loading">
  <section class="decks-main overflow-hidden p-2">

    <div #folderList class="card min-height-100 max-height-100 no-hover">

      <div class="card-header d-flex align-items-center p-0 py-2 ps-3">
        <span class="text-larger flex-grow-1" (click)="selectedDeckFolder = undefined">Decks</span>
        <div class="btn btn-sm btn-blank text-large" (click)="newFolder()" ngbTooltip="Create a new Folder" container="body">
          <fa-icon [fixedWidth]="true" [icon]="icons.faFolderPlus" class="me-1"></fa-icon>
        </div>
        <div class="btn btn-sm btn-blank text-large" (click)="createNewDeck()" ngbTooltip="Create a new Deck" container="body">
          <fa-icon [fixedWidth]="true" [icon]="icons.faPlus" class="me-1"></fa-icon>
        </div>
      </div>

      <div class="card-body no-hover scrollable p-0">
        <!-- FOLDERS WILL BE LOADED HERE -->
        <ul class="list-group list-group-flush">
          <div class="deck-folder list-group-item d-flex justify-content-between align-items-center"
              [ngClass]="{ 'selected': selectedDeckFolder === undefined }"
              (click)="selectedDeckFolder = undefined">
              <span class="name flex-grow-1">All Decks</span>
              <span class="badge text-bg-secondary rounded-pill" [innerText]="decks.length"></span>
            </div>

          <ng-container *ngFor="let deckFolder of deckFolderList$ | async">
            <div mwlDroppable dragOverClass="droppable-dropping" (drop)="dropped($event, deckFolder.docId)"
              class="deck-folder list-group-item d-flex justify-content-between align-items-center"
              [ngClass]="{ 'selected': selectedDeckFolder === deckFolder.docId }"
              (click)="selectedDeckFolder = deckFolder.docId">
              <span class="ps-2 name flex-grow-1" [innerText]="deckFolder.name" [ngClass]="{ 'text-secondary text-italic' : deckFolder.name === 'Uncategorized' }"></span>

              <span class="delete-button-wrapper" [ngbTooltip]="deckFolder.decks > 0 ? 'Please emty the folder before you can delete' : 'Delete Folder'" container="body" *ngIf="deckFolder.name !== 'Undcategorized'">
                <button class="btn btn-sm btn-blank" [disabled]="deckFolder.decks > 0" (click)="deleteDeckFolder(deckFolder.docId);">
                  <fa-icon [icon]="icons.faTrash" [fixedWidth]="true"></fa-icon>
                </button>
              </span>

              <span class="badge text-bg-secondary rounded-pill" [innerText]="deckFolder.decks"></span>
            </div>
          </ng-container>
        </ul>
      </div>

    </div>

    <div #decksList class="flex-grow-1 p-0 deck-list d-flex flex-wrap h-100 overflow-hidden overflow-y-auto ps-2 align-content-start">
      <div class="min-width-100 d-flex align-items-center justify-content-center mt-1 mb-3 position-sticky" [style.top]="0" [style.zIndex]="2">
        <input class="form-control-sm form-control" [style.width.rem]="20" placeholder="Search deck by name or card" [(ngModel)]="searchString">
      </div>
      <!-- DECKS WILL BE LOADED HERE -->
      <ng-container *ngFor="let deck of decks | decksFilter : selectedDeckFolder : searchString">
        <app-deck-item [deck]="deck" mwlDraggable dragActiveClass="being-dragged" [dropData]="deck.docId"></app-deck-item>
      </ng-container>
    </div>

  </section>
</ng-container>

<ng-template #loading>
  <div class="d-flex flex-column align-items-center justify-content-center p-5 w-100">
    <div class="spinner-wrapper">
      <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    <div class="text-secondary">loading decks</div>
  </div>
</ng-template>

<ng-template #newFolderDialog let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">New folder</h4>
    <div class="btn-close float-end text-secondary" aria-label="Close" (click)="modal.dismiss('cancel')">
      <fa-icon [fixedWidth]="true" [icon]="icons.faTimes"></fa-icon>
    </div>
  </div>
  <div class="modal-body">
    <input type="text" class="form-control form-control-sm"
      (keydown.enter)="newFolderName.length > 3 ? modal.dismiss('create') : null" [(ngModel)]="newFolderName">
  </div>
  <div class="modal-footer">
    <div type="button" class="btn btn-sm text-secondary" (click)="modal.dismiss('cancel')">Cancel</div>
    <div type="button" class="btn btn-sm btn-secondary" (click)="modal.dismiss('create')">Create</div>
  </div>
</ng-template>

<ng-template #newDeckDialog let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">New deck</h4>
    <div class="btn-close float-end text-secondary" aria-label="Close" (click)="modal.dismiss('cancel')">
      <fa-icon [fixedWidth]="true" [icon]="icons.faTimes"></fa-icon>
    </div>
  </div>
  <div class="modal-body">
    <input type="text" class="form-control form-control-sm mb-3" placeholder="Enter a deck name"
      (keydown.enter)="newDeckFormValid ? modal.dismiss('create') : null" [(ngModel)]="newDeck.name">
    <input type="text" class="form-control form-control-sm" placeholder="Enter a deck description"
      (keydown.enter)="newDeckFormValid ? modal.dismiss('create') : null" [(ngModel)]="newDeck.description">
  </div>
  <div class="modal-footer d-flex align-items-center">
    <div class="form-check form-switch flex-grow-1">
      <input type="checkbox" class="form-check-input" id="isCore" [(ngModel)]="newDeck.isPrivate">
      <label class="form-check-label" for="isCore">Private</label>
    </div>
    <div type="button" class="btn btn-sm text-secondary" (click)="modal.dismiss('cancel')">Cancel</div>
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.dismiss('create')" [disabled]="!newDeckFormValid">Create</button>
  </div>
</ng-template>

<ng-template #deleteFolderConfirmation let-modal>
  <div class="modal-header">Delete Folder?</div>
  <div class="moda-body p-3">
    Are you sure you want to delete the folder?
  </div>
  <div class="modal-footer d-flex align-items-center">
    <button class="btn btn-sm btn-blank text-secondary" (click)="modal.close()">Cancel</button>
    <button class="btn btn-sm btn-blank text-red" (click)="modal.close('delete')">Delete</button>
  </div>
</ng-template>

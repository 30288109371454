import { CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { NgbActiveModal, NgbCalendar, NgbDate, NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap'
import { IMatchMeta } from '../../play/matches/utilitiez/matches.interfaces'
import { BehaviorSubject } from 'rxjs'
import { AvailabilityService } from '../services/availability.service'
import { IMatchAvailabilityMeta } from 'tolaria-cloud-functions/src/_interfaces'
import { PlayerNameService } from 'src/app/services/players/player-name.service'
import { FullCalendarModule } from '@fullcalendar/angular'
import { AppointmentService } from '../services/appointment.service'
import { ITimeSlot, mapAvailabilityToTimeSlots } from '../services/timeslots.helper'
import { TimestampClockComponent } from 'src/app/components/app-structure/timestamp-clock/timestamp-clock.component'
import { MatchAppointmentHelperService } from '../../play/matches/services/match-appointment-helper.service'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

export enum AppointmentType {
  MATCH_APPOINTMENT = 'MATCH_APPOINTMENT'
}

interface TimeSlots {
  player: ITimeSlot[],
  opponent: ITimeSlot[],
  shared: ITimeSlot[],
}

@Component({
  selector: 'app-new-appointment',
  templateUrl: './new-match-appointment.component.html',
  styleUrls: ['./new-match-appointment.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    FormsModule,
    FullCalendarModule,
    TimestampClockComponent,
    FontAwesomeModule,
  ],
})
export class NewMatchAppointmentComponent implements OnInit {
  @Input() type: AppointmentType
  @Input() referenceDocument: string
  @Input() opponentDocId: string
  @Input() data: IMatchMeta

  public isMobile = window.innerWidth < 500
  public dataReady$ = new BehaviorSubject<boolean>(false)
  public selectedDate: NgbDate

  public start = null
  public end = null
  public from = {
    hour: null,
    minute: null,
  }
  public to = {
    hour: null,
    minute: null,
  }
  public slotsActive = true
  public selectedSlot: ITimeSlot | null = null
  public plannerDateStart: NgbDate
  public plannerDateEnd: NgbDate

  private myAvailability$ = new BehaviorSubject<IMatchAvailabilityMeta[]>([])
  private opponentAvailability$ = new BehaviorSubject<IMatchAvailabilityMeta[]>([])
  private playerSlots$ = new BehaviorSubject<ITimeSlot[]>([])
  private opponentSlots$ = new BehaviorSubject<ITimeSlot[]>([])
  private matchingSlots$ = new BehaviorSubject<ITimeSlot[]>([])

  public slotsOnSelectedDate = new BehaviorSubject<TimeSlots>({
    player: [],
    opponent: [],
    shared: []
  })


  constructor(
    private readonly availability: AvailabilityService,
    private readonly appointments: AppointmentService,
    private readonly appointmentHelper: MatchAppointmentHelperService,
    private readonly playerNames: PlayerNameService,
    private readonly calendar: NgbCalendar,
    private readonly modal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.init(this.type)
    this.initTime()
  }

  public create(): void {

    this.appointmentHelper.create({
      playerDocId: this.playerNames.currentPlayersMini.id,
      playerUid: this.playerNames.currentPlayersMini.uid,
      opponentDocId: this.opponentDocId,
      matchDocId: this.data.matchDocId,
      eventDocId: this.data.eventDocId,
      from: this.getOutput().start,
      to: this.getOutput().end,
    })
    .then(success => {
      if (success) {
        this.modal.close()
      }
    })

  }

  public onCancelPress(): void {
    this.modal.dismiss()
  }

  private async init(type: AppointmentType) {

    switch (type) {

      case AppointmentType.MATCH_APPOINTMENT:

        // get opponents availability and appointments
        let opponentAvailability = await this.availability.getAvailability(this.opponentDocId)
        let opponentAppointments = await this.appointments.getAppointments(this.opponentDocId)
        let opponentTimeslots = mapAvailabilityToTimeSlots(opponentAvailability, opponentAppointments)
        this.opponentAvailability$.next(opponentAvailability)
        this.opponentSlots$.next(opponentTimeslots)

        // get players availability and appointments
        let playerAvailability = await this.availability.getMyAvailability()
        let playerAppointments = await this.appointments.getMyAppointments()
        let playerTimeslots = mapAvailabilityToTimeSlots(playerAvailability, playerAppointments)
        this.myAvailability$.next(playerAvailability)
        this.playerSlots$.next(playerTimeslots)

        // set data ready
        this.dataReady$.next(true)

        // set initial date to current date
        this.onDateSelection(this.calendar.getToday())

        break

    }

  }

  public playerHasAvailability(date: NgbDate) {
    let d = new Date(date.year, date.month - 1, date.day)
    let timestampFrom = d.getTime() / 1000
    d.setHours(24)
    let timestampTo = d.getTime() / 1000
    let availability = this.myAvailability$.getValue()
    return availability.filter(i =>
      (i.timestampFrom > timestampFrom && i.timestampFrom < timestampTo) ||
      (i.timestampTo > timestampFrom && i.timestampTo < timestampTo)
    ).length > 0
  }

  public opponentHasAvailability(date: NgbDate) {
    let d = new Date(date.year, date.month - 1, date.day)
    let timestampFrom = d.getTime() / 1000
    d.setHours(24)
    let timestampTo = d.getTime() / 1000
    let availability = this.opponentAvailability$.getValue()
    return availability.filter(i =>
      (i.timestampFrom > timestampFrom && i.timestampFrom < timestampTo) ||
      (i.timestampTo > timestampFrom && i.timestampTo < timestampTo)
    ).length > 0
  }

  public onDateSelection(date: NgbDate) {
    this.selectedDate = date
    this.deselectSlots()
    if (this.opponentHasAvailability(date) || this.playerHasAvailability(date)) {
      this.updateSlots()
    }
    else {
      this.slotsOnSelectedDate.next({
        player: [],
        opponent: [],
        shared: []
      })
    }
  }

  private updateSlots() {

    let playerSlotsThisDay = this.playerSlots$.getValue().filter(i => i.date.equals(this.selectedDate))
    let opponentSlotsThisDay = this.opponentSlots$.getValue().filter(i => i.date.equals(this.selectedDate))
    let sharedSlots = []
    for (let slot of playerSlotsThisDay) {
      slot.bothHave = false
      if (slot.status === 'Free') {
        if (opponentSlotsThisDay.find(i => i.text === slot.text && i.status === 'Free')) {
          sharedSlots.push(slot)
          slot.bothHave = true
        }
      }
    }
    for (let slot of opponentSlotsThisDay) {
      slot.bothHave = false
      if (slot.status === 'Free') {
        if (playerSlotsThisDay.find(i => i.text === slot.text && i.status === 'Free')) {
          slot.bothHave = true
        }
      }
    }
    this.slotsOnSelectedDate.next({
      player: playerSlotsThisDay.filter(i => !i.bothHave).sort((a, b) => a.text.localeCompare(b.text)),
      opponent: opponentSlotsThisDay.filter(i => !i.bothHave).sort((a, b) => a.text.localeCompare(b.text)),
      shared: sharedSlots.sort((a, b) => a.text.localeCompare(b.text)),
    })
  }

  public setSlotsActive(active: boolean): void {
    this.slotsActive = active
    if (!active) {
      this.deselectSlots()
    }
  }

  private deselectSlots(): void {
    this.playerSlots$.getValue().forEach(i => i.selected = false)
    this.opponentSlots$.getValue().forEach(i => i.selected = false)
    this.matchingSlots$.getValue().forEach(i => i.selected = false)
    this.selectedSlot = null
  }

  public onSlotPressed(slot: ITimeSlot): void {
    this.deselectSlots()
    slot.selected = true
    this.selectedSlot = slot
  }

  public get hasSlots(): boolean {
    return [
      ...this.slotsOnSelectedDate.getValue().opponent,
      ...this.slotsOnSelectedDate.getValue().player,
      ...this.slotsOnSelectedDate.getValue().shared,
    ].length > 0
  }

  public get playerDocId(): string {
    return this.playerNames.currentPlayersMini.id
  }


  private initTime(): void {
    let date = new Date()
    date.setSeconds(0)
    date.setMilliseconds(0)

    if (this.start === null) {
      date.setMinutes(date.getMinutes() + (15 - (date.getMinutes() % 15)))
      this.start = date.getTime()
    }
    if (this.end === null) {
      date.setHours(date.getHours() + 1)
      this.end = date.getTime()
    }

    this.from = {
      hour: new Date(this.start).getHours(),
      minute: new Date(this.start).getMinutes(),
    }

    this.to = {
      hour: new Date(this.end).getHours(),
      minute: new Date(this.end).getMinutes(),
    }
  }
  public get selectedDateTimestamp(): number {
    let d = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day)
    return d.getTime()
  }
  public get ending(): number {
    const f = new Date()
    f.setHours(this.from.hour)
    f.setMinutes(this.from.minute)
    f.setSeconds(0)
    f.setMilliseconds(0)
    const t = new Date()
    t.setHours(this.to.hour)
    t.setMinutes(this.to.minute)
    t.setSeconds(0)
    t.setMilliseconds(0)
    if (f.getTime() >= t.getTime()) {
      const date = new Date(this.end)
      date.setDate(date.getDate() + 1)
      return date.getTime()
    }
    return this.end
  }
  private getOutput() {
    if (this.slotsActive) {
      return {
        start: this.selectedSlot.from.getTime() / 1000,
        end: this.selectedSlot.to.getTime() / 1000,
      }
    }
    else {
      const startDate = this.getPlannerDateStart()
      const endDate = this.getPlannerDateEnd()
      return {
        start: startDate.getTime() / 1000,
        end: endDate.getTime() / 1000,
      }
    }
  }

  public getPlannerDateStart(): Date {
    const startDate = new Date(`${this.plannerDateStart.year}-${this.plannerDateStart.month}-${this.plannerDateStart.day}`)
    startDate.setHours(this.from.hour)
    startDate.setMinutes(this.from.minute)
    return startDate
  }
  public getPlannerDateEnd(): Date {
    const endDate = new Date(`${this.plannerDateEnd.year}-${this.plannerDateEnd.month}-${this.plannerDateEnd.day}`)
    endDate.setHours(this.to.hour)
    endDate.setMinutes(this.to.minute)
    return endDate
  }

  public get disableCreate(): boolean {

    
    if (this.slotsActive) {
      if (this.selectedSlot === null) {
        return true
      }
    }
    
    if (!this.slotsActive) {
      if (this.plannerDateStart === undefined) {
        return true
      }
      if (this.plannerDateEnd === undefined) {
        return true
      }
      if (this.getPlannerDateStart() > this.getPlannerDateEnd()) {
        return true
      }
    }

    return false

  }

  public icon = {
    calendar: faCalendarAlt,
  }

}


<app-public-header></app-public-header>

<section class="landing-section"></section>

<section class="waves-wrapper" >
  <div class="d-flex align-items-center justify-content-center">

  </div>
  <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28 " preserveAspectRatio="none">
    <defs>
      <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
    </defs>
    <g class="wave1">
      <use xlink:href="#wave-path" x="50" y="3"></use>
    </g>
    <g class="wave2">
      <use xlink:href="#wave-path" x="50" y="0"></use>
    </g>
    <g class="wave3">
      <use xlink:href="#wave-path" x="50" y="9"></use>
    </g>
  </svg>
</section>
<section #readMore class="section d-flex flex-column">
  <div class="container mt-4">
    <div class="row flex-lg-row-reverse g-5 py-5">
      <div class="col-10 col-sm-8 col-lg-6">
        <div class="d-block mx-lg-auto img-fluid">
          <img src="../../../assets/images/tolaria-card.png"
            class="tolaria-image shadow shadow-lg rounded-4"
            title="“Fairest Isle, all isles excelling,/ Seat of Pleasures, and of loves …” —John Dryden"
            loading="lazy"
            [style.maxWidth.%]="100">
        </div>
      </div>
      <div class="col-lg-6">
        <h1 class="display-5 fw-bold lh-1 mb-3">Welcome to Tolaria.app</h1>
        <p class="lead">
          The online platform for the Old School Magic: the Gathering
          community. Named after the legendary island of Tolaria on the plane of Dominaria, our platform is a
          hub for fans of the Old School MTG format.
        </p>
        <p class="lead">
          Here, you can connect with other Old School MTG enthusiasts, share stories and experiences, and
          participate in events and tournaments. Our platform features tools for event management, including
          support for Swiss, Single Elimination, Double Elimination, Group, Round Robin, and Batch structures.
          With Tolaria.app, you can easily manage events both online and in person, and our integrated payment
          solution makes it simple to collect entry fees.
        </p>
        <p class="lead">
          Join, and become a part of the vibrant Old School MTG community. Connect with other fans,
          participate in events and tournaments, and celebrate the unique culture and history of this special
          format. Tolaria.app is your one-stop platform for all things Old School MTG.
        </p>

        <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          <button type="button" class="btn btn-primary btn-lg px-4 me-md-2" routerLink="/auth/sign-in">Sign-in</button>
          <button type="button" class="btn btn-outline-secondary btn-lg px-4" routerLink="/auth/register">Register</button>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section">
  <div class="container px-4" id="featured-3" [style.paddingTop.rem]="20">
    <h2 class="pb-2">Features</h2>
    <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">

      <ng-container *ngFor="let feature of features">
        <div class="feature col p-4">
          <div class="card rounded rounded-4 shadow shadow-sm border-0 p-3 h-100">
            <div class="feature-icon d-inline-flex align-items-center text-primary bg-gradient mb-3">
              <div class="text-bg-primary rounded rounded-5 d-flex align-items-center justify-content-center"
                [style.width.rem]="5" [style.height.rem]="5" [style.fontSize.rem]="3" [style.marginTop.rem]="-3">
                <fa-icon class="flex-shrink-0" [fixedWidth]="true" [icon]="feature.icon"></fa-icon>
              </div>
            </div>
            <h3 class="fs-2">{{ feature.title }}</h3>
            <p>{{ feature.description }}</p>
            <div class="" *ngIf="feature.hasReadMore">
              <button class="btn btn-primary" [routerLink]="feature.readMoreRoute">Read more</button>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</section>

<app-public-footer></app-public-footer>

<div class="background"></div>
<ng-container *ngIf="eventDoc$ | async as event">

  <div class="d-flex align-items-center mt-3 mb-2 ms-3 cursor-pointer" (click)="submenuOpen = !submenuOpen">
    <fa-icon [icon]="faChevronRight" class="text-small text-secondary me-2 transition-all-25" [ngClass]="{ 'rotate-90deg' : submenuOpen }"></fa-icon>
    <span class="text-secondary flex-grow-1">Batch</span>
  </div>

  <ng-container *ngIf="submenuOpen">

    <!-- VISIBLE IF PLAYOFF IS FALSE -->
    <button ngbDropdownItem (click)="addPlayoffBracket(event)"
      [disabled]="event.details.structure.batch.hasBracketAfterBatch || event.statusCode === 8">
      Add playoffs
    </button>
  
    <!-- VISIBLE IF PLAYOFF IS TRUE AND NOT STARTED -->
    <button ngbDropdownItem (click)="removePlayoffBracket(event)"
      [disabled]="!event.details.structure.batch.hasBracketAfterBatch || event.statusCode === 8">
      Remove playoffs
    </button>
  
    <!-- SHOW IF EVENT STATUS = Waiting for pairings AND structure = BATCH -->
    <button ngbDropdownItem (click)="openNewBacthModal(event)"
      [disabled]="event.statusCode !== 1 && event.statusCode !== 12">
      Add Batch
    </button>
  
    <button ngbDropdownItem (click)="deleteActiveBatch(event)"
      [disabled]="event.statusCode < 2 || event.statusCode > 4 && event.statusCode !== 12">
      Delete active Batch
    </button>
  
    <!-- DISBALED IF ROUND IS NOT PAIRED OR IF SWISS IS FINISHED -->
    <button ngbDropdownItem (click)="manualPairingMode(event)" [disabled]="event.statusCode < 2 || event.statusCode > 4">
      Manual Pairing
    </button>

  </ng-container>


</ng-container>

import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DeckListParserPipe } from 'src/app/pipes/deck-list-parser.pipe';
import { IDeckList } from 'src/app/services/decks.service';

interface IDeck extends IDeckList {
  playerName: string
}

@Component({
  selector: 'app-deck-viewer',
  templateUrl: './deck-viewer.component.html',
  styleUrls: ['./deck-viewer.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    AsyncPipe,
    DeckListParserPipe,
  ]
})
export class DeckViewerComponent implements OnInit {

  public loaded$ = new BehaviorSubject<boolean>(false)
  public deck: IDeck
  public previewCardUrl = 'https://tolaria.app/assets/card-back.jpg'
  public isMobile = window.innerWidth < 500

  constructor(
    private readonly router: Router,
  ) { }

  ngOnInit() {
    let id = this.router.url.split('/')[2]
    this.fetchDeckDocument(id)
  }

  private async fetchDeckDocument(id: string) {
    const response = await fetch('https://us-central1-tolaria-mtg.cloudfunctions.net/api-getDeckList?id=' + id)
    const deck = await response.json()
    console.log(deck)
    this.deck = deck as IDeck
    this.loaded$.next(true)
  }

  public setCardHover(card: any) {
    if (card !== null) {
      this.previewCardUrl = card.imageUrl
    }
  }

  public get showImage(): boolean {
    return this.deck.deckPhotoUrl !== ''
  }

}

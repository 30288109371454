import { faEye  } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from 'src/app/services/toast.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit, OnDestroy {
  @ViewChild('modalResetPassword') modalResetPassword: ElementRef;

  ngUnsubscribe$: Subject<any> = new Subject<any>();

  // The user management actoin to be completed
  mode: string;
  // Just a code Firebase uses to prove that
  // this is a real password reset.
  actionCode: string;

  newPassword: string;
  confirmPassword: string;
  showNew: boolean = false;
  showConfirm: boolean = false;

  faEye = faEye;

  actionCodeChecked: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private modalService: NgbModal,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(params => {
        // if we didn't receive any parameters,
        // we can't do anything
        if (!params) this.router.navigate(['auth/sign-in']);

        this.mode = params['mode'];
        this.actionCode = params['oobCode'];

        switch (params['mode']) {
          case 'resetPassword': {
            // Verify the password reset code is valid.
            this.afAuth.verifyPasswordResetCode(this.actionCode)
              .then(email => {
                this.actionCodeChecked = true;
                this.openResetPassword();
              })
              .catch(e => {
                // Invalid or expired action code. Ask user to try to reset the password
                // again.
                this.toastService.show(e, { classname: 'error-toast', delay: 10000 });
                this.router.navigate(['auth/sign-in']);
                this.modalService.dismissAll();
              });
          } break
          case 'recoverEmail': {

          } break
          case 'verifyEmail': {

          } break
          default: {
            console.log('query parameters are missing');
            this.router.navigate(['auth/sign-in']);
          }
        }
      })
  }

  ngOnDestroy() {
    // End all subscriptions listening to ngUnsubscribe
    // to avoid memory leaks.
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }

  /**
   * Attempt to confirm the password reset with firebase and
   * navigate user back to home.
   */
  handleResetPassword() {
    if (this.newPassword != this.confirmPassword) {
      this.toastService.show('New Password and Confirm Password do not match', { classname: 'error-toast', delay: 10000 });
      return;
    }
    // Save the new password.
    this.afAuth.confirmPasswordReset(
      this.actionCode,
      this.newPassword
    )
      .then(resp => {
        // Password reset has been confirmed and new password updated.
        this.router.navigate(['auth/sign-in']);
        this.toastService.show('Successfully created a new password, continue with a login.', { classname: 'success-toast', delay: 3000 });
        this.modalService.dismissAll();
      }).catch(e => {
        // Error occurred during confirmation. The code might have
        // expired or the password is too weak.
        alert(e);
      });
  }

  private openResetPassword(): void {
    const content = this.modalResetPassword;
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: 'static',
      keyboard: false,
      size: 'sm',
      windowClass: ''
    };
    this.modalService.open(content, modalOptions)
      .result.then(
        (result) => {
          console.log(`reason ${result}`);
        },
        (reason) => {
          console.log(`reason ${reason}`);
        }
      );

  }

  public get resetPasswordForm(): any {
    return {
      valid: this.newPassword !== undefined && this.confirmPassword !== undefined && this.newPassword.length > 6 && this.confirmPassword.length > 6 && this.newPassword === this.confirmPassword,
      invalid: this.newPassword === undefined || this.confirmPassword === undefined || this.newPassword.length <= 6 || this.confirmPassword.length <= 6 || this.newPassword !== this.confirmPassword
    }
  }
  public get newPasswordCheck(): boolean {
    return this.newPassword !== undefined && this.newPassword.length > 6;
  }
}

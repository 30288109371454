<ng-container *ngIf="players$ | async as playerList">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLongTitle">
      Add player
      <ng-container *ngIf="hasMaxSelection">
        <span class="ms-3 text-secondary">
          {{ (playerList | playerSearcher : playerSearch : true : refreshPipe).length }} / {{ maxSelection }}
        </span>
      </ng-container>
    </h5>
    <div class="btn-close float-end" (click)="close()">
      <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
    </div>
  </div>

  <div class="modal-body d-flex flex-column">

    <div class="flex-grow-0 p-3">
      <input class="player-search" [(ngModel)]="playerSearch" placeholder="Search by name">
    </div>

    <div class="flex-grow-0 p-3 selected-players-wrapper">
      <div *ngIf="(playerList | playerSearcher : playerSearch : true : refreshPipe).length === 0"
        class="text-secondary">
        Select players to add
      </div>
      <div class="selected-player-list">
        <div class="selected-player"
          *ngFor="let player of playerList | playerSearcher : playerSearch : true : refreshPipe"
          (click)="togglePlayer(player)"
          placement="top"
          container="body"
          [ngbTooltip]="player.name.display">
          <img [src]="player.avatar">
          <div class="delete-icon">
            <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
          </div>
        </div>
      </div>
    </div>


    <div class="search-players-list-wrapper flex-grow-1">
      <ng-container
        *ngFor="let player of playerList | playerSearcher : playerSearch : false : refreshPipe | slice : 0 : 25">
        <div class="player-list-item d-flex flex-row align-items-center" (click)="togglePlayer(player)">
          <div class="avatar"></div>
          <div class="info d-flex flex-column flex-grow-1">
            <div class="top-row d-flex flex-row align-items-center">
              <span class="flex-grow-1">{{ player.name.display }}</span>
              <span class="text-secondary">{{ player.name.nick }}</span>
            </div>
            <span class="text-secondary text-smaller">{{ player.country }}</span>
          </div>
          <div class="selection">
            <fa-icon [icon]="player.selected ? faCheckCircle : faCircle"
              [fixedWidth]="true"
              class="ms-3 float-end"
              [ngClass]="{ 'text-greenyellow' : player.selected }"></fa-icon>
          </div>
        </div>
      </ng-container>
    </div>

  </div>

  <div class="modal-footer">
    <div class="btn btn-secondary float-end" (click)="closeWithResult(playerList)">Add</div>
  </div>
</ng-container>

<ng-container *ngIf="match$ | async as match">



  <div class="modal-header d-flex flex-column align-items-start" [attr.report-slip]="match.docId">
    <div class="text-large">Report slip</div>
    <div class="mt-2 tournament-match d-flex align-items-center w-100" *ngIf="match.eventDocId !== ''">
      <app-event-name class="flex-grow-1" [eventDocId]="match.eventDocId" [routerLinkEnabled]="false"></app-event-name>
      <div class="labels" [innerHTML]="matchLabels"></div>
    </div>
    <div class="mt-2 d-flex align-items-center text-small">
      <app-player-display-name [nowrap]="false"
        [disableClick]="true"
        [disablePopover]="true"
        [playerDocId]="match.player1.playerDocId"
        [name]="match.player1.displayName">
      </app-player-display-name>
      <div class="text-secondary mx-3">vs</div>
      <app-player-display-name [nowrap]="false"
        [disableClick]="true"
        [disablePopover]="true"
        [playerDocId]="match.player2.playerDocId"
        [name]="match.player2.displayName">
      </app-player-display-name>
    </div>
  </div>

  <div class="modal-body d-flex flex-column p-0 overflow-y-auto scrollable">
    <div class="toolbar bg-body shadow-sm p-2 justify-content-center sticky-top d-flex" *ngIf="!playerIsConfirming">
      <div class="btn-group" role="group">
        <button type="button" class="btn {{ activeStep === 1 ? 'btn-primary' : 'btn-outline-primary' }}" (click)="toStep(1)">Step 1</button>
        <button type="button" class="btn {{ activeStep === 2 ? 'btn-primary' : 'btn-outline-primary' }}" (click)="toStep(2)" [disabled]="!stepOneDone">Step 2</button>
        <button type="button" class="btn {{ activeStep === 3 ? 'btn-primary' : 'btn-outline-primary' }}" (click)="toStep(3)" [disabled]="!stepTwoDone">Step 3</button>
      </div>
    </div>

    <div class="flex-grow-1 p-3">
      <ng-container [ngSwitch]="activeStep">
        <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="step1"></ng-container>
        <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="step2"></ng-container>
        <ng-container *ngSwitchCase="3" [ngTemplateOutlet]="step3"></ng-container>
      </ng-container>

      <div class="drop-zone mt-3 border border-danger text-danger rounded p-3 d-flex flex-column w-100" *ngIf="playerCanDrop">
        <div class="w-100">Drop from the event?</div>
        <div class="my-1 w-100" [style.minHeight.rem]="0.75">
          <ngb-progressbar *ngIf="isDropping" type="danger" height="0.75rem" [value]="dropProgress"></ngb-progressbar>
        </div>
        <div class="w-100 mt-2 text-small">Press and hold the below button for 3 seconds to drop</div>
        <button class="mt-3 btn btn-outline-danger" [disabled]="playerDropped"
          (mousedown)="startDropping()" (mouseup)="stopDropping()" (mouseleave)="stopDropping()"
          (touchstart)="startDropping()" (touchend)="stopDropping()">
          {{ playerDropped ? 'DROPPING' : 'DROP ME' }}
        </button>
        <button class="btn btn-blank text-secondary" (click)="undoDropping()" *ngIf="playerDropped">Undo</button>
      </div>

    </div>

  </div>

  <div class="modal-footer d-flex flex-column align-items-center w-100">
    <div class="d-flex align-items-center w-100 justify-content-end" *ngIf="!playerIsConfirming; else isConfirming">
      <button class="btn btn-sm btn-blank" (click)="onDismiss()">Cancel</button>
      <button class="btn btn-sm btn-primary" (click)="onReport()" [disabled]="disableReport">
        {{ isOrganizer ? 'Report' : 'Confirm' }}
      </button>
    </div>
    <ng-template #isConfirming>
      <div class="d-flex align-items-center gap-3 w-100">
        <button class="btn btn-sm btn-danger w-50" (click)="onAcceptResult(false)">
          Reject
        </button>
        <button class="btn btn-sm btn-success w-50" (click)="onAcceptResult(true)">
          Accept
        </button>
      </div>
      <!-- <button class="mt-3 btn btn-sm btn-blank text-secondary" (click)="onDismiss()">Cancel</button> -->
    </ng-template>
  </div>




  <!-- STEP 1 -->
  <ng-template #step1>
    <div class="mb-3 text-large d-none d-md-block">Select the winner</div>
    <div class="mb-3 p-3 border {{ activeStep === 1 ? 'border-primary' : 'border-secondary' }} rounded">
      <div class="d-flex flex-column gap-2">
        <ng-container *ngTemplateOutlet="playerRow; context: { player: match.player1, playerIs: 'player1' }"></ng-container>
        <ng-container *ngTemplateOutlet="playerRow; context: { player: match.player2, playerIs: 'player2' }"></ng-container>
        <div *ngIf="showDraw"
          class="p-2 btn d-flex align-items-center justify-content-center flex-fill text-larger"
          [ngClass]="{
            'btn-success' : match.isDraw,
            'btn-outline-secondary' : !match.isDraw
          }"
          (click)="selectWinner('draw')">Draw
        </div>
      </div>
    </div>
  </ng-template>


  <!-- STEP 2 -->
  <ng-template #step2>
    <div class="mb-3 text-large d-none d-md-block">Enter the result</div>
    <div class="mb-3 p-3 d-flex gap-2 border {{ activeStep === 2 ? 'border-primary' : 'border-secondary' }} rounded">
      <ng-container *ngIf="!match.isDraw">
        <div class="d-flex flex-column gap-3 w-100">
          <div class="d-flex flex-column gap-2">
            <span class="w-100 text-center">Result</span>
            <div class="d-flex gap-2">
              <div class="p-2 btn d-flex align-items-center justify-content-center flex-fill text-larger"
                [ngClass]="{
                  'btn-success' : score === '1 - 0',
                  'btn-outline-secondary' : score !== '1 - 0'
                }"
                (click)="setScore('1 - 0')">1 - 0
              </div>
              <div class="p-2 btn d-flex align-items-center justify-content-center flex-fill text-larger"
                [ngClass]="{
                  'btn-success' : score === '2 - 0',
                  'btn-outline-secondary' : score !== '2 - 0'
                }"
                (click)="setScore('2 - 0')">2 - 0
              </div>
              <div class="p-2 btn d-flex align-items-center justify-content-center flex-fill text-larger"
                [ngClass]="{
                  'btn-success' : score === '2 - 1',
                  'btn-outline-secondary' : score !== '2 - 1'
                }"
                (click)="setScore('2 - 1')">2 - 1
              </div>
            </div>
          </div>            
          <div class="d-flex flex-column gap-2">
            <span class="w-100 text-center">Drawn games</span>
            <div class="input-group">
              <button class="btn btn-outline-secondary border-opacity-50" type="button" (click)="decreaseDrawnGames()">
                <fa-icon [icon]="icon.minus"></fa-icon>
              </button>
              <input type="number" [min]="0" class="form-control text-center" [value]="match.player1.draws">
              <button class="btn btn-outline-secondary border-opacity-50" type="button" (click)="increaseDrawnGames()">
                <fa-icon [icon]="icon.plus"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="match.isDraw">
        <div class="p-2 btn d-flex align-items-center justify-content-center flex-fill text-larger"
          [ngClass]="{
            'btn-success' : score === '0 - 0',
            'btn-outline-secondary' : score !== '0 - 0'
          }"
          (click)="setScore('0 - 0')">0 - 0
        </div>
        <div class="p-2 btn d-flex align-items-center justify-content-center flex-fill text-larger"
          [ngClass]="{
            'btn-success' : score === '1 - 1',
            'btn-outline-secondary' : score !== '1 - 1'
          }"
          (click)="setScore('1 - 1')">1 - 1
        </div>
      </ng-container>
    </div>
  </ng-template>


  <!-- STEP 3 -->
  <ng-template #step3>
    <div class="mb-3 text-large d-none d-md-block">Review & Confirm</div>
    <div class="mb-3 p-3 border {{ activeStep === 3 ? 'border-primary' : 'border-secondary' }} rounded">
      <div class="winner d-flex flex-column align-items-center" *ngIf="score !== 'none'">
        <ng-container *ngIf="!match.isDraw; else draw">
          <app-player-avatar class="mt-3"
            [playerDocId]="winnerDocId"
            size="large"
            [disablePopover]="true"
            [disableClick]="true">
          </app-player-avatar>
          <app-player-display-name class="mt-3"
            [nowrap]="false"
            [playerDocId]="winnerDocId"
            [disablePopover]="true"
            [disableClick]="true"
            [name]="winnerName">
          </app-player-display-name>
          <div class="mt-3 text-secondary text-large">wins</div>
        </ng-container>
        <ng-template #draw>
          <div class="mt-3 text-large">Player draws</div>
        </ng-template>
        <div class="mt-3 result text-huge">{{ score }}{{ drawnGames }}</div>
      </div>
    </div>
  </ng-template>





</ng-container>

<ng-template #playerRow let-player="player" let-playerIs="playerIs">

  <div class="btn d-flex align-items-center"
  [ngClass]="{
    'btn-success' : player.isWinner,
    'btn-outline-secondary' : !player.isWinner
  }"
  (click)="selectWinner(playerIs)">

  <!-- size="small" -->
    <app-player-avatar class="p-2"
      [disablePopover]="true"  
      [disableClick]="true"
      [playerDocId]="player.playerDocId">
    </app-player-avatar>

    <app-player-display-name class="p-2 text-large text-start"
      [disablePopover]="true"
      [disableClick]="true"
      [nowrap]="false"
      [name]="player.displayName"
      [playerDocId]="player.playerDocId">
    </app-player-display-name>

  </div>

</ng-template>

import { AuthService } from './../../../services/auth.service'
import { IEventDetails, INewEventForm, IMessageDocument, IMessageGroupDocument, ProductType } from 'tolaria-cloud-functions/src/_interfaces'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Injectable } from '@angular/core'
import { v4 as uuidv4 } from 'node_modules/uuid'
import * as firestore from 'firebase/firestore'
import { PlayerNameService } from 'src/app/services/players/player-name.service'

@Injectable({
  providedIn: 'root'
})
export class EventCreationService {

  // TODO --> MOVE THIS INTO ITS OWN FILE
  public statusText = [
    'Open for player registration',         // 0
    'Waiting for pairings',                 // 1
    'Round paired, waiting to start',       // 2
    'Waiting for results',                  // 3
    'All match results reported',           // 4
    'Swiss finished',                       // 5
    'Playoffs: Waiting for organizer',      // 6
    'Playoffs',                             // 7
    'Ended',                                // 8
    '',                                     // 9
    'Waiting for organizer',                // 10
    'Manual pairings in progress',          // 11
    'Batch finished',                       // 12
    'Organizer reviews the new pairings',   // 13
    'Registration opens ',                  // 14 --> dependent on registrationOpensTimestamp to look nice
  ]

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly auth: AuthService,
    private readonly playerNames: PlayerNameService,
  ) { }

  public createEvent(eventDoc: IEventDetails) {

    return new Promise((resolve, reject) => {

      // create batch for writing
      const bacth = this.firestore.firestore.batch()


      /******************************************
       * perform various checks before writing!
       ******************************************/

      // check if registration should be closed at creation
      if (!eventDoc.details.registrationOpen) {
        eventDoc.statusCode = 14
        eventDoc.statusText = this.statusText[14]
      }
      // make sure isMultiDay is set correctly
      // if greater than 24 hours (86400000 milliseconds), isMultiDay should be set to true
      eventDoc.details.isMultiDay = eventDoc.details.datestampTo - eventDoc.details.datestampFrom > 86400000 

      // make sure the base property is the same as the details one
      eventDoc.isOnlineTournament = eventDoc.details.isOnlineTournament


      // write the event document
      bacth.set(this.firestore.collection('events').doc(eventDoc.docId).ref, eventDoc)


      // create the message group for the event
      const messageGroupDocId = 'eventChatFor[' + eventDoc.docId + ']'
      const messageGroup: IMessageGroupDocument = {
        docId: messageGroupDocId,
        name: 'EVENT: ' + eventDoc.details.name,
        createdByUid: eventDoc.createdByUid,
        createdDate: firestore.Timestamp.now().seconds,
        latestMessage: firestore.Timestamp.now().seconds,
        latestMessagePreview: '',
        playerDocIds: [
          this.playerNames.currentPlayersMini.id
        ],
        isSingle: false
      }

      // write the message group document
      bacth.set(this.firestore.collection('messageGroups').doc(messageGroupDocId).ref, messageGroup)


      // send an initial message to the group
      console.log('about to send a message')
      let initMessage = ''
      initMessage += '<h3>Welcome to the message group for the event - ' + eventDoc.details.name + '</h3>'
      initMessage += '<p>Be kind to one another and stay tuned for announcements from the organizer here.</p>'
      const message: IMessageDocument = {
        content: {
          matchDoc: null,
        },
        matchChat: false,
        matchDocId: '', // only available if the message is connected to a match chat
        mentionedPlayerDocIds: [],
        message: initMessage,
        messageGroupDocId,
        playerDocId: this.playerNames.currentPlayersMini.id,
        playerUid: this.playerNames.currentPlayersMini.uid,
        spectatorMode: false, // only available if the message is connected to a match chat
        timestamp: firestore.Timestamp.now().seconds,
        type: 'chat-message',
        whisperMode: null,
        archived: false,
      }

      // write the message document
      bacth.set(this.firestore.collection('messages').doc(`init-message-${eventDoc.docId}`).ref, message)

      // commit batch
      bacth.commit()
        .then(() => resolve(true))
        .catch((e) => reject(e))

    })

  }

  public newTournament(): IEventDetails {
    const guid = uuidv4();
    const doc: IEventDetails = {
      docId: guid,
      details: this.__newTournamentData(),
      createdByUid: this.playerNames.currentPlayersMini.uid,
      coOrganizers: [],
      activeRound: 0,
      statusCode: 0,
      statusText: 'Open for player registration',
      startingTable: 1,
      isOnlineTournament: false,
      roundTimer: 50,
      playerDocIds: [],
      invitedPlayers: [],
      log: [],
      isArchived: false,
      isDeleted: false,
    };

    return doc
  }

  private __newTournamentData(): INewEventForm {
    console.log('[EventCreationService] --> new tournament data creation', this.playerNames.currentPlayersMini.uid);
    const usersPlayerDocument = this.playerNames.currentUserPlayerDoc$.getValue()
    const event: INewEventForm = {
      name: '',
      type: '',
      format: '',
      ruleset: {
        name: '',
        url: ''
      },
      reprintPolicy: {
        name: '',
        url: ''
      },
      isMultiDay: false,
      datestampFrom: null,
      datestampTo: null,
      datetime: '',
      datetimeFrom: '',
      datetimeTo: '',
      GMT_offset: usersPlayerDocument ? usersPlayerDocument.timeZone.UTC : '+00:00',
      description: '',
      location: {
        name: '',
        address: '',
        extra: '',
        postalCode: '',
        postalArea: '',
        url: '',
        longitude: '',
        latitude: '',
        geocode: '',
      },
      isPublic: true,
      isPubliclyVisible: true,
      isPasswordProtected: false,
      password: '',
      hasAttendeeCap: false,
      attendeeCap: null,
      isOnlineTournament: false,
      allowSpectators: true,
      deckPhoto: false,
      deckList: false,
      deckInfoIsPublic: false,
      dropPlayersWithoutSubmittedDeckOnStart: false,
      registrationOpen: true,
      registrationOpensTimestamp: new Date().getTime(),
      registrationClosesTimestamp: null,
      registrationFee: {
        active: false,
        tolariaPayment: true,
        forcePaymentWhenAttending: true,
        charityExtra: true,
        amount: null,
        currency: this.auth.user?.stripe?.default_currency ? this.auth.user.stripe.default_currency : null,
        productId: null,
        productType: ProductType.REGISTRATION_FEE,
      },
      roundTimer: 50,
      startingTable: 1,
      provideFood: false,
      structure: {
        isRoundRobin: false,
        isSwiss: true,
        isBracket: false,
        isBatch: false,
        isGroup: false,
        swiss: {
          hideStandingsUntilPosted: false,
          hasBracketAfterSwiss: false,
          bracketSize: null,
          roundsToPlay: null
        },
        bracket: {
          singleElimination: false,
          doubleElimination: false
        },
        group: {
          matchesPerGroup: null,
          playersPerGroup: null,
          playersAdvancingPerGroup: null,
          hasBracketAfterGroupStage: false,
          bracketSize: null,
          numberOfGroups: null,
        },
        batch: {
          hasBracketAfterBatch: false,
          bracketSize: null,
          batches: []
        }
      }
    }

    return event
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { QuickMessageComponent } from 'src/app/private/social/messages/components/quick-message/quick-message.component';
import { AuthService } from 'src/app/services';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces';
import { CasualMatchButtonComponent } from './casual-match-button/casual-match-button.component';
// import { CasualMatchService } from './services/casual-match.service';

@Component({
  selector: 'app-player-popover',
  template: `
  <ng-container *ngIf="playerMini$ | async as player">
    <div class="d-flex flex-column border border-secondary border-opacity-25 rounded-3 overflow-hidden" style="background-color: var(--content-bg); --bs-border-opacity: 0.25;">
      <div class="p-3 d-flex gap-3 border-bottom border-secondary border-opacity-25" style="background-color: var(--content-bg);">
        <img [src]="avatarUrl" class="flex-shrink-0 rounded-3" [style.width.rem]="4">
        <div class="d-flex align-items-center text-large">
          <span [innerText]="displayName"></span>
        </div>
      </div>
      <div class="p-3 d-flex flex-column gap-2">
        <div class="d-flex align-items-center gap-2">
          <fa-icon [icon]="clock"></fa-icon>
          <span>{{ todayDate | date : displayType : player.utcOffset }} local time</span>
          <span class="text-secondary text-small">{{ timezone }}</span>
        </div>

        <ng-container *ngIf="showControls && !isYou">
          <app-quick-message [playerDocId]="id"></app-quick-message>
          <div class="d-flex gap-2">
            <button class="btn btn-sm btn-outline-secondary flex-grow-1">Collection</button>
            <!-- <button class="btn btn-sm btn-outline-secondary flex-grow-1" (click)="onCasualGame()">Casual game</button> -->
            <app-casual-match-button [playerId]="id"></app-casual-match-button>
          </div>
        </ng-container>

      </div>
    </div>
  </ng-container>
  `,
  styles: [],
  standalone: true,
  imports: [
    CommonModule,
    QuickMessageComponent,
    FontAwesomeModule,
    forwardRef(() => CasualMatchButtonComponent),
  ]
})
export class PlayerPopoverComponent implements OnInit {
  @Input() id: string
  @Input() showControls: boolean = true

  public playerMini$ = new BehaviorSubject<IPlayerMini | null>(null)
  public clock = faClock

  constructor(
    private readonly players: PlayerNameService,
    private readonly auth: AuthService,
    // private readonly casual: CasualMatchService,
  ) {
  }

  ngOnInit(): void {
    this.players.serviceReady$.subscribe(i => {
      if (i) {
        const player = this.players.getPlayerById(this.id)
        if (player) {
          this.playerMini$.next(player)
        }
      }
    })
  }

  public onCasualGame(): void {
    // this.casual.create(this.playerMini$.getValue()).then((result) => {
    //   console.log(result)
    // })
  }

  public get isYou(): boolean {
    if (this.id) {
      return this.players.currentPlayersMini.id === this.id
    }
    return false
  }

  public get avatarUrl(): string {
    if (this.playerMini$.getValue() !== null) {
      return this.playerMini$.getValue().avatar
    }
    return ''
  }

  public get displayName(): string {
    if (this.playerMini$.getValue() !== null) {
      return this.isYou
        ? `${this.playerMini$.getValue().name.display} (you)`
        : this.playerMini$.getValue().name.display
    }
    return ''
  }

  public get timezone(): string {
    if (this.playerMini$.getValue() !== null) {
      const timezone = this.playerMini$.getValue().timezone
      return timezone ? timezone.replace('_', ' ') : ''
    }
    return ''
  }

  public get displayType(): string {
    return this.auth.user.settings.clock24 ? 'HH:mm' : 'h:mm a';
  }

  public get todayDate(): Date {
    return new Date()
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CKEditorModule } from 'ckeditor4-angular';
import { LinkyModule } from 'ngx-linky';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PreventDoubleClickDirective } from '../directives/prevent-double-click.directive';
import { ResizableModule } from 'angular-resizable-element';


@NgModule({
  declarations: [
    PreventDoubleClickDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    CKEditorModule,
    LinkyModule,
    NgbModule,
    ImageCropperModule,
    ResizableModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    CKEditorModule,
    LinkyModule,
    NgbModule,
    ImageCropperModule,
    PreventDoubleClickDirective,
    ResizableModule,
  ]
})
export class SharedModule { }

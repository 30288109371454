import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input } from '@angular/core';
import { NgbModal, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { StickyPopoverDirective } from 'src/app/directives/sticky-popover.directive';
import { PlayerPopoverComponent } from 'src/app/private/_shared/player-popover.component';
import { PlayerListingService } from 'src/app/private/_shared/services/player-listing.service';
import { PlayerNameService } from 'src/app/services/players/player-name.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tolaria-player-mention',
  templateUrl: './tolaria-player-mention.component.html',
  styleUrls: ['./tolaria-player-mention.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    NgbPopoverModule,
    PlayerPopoverComponent,
    StickyPopoverDirective,
  ]
})
export class TolariaPlayerMentionComponent {
  @Input() set playerdocid(id: string) {
    this.id = id
  }
  @Input() set playerdisplayname(name: string) {
    this.name = name
  }
  @Input() set showcontrols(value: string) {
    this.showControls = value === 'true'
  }

  public id!: string
  public name!: string
  public showControls: boolean = true

  constructor(
    private readonly host: ElementRef<HTMLElement>,
    private readonly players: PlayerNameService,
    private readonly playerList: PlayerListingService,
  ) { }

  public destroy(): void {
    this.host.nativeElement.remove()
  }

  public openPlayerDetails(): void {
    this.playerList.showPofile(this.id)
  }

  public get mentionClass(): string {
    let player = this.players.getPlayerById(this.id)
    if (player) {
      return this.id === this.players.currentPlayersMini.id
        ? 'current-player'
        : 'not-current-player'
    }
    return 'not-current-player'
  }

}

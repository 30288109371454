<ng-container *ngIf="init$ | async">


  <ng-container *ngIf="showAsModal; else regPaymentForm">

    <div class="modal-header">
      <div class="text-larger">Register External Event Payment</div>
    </div>

    <div class="modal-body p-0">
      <ng-template [ngTemplateOutlet]="regPaymentForm"></ng-template>
    </div>

    <div class="modal-footer p-0 px-3 px-2">
      <button class="btn btn-sm btn-blank text-secondary" (click)="dismiss()">Cancel</button>
      <button class="btn btn-sm btn-blank" (click)="close()" [disabled]="disableSave">Save</button>
    </div>

  </ng-container>



  <ng-template #regPaymentForm>

    <div class="p-3">

      <div class="row mb-2 p-3">
        <div class="col btn btn-outline-main">
          You are about to register an external payment.<br>
          This will just add the <i>registration fee paid</i> flag on the player.<br>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-6">Player:</div>
        <app-player-display-name class="col-6 text-end" [playerDocId]="player.playerDocId" [name]="player.name">
        </app-player-display-name>
      </div>

      <div class="row mb-3">
        <div class="col-9">Registration Fee:</div>
        <div class="col-3 text-end"><span [innerHtml]="amount"></span><span class="text-secondary ms-1 text-smaller"
            [innerHtml]="currency"></span></div>
      </div>

      <div class="row mb-2" *ngIf="charityExtraEnabled">
        <div class="col-9">Charity Extra:</div>
        <div class="col-3 text-end">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="charityAmountInput">
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-9">Payment Type:</div>
        <div class="col-3 text-end">
          <select class="custom-select custom-select-sm" [(ngModel)]="payment.paymentType">
            <option [value]="type" *ngFor="let type of paymentTypes">{{ type }}</option>
          </select>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col">
          <textarea class="w-100 p-2" rows="3" [(ngModel)]="payment.comment"></textarea>
        </div>
      </div>

    </div>

  </ng-template>


</ng-container>

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, firstValueFrom } from 'rxjs';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { IMatchAppointment } from 'tolaria-cloud-functions/src/_interfaces';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  private playerAppointments = new Map<string, Observable<IMatchAppointment[]>>()

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly playerNames: PlayerNameService,
  ) { }

  getAppointments(playerDocId: string): Promise<IMatchAppointment[]> {
    return new Promise(async (resolve) => {
      if (this.playerAppointments.get(playerDocId) === undefined) {
        let now = new Date().getTime() / 1000
        const observer = this.firestore.collection<IMatchAppointment>('matchAppointments', ref => ref
          .where('playerDocIds', 'array-contains', playerDocId)
          .where('timestampFrom', '>=', now)
        ).valueChanges()
        this.playerAppointments.set(playerDocId, observer)
      }
      let data = await firstValueFrom(this.playerAppointments.get(playerDocId))
      resolve(data)
    })
  }

  getMyAppointments(): Promise<IMatchAppointment[]> {
    return new Promise(async (resolve) => {
      if (this.playerAppointments.get(this.playerNames.currentPlayersMini.id) === undefined) {
        let now = new Date().getTime() / 1000
        const observer = this.firestore.collection<IMatchAppointment>('matchAppointments', ref => ref
          .where('playerDocIds', 'array-contains', this.playerNames.currentPlayersMini.id)
          .where('timestampFrom', '>=', now)
        ).valueChanges()
        this.playerAppointments.set(this.playerNames.currentPlayersMini.id, observer)
      }
      let data = await firstValueFrom(this.playerAppointments.get(this.playerNames.currentPlayersMini.id))
      resolve(data)
    })
  }


}

<ng-container *ngIf="globals.isMobile; else desktopView">
  <div class="d-flex flex-column min-height-100 overflow-hidden dashboard-mobile" (click)="onEasterPress()">

    <div class="d-flex flex-wrap p-2">
      <ng-container *ngFor="let link of appLinks">
        <div
          class="app-link flex-grow-1 m-1 d-flex flex-column aligm-content-center align-items-center justify-content-center"
          [routerLink]="link.routerLink">
          <div class="icon">
            <fa-icon [icon]="link.icon" [fixedWidth]="true"></fa-icon>
          </div>
          <div class="title" [innerHtml]="link.text"></div>
        </div>
      </ng-container>
    </div>

    <div class="mt-3">
      <app-version-number></app-version-number>
    </div>

    <div class="flex-grow-1 spacer"></div>

    <div class="row mb-3">
      <div class="col d-flex justify-content-center">
        <div class="qr-code d-flex flex-column align-items-center" (click)="reportIssue()">
          <fa-icon [fixedWidth]="true" [icon]="faBug"></fa-icon>
          <span class="text-secondary text-uppercase text-smaller mt-2">report issue</span>
        </div>
      </div>
      <div class="col d-flex justify-content-center">
        <div class="qr-code d-flex flex-column align-items-center" (click)="openPlayerId()">
          <fa-icon [fixedWidth]="true" [icon]="faQrcode"></fa-icon>
          <span class="text-secondary text-uppercase text-smaller mt-2">player id</span>
        </div>
      </div>
      <div class="col d-flex justify-content-center">
        <div class="qr-code d-flex flex-column align-items-center" (click)="reportMatch()">
          <fa-icon [fixedWidth]="true" [icon]="faReport"></fa-icon>
          <span class="text-secondary text-uppercase text-smaller mt-2">report match</span>
        </div>
      </div>
    </div>

    <div class="easter-egg p-3 d-flex flex-column align-items-center" #congrats [ngClass]="{ 'visible' : foundIt }">
      <div class="text-large">
        Wohaaa you found the treasure!
      </div>
      <div class="">Contact Slanfan to receive your price</div>
      <img src="assets/easter-eggs.png">
    </div>

  </div>

</ng-container>

<ng-template #desktopView>
  <div class="dashboard-section-wrapper p-1">

    <div class="dashboard-section p-1 d-flex flex-row">
      <div class="me-2 d-flex flex-column justify-content-start min-height-100 max-height-100 overflow-hidden flex-grow-1 gap-2">
        <app-tolaria-support class="w-100"></app-tolaria-support>
        <app-match-monitor class="flex-grow-1 overflow-hidden"></app-match-monitor>
        <!-- <app-recent-brews [numberOfDecks]="15" class="flex-grow-1 overflow-hidden"></app-recent-brews> -->
      </div>
      <app-card-of-the-day class="flex-grow-1 me-2"></app-card-of-the-day>
      <app-news-feed class="flex-grow-1 me-2" [numFeeds]="19" [showMoreButton]="true"></app-news-feed>
    </div>

    <!-- <div class="dashboard-section p-1 d-flex flex-row">
    </div> -->

  </div>
</ng-template>


<ng-template #betaTesterInfo>
  <section>
    <div class="card" style="min-width: 100%; max-width: 100%; height: 100%;">
      <div class="card-body scrollable no-hover">
        <div class="h3">General Information for Beta Testers</div>
        <p>I will try to publish new updates every <span style="color: #e03e2d;"><strong>Sunday at 22:00
              GMT+2</strong></span></p>
        <p><span style="text-decoration: underline; color: #236fa1;">Please note that deployment might need:</span>
        </p>
        <ul>
          <li><span style="color: #236fa1;">Deletion of all <strong>EVENTS</strong></span>
            <ul>
              <li><span style="color: #236fa1;"><em>Messages</em></span></li>
              <li><span style="color: #236fa1;"><em>Matches</em></span></li>
              <li><span style="color: #236fa1;"><em>Events</em></span></li>
            </ul>
          </li>
        </ul>
        <p>This is due to the fact that we might need to change the data structure to be able to fit all the needed
          information.</p>
        <p>Missing or additional information might cause issues with previously created events due to mismatching data
          structure and this is why we might need deletion of events and all connected data.</p>
        <div class="h3">Bugs and Improvements</div>
        <p>You are more than welcome to report any strange behaivor or if you think you have a great idea that might
          smooth game play and organizing.</p>
        <p>Before you register an improvement suggestion, please see the list of improvements already registered
          @ <a target="_blank"
            href="https://github.com/Slanfan/MTG-Tolaria/labels/improvement%20request">improvement</a></p>
        <p>In addition, you might find the <a href="https://github.com/Slanfan/MTG-Tolaria/milestones"
            target="_blank">milestones</a> @ the <a target="_blank"
            href="https://github.com/Slanfan/MTG-Tolaria/projects">project</a> site interesting as well, here I try to
          document any thoughts that I come up with and that I have plans to develop.
        </p>
        <div class="h3">A rough and unstructured walkthrough of tolaria</div>
        <!-- <iframe src="https://player.vimeo.com/video/502828120" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> -->
        <iframe width="640" height="360" src="https://www.youtube.com/embed/ekkVkjbRwh4" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div class="card-footer">
        <a href="https://github.com/Slanfan/MTG-Tolaria/issues/new" target="_blank">
          <div class="btn btn-sm btn-danger">Report an issue (BUG)</div>
        </a>
        <a href="https://github.com/Slanfan/MTG-Tolaria/issues/new" target="_blank">
          <div class="btn btn-sm btn-primary ms-2">Register an improvement</div>
        </a>
        <button disabled="true" class="btn btn-sm text-secondary float-end">{{ 1595713090228 | date: 'medium'
          }}</button>
      </div>
    </div>
  </section>
</ng-template>


<ng-template #stripeRefresh let-modal>
  <div class="modal-header">
    <div class="text-large">Stripe Connect Account - Refresh</div>
  </div>
  <div class="modal-body d-flex flex-column">
    <ng-container *ngIf="hasRedirectUrl; else noLinks">
      <div class="">You have an ongoing onboarding for a Stripe <b>connected account</b>, do you want to continue with
        this session?</div>
      <div class="btn btn-sm btn-outline-main mt-5" (click)="redirectToStripe()">Continue Session</div>
      <div class="btn btn-sm btn-outline-main mt-2" (click)="closeWindow()">Close Window</div>
    </ng-container>
    <ng-template #noLinks>
      <div class="d-flex flex-column">
        <p>There are no active onboarding session for a Stripe <b>connected account</b>, please close this window and
          start over from the application settings</p>
      </div>
    </ng-template>
  </div>
  <div class="modal-footer" *ngIf="hasRedirectUrl">
    <div class="btn btn-sm btn-blank" (click)="modal.close()">Close</div>
  </div>
</ng-template>

<ng-template #stripeReturn let-modal>
  <div class="modal-header">
    <div class="text-large">Stripe Connect Account - Done</div>
  </div>
  <div class="modal-body"></div>
  <div class="modal-footer">
    <div class="btn btn-sm btn-blank" (click)="modal.close()">Close</div>
  </div>
</ng-template>

<ng-template #matchSelection let-modal>
  <ng-container *ngIf="matchesToReport$ | async as matches">
    <div class="modal-header d-flex justify-content-center">
      <div class="text-large">Select match to report</div>
    </div>
    <div class="modal-body d-flex flex-column">
      <ng-container *ngFor="let match of matches">
        <div class="btn btn-outline-secondary d-flex flex-column mb-3" (click)="modal.close(match.docId)">
          <div class="text-xlarge">{{ match.opponentName }}</div>
          <app-event-name class="text-secondary text-small" [routerLinkEnabled]="false" [eventDocId]="match.eventDocId"></app-event-name>
        </div>
      </ng-container>
    </div>
    <div class="modal-footer">
      <div class="btn btn-sm btn-blank" (click)="modal.dismiss()">Cancel</div>
    </div>
  </ng-container>
</ng-template>

<canvas id="confettiCanvase" class="easter-canvas hidden"></canvas>
<div class="message-toast {{ fade ? 'fade' : '' }}" *ngIf="type === 'success'">
  <div class="row">
    <div class="col-1">
      <fa-icon class="success" [fixedWidth]="true" [icon]="faCheckCircle"></fa-icon>
    </div>
    <div class="col text-start">
      {{ message }}
    </div>
  </div>
</div>

<div class="message-toast {{ fade ? 'fade' : '' }}" *ngIf="type === 'error'">
  <div class="row">
    <div class="col-1">
      <fa-icon class="error" [fixedWidth]="true" [icon]="faExclamationCircle"></fa-icon>
    </div>
    <div class="col text-start">
      {{ message }}
    </div>
  </div>
</div>

<div class="message-toast {{ fade ? 'fade' : '' }}" *ngIf="type === 'warning'">
  <div class="row">
    <div class="col-1">
      <fa-icon class="warning" [fixedWidth]="true" [icon]="faExclamationTriangle"></fa-icon>
    </div>
    <div class="col text-start">
      {{ message }}
    </div>
  </div>
</div>

<div class="message-toast {{ fade ? 'fade' : '' }}" *ngIf="type === 'info'">
  <div class="row">
    <div class="col-1">
      <fa-icon class="info" [fixedWidth]="true" [icon]="faInfoCircle"></fa-icon>
    </div>
    <div class="col text-start">
      {{ message }}
    </div>
  </div>
</div>

<div class="message-toast {{ fade ? 'fade' : '' }}" *ngIf="type === 'default'">
  {{ message }}
</div>

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
  ]
})
export class PublicHeaderComponent {
  @Input() showAbout = false

  showMenu = false

  constructor() { }

}

import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IClub, IFormatMeta, IPlayerDetails, IPlayerHandle, IUser } from 'tolaria-cloud-functions/src/_interfaces';

interface PlayerMetaList {
  [key: string]: IPlayerMeta
}

@Injectable({
  providedIn: 'root'
})
export class PlayerMetaService {

  private playerMeta$ = new BehaviorSubject<PlayerMetaList>({})

  constructor(
    private firestore: AngularFirestore,
  ) {}

  public getPlayerMeta(playerId: string): Promise<IPlayerMeta | null> {

    return new Promise(async (resolve) => {

      // check if existing in the service list
      if (this.playerMeta$.getValue()[playerId]) {
        resolve(this.playerMeta$.getValue()[playerId])
      }

      // get player document
      const playerDocSnap = await firstValueFrom(this.firestore.collection('players').doc<IPlayerDetails>(playerId).get())
      if (!playerDocSnap.exists) { resolve(null) }
      const playerDoc = playerDocSnap.data()

      // get user document
      const userDocSnap = await firstValueFrom(this.firestore.collection('users').doc<IUser>(playerDoc.uid).get())
      if (!userDocSnap.exists) { resolve(null) }
      const userDoc = userDocSnap.data()

      // get clubs
      let clubs: IClub[] = []
      const clubDocSnaps = await firstValueFrom(this.firestore.collection<IClub>('clubs', ref => ref.where('memberDocIds', 'array-contains', playerId)).get())
      if (clubDocSnaps.size > 0) { clubs = clubDocSnaps.docs.map(i => i.data()) }

      // create meta
      const playerMeta = this.createPlayerMeta(playerDoc, clubs, userDoc)

      // add to player list
      let list = this.playerMeta$.getValue()
      list[playerId] = playerMeta
      this.playerMeta$.next(list)

      console.log(list)

      // return response
      resolve(playerMeta)

    })

  }

  public getPlayerMetaByEmail(email: string): Promise<IPlayerMeta | null> {

    return new Promise(async (resolve) => {

      // check if existing in the service list
      let playerMap = this.playerMeta$.getValue()
      let player = Object.values(playerMap).find(i => i.email === email)
      if (player) {
        resolve(player)
      }

      // get player document
      const colSnap = await firstValueFrom(this.firestore.collection<IPlayerDetails>('players', ref => ref.where('email', '==', email).limit(1)).get())
      if (colSnap.empty) {
        resolve(null)
      }

      const playerDoc = colSnap.docs.map(i => i.data())[0]

      // get user document
      const userDocSnap = await firstValueFrom(this.firestore.collection('users').doc<IUser>(playerDoc.uid).get())
      if (!userDocSnap.exists) { resolve(null) }
      const userDoc = userDocSnap.data()

      // get clubs
      let clubs: IClub[] = []
      const clubDocSnaps = await firstValueFrom(this.firestore.collection<IClub>('clubs', ref => ref.where('memberDocIds', 'array-contains', playerDoc.docId)).get())
      if (clubDocSnaps.size > 0) { clubs = clubDocSnaps.docs.map(i => i.data()) }

      // create meta
      const playerMeta = this.createPlayerMeta(playerDoc, clubs, userDoc)

      // add to player list
      let list = this.playerMeta$.getValue()
      list[playerDoc.docId] = playerMeta
      this.playerMeta$.next(list)

      console.log('[PlayerMetaService] -> player meta list ready', list)

      // return response
      resolve(playerMeta)

    })

  }
  

  private createPlayerMeta(player: IPlayerDetails, clubs: IClub[], user: IUser): IPlayerMeta {
    const isOnline = player.presence !== undefined && player.presence.status !== undefined ? player.presence.status !== 'offline' : false
    const downToPlay = player.presence !== undefined && player.presence.downToPlay !== undefined ? player.presence.downToPlay ? true : false : false
    const downToPlayFormats = player.presence !== undefined && player.presence.downToPlayFormats !== undefined
      ? player.presence.downToPlayFormats
        ? player.presence.downToPlayFormats
        : []
      : []
    const statusText = player.presence !== undefined && player.presence.status !== undefined ? player.presence.status : 'Unknwon status'

    let isHero = false
    let isMemberOfClub = false
    let clubDocId = ''
    let avatarUrl = 'assets/avatars/default.jpg'
    let description = ''

    let clubsSearchString = ''
    if (player.clubDocIds !== undefined && player.clubDocIds.length > 0) {
      player.clubDocIds.forEach((clubDocId: string) => {
        const club = clubs.find(c => c.docId === clubDocId)
        if (club !== undefined) {
          clubsSearchString += `${club.name} `
        }
      })
    }

    // CLUB
    if (player.hasOwnProperty('identifiers') && player.identifiers.hasOwnProperty('memberOfClub')) {
      isMemberOfClub = player.identifiers.memberOfClub
    }
    if (player.hasOwnProperty('identifiers') && player.hasOwnProperty('clubDocIds')) {
      // should get the name from the team collection
      clubDocId = player.clubDocIds[0]
    }
    // HERO
    if (player.hasOwnProperty('identifiers') && player.identifiers.hasOwnProperty('isHero')) {
      isHero = player.identifiers.isHero
    }
    // DESCRIPTION
    if (player.hasOwnProperty('description') && player.description !== '' && player.description !== null) {
      description = player.description
    }
    // AVATAR
    if (player.hasOwnProperty('avatar') && player.avatar !== '' && player.avatar !== null) {
      avatarUrl = player.avatar
    }
    if (player.hasOwnProperty('avatarThumb') && player.avatarThumb !== '' && player.avatarThumb !== null) {
      avatarUrl = player.avatarThumb
    }


    const playerMeta: IPlayerMeta = {
      docId: player.docId,
      uid: player.uid,
      avatarUrl,
      country: player.country.name,
      region: player.country.region,
      subregion: player.country.subRegion,
      name: player.name.first + ' ' + player.name.last,
      nickName: player.name.nick,
      displayName: player.name.display !== undefined && player.name.display !== ''
        ? player.name.display
        : player.name.first + ' ' + player.name.last,
      detailsRoute: 'player/details/' + player.docId,
      type: player.type,
      UTC: player?.deviceUtcOffset ? player.deviceUtcOffset : player.timeZone.UTC,
      olson: player?.deviceUtcTimezoneName ? player.deviceUtcTimezoneName : player.timeZone.olson,
      isMemberOfClub,
      clubDocId,
      clubsSearchString,
      isOrganizer: user.role === 'organizer',
      isOnline,
      identifiers: [],
      hasShark: player?.identifiers?.hasShark,
      isDingus: player?.identifiers?.isDingus,
      isHero: isHero || player?.tolariaSupportUntil > Math.floor(Date.now() / 1000),
      statusText,
      downToPlay: isOnline ? downToPlay : false,
      downToPlayFormats: isOnline ? downToPlayFormats : [],
      description,
      addressIsPublic: player?.addressIsPublic,
      emailIsPublic: player?.emailIsPublic,
      phoneIsPublic: player?.phoneIsPublic,
      phone: player.phone,
      email: player.email,
      addressStreet: player?.address?.street,
      addressOther: player?.address?.other,
      addressZipCode: player?.address?.zipCode,
      addressCity: player?.address?.city,
      addressRegion: player?.address?.region,
      handles: player.hasOwnProperty('handles') ? player.handles : [],
      statusMessage: player.statusMessage && player.statusMessage.length > 0 ? player.statusMessage : null,
    }

    // IDENTIFIERS
    /*
    * 0. Drake
    * 1. Dingus
    * 2. Shark
    * 3. Guardian
    * 4. Hero
    */
    if (player?.identifiers?.isDrakeGuild) { playerMeta.identifiers.push('drake-guild') }
    if (player?.identifiers?.isGuardian) { playerMeta.identifiers.push('guardian') }
    if (playerMeta.isDingus) { playerMeta.identifiers.push('dingus') }
    if (playerMeta.hasShark) { playerMeta.identifiers.push('shark') }
    if (playerMeta.isHero) { playerMeta.identifiers.push('hero') }



    return playerMeta
  }


}


export interface IPlayerMeta {
  docId: string
  uid: string
  avatarUrl: string
  country: string
  region: string
  subregion: string
  name: string
  nickName: string
  displayName: string
  detailsRoute: string
  type: string
  UTC: string
  olson: string
  isMemberOfClub: boolean
  clubsSearchString: string
  clubDocId: string
  identifiers: Array<string>
  hasShark: boolean
  isDingus: boolean
  isHero: boolean
  isOnline: boolean
  statusText: string
  downToPlay: any
  downToPlayFormats?: Array<IFormatMeta>
  description: string
  addressIsPublic: boolean
  emailIsPublic: boolean
  phoneIsPublic: boolean
  addressStreet: string
  addressOther: string
  addressZipCode: number
  addressCity: string
  addressRegion: string
  email: string
  phone: string
  isOrganizer: boolean
  handles: IPlayerHandle[]
  statusMessage: string
}

export class PlayerIdentifiers {
  hasShark = false
  isDingus = false
  isDrakeGuild = false
  isHero = false
  isGuardian = false
}

export interface IPlayerSearch {
  displayName: string
  name: string
  nick: string
  docId: string
  country: string
  selected: boolean
  avatar: string
  searchString: string
}

export interface IPlayerLink {
  displayName: string
  first: string
  last: string
  docId: string
}

export interface IOnlinePlayer {
  status: string
  timestamp: number
  downToPlay: boolean
  downToPlayFormats: Array<IFormatMeta>
}

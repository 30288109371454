<div class="tolaria-tournament-viewer border border-secondary rounded m-1 mb-3 overflow-hidden"
  contenteditable="false"
  [ngClass]="{ 'show-full' : showAll, 'is-focused' : isFocused }">

  <ng-container *ngIf="tournament$ | async as event; else loading">
    <app-event-grid-item [event]="event"></app-event-grid-item>
  </ng-container>

  
  
</div>

<ng-template #loading>

  <app-event-grid-item [event]="null"></app-event-grid-item>

</ng-template>
import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebook, faGithub, faGoogle, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Subscription } from 'rxjs';
import { AuthService, GlobalsService } from 'src/app/services';
import { applicationIcon } from 'src/assets/font-icons/tolaria-icon';
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces';
import { PublicFooterComponent } from '../../public-footer/public-footer.component';
import { PublicHeaderComponent } from "../../public-header/public-header.component";

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
  imports: [
    FormsModule,
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    PublicFooterComponent,
    PublicHeaderComponent
],
})
export class SignInComponent implements OnDestroy {

  public isMobile = window.innerWidth < 500
  public topMargin = this.isMobile ? 0 : 10

  public loginData: any = {
    email: '',
    password: '',
  }

  public authMessage: any | null = null

  private authSubscription: Subscription;

  constructor(
    private auth: AuthService,
    private router: Router,
    public globals: GlobalsService
  ) {
    this.authSubscription = this.auth.user$.subscribe(user => {
      if (user) {
        console.log('LoginComponent: user logged in, move to previous url or dashboard');
        if (user.isRegisteredAsPlayer) {
          if (this.globals.returnUrl.has) {
            console.log('login.component -> globals.returnUrl.has set, should navigate accordingly')
            this.router.navigate([this.globals.returnUrl.url], { queryParams: this.globals.returnUrl.params })
            setTimeout(() => {
              this.globals.returnUrl = {
                has: false,
                url: null,
                params: null,
              }
            }, 3000)
          }
          else {
            this.router.navigate(['dashboard']);
          }
        }
        else {
          this.router.navigate(['register']);
        }
      }
      else {
        console.log('LoginComponent: user not logged in, staying at login');
        this.router.navigate(['/auth/sign-in']);
      }
    });
    this.globals.routerLoaded = true;
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  public signin(method: 'email' | 'facebook' | 'github' | 'google' | 'twitter') {
    this.authMessage = null;
    switch (method) {
      case 'email':
        this.auth.emailSignin(this.loginData).then((response: IPromiseResponse) => {
          console.log(response);
          this.authMessage = {
            success: response.status,
            message: response.text
          };
        });
        break;

      case 'facebook':
        this.auth.facebookSignin().then((response: IPromiseResponse) => {
          console.log(response);
          this.authMessage = {
            success: response.status,
            message: response.text
          };
        });
        break;

      case 'google':
        this.auth.googleSignin().then((response: IPromiseResponse) => {
          console.log(response);
          this.authMessage = {
            success: response.status,
            message: response.text
          };
        });
        break;

      case 'github':
        this.auth.githubSignin().then((response: IPromiseResponse) => {
          console.log(response);
          this.authMessage = {
            success: response.status,
            message: response.text
          };
        });
        break;

      case 'twitter':
        this.auth.twitterSignin().then((response: IPromiseResponse) => {
          console.log(response);
          this.authMessage = {
            success: response.status,
            message: response.text
          };
        });
        break;
    }
  }


  public get disableLoginButton(): boolean {
    const emailValidator = new RegExp(/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/)
    if (!emailValidator.test(this.loginData.email)) {
      return true
    }
    if (this.loginData.password === null || this.loginData.password === undefined || this.loginData.password.length === 0) {
      return true
    }
    return false
  }

  public icon = {
    app: applicationIcon,
    facebook: faFacebook,
    github: faGithub,
    google: faGoogle,
    twitter: faTwitter,
  }



}

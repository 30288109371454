
<div class="life" [ngClass]="{ 'critical' : playerLife.current <= 5, 'bottom' : !matchWinsOnTop, 'top': matchWinsOnTop }">

  {{ playerLife.current }}

  <div class="game-wins-wrapper">
    <div class="game-win" [ngClass]="{ 'active' : playerLife.gameWins > 0 }">
      <fa-icon [fixedWidth]="true" [icon]="playerLife.gameWins > 0 ? faCheckCircle : faCircleFilled"></fa-icon>
    </div>
    <div class="game-win" [ngClass]="{ 'active' : playerLife.gameWins > 1 }">
      <fa-icon [fixedWidth]="true" [icon]="playerLife.gameWins > 1 ? faCheckCircle : faCircleFilled"></fa-icon>
    </div>
  </div>

  <div class="life-history-popover">
    <div [innerText]="life !== null ? life : ''" [ngClass]="{ 'history-separator' : life === null }" *ngFor="let life of playerLife.history"></div>
  </div>

  <div class="help-popover">
    <h5>Player life</h5>
    <span>Adjust your life total by pressing either <span class="key">arrow up</span> or
      <span class="key">arrow down</span>. You can also adjust in steps of 5 by holding
      <span class="key">Shift</span> and press <span class="key">arrow up</span> or
      <span class="key">arrow down</span></span>
    <h5 class="mt-2">Game wins</h5>
    <span>You can add or remove game wins from the more actions button <span class="key">...</span></span>
  </div>

</div>


import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input } from '@angular/core';
import { PublicFooterComponent } from 'src/app/public/public-footer/public-footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { PublicHeaderComponent } from 'src/app/public/public-header/public-header.component';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css'],
  standalone: true,
  imports: [
    PublicHeaderComponent,
    PublicFooterComponent,
    FontAwesomeModule,
    CommonModule,
  ]
})
export class TermsOfUseComponent {
  @Input() modal = false

  faTimes = faTimes;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

}

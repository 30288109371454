import Fuse from 'fuse.js'
import Quill, { RangeStatic } from 'quill'
import Delta from 'quill-delta'


const Module: any = Quill.import('core/module')

class TolariaEmojiTypeheadModule extends Module {

    constructor(quill: any, options: any) {
        super(quill, options)
        this.logger('log', 'construction', quill)

        this.quill = quill
        this.triggerIndex = null
        this.isOpen = false
        this.debug = options.debug
        this.emojiList = options.emojiList
        this.logger('log', 'emojiList:', quill)
        this.fuse = new Fuse(this.emojiList, options.fuse)
        this.emojis = []
        this.onClose = options.onClose
        this.onOpen = options.onOpen
        this.onTextChange = this.update.bind(this)
        this.currentIndex = 0
        this.lastIndex = 0
        this.outsideClick = null

        // create the base container
        this.createContainer()

        // add keyboard listeners
        this.addKeyboardListeners()

        // add change handler listener
        this.quill.on('text-change', this.onTextChange)

    }

    logger(type: string, value: string, data?: any) {
        if (this.debug || type === 'error') {
            if (data) {
                console[type](`[TolariaEmojiTypeheadModule] -> ${value}`, data)
            }
            else {
                console[type](`[TolariaEmojiTypeheadModule] -> ${value}`)
            }
        }
    }

    createContainer() {
        this.container = document.createElement('div')
        this.container.classList.add('emoji-typehead', 'p-0', 'py-2', 'border', 'border-secondary', 'border-opacity-25', 'rounded', 'rounded-3')
        this.container.style.display = 'none'
        this.list = document.createElement('ul')
        this.container.appendChild(this.list)
        this.quill.container.appendChild(this.container)
    }

    addKeyboardListeners() {

        // adding this directly to the bindings array to make sure this
        // triggers before anyting else
        this.quill.keyboard.addBinding({
            key: 13,  // Enter
            collapsed: true,
        }, (range, context) => {
            this.logger('log', 'Enter key', {range, context})
            if (this.isOpen) {
                // the typehead is open, return the selected emoji
                this.logger('log', 'Closing with the selected emoji', this.emojis[this.currentIndex])
                this.close(this.emojis[this.currentIndex])
                return false
            }
            this.logger('log', 'Nothing to do here')
            return true
        })
        this.quill.keyboard.bindings[13].unshift(this.quill.keyboard.bindings[13].pop())

        this.quill.keyboard.addBinding({
            key: 9,  // Tab
            collapsed: true,
        }, (range, context) => {
            this.logger('log', 'Tab key', {range, context})
            if (this.isOpen) {
                this.close(this.emojis[this.currentIndex])
                return false
            }
            return true
        })
        this.quill.keyboard.bindings[9].unshift(this.quill.keyboard.bindings[9].pop())

        this.quill.keyboard.addBinding({
            key: 27,  // Escape
            collapsed: true,
        }, (range, context) => {
            this.logger('log', 'Escape key', {range, context})
            if (this.isOpen) {
                this.close(null)
                return false
            }
            return true
        })
        this.quill.keyboard.bindings[27].unshift(this.quill.keyboard.bindings[27].pop())
        
        this.quill.keyboard.addBinding({
            key: 32,  // Space
            collapsed: true,
        }, (range, context) => {
            this.logger('log', 'Space', {range, context})
            if (this.isOpen) {
                this.close('continue')
                return false
            }
            return true
        })
        this.quill.keyboard.bindings[32].unshift(this.quill.keyboard.bindings[32].pop())

        this.quill.keyboard.addBinding({
            key: 186,  // ':' instead of 190 in Safari. Since it's the same key it doesn't matter if we register both.
            shiftKey: true,
            collapsed: true,
        }, (range, context) => {
            this.logger('log', ':', {range, context})
            this.triggerTypehead(range, context)
            return true
        })

        this.quill.keyboard.addBinding({
            key: 190,  // ':' instead of 190 in Safari. Since it's the same key it doesn't matter if we register both.
            shiftKey: true,
            collapsed: true,
        }, (range, context) => {
            this.logger('log', ':', {range, context})
            this.triggerTypehead(range, context)
            return true
        })

        this.quill.keyboard.addBinding({
            key: 59,  // gecko based browsers (firefox) use 59 as the keycode for semicolon, which makes a colon character when combined with shift
            shiftKey: true,
            collapsed: true,
        }, (range, context) => {
            this.logger('log', ':', {range, context})
            this.triggerTypehead(range, context)
            return true
        })

        this.quill.keyboard.addBinding({
            key: 58,  // gecko based browsers (firefox) use 59 as the keycode for semicolon, which makes a colon character when combined with shift
            shiftKey: true,
            collapsed: true,
        }, (range, context) => {
            this.logger('log', ':', {range, context})
            this.triggerTypehead(range, context)
            return true
        })

        this.quill.keyboard.addBinding({
            key: 38,  // ArrowUp
            collapsed: true
        }, (range, context) => {
            if (this.isOpen) {
                this.logger('log', 'Arrow up', {range, context})
                this.lastIndex = JSON.parse(JSON.stringify(this.currentIndex))
                this.currentIndex = this.currentIndex === 0 ? this.listItems.length - 1 : this.currentIndex - 1
                this.updateSelection()
                return false
            }
            return true
        })
        this.quill.keyboard.bindings[38].unshift(this.quill.keyboard.bindings[38].pop())

        this.quill.keyboard.addBinding({
            key: 40,  // ArrowDown
            collapsed: true
        }, (range, context) => {
            if (this.isOpen) {
                this.logger('log', 'Arrow down', {range, context})
                this.lastIndex = JSON.parse(JSON.stringify(this.currentIndex))
                this.currentIndex = this.currentIndex === this.listItems.length - 1 ? 0 : this.currentIndex + 1
                this.updateSelection()
                return false
            }
            return true
        })
        this.quill.keyboard.bindings[40].unshift(this.quill.keyboard.bindings[40].pop())

        this.quill.keyboard.addBinding({
            key: 37,  // ArrowLeft
            collapsed: true
        }, (range, context) => {
            this.logger('log', 'Arrow left', {range, context})
            if (this.isOpen) {
                this.logger('log', 'typhead opened, close the typehead container')
                this.hideTypehead()
            }
            // todo -> open the typhead if moving back to emoji prefixed text
            return true
        })
        this.quill.keyboard.bindings[37].unshift(this.quill.keyboard.bindings[37].pop())

        this.quill.keyboard.addBinding({
            key: 39,  // ArrowRight
            collapsed: true
        }, (range, context) => {
            this.logger('log', 'Arrow right', {range, context})
            if (this.isOpen) {
                this.logger('log', 'typhead opened, close the typehead container')
                this.hideTypehead()
            }
            // todo -> open the typhead if moving back to emoji prefixed text
            return true
        })
        this.quill.keyboard.bindings[39].unshift(this.quill.keyboard.bindings[39].pop())

    }

    addOutsideClickListener() {
        this.logger('log', 'addClickListener')
        window.addEventListener('click', () => {
            if (this.isOpen) {
                this.close(null)
            }
        }, {
            once: true
        })
    }

    hideTypehead() {
        this.logger('log', 'hiding typehead')
        this.isOpen = false
        this.container.style.display = 'none'
    }

    triggerTypehead(range: RangeStatic, context: any) {
        this.logger('log', 'trigger picker ->', { range, context })
        
        if (this.isOpen) return true
        
        const triggerBounds = this.quill.getBounds(range.index - 1)

        this.triggerIndex = range.index

        let paletteMaxPos = triggerBounds.left + 250
        if (paletteMaxPos > this.quill.container.offsetWidth) {
            this.container.style.left = (triggerBounds.left - 250) + 'px'
        }
        else {
            this.container.style.left = triggerBounds.left + 'px'
        }

        this.container.style.bottom = triggerBounds.top + triggerBounds.height + 65 + 'px'
        this.isOpen = true
        this.quill.emojiTypeheadOpen = true

        this.onOpen && this.onOpen()
    }

    update(newDelte: Delta, oldDelta: Delta, source: string) {
        this.logger('log', 'update', { newDelte, oldDelta, source })

        let textUpToSelection = this.quill.getText().slice(0, this.quill.getSelection(true).index)
        if (this.triggerIndex === null) {
            this.logger('log', 'update > no trigger index set, nothing to do here', { textUpToSelection, triggerIndex: this.triggerIndex })
            return
        }
        let lastTriggerIndex = textUpToSelection.lastIndexOf(':')
        if (lastTriggerIndex === -1) {
            this.logger('log', 'update > no emoji trigger found, nothing to do here', { textUpToSelection, lastTriggerIndex })
            return
        }
        this.logger('log', 'update > emoji trigger match', { textUpToSelection, lastTriggerIndex })

        let triggerToSelection = this.quill.getText().slice(this.quill.getText().lastIndexOf(':'), this.quill.getSelection(true).index)
        let regEx = /^:[^\s\t\r]*/ // colon followed by non-space, non-return, and non-tab characters
        let isEmojiSearch = regEx.test(triggerToSelection)
        if (!isEmojiSearch) {
            this.logger('log', 'update > no emoji search, nothing to do here', { textUpToSelection, lastTriggerIndex })
            return
        }
        if (isEmojiSearch && triggerToSelection.length < 3) {
            this.logger('log', 'update > emoji search but too short for a proper search, nothing to do here', { textUpToSelection, lastTriggerIndex })
            this.hideTypehead()
            return
        }
        this.logger('log', 'update > emoji search', { isEmojiSearch, triggerToSelection })

        // search for emojis using: fuse.js
        this.query = triggerToSelection.replace(':', '').replace(/\n/g, '').trim()
        let emojis = this.fuse.search(this.query, 25)
        if (this.query.length < this.options.fuse.minMatchCharLength || emojis.length === 0) {
            this.logger('log', 'update > no matching emojis found, hiding container', { query: this.query, result: emojis })
            this.hideTypehead()
            return
        }
        this.logger('log', 'update > matching emojis found', { query: this.query, result: emojis })

        // render the emoji list (max 25)
        this.emojis = emojis.slice(0, 25).map(i => i.item)
        this.renderEmojiList()
        this.isOpen = true

    }

    renderEmojiList() {
        this.logger('log', 'renderEmojiList', this.emojis)

        // empty the list wrapper (<ul>)
        while (this.list.firstChild) {
            this.list.removeChild(this.list.firstChild)
        }

        this.listItems = []

        for (let [i, emoji] of this.emojis.entries()) {

            // generate the image element
            const imageTag = document.createElement('img')
            imageTag.className = 'ql-emoji-image'
            imageTag.src = emoji.image

            // generate the text nodes
            const texts = emoji.shortcode.split(this.query)
            const textStart = document.createElement('span')
            textStart.className = 'ql-emoji-shortcode'
            textStart.innerText = texts[0]
            const textMid = document.createElement('span')
            textMid.className = 'ql-emoji-shortcode query-match'
            textMid.innerText = this.query
            const textEnd = document.createElement('span')
            textEnd.className = 'ql-emoji-shortcode'
            textEnd.innerText = texts[1]
            const textTag = this.makeElement('div', {}, [textStart, textMid, textEnd])

            // generate the inner content
            const innerContent = this.makeElement('div', { className: 'emoji-typehead-item-inner' }, [imageTag, textTag])
            
            // generate the list item
            const li = this.makeElement('li', { className: i === 0 ? 'emoji-typehead-item active' : 'emoji-typehead-item' }, [innerContent])
            li.addEventListener('click', () => {
                this.close(emoji)
            })

            // append to list
            this.list.appendChild(li)
            this.listItems.push(li)

        }

        // set selection to the first emoji in the list
        this.currentIndex = 0
        this.lastIndex = 0

        // show the container
        this.container.style.display = 'block'
        this.addOutsideClickListener()

    }

    makeElement(tag: string, attrs: any, children: any) {
        const elem = document.createElement(tag)
        Object.keys(attrs).forEach(key => elem[key] = attrs[key])
        for (let child of children) {
            if (typeof child === 'string') {
                child = document.createTextNode(child)
            }
            elem.appendChild(child)
        }
        return elem
    }

    updateSelection() {
        this.logger('log', 'updateSelection', { last: this.lastIndex, current: this.currentIndex })
        this.listItems[this.lastIndex].classList.remove('active')
        this.listItems[this.currentIndex].classList.add('active')
        if (this.currentIndex === 0) {
            this.list.scrollTop = 0
        }
        else {
            let listHeight = this.list.clientHeight
            let listScrollHeight = this.list.scrollHeight
            let listScrollTop = this.list.scrollTop
            let itemTop = this.listItems[this.currentIndex].offsetTop
            let itemBottom = this.listItems[this.currentIndex].offsetTop + this.listItems[this.currentIndex].clientHeight
            if (itemBottom > listHeight && itemBottom > listScrollTop) {
                this.list.scrollTop = itemBottom
            }
            if (itemTop > listHeight && itemTop < listScrollTop) {
                this.list.scollTop = itemTop
            }
            if (itemTop < listScrollTop && itemTop < listHeight) {
                this.list.scrollTop = itemTop - 12
            }
        }
    }

    close(value, trailingDelete = 0) {
        this.logger('log', 'close', { value, trailingDelete, triggerIndex: this.triggerIndex, queryLength: this.query && this.query.length, query: this.query })

        // enable quill
        this.quill.enable()

        // remove all list elements
        while (this.list.firstChild) {
            this.list.removeChild(this.list.firstChild)
        }

        // handle insertion
        if (value && value === 'continue') {
            this.quill.insertText(this.triggerIndex + this.query.length + 1, ' ', Quill.sources.SILENT)
        }
        else if (value && value !== 'continue') {
            let index = JSON.parse(JSON.stringify(this.triggerIndex)) // raw copy as this gets updated otherwise
            this.quill.deleteText(index, this.query.length + 1 + trailingDelete, Quill.sources.USER)
            this.quill.insertEmbed(index, 'tolaria-emoji', value, Quill.sources.USER)
            // this.quill.insertText(index + 1, ' ', Quill.sources.USER)
            // reset the trigger index
            this.triggerIndex = null
            setTimeout(() => this.quill.setSelection(index + 1), 0)
        }

        // set focus to editor again
        this.quill.focus()

        // reset variables
        this.emojis = []
        this.listItems = []
        this.currentIndex = 0
        this.lastIndex = 0
        this.hideTypehead()

        // turn the typehead off and execute handler
        this.quill.emojiTypeheadOpen = false
        this.onClose && this.onClose(value)
    }


}

TolariaEmojiTypeheadModule.DEFAULTS = {
    emojiList: [],
    fuse: {
        shouldSort: true,
        threshold: 0.1,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
            {
                name: 'name',
                weight: 1,
            },
            {
                name: 'search',
                weight: 3,
            },
            {
                name: 'shortcode',
                weight: 2,
            },
        ]
    },
    debug: false,
}

export default TolariaEmojiTypeheadModule



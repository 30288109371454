import { ITicketMeta, ITicketCheckoutData } from './../../../tolaria-cloud-functions/src/_interfaces';
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { ToastService } from 'src/app/services/toast.service'
import { environment } from 'src/environments/environment'
import { AngularFireFunctions } from '@angular/fire/compat/functions'
import { SafeResourceUrl } from '@angular/platform-browser'
import { AuthService, GlobalsService, EventService } from 'src/app/services'
import { Injectable } from '@angular/core'
import {
  IRegistrationFeeMeta,
  ProductType,
  IPaymentMeta,
  IRegistrationCheckoutData,
  ICheckoutResponse,
  IStandardCheckoutData,
  PaymentFactor,
  IStripeRefundData,
  IStripeResponse,
  IStripeAccountCreateData,
  IStripeAccountLinksData,
  IStripeAccountLinksResponse,
  IStripePayoutData,
  IStripeGetEventCargesData,
  IStripeGetBalanceData,
  IStripeResponseBalance,
  IStripeAccountUpdateData,
  DonationType,
  IDonationCheckoutData,
  IDonationTier,
  IStripeGetChargesData,
  IStripeGetChargeResponse,
  IStripeGetSubscriptionData,
  IStripeGetSubsctiptionResponse,
  IStripeSubscriptionHandlingData,
  IDonationMeta,
  IStripeAccountDeleteData,
  PaymentType,
  IRegistrationFeePayment,
  IBalanceTemplateMeta,
  PaymentStatus,
} from 'tolaria-cloud-functions/src/_interfaces'
import * as firestore from 'firebase/firestore'
import { IPlayerDetails, IUser } from 'tolaria-cloud-functions/src/_interfaces'
import { map, take } from 'rxjs/operators'
import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs'
import { PlayerNameService } from '../services/players/player-name.service';

const stripe = Stripe(environment.stripeKey)

export interface TolariaSupportData {
  date: Date
  converted: number
  data: TolariaSupportDoc
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private donationTierSubscription$: Observable<IDonationTier[]> = this.afs.collection<IDonationTier>('donationTiers', ref => ref.orderBy('amount', 'asc')).valueChanges()
  private donationTiers$: BehaviorSubject<IDonationTier[]> = new BehaviorSubject<IDonationTier[]>(null)
  public exchangeRates$: BehaviorSubject<IExchangeRate[]> = new BehaviorSubject<IExchangeRate[]>(null)
  private paymentActive$: BehaviorSubject<'PROD' | 'TEST'> = new BehaviorSubject<'PROD' | 'TEST'>('PROD')
  public ready$ = new BehaviorSubject<boolean>(false)

  constructor(
    private globals: GlobalsService,
    private fns: AngularFireFunctions,
    private auth: AuthService,
    private toast: ToastService,
    private eventService: EventService,
    private afs: AngularFirestore,
    private readonly playerNames: PlayerNameService,
  ) {
    this.playerNames.serviceReady$.subscribe((ready) => {
      if (ready && this.ready$.getValue() === false) {
        console.log('[PaymentService] -> Calling init')
        this.init()
      }
    })
    this.donationTierSubscription$.subscribe((data: IDonationTier[]) => this.donationTiers$.next(data))
  }

  public getTolariaCosts() {
    return this.costs$
  }
  public getTolariaSupport() {
    return this.support$
  }

  private costs$: Observable<TolariaCostDoc[]>
  private support$: Observable<TolariaSupportData[]>
  private getCosts() {
    const d = new Date()
    d.setDate(1)
    d.setUTCHours(0)
    d.setUTCMinutes(0)
    d.setUTCSeconds(0)
    d.setMonth(d.getMonth() - 2)
    const fromTimestamp = Math.floor(d.getTime() / 1000)
    this.costs$ = this.afs.collection<TolariaCostDoc>('tolaria-costs', ref => ref.where('timestamp', '>=', fromTimestamp)).valueChanges()
  }
  private getSupporting() {
    const rates = this.exchangeRates$.getValue()
    const d = new Date()
    d.setDate(1)
    d.setUTCHours(0)
    d.setUTCMinutes(0)
    d.setUTCSeconds(0)
    d.setMonth(d.getMonth() - 2)
    const fromTimestamp = Math.floor(d.getTime() / 1000)
    this.support$ = this.afs
      .collection<TolariaSupportDoc>('tolaria-support', ref => ref.where('timestamp', '>=', fromTimestamp))
      .valueChanges()
      .pipe(map(docs => {
        const mapped = docs.map((i: TolariaSupportDoc) => {
          const data: TolariaSupportData = {
            data: i,
            date: new Date(i.timestamp * 1000),
            converted: i.currency.toUpperCase() === 'USD'
              ? i.amount
              : (rates['USD'] / rates[i.currency as string]) * i.amount
          }
          return data
        })
        return mapped
      }))
  }

  // STRIPE STUFF
  private deleteConnectedAccount(uid: string, accountId: string): void {
    const callable = this.fns.httpsCallable<IStripeAccountDeleteData, IStripeResponse>('stripe-AccountDelete')
    callable({ uid, accountId })
      .toPromise()
      .then((res) => {
        if (res.status) {
          this.toast.show(res.text, { classname: 'success-toast', delay: 4000 })
        }
        else {
          this.toast.show(res.text, { classname: 'error-toast', delay: 8000 })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  private deleteStripeUserData() {
    this.afs.collection<IPlayerDetails>('players', ref => ref.where('stripe-CustomerId', '!=', ''))
      .snapshotChanges()
      .pipe(take(1))
      .subscribe(async (p) => {
        if (p.length === 0) return
        for await (const doc of p) {
          const docData = doc.payload.doc.data() as IPlayerDetails
          delete docData.stripeCustomerId
          doc.payload.doc.ref.set(docData)
            .then(() => console.log(`[STRIPE] player doc ${docData.docId} updated`))
            .catch((error) => console.log(`[STRIPE] ${error}`))
        }
      })

    this.afs.collection<IUser>('users', ref => ref.where('stripe-', '!=', ''))
      .snapshotChanges()
      .pipe(take(1))
      .subscribe(async (u) => {
        if (u.length === 0) return
        for await (const doc of u) {
          const docData = doc.payload.doc.data() as IUser
          delete docData.stripe
          doc.payload.doc.ref.set(docData)
            .then(() => console.log(`[STRIPE] user doc ${docData.uid} updated`))
            .catch((error) => console.log(`[STRIPE] ${error}`))
        }
      })
  }
  private updateAccountProperty(accountId: string, property: string, value: string | boolean | number) {
    const callable = this.fns.httpsCallable<IStripeAccountUpdateData, IStripeResponse>('stripe-AccountUpdate')
    callable({ accountId, property, value }).toPromise()
      .then((res) => console.log(res))
      .catch((error) => console.log(error))
  }
  private updateCharge() {
    const callable = this.fns.httpsCallable<any, IStripeResponse>('stripe-UpdateCharge')
    callable({ chargeId: 'ch_3K3UBlG1bIlPbCGk14bsUeAW' }).toPromise()
      .then((res) => console.log(res))
      .catch((error) => console.log(error))
  }


  private async init() {
    this.fetchExchangeRates()
    const doc = await firstValueFrom(this.afs.collection('app').doc<any>('settings').get())
    if (doc.exists) {
      console.log(`[PaymentService] -> Tolaria payment is active = ${(doc.data() as any).paymentMode}`)
      this.paymentActive$.next((doc.data() as any).paymentMode)
    }
    this.exchangeRates$.subscribe(i => {
      if (i !== null) {
        this.getCosts()
        this.getSupporting()
        this.ready$.next(true)
      }
    })
  }
  private get paymentInactive(): boolean {
    return this.paymentActive$.getValue() === 'TEST' && !window.location.host.includes('localhost') || this.paymentActive$.getValue() === 'TEST' && !window.location.host.includes('tolaria-mtg--pr')
  }
  private get currentBrowserUrl(): string {
    const protocol = window.location.protocol
    const host = window.location.host
    const port = window.location.port
    const path = window.location.pathname

    if (host === 'localhost') {
      return `${protocol}//${host}:${port}${path}`
    }
    else {
      return `${protocol}//${host}${path}`
    }
  }
  private async fetchExchangeRates() {
    const snap = await firstValueFrom(this.afs.collection('app').doc('exchangeRates').get())
    if (!snap.exists) {
      // handle issue here
    }
    const data = snap.data() as any
    this.exchangeRates$.next(data.rates)
    console.log('[PaymentService] -> Exchange Rates loaded -> ', data.rates)
  }
  public connectedDashboardLogin(): Promise<boolean> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise((resolve, reject) => {
      const callable = this.fns.httpsCallable<IStripeAccountLinksData, IStripeResponse>('stripe-CreateLoginLink')
      callable({ uid: this.auth.user.uid, accountId: this.auth.user.stripe.id, currentUrl: this.currentBrowserUrl })
        .toPromise()
        .then((res) => {
          if (res.status) {
            this.toast.show(res.text, { classname: 'success-toast', delay: 4000 })
            window.open(res.data.url, '_blank')
            resolve(true)
          }
          else {
            this.toast.show(res.text, { classname: 'error-toast', delay: 8000 })
            reject(true)
          }
        })
        .catch((error) => {
          console.log(error)
          this.toast.show(error, { classname: 'error-toast', delay: 8000 })
          reject(false)
        })
    })
  }
  public createConnectedAccount(): Promise<IStripeResponse> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise((resolve, reject) => {
      const callable = this.fns.httpsCallable<IStripeAccountCreateData, IStripeResponse>('stripe-AccountCreate')
      callable({ uid: this.auth.user.uid, accountType: 'express' }).toPromise()
        .then((res) => {
          if (res.status) {
            this.toast.show(res.text, { classname: 'success-toast', delay: 4000 })
            resolve(res)
          }
          else {
            this.toast.show(res.text, { classname: 'error-toast', delay: 8000 })
            reject(res)
          }
        })
        .catch((error) => {
          this.toast.show(error, { classname: 'error-toast', delay: 8000 })
          console.log(error)
          reject({
            status: false,
            text: error
          })
        })
    })
  }
  public getAccountLinks(): Promise<IStripeAccountLinksResponse> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise((resolve, reject) => {
      const callable = this.fns.httpsCallable<IStripeAccountLinksData, IStripeAccountLinksResponse>('stripe-AccountLinks')
      callable({ uid: this.auth.user.uid, accountId: this.auth.user.stripe.id, currentUrl: this.currentBrowserUrl })
        .toPromise()
        .then((res) => {
          if (res.status) {
            this.toast.show(res.text, { classname: 'success-toast', delay: 4000 })
            resolve(res)
          }
          else {
            this.toast.show(res.text, { classname: 'error-toast', delay: 8000 })
            reject(res)
          }
        })
        .catch((error) => {
          this.toast.show(error, { classname: 'error-toast', delay: 8000 })
          console.log(error)
          reject({
            status: false,
            text: error
          })
        })
    })
  }
  public getLatestCharge(chargeId: string, limit: number, checkoutSessionId: string): Promise<any> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise(async (resolve) => {

      const callable = this.fns.httpsCallable<IStripeGetChargesData, IStripeGetChargeResponse>('stripe-GetCharges')

      callable({ playerDocId: this.auth.user.playerId, limit, chargeId, checkoutSessionId }).toPromise()
        // callable({ playerDocId: this.auth.user.playerId, limit: 1 }).toPromise()
        .then(async (response) => {
          if (response.charges.length > 0) {
            resolve(response.charges[0])
          }
          else {
            resolve(null)
          }
        })
        .catch((error) => {
          console.log(error)
          resolve(null)
        })

    })
  }
  public tolCharges$ = new BehaviorSubject<any>([])
  public tolLatestChargeId = null
  public getTolariaCharges() {
    const callable = this.fns.httpsCallable('stripe-GetTolariaCharges')
    let data
    if (this.tolLatestChargeId !== null) {
      data = { limit: 100, starting_after: this.tolLatestChargeId }
    }
    else {
      data = { limit: 100 }
    }
    callable(data).pipe(take(1)).subscribe(data => {
      const charges = this.tolCharges$.getValue()
      this.tolCharges$.next([...charges, ...data.charges])
      this.tolLatestChargeId = data.charges[data.charges.length - 1].id
      console.log('[TolariaCharges] -> charges:', this.tolCharges$.getValue())
    })
  }
  public tolPaymentIntents$ = new BehaviorSubject<any>([])
  public tolLatestPaymentIntentsId = null
  public tolPaymentIntentsWorking$ = new BehaviorSubject<boolean>(false)
  public getTolariaPaymentIntents() {
    this.tolPaymentIntentsWorking$.next(true)
    const callable = this.fns.httpsCallable('stripe-GetTolariaPaymentIntents')
    let data
    if (this.tolLatestPaymentIntentsId !== null) {
      data = { limit: 100, starting_after: this.tolLatestPaymentIntentsId }
    }
    else {
      data = { limit: 100 }
    }
    callable(data).pipe(take(1)).subscribe(async (data) => {
      const charges = this.tolPaymentIntents$.getValue()
      this.tolPaymentIntents$.next([...charges, ...data.charges])
      this.tolLatestPaymentIntentsId = data.charges[data.charges.length - 1].id
      console.log('[TolariaPaymentIntents] -> payment intents:', this.tolPaymentIntents$.getValue())
      const intentsWithDonation = data.charges.filter(i => i.metadata.hasOwnProperty('donationAmount') && i.metadata.donationAmount !== '0' && i.metadata.donationAmount !== undefined)
      const invoiceWithDonation = data.charges.filter(i => i.invoice !== null && i.description.includes('Subscription'))
      const intentsOfDonations = data.charges.filter(i => i.metadata.hasOwnProperty('productType') && i.metadata.productType === 'DONATION')
      const docs = []
      console.log(`[TolariaPaymentIntents] -> found ${intentsWithDonation.length} intents with donations:`, intentsWithDonation)
      for (const data of intentsWithDonation) {
        docs.push({
          amount: parseInt(data.metadata.donationAmount),
          currency: data.currency.toUpperCase(),
          id: data.id,
          playerId: data.metadata.playerDocId,
          timestamp: data.created,
          type: 'ONE_TIME',
        })
      }
      console.log(`[TolariaPaymentIntents] -> found ${intentsOfDonations.length} intents of donations:`, intentsOfDonations)
      for (const data of intentsOfDonations) {
        docs.push({
          amount: data.amount,
          currency: data.currency.toUpperCase(),
          id: data.id,
          playerId: data.metadata.playerDocId,
          timestamp: data.created,
          type: 'ONE_TIME',
        })
      }
      console.log(`[TolariaPaymentIntents] -> found ${invoiceWithDonation.length} support subscription intents:`, invoiceWithDonation)
      for await (const data of invoiceWithDonation) {
        console.log(`[TolariaPaymentIntents] -> fetching player document for customer ${data.customer}`)
        const snap = await firstValueFrom(this.afs.collection<IPlayerDetails>('players', ref => ref.where('stripeCustomerId', '==', data.customer)).get())
        if (!snap.empty) {
          const doc = snap.docs.map(i => i.data())[0]
          console.log(`[TolariaPaymentIntents] -> player document found:`, doc)
          const playerId = doc.docId
          docs.push({
            amount: data.amount,
            currency: data.currency.toUpperCase(),
            id: data.id,
            playerId: playerId,
            timestamp: data.created,
            type: 'SUBSCRIPTION',
          })
        }
      }
      const batch = this.afs.firestore.batch()
      console.log(`[TolariaPaymentIntents] -> creating batch write for ${docs.length} support documents`, docs)
      for (const doc of docs) {
        batch.set(this.afs.collection('tolaria-support').doc(doc.id).ref, doc)
      }
      console.log('[TolariaPaymentIntents] -> commit batch writes')
      await batch.commit()
        .then(() => console.log(`[TolariaPaymentIntents] -> wrote ${docs.length} supporter documents`, docs))
        .catch((error) => console.error(error))
      this.tolPaymentIntentsWorking$.next(false)
    })
  }
  public getCharges(limit: number = null): Promise<IPurchaseMeta[]> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise(async (resolve) => {

      const callable = this.fns.httpsCallable<IStripeGetChargesData, IStripeGetChargeResponse>('stripe-GetCharges')

      callable({ playerDocId: this.auth.user.playerId, limit }).toPromise()
        .then(async (response) => {
          if (response.charges.length > 0) {

            const charges: IPurchaseMeta[] = []

            for await (const charge of response.charges) {
              let productText: string
              const productType: ProductType = charge.metadata.productType
                ? charge.metadata.productType as ProductType
                : charge.description !== null
                  ? charge.description.includes('Subscription')
                    ? ProductType.DONATION
                    : ProductType.UNKNOWN
                  : ProductType.UNKNOWN

              switch (productType) {

                case ProductType.REGISTRATION_FEE:
                  productText = await this.eventService.getEventNameByIdPromise(charge.metadata.eventDocId)
                  break

                case ProductType.DONATION:
                  productText = 'Platform Support'
                  break

                default:
                  productText = charge.description === null
                    ? 'Unknown'
                    : charge?.description.includes('Subscription')
                      ? 'Platform Support'
                      : charge.description
                  break

              }


              const chargeData: IPurchaseMeta = {
                date: charge.created,
                captured: charge.captured,
                capturedAmount: charge.amount_captured,
                amount: (charge.amount_captured / PaymentFactor).toFixed(2),
                amountRefunded: (charge.amount_refunded / PaymentFactor).toFixed(2),
                currency: charge.currency.toUpperCase(),
                productType,
                productText,
                refunded: charge.refunded,
                partialRefund: charge.amount_captured !== charge.amount_refunded && charge.amount_refunded > 0,
                refundedAmount: charge.amount_refunded,
                receipt: charge.receipt_url,
                hasReceipt: charge.receipt_url !== '',
              }

              charges.push(chargeData)

            }

            resolve(charges)

          }
          else {
            resolve([])
          }
        })
        .catch((error) => {
          console.log(error)
          resolve([])
        })

    })
  }
  public getSubscriptions(): Promise<any> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise(async (resolve) => {
      const playerDoc = this.playerNames.currentUserPlayerDoc$.getValue()

      const callable = this.fns.httpsCallable<IStripeGetSubscriptionData, IStripeGetSubsctiptionResponse>('stripe-GetSubscriptions')
      callable({ playerDocId: this.auth.user.playerId, playerUid: this.auth.user.uid, stripeCustomerId: playerDoc.stripeCustomerId }).toPromise()
        .then(async (response) => {
          if (response.status) {
            const subs: ISubscriptionMeta[] = []
            for await (const sub of response.subscriptions as any) {
              // [TYPE ERROR] -> seems the typings are missing some properties from stripe and therefore the use of type ANY
              subs.push({
                id: sub.id,
                periodStart: sub.current_period_start,
                periodEnd: sub.current_period_end,
                tierName: sub.metadata.tier_name,
                tierAmount: (sub.plan.amount / PaymentFactor).toFixed(2),
                tierCurrency: sub.plan.currency.toUpperCase(),
                tierInterval: sub.plan.interval,
                tierIntervalCount: sub.plan.interval_count,
                isPaused: sub.pause_collection !== null,
                pausedUntil: sub.pause_collection !== null ? sub.pause_collection.resumes_at : null,
                status: sub.status.charAt(0).toUpperCase() + sub.status.slice(1),
                isActive: sub.status === 'active',
                startDate: sub.start_date,
              })
            }
            resolve(subs)
          }
          else {
            resolve([])
          }
        })
        .catch((error) => {
          console.log(error)
          resolve([])
        })

    })

  }
  public cancelSubscription(id: string): Promise<boolean> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise(async (resolve, reject) => {
      const callable = this.fns.httpsCallable<IStripeSubscriptionHandlingData, IStripeGetSubsctiptionResponse>('stripe-CancelSubscription')
      callable({ subscriptionId: id }).toPromise()
        .then(async (response) => {
          this.toast.show(response.text, { classname: response.status ? 'success-toast' : 'error-toast', delay: response.status ? 2000 : 8000 })
          resolve(response.status)
        })
        .catch((error) => {
          console.log(error)
          this.toast.show(error, { classname: 'error-toast', delay: 8000 })
          reject()
        })

    })
  }
  public pauseSubscription(id: string, pauseUntil: number): Promise<boolean> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise(async (resolve, reject) => {
      const callable = this.fns.httpsCallable<IStripeSubscriptionHandlingData, IStripeGetSubsctiptionResponse>('stripe-UpdateSubscription')
      callable({ subscriptionId: id, pauseUntil, pausBehavior: 'void' }).toPromise()
        .then(async (response) => {
          this.toast.show(response.text, { classname: response.status ? 'success-toast' : 'error-toast', delay: response.status ? 2000 : 8000 })
          resolve(response.status)
        })
        .catch((error) => {
          console.log(error)
          this.toast.show(error, { classname: 'error-toast', delay: 8000 })
          reject()
        })

    })
  }
  public createPayout(data: IStripePayoutData): Promise<IStripeResponse> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise((resolve, reject) => {
      // update amount
      data.amount = data.amount * PaymentFactor

      // perform call
      const callable = this.fns.httpsCallable<IStripePayoutData, IStripeResponse>('stripe-PayoutCreate')
      callable(data)
        .toPromise()
        .then((res) => {
          if (res.status) {
            this.toast.show(res.text, { classname: 'success-toast', delay: 4000 })
            resolve(res)
          }
          else {
            this.toast.show(res.text, { classname: 'error-toast', delay: 8000 })
            reject(res)
          }
        })
        .catch((error) => {
          this.toast.show(error, { classname: 'error-toast', delay: 8000 })
          console.log(error)
          reject({
            status: false,
            text: error
          })
        })
    })
  }
  public getEventCharges(eventDocId: string): Promise<IEventPaymentMeta[]> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise(async (resolve) => {

      // call backend to get data from stripe
      const callable = this.fns.httpsCallable<IStripeGetEventCargesData, IStripeResponse>('stripe-GetEventCharges')

      callable({ eventDocId }).toPromise()
        .then(async (response) => {

          if (!response.status) { this.toast.show(response.text, { classname: 'error-toast', delay: 8000 }) }

          const charges: IEventPaymentMeta[] = []

          if (response.status && response.data.length > 0) {

            // console.log(`[stripe] ---> getEventCharges -> Raw Data`, response.data)

            for await (const charge of response.data.filter((c: any) => c.paid)) {

              const meta: IRegistrationFeeMeta = {
                productType: charge.metadata.productType,
                eventDocId: charge.metadata.eventDocId,
                playerDocId: charge.metadata.playerDocId,
                playerUid: charge.metadata.playerUid,
                playerEmail: charge.metadata.playerEmail,
                currency: charge.metadata.currency,
                registrationFeeAmount: parseInt(charge.metadata.registrationFeeAmount) || 0,
                donationAmount: parseInt(charge.metadata.donationAmount) || 0,
                charityAmount: parseInt(charge.metadata.charityAmount) || 0,
                platformCostAmount: parseInt(charge.metadata.platformCostAmount) || 0,
                platformFee: parseFloat(charge.metadata.platformFee) || 0,
                eventCreatedByUid: charge.metadata.eventCreatedByUid,
              }
              charges.push(this.createEventPaymentMeta(charge, meta, PaymentType.TOLARIA))

            }


          }


          // get event data
          const event = await this.eventService.getEventByIdPromise(eventDocId)
          const paidBy = event.details.registrationFee.paidBy
          for await (const key of Object.keys(paidBy)) {
            if (paidBy[key].paymentType !== PaymentType.TOLARIA) {
              const charge = paidBy[key] as IRegistrationFeePayment
              const meta: IRegistrationFeeMeta = {
                productType: ProductType.REGISTRATION_FEE,
                eventDocId: eventDocId,
                playerDocId: key,
                playerUid: null,
                playerEmail: null,
                currency: event.details.registrationFee.currency,
                registrationFeeAmount: charge.amount,
                donationAmount: 0,
                charityAmount: charge.charityAmount,
                platformCostAmount: 0,
                platformFee: 0,
                eventCreatedByUid: event.createdByUid,
              }
              charges.push(this.createEventPaymentMeta(charge, meta, paidBy[key].paymentType))
            }
          }

          console.log('PaymentService:: got charges for tournament', charges)
          resolve(charges)
        })
        .catch((error) => {
          console.log(error)
          resolve([])
        })

    })
  }
  private createEventPaymentMeta(charge: any | IRegistrationFeePayment, meta: IRegistrationFeeMeta, paymentType: PaymentType): IEventPaymentMeta {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }

    let responseData: IEventPaymentMeta

    switch (paymentType) {

      case PaymentType.TOLARIA:

        const isRefunded = charge.refunded
        const isPartiallyRefunded = charge.amount_refunded > 0 && charge.amount_captured !== charge.amount_refunded

        responseData = {
          tolariaPay: true,
          paymentType,
          date: charge.created,
          productType: meta && meta.productType ? meta.productType : ProductType.UNKNOWN,
          player: {
            docId: meta.playerDocId,
            uid: meta.playerUid
          },
          isRefunded,
          isPartiallyRefunded,
          hasDonation: meta.donationAmount > 0 || meta.charityAmount > 0,
          amount: {
            total: charge.amount / PaymentFactor,
            fee: meta.platformCostAmount / PaymentFactor,
            newFee: isRefunded || isPartiallyRefunded
              ? (meta.charityAmount * meta.platformFee) / PaymentFactor
              : meta.platformCostAmount / PaymentFactor,
            transferred: charge.transfer_data.amount / PaymentFactor,
            newTransferred: isRefunded || isPartiallyRefunded
              ? (meta.charityAmount * (1 - meta.platformFee)) / PaymentFactor
              : charge.transfer_data.amount / PaymentFactor,
            registrationFee: (meta.registrationFeeAmount) / PaymentFactor,
            registrationFeeCost: (meta.registrationFeeAmount * meta.platformFee) / PaymentFactor,
            registrationFeeTransfer: (meta.registrationFeeAmount * (1 - meta.platformFee)) / PaymentFactor,
            charity: meta.charityAmount / PaymentFactor,
            charityCost: (meta.charityAmount * meta.platformFee) / PaymentFactor,
            charityTransfer: (meta.charityAmount * (1 - meta.platformFee)) / PaymentFactor,
            donation: meta.donationAmount / PaymentFactor,
            charityAndDonation: (meta.charityAmount / PaymentFactor) + (meta.donationAmount / PaymentFactor),
            refunded: charge.amount_refunded / PaymentFactor,
            net: isRefunded || isPartiallyRefunded
              ? (meta.charityAmount * (1 - meta.platformFee)) / PaymentFactor
              : ((meta.registrationFeeAmount + meta.charityAmount) * (1 - meta.platformFee)) / PaymentFactor,
          },
          amountString: {
            total: (charge.amount / PaymentFactor).toFixed(2),
            fee: (meta.platformCostAmount / PaymentFactor).toFixed(2),
            newFee: isRefunded || isPartiallyRefunded
              ? ((meta.charityAmount * meta.platformFee) / PaymentFactor).toFixed(2)
              : (meta.platformCostAmount / PaymentFactor).toFixed(2),
            transferred: (charge.transfer_data.amount / PaymentFactor).toFixed(2),
            newTransferred: isRefunded || isPartiallyRefunded
              ? ((meta.charityAmount * (1 - meta.platformFee)) / PaymentFactor).toFixed(2)
              : (charge.transfer_data.amount / PaymentFactor).toFixed(2),
            registrationFee: ((meta.registrationFeeAmount) / PaymentFactor).toFixed(2),
            registrationFeeCost: ((meta.registrationFeeAmount * meta.platformFee) / PaymentFactor).toFixed(2),
            registrationFeeTransfer: ((meta.registrationFeeAmount * (1 - meta.platformFee)) / PaymentFactor).toFixed(2),
            charity: (meta.charityAmount / PaymentFactor).toFixed(2),
            charityCost: ((meta.charityAmount / PaymentFactor) * meta.platformFee).toFixed(2),
            charityTransfer: ((meta.charityAmount * (1 - meta.platformFee)) / PaymentFactor).toFixed(2),
            donation: (meta.donationAmount / PaymentFactor).toFixed(2),
            charityAndDonation: ((meta.charityAmount / PaymentFactor) + (meta.donationAmount / PaymentFactor)).toFixed(2),
            refunded: (charge.amount_refunded / PaymentFactor).toFixed(2),
            net: isRefunded || isPartiallyRefunded
              ? ((meta.charityAmount * (1 - meta.platformFee)) / PaymentFactor).toFixed(2)
              : (((meta.registrationFeeAmount + meta.charityAmount) * (1 - meta.platformFee)) / PaymentFactor).toFixed(2),
          },
          registrationFee: meta.registrationFeeAmount / PaymentFactor,
          currency: charge.currency.toUpperCase(),
        }
        break

      default:
        const paidBy = charge as IRegistrationFeePayment
        responseData = {
          tolariaPay: false,
          paymentType,
          date: paidBy.timestamp,
          productType: meta && meta.productType ? meta.productType : ProductType.UNKNOWN,
          player: {
            docId: meta.playerDocId,
            uid: meta.playerUid
          },
          isRefunded: paidBy.status === PaymentStatus.REFUNDED,
          isPartiallyRefunded: paidBy.status === PaymentStatus.PARTIALLY_REFUNDED,
          hasDonation: paidBy.charityAmount > 0,
          amount: {
            total: paidBy.amount / PaymentFactor,
            fee: 0,
            newFee: 0,
            transferred: 0,
            newTransferred: 0,
            registrationFee: (meta.registrationFeeAmount) / PaymentFactor,
            registrationFeeCost: 0,
            registrationFeeTransfer: 0,
            charity: paidBy.charityAmount / PaymentFactor,
            charityCost: 0,
            charityTransfer: 0,
            donation: 0,
            charityAndDonation: paidBy.charityAmount / PaymentFactor,
            refunded: paidBy.refunded ? paidBy.amount / PaymentFactor : 0,
            net: (paidBy.amount + paidBy.charityAmount) / PaymentFactor,
          },
          amountString: {
            total: (paidBy.amount / PaymentFactor).toFixed(2),
            fee: (0).toFixed(2),
            newFee: (0).toFixed(2),
            transferred: (0).toFixed(2),
            newTransferred: (0).toFixed(2),
            registrationFee: ((meta.registrationFeeAmount) / PaymentFactor).toFixed(2),
            registrationFeeCost: (0).toFixed(2),
            registrationFeeTransfer: (0).toFixed(2),
            charity: (paidBy.charityAmount / PaymentFactor).toFixed(2),
            charityCost: (0).toFixed(2),
            charityTransfer: (0).toFixed(2),
            donation: (0).toFixed(2),
            charityAndDonation: (paidBy.charityAmount / PaymentFactor).toFixed(2),
            refunded: (paidBy.refunded ? paidBy.amount / PaymentFactor : 0).toFixed(2),
            net: ((paidBy.amount + paidBy.charityAmount) / PaymentFactor).toFixed(2),
          },
          registrationFee: meta.registrationFeeAmount / PaymentFactor,
          currency: paidBy.currency,
        }

    }

    return responseData

  }
  public issueRefund(data: IStripeRefundData): Promise<IStripeResponse> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise(async (resolve) => {

      // set app to busy
      this.globals.isBusy.status = true
      this.globals.isBusy.message = 'Issuing refund, please wait'
      this.globals.isBusy.showMessage = true

      // init the callable
      const callable = this.fns.httpsCallable<IStripeRefundData, IStripeResponse>('stripe-IssueRefund')

      // call the cloud function to create the checkout session
      callable(data).toPromise()
        .then((response) => {
          console.log(response)
          this.globals.isBusy.status = false
          resolve(response)
        })
        .catch((response) => {
          console.log(response)
          this.globals.isBusy.status = false
          resolve(response)
        })
    })

  }
  public createCheckoutSession = {

    standard: (
      currency: string,
      unit_amount: number,
      productName: string,
      paymentMeta: any = null
    ) => {

      if (this.paymentInactive) {
        this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
        return
      }

      // set app to busy
      this.globals.isBusy.status = true
      this.globals.isBusy.message = 'Creating Checkout Session, please wait'
      this.globals.isBusy.showMessage = true

      // init the callable
      const callable = this.fns.httpsCallable<IStandardCheckoutData, ICheckoutResponse>('stripe-CreateCheckout')

      // create request data
      const checkoutData: IStandardCheckoutData = {
        productType: null,
        metadata: paymentMeta,
        playerDocId: this.auth.user.playerId,
        line_items: [
          {
            price_data: {
              currency,
              product_data: {
                name: productName,
              },
              unit_amount: unit_amount * PaymentFactor,
            },
            quantity: 1,
          },
        ],
        success_url: this.currentBrowserUrl,
        cancel_url: this.currentBrowserUrl,
      }

      console.log(checkoutData)

      // call the cloud function to create the checkout session
      callable(checkoutData).toPromise()
        .then((response) => {
          stripe.redirectToCheckout({
            sessionId: response.session_id
          })
        })
        .catch((response) => {
          console.log(response)
          this.globals.isBusy.status = false
          this.toast.show(response.text, { classname: 'error-toast', delay: 8000 })
        })
    },
    REGISTRATION_FEE: (
      currency: string,
      unit_amount: number,
      productName: string,
      paymentMeta: IRegistrationFeeMeta,
      donation_amount: number,
      charity_amount: number,
    ) => {

      if (this.paymentInactive) {
        this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
        return
      }

      // init the callable
      const callable = this.fns.httpsCallable<IRegistrationCheckoutData, ICheckoutResponse>('stripe-CreateCheckout')

      const checkoutData: IRegistrationCheckoutData = {
        productType: ProductType.REGISTRATION_FEE,
        metadata: paymentMeta,
        playerDocId: this.auth.user.playerId,
        line_items: [],
        success_url: this.currentBrowserUrl,
        cancel_url: this.currentBrowserUrl,
      }

      // add the registration fee product
      checkoutData.line_items.push({
        price_data: {
          currency,
          product_data: {
            name: productName,
          },
          unit_amount: unit_amount * PaymentFactor,
        },
        quantity: 1,
      })

      // check if charity has been added
      if (charity_amount > 0) {
        checkoutData.line_items.push({
          price_data: {
            currency,
            product_data: {
              name: 'Extra - Charity (non-refundable)',
            },
            unit_amount: charity_amount * PaymentFactor,
          },
          quantity: 1,
        })
      }

      // check if donation has been added
      if (donation_amount > 0) {
        checkoutData.line_items.push({
          price_data: {
            currency,
            product_data: {
              name: 'Extra - Platform Support (non-refundable)',
            },
            unit_amount: donation_amount * PaymentFactor,
          },
          quantity: 1,
        })
      }

      console.log(checkoutData)

      // call the cloud function to create the checkout session
      callable(checkoutData).toPromise()
        .then((response) => {
          if (response.status) stripe.redirectToCheckout({ sessionId: response.session_id })
          else this.toast.show(response.text, { classname: 'error-toast', delay: 8000 })
        })
        .catch((response) => {
          console.log(response)
          this.globals.isBusy.status = false
          this.toast.show(response.text, { classname: 'error-toast', delay: 8000 })
        })
    },
    DONATION: (
      currency: string,
      unit_amount: number,
      productName: string,
      metadata: IPaymentMeta | IDonationMeta,
      donationType: DonationType,
    ): Promise<boolean> => {

      if (this.paymentInactive) {
        this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
        return
      }


      return new Promise(async (resolve, reject) => {

        // init the callable
        const callable = this.fns.httpsCallable<IDonationCheckoutData, ICheckoutResponse>('stripe-CreateCheckout')

        const checkoutData: IDonationCheckoutData = {
          donationType,
          productType: ProductType.DONATION,
          metadata: metadata,
          playerDocId: this.auth.user.playerId,
          line_items: [],
          success_url: this.currentBrowserUrl,
          cancel_url: this.currentBrowserUrl,
        }

        switch (donationType) {
          case DonationType.ONE_TIME:
            checkoutData.line_items.push(
              {
                price_data: {
                  currency,
                  product_data: {
                    name: productName,
                  },
                  unit_amount: unit_amount * PaymentFactor,
                },
                quantity: 1,
              }
            )
            break
          case DonationType.SUBSCRIPTION:
            checkoutData.line_items.push(
              {
                price_data: {
                  currency,
                  product_data: {
                    name: productName,
                    metadata: metadata as any,
                  },
                  unit_amount: unit_amount * PaymentFactor,
                  recurring: {
                    interval: 'month',
                    interval_count: 1,
                  }
                },
                quantity: 1,
              }
            )
            break
        }

        console.log(checkoutData)

        // call the cloud function to create the checkout session
        callable(checkoutData).toPromise()
          .then((response) => {
            if (!response.status) this.toast.show(response.text, { classname: 'error-toast', delay: 8000 })
            stripe.redirectToCheckout({
              sessionId: response.session_id
            })
            resolve(response.status)
          })
          .catch((response) => {
            console.log(response)
            this.globals.isBusy.status = false
            this.toast.show(response.text, { classname: 'error-toast', delay: 8000 })
            resolve(false)
          })

      })
    },
    /**
     * Create a checkout session for a ticket
     *
     * @param unit_amount - the total amount of the ticket
     * @param donation_amount - the total amount to be donated
     * @param currency - the currency to be used (creators default currency)
     * @param productName - the product name to be show as the item in the checkout
     * @param paymentMeta - the metadata to attach to the payment object
     */
    TICKET: (
      unit_amount: number,
      donation_amount: number,
      currency: string,
      productName: string,
      paymentMeta: ITicketMeta,
    ) => {
      console.log({
        unit_amount,
        donation_amount,
        currency,
        productName,
        paymentMeta,
      })

      if (this.paymentInactive) {
        this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
        return
      }

      // init the callable
      const callable = this.fns.httpsCallable<ITicketCheckoutData, ICheckoutResponse>('stripe-CreateCheckout')

      const checkoutData: ITicketCheckoutData = {
        productType: ProductType.TICKET,
        metadata: paymentMeta,
        playerDocId: this.auth.user.playerId,
        line_items: [],
        success_url: this.currentBrowserUrl,
        cancel_url: this.currentBrowserUrl,
      }

      // add the ticket product
      checkoutData.line_items.push({
        price_data: {
          currency,
          product_data: {
            name: productName,
          },
          unit_amount: unit_amount * PaymentFactor,
        },
        quantity: 1,
      })

      // check if donation has been added
      if (donation_amount > 0) {
        checkoutData.line_items.push({
          price_data: {
            currency,
            product_data: {
              name: 'Extra - Platform Support (non-refundable)',
            },
            unit_amount: donation_amount * PaymentFactor,
          },
          quantity: 1,
        })
      }

      console.log(checkoutData)

      // call the cloud function to create the checkout session
      callable(checkoutData).toPromise()
        .then((response) => {
          if (response.status) stripe.redirectToCheckout({ sessionId: response.session_id })
          else this.toast.show(response.text, { classname: 'error-toast', delay: 8000 })
        })
        .catch((response) => {
          console.log(response)
          this.globals.isBusy.status = false
          this.toast.show(response.text, { classname: 'error-toast', delay: 8000 })
        })
    },

  }
  public getStripeAccountIdByPlayerUid(playerUid: string): Promise<string> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise(async (resolve, reject) => {

      const snap = await this.afs.collection('users').doc<IUser>(playerUid).get().toPromise()
      if (snap.exists) {
        const stripeAccountId = snap.data()?.stripe?.id
        if (stripeAccountId) {
          resolve(stripeAccountId)
        }
        else {
          reject(`user document with id ${playerUid} does not contain a stripe account id`)
        }
      }
      else {
        reject(`user document not found for user id ${playerUid}`)
      }

    })

  }
  public getBalance(stripeConnectedAccountId: string = null): Promise<IBalanceTemplateMeta> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise(async (resolve, reject) => {

      // init the callable
      const callable = this.fns.httpsCallable<IStripeGetBalanceData, IStripeResponseBalance>('stripe-GetBalance')

      // call the cloud function to create the checkout session
      callable({ stripeAccount: stripeConnectedAccountId ? stripeConnectedAccountId : this.auth.user.stripe.id }).toPromise()
        .then((response) => {
          this.globals.isBusy.status = false
          if (response.status) {
            resolve(response.balance)
          }
          else {
            reject(false)
          }
        })
        .catch((error) => {
          console.log(error)
          this.globals.isBusy.status = false
          reject(false)
        })

    })
  }
  public getDonationTiers(): BehaviorSubject<IDonationTier[]> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return this.donationTiers$
  }
  public getEventOrganizerDefaultCurrency(organizerUid: string): Promise<string> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise((resolve) => {
      this.afs.collection('users').doc(organizerUid).get().subscribe(doc => {
        if (doc.exists) {

          const userDoc = doc.data() as IUser
          if (userDoc.stripe && userDoc.stripe.default_currency) {

            resolve(userDoc.stripe.default_currency)

          }

        }

        resolve(null)
      })
    })
  }

  // MANUAL STUFF
  public manualPayment(productType: ProductType, metadata: any): Promise<any> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise(async (resolve) => {

      switch (productType) {

        case ProductType.REGISTRATION_FEE:

          // save payment
          await this.afs.collection('events').doc(metadata.eventDocId)
            .update({
              [`details.registrationFee.paidBy.${metadata.playerDocId}`]: metadata.payment,
              log: firestore.arrayUnion({
                timestamp: firestore.Timestamp.now().seconds,
                text: `Registration fee paid by ${metadata.playerName} with method ${metadata.payment.paymentType} [MANUAL HANDLING]`,
              }),
            })
            .then(() => {
              setTimeout(() => {
                this.toast.show(`Payment for ${metadata.playerName} registered successfully`, { classname: 'success-toast', delay: 4000 })
              }, 1000)
              // resolve
              resolve({
                status: true,
                text: `Payment for ${metadata.playerName} registered successfully`
              })
            })
            .catch((err) => {
              console.log(err)
              setTimeout(() => {
                this.toast.show(`Something went wrong, please try again. ${err}`, { classname: 'error-toast', delay: 8000 })
              }, 1000)
              // resolve
              resolve({
                status: false,
                text: err
              })
            })

          break

      }

    })
  }
  public manualRefund(productType: ProductType, paymentStatus: PaymentStatus, refundAmount: number, metadata: any): Promise<any> {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    return new Promise(async (resolve) => {

      switch (productType) {

        case ProductType.REGISTRATION_FEE:

          // save payment
          await this.afs.collection('events').doc(metadata.eventDocId)
            .update({
              [`details.registrationFee.paidBy.${metadata.playerDocId}.paid`]: false,
              [`details.registrationFee.paidBy.${metadata.playerDocId}.refunded`]: true,
              [`details.registrationFee.paidBy.${metadata.playerDocId}.status`]: paymentStatus,
              [`details.registrationFee.paidBy.${metadata.playerDocId}.amountRefunded`]: refundAmount,
              [`details.registrationFee.paidBy.${metadata.playerDocId}.timestamp`]: firestore.Timestamp.now().seconds,
              log: firestore.arrayUnion({
                timestamp: firestore.Timestamp.now().seconds,
                text: `Registration fee refunded to ${metadata.playerName} (originally paid with method ${metadata.paymentType}) [MANUAL HANDLING]`,
              }),
            })
            .then(() => {
              setTimeout(() => {
                this.toast.show(`Payment for ${metadata.playerName} refunded successfully`, { classname: 'success-toast', delay: 4000 })
              }, 1000)
              // resolve
              resolve({
                status: true,
                text: `Payment for ${metadata.playerName} refunded successfully`
              })
            })
            .catch((err) => {
              console.log(err)
              setTimeout(() => {
                this.toast.show(`Something went wrong, please try again. ${err}`, { classname: 'error-toast', delay: 8000 })
              }, 1000)
              // resolve
              resolve({
                status: false,
                text: err
              })
            })

          break

      }

    })
  }
  public getErrorMessage(text: string): string {
    if (this.paymentInactive) {
      this.toast.show(`Tolaria payment is disabled at the moment, please try again later`, { classname: 'error-toast', delay: 8000 })
      return
    }
    switch (text) {
      case 'balance_insufficient':
        return 'Insufficient funds on main account (tolaria.app), please contact site administrator'

      default:
        return text
    }
  }
  public getCurrencySymbol(currency: string): string {
    const symbology = [
      { "name": "Afghan Afghani", "code": "AFA", "symbol": "؋" },
      { "name": "Albanian Lek", "code": "ALL", "symbol": "Lek" },
      { "name": "Algerian Dinar", "code": "DZD", "symbol": "دج" },
      { "name": "Angolan Kwanza", "code": "AOA", "symbol": "Kz" },
      { "name": "Argentine Peso", "code": "ARS", "symbol": "$" },
      { "name": "Armenian Dram", "code": "AMD", "symbol": "֏" },
      { "name": "Aruban Florin", "code": "AWG", "symbol": "ƒ" },
      { "name": "Australian Dollar", "code": "AUD", "symbol": "$" },
      { "name": "Azerbaijani Manat", "code": "AZN", "symbol": "m" },
      { "name": "Bahamian Dollar", "code": "BSD", "symbol": "B$" },
      { "name": "Bahraini Dinar", "code": "BHD", "symbol": ".د.ب" },
      { "name": "Bangladeshi Taka", "code": "BDT", "symbol": "৳" },
      { "name": "Barbadian Dollar", "code": "BBD", "symbol": "Bds$" },
      { "name": "Belarusian Ruble", "code": "BYR", "symbol": "Br" },
      { "name": "Belgian Franc", "code": "BEF", "symbol": "fr" },
      { "name": "Belize Dollar", "code": "BZD", "symbol": "$" },
      { "name": "Bermudan Dollar", "code": "BMD", "symbol": "$" },
      { "name": "Bhutanese Ngultrum", "code": "BTN", "symbol": "Nu." },
      { "name": "Bitcoin", "code": "BTC", "symbol": "฿" },
      { "name": "Bolivian Boliviano", "code": "BOB", "symbol": "Bs." },
      { "name": "Bosnia-Herzegovina Convertible Mark", "code": "BAM", "symbol": "KM" },
      { "name": "Botswanan Pula", "code": "BWP", "symbol": "P" },
      { "name": "Brazilian Real", "code": "BRL", "symbol": "R$" },
      { "name": "British Pound Sterling", "code": "GBP", "symbol": "£" },
      { "name": "Brunei Dollar", "code": "BND", "symbol": "B$" },
      { "name": "Bulgarian Lev", "code": "BGN", "symbol": "Лв." },
      { "name": "Burundian Franc", "code": "BIF", "symbol": "FBu" },
      { "name": "Cambodian Riel", "code": "KHR", "symbol": "KHR" },
      { "name": "Canadian Dollar", "code": "CAD", "symbol": "$" },
      { "name": "Cape Verdean Escudo", "code": "CVE", "symbol": "$" },
      { "name": "Cayman Islands Dollar", "code": "KYD", "symbol": "$" },
      { "name": "CFA Franc BCEAO", "code": "XOF", "symbol": "CFA" },
      { "name": "CFA Franc BEAC", "code": "XAF", "symbol": "FCFA" },
      { "name": "CFP Franc", "code": "XPF", "symbol": "₣" },
      { "name": "Chilean Peso", "code": "CLP", "symbol": "$" },
      { "name": "Chinese Yuan", "code": "CNY", "symbol": "¥" },
      { "name": "Colombian Peso", "code": "COP", "symbol": "$" },
      { "name": "Comorian Franc", "code": "KMF", "symbol": "CF" },
      { "name": "Congolese Franc", "code": "CDF", "symbol": "FC" },
      { "name": "Costa Rican ColÃ³n", "code": "CRC", "symbol": "₡" },
      { "name": "Croatian Kuna", "code": "HRK", "symbol": "kn" },
      { "name": "Cuban Convertible Peso", "code": "CUC", "symbol": "$, CUC" },
      { "name": "Czech Republic Koruna", "code": "CZK", "symbol": "Kč" },
      { "name": "Danish Krone", "code": "DKK", "symbol": "Kr." },
      { "name": "Djiboutian Franc", "code": "DJF", "symbol": "Fdj" },
      { "name": "Dominican Peso", "code": "DOP", "symbol": "$" },
      { "name": "East Caribbean Dollar", "code": "XCD", "symbol": "$" },
      { "name": "Egyptian Pound", "code": "EGP", "symbol": "ج.م" },
      { "name": "Eritrean Nakfa", "code": "ERN", "symbol": "Nfk" },
      { "name": "Estonian Kroon", "code": "EEK", "symbol": "kr" },
      { "name": "Ethiopian Birr", "code": "ETB", "symbol": "Nkf" },
      { "name": "Euro", "code": "EUR", "symbol": "€" },
      { "name": "Falkland Islands Pound", "code": "FKP", "symbol": "£" },
      { "name": "Fijian Dollar", "code": "FJD", "symbol": "FJ$" },
      { "name": "Gambian Dalasi", "code": "GMD", "symbol": "D" },
      { "name": "Georgian Lari", "code": "GEL", "symbol": "ლ" },
      { "name": "German Mark", "code": "DEM", "symbol": "DM" },
      { "name": "Ghanaian Cedi", "code": "GHS", "symbol": "GH₵" },
      { "name": "Gibraltar Pound", "code": "GIP", "symbol": "£" },
      { "name": "Greek Drachma", "code": "GRD", "symbol": "₯, Δρχ, Δρ" },
      { "name": "Guatemalan Quetzal", "code": "GTQ", "symbol": "Q" },
      { "name": "Guinean Franc", "code": "GNF", "symbol": "FG" },
      { "name": "Guyanaese Dollar", "code": "GYD", "symbol": "$" },
      { "name": "Haitian Gourde", "code": "HTG", "symbol": "G" },
      { "name": "Honduran Lempira", "code": "HNL", "symbol": "L" },
      { "name": "Hong Kong Dollar", "code": "HKD", "symbol": "$" },
      { "name": "Hungarian Forint", "code": "HUF", "symbol": "Ft" },
      { "name": "Icelandic KrÃ³na", "code": "ISK", "symbol": "kr" },
      { "name": "Indian Rupee", "code": "INR", "symbol": "₹" },
      { "name": "Indonesian Rupiah", "code": "IDR", "symbol": "Rp" },
      { "name": "Iranian Rial", "code": "IRR", "symbol": "﷼" },
      { "name": "Iraqi Dinar", "code": "IQD", "symbol": "د.ع" },
      { "name": "Israeli New Sheqel", "code": "ILS", "symbol": "₪" },
      { "name": "Italian Lira", "code": "ITL", "symbol": "L,£" },
      { "name": "Jamaican Dollar", "code": "JMD", "symbol": "J$" },
      { "name": "Japanese Yen", "code": "JPY", "symbol": "¥" },
      { "name": "Jordanian Dinar", "code": "JOD", "symbol": "ا.د" },
      { "name": "Kazakhstani Tenge", "code": "KZT", "symbol": "лв" },
      { "name": "Kenyan Shilling", "code": "KES", "symbol": "KSh" },
      { "name": "Kuwaiti Dinar", "code": "KWD", "symbol": "ك.د" },
      { "name": "Kyrgystani Som", "code": "KGS", "symbol": "лв" },
      { "name": "Laotian Kip", "code": "LAK", "symbol": "₭" },
      { "name": "Latvian Lats", "code": "LVL", "symbol": "Ls" },
      { "name": "Lebanese Pound", "code": "LBP", "symbol": "£" },
      { "name": "Lesotho Loti", "code": "LSL", "symbol": "L" },
      { "name": "Liberian Dollar", "code": "LRD", "symbol": "$" },
      { "name": "Libyan Dinar", "code": "LYD", "symbol": "د.ل" },
      { "name": "Lithuanian Litas", "code": "LTL", "symbol": "Lt" },
      { "name": "Macanese Pataca", "code": "MOP", "symbol": "$" },
      { "name": "Macedonian Denar", "code": "MKD", "symbol": "ден" },
      { "name": "Malagasy Ariary", "code": "MGA", "symbol": "Ar" },
      { "name": "Malawian Kwacha", "code": "MWK", "symbol": "MK" },
      { "name": "Malaysian Ringgit", "code": "MYR", "symbol": "RM" },
      { "name": "Maldivian Rufiyaa", "code": "MVR", "symbol": "Rf" },
      { "name": "Mauritanian Ouguiya", "code": "MRO", "symbol": "MRU" },
      { "name": "Mauritian Rupee", "code": "MUR", "symbol": "₨" },
      { "name": "Mexican Peso", "code": "MXN", "symbol": "$" },
      { "name": "Moldovan Leu", "code": "MDL", "symbol": "L" },
      { "name": "Mongolian Tugrik", "code": "MNT", "symbol": "₮" },
      { "name": "Moroccan Dirham", "code": "MAD", "symbol": "MAD" },
      { "name": "Mozambican Metical", "code": "MZM", "symbol": "MT" },
      { "name": "Myanmar Kyat", "code": "MMK", "symbol": "K" },
      { "name": "Namibian Dollar", "code": "NAD", "symbol": "$" },
      { "name": "Nepalese Rupee", "code": "NPR", "symbol": "₨" },
      { "name": "Netherlands Antillean Guilder", "code": "ANG", "symbol": "ƒ" },
      { "name": "New Taiwan Dollar", "code": "TWD", "symbol": "$" },
      { "name": "New Zealand Dollar", "code": "NZD", "symbol": "$" },
      { "name": "Nicaraguan CÃ³rdoba", "code": "NIO", "symbol": "C$" },
      { "name": "Nigerian Naira", "code": "NGN", "symbol": "₦" },
      { "name": "North Korean Won", "code": "KPW", "symbol": "₩" },
      { "name": "Norwegian Krone", "code": "NOK", "symbol": "kr" },
      { "name": "Omani Rial", "code": "OMR", "symbol": ".ع.ر" },
      { "name": "Pakistani Rupee", "code": "PKR", "symbol": "₨" },
      { "name": "Panamanian Balboa", "code": "PAB", "symbol": "B/." },
      { "name": "Papua New Guinean Kina", "code": "PGK", "symbol": "K" },
      { "name": "Paraguayan Guarani", "code": "PYG", "symbol": "₲" },
      { "name": "Peruvian Nuevo Sol", "code": "PEN", "symbol": "S/." },
      { "name": "Philippine Peso", "code": "PHP", "symbol": "₱" },
      { "name": "Polish Zloty", "code": "PLN", "symbol": "zł" },
      { "name": "Qatari Rial", "code": "QAR", "symbol": "ق.ر" },
      { "name": "Romanian Leu", "code": "RON", "symbol": "lei" },
      { "name": "Russian Ruble", "code": "RUB", "symbol": "₽" },
      { "name": "Rwandan Franc", "code": "RWF", "symbol": "FRw" },
      { "name": "Salvadoran ColÃ³n", "code": "SVC", "symbol": "₡" },
      { "name": "Samoan Tala", "code": "WST", "symbol": "SAT" },
      { "name": "Saudi Riyal", "code": "SAR", "symbol": "﷼" },
      { "name": "Serbian Dinar", "code": "RSD", "symbol": "din" },
      { "name": "Seychellois Rupee", "code": "SCR", "symbol": "SRe" },
      { "name": "Sierra Leonean Leone", "code": "SLL", "symbol": "Le" },
      { "name": "Singapore Dollar", "code": "SGD", "symbol": "$" },
      { "name": "Slovak Koruna", "code": "SKK", "symbol": "Sk" },
      { "name": "Solomon Islands Dollar", "code": "SBD", "symbol": "Si$" },
      { "name": "Somali Shilling", "code": "SOS", "symbol": "Sh.so." },
      { "name": "South African Rand", "code": "ZAR", "symbol": "R" },
      { "name": "South Korean Won", "code": "KRW", "symbol": "₩" },
      { "name": "Special Drawing Rights", "code": "XDR", "symbol": "SDR" },
      { "name": "Sri Lankan Rupee", "code": "LKR", "symbol": "Rs" },
      { "name": "St. Helena Pound", "code": "SHP", "symbol": "£" },
      { "name": "Sudanese Pound", "code": "SDG", "symbol": ".س.ج" },
      { "name": "Surinamese Dollar", "code": "SRD", "symbol": "$" },
      { "name": "Swazi Lilangeni", "code": "SZL", "symbol": "E" },
      { "name": "Swedish Krona", "code": "SEK", "symbol": "kr" },
      { "name": "Swiss Franc", "code": "CHF", "symbol": "CHf" },
      { "name": "Syrian Pound", "code": "SYP", "symbol": "LS" },
      { "name": "São Tomé and Príncipe Dobra", "code": "STD", "symbol": "Db" },
      { "name": "Tajikistani Somoni", "code": "TJS", "symbol": "SM" },
      { "name": "Tanzanian Shilling", "code": "TZS", "symbol": "TSh" },
      { "name": "Thai Baht", "code": "THB", "symbol": "฿" },
      { "name": "Tongan Pa'anga", "code": "TOP", "symbol": "$" },
      { "name": "Trinidad & Tobago Dollar", "code": "TTD", "symbol": "$" },
      { "name": "Tunisian Dinar", "code": "TND", "symbol": "ت.د" },
      { "name": "Turkish Lira", "code": "TRY", "symbol": "₺" },
      { "name": "Turkmenistani Manat", "code": "TMT", "symbol": "T" },
      { "name": "Ugandan Shilling", "code": "UGX", "symbol": "USh" },
      { "name": "Ukrainian Hryvnia", "code": "UAH", "symbol": "₴" },
      { "name": "United Arab Emirates Dirham", "code": "AED", "symbol": "إ.د" },
      { "name": "Uruguayan Peso", "code": "UYU", "symbol": "$" },
      { "name": "US Dollar", "code": "USD", "symbol": "$" },
      { "name": "Uzbekistan Som", "code": "UZS", "symbol": "лв" },
      { "name": "Vanuatu Vatu", "code": "VUV", "symbol": "VT" },
      { "name": "Venezuelan BolÃvar", "code": "VEF", "symbol": "Bs" },
      { "name": "Vietnamese Dong", "code": "VND", "symbol": "₫" },
      { "name": "Yemeni Rial", "code": "YER", "symbol": "﷼" },
      { "name": "Zambian Kwacha", "code": "ZMK", "symbol": "ZK" }
    ]

    const search = symbology.find(i => i.code.toLowerCase() === currency.toLowerCase())
    if (search) {
      return search.symbol
    }
    else {
      return currency
    }
  }

}

export interface TolariaCostDoc {
  id: string
  description: string
  amount: number
  currency: string
  type: 'HOSTING' | 'DOMAIN' | 'WebRTC' | 'OTHER'
  timestamp: number
}
export interface TolariaSupportDoc {
  amount: number
  currency: string
  id: string
  playerId: string
  timestamp: number
  type: 'ONE_TIME' | 'SUBSCRIPTION'
}

export interface TolariaSupport {
  data: TolariaSupportDoc
  date: Date
  converted: number
}

export interface IPurchaseMeta {
  date: number
  amount: string
  amountRefunded: string
  currency: string
  captured: boolean
  productType: ProductType
  refunded: boolean
  partialRefund: boolean
  refundedAmount: number
  capturedAmount: number
  receipt: SafeResourceUrl
  hasReceipt: boolean
  productText: string
  costs?: IFees
  playerDocId?: string
}

export interface ISubscriptionMeta {
  id: string
  periodStart: number
  periodEnd: number
  tierName: string
  tierAmount: string
  tierCurrency: string
  tierInterval: string
  tierIntervalCount: number
  isPaused: boolean
  pausedUntil: number
  status: string
  isActive: boolean
  startDate: number
}
export interface IEventPaymentMeta {
  date: number
  productType: ProductType
  tolariaPay: boolean
  player: {
    docId: string
    uid: string
  }
  isRefunded: boolean
  isPartiallyRefunded: boolean
  hasDonation: boolean
  amount: {
    total: number
    registrationFee: number
    registrationFeeCost: number
    registrationFeeTransfer: number
    charity: number
    charityCost: number
    charityTransfer: number
    refunded: number
    fee: number
    newFee: number
    donation: number
    charityAndDonation: number
    net: number
    transferred: number
    newTransferred: number
  }
  amountString: {
    total: string
    registrationFee: string
    registrationFeeCost: string
    registrationFeeTransfer: string
    charity: string
    charityCost: string
    charityTransfer: string
    refunded: string
    fee: string
    newFee: string
    donation: string
    charityAndDonation: string
    net: string
    transferred: string
    newTransferred: string
  }
  registrationFee: number
  currency: string
  paymentType: PaymentType
}

interface IFees {
  registrationFee: number
  cardCountry: string
  fee: string
  cost_fixed: number
  cost_fee: number
  cost_total: number
}

export interface IExchangeRate {
  [key: string]: number
}

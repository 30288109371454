import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-busy-indicator',
  template: `
    <div class="d-flex flex-column p-4 align-items-center">
      <div class="m-3 spinner-border" role="status" style="width: 3rem; height: 3rem;"></div>
      <div class="text-large">{{ text.title }}</div>
      <div class="text-normal" [innerHTML]="text.message"></div>
    </div>
  `,
  styles: [
  ]
})
export class BusyIndicatorComponent {
  @Input() set title(val: string) {
    this.text.title = val
  }
  @Input() set message(val: string) {
    this.text.message = val
  }

  public text = {
    title: '',
    message: '',
  }
  constructor() { }
}

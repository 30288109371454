import { Pipe, PipeTransform } from '@angular/core';
import { IBooster } from 'tolaria-cloud-functions/src/_interfaces';

@Pipe({
  name: 'draftWaitingBoosters'
})
export class DraftWaitingBoostersPipe implements PipeTransform {

  transform(boosters: IBooster[], playerDocId: string, activePack: string): string[] {

    console.log({
      boosters,
      playerDocId,
      activePack
    });

    const res: string[] = [];

    boosters
      .filter(b => b.pack === activePack)
      .filter(b => b.activePlayerDocId === playerDocId || b.nextPlayerDocId === playerDocId)
      .sort((a, b) => a.latestPick - b.latestPick)
      .forEach((b) => {
        if (b.activePlayerDocId) {
          res.push('active');
        }
        else {
          res.push('inactive');
        }
      });

    console.log(res);

    return res;

  }

}

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const setIconXSPC: IconDefinition = {
  prefix: 'fac',
  iconName: 'xspx',
  icon: [
    500, // SVG view box width
    480, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M25.3,280.2H0C3.2,124.8,113.9,0,250,0s246.8,124.8,250,280.2h-24.8C471.9,140.8,372.5,29,250.2,29S28.6,140.8,25.3,280.2z M250,68.9c103,0,186.8,94,190,211.3h25c-3.2-133.2-98.2-240-215-240S38.2,147,35,280.2h25C63.2,162.9,147,68.9,250,68.9z M95.1,280.2h-25C73.2,391.2,152.6,480,250,480s176.8-88.8,179.9-199.8h-25c-3.2,95.1-71.3,171.1-154.9,171.1S98.2,375.3,95.1,280.2 z M130.1,280.2h-25c3.1,88.8,66.8,159.6,144.9,159.6S391.8,369,394.9,280.2h-25c-3.1,72.9-55.6,130.9-119.9,130.9 S133.2,353.1,130.1,280.2z M250,189.5c44.9,0,81.7,40,84.8,90.7h25.1c-3.1-66.6-51.1-119.4-109.9-119.4s-106.8,52.9-109.9,119.4 h25.1C168.3,229.5,205.1,189.5,250,189.5z M250,229.7c25.6,0,46.7,22.1,49.6,50.5h25.1c-3.1-44.3-35.4-79.2-74.8-79.2 c-39.4,0-71.7,34.9-74.8,79.2h25.1C203.3,251.7,224.4,229.7,250,229.7z M250.2,319.2c20.1,0,36.7-16.9,39.6-39h-79.1 C213.6,302.3,230.2,319.2,250.2,319.2z'
  ],
} as any;

<app-public-header [showAbout]="true"></app-public-header>
<section style="min-height: 100%; height: auto;">
  <div class="container">
    <div class="d-flex align-items-center justify-content-center {{ isMobile ? 'p-0 w-100' : '' }}"
      [style.marginTop.vh]="topMargin">

      <div class="d-flex flex-row align-items-center justify-content-center flex-column login-form shadow-lg">


        <div class="sign-in-form card o-hover border border-0"
          [ngClass]="{ 'card-rounded-lg rounded rounded-4' : !isMobile, 'pt-5' : isMobile }">

          <div class="card-body top d-flex flex-column form-signin gap-3 no-hover">

            <div class="logo-wrapper align-self-center" routerLink="/public/about">
              <fa-icon [icon]="appIcon" [style.fontSize.rem]="8"></fa-icon>
            </div>

            <div class="h3 text-center">Create an account</div>
            <p class="text-center"></p>

            <div *ngIf="authMessage.success !== null">
              <div class="alert {{ authMessage.success ? 'alert-success' : 'alert-danger' }}" role="alert">
                {{ authMessage.message }}
              </div>
            </div>


            <div class="form-floating mb-2">
              <input type="email" class="form-control {{ emailValid ? 'is-valid' : 'is-invalid' }}" id="floatingInput"
                placeholder="name@example.com" [(ngModel)]="registrationData.email">
              <label for="floatingInput">Email address</label>
            </div>

            <div class="form-floating mb-2">
              <input type="password" class="form-control {{ passwordValid ? 'is-valid' : 'is-invalid' }}"
                id="floatingInput" placeholder="Password" [(ngModel)]="registrationData.password">
              <label for="floatingInput">Password</label>
              <div class="invalid-feedback">Password needs to be at least 6 characters long.</div>
            </div>

            <div class="form-floating mb-2">
              <input type="password" class="form-control {{ passwordMatch ? 'is-valid' : 'is-invalid' }}"
                id="floatingInput" placeholder="Confirm password" [(ngModel)]="registrationData.confirmPassword">
              <label for="floatingInput">Confirm password</label>
              <div class="invalid-feedback">Passwords mismatch</div>
            </div>

            <button type="submit" class="btn btn-success mt-3 mb-2" (click)="emailSignUp()" [disabled]="!formValid">
              Sign Up
            </button>

            <div class="btn btn-blank btn-sm w-100" [routerLink]="['/auth', 'sign-in']">Back to Sign In</div>

          </div>
>
        </div>

      </div>

    </div>
  </div>
</section>
<app-public-footer></app-public-footer>
import { AddNewTeamComponent } from './../add-new-team/add-new-team.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IEventDetails, IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, Input } from '@angular/core';
import { IEventTeamItem } from '../organizer.component';
import { faEllipsisH, faEdit } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event/event.service';

@Component({
  selector: 'app-team-list-item',
  templateUrl: './team-list-item.component.html',
  styleUrls: ['./team-list-item.component.css']
})
export class TeamListItemComponent {
  @Input() event: IEventDetails
  @Input() team: IEventTeamItem

  public faEllipsisH = faEllipsisH
  public editIcon = faEdit

  constructor(
    private es: EventService,
    private auth: AuthService,
    private modalService: NgbModal,
  ) { }


  public unattend(): void {
    this.es.unattendTeam(this.event.docId, this.team)
  }

  public drop(): void {
  }

  public undrop(): void {
  }

  public editTeam(team: IEventTeamItem) {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    }

    // close all open modals before opening a new one
    this.modalService.dismissAll()
    const modal = this.modalService.open(AddNewTeamComponent, modalOptions)
    modal.componentInstance.teamData = team
    modal.componentInstance.event = this.event
    modal.componentInstance.isModal = true

    modal.result
      .then(
        () => {
          console.log('closed')
        },
        () => {
          console.log('dismissed')
        },
      )
      .catch((error) => console.log(error))
  }

  public get eventStarted(): boolean {
    return this.event.statusCode > 0
  }

  public get teamPlayers(): IEventPlayerDetails[] {
    if (this.team === undefined || this.team === null) {
      return []
    }

    const players: IEventPlayerDetails[] = []
    for (const [seat, playerDocId] of Object.entries(this.team.player))  {
      if (playerDocId !== null) {
        const player = this.team.players.find(i => i.playerDocId === playerDocId)
        player.teamSeat = seat as 'a' | 'b' | 'c'
        players.push(player)
      }
    }
    return players.sort((a, b) => a.teamSeat.localeCompare(b.teamSeat))
  }

}

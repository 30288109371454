import { FontIconsService } from 'src/app/services/font-icons.service';
import { draftIcon } from 'src/assets/font-icons/draft-icon';
import { DialogsService } from 'src/app/services/dialogs.service';
import { AuthService } from 'src/app/services';
import { DraftService, DraftStatusChange } from 'src/app/services/draft.service';
import { Observable } from 'rxjs';
import { DraftSetupComponent } from './draft-setup/draft-setup.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Component } from '@angular/core';
import { faCalendarAlt, faClock, faHatWizard, faEllipsisH, faUsers, faFilter } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { IDraft } from 'tolaria-cloud-functions/src/_interfaces';
@Component({
  selector: 'app-drafts',
  templateUrl: './drafts.component.html',
  styleUrls: ['./drafts.component.css']
})
export class DraftsComponent {


  draftIcon = draftIcon;
  faHatWizard = faHatWizard;
  faClock = faClock;
  faCalendarAlt = faCalendarAlt;
  faEllipsisH = faEllipsisH;
  faUsers = faUsers;
  faFilter = faFilter;
  faCheckSquare = faCheckSquare;
  faSquare = faSquare;

  public drafts$: Observable<IDraft[]>;

  constructor(
    private modalService: NgbModal,
    public draftService: DraftService,
    public auth: AuthService,
    private dialogs: DialogsService,
    public fontIcons: FontIconsService
  ) {
    this.drafts$ = this.draftService.getDrafts();
  }

  public tracker(index: number, item: IDraft) {
    return `${item.docId}-${index}`;
  }

  public get isOrganizer() {
    return this.auth.user.role === 'admin' || this.auth.user.role === 'organizer';
  }
  public get isAdmin() {
    return this.auth.user.role === 'admin';
  }

  public newDraft(): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    };

    this.modalService.open(DraftSetupComponent, modalOptions);
  }

  public attendDraft(draftDocId: string): void {
    this.draftService.attendDraft(draftDocId);
  }

  public unattendDraft(draftDocId: string): void {
    this.draftService.unattendDraft(draftDocId);
  }

  public deleteDraft(draftDocId: string): void {
    this.dialogs.openDialog(
      'Delete Draft?',
      `You are about to delete the draft, are you sure?`,
      'ConfirmCancel'
    )
      .then((dialogResponse: any) => {
        if (dialogResponse.pressed !== 'cancel') {
          this.draftService.deleteDraftDocument(draftDocId);
        }
      });
  }

  public editDraft(draftDocId: string): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'xl',
    };

    const modalRef = this.modalService.open(DraftSetupComponent, modalOptions);
    modalRef.componentInstance.draftDocId = draftDocId;

  }

  public seatPlayers(draft: IDraft): void {
    this.dialogs.openDialog(
      'Seat Players?',
      `You are about to seat players, this will inform all users active in Tolaria to open the draft room. To open the first pack and start drafting you need to be in the draft room.`,
      'ConfirmCancel'
    )
      .then((dialogResponse: any) => {
        if (dialogResponse.pressed !== 'cancel') {
          this.draftService.updateDraftStatus(draft.docId, draft.statusCode, DraftStatusChange.NEXT);
        }
      });

  }

  public openDraftRoom(draft: IDraft): void {
    let typeParam = '';
    if (draft.isRotisserie) {
      typeParam = 'rotisserie-draft';
    }
    else if (draft.isBooster) {
      typeParam = 'booster-draft';
    }
    else if (draft.isRochester) {
      typeParam = 'rochester-draft';
    }

    window.open('draft-room/' + typeParam + '/' + draft.docId, 'Draft Room', 'height=600,width=800,toolbar=no');
  }

}

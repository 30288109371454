<ng-container *ngIf="init$ | async">

  <div class="outer-wrapper d-flex flex-row align-items-center">


    <div class="d-flex flex-column team-match-wrapper" [ngClass]="{ 'path-to-victory' : pathToHereSelected }">


      <ng-container *ngIf="hasTeamOne; else waitingForTeam1">
        <ng-container *ngIf="teamOne as team">
          <div class="match-row d-flex flex-row align-items-center" [ngClass]="{ 'path-to-here' : pathToHerePlayerSlot === 'teamOne' }">
            <div class="seed p-2">{{ team.seed }}</div>
            <div class="name p-2 px-3 flex-grow-1">{{ team.name }}</div>
            <!-- <div class="players pe-2 d-flex flex-row">
              <app-player-avatar size="small" class="me-1" [playerDocId]="team.player.a"></app-player-avatar>
              <app-player-avatar size="small" class="me-1" [playerDocId]="team.player.b"></app-player-avatar>
              <app-player-avatar size="small" class="me-1" [playerDocId]="team.player.c"></app-player-avatar>
            </div> -->
          </div>
        </ng-container>
      </ng-container>


      <ng-container *ngIf="hasTeamTwo; else waitingForTeam2">
        <ng-container *ngIf="teamTwo as team">
          <div class="match-row d-flex flex-row align-items-center" [ngClass]="{ 'path-to-here' : pathToHerePlayerSlot === 'teamTwo' }">
            <div class="seed p-2">{{ team.seed }}</div>
            <div class="name p-2 px-3 flex-grow-1">{{ team.name }}</div>
            <!-- <div class="players pe-2 d-flex flex-row">
              <app-player-avatar size="small" class="me-1" [playerDocId]="team.player.a"></app-player-avatar>
              <app-player-avatar size="small" class="me-1" [playerDocId]="team.player.b"></app-player-avatar>
              <app-player-avatar size="small" class="me-1" [playerDocId]="team.player.c"></app-player-avatar>
            </div> -->
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="matches$ | async as matches">
        <div class="matches-wrapper" >
          <ng-container *ngFor="let match of matches; trackBy: matchTracker">
            <!-- [ngClass]="{ 'reported' : match.isReported }" -->
            <div class="match-wrapper text-uppercase" (click)="openReportSlip(match.teamSeat)">
              <div class="winner text-warning mb-1" [ngClass]="{ 'show' : match.player1.isWinner }"><fa-icon [icon]="star"></fa-icon></div>
              <div class="seat" [innerHtml]="match.teamSeat"></div>
              <div class="winner text-warning mt-1" [ngClass]="{ 'show' : match.player2.isWinner }"><fa-icon [icon]="star"></fa-icon></div>
            </div>
          </ng-container>
        </div>
      </ng-container>


      <ng-template #waitingForTeam1>
        <div class="match-row d-flex flex-row align-items-center" [ngClass]="{ 'path-to-here' : pathToHerePlayerSlot === 'teamOne' }">
          <div class="seed p-2"></div>
          <div class="name p-2 px-3 flex-grow-1 text-italic text-secondary">Waiting for team to finish...</div>
          <div class="players pe-2 d-flex flex-row"></div>
        </div>
      </ng-template>
      <ng-template #waitingForTeam2>
        <div class="match-row d-flex flex-row align-items-center" [ngClass]="{ 'path-to-here' : pathToHerePlayerSlot === 'teamTwo' }">
          <div class="seed p-2"></div>
          <div class="name p-2 px-3 flex-grow-1 text-italic text-secondary">Waiting for team to finish...</div>
          <div class="players pe-2 d-flex flex-row"></div>
        </div>
      </ng-template>




    </div>

    <div class="actions ms-3 me-5" *ngIf="isOrganizer && !matchInfo.byeMatch">
      <div ngbDropdown class="d-inline-block" container="body">
        <button class="btn btn-sm btn-blank" id="actionMenu" ngbDropdownToggle>
          <fa-icon [fixedWidth]="true" [icon]="menu"></fa-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="actionMenu">
          <button ngbDropdownItem (click)="openReportSlip('a')">Report Seat A</button>
          <button ngbDropdownItem (click)="openReportSlip('b')">Report Seat B</button>
          <button ngbDropdownItem (click)="openReportSlip('c')">Report Seat C</button>
        </div>
      </div>
    </div>

  </div>

</ng-container>

<div class="message-wrapper d-flex gap-3 p-2 pe-3 m-3 border border-secondary rounded-2 {{ mobileDevice }}">

    <div class="prefix d-flex align-items-start justify-content-end flex-shrink-0" [style.minWidth.rem]="2.5">
        <fa-icon [fixedWidth]="true" class="text-xxlarge" [icon]="icon.calendar"></fa-icon>
    </div>

    <div class="d-flex flex-column flex-grow-1">

        <div class="d-flex align-items-center gap-3">
            
            <div class="text-large text-bold">
                Match Appointment Proposal
            </div>
            
            <app-timestamp-clock class="message-timestamp text-secondary"
                [timestamp]="msg.message.timestamp"
                displayType="time"
                [seconds]="true">
            </app-timestamp-clock>

        </div>

        <div class="message-content py-2">

            <ng-container *ngIf="matchAppointment$ | async as appointment; else loader">

                <div class="w-100 d-flex flex-row flex-wrap column-gap-5 row-gap-3">

                    <div class="flex-grow-1 d-flex flex-column">

                        <div class="event-name text-secondary text-large flex-grow-1" [innerText]="appointment.eventname"></div>

                        <div class="opponent text-secondary text-small mt-2">Opponent</div>

                        <div class="opponent-name text-normal d-flex align-items-center">
                            <app-player-display-name class="flex-grow-1"
                                [playerDocId]="appointment.opponentDocId === userPlayerId ? appointment.playerDocId : appointment.opponentDocId"
                                position="left" class="flex-grow-1">
                            </app-player-display-name>
                            <div class="btn btn-blank"
                                ngbTooltip="To the match room"
                                container="body"
                                (click)="openMatchRoom(appointment)" *ngIf="!mobile">
                                <fa-icon [icon]="icon.link" [fixedWidth]="true"></fa-icon>
                            </div>
                        </div>

                    </div>

                    <div class="d-flex flex-column">

                        <div class="wrapper text-large">
                            <app-timestamp-clock displayType="date"
                                [timestamp]="appointment.timestampFrom"
                                [seconds]="true">
                            </app-timestamp-clock>
                            <span class="text-secondary mx-2">@</span>
                            <app-timestamp-clock displayType="time"
                                [timestamp]="appointment.timestampFrom"
                                [seconds]="true">
                            </app-timestamp-clock>
                            <span class="text-secondary mx-1">-</span>
                            <app-timestamp-clock displayType="time"
                                [timestamp]="appointment.timestampTo"
                                [seconds]="true">
                            </app-timestamp-clock>
                        </div>

                        <div class="status flex-grow-1 d-flex align-items-center gap-2">
                            <div class="text-secondary">Status:</div>
                            <div class="ms-2">
                                <span class="text-normal" 
                                    *ngIf="appointment.opponentHasRead && !appointment.isCancelled && !appointment.isAccepted && !appointment.isRejected">
                                    Read
                                </span>
                                <span class="text-orange" *ngIf="!appointment.opponentHasRead && !appointment.isCancelled">
                                    Unread
                                </span>
                                <span class="text-red" *ngIf="appointment.isCancelled">
                                    Cancelled
                                </span>
                                <span class="text-green"
                                    *ngIf="appointment.isAccepted && !appointment.isCancelled">
                                    Accepted
                                </span>
                                <span class="text-red"
                                    *ngIf="appointment.isRejected && !appointment.isCancelled">
                                    Rejected
                                </span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center mt-3 gap-3">
                            <button class="btn btn-sm btn-success text-start flex-grow-1"
                                [disabled]="appointment.isCancelled || appointment.isAccepted"
                                (click)="updateAppointmentStatus('accept')">
                                <fa-icon [fixedWidth]="true" [icon]="icon.check" class="me-2"></fa-icon>Accept
                            </button>

                            <button class="btn btn-sm btn-danger text-start flex-grow-1"
                                [disabled]="appointment.isCancelled || appointment.isRejected"
                                (click)="updateAppointmentStatus('reject')">
                                <fa-icon [fixedWidth]="true" [icon]="icon.decline" class="me-2"></fa-icon>Reject
                            </button>
                        </div>

                    </div>

                </div>

            </ng-container>

            <ng-template #loader>
                <div class="w-100 d-flex align-items-center">
                    <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </ng-template>

        </div>
    </div>
</div>
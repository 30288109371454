<div class="modal-header d-flex align-items-center">
  <span class="flex-grow-1" [innerHtml]="card.name"></span>
  <span class="card-cmc flex-grow-0 ms-2"
    [innerHTML]="card.manaCost | manaParser : { shadowed: true, fontClass: 'text-smaller' }"></span>
</div>
<div class="modal-body d-flex p-0">

  <div class="m-3 card-wrapper border-right {{ card.keyruneCode.toUpperCase() }}">
    <img [src]="card.imageUrl" [style.width.rem]="15">
  </div>

  <div class="p-3">

    <div class="card-type text-bold mb-2">{{ card.type }}</div>

    <div class="card-text mb-1" [innerHTML]="card.text | manaParser : { fontClass: 'text-small', shadowed: true }">
    </div>

    <div class="carc-power-toughness mb-1" *ngIf="card.power !== ''">{{ card.power }}/{{
      card.toughness }}</div>

  </div>

</div>

import { StorageService } from 'src/app/services/storage.service';
import { EventService, MessagesService, MessageType } from 'src/app/services';
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, Input } from '@angular/core';

export interface IPlayersToMention {
  playerDocId: string;
  playerAtText: string;
}
@Component({
  selector: 'app-deck-submission-config',
  templateUrl: './deck-submission-config.component.html',
  styleUrls: ['./deck-submission-config.component.css']
})
export class DeckSubmissionConfigComponent {
  @Input() event: IEventDetails;

  constructor(
    private es: EventService,
    private ms: MessagesService,
    private storageService: StorageService
  ) { }

  updateEventDeckInfo(event: IEventDetails) {
    this.es.updateEventData(event);
  }

  downloadDeckLists() {
    this.storageService.downloadZippedDeckListsFromEvent(this.event).then(res => {
      console.log(res);
    });
  }

  downloadCardsByPlayerList() {
    this.storageService.downloadCardsByPlayerList(this.event);
  }

  downloadDeckPhotos() {
    const parsedEventName = this.event.details.datetime
      .substring(0, 9)
      .replace(/-/g, '') + '_' +
      this.event.details.name
        .replace(/ /g, '-')
        .toLowerCase();
    this.es.downloadDeckPhotos(this.event.docId, parsedEventName);
  }

  downloadEventInformation() {
    this.es.getEventPlayersByEventIdPromise(this.event.docId).then((eventPlayers) => {
      this.storageService.downloadEventInformation(this.event, eventPlayers);
    });
  }

  sendReminderMessage() {
    const deckList = this.event.details.deckList;
    const deckPhoto = this.event.details.deckPhoto;

    this.es.getPlayersWithoutDeckSubmitted(this.event.docId, deckList, deckPhoto).then(res => {
      // start composing the message
      let messageContent = '<h1>Deck Submission Reminder</h1>';
      if (deckList && deckPhoto) {
        messageContent += '<p>Deck Photo and Deck List needs to be submitted before the tournament start if you want to participate!</p>';
      }
      else if (deckList && !deckPhoto) {
        messageContent += '<p>Deck List needs to be submitted before the tournament start if you want to participate!</p>';
      }
      else if (!deckList && deckPhoto) {
        messageContent += '<p>Deck Photo needs to be submitted before the tournament start if you want to participate!</p>';
      }
      // players without submitted deck

      const playerList = {
        missingPhoto: [] as Array<IPlayersToMention>,
        missingList: [] as Array<IPlayersToMention>
      };
      playerList.missingList = res.data.missingList;
      playerList.missingPhoto = res.data.missingPhoto;

      const playersToMention: Array<string> = [];
      const playersAtList = {
        missingList: [] as Array<string>,
        missingPhoto: [] as Array<string>
      };

      playerList.missingList.forEach(player => {
        if (!playersToMention.includes(player.playerDocId)) {
          playersToMention.push(player.playerDocId);
        }
        if (!playersAtList.missingList.includes(player.playerAtText)) {
          playersAtList.missingList.push(player.playerAtText);
        }
      });

      playerList.missingPhoto.forEach(player => {
        if (!playersToMention.includes(player.playerDocId)) {
          playersToMention.push(player.playerDocId);
        }
        if (!playersAtList.missingPhoto.includes(player.playerAtText)) {
          playersAtList.missingPhoto.push(player.playerAtText);
        }
      });

      // add players to the list
      if (deckList) {
        messageContent += '<h5>Missing submitted DECK LIST from the following players:</h5>';
        messageContent += '<ul><li>' + playersAtList.missingList.join('</li><li>') + '</li></ul>';
      }
      if (deckPhoto) {
        messageContent += '<h5>Missing submitted DECK PHOTO from the following players:</h5>';
        messageContent += '<ul><li>' + playersAtList.missingPhoto.join('</li><li>') + '</li></ul>';
      }


      const messageGroupDocId = `eventChatFor[${this.event.docId}]`;
      this.ms.postChatMessage(
        messageContent,     // content: string
        messageGroupDocId,  // messageGroupDocId: string,
        MessageType.CHAT_MESSAGE,
        {
          mentionedPlayerDocIds: playersToMention
        }
      );

    });


  }

}

<ng-container *ngIf="roundIsActive$ | async else notStarted">

  <ng-container *ngIf="topBarWrapper">
    <div class="btn-action">
      <div class="round-timer me-2 text-center flex-row align-items-center {{ className }}"
        [ngClass]="{ 'text-red' : eventMeta.roundClock < 0 }" ngbTooltip="Round timer" placement="bottom-left"
        (click)="isLobbyTimer ? floatingTimer = true : null">
        <div class="flex-grow-0 d-flex align-items-center">
          <fa-icon [fixedWidth]="true" [icon]="faClock" class="me-2" *ngIf="showIcon"></fa-icon>
          <span>{{ eventMeta.roundClock | minSec }}</span>
        </div>
        <div class="size-slider" *ngIf="floatingTimer">
          <npn-slider hide-tooltip hide-values [multiRange]="false" [step]="1" [min]="5" [max]="20"
            (onChange)="onSliderChange($event)"></npn-slider>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!topBarWrapper">
    <div class="round-timer me-2 text-center flex-row align-items-center {{ className }}"
      [ngClass]="{ 'text-red' : eventMeta.roundClock < 0 }" ngbTooltip="Round timer" placement="bottom-left"
      (click)="isLobbyTimer ? floatingTimer = true : null">
      <div class="flex-grow-0 d-flex align-items-center">
        <fa-icon [fixedWidth]="true" [icon]="faClock" class="me-2" *ngIf="showIcon"></fa-icon>
        <span>{{ eventMeta.roundClock | minSec }}</span>
      </div>
      <div class="size-slider" *ngIf="floatingTimer">
        <npn-slider hide-tooltip hide-values [multiRange]="false" [step]="1" [min]="5" [max]="20"
          (onChange)="onSliderChange($event)"></npn-slider>
      </div>
    </div>
  </ng-container>

  <div ngDraggable [handle]="dragHandle" class="floaing-timer" *ngIf="floatingTimer">
    <div class="card card-auto">
      <div class="card-header d-flex">
        <div #dragHandle class="flex-grow-1 text-start">
          <fa-icon [fixedWidth]="true" [icon]="faClock" class="me-2" *ngIf="showIcon"></fa-icon>Round Timer
        </div>
        <div class="btn-close" (click)="floatingTimer = false">
          <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
        </div>
      </div>
      <div class="card-body" [ngClass]="{ 'background-red' : eventMeta.roundClock < 0 }">
        <span [style.font-size.rem]="floatingTimerFontSize">{{ eventMeta.roundClock | minSec }}</span>
      </div>
    </div>
  </div>

</ng-container>

<ng-template #notStarted>
  <ng-container *ngIf="topBarWrapper">
    <div class="btn-action">
      <div class="round-timer me-2 text-center flex-row align-items-center {{ className }}" ngbTooltip="Round timer" placement="bottom-left">
        <div class="flex-grow-0 d-flex align-items-center">
          <span>ROUND NOT STARTED</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

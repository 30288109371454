import { IMagicBlock } from './../services/card-search.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardBlocksFilter'
})
export class CardBlocksFilterPipe implements PipeTransform {

  transform(items: IMagicBlock[], filter: string): IMagicBlock[] {
    if (!items) {
      return [];
    }
    if (items && filter === '') {
      return items;
    }
    return items.filter(b => b.name.toLowerCase().includes(filter.toLowerCase()));
  }

}

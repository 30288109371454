import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons'
import { map, take } from 'rxjs/operators'
import { EventService, IPlayerMeta } from 'src/app/services'
import { Component, OnInit } from '@angular/core'
import { Observable, combineLatest, BehaviorSubject } from 'rxjs'
import { IFormat, IPlayerDetails, IRuleset } from 'tolaria-cloud-functions/src/_interfaces'
import { DownToPlayService } from 'src/app/services/down-to-play.service'

export interface IDownToPlayFormatMeta extends IFormat {
  // players: IPlayerMeta[]
  players: IPlayerDetails[]
}
@Component({
  selector: 'app-down-to-play',
  templateUrl: './down-to-play.component.html',
  styleUrls: ['./down-to-play.component.css']
})
export class DownToPlayComponent implements OnInit {

  public messageGroupDocId: string
  formats$: Observable<IFormat[]>
  formatList$: BehaviorSubject<IRuleset[]> = new BehaviorSubject<IRuleset[]>([])
  downToPlayPlayers$: Observable<IPlayerDetails[]>
  downToPlayFormats$: Observable<IDownToPlayFormatMeta[]>

  faUser = faUser
  faUsers = faUsers

  constructor(
    private es: EventService,
    private readonly downToPlay: DownToPlayService,
  ) {
    this.formats$ = this.es.formats$
  }

  ngOnInit(): void {

    this.formats$.pipe(take(1)).subscribe(formats => this.initFormats(formats))

    this.downToPlayPlayers$ = this.downToPlay.players$
    this.downToPlayFormats$ = combineLatest([this.downToPlay.players$, this.formatList$]).pipe(
      map(([players, formatList]) => {
        console.log('[DownToPlay] mapping downToPlayFormats$', players, formatList)
        return formatList.map((format) => {
          const data = {} as IDownToPlayFormatMeta
          data.name = format.name
          data.imageUris = format.imageUris
          data.players = players !== undefined && players !== null
            ? players.filter(p => {
              const foundMatch = p.presence && p.presence.downToPlayFormats ? p.presence.downToPlayFormats.find(f => f.name === format.name) : undefined
              if (foundMatch !== undefined && foundMatch.selected) {
                return foundMatch
              }
            })
            : []

          console.log(`${format.name} has ${data.players.length} active players`)
          return data
        })
      })
    )
    this.messageGroupDocId = 'downToPlay--general'
  }
  public playerTracker(index: number, item: IPlayerMeta) {
    return item.docId
  }
  public formatTracker(index: number, item: IDownToPlayFormatMeta) {
    return item.name.toLowerCase().replace(/ /g, '')
  }

  private async initFormats(formats: Array<IFormat>) {
    const res: IRuleset[] = []
    for await (const format of formats) {
      if (format.ruleSets !== undefined && format.ruleSets.length > 0) {
        for (const ruleset of format.ruleSets) {
          if (ruleset.downToPlay === undefined || ruleset.downToPlay === true) {
            const tmpRuleset: IRuleset = {
              name: `${format.name}: ${ruleset.name}`,
              imageUris: {
                downToPlay: ruleset.imageUris.downToPlay,
                eventBanner: ruleset.imageUris.eventBanner
              },
              url: '',
              downToPlay: ruleset.downToPlay
            }
            res.push(tmpRuleset)
          }
        }
      }
      else {
        const tmpRuleset: IRuleset = {
          name: format.name,
          imageUris: {
            downToPlay: format.imageUris.downToPlay,
            eventBanner: format.imageUris.eventBanner
          },
          url: '',
          downToPlay: format.downToPlay
        }
        res.push(tmpRuleset)
      }
    }
    console.log('[DownToPlay] setting formatList$', res)
    this.formatList$.next(res)
  }

}

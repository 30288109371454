import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import QRCode from 'qrcode'

@Component({
  selector: 'app-player-id-qrcode',
  templateUrl: './player-id-qrcode.component.html',
  styleUrls: ['./player-id-qrcode.component.css']
})
export class PlayerIdQrcodeComponent implements AfterViewInit {
  @ViewChild('qrCode', { static: true }) qrCode: ElementRef
  @ViewChild('modalBody', { static: true }) modalBody: ElementRef
  @Input() playerDocId: string

  public inverted = true
  private scale = 1

  public qrSize = {
    width: 233,
    height: 233,
  }

  constructor(
    public readonly modal: NgbActiveModal
  ) { }

  ngAfterViewInit(): void {
    console.log(this.modalBody.nativeElement.offsetWidth)
    if (this.playerDocId !== null && this.playerDocId !== undefined) {
      this.generateQR()
    }
  }

  public toggleInvert(): void {
    this.inverted = !this.inverted
    this.generateQR()
  }

  private generateQR(): void {

    this.qrSize.height = (this.modalBody.nativeElement.offsetWidth * this.scale) - 32
    this.qrSize.width = (this.modalBody.nativeElement.offsetWidth * this.scale) - 32

    QRCode.toCanvas(
      this.qrCode.nativeElement,
      `playerDocId.${this.playerDocId}`,
      {
        width: this.qrSize.width,
        color: {
          dark: this.inverted ? '#000000' : '#000000',
          light: this.inverted ? '#ffffff' : '#ffffff',
        }
      })
      .catch(error => console.log(error))
  }

}

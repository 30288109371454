import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { combineLatest, map, mergeMap, Observable, shareReplay } from 'rxjs';
import { IOnlineUser } from '../components/events/match-room/match-room.component';
import { ToastService } from '../services/toast.service';
import * as firestore from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'
import { AuthService } from '../services';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NewPubComponent } from './new-pub/new-pub.component';

export interface IPub {
  active: boolean
  activePlayerDocIds: string[]
  battle: {
    challengerDocId: string
    challengerStats: {
      life: number[]
      wins: number
    }
    opponentDocId: string
    opponentStats: {
      life: number[]
      wins: number
    }
    status: 'pending' | 'active' | 'none'
  }
  createdAt: number
  createdByPlayerDocId: string
  createdByUid: string
  docId: string
  imageUri: string
  invitedPlayerDocIds: string[]
  isPublic: boolean
  name: string
}

export interface IOnlinePubPlayer extends IOnlineUser {
  displayName: string
  volume: number
  muted: boolean
}


@Injectable({
  providedIn: 'root'
})
export class PubsService {

  private pubsCache_Public$: Observable<IPub[]>
  private pubsCache_Invited$: Observable<IPub[]>
  private pubsCache_Created$: Observable<IPub[]>
  private pubs$: Observable<IPub[]>

  constructor(
    private afs: AngularFirestore,
    private toast: ToastService,
    private auth: AuthService,
    private modalService: NgbModal,
  ) {
    this.pubsCache_Public$ = this.afs.collection<IPub>('pubs', ref => ref.where('isPublic', '==', true)).valueChanges()
    this.pubsCache_Invited$ = this.afs.collection<IPub>('pubs', ref => ref.where('invitedPlayerDocIds', 'array-contains', this.auth.user.playerId)).valueChanges()
    this.pubsCache_Created$ = this.afs.collection<IPub>('pubs', ref => ref.where('createdByPlayerDocId', '==', this.auth.user.playerId)).valueChanges()
    this.pubs$ = combineLatest([this.pubsCache_Public$, this.pubsCache_Invited$, this.pubsCache_Created$])
      .pipe(
        map(([publicPubs, invitedPubs, createdPubs]) => {
          return [...new Map([...publicPubs, ...invitedPubs, ...createdPubs].map(item => [item['docId'], item])).values()]
        }),
        shareReplay(1)
      )
  }

  public getPubs(): Observable<IPub[]> {
    return this.pubs$
  }

  public createPub(): void {

    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    }

    const modalRef = this.modalService.open(NewPubComponent, modalOptions)
    modalRef.componentInstance.pub = this.newPubDoc
    modalRef.componentInstance.aspectRatio = 500 / 300
    modalRef.result.then((pub) => this.savePub(pub), () => {})

  }

  public savePub(pubDoc: IPub): Promise<boolean> {

    return new Promise((resolve) => {

      this.afs.collection('pubs').doc(pubDoc.docId)
        .set(pubDoc)
        .then(() => {
          this.toast.show('Pub created', { classname: 'success-toast' })
          resolve(true)
        })
        .catch((e) => {
          this.toast.show(`Error --> ${e}`, { classname: 'error-toast', delay: 6000 })
          resolve(false)
        })
    })


  }

  public deletePub(pubDocId: string): void {

    this.afs.collection('pubs').doc(pubDocId)
        .delete()
        .then(() => {
          this.toast.show('Pub deleted', { classname: 'success-toast' })
        })
        .catch((e) => {
          this.toast.show(`Error --> ${e}`, { classname: 'error-toast', delay: 6000 })
        })

  }

  public get newPubDoc(): IPub {

    return {
      active: false,
      activePlayerDocIds: [],
      battle: {
        challengerDocId: null,
        challengerStats: {
          life: [20],
          wins: 0,
        },
        opponentDocId: null,
        opponentStats: {
          life: [20],
          wins: 0,
        },
        status: 'none',
      },
      createdAt: firestore.Timestamp.now().seconds,
      createdByPlayerDocId: this.auth.user.playerId,
      createdByUid: this.auth.user.uid,
      docId: uuidv4(),
      imageUri: null,
      invitedPlayerDocIds: [],
      isPublic: true,
      name: '',
    } as IPub

  }

}

import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Options } from '@popperjs/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { PlayerDisplayNameComponent } from 'src/app/components';
import { PlayerAvatarComponent } from 'src/app/components/players/player-avatar/player-avatar.component';
import { IDeckList } from 'src/app/services/decks.service';

interface Deck {
  name: string
  designer: string
  main: Card[]
  sideboard: Card[]
}
interface Card {
  qty: number
  name: string
  type: string
  imageUrl: string
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tolaria-deck-viewer',
  templateUrl: './tolaria-deck-viewer.component.html',
  styleUrls: ['./tolaria-deck-viewer.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    PlayerDisplayNameComponent,
    PlayerAvatarComponent,
    NgbTooltipModule,
  ]
})
export class TolariaDeckViewerComponent implements OnInit {
  @Input() set deckdocid(id: string) {
    this.deckDocId = id
  }
  @Input() set deckversiondocid(id: string) {
    this.deckVersionDocId = id
  }

  public deckDocId!: string
  public deckVersionDocId!: string

  public deck$ = new BehaviorSubject<Deck>(null)
  private deckDocument$ = new BehaviorSubject<IDeckList>(null)
  public showAll: boolean = false
  public isFocused: boolean = false

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    this.isFocused = this.host.nativeElement.contains(event.target)
  }

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly host: ElementRef<HTMLElement>,
    private readonly tooltipConfig: NgbTooltipConfig,
  ) {
    this.tooltipConfig.tooltipClass = 'tolaria-card-tooltip'
    this.tooltipConfig.container = 'body'
    this.tooltipConfig.popperOptions = (options: Partial<Options>) => {
      // for (const modifier of options.modifiers || []) {
      //   if (modifier.name === 'offset' && modifier.options) {
      //     modifier.options.offset = () => [200, 200]
      //     modifier.enabled = true
      //   }
      // }
      return options
    }
  }

  ngOnInit() {
    this.getDeck()
  }

  public destroy(): void {
    this.host.nativeElement.remove()
  }

  private async getDeck() {
    if (this.deckDocId && this.deckDocId !== '') {

      let docReference = this.firestore.collection('decks').doc(this.deckDocId)

      if (this.deckVersionDocId && this.deckVersionDocId !== '') {
        docReference.collection('versions').doc(this.deckVersionDocId)
      }

      const snap = await firstValueFrom(docReference.get())

      if (!snap.exists) {

      }

      const doc = snap.data() as IDeckList

      console.log(doc)

      this.deckDocument$.next(doc)
      this.deck$.next(this.mapDeck(doc))

    }
  }

  private mapDeck(deck: IDeckList): Deck {

    const main: Card[] = [...new Set(deck.main.map(i => i.name))].map(name => {
      return {
        qty: deck.main.filter(c => c.name === name).length,
        name: name,
        type: deck.main.find(c => c.name === name).typeLine,
        imageUrl: deck.main.find(c => c.name === name).imageUris.small,
      }
    })

    const sideboard: Card[] = [...new Set(deck.sideboard.map(i => i.name))].map(name => {
      return {
        qty: deck.sideboard.filter(c => c.name === name).length,
        name: name,
        type: deck.sideboard.find(c => c.name === name).typeLine,
        imageUrl: deck.sideboard.find(c => c.name === name).imageUris.small,
      }
    })

    let deckData: Deck = {
      name: deck.name,
      designer: deck.playerDocId,
      main: main.sort((a, b) => a.type.localeCompare(b.type)),
      sideboard: sideboard.sort((a, b) => a.type.localeCompare(b.type)),
    }
    return deckData
  }

  public get hasDeckPhoto(): boolean {
    if (this.deckDocument$.getValue() === null) {
      return false
    }
    return this.deckDocument$.getValue().deckPhotoUrl !== ''
  }

  public get deckPhotoUrl(): string {
    if (this.hasDeckPhoto) {
      return this.deckDocument$.getValue().deckPhotoUrl
    }
    return ''
  }

}

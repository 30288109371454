import { VersionHistoryComponent } from './../components/app-structure/version-history/version-history.component'
import { IVersionHistory } from 'src/app/private/admin/pages/releases/release-notes/release-notes.component'
import { AuthService } from 'src/app/services/auth.service'
import { DowntimeNoticeComponent } from './../components/app-structure/downtime-notice/downtime-notice.component'
import { ToastService } from './toast.service'
import { map, take } from 'rxjs/operators'
import { Observable, BehaviorSubject } from 'rxjs'
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/compat/firestore'
import { Injectable } from '@angular/core'
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import * as firestore from 'firebase/firestore'

export interface IReleaseNumber {
  version: number
  build: number
  subBuild: number
  beta: boolean
  versionText: string
  downtime: {
    planned: boolean
    showNotice: boolean
    description: string
    from: number
    to: number
  }
}

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  version$: Observable<IReleaseNumber>
  localVersion$: BehaviorSubject<IReleaseNumber> = new BehaviorSubject<IReleaseNumber>(null)
  news$: Observable<DocumentChangeAction<IVersionHistory>[]>

  constructor(
    private afs: AngularFirestore,
    private toastService: ToastService,
    private auth: AuthService,
    private modalService: NgbModal,
  ) {
    this.init()

    const versionCollection = this.afs.collection('app').doc('settings').collection<IVersionHistory>('version-history', ref => ref.orderBy('releaseDate', 'desc').limit(1))
    this.news$ = versionCollection.snapshotChanges()
    this.news$.pipe(take(1)).subscribe((snap) => {

      if (snap[0].payload.doc.exists) {

        const docRef = snap[0].payload.doc
        const releaseNoteDoc: IVersionHistory = docRef.data()

        if (releaseNoteDoc.readBy === undefined || releaseNoteDoc.readBy && !releaseNoteDoc.readBy[this.auth.user.playerId]) {

          console.log('open release notes in a modal')

          const modalOptions: NgbModalOptions = {
            centered: true,
            animation: true,
            backdrop: true,
            keyboard: true,
            size: 'lg',
          }

          const modalRef: NgbModalRef = this.modalService.open(VersionHistoryComponent, modalOptions)
          modalRef.componentInstance.showAll = false
          modalRef.result
            .then(
              (result) => {
                // closed by the button, update readBy
                this.afs.collection('app').doc('settings').collection<IVersionHistory>('version-history').doc(result).update({
                  [`readBy.${this.auth.user.playerId}`]: firestore.Timestamp.now().seconds
                })
              },
              (reason) => {
                // dismissed, do nothing
              }
            )
          // // get the stories connected to the version
          // const storyColRef = this.afs.collection('app').doc('settings').collection('version-history').doc(releaseNoteDocId).collection('stories')
          // const stories = storyColRef.get().subscribe(stories => {

          //   console.log(stories)
          // })

        }

      }

    })
  }

  public get current() {
    return this.localVersion$
  }

  init() {
    this.version$ = this.afs.collection('app').doc<IReleaseNumber>('settings').valueChanges()
    this.version$.subscribe((release) => {
      console.log(release)
      this.localVersion$.next(release)

      const localVersion = JSON.parse(localStorage.getItem('version'))
      // check version
      // console.log(localVersion)
      // console.log(release)
      if (!localVersion) {
        // console.log('no release stored, storing this release')
        localStorage.setItem('version', JSON.stringify(release))
      }
      else if (
        localVersion &&
        localVersion.version !== release.version ||
        localVersion.build !== release.build ||
        localVersion.subBuild !== release.subBuild ||
        localVersion.beta !== release.beta
      ) {
        // console.log('new release available, initiate force reload')
        this.toastService.show('New release available, initiate force reload', { classname: 'standard-toast', delay: 10000 })
        setTimeout(() => {
          localStorage.removeItem('version')
          this.clearCache(true)
        }, 3000)
      }
      else if (
        localVersion &&
        localVersion.version === release.version &&
        localVersion.build === release.build &&
        localVersion.subBuild === release.subBuild &&
        localVersion.beta === release.beta
      ) {
        // console.log('release is OK')
      }

      if (release.downtime && release.downtime.showNotice) {
        this.openDowntimeNotice(release)
      }
    })

  }

  private async clearCache(reloadAfterClear = true) {
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach(async (name) => {
          await caches.delete(name)
        })
      })

      if (reloadAfterClear)
        window.location.reload()
    }
  }

  private openDowntimeNotice(release: IReleaseNumber): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    }

    const modalRef: NgbModalRef = this.modalService.open(DowntimeNoticeComponent, modalOptions)
    modalRef.componentInstance.release = release
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chatFormat',
  standalone: true,
})
export class ChatFormatPipe implements PipeTransform {

  transform(value: any): any {
    return value.replace(/\n/g, '<br/>');
  }

}

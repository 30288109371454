import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ContentReadyService } from './content-ready.service';

@Injectable()
export class ContentGuard implements CanActivate {
  constructor(
    private readonly content: ContentReadyService,
    private readonly router: Router,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.content.getReadyState()) {
        console.log('[ContentGuard] --> content loaded, allow navigation')
        return true
    }
    else {
        console.log('[ContentGuard] --> content not loaded, navigate to loading screen')
        this.router.navigate(['loading'])
        return false
    }
  }
}


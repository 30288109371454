import { IPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  public returnUrl = {
    has: false,
    url: null,
    params: null,
  }

  public disableHotKeys$ = new BehaviorSubject<boolean>(false)


  public isMobile = false;
  public showSettings = false;
  public showNewEventForm = false;
  public routerLoaded = false;
  public showFullSize: BehaviorSubject<boolean>;
  public isChatting = false;
  public eventsGridView = true;
  public activeEventDocId: string;
  public activeEventName = '';
  public activeEventOrganizerDocId: string;
  public showEventChat = true;
  public showEventInfo = true;
  public showEventPlayers = true;
  public showEventPlayerDetails = false;
  public showPlayerDetailsPanel = false;
  public selectedMatchDocId: string;
  public selectedMatchDoc: IMatchData;
  public showReportSlip = false;
  public showPlayoffConfig = false;
  public userIsRegisteredPlayer: boolean;
  public playerRegistrationForm: IPlayerDetails;
  public pairingsSearchBarFocused = false;
  public isBusy = {
    status: false,
    showMessage: false,
    message: 'loading data',
    loaderWidth: 10,
  };

  constructor(
    private router: Router
  ) {
    // this.initEventListeners()
    this.disableHotKeys$.subscribe(i => console.log(`Hotkeys disabled emitted -> ${i}`))
    this.initFocusBlurListeners()
  }

  private initFocusBlurListeners(): void {
    // Attach the event listeners to the document
    document.addEventListener('focusin', (event) => {
      const target = event.target as any;
      if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
        // console.log('Input focused:', target);
        // Your custom focus handling logic for inputs
        this.disableHotKeys$.next(true)
        // console.log('...enable hot keys');
      }
    });
    document.addEventListener('focusout', (event) => {
      const target = event.target as any;
      if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
        // console.log('Input blurred:', target);
        // Your custom blur handling logic for inputs
        this.disableHotKeys$.next(false)
        // console.log('...disable hot keys');
      }
    });
  }

  static get logTime(): string {
    const date = new Date();
    // eslint-disable-next-line max-len
    return date.getFullYear().toString() + '-' + this.pad2(date.getMonth() + 1) + '-' + this.pad2(date.getDate()) + ' ' + this.pad2(date.getHours()) + ':' + this.pad2(date.getMinutes()) + ':' + this.pad2(date.getSeconds());
  }
  private static pad2(n: number): string { return n < 10 ? '0' + n : '' + n; }

  getFullSizeMode(): Observable<boolean> {
    return this.showFullSize.asObservable();
  }
  setFullSizeMode(value) {
    this.showFullSize.next(value);
  }

  getRouterLink() {
    return this.router.url.split('/')[1];
  }

  public get isMatchRoom() {
    return this.router.url.split('/')[1] === 'match-room';
  }

  public get isDraftRoom() {
    return this.router.url.split('/')[1] === 'draft-room';
  }

  public get hotkeysDisabled(): boolean {
    return this.disableHotKeys$.getValue()
  }

  public get hotkeysEnabled(): boolean {
    return !this.disableHotKeys$.getValue()
  }

  public get isAboutPage(): boolean {
    return this.router.url.includes('about')
  }

}

<div class="image-wrapper w-100 h-100" (click)="openImageViewer()">

  <img [src]="imageUrl" (load)="showImage = true" [ngClass]="{ 'hidden' : !showImage, 'margin-left' : marginLeft }" />

  <div class="photo-loader-wrapper" *ngIf="!showImage">
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

</div>

<div class="modal-header">Add Handle</div>
<div class="modal-body">
  <div class="form-group">
    <label for="handlePlatform">Platform</label>
    <div class="d-flex align-items-center flex-wrap">
      <div class="btn btn-blank" *ngFor="let handleIcon of handleIcons"
        (click)="handle.platform = handleIcon.handle; handle.placeholder = handleIcon.placeholder"
        [ngClass]="{ 'btn-main' : handle.platform === handleIcon.handle }"
        [ngbTooltip]="handleIcon.name" container="body">
        <fa-icon [icon]="handleIcon.icon" [fixedWidth]="true"></fa-icon>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="handleHandle">Handle</label>
    <input type="text" class="form-control" id="handleHandle" [(ngModel)]="handle.handle">
  </div>
  <div class="form-group">
    <label for="handleUrl">Url</label>
    <input type="text" class="form-control" id="handleUrl" [(ngModel)]="handle.url" [placeholder]="handle.placeholder">
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-sm btn-blank text-red" (click)="close()">Cancel</button>
  <button class="btn btn-sm btn-blank" (click)="addHandle()" [disabled]="formInvalid">Add Handle</button>
</div>

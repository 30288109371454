import { CardSearchService } from 'src/app/services/card-search.service';
import { Component, Input, OnInit } from '@angular/core';
import { ImageUris } from 'scryfall-client/dist/types/api/constants';

@Component({
  selector: 'app-card-image',
  templateUrl: './card-image.component.html',
  styleUrls: ['./card-image.component.css']
})
export class CardImageComponent implements OnInit {
  @Input() cardId: string;
  @Input() imageUris?: ImageUris;
  @Input() size: string;

  constructor(
    private cardService: CardSearchService
  ) { }

  ngOnInit(): void {
    if (!this.imageUris) {
      this.cardService.getCardImageUrlsById(this.cardId).then(imageUris => this.imageUris = imageUris);
    }
  }

}

import { applicationIcon } from './../../../../assets/font-icons/tolaria-icon';
import { Component, Input, OnInit } from '@angular/core';
import { IReleaseNumber } from 'src/app/services/version.service';

@Component({
  selector: 'app-downtime-notice',
  templateUrl: './downtime-notice.component.html',
  styleUrls: ['./downtime-notice.component.css']
})
export class DowntimeNoticeComponent implements OnInit {

  @Input() release: IReleaseNumber;

  appLogo = applicationIcon;

  constructor() { }

  ngOnInit(): void {
    console.log(this.release);
  }

}

<ng-container *ngIf="componentReady$ | async">
  <div class="d-flex flex-column py-2 px-3 appointment-item-line">

    <div class="mb-2 d-flex align-items-center" [ngClass]="{ 'item-cancelled text-secondary' : isCancelled}">
      <app-timestamp-clock [timestamp]="data.timestampFrom" displayType="date" [seconds]="true"></app-timestamp-clock>
      <span class="text-secondary mx-2"></span>
      <app-timestamp-clock [timestamp]="data.timestampFrom" displayType="time" [seconds]="true"></app-timestamp-clock>
      <span class="text-secondary me-1 ms-1">-</span>
      <app-timestamp-clock [timestamp]="data.timestampTo" displayType="time" [seconds]="true"></app-timestamp-clock>
    </div>

    <div class="d-flex align-items-center">

      <div class="d-flex align-items-center flex-grow-1" *ngIf="!isCancelled">
        <span class="text-small text-secondary me-2">Status:</span>
        <span [ngClass]="statusClass">{{ statusText }}</span>
      </div>

      <div *ngIf="isCancelled"
        class="px-3 mb-1 border border-danger rounded text-bg-danger"
        style="--bs-bg-opacity: .15;">
        CANCELLED
      </div>
      
      <ng-container *ngIf="showRSVP">
        <div ngbDropdown
          placement="left"
          class="d-inline-block">
          
          <button ngbDropdownToggle
            class="btn btn-sm btn-outline-secondary"
            type="button"
            id="rsvpButton">
            RSVP
          </button>
          
          <div ngbDropdownMenu
            aria-labelledby="rsvpButton">
            <button ngbDropdownItem (click)="onAcceptPress()">Accept</button>
            <button ngbDropdownItem (click)="onRejectPress()">Reject</button>
          </div>

        </div>
      </ng-container>
      
      <div *ngIf="canCancel"
        class="btn btn-sm btn-blank text-danger btn-cancel-item" 
        (click)="onCancelPress()">
        Cancel
      </div>

    </div>

  </div>
</ng-container>
import { map } from 'rxjs/operators';
import { faTimes, faFolderPlus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable, combineLatest } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DecksService, IDeckFolderMeta, IDeckList, MagicDeck } from 'src/app/services/decks.service';
import { Component, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-decks',
  templateUrl: './decks.component.html',
  styleUrls: ['./decks.component.css']
})
export class DecksComponent {
  @ViewChild('newFolderDialog', { static: true }) newFolderDialog: ElementRef;
  @ViewChild('newDeckDialog', { static: true }) newDeckDialog: ElementRef;
  @ViewChild('deleteFolderConfirmation') deleteFolderConfirmation: ElementRef;

  public decks$: Observable<IDeckList[]>;
  public deckFolderList$: Observable<IDeckFolderMeta[]>;
  public selectedDeckFolder: string;
  public searchString: string = '';

  public icons = {
    faTimes,
    faFolderPlus,
    faPlus,
    faTrash,
  };

  // models
  public newFolderName = '';
  public newDeck: IDeckList;

  constructor(
    private decksService: DecksService,
    private modalService: NgbModal,
    private auth: AuthService,
  ) {

    this.deckFolderList$ = this.decksService.deckFolderList$;

    this.decks$ = combineLatest([this.decksService.decks$, this.decksService.deckFolderList$]).pipe(
      map(([decks, deckFolders]) => {
        const decksWithFolderName: IDeckList[] = [];
        decks.forEach(deck => {
          let tmpDeck: IDeckList = deck;
          const folderName = deckFolders.find(f => f.docId === deck.deckFolderDocId);
          folderName ? tmpDeck.folderName = folderName.name : 'Uncategorized';
          tmpDeck.timestampCreated ? tmpDeck.timestampCreated : tmpDeck.timestampCreated = 1577833200; // set date to 2020-01-01 00:00:00
          decksWithFolderName.push(tmpDeck);
        });
        return decksWithFolderName;
      })
    );

  }

  public newFolder(): void {
    const options: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true
    };
    this.modalService
      .open(this.newFolderDialog, options).result
      .then((result) => {
        this.modalService.dismissAll();
      }, (reason) => {
        if (reason === 'create') {
          this.decksService.newDecksFolder(this.newFolderName);
        }
        this.modalService.dismissAll();
        this.newFolderName = '';
      });
  }

  public deleteDeckFolder(deckFolderDocId: string): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: false,
      keyboard: false,
      size: 'sm',
    };

    this.modalService.open(this.deleteFolderConfirmation, modalOptions).result
      .then((result) => {
        if (result === 'delete') {
          this.decksService.deleteDeckFolder(deckFolderDocId);
        }
      });
  }

  public createNewDeck(): void {
    this.newDeck = new MagicDeck(this.auth.user.playerId, this.auth.user.uid).data;

    const options: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true
    };
    this.modalService
      .open(this.newDeckDialog, options).result
      .then((result) => {
        this.modalService.dismissAll();
      }, (reason) => {
        if (reason === 'create') {
          this.decksService.saveDeckList(this.newDeck).then((res) => console.log(res));
        }
        this.modalService.dismissAll();
        this.newDeck = null;
      });
  }

  public dropped(event: any, deckFolderDocId: string): void {
    const deckDocId = event.dropData;
    this.decksService.moveDeckToFolder(deckDocId, deckFolderDocId);
  }

  public get newDeckFormValid(): boolean {
    return this.newDeck.name.trimLeft().length >= 5;
  }

}

<div class="modal-header d-flex align-items-center">
  <div class="h5 m-0 p-0 flex-grow-1">Settings</div>
  <div class="btn-close" (click)="destroySelf()"></div>
</div>
<div class="modal-body d-flex position-relative" style="max-height: calc(100vh - 8rem)">
  <div class="d-flex flex-column sticky me-3" style="min-width: 15rem;">
    <button type="button" class="btn btn-sm btn-blank mb-2 w-100 text-start {{ settings.you ? 'btn-main' : '' }}"
      (click)="settingsFalse(); settings.you = true;">
      <fa-icon [fixedWidth]="true" [icon]="faAddressCard"></fa-icon>You
    </button>
    <button type="button" class="btn btn-sm btn-blank mb-2 w-100 text-start {{ settings.avatar ? 'btn-main' : '' }}"
      (click)="settingsFalse(); settings.avatar = true;">
      <fa-icon [fixedWidth]="true" [icon]="faHatWizard"></fa-icon>Avatar
    </button>
    <button type="button" class="btn btn-sm btn-blank mb-2 w-100 text-start {{ settings.apperance ? 'btn-main' : '' }}"
      (click)="settingsFalse(); settings.apperance = true;">
      <fa-icon [fixedWidth]="true" [icon]="faPalette"></fa-icon>Appearance
    </button>
    <button type="button"
      class="btn btn-sm btn-blank mb-2 w-100 text-start {{ settings.notifications ? 'btn-main' : '' }}"
      (click)="settingsFalse(); settings.notifications = true;">
      <fa-icon [fixedWidth]="true" [icon]="faBell"></fa-icon>Notifications
    </button>
    <button type="button" *ngIf="isOrganizer"
      class="btn btn-sm btn-blank mb-2 w-100 text-start {{ settings.payments ? 'btn-main' : '' }}"
      (click)="settingsFalse(); settings.payments = true;">
      <fa-icon [fixedWidth]="true" [icon]="faMoneyCheckAlt"></fa-icon>Payments
    </button>
  </div>
  <div class="flex-grow-1">
    <div class="">
      <app-you *ngIf="settings.you"></app-you>
      <app-avatar *ngIf="settings.avatar"></app-avatar>
      <app-apperance *ngIf="settings.apperance"></app-apperance>
      <app-notifications *ngIf="settings.notifications"></app-notifications>
      <app-stripe-connected-account *ngIf="settings.payments" [action]="action" (updateSettings)="updateSettings($event)"></app-stripe-connected-account>
    </div>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.css']
})
export class CardItemComponent {
  @Input() cardData: any;

  faExchangeAlt = faExchangeAlt;

  constructor() { }

}

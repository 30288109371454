import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const tolFormat: IconDefinition = {
  prefix: 'tol',
  iconName: 'format',
  icon: [
    500, // SVG view box width
    377.8145, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M0,292.79c4.22-13.4,8.55-26.77,12.65-40.21Q46.52,141.79,80.31,31c5.89-19.3,18-29.53,38.11-30.52A430.26,430.26,0,0,1,164.27.8c16.11.92,27.62,9.74,33,24.86,6.59,18.64,12.07,37.69,17.94,56.59q31.41,101.1,62.75,202.23c7.23,23.35-4.34,45.34-26.88,51.4-18.69,5-39.24-4.75-45.7-23.07-6.63-18.8-11.87-38.09-17.72-57.17-.47-1.53-1-3-1.55-4.89h-4.41c-29.14,0-58.27.06-87.41-.08-3.38,0-4.71,1-5.59,4.25C83.94,272.64,79,290.3,74,307.94,65.82,337,32.32,346.88,11.22,326.55,5.41,321,2.62,313.78,0,306.46ZM136.83,59.09c-11.56,44.1-23.32,89-35.12,134h72.82Q155.46,125.36,136.83,59.09ZM436.07,199.17c.38-16.38-8.76-25.56-26-27.74-15.71-2-30.71,1-45.67,5.24-13.21,3.74-24.22-3.17-26.92-16.57-1.76-8.75,3.06-18.55,12.06-21.59,32.49-11,65.57-15.15,99-4.91,28.26,8.64,43.27,29.25,48.31,57.52A166.73,166.73,0,0,1,499,218.3c.28,24.25,0,48.51.16,72.76.05,8.94.74,17.88.89,26.83s-5.6,14.71-14.48,14.84c-8.14.13-16.28,0-24.42,0-11.48,0-15.73-3.45-18.14-14.72-.34-1.57-.61-3.15-1-5.32a15.16,15.16,0,0,0-2.83,1.89c-11.67,12.95-26.58,19.63-43.52,21.76-19.86,2.5-38.75-.32-54.71-13.56-15.53-12.89-22.68-29.8-21.86-49.9,1.39-33.76,21.12-53.11,51.12-64.07,19.47-7.11,39.81-9.18,60.36-9.66C432.25,199.14,434,199.17,436.07,199.17ZM437.54,238c-6,.36-12.15.46-18.23,1.15-9.89,1.13-19.52,3.29-27.93,9.06-9.63,6.61-12,16.11-10.46,27,1.23,8.81,7.49,15.29,16.93,17.19a42.53,42.53,0,0,0,11.61.43c13-1,26.66-12.63,27.88-24.78C438.31,258.39,437.54,248.6,437.54,238Z'
  ],
} as any;

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Injectable } from '@angular/core';
import { faNewspaper, faChevronRight, faTrophy, faAward, faBeer, faUser, faUsers, faSearch, faHandshake, faGavel, faHome, faHandSparkles, faComment, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { draftIcon } from 'src/assets/font-icons/draft-icon';
import { cardFactory } from 'src/assets/font-icons/card-factory';
import { cardsIcon } from 'src/assets/font-icons/cards-icon';
import { deckStackIcon } from 'src/assets/font-icons/deck-stack-icon';
import { tolCrossingSwords } from 'src/assets/font-icons/crossing-swords-icon';

export interface MainMenuItem {
  text: string
  icon: IconDefinition
  link: string
  expandable: boolean
  expanded: boolean
  subItems: MainMenuSubItem[]
}

export interface MainMenuSubItem {
  text: string
  icon: IconDefinition,
  link: string
}

@Injectable({
  providedIn: 'root'
})
export class MainMenuService {

  public menu: MainMenuItem[] = [
    {
      text: 'Home',
      icon: faHome,
      link: 'dashboard',
      expandable: false,
      expanded: false,
      subItems: []
    },
    {
      text: 'News',
      icon: faNewspaper,
      expanded: false,
      link: 'news',
      expandable: false,
      subItems: []
    },
    {
      text: 'Tickets',
      icon: faTicketAlt,
      expanded: false,
      link: 'tickets',
      expandable: false,
      subItems: []
    },
    {
      text: 'Play',
      icon: faChevronRight,
      link: '',
      expandable: true,
      expanded: false,
      subItems: [
        {
          text: 'Matches',
          icon: tolCrossingSwords,
          link: 'matches',
        },
        {
          text: 'Casual',
          icon: faHandSparkles,
          link: 'down-to-play',
        },
        {
          text: 'Events',
          icon: faTrophy,
          link: 'events',
        },
        {
          text: 'Leagues',
          icon: faAward,
          link: 'leagues',
        },
        {
          text: 'Draft',
          icon: draftIcon,
          link: 'drafts',
        },
      ]
    },
    {
      text: 'Social',
      icon: faChevronRight,
      link: '',
      expandable: true,
      expanded: false,
      subItems: [
        {
          text: 'Messages',
          icon: faComment,
          link: 'messages',
        },
        // {
        //   text: 'Forum',
        //   icon: faUsers,
        //   link: 'forum',
        // },
        {
          text: 'Pubs',
          icon: faBeer,
          link: 'pubs',
        },
        {
          text: 'Players',
          icon: faUser,
          link: 'players',
        },
        {
          text: 'Clubs',
          icon: faUsers,
          link: 'clubs',
        },
      ]
    },
    {
      text: 'Cards',
      icon: faChevronRight,
      link: '',
      expandable: true,
      expanded: false,
      subItems: [
        {
          text: 'Search',
          icon: faSearch,
          link: 'cards',
        },
        // {
        //   text: 'Sets',
        //   icon: cardsIcon,
        //   link: 'sets',
        // },
        {
          text: 'Workshop',
          icon: cardFactory,
          link: 'card-factory',
        },
      ]
    },
    {
      text: 'Collection',
      icon: faChevronRight,
      link: '',
      expandable: true,
      expanded: false,
      subItems: [
        {
          text: 'Decks',
          icon: deckStackIcon,
          link: 'decks',
        },
        // {
        //   text: 'Have',
        //   icon: faFistRaised,
        //   link: 'collection-have',
        // },
        // {
        //   text: 'Want',
        //   icon: faFistRaised,
        //   link: 'collection-want',
        // },
        // {
        //   text: 'Trades',
        //   icon: faHandshake,
        //   link: 'collection-trades',
        // },
        // {
        //   text: 'Auctions',
        //   icon: faGavel,
        //   link: 'collection-auctions',
        // },
      ]
    },
  ]

  public menu$: BehaviorSubject<MainMenuItem[]> = new BehaviorSubject<MainMenuItem[]>(this.menu)
  public isPinned$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(
    private offcanvasService: NgbOffcanvas,
  ) {
    console.log('MainMenuService --> constructed')

    // // unpin menu on logout even though it might not be pinned at all
    // this.userService.isLoggedOut$.subscribe(() => this.unpin())

    // // on user settings loaded, set the pinned state if user has menu pinned
    // this.userSettings.userSettingsLoaded$.subscribe((loaded) => {
    //   if (loaded && this.userSettings.mainMenuPinned && this.resultion.isDesktop) {
    //     this.pin(false)
    //   }
    // })
  }

  public toggleExpanded(item: any): void {

    const menu = this.menu$.getValue()

    const menuItem = menu.find(i => i.text === item.text)
    if (menuItem) {
      menuItem.expanded = !menuItem.expanded
    }

    this.menu$.next(menu)

  }

  // public open(): void {
  //   if (!this.offcanvasService.hasOpenOffcanvas()) {
  //     const offcanvasRef = this.offcanvasService.open(MainMenuComponent, {
  //       ariaLabelledBy: 'offcanvas-basic-title',
  //       keyboard: true,
  //     })
  //     offcanvasRef.componentInstance.offcanvas = true
  //     offcanvasRef.result.then((pin) => { if (pin) {
  //       this.pin()
  //     } }, () => {})
  //   }
  // }

  public close(): void {
    if (this.offcanvasService.hasOpenOffcanvas()) {
      this.offcanvasService.dismiss()
    }
  }

  // public pin(save: boolean = true): void {
  //   if (save) { this.userSettings.setMenuPinState(true) }
  //   this.isPinned$.next(true)
  //   document.documentElement.style.setProperty('--left-bar-width', '15rem')
  //   this.offcanvasService.dismiss()
  // }

  // public unpin(save: boolean = true): void {
  //   if (save) { this.userSettings.setMenuPinState(false) }
  //   document.documentElement.style.setProperty('--left-bar-width', '0rem')
  //   this.isPinned$.next(false)
  // }

}

<div class="pairings-view-fullscreen" (mousemove)="onMouseMove($event)" [ngClass]="{ 'show-mouse' : mouseMoving }">

  <div class="close-button" (click)="closeFullscreen()" [ngClass]="{ 'moving' : mouseMoving }">
    <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>
  </div>


  <div class="d-flex w-100 h-100">


    <div class="various-info">



      <div class="reporting-instructions position-relative" *ngIf="showReportInstructions">

        <div class="btn-remove-instructions btn btn-sm btn-outline-main" (click)="showReportInstructions = false">
          Hide report instructions
        </div>

        <ng-container *ngIf="activeStep$ | async as step">
  
          <div class="step-wrapper d-flex">
  
            <div class="d-flex flex-column left">
              <img [src]="step.images.left.url">
              <div class="caption">{{ step.images.left.caption }}</div>
            </div>
  
            <div class="middle">
              <div class="header">HOW TO REPORT</div>
              <div class="title">STEP {{ step.step }}</div>
              <div class="instruction" [innerHTML]="step.description"></div>
            </div>
  
            <div class="d-flex flex-column right">
              <img [src]="step.images.right.url">
              <div class="caption">{{ step.images.right.caption }}</div>
            </div>
  
          </div>
  
        </ng-container>
      </div>



      <div class="extra-information">

        <div class="btn-remove-extra btn btn-sm btn-outline-main" (click)="widgetData.editWidget = true" *ngIf="!widgetData.editWidget">
          <fa-icon [icon]="faEdit" [fixedWidth]="true" class="me-2"></fa-icon>
          <span class="">Change widget</span>
        </div>

        <ng-container *ngIf="widgetData.editWidget; else showAddInformation">

          <div class="d-flex w-100 h-100 align-items-center justify-content-around tile-wrapper">

            <div class="tile d-flex flex-column align-items-center justify-content-center"
              (click)="selectView('roundTimer')">
              <fa-icon [icon]="faClock" [fixedWidth]="true"></fa-icon>
              <span class="">Round timer</span>
            </div>

            <div class="tile d-flex flex-column align-items-center justify-content-center"
              (click)="selectView('customText')">
              <fa-icon [icon]="faFont" [fixedWidth]="true"></fa-icon>
              <span class="">Custom text message</span>
            </div>

            <div class="tile d-flex flex-column align-items-center justify-content-center"
              (click)="selectView('customImage')">
              <fa-icon [icon]="faImage" [fixedWidth]="true"></fa-icon>
              <span class="">Custom image</span>
            </div>

            <div class="tile d-flex flex-column align-items-center justify-content-center"
              (click)="selectView('youtubePlaylist')">
              <fa-icon [icon]="faYoutube" [fixedWidth]="true"></fa-icon>
              <span class="">Embed Youtube playlist</span>
            </div>

          </div>

        </ng-container>


        <ng-template #showAddInformation>

          <ng-container [ngSwitch]="selectedView">

            <ng-container *ngSwitchCase="'roundTimer'">
              <div class="custom-text-message">
                
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'customText'">
              <div class="custom-text-message">
                <textarea placeholder="Enter your custom text here" [(ngModel)]="widgetData.customText"></textarea>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'customImage'">
              <div class="image-uploader" *ngIf="widgetData.image === ''; else showImage">
                <app-image-cropper-uploader
                  class="image-uploader-ui"
                  (base64)="setTmpImage($event)"
                  [maintainAspectRatio]="false"
                  [containWithinAspectRatio]="false">
                </app-image-cropper-uploader>
                <div class="image-preview">
                  <div class="button-wrapper d-flex align-items-end">
                    <button [disabled]="tmpImage === ''"
                      class="btn btn-sm mb-3 {{ tmpImage === '' ? 'btn-secondary' : 'btn-success' }}"
                      (click)="setImage()">Set as image</button>
                  </div>
                  <img [src]="tmpImage" />
                </div>
              </div>
              <ng-template #showImage>
                <div class="custom-image-wrapper">
                  <div class="btn btn-sm btn-outline-main" (click)="setImage(true)">Change image</div>
                  <img [src]="widgetData.image" />
                </div>
              </ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="'youtubePlaylist'">
              <div class="streaming position-relative">
                <div class="youtube-config" *ngIf="widgetData.youtubeUrl === ''; else youtubePlayer">
                  <div class="instructions-wrapper d-flex p-3">
                    <div class="instruction-text me-3 flex-grow-1">
                      <div class="text-large text-bold">How to add a youtube playlist</div>
                      <ol>
                        <li>Create your youtube playlist</li>
                        <li>Make the playlist <span class="text-bold">Public</span></li>
                        <li>Copy the <span class="text-bold">Video/Playlist id</span> from the input (see image to the right)</li>
                        <li>Paste the copied Url in the below input field</li>
                        <li>Hit the <span class="text-bold">Activate</span> button</li>
                        <li>Click play on the embedded youtube player</li>
                      </ol>
                    </div>
                    <div class="instruction-image">
                      <img src="assets/images/youtube-embed-url-copy.png" [style.width.vh]="30">
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="row px-3">
                      <div class="col-4">
                        <input type="email"
                          class="form-control form-control-sm"
                          [(ngModel)]="youtubeUrl"
                          id="youtubeUrl"
                          placeholder="youtube video/playlist id">
                      </div>
                      <div class="col-2">
                        <button (click)="setYoutubeUrl()"
                          [disabled]="youtubeUrl === ''"
                          class="w-100 btn btn-sm {{ youtubeUrl === '' ? 'btn-outline-secondary' : 'btn-outline-success'}}">
                          Activate
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
                <ng-template #youtubePlayer>
                  <div class="btn btn-sm btn-clear-url btn-outline-main" (click)="setYoutubeUrl(true)">Change playlist</div>
                  <iframe
                    width="100%"
                    height="100%"
                    [src]="embeddedYoutubeUrl"
                    title="YouTube video player"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen>
                  </iframe>
                </ng-template>
              </div>
            </ng-container>

          </ng-container>

        </ng-template>


      </div>



    </div>


    <div class="pairings-section d-flex flex-column">



      <div class="event-name">{{ eventName }}</div>
      <div class="pairings-round d-flex align-item-center">
        <div class="flex-grow-1">Pairings for round {{ activeRound }}</div>
        <div class="page-counter">Page <span class="page">{{ activeBatchNum }}</span> of {{ batchCount }}</div>
      </div>


      <div class="scrolling-pairings">

        <ng-container *ngIf="activePairingsBatch$ | async as matches">
          <ng-container *ngFor="let match of matches; let i = index">

            <div class="row d-flex align-items-center" [ngClass]="{ 'alternated' : i % 2 === 0 }">
              <div class="col-6 player">
                {{ match.player.name }}
                <span class="match-points">
                  {{ match.player.points }}
                </span>
              </div>
              <div class="col-4 opponent text-secondary flex-grow-1">
                {{ match.opponent.name }}
                <span class="match-points" *ngIf="match.opponent.name !== '*** BYE ***' && match.opponent.name !== '*** LOSS ***'">
                  {{ match.opponent.points }}
                </span>
              </div>
              <div class="col-2 table-number">{{ match.table }}</div>
            </div>

          </ng-container>
        </ng-container>

      </div>


    </div>


  </div>

</div>

<ng-template #donationForm>
  <ng-container *ngIf="init$ | async; else loader">


    <div class="d-flex flex-column" [ngClass]="{ 'border-top': !modalView }">

      <div class="d-flex align-items-center text-mega text-center text-secondary justify-content-center" *ngIf="!selectedTier || donationType === 'ONE_TIME'">
        <fa-icon [icon]="donationIcon" [fixedWidth]="true"></fa-icon>
      </div>

      <p class="text-center" *ngIf="showText">
        If you want to contribute to the Tolaria platform, please consider leaving a donation.<br>
        Any amount is helpful to make the plaform a better place for all us old school lovers!
      </p>

      <div class="donation-selector-wrapper" *ngIf="showTypeSelector; else standardForm">
        <div class="selection d-flex flex-row align-items-center">
          <button class="btn btn-sm btn-blank btn-selector w-50" [ngClass]="{ 'selected': donationType === 'ONE_TIME' }"
            (click)="setDonationType('ONE_TIME')">One time</button>
          <button class="btn btn-sm btn-blank btn-selector w-50"
            [ngClass]="{ 'selected': donationType === 'SUBSCRIPTION' }"
            (click)="setDonationType('SUBSCRIPTION')">Subscription</button>
        </div>
        <ng-template [ngTemplateOutlet]="donationType === 'ONE_TIME' ? standardForm : subscriptionForm"></ng-template>
      </div>

      <div class="mt-3">
        <button class="btn w-100 {{  creatingCheckout ? 'btn-outlined-secondary' : 'btn-main' }}"
          (click)="proceedToCheckout()" [disabled]="checkoutFormDisabled">
          <span *ngIf="!creatingCheckout; else loader">Proceed to checkout</span>
        </button>
        <button class="btn w-100 btn-blank"
          *ngIf="modalView"
          (click)="dismiss()">
          Cancel
        </button>
      </div>

    </div>


  </ng-container>
</ng-template>

<ng-template #standardForm>
  <div class="form-group mt-3">
    <input type="number" class="form-control text-larger text-center" [min]="1" [step]="1" id="extraAmount"
      aria-describedby="extraAmountHelp" [(ngModel)]="donationAmount">
  </div>
  <div class="my-2 border-bottom border-secondary"></div>
  <div class="py-2 mt-3 text-larger d-flex flex-row">
    <div class="flex-grow-1">Total:</div>
    <div class="flex-grow-0">{{ donationAmount }} {{ currency }}</div>
  </div>
</ng-template>

<ng-template #subscriptionForm>
  <div class="tier-selector py-3">
    <div class="tier d-flex align-items-center p-2" *ngFor="let tier of donationTiers$ | async"
      (click)="selectedTier = tier">
      <div class="thumb-wrapper me-2" [ngClass]="{ 'selected': selectedTier === tier }">
        <img [src]="tier.thumbUrl">
      </div>
      <div class="text-large flex-grow-1" [innerHtml]="tier.name"></div>
      <div class="text-normal" [innerHtml]="tier.amount === 666 ? 'Go 🍌' : tier.amount"></div>
      <div class="text-secondary ms-1" [innerHtml]="tier.currency"></div>
    </div>
  </div>
  <div class="form-group mt-3 border-top" *ngIf="selectedTier && chaosTier">
    <input type="number" class="form-control text-larger text-center" [min]="1" [step]="1" id="extraAmount"
      aria-describedby="extraAmountHelp" [(ngModel)]="donationAmount">
  </div>
  <div class="py-2 mt-3 text-larger d-flex flex-row" *ngIf="selectedTier">
    <div class="flex-grow-1">Total:</div>
    <div class="flex-grow-0">{{ chaosTier ? donationAmount : selectedTier.amount }} {{ selectedTier.currency }}<span
        class="text-secondary text-normal">/month</span></div>
  </div>
  <div class="text-secondary text-center" *ngIf="exchangeRatesLoaded" [innerHtml]="exchangeRateInfo"></div>
</ng-template>


<ng-template #loader>
  <div class="d-flex flex-row align-items-center justify-content-center">
    <app-busy-spinner></app-busy-spinner>
  </div>
</ng-template>

<ng-template #tooltipper>
  <span class="text-secondary">Press <span class="badge text-bg-dark text-monospaced">Esc</span> to close</span>
</ng-template>
<div class="modal-body rounded"
  #tooltip="ngbTooltip"
  [ngbTooltip]="tooltipper"
  [autoClose]="false"
  container="body"
  triggers="manual"
  (mouseenter)="tooltip.open()"
  *ngIf="modalView; else donationForm"
  style="max-height: calc(100vh - 5rem);">
  <ng-template [ngTemplateOutlet]="donationForm"></ng-template>
</div>

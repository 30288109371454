import { Subscription } from 'rxjs';
import { IUserRegistration, IUserLogin, IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { faGoogle, faFacebook, faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';
import { AuthService, GlobalsService } from 'src/app/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  faGoogle = faGoogle;
  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faGithub = faGithub;

  showSignIn = false;

  private authSubscription: Subscription;

  public forgotPassword = false;
  public isRegistering = false;
  public registrationData: IUserRegistration = {
    email: '',
    password: '',
    confirmPassword: ''
  };
  public loginData: IUserLogin = {
    email: '',
    password: ''
  };
  public forgotPasswordEmail = '';
  public authMessage = '';

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public globals: GlobalsService
  ) {
    this.authSubscription = this.auth.user$.subscribe(user => {
      if (user) {
        console.log('LoginComponent: user logged in, move to previous url or dashboard');
        if (user.isRegisteredAsPlayer) {
          if (this.globals.returnUrl.has) {
            console.log('login.component -> globals.returnUrl.has set, should navigate accordingly')
            this.router.navigate([this.globals.returnUrl.url], { queryParams: this.globals.returnUrl.params })
            setTimeout(() => {
              this.globals.returnUrl = {
                has: false,
                url: null,
                params: null,
              }
            }, 3000)
          }
          else {
            this.router.navigate(['dashboard']);
          }
        }
        else {
          this.router.navigate(['register']);
        }
      }
      else {
        console.log('LoginComponent: user not logged in, staying at login');
        this.router.navigate(['auth/sign-in']);
      }
    });
    this.globals.routerLoaded = true;
  }

  ngOnInit(): void {
    this.showSignIn = this.globals.isMobile;
  }
  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  public registrationFormIsValid() {
    return this.auth.registrationFormIsValid(this.registrationData);
  }

  public resetPassword() {
    this.auth.sendPasswordResetEmail(this.forgotPasswordEmail).then((res: IPromiseResponse) => {
      console.log(res);
      this.authMessage = res.text;
    });
  }

  public emailSignup() {
    this.authMessage = '';
    this.auth.emailSignup(this.registrationData).then((response: IPromiseResponse) => {
      console.log(response);
      this.authMessage = response.text;
    });

  }

  public signin(method: string) {
    this.authMessage = '';
    switch (method) {
      case 'email':
        this.auth.emailSignin(this.loginData).then((response: IPromiseResponse) => {
          console.log(response);
          this.authMessage = response.text;
        });
        break;

      case 'facebook':
        this.auth.facebookSignin().then((response: IPromiseResponse) => {
          console.log(response);
          this.authMessage = response.text;
        });
        break;

      case 'google':
        this.auth.googleSignin().then((response: IPromiseResponse) => {
          console.log(response);
          this.authMessage = response.text;
        });
        break;

      case 'github':
        this.auth.githubSignin().then((response: IPromiseResponse) => {
          console.log(response);
          this.authMessage = response.text;
        });
        break;

      case 'twitter':
        this.auth.twitterSignin().then((response: IPromiseResponse) => {
          console.log(response);
          this.authMessage = response.text;
        });
        break;
    }
  }

  public get emailIsValid(): boolean {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.registrationData.email)) {
      return (true);
    }
    return (false);
  }


}

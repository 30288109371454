<div class="card min-width-100 d-flex flex-column min-height-100 max-height-100 overflow-hidden">
  <div class="card-body no-hover border-bottom border-secondary">
    <div class="text-larger text-center">Latest Brews</div>
  </div>
  <div class="card-body no-hover p-0 flex-grow-1 overflow-y-auto scrollable">

    <ng-container *ngIf="recentBrews$ | async as brews">

      <div class="deck-card d-flex flex-row p-1 align-items-center" *ngFor="let deck of brews; trackBy: tracker"
        [attr.data-doc-id]="deck.docId">
        <div class="image-wrapper ms-2">
          <app-scryfall-image-version [scryfallId]="deck.cardIds[0]" version="art_crop">
          </app-scryfall-image-version>
        </div>
        <div class="info-wrapper d-flex flex-grow-1 flex-column px-2 ms-3">
          <div class="deck-name text-bold text-large">{{ deck.name }}</div>
          <div class="deck-creator d-flex align-items-center my-1">
            <fa-icon [icon]="icon.user" [fixedWidth]="true" class="text-secondary me-2"></fa-icon>
            <app-player-display-name class="flex-grow-1 text-secondary" [playerDocId]="deck.playerDocId">
            </app-player-display-name>
          </div>
          <div class="color-and-actions d-flex align-items-center">
            <div class="colors">
              <ul class="color-list flex-grow-1">
                <li class="color-item" *ngIf="deck.colors ? deck.colors.includes('WHITE') : false"><i
                    class="ms ms-w ms-cost"></i></li>
                <li class="color-item" *ngIf="deck.colors ? deck.colors.includes('BLUE') : false"><i
                    class="ms ms-u ms-cost"></i></li>
                <li class="color-item" *ngIf="deck.colors ? deck.colors.includes('BLACK') : false"><i
                    class="ms ms-b ms-cost"></i></li>
                <li class="color-item" *ngIf="deck.colors ? deck.colors.includes('RED') : false"><i
                    class="ms ms-r ms-cost"></i></li>
                <li class="color-item" *ngIf="deck.colors ? deck.colors.includes('GREEN') : false"><i
                    class="ms ms-g ms-cost"></i></li>
              </ul>
            </div>
            <div class="flex-grow-1"></div>
            <div class="actions">

              <div class="d-inline preview-wrapper" container="body" placement="top"
                [ngbTooltip]="deck.assignedToEvent ? 'Preview disabled as deck has been assigned to an ongoing event' : 'Preview deck photo and/or list'">

                <button [disabled]="deck.deckPhotoUrl === null || deck.deckPhotoUrl === '' || deck.disablePreview"
                  class="btn btn-sm btn-blank" (click)="showDeckPic(deck.docId)" ngbTooltip="Show deck photo"
                  container="body">
                  <fa-icon [icon]="icon.camera" [fixedWidth]="true"></fa-icon>
                </button>

                <button [disabled]="deck.main.length === 0 || deck.disablePreview" class="btn btn-sm btn-blank"
                  (click)="showDeckList(deck.docId)" ngbTooltip="Show deck list" container="body">
                  <fa-icon [icon]="icon.list" [fixedWidth]="true"></fa-icon>
                </button>

              </div>

              <div class="d-inline" container="body"
                [ngbTooltip]="deck.ratedByPlayerDocIds.length > 0 ? ratedByPlayers : 'Be the first to rate this deck'">
                <button [disabled]="deck.disableRating" class="btn btn-sm btn-blank" (click)="rateDeck(deck.docId)">
                  <fa-icon [icon]="icon.star" [fixedWidth]="true" class="me-2"></fa-icon> {{ deck.rating ? deck.rating :
                  0}}
                </button>
              </div>

              <ng-template #ratedByPlayers>
                <div class="list-wrapper">
                  <div class="list-item px-3 py-1" *ngFor="let playerDocId of deck.ratedByPlayerDocIds">
                    <app-player-display-name [playerDocId]="playerDocId"></app-player-display-name>
                  </div>
                </div>
              </ng-template>

            </div>
          </div>
        </div>
      </div>

    </ng-container>

  </div>
</div>

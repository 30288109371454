import { faExclamationTriangle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { IConventionTicketMeta, ITicket, TicketType } from 'tolaria-cloud-functions/src/_interfaces';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { faCircle, faCheckCircle, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from 'src/app/services/auth.service';
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, Input, OnInit } from '@angular/core';
import { GlobalsService, EventService } from 'src/app/services';
import { TicketService } from 'src/app/services/ticket.service';
import { BehaviorSubject } from 'rxjs';

interface IEventListMeta extends IEventDetails {
  selected: boolean
  capReached: boolean
}

@Component({
  selector: 'app-new-ticket',
  templateUrl: './new-ticket.component.html',
  styleUrls: ['./new-ticket.component.css']
})
export class NewTicketComponent implements OnInit {
  @Input() edit: boolean = false
  @Input() data: ITicket = null

  public formValidationMessage: string = ''
  public ticketType: TicketType = null
  public ticketCost: number = 0
  public ticketLimit: number = 0
  public showAsSoldOut: boolean = false

  // CONVENTION TICKET
  public ticketName: string = null
  public ticketDescription: string = null
  public ticketOnSaleFrom: NgbDateStruct = null
  public ticketOnSaleTo: NgbDateStruct = null


  public eventList$: BehaviorSubject<IEventListMeta[]> = new BehaviorSubject<IEventListMeta[]>(null)

  public selected = faCheckCircle
  public unselected = faCircle
  public calendarIcon = faCalendarAlt
  public warningIcon = faExclamationTriangle
  public playersIcon = faUsers

  constructor(
    private globals: GlobalsService,
    private eventService: EventService,
    private auth: AuthService,
    private modal: NgbActiveModal,
    private ticketService: TicketService,
  ) { }

  ngOnInit(): void {
    console.log('init')
    if (this.edit) {
      this.ticketType = this.data.type
      this.ticketName = this.data.name
      this.ticketDescription = this.data.description
      this.ticketOnSaleFrom = this.dateFromTimestamp(this.data.salesData.from)
      this.ticketOnSaleTo = this.dateFromTimestamp(this.data.salesData.to)
      this.ticketCost = this.data.salesData.price
      this.showAsSoldOut = this.data.showAsSoldOut
      if (this.data.type === TicketType.CONVENTION) {
        this.onTicketTypeChanged()
      }
    }
  }

  private dateFromTimestamp(timestamp: number): NgbDateStruct {
    const d = new Date(timestamp * 1000)
    const date: NgbDateStruct = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    }
    console.log({timestamp,date})
    return date
  }

  public async onTicketTypeChanged() {

    if (this.ticketType === TicketType.CONVENTION) {
      let events: IEventListMeta[]
      if (this.edit && this.isAdmin) {
        events = await this.eventService.getEventsByCreator(this.data.createdByUid, { open: true, registrationFee: true }) as IEventListMeta[]
      }
      else {
        events = await this.eventService.getEventsByCreator(this.auth.user.uid, { open: true, registrationFee: true }) as IEventListMeta[]
      }

      events.forEach(e => {
        // make selection of event
        e.selected = this.data.data.eventDocIds.includes(e.docId)
        // mark if cap is reached
        e.capReached = e.details.hasAttendeeCap && e.details.attendeeCap <= e.playerDocIds.length
      })
      this.eventList$.next(events)
    }

  }

  public cancel(): void {
    this.modal.dismiss()
  }

  public createTicket(): void {
    let data
    switch (this.ticketType) {
      case TicketType.CONVENTION:
        data = {
          eventDocIds: this.eventList$.getValue().filter(i => i.selected).map(i => i.docId)
        } as IConventionTicketMeta
        break
      default:
        data = {}
        break
    }
    this.ticketService.createTicket(
      this.ticketType,
      this.ticketName,
      this.ticketCost,
      this.salesDateFromTimestamp,
      this.salesDateToTimestamp,
      this.ticketLimit,
      data,
      this.ticketDescription,
      this.showAsSoldOut,
      this.edit ? this.data.docId : null,
    ).then(() => this.modal.close())
  }

  public get selectedTicketType(): TicketType {
    return this.ticketType
  }

  public get selectedEventsTotal(): number {
    if (this.eventList$.getValue() === null) {
      return 0
    }
    else {
      return this.eventList$.getValue().filter(i => i.selected).reduce((acc, i) => acc + i.details.registrationFee.amount, 0)
    }
  }

  public get usersDefaultCurrency(): string {
    if (this.isAdmin && this.edit) {
      return this.data.salesData.currency.toUpperCase()
    }
    return this.auth.user.stripe.default_currency.toUpperCase()
  }

  public get ticketDiscountPercentage(): string {
    return '(discount: ' + (((this.selectedEventsTotal - this.ticketCost) / this.selectedEventsTotal) * 100).toFixed(2) + '%)'
  }

  public get isMobile(): boolean {
    return this.globals.isMobile
  }

  private get salesDateFromTimestamp(): number {
    // check dates here
    let ticketOnSaleFrom = 0
    if (this.ticketOnSaleFrom !== null) {
      const selectedDateFrom = new Date(this.ticketOnSaleFrom.year, this.ticketOnSaleFrom.month - 1, this.ticketOnSaleFrom.day)
      selectedDateFrom.setHours(0, 0, 0, 0)
      ticketOnSaleFrom = Math.floor(selectedDateFrom.getTime()) / 1000
    }
    return ticketOnSaleFrom
  }
  private get salesDateToTimestamp(): number {
    // check dates here
    let ticketOnSaleTo = 0
    if (this.ticketOnSaleTo !== null) {
      const selectedDateTo = new Date(this.ticketOnSaleTo.year, this.ticketOnSaleTo.month - 1, this.ticketOnSaleTo.day)
      selectedDateTo.setHours(23, 59, 99, 999)
      ticketOnSaleTo = Math.floor(selectedDateTo.getTime() / 1000)
    }
    return ticketOnSaleTo
  }

  public get formInvalid(): boolean {
    if (this.ticketType === null) {
      this.formValidationMessage = 'Choose a ticket type'
      return true
    }

    // CONVENTION TICKET
    if (this.ticketType === TicketType.CONVENTION) {

      if (this.ticketName === null || this.ticketName === '') {
        this.formValidationMessage = 'Enter a name for your ticket'
        return true
      }

      // if (this.ticketDescription === null || this.ticketDescription === '') {
      //   this.formValidationMessage = 'Please add a description'
      //   return true
      // }

      if (this.selectedEventsTotal === null || this.selectedEventsTotal === undefined || this.selectedEventsTotal === 0) {
        this.formValidationMessage = 'Please select one or more events that will be covered by this ticket'
        return true
      }

      if (this.salesDateFromTimestamp === 0) {
        this.formValidationMessage = 'Please select the first date this ticket should be available for purchase'
        return true
      }
      if (this.salesDateToTimestamp === 0) {
        this.formValidationMessage = 'Please select the last date this ticket should be available for purchase'
        return true
      }
      if (this.salesDateToTimestamp < this.salesDateFromTimestamp) {
        this.formValidationMessage = 'You have to select a valid date range'
        return true
      }

      if (this.ticketLimit === null || this.ticketLimit === undefined || this.ticketLimit < 0) {
        this.formValidationMessage = 'Please enter either ZERO (0) for unlimited number of tickets, or a positive number of tickets'
        return true
      }

      if (this.ticketCost <= 0) {
        this.formValidationMessage = 'Please enter a sales price'
        return true
      }

      this.formValidationMessage = ''
      return false
    }
    else {
      this.formValidationMessage = ''
      return false
    }

  }

  public get salesHasStarted(): boolean {

    if (this.data && this.edit) {

      if (this.data.salesData.sold > 0) { return true }

    }

    return false
  }

  public get isAdmin(): boolean {
    return this.auth.user.role === 'admin'
  }

}

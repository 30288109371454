<ng-container *ngIf="notes$ | async as notes">
  <div ngbDropdown #notesDropdown="ngbDropdown">

    <button class="btn btn-sm btn-blank" id="dropdownBasic1" ngbDropdownToggle
      ngbTooltip="Your match notes" placement="right" container="body">
      <fa-icon [fixedWidth]="true" [icon]="faStickyNote" class="me-2"></fa-icon>{{ notes.length }}
    </button>

    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="custom-drop-down">
      <div ngbDropdownItem class="d-flex align-items-center justify-content-center note-wrapper" *ngFor="let note of notes">
        <div class="flex-grow-1 note-content" [innerHTML]="note.note"></div>
        <div class="flex-grow-0 btn-remove-note" (click)="deleteMatchNote(note.docId); $event.stopPropagation()">
          <fa-icon [fixedWidth]="true" [icon]="faTrash"></fa-icon>
        </div>
      </div>
    </div>

  </div>
</ng-container>

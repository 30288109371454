import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { MessageModalService } from 'src/app/components/modals/message-modal/message-modal.service'
import { ToastService } from 'src/app/services/toast.service'
import { IEventPlayerDetails, IMatchData, IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces'


@Injectable({
  providedIn: 'root'
})
export class TournamentMatchService {

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly toast: ToastService,
    private readonly confirm: MessageModalService,
  ) { }


  public delete(id: string) {

    this.confirm.open({
      type: 'danger',
      title: 'Delete match?',
      message: 'Are you sure you want to delete this match?',
      buttons: [
        {
          text: 'Cancel',
          type: 'dismiss',
          value: 'dismissed',
        },
        {
          text: 'Delete',
          type: 'close',
          value: 'delete',
        }
      ],
    })
    .then((value) => {
      if (value === 'delete') {
        this.firestore.collection('matches').doc(id).delete()
          .then(() => this.toast.show('Match deleted'))
          .catch((error) => this.toast.show(error, {classname: 'error-toast', delay: 10000}))
      }
    })
    .catch((error) => this.toast.show(error, {classname: 'error-toast', delay: 10000}))

  }


  /**
   * Delete all the match documents referenced by ids passed
   * 
   * @param docIds 
   * @returns boolean | if the operation was successful or not
   */
  public deleteMatches(docIds: string[]): Promise<boolean> {

    return new Promise(async (resolve) => {

      // create firestore batch writer
      const batchWrites: firebase.default.firestore.WriteBatch[] = []
      let batchWriteIndex = 0
      let writeCount = 0

      // create local method to update counter and index of the batch writer
      const __incrementWriteCounter = () => {
        writeCount++
        if ((writeCount % 500) === 0 && (writeCount / 500) === (batchWriteIndex + 1)) {
          batchWrites.push(this.firestore.firestore.batch())
          batchWriteIndex++
        }
      }

      // add the first batch
      batchWrites.push(this.firestore.firestore.batch())

      // add all deletions
      for (const id of docIds) {
        __incrementWriteCounter()
        const matchDocRef = this.firestore.collection('matches').doc(id).ref
        batchWrites[batchWriteIndex].delete(matchDocRef)
      }

      // perform deletions
      console.log('...writing matches and player updates to firebase')
      for await (const [index, batch] of batchWrites.entries()) {
        batch
          .commit()
          .then(() => console.log(`...batchWrite #${index + 1} successful`))
          .catch((e) => {
            console.log(e)
            this.toast.show(e, { classname: 'error-toast', delay: 5000 })
            resolve(false)
          })
      }


      // all good, return response
      resolve(true)


    })

  }

  /**
   * Inactivate all the match documents referenced by ids passed
   * 
   * @param docIds 
   * @returns boolean | if the operation was successful or not
   */
  public inactivateMatches(docIds: string[]): Promise<boolean> {

    return new Promise(async (resolve) => {

      // create firestore batch writer
      const batchWrites: firebase.default.firestore.WriteBatch[] = []
      let batchWriteIndex = 0
      let writeCount = 0

      // create local method to update counter and index of the batch writer
      const __incrementWriteCounter = () => {
        writeCount++
        if ((writeCount % 500) === 0 && (writeCount / 500) === (batchWriteIndex + 1)) {
          batchWrites.push(this.firestore.firestore.batch())
          batchWriteIndex++
        }
      }

      // add the first batch
      batchWrites.push(this.firestore.firestore.batch())

      // add all deletions
      for (const id of docIds) {
        __incrementWriteCounter()
        const matchDocRef = this.firestore.collection('matches').doc(id).ref
        batchWrites[batchWriteIndex].update(matchDocRef, {
          deleted: true
        })
      }

      // perform deletions
      console.log('...writing matches and player updates to firebase')
      for await (const [index, batch] of batchWrites.entries()) {
        batch
          .commit()
          .then(() => console.log(`...batchWrite #${index + 1} successful`))
          .catch((e) => {
            console.log(e)
            this.toast.show(e, { classname: 'error-toast', delay: 5000 })
            resolve(false)
          })
      }


      // all good, return response
      resolve(true)


    })

  }


  /**
   * Exchanve one player for another for each given match document
   * 
   * @param remove IEventPlayerDetails
   * @param add IPlayerMini
   * @param docs IMatchData[] - The match document ids
   */
  public exchangePlayer(remove: IEventPlayerDetails, add: IPlayerMini, docs: IMatchData[]): Promise<boolean> {

    return new Promise(async (resolve) => {

      // create firestore batch writer
      const batchWrites: firebase.default.firestore.WriteBatch[] = []
      let batchWriteIndex = 0
      let writeCount = 0

      // create local method to update counter and index of the batch writer
      const __incrementWriteCounter = () => {
        writeCount++
        if ((writeCount % 500) === 0 && (writeCount / 500) === (batchWriteIndex + 1)) {
          batchWrites.push(this.firestore.firestore.batch())
          batchWriteIndex++
        }
      }

      // add the first batch
      batchWrites.push(this.firestore.firestore.batch())

      // add all deletions
      for (const matchDoc of docs) {
        __incrementWriteCounter()
        const droppingPlayerIs = remove.playerDocId === matchDoc.player1.playerDocId ? 'player1' : 'player2'
        const opponentIs = droppingPlayerIs === 'player1' ? 'player2' : 'player1'
        // update the main properties
        matchDoc[droppingPlayerIs].displayName = add.name.display
        matchDoc[droppingPlayerIs].playerDocId = add.id
        matchDoc[droppingPlayerIs].playerUid = add.uid
        matchDoc.playerFilterValue = matchDoc.player1.displayName + ' ' + matchDoc.player2.displayName + ' ' + matchDoc.tableNumber + ' ' + matchDoc.segmentNumber
        matchDoc.playerDocIds = [matchDoc[opponentIs].playerDocId, add.id]
        // reset the result as long as its not a BYE or LOSS match
        if (!matchDoc.isLossMatch && !matchDoc.isByeMatch) {
          matchDoc.isByeMatch = false
          matchDoc.isDraw = false
          matchDoc.isLossMatch = false
          matchDoc.isReported = false
          matchDoc.player1.draws = 0
          matchDoc.player1.isWinner = false
          matchDoc.player1.drop = false
          matchDoc.player1.lifePoints = [20],
            matchDoc.player1.losses = 0
          matchDoc.player1.matchPoints = 0
          matchDoc.player1.wins = 0
          matchDoc.player2.draws = 0
          matchDoc.player2.isWinner = false
          matchDoc.player2.drop = false
          matchDoc.player2.lifePoints = [20],
            matchDoc.player2.losses = 0
          matchDoc.player2.matchPoints = 0
          matchDoc.player2.wins = 0
        }
      
        const matchDocRef = this.firestore.collection('matches').doc(matchDoc.docId).ref
        batchWrites[batchWriteIndex].update(matchDocRef, matchDoc)
      }

      // perform deletions
      console.log('...writing matches and player updates to firebase')
      for await (const [index, batch] of batchWrites.entries()) {
        batch
          .commit()
          .then(() => console.log(`...batchWrite #${index + 1} successful`))
          .catch((e) => {
            console.log(e)
            this.toast.show(e, { classname: 'error-toast', delay: 5000 })
            resolve(false)
          })
      }


      // all good, return response
      resolve(true)


    })

  }


}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IDeckList, IDeckListVersion } from 'src/app/services/decks.service';
import { ToastService } from 'src/app/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class DeckSharingService {

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly toast: ToastService,
  ) { }


  public createSharingUrl(deck: IDeckList | IDeckListVersion): void {

    let shareInfo: { deckDocId: string, deckVersionDocId: string }
    let isVersion: boolean

    if (deck.hasOwnProperty('deckDocId')) {
      isVersion = true
      let data = deck as IDeckListVersion
      shareInfo = {
        deckDocId: data.deckDocId,
        deckVersionDocId: deck.docId,
      }
    }
    else {
      isVersion = false
      let data = deck as IDeckList
      shareInfo = {
        deckDocId: data.docId,
        deckVersionDocId: ''
      }
    }

    // create sharing document
    this.firestore
      .collection('sharedContent')
      .doc('decks')
      .collection('shares')
      .add(shareInfo)
      .then((ref) => {

        // copy url to clipboard
        navigator.clipboard.writeText(`${window.location.origin}/deck-viewer/${ref.id}`)
          .then(
            () => this.toast.show('Sharing url copied to the clipboard', { classname: 'success-toast' }),
            () => this.toast.show('Could not copy sharing url to clipboard', { classname: 'error-toast' })
          )

        // create a document ref
        let docRef = this.firestore.collection('decks').doc(shareInfo.deckDocId)

        // append version document ref if needed
        if (isVersion) {
          docRef = docRef.collection('versions').doc(shareInfo.deckVersionDocId)
        }

        // add sharingId to the deck
        docRef
          .update({ sharingId: ref.id })
          .then(() => console.log('DeckSharingService:: deck updated with sharing id'))
          .catch((e) => console.log(e))

      })
      .catch((error) => this.toast.show(error, { classname: 'error-toast', delay: 10000 }))

  }

}

import { AuthService } from './../../../../services/auth.service';
import { Router } from '@angular/router';
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IWebRtcDataObject } from '../webrtc-helper.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-who-starts-modal',
  templateUrl: './who-starts-modal.component.html',
  styleUrls: ['./who-starts-modal.component.css'],
  standalone: true,
  imports: [
    CommonModule,
  ]
})
export class WhoStartsModalComponent implements OnInit {
  @Input() data: IWebRtcDataObject;
  @ViewChild('player1', { static: true }) player1: ElementRef;
  @ViewChild('player2', { static: true }) player2: ElementRef;

  public player1get: string;
  public player2get: string;

  private player1DocId: string;
  public countDown: number;
  public showResult = false;

  constructor(
    private router: Router,
    private auth: AuthService
  ) {
    this.player1DocId = this.router.url.split('/')[4];
  }

  public get isPlayer1(): boolean {
    return this.player1DocId === this.auth.user.playerId;
  }

  public get player1wins(): boolean {
    return this.player1get === 'RockLobster' && this.player2get === 'ScissorsLizard' ||
      this.player1get === 'ScissorsLizard' && this.player2get === 'PaperTiger' ||
      this.player1get === 'PaperTiger' && this.player2get === 'RockLobster';
  }

  ngOnInit(): void {
    const selectors = JSON.parse(this.data.value);
    this.player1get = selectors.player1get;
    this.player2get = selectors.player2get;

    this.countDown = 5;

    const counter = setInterval(() => {

      this.countDown--;

      if (this.countDown === 0) {
        clearInterval(counter);
        this.showResult = true;
      }

    }, 1000);

  }

}

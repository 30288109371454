<ng-container *ngIf="eventDoc$ | async as event">

  <div class="card card-auto flex-row min-height-100 max-height-100 mb-2">
    <div
      class="card-xlarge no-hover d-flex flex-column flex-grow-1 {{ globals.isMobile ? 'min-width-100 max-width-100' : '' }}">

      <!-- EVENT ACTIONS TO MANAGE THE EVENT STATES -->
      <app-event-actions-swiss *ngIf="eventIs(event) === 'swiss'"
        [ngClass]="{ 'flex-grow-1' : panels.advanceActions }"
        [eventDoc$]="eventDoc$"
        [eventMatches$]="eventMatches$"></app-event-actions-swiss>

      <app-event-actions-batch *ngIf="eventIs(event) === 'batch'"
        [ngClass]="{ 'flex-grow-1' : panels.advanceActions }"
        [eventDoc$]="eventDoc$"
        [eventMatches$]="eventMatches$"></app-event-actions-batch>

      <app-event-actions-group *ngIf="eventIs(event) === 'group'"
        [ngClass]="{ 'flex-grow-1' : panels.advanceActions }"
        [eventDoc$]="eventDoc$"
        [eventMatches$]="eventMatches$"></app-event-actions-group>

      <app-event-actions-robin *ngIf="eventIs(event) === 'round-robin'"
        [ngClass]="{ 'flex-grow-1' : panels.advanceActions }"
        [eventDoc$]="eventDoc$"
        [eventMatches$]="eventMatches$"></app-event-actions-robin>

      <!-- PLAYERS (add or manage invitations) [component] -->
      <ng-container #playersPanel>
        <div class="card-header" (click)="togglePanels('players', panels.players = !panels.players)">
          <span>Players</span>
          <span class="btn-header float-end {{ panels.players ? 'rotate-90deg' : '' }}">
            <fa-icon [fixedWidth]="true" [icon]="faChevronRight"></fa-icon>
          </span>
        </div>
        <app-event-to-players class="card-body bottom-border no-hover scrollable flex-grow-1" *ngIf="panels.players"
          [eventDoc$]="eventDoc$" [eventDocId]="event.docId"></app-event-to-players>
      </ng-container>
      <ng-template *ngIf="panels.players && globals.isMobile" [ngTemplateOutlet]="playersPanelTemplate"></ng-template>

      <!-- DECK SUBMISSION [component] -->
      <ng-container #deckSubmissionPanel *ngIf="event.details.deckList || event.details.deckPhoto">
        <div class="card-header"
          (click)="togglePanels('deckSubmission', panels.deckSubmission = !panels.deckSubmission)">
          <span>Deck Submission</span>
          <span class="btn-header float-end {{ panels.deckSubmission ? 'rotate-90deg' : '' }}">
            <fa-icon [fixedWidth]="true" [icon]="faChevronRight"></fa-icon>
          </span>
        </div>
        <app-deck-submission-config class="card-body bottom-border no-hover scrollable flex-grow-1"
          *ngIf="panels.deckSubmission" [event]="event"></app-deck-submission-config>
      </ng-container>

      <!-- CO ORGANIZERS [component] -->
      <ng-container #coOrganizersPanel *ngIf="isEventCreator(event.createdByUid)">

        <div class="card-header" (click)="togglePanels('organizers', panels.organizers = !panels.organizers)">
          <span>Co-Organizers</span>
          <span class="btn-header float-end {{ panels.organizers ? 'rotate-90deg' : '' }}">
            <fa-icon [fixedWidth]="true" [icon]="faChevronRight"></fa-icon>
          </span>
        </div>
        <app-event-to-co-organizers class="card-body bottom-border no-hover scrollable flex-grow-1"
          *ngIf="panels.organizers" [eventDoc$]="eventDoc$"></app-event-to-co-organizers>
      </ng-container>

      <!-- EVENT LOGS [component] -->
      <ng-container #eventLog>

        <div class="card-header" (click)="togglePanels('log', panels.log = !panels.log)">
          <span>Event Log</span>
          <span class="btn-header float-end {{ panels.log ? 'rotate-90deg' : '' }}">
            <fa-icon [fixedWidth]="true" [icon]="faChevronRight"></fa-icon>
          </span>
        </div>
        <app-event-to-log class="card-body bottom-border no-hover scrollable flex-grow-1 p-0" *ngIf="panels.log" [eventDocId]="event.docId"></app-event-to-log>
      </ng-container>

      <!-- FILLER -->
      <div class="card-body flex-grow-1" *ngIf="allPanelsClosed"></div>

      <div class="card-footer d-flex align-items-center">
        <ng-container *ngIf="!globals.isMobile">
        </ng-container>
        <div class="flex-grow-0">
          <button disabled="true" class="btn btn-sm text-secondary text-small lext-left">{{ event.docId }}</button>
          <button (click)="copyText(event.docId)" class="btn btn-sm text-secondary m-0">
            <fa-icon [icon]="faCopy" [fixedWidth]="true"></fa-icon>
          </button>
        </div>
        <div class="flex-grow-1 filler"></div>
        <div class="btn btn-sm btn-secondary me-2" (click)="editEventDetails(event)">Edit</div>
        <button class="btn btn-sm btn-danger" (click)="deleteEvent()">Delete event</button>
      </div>

    </div>

    <div class="extra-panel border-left card card-xlarge min-height-100 max-height-100 overflow-hidden"
      [style.marginTop.px]="-1" [style.marginRight.px]="-1" *ngIf="showExtraPanel">

      <ng-template *ngIf="panels.players && !globals.isMobile" [ngTemplateOutlet]="playersPanelTemplate"></ng-template>

    </div>

  </div>



  <ng-template #playersPanelTemplate>
    <div class="card-header d-flex align-items-center">
      <button class="btn btn-selector" [ngClass]="{ 'selected' : panels.extra.players.attending }"
        (click)="panels.extra.players.invited = false; panels.extra.players.attending = true">
        Attending Players ({{ event.playerDocIds.length }})
      </button>
      <button class="btn btn-selector" [ngClass]="{ 'selected' : panels.extra.players.invited }"
        (click)="panels.extra.players.invited = true; panels.extra.players.attending = false">
        Invited Players ({{ event.invitedPlayers.length }})
      </button>
      <div class="flex-grow-1"></div>

      <div ngbDropdown class="d-inline-block" container="body">
        <button class="btn btn-blank text-secondary" id="playerActions" ngbDropdownToggle>
          <fa-icon [icon]="burgerMenu" [fixedWidth]="true"></fa-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="playerActions">
          <button ngbDropdownItem [disabled]="false" (click)="exportAttendeeList(event)">Export attendee
            list</button>
          <button ngbDropdownItem [disabled]="false" (click)="exportInviteeList(event)">Export invitation
            list</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem [disabled]="true" class="text-secondary">Unattend non checked in</button>
          <button ngbDropdownItem [disabled]="true" class="text-secondary">Unattend non paid</button>
          <button ngbDropdownItem [disabled]="true" class="text-secondary">Unattend non deck photo</button>
          <button ngbDropdownItem [disabled]="true" class="text-secondary">Unattend non deck list</button>
        </div>
      </div>

    </div>

    <div class="card-header d-flex align-items-center" *ngIf="panels.extra.players.attending">
      <ng-container *ngIf="!isTeamEvent">
        <div class="btn btn-sm btn-blank" (click)="showPlayersPaid = !showPlayersPaid"
          *ngIf="event.details.registrationFee.active"
          ngbTooltip="Toggle to show all or only players who have not paid the registration fee." container="body">
          <fa-icon [fixedWidth]="true" [icon]="faCoins"></fa-icon><span class="ms-2">{{ !showPlayersPaid ? 'Show all' :
            'Not paid' }}</span>
        </div>
        <div class="btn btn-sm btn-blank" (click)="showCheckedIn = !showCheckedIn"
          ngbTooltip="Toggle to show all or only players who have not checked in." container="body">
          <fa-icon [fixedWidth]="true" [icon]="faCheckedIn"></fa-icon><span class="ms-2">{{ !showCheckedIn ? 'Show all' :
            'Not checked in' }}</span>
        </div>
        <div class="btn btn-sm btn-blank" (click)="showNonAvailable = !showNonAvailable"
          ngbTooltip="Show players without availability." container="body">
          <fa-icon [fixedWidth]="true" [icon]="faCalendar"></fa-icon><span class="ms-2">{{ !showNonAvailable ? 'Show all' :
            'No availability' }}</span>
        </div>
        <div class="flex-grow-1"></div>
        <div class="search-bar float-end {{ extendSearchBar || playerFilter !== '' ? 'extended' : '' }}"
          (mouseenter)="extendSearchBar = true" (mouseleave)="extendSearchBar = false">
          <div class="search-icon">
            <fa-icon [fixedWidth]="true" [icon]="faSearch" (click)="extendSearchBar = !extendSearchBar"></fa-icon>
          </div>
          <input #searchBar type="text" class="search-field"
            (focus)="extendSearchBar = true;"
            [(ngModel)]="playerFilter" placeholder="Search by email">
          <div class="clear-input-icon">
            <fa-icon [fixedWidth]="true" [icon]="faTimes" (click)="playerFilter = ''; searchBar.focus()"
              *ngIf="playerFilter !== ''" style="cursor: pointer;"></fa-icon>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isTeamEvent">
        Enrolled teams: <span class="text-bold ms-2 badge text-bg-secondary">{{ event.teamIds ? event.teamIds.length : 0 }}</span>
      </ng-container>
    </div>

    <div class="no-hover card-body p-0 scrollable">

      <app-event-invited-players [invitedPlayers]="event.invitedPlayers" *ngIf="panels.extra.players.invited">
      </app-event-invited-players>

      <ng-container *ngIf="panels.extra.players.attending">


        <div class="player-list" *ngIf="!isTeamEvent">
          <ng-container *ngIf="players$ | async as playerList">

              <app-event-to-attending-player
                class="player event-player-list d-flex align-items-center"
                *ngFor="let player of playerList | toAttendingPlayers : attendingPlayersConfig; trackBy: tracker"
                [event]="event"
                [player]="player"
                [hideIfAvailable]="showNonAvailable"></app-event-to-attending-player>

          </ng-container>
        </div>

        <div class="team-list" *ngIf="isTeamEvent">

          <ng-container *ngIf="teamsList$ | async as teams">

            <app-team-list-item [event]="event" [team]="team" *ngFor="let team of teams"></app-team-list-item>

          </ng-container>

        </div>

      </ng-container>

    </div>
  </ng-template>

  <ng-template #deleteConfirmation let-modal>
    <div class="modal-body d-flex flex-column justify-items-center align-items-center">
      <fa-icon class="text-huge text-warning" [icon]="faExclamationTriangle" [fixedWidth]="true"></fa-icon>
      <div class="text-warning">
        Are you sure you want to delete this event, this can't be undone!
        <div class="text-italic">All match documents will be deleted as well</div>
        <ng-container *ngIf="event.ticketDocIds && event.ticketDocIds.length > 0">
          <div class="mt-5 border border-warning rounded p-2">
            <div class="text-larger">CONVENTION TICKETS</div>
            Please remember to handle the tickets that are connected to this event accordingly!
          </div>
        </ng-container>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-sm btn-blank" (click)="modal.close()">Cancel</button>
      <button class="btn btn-sm btn-danger" (click)="modal.close(true)">Delete</button>
    </div>
  </ng-template>

</ng-container>

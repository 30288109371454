import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services';
import { IPlayerDetails, IUser } from 'tolaria-cloud-functions/src/_interfaces';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public playerDocument$ = new BehaviorSubject<IPlayerDetails>(null)
  public userDocument$ = new BehaviorSubject<IUser>(null)
  private userSub: Subscription
  private playerSub: Subscription


  constructor(
    private readonly auth: AuthService,
    private readonly firestore: AngularFirestore,
  ) {
    this.auth.isLoggedIn$.subscribe(state => {
      console.log('[UserService]:: auth state changed', state)
      if (state) {
        this.getDocuments()
      }
      else {
        this.clearDocuments()
      }
    })
  }

  private async getDocuments() {

    const userObserver = this.firestore.collection('users').doc<IUser>(this.auth.user.uid).valueChanges()

    this.userSub = userObserver.subscribe((doc) => this.userDocument$.next(doc))

    this.userDocument$.subscribe(doc => {
      if (doc !== null) {
        const playerObserver = this.firestore.collection('players').doc<IPlayerDetails>(doc.playerId).valueChanges()
        this.playerSub = playerObserver.subscribe((doc) => this.playerDocument$.next(doc))
      }
      else {
        if (this.playerSub !== undefined) {
          this.playerSub.unsubscribe()
        }
      }
    })

    // const userSnap = await firstValueFrom(this.firestore.collection('users').doc<IUser>(this.auth.user.uid).get())
    // if (!userSnap.exists) {
    //   console.error('User document not found')
    //   return
    // }

    // const userDoc = userSnap.data() as IUser
    // console.log('[UserService]:: User document loaded', userDoc)
    // this.userDocument$.next(userDoc)

    // const playerSnap = await firstValueFrom(this.firestore.collection('players').doc<IPlayerDetails>(userDoc.playerId).get())
    // if (!playerSnap.exists) {
    //   console.error('Player document not found')
    //   return
    // }

    // const playerDoc = playerSnap.data() as IPlayerDetails
    // console.log('[UserService]:: Player document loaded', playerDoc)
    // this.playerDocument$.next(playerDoc)

  }

  private clearDocuments(): void {
    this.playerDocument$.next(null)
    this.userDocument$.next(null)
    if (this.userSub) {
      this.userSub.unsubscribe()
    }
    if (this.playerSub) {
      this.playerSub.unsubscribe()
    }
  }

  public get isHero(): boolean {
    const doc = this.playerDocument$.getValue()
    if (doc === null) {
      return false
    }

    if (doc.tolariaSupportUntil > (Date.now() / 1000)) {
      return true
    }

    if (doc.identifiers && doc.identifiers.isHero) {
      return doc.identifiers.isHero
    }

    return false

  }


}

import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { Observable } from 'rxjs';
import { EventService } from 'src/app/services';
import { GlobalsService } from 'src/app/services/globals.service';
import { IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, Output, EventEmitter } from '@angular/core';
import { faChevronRight, faTimes, faCamera, faFileAlt, faCoins } from '@fortawesome/free-solid-svg-icons';

export interface IEventData {
  event: IEventDetails;
  players: IEventPlayerDetails[];
}
@Component({
  selector: 'app-event-players',
  templateUrl: './event-players.component.html',
  styleUrls: ['./event-players.component.css']
})
export class EventPlayersComponent {
  @Output() selectPlayer = new EventEmitter<IEventPlayerDetails>();

  showEventPlayerDetails = false;
  selectedPlayer: IEventPlayerDetails;

  faChevronRight = faChevronRight;
  faTimes = faTimes;
  faCamera = faCamera;
  faFileAlt = faFileAlt;
  faCoins = faCoins;

  eventData$: Observable<IEventData>;
  players$: Observable<IEventPlayerDetails[]>;
  event$: Observable<IEventDetails>;
  eventDocId: string;

  constructor(
    public globals: GlobalsService,
    private es: EventService,
    private route: ActivatedRoute
  ) {
    this.route.params.pipe(take(1)).subscribe((params) => {
      this.eventDocId = params.eventDocId;
      this.event$ = this.es.getEventById(this.eventDocId);
      this.players$ = this.es.getEventPlayersByEventId(this.eventDocId);
    });
  }

  public tracker(index: number, item: IEventPlayerDetails): string {
    return item.playerDocId;
  }

  showPlayerDetails(player: IEventPlayerDetails) {
    if (this.selectedPlayer !== undefined && this.selectedPlayer.playerDocId === player.playerDocId) {
      // emit empty value if panel should hide
      this.selectPlayer.emit();
      this.selectedPlayer = undefined;
      // this.showEventPlayerDetails = false;
    }
    else {
      // emit player details if panel should switch or open
      this.selectPlayer.emit(player);
      this.selectedPlayer = player;
      // this.showEventPlayerDetails = true;
    }
  }

  close() {
    this.selectedPlayer = undefined;
    this.globals.showEventPlayers = false;
    this.showEventPlayerDetails = false;
  }

  isSelectedPlayer(playerDocId: string): boolean {
    if (this.selectedPlayer === undefined) {
      return false;
    }
    if (this.selectedPlayer.playerDocId === playerDocId) {
      return true;
    }
    return false;
  }

  getPadding(): string {
    if (this.showEventPlayerDetails) {
      return '';
    }
    return 'pr-2';
  }

}

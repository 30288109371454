import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const tiFlipRight: IconDefinition = {
  prefix: 'fac',
  iconName: 'flipRight',
  icon: [
    470.27, // SVG view box width
    512, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M75.41,283.91c33.21-33,66.25-66.23,99.51-99.22,31-30.76,75.27-30.73,106.21,0q50,49.72,99.86,99.67c29.85,29.92,30.08,74.33.33,104.33q-50.67,51.1-101.88,101.67c-29,28.62-73.29,28.91-102.4.37-34.66-34-68.83-68.46-103.13-102.81-13.85-13.87-20.68-30.8-21.17-51.26C52.79,316.57,60.47,298.77,75.41,283.91ZM109,354.39Q159.89,405.24,210.9,456c10.06,10,24.49,9.91,34.51,0q51.06-50.7,101.88-101.67c10.43-10.47,10.26-24.78-.24-35.29Q296.59,268.52,246,218.15c-10.86-10.81-24.83-10.84-35.68,0q-50.41,50.16-100.7,100.44c-5,5-8,10.87-8,18.31C101.39,343.65,104.09,349.51,109,354.39ZM378.92,120.32c-3.87.43-7.25.51-10.5,1.22-12.82,2.8-20.55,13.73-19.08,26.69,1.37,12,11.53,21,24.09,21q36.3.1,72.61,0c12.75-.05,23.49-9.17,23.78-21.83q.9-38.46,0-77c-.28-12.52-11.33-22-23.67-22.18A24.09,24.09,0,0,0,422,70.56c-.54,7.47-.1,15-.1,22.52l-1.74,1.13A29.92,29.92,0,0,0,418,90.68C377.08,40.3,324.35,10.44,260.08,2.05,148.05-12.59,41.2,52.49,2.21,158.22c-5.59,15.15-.37,28.52,13.15,33.67s26.4-1.74,32.19-16.61C72.13,112.12,117,70.31,182.93,54.66c75-17.81,139.5,3.32,191.86,60.18,1,1.11,1.94,2.32,2.88,3.5C378,118.77,378.26,119.27,378.92,120.32Z'
  ],
} as any;

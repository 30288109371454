import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EmojiService } from '../emoji.service';
import { BehaviorSubject } from 'rxjs';
import { EmojiItem } from '../emojis';

@Component({
  selector: 'app-tolaria-emoji',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
  ],
  template: `
  <ng-container *ngIf="emoji$ | async as emoji">
    <img class="ql-emoji ql-tolaria-emoji {{ size }} {{ rounded ? 'rounded' : '' }}"
    [attr.shortcode]="emoji.shortcode"
    [src]="emoji.image"
    [alt]="emoji.shortcode"
    [attr.aria-label]="emoji.search.join(' ')">
  </ng-container>`,
  styleUrls: ['./tolaria-emoji.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TolariaEmojiComponent implements OnInit {
  @Input() shortcode: string
  @Input() size: 'large' | 'medium' | 'default' = 'default'
  @Input() rounded: boolean = false

  public emoji$ = new BehaviorSubject<EmojiItem>(null)

  constructor(private readonly emojiService: EmojiService) {}

  ngOnInit() {
    if (this.shortcode) {
      let emoji = this.emojiService.emojiByShortcode(this.shortcode)
      this.emoji$.next(emoji)
    }
  }

}

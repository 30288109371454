<ng-template #tooltipper>
  <span class="text-secondary">Press <span class="badge text-bg-dark text-monospaced">Esc</span> to close</span>
</ng-template>
<div class="modal-wrapper" #tooltip="ngbTooltip" [ngbTooltip]="tooltipper" [autoClose]="false" container="body"
  triggers="manual" (mouseenter)="tooltip.open()">

  <div class="modal-header">{{ edit ? 'Edit' : 'Create new'}} ticket</div>
  <div class="modal-body">
    <div class="form-wrapper d-flex flex-column">
      <ng-container *ngIf="!salesHasStarted">
        <label for="ticketType">Ticket type <span class="text-secondary">*</span></label>
        <select class="custom-select" id="ticketType" [(ngModel)]="ticketType" (change)="onTicketTypeChanged()">
          <option selected [value]="null">Open this select menu</option>
          <option value="CONVENTION">Convention</option>
          <!-- <option value="DRAFT">Draft</option> -->
        </select>
      </ng-container>

      <div class="content-wrapper">
        <ng-container [ngSwitch]="ticketType">
          <ng-template [ngTemplateOutlet]="convention" *ngSwitchCase="'CONVENTION'"></ng-template>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex align-items-center">
    <span class="text-secondary flex-grow-1">* mandatory</span>
    <div class="text-warning text-start">{{ formValidationMessage }}</div>
    <button class="btn btn-sm btn-blank text-secondary" (click)="cancel()">Cancel</button>
    <button class="btn btn-sm btn-blank" [disabled]="formInvalid" (click)="createTicket()">{{ edit ? 'Save' : 'Create' }}</button>
  </div>

</div>

<ng-template #convention>
  <div class="py-3">
    <div class="h3">Convention Ticket</div>

    <div class="details-wrapper">
      <div class="row mt-3">
        <div class="col text-secondary text-large">Details</div>
      </div>
      <div class="row">
        <div class="form-group col">
          <label for="ticketName">Name <span class="text-secondary">*</span></label>
          <input class="form-control" id="ticketName" [(ngModel)]="ticketName">
        </div>
      </div>
      <div class="row">
        <div class="form-group col">
          <label for="ticketDescription">Description</label>
          <textarea class="form-control" id="ticketDescription" rows="5" [(ngModel)]="ticketDescription"></textarea>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-secondary text-large d-flex align-items-center">
          <div class="flex-grow-1">Events <span class="text-secondary">*</span></div>
          <div class="flex-grow-0 text-warning" *ngIf="salesHasStarted">
            <fa-icon [icon]="warningIcon" [fixedWidth]="true" class="me-2"></fa-icon>Section disabled as sales has started
          </div>
        </div>
      </div>
      <ng-container *ngIf="eventList$ | async as events">
        <ul class="list-group">
          <ng-container *ngFor="let event of events">
            <ng-template #eventTooltip>
              <div class="d-flex flex-column">
                <div class="text-larger">Cap reached</div>
                <p>You are not able to add this event as the attendee cap has been reached already</p>
              </div>
            </ng-template>
            <li class="list-group-item d-flex align-items-center"
              (click)="salesHasStarted || event.capReached ? null : event.selected = !event.selected"
              [ngClass]="{ 'selected': event.selected, 'text-danger': !event.selected && salesHasStarted || !event.selected && event.capReached }"
              [ngbTooltip]="event.capReached ? eventTooltip : 'Feel free to add event as the attendee cap is NOT reached'"
              container="body">

              <fa-icon [icon]="event.selected ? selected : unselected" [fixedWidth]="true" class="me-2"></fa-icon>
              <span class="flex-grow-1">
                {{ event.details.name }}
              </span>
              <div class="d-flex align-items-center text-secondary" [style.minWidth.rem]="10">
                <span class="flex-grow-0 px-2">
                  <fa-icon [icon]="playersIcon" [fixedWidth]="true"></fa-icon>
                </span>
                <span class="flex-grow-1 px-2">
                  {{ event.playerDocIds.length }} of {{ event.details.hasAttendeeCap ? event.details.attendeeCap : '∞' }}
                </span>
              </div>
              <span class="flex-grow-0 ms-3 text-end" [style.minWidth.rem]="6">
                {{ event.details.registrationFee.amount }} {{ event.details.registrationFee.currency.toUpperCase() }}
              </span>

            </li>
          </ng-container>
        </ul>
        <div class="row mt-2">
          <div class="col text-end text-bold">
            Total: {{ selectedEventsTotal }} {{ usersDefaultCurrency }}
          </div>
        </div>
      </ng-container>
    </div>

    <div class="sales-wrapper mt-3 pt-3 border-top">
      <div class="row mt-3">
        <div class="col text-secondary text-large">Sales details</div>
      </div>
      <div class="row">
        <div class="form-group {{ isMobile ? 'col-12' : 'col' }}">
          <label for="dateFrom">From <span class="text-secondary">*</span></label>
          <div class="input-group flex-grow-1 me-3 new-appointment-date-picker" id="dateFrom">
            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="ticketOnSaleFrom" ngbDatepicker
              #from="ngbDatepicker" placement="bottom" container="body">
            <div class="input-group-append">
              <button class="btn btn-sm btn-outline-secondary calendar" (click)="from.toggle()" type="button">
                <fa-icon [icon]="calendarIcon" [fixedWidth]="true"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group {{ isMobile ? 'col-12' : 'col' }}">
          <label for="dateTo">To <span class="text-secondary">*</span></label>
          <div class="input-group flex-grow-1 me-3 new-appointment-date-picker" id="dateTo">
            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="ticketOnSaleTo" ngbDatepicker
              #to="ngbDatepicker" placement="bottom" container="body">
            <div class="input-group-append">
              <button class="btn btn-sm btn-outline-secondary calendar" (click)="to.toggle()" type="button">
                <fa-icon [icon]="calendarIcon" [fixedWidth]="true"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ng-template #availableTicketInfo>
        <p>
          Define a ticket limit by adding a number.
        </p>
        <p>
          If set to ZERO it means either unlimited number of tickets or it will be regulated by the connected events if there's a player cap.
          If more than one event is connected and the cap in one or more events are reached, the ticket will be marked as <b class="text-red">sold out</b>
        </p>
      </ng-template>
      <div class="row align-items-center" [ngbTooltip]="availableTicketInfo" container="body" placement="top-right">
        <div class="text-secondary text-large {{ isMobile ? 'col-7' : 'col' }}">
          Available tickets <span class="text-secondary">*</span>
        </div>
        <div class="form-group {{ isMobile ? 'col-5' : 'col-2' }} text-end mb-0">
          <input class="form-control text-end text-large" [min]="0" type="number" id="ticketCost" [(ngModel)]="ticketLimit">
        </div>
      </div>
      <div class="row align-items-center mt-3">
        <div class="text-secondary text-large {{ isMobile ? 'col-7' : 'col' }}">
          Ticket price ({{ usersDefaultCurrency }}) <span class="text-secondary">*</span>
        </div>
        <div class="form-group {{ isMobile ? 'col-5' : 'col-2' }} text-end mb-0">
          <input class="form-control text-end text-large" [min]="0" type="number" id="ticketCost" [(ngModel)]="ticketCost">
        </div>
      </div>
      <div class="row">
        <div class="col text-end text-secondary text-small text-monospace">{{ ticketDiscountPercentage }}</div>
      </div>
      <div class="row align-items-center mt-3">
        <div class="text-secondary text-large {{ isMobile ? 'col-7' : 'col' }}">
          Show as sold out
        </div>
        <div class="wrapper text-end {{ isMobile ? 'col-5' : 'col-2' }}">
          <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input" id="showAsSoldOut" [(ngModel)]="showAsSoldOut">
            <label class="form-check-label" for="showAsSoldOut"></label>
          </div>
        </div>
      </div>
    </div>

  </div>
</ng-template>

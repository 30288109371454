<ng-container *ngIf="eventList$ | async as events">


  <div class="event-filter-panel d-flex align-items-center" [ngClass]="{ 'mobile' : globals.isMobile }">
    <!-- EVENT NAME -->
    <div class="d-inline-block m-1 h-100">
      <input type="text" class="form-control form-control-sm" placeholder="Event name"
        [(ngModel)]="es.filter.nameString" style="min-width: 16rem; font-size: .875rem !important;">
    </div>
    <!-- DATES -->
    <div ngbDropdown class="d-inline-block m-1">
      <button class="btn btn-outline-secondary btn-sm text-start" id="dropDownDatePicker" ngbDropdownToggle>
        <fa-icon [icon]="faCalendarAlt" [fixedWidth]="true" class="me-2"></fa-icon>{{ es.filter.dateRangeString }}
      </button>
      <div ngbDropdownMenu aria-labelledby="dropDownDatePicker" class="drop-down-panel">
        <ngb-datepicker #dp class="w-100" [displayMonths]="3" [showWeekNumbers]="true" [dayTemplate]="t"
          navigation="arrows" outsideDays="collapsed" (dateSelect)="onDateSelection($event)">
        </ngb-datepicker>
        <ng-template #t let-date let-focused="focused">
          <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
    <!-- YOU -->
    <div ngbDropdown class="d-inline-block m-1">
      <button class="btn btn-outline-secondary btn-sm" id="dropDownFormats" ngbDropdownToggle>
        <fa-icon [icon]="faFilter" [ngClass]="{ 'selected': es.filter.checker.youIsFiltered }" [fixedWidth]="true"
          class="me-2"></fa-icon>You
      </button>
      <div ngbDropdownMenu aria-labelledby="dropDownFormats" class="drop-down-panel">
        <ng-container *ngFor="let item of es.filter.you">
          <div class="row pt-1 pb-1 {{ item.selected ? '' : 'text-secondary' }} format-item"
            (click)="item.selected = !item.selected; es.setYouIsFiltered()">
            <div class="col">{{ item.name }}</div>
            <div class="col-2 text-end">
              <fa-icon [fixedWidth]="true" [icon]="item.selected ? faCheckSquare : faSquare"></fa-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- FORMATS -->
    <div ngbDropdown class="d-inline-block m-1">
      <button class="btn btn-outline-secondary btn-sm" id="dropDownFormats" ngbDropdownToggle>
        <fa-icon [icon]="faFilter" [ngClass]="{ 'selected': es.filter.checker.formatIsFiltered }" [fixedWidth]="true"
          class="me-2"></fa-icon>Format
      </button>
      <div ngbDropdownMenu aria-labelledby="dropDownFormats" class="drop-down-panel">
        <ng-container *ngFor="let item of es.filter.format">
          <div class="row pt-1 pb-1 {{ item.selected ? '' : 'text-secondary' }} format-item"
            (click)="item.selected = !item.selected; es.setFormatIsFiltered()">
            <div class="col">{{ item.name }}</div>
            <div class="col-2 text-end">
              <fa-icon [fixedWidth]="true" [icon]="item.selected ? faCheckSquare : faSquare"></fa-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- TYPES -->
    <div ngbDropdown class="d-inline-block m-1">
      <button class="btn btn-outline-secondary btn-sm" id="dropDownFormats" ngbDropdownToggle>
        <fa-icon [icon]="faFilter"
          [ngClass]="{ 'selected': es.filter.checker.typeIsFiltered || es.filter.checker.onlineIsFiltered }"
          [fixedWidth]="true" class="me-2"></fa-icon>Type
      </button>
      <div ngbDropdownMenu aria-labelledby="dropDownFormats" class="drop-down-panel">
        <ng-container *ngFor="let item of es.filter.type">
          <div class="row pt-1 pb-1 {{ item.selected ? '' : 'text-secondary' }} format-item"
            (click)="item.selected = !item.selected; es.setTypeIsFiltered()">
            <div class="col">{{ item.name }}</div>
            <div class="col-2 text-end">
              <fa-icon [fixedWidth]="true" [icon]="item.selected ? faCheckSquare : faSquare"></fa-icon>
            </div>
          </div>
        </ng-container>
        <div class="drop-dowm-separator"></div>
        <ng-container *ngFor="let item of es.filter.online">
          <div class="row pt-1 pb-1 {{ item.selected ? '' : 'text-secondary' }} format-item"
            (click)="item.selected = !item.selected; es.setOnlineIsFiltered()">
            <div class="col">{{ item.name }}</div>
            <div class="col-2 text-end">
              <fa-icon [fixedWidth]="true" [icon]="item.selected ? faCheckSquare : faSquare"></fa-icon>
            </div>
          </div>
        </ng-container>
        <div class="drop-dowm-separator"></div>
        <ng-container *ngFor="let item of es.filter.public">
          <div class="row pt-1 pb-1 {{ item.selected ? '' : 'text-secondary' }} format-item"
            (click)="item.selected = !item.selected; es.setPublicIsFiltered()">
            <div class="col">{{ item.name }}</div>
            <div class="col-2 text-end">
              <fa-icon [fixedWidth]="true" [icon]="item.selected ? faCheckSquare : faSquare"></fa-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- STATUS -->
    <div ngbDropdown class="d-inline-block m-1">
      <button class="btn btn-outline-secondary btn-sm" id="dropDownFormats" ngbDropdownToggle>
        <fa-icon [icon]="faFilter" [ngClass]="{ 'selected': es.filter.checker.statusIsFiltered }" [fixedWidth]="true"
          class="me-2"></fa-icon>Status
      </button>
      <div ngbDropdownMenu aria-labelledby="dropDownFormats" class="drop-down-panel">
        <ng-container *ngFor="let item of es.filter.status">
          <div class="row pt-1 pb-1 {{ item.selected ? '' : 'text-secondary' }} format-item"
            (click)="item.selected = !item.selected; es.setStatusIsFiltered()">
            <div class="col">{{ item.name }}</div>
            <div class="col-2 text-end">
              <fa-icon [fixedWidth]="true" [icon]="item.selected ? faCheckSquare : faSquare"></fa-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- ORGANIZER -->
    <!-- <div class="d-inline-block ms-2">
    <input type="text" class="form-control form-control-sm" placeholder="Organizer name">
  </div> -->

    <button class="btn text-red btn-sm m-1" (click)="es.clearFilters()">
      <!-- <fa-icon [icon]="" [fixedWidth]="true" class="me-2"></fa-icon> -->
      Clear filters
    </button>

    <div class="filler flex-grow-1"></div>

    <button class="btn btn-sm btn-blank text-secondary m-1" (click)="showNewEventForm()" *ngIf="isOrganizer">Create</button>

    <button class="btn btn-sm btn-blank text-secondary m-1" ngbTooltip="Configure your availability" container="body"
      (click)="showAvailabilityConfig()">Availability</button>

  </div>

  <div class="main-events" [ngClass]="{ 'mobile' : globals.isMobile }">

    <section class="p-1">

      <ng-container *ngIf="globals.isMobile; else desktopView">

        <div class="card-group pb-5">

          <div class="attending-info text-large text-secondary px-2 py-3">Attending</div>

          <ng-container *ngFor="let event of events | eventFilter : es.filter : true; trackBy : eventTracker">

            <app-event-card [event]="event"></app-event-card>

          </ng-container>

          <div class="attending-info text-large text-secondary px-2 py-3 mt-3">Open for registration</div>

          <ng-container *ngFor="let event of events | eventFilter : es.filter : false; trackBy : eventTracker">

            <app-event-card [event]="event"></app-event-card>

          </ng-container>

          <div class="create-event-wrapper" *ngIf="isOrganizer">
            <div class="btn btn-rouned btn-main" (click)="showNewEventForm()">Create</div>
          </div>

        </div>

      </ng-container>

      <ng-template #desktopView>

        <!-- FILTER INFO -->
        <div class="d-inline-block text-secondary m-1 pt-2 pb-2">
          <span *ngIf="es.noFilters">Showing public events with status "open for registration" and "in progress" within
            the
            next 180 days.</span>
          <span *ngIf="!es.noFilters">Showing events according to your filters.</span>
        </div>

        <div class="event-cards-wrapper p-1">

          <ng-container *ngFor="let event of events | eventFilter: es.filter; trackBy : eventTracker">

            <app-event-card [event]="event"></app-event-card>

          </ng-container>
        </div>
      </ng-template>

    </section>

  </div>


</ng-container>

import { Directive, ElementRef, AfterViewInit } from '@angular/core';


@Directive({
  selector: '[appScrollTo]'
})
export class ScrollToDirective implements AfterViewInit {

  constructor(private elRef: ElementRef) { }

  ngAfterViewInit() {
    this.elRef.nativeElement.scrollIntoView();
    this.elRef.nativeElement.children[0].classList.add('flash');
    setTimeout(() => {
      this.elRef.nativeElement.children[0].classList.remove('flash');
    }, 1000);
  }

}

<div class="modal-wrapper backdrop"
  [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')'}">
</div>

<div class="modal-content-wrapper position-relative">
  
  <div class="d-flex p-2 align-items-start position-absolute w-100 top-0 start-0" [style.zIndex]="10">
    <div class="avatar-wrapper" *ngIf="showPlayerAvatar">
      <app-player-avatar [playerDocId]="playerDocId"></app-player-avatar>
    </div>
    <div class="flex-grow-1"></div>
    <div class="btn btn-blank text-light"
      (click)="dismiss()"
      ngbTooltip="Close"
      placement="bottom-right"
      container="body">
      <fa-icon [icon]="icon.close"></fa-icon>
    </div>
  </div>
  
  <div class="p-0 d-flex flex-column h-100">
  
    <ng-container *ngIf="imageUris && selectedIndex">
      <ngb-carousel
        [style.height.%]="100"
        [showNavigationArrows]="showNavigation"
        [showNavigationIndicators]="showNavigation"
        [activeId]="selectedIndex"
        (slide)="onBeforeSlideChange($event)">
        <ng-container *ngFor="let image of imageUris; let i = index">
          <ng-template ngbSlide [id]="'slide-' + i">
            <div class="carousel-image-wrapper d-flex align-items-center justify-content-center h-100">
              <img class="carousel-image"
                [ngClass]="styleClass"
                [src]="image"
                [style]="imageStyle"
                [draggable]="false"
                (click)="onImagePress()"
                (mousedown)="onMouseDown($event)">
            </div>
          </ng-template>
        </ng-container>
      </ngb-carousel>
    </ng-container>

  </div>
    
  <div class="d-flex p-2 align-items-center justify-content-start position-absolute w-100 bottom-0 start-0" [style.zIndex]="10">
    <button class="btn btn-sm btn-blank" (click)="updateScale('neg')">
      <fa-icon [icon]="icon.minus"></fa-icon>
    </button>
    <input type="range"
      [ngbTooltip]="imageScaleText"
      placement="top"
      container="body"
      class="border-0 form-range bg-transparent"
      [style.maxWidth.rem]="6"
      [min]="100"
      [max]="200"
      [ngModel]="imageScale$ | async"
      (ngModelChange)="onScaleChanged($event)">
    <button class="btn btn-sm btn-blank" (click)="updateScale('pos')">
      <fa-icon [icon]="icon.plus"></fa-icon>
    </button>
    <div class="flex-grow-1"></div>
    <button class="btn btn-blank"
      ngbTooltip="Download image"
      placement="top-right"
      container="body"
      (click)="onDownloadImage()">
      <fa-icon [icon]="icon.download"></fa-icon>
    </button>
  </div>
  
</div>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  public avatars = [
    'assets/avatars/alifromcairo.jpg',
    'assets/avatars/birdsofparadise.jpg',
    'assets/avatars/forceofnature.jpg',
    'assets/avatars/junonefreet.jpg',
    'assets/avatars/juzamdjinn.jpg',
    'assets/avatars/llanowarelves.jpg',
    'assets/avatars/mahamotidjinn.jpg',
    'assets/avatars/royalassassin.jpg',
    'assets/avatars/sedgetroll.jpg',
    'assets/avatars/serendibdjinn.jpg',
    'assets/avatars/serendibefreet.jpg',
    'assets/avatars/serraangel.jpg',
    'assets/avatars/shivandragon.jpg',
    'assets/avatars/sorceressqueen.jpg',
    'assets/avatars/triskelion.jpg',
    'assets/avatars/twoheadedgiantofforiys.jpg'
  ];
  constructor() { }
}

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GlobalsService } from './../../../../../services/globals.service';
import { faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faTimes, faEdit, faFont, faImage, faShare, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, interval, Subject, timer, takeUntil } from 'rxjs';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { IPairingsPreviewTemplate } from 'src/app/pipes/pairings-view.pipe';
import { PairingsViewService } from './pairings-view.service'
import { faClock } from '@fortawesome/free-regular-svg-icons';
@Component({
  selector: 'app-event-pairings-view',
  templateUrl: './event-pairings-view.component.html',
  styleUrls: ['./event-pairings-view.component.css']
})
export class EventPairingsViewComponent implements OnInit, OnDestroy {
  @Input() matches: IPairingsPreviewTemplate[]
  @Input() eventName: string
  @Input() activeRound: number
  @Output() showMe = new EventEmitter()

  public faTimes = faTimes
  public faEdit = faEdit
  public faFont = faFont
  public faTwitch = faTwitch
  public faClock = faStopwatch
  public faImage = faImage
  public faYoutube = faYoutube
  public faShare = faShare

  public showReportInstructions: boolean = true
  public reportInstructions: any[] = [
    {
      step: 1,
      description: `Player A opens his or her player identification by pressing the button with the QR icon at the bottom.`,
      images: {
        left: {
          url: 'assets/how-to-report/step-01a.png',
          caption: 'Player A: Press the button at the bottom',
        },
        right: {
          url: 'assets/how-to-report/step-01b.png',
          caption: 'Player A: His or her player identification will open.',
        },
      },
    },
    {
      step: 2,
      description: `Player B will initiate the reporting sequence by pressing the button with the handshake icon at the bottom right.
      When asked to allow Tolaria to use your camera, please select <strong>Allow</strong>`,
      images: {
        left: {
          url: 'assets/how-to-report/step-02a.png',
          caption: 'Player B: Press the button at the bottom right.',
        },
        right: {
          url: 'assets/how-to-report/step-02b.png',
          caption: 'Player B: Allow use of camera.',
        },
      },
    },
    {
      step: 3,
      description: `Player B uses the camera on his or her device to capture Player A's identification.
      This will trigger the report slip to open for both Player A and Player B.
      However, only Player B (the initiator) will be able to edit.`,
      images: {
        left: {
          url: 'assets/how-to-report/step-03a.png',
          caption: 'Player B: Uses camera to capture the identification of Player A.',
        },
        right: {
          url: 'assets/how-to-report/step-03b.png',
          caption: 'Player B: The report slip opens for both players.',
        },
      },
    },
    {
      step: 4,
      description: `Player B shall now fill out the report slip. First select the winner, or in the case of a draw, select <strong>draw</strong>.
      Then set the result. Finally, thick the confirmation box at the bottom left.
      As soon as Player B has confirmed the result, the confirmation box shall be enabled for Player A.`,
      images: {
        left: {
          url: 'assets/how-to-report/step-04a.png',
          caption: 'Player B: Fill out the report slip.',
        },
        right: {
          url: 'assets/how-to-report/step-04b.png',
          caption: 'Player A: The confirmation box will be enabled.',
        },
      },
    },
    {
      step: 5,
      description: `Player A confirms the result by thicking the confirmation box at the bottom left.
      This will enable the <strong>Report</strong> button for Player B.
      Player B can now continue by pressing <strong>Report</strong>.`,
      images: {
        left: {
          url: 'assets/how-to-report/step-05a.png',
          caption: 'Player A: Confirms the result.',
        },
        right: {
          url: 'assets/how-to-report/step-05b.png',
          caption: 'Player B: Reports the result.',
        },
      },
    },
    {
      step: 6,
      description: `All done, the players have successfully reported the result and it immediately reflected in the pairings of the event.`,
      images: {
        left: {
          url: 'assets/how-to-report/step-06a.png',
          caption: 'All DONE!',
        },
        right: {
          url: 'assets/how-to-report/step-06b.png',
          caption: 'The result is shown in the pairings of the event.',
        },
      },
    },
  ]

  public batchCount: number = null
  private batchCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  private pairingBatches$: BehaviorSubject<IPairingsPreviewTemplate[][]> = new BehaviorSubject<IPairingsPreviewTemplate[][]>(null)
  public activePairingsBatch$: BehaviorSubject<IPairingsPreviewTemplate[]> = new BehaviorSubject<IPairingsPreviewTemplate[]>(null)
  private counter$: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  private destroyed$: Subject<boolean> = new Subject<boolean>()
  public activeStep$: BehaviorSubject<any> = new BehaviorSubject<any>(this.reportInstructions[0])
  private timerStarted: boolean = false
  private mouseTimer: any
  public mouseMoving: boolean = false

  constructor(
    public globals: GlobalsService,
    public widgetData: PairingsViewService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    console.log('EventPairingsView --> Init')
    document.documentElement.requestFullscreen()

    this.pairingBatches$.pipe(takeUntil(this.destroyed$)).subscribe(i => {
      if (i !== null && !this.timerStarted) {
        this.timerStarted = true
        this.startTimer()
      }
    })

    this.createBatches()

  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }



  public selectView(view: string): void {
    Object.entries(this.widgetData.infoView).forEach(([k, v], i) => this.widgetData.infoView[k] = false)
    this.widgetData.infoView[view] = true
    this.widgetData.editWidget = false
  }

  public get showInformation(): boolean {
    return Object.entries(this.widgetData.infoView).filter(([k, v], i) => v).length > 0
  }

  public get selectedView(): string {
    return Object.entries(this.widgetData.infoView).filter(([k, v], i) => v === true).map(([k, v], i) => {
      return {
        key: k
      }
    })[0].key
  }

  public tmpImage: string = ''
  public setTmpImage(base64: string): void {
    // this.widgetData.image = base64
    this.tmpImage = base64
  }
  public setImage(clear: boolean = false): void {
    if (clear) {
      this.widgetData.image = ''
      document.documentElement.requestFullscreen()
      return
    }
    this.widgetData.image = this.tmpImage
    document.documentElement.requestFullscreen()
  }

  public youtubeUrl: string = ''
  public setYoutubeUrl(clear: boolean = false): void {
    if (clear) {
      this.widgetData.youtubeUrl = ''
      return
    }
    this.widgetData.youtubeUrl = this.youtubeUrl
  }
  public get embeddedYoutubeUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.widgetData.youtubeUrl + '?rel=0&showinfo=0&autoplay=1')
  }


  public onMouseMove(event: any) {
    this.mouseMoving = true
    clearTimeout(this.mouseTimer)
    this.mouseTimer = setTimeout(() => {
      this.mouseMoving = false
    }, 5000)
  }

  public closeFullscreen() {
    this.showMe.emit(false);
  }

  private createBatches(): void {
    const pairingsBatches: IPairingsPreviewTemplate[][] = []
    this.batchCount = Math.ceil(this.matches.length / 20)
    const tmpMatches: IPairingsPreviewTemplate[] = JSON.parse(JSON.stringify(this.matches))
    let count = Math.ceil(this.matches.length / 20)
    do {
      const batch = tmpMatches.splice(0, 20)
      pairingsBatches.push(batch)
      count--
    }
    while (count > 0)
    this.pairingBatches$.next(pairingsBatches)
  }

  private startTimer(): void {

    interval(10000).pipe(takeUntil(this.destroyed$)).subscribe(i => {
      let counter = this.counter$.getValue()
      counter++
      if (counter % 5 === 0) {
        counter = 0
      }
      this.counter$.next(counter)
      this.activeStep$.next(this.reportInstructions[counter])
    })

    timer(0, 7000).pipe(takeUntil(this.destroyed$)).subscribe(i => {
      let counter = this.batchCounter$.getValue()
      this.activePairingsBatch$.next(this.pairingBatches$.getValue()[counter])
      counter++
      if (counter % this.batchCount === 0) {
        counter = 0
      }
      this.batchCounter$.next(counter)
    })

  }

  public get activeBatchNum(): number {
    return this.batchCounter$.getValue() === 0 ? this.batchCount : this.batchCounter$.getValue()
  }

}


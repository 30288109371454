<nav class="navbar navbar-expand-sm bg-body-tertiary position-fixed top-0 w-100 shadow bg-opacity-25 py-0"
  [style.zIndex]="10">
  <div class="container-fluid">
    <div class="title cursor-pointer px-3" [routerLink]="['/dashboard']">Tolaria</div>
    <button class="navbar-toggler" (click)="showMenu = !showMenu">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="p-2 navbar-collapse collapse justify-content-end" [ngClass]="{'show': showMenu}">
      <button *ngIf="showAbout" [routerLink]="['/about']" class="btn btn-sm btn-light me-2 hide-on-mobile">About</button>
      <button [routerLink]="['/about/privacy-policy']" class="btn btn-sm btn-light me-2 hide-on-mobile">Privacy Policy</button>
      <button [routerLink]="['/about/terms-of-use']" class="btn btn-sm btn-light me-2 hide-on-mobile">Terms of Use</button>
      <button [routerLink]="['/auth/sign-in']" class="btn btn-sm btn-primary">Sign in</button>
    </div>
  </div>
</nav>

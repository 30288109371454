import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { IEventDetails, IMatchAppointment, IMatchData, IPlayerMini, IMatchAvailabilityMeta } from 'tolaria-cloud-functions/src/_interfaces';
import { ICalendarMeta, IMatchFilter, IMatchMeta, IMatchOpponent, IMatchTournament } from '../utilitiez/matches.interfaces';


@Injectable({
  providedIn: 'root'
})
export class MatchListingService {

  private _availabilityFrom: number = 0
  private _matchObserver$: Observable<IMatchData[]>
  private _eventObserver$: Observable<IEventDetails[]>
  private _appointmentObserver$: Observable<IMatchAppointment[]>
  private _availabilitySlotsObserver$: Observable<IMatchAvailabilityMeta[]>
  private _observerCreated: boolean = false
  public matches$ = new BehaviorSubject<IMatchMeta[]>(null)
  public ongoingMatches$ = new BehaviorSubject<IMatchMeta[]>(null)
  public events$ = new BehaviorSubject<IEventDetails[]>(null)
  public appointments$ = new BehaviorSubject<IMatchAppointment[]>(null)
  public calendarItems$ = new BehaviorSubject<ICalendarMeta[]>(null)
  public calendarItemsReady$ = new BehaviorSubject<boolean>(false)
  public filters: IMatchFilter = {
    ready: false,
    filterString: '',
    showFilters: false,
    type: {
      competetive: true,
      casual: true,
    },
    status: {
      reported: false,
      unreported: true,
      inactive: false,
    },
    schedulable: {
      schedulable: false,
      hasUnreadAppointments: true,
      hasAcceptedAppointments: true,
      hasRejectedAppointments: true,
    },
    created: {
      from: null,
      to: null,
    },
    opponents: {
      available: [],
      selected: [],
    },
    tournaments: {
      available: [],
      selected: [],
    },
    calendar: {
      showAttendingTournaments: true,
    },
  }
  public filters$ = new BehaviorSubject<IMatchFilter>(this.filters)

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly playerNames: PlayerNameService,
  ) {
    console.log('MatchListingService:: constructing service. Observer created:', this._observerCreated)
    this.playerNames.serviceReady$.subscribe((ready) => {
      if (ready) {
        console.log('MatchListingService:: minified player service ready')
        if (!this._observerCreated) {
          console.log('MatchListingService:: observer not created, lets do it!')
          this.initObserver()
        }
      }
    })

  }

  public updateFilters(): void {
    this.filters$.next(this.filters)
  }

  private initObserver() {
    this._observerCreated = true
    console.log('MatchListingService:: init user match observer')

    this._matchObserver$ = this.firestore
      .collection<IMatchData>('matches', ref => ref
        .where('playerDocIds', 'array-contains', this.playerId)
        .orderBy('eventDocId', 'asc')
        .orderBy('roundNumber', 'asc'))
      .valueChanges()

    this._eventObserver$ = this.firestore
      .collection<IEventDetails>('events', ref => ref
        .where('playerDocIds', 'array-contains', this.playerId))
      .valueChanges()

    this._appointmentObserver$ = this.firestore
      .collection<IMatchAppointment>('matchAppointments', ref => ref
        .where('playerDocIds', 'array-contains', this.playerId)
        .orderBy('timestampCreated', 'desc'))
      .valueChanges()

    const d = new Date()
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    d.setMilliseconds(0)
    d.setDate(d.getDate() - 20)
    this._availabilityFrom = parseInt((d.getTime() / 1000).toFixed(0), 10)
    console.log(`MatchListingService:: Fetching all availability greater than ${this._availabilityFrom} for player with doc id ${this.playerId}`)

    this._availabilitySlotsObserver$ = this.firestore
      .collection<IMatchAvailabilityMeta>('matchAvailability', ref => ref
        .where('timestampFrom', '>=', this._availabilityFrom)
        .where('playerDocId', '==', this.playerId)
        .orderBy('timestampFrom', 'asc'))
      .valueChanges()

    this._availabilitySlotsObserver$.subscribe(i => console.log('availability emitted', i))

    // bind all observers and subscribe to load match listing metadata
    combineLatest([this._matchObserver$, this._eventObserver$, this._appointmentObserver$])
      .pipe(map(([matches, events, appointments]) => {
        this.events$.next(events)
        this.appointments$.next(appointments)
        const mappedMatches = this._mapMatches(matches, events, appointments)
        this._setOngoingMatchData(mappedMatches)
        return mappedMatches
      }))
      .subscribe(m => {
        console.log('MatchListingService:: mapped matches emitted', m)
        this.matches$.next(m)
        this._updateFilters(m)
        if (!this.filters.ready) {
          this.filters.ready = true
        }
      })

    combineLatest([this._eventObserver$, this._appointmentObserver$, this._availabilitySlotsObserver$])
      .pipe(map(([events, appointments, slots]) => {
        return this._mapCalendarItems(events, appointments, slots)
      }))
      .subscribe(i => {
        console.log('MatchListingService:: mapped calendar items emitted', i)
        this.calendarItems$.next(i)
        if (!this.calendarItemsReady$.getValue()) {
          this.calendarItemsReady$.next(true)
        }
      })

  }

  private _updateFilters(m: IMatchMeta[]): void {

    const opponents: IMatchOpponent[] = []
    m.forEach(i => {
      if (!opponents.find(o => o.id === i.eventDocId)) {
        opponents.push({
          name: i.opponentName,
          id: i.opponentUid,
        })
      }
    })

    const tournaments: IMatchTournament[] = []
    m.forEach(i => {
      if (!tournaments.find(o => o.id === i.eventDocId)) {
        tournaments.push({
          name: i.eventName,
          id: i.eventDocId,
        })
      }
    })

    this.filters.opponents.available = opponents.sort((a, b) => a.name.localeCompare(b.name))
    this.filters.tournaments.available = tournaments.sort((a, b) => a.name.localeCompare(b.name))

  }

  private _mapMatches(m: IMatchData[], e: IEventDetails[], a: IMatchAppointment[]): IMatchMeta[] {
    return m.map(match => {
      // get the players and opponents positions in the match document
      const playerIs = match.isType === 'swiss-team'
        ? match.player1.teamPlayerDocIds.includes(this.playerId) ? 'player1' : 'player2'
        : match.player1.playerDocId === this.playerId ? 'player1' : 'player2'
      const opponentIs = playerIs === 'player1' ? 'player2' : 'player1'

      // get player seat
      let playerSeat = null
      if (match.isType === 'swiss-team') {
        playerSeat = ['A', 'B', 'C'][match[playerIs].teamPlayerDocIds.findIndex(i => i === this.playerId)]
      }

      // get opponent if possible
      let opponent = undefined
      if (!['*** LOSS ***', '*** BYE ***', 'temp__'].includes(match[opponentIs].playerDocId)) {
        opponent = this.playerNames.getPlayerById(match[opponentIs].playerDocId)
      }

      // get event if possible
      let event = undefined
      let eventToday = false
      if (match.isType !== 'casual-match') {
        event = e.find(i => i.docId === match.eventDocId)
          ? e.find(i => i.docId === match.eventDocId)
          : undefined
        if (event) {
          const timestampNow = Date.now()
          eventToday = timestampNow > (event as IEventDetails).details.datestampFrom && timestampNow < (event as IEventDetails).details.datestampTo
        }
      }

      // create the meta data to be used
      const matchMeta: IMatchMeta = {
        matchDocId: match.docId,
        opponentDocId: match[opponentIs].playerDocId,
        opponentUid: match[opponentIs].playerUid,
        opponentName: opponent
          ? (opponent as IPlayerMini).name.display
          : match[opponentIs].displayName,
        eventDocId: match.eventDocId,
        eventName: event
          ? (event as IEventDetails).details.name
          : 'Casual Match',
        eventStatus: event
          ? (event as IEventDetails).statusCode
          : 0,
        isPosted: !event
          ? true
          : (event as IEventDetails).details.structure.isSwiss
            ? (event as IEventDetails).activeRound > match.roundNumber
              ? true
              : (event as IEventDetails).activeRound === match.roundNumber
                ? (event as IEventDetails).statusCode > 1 && (event as IEventDetails).statusCode !== 13
                : false
            : true,

        isScheduleable: event
          ? (event as IEventDetails).details.isMultiDay
          : true,
        isCasual: match.isType === 'casual-match',
        isCompetitive: match.isType !== 'casual-match',
        isReported: match.isReported,
        isNotReported: !match.isReported,
        isInactive: match.deleted,
        isWin: match[playerIs].isWinner,
        isDraw: match.isDraw,
        isLoss: match[opponentIs].isWinner,
        gameWins: match[playerIs].wins,
        gameLosses: match[playerIs].losses,
        gameDraws: match[playerIs].draws,
        isCreatedByThisUser: match.createdByUid === this.playerUid,
        timestampCreated: match.timestampCreated,
        timestampReported: match.timestampReported,
        roundNumber: match.roundNumber,
        hasAppointments: a.filter(i => i.matchDocId === match.docId).length > 0,
        appointments: {
          total: a.filter(i => i.matchDocId === match.docId).length,
          unread: a.filter(i => i.matchDocId === match.docId && !i.isAccepted && !i.isRejected),
          accepted: a.filter(i => i.matchDocId === match.docId && i.isAccepted),
          rejected: a.filter(i => i.matchDocId === match.docId && i.isRejected),
        },
        unreadAppointments: a.filter(i => i.matchDocId === match.docId && !i.opponentHasRead).length > 0,
        acceptedAppointments: a.filter(i => i.matchDocId === match.docId && i.isAccepted).length > 0,
        rejectedAppointments: a.filter(i => i.matchDocId === match.docId && i.isRejected).length > 0,
        createdByUid: match.createdByUid,
        player1DocId: match.player1.playerDocId,
        player2DocId: match.player2.playerDocId,
        showMatchRoomLink: match.isType === 'casual-match'
          ? true
          : !event
            ? false
            : (event as IEventDetails).isOnlineTournament,
        table: match.tableNumber,
        seat: playerSeat,
        tournamentLink: match.isType === 'casual-match'
          ? []
          : event
            ? ['/tournament', (event as IEventDetails).docId]
            : [],
        tournamentOpen: match.isType === 'casual-match'
          ? false
          : event
            ? (event as IEventDetails).statusCode !== 8
            : false,
        tournamentToday: match.isType === 'casual-match'
          ? false
          : eventToday,
        tournamentIsSingleDay: event
          ? (event as IEventDetails).details.isMultiDay === false
          : false,
        isActive: event
          ? eventToday ? (event as IEventDetails).activeRound === match.roundNumber && !match.isReported : false
          : false
      }

      return matchMeta
    })
  }

  private _setOngoingMatchData(mappedMatches: IMatchMeta[]): void {
    this.ongoingMatches$.next(mappedMatches.filter(i => i.isActive))
  }

  private _mapCalendarItems(e: IEventDetails[], a: IMatchAppointment[], s: IMatchAvailabilityMeta[]): ICalendarMeta[] {

    const appointments = a.filter(a => !a.isCancelled && !a.isRejected).map((appointment) => {

      const styleClasses = ['calendar-item-match']

      if (appointment.eventDocId === 'casual-match') { styleClasses.push('casual') }
      else { styleClasses.push('competetive') }

      if (appointment.isAccepted) { styleClasses.push('accepted') }
      else { styleClasses.push('waiting-response') }

      const tournament = e.find(e => e.docId === appointment.eventDocId)

      const meta: ICalendarMeta = {
        className: styleClasses.join(' '),
        allDay: false,
        title: appointment.eventDocId === 'casual-match' ? 'Casual Match' : 'Event Match',
        start: appointment.timestampFrom * 1000,
        startStr: new Date(appointment.timestampFrom * 1000).toISOString(),
        end: appointment.timestampTo * 1000,
        endStr: new Date(appointment.timestampTo * 1000).toISOString(),
        metadata: {
          docId: appointment.docId,
          type: 'MatchAppointment',
          appointmentDoc: appointment,
          tournamentDoc: tournament ? tournament : null,
          availabilityMeta: null,
        }
      }

      // sanity check on start/end
      if (meta.start === undefined || meta.start === null) {
        meta.start = 0
      }
      if (meta.end === undefined || meta.end === null) {
        meta.end = 0
      }

      return meta

    })

    const tournaments = e.filter(e => e.playerDocIds.includes(this.playerId)).map((item) => {
      const styleClasses = ['calendar-item-event']
      if (window.innerWidth < 500) { styleClasses.push('small-screen') }
      if (item && item.isOnlineTournament) { styleClasses.push('online') }
      if (item && !item.isOnlineTournament) { styleClasses.push('in-person') }
      if (item && item.details && item.details.isMultiDay) { styleClasses.push('multi-day') }
      if (item && item.details && !item.details.isMultiDay) { styleClasses.push('single-day') }

      const meta: ICalendarMeta = {
        className: styleClasses.join(' '),
        allDay: item?.details?.isMultiDay ? item.details.isMultiDay : false,
        title: item.details.name,
        start: item.details.hasOwnProperty('dateStamp') ? item.details.dateStamp : item.details.datestampFrom,
        startStr: new Date(item.details.hasOwnProperty('dateStamp') ? item.details.dateStamp : item.details.datestampFrom).toISOString(),
        end: item.details.hasOwnProperty('dateStamp') ? item.details.dateStamp : item.details.datestampTo,
        endStr: new Date(item.details.hasOwnProperty('dateStamp') ? item.details.dateStamp : item.details.datestampTo).toISOString(),
        metadata: {
          docId: item.docId,
          type: 'Tournament',
          appointmentDoc: null,
          tournamentDoc: item,
          availabilityMeta: null,
        }
      }

      // sanity check on start/end
      if (meta.start === undefined || meta.start === null) {
        meta.start = 0
      }
      if (meta.end === undefined || meta.end === null) {
        meta.end = 0
      }

      return meta
    })

    const slots = s.map((availablity) => {
      const meta: ICalendarMeta = {
        className: 'calendar-item-availablity',
        title: 'Availablity Slot',
        start: availablity.timestampFrom * 1000,
        startStr: new Date(availablity.timestampFrom * 1000).toISOString(),
        end: availablity.timestampTo * 1000,
        endStr: new Date(availablity.timestampTo * 1000).toISOString(),
        display: 'background',
        metadata: {
          docId: availablity.docId,
          type: 'Availability',
          appointmentDoc: null,
          tournamentDoc: null,
          availabilityMeta: availablity,
        }
      }
      return meta
    })

    return [...appointments, ...tournaments, ...slots]
  }

  private get playerId(): string {
    return this.playerNames.currentPlayersMini.id
  }

  private get playerUid(): string {
    return this.playerNames.currentPlayersMini.uid
  }


  public get opponents(): string[] {
    if (this.matches$.getValue() === undefined || this.matches$.getValue() === null) {
      return []
    }
    return [...new Set(this.matches$.getValue().map(i => i.opponentName))].sort((a, b) => a.localeCompare(b))
  }

}


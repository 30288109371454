<div class="modal-header text-large d-flex flex-wrap gap-3">
  <div class="flex-grow-1">New match appointment</div>
  <div class="btn-group flex-md-grow-0 flex-grow-1">
    <button class="btn {{ slotsActive ? 'btn-secondary' : 'btn-outline-secondary' }}" (click)="setSlotsActive(true)">Time slots</button>
    <button class="btn {{ slotsActive ? 'btn-outline-secondary' : 'btn-secondary' }}" (click)="setSlotsActive(false)">Planner</button>
  </div>
</div>
<div class="modal-body d-flex gap-3 pb-3" [ngClass]="{ 'flex-column' : isMobile }">

  <ng-container *ngIf="dataReady$ | async; else loading">

    <ng-container *ngIf="slotsActive">

      <ngb-datepicker #picker="ngbDatepicker"
        [ngClass]="{ 'sticky-top top-0 flex-wrap': !isMobile }"
        outsideDays="hidden"
        [(ngModel)]="selectedDate"
        [dayTemplate]="customDay"
        [displayMonths]="isMobile ? 1 : 2"
        [style.minWidth.rem]="16"
        [style.maxWidth.rem]="isMobile ? 24 : 16"
        (dateSelect)="onDateSelection($event)">
      </ngb-datepicker>
  
  
      <div class="d-flex flex-column gap-3 flex-grow-1">
  
        <div class="slots d-flex flex-column gap-3 flex-grow-1">
          <ng-container *ngIf="hasSlots; else noSlots">
  
            <ng-container *ngIf="slotsOnSelectedDate | async as slots">
  
              <div class="text-large">Matching Slots</div>
  
              <div class="mb-3 slots d-flex gap-3 flex-wrap">
                <div *ngFor="let slot of slots.shared"
                  class="btn d-flex align-items-center gap-1 position-relative {{slot.status}}"
                  [ngClass]="{ 'btn-primary' : slot.selected, 'btn-outline-secondary' : !slot.selected, 'flex-grow-1 justify-content-center' : isMobile }"
                  (click)="onSlotPressed(slot)">
                  <app-timestamp-clock [timestamp]="slot.timestampFrom" displayType="time"></app-timestamp-clock>
                  <span>-</span>
                  <app-timestamp-clock [timestamp]="slot.timestampTo" displayType="time"></app-timestamp-clock>
                </div>
              </div>
  
              <div class="text-large">Opponent Slots</div>
  
              <div class="mb-3 slots d-flex gap-3 flex-wrap">
                <div *ngFor="let slot of slots.opponent"
                  [ngClass]="{ 'btn-primary' : slot.selected, 'btn-outline-secondary' : !slot.selected, 'flex-grow-1 justify-content-center' : isMobile }"
                  class="btn d-flex align-items-center gap-1  position-relative {{slot.status}}"
                  (click)="onSlotPressed(slot)">
                  <app-timestamp-clock [timestamp]="slot.timestampFrom" displayType="time"></app-timestamp-clock>
                  <span>-</span>
                  <app-timestamp-clock [timestamp]="slot.timestampTo" displayType="time"></app-timestamp-clock>
                </div>
              </div>
  
              <div class="text-large">Your Slots</div>
  
              <div class="mb-3 slots d-flex gap-3 flex-wrap">
                <div *ngFor="let slot of slots.player"
                  [ngClass]="{ 'btn-primary' : slot.selected, 'btn-outline-secondary' : !slot.selected, 'flex-grow-1 justify-content-center' : isMobile }"
                  class="btn d-flex align-items-center gap-1  position-relative {{slot.status}}"
                  (click)="onSlotPressed(slot)">
                  <app-timestamp-clock [timestamp]="slot.timestampFrom" displayType="time"></app-timestamp-clock>
                  <span>-</span>
                  <app-timestamp-clock [timestamp]="slot.timestampTo" displayType="time"></app-timestamp-clock>
                </div>
              </div>
  
            </ng-container>
  
          </ng-container>
          <ng-template #noSlots></ng-template>
        </div>
    
      </div>

    </ng-container>


    <div *ngIf="!slotsActive"
    class="planner d-flex flex-column p-3">

      <div [style.minWidth.rem]="6" class="flex-grow-1 flex-md-grow-0">Start</div>
      
      <div class="d-flex align-items-center gap-3 flex-grow-1 flex-grow-lg-0 mb-3">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="dp"
            [(ngModel)]="plannerDateStart"
            ngbDatepicker
            #dFrom="ngbDatepicker"/>
          <button class="btn btn-outline-secondary" (click)="dFrom.toggle()" type="button">
            <fa-icon [icon]="icon.calendar" [fixedWidth]="true"></fa-icon>
          </button>
        </div>
        <ngb-timepicker [(ngModel)]="from" [minuteStep]="15"></ngb-timepicker>
      </div>

      <div [style.minWidth.rem]="6" class="flex-grow-1 flex-md-grow-0">End</div>

      <div class="d-flex align-items-center gap-3 flex-grow-1 flex-grow-lg-0 mb-3">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="dp"
            [(ngModel)]="plannerDateEnd"
            ngbDatepicker
            #dTo="ngbDatepicker"/>
          <button class="btn btn-outline-secondary" (click)="dTo.toggle()" type="button">
            <fa-icon [icon]="icon.calendar" [fixedWidth]="true"></fa-icon>
          </button>
        </div>
        <ngb-timepicker [(ngModel)]="to" [minuteStep]="15"></ngb-timepicker>
      </div>
      
    </div>
    
  </ng-container>

</div>
<div class="modal-footer d-flex align-items-center justify-content-end">
  <button class="btn btn-blank" (click)="onCancelPress()">Cancel</button>
  <button class="btn btn-blank" [disabled]="disableCreate" (click)="create()">Create</button>
</div>

<ng-template #loading>
  <div class="d-flex flex-column align-items-center p-5 justify-content-center">
    <div class="d-flex align-items-center text-secondary">
      <div class="spinner-border spinner-border-sm me-2" role="status"></div>
      <span>Loading availability slots, please wait...</span>
    </div>
  </div>
</ng-template>


<ng-template
	#customDay
	let-date
	let-currentMonth="currentMonth"
	let-selected="selected"
	let-disabled="disabled"
	let-focused="focused">
	<span
		class="custom-day"
		[class.available]="playerHasAvailability(date)"
    [class.opponentAvailable]="opponentHasAvailability(date)"
		[class.focused]="focused"
		[class.bg-primary]="selected"
		[class.hidden]="date.month !== currentMonth"
		[class.text-secondary]="disabled">
		{{ date.day }}
	</span>
</ng-template>

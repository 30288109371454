export interface ICountryCurrency {
  code: string
  name: string
  symbol: string
}
export interface ICountryLanguage {
  iso639_1: string
  iso639_2: string
  name: string
  nativeName: string
}
export interface ICountryListItem {
  name: string
  topLevelDomain: string[]
  alpha2Code: string
  alpha3Code: string
  callingCodes: number[]
  capital: string
  altSpellings: string[]
  region: string
  subregion: string
  population: number
  latlng: number[]
  demonym: string
  area: number
  gini: number
  timezones: string[]
  borders: string[]
  nativeName: string
  numericCode: number
  currencies: ICountryCurrency[]
  languages: ICountryLanguage[]
  flag: string
}

import { take, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private auth: AuthService
  ) { }

  canActivate(): Observable<boolean> {
    const canDoIt = this.auth.user$.pipe(
      take(1),
      map(user => user.role === 'admin' ? true : false),
    );
    // console.log(canDoIt);
    return canDoIt;
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-busy-spinner',
  templateUrl: './busy-spinner.component.html',
  styleUrls: ['./busy-spinner.component.css'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
export class BusySpinnerComponent {
  @Input() spinnerText: string = ''

  constructor() { }

  public get showSpinnerText(): boolean {
    return this.spinnerText === ''
      ? false
      : true
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, take, tap, withLatestFrom } from 'rxjs/operators';
import { ILocalDeviceSettings } from '../components';
import { WebRtcCallerRole } from '../components/events/match-room/webrtc-helper.service';

export enum ConstraintsLevel {
  BASIC = 'BASIC',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  AVATAR = 'AVATAR'
}

@Injectable({
  providedIn: 'root'
})
export class MediaStreamsService {

  public currentCallerRole$ = new BehaviorSubject<WebRtcCallerRole>(undefined)
  public streamReady$ = new BehaviorSubject<boolean>(false);
  public videoEnabled$ = new BehaviorSubject<boolean>(false);
  public audioInputEnabled$ = new BehaviorSubject<boolean>(false);
  public audioOutputMuted$ = new BehaviorSubject<boolean>(true);
  public connectedMediaDevices$ = new BehaviorSubject<MediaDeviceInfo[]>([]);
  public selectedVideoInputDeviceId$ = new BehaviorSubject<string>(undefined);
  public selectedAudioInputDeviceId$ = new BehaviorSubject<string>(undefined);
  public selectedAudioOutputDeviceId$ = new BehaviorSubject<string>(undefined);
  // private LOCAL_STREAM$ = new Subject<MediaStream>();
  private LOCAL_STREAM$ = new BehaviorSubject<MediaStream>(null);
  public localStream$: Observable<MediaStream> = this.LOCAL_STREAM$.pipe(
    filter((stream: MediaStream) => stream !== undefined && stream !== null),
    shareReplay(1)
  );

  private videoOrAudioDeviceChanged$ = merge(this.selectedVideoInputDeviceId$, this.selectedAudioInputDeviceId$);
  private videoEnabledToggled$: Subject<boolean> = new Subject<boolean>();
  private audioInputEnabledToggled$: Subject<boolean> = new Subject<boolean>();

  constructor() {
    console.log('MediaStreamsService:: Service created');
  }

  public initUserMedia(constraints: ConstraintsLevel = ConstraintsLevel.MEDIUM) {
    this.streamReady$.next(false)
    this.updateLocalStream(constraints);
    this.setupMediaEventHandlers(constraints);
    this.getConnectedMediaDevices();
  }

  public toggleVideoEnabled() {
    this.videoEnabledToggled$.next(true);
  }

  public toggleAudioInputEnabled() {
    this.audioInputEnabledToggled$.next(true);
  }

  public toggleAudioOutputMuted() {
    console.log('MediaStreamsService:: MediaStreamService:: toggle audioOutputMuted > ', !this.audioOutputMuted$.getValue());
    this.audioOutputMuted$.next(!this.audioOutputMuted$.getValue());
  }

  private setupMediaEventHandlers(constraints: ConstraintsLevel) {
    // First time we discover at least one connected Media Device, we should try and set any user stored
    this.connectedMediaDevices$.pipe(
      filter((devices: MediaDeviceInfo[]) => devices.length > 0),
      map(this.setUserDefaultMedia),
      take(1)
    ).subscribe();

    // Whenever the selected AUDIO or VIDEO device changed (user or code initiated)
    this.videoOrAudioDeviceChanged$.pipe(
      filter((Boolean))
    ).subscribe((deviceId) => {
      console.log('MediaStreamsService:: Video OR Audio changed with ID: ', deviceId);
      console.log('MediaStreamsService:: Media device CHANGED');
      // Stop previous stream
      // previousStream.getTracks().forEach(track => {
      //   track.stop();
      // });
      this.savePreferredUserMediaSettings();
      this.updateLocalStream(constraints);
    });

    // Start listening to [Video TOGGLED] and update videoEnabled$
    this.videoEnabledToggled$.pipe(map((_) => {
      console.log(`MediaStreamsService:: Video enabled toggled, setting enabled state to ${!this.videoEnabled$.getValue()}`);
      this.videoEnabled$.next(!this.videoEnabled$.getValue())
    })).subscribe();

    // Start listening to [Video STATE]
    this.videoEnabled$
      .pipe(withLatestFrom(this.LOCAL_STREAM$))
      .subscribe(([state, stream]) => {
        console.log(`MediaStreamsService:: Video ${state ? 'enabled' : 'disabled'}`);
        if (stream && stream.getVideoTracks().length > 0) {
          stream.getVideoTracks()[0].enabled = state;
        }
      })

    // Start listening to [Audio Input TOGGLED] and update audioInputEnabled$
    this.audioInputEnabledToggled$.pipe(map((_) => {
      console.log(`MediaStreamsService:: Audio Input enabled toggled, setting enabled state to ${!this.audioInputEnabled$.getValue()}`);
      this.audioInputEnabled$.next(!this.audioInputEnabled$.getValue())
    })).subscribe();

    // Start listening to [Audio Input STATE]
    this.audioInputEnabled$
      .pipe(withLatestFrom(this.LOCAL_STREAM$))
      .subscribe(([state, stream]) => {
        console.log(`MediaStreamsService:: Audio Input ${state ? 'enabled' : 'disabled'}`);
        if (stream && stream.getAudioTracks().length > 0) {
          stream.getAudioTracks()[0].enabled = state
        }
      })
  }

  public getConnectedMediaDevices = () => {
    console.log('MediaStreamsService:: Looking for connected [Media Devices]...');
    navigator.mediaDevices.enumerateDevices()
      .then((deviceInfos) => {
        console.log(`MediaStreamsService:: Found ${deviceInfos.length} connected [Media Devices]:`, deviceInfos);
        this.connectedMediaDevices$.next(deviceInfos);
      })
      .catch((err) => {
        console.log('MediaStreamsService:: Could not aquire users [Media Devices]::', err);
      });
  }

  private setUserDefaultMedia = () => {
    console.log('MediaStreamsService:: Set Default user media');
    const localSettings: ILocalDeviceSettings = JSON.parse(localStorage.getItem('deviceInfos'));
    if (localSettings) {
      console.log('MediaStreamsService:: Existing device settings found');
      // audio input
      if (this.connectedMediaDevices$.getValue().some(device => device.deviceId === localSettings.audioInputDeviceId)) {
        this.setAudioInputDevice(localSettings.audioInputDeviceId);
      }
      // audio output
      if (this.connectedMediaDevices$.getValue().some(device => device.deviceId === localSettings.audioOutputDeviceId)) {
        this.setAudioOutputDevice(localSettings.audioOutputDeviceId);
      }
      // video
      if (this.connectedMediaDevices$.getValue().some(device => device.deviceId === localSettings.videoInputDeviceId)) {
        this.setVideoDevice(localSettings.videoInputDeviceId);
      }
    }
    else {
      console.log('MediaStreamsService:: No stored user media in LocalStorage');
    }
    if (!localSettings) {
      this.useAnyAvailableUserMediaDevice();
    }
  }

  private useAnyAvailableUserMediaDevice = () => {
    this.setAudioInputDevice(this.connectedMediaDevices$.getValue().find(x => x.kind === 'audioinput').deviceId);
    this.setAudioOutputDevice(this.connectedMediaDevices$.getValue().find(x => x.kind === 'audiooutput').deviceId);
    this.setVideoDevice(this.connectedMediaDevices$.getValue().find(x => x.kind === 'videoinput').deviceId);
  }

  private savePreferredUserMediaSettings = () => {
    // store device settings in local storage
    const newLocalSettings: ILocalDeviceSettings = {
      audioInputDeviceId: this.selectedAudioInputDeviceId$.getValue(),
      audioOutputDeviceId: this.selectedAudioInputDeviceId$.getValue(),
      videoInputDeviceId: this.selectedVideoInputDeviceId$.getValue()
    };
    console.log('MediaStreamsService:: new local settings:', newLocalSettings);
    if (!!newLocalSettings.audioInputDeviceId && !!newLocalSettings.videoInputDeviceId && !!newLocalSettings.audioOutputDeviceId) {
      localStorage.setItem('deviceInfos', JSON.stringify(newLocalSettings));
    }
  }

  public setAudioInputDevice = (deviceId: string) => {
    this.selectedAudioInputDeviceId$.next(deviceId);
  }

  public setAudioOutputDevice = (deviceId: string) => {
    this.selectedAudioOutputDeviceId$.next(deviceId);
  }

  public setVideoDevice = (deviceId: string) => {
    this.selectedVideoInputDeviceId$.next(deviceId);
  }

  private async updateLocalStream(constraints: ConstraintsLevel) {
    console.log('MediaStreamsService:: Updating Local Stream...');
    await navigator.mediaDevices.getUserMedia(this.getMediaContraints(constraints))
      .then(async (stream) => {
        console.log('MediaStreamsService:: got a new stream', stream);
        console.log('MediaStreamsService:: lets kill the old one before updating the observer');
        await this.killLocalStream()
        console.log('MediaStreamsService:: old stream killed, updatng the observer');
        this.LOCAL_STREAM$.next(stream);
        this.videoEnabled$.next(stream.getVideoTracks()[0].enabled);
        // enable audio track if caller role not ORGANIZER
        if (this.currentCallerRole$.getValue() === undefined || this.currentCallerRole$.getValue() !== WebRtcCallerRole.ORGANIZER) {
          this.audioInputEnabled$.next(stream.getAudioTracks()[0].enabled);
        }
        // set ready state if not already set
        if (this.streamReady$.getValue() === false) {
          this.streamReady$.next(true)
        }
      })
      .catch((err) => {
        console.error('MediaStreamsService:: Error trying to getUserMedia::', err);
        // call again with fallback to minimum constraints = device ID only
        if (err.name === 'OverconstrainedError') {
          this.updateLocalStream(ConstraintsLevel.BASIC);
        }
      });
  }

  private getMediaContraints(constraints: ConstraintsLevel) {

    let mediaConstraints: MediaStreamConstraints = {
      video: true,
      audio: true
    };

    switch (constraints) {
      case ConstraintsLevel.LOW:
        mediaConstraints = {
          video: {
            width: { min: 320, max: 480 },
            height: { min: 180, max: 270 },
            aspectRatio: 16 / 9,
            frameRate: { max: 20 },
            deviceId: {
              exact: this.selectedVideoInputDeviceId$.getValue()
            }
          },
          audio: {
            deviceId: {
              exact: this.selectedAudioInputDeviceId$.getValue()
            }
          }
        };
        break;

      case ConstraintsLevel.MEDIUM:
        mediaConstraints = {
          video: {
            width: { min: 480, ideal: 600, max: 853.33 },
            height: { min: 270, ideal: 338, max: 480 },
            aspectRatio: 16 / 9,
            frameRate: { max: 20 },
            deviceId: {
              exact: this.selectedVideoInputDeviceId$.getValue()
            }
          },
          audio: {
            deviceId: {
              exact: this.selectedAudioInputDeviceId$.getValue()
            }
          }
        };
        break;

      case ConstraintsLevel.HIGH:
        mediaConstraints = {
          video: {
            width: { min: 853, ideal: 1280, max: 1920 },
            height: { min: 480, ideal: 720, max: 1080 },
            aspectRatio: 16 / 9,
            frameRate: { max: 20 },
            deviceId: {
              exact: this.selectedVideoInputDeviceId$.getValue()
            }
          },
          audio: {
            deviceId: {
              exact: this.selectedAudioInputDeviceId$.getValue()
            }
          }
        };
        break;

        case ConstraintsLevel.AVATAR:
          mediaConstraints = {
            video: {
              width: { min: 200, ideal: 300, max: 400 },
              height: { min: 200, ideal: 300, max: 400 },
              aspectRatio: 1 / 1,
              frameRate: { max: 20 },
              deviceId: {
                exact: this.selectedVideoInputDeviceId$.getValue()
              }
            },
            audio: {
              deviceId: {
                exact: this.selectedAudioInputDeviceId$.getValue()
              }
            }
          };
          break;
    }

    const supported: MediaTrackSupportedConstraints = navigator.mediaDevices.getSupportedConstraints();
    console.log('MediaStreamsService:: Media Device: Supported Constraints: ', supported);

    if (!supported.width || !supported.height || !supported.aspectRatio || !supported.frameRate || ConstraintsLevel.BASIC) {
      console.log('MediaStreamsService:: Returning basic constraints: ', mediaConstraints);
      return mediaConstraints;
    }
    else {
      console.log('MediaStreamsService:: Returning fixed constraints:', mediaConstraints);
      return mediaConstraints;
    }
  }

  public killLocalStream() {
    return new Promise((resolve) => {
      console.log('MediaStreamsService:: about to kill the local stream');
      const stream = this.LOCAL_STREAM$.getValue()
      if (stream !== null) {
        stream.getTracks().forEach(t => {
          console.log(`MediaStreamsService:: stopping ${t.kind} track:`, t);
          t.stop()
        })
        this.LOCAL_STREAM$.next(null)
      }
      resolve(true)
    })
  }

  public async close() {
    await this.killLocalStream()
    this.streamReady$.next(false)
  }

}

import { IPlayerMeta } from 'src/app/services';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'playersFilter'
})
export class PlayersFilterPipe implements PipeTransform {

  transform(items: IPlayerMeta[], name: string, country: string, limit: number): IPlayerMeta[] {
    // console.log(name);
    const res = [];
    items.forEach(p => {
      // console.log(p);
      const nameString = p.name + p.nickName;
      const countryString = p.country + p.region + p.subregion + p.olson;
      if (
        nameString.toLowerCase().includes(name.toLowerCase()) &&
        countryString.toLowerCase().includes(country.toLowerCase())
      ) {
        res.push(p);
      }
    });
    return res.slice(0, limit);
  }

}

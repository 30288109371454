import { Injectable } from '@angular/core';
import { setIconXSCR } from 'src/assets/font-icons/tolaria-set-xscr';
import { setIconXSPC } from 'src/assets/font-icons/tolaria-set-xspc';
import { setIconXSDC } from 'src/assets/font-icons/tolaria-set-xsdc';

@Injectable({
  providedIn: 'root'
})
export class FontIconsService {

  public setIcons = {
    xspc: setIconXSPC, // Spectral Chaos
    xscr: setIconXSCR, // Scryings
    xsdc: setIconXSDC, // Slanfan's Cube
  }

  constructor() { }

}

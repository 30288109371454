<ng-container *ngIf="(eventDoc$ | async) as event">
  <!-- <div class="main event p-2" [ngClass]="{ 'show-message-section' : !eventService.eventViewSettings.messages }"> -->
  <ng-container *ngIf="globals.isMobile; else desktopView">
    <div class="mobile-wrapper">

      <div class="card-header p-0 d-flex flex-grow-0 align-items-center">

        <div class="btn btn-blank flex-grow-0 my-2 ms-2 me-1" (click)="navBack()">
          <fa-icon [icon]="icons.faChevronLeft" [fixedWidth]="true"></fa-icon>
        </div>

        <div class="btn btn-sm flex-grow-1 {{ mobileView.organizer ? 'btn-main' : 'btn-blank text-secondary' }} my-2 ms-2"
          *ngIf="isOrganizer" (click)="changeView('organizer')">
          Organizer
        </div>

        <div class="btn btn-sm flex-grow-1 {{ mobileView.details ? 'btn-main' : 'btn-blank text-secondary' }} my-2"
          (click)="changeView('details')">
          Details
        </div>

        <div class="btn btn-sm flex-grow-1 {{ mobileView.main ? 'btn-main' : 'btn-blank text-secondary' }} my-2 me-2"
          (click)="changeView('main')">
          Pairings & Standings
        </div>

      </div>

      <app-event-details class="flex-grow-1 d-flex flex-column overflow-hidden p-0 m-0"
        [eventDocId]="event.docId" *ngIf="mobileView.details">
      </app-event-details>
      <!-- <app-event-details class="flex-grow-1 d-flex flex-column overflow-hidden p-0 m-0" [eventDoc$]="eventDoc$"
        [eventDocId]="event.docId" *ngIf="mobileView.details">
      </app-event-details> -->

      <app-event-main-view class="flex-grow-1 d-flex flex-column overflow-hidden p-0 m-0" [eventDoc$]="eventDoc$"
        [eventDocId]="event.docId" [eventPlayers$]="eventPlayers$" [eventMatches$]="eventMatches$"
        [isOrganizer]="isOrganizer" *ngIf="mobileView.main">
      </app-event-main-view>

      <div class="flex-grow-1 d-flex flex-column overflow-hidden overflow-y-auto no-scrollbar"
        *ngIf="mobileView.organizer">
        <app-organizer [eventDoc$]="eventDoc$" [eventMatches$]="eventMatches$" [players$]="eventPlayers$"></app-organizer>
      </div>

    </div>
  </ng-container>

  <ng-template #desktopView>
    <div class="main event p-2 overflow-hidden overflow-x-auto">

      <section class="event-info pe-2" *ngIf="eventService.eventViewSettings.organizer && isOrganizer">
        <div class="panel-header d-flex">
          <div class="flex-grow-1 text-secondary">Tournament Organizer</div>
          <div class="icon text-secondary" (click)="eventService.eventViewSettings.organizer = false; saveViewSettings();"
            container="body" placement="bottom-right" ngbTooltip="Minimize panel (o)">
            <fa-icon [fixedWidth]="true" [icon]="icons.faTimes"></fa-icon>
          </div>
        </div>
        <app-organizer [eventDoc$]="eventDoc$" [eventMatches$]="eventMatches$" [players$]="eventPlayers$"></app-organizer>
      </section>

      <section class="event-info pe-2" *ngIf="eventService.eventViewSettings.details">
        <div class="panel-header d-flex">
          <div class="flex-grow-1 text-secondary">Details</div>
          <div class="icon text-secondary" (click)="eventService.eventViewSettings.details = false; saveViewSettings();"
            container="body" placement="bottom-right" ngbTooltip="Minimize panel (d)">
            <fa-icon [fixedWidth]="true" [icon]="icons.faTimes"></fa-icon>
          </div>
        </div>
        <!-- <app-event-details [eventDoc$]="eventDoc$" [eventDocId]="event.docId"></app-event-details> -->
        <app-event-details [eventDocId]="event.docId"></app-event-details>
      </section>

      <section class="event-info pe-2 flex-grow-1 {{ !eventService.eventViewSettings.messages ? 'mr-2' : '' }}">
        <div class="panel-header d-flex">
          <div class="flex-grow-1 text-secondary">Pairings & Standings</div>
        </div>
        <app-event-main-view [eventDoc$]="eventDoc$" [eventDocId]="event.docId" [eventPlayers$]="eventPlayers$"
          [eventMatches$]="eventMatches$" [isOrganizer]="isOrganizer"></app-event-main-view>
      </section>

      <section class="event-info message-panel pe-2 flex-grow-0"
        [ngClass]="{ 'invisible' : !eventService.eventViewSettings.messages }">
        <div class="panel-header d-flex">
          <div class="flex-grow-1 text-secondary">Messages</div>
          <div class="icon text-secondary" (click)="eventService.eventViewSettings.messages = false; saveViewSettings();"
            container="body" placement="bottom-right" ngbTooltip="Minimize panel (m)">
            <fa-icon [fixedWidth]="true" [icon]="icons.faTimes"></fa-icon>
          </div>
        </div>
        <div class="wrapper d-flex" style="max-height: 100%; min-height: 100%;">
          <div class="card no-hover" style="min-width: 40rem; max-width: 40rem;">
            <app-message-list class="flex-grow-1 overflow-hidden overflow-y-auto" [messageGroupDocId]="'eventChatFor[' + eventDocId  + ']'"></app-message-list>
            <div class="message-form-wrapper">
              <div class="btn btn-mute {{ muteMessages ? 'text-red' : '' }}" (click)="muteMessages = !muteMessages"
                placement="top-left" ngbTooltip="Turn notifcation sound: {{ muteMessages ? 'ON' : 'OFF' }}">
                <fa-icon [fixedWidth]="true" [icon]="muteMessages ? icons.faBellSlash : icons.faBell"></fa-icon>
              </div>
              <tolaria-message-form class="w-100" [messageGroupDocId]="'eventChatFor[' + eventDocId  + ']'" [matchChat]="false" [spectatorMode]="false"></tolaria-message-form>
              <!-- <app-message-form [messageGroupDocId]="'eventChatFor[' + eventDocId + ']'" [matchChat]="false"
                [spectatorMode]="false" matchDocId=""></app-message-form> -->
            </div>
          </div>
        </div>


      </section>

    </div>
    <div class="minimized-panels-wrapper d-flex">
      <div *ngIf="!eventService.eventViewSettings.organizer && isOrganizer" class="minimized-panel"
        (click)="eventService.eventViewSettings.organizer = true; saveViewSettings();">
        Organizer</div>
      <div *ngIf="!eventService.eventViewSettings.details" class="minimized-panel"
        (click)="eventService.eventViewSettings.details = true; saveViewSettings();">Details</div>
      <div *ngIf="!eventService.eventViewSettings.messages" class="minimized-panel"
        (click)="eventService.eventViewSettings.messages = true; saveViewSettings();">Messages</div>
    </div>
  </ng-template>


  <ng-template let-modal #tryTheNewLayout>
    <div class="modal-body">
      Hi there!
      I've been working on a new user interface for the event lobby.
      Would you be interested in checking it out?
    </div>
    <div class="modal-footer d-flex justify-content-end align-items-center">
      <button class="btn btn-sm btn-blank" (click)="modal.dismiss()">Cancel</button>
      <button class="btn btn-sm btn-blank" (click)="modal.close()">Hell yeah! Take me there</button>
    </div>
  </ng-template>


</ng-container>


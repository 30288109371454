import { Component, Input } from '@angular/core';

export interface ICardLegalityItem {
  name: string;
  text: string;
  legal: boolean;
  class: string;
}
@Component({
  selector: 'app-card-legality',
  templateUrl: './card-legality.component.html',
  styleUrls: ['./card-legality.component.css']
})
export class CardLegalityComponent {
  @Input() cardLegalities: any;

  constructor() { }

  public get legalities(): Array<ICardLegalityItem> {
    const keys = Object.keys(this.cardLegalities);
    const legalityList = [];
    keys.forEach(k => {
      const legality = {
        name: k.charAt(0).toUpperCase() + k.slice(1),
        text: this.cardLegalities[k].replace('_', ' '),
        class: this.cardLegalities[k],
        legal: this.cardLegalities[k] !== 'not_legal'
      };
      legalityList.push(legality);
    });

    return legalityList;
  }

}

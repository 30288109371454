import { ConfirmationDialogComponent } from './../../../../modals/confirmation-dialog/confirmation-dialog.component';
import { EventService } from 'src/app/services';
import { Observable } from 'rxjs';
import { Component, Input } from '@angular/core';
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap/modal/modal-config';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-more-actions-group',
  templateUrl: './more-actions-group.component.html',
  styleUrls: ['./more-actions-group.component.css']
})
export class MoreActionsGroupComponent {
  @Input() eventDoc$: Observable<IEventDetails>;

  faChevronRight = faChevronRight
  
  public submenuOpen = false

  constructor(private eventService: EventService, private modalService: NgbModal) { }

  addPlayoffBracket(event: IEventDetails): void {
    this.eventService.addPlayoffBracket(event);
  }
  removePlayoffBracket(event: IEventDetails): void {
    console.log('removePlayoffBracket');

    if (event.statusCode === 6) {

      console.log('... update event status');
      this.eventService.eventUpdateStatus(event, 4, false);

      console.log('... switch the hasBracketAfterGroup to false');
      this.eventService.removePlayoffBracket(event);

    }
    else if (event.statusCode === 7) {
      // need to remove the matches before removing the playoff
      console.log('... remove matches');
      this.eventService.deleteAllBracketMatches(event.docId).then((response) => {

        this.eventService.eventUpdateStatus(event, 4, false);

        this.eventService.removePlayoffBracket(event);

      });
    }
    else if (event.statusCode < 6) {
      this.eventService.removePlayoffBracket(event);
    }
  }
  resetEvent(event: IEventDetails): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: 'static',
      keyboard: false,
      size: 'sm',
    };

    const modalRef = this.modalService.open(ConfirmationDialogComponent, modalOptions);
    modalRef.componentInstance.title = 'Reset Event?';
    modalRef.componentInstance.body = `
    You are about to reset the event.
    This will delete all match results, matches, pairings, and you have to begin from the start.
    <br><br>
    Are you sure you want to reset the event?`;
    modalRef.componentInstance.buttonTextOk = 'Reset';

    modalRef.result.then(() => { this.eventService.eventResetGroupEvent(event) }, () => {})

  }

}

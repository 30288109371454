import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GoogleMapsModule, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ClubsService } from 'src/app/services/clubs.service';
import { IClub } from 'tolaria-cloud-functions/src/_interfaces';
import { PlayerDisplayNameComponent } from '../../players/player-display-name/player-display-name.component';

export interface GoogleMapMarker {
  position: {
    lat: number
    lng: number
  }
  title: string
}

@Component({
  selector: 'app-club-maps',
  standalone: true,
  imports: [
    CommonModule,
    GoogleMapsModule,
    PlayerDisplayNameComponent,
  ],
  template: `
  <google-map width="100%" height="100%">
    <map-marker *ngFor="let marker of markers"
      #clubMarker="mapMarker"
      [position]="marker.position"
      [title]="marker.title"
      (mapClick)="openInfoWindow(clubMarker)">
    </map-marker>
    <map-info-window>
      <div class="p-3">
        <ng-container *ngIf="selectedClub$ | async as club">
          <div class="marker-info-wrapper">
            <img width="300" class="mb-3" [src]="club.imageUris.small" *ngIf="club.imageUris.small !== null && club.imageUris.small !== ''">
            <div class="h3 mb-2">{{ club.name }}</div>
            <div class="text-bold mb-2">{{ club.memberDocIds.length }} members</div>
            <div class="text-secondary" [innerHtml]="club.info"></div>
            <hr>
            <div class="text-secondary" [innerHtml]="club.region" *ngIf="club.region !== ''"></div>
            <div class="text-secondary" [innerHtml]="club.city" *ngIf="club.city !== ''"></div>
            <div class="text-secondary" [innerHtml]="club.country" *ngIf="club.country !== ''"></div>
            <div class="text-secondary">{{ club.websiteUrl }}</div>
            <hr>
            <div class="">
              <span class="text-secondary me-1">Contact:</span>
              <ng-container *ngIf="club.responsiblePlayerDocIds.length > 0">
                <app-player-display-name [playerDocId]="club.responsiblePlayerDocIds[0]"></app-player-display-name>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </map-info-window>
  </google-map>
  `,
  styleUrls: ['./club-maps.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ClubMapsComponent implements OnInit, OnDestroy {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow
  
  @Input() markers: GoogleMapMarker[] = []
  @Input() center: google.maps.LatLngLiteral = { lat: 24, lng: 12 }
  @Input() zoom: number = 10

  private destroyed$ = new Subject<boolean>()
  private clubs$ = new BehaviorSubject<IClub[]>([])
  public selectedClub$ = new BehaviorSubject<IClub>(null)

  constructor(
    private clubsService: ClubsService,
  ) {}

  ngOnInit(): void {
    this.clubsService.getClubs().pipe(takeUntil(this.destroyed$)).subscribe(clubs => {
      this.clubs$.next(clubs)
      if (clubs !== null) {
        this.markers = clubs.filter(i => i.latitude && i.latitude).map(i => {
          const m: GoogleMapMarker = {
            title: i.name,
            position: {
              lat: i.latitude,
              lng: i.longitude,
            }
          }
          return m
        })
      }
    })
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  openInfoWindow(data: any) {
    const club = this.getClubByMarker(data.marker.position.lng(), data.marker.position.lat())
    this.selectedClub$.next(club)
    this.infoWindow.open(data)
  }

  private getClubByMarker(lng: any, lat: any): IClub {
    const clubs = this.clubs$.getValue()
    const club = clubs.find(i => i.longitude === lng && i.latitude === lat)
    return club
  }

  public markerOptions: google.maps.MarkerOptions = {
    draggable: false,
  }
}

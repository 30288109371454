<div [id]="msg.docId" class="message-wrapper d-flex flex-column gap-3 p-2 px-3 position-relative"
  [ngClass]="{ 'bg-warning bg-opacity-10' : msg.isPinned}">

  <div *ngIf="msg.isPinned"
    class="message-pinned ms-5 d-flex align-items-center gap-2 text-small"
    [style.marginBottom.rem]="-1">
    <fa-icon [icon]="pin" class="text-warning ms-2"></fa-icon>
    <span class="text-secondary">{{ msg.pinnedBy }}</span>
  </div>

  <app-message-action class="message-action position-absolute top-0 end-0 me-3 translate-middle-y"
    [msg]="msg"
    (editMessage)="onEditMessageEmitted($event)">
  </app-message-action>

  <div *ngIf="msg.replyingTo"
    class="message-replyto d-flex align-items-center gap-2 cursor-pointer"
    (click)="onReplyToPressed(msg.replyToMeta.messageDocId)">
    <div class="message-replyto-connector"></div>
    <app-player-avatar class=""
      size="small"
      [playerDocId]="msg.replyToMeta.playerDocId"
      [disablePopover]="true"
      [disableClick]="true"
      [border]="false">
    </app-player-avatar>
    <app-player-display-name class="text-medium"
      display="short"
      [playerDocId]="msg.replyToMeta.playerDocId"
      [disableClick]="true">
    </app-player-display-name>
    <span class="text-secondary text-medium text-truncate">{{ msg.replyToMeta.message }}</span>
  </div>

  <div class="message-row overflow-hidden d-flex gap-3">

    <div class="prefix d-flex align-items-start justify-content-end flex-shrink-0" [style.minWidth.rem]="2.5">
  
      <app-player-avatar *ngIf="msg.showHeader"
        class=""
        [playerDocId]="msg.playerDocId"
        [border]="false"
        size="chat">
      </app-player-avatar>
  
      <app-timestamp-clock *ngIf="!msg.showHeader"
        class="text-secondary message-time"
        [timestamp]="msg.message.timestamp"
        [seconds]="true"
        displayType="time">
      </app-timestamp-clock>
  
    </div>
  
    <div class="d-flex flex-column overflow-hidden flex-grow-1">
  
      <div class="d-flex align-items-center gap-3" *ngIf="msg.showHeader">
        <app-player-display-name class="text-large text-bold text-truncate"
          [playerDocId]="msg.playerDocId">
        </app-player-display-name>
        <app-timestamp-clock class="text-secondary"
          [timestamp]="msg.message.timestamp"
          [seconds]="true"
          displayType="time">
        </app-timestamp-clock>
      </div>
  
      <ng-container *ngIf="editMode$ | async; else messageView">
        <tolaria-wysiwyg
          (cancelEdit)="handleCancelEdit($event)"
          (deltaComplete)="handleSave($event)"
          [message]="msg.message.delta"
          [composer]="false"
          [debug]="false">
        </tolaria-wysiwyg>
      </ng-container>
      <ng-template #messageView>
        <ng-container *ngIf="msg.message.delta; else defaultMessage">
          <quill-view [debug]="false" [ngClass]="{ 'only-emojis' : onlyEmojis }" [content]="msg.message.delta.ops" format="object"></quill-view>
        </ng-container>
        <ng-template #defaultMessage>
          <div class="message-content" [innerHtml]="msg.message.message | linky: { newWindow: true } | chatFormat"></div>
        </ng-template>
      </ng-template>
      
      <div class="message-images d-flex flex-wrap gap-3 py-2" *ngIf="msg.message.images !== undefined && msg.message.images.length > 0">
        <div *ngFor="let image of msg.message.images"
          class="message-image rounded rounded-3 overflow-hidden d-flex align-items-center justify-content-center border border-secondary"
          style="--bs-border-opacity: .5;"
          [style.height.rem]="8">
          <img [src]="image.downloadUrl" height="100%" (click)="onImagePress(image)">
        </div>
      </div>

      <div *ngIf="msg.hasReactions"
        class="d-flex align-items-center message-reactions gap-2">

        <ng-container *ngFor="let item of msg.reactions">

          <ng-template #tooltipContent>
            <div class="p-3 d-flex flex-column gap-2 align-items-center">
              <div class="d-flex align-items-center gap-1" *ngIf="item.isShortcode; else nativeEmoji">
                <app-tolaria-emoji [shortcode]="item.reaction" size="larger" [rounded]="true"></app-tolaria-emoji>
              </div>
              <ng-template #nativeEmoji>
                <div class="d-flex align-items-center gap-1">
                  <span class="native-reaction">{{ item.reaction }}</span>
                </div>
              </ng-template>
              <div class="">
                <span class="me-1">{{ item.reactedBy }}</span><span class="text-secondary">reacted with {{ item.reaction }}</span>
              </div>
            </div>
          </ng-template>

          <div class="btn btn-reaction p-0 px-2 rounded rounded-pill bg-opacity-25 cursor-pointer"
            [ngbTooltip]="tooltipContent"
            tooltipClass="tooltip-reaction"
            container="body"
            (click)="updateReaction(item)"
            [ngClass]="{ 'bg-primary' : item.youReacted, 'bg-secondary' : !item.youReacted}">
            <div class="d-flex align-items-center gap-1" *ngIf="item.isShortcode; else nativeEmoji">
              <app-tolaria-emoji [shortcode]="item.reaction"></app-tolaria-emoji><span class="text-medium">{{ item.count }}</span>
            </div>
            <ng-template #nativeEmoji>
              <div class="d-flex align-items-center gap-1">
                <span class="native-reaction">{{ item.reaction }}</span><span class="text-medium">{{ item.count }}</span>
              </div>
            </ng-template>
          </div>
          
        </ng-container>
          
        <tolaria-emoji-picker-button
          buttonStyle="btn btn-reaction bg-body bg-opacity-50 rounded rounded-pill p-0 px-2"
          buttonHoverStyle="border border-secondary"
          tooltipText="Add reaction"
          (onEmojiSelected)="onReaction($event)">
        </tolaria-emoji-picker-button>

      </div>
  
    </div>
  
    <div class="suffix">
      <fa-icon [icon]="whisperMode" class="text-secondary" *ngIf="msg.isWhispered" [ngClass]="{ 'mt-1' : msg.showHeader }"></fa-icon>
    </div>
    
  </div>

</div>

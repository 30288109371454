import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-draft-booster',
  templateUrl: './draft-booster.component.html',
  styleUrls: ['./draft-booster.component.css']
})
export class DraftBoosterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    const init = true;
  }

}

import { GlobalsService } from 'src/app/services/globals.service';
import { ConfigService } from 'src/app/services/config.service';
import { ILeaguePoint, LeaguePointType } from 'tolaria-cloud-functions/src/_interfaces';
import { faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbDate, NgbDatepickerI18n, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ILeagueData, LeaguesService } from 'src/app/services/leagues.service';
import { IDateSelection, ITimeSelection } from '../../modals/new-event-form/new-event-form.component';
import { Observable, Subject } from 'rxjs';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize, takeUntil } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-league-config',
  templateUrl: './league-config.component.html',
  styleUrls: ['./league-config.component.css']
})
export class LeagueConfigComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('newPointModal', { static: false }) newPointModal: ElementRef;
  @ViewChild('editorWrapper', { static: true }) editorWrapper: ElementRef;
  @Input() leagueData: ILeagueData;

  public icons = {
    faTrash: faTrash,
    faPlusCircle: faPlusCircle,
  }

  public view = {
    details: true,
    description: false,
    banner: false,
  }


  public newPoint: ILeaguePoint;
  public pointTypes: string[] = this.leagueService.pointTypes;
  public translations = this.leagueService.translations;
  public ckeConfig = JSON.parse(JSON.stringify(this.config.ckeConfig));

  // NgbDatePicker
  public hoveredDate: NgbDate | null = null;
  public dateSelection: IDateSelection = {
    fromDate: null,
    toDate: null,
  };
  public timeSelection: ITimeSelection = {
    fromTime: null,
    toTime: null  // this is used as DURATION of hours and minutes
  };

  private originLeagueData: string;

  constructor(
    public i18n: NgbDatepickerI18n,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private leagueService: LeaguesService,
    private config: ConfigService,
    private globals: GlobalsService,
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {
    if (this.leagueData !== undefined) { this.originLeagueData = JSON.stringify(this.leagueData) }
    // set date for the date selection according to league data
    this.initDateSelection();
  }
  ngAfterViewInit(): void {
    // console.log(this.editorWrapper.nativeElement.getBoundingClientRect());
    const wrapperHeight = (this.editorWrapper.nativeElement.getBoundingClientRect().height / 16) - 2;
    this.ckeConfig.height = wrapperHeight + 'rem';
  }
  ngOnDestroy(): void {
    this.componentWasDestroyed$.next(true);
  }

  public changeView(selectedView: string): void {
    Object.keys(this.view).forEach((view) => {
      if (view === selectedView) { this.view[view] = true }
      else { this.view[view] = false }
    })
  }

  public openNewPointModal(): void {
    // clear form
    this.newPoint = {
      type: null,
      points: null,
      rankFrom: null,
      rankFromSuffix: null,
      rankTo: null,
      rankToSuffix: null,
      isRange: false,
      customExplaination: '',
    };

    const content = this.newPointModal;
    this.modalService
      .open(content, { centered: true, keyboard: false, size: 'sm' }).result
      .then(() => {
        // cancelled
      }, (reason) => {
        if (reason === 'save') {
          // check if ranking and then add rank suffixes
          if (this.newPoint.type === LeaguePointType.RANK) {
            this.newPoint.rankFromSuffix = this.getNumberSuffix(this.newPoint.rankFrom);
            this.newPoint.rankToSuffix = this.getNumberSuffix(this.newPoint.rankTo);
          }

          this.leagueData.pointStructure.push(this.newPoint);
        }
      });
  }

  public save(): void {
    this.activeModal.dismiss(this.leagueData);
  }

  public close(): void {
    this.activeModal.close();
  }

  // league configuration
  public removePoint(i: number): void {
    this.leagueData.pointStructure.splice(i ,1);
  }
  private initDateSelection(): void {
    const dateFrom = new Date(this.leagueData.timestampStart * 1000);
    // console.log(dateFrom);
    this.dateSelection.fromDate = new NgbDate(dateFrom.getFullYear(), dateFrom.getMonth() + 1, dateFrom.getDate());
    const dateTo = new Date(this.leagueData.timestampEnd * 1000);
    // console.log(dateTo);
    this.dateSelection.toDate = new NgbDate(dateTo.getFullYear(), dateTo.getMonth() + 1, dateTo.getDate());
  }
  public onDateSelection(date: NgbDate): void {
    if (!this.dateSelection.fromDate && !this.dateSelection.toDate) {
      this.dateSelection.fromDate = date;
      this.dateSelection.toDate = null;
      // set starting timestamp
      this.leagueData.timestampStart = new Date(this.dateSelection.fromDate.year, this.dateSelection.fromDate.month - 1, this.dateSelection.fromDate.day).getTime() / 1000;
      // clear ending timestamp
      this.leagueData.timestampEnd = null;
      // console.log(this.leagueData);
    }
    else if (this.dateSelection.fromDate && !this.dateSelection.toDate && date.after(this.dateSelection.fromDate)) {
      this.dateSelection.toDate = date;
      this.leagueData.timestampEnd = new Date(this.dateSelection.toDate.year, this.dateSelection.toDate.month - 1, this.dateSelection.toDate.day).getTime() / 1000;
      // console.log(this.leagueData);
    }
    else {
      this.dateSelection.fromDate = date;
      this.dateSelection.toDate = null;
      // set starting timestamp
      this.leagueData.timestampStart = new Date(this.dateSelection.fromDate.year, this.dateSelection.fromDate.month - 1, this.dateSelection.fromDate.day).getTime() / 1000;
      // clear ending timestamp
      this.leagueData.timestampEnd = null;
      // console.log(this.leagueData);
    }
  }
  public isHovered(date: NgbDate): boolean {
    return this.dateSelection.fromDate
      && !this.dateSelection.toDate
      && this.hoveredDate
      && date.after(this.dateSelection.fromDate)
      && date.before(this.hoveredDate);
  }
  public isInside(date: NgbDate): boolean {
    return this.dateSelection.toDate
      && date.after(this.dateSelection.fromDate)
      && date.before(this.dateSelection.toDate);
  }
  public isRange(date: NgbDate): boolean {
    return date.equals(this.dateSelection.fromDate)
      || (this.dateSelection.toDate
        && date.equals(this.dateSelection.toDate))
      || this.isInside(date)
      || this.isHovered(date);
  }
  public get dataChanged(): boolean {
    return JSON.stringify(this.leagueData) !== this.originLeagueData;
  }
  public get formInvalid(): boolean {
    // check dates
    if (this.leagueData.timestampStart === null || this.leagueData.timestampEnd === null) { return true }
    // check name
    if (this.leagueData.name === undefined || this.leagueData.name.length < 5) { return true }

    return false
  }
  private getNumberSuffix(num: number): string {
    const rank = parseInt(num.toString().substr(-1));
    if (rank === 1) { return 'st' }
    else if (rank === 2) { return 'nd' }
    else if (rank === 3) { return 'rd' }
    else { return 'th' }
  }


  // IMAGE UPLOAD
  public change = true;
  file: File;
  private fileExt: string;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  saveDisabled = true;
  downloadUrl: Observable<string>;
  imageUrl: string | ArrayBuffer = 'https://bulma.io/images/placeholders/480x480.png';
  fileName = 'No file selected';
  showImagePreview = false;
  private componentWasDestroyed$ = new Subject<boolean>();

  fileChangeEvent(event: any): void {
    this.showImagePreview = true;
    const name = event.target.files[0].name;
    const lastDot = name.lastIndexOf('.');
    this.fileExt = name.substring(lastDot + 1);
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
    // console.log('imageLoaded');
    this.saveDisabled = false;
  }
  cropperReady() {
    // cropper ready
    // console.log('cropperReady');
  }
  loadImageFailed() {
    // show message
    // console.log('loadImageFailed');
  }

  async saveImage() {
    this.globals.isBusy.message = 'Uploading your image, please wait';
    this.globals.isBusy.status = true;
    this.globals.isBusy.showMessage = true;

    const filePath = 'leagues/' + this.leagueData.docId + '.' + this.fileExt;
    // console.log('create filePath', filePath);
    const ref = this.storage.ref(filePath);
    // console.log('create ref', ref);
    const file = this.imageToDataUri(this.croppedImage, 300, 150);
    // console.log('create file', file);
    const task = ref.putString(file, 'data_url');
    // console.log('crate task', task);
    task
      .snapshotChanges()
      .pipe(
        takeUntil(this.componentWasDestroyed$),
        finalize(() => {
          // console.log('task finalized, getting download url');
          this.downloadUrl = ref.getDownloadURL();
          // store the download url as the avatar link for both user and player doc.
          this.downloadUrl.subscribe(async (url) => {
            this.globals.isBusy.message = 'Uploading done, updating the event banner url';
            // console.log('downlaod url emitted');
            await this.updateEventBanner(url);

            this.globals.isBusy.message = 'Finished!';
            setTimeout(() => {
              this.globals.isBusy.status = false;
              this.globals.isBusy.showMessage = false;
            }, 1500);
          });
        })
      ).subscribe();

    this.change = false;
    this.croppedImage = '';
    this.saveDisabled = true;
    this.imageChangedEvent = '';
  }
  async updateEventBanner(url: string) {
    this.leagueData.bannerUrl = url;
    this.afs.collection('leagues').doc(this.leagueData.docId)
      .update({
        bannerUrl: url
      })
      .catch((err) => {
        // console.log(err);
      });
    this.change = true;
  }
  imageToDataUri(base64: string, width: number, height: number) {
    // Create and initialize two canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const canvasCopy = document.createElement('canvas');
    const copyContext = canvasCopy.getContext('2d');

    // Create original image
    const img = new Image();
    img.src = base64;

    // Draw original image in second canvas
    canvasCopy.width = img.width;
    canvasCopy.height = img.height;
    copyContext.drawImage(img, 0, 0);

    // Copy and resize second canvas to first canvas
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL();
  }
  removeBannerImage() {
    this.change = false;
    this.afs.collection('leagues').doc(this.leagueData.docId)
      .update({
        bannerUrl: ''
      })
      .catch((err) => {
        // console.log(err);
      });
    const file = this.leagueData.bannerUrl;
    const ref = this.storage.ref(file);
    ref.delete();
  }

}

import { BehaviorSubject } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { ITicket } from 'tolaria-cloud-functions/src/_interfaces';
import { faCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-ticket-row',
  templateUrl: './ticket-row.component.html',
  styleUrls: ['./ticket-row.component.css']
})
export class TicketRowComponent implements OnInit {
  @Input() ticket: ITicket

  public ticket$: BehaviorSubject<ITicket> = new BehaviorSubject<ITicket>(null)
  public ticketStatus = faCircle
  public ticketDeleted = faTrashAlt

  constructor() { }

  ngOnInit(): void {
    if (this.ticket) {
      this.ticket$.next(this.ticket)
    }
  }

  public get revenue(): string {
    const ticket = this.ticket$.getValue()
    const sales = Object.values(ticket.salesData.ticketPurchases).filter(i => !i.refunded).reduce((acc, i) => acc + i.price, 0)
    return `${sales} ${ticket.salesData.currency.toUpperCase()}`
  }

}

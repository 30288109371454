<ng-container *ngIf="player$ | async as player; else loader">
  
  <div class="d-flex position-relative overflow-hidden rounded-top-3" [style.height.rem]="16">
    <img [src]="player.avatar ? player.avatar : 'assets/avatars/default.jpg'"
      class="w-100 rounded rounded-3 mb-2 position-absolute translate-middle-y top-50 start-0">
    <button *ngIf="modal" class="btn-close position-absolute end-0 top-0 m-3" (click)="onClose()"></button>
  </div>

  <div class="d-flex flex-column gap-3 p-3">

    <div class="d-flex flex-column">
      <span class="text-large fw-bold">{{ player.name.first }} {{ player.name.last }}</span>
      <span *ngIf="player.name?.nick !== ''" class="text-large fw-light">{{ player.name.nick }}</span>
      <div class="d-flex align-items-center gap-2">
        <fa-icon [icon]="icon.clock"></fa-icon>
        <span class="text-nowrap flex-grow-1">{{ todayDate | date : displayType : player.utcOffset }} local time</span>
        <span class="text-secondary text-small">{{ player.timeZone.olson }}</span>
        <span class="text-secondary text-small">{{ player.timeZone.zoneName }}</span>
      </div>
    </div>
    
    <div *ngIf="player.statusMessage"
      class="border border-secondary rounded border-opacity-25 p-2 bg-secondary"
      style="--bs-bg-opacity: 0.125;"
      [innerHTML]="player.statusMessage"></div>

    <app-quick-message [playerDocId]="player.docId"></app-quick-message>

    <div *ngIf="player.emailIsPublic" class="d-flex gap-3 align-items-center">
      <div class="btn" style="background-color: rgba(var(--bs-primary-rgb), 0.125);">
        <fa-icon [icon]="icon.email"></fa-icon>
      </div>
      <div class="d-flex flex-column text-small">
        <span class="">Email Address</span>
        <a [href]="'mailto:' + player.email" class="">{{ player.email }}</a>
      </div>
    </div>
    
    <div *ngIf="player.phoneIsPublic" class="d-flex gap-3 align-items-center">
      <div class="btn" style="background-color: rgba(var(--bs-primary-rgb), 0.125);">
        <fa-icon [icon]="icon.phone"></fa-icon>
      </div>
      <div class="d-flex flex-column text-small">
        <span class="">Phone</span>
        <a [href]="'tel:' + player.phone" class="">{{ player.phone }}</a>
      </div>
    </div>

    <div *ngIf="player?.handles?.length > 0" class="d-flex flex-wrap align-items-center gap-2">
      <div *ngFor="let handle of player.handles"
        class="btn"
        style="background-color: rgba(var(--bs-primary-rgb), 0.125);"
        [ngbTooltip]="handle.handle"
        container="body">
        <a [href]="handle.url" target="_blank" *ngIf="handle.url !== ''; else handleNoUrl">
          <fa-icon [icon]="handleIcons[handle.platform]" [fixedWidth]="true"></fa-icon>
        </a>
        <ng-template #handleNoUrl>
          <fa-icon [icon]="handleIcons[handle.platform]" [fixedWidth]="true"></fa-icon>
        </ng-template>
      </div>
    </div>

    <div class="d-flex gap-2">
      <button class="btn btn-sm btn-outline-secondary flex-grow-1">Collection</button>
      <!-- <button class="btn btn-sm btn-outline-secondary flex-grow-1" (click)="onCasualGame()">Casual game</button> -->
      <app-casual-match-button [playerId]="player.docId"></app-casual-match-button>
      <div ngbDropdown class="d-inline-block">
        <button type="button" class="btn btn-sm btn-outline-secondary no-arrow" id="playerMenu" ngbDropdownToggle>
          <fa-icon [icon]="icon.menu" [fixedWidth]="true"></fa-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="playerMenu">
          <button ngbDropdownItem (click)="onCopyPlayerId(player.docId)">Copy player id</button>
          <button ngbDropdownItem (click)="onEmailPlayer(player.email)">Email player</button>
        </div>
      </div>
    </div>
    
  </div>

</ng-container>


<ng-template #loader>

  <div class="d-flex position-relative overflow-hidden rounded-top-3 bg-secondary" [style.height.rem]="16">
    <button *ngIf="modal" class="btn-close position-absolute end-0 top-0 m-3" (click)="onClose()"></button>
  </div>

  <div class="d-flex flex-column gap-3 p-3">

    <div class="d-flex flex-column placeholder-glow gap-3">
      <span class="placeholder rounded col-7 placeholder-lg"></span>
      <div class="d-flex align-items-center gap-2">
        <span class="placeholder rounded col-4"></span>
        <span class="flex-grow-1"></span>
        <span class="placeholder rounded col-3"></span>
        <span class="placeholder rounded col-3"></span>
      </div>
    </div>
    
    <div class="d-flex flex-column placeholder-glow">
      <span class="placeholder rounded col-12 placeholder-lg"></span>
    </div>

    <div class="d-flex gap-2">
      <button class="btn btn-sm btn-outline-secondary flex-grow-1" [disabled]="true">Collection</button>
      <button class="btn btn-sm btn-outline-secondary flex-grow-1" [disabled]="true">Casual game</button>
    </div>
    
  </div>

</ng-template>
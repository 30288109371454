import { PaymentService } from 'src/app/payment/payment.service';
import { ConfigService } from 'src/app/services/config.service'
import { ToastService } from 'src/app/services/toast.service'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { finalize, take, takeUntil } from 'rxjs/operators'
import { AngularFireStorage } from '@angular/fire/compat/storage'
import { ImageCroppedEvent } from 'ngx-image-cropper'
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input, OnDestroy } from '@angular/core'
import {
  faCalendarAlt, faMapMarkerAlt, faEllipsisH, faTimes, faInfoCircle, faEdit,
  faTrash, faCamera, faFileAlt, faClock, faVideo
} from '@fortawesome/free-solid-svg-icons'
import { faSquare, faCheckSquare, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { AuthService, EventService, GlobalsService } from 'src/app/services'
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces'
import { INewEventForm, IEventDetails, IFormat, IRuleset, IReprintPolicy } from 'tolaria-cloud-functions/src/_interfaces'
import { NgbCalendar, NgbDate, NgbTimeStruct, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ProductType, StripeRole } from 'tolaria-cloud-functions/src/_interfaces'

export interface IDateSelection {
  fromDate: NgbDate | null
  toDate: NgbDate | null
}
export interface ITimeSelection {
  fromTime: NgbTimeStruct | null
  toTime: NgbTimeStruct | null
}
@Component({
  selector: 'app-new-event-form',
  templateUrl: './new-event-form.component.html',
  styleUrls: ['./new-event-form.component.css']
})
export class NewEventFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('eventName', { static: true }) eventName: ElementRef
  @ViewChild('editorWrapper', { static: true }) editorWrapper: ElementRef

  @Input() eventDoc?: IEventDetails
  @Input() editMode: boolean

  faCheckCircle = faCheckCircle
  faCalendarAlt = faCalendarAlt
  faMapMarkerAlt = faMapMarkerAlt
  faSquare = faSquare
  faCheckSquare = faCheckSquare
  faEllipsisH = faEllipsisH
  faTimes = faTimes
  faInfoCircle = faInfoCircle
  faEdit = faEdit
  faClock = faClock
  faTrash = faTrash
  faCamera = faCamera
  faFileAlt = faFileAlt
  faVideo = faVideo

  showDetails = true
  showBannerEdit = false
  showTimePicker = false
  showLocationExtra = false
  showDatePicker = false
  isEdit = false

  minRegEUR = 10
  minRegFee = null

  eventData: INewEventForm
  formWarning: string

  // IMAGE UPLOAD
  public change = true
  progress: number
  infoMessage: any
  isUploading = false
  file: File
  private fileExt: string
  imageChangedEvent: any = ''
  croppedImage: any = ''
  saveDisabled = true
  downloadUrl: Observable<string>

  imageUrl: string | ArrayBuffer = 'https://bulma.io/images/placeholders/480x480.png'
  fileName = 'No file selected'
  showImagePreview = false

  public formatsLoaded$ = new BehaviorSubject<IFormat[]>(null)
  public exchangeRatesLoaded$ = new BehaviorSubject<boolean>(null)
  private componentWasDestroyed$ = new Subject<boolean>()

  // NgbDatePicker
  hoveredDate: NgbDate | null = null
  dateSelection: IDateSelection = {
    fromDate: null,
    toDate: null,
  }
  timeSelection: ITimeSelection = {
    fromTime: null,
    toTime: null
  }
  dateSelectionRegistrationOpens: IDateSelection = {
    fromDate: null,
    toDate: null,
  }
  timeSelectionRegistrationOpens: ITimeSelection = {
    fromTime: null,
    toTime: null
  }

  // CK Editor config
  ckeConfig = JSON.parse(JSON.stringify(this.config.ckeConfig))

  constructor(
    public globals: GlobalsService,
    public es: EventService,
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    public auth: AuthService,
    private calendar: NgbCalendar,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private config: ConfigService,
    private payment: PaymentService,
  ) {

  }

  ngOnInit(): void {
    this.es.formats$.pipe(
      take(1)
    ).subscribe((formatList) => {
      console.log(formatList)
      this.formatsLoaded$.next(formatList)
    })
    if (this.eventDoc !== undefined) {
      this.eventData = this.checkMissingProps(this.eventDoc)

      this.change = this.eventDoc?.bannerUrl ? true : false
      this.initDateAndTimeForEdit()
      this.isEdit = true
    }
    else {
      console.log('no event data passed, fetching a blank object')
      this.eventData = this.es.newEventData()
    }

    console.log(this.eventData)

    // get exchange rates as these are needed for the registration fee conversion
    this.payment.exchangeRates$.pipe(takeUntil(this.componentWasDestroyed$)).subscribe((rates) => {
      if (rates !== null) {
        const currency = this.eventData.registrationFee.currency.toUpperCase()
        console.log(`default currency ${currency}`)
        console.log(`exchange rate ${rates[currency]}`)
        this.minRegFee = Math.ceil(this.minRegEUR * rates[currency])
        console.log(`${currency} > EUR = ${this.eventData.registrationFee.amount / rates[currency]}`)
        console.log(`min registration fee ${this.minRegFee} ${currency}`)
        this.exchangeRatesLoaded$.next(true)
      }
    })
  }
  ngAfterViewInit(): void {
    if (this.editorWrapper === undefined) return
    const wrapperHeight = (this.editorWrapper.nativeElement.getBoundingClientRect().height / 16) - 5
    this.ckeConfig.height = wrapperHeight + 'rem'
    if (this.eventName) this.eventName.nativeElement.focus()
  }
  ngOnDestroy(): void {
    this.componentWasDestroyed$.next(true)
  }
  checkMissingProps(eventDoc: IEventDetails): INewEventForm {

    // add registration fee object (set currency to NULL if user does not have one set)
    if (!eventDoc.details?.registrationFee) {
      eventDoc.details.registrationFee = {
        active: false,
        tolariaPayment: true,
        charityExtra: true,
        amount: null,
        currency: this.auth.user?.stripe?.default_currency ? this.auth.user.stripe.default_currency : null,
        productId: `registrationFee_${eventDoc.docId}`,
        productType: ProductType.REGISTRATION_FEE,
      }
    }

    // set default currency if possible (user might have signed up for a stripe account after the event was created)
    if (!eventDoc.details?.registrationFee?.currency === null && this.auth.user?.stripe?.default_currency) {
      eventDoc.details.registrationFee.currency = this.auth.user.stripe.default_currency
    }

    return eventDoc.details

  }
  initDateAndTimeForEdit() {
    // set date and time range in date and time pickers
    const dateFrom = new Date(this.eventData.datetimeFrom)
    this.timeSelection.fromTime = {
      hour: dateFrom.getHours(),
      minute: dateFrom.getMinutes(),
      second: 0
    }
    this.dateSelection.fromDate = new NgbDate(dateFrom.getFullYear(), dateFrom.getMonth() + 1, dateFrom.getDate())
    if (this.eventData.isMultiDay) {
      const dateTo = new Date(this.eventData.datetimeTo)
      this.timeSelection.toTime = {
        hour: dateTo.getHours(),
        minute: dateTo.getMinutes(),
        second: 0
      }
      this.dateSelection.toDate = new NgbDate(dateTo.getFullYear(), dateTo.getMonth() + 1, dateTo.getDate())
    }

    // set date and time range in date and time pickers (Registration Opens)
    const dateFromRegistrationOpens = new Date(this.eventData.registrationOpensTimestamp)
    this.timeSelectionRegistrationOpens.fromTime = {
      hour: dateFromRegistrationOpens.getHours(),
      minute: dateFromRegistrationOpens.getMinutes(),
      second: 0
    }
    this.dateSelectionRegistrationOpens.fromDate = new NgbDate(dateFromRegistrationOpens.getFullYear(), dateFromRegistrationOpens.getMonth() + 1, dateFromRegistrationOpens.getDate())
    console.log(this.dateSelectionRegistrationOpens.fromDate)
  }
  setDateTimeAsToday(isMultiDay: boolean) {
    // set the date to today
    this.dateSelection.fromDate = this.calendar.getToday()
    // if multi-day, add a week
    if (isMultiDay) {
      this.dateSelection.toDate = this.calendar.getNext(this.dateSelection.fromDate, 'd')
    }
    // set time
    const hour = new Date().getHours()
    let minute = Math.ceil(new Date().getMinutes() / 15) * 15
    if (minute === 60) { minute = 0 }
    this.timeSelection.fromTime = { hour, minute, second: 0 }

    // if multiday, set the same tme
    if (isMultiDay) {
      this.timeSelection.toTime = { hour, minute, second: 0 }
    }

    this.setDateTime(isMultiDay)
  }
  // NgbDatePicker
  setDateTime(isMultiDay: boolean) {
    this.showDatePicker = false
    if (isMultiDay) {
      this.eventData.datetime = this.fromDate + ' > ' + this.toDate
      this.eventData.datetimeFrom = this.fromDate
      this.eventData.datetimeTo = this.toDate
      this.eventData.datestampFrom = Date.parse(this.fromDate)
      this.eventData.datestampTo = Date.parse(this.toDate)
    }
    else {
      this.eventData.datetime = this.fromDate
      this.eventData.datetimeFrom = this.fromDate
      this.eventData.datestampFrom = Date.parse(this.fromDate)
    }
  }
  private get fromDate() {
    return this.dateSelection.fromDate.year + '-' +
      this.pad2(this.dateSelection.fromDate.month) + '-' +
      this.pad2(this.dateSelection.fromDate.day) + 'T' +
      this.pad2(this.timeSelection.fromTime.hour) + ':' +
      this.pad2(this.timeSelection.fromTime.minute) + this.eventData.GMT_offset
  }
  private get toDate() {
    return this.dateSelection.toDate.year + '-' +
      this.pad2(this.dateSelection.toDate.month) + '-' +
      this.pad2(this.dateSelection.toDate.day) + 'T' +
      this.pad2(this.timeSelection.toTime.hour) + ':' +
      this.pad2(this.timeSelection.toTime.minute) + this.eventData.GMT_offset
  }
  private get fromDateRegistrationOpen() {
    return this.dateSelectionRegistrationOpens.fromDate.year + '-' +
      this.pad2(this.dateSelectionRegistrationOpens.fromDate.month) + '-' +
      this.pad2(this.dateSelectionRegistrationOpens.fromDate.day) + 'T' +
      this.pad2(this.timeSelectionRegistrationOpens.fromTime.hour) + ':' +
      this.pad2(this.timeSelectionRegistrationOpens.fromTime.minute) + this.eventData.GMT_offset
  }
  public get dateAndTimeInvalid() {
    if (this.eventData.isMultiDay) {
      if (
        this.dateSelection.fromDate === null || this.dateSelection.fromDate === undefined ||
        this.dateSelection.toDate === null || this.dateSelection.toDate === undefined ||
        this.timeSelection.fromTime === null || this.timeSelection.fromTime === undefined ||
        this.timeSelection.fromTime.hour === null || this.timeSelection.fromTime.hour === undefined ||
        this.timeSelection.fromTime.minute === null || this.timeSelection.fromTime.minute === undefined ||
        this.timeSelection.toTime === null || this.timeSelection.toTime === undefined ||
        this.timeSelection.toTime.hour === null || this.timeSelection.toTime.hour === undefined ||
        this.timeSelection.toTime.minute === null || this.timeSelection.toTime.minute === undefined
      ) {
        return true
      }
      else {
        return false
      }
    }
    else {
      if (
        this.dateSelection.fromDate === null || this.dateSelection.fromDate === undefined ||
        this.timeSelection.fromTime === null || this.timeSelection.fromTime === undefined ||
        this.timeSelection.fromTime.hour === null || this.timeSelection.fromTime.hour === undefined ||
        this.timeSelection.fromTime.minute === null || this.timeSelection.fromTime.minute === undefined
      ) {
        return true
      }
      else {
        return false
      }
    }
  }
  onDateSelection(date: NgbDate, isMultiDay: boolean) {
    if (isMultiDay) {
      if (!this.dateSelection.fromDate && !this.dateSelection.toDate) {
        this.dateSelection.fromDate = date
      } else if (this.dateSelection.fromDate && !this.dateSelection.toDate && date.after(this.dateSelection.fromDate)) {
        this.dateSelection.toDate = date
      } else {
        this.dateSelection.toDate = null
        this.dateSelection.fromDate = date
      }
    }
    else {
      this.dateSelection.fromDate = date
    }
  }
  isHovered(date: NgbDate) {
    return this.dateSelection.fromDate
      && !this.dateSelection.toDate
      && this.hoveredDate
      && date.after(this.dateSelection.fromDate)
      && date.before(this.hoveredDate)
  }
  isInside(date: NgbDate) {
    return this.dateSelection.toDate
      && date.after(this.dateSelection.fromDate)
      && date.before(this.dateSelection.toDate)
  }
  isRange(date: NgbDate) {
    return date.equals(this.dateSelection.fromDate)
      || (this.dateSelection.toDate
        && date.equals(this.dateSelection.toDate))
      || this.isInside(date)
      || this.isHovered(date)
  }
  destroySelf() {
    // this.componentRef.destroy()
    this.activeModal.close()
  }
  resetButtons() {
    this.eventData.structure.isSwiss = false
    this.eventData.structure.isBracket = false
    this.eventData.structure.isGroup = false
    this.eventData.structure.isRoundRobin = false
    this.eventData.structure.isBatch = false
  }
  calculateBracketSize() {
    if (this.eventData.structure.group.matchesPerGroup !== null
      && this.eventData.structure.group.playersAdvancingPerGroup !== null) {
      // eslint-disable-next-line max-len
      this.eventData.structure.group.bracketSize = this.eventData.structure.group.matchesPerGroup * this.eventData.structure.group.playersAdvancingPerGroup
    }
  }
  createNewEvent() {
    // set app busy
    this.globals.isBusy.status = true
    this.globals.isBusy.message = 'Creating the event, please wait'
    this.globals.isBusy.showMessage = true

    // create the event
    let eventType = ''
    if (this.eventData.structure.isSwiss) {
      eventType = 'swiss'
    }
    else if (this.eventData.structure.isBracket) {
      eventType = 'bracket'
    }
    else if (this.eventData.structure.isGroup) {
      eventType = 'group'
    }
    else if (this.eventData.structure.isRoundRobin) {
      eventType = 'round-robin'
    }
    else if (this.eventData.structure.isBatch) {
      eventType = 'batch'
    }

    // check if registration will start closed
    if (!this.eventData.registrationOpen) {
      this.eventData.registrationOpensTimestamp = Date.parse(this.fromDateRegistrationOpen)
    }

    // add format imageUrl
    // if (this.eventData.ruleset.)
    this.es.createEvent(this.eventData, eventType)
      .then((response: IPromiseResponse) => {
        // return to non-busy state
        this.globals.isBusy.status = false

        console.log(response)
        if (response.status) {
          this.destroySelf()
        }
        else {
          this.toastService.show(response.text, { classname: 'error-toast', delay: 6000 })
        }
      })
  }
  updateEvent() {

    this.eventDoc.details = this.eventData
    this.eventDoc.startingTable = this.eventData.startingTable
    this.eventDoc.isOnlineTournament = this.eventData.isOnlineTournament
    this.eventDoc.roundTimer = this.eventData.roundTimer

    // check for ruleset and reprint policies
    const formats = this.formatsLoaded$.getValue()
    if (formats[formats.findIndex(x => x.name === this.eventData.format)].ruleSets.length === -1) {
      this.eventData.ruleset.name = ''
      this.eventData.ruleset.url = ''
    }
    if (formats[formats.findIndex(x => x.name === this.eventData.format)].reprintPolicies.length === -1) {
      this.eventData.reprintPolicy.name = ''
      this.eventData.reprintPolicy.url = ''
    }

    // check for registration open
    if (this.eventDoc.statusCode === 14 && this.eventDoc.details.registrationOpen) {
      this.eventDoc.statusCode = 0
      this.eventDoc.statusText = this.es.statusText[0]
      this.eventData.registrationOpensTimestamp = null
    }
    else if (this.eventDoc.statusCode === 0 && !this.eventDoc.details.registrationOpen) {
      // check for registration open
      this.eventDoc.statusCode = 14
      this.eventDoc.statusText = this.es.statusText[14]
      this.eventData.registrationOpensTimestamp = Date.parse(this.fromDateRegistrationOpen)
    }
    else if (!this.eventDoc.details.registrationOpen) {
      this.eventData.registrationOpensTimestamp = Date.parse(this.fromDateRegistrationOpen)
    }

    this.es.updateEventData(this.eventDoc)
      .then((response: IPromiseResponse) => {
        if (response.status) {
          this.destroySelf()
        }
        else {
          this.toastService.show(response.text, { classname: 'error-toast', delay: 6000 })
        }
      })
  }
  byName(data1: IRuleset | IReprintPolicy, data2: IRuleset | IReprintPolicy) {
    if (data1 === undefined || data2 === undefined || data1 === null || data2 === null) return false
    if (data1.name === undefined || data2.name === undefined || data1.name === null || data2.name === null) return false
    return data1.name === data2.name
  }
  get minRegFeeMet(): boolean {
    return this.minRegFee !== null ? this.eventData.registrationFee.amount >= this.minRegFee : false
  }
  get disableFormSubmit() {

    if (!this.eventData || this.eventData === undefined) return true

    if (
      this.eventData.name.length < 5 ||
      this.eventData.location.name.length < 5 ||
      this.eventData.datetime === '' ||
      !this.checkDatePattern(this.eventData.datetime)
    ) {
      // console.log('fail 1')
      this.formWarning = `Event name, location, and date must be set`
      return true
    }
    if (
      this.eventData.type === '' ||
      this.eventData.format === ''
    ) {
      // console.log('fail 2')
      this.formWarning = `Event type or format missing`
      return true
    }

    const formats = this.formatsLoaded$.getValue()
    if (
      this.eventData.type === 'Constructed' &&
      formats[formats.findIndex(x => x.name === this.eventData.format)].name === this.eventData.format &&
      formats[formats.findIndex(x => x.name === this.eventData.format)].ruleSets.length > 0 &&
      this.eventData.ruleset.name === ''
    ) {
      // console.log('fail 3')
      this.formWarning = `You have to select a ruleset`
      return true
    }
    if (
      this.eventData.type === 'Constructed' &&
      formats[formats.findIndex(x => x.name === this.eventData.format)].name === this.eventData.format &&
      formats[formats.findIndex(x => x.name === this.eventData.format)].reprintPolicies.length > 0 &&
      this.eventData.reprintPolicy.name === ''
    ) {
      // console.log('fail 4')
      this.formWarning = `You have to select a reprint policy`
      return true
    }
    if (
      !this.eventData.structure.isBatch &&
      !this.eventData.structure.isBracket &&
      !this.eventData.structure.isSwiss &&
      !this.eventData.structure.isRoundRobin &&
      !this.eventData.structure.isGroup
    ) {
      // console.log('fail 5')
      this.formWarning = `Select the structure to use`
      return true
    }
    if (
      this.eventData.structure.isBracket === true &&
      this.eventData.structure.bracket.singleElimination === false &&
      this.eventData.structure.bracket.doubleElimination === false
    ) {
      // console.log('fail 6')
      this.formWarning = `Select a bracket type`
      return true
    }
    if (
      this.eventData.roundTimer === null ||
      this.eventData.startingTable === null
    ) {
      // console.log('fail 7')
      this.formWarning = `Both round timer and starting table must be set`
      return true
    }
    if (
      this.eventData.isPasswordProtected &&
      this.eventData.password === ''
    ) {
      // console.log('fail 8')
      this.formWarning = `Enter a password to contiune`
      return true
    }
    if (
      this.eventData.hasAttendeeCap &&
      this.eventData.attendeeCap === null
    ) {
      // console.log('fail 9')
      this.formWarning = `Set the cap number`
      return true
    }
    if (
      !this.eventData.registrationOpen &&
      this.dateSelectionRegistrationOpens.fromDate === null ||
      !this.eventData.registrationOpen &&
      this.timeSelectionRegistrationOpens.fromTime === null
    ) {
      // console.log('fail 10')
      this.formWarning = `Select both date and time for when registration opens`
      return true
    }
    if (this.eventData.registrationFee.active && !this.minRegFeeMet) {
      this.formWarning = `Registration fee needs to be adjusted to meet minimum requirements`
      return true
    }

    this.formWarning = ``
    return false
  }
  get reprintPolicyOk() {
    const formats = this.formatsLoaded$.getValue()
    if (
      formats[formats.findIndex(x => x.name === this.eventData.format)].name === this.eventData.format &&
      formats[formats.findIndex(x => x.name === this.eventData.format)].reprintPolicies.length > 0 &&
      this.eventData.reprintPolicy.name === ''
    ) {
      return false
    }
    return true
  }
  get rulesetOk() {
    const formats = this.formatsLoaded$.getValue()
    if (
      formats[formats.findIndex(x => x.name === this.eventData.format)].name === this.eventData.format &&
      formats[formats.findIndex(x => x.name === this.eventData.format)].ruleSets.length > 0 &&
      this.eventData.ruleset.name === ''
    ) {
      return false
    }
    return true
  }
  get structureOk() {
    if (
      !this.eventData.structure.isBatch &&
      !this.eventData.structure.isBracket &&
      !this.eventData.structure.isSwiss &&
      !this.eventData.structure.isRoundRobin &&
      !this.eventData.structure.isGroup
    ) {
      return false
    }
    return true
  }
  get monthsToDisplay() {
    return this.globals.isMobile ? 1 : 3
  }
  get showPaymentOptions() {
    if (this.isEdit && this.auth.user.uid !== this.eventDoc.createdByUid) return false
    return this.auth.user.stripe !== undefined &&
      this.auth.user.stripe !== null &&
      this.auth.user.stripe.charges_enabled &&
      this.auth.user.stripe.payouts_enabled &&
      this.auth.user.stripe.role === StripeRole.SERVICE_PROVIDER
  }
  get registrationFeeTooltip() {
    return this.showPaymentOptions
      ? 'Toggle registration fee'
      : `To be able to set registration fee options, you need to sign-up as a service provider. Check your settings for this option.`
  }
  checkDatePattern(value: string) {
    // console.log('pattern-check', pattern.test(value))
    if (this.eventData.isMultiDay) {
      const multiPattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}[+|-][0-9]{2}:[0-9]{2} > [0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}[+|-][0-9]{2}:[0-9]{2}$/
      return multiPattern.test(value)
    }
    else {
      const pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}[+|-][0-9]{2}:[0-9]{2}$/
      return pattern.test(value)
    }
  }

  // IMAGE UPLOAD
  fileChangeEvent(event: any): void {
    this.showImagePreview = true
    const name = event.target.files[0].name
    const lastDot = name.lastIndexOf('.')
    this.fileExt = name.substring(lastDot + 1)
    this.imageChangedEvent = event
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64
  }
  imageLoaded() {
    // show cropper
    console.log('imageLoaded')
    this.saveDisabled = false
  }
  cropperReady() {
    // cropper ready
    console.log('cropperReady')
  }
  loadImageFailed() {
    // show message
    console.log('loadImageFailed')
  }
  async saveImage() {
    this.globals.isBusy.message = 'Uploading your image, please wait'
    this.globals.isBusy.status = true
    this.globals.isBusy.showMessage = true

    const filePath = 'event-banners/' + this.eventDoc.docId + '.' + this.fileExt
    console.log('create filePath', filePath)
    const ref = this.storage.ref(filePath)
    console.log('create ref', ref)
    const file = this.imageToDataUri(this.croppedImage, 600, 300)
    console.log('create file', file)
    const task = ref.putString(file, 'data_url')
    console.log('crate task', task)
    task
      .snapshotChanges()
      .pipe(
        takeUntil(this.componentWasDestroyed$),
        finalize(() => {
          console.log('task finalized, getting download url')
          this.downloadUrl = ref.getDownloadURL()
          // store the download url as the avatar link for both user and player doc.
          this.downloadUrl.subscribe(async (url) => {
            this.globals.isBusy.message = 'Uploading done, updating the event banner url'
            console.log('downlaod url emitted')
            await this.updateEventBanner(url)

            this.globals.isBusy.message = 'Finished!'
            setTimeout(() => {
              this.globals.isBusy.status = false
              this.globals.isBusy.showMessage = false
            }, 1500)
          })
        })
      ).subscribe()

    this.change = false
    this.croppedImage = ''
    this.saveDisabled = true
    this.imageChangedEvent = ''
  }
  async updateEventBanner(url: string) {
    this.eventDoc.bannerUrl = url
    this.afs.collection('events').doc(this.eventDoc.docId)
      .update({
        bannerUrl: url
      })
      .then(() => {

      })
      .catch((err) => {
        console.log(err)
      })
    this.change = true
  }
  imageToDataUri(base64: string, width: number, height: number) {
    // Create and initialize two canvas
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const canvasCopy = document.createElement('canvas')
    const copyContext = canvasCopy.getContext('2d')

    // Create original image
    const img = new Image()
    img.src = base64

    // Draw original image in second canvas
    canvasCopy.width = img.width
    canvasCopy.height = img.height
    copyContext.drawImage(img, 0, 0)

    // Copy and resize second canvas to first canvas
    canvas.width = width
    canvas.height = height
    ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height)

    return canvas.toDataURL()
  }
  removeBannerImage() {
    this.change = false
    this.afs.collection('events').doc(this.eventDoc.docId)
      .update({
        bannerUrl: ''
      })
    const file = this.eventDoc.bannerUrl
    const ref = this.storage.ref(file)
    ref.delete()
  }
  pad2(n: number) { return n < 10 ? '0' + n : n }
}

<ng-container *ngIf="deck">

  <div class="p-3">

    <ng-container *ngIf="editMode; else showPicture">
      <div class="text-secondary mb-3">Uploading a deck photo will overwrite any existing deck photo already uploaded.</div>
      <div class="d-flex align-items-center mb-3">
        <input type="file" id="deckPhoto" #fileInput (change)="fileChangeEvent($event)" class="flex-grow-1 me-2" [disabled]="deck.isLocked">
        <button class="btn btn-sm {{ saveDisabled ? 'btn-outline-secondary' : 'btn-outline-main' }}"
          [disabled]="saveDisabled || deck.isLocked" (click)="saveImage()">Upload</button>
      </div>
      <image-cropper class="image-cropper mb-3" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
        [aspectRatio]="16 / 9" [containWithinAspectRatio]="true" format="png" (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"
        [disabled]="false">
      </image-cropper>
    </ng-container>

    <ng-template #showPicture>
      <div class="deck-pic-wrapper">
        <img [src]="deck.deckPhotoUrl">
      </div>
    </ng-template>

  </div>


  <div class="changes-wrapper d-flex align-items-center">

    <button class="btn btn-sm btn-blank text-red me-2" (click)="editMode = false" *ngIf="showCancel">
      <span>Cancel</span>
    </button>
    <button class="btn btn-sm {{ editMode ? 'btn-outline-main' : 'btn-outline-secondary' }} me-2"
      (click)="editMode = true" *ngIf="showChange" [disabled]="deck.isLocked">
      <fa-icon [icon]="faEdit" [fixedWidth]="true" class="me-2"></fa-icon>
      <span>Change</span>
    </button>
    <button class="btn btn-sm btn-outline-danger" (click)="deletePicture()" *ngIf="showDelete" [disabled]="deck.isLocked">
      <fa-icon [icon]="faTrash" [fixedWidth]="true" class="me-2"></fa-icon>
      <span>Delete</span>
    </button>

  </div>


  <ng-template #deleteConfirmation let-modal>
    <div class="modal-header">
      Delete Picture?
    </div>

    <div class="modal-body">
      <div class="text-normal">Are you sure you want to delete the picture, this can't be undone!</div>
    </div>

    <div class="modal-footer d-flex align-items-center justify-content-end">
      <button class="btn btn-sm btn-blank text-red" (click)="modal.close()">Cancel</button>
      <button class="btn btn-sm btn-blank" (click)="modal.dismiss('delete')">Delete</button>
    </div>
  </ng-template>


</ng-container>

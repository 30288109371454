import {
  ElementRef,
  Directive, Input, TemplateRef,
  Renderer2,
  Injector,
  ViewContainerRef,
  NgZone,
  OnInit,
  OnDestroy,
  Inject,
  ChangeDetectorRef,
  ApplicationRef
} from '@angular/core'

import { DOCUMENT } from '@angular/common'
import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap'
@Directive({
  standalone: true,
  selector: '[stickyPopover]',
  exportAs: 'stickyPopover'
})
export class StickyPopoverDirective extends NgbPopover implements OnInit, OnDestroy {
  @Input() stickyPopover: TemplateRef<any>

  popoverTitle: string

  placement: 'auto' | 'top' | 'bottom' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'left-top' | 'left-bottom' | 'right-top' | 'right-bottom' | ('auto' | 'top' | 'bottom' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'left-top' | 'left-bottom' | 'right-top' | 'right-bottom')[]

  triggers: string
  container: string
  ngpPopover: TemplateRef<any>
  canClosePopover: boolean
  popoverClass: string
  openDelay: number
  closeDelay: number
  disablePopover: boolean
  timeout: any

  toggle(): void {
    super.toggle()
  }

  isOpen(): boolean {
    return super.isOpen()
  }

  constructor(
    private _elRef: ElementRef,
    private _render: Renderer2,
    injector: Injector,
    private applicationRef: ApplicationRef,
    private viewContainerRef: ViewContainerRef,
    config: NgbPopoverConfig,
    ngZone: NgZone,
    private changeRef: ChangeDetectorRef,
    @Inject(DOCUMENT) _document: any) {
    super(_elRef, _render, injector, viewContainerRef, config, ngZone, _document, changeRef, applicationRef)
    this.triggers = 'manual'
    this.popoverTitle = ''
    this.container = 'body'
  }

  ngOnInit(): void {
    
    super.ngOnInit()

    this.ngbPopover = this.stickyPopover
    
    this._render.listen(this._elRef.nativeElement, 'mouseenter', () => {
      this.canClosePopover = true
      this.timeout = setTimeout(() => this.open(), this.openDelay)
    })

    this._render.listen(this._elRef.nativeElement, 'mouseleave', (event: Event) => {
      clearTimeout(this.timeout)
      setTimeout(() => { if (this.canClosePopover) { this.close() } }, 100)
    })

    this._render.listen(this._elRef.nativeElement, 'click', () => {
      this.close()
    })

  }

  ngOnDestroy(): void {
    super.ngOnDestroy()
  }

  open() {
    if (this.disablePopover) { return }
    super.open()
    setTimeout(() => {
      const popover = window.document.querySelector('.popover')
      this._render.listen(popover, 'mouseover', () => {
        this.canClosePopover = false
      })

      this._render.listen(popover, 'mouseout', () => {
        this.canClosePopover = true
        setTimeout(() => { if (this.canClosePopover) { this.close() } }, 0)
      })
    }, 0)
  }

  close() {
    setTimeout(() => super.close(), this.closeDelay)
  }
}
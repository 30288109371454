import { ILeagueDocument, ILeagueEventPlayerDetails, ILeaguePlayer, ILeaguePointInfo, IMatchData, LeaguePointType } from 'tolaria-cloud-functions/src/_interfaces';
import { MatchService } from './match.service';
import { ToastService } from './toast.service';
import { AuthService } from './auth.service';
import { Observable, firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { EventService } from 'src/app/services/event/event.service';
import { map, shareReplay, withLatestFrom } from 'rxjs/operators';

export interface ILeagueData extends ILeagueDocument {
  events: ILeagueEventMeta[],
  matches: IMatchData[],
  isOrganizer: boolean;
}
export interface ILeagueEventMeta {
  name: string;
  docId: string;
  isEnded: boolean;
}
export interface ILeaguePlayerDetails extends IEventPlayerDetails {
  eventDocId: string;
  eventName: string;
}

@Injectable({
  providedIn: 'root'
})
export class LeaguesService {

  private leaguesCollection$: Observable<ILeagueDocument[]>;
  private leagues$: Observable<ILeagueData[]>;

  constructor(
    private afs: AngularFirestore,
    private eventService: EventService,
    private auth: AuthService,
    private toastService: ToastService,
    private matchService: MatchService,
  ) {
    // Initial subscription to changes in firestore - Populate behaviourSubject and first emit on valueChanges
    this.leaguesCollection$ = this.afs.collection<ILeagueDocument>('leagues').valueChanges();

    this.leagues$ = this.leaguesCollection$.pipe(
      withLatestFrom(this.eventService.events$, this.matchService.allMatches$),
      map(([leagues, events, matches]) => {
        const leagueMeta: ILeagueData[] = [];
        leagues.forEach((league) => {
          const meta = league as ILeagueData;
          meta.isOrganizer = league.createdByUid === this.auth.user.uid || this.auth.user.role === 'admin';
          meta.events = [];
          meta.matches = [];
          meta.eventDocIds.forEach((eventDocId) => {
            // find event and add its meta to the league
            const event = events.find(e => e.docId === eventDocId);
            if (event !== undefined) {
              meta.events.push({
                name: event.details.name,
                docId: eventDocId,
                isEnded: event.statusCode === 8,
              });
            }
            // find all matches for the event and add them to the league meta
            const eventMatches = matches.filter(m => m.eventDocId === eventDocId);
            meta.matches = meta.matches.concat(eventMatches);
          });
          leagueMeta.push(meta);
        })
        return leagueMeta;
      }),
      shareReplay(1)
    );
  }

  public get translations() {
    return {
      [LeaguePointType.RANK]: {
        text: 'Ranking',
        tooltip: `Reward points according to their final standings`,
        explaination: `Received according to your ranking in an event`
      },
      [LeaguePointType.BRACKET_WIN]: {
        text: 'Bracket Win',
        tooltip: `Reward points for every match win of type bracket (playoffs)`,
        explaination: `Received for every match win in the playoffs of an event`
      },
      [LeaguePointType.MATCH_WIN]: {
        text: 'Match Win',
        tooltip: `Reward points for every match wins`,
        explaination: `Received for every match win during an event`
      },
      [LeaguePointType.GAME_WIN]: {
        text: 'Game Win',
        tooltip: `Reward points for every game wins`,
        explaination: `Received for every game win during an event`
      },
      [LeaguePointType.MATCH_FULFILMENT]: {
        text: 'Match Fulfilment',
        tooltip: `Reward points for finalizing all matches (used for group stages)`,
        explaination: `Received if you finish all your group matches in an event`
      },
      [LeaguePointType.CUSTOM]: {
        text: 'Custom Point',
        tooltip: `Reward points for custom achivements`,
        explaination: `Added manually by the tournament organizer`,
      }
    }
  }

  public get leagues(): Observable<ILeagueData[]> {
    return this.leagues$;
  }

  public get pointTypes(): string[] {
    return Object.keys(LeaguePointType);
  }

  public getLeagueByDocId(leagueDocId: string): Observable<ILeagueData> {
    return this.leagues$.pipe(
      map((leagues: ILeagueData[]) => leagues.find(l => l.docId === leagueDocId))
    );
  }

  public saveLeague(leagueData: ILeagueData): void {
    // strip the additional data before storing in firebase
    delete leagueData.events;
    delete leagueData.isOrganizer;
    delete leagueData.matches;

    // send update to firebase
    this.afs.collection('leagues').doc(leagueData.docId).set(leagueData)
      .then(() => {
        this.toastService.show('Changes saved', { delay: 2000, classname: 'success-toast' })
      })
      .catch((error) => {
        console.log(error);
        this.toastService.show('Something went wrong, changes NOT saved', { delay: 6000, classname: 'error-toast' })
      });
  }

  public deleteLeague(leagueDocId: string): void {
    this.afs.collection('leagues').doc(leagueDocId).delete()
      .then(() => {
        this.toastService.show('League deleted', { delay: 2000, classname: 'success-toast' })
      })
      .catch((error) => {
        console.log(error);
        this.toastService.show('Something went wrong, league NOT deleted', { delay: 6000, classname: 'error-toast' })
      });
  }

  // public async getLeaguePlayers(leagueDocId: string): Promise<ILeaguePlayer[]> {
  //   return new Promise((resolve) => {
  //     const callable = this.fns.httpsCallable('getLeaguePlayers');
  //     callable({leagueDocId}).toPromise()
  //       .then((res: string) => {
  //         const data = JSON.parse(res) as IPromiseResponse;
  //         resolve(data.data as ILeaguePlayer[]);
  //       });
  //   })
  // }

  // public getLeagues(): Observable<ILeagueMeta[]> {
  //   this.leagues$.pipe(
  //     withLatestFrom(this.leagueEvents$),
  //     map(async ([leagues, leagueEvents]) => {
  //       for await (const league of leagues) {
  //         const leagueMeta: ILeagueMeta = league as ILeagueMeta;
  //         leagueMeta.players = await this.getLeagueEventPlayers(league.eve)
  //       }
  //     })
  //   );
  // }

  // private getLeagueEventPlayers(eventDocId: string): Promise<IEventPlayerDetails[]> {
  //   return new Promise((resolve, reject) => {
  //     this.afs.collection('events').doc(eventDocId).collection<IEventPlayerDetails>('players')
  //       .get()
  //       .toPromise()
  //       .then(async (snapShot) => {
  //         const players: IEventPlayerDetails[] = [];
  //         if (snapShot.docs.length > 0) {
  //           for await (const doc of snapShot.docs) {
  //             players.push(doc.data() as IEventPlayerDetails);
  //           }
  //         }
  //         resolve(players);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         reject([]);
  //       })
  //   })
  // }


  private LeagueTiebreakers = {
    equal: (a: ILeaguePlayer, b: ILeaguePlayer) => {
      if (a.leaguePoints === b.leaguePoints) {
        if (a.matchPoints === b.matchPoints) {
          if (a.gamePoints === b.gamePoints) return true;
          else return 3;
        } else return 2;
      } else return 1;
    },
    diff: (a: ILeaguePlayer, b: ILeaguePlayer, n: number) =>
      n === 1 ? b.leaguePoints - a.leaguePoints :
        n === 2 ? b.matchPoints - a.matchPoints : b.gamePoints - a.gamePoints
  }

  public async getLeaguePlayers(league: ILeagueData): Promise<ILeaguePlayer[]> {

    /* FETCH all players from events connected to a league.
     * Add points according to structure and custom points.
     *
     * 1) Fetch the league document
     * 2) Fetch all players
     * 3) Create an array with league players (sum up stats and award points to players according to results in the connected events)
     * 4) Rank players
     * 5) Return list with points and ranking
     */

    return new Promise(async (resolve, reject) => {

      console.log(`starting`);

      // get the params sent
      console.log(`:: leagueDocId = ${league.docId}`);


      // 1) FETCH LEAGUE DOCUMENT
      console.log(`1) FETCH LEAGUE DOCUMENT`);
      // const leagueDocRef = await this.afs.collection('leagues').doc<ILeagueDocument>(league.docId).get().toPromise();
      // if (!leagueDocRef.exists) resolve({ status: false, text: 'league document no found' });
      // const leagueDocument: ILeagueDocument = leagueDocRef.data() as ILeagueDocument;
      // const leagueEventDocIds: string[] = leagueDocument.eventDocIds;
      console.log(`:: document fetched`);


      // 2) FETCH ALL PLAYERS
      console.log(`2) FETCH ALL PLAYERS`);
      const playerList: ILeaguePlayerDetails[] = [];
      // loop through all events and fetch the player documents
      for await (const eventDocId of league.eventDocIds) {
        const event = league.events.find(e => e.docId === eventDocId)
        // check if event is ended
        if (event.isEnded) {
          console.log(`.. fetching players for = ${eventDocId}`);
          // get the collection of player documents
          let playerSnap
          let matchSnap
          try {
            playerSnap = await firstValueFrom(this.afs.collection('events').doc(eventDocId).collection<IEventPlayerDetails>('players').get())
            if (playerSnap.empty) {
              // handle error here
            }
          }
          catch(e) { console.log(e) }

          try {
            matchSnap = await firstValueFrom(this.afs.collection<IMatchData>('matches', ref => ref.where('eventDocId', '==', eventDocId)).get())
            if (matchSnap.empty) {
              // handle error here
            }
          }
          catch(e) { console.log(e) }

          const players = playerSnap.empty ? [] : playerSnap.docs.map(i => i.data())
          const matches = matchSnap.empty ? [] : matchSnap.docs.map(i => i.data())
          const eventData = await this.eventService.getEventByIdPromise(eventDocId)

          const extendedPlayers = this.eventService.__getExtendedPlayers(players, matches, eventData)
          console.log(`..  >> ${extendedPlayers.length} players fetched`);
          console.log('..  >> ', extendedPlayers)
          // loop through all player documents and add them to the playerList
          for await (const doc of extendedPlayers) {
            const player = doc as ILeaguePlayerDetails;
            player.eventDocId = eventDocId;
            player.eventName = league.events.find(e => e.docId === eventDocId).name;
            playerList.push(player);
          }
        }
      }
      console.log(`.. all players fetched. playerList: `, playerList);


      // 3) CREATE LEAGUE PLAYER LIST
      console.log(`3) CREATE LEAGUE PLAYER LIST`);
      const leaguePlayers: ILeaguePlayer[] = [];
      for await (const player of playerList) {
        // check if player is added to leaguePlayers. If not, then add it
        if (leaguePlayers.find(p => p.playerDocId === player.playerDocId) === undefined) {
          console.log(`getLeaguePlayers:: adding ${player.name}`);
          const thisPlayerList = JSON.parse(JSON.stringify(playerList.filter(p => p.playerDocId === player.playerDocId)));
          console.log(`getLeaguePlayers:: players event data`, thisPlayerList);
          const leaguePlayer = await this.createLeaguePlayerObject(player, league, thisPlayerList);
          leaguePlayers.push(leaguePlayer);
        }
      }
      console.log(`.. all league players created. leaguePlayers: `, leaguePlayers);


      // 4) RANK LEAUGE PLAYERS
      console.log(`4) RANK LEAUGE PLAYERS`);
      // sort players by standings to be able to add the ranking
      leaguePlayers.sort((a, b) => {
        const eqCheck = this.LeagueTiebreakers.equal(a, b);
        if (eqCheck === true) {
          return 0;
        }
        else {
          if (typeof eqCheck === 'number') return this.LeagueTiebreakers.diff(a, b, eqCheck);
          return 0;
        }
      });
      // add ranking
      let rank = 1;
      for (const [i, player] of leaguePlayers.entries()) {
        if (i === 0) player.leagueRank = rank;
        else {
          if (this.LeagueTiebreakers.equal(leaguePlayers[i - 1], leaguePlayers[i]) === true) player.leagueRank = rank;
          else rank++; player.leagueRank = rank;
        }
        console.log(`.. ${player.name} is ranked ${player.leagueRank}`);
      }
      console.log(`.. all league players ranked`);


      // 5) Return list with all the league players
      console.log(leaguePlayers);
      resolve(leaguePlayers);

    });

  }

  private async createLeaguePlayerObject(player: ILeaguePlayerDetails, league: ILeagueData, thisPlayerList: ILeagueEventPlayerDetails[]): Promise<ILeaguePlayer> {

    // debugger

    const points = await this.awardLeaguePointsToPlayer(league, thisPlayerList);
    const leaguePlayer: ILeaguePlayer = {
      playerDocId: player.playerDocId,
      playerUid: player.playerUid,
      name: player.name,
      wins: await this.sumProperty(thisPlayerList, 'matchesWon'),
      losses: await this.sumProperty(thisPlayerList, 'matchesLost'),
      draws: await this.sumProperty(thisPlayerList, 'matchesDrawn'),
      matchPoints: await this.sumProperty(thisPlayerList, 'matchPoints'),
      gamePoints: await this.sumProperty(thisPlayerList, 'gamePoints'),
      leagueDocId: league.docId,
      leagueRank: 0,
      leaguePoints: points.points,
      leaguePointsInfo: points.pointsInfo
    }

    return leaguePlayer;
  }

  private async awardLeaguePointsToPlayer(league: ILeagueData, thisPlayerList: ILeaguePlayerDetails[]): Promise<ILeaugePlayerPoints> {
    // this function will be run for a single player at a time

    // check if there are any points to be rewarded at all, if none return an empty response
    if (league.pointStructure.length === 0) { return { points: 0, pointsInfo: [] } };

    // create the array to store all points awarded to the player
    const pointsInfo: ILeaguePointInfo[] = [];

    // loop through the point structure to award points
    league.pointStructure.forEach(async (point) => {

      switch (point.type) {

        case LeaguePointType.RANK:
          for await (const player of thisPlayerList) {
            const rank = player.rank === undefined ? 0 : player.rank;
            // check if the event has ended, else skip
            if (league.events.find(e => e.docId === player.eventDocId).isEnded && rank >= point.rankFrom && rank <= point.rankTo) {
              const pointInfo: ILeaguePointInfo = {
                points: point.points,
                comment: `Finnished @ ${player.rank}${this.getRankSuffix(player.rank)} place in ${player.eventName}`,
              };
              pointsInfo.push(pointInfo);
            }
          }
          break;

        case LeaguePointType.MATCH_WIN:
          for await (const player of thisPlayerList) {
            // check if the event has ended, else skip
            if (league.events.find(e => e.docId === player.eventDocId).isEnded) {
              const pointInfo: ILeaguePointInfo = {
                points: point.points * player.matchesWon,
                comment: `Won ${player.matchesWon} matches in event ${player.eventName}`,
              };
              pointsInfo.push(pointInfo);
            }
          }
          break;

        case LeaguePointType.GAME_WIN:
          for await (const player of thisPlayerList) {
            // check if the event has ended, else skip
            if (league.events.find(e => e.docId === player.eventDocId).isEnded) {
              const gameWins = player.gamePoints / 3; // divide the number of MatchPoints with 3 as this is the points per MatchWin
              const pointInfo: ILeaguePointInfo = {
                points: point.points * gameWins,
                comment: `Won ${gameWins} games in event ${player.eventName}`,
              };
              pointsInfo.push(pointInfo);
            }
          }
          break;

        case LeaguePointType.BRACKET_WIN:
          for await (const player of thisPlayerList) {
            // check if the event has ended, else skip
            if (league.events.find(e => e.docId === player.eventDocId).isEnded) {
              // filter out all bracket matches from the event
              const bracketMatches = league.matches
                .filter(m => m.player1.playerDocId === player.playerDocId || m.player2.playerDocId === player.playerDocId)
                .filter(m => m.eventDocId === player.eventDocId && m.isType === 'bracket');
              // check if any matches to consider
              if (bracketMatches.length > 0) {
                let bracketWins = 0;
                // loop through all matches and add wins if winner
                for await (const match of bracketMatches) {
                  const playerIs = match.player1.playerDocId === player.playerDocId ? 'player1' : 'player2';
                  match[playerIs].isWinner ? bracketWins++ : null;
                }
                // if any wins, create the point
                if (bracketWins > 0) {
                  const pointInfo: ILeaguePointInfo = {
                    points: point.points * bracketWins,
                    comment: `Won ${bracketWins} bracket matches in event ${player.eventName}`,
                  };
                  pointsInfo.push(pointInfo);
                }
              }
            }
          }
          break;

        case LeaguePointType.MATCH_FULFILMENT:
          for await (const player of thisPlayerList) {
            // check if the event has ended, else skip
            if (league.events.find(e => e.docId === player.eventDocId).isEnded) {
              // filter out all bracket matches from the event
              const allMatchesPlayed = league.matches
                .filter(m => m.player1.playerDocId === player.playerDocId || m.player2.playerDocId === player.playerDocId)
                .filter(m => m.eventDocId === player.eventDocId && !m.isReported && m.isType === 'group').length === 0;
              if (allMatchesPlayed) {
                const pointInfo: ILeaguePointInfo = {
                  points: point.points,
                  comment: `Played all matches during the group stage of event ${player.eventName}`,
                };
                pointsInfo.push(pointInfo);
              }
            }
          }
          break;

      }

    });

    for await (const manualPoint of league.manualPoints.filter(p => p.playerDocId === thisPlayerList[0].playerDocId)) {
      pointsInfo.push({
        points: manualPoint.points,
        comment: manualPoint.comment
      } as ILeaguePointInfo);
    }

    const points = await this.sumProperty(pointsInfo, 'points');

    return {
      points,
      pointsInfo
    } as ILeaugePlayerPoints;

  }

  private async sumProperty(arr: Array<any>, property: string) {
    let total = 0
    for await (const item of arr) {
      total += item[property];
    }
    return total
  }

  private getRankSuffix(rank: number): string {
    const rankEnding = parseInt(rank.toString().substr(-1));
    if (rankEnding === 1) { return 'st' }
    else if (rankEnding === 2) { return 'nd' }
    else if (rankEnding === 3) { return 'rd' }
    else { return 'th' }
  }



}

export interface ILeaugePlayerPoints {
  points: number;
  pointsInfo: ILeaguePointInfo[]
}

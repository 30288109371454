import { IEventDetails, IEventTeam, IMatchData } from "tolaria-cloud-functions/src/_interfaces";
import { IEventTeamMeta } from "../tournament-data-helper.service";

export const ModelProcess = {

    clean: {

        teamData: (data: IEventTeam | IEventTeamMeta) => {

            const optionalProperties = [
                'rank',
                'seed',
                'isWinner',
                'isLoser',
                'matchPoints',
                'matchesWon',
                'matchesLost',
                'matchesDrawn',
                'matchesPlayed',
                'gamePoints',
                'gamesWon',
                'gamesLost',
                'gamesDrawn',
                'gamesPlayed',
                'haveByeMatch',
                'pairedUpDown',
                'teamsPlayed',
                'avoid',
                'tiebreakers',
                'selected',
                'rating',
                'index',
                'players',
                'seated',
                'extra',
                'playoffMatchesPlayed',
                'playoffMatchesWon',
            ]

            for (const property of optionalProperties) {

                if (data.hasOwnProperty(property)) {
                    delete data[property]
                }

            }

            return data

        },

    },

    extend: {

        teamData: (teams: IEventTeamMeta[], matches: IMatchData[], event: IEventDetails): IEventTeamMeta[] => {

            const teamsData: IEventTeamMeta[] = []

            for (const team of teams) {


                // get matches as player1
                const stageMatches = matches.filter(i => i.isType !== 'bracket' && i.isReported && !i.deleted)
                const playoffMatches = [
                    ...matches.filter(i => i.isType === 'bracket' && i.player1.teamId === team.id).map(i => i.player1),
                    ...matches.filter(i => i.isType === 'bracket' && i.player2.teamId === team.id).map(i => i.player2),
                ]
                const matchesAsPlayer1 = stageMatches.filter(i => i.player1.teamId === team.id).map(i => {
                    return {
                        player: i.player1,
                        match: i,
                    }
                })
                const matchesAsPlayer2 = stageMatches.filter(i => i.player2.teamId === team.id).map(i => {
                    return {
                        player: i.player2,
                        match: i,
                    }
                })
                const playersMatchData = [...matchesAsPlayer1, ...matchesAsPlayer2]

                // create the extended team object
                const data = team as IEventTeamMeta
                data.teamsPlayed = [
                    ...stageMatches.filter(i => i.player1.teamId === team.id).map(i => i.player2.playerDocId),
                    ...stageMatches.filter(i => i.player2.teamId === team.id).map(i => i.player1.playerDocId)
                ]
                data.gamesWon = playersMatchData.map(i => i.player.wins).reduce((sum, val) => sum + val, 0)
                data.gamesLost = playersMatchData.map(i => i.player.losses).reduce((sum, val) => sum + val, 0)
                data.gamesDrawn = playersMatchData.map(i => i.player.draws).reduce((sum, val) => sum + val, 0)
                data.gamesPlayed = (data.gamesWon + data.gamesLost + data.gamesDrawn)
                data.gamePoints = (data.gamesWon * 3) + (data.gamesDrawn * 1)
                data.matchesWon = playersMatchData.filter(i => i.player.isWinner && !i.match.isDraw).length
                data.matchesLost = playersMatchData.filter(i => !i.player.isWinner && !i.match.isDraw).length
                data.matchesDrawn = playersMatchData.filter(i => i.match.isDraw).length
                data.matchesPlayed = playersMatchData.length
                data.matchPoints = (data.matchesWon * 3) + (data.matchesDrawn * 1)
                data.avoid = data.teamsPlayed
                data.selected = false
                data.dropped = playersMatchData.filter(i => i.player.drop).length > 0 || team.dropped
                data.haveByeMatch = playersMatchData.filter(i => i.match.isByeMatch).length > 0
                data.playoffMatchesPlayed = playoffMatches.length
                data.playoffMatchesWon = playoffMatches.filter(i => i.isWinner).length
        
                teamsData.push(data)

            }


            // const rounds = Array.from({ length: event.details.structure.swiss.roundsToPlay }, (_, i) => i + 1)
            // console.log(rounds)


            // for (const team of teams) {

            //     // create the extended team object
            //     const teamData = team
            //     teamData.avoid = []
            //     teamData.teamsPlayed = []
            //     teamData.gamePoints = 0
            //     teamData.gamesWon = 0
            //     teamData.gamesLost = 0
            //     teamData.gamesDrawn = 0
            //     teamData.gamesPlayed = 0
            //     teamData.matchPoints = 0
            //     teamData.matchesWon = 0
            //     teamData.matchesLost = 0
            //     teamData.matchesDrawn = 0
            //     teamData.matchesPlayed = 0
            //     teamData.selected = false


            //     for (const round of rounds) {

            //         // filter out all the matches for the round and the team
            //         const roundMatches = matches.filter(i => i.teamIds.includes(team.id) && i.roundNumber === round && i.isType !== 'bracket')

            //         // if no matches, just skip iteration
            //         if (roundMatches.length === 0) { continue }

            //         // add the opponent team
            //         teamData.teamsPlayed.push(roundMatches[0].teamIds.filter(i => i !== team.id)[0])
            //         teamData.avoid.push(roundMatches[0].teamIds.filter(i => i !== team.id)[0])

            //         // get the number of wins
            //         const wins = roundMatches.filter(i => i.player1.teamId === team.id && i.player1.isWinner && !i.isDraw ||
            //             i.player2.teamId === team.id && i.player2.isWinner && !i.isDraw).length > 2
            //             ? 2
            //             : roundMatches.filter(i => i.player1.teamId === team.id && i.player1.isWinner && !i.isDraw ||
            //                 i.player2.teamId === team.id && i.player2.isWinner && !i.isDraw).length

            //         // get the number of losses
            //         const losses = roundMatches.filter(i => i.player1.teamId === team.id && !i.player1.isWinner && !i.isDraw ||
            //             i.player2.teamId === team.id && !i.player2.isWinner && !i.isDraw).length > 2
            //             ? 2
            //             : roundMatches.filter(i => i.player1.teamId === team.id && !i.player1.isWinner && !i.isDraw ||
            //                 i.player2.teamId === team.id && !i.player2.isWinner && !i.isDraw).length

            //         // get the number of draws
            //         let draws = roundMatches.filter(i => i.isDraw).length

            //         // update the match stats
            //         teamData.gamesWon += wins
            //         teamData.gamesLost += losses
            //         teamData.gamesDrawn += draws
            //         teamData.gamesPlayed += (wins + losses + draws)

            //         // update the game stats
            //         if (wins > losses) {
            //             teamData.matchesWon++
            //         }
            //         else if (losses > wins) {
            //             teamData.matchesLost++
            //         }
            //         else if (draws > 0 && draws >= wins && draws >= losses) {
            //             teamData.matchesDrawn++
            //         }
            //         else if (draws === wins && draws === losses) {
            //             teamData.matchesDrawn++
            //         }

            //         team.matchesPlayed++

            //     }

            //     // update points
            //     teamData.matchPoints += (teamData.matchesWon * 3) + teamData.matchesDrawn
            //     teamData.gamePoints += (teamData.gamePoints * 3) + teamData.gamesDrawn


            //     teamsData.push(teamData)

            // }

            return teamsData

        },

    }



}
import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEdit, faReply, faTrashAlt, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { MessageItem } from '../../services/message-list.service';
import { MessageActionService } from '../../services/message-action.service';
import { TolariaEmojiPickerButtonComponent } from '../tolaria-emoji-picker/tolaria-emoji-picker-button/tolaria-emoji-picker-button.component';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EmojiItem } from '../tolaria-emoji-picker/emojis';
import { ToastService } from 'src/app/services/toast.service';
import { MessageReplyToService } from '../tolaria-message-form/message-reply-to.service';

@Component({
  selector: 'app-message-action',
  templateUrl: './message-action.component.html',
  styleUrls: ['./message-action.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    TolariaEmojiPickerButtonComponent,
    NgbTooltipModule,
    NgbDropdownModule,
  ]
})
export class MessageActionComponent {
  @Input() msg: MessageItem
  @Output() editMessage = new EventEmitter<boolean>()

  constructor(
    private readonly action: MessageActionService,
    private readonly toast: ToastService,
    private readonly replyToService: MessageReplyToService,
  ) { }

  public get icon() {
    return {
      reply: faReply,
      edit: faEdit,
      trash: faTrashAlt,
      more: faEllipsisV,
    }
  }

  public get isSender(): boolean {
    if (this.msg === undefined) {
      return false
    }
    return this.msg.isSender
  }

  public onReaction(emoji: EmojiItem): void {
    this.action.addReaction(this.msg.docId, emoji.shortcode)
  }

  public deleteMessage(): void {
    this.action.delete(this.msg)
  }

  public onEditMessagePress(): void {
    this.editMessage.emit(true)
  }

  public onReplyToMessagePress(): void {
    this.replyToService.setNew(this.msg)
  }

  public onCopyId(): void {
    navigator.clipboard
      .writeText(this.msg.docId)
      .then(() => this.toast.show('Message document id copied', { classname: 'success-toast' }))
  }

  public onMarkAsUnread(): void {
    this.action.markAsUnread(this.msg)
  }

  public onPinMessage(): void {
    this.action.pinMessage(this.msg)
  }

  public onUnpinMessage(): void {
    this.action.unpinMessage(this.msg)
  }

}

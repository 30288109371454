import { ConfigService } from './../../../services/config.service'
import { AppComponent } from './../../../app.component'
import { MatchService } from './../../../services/match.service'
import { AuthService } from './../../../services/auth.service'
import { IPlayerMeta } from 'src/app/services'
import { BehaviorSubject, Observable } from 'rxjs'
import { faTimes, faCopy, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Component, Input, OnInit, ComponentRef } from '@angular/core'
import { map } from 'rxjs/operators'
import { IMatchData } from 'tolaria-cloud-functions/src/_interfaces/event.interfaces'
import { PlayerMetaService } from 'src/app/services/players/player-meta.service'
import { CommonModule } from '@angular/common'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
import { QuickMessageComponent } from '../../../private/social/messages/components/quick-message/quick-message.component'
import { PlayerBadgesCarouselComponent } from '../player-badges-carousel/player-badges-carousel.component'

export interface IGameStats {
  casual: {
    matches: number
    matchesWon: number
    matchesLost: number
    matchWinPercentage: string
    gamesWon: number
    gamesLost: number
    gameWinPercentage: string
  }
  competitive: {
    matches: number
    matchesWon: number
    matchesLost: number
    matchWinPercentage: string
    gamesWon: number
    gamesLost: number
    gameWinPercentage: string
  }
  overall: {
    matches: number
    matchesWon: number
    matchesLost: number
    matchWinPercentage: string
    gamesWon: number
    gamesLost: number
    gameWinPercentage: string
  }
}
@Component({
  selector: 'app-player-details',
  templateUrl: './player-details.component.html',
  styleUrls: ['./player-details.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgbTooltipModule,
    QuickMessageComponent,
    PlayerBadgesCarouselComponent,
  ]
})
export class PlayerDetailsComponent implements OnInit {
  @Input() playerDocId: string
  @Input() componentRef: ComponentRef<PlayerDetailsComponent>
  public playerDoc$: Observable<IPlayerMeta>
  public player$ = new BehaviorSubject<IPlayerMeta>(null)
  public matches$: Observable<IMatchData[]>
  public matchDetails$: Observable<IGameStats>

  public showDetailsMatches = false

  public handleIcons = this.configService.handleIcons

  public icons = {
    faTimes,
    faCopy,
    faChevronRight
  }

  constructor(
    // private route: ActivatedRoute,
    private readonly playerMeta: PlayerMetaService,
    public auth: AuthService,
    private ms: MatchService,
    private app: AppComponent,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.playerMeta.getPlayerMeta(this.playerDocId).then(i => this.player$.next(i))
    this.matches$ = this.ms.getMatchesForPlayerByDocId(this.playerDocId)
    this.matchDetails$ = this.matches$.pipe(
      map(matches => {
        const gameStats: IGameStats = {
          competitive: {
            matches: 0,
            matchesWon: 0,
            matchesLost: 0,
            matchWinPercentage: '',
            gamesWon: 0,
            gamesLost: 0,
            gameWinPercentage: ''
          },
          casual: {
            matches: 0,
            matchesWon: 0,
            matchesLost: 0,
            matchWinPercentage: '',
            gamesWon: 0,
            gamesLost: 0,
            gameWinPercentage: ''
          },
          overall: {
            matches: 0,
            matchesWon: 0,
            matchesLost: 0,
            matchWinPercentage: '',
            gamesWon: 0,
            gamesLost: 0,
            gameWinPercentage: ''
          }
        }

        matches.forEach((match) => {

          // get player slot
          const playerIs = match.player1.playerDocId === this.playerDocId ? 'player1' : 'player2'

          switch (match.isType) {
            case 'casual-match':
              // increase match count
              gameStats.casual.matches++
              // increase match wins / losses
              if (match[playerIs].isWinner) { gameStats.casual.matchesWon++ }
              else { gameStats.casual.matchesLost++ }
              // increase game wins and losses
              gameStats.casual.gamesWon = gameStats.casual.gamesWon + match[playerIs].wins
              gameStats.casual.gamesLost = gameStats.casual.gamesLost + match[playerIs].losses
              break

            default:
              // increase match count
              gameStats.competitive.matches++
              // increase match wins / losses
              if (match[playerIs].isWinner) { gameStats.competitive.matchesWon++ }
              else { gameStats.competitive.matchesLost++ }
              // increase game wins and losses
              gameStats.competitive.gamesWon = gameStats.competitive.gamesWon + match[playerIs].wins
              gameStats.competitive.gamesLost = gameStats.competitive.gamesLost + match[playerIs].losses
              break
          }

          // increase match count
          gameStats.overall.matches++
          // increase match wins / losses
          if (match[playerIs].isWinner) { gameStats.overall.matchesWon++ }
          else { gameStats.overall.matchesLost++ }
          // increase game wins and losses
          gameStats.overall.gamesWon = gameStats.overall.gamesWon + match[playerIs].wins
          gameStats.overall.gamesLost = gameStats.overall.gamesLost + match[playerIs].losses

        })

        // calculate casual stats
        gameStats.casual.gameWinPercentage = ((gameStats.casual.gamesWon /
          (gameStats.casual.gamesWon + gameStats.casual.gamesLost)) * 100).toFixed(1) + '%'
        gameStats.casual.matchWinPercentage = ((gameStats.casual.matchesWon / gameStats.casual.matches) * 100).toFixed(1) + '%'

        // calculate competitive stats
        gameStats.competitive.gameWinPercentage = ((gameStats.competitive.gamesWon /
          (gameStats.competitive.gamesWon + gameStats.competitive.gamesLost)) * 100).toFixed(1) + '%'
        gameStats.competitive.matchWinPercentage = ((gameStats.competitive.matchesWon / gameStats.competitive.matches) * 100).toFixed(1) + '%'

        // calculate overall stats
        gameStats.overall.gameWinPercentage = ((gameStats.overall.gamesWon /
          (gameStats.overall.gamesWon + gameStats.overall.gamesLost)) * 100).toFixed(1) + '%'
        gameStats.overall.matchWinPercentage = ((gameStats.overall.matchesWon / gameStats.overall.matches) * 100).toFixed(1) + '%'

        return gameStats
      })
    )
  }
  destroySelf() {
    this.componentRef.destroy()
  }
  copyText(text: string) {
    this.app.copyTextToClipboard(text)
  }

  public get today() {
    return new Date()
  }

}

import { ToastService } from './../../../../services/toast.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services';
import { Component, OnDestroy } from '@angular/core';
import { IPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';

enum NotificationSettings {
  eventReminders = 'Event Reminders',
  matchAppointmentReminders = 'Match Appointment Reminders',
  matchAppointments = 'Match Appointments',
  newsLetter = 'News Letter',
  developmentNews = 'Development News',
}

interface INotificationSetting {
  text: string;
  value: boolean;
  property: string;
}
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnDestroy {

  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()
  private playerDocId: string;
  public emailNotificationSettings: INotificationSetting[] = []

  constructor(
    private afs: AngularFirestore,
    private toastService: ToastService,
    private auth: AuthService,
  ) {

    this.afs
    .collection('players')
    .doc<IPlayerDetails>(this.auth.user.playerId)
    .valueChanges()
    .pipe(takeUntil(this.componentDestroyed$)).subscribe(playerDoc => {

      this.playerDocId = playerDoc.docId;

      this.emailNotificationSettings = [];

      Object.keys(playerDoc.notifications.email)
        .sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
        .forEach(key => {
          this.emailNotificationSettings.push({
            text: NotificationSettings[key],
            value: playerDoc.notifications.email[key],
            property: 'email.' + key
          } as INotificationSetting)
        })

    })
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  public updateProperty(setting: INotificationSetting): void {
    console.log(setting)
    this.afs.collection('players').doc(this.playerDocId).update({
      [`notifications.${setting.property}`]: setting.value
    })
    .then(() => this.toastService.show(`Settings for ${setting.text} updated`, { classname: 'success-toast' }))
    .catch((error) => console.log(error))

  }

  public tracker(index: number, item: INotificationSetting): string {
    return item.property;
  }

}

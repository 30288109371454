import { AuthService } from '../../../../services/auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, firstValueFrom } from 'rxjs';
import { IEventDetails, IMatchData, IEventPlayerDetails, IPlayerDetails, IInvitedPlayer, ITournamentGroup, IGroupSettings, IWaitListPlayer, IPlayerMini, IEventTeam, DeckLibraryDoc, IBatchConfig } from 'tolaria-cloud-functions/src/_interfaces';
import { PlayerExtender } from './helpers/player-extender.helper';
import { DatePipe } from '@angular/common';
import { CountryService } from 'src/app/services';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { ModelProcess } from './helpers/model-processing.helper';
import { PlayerTieBreakers, TeamTieBreakers } from './helpers/utilities/tiebreakers';

export interface IEventMeta {
  event: IEventDetails
  players: IEventPlayerDetails[]
  matches: IMatchData[]
  invitations: IInvitedPlayer[]
  waitList: IWaitListPlayer[]
  // log: IEventLog[]
}

export interface IEventPlayerMeta {
  eventPlayer: IEventPlayerDetails
  playerDoc: IPlayerDetails | null
  info: {
    isInvited: boolean
    isTemporary: boolean
    hasPaid: boolean
    hasRefund: boolean
    hasPaidFee: boolean
    hasPaidTicket: boolean
    hasRefundedFee: boolean
    hasRefundedTicket: boolean
    hasSubmittedDeckList: boolean
    hasSubmittedDeckPhoto: boolean
    hasSubmittedDietaryInformation: boolean
    hasDietaryRestrictions: boolean
    hasCheckedIn: boolean
    hasDropped: boolean
    inTeam: boolean
    inGroup: boolean
    dietaryRestrictions: string
  }
  groupName: string
  groupId: string
  groupLetter: string
  teamName: string
  teamId: string
  selected: boolean
}

export interface IEventTeamMeta extends IEventTeam {
  selected: boolean
  players: {
    name: string
    id: string
  }[]
  seated: {
    a: EventSeat
    b: EventSeat
    c: EventSeat
  }
  extra: {
    hasErrors: boolean
    errors: {
      type: 'warning' | 'danger'
      message: string
      counter: number
      showCounter: boolean
    }[]
    playerCount: number
    filled: boolean
    empty: boolean
    partial: boolean
    hasEmptySlots: boolean
    hasEmptySeats: boolean
    emptySlots: number
    emptySeats: number
    filledAndFullySeated: boolean
    filledAndPartiallySeated: boolean
    filledWithNoneSeated: boolean
  }
  playoffMatchesPlayed: number
  playoffMatchesWon: number
}

interface EventSeat {
  filled: boolean
  name: string
  id: string
  isTemp: boolean
}

@Injectable({
  providedIn: 'root'
})
export class TournamentDataHelperService {

  // initial variables and services
  private eventDocId: string
  private destroyed$: Subject<boolean> = new Subject<boolean>()

  // private observers of the important documents
  private _event$: Observable<IEventDetails>
  private _matches$: Observable<IMatchData[]>
  private _players$: Observable<IEventPlayerDetails[]>
  private _teams$: Observable<IEventTeam[]>
  private _invitations$: Observable<IInvitedPlayer[]>

  // public data
  public event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  public eventPlayers$: BehaviorSubject<IEventPlayerDetails[]> = new BehaviorSubject<IEventPlayerDetails[]>(null)
  public eventData$: BehaviorSubject<IEventMeta> = new BehaviorSubject<IEventMeta>(null)
  public matches$: BehaviorSubject<IMatchData[]> = new BehaviorSubject<IMatchData[]>(null)
  public players$: BehaviorSubject<IEventPlayerMeta[]> = new BehaviorSubject<IEventPlayerMeta[]>(null)
  public teams$: BehaviorSubject<IEventTeamMeta[]> = new BehaviorSubject<IEventTeamMeta[]>(null)
  public invitations$: BehaviorSubject<IInvitedPlayer[]> = new BehaviorSubject<IInvitedPlayer[]>(null)
  public waitingList$: BehaviorSubject<IWaitListPlayer[]> = new BehaviorSubject<IWaitListPlayer[]>(null)

  constructor(
    private router: Router,
    private firestore: AngularFirestore,
    private auth: AuthService,
    private datePipe: DatePipe,
    private readonly countryService: CountryService,
    private readonly playerNames: PlayerNameService,
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((i: any) => {
      console.log(`[TournamentDataHelperService] --> router emitted`, i.url.split('/'))
      const urlSplit = i.url.replace(/\([^)]*\)/g, '').split('/')
      if (urlSplit[1] !== 'tournament') {
        console.log(`[TournamentDataHelperService] --> User left the event, destroy data connections`)
        this.destroy()
      }
      else if (urlSplit[1] === 'tournament' && this.eventDocId === urlSplit[2]) {
        console.log(`[TournamentDataHelperService] --> User navigated internally in the event`)
      }
      else if (urlSplit[1] === 'tournament' && this.eventDocId !== urlSplit[2]) {
        console.log(`[TournamentDataHelperService] --> User navigated to another event, get new data`)
        this.eventDocId = urlSplit[2]
        this.initObservers()
      }
    })
  }

  private initObservers(): void {

    this._event$ = this.firestore.collection('events').doc<IEventDetails>(this.eventDocId).valueChanges()
    this._matches$ = this.firestore.collection<IMatchData>('matches', ref => ref.where('eventDocId', '==', this.eventDocId)).valueChanges()
    this._players$ = this.firestore.collection('events').doc(this.eventDocId).collection<IEventPlayerDetails>('players').valueChanges()
    this._teams$ = this.firestore.collection('events').doc(this.eventDocId).collection<IEventTeam>('teams').valueChanges()
    this._invitations$ = this.firestore.collection('events').doc(this.eventDocId).collection<IInvitedPlayer>('invitations').valueChanges()

    // clear decks
    this.decks$.next([])

    combineLatest([this._event$, this._players$, this._teams$, this._matches$, this._invitations$])
      .pipe(takeUntil(this.destroyed$)).subscribe(async ([event, players, teams, matches, invitations]) => {

        console.log('[TournamentDataHelperService] --> Bulk observer emitted', {
          event,
          players,
          teams,
          matches,
          invitations,
        })

        if (event !== null && players !== null && teams !== null && matches !== null && invitations !== null) {

          const mappedMatches = matches.map(match => {
            // check if the players have been paired before in any match (match count greater than one)
            const matchesVsThisOpponent = matches.filter(m =>
              m.docId !== match.docId && m.player1.playerDocId === match.player1.playerDocId && m.player2.playerDocId === match.player2.playerDocId ||
              m.docId !== match.docId && m.player2.playerDocId === match.player1.playerDocId && m.player1.playerDocId === match.player2.playerDocId
            )
            match.hasPlayedEarlier = matchesVsThisOpponent.length > 0
            if (matchesVsThisOpponent.length > 0) {
              match.playedThisOpponentInRound = matchesVsThisOpponent.map(i => {
                return {
                  round: i.roundNumber,
                  segment: i.segmentNumber
                }
              })
            }
            return match
          })
          console.log(mappedMatches.filter(i => i.hasPlayedEarlier))

          this.event$.next(event)
          this.matches$.next(mappedMatches)
          this.eventPlayers$.next(players)
          const mappedTeams = teams.map(i => this.mapTeamData(i, players)).sort((a, b) => a.name.localeCompare(b.name))
          let extendedTeams = ModelProcess.extend.teamData(mappedTeams, mappedMatches, event)
          extendedTeams = TeamTieBreakers.compute(extendedTeams)
          extendedTeams = TeamTieBreakers.sort(extendedTeams)
          extendedTeams = TeamTieBreakers.rank(extendedTeams)

          console.log('[TournamentDataHelperService] --> Extended teams', extendedTeams)
          this.teams$.next(extendedTeams)
          await this.extendPlayerList(players, teams, mappedMatches, event)

          // combine invitations from old events and new ones (stored in different places)
          const allInvitations = [...event.invitedPlayers, ...invitations]
          const uniqueInvitations = allInvitations.reduce((acc: IInvitedPlayer[], current: IInvitedPlayer) => {
            const isDuplicate = acc.some(item => item.email === current.email)
            if (!isDuplicate) {
              acc.push(current)
            }
            return acc
          }, [])
          this.invitations$.next(uniqueInvitations)

          // get waiting list
          const waitList = event.waitingList !== undefined
            ? event.waitingList
            : []
          this.waitingList$.next(waitList)

          this.eventData$.next({
            event,
            players,
            matches: mappedMatches,
            invitations: allInvitations,
            waitList: waitList,
          })


        }

      })

    console.log(`[TournamentDataHelperService] --> initObservers`)

    this._event$.pipe(takeUntil(this.destroyed$)).subscribe(i => console.log(`[TournamentDataHelperService] --> event$ emitted`, i))
    this._matches$.pipe(takeUntil(this.destroyed$)).subscribe(i => console.log(`[TournamentDataHelperService] --> matches$ emitted`, i))
    this._players$.pipe(takeUntil(this.destroyed$)).subscribe(i => console.log(`[TournamentDataHelperService] --> players$ emitted`, i))
    this._invitations$.pipe(takeUntil(this.destroyed$)).subscribe(i => console.log(`[TournamentDataHelperService] --> invitations$ emitted`, i))

  }

  private async extendPlayerList(players: IEventPlayerDetails[], teams: IEventTeam[], matches: IMatchData[], event: IEventDetails) {

    const d = new Date()
    const start = d.getTime()
    console.log(`[TournamentDataHelperService] --> extendPlayerList --> Starting to extend player list`)

    let extendedPlayers = PlayerExtender.getList(players, matches, event)
    extendedPlayers = PlayerTieBreakers.compute(extendedPlayers)
    extendedPlayers = PlayerTieBreakers.sort(extendedPlayers)
    extendedPlayers = PlayerTieBreakers.rank(extendedPlayers, event.details.structure.isGroup)

    const playerDocIds = players.map(i => i.playerDocId)
    const playerBatches: string[][] = []

    do {

      if (playerDocIds.length > 10) {
        playerBatches.push(playerDocIds.splice(0, 10))
      }
      else {
        playerBatches.push(playerDocIds.splice(0, playerDocIds.length))
      }

    }
    while (playerDocIds.length > 0)

    let playerDocuments: IPlayerDetails[] = []
    if (playerBatches.length > 0) {
      // need to filter on non-empty arrays as firestore otherwise will fail
      for await (const batch of playerBatches.filter(i => i.length > 0)) {
        const snap = await firstValueFrom(this.firestore.collection<IPlayerDetails>('players', ref => ref.where('docId', 'in', batch)).get())
        const docs = snap.docs.map(i => i.data())
        playerDocuments = [...playerDocuments, ...docs]
      }
    }

    // get invitation list
    const invitations = this.invitations$.getValue() === null ? [] : this.invitations$.getValue()

    const playerList: IEventPlayerMeta[] = []

    for await (const player of extendedPlayers) {

      const hasPaidTicket = event.details?.registrationFee?.paidByTicket && event.details?.registrationFee?.paidByTicket[player.playerDocId]
      const hasPaidRegFee = event.details?.registrationFee?.paidBy && event.details?.registrationFee?.paidBy[player.playerDocId]

      const playerDoc = playerDocuments.find(i => i.docId === player.playerDocId)
      const playerMeta: IEventPlayerMeta = {
        playerDoc: playerDoc ? playerDoc : null,
        eventPlayer: player,
        info: {
          isInvited: invitations.find(i => i.playerDocId === player.playerDocId) !== undefined,
          isTemporary: player.name.includes('temp__'),
          hasPaid: false,
          hasRefund: false,
          hasPaidFee: hasPaidRegFee && hasPaidRegFee?.paid
            ? hasPaidRegFee.paid
            : false,
          hasRefundedFee: hasPaidRegFee && hasPaidRegFee?.refunded
            ? hasPaidRegFee.refunded
            : false,
          hasPaidTicket: hasPaidTicket
            ? hasPaidTicket?.refunded
              ? !hasPaidTicket.refunded
              : true
            : false,
          hasRefundedTicket: hasPaidTicket && hasPaidTicket?.refunded
            ? hasPaidTicket.refunded
            : false,
          hasCheckedIn: player?.hasCheckedIn
            ? player.hasCheckedIn
            : false,
          hasSubmittedDeckList: player.deckSubmission.deckList,
          hasSubmittedDeckPhoto: player.deckSubmission.deckPhoto,
          hasSubmittedDietaryInformation: player.dietaryInformation !== undefined,
          hasDietaryRestrictions: player.dietaryInformation ? player.dietaryInformation.hasRestrictions : false,
          dietaryRestrictions: player.dietaryInformation && player.dietaryInformation.hasRestrictions ? player.dietaryInformation.information : '',
          hasDropped: player.dropped,
          inTeam: player.teamId !== undefined && player.teamId !== '' && player.teamId !== null,
          inGroup: player.playedInGroupId !== undefined && player.playedInGroupId !== '' && player.playedInGroupId !== null
        },
        selected: this.players$.getValue() !== null
          ? this.players$.getValue().find(i => i.eventPlayer.playerDocId === player.playerDocId)
            ? this.players$.getValue().find(i => i.eventPlayer.playerDocId === player.playerDocId).selected
            : false
          : false,
        teamName: player.teamId !== undefined && player.teamId !== '' && player.teamId !== null
          ? teams.find(i => i.id === player.teamId) ? teams.find(i => i.id === player.teamId).name : '! team not found !'
          : '',
        teamId: player.teamId !== undefined && player.teamId !== '' && player.teamId !== null
          ? player.teamId
          : '',
        groupName: player.playedInGroup !== undefined && player.playedInGroup !== '' && player.playedInGroup !== null
          ? player.playedInGroup
          : '',
        groupId: player.playedInGroupId !== undefined && player.playedInGroupId !== '' && player.playedInGroupId !== null
          ? player.playedInGroupId
          : '',
        groupLetter: player.playedInGroupId !== undefined && player.playedInGroupId !== '' && player.playedInGroupId !== null
          ? this.groups.find(i => i.id === player.playedInGroupId)
            ? this.groups.find(i => i.id === player.playedInGroupId).letter
            : ''
          : ''
      }

      // update payment status
      playerMeta.info.hasPaid = playerMeta.info.hasPaidFee || playerMeta.info.hasPaidTicket
      playerMeta.info.hasRefund = playerMeta.info.hasRefundedFee || playerMeta.info.hasRefundedTicket

      playerList.push(playerMeta)

    }

    this.players$.next(playerList)

    const d2 = new Date()
    const end = d2.getTime()
    const secs = (end - start) / 1000
    console.log(`[TournamentDataHelperService] --> extendPlayerList --> Finished extended player list, operation took ${secs} seconds`)

  }

  public mapTeamData(team: IEventTeam, players: IEventPlayerDetails[]): IEventTeamMeta {

    let newData = team as IEventTeamMeta
    newData.selected = false
    newData.extra = {
      hasErrors: false,
      errors: [],
      playerCount: team.playerDocIds.length,
      empty: team.playerDocIds.length === 0,
      filled: team.playerDocIds.length === 3,
      partial: team.playerDocIds.length > 0 && team.playerDocIds.length < 3,
      emptySlots: 3 - team.playerDocIds.length,
      emptySeats: 3 - [team.player.a, team.player.b, team.player.c].filter(i => i !== null).length,
      hasEmptySlots: team.playerDocIds.length !== 3,
      hasEmptySeats: [team.player.a, team.player.b, team.player.c].find(i => i === null) !== undefined,
      filledAndFullySeated: team.playerDocIds.length === 3 && ![team.player.a, team.player.b, team.player.c].includes(null),
      filledAndPartiallySeated: team.playerDocIds.length === 3 && [team.player.a, team.player.b, team.player.c].filter(i => i === null).length > 0 && [team.player.a, team.player.b, team.player.c].filter(i => i !== null).length < 3,
      filledWithNoneSeated: team.playerDocIds.length === 3 && [team.player.a, team.player.b, team.player.c].filter(i => i === null).length === 3,
    }

    // append error messages
    if (newData.extra.empty) {
      newData.extra.errors.push({
        type: 'warning',
        message: 'No player assigned to the player slots',
        counter: 0,
        showCounter: false,
      })
    }
    if (newData.extra.emptySlots) {
      newData.extra.errors.push({
        type: 'warning',
        message: 'Empty player slots found',
        counter: newData.extra.emptySlots,
        showCounter: true,
      })
    }
    if (newData.extra.emptySeats) {
      newData.extra.errors.push({
        type: 'warning',
        message: 'Empty player seats found',
        counter: newData.extra.emptySeats,
        showCounter: true,
      })
    }
    newData.extra.hasErrors = newData.extra.errors.length > 0

    // add seated information
    newData.seated = {
      a: {
        filled: team.player.a !== null,
        name: team.player.a !== null
          ? players.find(i => i.playerDocId === team.player.a)
            ? players.find(i => i.playerDocId === team.player.a).name
            : ''
          : '',
        id: team.player.a,
        isTemp: team.player.a !== null ? team.player.a.includes('temp__') : false,
      },
      b: {
        filled: team.player.b !== null,
        name: team.player.b !== null
          ? players.find(i => i.playerDocId === team.player.b)
            ? players.find(i => i.playerDocId === team.player.b).name
            : ''
          : '',
        id: team.player.a,
        isTemp: team.player.a !== null ? team.player.a.includes('temp__') : false,
      },
      c: {
        filled: team.player.c !== null,
        name: team.player.c !== null
          ? players.find(i => i.playerDocId === team.player.c)
            ? players.find(i => i.playerDocId === team.player.c).name
            : ''
          : '',
        id: team.player.a,
        isTemp: team.player.a !== null ? team.player.a.includes('temp__') : false,
      }
    }

    // add player information
    newData.players = team.playerDocIds.map(id => {
      return {
        name: players.find(i => i.playerDocId === id)
          ? players.find(i => i.playerDocId === id).name
          : '',
        id: id,
      }
    })

    return newData

  }

  /**
   * Returns the current tournament document id
   */
  public get id(): string {
    return this.eventDocId
  }

  public get event(): Observable<IEventDetails> {
    return this.event$
  }

  public get players(): Observable<IEventPlayerDetails[]> {
    return this._players$
  }

  public get currentPlayer(): IEventPlayerMeta {
    if (this.players$.getValue() === null) {
      return null
    }

    return this.players$.getValue().find(i => i.eventPlayer.playerUid === this.auth.user.uid)

  }

  public get matches(): Observable<IMatchData[]> {
    return this._matches$
  }

  public get playerCount(): number {
    return this.eventData$.getValue() === null
      ? 0
      : this.eventData$.getValue().event.playerDocIds.length
  }

  public get isAdmin(): boolean {
    return this.eventData$.getValue() === null
      ? false
      : this.auth.user.role === 'admin'
  }

  public get isOrganizer(): boolean {
    return this.eventData$.getValue() === null
      ? false
      : this.eventData$.getValue().event.createdByUid === this.auth.user.uid
  }

  public get createdByUid(): string {
    return this.eventData$.getValue() === null
      ? ''
      : this.eventData$.getValue().event.createdByUid
  }

  public get isCoOrganizer(): boolean {
    return this.eventData$.getValue() === null
      ? false
      : this.eventData$.getValue().event.coOrganizers.includes(this.auth.user.uid) || this.eventData$.getValue().event.coOrganizers.includes(this.auth.user.playerId)
  }

  public get isAttending(): boolean {
    return this.eventData$.getValue() === null
      ? true
      : this.eventData$.getValue().event.playerDocIds.includes(this.auth.user.playerId)
  }

  public get playsInGroupLetter(): string | null {
    if (this.isAttending) {
      const playerMeta = this.players$.getValue().find(i => i.eventPlayer.playerDocId === this.auth.user.playerId)
      return playerMeta.eventPlayer.playedInGroup.replace('GROUP ', '').toLowerCase()
    }
    return null
  }

  public get name(): string {
    return this.eventData$.getValue() === null
      ? ''
      : this.eventData$.getValue().event.details.name
  }

  public get playerList(): IEventPlayerDetails[] {
    return this.eventData$.getValue() === null
      ? []
      : PlayerExtender.getList(this.eventData$.getValue().players, this.eventData$.getValue().matches, this.eventData$.getValue().event)
  }

  public get hasMandatoryDeckList(): boolean {
    if (this.event$.getValue() === null) {
      return false
    }
    return this.event$.getValue().details.deckList
  }

  public get hasMandatoryDeckPhoto(): boolean {
    if (this.event$.getValue() === null) {
      return false
    }
    return this.event$.getValue().details.deckPhoto
  }

  public get hasRegistrationFee(): boolean {
    if (this.event$.getValue() === null) {
      return false
    }

    const event = this.event$.getValue()

    if (event.details.registrationFee) {
      if (event.details.registrationFee.active) {
        return true
      }
    }
    else {
      return false
    }
  }

  public get hasCharityExtra(): boolean {
    if (!this.hasRegistrationFee) {
      return false
    }

    const event = this.event$.getValue()
    if (event.details.registrationFee.charityExtra) {
      return true
    }

    return false
    
  }

  public get hasTolariaPayment(): boolean {
    if (!this.hasRegistrationFee) {
      return false
    }

    const event = this.event$.getValue()
    if (event.details.registrationFee.tolariaPayment && (event.details.registrationFee.forcePaymentWhenAttending ? event.details.registrationFee.forcePaymentWhenAttending : false)) {
      return true
    }

    return false
    
  }

  public get registrationFee(): string {
    if (this.event$.getValue() === null) {
      return ''
    }

    const event = this.event$.getValue()

    if (event.details.registrationFee) {
      if (event.details.registrationFee.active) {
        return `${event.details.registrationFee.amount} ${event.details.registrationFee.currency.toUpperCase()}`
      }
    }

    return 'All free! 🎉'

  }

  public get canAttend(): boolean {
    const event = this.event$.getValue()
    if (event === null) {
      return false
    }

    if (event.statusCode > 0) {
      return false
    }

    if (event.playerDocIds.includes(this.auth.user.playerId)) {
      return false
    }

    let invited = true
    if (!event.details.isPublic) {
      const invitations = this.invitations$.getValue() === null ? [] : this.invitations$.getValue()
      invited = invitations.find(i => i.playerDocId === this.auth.user.playerId) !== undefined
    }

    const capNotReached = this.event$.getValue().details.hasAttendeeCap
      ? !this.playerCapReached
      : true

    return capNotReached && invited

  }

  public get registrationOpen(): boolean {
    const event = this.event$.getValue()
    const now = new Date()
    if (event.details.registrationOpensTimestamp > now.getTime() || event.details.registrationClosesTimestamp < now.getTime()) {
      return false
    }

    return true

  }

  public get onWaitList(): boolean {
    if (this.event$.getValue() === null) {
      return false
    }
    return this.event$.getValue().waitingList
      ? this.event$.getValue().waitingList.find(i => i.playerDocId === this.playerNames.currentPlayersMini.id) !== undefined
      : false
  }

  public get structure(): string {

    if (this.event$.getValue() === null) {
      return ''
    }

    if (this.event$.getValue().details.structure.isSwiss) {
      return 'Swiss'
    }
    if (this.event$.getValue().details.structure.isGroup) {
      return 'Group'
    }
    if (this.event$.getValue().details.structure.isBatch) {
      return 'Batch'
    }
    if (this.event$.getValue().details.structure.isRoundRobin) {
      return 'Round Robin'
    }
    if (this.event$.getValue().details.structure.isBracket) {
      return 'Bracket'
    }

  }

  public get format(): string {
    return this.event$.getValue() === null
      ? ''
      : this.event$.getValue().details.format
  }

  public get ruleset(): string {
    return this.event$.getValue() === null
      ? ''
      : this.event$.getValue().details.ruleset.name
  }

  public get reprintPolicy(): string {
    return this.event$.getValue() === null
      ? ''
      : this.event$.getValue().details.reprintPolicy.name
  }

  public get capInfo(): string {
    return this.event$.getValue() === null
      ? ''
      : `${this.event$.getValue().playerDocIds.length} / ${this.event$.getValue().details.hasAttendeeCap ? this.event$.getValue().details.attendeeCap : '∞'}`
  }

  public get eventType(): 'swiss' | 'batch' | 'group' | 'bracket' | 'round-robin' | null {
    if (this.event$.getValue() !== null) {
      const structure = this.event$.getValue().details.structure
      if (structure.isSwiss) { return 'swiss' }
      if (structure.isBatch) { return 'batch' }
      if (structure.isGroup) { return 'group' }
      if (structure.isRoundRobin) { return 'round-robin' }
      if (structure.isBracket) { return 'bracket' }
    }
    return null
  }

  public get isOnlineTournament(): boolean {
    return this.event$.getValue() === null
      ? false
      : this.event$.getValue().details.isOnlineTournament
  }

  public get showStandings(): boolean {

    if (this.event$.getValue() === null) {
      return false
    }

    if (this.event$.getValue().statusCode === 0) {
      return false
    }

    if (this.isAdmin || this.isOrganizer || this.isCoOrganizer) {
      return true
    }

    const structure = this.event$.getValue().details.structure
    const event = this.event$.getValue()

    switch (this.eventType) {
      case 'swiss':
        // check if it's the next last round and that all matches has been ¸ed OR if it's the last round
        if (event.activeRound === structure.swiss.roundsToPlay - 1 && event.statusCode === 4 || event.activeRound === structure.swiss.roundsToPlay) {
          // make sure the standings should not be hidden
          if (structure.swiss.hideStandingsUntilPosted) {
            return structure.swiss.standingsPosted
          }
          return true
        }
        return false

      case 'group':
      case 'batch':
      case 'round-robin':
        return true

      case 'bracket':
        return false

    }
  }

  public get statusTitle(): string {
    if (this.event$.getValue() === null) {
      return ''
    }

    const event = this.event$.getValue()

    // event has ended
    if (event.statusCode === 8 || event.statusCode === 0) {
      return ''
    }

    // event in playoffs
    if (event.statusCode === 6 || event.statusCode === 7) {
      return 'Playoffs'
    }

    // check event type and return accordingly
    switch (this.eventType) {
      case 'batch':
        return `Batch ${event.activeRound}`
      case 'group':
        return `Group Stage`
      case 'bracket':
        return 'Bracket Stage'
      case 'round-robin':
        return 'Round Stage'
      case 'swiss':
        return `Round ${this.event$.getValue().activeRound}`
    }
  }

  public get statusText(): string {
    if (this.event$.getValue() === null) {
      return ''
    }
    return this.event$.getValue().statusText
  }

  public get statusCode(): number {
    if (this.event$.getValue() === null) {
      return 0
    }
    return this.event$.getValue().statusCode
  }

  public get playerRegistrationIsOpen(): boolean {
    if (this.event$.getValue() === null) {
      return false
    }
    const now = new Date()
    return this.event$.getValue().statusCode === 0
      ? this.event$.getValue().details.registrationOpensTimestamp < now.getTime()
      : false
  }

  public get hasPlayoffs(): boolean {
    if (this.event$.getValue() === null) {
      return false
    }
    switch (this.eventType) {
      case 'batch':
        return this.event$.getValue().details.structure.batch.hasBracketAfterBatch
      case 'swiss':
        return this.event$.getValue().details.structure.swiss.hasBracketAfterSwiss
      case 'group':
        return this.event$.getValue().details.structure.group.hasBracketAfterGroupStage
      default:
        return false
    }
  }

  public get docId(): string {
    if (this.event$.getValue() === null) {
      return ''
    }
    return this.event$.getValue().docId
  }

  public get activeRound(): number {
    if (this.event$.getValue() === null) { return 0 }
    return this.event$.getValue().activeRound
  }

  public get pairingsType(): string {
    switch (this.eventType) {
      case 'batch':
        return 'batch'
      case 'group':
        return 'group'
      case 'round-robin':
      case 'swiss':
      case 'bracket':
        return 'round'
    }
  }

  public get numberOfBatches(): number {
    if (this.event$.getValue() === null) { return 0 }
    return this.event$.getValue().details.structure.batch.batches.length
  }

  public get numberOfSwissRounds(): number {
    if (this.event$.getValue() === null) { return 0 }
    return this.event$.getValue().details.structure.swiss.roundsToPlay
  }

  public get allMatchesForCurrentRoundReported(): boolean {
    if (this.event$.getValue() === null) { return false }
    if (this.statusCode === 7) {
      const matches = this.matches$.getValue().filter(i => i.isType === 'bracket')
      return matches.filter(i => !i.isReported).length === 0
    }
    const matches = this.matches$.getValue().filter(i => i.roundNumber === this.activeRound)
    return matches.filter(i => !i.isReported && !i.deleted).length === 0
  }

  public get allBracketMatchesReported(): boolean {
    if (this.event$.getValue() === null) { return false }
    const matches = this.matches$.getValue().filter(i => i.isType === 'bracket' || i.isType === 'double-bracket')
    return matches.filter(i => !i.isReported).length === 0
  }

  public get hasBracketMatches(): boolean {
    if (this.event$.getValue() === null) { return true }
    const matches = this.matches$.getValue().filter(i => i.isType === 'bracket' || i.isType === 'double-bracket')
    return matches.filter(i => !i.isReported).length > 0
  }

  public get calendarButtonReady(): boolean {
    return this.event$.getValue() !== null &&
      this.startDate !== '' &&
      this.startTime !== '' &&
      this.endDate !== '' &&
      this.endTime !== '' &&
      this.timeZone !== ''
  }

  public get startDate(): string {
    if (this.event$.getValue() === null) { return '' }
    const date = new Date(this.event$.getValue().details.datestampFrom)
    const dateString = this.datePipe.transform(date, 'YYYY-MM-dd', this.event$.getValue().details.GMT_offset)
    return dateString
  }

  public get startTime(): string {
    if (this.event$.getValue() === null) { return '' }
    const date = new Date(this.event$.getValue().details.datestampFrom)
    const dateString = this.datePipe.transform(date, 'HH:mm', this.event$.getValue().details.GMT_offset)
    return dateString
  }

  public get endDate(): string {
    if (this.event$.getValue() === null) { return '' }
    const date = this.event$.getValue().details.datestampTo !== null
      ? new Date(this.event$.getValue().details.datestampTo)
      : new Date(this.event$.getValue().details.datestampFrom + (4 * 3600000)) // add 4 hours to the time
    const dateString = this.datePipe.transform(date, 'YYYY-MM-dd', this.event$.getValue().details.GMT_offset)
    return dateString
  }

  public get endTime(): string {
    if (this.event$.getValue() === null) { return '' }
    const date = this.event$.getValue().details.datestampTo !== null
      ? new Date(this.event$.getValue().details.datestampTo)
      : new Date(this.event$.getValue().details.datestampFrom + (4 * 3600000)) // add 4 hours to the time
    const dateString = this.datePipe.transform(date, 'HH:mm', this.event$.getValue().details.GMT_offset)
    return dateString
  }

  public get timeZone(): string {
    if (this.event$.getValue() === null) { return '' }
    const olson = this.countryService.getFirstTimeOlsonZoneByOffset(this.event$.getValue().details.GMT_offset)
    if (olson !== null) {
      return olson
    }
    return ''
  }

  public get groupsCreated(): boolean {
    if (this.event$.getValue() === null) { return false }
    return false
  }

  public get groupSettings(): IGroupSettings {
    if (this.event$.getValue() === null) { return null }
    return this.event$.getValue().details.structure.group
  }

  public get groups(): ITournamentGroup[] {
    if (this.event$.getValue() === null) { return [] }
    const groupStructure = this.event$.getValue().details.structure.group
    let groups: ITournamentGroup[] = []
    if (groupStructure.groups) {
      groups = groupStructure.groups.map(group => {
        group.playerDocs = group.playerDocIds.map(playerDocId => {
          const players = this.players$.getValue() === null ? [] : this.players$.getValue()
          let player = players.find(i => i.eventPlayer.playerDocId === playerDocId)
          if (player) {
            return player.eventPlayer
          }
          return null
        })
        return group
      })
    }
    else {
      groups = [...new Set(this.players$.getValue().map(p => p.eventPlayer.playedInGroup))].map(group => {
        const g: ITournamentGroup = {
          name: group,
          letter: group.replace('GROUP ', ''),
          id: group,
          description: '',
          playerDocIds: this.players$.getValue().map(p => p.eventPlayer).filter(i => i.playedInGroup === group).map(d => d.playerDocId),
          playerDocs: this.players$.getValue().map(p => p.eventPlayer).filter(i => i.playedInGroup === group),
        }
        return g
      })
    }

    return groups
  }

  public get isSingleElimination(): boolean {
    return this.event$.getValue() === null
      ? false
      : this.eventType === 'bracket'
        ? this.event$.getValue().details.structure.bracket.singleElimination
        : true
  }

  public get isDoubleElimination(): boolean {
    return this.event$.getValue() === null
      ? false
      : this.eventType === 'bracket'
        ? this.event$.getValue().details.structure.bracket.doubleElimination
        : false
  }

  public get playerCap(): number {
    return this.event$.getValue() === null
      ? 0
      : this.event$.getValue().details.attendeeCap
  }

  public get hasAttendeeCap(): boolean {
    return this.event$.getValue() === null
      ? false
      : this.event$.getValue().details.hasAttendeeCap
  }

  public get playerCapReached(): boolean {
    return this.event$.getValue() === null
      ? true
      : this.event$.getValue().details.hasAttendeeCap
        ? this.playerCount >= this.event$.getValue().details.attendeeCap
        : false
  }

  public get waitingListCount(): number {
    return this.event$.getValue() === null
      ? 0
      : this.event$.getValue().waitingList
        ? this.event$.getValue().waitingList.length
        : 0
  }

  public get bannerUrl(): string {
    return this.event$.getValue() === null
      ? ''
      : this.event$.getValue().bannerUrl !== undefined && this.event$.getValue().bannerUrl !== ''
        ? this.event$.getValue().bannerUrl
        : 'assets/banners/' + this.format.toLowerCase().replace(/ /g, '-') + '.default.jpg'
  }


  public destroy(): void {
    this.destroyed$.next(true)
    console.log(`[TournamentDataHelperService] --> destroying helper`)
  }

  public get coOrganizers(): IPlayerMini[] {
    return this.event$.getValue() === null
      ? []
      : this.event$.getValue().coOrganizers.map(i => this.playerNames.getPlayerByUid(i))
  }

  public get isTeamTournament(): boolean {
    return this.event$.getValue() === null
      ? false
      : this.eventType === 'swiss'
        ? this.event$.getValue().details.structure.swiss.teams
        : false
  }

  public get teamCount(): number {
    return this.teams$.getValue() === null
      ? 0
      : this.teams$.getValue().length
  }

  public get teamsData() {
    const teams = this.teams$.getValue()
    const players = this.players$.getValue()
    const enrolled = teams === null ? 0 : teams.length
    const filled = teams === null ? 0 : teams.filter(i => i.extra.filled).length
    const semiFilled = teams === null ? 0 : teams.filter(i => i.playerDocIds.length > 0 && !i.extra.filled).length
    const empty = teams === null ? 0 : teams.filter(i => i.playerDocIds.length === 0).length
    const filledAndFullySeated = teams === null ? 0 : teams.filter(i => i.extra.filledAndFullySeated).length
    const filledAndPartiallySeated = teams === null ? 0 : teams.filter(i => i.extra.filledAndPartiallySeated).length
    const filledNoneSeated = teams === null ? 0 : teams.filter(i => i.extra.filledWithNoneSeated).length
    const data = {
      hasErrors: teams.find(i => i.extra.hasErrors) !== undefined,
      enrolled,
      filled,
      semiFilled,
      empty,
      filledAndFullySeated,
      filledAndPartiallySeated,
      filledNoneSeated,
      canStartTournament: filledAndFullySeated > 4,
      playersOutsideOfTeams: players.filter(i => !i.info.inTeam).length,
      playersNotSeated: players.filter(i => i.eventPlayer.teamSeat === undefined || i.eventPlayer.teamSeat === null).length,
    }
    return data
  }

  public get manualPairingsPossible(): boolean {
    if (this.event$.getValue() === null) { return false }
    const matches = this.matches$.getValue().filter(i => i.roundNumber === this.activeRound)
    return matches.filter(i => i.isReported && !i.deleted && !i.isByeMatch && !i.isLossMatch).length === 0
  }

  public get deckInfoIsPublic(): boolean {
    return this.event$.getValue() === null
      ? false
      : this.event$.getValue().details.deckInfoIsPublic
  }

  public decks$ = new BehaviorSubject<DeckLibraryDoc[]>([])
  public async getPublishedDecks(): Promise<BehaviorSubject<DeckLibraryDoc[]>> {
        
    if (this.decks$.getValue().length === 0 && this.statusCode === 8 && this.deckInfoIsPublic) {

      console.log(`[TournamentDataHelperService] --> getting published decks`)
      const snap = await firstValueFrom(this.firestore.collection<DeckLibraryDoc>('published-decks', ref => ref.where('tournamentId', '==', this.docId)).get())

      console.log(`[TournamentDataHelperService] --> snap returned ${snap.docs.length} documents`)

      const docs = snap.docs.map(i => i.data())

      this.decks$.next(docs.sort((a, b) => a.rank - b.rank))

    }

    return this.decks$
  }

  public get manualGroupPairingEnabled(): boolean {
    const matches = this.matches$.getValue() === null ? [] : this.matches$.getValue()
    const players = this.players$.getValue() === null ? [] : this.players$.getValue()
    const playerMatches = players.filter(i => i.eventPlayer.dropped === false).map(player => {
      const pMatches = matches.filter(i => i.playerDocIds.includes(player.eventPlayer.playerDocId))
      return {
        playerDocId: player.eventPlayer.playerDocId,
        playerName: player.eventPlayer.name,
        matches: pMatches,
      }
    })
    const matchesWithMultiPairing = matches.filter(i => i.hasPlayedEarlier)
    return playerMatches.filter(i => i.matches.length === 0).length > 0 || matchesWithMultiPairing.length > 0
  }

  public get providesFood(): boolean {
    return this.event$.getValue() === null
      ? false
      : this.event$.getValue().details.provideFood
  }


  public getActiveBatch(): IBatchConfig {
    const event = this.event$.getValue()
    const batches = event.details.structure.batch.batches
    const activeBatch = batches.find(i => i.roundNumber === this.activeRound)
    return activeBatch
  }

}


import { PresenceService } from './presence.service';
import { IUser, IUserRegistration, IUserLogin, IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces'; // optional
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalsService } from 'src/app/services';
import * as auth from 'firebase/auth'
import * as firestore from 'firebase/firestore'
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import { Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'node_modules/uuid';
@Injectable({ providedIn: 'root' })
export class AuthService {

  isLoggedIn$: Subject<boolean> = new Subject<boolean>();
  isLoggedOut$: Subject<boolean> = new Subject<boolean>();

  userUid: string;
  userEmail: string;
  userTheme: string;
  userIsPlayer: boolean;
  userRole: string;
  user$: Observable<IUser>;
  user: IUser;
  authError = '';

  public showFullSize = false;
  public isRegistering = false;
  public registrationData: IUserRegistration = {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    nickname: ''
  };
  public loginData: IUserLogin = {
    email: '',
    password: ''
  };

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private globals: GlobalsService,
    private presence: PresenceService,
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          this.userUid = user.uid;
          this.userEmail = user.email;
          // Fetch the user document
          return this.afs.doc<IUser>(`users/${user.uid}`).valueChanges();
        }
        else {
          this.isLoggedOut$.next(true);
          return of(null);
        }
      })
    );

    this.user$
      .subscribe((usr: IUser) => {
        // this.userTheme = usr.theme;
        console.log('[AuthService] --> user document: ', usr);
        if (usr) {
          this.user = usr;
          this.userIsPlayer = usr.isRegisteredAsPlayer;
          this.userRole = usr.role;
          if (usr.settings.darkMode) {
            document.documentElement.setAttribute('data-theme', 'dark');
            document.documentElement.setAttribute('data-bs-theme', 'dark');
          }
          else {
            document.documentElement.setAttribute('data-theme', 'light');
            document.documentElement.setAttribute('data-bs-theme', 'light');
          }
          if (usr.settings.textSize) {
            document.documentElement.setAttribute('data-text-size', usr.settings.textSize);
          }
          else {
            document.documentElement.setAttribute('data-text-size', usr.settings.textSize);
          }

          this.updateTimeZoneOffset(usr);

          // console.log('logged in and user info loaded, switching the observable to true');
          // we have the user object, now lets tell everything we have logged in properly
          this.isLoggedIn$.next(true);

        }
        else {
          this.isLoggedIn$.next(false);
        }
      });

    // this.isLoggedIn$.pipe(
    //   filter((loggedIn) => loggedIn === true),
    //   withLatestFrom(this.user$),
    //   take(1)).subscribe(([isLoggedIn, user]) => {
    //     this.updateTimeZoneOffset(user);
    //   });
  }

  public async getAuthProvider(): Promise<string> {
    const user = await this.afAuth.currentUser
    let providerId = ''
    user.providerData.forEach(i => {
      providerId = i.providerId
    })
    return providerId
  }

  public updateEmailAddress(newEmail: string): Promise<boolean> {

    return new Promise(async (resolve) => {
      const user = await this.afAuth.currentUser

      const emailUpdated = await user.updateEmail(newEmail)
        .then(() => { return true })
        .catch((error) => {
          console.error('[AuthService] --> ', error)
          return false
        })

      if (!emailUpdated) { return }


      // update player document
      this.afs.collection('players').doc(this.user.playerId)
        .update({ email: newEmail })
        .then(() => {
          console.log('[AuthService] --> successfully updated the email address')
          resolve(true)
        })
        .catch((error) => {
          resolve(false)
          console.error('[AuthService] --> ', error)
        })

    })


  }

  updateTimeZoneOffset(user: IUser) {

    const offset = this.formatUTCOffset(new Date().getTimezoneOffset());
    const timezoneName = this.getTimezoneName();
    this.afs.collection('players').doc(user.playerId)
    .update({
      deviceUtcOffset: offset,
      deviceUtcOffsetUpdated: firestore.Timestamp.now().seconds,
      deviceUtcTimezoneName: timezoneName,
    })
    .then(() => {
      // console.log('successfully updated your local UTC offset');
    })
    .catch((err) => {
      console.log(err);
    });
  }

  formatUTCOffset(offset: number) {
    const negative = (- (offset / 60)) < 0;
    const formattedOffset = Math.abs(- (offset / 60)).toFixed(2).length === 5 ? Math.abs(- (offset / 60)).toFixed(2) : '0' + Math.abs(- (offset / 60)).toFixed(2).replace('.', ':');
    return negative ? '-' + formattedOffset : '+' + formattedOffset;
  }

  getTimezoneName() {
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

    // Trying to remove date from the string in a locale-agnostic way
    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

      // by this time `trimmed` should be the timezone's name with some punctuation -
      // trim it from both sides
      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    }
  }


  async sendPasswordResetEmail(email: string): Promise<IPromiseResponse> {
    return new Promise((resolve, reject) => {
      this.afAuth.sendPasswordResetEmail(email)
        .then(() => {
          resolve({
            status: true,
            text: 'Successfully sent password reset email to ' + email
          });
        })
        .catch((error) => {
          console.error(error);
          resolve({
            status: false,
            text: error.message
          });
        });
    });
  }
  async createPlayerRegistrationForm() {
    this.globals.playerRegistrationForm = {
      attendingEvents: [],
      country: {
        name: null,
        code: null,
        callingCode: null,
        region: null,
        subRegion: null
      },
      createdByUid: this.userUid,
      docId: this.user.playerId,
      email: this.userEmail,
      name: {
        first: '',
        last: '',
        nick: '',
      },
      phone: null,
      timeZone: {
        countryCode: null,
        countryName: null,
        olson: null,
        UTC: null,
        DST: null,
        zoneCode: null,
        zoneName: null
      },
      type: 'global',
      uid: this.userUid,
      userWithThisPlayerStored: [],
      registered: false,
      notifications: {
        email: {
          eventReminders: true,
          matchAppointmentReminders: true,
          matchAppointments: true,
          newsLetter: false,
          developmentNews: false,
        }
      }
    };
  }
  async googleSignin() {
    return new Promise((resolve, reject) => {
      this.authError = '';
      const provider = new auth.GoogleAuthProvider();
      this.afAuth.signInWithPopup(provider)
        .then((credential) => {
          this.updateUserData(credential.user);
          resolve({
            status: true,
            text: 'Successfuly logged in'
          });
        })
        .catch((error) => {
          this.authError = error.message;
          resolve({
            status: false,
            text: error.message
          });
        });
    });
  }
  async githubSignin() {
    return new Promise((resolve, reject) => {
      this.authError = '';
      const provider = new auth.GithubAuthProvider();
      this.afAuth.signInWithPopup(provider)
        .then((credential) => {
          this.updateUserData(credential.user);
          resolve({
            status: true,
            text: 'Successfuly logged in'
          });
        })
        .catch((error) => {
          this.authError = error.message;
          resolve({
            status: false,
            text: error.message
          });
        });
    });
  }
  async facebookSignin() {
    return new Promise((resolve, reject) => {
      this.authError = '';
      const provider = new auth.FacebookAuthProvider();
      this.afAuth.signInWithPopup(provider)
        .then((credential) => {
          this.updateUserData(credential.user);
          resolve({
            status: true,
            text: 'Successfuly logged in'
          });
        })
        .catch((error) => {
          this.authError = error.message;
          resolve({
            status: false,
            text: error.message
          });
        });
    });
  }
  async twitterSignin() {
    return new Promise((resolve, reject) => {
      this.authError = '';
      const provider = new auth.TwitterAuthProvider();
      this.afAuth.signInWithPopup(provider)
        .then((credential) => {
          this.updateUserData(credential.user);
          resolve({
            status: true,
            text: 'Successfuly logged in'
          });
        })
        .catch((error) => {
          this.authError = error.message;
          resolve({
            status: false,
            text: error.message
          });
        });
    });
  }
  async emailSignin(loginData: IUserLogin) {
    return new Promise((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(loginData.email, loginData.password)
        .then((credentials) => {
          this.updateUserData(credentials.user, true);
          resolve({
            status: true,
            text: 'Successfuly logged in'
          });
        })
        .catch((error) => {
          this.authError = error.message;
          resolve({
            status: false,
            text: error.message
          });
        });
    });
  }
  async emailSignup(registrationData: IUserRegistration) {
    return new Promise((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(registrationData.email, registrationData.password)
        .then((credential) => {
          this.updateUserData(credential.user);
          resolve({
            status: true,
            text: 'Successfully created account'
          });
        })
        .catch((error) => {
          console.log(error);
          this.authError = error.message;
          resolve({
            status: false,
            text: error.message
          });
        });
    });
  }
  private async updateUserData({ uid, email }: IUser, emailSignIn: boolean = false) {
    console.log('user data', { uid, email });
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument = this.afs.collection('users').doc(uid);
    const data: IUser = {
      uid,
      email
    };
    // console.log('userRef', userRef.set(data, { merge: true }));
    const userDocExist = await this.docExists('users/' + uid);
    if (userDocExist) {
      console.log('Document exists');
      if (!emailSignIn) { return userRef.set(data, { merge: true }); }
      // data.displayName = email;
      return userRef.update(data);
    }
    if (!userDocExist) {
      console.log('Document DOES NOT exist');
      // data.displayName = email;
      const newDoc: IUser = {
        uid,
        email,
        role: 'user',
        avatar: 'assets/avatars/default.jpg',
        settings: {
          darkMode: true,
          clock24: true,
          textSize: 'normal'
        },
        isRegisteredAsPlayer: false,
        playerId: uuidv4()
      };
      return userRef.set(newDoc, { merge: true });
    }
  }
  async signOut() {
    await this.presence.setPresence('offline');
    await this.afAuth.signOut();
    this.isLoggedIn$.next(false);
    this.isLoggedOut$.next(true);
    this.router.navigate(['/']);
  }
  private docExists(path: string) {
    return new Promise((resolve, reject) => {
      console.log('Checking if a document exists (' + path + ')');
      const docRef = this.afs.doc(path).get();
      docRef.subscribe(doc => {
        if (doc.exists) {
          console.log('...doc exists, returning');
          resolve(true);
        }
        else {
          console.log('...doc DOES NOT exists, returning');
          resolve(false);
        }
      });
    });
  }
  public updateUserAvatar(avatar: string) {
    this.afs.collection('users').doc(this.user.uid).update({ avatar });
    this.afs.collection('players').doc(this.user.playerId).update({ avatar });
  }
  public registrationFormIsValid(registrationData: IUserRegistration) {
    if (
      registrationData.email !== '' &&
      registrationData.password !== '' &&
      registrationData.confirmPassword !== '' &&
      registrationData.password === registrationData.confirmPassword
    ) {
      return true;
    } else {
      return false;
    }
  }

}

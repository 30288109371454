import Quill from 'quill'
import { EmojiItem } from '../../../tolaria-emoji-picker/emojis'

const Parchment: any = Quill.import('parchment')

export class TolariaEmojiBlot extends Parchment.Embed {

  static blotName = 'tolaria-emoji'
  static tagName = 'img'
  static className = 'ql-emoji'

  static create(data: EmojiItem) {

    const node = super.create() as HTMLElement

    // get all emojis from local storage (the emoji service should have stored them there, initialized in the app.component)
    const storedData = localStorage.getItem('tolaria-emojis')

    // convert the string value to emoji items array
    const emojis: EmojiItem[] = storedData ? JSON.parse(storedData) : []

    // find the correct emoji
    let emoji = emojis.find(i => i.shortcode === data.shortcode)

    // if found, add attributes to the node element
    if (emoji) {
      node.setAttribute('shortcode', String(data.shortcode))
      node.setAttribute('src', emoji.image)
      node.setAttribute('alt', emoji.shortcode)
      node.setAttribute('aria-label', emoji.name.replace(/_/g, ' ') + ' emoji')
    }

    // add additional style classes
    node.classList.add('ql-tolaria-emoji')

    // return node element
    return node
  }

  static value(node: HTMLElement) {
    return {
      shortcode: node.getAttribute('shortcode') || '',
    }
  }

}

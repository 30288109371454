import { EventService, IEventLinkMeta } from 'src/app/services/event/event.service';
import { ILeagueManualPoint } from 'tolaria-cloud-functions/src/_interfaces';
import { LeagueConfigComponent } from './../league-config/league-config.component';
import { faExternalLinkAlt, faChevronRight, faEdit, faPlus, faTrash, faLink } from '@fortawesome/free-solid-svg-icons';
import { pricePodiumIcon } from 'src/assets/font-icons/price-podium-icon';
import { Observable } from 'rxjs';
import { Component, ElementRef, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ILeagueData, LeaguesService } from 'src/app/services/leagues.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';

@Pipe({
  name: 'eventLinkFilter'
})
export class EventLinkPipe implements PipeTransform {
  transform(items: IEventLinkMeta[], filterValue: string): IEventLinkMeta[] {
    if (filterValue.length > 3) {
      return items.filter(e => e.eventName.toLowerCase().includes(filterValue.toLowerCase()));
    }
    else {
      return [];
    }
  }
}
@Component({
  selector: 'app-league-details',
  templateUrl: './league-details.component.html',
  styleUrls: ['./league-details.component.css']
})
export class LeagueDetailsComponent implements OnInit {
  @ViewChild('addManualPointModal', { static: false }) addManualPointModal: ElementRef;
  @ViewChild('linkEventModal', { static: false }) linkEventModal: ElementRef;

  private leagueDocId: string;
  public league$: Observable<ILeagueData>;
  public translations = this.leagueService.translations;
  public newManualPoint: ILeagueManualPoint;
  public deleteLeagueConfirmation: string = '';
  public searchEvent: string = '';
  public linkEvent: {
    eventDocId: null,
    eventName: null
  }
  public eventNames: IEventLinkMeta[] = [];
  public managingLeague: boolean = false;

  public icons = {
    faPodium: pricePodiumIcon,
    faExternalLinkAlt: faExternalLinkAlt,
    faChevronRight: faChevronRight,
    faEdit: faEdit,
    faPlus: faPlus,
    faTrash: faTrash,
    faLink: faLink,
  }

  constructor(
    private leagueService: LeaguesService,
    private router: Router,
    private modalService: NgbModal,
    private eventService: EventService,
  ) {
  }

  ngOnInit(): void {
    this.leagueDocId = this.router.url.split('/')[2];

    this.league$ = this.leagueService.getLeagueByDocId(this.leagueDocId);
  }

  public openConfiguration(leagueData: ILeagueData): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    };

    const modalRef: NgbModalRef = this.modalService.open(LeagueConfigComponent, modalOptions);
    modalRef.componentInstance.leagueData = JSON.parse(JSON.stringify(leagueData));
    modalRef.dismissed.pipe(take(1)).subscribe((data) => {
      this.leagueService.saveLeague(data);
    });
  }

  public addManualPointToPlayer(leagueData: ILeagueData): void {
    // clear form
    this.newManualPoint = {
      comment: null,
      playerDocId: null,
      points: null
    };

    const content = this.addManualPointModal;
    this.modalService
      .open(content, { centered: true, keyboard: false }).result
      .then(() => {
        // cancelled
      }, (reason) => {
        if (reason === 'save') {
          leagueData.manualPoints.push(this.newManualPoint);
          this.leagueService.saveLeague(leagueData);
        }
      });
  }

  public removeManualPoint(league: ILeagueData, i: number): void {
    league.manualPoints.splice(i, 1);
    this.leagueService.saveLeague(league);
  }

  public deleteLeague(leagueDocId: string): void {
    this.leagueService.deleteLeague(leagueDocId);
    this.router.navigate(['/leagues']);
  }

  public openLinkEventModal(leagueData: ILeagueData): void {
    this.eventService.getEventNames().then(eventNames => {
      this.eventNames = eventNames;

      // clear form
      this.linkEvent = {
        eventDocId: null,
        eventName: null
      };

      const content = this.linkEventModal;
      this.modalService
        .open(content, { centered: true, keyboard: false }).result
        .then(() => {
          // cancelled
        }, (reason) => {
          if (reason === 'save') {
            // check if event is already linked
            if (leagueData.eventDocIds.indexOf(this.linkEvent.eventDocId) === -1) {
              leagueData.eventDocIds.push(this.linkEvent.eventDocId);
              this.leagueService.saveLeague(leagueData);
            }
          }
        });

    });
  }

  public unlinkEvent(league: ILeagueData, eventDocId: string): void {
    const docIndex = league.eventDocIds.indexOf(eventDocId);
    league.eventDocIds.splice(docIndex, 1);
    this.leagueService.saveLeague(league);
  }

  public get newManualPointFormDisabled(): boolean {
    if (this.newManualPoint === undefined) { return true }
    if (this.newManualPoint.comment === '' || this.newManualPoint.comment === null) { return true }
    if (this.newManualPoint.points === null || this.newManualPoint.points === 0) { return true }
    if (this.newManualPoint.playerDocId === null || this.newManualPoint.playerDocId === '') { return true }
    return false;
  }


}

import { PaymentService } from 'src/app/payment/payment.service';
import { IEventPlayerDetails, IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { IRegistrationFeePayment, PaymentFactor, PaymentStatus, PaymentType, ProductType } from 'tolaria-cloud-functions/src/_interfaces';
import { BehaviorSubject } from 'rxjs';
import * as firestore from 'firebase/firestore'

@Component({
  selector: 'app-register-event-payment',
  templateUrl: './register-event-payment.component.html',
  styleUrls: ['./register-event-payment.component.css']
})
export class RegisterEventPaymentComponent implements OnInit {
  @Input() event: IEventDetails
  @Input() player: IEventPlayerDetails
  @Input() showAsModal: boolean

  public init$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  public paymentTypes: string[] = []
  public payment: IRegistrationFeePayment
  public charityAmountInput: number = null

  constructor(
    private modal: NgbActiveModal,
    private paymentService: PaymentService,
  ) {
    Object.keys(PaymentType).forEach(v => {
      if (v !== PaymentType.TOLARIA) this.paymentTypes.push(v)
    })
  }

  ngOnInit(): void {
    this.init$.next(true)
    this.payment = {
      paid: true,
      refunded: false,
      status: PaymentStatus.PAID,
      timestamp: firestore.Timestamp.now().seconds,
      amount: this.amount * PaymentFactor,
      amountRefunded: 0,
      currency: this.event.details.registrationFee.currency.toUpperCase(),
      charityAmount: null,
      paymentType: null,
      comment: '',
    }
  }

  public dismiss(): void {
    this.modal.dismiss()
  }

  public async close() {

    // add charity amount if any
    this.payment.charityAmount = this.charityAmountInput === null ? 0 : this.charityAmountInput * PaymentFactor

    this.paymentService.manualPayment(ProductType.REGISTRATION_FEE, {
      eventDocId: this.event.docId,
      playerDocId: this.player.playerDocId,
      playerName: this.player.name,
      payment: this.payment,
    })
    .then(() => this.modal.close())
  }

  public get amount(): number {
    return this.event && this.event.details && this.event.details.registrationFee && this.event.details.registrationFee.amount
  }

  public get currency(): string {
    return this.event && this.event.details && this.event.details.registrationFee && this.event.details.registrationFee.currency.toUpperCase()
  }

  public get disableSave(): boolean {
    return this.payment.paymentType === null
  }

  public get charityExtraEnabled(): boolean {
    return this.event.details.registrationFee.charityExtra
  }

}

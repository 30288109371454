<ng-container *ngIf="eventDoc$ | async as event">

  <div class="card full-height card-auto">

    <div class="card-header">Group Settings</div>

    <div class="card-body full-size no-hover p-0 d-flex flex-row">

      <div class="congifuration-wrapper p-3">

        <div class="row mt-3 mb-3">
          <div class="col">
            <span class="text-secondary">Number of active players</span>
          </div>
          <div class="col-3">
            <input type="number" class="text-center text-secondary w-100" [value]="playerCount" disabled>
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col">
            <span>Number of groups</span>
          </div>
          <div class="col-3">
            <input type="number" class="text-center w-100" [(ngModel)]="numberOfGroups" (input)="generateGroups()">
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col">
            <span [ngClass]="{ 'text-secondary' : roundRobin }">Number of matches per player</span>
          </div>
          <div class="col-3">
            <input type="number" class="text-center w-100" [ngClass]="{ 'text-secondary' : roundRobin }" [(ngModel)]="matchesPerPlayer" (input)="generateGroups()" [disabled]="roundRobin">
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col">
            <span>Round Robin</span>
          </div>
          <div class="col-3 text-end">
            <div class="form-check form-switch">
              <input type="checkbox" class="form-check-input" id="roundRobin"
                (change)="roundRobin ? allowByes = false : null; checkGroups()"
                [(ngModel)]="roundRobin">
              <label class="form-check-label" for="roundRobin"></label>
            </div>
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col">
            <span [ngClass]="{ 'text-secondary' : roundRobin }">Allow byes</span>
          </div>
          <div class="col-3 text-end">
            <div class="form-check form-switch">
              <input type="checkbox" class="form-check-input" id="allowByes"
                [(ngModel)]="allowByes" [disabled]="roundRobin" (change)="checkGroups()">
              <label class="form-check-label" for="allowByes"></label>
            </div>
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col">
            <span>Autogenerate groups</span>
          </div>
          <div class="col-3 text-end">
            <div class="form-check form-switch">
              <input type="checkbox" class="form-check-input" id="autoGenerateGroups"
                [(ngModel)]="autoGenerateGroups">
              <label class="form-check-label" for="autoGenerateGroups"></label>
            </div>
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col">
            <button [disabled]="autoGenerateGroups" [ngClass]="{ 'btn-main' : !autoGenerateGroups }"
              class="btn btn-sm btn-secondary w-100" (click)="generateGroups()">Generate groups</button>
          </div>
        </div>

        <div class="help-wraper">
          In a <span class="marker">Round Robin</span> group event, each player will be paired againt all other players in the group.
          If the groups are not even in player count the number of matches played by each player will vary as the player count vary.
          <br><br>
          When you have selected an odd number of <span class="marker">matches per player</span> all groups with odd number of players
          will fail to be paried without allowing <span class="marker">byes</span> to be assigned.
          <br><br>
          You can change the number of groups, number of matches to be played, drag and drop players around between groups, toggle
          round robin, and toggle to allow byes to make the pairings valid.
        </div>

      </div>

      <div class="groups-container flex-grow-1 d-flex flex-wrap">

        <ng-container *ngFor="let group of groups; let groupIndex = index">

          <div mwlDroppable dragOverClass="droppable-dropping" class="group-wrapper"
            [ngClass]="{ 'invalid' : !group.canBePaired }"
            (drop)="dropped($event, groupIndex)">

            <div class="group-name d-flex align-items-center">
              <span class="flex-grow-1">{{ group.name }}</span>
              <span class="flex-grow-0" *ngIf="!group.canBePaired">
                <ng-template #popContent>
                  <div [innerHTML]="group.warningText"></div>
                </ng-template>
                <div class="btn btn-sm btn-blank" [ngbPopover]="popContent">
                  <fa-icon [fixedWidth]="true" [icon]="faExclamationTriangle"></fa-icon>
                </div>
              </span>
            </div>

            <div class="player-list-wrapper">

              <div class="list-item player draggable" *ngFor="let player of group.players; trackBy: tracker" mwlDraggable
                dragActiveClass="being-dragged" [dropData]="player">

                <app-player-display-name *ngIf="player.playerUid.substr(0, 6) !== 'temp__'; else tempPlayer"
                  [playerUid]="player.playerUid" [name]="player.name" position="left"></app-player-display-name>
                <ng-template #tempPlayer>{{ player.name }}</ng-template>

              </div>

            </div>

            <div class="text-center w-100 text-secondary mt-2 mb-2">{{ group.players.length }} players</div>

          </div>

        </ng-container>

      </div>

    </div>


    <div class="card-footer">
      <button class="btn btn-sm btn-secondary float-end" [ngClass]="{ 'btn-main' : !groupsInvalid }"
        [disabled]="groupsInvalid" (click)="createMatches(event)">Create matches</button>
    </div>

  </div>

</ng-container>

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs'
import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { faTimes, faSquare, faCheckSquare, faBars } from '@fortawesome/free-solid-svg-icons'
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces'
import { EventService } from 'src/app/services'
import { IBracketSize, IEventPlayerDetails, IEventDetails, IEventTeam, IMatchData } from 'tolaria-cloud-functions/src/_interfaces'
import { MatchService } from 'src/app/services/match.service'
import { PlayerTieBreakers, TeamTieBreakers } from 'src/app/services/event/tiebreakers'

export interface IEventPlayoffPlayer extends IEventPlayerDetails {
  selected: boolean
  seed: number
  rank: number
}
@Component({
  selector: 'app-event-playoff-config',
  templateUrl: './event-playoff-config.component.html',
  styleUrls: ['./event-playoff-config.component.css']
})
export class EventPlayoffConfigComponent implements OnInit, OnDestroy {
  @Input() eventDocId: string
  @Input() isGroupEvent: boolean

  public faTimes = faTimes
  public faCheckSquare = faCheckSquare
  public faSquare = faSquare
  public faBars = faBars

  public playoffSize = 8

  public playoffSizes$: Observable<IBracketSize[]>
  public playersList$: BehaviorSubject<IEventPlayerDetails[]> = new BehaviorSubject<IEventPlayerDetails[]>(null)
  public teamsList$: BehaviorSubject<IEventTeam[]> = new BehaviorSubject<IEventTeam[]>(null)
  public eventDoc$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)

  public listStyle = {
    height: 'auto',
    width: 'auto',
    dropZoneHeight: '.5rem'
  }

  private componentWasDestroyed$ = new Subject<boolean>()

  constructor(
    private es: EventService,
    private activeModal: NgbActiveModal,
    private ms: MatchService,
  ) { }


  ngOnInit(): void {
    this.playoffSizes$ = this.es.getBracketSizes()
    this.initDocs()
  }

  ngOnDestroy(): void {
    this.componentWasDestroyed$.next(true)
  }

  private async initDocs() {

    const event = await this.es.getEventByIdPromise(this.eventDocId)

    if (this.isTeamSwissEvent) {
      this.initTeamsList(event)
    }
    else {
      this.initPlayerList(event)
    }

    this.eventDoc$.next(event)

  }

  private async initPlayerList(event: IEventDetails) {

    const players = await this.es.getEventPlayersByEventIdPromise(this.eventDocId)
    const matches = await this.ms.getMatchesForEventByEventIdData(this.eventDocId)

    const playersData = this.es.__getExtendedPlayers(players, matches, event)
    const playersWithBreakers = PlayerTieBreakers.compute(playersData)
    const playersSorted = PlayerTieBreakers.sort(playersWithBreakers)
    const playersRanked = PlayerTieBreakers.rank(playersSorted, event.details.structure.isGroup)

    // select the players for a default playoff size
    for (const player of playersRanked.filter(i => i.rank <= this.playoffSize && !i.dropped)) {
      player.selected = true
    }

    console.log(playersRanked)
    this.playersList$.next(playersRanked)
  }
  private async initTeamsList(event: IEventDetails) {

    // const teamDocs = await firstValueFrom(this.es.getEventTeamsByEventId(this.eventDocId))

    // const matchDocs = await firstValueFrom(this.ms.getMatchesForEventByEventId(this.eventDocId))

    // const teams = this.es.__getExtendedTeams(teamDocs, matchDocs, event)

    // const teamsWithBreakers = TeamTieBreakers.compute(teams)

    // const teamsSorted = TeamTieBreakers.sort(teamsWithBreakers)

    // const teamsRanked = TeamTieBreakers.rank(teamsSorted)

    // console.log(teamsRanked)

    // this.teamsList$.next(teamsRanked)

  }
  public selectPlayerForPlayoff(rank: number) {
    const players = this.playersList$.getValue()
    const playerIndex = players.findIndex(x => x.rank === rank)
    if (players[playerIndex].dropped) {
      return
    }
    else if (players[playerIndex].selected) {
      players[playerIndex].selected = false
    }
    else if (!players[playerIndex].selected && this.selectedQty < this.playoffSize) {
      players[playerIndex].selected = true
    }
    this.listSorted(players)
  }
  public updateSelectedPlayers() {
    const players = this.playersList$.getValue()
    players.forEach(player => {
      if (player.rank <= this.playoffSize && !player.dropped) {
        player.selected = true
      }
      else {
        player.selected = false
      }
    })
    this.playersList$.next(players)
  }
  public async generatePlayoffBracket() {
    this.es.createSwissEliminationBracket(
      this.playersList$.getValue().filter(player => player.selected),
      this.eventDoc$.getValue(),
      this.eventDocId
    )
      .then((response: IPromiseResponse) => {
        this.activeModal.close()
      })
  }
  public async generateTeamPlayoffBracket() {

    this.es.createSwissTeamEliminationBracket(
      this.teamsList$.getValue().filter(i => i.selected),
      this.eventDoc$.getValue(),
      this.eventDoc$.getValue().docId
    )
      .then((response: IPromiseResponse) => {
        this.activeModal.close()
      })

  }
  public listSorted(updatedList: any) {
    let seed = 1
    for (const player of updatedList) {
      if (player.selected) {
        player.seed = seed
        seed++
      }
      else {
        player.seed = null
      }
    }
    this.playersList$.next(updatedList)
  }


  public get isTeamSwissEvent(): boolean {
    if (this.eventDoc$.getValue() === null) {
      return false
    }

    return this.eventDoc$.getValue().details.structure.isSwiss
      ? this.eventDoc$.getValue().details.structure.swiss.teams
      : false

  }
  public get countSelected(): number {
    if (this.playersList$.getValue() === null) { return 0 }
    return this.playersList$.getValue().filter(x => x.selected === true).length
  }
  public get getSelectedPlayerQty(): number {
    if (this.playersList$.getValue() === null) { return 0 }
    return this.playersList$.getValue().filter(player => player.selected).length
  }
  public get selectedQty(): number {

    if (this.eventDoc$.getValue() === null) {
      return 0
    }

    if (this.isTeamSwissEvent && this.teamsList$.getValue() === null) {
      return 0
    }

    if (!this.isTeamSwissEvent && this.playersList$.getValue() === null) {
      return 0
    }

    return this.isTeamSwissEvent
      ? this.teamsList$.getValue().filter(i => i.selected).length
      : this.playersList$.getValue().filter(i => i.selected).length

  }
  public get canCreateTeamBracket(): boolean {
    if (this.teamsList$.getValue() === null) {
      return false
    }
    if (this.teamsList$.getValue().filter(i => i.selected).length < 2) {
      return false
    }
    const exponent = Math.log2(this.teamsList$.getValue().filter(i => i.selected).length)
    return exponent % 1 === 0
  }
  public get canCreateTeamBracketText(): string {
    if (this.teamsList$.getValue() === null) {
      return 'Waiting for team data to be loaded...'
    }
    if (this.teamsList$.getValue().filter(i => i.selected).length < 2) {
      return 'You have to select at least 2 teams to continue'
    }
    const exponent = Math.log2(this.teamsList$.getValue().filter(i => i.selected).length)
    return exponent % 1 === 0
      ? 'All good'
      : 'You need to select teams to fill a whole bracket (2/4/8/16/32/etc.)'
  }

}

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { faFolder, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { IDeckFolder, IDeckList, DecksService, IDeckVersion } from 'src/app/services/decks.service';
import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'app-deck-details',
  templateUrl: './deck-details.component.html',
  styleUrls: ['./deck-details.component.css']
})
export class DeckDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('deckFolderSelection', { static: false }) deckFolderSelection: ElementRef;
  @ViewChild('newDeckFolder', { static: false }) newDeckFolder: ElementRef;
  @ViewChild('deleteDeckConfirmation', { static : false }) deleteDeckConfirmation: ElementRef;

  @Input()
  set deckList(deck: IDeckList) {
    this.deck = deck;
    console.log(deck);
    this.originDeckString = JSON.stringify(deck);
    this.checkIfDeletable();
  }
  @Input() originDeck: IDeckList;

  public faFolder = faFolder;
  public faFolderPlus = faFolderPlus;

  public newDeckFolderName: string = '';
  public deck: IDeckList;
  private originDeckString: string;
  public folderList$: Observable<IDeckFolder[]>;
  private deckFolders: IDeckFolder[] = [];
  private componentWasDestroyed$: Subject<boolean> = new Subject<boolean>();
  public canBeDeleted$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public deckEventInfo$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private deckService: DecksService,
    private modalService: NgbModal,
    ) {
      this.folderList$ = this.deckService.deckFolders$;
      this.folderList$.pipe(takeUntil(this.componentWasDestroyed$)).subscribe((folders: IDeckFolder[]) => {
        this.deckFolders = folders;
      });

    }

  ngOnInit(): void {
    console.log('getting deck event info')
    this.deckService.getDeckEventInformation(this.originDeck).then(data => {
      console.log(data);
      this.deckEventInfo$.next(data)
    });
  }
  ngOnDestroy(): void {
    this.componentWasDestroyed$.next(true);
  }

  private checkIfDeletable(): void {
    this.canBeDeleted$.next(null);
    this.deckService.checkIfDeckCanBeDeleted().then(check => this.canBeDeleted$.next(check));
  }

  public get isVersion(): boolean {
    return this.deck.docId !== this.originDeck.docId;
  }
  public get deckFolderName(): string {
    const folder = this.deckFolders.find(f => f.docId === this.originDeck.deckFolderDocId);
    if (folder) {
      return folder.name;
    }
    else {
      return 'Uncategorized';
    }
  }

  public selectDeckFolder(folderDocId: string): void {
    this.deckService.updateDeckProperty(this.deck.docId, 'deckFolderDocId', folderDocId);
  }

  public createNewDeckFolder(): void {
    const content = this.newDeckFolder;
    this.modalService
      .open(content, { centered: true, keyboard: false }).result
      .then((result) => {
        this.deckService.newDecksFolder(result)
          .then((deckFolderDocId: string) => this.deckService.updateDeckProperty(this.deck.docId, 'deckFolderDocId', deckFolderDocId));
      }, (reason) => {
        console.log(reason);
      });
  }

  public updateDeck(property: string): void {
    const originProperty = JSON.parse(this.originDeckString)[property];
    const newProperty = this.deck[property];

    if (originProperty !== newProperty) {
      this.deckService.updateDeckProperty(this.deck.docId, property, newProperty);
    }
  }

  public deleteDeck(): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: false,
      keyboard: false,
      size: 'sm',
    };

    this.modalService.open(this.deleteDeckConfirmation, modalOptions)
    .result
    .then((result) => {
      if (result === 'delete') {
        this.deckService.deleteDeckList(this.originDeck, this.deck);
      }
    });
  }

}

import { INoteMeta, NotesService } from './../../../../services/notes.service';
import { Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { faStickyNote, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-match-notes',
  templateUrl: './match-notes.component.html',
  styleUrls: ['./match-notes.component.css']
})
export class MatchNotesComponent implements OnInit {
  @Input() matchDocId: string;

  faStickyNote = faStickyNote;
  faTrash = faTrash;

  notes$: Observable<INoteMeta[]>;

  constructor(
    private notesService: NotesService
  ) {
  }

  ngOnInit(): void {
    this.notes$ = this.notesService.getNotesForMatchWithId(this.matchDocId);
  }

  deleteMatchNote(docId: string) {
    this.notesService.deleteNote(docId);
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ClubsService } from 'src/app/services/clubs.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { IClub } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-club-avatar',
  templateUrl: './club-avatar.component.html',
  styleUrls: ['./club-avatar.component.css'],
  standalone: true,
  imports: [
    NgIf,
    NgbTooltip,
    AsyncPipe,
  ],
})
export class ClubAvatarComponent implements OnInit {
  @Input() playerDocId: string;
  @Input() clubDocId: string;
  @Input() imageSize: string = 'small';
  @Input() borderClass: string = 'border border-3 border-dark';

  public club$: Observable<IClub>;

  constructor(private clubsService: ClubsService) { }

  ngOnInit(): void {

    console.log(`ClubAvatar => Initialize`);
    console.log(`ClubAvatar => clubDocId : ${this.clubDocId}`);
    console.log(`ClubAvatar => playerDocId : ${this.playerDocId}`);
    console.log(`ClubAvatar => imageSize : ${this.imageSize}`);

    if (this.clubDocId !== undefined) {
      console.log(`ClubAvatar => fetching club by clubDocId: ${this.clubDocId}`);
      this.club$ = this.clubsService.getClub(this.clubDocId);
    }
    else if (this.clubDocId === undefined && this.playerDocId !== undefined) {
      console.log(`ClubAvatar => fetching club by playerDocId: ${this.playerDocId}`);
      this.clubsService.getClubByPlayerId(this.playerDocId)
        .then(club => {
          this.club$ = club;
        })
        .catch(() => {
          console.log(`ClubAvatar => error getting club data`);
        })
    }

  }

}

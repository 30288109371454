import { GlobalsService } from 'src/app/services';
import { Pipe, PipeTransform } from '@angular/core';
import { IEventFilter, IEventFilterSelectList, IEventListItem } from '../services';

@Pipe({
  name: 'eventFilter',
  pure: false
})
export class EventFilterPipe implements PipeTransform {

  constructor(
    private globals: GlobalsService
  ) {}

  transform(items: IEventListItem[], filter: IEventFilter, attending: boolean = null): IEventListItem[] {

    const timestamp = this.getDateIntervals(filter);

    // console.log({
    //   items,
    //   filter,
    //   attending,
    //   timestamp,
    // })
    // return items;

    if (!items) {
      return [];
    }
    
    if (this.globals.isMobile) {
      return attending
      ? items.filter(i => i.statusCode !== 8 && i.isAttending)
      : items.filter(i => i.statusCode !== 8 && !i.isAttending)
    }
    
    let filterTimestamp = timestamp;
    // console.log('about to filter events...', Object.keys(filter.checker).filter(f => filter.checker[f]).length > 0);
    // console.log(Object.keys(filter.checker).filter(f => !filter.checker[f]));
    if (Object.keys(filter.checker).filter(f => filter.checker[f]).length > 0 || filter.nameString !== '') {
      if (this.getFilterTexts(filter.dateInterval).length > 0) {
        filterTimestamp = this.getDateIntervals(filter, filter.dateInterval.find(d => d.selected).name);
      }
      // console.log('filtered values used');
      return items
        .filter(item => {
          if (item !== undefined && filter.checker.formatIsFiltered
            && this.getFilterTexts(filter.format).includes(item.format)) {
            return item;
          }
          else if (item !== undefined && !filter.checker.formatIsFiltered) {
            return item;
          }
        })
        .filter(item => {
          if (item !== undefined && filter.checker.typeIsFiltered
            && this.getFilterTexts(filter.type).includes(item.type)) {
            return item;
          }
          else if (item !== undefined && !filter.checker.typeIsFiltered) {
            return item;
          }
        })
        .filter(item => {
          if (item !== undefined && filter.checker.onlineIsFiltered
            && this.getFilterTexts(filter.online).includes(item.isOnline ? 'Online' : 'In person')) {
            return item;
          }
          else if (item !== undefined && !filter.checker.onlineIsFiltered) {
            return item;
          }
        })
        .filter(item => {
          if (item !== undefined && filter.checker.publicIsFiltered
            && this.getFilterTexts(filter.public).includes(item.isPublic ? 'Public' : 'Private')) {
            return item;
          }
          else if (item !== undefined && !filter.checker.publicIsFiltered) {
            return item;
          }
        })
        .filter(item => {
          if (item !== undefined && filter.checker.statusIsFiltered
            && this.getFilterTexts(filter.status).includes(this.getEventStatusText(item.statusCode))) {
            return item;
          }
          else if (item !== undefined && !filter.checker.statusIsFiltered) {
            return item;
          }
        })
        .filter(item => {
          if (item !== undefined && filter.checker.youIsFiltered
            && this.getFilterTexts(filter.you).includes('Attending') && item.isAttending) {
            return item;
          }
          else if (item !== undefined && filter.checker.youIsFiltered
            && this.getFilterTexts(filter.you).includes('Hosting') && item.isOrganizing) {
            return item;
          }
          else if (item !== undefined && !filter.checker.youIsFiltered) {
            return item;
          }
        })
        .filter(item => {
          if (item !== undefined && filter.nameString !== ''
            && item.name.toLowerCase().includes(filter.nameString.toLowerCase())) {
            return item;
          }
          else if (item !== undefined && filter.nameString === '') {
            return item;
          }
        })
        .filter(item => {
          if (item !== undefined) {
            if (
              // MULTI-DAY.: FILTER START <= EVENT START && FILTER END >= EVENT END
              item.isMultiDay && timestamp.from <= item.datestampFrom && timestamp.to >= item.datestampTo ||
              // MULTI-DAY.: FILTER START >= EVENT START && FILTER END >= EVENT END
              item.isMultiDay && timestamp.from >= item.datestampFrom && timestamp.to >= item.datestampTo ||
              // MULTI-DAY.: FILTER START <= EVENT START && FILTER END <= EVENT END
              item.isMultiDay && timestamp.from <= item.datestampFrom && timestamp.to <= item.datestampTo ||
              // MULTI-DAY.: FILTER START >= EVENT START && FILTER END <= EVENT END
              item.isMultiDay && timestamp.from >= item.datestampFrom && timestamp.to <= item.datestampTo ||
              // SINGLE-DAY: FILTER START <= EVENT START && FILTER END >= EVENT START
              !item.isMultiDay && item.datestampFrom >= timestamp.from && item.datestampFrom <= timestamp.to
            ) {
              return item;
            }
          }
        });
    }
    else {
      return items.filter(item => {
        if (item.statusCode !== 8) {
          if (
            // MULTI-DAY.: FILTER START <= EVENT START && FILTER END >= EVENT END
            item.isMultiDay && timestamp.from <= item.datestampFrom && timestamp.to >= item.datestampTo ||
            // MULTI-DAY.: FILTER START >= EVENT START && FILTER END >= EVENT END
            item.isMultiDay && timestamp.from >= item.datestampFrom && timestamp.to >= item.datestampTo ||
            // MULTI-DAY.: FILTER START <= EVENT START && FILTER END <= EVENT END
            item.isMultiDay && timestamp.from <= item.datestampFrom && timestamp.to <= item.datestampTo ||
            // MULTI-DAY.: FILTER START >= EVENT START && FILTER END <= EVENT END
            item.isMultiDay && timestamp.from >= item.datestampFrom && timestamp.to <= item.datestampTo ||
            // SINGLE-DAY: FILTER START <= EVENT START && FILTER END >= EVENT START
            !item.isMultiDay && item.datestampFrom >= timestamp.from && item.datestampFrom <= timestamp.to
          ) {
            return item;
          }
        }
      });
    }
  }

  getFilterTexts(filterArray: Array<IEventFilterSelectList>): Array<string> {
    const texts: Array<string> = [];
    filterArray.forEach(filter => {
      if (filter.selected) {
        texts.push(filter.name);
      }
    });
    return texts;
  }

  getEventStatusText(statusCode: number): string {
    if (statusCode === 0) {
      return 'Open for registration';
    }
    else if (statusCode === 8) {
      return 'Ended';
    }
    else {
      return 'In progress';
    }
  }

  getDateIntervals(filter: IEventFilter, dateIntervalText: string = 'Next 7 Days') {
    const d = new Date();
    let timestampFrom: number;
    let timestampTo: number;

    const dateFrom = new Date(filter.dateFrom.split('.').join('-'));
    dateFrom.setHours(0);
    dateFrom.setMinutes(0);
    dateFrom.setSeconds(0);
    const dateTo = new Date(filter.dateTo.split('.').join('-'));
    dateTo.setHours(23);
    dateTo.setMinutes(59);
    dateTo.setSeconds(59);

    const returnObj = {
      from: Math.floor(dateFrom.getTime()),
      to: Math.floor(dateTo.getTime())
    };
    // console.log(returnObj);
    return returnObj;

    // set Last 7 Days as default if no date is set and Ended is selected
    if (
      // DEFAULT VALUE IS USED
      dateIntervalText === 'Next 7 Days' &&
      // DEFAULT VALUE IS NOT SELECTED BY USER
      !filter.dateInterval.find(df => df.name === 'Next 7 Days').selected &&
      // ENDED IS SELECTED
      filter.status.findIndex(ff => ff.name === 'Ended' && ff.selected) > -1 &&
      // ONLY ENDED SELECTED
      filter.status.filter(ff => ff.selected).length === 1
    ) {
      dateIntervalText = 'Last 7 Days';
    }

    const thisWeekMondayLookup = [
      -6,
      0,
      -1,
      -2,
      -3,
      -4,
      -5
    ];
    const thisWeekFridayLookup = [
      -2,
      4,
      3,
      2,
      1,
      0,
      -1
    ];
    const thisWeekSundayLookup = [
      0,
      6,
      5,
      4,
      3,
      2,
      1
    ];
    const nextWeekMondayLookup = [
      1,
      7,
      6,
      5,
      4,
      3,
      2
    ];
    const nextWeekFridayLookup = [
      5,
      11,
      10,
      9,
      8,
      7,
      6
    ];
    const nextWeekSundayLookup = [
      7,
      13,
      12,
      11,
      10,
      9,
      8
    ];

    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    const dYesterday = new Date();
    const mondayThisWeek = new Date();
    const fridayThisWeek = new Date();
    const sundayThisWeek = new Date();
    const mondayNextWeek = new Date();
    const fridayNextWeek = new Date();
    const sundayNextWeek = new Date();
    dYesterday.setDate(d.getDate() - 1);

    switch (dateIntervalText) {
      case 'Next 2 Days':
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        timestampFrom = Math.floor(d.getTime());

        const d2 = new Date();
        d2.setDate(d.getDate() + 7);
        timestampTo = Math.floor(d2.getTime());
        return {
          from: timestampFrom,
          to: timestampTo
        };
      case 'Next 7 Days':
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        const d7 = new Date();
        d7.setDate(d.getDate() + 7);
        return {
          from: Math.floor(d.getTime()),
          to: Math.floor(d7.getTime())
        };
      case 'This Week':
        mondayThisWeek.setDate(d.getDate() + thisWeekMondayLookup[d.getDay()]);
        mondayThisWeek.setHours(0);
        mondayThisWeek.setMinutes(0);
        mondayThisWeek.setSeconds(0);
        sundayThisWeek.setDate(d.getDate() + thisWeekSundayLookup[d.getDay()]);
        sundayThisWeek.setHours(23);
        sundayThisWeek.setMinutes(59);
        sundayThisWeek.setSeconds(59);
        return {
          from: Math.floor(mondayThisWeek.getTime()),
          to: Math.floor(sundayThisWeek.getTime())
        };
      case 'This Weekend':
        fridayThisWeek.setDate(d.getDate() + thisWeekFridayLookup[d.getDay()]);
        fridayThisWeek.setHours(0);
        fridayThisWeek.setMinutes(0);
        fridayThisWeek.setSeconds(0);
        sundayThisWeek.setDate(d.getDate() + thisWeekSundayLookup[d.getDay()]);
        sundayThisWeek.setHours(23);
        sundayThisWeek.setMinutes(59);
        sundayThisWeek.setSeconds(59);
        return {
          from: Math.floor(fridayThisWeek.getTime()),
          to: Math.floor(sundayThisWeek.getTime())
        };
      case 'Next Week':
        mondayNextWeek.setDate(d.getDate() + nextWeekMondayLookup[d.getDay()]);
        mondayNextWeek.setHours(0);
        mondayNextWeek.setMinutes(0);
        mondayNextWeek.setSeconds(0);
        sundayNextWeek.setDate(d.getDate() + nextWeekSundayLookup[d.getDay()]);
        sundayNextWeek.setHours(23);
        sundayNextWeek.setMinutes(59);
        sundayNextWeek.setSeconds(59);
        return {
          from: Math.floor(mondayNextWeek.getTime()),
          to: Math.floor(sundayNextWeek.getTime())
        };
      case 'Next Weekend':
        fridayNextWeek.setDate(d.getDate() + nextWeekFridayLookup[d.getDay()]);
        fridayNextWeek.setHours(0);
        fridayNextWeek.setMinutes(0);
        fridayNextWeek.setSeconds(0);
        sundayNextWeek.setDate(d.getDate() + nextWeekSundayLookup[d.getDay()]);
        sundayNextWeek.setHours(23);
        sundayNextWeek.setMinutes(59);
        sundayNextWeek.setSeconds(59);
        return {
          from: Math.floor(fridayNextWeek.getTime()),
          to: Math.floor(sundayNextWeek.getTime())
        };
      case 'All upcoming':
        const dAllUpcoming = new Date();
        dAllUpcoming.setDate(d.getDate() + 2000);
        dAllUpcoming.setHours(23);
        dAllUpcoming.setMinutes(59);
        dAllUpcoming.setSeconds(59);
        return {
          from: Math.floor(d.getTime()),
          to: Math.floor(dAllUpcoming.getTime())
        };
      case 'Last 7 Days':
        const dLast7Days = new Date();
        dLast7Days.setDate(d.getDate() - 7);
        dLast7Days.setHours(0);
        dLast7Days.setMinutes(0);
        dLast7Days.setSeconds(0);
        d.setHours(23);
        d.setMinutes(59);
        d.setSeconds(59);
        return {
          from: Math.floor(dLast7Days.getTime()),
          to: Math.floor(d.getTime())
        };
      case 'Last 30 Days':
        const dLastMonth = new Date();
        dLastMonth.setDate(d.getDate() - 30);
        dLastMonth.setHours(0);
        dLastMonth.setMinutes(0);
        dLastMonth.setSeconds(0);
        d.setHours(23);
        d.setMinutes(59);
        d.setSeconds(59);
        return {
          from: Math.floor(dLastMonth.getTime()),
          to: Math.floor(d.getTime())
        };
      case 'All Past':
        const dAllPast = new Date('1900-01-01');
        dAllPast.setHours(0);
        dAllPast.setMinutes(0);
        dAllPast.setSeconds(0);
        d.setHours(23);
        d.setMinutes(59);
        d.setSeconds(59);
        return {
          from: Math.floor(dAllPast.getTime()),
          to: Math.floor(d.getTime())
        };
    }
  }

}

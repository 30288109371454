<ng-container *ngIf="event$ | async as event">
  <ng-container *ngIf="players$ | async as playerList">
    <div class="player-list">
      <ng-container *ngFor="let player of playerList; trackBy: tracker">
        <div class="player event-player-list pt-2 pb-2 d-flex"
          [ngClass]="{'selected' : isSelectedPlayer(player.playerDocId) }" (click)="showPlayerDetails(player);">
          <app-user-status class="pe-2 flex-grow-0" [playerUid]="player.playerUid"></app-user-status>
          <span class="flex-grow-1">
            <ng-container *ngIf="player.playerDocId.includes('temp__'); else playerLink">
              <span>{{ player.name }}</span>
            </ng-container>
            <ng-template #playerLink>
              <app-player-display-name [playerDocId]="player.playerDocId" [name]="player.name"
                (click)="$event.stopPropagation(); $event.preventDefault()"></app-player-display-name>
            </ng-template>
          </span>

          <span class="drop-wrapper flex-grow-0" *ngIf="player.dropped">dropped</span>

          <fa-icon class="ms-2 text-secondary chevron flex-grow-0" [fixedWidth]="true" [icon]="faChevronRight"
            *ngIf="!globals.isMobile"></fa-icon>

        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

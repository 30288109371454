<div class="modal-header text-larger">Add emoji</div>


<div class="modal-body d-flex flex-column gap-3">

  <div class="mb-3">
    Your custom emoji will be available to everyone. You’ll find it in the custom tab of the emoji picker. (Hint: it’s the one with the Tolaria icon!)
  </div>

  <div class="d-flex flex-column">

    <div class="text-bold d-flex">
      <div class="" [style.minWidth.rem]="1.5">1.</div>
      <div class="">Upload an image</div>
    </div>

    <div class="ms-4">
      Square images under 128KB and with transparent backgrounds work best. If your image is too large, we’ll try to resize it for you.
    </div>

    <div class="ms-4 d-flex gap-4 mt-3 align-items-end">

      <div class="d-flex gap-2">
        <div class="emoji-preview border border-secondary text-bg-light d-flex align-items-center justify-content-center rounded">
          <img [src]="base64" *ngIf="hasImage; else imageIcon">
        </div>
        <div class="emoji-preview border border-secondary text-bg-dark d-flex align-items-center justify-content-center rounded">
          <img [src]="base64" *ngIf="hasImage; else imageIcon">
        </div>
      </div>

      <div class="d-flex flex-column gap-2">

        <div class="" [innerText]="filename"></div>

        <input #tolariaEmojiUpload
          id="tolariaEmojiUpload"
          name="tolariaEmojiUpload"
          type="file"
          class="d-none"
          (change)="onInputChange(tolariaEmojiUpload)">

        <div class="btn btn-sm btn-outline-secondary" (click)="onUploadImage()">Upload Image</div>

      </div>

    </div>


  </div>


  <div class="d-flex flex-column">

    <div class="text-bold d-flex">
      <div class="" [style.minWidth.rem]="1.5">2.</div>
      <div class="">Give it a name</div>
    </div>

    <div class="ms-4">
      This is also what you’ll type to add this emoji to your messages.
    </div>

    <div class="ms-4 mt-2">
      
      <div class="input-wrapper position-relative">
        <div aria-hidden="true"
          class="text-overlay"
          [ngClass]="{ 'has-focus': shortcodeHasFocus || !shortcodeEmpty }"
          [innerText]="shortcodeEmpty && !shortcodeHasFocus ? 'avocado' : shortcode">
        </div>
        <input
          (input)="onShortcodeInput($event)"
          (focus)="onShortcodeFocus()"
          (blur)="onShortcodeBlur()"
          [(ngModel)]="shortcode"
          id="emojiShortcode"
          name="emojiShortcode"
          type="text"
          class="form-control px-3"
          [ngClass]="{ 'is-invalid border-danger' : shortcodeInvalid }"
          placeholder="avocado">
        <div class="invalid-feedback" *ngIf="shortcodeInvalid">
          {{ shortcodeValidationText }}
        </div>
      </div>


      <ng-container *ngIf="existingEmoji$ | async as item">
        <div class="emoji-preview mt-3 d-flex gap-3 w-100" *ngIf="emojiExist">
          <img [src]="item.image">
          <div class="d-flex flex-column">
            <div class="text-large">Existing emoji</div>
            <div class="">{{ item.shortcode }}</div>
          </div>
        </div>
      </ng-container>

    </div>

  </div>

</div>


<div class="modal-footer d-flex align-items-center justify-content-end gap-2">
  <button class="btn btn-blank" (click)="onDismiss()">Cancel</button>
  <button class="btn btn-blank" (click)="onSave()" [disabled]="formInvalid">Save</button>
</div>




<ng-template #imageIcon>
  <fa-icon class="text-xxlarge" [icon]="fileIcon" [fixedWidth]="true"></fa-icon>
</ng-template>
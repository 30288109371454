import { IMagicSet } from './../services/card-search.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardSetsFilter'
})
export class CardSetsFilterPipe implements PipeTransform {

  transform(items: IMagicSet[], filter: string): IMagicSet[] {
    if (!items) {
      return [];
    }

    const res = [];

    items.forEach(set => {
      set.list.sort((a, b) => (a.released_at > b.released_at) ? 1 : ((b.released_at > a.released_at) ? -1 : 0))
    });

    if (items && filter === '') {
      return items;
    }


    items.forEach(set => {
      const setNames = set.list.filter(t =>
        t.name.toLowerCase().includes(filter.toLowerCase()) || t.code.toLowerCase().includes(filter.toLowerCase()));
      if (setNames.length > 0) {
        const resType = {
          name: set.name,
          type: set.type,
          list: setNames
        } as IMagicSet;
        res.push(resType);
      }
    });
    return res;
  }

}

<app-public-header [showAbout]="true"></app-public-header>
<section class="my-5 pt-5" style="height: auto;">
  <div class="container bg-body rounded rounded-4 p-4 shadow-lg d-flex flex-column gap-5">

    <div class="">
      <h1 class="mb-4">Payments & Fees on Tolaria.app</h1>
      <p class="">
        Tolaria.app is a community-driven platform designed to help organizers manage
        in-person and online <strong>Magic: The Gathering tournaments and events</strong>.
      </p>
    </div>

    <div class="">
      <h3 class="">How Payments Work</h3>
      <p>
        Tolaria.app integrates <strong>Stripe</strong> to facilitate payments for tournament registration,
        platform support, and event-related costs.
      </p>
    </div>

    <!-- Tournament & Event Registration Fees -->
    <div class="">
      <h4 class="">Tournament & Event Registration Fees</h4>
      <ul class="">
        <li class="">
          Organizers set <strong>registration fees</strong> for their events.
        </li>
        <li class="">
          Participants pay securely through <strong>Stripe</strong>.
        </li>
        <li class="">
          Fees help <strong>cover venue costs</strong> and necessary expenses.
        </li>
        <li class="">
          Some events allow <strong>donation-based entries</strong> to support charities.
        </li>
      </ul>
    </div>

    <!-- Platform Support & Donations -->
    <div class="">
      <h4 class="">Platform Support & Donations</h4>
      <ul class="">
        <li class="">
          Community members can voluntarily <strong>support Tolaria.app</strong> to cover the costs for upholding the platform and/or support further development.
        </li>
        <li class="">
          Contributions help keep the platform running <strong>free for organizers and players</strong>.
        </li>
        <li class="">
          Supporters may receive <strong>tokens of appreciation</strong> at events.
        </li>
      </ul>
    </div>

    <!-- Payouts to Event Organizers -->
    <div class="">
      <h4 class="">Payouts to Event Organizers</h4>
      <ul class="">
        <li class="">
          Organizers can enroll in <strong>Stripe Connected Accounts</strong> to receive payments.
        </li>
        <li class="">
          Funds are used to <strong>cover event expenses</strong> or <strong>support charities</strong>.
        </li>
        <li class="">
          Tolaria.app aim to <strong>not take a percentage*</strong> — all funds should go to the intended purpose.
        </li>
      </ul>
      <div class="fs-7 text-secondary">* In the current setup Tolaria.app applies a strict 5% fee on all transaction to cover the Stripe fees and any currency exchange costs.
        However, the goal is to have only the actual Stripe fees to be applied in the long run.</div>
    </div>

    <!-- Non-Profit & Community Focus -->
    <div class="">
      <h3 class="">Non-Profit & Community Focus</h3>
      <div>
        Tolaria.app operates <strong>strictly as a non-profit initiative</strong>. All funds are used to support
        <ul>
          <li>Event-related costs</li>
          <li>Charitable donations</li>
          <li>Platform maintenance & development</li>
        </ul>
        Our goal is to bring the <strong>global Old School Magic: The Gathering</strong> community closer
        together.
      </div>
    </div>

    <!-- Transparency & Contact -->
    <div class="">
      <h4 class="">Transparency & Contact</h4>
      <p>
        If you have any questions about payments or fund handling, feel free to reach out to us at:
        <strong><a href="mailto:support@tolaria.app">support&#64;tolaria.app</a></strong>
      </p>
    </div>

    <div class="border border-2 border-secondary my-3"></div>

    <div class="">
      <h1 class="">For Organizers</h1>
      <p class="">Manage event payments, refunds, and fees effortlessly with Tolaria.app.</p>
    </div>

    <app-stripe-connected-account-info></app-stripe-connected-account-info>

  </div>
</section>
<app-public-footer></app-public-footer>
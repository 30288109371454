import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { faHatWizard, faTrophy, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable } from 'rxjs';
import { ILeaguePlayer, ILeagueDocument } from 'tolaria-cloud-functions/src/_interfaces';
import { ILeagueData, LeaguesService } from 'src/app/services/leagues.service';
import { Component, OnInit } from '@angular/core';
import { LeagueConfigComponent } from './league-config/league-config.component';
import { take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'node_modules/uuid';

@Component({
  selector: 'app-leagues',
  templateUrl: './leagues.component.html',
  styleUrls: ['./leagues.component.css']
})
export class LeaguesComponent implements OnInit {

  public faHatWizard = faHatWizard;
  public faTrophy = faTrophy;
  public faCalendarAlt = faCalendarAlt;


  public leagues$: Observable<ILeagueData[]>;
  public players$: BehaviorSubject<ILeaguePlayer[]> = new BehaviorSubject<ILeaguePlayer[]>(null)

  constructor(
    private leagues: LeaguesService,
    private auth: AuthService,
    private modalService: NgbModal,
    private leagueService: LeaguesService
  ) { }

  ngOnInit(): void {
    // this.getData();
    this.leagues$ = this.leagues.leagues;

    // this.leagues$.subscribe((test) => {
    //   test.forEach((league) => {
    //     const players = this.getPlayerRanking(league);
    //   })
    // });
  }

  // getPlayerRanking(league: ILeagueData): void {
  //   this.leagues.getLeaguePlayers(league).then((response: IPromiseResponse) => {
  //     this.players$.next(response.data as ILeaguePlayer[]);
  //   });
  // }

  public get isOrganizer(): boolean {
    return this.auth.user.role === 'organizer' || this.auth.user.role === 'admin';
  }

  public createLeague(): void {

    const leagueData: ILeagueDocument = {
      allowManualPoints: false,
      bannerUrl: '',
      createdByPlayerDocId: this.auth.user.playerId,
      createdByUid: this.auth.user.uid,
      createdTimestamp: new Date().getTime() / 1000,
      description: '',
      docId: uuidv4(),
      eventDocIds: [],
      manualPoints: [],
      name: '',
      pointStructure: [],
      timestampEnd: null,
      timestampStart: null,
    }


    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    };

    const modalRef: NgbModalRef = this.modalService.open(LeagueConfigComponent, modalOptions);
    modalRef.componentInstance.leagueData = JSON.parse(JSON.stringify(leagueData));
    modalRef.dismissed.pipe(take(1)).subscribe((data) => {
      console.log(data);
      this.leagueService.saveLeague(data);
    });

  }

}

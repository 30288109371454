<div class="dropdown-item cursor-pointer" *ngFor="let format of formatForm; trackBy : tracker" (click)="format.selected = !format.selected">
  <div>
    <fa-icon [fixedWidth]="true" [icon]="format.selected ? faCheckCircle : faCircle"></fa-icon><span class="ms-2">{{ format.name }}</span>
  </div>
</div>
<div class="px-4 py-3 mt-3 d-flex flex-column gap-2">
  <button class="btn btn-sm btn-secondary w-100" (click)="updateFormatList()">Update formats</button>
  <button class="btn btn-sm btn-primary w-100" (click)="toggleDownToPlay()" *ngIf="!active">Activate</button>
  <button class="btn btn-sm btn-danger w-100" (click)="toggleDownToPlay()" *ngIf="active">Deactivate</button>
</div>
<div class="px-4 text-secondary text-italic" style="max-width: 20rem;">Inactivity for more than 30 minutes will automatically turn off your down to play status.</div>

<div class="d-flex flex-column align-items-center justify-content-center w-100 h-100 bg-body">
  <div class="d-flex flex-column gap-3 p-3 rounded-3 bg-main" [style.marginTop.rem]="-10">
    <div class="p-3 text-jumbo align-items-center d-flex justify-content-center">
      <fa-icon [icon]="icon.app"></fa-icon>
    </div>
    <div class="d-flex flex-column gap-3 p-3">
      <div class="row">
        <div class="col">Your user document</div>
        <div class="col-2">
          <fa-icon *ngIf="content.userDocumentReady$ | async as ready; else spinner" [icon]="icon.loaded" class="text-success"></fa-icon>
        </div>
      </div>
      <div class="row">
        <div class="col">Your player document</div>
        <div class="col-2">
          <fa-icon *ngIf="content.playerDocumentReady$ | async as ready; else spinner" [icon]="icon.loaded" class="text-success"></fa-icon>
        </div>
      </div>
      <div class="row">
        <div class="col">Application data</div>
        <div class="col-2">
          <fa-icon *ngIf="content.playerMinisReady$ | async as ready; else spinner" [icon]="icon.loaded" class="text-success"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #spinner>
  <div class="spinner-border spinner-border-sm" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</ng-template>
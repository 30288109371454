<div class="m-3 p-3 pt-4 mt-5 mb-3 border border-primary rounded-3 bg-primary position-relative"
    style="--bs-bg-opacity: .15;">

    <div class="position-absolute top-0 d-flex align-item-center gap-3 translate-middle-y px-3 p-1 bg-primary rounded rounded-pill" [style.left.rem]="1">
        <div class="text-large">Announcement</div>
        <fa-icon class="text-large" [icon]="icon.horn" [fixedWidth]="true"></fa-icon>
    </div>
    
    <div class="pt-2" [innerHTML]="htmlData"></div>

    <div class="mt-3 d-flex align-items-end gap-3">
        <ng-container *ngIf="eventFetched$ | async; else loading">
            <ng-container *ngIf="eventDoc$ | async as event">
                <button class="btn btn-outline-primary">To the lobby</button>
                <span class="flex-grow-1"></span>
                <div class="d-flex flex-column flex-grow-1 gap-2">
                    <div class="text-primary text-end text-medium">
                        Tournament organizers
                    </div>
                    <div class="d-flex flex-grow-1 justify-content-end gap-2">
                        <app-player-avatar *ngFor="let p of organizers; let i = index"
                            class="rounded rounded-3 overflow-hidden"
                            size="small"
                            [round]="false"
                            [playerUid]="p"
                            [border]="false"
                            [style.zIndex]="i"
                            >
                            <!-- [style.marginLeft.rem]="-2" -->
                        </app-player-avatar>
                    </div>
                </div>
            </ng-container>
        </ng-container> 
    </div>


</div>


<ng-template #loading>

</ng-template>
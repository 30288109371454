import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, ElementRef, Input, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faStopCircle } from '@fortawesome/free-regular-svg-icons'
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { BehaviorSubject } from 'rxjs'
import QrScanner from 'qr-scanner'

@Component({
  imports: [
    CommonModule,
    FontAwesomeModule,
  ],
  standalone: true,
  selector: 'app-qrscanner',
  template: `

  <div #contentWrapper
    class="d-flex flex-column w-100 overflow-hidden position-relative {{ orientation }}"
    [ngClass]="{ 'rounded' : rounded }">

    <div #success
      style="display:none;"
      [style.zIndex]="2"
      class="text-bg-success position-absolute start-0 end-0 top-0 bottom-0 flex-column align-items-center justify-content-center">
      <fa-icon class="text-jumbo" [icon]="icon.success"></fa-icon>
    </div>

    <div #error
      style="display:none;"
      [style.zIndex]="2"
      class="text-bg-error position-absolute start-0 end-0 top-0 bottom-0 flex-column align-items-center justify-content-center">
      <fa-icon class="text-jumbo" [icon]="icon.error"></fa-icon>
    </div>

    <ng-container *ngIf="scannerReady$ | async">

      <div class="position-absolute video-overlay"
        [style.zIndex]="1"
        [style.width.px]="overlayDimensions.width"
        [style.height.px]="overlayDimensions.height"
        [style.left.px]="overlayDimensions.x"
        [style.top.px]="overlayDimensions.y">
        <svg class="scan-region-highlight-svg"
          viewBox="0 0 238 238"
          preserveAspectRatio="none"
          style="fill:none;stroke:#e9b213;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;width:100%;height:100%">
          <path d="M31 2H10a8 8 0 0 0-8 8v21M207 2h21a8 8 0 0 1 8 8v21m0 176v21a8 8 0 0 1-8 8h-21m-176 0H10a8 8 0 0 1-8-8v-21"></path>
        </svg>
      </div>

    </ng-container>


    <video #cameraFeed></video>


    <div class="btn btn-sm btn-blank text-danger mt-2"
      (click)="dismiss()"
      *ngIf="modal">
      Cancel
    </div>


  </div>

  `,
  styles: [`
  .card-size {
    aspect-ratio: 2.5/3.5;
  }
  .portrait {
    aspect-ratio: 9/16;
  }
  .landscape {
    aspect-ratio: 16/9;
  }
  video {
    object-fit: cover;
    height: 100%;
  }
  .video-overlay {
    -webkit-animation-name: grow;
    -webkit-animation-duration: 1.125s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: grow;
    -moz-animation-duration: 1.125s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
  }
  @-webkit-keyframes grow {
    0% { transform: scale(1); }
    50% { transform: scale(1.125); }
    100% { transform: scale(1); }
  }
  `]
})
export class QRScannerComponent implements AfterViewInit, OnDestroy {
  @Input() modal: boolean = true
  @Input() orientation: 'landscape' | 'portrait' | 'card-size' = 'card-size'
  @Input() highlightScanRegion: boolean = true
  @Input() highlightCodeOutline: boolean = true
  @Input() rounded: boolean = false

  @Output() read = new EventEmitter()

  @ViewChild('cameraFeed', { static: true }) cameraFeed: ElementRef<HTMLVideoElement>
  @ViewChild('contentWrapper', { static: true }) contentWrapper: ElementRef<HTMLDivElement>
  @ViewChild('error', { static: true }) error: ElementRef<HTMLDivElement>
  @ViewChild('success', { static: true }) success: ElementRef<HTMLDivElement>

  private qrScanner: QrScanner = null
  private scanBeep = new Audio('assets/audio/scanner-beep.wav')
  private blockScanning: boolean = true

  public scannerReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null)

  public icon = {
    success: faCheckCircle,
    error: faExclamationCircle,
    stopped: faStopCircle,
  }

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnDestroy(): void {
    if (this.qrScanner !== null) {
      this.qrScanner.destroy()
    }
  }

  ngAfterViewInit(): void {
    if (this.qrScanner === null) {

      const camera = this.cameraFeed.nativeElement as HTMLVideoElement

      // create the scanner
      this.qrScanner = new QrScanner(
        camera,
        (result: QrScanner.ScanResult) => this.onHandleResult(result),
        {
          highlightScanRegion: false, //this.highlightScanRegion,
          highlightCodeOutline: false, //this.highlightCodeOutline,
          maxScansPerSecond: 10,
          // onDecodeError: (error) => {
          //   console.log('[QRScannerComponent] --> onDecodeError', error)
          // },
        }
      )

      // read both regular and inverted codes
      this.qrScanner.setInversionMode('both')
    }

    // enable scanning
    this.blockScanning = false

    // start the scanner
    this.qrScanner.start()
      .then(() => {
        this.scannerReady$.next(true)
      })
      .catch((error) => {
        console.log('[QRScannerComponent] --> onDecodeError', error)
      })


  }

  public dismiss(): void {
    this.activeModal.dismiss()
  }

  private onHandleResult(result: QrScanner.ScanResult): void {
    if (this.modal) {
      this.qrScanner.destroy()
      this.activeModal.close(result.data)
    }
    else {

      if (this.blockScanning) { return }
      else {
        console.log('[QRScannerComponent] --> adding scanner block')
        this.blockScanning = true
      }

      // start timer to reset state to reading
      setTimeout(() => {
        console.log('[QRScannerComponent] --> removing scanner block')
        this.blockScanning = false
        this.success.nativeElement.style.display = 'none'
        this.error.nativeElement.style.display = 'none'
      }, 2000)

      // get data from the read
      const data = result.data.split('.')
      const property = data[0]
      const value = data[1]

      console.log('[QRScannerComponent] --> read data', { data, property, value, })

      // check type of read
      if (property === 'playerDocId') {

        this.scanBeep.play()

        console.log('[QRScannerComponent] --> player document id read')

        if (value.length === 36) {
          console.log('[QRScannerComponent] --> player document id VALID')
          this.success.nativeElement.style.display = 'flex'
          this.read.emit(value)
        }
        else {
          this.error.nativeElement.style.display = 'flex'
          console.log('[QRScannerComponent] --> player document id NOT VALID')
        }

      }
    }
  }

  public get overlayDimensions(): QrScanner.ScanRegion {
    const square = Math.min(...[
      this.contentWrapper.nativeElement.getBoundingClientRect().width as number,
      this.contentWrapper.nativeElement.getBoundingClientRect().height as number,
    ]) * 0.666
    const region: QrScanner.ScanRegion = {
      width: square,
      height: square,
      x: (this.contentWrapper.nativeElement.getBoundingClientRect().width / 2) - (square / 2),
      y: (this.contentWrapper.nativeElement.getBoundingClientRect().height / 2) - (square / 2),
      downScaledHeight: 200,
      downScaledWidth: 200,
    }
    return region
  }

}

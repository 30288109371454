<div class="d-flex align-items-center gap-1 p-1 pe-0">

  <div class="ql-formats d-flex align-items-center">
    <button (click)="onButtonPressed('toggle-format')"
      [ngbTooltip]="tipContentFormat"
      [tooltipClass]="tooltipClass"
      [openDelay]="openDelay"
      [container]="container"
      [placement]="placement"
      [popperOptions]="popperOptions"
      class="ql-button btn btn-sm px-1 position-relative"
      [ngClass]="{ 'ql-active' : showFormatting }">
      <fa-icon class="ql-button-icon svg-evenodd" [ngClass]="{ 'selected' : showFormatting }" [fixedWidth]="true" [icon]="icon.format"></fa-icon>
    </button>
    <button (click)="onButtonPressed('upload-image')"
      [ngbTooltip]="tipInsertImage"
      [openDelay]="openDelay"
      [container]="container"
      [placement]="placement"
      [popperOptions]="popperOptions"
      [disabled]="disableImageUpload || !imageUploadEnabled"
      class="ql-button btn btn-sm px-1">
      <fa-icon class="ql-button-icon" [fixedWidth]="true" [icon]="icon.image"></fa-icon>
    </button>
    <tolaria-emoji-picker-button (onEmojiSelected)="onEmojiSelected($event)"
      [ngbTooltip]="tipContentEmoji"
      [openDelay]="openDelay"
      [container]="container"
      [placement]="placement"
      [popperOptions]="popperOptions"
      [buttonIcon]="icon.emoji"
      [buttonHoverIcon]="icon.wink"
      [editor]="editor"
      buttonStyle="btn btn-sm ql-button"
      [tooltipDisabled]="true"
      iconStyle="ql-button-icon">
    </tolaria-emoji-picker-button>
    <button (click)="onButtonPressed('mention-someone')"
      [ngbTooltip]="tipContentMention"
      [openDelay]="openDelay"
      [container]="container"
      [placement]="placement"
      [popperOptions]="popperOptions"
      class="ql-button btn btn-sm px-1">
      <fa-icon class="ql-button-icon svg-evenodd" [fixedWidth]="true" [icon]="icon.at"></fa-icon>
    </button>
  </div>

  <div class="toolbar-separator"></div>

  <div class="ql-formats d-flex align-items-center">
    <button (click)="onButtonPressed('insert-card')"
      [ngbTooltip]="tipContentInsertCard"
      [openDelay]="openDelay"
      [container]="container"
      [placement]="placement"
      [popperOptions]="popperOptions"
      class="ql-button btn btn-sm px-1">
      <fa-icon class="ql-button-icon" [fixedWidth]="true" [icon]="icon.card"></fa-icon>
    </button>
    <button (click)="onButtonPressed('insert-deck')"
      [ngbTooltip]="tipContentInsertDeck"
      [openDelay]="openDelay"
      [container]="container"
      [placement]="placement"
      [popperOptions]="popperOptions"
      class="ql-button btn btn-sm px-1">
      <fa-icon class="ql-button-icon" [fixedWidth]="true" [icon]="icon.deck"></fa-icon>
    </button>
    <button (click)="onButtonPressed('insert-tourney')"
      [ngbTooltip]="tipContentInsertTourney"
      [openDelay]="openDelay"
      [container]="container"
      [placement]="placement"
      [popperOptions]="popperOptions"
      class="ql-button btn btn-sm px-1">
      <fa-icon class="ql-button-icon" [fixedWidth]="true" [icon]="icon.tourney"></fa-icon>
    </button>
    <div class="toolbar-separator"></div>
    <div class="ql-formats d-flex align-items-center">
      <button (click)="onButtonPressed('toggle-silent-mode')"
        *ngIf="composer"
        [ngbTooltip]="tipContentSilentMode"
        [openDelay]="openDelay"
        [container]="container"
        [placement]="placement"
        [popperOptions]="popperOptions"
        class="ql-button btn btn-sm px-1"
        [ngClass]="{ 'ql-active' : isSilentMode }">
        <fa-icon class="ql-button-icon" [fixedWidth]="true" [icon]="icon.silent"></fa-icon>
      </button>
    </div>
  </div>

  <ng-container *ngIf="isSilentMode; else justSpacer">
    <div class="flex-grow-1"></div>
    <div class="silent-mode-active me-2 d-flex align-items-center gap-1">
      <span>🤫</span>
      <span class="text-secondary text-smaller">Sending silent</span>
    </div>
  </ng-container>
  <ng-template #justSpacer>
    <div class="flex-grow-1"></div>
  </ng-template>


  <ng-container *ngIf="composer; else editMessage">
    <button (click)="onButtonPressed('send-message')"
      class="btn btn-sm px-2 py-0"
      [ngClass]="{
        'btn-blank text-secondary' : isEmpty,
        'btn-warning' : isSilentMode && !isEmpty,
        'btn-primary' : !isSilentMode && !isEmpty && !disableSend,
        'border-0' : disableSend
      }"
      [ngbTooltip]="tipContentSend"
      [openDelay]="openDelay"
      [container]="container"
      [popperOptions]="popperOptions"
      [disabled]="disableSend"
      placement="top-right">
      <fa-icon class="ql-button-icon" [icon]="icon.send" [fixedWidth]="true"></fa-icon>
    </button>
  </ng-container>
  <ng-template #editMessage>
    <div class="d-flex align-items-center gap-2">
      <button class="btn btn-sm btn-outline-secondary px-2 py-0" (click)="onButtonPressed('cancel-edit')">Cancel</button>
      <button class="btn btn-sm btn-success px-2 py-0" (click)="onButtonPressed('save-message')">Save</button>
    </div>
  </ng-template>

</div>




<ng-template #tipContentFormat>
  <div class="d-flex flex-column gap-2 p-2">
    <div class="fw-bold">{{ showFormatting ? 'Hide formatting' : 'Show formatting' }}</div>
  </div>
</ng-template>
<ng-template #tipInsertImage>
  <div class="d-flex flex-column gap-2 p-2">
    <div class="fw-bold">Insert image</div>
  </div>
</ng-template>
<ng-template #tipContentEmoji>
  <div class="d-flex flex-column gap-2 p-2">
    <div class="fw-bold">Emoji</div>
  </div>
</ng-template>
<ng-template #tipContentMention>
  <div class="d-flex flex-column gap-2 p-2">
    <div class="fw-bold">Mention someone</div>
  </div>
</ng-template>
<ng-template #tipContentSilentMode>
  <div class="d-flex flex-column gap-2 p-2">
    <div class="fw-bold">{{ isSilentMode ? 'Turn off silent mode' : 'Turn on silent mode' }}</div>
    <div class="text-small">Send a messsage without showing it to the other members of this message group</div>
  </div>
</ng-template>
<ng-template #tipContentInsertCard>
  <div class="d-flex flex-column gap-2 p-2">
    <div class="fw-bold">Insert card</div>
  </div>
</ng-template>
<ng-template #tipContentInsertDeck>
  <div class="d-flex flex-column gap-2 p-2">
    <div class="fw-bold">Insert deck</div>
  </div>
</ng-template>
<ng-template #tipContentInsertTourney>
  <div class="d-flex flex-column gap-2 p-2">
    <div class="fw-bold">Insert tournament info</div>
  </div>
</ng-template>
<ng-template #tipContentSend>
  <div class="d-flex flex-column gap-2 p-2">
    <div class="fw-bold">Send now</div>
    <div class="d-flex align-items-center gap-2">
      <span class="text-secondary">Press</span>
      <div class="keyboard-key">Return</div>
    </div>
  </div>
</ng-template>

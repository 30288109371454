<div class="card border-secondary border-opacity-50 rounded min-width-100 h-100">
  <div class="card-header no-hover d-flex align-items-center flex-grow-0">
    <div class="flex-grow-1 text-larger">Ongoing Matches</div>
    <!-- <div class="badge rounded-pill text-bg-secondary" [innerText]="matches.length"></div> -->
  </div>
  <div class="card-body no-hover scrollable p-0 flex-grow-1">
    <ng-container *ngIf="onlineMatches$ | async as matches">
      <ng-container *ngFor="let item of matches">
        <app-match-info-item [matchDocId]="item.matchDocId"></app-match-info-item>
      </ng-container>
    </ng-container>
  </div>
</div>

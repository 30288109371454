import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {
  @Input() title: string;
  @Input() body: string;
  @Input() buttonTextCancel: string = 'Cancel';
  @Input() buttonTextOk: string = 'Ok';

  constructor(public activeModal: NgbActiveModal) { }


}

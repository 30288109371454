import { applicationIcon } from 'src/assets/font-icons/tolaria-icon';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { AuthService } from 'src/app/services/auth.service';
import { IEventPaymentMeta } from 'src/app/payment/payment.service';
import { take, takeUntil } from 'rxjs/operators';
import { faInfo, faReply, faDonate, faCheck, faInfoCircle, faMoneyBillAlt, faQuestionCircle, faExclamationTriangle, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from 'src/app/payment/payment.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';
import _ from 'lodash';
import { PaymentFactor, StripePayoutType, PaymentType, IBalanceTemplateMeta, StripePayoutStatus } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-event-to-payments',
  templateUrl: './event-to-payments.component.html',
  styleUrls: ['./event-to-payments.component.css']
})
export class EventToPaymentsComponent implements OnInit, OnDestroy {
  @Input() event$: Observable<IEventDetails>

  private componentDestroyed$ = new Subject<boolean>();
  private readyToInit$ = new Subject<boolean>();
  public data$: BehaviorSubject<ITemplateMeta> = new BehaviorSubject<ITemplateMeta>(null)
  public eventData$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  private balance$: BehaviorSubject<IBalanceTemplateMeta> = new BehaviorSubject<IBalanceTemplateMeta>(null)

  public refundedIcon = faReply
  public infoIcon = faInfo
  public donationIcon = faDonate
  public paidIcon = faCheck
  public roundInfoIcon = faInfoCircle
  public paypalIcon = faPaypal
  public tolariaIcon = applicationIcon
  public cashIcon = faMoneyBillAlt
  public questionIcon = faQuestionCircle
  public warningIcon = faExclamationTriangle
  public pendingIcon = faHourglassHalf

  public payoutConfirmation: boolean = false
  public requestingPayout: boolean = false

  constructor(
    private payment: PaymentService,
    private modal: NgbActiveModal,
    private auth: AuthService,
  ) {
  }

  ngOnInit(): void {

    this.event$.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(async (event) => {
        event.details.registrationFee.organizerDefaultCurrency = await this.payment.getEventOrganizerDefaultCurrency(event.createdByUid)
        this.eventData$.next(event)
        this.readyToInit$.next(true)
      })

    this.readyToInit$.pipe(take(1)).subscribe(() => this.init())

  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  private init(): void {
    if (this.eventData$.getValue() === undefined || this.eventData$.getValue() === null) return

    this.payment.getEventCharges(this.eventData$.getValue().docId).then(async (res) => {

      const data: ITemplateMeta = {
        charges: res,
        total: {
          amount: _.sumBy(res, 'amount.total').toFixed(2),
          fee: (_.sumBy(res, 'amount.newFee')).toFixed(2),
          registrationFees: _.sumBy(res, 'amount.registrationFee').toFixed(2),
          registrationFeesCost: _.sumBy(res, 'amount.registrationFeeCost').toFixed(2),
          charity: _.sumBy(res, 'amount.charity').toFixed(2),
          charityCost: _.sumBy(res, 'amount.charityCost').toFixed(2),
          refunded: _.sumBy(res, 'amount.refunded').toFixed(2),
          net: _.sumBy(res.filter(c => c.paymentType === PaymentType.TOLARIA), 'amount.net').toFixed(2),
          donation: _.sumBy(res, 'amount.donation').toFixed(2),
          transferred: _.sumBy(res, 'amount.transferred').toFixed(2),
          // amount: _.sumBy(res.filter(c => c.paymentType === PaymentType.TOLARIA), 'amount.total').toFixed(2),
          // fee: (_.sumBy(res.filter(c => c.paymentType === PaymentType.TOLARIA), 'amount.newFee')).toFixed(2),
          // registrationFees: _.sumBy(res.filter(c => c.paymentType === PaymentType.TOLARIA), 'amount.registrationFee').toFixed(2),
          // registrationFeesCost: _.sumBy(res.filter(c => c.paymentType === PaymentType.TOLARIA), 'amount.registrationFeeCost').toFixed(2),
          // charity: _.sumBy(res.filter(c => c.paymentType === PaymentType.TOLARIA), 'amount.charity').toFixed(2),
          // charityCost: _.sumBy(res.filter(c => c.paymentType === PaymentType.TOLARIA), 'amount.charityCost').toFixed(2),
          // refunded: _.sumBy(res.filter(c => c.paymentType === PaymentType.TOLARIA), 'amount.refunded').toFixed(2),
          // net: _.sumBy(res.filter(c => c.paymentType === PaymentType.TOLARIA), 'amount.net').toFixed(2),
          // donation: _.sumBy(res.filter(c => c.paymentType === PaymentType.TOLARIA), 'amount.donation').toFixed(2),
          // transferred: _.sumBy(res.filter(c => c.paymentType === PaymentType.TOLARIA), 'amount.transferred').toFixed(2),
        },
        currency: res[0].currency,
      }

      this.data$.next(data)
    })

    this.getStripeAccountBalance()

  }

  private async getStripeAccountBalance() {

    // get account balance if user is the event creator
    if (this.isEventCreator) {

      this.payment.getBalance()
        .then(balance => {
          // console.log(balance)
          this.balance$.next(balance)
        })
        .catch(error => console.log(error))

    }

    // get account balance if user is administrator
    if (this.auth.user.role === 'admin') {

      // need to get the creators stripe account id
      this.payment.getStripeAccountIdByPlayerUid(this.eventData$.getValue().createdByUid)
        .then((accountId: string) => {
          // get the balance
          this.payment.getBalance(accountId)
            .then(balance => {
              console.log(balance)
              this.balance$.next(balance)
            })
            .catch(error => console.log(error))
        })
        .catch((error) => console.log(error))


    }

  }

  public close(): void {
    this.modal.close()
  }

  public requestPayout(): void {
    this.requestingPayout = true
    this.payment.createPayout({
      payoutType: StripePayoutType.EVENT,
      eventDocId: this.eventData$.getValue().docId,
      amount: _.sumBy(this.data$.getValue().charges.filter(c => c.paymentType === PaymentType.TOLARIA), 'amount.net'),
      currency: this.data$.getValue().currency,
    })
      .then(() => {
        this.requestingPayout = false
        this.payoutConfirmation = false
      })
      .catch(() => {
        this.requestingPayout = false
        this.payoutConfirmation = false
      })
  }

  public get disablePayout(): boolean {
    return !this.payoutConfirmation || this.requestingPayout || !this.balanceCoversPayout
  }

  public get payoutDone(): boolean {
    return this.eventData$.getValue().details?.registrationFee?.payout?.paid
  }

  public get payoutAmountText(): string {
    return (this.eventData$.getValue().details.registrationFee.payout.amount / PaymentFactor).toFixed(2)
  }

  public get payoutCurrency(): string {
    return this.eventData$.getValue().details.registrationFee.payout.currency.toUpperCase()
  }

  public get payoutTimestamp(): number {
    return this.eventData$.getValue().details.registrationFee.payout.created
  }

  public get charityExtraEnabled(): boolean {
    return this.eventData$.getValue().details.registrationFee.charityExtra
  }

  public get isEventCreator(): boolean {
    return this.eventData$.getValue().createdByUid === this.auth.user.uid
  }

  public get isAdmin(): boolean {
    return this.auth.user.role === 'admin'
  }

  public get chargeIcon(): any {
    return {
      'TOLARIA': this.tolariaIcon,
      'PAYPAL': this.paypalIcon,
      'CASH': this.cashIcon,
      'OTHER': this.questionIcon,
    }
  }

  public get chargeTooltip(): any {
    return {
      'TOLARIA': 'Paid with Tolaria Payment',
      'PAYPAL': 'Paid with PayPal (external payment)',
      'CASH': 'Paid by Cash (external payment)',
      'OTHER': 'Other payment used (external payment)',
    }
  }

  public get currentBalance(): string {
    const currency = this.eventData$.getValue().details.registrationFee.currency.toUpperCase()
    const available = this.balance$.getValue().currency[currency].available
    return `Available balance: ${(available).toFixed(2)} ${currency}`
  }

  public get balanceCoversPayout(): boolean {

    const eventCurrency = this.eventData$.getValue().details.registrationFee.currency.toUpperCase()
    const organizerDefaultCurrency = this.eventData$.getValue().details.registrationFee.organizerDefaultCurrency.toLocaleUpperCase()
    const exchangeRate = this.payment.exchangeRates$.getValue()[organizerDefaultCurrency] / this.payment.exchangeRates$.getValue()[eventCurrency]

    if (this.balance$.getValue() === null) { return false }
    else if (eventCurrency !== organizerDefaultCurrency) {
      // const eventCurrencyExchange =
      const data = this.data$.getValue()
      const payout = _.sumBy(data.charges.filter(c => c.paymentType === PaymentType.TOLARIA), 'amount.net') * exchangeRate
      // console.log({
      //   eventCurrency,
      //   organizerDefaultCurrency,
      //   data,
      //   payout,
      //   event: this.eventData$.getValue().details.registrationFee,
      //   balance: this.balance$.getValue(),
      //   currencies: this.payment.exchangeRates$.getValue(),
      //   exchangeRate: this.payment.exchangeRates$.getValue()[organizerDefaultCurrency] / this.payment.exchangeRates$.getValue()[eventCurrency]
      // })
      return payout <= this.balance$.getValue().currency[organizerDefaultCurrency].available
    }
    else {
      const data = this.data$.getValue()
      const payout = _.sumBy(data.charges.filter(c => c.paymentType === PaymentType.TOLARIA), 'amount.net')
      return payout <= this.balance$.getValue().currency[this.eventData$.getValue().details.registrationFee.currency.toUpperCase()].available
    }
  }

  public get payoutStatus(): any {
    const event = this.eventData$.getValue()
    const res = {
      text: event.details.registrationFee?.payout
        ? event.details.registrationFee.payout.status === StripePayoutStatus.PAID_OUT
          ? 'PAID'
          : 'PENDING'
        : null,
      class: event.details.registrationFee?.payout
        ? event.details.registrationFee.payout.status === StripePayoutStatus.PAID_OUT
          ? 'badge-success'
          : 'badge-primary'
        : null,
      icon: event.details.registrationFee?.payout
        ? event.details.registrationFee.payout.status === StripePayoutStatus.PAID_OUT
          ? this.paidIcon
          : this.pendingIcon
        : null,
    }

    return res
  }

}

interface ITemplateMeta {
  charges: IEventPaymentMeta[],
  total: {
    amount: string,
    fee: string,
    registrationFees: string
    registrationFeesCost: string
    charity: string
    charityCost: string
    refunded: string
    net: string
    donation: string
    transferred: string
  },
  currency: string
}

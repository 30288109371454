import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PairingsViewService {

  public customText: string = ''
  public image: string = ''
  public youtubeUrl: string = ''
  public infoView = {
    customText: false,
    customImage: false,
    embedTwitch: false,
    youtubePlaylist: false,
  }
  public editWidget = true

  constructor() { }





}

import { IEventDetails, ISingleBracketMatrixMatch, ISingleBracketMatrixRound } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-swiss-playoff',
  templateUrl: './event-swiss-playoff.component.html',
  styleUrls: ['./event-swiss-playoff.component.css']
})
export class EventSwissPlayoffComponent implements OnInit {
  @Input() eventDetails: IEventDetails;
  @Input() eventDocId: string;
  @Input() bracketMatrix: Array<ISingleBracketMatrixRound>;
  @Input() isOrganizer: boolean;

  public selectedPathToVictory: ISingleBracketMatrixMatch = null;

  constructor() { }

  ngOnInit(): void {
    console.log('EventSwissPlayoffComponent --> ', this.bracketMatrix)
  }

  public get isTeamSwiss(): boolean {
    return this.eventDetails !== undefined && this.eventDetails !== null
      ? this.eventDetails.details.structure.swiss.teams !== undefined
        ? this.eventDetails.details.structure.swiss.teams
        : false
      : false
  }

  getRoundHeaderText(round: ISingleBracketMatrixRound, numRounds: number): string {
    if (round.round === numRounds) {
      return 'Finals';
    }
    else if (round.round === numRounds - 1) {
      return 'Semifinals';
    }
    else if (round.round === numRounds - 2) {
      return 'Quarter finals';
    }
    else {
      return 'Round ' + round.round;
    }
  }
}

import { AuthService } from 'src/app/services';
import { Component, Input } from '@angular/core';
import { tiskelionIcon } from 'src/assets/font-icons/tiskelion-icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ManaParserPipe } from 'src/app/pipes/mana-parser.pipe';
import { CommonModule } from '@angular/common';
import { MessageItem } from 'src/app/private/social/messages/services/message-list.service';
@Component({
  selector: 'app-scryfall-rules',
  templateUrl: './scryfall-rules.component.html',
  styleUrls: ['../messages-style.css'],
  standalone: true,
  imports: [
    FontAwesomeModule,
    ManaParserPipe,
    CommonModule,
  ]
})
export class ScryfallRulesComponent {
  @Input() messageDoc: MessageItem;
  faRobot = tiskelionIcon;
  constructor(public auth: AuthService) { }
  getDate(timestamp: number) {
    return new Date(timestamp * 1000);
  }
}

<section class="p-2 d-flex flex-row">

  <ng-container *ngIf="league$ | async as league">

    <div class="card card-xlarge me-2">
      <div class="card-header d-flex align-items-center">
        <span class="flex-grow-1" [innerHtml]="league.name"></span>
        <div class="btn btn-sm {{ managingLeague ? 'btn-main' : 'btn-blank text-secondary' }}" (click)="managingLeague = !managingLeague" *ngIf="league.isOrganizer">Manage</div>
      </div>
      <div class="card-body no-hover scrollable">

        <ng-container *ngIf="!managingLeague">
          <div class="h3">Information</div>
          <div class="text-secondary" [innerHtml]="league.description | linky: { newWindow: true }"></div>

          <hr>

          <div class="h3 mt-5">Events</div>
          <div class="event-list-item py-2 d-flex align-items-center" *ngFor="let event of league.events"
            [routerLink]="'/event-lobby/' + event.docId + '/' + event.name">
            <span class="prefix me-1">
              <fa-icon [icon]="icons.faChevronRight" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="text flex-grow-1">{{ event.name }}</span>
            <span class="text-secondary me-2" *ngIf="event.isEnded">Ended</span>
            <span class="link" ngbTooltip="Go to Event lobby" container="body">
              <fa-icon [icon]="icons.faExternalLinkAlt" [fixedWidth]="true"></fa-icon>
            </span>
          </div>

          <div class="h3 mt-5">Point Structure</div>
          <div class="league-point-item py-2 pe-3 d-flex align-items-center" *ngFor="let point of league.pointStructure">

            <div class="badge text-bg-dark text-large me-2">
              {{ point.points }}p
            </div>

            <ng-container *ngIf="point.type === 'RANK'">
              <ng-container *ngIf="!point.isRange; else rangePlacement">
                {{ point.rankTo + point.rankToSuffix}} place in an event
              </ng-container>
              <ng-template #rangePlacement>
                {{ point.rankFrom + point.rankFromSuffix }} - {{ point.rankTo + point.rankToSuffix}} place in an event
              </ng-template>
            </ng-container>

            <ng-container *ngIf="point.type !== 'RANK'">
              <span [innerHtml]="point.type === 'CUSTOM' ? point.customExplaination : translations[point.type].explaination"></span>
            </ng-container>

          </div>
        </ng-container>


        <ng-container *ngIf="managingLeague">
          <div class="mb-5 d-flex flex-row align-items-center justify-content-start">
            <button class="btn btn-sm btn-outline-secondary" (click)="openConfiguration(league)"
              [disabled]="!league.isOrganizer">
              <fa-icon [icon]="icons.faEdit" [fixedWidth]="true" class="me-2"></fa-icon>Edit
            </button>
            <button class="btn btn-sm btn-outline-secondary ms-1" (click)="addManualPointToPlayer(league)"
              [disabled]="!league.isOrganizer">
              <fa-icon [icon]="icons.faPlus" [fixedWidth]="true" class="me-2"></fa-icon>Manual Point
            </button>
            <button class="btn btn-sm btn-outline-secondary ms-1" (click)="openLinkEventModal(league)"
              [disabled]="!league.isOrganizer">
              <fa-icon [icon]="icons.faLink" [fixedWidth]="true" class="me-2"></fa-icon>Link Event
            </button>
          </div>

          <div class="h5 mt-5">Linked Events</div>
          <div class="manual-points-wrapper">

            <div class="list-item d-flex align-items-center" *ngFor="let event of league.events; let i = index">
              <div class="flex-grow-1">
                {{ event.name }}
              </div>
              <span class="trash-icon ms-2 flex-grow-1 text-end" (click)="unlinkEvent(league, event.docId)">
                <fa-icon [fixedWidth]="true" [icon]="icons.faTrash"></fa-icon>
              </span>
            </div>

          </div>

          <div class="h5 mt-3">Manual Points</div>
          <div class="manual-points-wrapper">

            <div class="list-item d-flex align-items-center" *ngFor="let point of league.manualPoints; let i = index"
              [ngbTooltip]="point.comment" container="body">
              <div class="point-wrapper" [style.minWidth.rem]="3">
                <span class="badge rounded-pill text-bg-dark">{{ point.points }}p</span>
              </div>
              <div class="flex-grow-1">
                <app-player-display-name [playerDocId]="point.playerDocId"></app-player-display-name>
              </div>
              <span class="trash-icon ms-2 flex-grow-1 text-end" (click)="removeManualPoint(league, i)">
                <fa-icon [fixedWidth]="true" [icon]="icons.faTrash"></fa-icon>
              </span>
            </div>

          </div>

          <div class="danger-zone-wrapper mt-5 p-2">

            <div class="h5 text-red">DANGER ZONE</div>

            <div class="form-inline">
              <input type="text" class="form-control flex-grow-1 danger-input" [(ngModel)]="deleteLeagueConfirmation" placeholder="Enter the league name">
              <button class="btn btn-sm btn-danger ms-2" [disabled]="league.name !== deleteLeagueConfirmation" (click)="deleteLeague(league.docId)">Delete League</button>
            </div>

          </div>

        </ng-container>

      </div>
    </div>

    <app-league-ranking></app-league-ranking>

  </ng-container>

</section>

<ng-template #addManualPointModal let-modal>
  <div class="modal-header">Add Point to Player</div>
  <div class="modal-body">
    <div class="form-group">
      <label>Player</label>
      <ng-container *ngIf="newManualPoint.playerDocId === null; else newManualPointPlayerName">
        <app-tolaria-player-search (playerDocId)="newManualPoint.playerDocId = $event"></app-tolaria-player-search>
      </ng-container>
      <ng-template #newManualPointPlayerName>
        <div class="d-flex flex-row align-items-center">
          <div class="flex-grow-1">
            <app-player-display-name [playerDocId]="newManualPoint.playerDocId"></app-player-display-name>
          </div>
          <div class="btn btn-sm btn-blank text-red" (click)="newManualPoint.playerDocId = null">
            <fa-icon [icon]="icons.faTrash" [fixedWidth]="true"></fa-icon>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="form-group">
      <label>Points</label>
      <input type="number" class="form-control" [(ngModel)]="newManualPoint.points">
    </div>
    <div class="form-group">
      <label>Comment</label>
      <input type="text" class="form-control" [(ngModel)]="newManualPoint.comment">
    </div>
  </div>
  <div class="modal-footer d-flex align-items-center justify-content-end">
    <button class="btn btn-sm btn-blank text-red" (click)="modal.close()">Cancel</button>
    <button class="btn btn-sm btn-blank" (click)="modal.dismiss('save')" [disabled]="newManualPointFormDisabled">Add
      Point</button>
  </div>
</ng-template>

<ng-template #linkEventModal let-modal>
  <div class="modal-header">Link Event</div>
  <div class="modal-body">
    <div class="form-group">
      <input type="text" class="form-control" [(ngModel)]="searchEvent" placeholder="Start typing to find and event to link">

      <div class="search-results">
        <div class="p-2" *ngFor="let event of eventNames | eventLinkFilter : searchEvent" (click)="linkEvent = event; searchEvent = ''">{{ event.eventName }}</div>
      </div>

      <div class="mt-3">
        <ng-container *ngIf="linkEvent.eventName === null || linkEvent.eventName === ''; else linking">
          <i class="text-secondary">Find and event to link</i>
        </ng-container>
        <ng-template #linking>
          <span class="text-secondary me-2">Link event: </span><span [innerHtml]="linkEvent.eventName"></span>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex align-items-center justify-content-end">
    <button class="btn btn-sm btn-blank text-red" (click)="modal.close()">Cancel</button>
    <button class="btn btn-sm btn-blank" (click)="modal.dismiss('save')" [disabled]="linkEvent.eventDocId === null || linkEvent.eventDocId === ''">Link</button>
  </div>
</ng-template>

import { Component, ElementRef, HostListener, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { GlobalsService } from 'src/app/services'
import { cardFactory } from 'src/assets/font-icons/card-factory'
import { CardFactoryHelperService } from './card-factory-helper.service'
import { PlayerNameService } from 'src/app/services/players/player-name.service'


@Component({
  selector: 'app-card-factory',
  templateUrl: './card-factory.component.html',
  styleUrls: ['./card-factory.component.css']
})
export class CardFactoryComponent {
  @ViewChild('searchInput') searchInput: ElementRef
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.globals.hotkeysDisabled) { return }
    if (event.ctrlKey) {
      switch (event.key) {

        case 'n':
          this.newCard()
          break

        case 'm':
          this.router.navigate(['card-factory/your-cards'])
          break

        case 's':
          this.searchInput.nativeElement.focus()
          break

      }

    }

  }

  public backgroundIcon = cardFactory
  public filterValue: string = ''

  constructor(
    public globals: GlobalsService,
    private readonly playerNames: PlayerNameService,
    private cfHelper: CardFactoryHelperService,
    private router: Router,
  ) {
  }


  public get isHero(): boolean {
    return this.playerNames.currentPlayerIsHero || this.playerNames.currentPlayerIsDrakeGuild
  }

  public newCard(): void {
    this.cfHelper.newCard()
      .then(docId => {
        this.router.navigate(['card-factory/edit', docId])
      })
      .catch((e) => console.log(e))
  }

  public filter(): void {
    this.cfHelper.setFilterString(this.filterValue)
  }


}

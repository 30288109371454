import { Router } from '@angular/router';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import { faCheckCircle, faCircle, faEye, faEyeSlash, faCheckSquare, faExclamationCircle, faCamera, faListAlt, faTablet, faQrcode, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { IEventDetails, IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { BehaviorSubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { EventService, GlobalsService } from 'src/app/services'
import { QRScannerComponent } from 'src/app/components/modals/qrscanner/qrscanner.component';

@Component({
  selector: 'app-event-to-check-in',
  templateUrl: './event-to-check-in.component.html',
  styleUrls: ['./event-to-check-in.component.css']
})
export class EventToCheckInComponent implements OnInit, OnDestroy {
  @ViewChild('qrReader') qrReader: ElementRef<HTMLVideoElement>
  @Input() eventDocId: string
  private timer: any
  private reader: string = ''

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {

    const char = event.code === 'Minus'
      ? '-'
      : event.key
    this._startInput(char)

  }
  private _startInput(char: string): void {

    if (this.timer) { window.clearTimeout(this.timer) }

    if (char === 'Shift') { return }

    if (char === 'Enter') {

      const data = this.reader.split('.')
      const property = data[0]
      const value = data[1]

      console.log({ data, property, value, })

      if (property === 'playerDocId') {

        if (value.length === 36) {
          this._checkIn(value)
        }
        else {
          this._showError()
        }

      }

    }

    this.timer = setTimeout(() => {
      this.reader = ''
    }, 100)

    this.reader += char

  }
  private _checkIn(playerDocId: string): void {
    const player = this.players$.getValue().find(p => p.playerDocId === playerDocId)
    if (player) {
      this.playerCheckInScan = player
    }
    else {
      this._showError()
    }
  }
  private _showError(): void {
    this.nothingFoundOnScan = true
    setTimeout(() => {
      this.nothingFoundOnScan = false
    }, 3000)
  }

  public faEye = faEye
  public faEyeSlash = faEyeSlash
  public faCircle = faCircle
  public faCheckCircle = faCheckCircle
  public checked = faCheckSquare
  public unchecked = faSquare
  public errorScan = faExclamationCircle
  public deckPhoto = faCamera
  public deckList = faListAlt
  public kioskMode = faTablet
  public faQrcode = faQrcode
  public faSearch = faSearch
  public faTimes = faTimes

  public playerCheckInScan: IEventPlayerDetails = null
  public nothingFoundOnScan: boolean = false
  public showCheckedInPlayers = true
  public playerFilter: string = ''
  public extendSearchBar: boolean = false


  public players$: BehaviorSubject<IEventPlayerDetails[]> = new BehaviorSubject<IEventPlayerDetails[]>(null)
  public event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  private destroyed$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private eventService: EventService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private router: Router,
    public globals: GlobalsService,
  ) { }

  ngOnInit(): void {
    this.eventService.getEventPlayersByEventId(this.eventDocId).pipe(takeUntil(this.destroyed$)).subscribe(p => this.players$.next(p))
    this.eventService.getEventById(this.eventDocId).pipe(takeUntil(this.destroyed$)).subscribe(e => this.event$.next(e))
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public get isMobile(): boolean {
    return this.globals.isMobile
  }

  public toggleCheckIn(player: IEventPlayerDetails) {

    if (player.hasCheckedIn) {
      this.eventService.checkOutPlayer(this.eventDocId, player.playerDocId)
    }
    else {
      this.eventService.checkInPlayer(this.eventDocId, player.playerDocId)
    }

    if (this.playerCheckInScan === player) {
      this.playerCheckInScan.hasCheckedIn = !this.playerCheckInScan.hasCheckedIn
    }

    // on mobile the player info should be hidden after check-in/out action
    if (this.isMobile) {
      this.playerCheckInScan = null
    }

  }

  public goToKioskMode(): void {
    this.activeModal.close()
    this.closeCheckIn()
    this.router.navigate(['/event-check-in', this.eventDocId])
  }

  public closeCheckIn() {
    this.eventService.closeCheckIn(this.eventDocId)
    this.activeModal.close()
  }

  public toggleManualCheckIn(state: boolean) {
    this.eventService.toggleManualCheckIn(this.eventDocId, state)
  }

  public get registrationFeeActive(): boolean {
    return this.event$.getValue().details?.registrationFee?.active
  }

  public get playerHasPayed(): boolean {
    if (this.registrationFeeActive) {
      if (
        this.event$.getValue().details.registrationFee?.paidBy !== undefined &&
        this.event$.getValue().details.registrationFee?.paidBy[this.playerCheckInScan.playerDocId] !== undefined &&
        this.event$.getValue().details.registrationFee?.paidBy[this.playerCheckInScan.playerDocId].paid
      ) {
        return true
      }
      if (
        this.event$.getValue().details.registrationFee?.paidByTicket !== undefined &&
        this.event$.getValue().details.registrationFee?.paidByTicket[this.playerCheckInScan.playerDocId] !== undefined
      ) {
        if (!this.event$.getValue().details.registrationFee?.paidByTicket[this.playerCheckInScan.playerDocId].refunded) {
          return true
        }
      }
    }

    return false
  }

  public openQrScanner(): void {

    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    }

    this.modalService.open(QRScannerComponent, modalOptions)
      .result.then(
        (result) => {
          if (result.includes('playerDocId.')) {
            const playerDocId = result.replace('playerDocId.', '')
            this._checkIn(playerDocId)
          }
          else {
            this._showError()
          }
        },
        (dismissed) => { })
  }

}
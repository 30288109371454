import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/services/storage.service'
import { GlobalsService } from 'src/app/services/globals.service'
import { ToastService } from 'src/app/services/toast.service'
import { EventService, AuthService } from 'src/app/services'
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces'
import { IEventDetails, IEventPlayerDetails, IEventTeam, IMatchData } from 'tolaria-cloud-functions/src/_interfaces'
import { ToModalService } from './actions/to-modal.service'
import { takeUntil, pairwise, startWith, map } from 'rxjs/operators'
import { Router } from '@angular/router'
import { AppComponent } from 'src/app/app.component'
import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core'
import { faBars, faCamera, faChevronRight, faCoins, faCopy, faEdit, faEllipsisH, faExclamationTriangle, faEye, faEyeSlash, faFileAlt, faSearch, faTimes, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs'
import { IToAttendingPlayers } from 'src/app/pipes/to-attending-players.pipe';
import { MatchService } from 'src/app/services/match.service';

export interface IEventTeamItem extends IEventTeam {
  players: IEventPlayerDetails[]
}

@Component({
  selector: 'app-organizer',
  templateUrl: './organizer.component.html',
  styleUrls: ['./organizer.component.css']
})
export class OrganizerComponent implements OnInit, OnDestroy {
  @ViewChild('deleteConfirmation') deleteConfirmation: ElementRef
  @Input() eventDoc$: Observable<IEventDetails>
  @Input() eventMatches$: Observable<IMatchData[]>
  @Input() players$: Observable<IEventPlayerDetails[]>
  event: IEventDetails
  private matches$: BehaviorSubject<IMatchData[]> = new BehaviorSubject<IMatchData[]>(null)

  faCopy = faCopy
  faEdit = faEdit
  faEllipsisH = faEllipsisH
  faChevronRight = faChevronRight
  faCamera = faCamera
  faFileAlt = faFileAlt
  faCoins = faCoins
  burgerMenu = faBars
  faTimes = faTimes
  faSearch = faSearch
  faExclamationTriangle = faExclamationTriangle
  faCheckedIn = faUserCheck
  faCalendar = faCalendar

  playerFilter: string = ''
  extendSearchBar: boolean = false
  showPlayersPaid: boolean = true
  showCheckedIn: boolean = true
  showNonAvailable: boolean = true


  toggleActions = {
    players: false,
  }

  panels = {
    advanceActions: false,
    players: false,
    announcement: false,
    organizers: false,
    deckSubmission: false,
    log: false,
    extra: {
      players: {
        attending: true,
        invited: false
      }
    }
  }

  teams$: Observable<IEventTeam[]>
  teamsList$: BehaviorSubject<IEventTeamItem[]> = new BehaviorSubject<IEventTeamItem[]>(null)

  // sendEmail = true
  // sendMessage = true

  componentWasDestroyed$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private auth: AuthService,
    private app: AppComponent,
    private eventService: EventService,
    private router: Router,
    private toastService: ToastService,
    private toModals: ToModalService,
    public globals: GlobalsService,
    private storage: StorageService,
    private modalService: NgbModal,
    private matchService: MatchService,
  ) { }

  public get showExtraPanel(): boolean {
    return this.panels.players
  }

  public tracker(index: number, item: IEventPlayerDetails): string {
    return item.playerDocId
  }

  ngOnInit(): void {
    // console.log(this.panels)
    this.eventDoc$.pipe(
      takeUntil(this.componentWasDestroyed$),
      startWith(0),
      pairwise()
    ).subscribe(([prev, curr]) => {
      // console.log({ prev, curr })

      const previous = typeof prev === 'number' ? null : prev
      const current = typeof curr === 'number' ? null : curr

      // set local event document
      this.event = current
      // this.initMatchWatcher() // removing this as it caused missmatch when triggered automatically

      // check if manual pairings is active
      if (
        current.statusCode === 11 && previous === null ||
        current.statusCode === 11 && previous.statusCode !== 11
      ) {
        // console.log('Manual pairings has been initiated')
        this.toModals.openManualPairingsModal(current.docId, this.eventMatches$, this.players$)
      }

      // check if check-in has started
      if (
        current.checkInHasStarted && previous === null ||
        current.checkInHasStarted && !previous.checkInHasStarted
      ) {
        // console.log('Check-in has started')
        this.toModals.openCheckInModal(current.docId)
      }

      // check if playoff config should open
      if (
        current.statusCode === 6 && previous === null ||
        current.statusCode === 6 && previous.statusCode !== 6
      ) {
        // console.log('Open the playoff config modal')
        this.toModals.openPlayoffConfigModal(current.docId, current.details.structure.isGroup)
      }

      // check if group config should open
      if (
        current.details.structure.isGroup && current.statusCode === 10 && previous === null ||
        current.details.structure.isGroup && current.statusCode === 10 && previous.statusCode !== 10
      ) {
        // console.log('Open the group config modal')
        this.toModals.openGroupConfigModal(current.docId)
      }

      // fetch players if not already fetched
      if (this.players$ === undefined) { this.players$ = this.eventService.getEventPlayersByEventId(this.event.docId) }

      // fetch teams if not already fetched
      if (this.teams$ === undefined) {
        this.teams$ = this.eventService.getEventTeamsByEventId(this.event.docId)

        combineLatest([this.teams$, this.players$]).pipe(takeUntil(this.componentWasDestroyed$)).subscribe(([teams, players]) => {
          // create data array
          const teamItems: IEventTeamItem[] = []
          // loop through the data to create the items
          for (const team of teams) {
            const teamItem: any = team
            teamItem.players = players.filter(p => team.playerDocIds.includes(p.playerDocId)).sort((a, b) => a.name.localeCompare(b.name))
            teamItems.push(teamItem as IEventTeamItem)
          }
          // update behavior subject
          this.teamsList$.next(teamItems)
        })
      }

    })

  }

  ngOnDestroy() {
    this.componentWasDestroyed$.next(true)
  }

  private initMatchWatcher(): void {
    // add all matches to the private observer
    this.eventMatches$.pipe(takeUntil(this.componentWasDestroyed$)).subscribe(m => {
      this.matches$.next(m)

      if (this.event === null || this.event === undefined) { return }
      if (this.event.details.structure.isBracket) { return }

      switch (m.filter(i => i.roundNumber === this.event.activeRound && !i.deleted && !i.isReported).length === 0) {
        case true:
          // only update round to finished if the round is in an active state aka "Waiting for results"
          if (this.event.statusCode === 3) {
            console.log('All matches are reported, lets update the event status...')
            this.eventService.eventUpdateStatus(this.event, 4)
          }
          break

        case false:
          if (this.event.statusCode === 4) {
            console.log('Not all matches are reported, lets update the event status...')
            this.eventService.eventUpdateStatus(this.event, 3)
          }
          break

      }
    })

  }

  eventIs(event: IEventDetails): string {
    if (event.details.structure.isSwiss) { return 'swiss' }
    if (event.details.structure.isGroup) { return 'group' }
    if (event.details.structure.isBatch) { return 'batch' }
    if (event.details.structure.isRoundRobin) { return 'round-robin' }
  }
  isEventCreator(createdByUid: string): boolean {
    return createdByUid === this.auth.user.uid
  }

  get allPanelsClosed() {
    return Object.keys(this.panels).filter(v => this.panels[v] === true).length === 0
  }

  togglePanels(panelName: string, state: boolean) {
    // console.log('toggle panel: ', panelName, state)
    Object.keys(this.panels).forEach((v) => {
      if (v !== 'extra') this.panels[v] = false
    })
    this.panels[panelName] = state
  }

  deleteEvent() {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'sm',
    };
    this.modalService.open(this.deleteConfirmation, modalOptions).result.then(
      (res) => {
        if (res) {
          this.eventService.deleteEvent(this.event.docId).then((response: IPromiseResponse) => {
            if (response.status) {
              this.router.navigate(['events'])
              this.toastService.show(response.text, { classname: 'success-toast', delay: 2000 })
            }
            else {
              this.toastService.show(response.text, { classname: 'error-toast', delay: 5000 })
            }
          })
        }
      }
    )
  }

  copyText(text: string) {
    this.app.copyTextToClipboard(text)
  }

  editEventDetails(event: IEventDetails) {
    this.toModals.openEventEdit(event)
  }

  exportAttendeeList(event: IEventDetails) {
    this.eventService.getEventPlayersByEventIdPromise(event.docId)
      .then((eventPlayers) => this.storage.downloadEventAttendeeList(this.event, eventPlayers))
  }

  exportInviteeList(event: IEventDetails) {
    this.eventService.getEventPlayersByEventIdPromise(event.docId)
      .then((eventPlayers => this.storage.downloadEventInviteeList(event, eventPlayers)))
  }

  public get attendingPlayersConfig(): IToAttendingPlayers {
    return {
      showPaid: this.showPlayersPaid,
      searchString: this.playerFilter,
      event: this.event,
      showCheckedIn: this.showCheckedIn,
    }
  }

  public get isTeamEvent(): boolean {
    return this.event.details.structure.isSwiss && this.event.details.structure.swiss.teams
  }

}

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MagicDeck, IDeckList, ICardMeta, MagicColor, DecksService } from 'src/app/services/decks.service';
import { IPlayerLink } from 'src/app/services/player.service';
import { FontIconsService } from 'src/app/services/font-icons.service';
import { ToastService } from 'src/app/services/toast.service';
import { faChevronLeft, faLayerGroup, faListOl, faSortAmountDown,
  faSortAmountDownAlt, faSearch, faChevronRight, faCircle, faEyeSlash, faEye, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { distinctUntilChanged, takeUntil, tap, map, take, filter, withLatestFrom } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { DraftService, IBoosterCardDocument } from 'src/app/services/draft.service';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { AuthService } from 'src/app/services/auth.service';
import { combineLatest, Observable, Subject, BehaviorSubject } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import * as firestore from 'firebase/firestore'
import { ConstraintsLevel, MediaStreamsService } from 'src/app/services/media-streams.service';
import { WebRtcHelperService } from '../../events/match-room/webrtc-helper.service';
import { IOnlineUser } from '../../events/match-room/match-room.component';
import { IBooster, IBoosterCard, IDraft, IPick } from 'tolaria-cloud-functions/src/_interfaces';
import { faPlusSquare, faMinusSquare, faCheckCircle, faSave, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { IDraftPickPipeOptions } from 'src/app/pipes/draft-picks.pipe';
import { IDraftRotisseriePipeConfig } from 'src/app/pipes/draft-rotisserie-cards.pipe';
import { v4 as uuidv4 } from 'node_modules/uuid';
import _ from 'lodash';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { TolariaWysiwygOutput } from 'src/app/private/social/messages/components/tolaria-wysiwyg/tolaria-wysiwyg.interfaces';
import { MessageSenderService } from 'src/app/private/social/messages/services/message-sender.service';
export interface IDraftUser {
  webRtcPeerId: string;
  playerDocId: string;
  playerUid: string;
  mediaStream: MediaStream;
}

export interface IOnlineUserMeta extends IOnlineUser {
  draftStatusCode: number;
  isActivePlayer: boolean;
  displayName: string;
}

@Component({
  selector: 'app-draft-room',
  templateUrl: './draft-room.component.html',
  styleUrls: ['./draft-room.component.css']
})
export class DraftRoomComponent implements OnInit, OnDestroy {
  @ViewChild('deckCreationConfirmation') deckCreationConfirmation: ElementRef;

  private currentDraftStatusCode: number;

  private dbRef: AngularFireList<IOnlineUser>;
  private draftDocId: string;
  private draftType: string;
  private _draftDoc: IDraft;
  public draftDoc$: Observable<IDraft>;
  public boosters$: Observable<IBooster[]>;
  public cards$: Observable<IBoosterCardDocument[]>;
  public activeUsersInRoom$: Observable<IOnlineUserMeta[]>;
  public isAttending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public playerInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public databaseInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public draftInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private componentWasDestroyed$ = new Subject<boolean>();
  private webRtcInitialized$: Observable<boolean> = this.webRtcHelper.initialized$.pipe(
    filter((isInitialized) => isInitialized),
    tap(() => console.log('WebRTC is INITIALIZED'))
  );
  public localStream$: Observable<MediaStream> = this.streamService.localStream$.pipe(
    filter((stream: MediaStream) => stream !== undefined)
  );
  public activeStreams = new Map<string, MediaStream>();
  public selectedCardId = '';
  public selectedPlayerDocId: string;
  public activeBooster$: Observable<IBooster>;
  public picks$: Observable<IPick[]>;
  public playerPicks$: Observable<IPick[]>;
  public draftDirection: string;
  public isLive: boolean;
  public showCards = false;
  public showPicks = true;

  public icons = {
    faListOl: faListOl,
    faChevronLeft: faChevronLeft,
    faChevronRight: faChevronRight,
    faLayerGroup: faLayerGroup,
    faSortAmountDownAlt: faSortAmountDownAlt,
    faSortAmountDown: faSortAmountDown,
    faMinusSquare: faMinusSquare,
    faPlusSquare: faPlusSquare,
    faSearch: faSearch,
    faCircle: faCircle,
    faEyeSlash: faEyeSlash,
    faEye: faEye,
    faCheckCircle: faCheckCircle,
    faSave: faSave,
    faTrashAlt: faTrashAlt,
    faBriefCase: faBriefcase,
  }

  public cardZoomLevel = 1;
  public pickOptions: IDraftPickPipeOptions = {
    isAscending: true,
    sortBy: 'pickOrder',
    groupCards: false,
    colors: {
      white: true,
      blue: true,
      black: true,
      red: true,
      green: true,
      colorless: true
    }
  }
  public showPicksPanel = false;
  public pipeRefresher = 0;
  public showPreview = false;
  public previewCard: IBoosterCard;

  public rotisserieFilter: IDraftRotisseriePipeConfig = {
    search: '',
    textSearch: false,
    colors: {
      white: true,
      blue: true,
      black: true,
      red: true,
      green: true,
      colorless: true
    },
    types: {
      sorcery: true,
      instant: true,
      land: true,
      enchantment: true,
      artifact: true,
      creature: true
    },
    isPicked: true,
    onlyShowSaved: false
  }

  public myPlayerDetails$ = new Subject<IPlayerLink>()
  public draftDeck$: Observable<IDeckList[]>;

  constructor(
    public auth: AuthService,
    private afAuth: AngularFireAuth,
    private afDb: AngularFireDatabase,
    private draftService: DraftService,
    private route: ActivatedRoute,
    private readonly streamService: MediaStreamsService,
    private readonly webRtcHelper: WebRtcHelperService,
    private toastService: ToastService,
    public fontIcons: FontIconsService,
    private playerNames: PlayerNameService,
    private deckService: DecksService,
    private modalService: NgbModal,
    private sender: MessageSenderService,
  ) {

    this.selectedPlayerDocId = this.auth.user.playerId;

    // get the draft document id from the url params
    this.draftType = this.route.snapshot.params.draftType;
    this.draftDocId = this.route.snapshot.params.draftDocId;

    // fetch the draft document
    this.draftDoc$ = this.draftService.getDraftDocObserver(this.draftDocId);

    // fetch decks connected to the draft
    this.draftDeck$ = this.deckService.getDraftDeckByDraftDocId(this.draftDocId);
  }

  public replyTo$ = new BehaviorSubject<null>(null)
  private readyToInit$ = new Subject<boolean>()
  ngOnInit(): void {
    this.playerNames.serviceReady$.pipe(takeUntil(this.readyToInit$)).subscribe(state => {
      if (state) {
        this.readyToInit$.next(true)
        this.init()
      }
    })
    
  }
  ngOnDestroy(): void {
    this.webRtcHelper.destroy();
    this.componentWasDestroyed$.next(true);
  }

  private init() {
    let player = this.playerNames.getPlayerById(this.auth.user.playerId)
    let playerLink: IPlayerLink = {
      displayName: player.name.display,
      first: player.name.first,
      last: player.name.last,
      docId: player.id,
    }

    this.myPlayerDetails$.next(playerLink)

    // Observe the draft document and trigger on changes
    this.draftDoc$.pipe(
      takeUntil(this.componentWasDestroyed$),
      distinctUntilChanged((prev, next) => {
        return prev.statusCode === next.statusCode && prev.activePack === next.activePack;
      }))
      .subscribe(doc => {
        console.log('draft document emitted');
        console.log(doc);

        this.currentDraftStatusCode = doc.statusCode;
        this._draftDoc = doc;
        this.isLive = doc.isLive;

        this.draftDirection = doc.activePack === 'b' ? 'right' : 'left';

        if (doc.playerDocIds.includes(this.auth.user.playerId) && this.isAttending) {
          console.log('isAttending$ already set and player is attending, no need to update anything');
        }
        else if (
          doc.statusCode <= 2 &&
          doc.playerDocIds.includes(this.auth.user.playerId) &&
          !this.isAttending
        ) {
          console.log('isAttending$ NOT set and player is attending, setting isAttending$ to TRUE');
          this.isAttending$.next(true);
        }
        else if (
          doc.statusCode > 2 &&
          doc.seatedPlayers.includes(this.auth.user.playerId) &&
          doc.playerDocIds.includes(this.auth.user.playerId) &&
          !this.isAttending
        ) {
          console.log('isAttending$ NOT set and player is attending and seated, setting isAttending$ to TRUE');
          this.isAttending$.next(true);
        }
        else if (
          doc.statusCode > 2 &&
          !doc.seatedPlayers.includes(this.auth.user.playerId) &&
          doc.playerDocIds.includes(this.auth.user.playerId)
        ) {
          console.log('player is attending but NOT seated, setting isAttending$ to FALSE');
          this.isAttending$.next(false);
        }
        else if (
          doc.statusCode <= 2 &&
          !doc.playerDocIds.includes(this.auth.user.playerId)
        ) {
          console.log('player is NOT attending, setting isAttending$ to FALSE');
          this.isAttending$.next(false);
        }
        else {
          console.log('...catching other state, setting isAttending$ to FALSE');
          this.isAttending$.next(false);
        }

      });

    // Trigger ONCE when player is attending and draft document is emitted
    this.isAttending$.subscribe((status) => {
      console.log('isAttending$ emitted.');
      if (status) {
        // init database and player
        this.initDatabaseObservable();
        this.initPlayer();

        // init draft type
        if (this._draftDoc.isBooster) {
          this.initBoosterDraft();
        }
        else if (this._draftDoc.isRochester) {
          this.initRochesterDraft();
        }
        else if (this._draftDoc.isRotisserie) {
          this.initRotisserieDraft();
        }
      }
      else {
        console.log('player is NOT attending draft, do nothing');
      }
    });
  }

  public handleSend(output: TolariaWysiwygOutput, messageGroupDocId: string): void {
    let options = {
      messageGroupDocId: messageGroupDocId,
      html: output.html,
      message: output.message,
      delta: output.delta,
      whisperMode: output.whisperMode,
      mentionedPlayerDocIds: null,
      images: output.images,
      matchChat: false,
      matchDocId: '',
      spectatorMode: false,
      replyTo: output.replyTo,
    }

    if (output.mentionedPlayers.length > 0) {
      options.mentionedPlayerDocIds = output.mentionedPlayers
    }
    this.sender.send(options)
  }

  public trackByPlayerDocId(index: number, player: IOnlineUserMeta): string {
    return player.playerDocId + '-' + player.draftStatusCode;
  }
  public trackByCardInBooster(index: number, card: IBoosterCard): string {
    return card.isPicked + '--' + card.pickedByPlayerDocId;
  }
  public trackByCardInList(index: number, card: IBoosterCardDocument): string {
    return card.docId;
  }
  public resetRotisserieFilters() {
    this.rotisserieFilter = {
      search: '',
      textSearch: false,
      colors: {
        white: true,
        blue: true,
        black: true,
        red: true,
        green: true,
        colorless: true
      },
      types: {
        sorcery: true,
        instant: true,
        land: true,
        enchantment: true,
        artifact: true,
        creature: true
      },
      isPicked: true,
      onlyShowSaved: false
    }
  }
  public getPlayerStream(playerDocId: string): MediaStream {
    return this.activeStreams.get(playerDocId);
  }

  // Initializations
  private initDatabaseObservable = () => {
    console.log('init database observables');
    this.dbRef = this.afDb.list(`draftPresence/${this.draftDocId}/onlineUsers`);
    // Start Observing the online users to be able to view a list of spectators
    const activeUsers$ = this.dbRef.valueChanges();
    this.activeUsersInRoom$ = combineLatest([activeUsers$, this.draftDoc$]).pipe(
      tap(([onlineUsers, draftDoc]) => console.log('[TESTING] activeUsersInRoom emitted', onlineUsers, draftDoc)),
      map(([onlineUsers, draftDoc]) => {
        let seatedPlayers: IOnlineUserMeta[] = [];

        if (draftDoc.seatedPlayers.length > 0) {
          draftDoc.seatedPlayers.forEach(async (playerDocId) => {
            const user = onlineUsers.find((user) => user.playerDocId === playerDocId);
            if (user !== undefined) {
              const tmpMeta: IOnlineUserMeta = {
                online: user.online,
                playerDocId: user.playerDocId,
                playerUid: user.playerUid,
                timestamp: user.timestamp,
                webRtcPeerId: user.webRtcPeerId,
                callerRole: user.callerRole,
                draftStatusCode: draftDoc.statusCode,
                isActivePlayer: draftDoc.activePlayerDocId === user.playerDocId,
                displayName: user.displayName,
              }
              seatedPlayers.push(tmpMeta);
            }
            else {
              seatedPlayers.push({
                online: false,
                playerDocId,
                playerUid: undefined,
                timestamp: null,
                webRtcPeerId: undefined,
                callerRole: undefined,
                draftStatusCode: draftDoc.statusCode,
                isActivePlayer: draftDoc.activePlayerDocId === playerDocId,
                displayName: 'Nameless Race',
              });
            }
          });
        }

        else {

          seatedPlayers = [];

          onlineUsers.forEach((user) => {
            seatedPlayers.push({
              online: user.online,
              playerDocId: user.playerDocId,
              playerUid: user.playerUid,
              timestamp: user.timestamp,
              webRtcPeerId: user.webRtcPeerId,
              callerRole: user.callerRole,
              draftStatusCode: draftDoc.statusCode,
              isActivePlayer: draftDoc.activePlayerDocId === user.playerDocId,
              displayName: user.displayName,
            });
          })

        }

        return seatedPlayers;

      })
    );
  }
  private initDatabaseEventListeners = () => {
    // EventListener: Player is entering the room
    this.dbRef
      .stateChanges(['child_added'])
      .pipe(
        filter(user => user.key !== this.auth.user.playerId),
        takeUntil(this.componentWasDestroyed$)
      )
      .subscribe(action => {
        const user = action.payload.val();
        console.log('user joined the room', user);

        if (this._draftDoc.isWebcam) {
          // call user if not self
          this.webRtcHelper.callUserWithId(user.webRtcPeerId, null, user.callerRole);
        }
      });
    // EventListener: Player is leaving the room
    this.dbRef
      .stateChanges(['child_removed'])
      .pipe(
        filter(user => user.key !== this.auth.user.playerId),
        takeUntil(this.componentWasDestroyed$),
      )
      .subscribe(action => {
        const user = action.payload.val();
        console.log('user left the room', user);

        if (this._draftDoc.isWebcam) {
          // hang up on user
          this.webRtcHelper.hangupOnCall(user.webRtcPeerId);
        }
      });

  }
  private initPlayer = () => {

    this.myPlayerDetails$.pipe(take(1)).subscribe(myDetails => {

      // if webcam game, init camera
      if (this._draftDoc.isWebcam) {

        combineLatest([this.webRtcInitialized$, this.localStream$]).pipe(
          take(1)
        ).subscribe(() => {
          console.log('WebRTC is INITIALIZED and MediaStream is PRESENT. Lets GO!');
          this.initDatabaseEventListeners();
          this.getOnline(myDetails);
        });

        this.streamService.initUserMedia(ConstraintsLevel.AVATAR);

        this.webRtcHelper.playerCalls$.pipe(
          withLatestFrom(this.activeUsersInRoom$),
          map(([stream, activeUsers]) => {
            return {
              webRtcPeerId: stream.peerId,
              mediaStream: stream.mediaStream,
              playerDocId: activeUsers.find(u => u.webRtcPeerId === stream.peerId).playerDocId,
              playerUid: activeUsers.find(u => u.webRtcPeerId === stream.peerId).playerUid
            } as IDraftUser;
          }),
          takeUntil(this.componentWasDestroyed$),
        ).subscribe((data: IDraftUser) => {
          console.log(`DraftComponent => Received stream from ${data.playerDocId}`);
          this.activeStreams.set(data.playerDocId, data.mediaStream);
          console.log(this.activeStreams);
        });

      }
      else {
        this.initDatabaseEventListeners();
        this.getOnline(myDetails);
      }

    });


  }
  private getOnline = (myDetails: IPlayerLink) => {
    // get online and subscribe to disconnection <--- ALL USERS WILL ACTIVATE THIS
    console.log('Get online and make your Presence known to the world!');
    this.updateUserStatusOnline(myDetails);
    this.updateUserStatusOnDisconnect().subscribe();
  }
  private initBoosterDraft = () => {
    // fetch all boosters
    this.boosters$ = this.draftService.getBoostersForDraft(this.draftDocId);
    this.activeBooster$ = combineLatest([this.boosters$, this.draftDoc$]).pipe(
      tap(([boosters, draft]) => console.log('[TESTING] activeBooster emitted', boosters, draft)),
      map(([boosters, draft]) => {
        return boosters
          .filter(b => b.pack === draft.activePack)
          .filter(b => b.activePlayerDocId === this.auth.user.playerId)
          .sort((a, b) => a.latestPick - b.latestPick)[0];
      })
    );
    this.picks$ = this.draftService.getPicksForDraft(this.draftDocId);
    this.playerPicks$ = this.draftService.getPicksForDraft(this.draftDocId).pipe(map(picks => picks.filter(p => p.playerDocId === this.auth.user.playerId)));
    // Subscribe to changes in boosters and update when its time to pick a booster
    this.boosters$.pipe(
      withLatestFrom(this.draftDoc$),
      takeUntil(this.componentWasDestroyed$)
    ).subscribe(([boosters, draft]) => {

      console.log('boosters$ emitted');

      if (draft.statusCode === 4) {
        console.log('[TESTING] boosters$ emitted');
        const activeBooster = boosters
          .filter(b => b.pack === draft.activePack)
          .filter(b => b.activePlayerDocId === this.auth.user.playerId)
          .sort((a, b) => a.latestPick - b.latestPick)[0];
        console.log('[TESTING] active booster = ', activeBooster);

        const nextBoosters = boosters
          .filter(b => b.pack === draft.activePack)
          .filter(b => b.nextPlayerDocId === this.auth.user.playerId)
          .sort((a, b) => a.latestPick - b.latestPick);
        console.log('[TESTING] nextBoosters', nextBoosters);

        if (activeBooster === undefined && nextBoosters.length > 0) {
          console.log('[TESTING] no active booster and available booster, picking up booster');
          this.draftService.pickUpBooster(draft.docId, nextBoosters[0]);
        }
      }

    });

  }
  private initRochesterDraft = () => {
    // fetch all boosters
    this.boosters$ = this.draftService.getBoostersForDraft(this.draftDocId);
    this.activeBooster$ = combineLatest([this.boosters$, this.draftDoc$]).pipe(
      tap(([boosters, draft]) => console.log('[TESTING] activeBooster emitted', boosters, draft)),
      map(([boosters, draft]) => {
        return boosters
          .filter(b => b.docId === draft.activeBoosterDocId)
          .sort((a, b) => a.latestPick - b.latestPick)[0];
      })
    );
    this.picks$ = this.draftService.getPicksForDraft(this.draftDocId);
    this.playerPicks$ = this.draftService.getPicksForDraft(this.draftDocId).pipe(map(picks => picks.filter(p => p.playerDocId === this.auth.user.playerId)));
  }
  private initRotisserieDraft = () => {
    // fecth all cards
    this.cards$ = this.draftService.getCardsForDraft(this.draftDocId);
    this.picks$ = this.draftService.getPicksForDraft(this.draftDocId);
  }

  // Realtime Database function for presence
  private async updateUserStatusOnline(myDetails: IPlayerLink) {
    // set the userOnline object
    const userOnlineStatus = {
      online: true,
      timestamp: this.timestamp,
      playerDocId: this.auth.user.playerId,
      playerUid: this.auth.user.uid,
      webRtcPeerId: this.webRtcHelper.peerId,
      callerRole: null,
      displayName: myDetails.displayName,
    };

    console.log(userOnlineStatus);

    return this.afDb
      .object(`draftPresence/${this.draftDocId}/onlineUsers/${this.auth.user.playerId}`)
      .update(userOnlineStatus);
  }
  private updateUserStatusOnDisconnect() {
    return this.afAuth.authState.pipe(
      tap((user) => {
        if (user) {
          this.afDb.object(`draftPresence/${this.draftDocId}/onlineUsers/${this.auth.user.playerId}`)
            .query.ref.onDisconnect().remove();
        }
      })
    );
  }

  public startDraft(draft: IDraft, activeUsers: IOnlineUser[]) {
    this.draftService.startDraft(draft, activeUsers);
  }
  public saveCard(cardDocId: string, draftDocId: string) {
    this.draftService.saveCard(draftDocId, cardDocId);
  }
  public unsaveCard(cardDocId: string, draftDocId: string) {
    this.draftService.unsaveCard(draftDocId, cardDocId);
  }
  public pickCard(draft: IDraft, booster: IBooster, card: IBoosterCard, cardItem: IBoosterCardDocument = null, cardItems: IBoosterCardDocument[] = null) {
    if (
      draft.isRochester &&
      draft.activePlayerDocId === this.auth.user.playerId &&
      !card.isPicked
    ) {
      console.log(`picking ${card.name} from booster ${booster.docId}`);
      this.draftService.pickCard(draft, booster, card);
    }
    else if (
      draft.isBooster &&
      booster.activePlayerDocId === this.auth.user.playerId &&
      !card.isPicked
    ) {
      console.log(`picking ${card.name} from booster ${booster.docId}`);
      this.draftService.pickCard(draft, booster, card);
    }
    else if (
      draft.isRotisserie &&
      draft.activePlayerDocId === this.auth.user.playerId &&
      !card.isPicked
    ) {
      console.log(`picking ${card.name}`);
      this.draftService.pickCard(draft, booster, card, cardItem, cardItems);
      const allDone = cardItems.filter((c) => c.card.isPicked).length === (draft.numberOfPicks * draft.seatedPlayers.length);
      if (allDone) { this.showPicks = true; this.showCards = false; }
    }
    else {
      console.log(`You are not the active player or the card has already been picked`);
      this.toastService.show(`You are not the active player or the card has already been picked`, { classname: 'error-toast', delay: 5000 });
    }
  }

  // deck
  public createDeck(draft: IDraft, picks: IPick[]): void {
    const deck: IDeckList = new MagicDeck(this.auth.user.playerId, this.auth.user.uid).data;

    deck.draftDocId = draft.docId;
    deck.name = `Drafted @ ${draft.name}`;
    deck.description = `This is my amazing pile of goodies from the draft, pure gaz!`;

    picks.forEach((pick) => {
      const tempCard: ICardMeta = {
        cardId: pick.card.cardId,
        cmc: pick.card.convertedManaCost,
        name: pick.card.name,
        scryfallId: null,
        typeLine: pick.card.type,
        imageUrl: pick.card.imageUrl,
        imageUris: null,
        metaUuid: uuidv4(),
        setCode: pick.card.keyruneCode,
        colors: pick.card.colors,
        coords: {
          x: 0,
          y: 0,
        }
      }

      deck.main.push(tempCard);
    });

    // map up all card id's
    deck.cardIds = _.map(_.uniqBy(_.concat(deck.main, deck.sideboard, deck.maybeboard), 'scryfallId'), 'scryfallId');

    // add deck colors
    if (deck.main && deck.main.length > 0) {
      if (deck.main.filter(c => c?.colors && c.colors.includes('W')).length > 0 && !deck.colors.includes(MagicColor.WHITE)) {
        deck.colors.push(MagicColor.WHITE);
      }
      if (deck.main.filter(c => c?.colors && c.colors.includes('U')).length > 0 && !deck.colors.includes(MagicColor.BLUE)) {
        deck.colors.push(MagicColor.BLUE);
      }
      if (deck.main.filter(c => c?.colors && c.colors.includes('B')).length > 0 && !deck.colors.includes(MagicColor.BLACK)) {
        deck.colors.push(MagicColor.BLACK);
      }
      if (deck.main.filter(c => c?.colors && c.colors.includes('R')).length > 0 && !deck.colors.includes(MagicColor.RED)) {
        deck.colors.push(MagicColor.RED);
      }
      if (deck.main.filter(c => c?.colors && c.colors.includes('G')).length > 0 && !deck.colors.includes(MagicColor.GREEN)) {
        deck.colors.push(MagicColor.GREEN);
      }
    }

    // save the deck list
    this.deckService.saveDeckList(deck)
    .then(() => {
      const modalOptions: NgbModalOptions = {
        centered: false,
        animation: true,
        backdrop: true,
        keyboard: true,
        size: 'sm',
      };

      this.modalService.open(this.deckCreationConfirmation, modalOptions);

    })
    .catch((error) => console.log(error));

  }

  private get timestamp() {
    return firestore.Timestamp.now().seconds;
  }
  public get isAttending(): boolean {
    return this.isAttending$.value;
  }
  public get viewSwitch(): string {
    if (this._draftDoc.statusCode === 4 || this._draftDoc.statusCode === 5 && this._draftDoc.isRotisserie) { return 'drafting' }
    if (this._draftDoc.statusCode < 4) { return 'waiting-to-start' }
    if (this._draftDoc.statusCode === 5 && !this._draftDoc.isRotisserie) { return 'finished' }
  }
  public refreshPipe() {
    this.pipeRefresher++;
  }

}

import { TermsOfUseComponent } from './../../terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './../../privacy-policy/privacy-policy.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AuthService, GlobalsService, CountryService } from 'src/app/services';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ICountry, IPlayerDetails, ITimeZone } from 'tolaria-cloud-functions/src/_interfaces';
import { Component } from '@angular/core';
import { faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-player-registration-form',
  templateUrl: './player-registration-form.component.html',
  styleUrls: ['./player-registration-form.component.css']
})
export class PlayerRegistrationFormComponent {
  faSquare = faSquare;
  faCheckSquare = faCheckSquare;

  public registrationForm: IPlayerDetails;

  public countries: Array<ICountry>;
  public timeZones: Array<ITimeZone>;
  public agreeToTermsAndConditions = false;

  public userEmailNull = false;

  public registrationText = `To be able to enjoy Tolaria, you need to register as a player.
  <br><br>Please user your real name and not a made up name, use the Nick name option to specify a made up name.`;

  constructor(
    public globals: GlobalsService,
    private ctrs: CountryService,
    private afs: AngularFirestore,
    private auth: AuthService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.countries = this.ctrs.getCountries();
    this.timeZones = this.ctrs.getTimeZones();
    this.registrationForm = {
      docId: this.auth.user.playerId,
      uid: this.auth.user.uid,
      createdByUid: this.auth.user.uid,
      name: {
        first: '',
        last: '',
        nick: '',
      },
      email: this.auth.user.email,
      phone: null,
      country: {
        name: null,
        code: null,
        callingCode: null,
        region: null,
        subRegion: null
      },
      type: 'global',
      userWithThisPlayerStored: [],
      attendingEvents: [],
      timeZone: {
        countryCode: null,
        countryName: null,
        zoneCode: null,
        zoneName: null,
        olson: null,
        UTC: null,
        DST: null
      },
      registered: true,
      notifications: {
        email: {
          eventReminders: true,
          matchAppointmentReminders: true,
          matchAppointments: true,
          newsLetter: false,
          developmentNews: false,
        }
      }
    };

    this.fetchUserTimezone();
    this.fetchUsersCountry();
  }

  fetchUserTimezone() {
    this.userEmailNull = this.auth.user.email === null;
    const clientOlson = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(Intl.DateTimeFormat().resolvedOptions());
    const clientTimezone = this.timeZones.find(t => t.olson === clientOlson);
    if (clientTimezone !== undefined) {
      this.registrationForm.timeZone = clientTimezone;
    }

  }

  fetchUsersCountry() {
    fetch('https://extreme-ip-lookup.com/json/')
      .then(res => res.json())
      .then(response => {
        const index = this.countries.findIndex(c => c.name === response.country);
        if (index > -1) {
          this.changeCountry(index);
        }
      })
      .catch(() => {
        console.log('Request failed');
      })
  }

  public get isMobile() {
    return this.globals.isMobile;
  }

  showInfo(info: string): void {

    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    };

    switch (info) {
      case 'privacyPolicy':
        const privacyModal = this.modalService.open(PrivacyPolicyComponent, modalOptions);
        privacyModal.componentInstance.modal = true
        break;
      case 'termsOfUse':
        const termsModal = this.modalService.open(TermsOfUseComponent, modalOptions);
        termsModal.componentInstance.modal = true
        break;
    }
  }

  changeCountry(index: number) {
    this.registrationForm.country.name = this.countries[index].name;
    this.registrationForm.country.region = this.countries[index].region;
    this.registrationForm.country.subRegion = this.countries[index].subregion;
    this.registrationForm.country.code = this.countries[index].code;
    this.registrationForm.country.callingCode = this.countries[index].callingCodes[0];
  }

  registrationFormValidation(): boolean {
    if (
      this.registrationForm.name.first !== null &&
      this.registrationForm.name.first.length > 0 &&
      this.registrationForm.name.last !== null &&
      this.registrationForm.name.last.length > 0 &&
      this.registrationForm.country.name !== null &&
      this.registrationForm.country.name.length > 0 &&
      this.registrationForm.timeZone.UTC !== null &&
      this.registrationForm.timeZone.UTC.length > 0 &&
      this.registrationForm &&
      this.registrationForm.email !== null &&
      this.validateEmail(this.registrationForm.email) &&
      this.agreeToTermsAndConditions
    ) {
      return false;
    }
    return true;
  }

  validateEmail(emailAddress: string): boolean {
    if (/^\w+([+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
      //     if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
      return (true);
    }
    return (false);
  }

  registerAsPlayer() {
    this.afs.collection('players').doc(this.auth.user.playerId).set(this.registrationForm)
      .then(() => {
        this.afs.collection('users').doc(this.auth.user.uid).update({
          isRegisteredAsPlayer: true
        }).then(() => {
          this.router.navigate(['/dashboard']);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

}

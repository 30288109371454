import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IVersionHistory } from 'src/app/private/admin/pages/releases/release-notes/release-notes.component';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'versionHtml'
})
export class VersionHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(string: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(string);
  }
}

@Component({
  selector: 'app-version-history',
  templateUrl: './version-history.component.html',
  styleUrls: ['./version-history.component.css']
})
export class VersionHistoryComponent {

  @Input() showAll: boolean = true;

  versionHistoryRef: AngularFirestoreCollection<IVersionHistory>;
  versionHistory$: Observable<IVersionHistory[]>;

  constructor(
    private afs: AngularFirestore,
    private activeModal: NgbActiveModal,
  ) {
    this.versionHistoryRef = this.afs.collection('app').doc('settings').collection<IVersionHistory>('version-history', ref => ref
      .where('published', '==', true)
      .orderBy('releaseDate', 'desc')
    );
    this.versionHistory$ = this.versionHistoryRef.snapshotChanges().pipe(
      map(snap => {
        const res: IVersionHistory[] = [];
        snap.forEach(doc => {
          const newDoc: IVersionHistory = doc.payload.doc.data();
          newDoc.docId = doc.payload.doc.id;

          res.push(newDoc);
        })

        return res;
      }));
  }

  close(releaseNoteDocId: string): void {
    this.activeModal.close(releaseNoteDocId);
  }

}

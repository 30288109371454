import { IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { MessagesService, MessageType, IMessageOptions } from 'src/app/services/messages.service';
import { take, takeUntil } from 'rxjs/operators';
import { coin } from 'src/assets/font-icons/coin';
import { NotesService, NoteType } from 'src/app/services/notes.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { MatchService } from 'src/app/services/match.service';
import { Component, ElementRef, HostListener, Input, ViewChild, OnDestroy } from '@angular/core';
import {
  faEdit, faBolt, faHandshake, faMinusSquare, faPlusSquare, faUndo, faGripLines,
  faTimes, faDiceD6, faDiceD20, faWindowMinimize, faWindowMaximize, faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebRtcDataCommands, WebRtcHelperService } from '../webrtc-helper.service';
import { Subject } from 'rxjs';
import { MatchReportingService } from 'src/app/private/tournament/services/match-reporting.service';

@Component({
  selector: 'app-match-actions',
  templateUrl: './match-actions.component.html',
  styleUrls: ['./match-actions.component.css']
})
export class MatchActionsComponent implements OnDestroy {
  @ViewChild('addMatchNoteForm', { static: false }) addMatchNoteForm: ElementRef;
  @Input() eventDocId: string;
  @Input() matchDocId: string;
  @Input() playerIs: string;
  @Input() opponentDocId: string;

  icons = {
    faEdit,
    faPlayerAction: faBolt,
    faMinusSquare,
    faPlusSquare,
    faHandshake,
    faUndo,
    faTimes,
    faDiceD6,
    faDiceD20,
    facCoin: coin,
    faWindowMinimize,
    faWindowMaximize,
    faExternalLinkAlt,
    faGripLines
  };

  lifeUpdateTimer: any;
  lifeUpdate = 0;
  newMatchNote = '';

  dice = {
    d6: [1, 2, 3, 4, 5, 6],
    d20: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
  };

  diceResult: number;
  showPopOver: boolean;

  whoStartsTriggers = {
    y: false,
    six: false,
    sixsix: false,
    sixsixsix: false,
    timer: null,
    selectors: [
      'RockLobster',
      'ScissorsLizard',
      'PaperTiger'
    ]
  }

  private componentWasDestroyed$: Subject<boolean> = new Subject<boolean>();
  private dataConnectionEstablished: boolean = false;

  constructor(
    private matchService: MatchService,
    public globals: GlobalsService,
    private modalService: NgbModal,
    private notesService: NotesService,
    public webRtcService: WebRtcHelperService,
    private messagesService: MessagesService,
    private readonly reporting: MatchReportingService,
  ) {
    this.webRtcService.dataConnectionEstablished$
      .pipe(takeUntil(this.componentWasDestroyed$))
      .subscribe((value) => {
        // data connection is established
        this.dataConnectionEstablished = value;
      });
  }

  ngOnDestroy(): void {
    this.componentWasDestroyed$.next(true);
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.globals.hotkeysEnabled) {

      if (event.shiftKey) {

        switch (event.key) {

          case 'ArrowDown':
            this.updateLife(-5)
            break

          case 'ArrowUp':
            this.updateLife(5)
            break

        }

      }
      else if (this.dataConnectionEstablished && event.key === 'y') {
        this.whoStarts(true)
      }
      else if (this.dataConnectionEstablished && this.whoStartsTriggers.y && event.key === '6') {
        this.whoStarts(false)
      }
      else {

        switch (event.key) {

          case 'ArrowDown':
            this.updateLife(-1)
            break

          case 'ArrowUp':
            this.updateLife(1)
            break

          case 'c':
            this.updateLife(20, true)
            break

          case 'n':
            this.addMatchNote()
            break

          case 'g':
            this.updateGameWin(true)
            break

          case 'h':
            this.updateGameWin(false)
            break

          case 'r':
            this.openReportSlip()
            break

          case 'i':
            if (this.dataConnectionEstablished) { this.flipCoin() }
            break

          case 'o':
            if (this.dataConnectionEstablished) { this.rollD6() }
            break

          case 'p':
            if (this.dataConnectionEstablished) { this.rollD20() }
            break

          case '0':
            this.setHandSize(0)
            break

          case '1':
            this.setHandSize(1)
            break

          case '2':
            this.setHandSize(2)
            break

          case '3':
            this.setHandSize(3)
            break

          case '4':
            this.setHandSize(4)
            break

          case '5':
            this.setHandSize(5)
            break

          case '6':
            this.setHandSize(6)
            break

          case '7':
            this.setHandSize(7)
            break

          case '8':
            this.setHandSize(8)
            break

          case '9':
            this.setHandSize(9)
            break


        }
      }
    }
  }

  public updateGameWin(add: boolean) {
    console.log('about to update game wins. Add: ', add);
    this.matchService.updateGameWins(this.matchDocId, this.playerIs, add);
  }

  private whoStarts(startTrigger: boolean) {
    // clear the timer
    clearTimeout(this.whoStartsTriggers.timer);

    // check progress
    if (!this.whoStartsTriggers.y && startTrigger) {
      this.whoStartsTriggers.y = true;
      this.whoStartsTriggers.timer = setTimeout(() => { this.restartWhoStarts(); }, 1000);
    }
    else if (this.whoStartsTriggers && !this.whoStartsTriggers.six && !startTrigger) {
      this.whoStartsTriggers.six = true;
      this.whoStartsTriggers.timer = setTimeout(() => { this.restartWhoStarts(); }, 1000);
    }
    else if (this.whoStartsTriggers && this.whoStartsTriggers.six && !this.whoStartsTriggers.sixsix && !startTrigger) {
      this.whoStartsTriggers.sixsix = true;
      this.whoStartsTriggers.timer = setTimeout(() => { this.restartWhoStarts(); }, 1000);
    }
    else if (this.whoStartsTriggers && this.whoStartsTriggers.six && this.whoStartsTriggers.sixsix && !this.whoStartsTriggers.sixsixsix && !startTrigger) {
      this.whoStartsTriggers.six = true;
      this.whoStartsTriggers.timer = setTimeout(() => { this.restartWhoStarts(); }, 1000);

      // do the thing
      const selectors: Array<string> = JSON.parse(JSON.stringify(this.whoStartsTriggers.selectors));
      this.shuffle(selectors);
      this.shuffle(selectors);
      const player1get = selectors.splice(0, 1);
      this.shuffle(selectors);
      this.shuffle(selectors);
      const player2get = selectors.splice(0, 1);

      const data = {
        player1get: player1get[0],
        player2get: player2get[0]
      }

      this.webRtcService.sendData({
        command: WebRtcDataCommands.WHOSTART,
        value: JSON.stringify(data)
      });

    }
  }

  restartWhoStarts() {
    this.whoStartsTriggers.y = false;
    this.whoStartsTriggers.six = false;
    this.whoStartsTriggers.sixsix = false;
    this.whoStartsTriggers.sixsixsix = false;
  }

  addMatchNote() {
    this.modalService
      .open(this.addMatchNoteForm, { centered: true, keyboard: true }).result
      .then((result) => {
        console.log(result);
      }, (reason) => {
        console.log(reason);
        switch (reason) {
          case 'cancel':
            break;
          case 'addNote':
            this.notesService.createNote(NoteType.MATCH_NOTE,
              { matchDocId: this.matchDocId, eventDocId: this.eventDocId, playerDocId: this.opponentDocId }, this.newMatchNote);
            this.newMatchNote = '';
        }
      });
  }

  openReportSlip() {
    this.reporting.openReportSlip({ isOrganizer: false, matchDocId: this.matchDocId })
  }

  updateLife(change: number, reset: boolean = false) {
    if (reset) {
      change = 20;
      console.log('calling life updater with change: ', change);
      this.matchService.updateLife(this.matchDocId, this.playerIs, change, true);
    }
    else {
      this.lifeUpdate = this.lifeUpdate + change;
      clearTimeout(this.lifeUpdateTimer);
      this.lifeUpdateTimer = setTimeout(() => {
        console.log('calling life updater with change: ', change);
        this.matchService.updateLife(this.matchDocId, this.playerIs, this.lifeUpdate);
        this.lifeUpdate = 0;
      }, 2000);
    }

  }

  setHandSize(size: number) {
    this.matchService.updateHandSize(this.matchDocId, this.playerIs, size)
  }

  flipCoin() {
    const flip = (Math.floor(Math.random() * 2) === 0) ? 'heads' : 'tails';
    this.sendMessage(`flipped a coin that came up ${flip}`);
    this.webRtcService.sendData({
      command: WebRtcDataCommands.FLIPCOIN,
      value: flip
    });
  }

  rollD6() {
    const value = this.shuffle(this.dice.d6)[0];
    this.sendMessage(`rolled a D6 with the result ${value}`);
    this.webRtcService.sendData({
      command: WebRtcDataCommands.ROLLD6,
      value: value.toString()
    });
  }

  rollD20() {
    const value = this.shuffle(this.dice.d20)[0];
    this.sendMessage(`rolled a D20 with the result ${value}`);
    this.webRtcService.sendData({
      command: WebRtcDataCommands.ROLLD20,
      value: value.toString()
    });
  }

  private shuffle(array: any) {
    let currentIndex = array.length;
    let temporaryValue: any;
    let randomIndex: number;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  sendMessage(text: string): void {
    const messageGroupDocId = `eventChatFor[${this.eventDocId}]`;
    const options: IMessageOptions = {
      matchChat: true,
      matchDocId: this.matchDocId,
      spectatorMode: false
    }

    this.matchService.getMatchDocById(this.matchDocId).pipe(take(1)).subscribe((matchDoc: IMatchData) => {
      const playerDisplayName = matchDoc[this.playerIs].displayName;
      const content = `${playerDisplayName} ${text}`;
      this.messagesService.postChatMessage(content, messageGroupDocId, MessageType.MATCH_ROOM_ACTION, options);
    });

  }

}

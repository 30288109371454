import { Component, AfterViewInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICardMeta, IDeckList } from 'src/app/services/decks.service';

export interface IGraphData {
  name: string;
  value: number;
}

@Component({
  selector: 'app-deck-graphs',
  templateUrl: './deck-graphs.component.html',
  styleUrls: ['./deck-graphs.component.css']
})
export class DeckGraphsComponent implements AfterViewInit {
  @Input()
  set deckList(deck: IDeckList) {
    this.deck = deck;
  }
  @Input() graphWidth: number = 100; // percentage
  @Input() graphHeight: number = 30; // rem

  private deck: IDeckList;


  // options
  gradient: boolean = false;
  showLegend: boolean = false;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';
  colorScheme = {
    color: { domain: ['#5188CB', '#5362B5', '#E4CCE6', '#8367B4', '#C878B7', '#FF7167', '#FF876F', '#FFA567'] },
    cmc: { domain: ['#FF5F1F', '#F87217', '#F88017', '#FF8C00', '#F87431', '#FF5F1F', '#F87217', '#F88017', '#FF8C00', '#F87431'] },
    type: { domain: ['#FEC667', '#FFA567', '#FFA200', '#FF7B00', '#CB8000', '#D06401', '#A56400', '#A54B00'] }
  };

  data = {
    type: [],
    color: [],
    cmc: [],
  }

  typeDataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  colorDataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  cmcDataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor() { }

  ngAfterViewInit(): void {
    this.init();
  }

  private async init() {

    // get color data
    this.data.color = await this.getColorData();
    this.colorDataReady$.next(true);
    console.log(`data COLOR:`, this.data.color);

    // get types data
    this.data.type = await this.getTypeData();
    this.typeDataReady$.next(true);
    console.log(`data TYPES:`, this.data.type);

    // get cmc data
    this.data.cmc = await this.getConvertedManaCostData();
    this.cmcDataReady$.next(true);
    console.log(`data CMC:`, this.data.cmc);

  }

  private async getConvertedManaCostData(): Promise<IGraphData[]> {
    const mainDeck: ICardMeta[] = JSON.parse(JSON.stringify(this.deck.main));

    const graphData: IGraphData[] = [];

    const map = Object.create(null);
    mainDeck.forEach((card: ICardMeta) => {
      if (!(card.cmc in map)) { map[card.cmc] = 0; }
      map[card.cmc] += 1;
    });

    Object.keys(map).forEach(key => {
      const data: IGraphData = {
        name: `${key} (${map[key]})`,
        value: map[key]
      }
      graphData.push(data);
    })

    return graphData;

  }

  private async getTypeData(): Promise<IGraphData[]> {
    const mainDeck: ICardMeta[] = JSON.parse(JSON.stringify(this.deck.main));

    const graphData: IGraphData[] = [];

    const map = Object.create(null);
    mainDeck.forEach((card: ICardMeta) => {
      const type = this.getCardType(card.typeLine);
      if (!(type in map)) { map[type] = 0; }
      map[type] += 1;
    });

    Object.keys(map).forEach(key => {
      const data: IGraphData = {
        name: `${key} (${map[key]})`,
        value: map[key]
      }
      graphData.push(data);
    })

    return graphData;

  }

  private async getColorData(): Promise<IGraphData[]> {
    const mainDeck: ICardMeta[] = JSON.parse(JSON.stringify(this.deck.main));

    const graphData: IGraphData[] = [];

    const map = Object.create(null);
    mainDeck.forEach((card: ICardMeta) => {
      const type = this.getCardColor(card.colors);
      if (!(type in map)) { map[type] = 0; }
      map[type] += 1;
    });

    Object.keys(map).forEach(key => {
      const data: IGraphData = {
        name: `${key} (${map[key]})`,
        value: map[key]
      }
      graphData.push(data);
    })

    return graphData;

  }

  private getCardColor(colors: string[]): string {
    if (colors.length === 1 && colors.includes('W')) { return 'White' }
    if (colors.length === 1 && colors.includes('U')) { return 'Blue' }
    if (colors.length === 1 && colors.includes('B')) { return 'Black' }
    if (colors.length === 1 && colors.includes('R')) { return 'Red' }
    if (colors.length === 1 && colors.includes('G')) { return 'Green' }
    if (colors.length > 1) { return 'Multicolor' }
    if (colors.length === 0) { return 'Colorless' }

    return 'Other';
  }

  private getCardType(typeLine: string): string {
    if (typeLine === undefined) return 'Others'
    if (typeLine.toLowerCase().includes('land')) { return 'Lands' }
    if (typeLine.toLowerCase().includes('creature')) { return 'Creatures' }
    if (typeLine.toLowerCase().includes('instant')) { return 'Instants' }
    if (typeLine.toLowerCase().includes('sorcery')) { return 'Sorceries' }
    if (typeLine.toLowerCase().includes('artifact')) { return 'Artifacts' }
    if (typeLine.toLowerCase().includes('enchantment')) { return 'Enchantments' }
    if (typeLine.toLowerCase().includes('planeswalker')) { return 'Planeswalkers' }
    return 'Others';
  }

}

<div class="mt-4">
  <div class="text-secondary text-smaller">Legalities</div>
  <div class="legalities-container">
    <ng-container *ngFor="let legality of legalities">
      <div class="legality-wrapper pt-1 pb-1 d-flex align-items-center">
        <div class="legality-status me-2 w-50 flex-grow-1 {{ legality.class }}">{{ legality.text.toUpperCase() }}</div>
        <div class="legality-name w-50 flex-grow-1 {{ legality.legal ? '' : 'text-secondary' }}">{{ legality.name }}</div>
      </div>
    </ng-container>
  </div>
</div>

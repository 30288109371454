
<ng-container *ngIf="event$ | async as event">
  <span
    [routerLink]="routerLinkEnabled ? routerLink : null"
    [innerText]="event.details.name"
    #t="ngbTooltip"
    [ngbTooltip]="tooltip"
    container="body"
    placement="right-top left-top"
    tooltipClass="event-info-tooltip"
    [openDelay]="750">
  </span>

  <ng-template #tooltip>
    <div class="info-wrapper" [style.minWidth.rem]="25" (click)="$event.stopPropagation(); $event.preventDefault();">
      <div class="card-header p-0 m-0">
        <img width="100%" [src]="event.banner ? event.banner : parseDefaultBanner(event.details.format)">
      </div>
      <div class="card-body no-hover">
        <div class="h4 mb-3" [innerText]="event.details.name"></div>
        <app-event-info [eventDocId]="event.docId" [showTickets]="false"></app-event-info>
      </div>
    </div>
  </ng-template>

</ng-container>


import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router'
import { BehaviorSubject, firstValueFrom } from 'rxjs';

interface IRedirectUrl {
  redirectUrl: string
  queryParams: any
}

@Injectable({
  providedIn: 'root'
})
export class RouteWatcherService {

  private _currentRoute: string;
  private _redirectUrl: string = '';
  public params$: BehaviorSubject<IRedirectUrl | null> = new BehaviorSubject<IRedirectUrl | null>(null)

  public matchRoomOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public matchRoomState$: BehaviorSubject<'maximized' | 'floating' | 'minimized' | 'closed'> = new BehaviorSubject<'maximized' | 'floating' | 'minimized' | 'closed'>('maximized')

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
  ) {

    console.log('[RouteWatcherService] --> Initialized')

    this.params$.subscribe(i => console.log(`[RouteWatcherService] --> params$ emitted`, i))

    this.route.queryParams.subscribe(params => {
      console.log('[RouteWatcherService] --> queryParams emitted', params)

      if (params.hasOwnProperty('returnUrl')) {

        const urlSplit: string[] = params['returnUrl'].split('?')
        const redirectUrl = urlSplit[0]
        let queryParams = {}
        urlSplit.length === 2
          ? queryParams = this.paramsParser(urlSplit[1])
          : queryParams = {}

        this.params$.next({ redirectUrl, queryParams })
      }
      else {
        this.params$.next(null)
      }
    })

    this._currentRoute = '';
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationStart) {
        console.log('[RouteWatcherService] --> NavigationStart', this.router.url);
        // Show progress spinner or progress bar
        console.log('[RouteWatcherService] --> event: ', event)
        if (event.url === '/loading') {

        }
        else if (event.url.includes('/s/')) {
          this.getShortUrlAndRedirect(event.url.split('/')[2])
        }
        // else if (event.url === '/sign-in' && ['/public', '/sign-in'].includes(this.router.url)) {
        else if (event.url === '/player-registration-form') {
          this.params$.next({
            redirectUrl: '/dashboard',
            queryParams: {},
          })
        }
        else if (event.url === '/auth/sign-in') {
          this.params$.next({
            redirectUrl: '/dashboard',
            queryParams: {},
          })
        }
        else if (event.url === '/sign-in') {

        }
        else {
          this.params$.next({
            redirectUrl: event.url.split('?')[0],
            queryParams: {},
          })
        }
      }

      if (event instanceof NavigationEnd) {
        console.log('[RouteWatcherService] --> NavigationEnd');
        console.log('[RouteWatcherService] --> event: ', event)
        console.log('[RouteWatcherService] --> watcher: ', this.params$.getValue())


        // update parameter that holds info about open match room
        this.matchRoomOpen$.next(event.url.includes('(matchRoom:match-room/match/'))
        if (this._currentRoute.includes('(matchRoom:match-room/match/')) {
          this.matchRoomState$.next('minimized')
        }
        if (!this._currentRoute.includes('(matchRoom:match-room/match/')) {
          this.matchRoomState$.next('maximized')
        }

        this._currentRoute = event.url;

        if (this._currentRoute.includes('events/event')) {
          const eventDocId = this._currentRoute.split('/')[3]
          const currentChildRoute = this._currentRoute.split('/')[4]
          if (currentChildRoute) {
            localStorage.setItem(`tolaria-event-route-${eventDocId}`, currentChildRoute)
          }
        }

      }

      if (event instanceof NavigationError) {
        console.log('[RouteWatcherService] --> NavigationError');
        // Hide progress spinner or progress bar
        // Present error to user
        console.log('[RouteWatcherService] --> error: ', event.error)
      }
    });

  }

  public getRedirectUrl(): string {
    return this._redirectUrl;
  }

  public setRedirectUrl(url: string) {
    console.log(`[RouteWatcherService] --> setting redirect url: ${url}`)
    this._redirectUrl = url;
  }

  public paramsParser(params: string): any {
    return JSON.parse('{"' + decodeURI(params).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
  }

  public navigateToSplash(): void {
    this.router.navigate(['loading'])
  }

  public navigate(): void {
    console.log('[RouteWatcherService] --> Perform navigation to --> ', this.params$.getValue())

    if (this.params$.getValue()?.redirectUrl.includes('/sign-in')) {
      this.router.navigate(['/dashboard'])
    }
    else if (this.params$.getValue()?.redirectUrl === '/player-registration-form') {
      this.router.navigate(['/dashboard'])
    }
    else if (this.router.url.includes('/loading') && this.params$.getValue() === null) {
      this.router.navigate(['/dashboard'])
    }
    else {
      this.router.navigate(this.params$.getValue()?.redirectUrl ? [this.params$.getValue()?.redirectUrl] : ['/dashboard'], { queryParams: this.params$.getValue()?.queryParams || {} })
    }
  }

  private async getShortUrlAndRedirect(shortUrlId: string) {

    console.log('[RouteWatcherService] --> Short url route, lets fetch the routing address --> ', shortUrlId)
    const snap = await firstValueFrom(this.afs.collection('short-urls').doc(shortUrlId).get())
    if (snap.exists) {
      const doc: any = snap.data()
      console.log('[RouteWatcherService] --> Got routing address --> ', doc.route)
      this.router.navigate([doc.route])
    }

  }

}

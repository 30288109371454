<div class="d-flex flex-column align-items-stretch rounded-4 gap-4">

  <div class="p-3 d-flex justify-content-between align-items-center">
    <span class="text-larger">Assign player to team</span>
    <button class="btn-close" (click)="onDismiss()"></button>
  </div>
  
  <div class="px-4 d-flex align-items-center justify-content-between gap-3">
    <div class="">Select team</div>
    <button class="btn btn-sm btn-outline-secondary" (click)="onNewTeamPressed()">New team</button>
  </div>

  <ng-container *ngIf="teams$ | async as teams">
  
    <div class="mx-4">

      <select class="w-100 focus-ring focus-ring-primary"
        [size]="5"
        [ngModel]="selectedTeam"
        (ngModelChange)="onTeamSelectionChange($event)">
    
        <option *ngFor="let team of teams"
          [ngClass]="{ 'selected' : team.selected }"
          class="px-3 py-2 team-select-option d-flex align-items-center gap-5 position-relative"
          [ngClass]="{ 'team-full' : team.extra.filled }"
          [ngValue]="team"
          (keydown.enter)="onTeamSelected(team)"
          (keydown.space)="onTeamSelected(team)"
          (click)="onTeamSelected(team)">
          {{ team.name }}
        </option>
        
      </select>

    </div>

  </ng-container>

  <ng-container *ngIf="selectedTeam$ | async as team">

    <div class="px-4">Select seat</div>

    <div class="px-4 d-flex gap-3">

      <div class="team-seat col-3 flex-grow-1 d-flex flex-column align-items-center gap-3 p-3 rounded rounded-3 bg-secondary bg-opacity-25 border border-secondary cursor-pointer"
        (click)="onSeatSelected('a')">
        <span class="d-flex flex-column align-items-center gap-1">
          <span class="text-smaller">SEAT</span>
          <span class="text-xlarge fw-bold">A</span>
        </span>
        <div class="text-center">
          <app-player-display-name [name]="playerNames.a" [nowrap]="false" [playerDocId]="team.player.a" *ngIf="team.player.a; else seatAtA"></app-player-display-name>
        </div>
        <ng-template #seatAtA>
          <button class="btn btn-sm btn-primary" [disabled]="team.player.a !== null" (click)="onSeatSelected('a')">Seat here</button>
        </ng-template>
      </div>

      <div class="team-seat col-3 flex-grow-1 d-flex flex-column align-items-center gap-3 p-3 rounded rounded-3 bg-secondary bg-opacity-25 border border-secondary cursor-pointer"
        (click)="onSeatSelected('b')">
        <span class="d-flex flex-column align-items-center gap-1">
          <span class="text-smaller">SEAT</span>
          <span class="text-xlarge fw-bold">B</span>
        </span>
        <div class="text-center">
          <app-player-display-name [name]="playerNames.b" [nowrap]="false" [playerDocId]="team.player.b" *ngIf="team.player.b; else seatAtB"></app-player-display-name>
        </div>
        <ng-template #seatAtB>
          <button class="btn btn-sm btn-primary" [disabled]="team.player.b !== null" (click)="onSeatSelected('b')">Seat here</button>
        </ng-template>
      </div>

      <div class="team-seat col-3 flex-grow-1 d-flex flex-column align-items-center gap-3 p-3 rounded rounded-3 bg-secondary bg-opacity-25 border border-secondary cursor-pointer"
        (click)="onSeatSelected('c')">
        <span class="d-flex flex-column align-items-center gap-1">
          <span class="text-smaller">SEAT</span>
          <span class="text-xlarge fw-bold">C</span>
        </span>
        <div class="text-center">
          <app-player-display-name [name]="playerNames.c" [nowrap]="false" [playerDocId]="team.player.c" *ngIf="team.player.c; else seatAtC"></app-player-display-name>
        </div>
        <ng-template #seatAtC>
          <button class="btn btn-sm btn-primary" [disabled]="team.player.c !== null" (click)="onSeatSelected('c')">Seat here</button>
        </ng-template>
      </div>

    </div>

  </ng-container>

  <div class="p-3 d-flex align-items-center justify-content-end gap-3">
    <button class="btn btn-blank focus-ring focus-ring-secondary" (click)="onDismiss()">Cancel</button>
    <button
      class="btn {{ disabled ? 'btn-blank' : 'btn-primary' }} focus-ring focus-ring-primary"
      [disabled]="disabled"
      (click)="onClose()">
      Assign to team
    </button>
  </div>

</div>
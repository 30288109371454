<div class="main-view d-flex flex-row" *ngIf="draftDoc$ | async as draft" (contextmenu)="$event.preventDefault()">


  <ng-container *ngIf="isAttending">

    <div class="main-wrapper flex-grow-1 d-flex flex-column">

      <div class="webrtc-section p-3 flex-grow-0 d-flex flex-row">

        <div class="webrtc-elements flex-grow-1 d-flex flex-row align-items-center"
          *ngIf="activeUsersInRoom$ | async as playersInRoom">

          <ng-container *ngFor="let player of playersInRoom; let i = index; trackBy : trackByPlayerDocId">

            <!-- only show for booster draft -->
            <div class="draft-direction {{ draftDirection }}" *ngIf="draft.isBooster">
              <div class="mb-2">
                <fa-icon [icon]="draftDirection === 'left' ? icons.faChevronLeft : icons.faChevronRight"
                  [fixedWidth]="true">
                </fa-icon>
              </div>
            </div>

            <div class="webrtc-camera-wrapper" [id]="player.playerDocId"
              [ngClass]="{ 'disconnected' : !player.online, 'active-player' : player.isActivePlayer, 'mr-3' : !draft.isBooster }">

              <ng-container *ngIf="draft.isWebcam; else noWebCam">

                <ng-container *ngIf="player.playerDocId === auth.user.playerId; else avatar">
                  <div class="avatar-wrapper">
                    <video [srcObject]="localStream$ | async" id="your-stream" autoplay [muted]="true" playsinline
                      [ngbTooltip]="player.displayName" container="body"
                      placement="bottom bottom-left bottom-right"></video>
                  </div>
                </ng-container>
                <ng-template #avatar>
                  <div class="avatar-wrapper">
                    <video [srcObject]="getPlayerStream(player.playerDocId)" [id]="player.playerDocId" autoplay
                      playsinline *ngIf="player.online; else disconnected" [ngbTooltip]="player.displayName"
                      container="body" placement="bottom bottom-left bottom-right"></video>
                    <ng-template #disconnected>
                      <app-player-avatar [playerDocId]="player.playerDocId" [round]="false" size="fill"
                        [border]="false"></app-player-avatar>
                      <!-- <app-player-avatar [muted]="true" [playerDocId]="player.playerDocId"></app-player-avatar> -->
                    </ng-template>
                  </div>
                </ng-template>

              </ng-container>

              <ng-template #noWebCam>
                <div class="avatar-wrapper">
                  <app-player-avatar [playerDocId]="player.playerDocId" [round]="false" size="fill" [border]="false">
                  </app-player-avatar>
                  <!-- <app-player-avatar [muted]="!player.online" [playerDocId]="player.playerDocId"></app-player-avatar> -->
                </div>
              </ng-template>

              <!-- Only show booster "dots" when there is a booster draft as no other draft uses multiple boosters at once -->
              <ng-container *ngIf="draft.isBooster">
                <div class="active-boosters d-flex align-items-center" *ngIf="boosters$ | async as boosters">
                  <ng-container
                    *ngFor="let status of boosters | draftWaitingBoosters : player.playerDocId : draft.activePack">
                    <div class="booster-marker ms-1 {{ status }}"
                      [ngbTooltip]="status === 'active' ? 'Drafting' : 'Waiting'" container="body">
                      <fa-icon [icon]="icons.faCircle" [fixedWidth]="true"></fa-icon>
                    </div>
                  </ng-container>
                </div>
              </ng-container>

            </div>
          </ng-container>


        </div>

        <div *ngIf="draft.isWebcam" class="webrtc-controls flex-grow-0 d-flex flex-row align-items-center">
          <app-media-controls orientation="horizontal"></app-media-controls>
        </div>

      </div>


      <div class="draft-section flex-grow-1 d-flex flex-row">

        <div
          class="booster flex-grow-1 d-flex flex-wrap ps-2 pe-2 pt-3 pb-4 ms-1 me-1 align-content-start justify-content-center">

          <ng-container [ngSwitch]="viewSwitch">

            <ng-container *ngSwitchCase="'waiting-to-start'">
              <div class="w-100 h-100 d-flex align-items-center justify-content-center"
                *ngIf="activeUsersInRoom$ | async as activeUsers">
                <div class="info-content-wrapper text-center">

                  <ng-container *ngIf="draft.isBooster || draft.isRochester">
                    <div class="info-content-icons d-flex mb-5 text-secondary justify-content-center align-items-end">
                      <div class="pack-details m-3 d-flex flex-column align-items-center">
                        <ng-container *ngIf="draft.packs.a.customIcon; else keyruneIconA">
                          <fa-icon [icon]="fontIcons.setIcons[draft.packs.a.keyruneCode]" [fixedWidth]="true"
                            style="font-size: 4em;"></fa-icon>
                        </ng-container>
                        <ng-template #keyruneIconA>
                          <i class="mb-3 ss ss-4x ss-fw ss-{{ draft.packs.a.keyruneCode }}"></i>
                        </ng-template>
                        <span class="text-smaller">{{ draft.packs.a.name }}</span>
                      </div>
                      <div class="pack-details m-3 d-flex flex-column align-items-center">
                        <ng-container *ngIf="draft.packs.b.customIcon; else keyruneIconB">
                          <fa-icon [icon]="fontIcons.setIcons[draft.packs.b.keyruneCode]" [fixedWidth]="true"
                            style="font-size: 4em;"></fa-icon>
                        </ng-container>
                        <ng-template #keyruneIconB>
                          <i class="mb-3 ss ss-4x ss-fw ss-{{ draft.packs.b.keyruneCode }}"></i>
                        </ng-template>
                        <span class="text-smaller">{{ draft.packs.b.name }}</span>
                      </div>
                      <div class="pack-details m-3 d-flex flex-column align-items-center">
                        <ng-container *ngIf="draft.packs.c.customIcon; else keyruneIconC">
                          <fa-icon [icon]="fontIcons.setIcons[draft.packs.c.keyruneCode]" [fixedWidth]="true"
                            style="font-size: 4em;"></fa-icon>
                        </ng-container>
                        <ng-template #keyruneIconC>
                          <i class="mb-3 ss ss-4x ss-fw ss-{{ draft.packs.c.keyruneCode }}"></i>
                        </ng-template>
                        <span class="text-smaller">{{ draft.packs.c.name }}</span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="draft.isRotisserie">
                    <div class="info-content-icons d-flex mb-5 text-secondary justify-content-center align-items-end">
                      <div class="pack-details m-3 d-flex flex-column align-items-center"
                        *ngFor="let set of draft.sets">
                        <ng-container *ngIf="set.customIcon; else keyruneIcon">
                          <fa-icon [icon]="fontIcons.setIcons[set.keyruneCode]" [fixedWidth]="true"
                            style="font-size: 4em;"></fa-icon>
                        </ng-container>
                        <ng-template #keyruneIcon>
                          <i class="mb-3 ss ss-4x ss-fw ss-{{ set.keyruneCode }}"></i>
                        </ng-template>
                        <span class="text-smaller">{{ set.name }}</span>
                      </div>
                    </div>
                  </ng-container>

                  <div class="info-content-text text-secondary" [innerText]="draft.statusText"></div>

                  <div class="info-content-text mt-5" *ngIf="auth.user.playerId === draft.createdByPlayerDocId">
                    <button class="btn btn-main" (click)="startDraft(draft, activeUsers)" *ngIf="draft.statusCode === 2"
                      [disabled]="draft.isLive && activeUsers.length < 2">Start Draft</button>
                  </div>

                  <div class="draft-info" *ngIf="draft.statusCode !== 5">
                    <div class="info-content-text text-secondary mt-3" style="max-width: 30rem;" *ngIf="!draft.isLive">This
                      draft does not
                      have a LIVE interface and players will be notified with a push notification whenever it's their
                      turn
                      to pick a card. You can start the draft whenever you like and all attending player will be seated.
                    </div>
                    <div class="info-content-text text-secondary mt-3" style="max-width: 30rem;" *ngIf="draft.isLive">This
                      draft
                      has
                      a LIVE interface and players need to be active here in the draft room. Players will <b>NOT</b> be
                      notified with push notifications whenever it's their turn to
                      pick a card. You can start the draft whenever there are more then two players active in the room.
                      <div class="note">NOTE: Only active players in the room will be seated.</div>
                    </div>
                  </div>

                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'drafting'">
              <!-- BOOSTER DRAFT -->
              <ng-container *ngIf="draft.isBooster">
                <ng-container *ngIf="activeBooster$ | async as booster">
                  <ng-container *ngFor="let card of booster.cards">
                    <app-booster-card *ngIf="!card.isPicked"
                      (cardPicked)="$event ? pickCard(draft, booster, card) : null"
                      (cardSelected)="$event ? selectedCardId = card.cardId : null"
                      [selected]="card.cardId === selectedCardId" [card]="card" [zoomLevel]="cardZoomLevel"
                      [disabled]="false">
                    </app-booster-card>
                  </ng-container>
                </ng-container>
              </ng-container>

              <!-- ROCHESTER DRAFT -->
              <ng-container *ngIf="draft.isRochester">
                <ng-container *ngIf="activeBooster$ | async as booster">
                  <ng-container *ngFor="let card of booster.cards; trackBy : trackByCardInBooster">
                    <div class="card-wrapper zoom-level-{{ cardZoomLevel }} {{ card.keyruneCode }}"
                      [ngClass]="{ 'selected' : card.cardId === selectedCardId, 'picked' : card.isPicked }"
                      [ngbTooltip]="card.name" container="body" (dblclick)="pickCard(draft, booster, card)"
                      (click)="selectedCardId = card.cardId">
                      <div class="picked-card" *ngIf="card.isPicked">
                        <div class="picked-card-player-wrapper">
                          <app-player-avatar [playerDocId]="card.pickedByPlayerDocId" [round]="false" size="fill"
                            [border]="false"></app-player-avatar>
                        </div>
                      </div>
                      <div class="card-image-wrapper">
                        <img [src]="card.imageUrl" />
                      </div>
                      <div class="card-ruling-wrapper p-3">
                        <div class="name-cmc-wrapper d-flex align-items-center">
                          <span class="name flex-grow-1 text-bold">{{ card.name }}</span>
                          <span class="card-cmc flex-grow-0 ms-2"
                            [innerHTML]="card.manaCost | manaParser : { shadowed: true, fontClass: 'text-smaller' }"></span>
                        </div>
                        <div class="card-type text-bold mb-2">{{ card.type }}</div>
                        <div class="card-text mb-1"
                          [innerHTML]="card.text | manaParser : { fontClass: 'text-small', shadowed: true }"></div>
                        <div class="carc-power-toughness mb-1" *ngIf="card.power !== ''">{{ card.power }}/{{
                          card.toughness }}</div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>

              <!-- ROTISSERIE DRAFT -->
              <ng-container *ngIf="draft.isRotisserie">

                <div class="rotisserie-draft-wrapper d-flex flex-column">

                  <div class="top-bar d-flex flex-wrap align-items-center p-3" *ngIf="draft.statusCode === 4">

                    <div class="outer-wrapper left">
                      <div class="outer-wrapper me-3">

                        <div class="btn btn-sm btn-blank me-3" [ngClass]="{ 'text-secondary' : !showCards }"
                          (click)="showCards = true; showPicks = false;">
                          Cards
                        </div>
                        <ng-container *ngIf="picks$ | async as picks">
                          <div class="btn btn-sm btn-blank me-3" [ngClass]="{ 'text-secondary' : !showPicks }"
                            (click)="showCards = false; showPicks = true;">
                            Picks<span class="ms-2 badge rounded-pill text-bg-secondary">{{ (picks | draftRotisseriePicks :
                              auth.user.playerId).length }}</span>
                          </div>
                        </ng-container>

                      </div>

                      <div class="outer-wrapper ms-3">

                        <div class="btn btn-sm btn-blank" [ngClass]="{ 'text-secondary' : !rotisserieFilter.onlyShowSaved }"
                          (click)="rotisserieFilter.onlyShowSaved = !rotisserieFilter.onlyShowSaved; refreshPipe()"
                          [ngbTooltip]="rotisserieFilter.onlyShowSaved ? 'Show all cards' : 'Only show saved cards'"
                          container="body">
                          <fa-icon [icon]="icons.faSave" [fixedWidth]="true" style="font-size: var(--font-large);">
                          </fa-icon>
                        </div>

                        <div class="btn btn-sm btn-blank" [ngClass]="{ 'text-secondary' : !rotisserieFilter.isPicked }"
                          (click)="rotisserieFilter.isPicked = !rotisserieFilter.isPicked; refreshPipe()"
                          [ngbTooltip]="rotisserieFilter.isPicked ? 'Hide picked cards' : 'Show picked cards'"
                          container="body">
                          <fa-icon [icon]="rotisserieFilter.isPicked ? icons.faEye : icons.faEyeSlash"
                            [fixedWidth]="true" style="font-size: var(--font-large);">
                          </fa-icon>
                        </div>

                        <div class="d-inline-block ms-2 h-100">
                          <input type="text" class="form-control form-control-sm"
                            placeholder="Filter by {{ rotisserieFilter.textSearch ? 'rules text' : 'name' }}"
                            style="min-width: 16rem; font-size: .875rem !important;"
                            [(ngModel)]="rotisserieFilter.search" (input)="refreshPipe()" />
                        </div>

                        <div class="ms-3 mt-1 form-check form-switch">
                          <input type="checkbox" class="form-check-input" id="nameSearch" (change)="refreshPipe()"
                            [(ngModel)]="rotisserieFilter.textSearch">
                          <label class="form-check-label" for="nameSearch">Rules text</label>
                        </div>

                      </div>
                    </div>

                    <div class="outer-wrapper right">
                      <div class="outer-wrapper me-3">

                        <div class="colors-wrapper">
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.colors.white }" ngbTooltip="Toggle white cards"
                            container="body"
                            (click)="rotisserieFilter.colors.white = !rotisserieFilter.colors.white; refreshPipe()">
                            <i class="ms ms-w ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.colors.blue }" ngbTooltip="Toggle blue cards"
                            container="body"
                            (click)="rotisserieFilter.colors.blue = !rotisserieFilter.colors.blue; refreshPipe()">
                            <i class="ms ms-u ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.colors.black }" ngbTooltip="Toggle black cards"
                            container="body"
                            (click)="rotisserieFilter.colors.black = !rotisserieFilter.colors.black; refreshPipe()">
                            <i class="ms ms-b ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.colors.red }" ngbTooltip="Toggle red cards"
                            container="body"
                            (click)="rotisserieFilter.colors.red = !rotisserieFilter.colors.red; refreshPipe()">
                            <i class="ms ms-r ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.colors.green }" ngbTooltip="Toggle green cards"
                            container="body"
                            (click)="rotisserieFilter.colors.green = !rotisserieFilter.colors.green; refreshPipe()">
                            <i class="ms ms-g ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.colors.colorless }"
                            ngbTooltip="Toggle colorless cards" container="body"
                            (click)="rotisserieFilter.colors.colorless = !rotisserieFilter.colors.colorless; refreshPipe()">
                            <i class="ms ms-c ms-fw"></i>
                          </div>
                        </div>

                      </div>

                      <div class="outer-wrapper ms-3 me-3">

                        <div class="types-wrapper">
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.types.land }" ngbTooltip="Toggle land cards"
                            container="body"
                            (click)="rotisserieFilter.types.land = !rotisserieFilter.types.land; refreshPipe()">
                            <i class="ms ms-land ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.types.enchantment }"
                            ngbTooltip="Toggle enchantment cards" container="body"
                            (click)="rotisserieFilter.types.enchantment = !rotisserieFilter.types.enchantment; refreshPipe()">
                            <i class="ms ms-enchantment ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.types.artifact }"
                            ngbTooltip="Toggle artifact cards" container="body"
                            (click)="rotisserieFilter.types.artifact = !rotisserieFilter.types.artifact; refreshPipe()">
                            <i class="ms ms-artifact ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.types.creature }"
                            ngbTooltip="Toggle creature cards" container="body"
                            (click)="rotisserieFilter.types.creature = !rotisserieFilter.types.creature; refreshPipe()">
                            <i class="ms ms-creature ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.types.sorcery }"
                            ngbTooltip="Toggle sorcery cards" container="body"
                            (click)="rotisserieFilter.types.sorcery = !rotisserieFilter.types.sorcery; refreshPipe()">
                            <i class="ms ms-sorcery ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.types.instant }"
                            ngbTooltip="Toggle instant cards" container="body"
                            (click)="rotisserieFilter.types.instant = !rotisserieFilter.types.instant; refreshPipe()">
                            <i class="ms ms-instant ms-fw"></i>
                          </div>
                        </div>

                      </div>

                      <div class="outer-wrapper ms-3">
                        <button class="btn btn-sm btn-blank text-danger"
                          (click)="resetRotisserieFilters()">Clear</button>
                      </div>
                    </div>

                  </div>

                  <div class="main-content d-flex h-100">

                    <div class="card-list flex-grow-1 d-flex flex-wrap align-content-start justify-content-center" *ngIf="showCards">
                      <ng-container *ngIf="cards$ | async as cardItems; else loadingCards">
                        <!-- | draftRotisserieCards : rotisserieFilter -->
                        <ng-container
                          *ngFor="let cardItem of cardItems | draftRotisserieCards : rotisserieFilter : pipeRefresher; trackBy : trackByCardInList">

                          <div class="card-wrapper zoom-level-{{ cardZoomLevel }} {{ cardItem.card.keyruneCode }}"
                            [ngClass]="{ 'picked' : cardItem.card.isPicked, 'selected' : cardItem.savedByPlayerDocIds.includes(auth.user.playerId) }"
                            [ngbTooltip]="cardItem.card.name" container="body"
                            (mouseenter)="previewCard = cardItem.card">
                            <div class="picked-card" *ngIf="cardItem.card.isPicked">
                              <div class="picked-card-player-wrapper">
                                <app-player-avatar [playerDocId]="cardItem.card.pickedByPlayerDocId" [round]="false"
                                  size="fill" [border]="false"></app-player-avatar>
                              </div>
                            </div>
                            <div class="card-image-wrapper">
                              <img [src]="cardItem.card.imageUrl" />
                            </div>

                            <div class="actions d-flex align-items-center" *ngIf="!cardItem.card.isPicked">
                              <button class="btn btn-blank flex-grow-1" ngbTooltip="Pick card" container="body"
                                [ngClass]="{ 'text-secondary' : draft.activePlayerDocId !== auth.user.playerId }"
                                [disabled]="draft.activePlayerDocId !== auth.user.playerId"
                                (click)="pickCard(draft, null, cardItem.card, cardItem, cardItems)">
                                <fa-icon [icon]="icons.faCheckCircle" [fixedWidth]="true"></fa-icon>
                              </button>
                              <button class="btn btn-blank flex-grow-1" ngbTooltip="Remove from saved" container="body"
                                *ngIf="cardItem.savedByPlayerDocIds.includes(auth.user.playerId)"
                                (click)="unsaveCard(cardItem.docId, draft.docId)">
                                <fa-icon [icon]="icons.faTrashAlt" [fixedWidth]="true"></fa-icon>
                              </button>
                              <button class="btn btn-blank flex-grow-1" ngbTooltip="Save card" container="body"
                                *ngIf="!cardItem.savedByPlayerDocIds.includes(auth.user.playerId)"
                                (click)="saveCard(cardItem.docId, draft.docId)">
                                <fa-icon [icon]="icons.faSave" [fixedWidth]="true"></fa-icon>
                              </button>
                            </div>

                          </div>

                        </ng-container>
                      </ng-container>
                      <ng-template #loadingCards>
                        <div class="w-100 h-100 d-flex align-items-center align-content-center justify-content-center">
                          <div
                            class="content-wrapper d-flex flex-column align-items-center align-content-center justify-content-center">
                            <div class="info-content-icons d-flex mb-5 text-secondary justify-content-center">
                              <div class="pack-details m-3 d-flex flex-column align-items-center"
                                *ngFor="let set of draft.sets">
                                <ng-container *ngIf="set.customIcon; else keyruneIcon">
                                  <fa-icon [icon]="fontIcons.setIcons[set.keyruneCode]" [fixedWidth]="true"
                                    style="font-size: 4em;"></fa-icon>
                                </ng-container>
                                <ng-template #keyruneIcon>
                                  <i class="mb-3 ss ss-4x ss-fw ss-{{ set.keyruneCode }}"></i>
                                </ng-template>
                                <span class="text-smaller">{{ set.name }}</span>
                              </div>
                            </div>
                            <div class="text-secondary mt-3 mb-3">Loading cards</div>
                            <div class="lds-spinner">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </div>

                    <div class="pick-list d-flex flex-column flex-grow-1 align-content-start justify-content-center" *ngIf="showPicks">

                      <div class="flex-grow-0 p-3">
                        <ng-container *ngFor="let playerDocId of draft.seatedPlayers">
                          <div class="btn btn-sm btn-blank me-3"
                            [ngClass]="{ 'text-secondary' : selectedPlayerDocId !== playerDocId }"
                            (click)="selectedPlayerDocId = playerDocId">
                            <app-player-display-name class="mt-3" [playerDocId]="playerDocId"></app-player-display-name>
                          </div>
                        </ng-container>
                      </div>

                      <div class="card-list flex-grow-1 d-flex flex-wrap align-content-start justify-content-center">
                        <ng-container *ngIf="picks$ | async as picks; else loadingPicks">
                          <!-- | draftRotisserieCards : rotisserieFilter -->
                          <ng-container *ngFor="let pick of picks | draftRotisseriePicks : selectedPlayerDocId">

                            <div class="card-wrapper zoom-level-{{ cardZoomLevel }} {{ pick.card.keyruneCode }}"
                              [ngbTooltip]="pick.card.name" container="body" (mouseenter)="previewCard = pick.card">

                              <div class="card-image-wrapper">
                                <img [src]="pick.card.imageUrl" />
                              </div>

                            </div>

                          </ng-container>
                        </ng-container>
                        <ng-template #loadingPicks>
                          <div
                            class="w-100 h-100 d-flex align-items-center align-content-center justify-content-center">
                            <div
                              class="content-wrapper d-flex flex-column align-items-center align-content-center justify-content-center">
                              <div class="info-content-icons d-flex mb-5 text-secondary justify-content-center">
                                <div class="pack-details m-3 d-flex flex-column align-items-center"
                                  *ngFor="let set of draft.sets">
                                  <ng-container *ngIf="set.customIcon; else keyruneIcon">
                                    <fa-icon [icon]="fontIcons.setIcons[set.keyruneCode]" [fixedWidth]="true"
                                      style="font-size: 4em;"></fa-icon>
                                  </ng-container>
                                  <ng-template #keyruneIcon>
                                    <i class="mb-3 ss ss-4x ss-fw ss-{{ set.keyruneCode }}"></i>
                                  </ng-template>
                                  <span class="text-smaller">{{ set.name }}</span>
                                </div>
                              </div>
                              <div class="text-secondary mt-3 mb-3">Loading picks</div>
                              <div class="lds-spinner">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>

                    <div class="card-preview flex-grow-0 p-3" *ngIf="previewCard">
                      <div class="card-wrapper zoom-level-3 mb-3" style="margin: 0">
                        <div class="card-image-wrapper"><img [src]="previewCard.imageUrl" /></div>
                      </div>
                      <div class="name-cmc-wrapper d-flex align-items-center mb-2">
                        <span class="name flex-grow-1 text-bold text-large">{{ previewCard.name }}</span>
                        <span class="card-cmc flex-grow-0 ms-2"
                          [innerHTML]="previewCard.manaCost | manaParser : { shadowed: true, fontClass: 'text-medium' }"></span>
                      </div>
                      <div class="card-type text-bold mb-2">{{ previewCard.type }}</div>
                      <div class="card-text mb-1"
                        [innerHTML]="previewCard.text | manaParser : { fontClass: 'text-small', shadowed: true }">
                      </div>
                      <div class="carc-power-toughness mb-1" *ngIf="previewCard.power !== ''">
                        {{ previewCard.power }}/{{ previewCard.toughness }}
                      </div>
                    </div>
                  </div>

                </div>

              </ng-container>

            </ng-container>

            <ng-container *ngSwitchCase="'finished'">

              <ng-container *ngIf="draft.isRotisserie; else showDraftedPool">

                <div class="rotisserie-draft-wrapper d-flex flex-column">

                  <div class="top-bar d-flex flex-wrap align-items-center p-3" *ngIf="draft.statusCode === 4">

                    <div class="outer-wrapper left">
                      <div class="outer-wrapper me-3">

                        <div class="btn btn-sm btn-blank me-3" [ngClass]="{ 'text-secondary' : !showCards }"
                          (click)="showCards = true; showPicks = false;">
                          Cards
                        </div>
                        <ng-container *ngIf="picks$ | async as picks">
                          <div class="btn btn-sm btn-blank me-3" [ngClass]="{ 'text-secondary' : !showPicks }"
                            (click)="showCards = false; showPicks = true;">
                            Picks<span class="ms-2 badge rounded-pill text-bg-secondary">{{ (picks | draftRotisseriePicks :
                              auth.user.playerId).length }}</span>
                          </div>
                        </ng-container>

                      </div>

                      <div class="outer-wrapper ms-3">

                        <div class="btn btn-sm btn-blank" [ngClass]="{ 'text-secondary' : !rotisserieFilter.onlyShowSaved }"
                          (click)="rotisserieFilter.onlyShowSaved = !rotisserieFilter.onlyShowSaved; refreshPipe()"
                          [ngbTooltip]="rotisserieFilter.onlyShowSaved ? 'Show all cards' : 'Only show saved cards'"
                          container="body">
                          <fa-icon [icon]="icons.faSave" [fixedWidth]="true" style="font-size: var(--font-large);">
                          </fa-icon>
                        </div>

                        <div class="btn btn-sm btn-blank" [ngClass]="{ 'text-secondary' : !rotisserieFilter.isPicked }"
                          (click)="rotisserieFilter.isPicked = !rotisserieFilter.isPicked; refreshPipe()"
                          [ngbTooltip]="rotisserieFilter.isPicked ? 'Hide picked cards' : 'Show picked cards'"
                          container="body">
                          <fa-icon [icon]="rotisserieFilter.isPicked ? icons.faEye : icons.faEyeSlash"
                            [fixedWidth]="true" style="font-size: var(--font-large);">
                          </fa-icon>
                        </div>

                        <div class="d-inline-block ms-2 h-100">
                          <input type="text" class="form-control form-control-sm"
                            placeholder="Filter by {{ rotisserieFilter.textSearch ? 'rules text' : 'name' }}"
                            style="min-width: 16rem; font-size: .875rem !important;"
                            [(ngModel)]="rotisserieFilter.search" (input)="refreshPipe()" />
                        </div>

                        <div class="ms-3 mt-1 form-check form-switch">
                          <input type="checkbox" class="form-check-input" id="nameSearch" (change)="refreshPipe()"
                            [(ngModel)]="rotisserieFilter.textSearch">
                          <label class="form-check-label" for="nameSearch">Rules text</label>
                        </div>

                      </div>
                    </div>

                    <div class="outer-wrapper right">
                      <div class="outer-wrapper me-3">

                        <div class="colors-wrapper">
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.colors.white }" ngbTooltip="Toggle white cards"
                            container="body"
                            (click)="rotisserieFilter.colors.white = !rotisserieFilter.colors.white; refreshPipe()">
                            <i class="ms ms-w ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.colors.blue }" ngbTooltip="Toggle blue cards"
                            container="body"
                            (click)="rotisserieFilter.colors.blue = !rotisserieFilter.colors.blue; refreshPipe()">
                            <i class="ms ms-u ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.colors.black }" ngbTooltip="Toggle black cards"
                            container="body"
                            (click)="rotisserieFilter.colors.black = !rotisserieFilter.colors.black; refreshPipe()">
                            <i class="ms ms-b ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.colors.red }" ngbTooltip="Toggle red cards"
                            container="body"
                            (click)="rotisserieFilter.colors.red = !rotisserieFilter.colors.red; refreshPipe()">
                            <i class="ms ms-r ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.colors.green }" ngbTooltip="Toggle green cards"
                            container="body"
                            (click)="rotisserieFilter.colors.green = !rotisserieFilter.colors.green; refreshPipe()">
                            <i class="ms ms-g ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.colors.colorless }"
                            ngbTooltip="Toggle colorless cards" container="body"
                            (click)="rotisserieFilter.colors.colorless = !rotisserieFilter.colors.colorless; refreshPipe()">
                            <i class="ms ms-c ms-fw"></i>
                          </div>
                        </div>

                      </div>

                      <div class="outer-wrapper ms-3 me-3">

                        <div class="types-wrapper">
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.types.land }" ngbTooltip="Toggle land cards"
                            container="body"
                            (click)="rotisserieFilter.types.land = !rotisserieFilter.types.land; refreshPipe()">
                            <i class="ms ms-land ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.types.enchantment }"
                            ngbTooltip="Toggle enchantment cards" container="body"
                            (click)="rotisserieFilter.types.enchantment = !rotisserieFilter.types.enchantment; refreshPipe()">
                            <i class="ms ms-enchantment ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.types.artifact }"
                            ngbTooltip="Toggle artifact cards" container="body"
                            (click)="rotisserieFilter.types.artifact = !rotisserieFilter.types.artifact; refreshPipe()">
                            <i class="ms ms-artifact ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.types.creature }"
                            ngbTooltip="Toggle creature cards" container="body"
                            (click)="rotisserieFilter.types.creature = !rotisserieFilter.types.creature; refreshPipe()">
                            <i class="ms ms-creature ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.types.sorcery }"
                            ngbTooltip="Toggle sorcery cards" container="body"
                            (click)="rotisserieFilter.types.sorcery = !rotisserieFilter.types.sorcery; refreshPipe()">
                            <i class="ms ms-sorcery ms-fw"></i>
                          </div>
                          <div class="btn btn-sm btn-blank text-secondary"
                            [ngClass]="{ 'selected' : rotisserieFilter.types.instant }"
                            ngbTooltip="Toggle instant cards" container="body"
                            (click)="rotisserieFilter.types.instant = !rotisserieFilter.types.instant; refreshPipe()">
                            <i class="ms ms-instant ms-fw"></i>
                          </div>
                        </div>

                      </div>

                      <div class="outer-wrapper ms-3">
                        <button class="btn btn-sm btn-blank text-danger"
                          (click)="resetRotisserieFilters()">Clear</button>
                      </div>
                    </div>

                  </div>

                  <div class="main-content d-flex h-100">

                    <div class="card-list flex-grow-1 d-flex flex-wrap align-content-start justify-content-center" *ngIf="showCards">
                      <ng-container *ngIf="cards$ | async as cardItems; else loadingCards">
                        <!-- | draftRotisserieCards : rotisserieFilter -->
                        <ng-container
                          *ngFor="let cardItem of cardItems | draftRotisserieCards : rotisserieFilter : pipeRefresher; trackBy : trackByCardInList">

                          <div class="card-wrapper zoom-level-{{ cardZoomLevel }} {{ cardItem.card.keyruneCode }}"
                            [ngClass]="{ 'picked' : cardItem.card.isPicked, 'selected' : cardItem.savedByPlayerDocIds.includes(auth.user.playerId) }"
                            [ngbTooltip]="cardItem.card.name" container="body"
                            (mouseenter)="previewCard = cardItem.card">
                            <div class="picked-card" *ngIf="cardItem.card.isPicked">
                              <div class="picked-card-player-wrapper">
                                <app-player-avatar [playerDocId]="cardItem.card.pickedByPlayerDocId" [round]="false"
                                  size="fill" [border]="false"></app-player-avatar>
                              </div>
                            </div>
                            <div class="card-image-wrapper">
                              <img [src]="cardItem.card.imageUrl" />
                            </div>

                            <div class="actions d-flex align-items-center" *ngIf="!cardItem.card.isPicked">
                              <button class="btn btn-blank flex-grow-1" ngbTooltip="Pick card" container="body"
                                [ngClass]="{ 'text-secondary' : draft.activePlayerDocId !== auth.user.playerId }"
                                [disabled]="draft.activePlayerDocId !== auth.user.playerId"
                                (click)="pickCard(draft, null, cardItem.card, cardItem, cardItems)">
                                <fa-icon [icon]="icons.faCheckCircle" [fixedWidth]="true"></fa-icon>
                              </button>
                              <button class="btn btn-blank flex-grow-1" ngbTooltip="Remove from saved" container="body"
                                *ngIf="cardItem.savedByPlayerDocIds.includes(auth.user.playerId)"
                                (click)="unsaveCard(cardItem.docId, draft.docId)">
                                <fa-icon [icon]="icons.faTrashAlt" [fixedWidth]="true"></fa-icon>
                              </button>
                              <button class="btn btn-blank flex-grow-1" ngbTooltip="Save card" container="body"
                                *ngIf="!cardItem.savedByPlayerDocIds.includes(auth.user.playerId)"
                                (click)="saveCard(cardItem.docId, draft.docId)">
                                <fa-icon [icon]="icons.faSave" [fixedWidth]="true"></fa-icon>
                              </button>
                            </div>

                          </div>

                        </ng-container>
                      </ng-container>
                      <ng-template #loadingCards>
                        <div class="w-100 h-100 d-flex align-items-center align-content-center justify-content-center">
                          <div
                            class="content-wrapper d-flex flex-column align-items-center align-content-center justify-content-center">
                            <div class="info-content-icons d-flex mb-5 text-secondary justify-content-center">
                              <div class="pack-details m-3 d-flex flex-column align-items-center"
                                *ngFor="let set of draft.sets">
                                <ng-container *ngIf="set.customIcon; else keyruneIcon">
                                  <fa-icon [icon]="fontIcons.setIcons[set.keyruneCode]" [fixedWidth]="true"
                                    style="font-size: 4em;"></fa-icon>
                                </ng-container>
                                <ng-template #keyruneIcon>
                                  <i class="mb-3 ss ss-4x ss-fw ss-{{ set.keyruneCode }}"></i>
                                </ng-template>
                                <span class="text-smaller">{{ set.name }}</span>
                              </div>
                            </div>
                            <div class="text-secondary mt-3 mb-3">Loading cards</div>
                            <div class="lds-spinner">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </div>

                    <div class="pick-list d-flex flex-column flex-grow-1 align-content-start justify-content-center" *ngIf="showPicks">

                      <div class="flex-grow-0 p-3">
                        <ng-container *ngFor="let playerDocId of draft.seatedPlayers">
                          <div class="btn btn-sm btn-blank me-3"
                            [ngClass]="{ 'text-secondary' : selectedPlayerDocId !== playerDocId }"
                            (click)="selectedPlayerDocId = playerDocId">
                            <app-player-display-name class="mt-3" [playerDocId]="playerDocId"></app-player-display-name>
                          </div>
                        </ng-container>
                      </div>

                      <div class="card-list flex-grow-1 d-flex flex-wrap align-content-start justify-content-center">
                        <ng-container *ngIf="picks$ | async as picks; else loadingPicks">
                          <!-- | draftRotisserieCards : rotisserieFilter -->
                          <ng-container *ngFor="let pick of picks | draftRotisseriePicks : selectedPlayerDocId">

                            <div class="card-wrapper zoom-level-{{ cardZoomLevel }} {{ pick.card.keyruneCode }}"
                              [ngbTooltip]="pick.card.name" container="body" (mouseenter)="previewCard = pick.card">

                              <div class="card-image-wrapper">
                                <img [src]="pick.card.imageUrl" />
                              </div>

                            </div>

                          </ng-container>
                        </ng-container>
                        <ng-template #loadingPicks>
                          <div
                            class="w-100 h-100 d-flex align-items-center align-content-center justify-content-center">
                            <div
                              class="content-wrapper d-flex flex-column align-items-center align-content-center justify-content-center">
                              <div class="info-content-icons d-flex mb-5 text-secondary justify-content-center">
                                <div class="pack-details m-3 d-flex flex-column align-items-center"
                                  *ngFor="let set of draft.sets">
                                  <ng-container *ngIf="set.customIcon; else keyruneIcon">
                                    <fa-icon [icon]="fontIcons.setIcons[set.keyruneCode]" [fixedWidth]="true"
                                      style="font-size: 4em;"></fa-icon>
                                  </ng-container>
                                  <ng-template #keyruneIcon>
                                    <i class="mb-3 ss ss-4x ss-fw ss-{{ set.keyruneCode }}"></i>
                                  </ng-template>
                                  <span class="text-smaller">{{ set.name }}</span>
                                </div>
                              </div>
                              <div class="text-secondary mt-3 mb-3">Loading picks</div>
                              <div class="lds-spinner">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>

                    <div class="card-preview flex-grow-0 p-3" *ngIf="previewCard">
                      <div class="card-wrapper zoom-level-3 mb-3" style="margin: 0">
                        <div class="card-image-wrapper"><img [src]="previewCard.imageUrl" /></div>
                      </div>
                      <div class="name-cmc-wrapper d-flex align-items-center mb-2">
                        <span class="name flex-grow-1 text-bold text-large">{{ previewCard.name }}</span>
                        <span class="card-cmc flex-grow-0 ms-2"
                          [innerHTML]="previewCard.manaCost | manaParser : { shadowed: true, fontClass: 'text-medium' }"></span>
                      </div>
                      <div class="card-type text-bold mb-2">{{ previewCard.type }}</div>
                      <div class="card-text mb-1"
                        [innerHTML]="previewCard.text | manaParser : { fontClass: 'text-small', shadowed: true }">
                      </div>
                      <div class="carc-power-toughness mb-1" *ngIf="previewCard.power !== ''">
                        {{ previewCard.power }}/{{ previewCard.toughness }}
                      </div>
                    </div>
                  </div>

                </div>

              </ng-container>

              <ng-template #showDraftedPool>
                <ng-container *ngIf="playerPicks$ | async as picks">
                  <ng-container *ngFor="let pick of picks">
                    <app-booster-card [card]="pick.card" [zoomLevel]="cardZoomLevel" [onlyCard]="true">
                    </app-booster-card>
                  </ng-container>

                  <ng-container *ngIf="draftDeck$ | async as decks">
                    <div class="create-deck-button-wrapper">
                      <button class="btn btn-sm btn-blank" (click)="createDeck(draft, picks)" [disabled]="decks.length > 0">
                        <fa-icon [icon]="icons.faBriefCase" [fixedWidth]="true" class="me-2"></fa-icon>Create Deck
                      </button>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-template>

            </ng-container>
          </ng-container>

        </div>

        <div class="card-zoom-selector-wrapper" ngbTooltip="Change visible card size" container="body">
          <button class="btn btn-sm btn-blank" [disabled]="true">
            <fa-icon [icon]="icons.faSearch" [fixedWidth]="true"></fa-icon>
          </button>
          <button class="btn btn-sm btn-blank" [disabled]="cardZoomLevel === 1"
            (click)="cardZoomLevel = cardZoomLevel - 1">
            <fa-icon [icon]="icons.faMinusSquare" [fixedWidth]="true"></fa-icon>
          </button>
          <button class="btn btn-sm btn-blank" [disabled]="cardZoomLevel === 3"
            (click)="cardZoomLevel = cardZoomLevel + 1">
            <fa-icon [icon]="icons.faPlusSquare" [fixedWidth]="true"></fa-icon>
          </button>
        </div>

        <ng-container *ngIf="draft.statusCode < 5">
          <ng-container *ngIf="playerPicks$ | async as picks">

            <div class="picks-toggler-button d-flex align-items-center"
              [ngbTooltip]="showPicksPanel ? 'Hide picks' : 'Show picks'" container="body"
              (click)="showPicksPanel = !showPicksPanel">
              <fa-icon [icon]="icons.faChevronLeft" [fixedWidth]="true" class="me-2"
                [ngClass]="{ 'rotate-normal-180deg' : showPicksPanel }"></fa-icon>
              <span>{{ picks.length }}</span>
            </div>


            <div class="picks flex-grow-0 d-flex flex-column" *ngIf="showPicksPanel">

              <div class="picks-header text-large p-3 flex-grow-0">Picks</div>

              <div class="picks-list me-1 flex-grow-1">
                <div class="pick-list-item d-flex align-items-center"
                  *ngFor="let pick of picks | draftPicks : pickOptions : pipeRefresher"
                  (mouseenter)="showPreview = true; previewCard = pick.card"
                  (mouseleave)="showPreview = false; previewCard = null">
                  <div class="card-qty badge text-bg-secondary flex-grow-0 me-2" *ngIf="pickOptions.groupCards">{{ pick.qty
                    }}
                  </div>
                  <div class="card-name flex-grow-0 me-2" [ngbTooltip]="pick.card.typeSymbol" container="body"
                    placement="top"><i class="ms ms-{{ pick.card.typeSymbol }} ms-fw"></i></div>
                  <div class="card-name flex-grow-1">{{ pick.card.name }}</div>
                  <div class="card-cmc flex-grow-0 ms-2"
                    [innerHTML]="pick.card.manaCost | manaParser : { shadowed: true, fontClass: 'text-smaller' }"></div>
                </div>
              </div>

              <div class="pick-preview {{ previewCard.keyruneCode }}" *ngIf="showPreview">
                <img [src]="previewCard.imageUrl">
              </div>

              <div class="picks-footer flex-grow-0 d-flex flex-column pt-2 pb-2">

                <div class="ps-3 pe-3 mb-1 d-flex align-items-center">
                  <div class="btn btn-sm btn-blank text-secondary" [ngClass]="{ 'selected' : pickOptions.groupCards }"
                    ngbTooltip="Toggle grouping of cards" container="body" placement="top"
                    (click)="pickOptions.groupCards = !pickOptions.groupCards; refreshPipe()">
                    <fa-icon [icon]="icons.faLayerGroup" [fixedWidth]="true"></fa-icon>
                  </div>
                  <div class="btn btn-sm btn-blank text-secondary" [ngClass]="{ 'selected' : pickOptions.isAscending }"
                    ngbTooltip="Sort ascending" container="body" placement="top"
                    (click)="pickOptions.isAscending = true; refreshPipe()">
                    <fa-icon [icon]="icons.faSortAmountDownAlt" [fixedWidth]="true"></fa-icon>
                  </div>
                  <div class="btn btn-sm btn-blank text-secondary" [ngClass]="{ 'selected' : !pickOptions.isAscending }"
                    ngbTooltip="Sort descending" container="body" placement="top"
                    (click)="pickOptions.isAscending = false; refreshPipe()">
                    <fa-icon [icon]="icons.faSortAmountDown" [fixedWidth]="true"></fa-icon>
                  </div>
                  <div class="wrapper flex-grow-1 text-end">
                    <div ngbDropdown placement="top-right" class="d-inline-block">
                      <div class="btn btn-sm btn-blank" id="sortBy" ngbDropdownToggle ngbTooltip="Sort by pick order"
                        container="body" placement="top-right">Sort by</div>
                      <div ngbDropdownMenu aria-labelledby="sortBy">
                        <button class="text-medium" ngbDropdownItem
                          (click)="pickOptions.sortBy = 'pickOrder'; refreshPipe()">Pick Order</button>
                        <button class="text-medium" ngbDropdownItem
                          (click)="pickOptions.sortBy = 'cmc'; refreshPipe()">Converted Mana Cost</button>
                        <button class="text-medium" ngbDropdownItem
                          (click)="pickOptions.sortBy = 'type'; refreshPipe()">Type</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ps-3 pe-3 d-flex align-items-center">
                  <div class="btn btn-sm btn-blank text-secondary" [ngClass]="{ 'selected' : pickOptions.colors.white }"
                    ngbTooltip="Toggle white cards" container="body"
                    (click)="pickOptions.colors.white = !pickOptions.colors.white; refreshPipe()">
                    <i class="ms ms-w ms-fw"></i>
                  </div>
                  <div class="btn btn-sm btn-blank text-secondary" [ngClass]="{ 'selected' : pickOptions.colors.blue }"
                    ngbTooltip="Toggle blue cards" container="body"
                    (click)="pickOptions.colors.blue = !pickOptions.colors.blue; refreshPipe()">
                    <i class="ms ms-u ms-fw"></i>
                  </div>
                  <div class="btn btn-sm btn-blank text-secondary" [ngClass]="{ 'selected' : pickOptions.colors.black }"
                    ngbTooltip="Toggle black cards" container="body"
                    (click)="pickOptions.colors.black = !pickOptions.colors.black; refreshPipe()">
                    <i class="ms ms-b ms-fw"></i>
                  </div>
                  <div class="btn btn-sm btn-blank text-secondary" [ngClass]="{ 'selected' : pickOptions.colors.red }"
                    ngbTooltip="Toggle red cards" container="body"
                    (click)="pickOptions.colors.red = !pickOptions.colors.red; refreshPipe()">
                    <i class="ms ms-r ms-fw"></i>
                  </div>
                  <div class="btn btn-sm btn-blank text-secondary" [ngClass]="{ 'selected' : pickOptions.colors.green }"
                    ngbTooltip="Toggle green cards" container="body"
                    (click)="pickOptions.colors.green = !pickOptions.colors.green; refreshPipe()">
                    <i class="ms ms-g ms-fw"></i>
                  </div>
                  <div class="btn btn-sm btn-blank text-secondary" [ngClass]="{ 'selected' : pickOptions.colors.colorless }"
                    ngbTooltip="Toggle colorless cards" container="body"
                    (click)="pickOptions.colors.colorless = !pickOptions.colors.colorless; refreshPipe()">
                    <i class="ms ms-c ms-fw"></i>
                  </div>
                </div>

              </div>

            </div>

          </ng-container>
        </ng-container>

      </div>

    </div>


    <div class="chat-wrapper flex-grow-0 d-flex flex-column h-100">
      <div #chatWrapper class="message-list-wrapper flex-grow-1 ps-1" [scrollTop]="chatWrapper.scrollHeight">
        <app-message-list [messageGroupDocId]="'draftChat[' + draft.docId  + ']'"></app-message-list>
      </div>
      <div class="message-form-wrapper flex-grow-0 p-2 position-relative">
        <tolaria-message-form [messageGroupDocId]="'draftChat[' + draft.docId  + ']'"></tolaria-message-form>
      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="!isAttending">
    <div class="container p-5" style="max-width: 40vw;">
      <div class="h3 p-5 text-center">You are not attending this draft, please contact the organizer.</div>
      <div class="d-flex flex-column align-content-center align-items-center justify-content-center">
        <div class="avatar-wrapper" style="border-radius: 1rem; overflow: hidden;">
          <app-player-avatar [playerDocId]="draft.createdByPlayerDocId" [round]="false" size="fill" [border]="false">
          </app-player-avatar>
        </div>
        <app-player-display-name class="mt-3" [playerDocId]="draft.createdByPlayerDocId"></app-player-display-name>
      </div>
    </div>
  </ng-container>


</div>

<ng-template #deckCreationConfirmation let-modal>
  <div class="modal-body">
    Your deck was successfully created and you will be able to further work with it in the deck builder.
  </div>
  <div class="modal-footer d-flex align-items-center justify-content-center">
    <button class="btn btn-sm btn-blank text-center" (click)="modal.close()">Ok</button>
  </div>
</ng-template>

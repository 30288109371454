export const defaultBages = [
  {
    isClub: false,
    name: 'isHero',
    imageUrl: 'assets/overlays/hero.jpg',
    tooltipHtmlContent: `
  <h3>Hero</h3>
  <p>According to Tolarian traditions, you should let the hero decide who will play first whenever battle one.</p>
  <hr>
  <p class="text-secondary text-italic">Become a Tolaria Supporter and get rewarded with the hero badge.</p>`,
  },
  {
    isClub: false,
    name: 'isDingus',
    imageUrl: 'assets/overlays/dingus.jpg',
    tooltipHtmlContent: `
  <p>Once a <b>Dingus</b>,
  <br>always a <b>DINGUS</b>!</p>`,
  },
  {
    isClub: false,
    name: 'isGuardian',
    imageUrl: 'assets/overlays/guardian.jpg',
    tooltipHtmlContent: `
  <h3>Guardian</h3>
  <p>Guardians of Tolaria are a rare species that serve to the keep peace and order.</p>
  <p class="text-italic">If you have an issue or want to report something, contact a <b>Guardian</b</p>`,
  },
  {
    isClub: false,
    name: 'hasShark',
    imageUrl: 'assets/overlays/shark.jpg',
    tooltipHtmlContent: `
  <h3>Aaaahhh, there's a <b>Shark</b> in the water</h3>
  <p><i>Not much else to say here...this player is a former World Champion or Shark tournament Winner!</i></p>
  <hr>
  <p class="text-secondary">Stay focused and out of the water</p>`,
  }
]
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Subject, takeUntil } from 'rxjs';
import { ContentReadyService } from 'src/app/services/content-ready.service';
import { RouteWatcherService } from 'src/app/services/route-watcher.service';
import { applicationIcon } from 'src/assets/font-icons/tolaria-icon';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.css'],
  standalone : true,
  imports: [
    FontAwesomeModule,
    CommonModule,
  ]
})
export class LoadingScreenComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<boolean>()

  constructor(
    public readonly content: ContentReadyService,
    private readonly router: Router,
    private readonly watcher: RouteWatcherService,
  ) { }

  ngOnInit() {
    this.content.contentReady$.pipe(takeUntil(this.destroyed$)).subscribe(i => {
      if (i) {
        // this.router.navigate(['/dashboard'])
        this.watcher.navigate()
      }
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public icon = {
    loaded: faCheckCircle,
    app: applicationIcon,
  }
}

<ng-container *ngIf="matchData$ | async as match">

  <div class="modal-header">
    <h5 class="modal-title">Report Slip</h5>
    <div class="btn-close float-end" (click)="closeReportSlipByButton()">
      <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
    </div>
  </div>


  <div class="modal-body scrollable" style="max-height: calc(100vh - 12.5rem);">
    <ng-container *ngIf="this.matchData">
      <div class="report-slip-top">

        <div class="bd-callout bd-callout-muted mb-3" *ngIf="twoPlayerReportingActive && !openedByMe">
          Report slip opened by <app-player-display-name [playerDocId]="matchData.reportSlip.openedByPlayerDocId"></app-player-display-name>.
          Plaese wait for the player to fill out the report slip. When done, you will be able to confirm the result.
        </div>

        <div class="row mb-2">
          <div class="col text-center">WINNER</div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <div class="btn btn-report btn-name {{ matchData.player1.isWinner ? 'selected' : '' }}"
              (click)="setMatchResult(false, true, false, 0, 0, 0, matchData.player1.drop, matchData.player2.drop)"
              placement="top-left" ngbTooltip="Keyboard: 7">
              {{ matchData.player1.displayName }}
            </div>
          </div>
          <div class="col">
            <div class="btn btn-report btn-name {{ matchData.player2.isWinner ? 'selected' : '' }}"
              (click)="setMatchResult(false, false, true, 0, 0, 0, matchData.player1.drop, matchData.player2.drop)"
              placement="top-right" ngbTooltip="Keyboard: 9">
              {{ matchData.player2.displayName }}
            </div>
          </div>
        </div>

        <div class="row mb-5" *ngIf="matchData.isType !== 'bracket'">
          <div class="col">
            <div class="btn btn-report btn-draw {{ matchData.isDraw ? 'selected' : '' }}"
              (click)="setMatchResult(true, false, false, 0, 0, 0, matchData.player1.drop, matchData.player2.drop)"
              placement="top" ngbTooltip="Keyboard: 5">
              DRAW
            </div>
          </div>
        </div>

        <div class="row mb-2" *ngIf="matchData.player1.isWinner || matchData.player2.isWinner || matchData.isDraw">
          <div class="col text-center">RESULT</div>
        </div>
        <!-- player 1 report buttons -->
        <div class="row mb-5 game-wins-wrapper" *ngIf="matchData.player1.isWinner">
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchData.player1.isWinner && matchData.player1.wins === 2 && matchData.player2.wins === 0 ? 'selected' : '' }}"
              (click)="setMatchResult(false, true, false, 2, 0, 0, matchData.player1.drop, matchData.player2.drop)"
              placement="top-left" ngbTooltip="Keyboard: 1">2 - 0</div>
          </div>
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchData.player1.isWinner && matchData.player1.wins === 2 && matchData.player2.wins === 1 ? 'selected' : '' }}"
              (click)="setMatchResult(false, true, false, 2, 1, 0, matchData.player1.drop, matchData.player2.drop)"
              placement="top" ngbTooltip="Keyboard: 2">2 - 1</div>
          </div>
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchData.player1.isWinner && matchData.player1.wins === 1 && matchData.player2.wins === 0 ? 'selected' : '' }}"
              (click)="setMatchResult(false, true, false, 1, 0, 0, matchData.player1.drop, matchData.player2.drop)"
              placement="top-right" ngbTooltip="Keyboard: 3">1 - 0</div>
          </div>
        </div>

        <!-- player 2 report buttons -->
        <div class="row mb-5 game-wins-wrapper" *ngIf="matchData.player2.isWinner">
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchData.player2.isWinner && matchData.player1.wins === 0 && matchData.player2.wins === 2 ? 'selected' : '' }}"
              (click)="setMatchResult(false, false, true, 0, 2, 0, matchData.player1.drop, matchData.player2.drop)"
              placement="top-left" ngbTooltip="Keyboard: 1">2 - 0</div>
          </div>
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchData.player2.isWinner && matchData.player1.wins === 1 && matchData.player2.wins === 2 ? 'selected' : '' }}"
              (click)="setMatchResult(false, false, true, 1, 2, 0, matchData.player1.drop, matchData.player2.drop)"
              placement="top" ngbTooltip="Keyboard: 2">2 - 1</div>
          </div>
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchData.player2.isWinner && matchData.player1.wins === 0 && matchData.player2.wins === 1 ? 'selected' : '' }}"
              (click)="setMatchResult(false, false, true, 0, 1, 0, matchData.player1.drop, matchData.player2.drop)"
              placement="top-right" ngbTooltip="Keyboard: 3">1 - 0</div>
          </div>
        </div>

        <!-- draw report buttons -->
        <div class="row mb-5 game-wins-wrapper" *ngIf="matchData.isDraw">
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchData.isDraw && matchData.player1.wins === 1 && matchData.player2.wins === 1 ? 'selected' : '' }}"
              (click)="setMatchResult(true, false, false, 1, 1, 0, matchData.player1.drop, matchData.player2.drop)"
              placement="top-left" ngbTooltip="Keyboard: 1">1 - 1</div>
          </div>
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchData.isDraw && matchData.player1.wins === 0 && matchData.player2.wins === 0 ? 'selected' : '' }}"
              (click)="setMatchResult(true, false, false, 0, 0, 0, matchData.player1.drop, matchData.player2.drop)"
              placement="top-right" ngbTooltip="Keyboard: 2">0 - 0</div>
          </div>
        </div>

        <div class="row mb-2" *ngIf="matchData.player1.isWinner || matchData.player2.isWinner || matchData.isDraw">
          <div class="col text-center">DRAWN GAMES</div>
        </div>
        <!-- drawn games stepper -->
        <div class="row mb-5" *ngIf="matchData.player1.isWinner || matchData.player2.isWinner || matchData.isDraw">
          <div class="col">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend" (click)="changeDrawnGames('decrease')" placement="top-left"
                ngbTooltip="Keyboard: -">
                <span class="input-group-text">
                  <fa-icon [fixedWidth]="true" [icon]="faMinus"></fa-icon>
                </span>
              </div>
              <input type="text" class="report-stepper" [value]="matchData.player1.draws" disabled>
              <div class="input-group-append" (click)="changeDrawnGames('increase')" placement="top-right"
                ngbTooltip="Keyboard: +">
                <span class="input-group-text">
                  <fa-icon [fixedWidth]="true" [icon]="faPlus"></fa-icon>
                </span>
              </div>
            </div>
            <div class="text-secondary text-center mt-2" *ngIf="matchData.isDraw">Intentional Draw (ID) should be
              reported 0-0 and with 3 drawn games</div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Don't show DROP section unless the match is BATCH or SWISS -->
    <div class="report-slip-bottom">
      <div class="row mb-2">
        <div class="col">Drop?</div>
      </div>
      <ng-container *ngIf="showDropOption; else dropInfo">
        <!-- drop selections -->
        <div class="row player-drop mb-2"
          *ngIf="matchData.player1.playerUid !== '*** BYE ***' && matchData.player1.playerUid !== '*** LOSS ***'"
          (click)="playerIs === 'player1' ? toggleDrop() : $event.preventDefault()">
          <div class="col" style="flex-grow: 0;">
            <fa-icon [fixedWidth]="true" [icon]="faSquare" *ngIf="!matchData.player1.drop"></fa-icon>
            <fa-icon [fixedWidth]="true" [icon]="faCheckSquare" *ngIf="matchData.player1.drop">
            </fa-icon>
          </div>
          <div class="col">{{ matchData.player1.displayName }}</div>
        </div>
        <div class="row player-drop mb-2"
          *ngIf="matchData.player2.playerUid !== '*** BYE ***' && matchData.player2.playerUid !== '*** LOSS ***'"
          (click)="playerIs === 'player2' ? toggleDrop() : $event.preventDefault()">
          <div class="col" style="flex-grow: 0;">
            <fa-icon [fixedWidth]="true" [icon]="faSquare" *ngIf="!matchData.player2.drop"></fa-icon>
            <fa-icon [fixedWidth]="true" [icon]="faCheckSquare" *ngIf="matchData.player2.drop">
            </fa-icon>
          </div>
          <div class="col">{{ matchData.player2.displayName }}</div>
        </div>
        <div class="alert alert-custom d-flex align-items-center text-warning my-3" *ngIf="playerAboutToDrop">
          <span class="text-huge me-3">
            <fa-icon [icon]="faWarning"></fa-icon>
          </span>
          <span>You are about to drop from the event, are you sure!</span>
        </div>
      </ng-container>
      <ng-template #dropInfo>
        <div class="alert alert-custom d-flex align-items-center text-warning my-3">
          If you like to drop from the event, please inform the tournament organizer by sending a message in the
          event lobby. (For batch events, you will be able to drop when you have finished all your matches)
        </div>
      </ng-template>
    </div>
  </div>


  <div class="modal-footer d-flex justify-content-start flex-nowrap align-content-between">

    <ng-container *ngIf="twoPlayerReportingActive">
      <button class="btn btn-sm text-normal" (click)="toggleConfirm()" placement="top-right"
        ngbTooltip="Confirm entered result" [disabled]="disableConfirmation">
        <fa-icon [fixedWidth]="true" [icon]="faCheckSquare"
          *ngIf="playerIs === 'player1' && matchData.reportSlip.player1Confirmed" class="me-2"></fa-icon>
        <fa-icon [fixedWidth]="true" [icon]="faSquare"
          *ngIf="playerIs === 'player1' && !matchData.reportSlip.player1Confirmed" class="me-2"></fa-icon>
        <fa-icon [fixedWidth]="true" [icon]="faCheckSquare"
          *ngIf="playerIs === 'player2' && matchData.reportSlip.player2Confirmed" class="me-2"></fa-icon>
        <fa-icon [fixedWidth]="true" [icon]="faSquare"
          *ngIf="playerIs === 'player2' && !matchData.reportSlip.player2Confirmed" class="me-2"></fa-icon>
        Confirm result
      </button>

      <div class="text-secondary"
        *ngIf="playerIs === 'player1' && !matchData.reportSlip.player1Confirmed">
        You need to confirm the result
      </div>

      <div class="text-secondary"
        *ngIf="playerIs === 'player1' && matchData.reportSlip.player1Confirmed && !matchData.reportSlip.player2Confirmed">
        Waiting for opponent to confirm the result
      </div>

      <div class="text-secondary"
        *ngIf="playerIs === 'player2' && !matchData.reportSlip.player2Confirmed">
        You need to confirm the result
      </div>

      <div class="text-secondary"
        *ngIf="playerIs === 'player2' && matchData.reportSlip.player2Confirmed && !matchData.reportSlip.player1Confirmed">
        Waiting for opponent to confirm the result
      </div>

    </ng-container>

    <div class="filler-div flex-grow-1"></div>

    <button class="btn btn-sm {{ reportSlipIsValid ? 'btn-success' : 'btn-secondary' }}"
      *ngIf="showConfirmButton"
      (click)="reportMatchResult(matchData)"
      [disabled]="invalidReportSlip"
      placement="top-right" ngbTooltip="Report results">Report</button>

  </div>

</ng-container>

<div class="modal-header d-flex align-items-center">
  <div class="text-large flex-grow-1">Match Room Settings</div>
  <div class="btn btn-sm btn-blank" (click)="close()">
    <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
  </div>
</div>


<div class="modal-body d-flex overflow-hidden p-0" [style.minHeight.vh]="65" [style.maxHeight.vh]="65">

  <div class="d-flex flex-column flex-shrink-0 p-3" [style.width.rem]="12.5">
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item cursor-pointer" *ngFor="let item of menu; trackBy: menuTracker">
        <div class="nav-link d-flex align-items-center"
          (click)="settingsContent = item.content"
          [ngClass]="{ 'active' : item.active }">
          <span class="flex-grow-1">{{ item.label }}</span>
          <fa-icon [icon]="item.icon"></fa-icon>
        </div>
      </li>
    </ul>
  </div>


  <div class="content-area p-3 px-5 scrollable w-100">

    <ng-container [ngSwitch]="settingsContent">


      <ng-container *ngSwitchCase="'devices'">

        <div class="d-flex justify-content-center w-100 mb-4">
          <video #testVideo class="w-100 rounded-3" id="test-video" [muted]="true" autoplay playsinline [srcObject]="streamService.localStream$ | async"></video>
        </div>

        <div class="text-large mb-1">Camera</div>
        <select class="form-select w-100 mb-4" [(ngModel)]="videoInputSelect" (change)="onChangeVideoInput()">
          <option *ngFor="let device of this.connectedMediaDevices$ | async | mediaDevice: 'videoinput'" [value]="device.deviceId">{{ device.label }}</option>
        </select>


        <div class="text-large mb-1">Microphone</div>
        <select class="form-select w-100 mb-4" [(ngModel)]="audioInputSelect" (change)="onChangeAudioInput()">
          <option *ngFor="let device of this.connectedMediaDevices$ | async | mediaDevice: 'audioinput'" [value]="device.deviceId">{{ device.label }}</option>
        </select>


        <div class="text-large mb-1 d-flex align-items-center">
          <span class="flex-grow-1">Speaker</span>
          <span class="btn btn-sm btn-blank" (click)="testAudio()">Test</span>
        </div>
        <select class="form-select w-100 mb-4" [(ngModel)]="audioOutputSelect" (change)="onChangeAudioOutput()">
          <option *ngFor="let device of this.connectedMediaDevices$ | async | mediaDevice: 'audiooutput'" [value]="device.deviceId">{{ device.label }}</option>
        </select>

      </ng-container>


      <ng-container *ngSwitchCase="'background'">

        <div class="text-large mb-4 d-flex align-items-center">
          <span class="flex-grow-1">Match Room Background</span>
          <span class="btn btn-sm btn-blank" *ngIf="hasBackground" (click)="clearImage()">Clear image</span>
          <button class="btn btn-sm btn-primary" *ngIf="readyToUpload" (click)="onImageUpload()">Set background</button>
        </div>
        <ng-container *ngIf="!isUploading && !hasBackground; else uploadingOrHasBackground">
          <app-image-cropper-uploader (base64)="onImageCropped($event)"></app-image-cropper-uploader>
          <div class="image-preview-wrapper" *ngIf="readyToUpload">
            <img [src]="imageToUpload">
          </div>
        </ng-container>
        <ng-template #uploadingOrHasBackground>
          <ng-container *ngIf="hasBackground; else busySpinner">

            <img class="rounded-3" [src]="backgroundUrl">

          </ng-container>
        </ng-template>

      </ng-container>


    </ng-container>







    <audio #testSound>
      <source autoplay="false" src="../../../../../assets/audio/test-sound.mp3">
      <source autoplay="false" src="../../../../../assets/audio/test-sound.wav">
    </audio>

  </div>


</div>


<div class="modal-footer d-flex align-items-center justify-content-end">
  <div class="btn btn-sm btn-blank" (click)="close()">Close</div>
</div>

<ng-template #busySpinner>
  <div class="d-flex align-items-center">
    <div class="spinner-border text-secondary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="ms-3 text-secondary">uploading background...</div>
  </div>
</ng-template>

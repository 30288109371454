import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IEventDetails, IEventPlayerDetails, IEventTeam, IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { BehaviorSubject, combineLatest, Subject, take } from 'rxjs';
import { EventService } from 'src/app/services/event/event.service';
import { TeamTieBreakers } from 'src/app/services/event/tiebreakers';
import { MatchService } from 'src/app/services/match.service';

@Component({
  selector: 'app-team-standings',
  templateUrl: './team-standings.component.html',
  styleUrls: ['./team-standings.component.css']
})
export class TeamStandingsComponent implements OnInit, OnDestroy {
  @Input() eventDocId: string

  public standings$: BehaviorSubject<IEventTeam[]> = new BehaviorSubject<IEventTeam[]>(null)

  private event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  private teams$: BehaviorSubject<IEventTeam[]> = new BehaviorSubject<IEventTeam[]>(null)
  private players$: BehaviorSubject<IEventPlayerDetails[]> = new BehaviorSubject<IEventPlayerDetails[]>(null)
  private matches$: BehaviorSubject<IMatchData[]> = new BehaviorSubject<IMatchData[]>(null)
  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private es: EventService,
    private ms: MatchService,
  ) { }

  ngOnInit(): void {
    const event = this.es.getEventById(this.eventDocId)
    const teams = this.es.getEventTeamsByEventId(this.eventDocId)
    const players = this.es.getEventPlayersByEventId(this.eventDocId)
    const matches = this.ms.getMatchesForEventByEventId(this.eventDocId)


    combineLatest([event, teams, players, matches])
    .pipe(
      take(1)
      // takeUntil(this.componentDestroyed$)
    )
    .subscribe(([event, teams, players, matches]) => {
        console.log('Emitted')
        console.log({
          event,
          teams,
          players,
          matches,
        })
        this.event$.next(event)
        this.teams$.next(teams)
        this.players$.next(players)
        this.matches$.next(matches)

        this.constructStandings(event, teams, matches)

      })

  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  private constructStandings(event: IEventDetails, teams: IEventTeam[], matches: IMatchData[]): void {

    // const teamsData = this.es.__getExtendedTeams(teams, matches, event)

    // console.log(teamsData)

    // const teamsWithBreakers = TeamTieBreakers.compute(teamsData)

    // console.log(teamsWithBreakers)

    // const teamsSorted = TeamTieBreakers.sort(teamsWithBreakers)

    // const teamsRanked = TeamTieBreakers.rank(teamsSorted)

    // this.standings$.next(teamsRanked)

  }

}

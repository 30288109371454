import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const tiSingleElimination: IconDefinition = {
  prefix: 'fac',
  iconName: 'singleElimination',
  icon: [
    512, // SVG view box width
    512, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M124.44,421.83l-9.74,46C110,490.26,124.34,512,144,512H368.09c19.61,0,34-21.74,29.24-44.18l-9.77-46C461.83,382,512,310.28,512,227.56,512,101.88,397.39,0,256,0S0,101.88,0,227.56C0,310.28,50.17,382,124.44,421.83ZM355.56,199.11A56.89,56.89,0,1,1,298.67,256,56.94,56.94,0,0,1,355.56,199.11Zm-199.12,0A56.89,56.89,0,1,1,99.56,256,56.94,56.94,0,0,1,156.44,199.11Z'
  ],
} as any;
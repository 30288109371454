<section class="d-flex">

  <div class="main-down-to-play flex-grow-1 p-2">
    <ng-container *ngIf="downToPlayFormats$ | async as downToPlayFormats; else loadingDownToPlayFormats">
      <div class="format-list">
        <ng-container *ngFor="let format of downToPlayFormats; trackBy : formatTracker">
          <div class="card down-to-play">
            <div class="card-header">

              <ng-template #tipContent>
                <ng-container *ngIf="format.players.length > 0; else noPlayers">
                  <ng-container *ngFor="let player of format.players">
                    <div class="text-start m-1">
                      <app-player-display-name [playerUid]="player.uid" position="right"></app-player-display-name>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #noPlayers>
                  <span class="text-secondary">No players found</span>
                </ng-template>
              </ng-template>

              <div class="player-counter btn-sm btn"
                [ngClass]="{ 'btn-success' : format.players.length > 0, 'btn-secondary' : format.players.length === 0}"
                [ngbTooltip]="tipContent" container="body" placement="left-top" [autoClose]="false"
                triggers="manual" #t="ngbTooltip" (click)="t.isOpen() ? t.close() : t.open()">
                <fa-icon [icon]="format.players.length > 1 ? faUsers : faUser" [fixedWidth]="true" class="me-1">
                </fa-icon>
                <span [innerHtml]="format.players.length"></span>
              </div>

              <div class="format-image">
                <img [src]="format.imageUris.downToPlay" class="{{ format.players.length === 0 ? 'grayscale' : '' }}">
              </div>
              <div class="format-title" [innerHTML]="format.name"></div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #loadingDownToPlayFormats>
      <div class="text-center">
        <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
    </ng-template>
  </div>



  <ng-container *ngIf="downToPlayPlayers$ | async as downToPlayPlayers; else loadingDownToPlayPlayers">
    <div #messagePanel class="message-section-wrapper">
      <ng-template #playerListContent>
        <div class="text-start m-1" *ngFor="let player of downToPlayPlayers; trackBy : playerTracker">
          <app-player-display-name [playerUid]="player.uid"></app-player-display-name>
        </div>
      </ng-template>
      <div class="p-2 card-header d-flex align-items-center">
        <span class="flex-grow-1">Down to Play</span>
        <div class="badge {{ downToPlayPlayers.length > 0 ? 'text-bg-success' : 'text-bg-secondary' }} cursor-pointer"
          [ngbTooltip]="playerListContent" container="body" placement="left-top" [innerHtml]="downToPlayPlayers.length" [autoClose]="false"
          triggers="manual" #t="ngbTooltip"(click)="t.isOpen() ? t.close() : t.open()"></div>
      </div>
      <div #chatWrapper class="message-list-wrapper p-0 overflow-hidden" [scrollTop]="chatWrapper.scrollHeight">
        <app-message-list [messageGroupDocId]="messageGroupDocId"></app-message-list>
      </div>
      <div class="message-form-wrapper">
        <tolaria-message-form class="w-100" [messageGroupDocId]="messageGroupDocId"></tolaria-message-form>
      </div>
    </div>
  </ng-container>

  <ng-template #loadingDownToPlayPlayers>
    <div class="message-section-wrapper text-center d-flex align-items-center justify-content-center">
      <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  </ng-template>

</section>

<div class="card" style="min-width: 100%;">
  <div class="card-header d-flex align-items-center">
    <div class="flex-grow-1">Terms of Use</div>
    <div class="btn btn-sm btn-blank" (click)="activeModal.close()">
      <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>
    </div>
  </div>
  <div class="card-body scrollable no-hover">

    <ol>
      <li><strong>Introduction</strong>
      <ol>
        <li>These terms tell you the rules for using the Tolaria.app platform (<strong>Platform</strong>).</li>
        <li>The Platform is operated on behalf of the Old School 93/94 community. To contact us please use <a href="mailto:development@slanfan.com">development@slanfan.com</a>.</li>
        <li>Please note we may amend these terms from time to time. Every time you wish to use the Platform, please check these terms to ensure you understand the terms that apply at that time.</li>
      </ol>
      </li>
      <li><strong>Accessing the Platform</strong>
      <ol>
        <li>By using the Platform, you confirm that you accept these terms and conditions and that you agree to comply with them. If you do not agree to these terms, you must not use the Platform.</li>
        <li>We recommend that you save and/or print a copy of these terms for future reference.</li>
        <li>These terms and conditions also contain our privacy policy which sets out the terms on which we process any personal data we collect from you, or that you provide to us, and information about the cookies on the Platform. By using the Platform, you consent to such processing and you agree that all data provided by you is accurate.</li>
      </ol>
      </li>
      <li><strong>Availability of the Platform</strong>
      <ol>
        <li>We may update and change the Platform from time to time to reflect our users&#39; needs and our priorities.</li>
        <li>We do not guarantee that the Platform, or any content on them, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of the Platform for operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</li>
        <li>You are responsible for ensuring that all persons who access the Platform through your internet connection are aware of these terms and conditions and other applicable terms and that they comply with them.</li>
        <li>We will determine, at our sole discretion, whether there has been a breach of these terms. When a breach has occurred, we may take such action as we deem appropriate, including immediate, temporary, or permanent withdrawal of your right to use the Platform. You will be notified and must not then attempt to use the Platform under any other name or through any other user.</li>
      </ol>
      </li>
      <li><strong>Keeping your account details safe</strong>
      <ol>
        <li>Any user identification code, password, or any other piece of information used as part of our security procedures must be treated as confidential. You must not disclose it to any third party.</li>
        <li>We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms and conditions.</li>
        <li>If you know or suspect that anyone other than you, knows your user identification code or password, you must promptly notify us.</li>
      </ol>
      </li>
      <li><strong>Using the Platform</strong>
      <ol>
        <li>You may only use the Platform for lawful purposes. You may not use the Platform: (i) in any way that breaches any applicable local, national, or international law or regulation; (ii) in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect; (iii) in any way that could, in our reasonable opinion, harm our reputation; (iv) for the purpose of harming or attempting to harm minors in any way; or (v) to transmit, or procure the sending of, any unsolicited or unauthorized advertising or promotional material or any other form of similar solicitation (spam).</li>
        <li>You agree not to reproduce, duplicate, copy or re-sell any part of the Platform in contravention of the provisions of paragraph 6 below.</li>
        <li>You also agree not to access without authority, interfere with, damage, or disrupt: (i) any part of the Platform; (ii) any equipment or network on which the Platform is stored; (iii) any software used in the provision of the Platform; and (iv) any equipment or network or software owned or used by any third party.</li>
      </ol>
      </li>
      <li><strong>How you may use material on the Platform</strong>
      <ol>
        <li>The Platform and the material published on it are protected by copyright laws and treaties around the world. You must not use any part of the content on the Platform for any commercial purposes.&nbsp;</li>
        <li>The Platform is unofficial fan content permitted under Wizards of the Coast&rsquo;s Fan Content Policy. The Platform is not approved/endorsed by Wizards of the Coast. Portions of the materials used on the Platform are property of Wizards of the Coast. &copy;Wizards of the Coast LLC.</li>
        <li>If you print off, copy, or download any part of any of the Platform or the material published on it in breach of these terms and conditions, your right to use the Platform will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</li>
      </ol>
      </li>
      <li><strong>Information on the Platform</strong>
      <ol>
        <li>The content on the Platform is provided for general information only. Although we make reasonable efforts to update the information on the Platform, we make no representations, warranties or guarantees, whether express or implied, that the content on the Platform is accurate, complete, or up to date.</li>
        <li>Where the Platform contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval of those linked websites or information you may obtain from them. We have no control over the contents of those sites or resources.</li>
      </ol>
      </li>
      <li><strong>Our responsibility for loss or damage suffered by you</strong>
      <ol>
        <li>Please note that we only provide the Platform for domestic and private use. You agree not to use the Platform for any commercial or business purposes.</li>
        <li>Except where it would be unlawful to do so, we exclude all liability to you for any reason, including for any loss of profit, loss of business, business interruption, or loss of business opportunity.</li>
        <li>We exclude all implied conditions, warranties, representations, or other terms that may apply to the Platform or any content contained on it.</li>
      </ol>
      </li>
      <li><strong>Viruses on the Platform</strong>
      <ol>
        <li>We do not guarantee that the Platform will be secure or free from bugs or viruses.</li>
        <li>You are responsible for configuring your information technology, computer programs, and platform to access the Platform. You should use your own virus protection software.</li>
        <li>You must not misuse the Platform by knowingly introducing viruses, trojans, worms, logic bombs, or other material that is malicious or technologically harmful. You must not attempt to gain unauthorized access to the Platform, the server on which the Platform is stored, or any server, computer, or database connected to the Platform. You must not attack the Platform via a denial-of-service attack or a distributed denial-of-service attack. By breaching this provision, you would commit a criminal offense and we will report any such breach to the relevant law enforcement authorities, and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use the Platform will cease immediately.</li>
      </ol>
      </li>
      <li><strong>Rules about linking to the Platform</strong>
      <ol>
        <li>You may link to the Platform, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</li>
        <li>You must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part where none exists.</li>
        <li>You must not establish a link to the Platform on any website that is not owned by you.</li>
        <li>The Platform must not be framed on any other sites, nor may you create a link to any part of the Platform, other than its home page.</li>
        <li>We reserve the right to withdraw linking permission without notice.</li>
      </ol>
      </li>
      <li><strong>Governing law</strong><br />
      These terms and conditions, their subject matter, and their formation are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.</li>
    </ol>


  </div>
</div>

import { IDraftListFilters } from './../services/draft.service';
import { Pipe, PipeTransform } from '@angular/core';
import { IDraft } from 'tolaria-cloud-functions/src/_interfaces';

@Pipe({
  name: 'drafts'
})
export class DraftsPipe implements PipeTransform {

  transform(drafts: IDraft[], filter: IDraftListFilters): IDraft[] {
    return drafts;
  }

}

<ng-container *ngIf="languageItems$ | async as items">
  <div class="mt-4 h6">
    <div class="text-secondary text-smaller me-2">Languages</div>
    <div class="languange-selection-wrapper">
      <ng-container *ngFor="let item of items">
        <div class="me-2 mt-2 btn btn-sm {{ item.selected ? 'btn-main' : 'btn-secondary' }}" (click)="doUpdateCardId(item.id)"
          placement="top" [ngbTooltip]="item.name">{{ item.lang }}</div>
      </ng-container>
    </div>
  </div>
</ng-container>

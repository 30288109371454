<ng-container *ngIf="ticket$ | async as ticket">
  <tr class="ticket-row row m-0" [ngClass]="{ 'text-secondary': ticket.deleted }">
    <td class="p-2 col-1" [ngbTooltip]="ticket.soldOut || ticket.showAsSoldOut ? 'Sold out' : 'Available for sale'" container="body">
      <fa-icon [icon]="ticket.deleted ? ticketDeleted : ticketStatus" class="{{ ticket.soldOut || ticket.showAsSoldOut || ticket.deleted ? 'text-danger' : 'text-success' }}">
      </fa-icon>
    </td>

    <td class="p-2 col-4">
      <div>{{ ticket.name }}</div>
    </td>

    <td class="p-2 col-2">
      <span class="badge text-secondary text-small text-uppercase text-monospace">
        {{ ticket.type }}
      </span>
    </td>

    <td class="p-0 py-2 col-1 text-end">
      {{ ticket.salesData.price }} {{ ticket.salesData.currency.toUpperCase() }}
    </td>

    <td class="p-0 py-2 col-1 text-end">
      {{ ticket.salesData.ticketLimit }}
    </td>

    <td class="p-0 py-2 col-1 text-end">
      {{ ticket.salesData.sold }}
    </td>

    <td class="p-2 pe-3 col-2 text-end">
      {{ revenue }}
    </td>
  </tr>
</ng-container>

import { switchMap, take } from 'rxjs/operators';
import { EventService } from 'src/app/services/event/event.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeckListComponent } from './../../decks/deck-list/deck-list.component';
import { DeckPhotoComponent } from './../../decks/deck-photo/deck-photo.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { DecksService, IDeckList } from 'src/app/services/decks.service';
import { Component, Input } from '@angular/core';
import { faUser, faCamera, faFileAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs/internal/operators/map';

export interface IRecentBrews extends IDeckList {
  disableRating: boolean;
  disablePreview: boolean;
  assignedToEvent: boolean;
  eventNames: string[]
}

@Component({
  selector: 'app-recent-brews',
  templateUrl: './recent-brews.component.html',
  styleUrls: ['./recent-brews.component.css']
})
export class RecentBrewsComponent {
  @Input() numberOfDecks: number = 10;

  public icon = {
    user: faUser,
    camera: faCamera,
    list: faFileAlt,
    star: faStar,

  }

  public recentBrews$: Observable<IRecentBrews[]>;

  constructor(
    private decks: DecksService,
    private eventService: EventService,
    private modalService: NgbModal,
    private auth: AuthService,
  ) {
    this.recentBrews$ = this.decks.getPublicDecks(this.numberOfDecks).pipe(
      map(decks => {
        console.log(decks)
        const recentBrews: IRecentBrews[] = [];
        decks.forEach(d => {
          const brew = this.getBrewData(d)
          recentBrews.push(brew);
        })
        return recentBrews;
      })
    )
  }

  private getBrewData(d: IDeckList): IRecentBrews {
    const brew: IRecentBrews = d as IRecentBrews;
    d.ratedByPlayerDocIds
      ? d.ratedByPlayerDocIds.includes(this.auth.user.playerId) ? brew.disableRating = true : brew.disableRating = false
      : brew.disableRating = false;

    d.ratedByPlayerDocIds
      ? brew.ratedByPlayerDocIds = d.ratedByPlayerDocIds
      : brew.ratedByPlayerDocIds = [];

    d.eventDocIds.length > 0
      ? brew.assignedToEvent = true
      : null

    d.eventDocIds.length > 0
      ? brew.disablePreview = true
      : null

    return brew
    // return new Promise(async (resolve) => {
    //   console.log('getting data')

    //   if (d.eventDocIds.length > 0) {
    //     brew.eventNames
    //     console.log('getting event names')
    //     for await (const eventDocId of d.eventDocIds) {
    //       console.log(eventDocId)
    //       const eventName = await this.eventService.getEventNameByIdPromise(eventDocId)
    //       console.log(eventName)
    //       brew.eventNames.push(eventName)
    //     }
    //   }
    //   console.log('fetch complete')
    //   resolve(brew)

    // })
  }

  public tracker(index: number, item: IDeckList) {
    return item.docId;
  }

  public showDeckPic(deckDocId: string): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'xl',
    };

    const modalRef = this.modalService.open(DeckPhotoComponent, modalOptions);
    modalRef.componentInstance.deckListDocId = deckDocId;
  }

  public showDeckList(deckDocId: string): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'xl',
    };

    const modalRef = this.modalService.open(DeckListComponent, modalOptions);
    modalRef.componentInstance.deckListDocId = deckDocId;
    modalRef.componentInstance.wrapperClass = 'modal-body';
  }

  public rateDeck(deckDocId: string): void {
    this.decks.addRating(true, deckDocId);
  }
}

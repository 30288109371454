<div class="card" style="min-width: 100%;" *ngIf="modal; else nonModal">
  <div class="card-header d-flex align-items-center">
    <div class="flex-grow-1">Privacy Policy</div>
    <div class="btn btn-sm btn-blank" (click)="activeModal.close()">
      <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>
    </div>
  </div>
  <div class="card-body scrollable no-hover">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </div>
</div>

<ng-template #nonModal>
  <app-public-header [showAbout]="true"></app-public-header>
  <div class="d-flex flex-column gap-5 align-items-center overflow-y-auto page-wrapper mt-5">
    <div class="card rounded p-4 rounded-4 no-hover border border-0 mt-5"
      [style.minWidth.vw]="90"
      style="min-width: 90vw;max-height: fit-content;height: fit-content;">
      <h1>Privacy Policy</h1>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
    <app-public-footer class="w-100"></app-public-footer>
  </div>
</ng-template>


<ng-template #content>
  <ol>
    <li><strong>Introduction</strong>
      <ul>
        <li>Welcome to Tolaria.app platform&rsquo;s (<strong>Platform</strong>) privacy policy.</li>
        <li>The Platform respects your privacy and is committed to protecting your personal data. This privacy policy
          will inform you as to how we look after your personal data when you visit the Platform (regardless of where
          you visit it from) and tell you about your privacy rights and how the law protects you.</li>
      </ul>
    </li>
    <li><strong>Purpose of this privacy policy</strong>
      <ul>
        <li>This privacy policy aims to give you information on how the Platform collects and processes your personal
          data through your use of the Platform, including any data you may provide when you register as a user, and
          information about the cookies on the Platform.</li>
        <li>The Platform is not intended for children and we do not knowingly collect data relating to children.</li>
        <li>It is important that you read this privacy policy together with any other privacy policy or fair
          processing policy we may provide on specific occasions when we are collecting or processing personal data
          about you so that you are fully aware of how and why we are using your data. This privacy policy supplements
          other notices and privacy policies and is not intended to override them.</li>
        <li>The Platform is the controller and responsible for your personal data. Questions regarding this privacy
          policy should be directed to us by sending an email to <a
            href="mailto:development@slanfan.com">development&#64;slanfan.com</a>.</li>
        <li>If you are unhappy with the way in which we have handled your personal data please get in touch with us.
          You have the right to make a complaint at any time to the Information Commissioner&#39;s Office (ICO), the
          UK regulator for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal
          with your concerns before you approach the ICO so please contact us in the first instance.</li>
      </ul>
    </li>
    <li><strong>Changes to the privacy policy and your duty to inform us of changes</strong>
      <ul>
        <li>We keep our privacy policy under regular review. This version was last updated on 31 March 2021.</li>
        <li>It is important that the personal data we hold about you is accurate and current. Please keep us informed
          if your personal data changes during your relationship with us.</li>
      </ul>
    </li>
    <li><strong>Third-party links</strong></li>
  </ol>

  <p>The Platform may include links to third-party websites, plug-ins, and applications. Clicking on those links or
    enabling those connections may allow third parties to collect or share data about you. We do not control these
    third-party websites and are not responsible for their privacy statements. When you leave the Platform, we
    encourage you to read the privacy policy of every website you visit.</p>

  <ol>
    <li><strong>The data we collect about you</strong>

      <ul>
        <li>Personal data, or personal information, means any information about an individual from which that person
          can be identified. It does not include data where the identity has been removed (anonymous data).</li>
        <li>We may collect, use, store and transfer different kinds of personal data about you which we have grouped
          together as follows:
          <ul>
            <li><strong>Identity Data</strong> includes first name, last name, username, or similar identifier.</li>
            <li><strong>Contact Data</strong> includes email addresses.</li>
            <li><strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser
              type, and version, time zone setting and location, browser plug-in types and versions, operating system
              and platform, and other technology on the devices you use to access the Platform.</li>
            <li><strong>Profile Data</strong> includes your username and password, your interests, preferences, and
              feedback.</li>
            <li><strong>Usage Data</strong> includes information about how you use the Platform.</li>
            <li><strong>Marketing and Communications Data</strong> includes your preferences in receiving marketing
              from us and your communication preferences.</li>
          </ul>
        </li>
        <li>We also collect, use, and share Aggregated Data such as statistical or demographic data for any purpose.
          Aggregated Data could be derived from your personal data but is not considered personal data in law as this
          data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to
          calculate the percentage of users accessing a specific Platform feature. However, if we combine or connect
          Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the
          combined data as personal data which will be used in accordance with this privacy policy.</li>
        <li>We do not collect any special categories of personal data about you (this includes details about your race
          or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade
          union membership, information about your health, and genetic and biometric data). Nor do we collect any
          information about criminal convictions and offenses.</li>
      </ul>
    </li>
    <li><strong>How is your personal data collected?</strong>
      <ul>
        <li>We use different methods to collect data from and about you including through:
          <ul>
            <li>Direct interactions. You may give us your Identity and Contact Data by filling in forms or by
              corresponding with us.</li>
            <li>Automated technologies or interactions. As you interact with the Platform, we will automatically
              collect Technical Data about your equipment, browsing actions, and patterns. We collect this personal
              data by using cookies, server logs, and other similar technologies.</li>
          </ul>
        </li>
      </ul>
    </li>
  </ol>

  <ol>
    <li><strong>How we use your personal data</strong>

      <ul>
        <li>We will only use your personal data when the law allows us to. Most commonly, we will use your personal
          data in the following circumstances:
          <ul>
            <li>Obtaining your consent to use your data for a particular purpose.</li>
            <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and
              fundamental rights do not override those interests.</li>
            <li>Where we need to comply with a legal obligation.</li>
          </ul>
        </li>
      </ul>
    </li>
  </ol>

  <ol>
    <li><strong>Purposes for which we will use your personal data</strong>

      <ul>
        <li>We have set out below, in a table format, a description of all the ways we plan to use your personal data,
          and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are
          where appropriate.</li>
        <li>Note that we may process your personal data for more than one lawful ground depending on the specific
          purpose for which we are using your data. Please contact us if you need details about the specific legal
          ground we are relying on to process your personal data where more than one ground has been set out in the
          table below.</li>
      </ul>
    </li>
  </ol>

  <table>
    <tbody>
      <tr>
        <td>
          <p><strong>Purpose/Activity</strong></p>
        </td>
        <td>
          <p><strong>Type of data</strong></p>
        </td>
        <td>
          <p><strong>Lawful basis for processing including basis of legitimate interest</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p>To register you as a user of the Platform</p>
        </td>
        <td>
          <p>Identity; Contact; Profile</p>
        </td>
        <td>
          <p>(a) Consent</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>To manage our relationship with you which will include:</p>

          <p>(a) notifying you about changes to our terms or privacy policy; and/or</p>

          <p>(b) asking you to leave a review or take a survey</p>
        </td>
        <td>
          <p>Identity; Contact; Profile; Marketing and Communications</p>
        </td>
        <td>
          <p>(a) Consent</p>

          <p>(b) Necessary to comply with a legal obligation</p>

          <p>(c) Necessary for our legitimate interests (to keep our records updated and to study how users use the
            Platform)</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>To administer and protect the Platform (including troubleshooting, data analysis, testing, system
            maintenance, support, reporting, and hosting of data)</p>
        </td>
        <td>
          <p>Identity; Technical; Profile; Usage</p>
        </td>
        <td>
          <p>(a) Necessary to comply with a legal obligation</p>

          <p>(b) Necessary for our legitimate interests (network security and for running the Platform)</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>To use data analytics to improve the Platform, including marketing, user relationships, and experiences
          </p>
        </td>
        <td>
          <p>Identity; Contact; Technical; Profile; Usage; Marketing and Communications</p>
        </td>
        <td>
          <p>Necessary for our legitimate interests (to keep the Platform updated and relevant and to inform our
            marketing strategy)</p>
        </td>
      </tr>
    </tbody>
  </table>

  <p>&nbsp;</p>

  <ul>
    <li>We may use your Identity, Contact, Technical, Usage, and Profile Data to form a view on what we think may be
      of interest to you. This is how we decide which Platform features may be relevant for you (we call this
      marketing). You will receive marketing communications from us if you have requested information from us and if
      you have not opted out of receiving that marketing.</li>
    <li>You can ask us to stop sending you marketing messages at any time by contacting us at any time.</li>
  </ul>

  <ol>
    <li><strong>Change of purpose </strong>

      <ul>
        <li>We will only use your personal data for the purposes for which we collected it unless we reasonably
          consider that we need to use it for another reason and that reason is compatible with the original purpose.
          If you wish to get an explanation as to how the processing for the new purpose is compatible with the
          original purpose, please contact us.</li>
        <li>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the
          legal basis which allows us to do so.</li>
        <li>Please note that we may process your personal data without your knowledge or consent, in compliance with
          the above rules, where this is required or permitted by law.</li>
      </ul>
    </li>
    <li><strong>Disclosures of your personal data</strong>
      <ul>
        <li>We may share your personal data with service providers acting as processors based in the UK and/or the
          European Economic Area who provide IT and system administration services.</li>
        <li>We require all third parties to respect the security of your personal data and to treat it in accordance
          with the law. We do not allow our third-party service providers to use your personal data for their own
          purposes and only permit them to process your personal data for specified purposes and in accordance with
          our instructions.</li>
        <li>Many of our external third parties are based outside the UK so their processing of your personal data will
          involve a transfer of data outside the UK.</li>
        <li>Whenever we transfer your personal data out of the UK, we ensure a similar degree of protection is
          afforded to it by ensuring at least one of the following safeguards is implemented:
          <ul>
            <li>we will only transfer your personal data to countries that have been deemed to provide an adequate
              level of protection for personal data; or</li>
            <li>where we use certain service providers, we may use specific contracts approved for use in the UK which
              give personal data the same protection it has in the UK.</li>
          </ul>
        </li>
        <li>Please contact us if you want further information on the specific mechanism used by us when transferring
          your personal data out of the UK.</li>
      </ul>
    </li>
    <li><strong>Data security</strong>
      <ul>
        <li>We have put in place appropriate security measures to prevent your personal data from being accidentally
          lost, used, or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your
          personal data to those employees, agents, contractors, and other third parties who have a business need to
          know. They will only process your personal data on our instructions, and they are subject to a duty of
          confidentiality.</li>
        <li>We have put in place procedures to deal with any suspected personal data breach and will notify you and
          any applicable regulator of a breach where we are legally required to do so.</li>
      </ul>
    </li>
    <li><strong>Data retention</strong>
      <ul>
        <li>How long will you use my personal data? We will only retain your personal data for as long as reasonably
          necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal,
          regulatory, or other reporting requirements. We may retain your personal data for a longer period in the
          event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our
          relationship with you.</li>
        <li>To determine the appropriate retention period for personal data, we consider the amount, nature, and
          sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your
          personal data, the purposes for which we process your personal data, and whether we can achieve those
          purposes through other means, and the applicable legal, regulatory, or other requirements.</li>
        <li>In some circumstances you can ask us to delete your data - see the Glossary below for further information.
        </li>
        <li>In some circumstances, we will anonymize your personal data (so that it can no longer be associated with
          you) for research or statistical purposes, in which case we may use this information indefinitely without
          further notice to you.</li>
      </ul>
    </li>
    <li><strong>Your legal rights</strong>
      <ul>
        <li>Under certain circumstances, you have the following rights under data protection laws in relation to your
          personal data:
          <ul>
            <li>Request access to your personal data.</li>
            <li>Request correction of your personal data.</li>
            <li>Request erasure of your personal data.</li>
            <li>Object to processing of your personal data.</li>
            <li>Request restriction of processing your personal data.</li>
            <li>Request transfer of your personal data.</li>
            <li>Right to withdraw consent.</li>
          </ul>
        </li>
      </ul>
    </li>
  </ol>

  <p>If you wish to exercise any of the rights set out above, please contact us.</p>

  <ul>
    <li>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However,
      we may refuse to comply with your request if your request is clearly unfounded, repetitive, or excessive.</li>
    <li>We may need to request specific information from you to help us confirm your identity and ensure your right to
      access your personal data (or to exercise any of your other rights). This is a security measure to ensure that
      personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you
      for further information in relation to your request to speed up our response.</li>
    <li>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a
      month if your request is particularly complex or you have made a number of requests. In this case, we will
      notify you and keep you updated.</li>
  </ul>

  <ol>
    <li><strong>Cookies</strong>

      <ul>
        <li>The Platform uses certain third-party cookies from https://scryfall.com in order to provide certain
          features of the Platform. This helps us to provide you with a good experience when you browse the Platform
          and also allows us to improve the Platform. Please note we have no control over these cookies. These
          third-party cookies are likely to be analytical cookies, performance cookies, or targeting cookies.</li>
        <li>You can block cookies by activating the setting on your browser that allows you to refuse the setting of
          all or some cookies. However, if you use your browser settings to block all cookies you may not be able to
          access all or parts of the Platform.</li>
      </ul>
    </li>
    <li><strong>Glossary</strong>
      <ul>
        <li><strong>Lawful Basis for data processing </strong>
          <ul>
            <li><strong>Legitimate Interest</strong> means the interest of the Platform to enable us to give you the
              best service and the best and most secure experience. We make sure we consider and balance any potential
              impact on you (both positive and negative) and your rights before we process your personal data for our
              legitimate interests. We do not use your personal data for activities where our interests are overridden
              by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You
              can obtain further information about how we assess our legitimate interests against any potential impact
              on you in respect of specific activities by contacting us.</li>
            <li><strong>Consent </strong>means where we have your express consent to process your personal data for a
              particular purpose.</li>
            <li><strong>Comply with a legal obligation </strong>means processing your personal data where it is
              necessary for compliance with a legal obligation that we are subject to.</li>
          </ul>
        </li>
        <li><strong>Your Legal Rights</strong>
          <ul>
            <li>Request access to your personal data (commonly known as a &quot;data subject access request&quot;).
              This enables you to receive a copy of the personal data we hold about you and to check that we are
              lawfully processing it.</li>
            <li>Request correction of the personal data that we hold about you. This enables you to have any
              incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of
              the new data you provide to us.</li>
            <li>Request erasure of your personal data. This enables you to ask us to delete or remove personal data
              where there is no good reason for us to continue to process it. You also have the right to ask us to
              delete or remove your personal data where you have successfully exercised your right to object to
              processing (see below), where we may have processed your information unlawfully or where we are required
              to erase your personal data to comply with local law. Note, however, that we may not always be able to
              comply with your request of erasure for specific legal reasons which will be notified to you, if
              applicable, at the time of your request.</li>
            <li>Object to processing of your personal data where we are relying on a legitimate interest (or those of
              a third party) and there is something about your particular situation which makes you want to object to
              processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have
              the right to object where we are processing your personal data for direct marketing purposes. In some
              cases, we may demonstrate that we have compelling legitimate grounds to process your information which
              override your rights and freedoms.</li>
            <li>Request restriction of processing of your personal data. This enables you to ask us to suspend the
              processing of your personal data in the following scenarios:
              <ul>
                <li>if you want us to establish the data&#39;s accuracy;</li>
                <li>where our use of the data is unlawful, but you do not want us to erase it;</li>
                <li>where you need us to hold the data even if we no longer require it as you need it to establish,
                  exercise, or defend legal claims; or</li>
                <li>you have objected to our use of your data, but we need to verify whether we have overriding
                  legitimate grounds to use it.</li>
              </ul>
            </li>
            <li>Request the transfer of your personal data to you or to a third party. We will provide to you, or a
              third party you have chosen, your personal data in a structured, commonly used, machine-readable format.
              Note that this right only applies to automated information which you initially provided consent for us
              to use or where we used the information to perform a contract with you.</li>
            <li>Withdraw consent at any time where we are relying on consent to process your personal data. However,
              this will not affect the lawfulness of any processing carried out before you withdraw your consent. If
              you withdraw your consent, we may not be able to provide certain Platform features to you. We will
              advise you if this is the case at the time you withdraw your consent.</li>
          </ul>
        </li>
      </ul>
    </li>
  </ol>
</ng-template>
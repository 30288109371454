import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faCode, faAlignCenter, faAlignLeft, faAlignRight, faAlignJustify, faParagraph } from '@fortawesome/free-solid-svg-icons'
import { NgbDropdownModule, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Options } from '@popperjs/core';
import { tolFormat } from 'src/assets/font-icons/format';
import { tolHeaderLevel1 } from 'src/assets/font-icons/header-level-1';
import { tolHeaderLevel2 } from 'src/assets/font-icons/header-level-2';
import { tolCodeBlock } from '../assets/tol-code-block';
import { tolLink } from '../assets/tol-link';
import { tolBold } from '../assets/tol-bold';
import { tolItalic } from '../assets/tol-italic';
import { tolNumberList } from '../assets/tol-number-list';
import { tolStrike } from '../assets/tol-strikethrough';
import { tolBulletList } from '../assets/tol-bullet-list';
import { tolBlockqoute } from '../assets/tol-blockqoute';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tolaria-wysiwyg-toolbar',
  templateUrl: './tolaria-wysiwyg-toolbar.component.html',
  styleUrls: [],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgbTooltipModule,
    NgbDropdownModule,
  ]
})
export class TolariaWysiwygToolbarComponent {
  @Input() richText: boolean = false
  @Input() set show(val: boolean) {
    this.toolbarHidden = !val
  }

  public toolbarHidden: boolean = false

  public ctrlKey = navigator.userAgent.toLowerCase().includes('mac') ? '⌘' : 'Ctrl'
  public altKey = navigator.userAgent.toLowerCase().includes('mac') ? 'Option' : 'Alt'

  constructor(private readonly tooltipConfig: NgbTooltipConfig) {
    this.tooltipConfig.placement = 'top'
    this.tooltipConfig.container = 'body'
    this.tooltipConfig.openDelay = 250
    this.tooltipConfig.tooltipClass = 'ql-button-tooltip'
    this.tooltipConfig.popperOptions = (options: Partial<Options>) => options
    // this.tooltipConfig.popperOptions = (options: Partial<Options>) => {
    //   // customize modifiers
    //   for (const modifier of options.modifiers || []) {
    //     // customize offset
    //     if (modifier.name === 'offset' && modifier.options) {
    //       modifier.options.offset = () => [-20, -100]
    //     }
    //   }
    //   return options
    // }
  }

  public icon = {
    bold: tolBold,
    italic: tolItalic,
    strike: tolStrike,
    link: tolLink,
    ol: tolNumberList,
    ul: tolBulletList,
    blockQuote: tolBlockqoute,
    code: faCode,
    codeBlock: tolCodeBlock,
    image: faImage,
    alignCenter: faAlignCenter,
    alignLeft: faAlignLeft,
    alignRight: faAlignRight,
    alignJustify: faAlignJustify,
    paragraph: faParagraph,
    normal: tolFormat,
    heading1: tolHeaderLevel1,
    heading2: tolHeaderLevel2,
  }

}

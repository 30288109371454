import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatchListingService } from '../../services/match-listing.service';
import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';
import { IMatchMeta } from '../../utilitiez/matches.interfaces';
import { MatchCardComponent } from '../match-card/match-card.component';
import { MatchCardPlaceholderComponent } from '../match-card-placeholder/match-card-placeholder.component';

@Component({
  selector: 'app-players-match-monitor',
  templateUrl: './match-monitor.component.html',
  styleUrls: ['./match-monitor.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatchCardComponent,
    MatchCardPlaceholderComponent,
  ]
})
export class PlayerMatchMonitorComponent implements OnInit, OnDestroy {

  private destoyed$ = new Subject<void>()
  public activeMatches$ = new BehaviorSubject<IMatchMeta[] | null>(null)

  constructor(
    private readonly matchListing: MatchListingService,
  ) { }

  ngOnInit() {
    console.log('PlayerMatchMonitorComponent:: ngOnInit')
    this.matchListing.ongoingMatches$
      .pipe(takeUntil(this.destoyed$), tap((data) => console.log('PlayerMatchMonitorComponent:: monitoring emitted ->', data)))
      .subscribe(data => this.activeMatches$.next(data))
  }

  ngOnDestroy(): void {
    console.log('PlayerMatchMonitorComponent:: ngOnDestroy')
    this.destoyed$.next()
    this.destoyed$.complete()
  }

}

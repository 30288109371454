import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mediaDevice',
  standalone: true,
})
export class MediaDevicePipe implements PipeTransform {

  transform(items: any[], type: string): any [] {
    const res: Array<any> = [];
    if (items) {
      items.forEach(device => {
        if (device.kind === type) {
            res.push(device);
        }
      });
    }
    return res;
  }

}

<div class="modal-header text-large">
    {{ label === 'bug' ? 'Report an issue' : 'Request a Feature' }}
</div>


<div class="model-body p-3 gap-3 d-flex flex-column">

    <div class="form-group gap-2 d-flex flex-column">
        <label for="title">Summary</label>
        <input type="text" class="form-control" id="title" placeholder="Enter a short summary" [(ngModel)]="title">
    </div>

    <div class="form-group gap-2 d-flex flex-column">
        <label for="body">Description</label>
        <textarea rows="10" type="text" class="form-control" id="body"
            placeholder="Describe the error as best as possible. When it happens and what steps you took before it..."
            [(ngModel)]="body">
        </textarea>
    </div>
    
    <div class="my-3" *ngIf="showError">
        <div class="alert alert-warning" role="alert">
            {{ errorMessage }}
        </div>
    </div>

</div>


<div class="modal-footer d-flex aligm-content-center">
    
    <div class="flex-grow-1 text-warning" *ngIf="!issueFormValid">{{ validationMessage }}</div>
    
    <button class="btn btn-sm btn-blank" (click)="cancel()">Cancel</button>
    
    <button class="btn btn-sm btn-main d-flex gap-2 align-items-center" [disabled]="sendingData" (click)="createIssue()" [disabled]="!issueFormValid">
        <span class="spinner-border spinner-border-sm" aria-hidden="true" *ngIf="sendingData"></span>
        <span>Report issue</span>
    </button>

</div>

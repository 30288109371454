<div class="card card-xlarge no-hover min-height-100" [ngClass]="{ 'max-width-100 min-width-100' : globals.isMobile }">

  <div class="card-header">Report Slip</div>

  <div class="card-header" *ngIf="event?.isOnlineTournament">
    <button class="btn btn-sm btn-secondary" (click)="openVideoRoom()">
      <fa-icon [fixedWidth]="true" [icon]="faVideo"></fa-icon>
      Spectate match
    </button>
  </div>

  <div class="card-body scrollable no-hover">

    <ng-container *ngIf="!this.matchDoc">
      <div class="text-secondary text-center">No match selected</div>
    </ng-container>

    <ng-container *ngIf="this.matchDoc">
      <div class="report-slip-top">

        <div class="row mb-2">
          <div class="col text-center">WINNER</div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <div class="btn btn-report btn-name {{ matchDoc.player1.isWinner ? 'selected' : '' }}"
              (click)="setMatchResult(false, true, false, 0, 0, 0, false, false)" placement="top-left"
              ngbTooltip="Keyboard: 7">
              {{ matchDoc.player1.displayName }}
            </div>
          </div>
          <div class="col">
            <div class="btn btn-report btn-name {{ matchDoc.player2.isWinner ? 'selected' : '' }}"
              (click)="setMatchResult(false, false, true, 0, 0, 0, false, false)" placement="top-right"
              ngbTooltip="Keyboard: 9">
              {{ matchDoc.player2.displayName }}
            </div>
          </div>
        </div>

        <div class="row mb-5" *ngIf="matchDoc.isType !== 'bracket'">
          <div class="col">
            <div class="btn btn-report btn-draw {{ matchDoc.isDraw ? 'selected' : '' }}"
              (click)="setMatchResult(true, false, false, 0, 0, 0, false, false)" placement="top"
              ngbTooltip="Keyboard: 5">
              DRAW
            </div>
          </div>
        </div>

        <div class="row mb-2" *ngIf="matchDoc.player1.isWinner || matchDoc.player2.isWinner || matchDoc.isDraw">
          <div class="col text-center">RESULT</div>
        </div>
        <!-- player 1 report buttons -->
        <div class="row mb-5 game-wins-wrapper" *ngIf="matchDoc.player1.isWinner">
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchDoc.player1.isWinner && matchDoc.player1.wins === 2 && matchDoc.player2.wins === 0 ? 'selected' : '' }}"
              (click)="setMatchResult(false, true, false, 2, 0, 0, false, false)" placement="top-left"
              ngbTooltip="Keyboard: 1">2 - 0</div>
          </div>
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchDoc.player1.isWinner && matchDoc.player1.wins === 2 && matchDoc.player2.wins === 1 ? 'selected' : '' }}"
              (click)="setMatchResult(false, true, false, 2, 1, 0, false, false)" placement="top"
              ngbTooltip="Keyboard: 2">2 - 1</div>
          </div>
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchDoc.player1.isWinner && matchDoc.player1.wins === 1 && matchDoc.player2.wins === 0 ? 'selected' : '' }}"
              (click)="setMatchResult(false, true, false, 1, 0, 0, false, false)" placement="top-right"
              ngbTooltip="Keyboard: 3">1 - 0</div>
          </div>
        </div>

        <!-- player 2 report buttons -->
        <div class="row mb-5 game-wins-wrapper" *ngIf="matchDoc.player2.isWinner">
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchDoc.player2.isWinner && matchDoc.player1.wins === 0 && matchDoc.player2.wins === 2 ? 'selected' : '' }}"
              (click)="setMatchResult(false, false, true, 0, 2, 0, false, false)" placement="top-left"
              ngbTooltip="Keyboard: 1">2 - 0</div>
          </div>
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchDoc.player2.isWinner && matchDoc.player1.wins === 1 && matchDoc.player2.wins === 2 ? 'selected' : '' }}"
              (click)="setMatchResult(false, false, true, 1, 2, 0, false, false)" placement="top"
              ngbTooltip="Keyboard: 2">2 - 1</div>
          </div>
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchDoc.player2.isWinner && matchDoc.player1.wins === 0 && matchDoc.player2.wins === 1 ? 'selected' : '' }}"
              (click)="setMatchResult(false, false, true, 0, 1, 0, false, false)" placement="top-right"
              ngbTooltip="Keyboard: 3">1 - 0</div>
          </div>
        </div>

        <!-- draw report buttons -->
        <div class="row mb-5 game-wins-wrapper" *ngIf="matchDoc.isDraw">
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchDoc.isDraw && matchDoc.player1.wins === 1 && matchDoc.player2.wins === 1 ? 'selected' : '' }}"
              (click)="setMatchResult(true, false, false, 1, 1, 0, false, false)" placement="top-left"
              ngbTooltip="Keyboard: 1">1 - 1</div>
          </div>
          <div class="col">
            <div
              class="btn btn-report btn-game-wins {{ matchDoc.isDraw && matchDoc.player1.wins === 0 && matchDoc.player2.wins === 0 ? 'selected' : '' }}"
              (click)="setMatchResult(true, false, false, 0, 0, 0, false, false)" placement="top-right"
              ngbTooltip="Keyboard: 2">0 - 0</div>
          </div>
        </div>

        <div class="row mb-2" *ngIf="matchDoc.player1.isWinner || matchDoc.player2.isWinner || matchDoc.isDraw">
          <div class="col text-center">DRAWN GAMES</div>
        </div>
        <!-- drawn games stepper -->
        <div class="row mb-5" *ngIf="matchDoc.player1.isWinner || matchDoc.player2.isWinner || matchDoc.isDraw">
          <div class="col">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend" (click)="changeDrawnGames('decrease')" placement="top-left"
                ngbTooltip="Keyboard: -">
                <span class="input-group-text">
                  <fa-icon [fixedWidth]="true" [icon]="faMinus"></fa-icon>
                </span>
              </div>
              <input type="text" class="report-stepper" [value]="matchDoc.player1.draws" disabled>
              <div class="input-group-append" (click)="changeDrawnGames('increase')" placement="top-right"
                ngbTooltip="Keyboard: +">
                <span class="input-group-text">
                  <fa-icon [fixedWidth]="true" [icon]="faPlus"></fa-icon>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="this.matchDoc && matchDoc.isType !== 'bracket'">
      <div class="report-slip-bottom">
        <div class="row mb-2">
          <div class="col">Drop players?</div>
        </div>
        <ng-container *ngIf="showDropOption; else dropInfo">
          <!-- drop selections -->
          <div class="row player-drop mb-2"
            *ngIf="matchDoc.player1.playerUid !== '*** BYE ***' && matchDoc.player1.playerUid !== '*** LOSS ***'"
            (click)="matchDoc.player1.drop = !matchDoc.player1.drop">
            <div class="col" style="flex-grow: 0;">
              <fa-icon [fixedWidth]="true" [icon]="faSquare" *ngIf="!matchDoc.player1.drop"></fa-icon>
              <fa-icon [fixedWidth]="true" [icon]="faCheckSquare" *ngIf="matchDoc.player1.drop">
              </fa-icon>
            </div>
            <div class="col">{{ matchDoc.player1.displayName }}</div>
          </div>
          <div class="row player-drop mb-2"
            *ngIf="matchDoc.player2.playerUid !== '*** BYE ***' && matchDoc.player2.playerUid !== '*** LOSS ***'"
            (click)="matchDoc.player2.drop = !matchDoc.player2.drop">
            <div class="col" style="flex-grow: 0;">
              <fa-icon [fixedWidth]="true" [icon]="faSquare" *ngIf="!matchDoc.player2.drop"></fa-icon>
              <fa-icon [fixedWidth]="true" [icon]="faCheckSquare" *ngIf="matchDoc.player2.drop">
              </fa-icon>
            </div>
            <div class="col">{{ matchDoc.player2.displayName }}</div>
          </div>
        </ng-container>
        <ng-template #dropInfo>
          <div class="text-secondary">
            As this event has all it's matches generated already, dropping a player needs to be done through the player details as you need to select how the drop shall be handled.
          </div>
        </ng-template>
      </div>
    </ng-container>

  </div>

  <div class="card-footer">
    <ng-container *ngIf="this.matchDoc">
      <button class="btn btn-sm btn-secondary" *ngIf="!matchDoc.isReported" (click)="reportMatchResult()"
        [disabled]="invalidReportSlip() || isWaitingResponse || matchDoc.isLossMatch || matchDoc.isByeMatch"
        placement="top-left" ngbTooltip="Keyboard: Enter">Report</button>
      <button class="btn btn-sm btn-secondary" *ngIf="matchDoc.isReported" (click)="reportMatchResult(true)"
        [disabled]="isWaitingResponse" placement="top-left" ngbTooltip="Keyboard: Enter">Update
        result</button>
      <button class="btn btn-sm btn-danger-text" (click)="resetMatchData()"
        [disabled]="isWaitingResponse || matchDoc.isLossMatch || matchDoc.isByeMatch" placement="top-left"
        ngbTooltip="Reset slip">Reset slip</button>
    </ng-container>
  </div>

</div>

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const cardFactory: IconDefinition = {
  prefix: 'fac',
  iconName: 'cardFactory',
  icon: [
    500, // SVG view box width
    385, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M259.34.38a15.68,15.68,0,0,0-6.73,4.5c-1.57,1.72-3,4.17-7.24,12.36-2.85,5.63-5.3,10.31-5.37,10.39a5,5,0,0,1-1.9-.7,11,11,0,0,0-7.9-.58c-2.38.91-3.62,2.37-6.43,7.78s-3.37,7.43-2.56,10.32a11,11,0,0,0,5.12,5.7c.8.37,1.46.84,1.46,1s-3.62,7.43-8,16-8,15.83-8,16,7.79,4.31,17.29,9.17,21.06,10.83,25.71,13.2l8.44,4.35,8-15.57c4.39-8.6,8.19-15.87,8.41-16.16.37-.48,24.86,11.88,93.2,47.09,7.39,3.81,64.06,32.87,94,48.2,10.93,5.59,18.5,9.28,19.45,9.43a10.27,10.27,0,0,0,6.62-1.65c1.06-.8,2-2.3,4.27-6.47,2.86-5.3,2.93-5.52,2.93-8a6.7,6.7,0,0,0-1-4.27,11,11,0,0,0-2.2-2.64c-.62-.47-10-5.41-20.73-10.93C424.86,122.7,341.13,79.73,319.93,68.87,307,62.25,295.32,56.26,294,55.56l-2.3-1.24,5.33-10.39c5.89-11.51,6.62-13.6,6.26-17.88-.26-3.21-1.87-6.65-3.81-8.19-.73-.58-8.66-4.79-17.58-9.39C266.25.42,265.59.13,263.29,0A12.8,12.8,0,0,0,259.34.38ZM290.68,185H158v10.6H139.31v2.93l-69.66.07L0,198.7l1.61,4c9,21.94,28.52,37.18,60.62,47.28,11.59,3.65,19.6,5.55,48.56,11.66,10.16,2.12,21.5,4.61,25.22,5.52,18,4.43,31.23,10.53,38.58,17.88q10.64,10.69,14.08,33.83c.87,6,.91,8.3.14,12-2.08,10.17-10.31,18.72-24.53,25.56-2.78,1.35-7.5,3.29-10.53,4.35l-5.49,1.9-.11,11.19L148.08,385H210.9l.22-.84a10.66,10.66,0,0,0,.21-1.94,30.48,30.48,0,0,1,.92-4.75c3.47-13.68,14.15-24.06,28.34-27.46,3.18-.73,28.22-1.14,33.63-.52A37.33,37.33,0,0,1,296.38,360a38,38,0,0,1,11,22.52l.22,2.49h55.46V363.5l-2.81-1.57c-14.92-8.16-26.8-18.07-33.46-27.94-5.34-8-8-15.54-9.1-25.74-.55-5.38-.22-18,.58-22.53,3.29-18.24,15.8-32.36,38.25-43.11a137.4,137.4,0,0,1,19.59-7.75,294.56,294.56,0,0,1,40.44-9.8l6.66-1.17.11-19.45L423.4,185ZM140.77,255.3c-2,1-2.12,1.06-6.14.81-5.38-.3-14.92-1.43-21.8-2.6C83.51,248.57,59.52,238.45,40,222.8c-4-3.19-13.31-11.78-13.31-12.22,0-.18,1.87.77,4.13,2.09s7.32,4,11.23,6c27.57,13.82,55.83,21,88.85,22.63a79.3,79.3,0,0,1,8.51.77,8.74,8.74,0,0,1,5,5.08A6.69,6.69,0,0,1,140.77,255.3Z'
  ],
} as any;

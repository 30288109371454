<ng-container *ngIf="cardData as card">
  <ng-container *ngIf="card.layout !== 'modal_dfc'">
    <div class="card-item d-inline-block m-2" style="position: relative;">
      <div class="hidden-label" [innerHTML]="cardData.name"></div>
      <div class="flip-box {{ card.layout === 'transform' || card.card_faces.length > 1 ? 'active' : 'inactive' }}" [ngClass]="[card.set]">
        <div class="flip-box-inner">
          <div class="flip-box-front">
            <img class="card-image"
              [src]="card.layout === 'transform' || card.card_faces.length > 1 ? card.card_faces[0].image_uris.normal : card.image_uris.normal"
              [ngClass]="[card.set, card.border_color]" [title]="card.name">
          </div>
          <div class="flip-box-back" *ngIf="card.card_faces.length > 1">
            <img class="card-image" [src]="card.card_faces[1].image_uris.normal" [ngClass]="[card.set, card.border_color]" [title]="card.name">
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="canCreate; else infoText">
  <div class="create-button btn-sm btn-main" *ngIf="!maxPubsReached" (click)="createPub()">Create</div>
  <div class="footer-message d-flex align-items-center justify-content-center"  *ngIf="maxPubsReached">
    <div class="cant-create">
      You are not allowed to create any more pubs
    </div>
  </div>
</ng-container>
<ng-template #infoText>
  <div class="footer-message d-flex align-items-center justify-content-center">
    <div class="cant-create">
      You are not allowed to create pubs. To be able to create your own pub, consider becoming a Hero by supporting Tolaria.app
    </div>
  </div>
</ng-template>

<div class="card-group p-1" *ngIf="pubs$ | async as pubs">
  <div class="card m-1" *ngFor="let pub of pubs" [routerLink]="['pub', pub.docId]">
    <div class="card-header position-relative p-0">
      <div class="private" *ngIf="!pub.isPublic" ngbTooltip="This pub is private" container="body">
        <fa-icon [icon]="icon.private" [fixedWidth]="true"></fa-icon>
      </div>
      <div class="btn btn-sm btn-danger delete-button"
        *ngIf="pub.createdByUid === myUid && pub.activePlayerDocIds.length === 0"
        (click)="$event.stopPropagation(); deletePub(pub.docId)">Delete Room</div>
      <img [src]="pub.imageUri">
      <div class="enter">
        <fa-icon [icon]="icon.enter" [fixedWidth]="true" class="me-1"></fa-icon>
      </div>
    </div>
    <div class="card-footer p-2 d-flex align-items-center">
      <span class="flex-grow-1">{{ pub.name }}</span>
      <div class="btn btn-sm btn-blank">
        <fa-icon [icon]="icon.users" [fixedWidth]="true" class="me-1"></fa-icon>
        {{ pub.activePlayerDocIds.length }}
      </div>
    </div>
  </div>
</div>

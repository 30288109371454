
<div class="d-flex flex-column max-height-100 min-height-100 overflow-hidden">
    
    <div class="menu d-flex flex-row p-1 align-items-center">
        
        <div class="btn btn-sm btn-blank m-1 text-secondary"
        [routerLink]="['./']">Card Factory</div>
        
        <div class="d-inline-block flex-grow-1 m-1">
            <input type="text"
            #searchInput
            ngbTooltip="ctrl + s"
            container="body"
            placeholder="Start typing to filter"
            class="form-control form-control-sm"
            [style.minWidth-rem]="20"
            [style.maxWidth.rem]="30"
            (input)="filter()"
            [(ngModel)]="filterValue">
        </div>
        
        <div class="action-wrapper" *ngIf="isHero">
            <div class="btn btn-sm btn-outline-main m-1"
            ngbTooltip="ctrl + n"
            container="body"
            (click)="newCard()">New Card</div>
            <div class="btn btn-sm btn-blank m-1"
            ngbTooltip="crtl + m"
            container="body"
            [routerLink]="['your-cards']">My cards</div>
        </div>
        
    </div>
    
    <div class="content d-flex flex-grow-1 overflow-hidden">
        <router-outlet></router-outlet>
    </div>
    
</div>


<div class="background">
    <fa-icon [icon]="backgroundIcon" fixedWidth="true"></fa-icon>
</div>
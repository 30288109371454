import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const coin: IconDefinition = {
  prefix: 'fac',
  iconName: 'coin',
  icon: [
    512, // SVG view box width
    512, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M242.7,181.9c2.7,5.7,6.1,11.1,10.4,16.1 c1.9,2.2,3.9,4.3,6,6.4c7.1,6.8,15.1,13.7,23.9,20.7c2.5,2,5.1,4,7.6,6.1c6.4,5.2,12.8,10.5,19.2,16.1c9.1,7.8,17.1,16.1,24.2,24.8 c7.1,8.7,12.3,18.8,15.7,30.5c3.4,11.6,4,24,1.8,37.2c-2.3,14.1-7.3,26.4-14.9,36.9c-7.6,10.5-17,18.6-28.1,24.4 c-11.1,5.8-23.5,9.5-37.1,11.3c-3.6,0.5-7.3,0.8-11,1l-4.7,28.7c-1.9,11.6-12.8,19.4-24.4,17.5c-11.6-1.9-19.4-12.8-17.5-24.4 l4.3-25.8c-26.4-5.7-50.3-16.1-71.6-31l19.3-78.2l1.7,0.3c8,45.2,26.4,72.6,55.1,82.1c3.5,1.2,7.1,2.1,10.9,2.7 c12.6,2.1,23.4,0.4,32.5-4.9c0.5-0.3,1.1-0.6,1.6-0.9c9.4-6.1,15.2-15.7,17.3-28.7c1.6-9.8,0.7-19.2-2.9-28.1 c-1.4-3.5-3-6.8-4.8-9.9c-2.9-5-6.5-9.7-10.7-14c-6.8-7-14.4-14-23-21c-1.1-0.9-2.1-1.8-3.2-2.6c-7.5-6.1-14.9-12.4-22.4-19 c-8.5-7.4-16-15.2-22.6-23.4c-6.6-8.2-11.5-17.7-14.7-28.7c-3.2-10.9-3.8-22.7-1.7-35.1c4.4-26.8,16.9-46.3,37.5-58.5 c15.7-9.3,33.5-13.7,53.4-13.2l4.4-26.8c1.9-11.6,12.8-19.4,24.4-17.5c11.6,1.9,19.4,12.8,17.5,24.4l-4.5,27.1 c6.4,2,12.7,4.3,18.9,7.1c13.6,6,25.2,12.4,34.9,19.4l-19.2,75.9l-1.7-0.3c-1.7-11.2-3.9-21.1-6.5-29.7c-2.7-8.6-6.3-16.6-10.8-24.1 c-4.6-7.5-10.4-13.5-17.6-18.2c-0.9-0.6-1.8-1.1-2.7-1.7c-6.5-3.8-14-6.3-22.3-7.7c-7-1.2-13.5-0.7-19.7,1.3 c-3.4,1.1-6.7,2.8-9.9,4.9c-8.8,6-14.1,14.3-15.9,25.1C237.7,165.1,238.9,173.6,242.7,181.9z'
  ],
} as any;

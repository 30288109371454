<ng-container *ngIf="isMobile; else desktopView">
  <app-player-registration-form></app-player-registration-form>
</ng-container>

<ng-template #desktopView>
  <div class="main image-back">
    <div class="row">
      <div class="col-8">
        <div class="parchment">
          <img src="assets/parchment-01.png">
        </div>
      </div>
      <div class="col">
        <app-player-registration-form></app-player-registration-form>
      </div>
    </div>
  </div>
</ng-template>


<!-- <div class="title">Welcome to Tolaria</div>
<p>Tolaria is a remote tropical isle on Dominaria.</p>
<p>
  The remote isle of Tolaria was the site of a famous wizards' school founded by Barrin and the planeswalker Urza:
  the Tolarian Academy. This was where Urza perfected his skills as an artificer and began experimenting with
  time. His goal was to somehow travel backward in time, before Phyrexia and its god, Yawgmoth, became too strong
  to be opposed. He created a golem of silver, Karn, to test his time-travel theories.
</p>
<p>
  One time experiment went disastrously wrong, however, and the academy was decimated. Fallout from Urza's
  dangerous time-travel experiments caused time storms to ravage the island. Most of its wizards and sages were
  killed. The fabric of time on Tolaria was shattered, with unpredictable pockets of slow-moving and fast-moving
  time riddling the island. The students quickly learned to navigate and eventually explore the time bubbles.
</p>
<p>
  Several of them formed pockets in which Phyrexian horrors evolved much faster than the lands around it.
  Eventually, however, the island was somewhat healed, and the Academy was reestablished with less grandiose and
  less dangerous goals.
</p>
<p>
  During the Phyrexian Invasion, the wizard Barrin returned to Tolaria to lay his dead daughter Hanna to rest
  there, only to find it under attack by Phyrexians. Overcome with fury and a lust for vengeance, Barrin cast the
  spell he swore he would never cast. The Obliterate spell utterly obliterated the attacking Phyrexians, the
  Academy, and the isle of Tolaria itself.
</p>
<p>
  Barrin's actions created one of many rifts on Dominaria. The rift at Tolaria was too severe to be mended in the
  present day. Karn traveled back in time, to the moment before the wizard Barrin obliterated the island,
  countless Phyrexians, and himself. Karn was then able to enter the rift and mend it with the planeswalker spark
  passed on to him by Urza. In the moment after the rift was mended, Karn seemed to realize something terrible and
  flung himself into the Blind Eternities, the void between planes.
</p>
<p>
  The academy worked with time -until time ran out. (Tolarian Academy)
</p>

<p>
  Why not ask the winds? -Tolarian expression meaning "Who knows?"
</p>
<p>
  Afterward, Tolaria's winds were like the whispers of lost wizards, calling for life. (Tolarian Winds)
</p>
<p>
  "Why should I boast? The bards will do it for me -and with music." -Ertai, wizard adept (Tolarian Entrancer)
</p>
<p>
  "It is not just our people I try to rescue. It is our culture, and our hope that we can return to greatness."
  (Tolarian Sentinel)
</p>
<p>
  "Fairest Isle, all isles excelling,/ Seat of pleasures, and of loves . . ."John Dryden (Tolaria)
</p>
<p>
  Many have been lost just in staring at its wings. What's seen differs from account to account, but madness is
  always part of the story. (Tolarian Drake)
</p>
<p>
  "When the fishtails' threats of magic didn't move me, they set their pet on us." -Tahngarth of the Weatherlight
  (Tolarian Serpent)
</p> -->

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MatchRoomOpenService {

  constructor(
    private readonly router: Router,
  ) { }

  /**
   * Opens a match room defined by the given properties
   * 
   * @param id string
   */
  public openMatchRoom(id: string) {
    this.router.navigate([
      {
        outlets: {
          'match-room': [ 
            'match',
            id,
          ]
        }
      }
    ])
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { ICardMeta } from '../services/decks.service';

export interface IDeckSection {
  name: string;
  text: string;
  cardCount: number;
  cards: Array<ICardItem>;
}

export interface ICardItem {
  qty: number;
  name: string;
  imageUrl: string;
  setCode: string;
}

@Pipe({
  name: 'deckListParser',
  standalone: true,
})
export class DeckListParserPipe implements PipeTransform {

  transform(cards: Array<ICardMeta>, sideboard: boolean, maybeboard: boolean): Array<IDeckSection> {

    // return empty array if null
    if (cards === null) {
      return [];
    }

    // create the return array
    const res: Array<IDeckSection> = [];

    if (!sideboard && !maybeboard) {

      // loop through all cards to place them into the return array
      cards.forEach(card => {

        // get the formatted card type
        const cardType = this.getCardGroup(card.typeLine);

        // check if the card type is present in the return array
        const typeSectionIndex = res.findIndex(t => t.name === cardType[0]);

        if (typeSectionIndex > -1) {

          // check if the card is present in the type section
          const cardItemIndex = res[typeSectionIndex].cards.findIndex(c => c.name === card.name);

          if (cardItemIndex > -1) {

            // increment card count
            res[typeSectionIndex].cardCount++;

            // increment the quantity
            res[typeSectionIndex].cards[cardItemIndex].qty++;
          }
          else {

            // create the card item
            const cardItem: ICardItem = {
              qty: 1,
              name: card.name,
              imageUrl: card.imageUrl ? card.imageUrl : card.imageUris.normal,
              setCode: card.setCode,
            };

            // increment card count
            res[typeSectionIndex].cardCount++;

            // add the card to the type section
            res[typeSectionIndex].cards.push(cardItem);

          }
        }
        else {

          // create the type section
          const typeSection: IDeckSection = {
            name: cardType[0],
            text: cardType[1],
            cardCount: 0,
            cards: []
          };

          // create the card item
          const cardItem: ICardItem = {
            qty: 1,
            name: card.name,
            imageUrl: card.imageUrl ? card.imageUrl : card.imageUris.normal,
            setCode: card.setCode,
          };

          // increment card count
          typeSection.cardCount++;

          // add the first card to the type section
          typeSection.cards.push(cardItem);

          // add the section to the return array
          res.push(typeSection);

        }

      });

      res.forEach(section => section.cards.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
      res.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      return res;
    }
    else {

      // create the type section
      const typeSection: IDeckSection = {
        name: sideboard ? 'sideboard' : 'maybeboard',
        text: sideboard ? 'Sideboard' : 'Maybeboard',
        cardCount: 0,
        cards: []
      };

      // add the card section to the return array
      res.push(typeSection);

      cards.forEach(card => {

        // check if the card is present in the type section
        const cardItemIndex = res[0].cards.findIndex(c => c.name === card.name);

        if (cardItemIndex > -1) {

          // increment card count
          res[0].cardCount++;

          // increment the quantity
          res[0].cards[cardItemIndex].qty++;

        }
        else {

          // create the card item
          const cardItem: ICardItem = {
            qty: 1,
            name: card.name,
            imageUrl: card.imageUrl ? card.imageUrl : card.imageUris.normal,
            setCode: card.setCode,
          };

          // increment card count
          res[0].cardCount++;

          // add the card to the type section
          res[0].cards.push(cardItem);

        }
      });

      res.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      return res;
    }

  }

  getCardGroup(typeLine: string) {
    if (typeLine.toLowerCase().includes('sorcery') || typeLine.toLowerCase().includes('instant')) {
      return ['instantsAndSorceries', 'Instant & Sorceries'];
    }
    else if (typeLine.toLowerCase().includes('creature')) {
      return ['creatures', 'Creatures'];
    }
    else if (typeLine.toLowerCase().includes('land')) {
      return ['lands', 'Lands'];
    }
    else {
      return ['others', 'Others'];
    }
  }


}

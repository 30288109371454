<ng-container *ngIf="eventDoc$ | async as event">

  <div class="d-flex align-items-center mt-3 mb-2 ms-3 cursor-pointer" (click)="submenuOpen = !submenuOpen">
    <fa-icon [icon]="faChevronRight" class="text-small text-secondary me-2 transition-all-25" [ngClass]="{ 'rotate-90deg' : submenuOpen }"></fa-icon>
    <span class="text-secondary flex-grow-1">Group</span>
  </div>

  <ng-container *ngIf="submenuOpen">

    <button ngbDropdownItem (click)="addPlayoffBracket(event)"
      [disabled]="event.details.structure.group.hasBracketAfterGroupStage || event.statusCode >= 6">
      Add playoffs
    </button>
  
    <button ngbDropdownItem (click)="removePlayoffBracket(event)"
      [disabled]="!event.details.structure.group.hasBracketAfterGroupStage">
      Remove playoffs
    </button>
  
    <button ngbDropdownItem (click)="resetEvent(event)" [disabled]="event.statusCode === 0">
      Reset Event
    </button>

  </ng-container>

</ng-container>

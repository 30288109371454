import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MessageItem } from '../../services/message-list.service';

@Injectable({
  providedIn: 'root'
})
export class MessageReplyToService {

  public current$ = new BehaviorSubject<MessageItem>(null)

  constructor() { }

  public setNew(message: MessageItem) {
    this.current$.next(message)
  }


}

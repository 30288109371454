import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const tolBulletList: IconDefinition = {
  prefix: 'tol',
  iconName: 'bulletList',
  icon: [
    20, // SVG view box width
    20, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M4 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a.75.75 0 0 1 .75-.75h10a.75.75 0 0 1 0 1.5h-10A.75.75 0 0 1 7 3m.75 6.25a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5zm0 7a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5zM3 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2'
  ],
} as any;

import { CommonModule, NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faMessage, faNewspaper, faHeart, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faTrophy, faAward, faCamera, faQuestion, faBoxesPacking } from '@fortawesome/free-solid-svg-icons';
import { faStripe } from '@fortawesome/free-brands-svg-icons';
import { cardsIcon } from 'src/assets/font-icons/cards-icon';
import { cardFactory } from 'src/assets/font-icons/card-factory';
import { deckStackIcon } from 'src/assets/font-icons/deck-stack-icon';
import { draftIcon } from 'src/assets/font-icons/draft-icon';
import { RouterLink } from '@angular/router';
import { PublicFooterComponent } from '../public-footer/public-footer.component';
import { PublicHeaderComponent } from '../public-header/public-header.component';

interface IFeature {
  icon: IconDefinition
  title: string
  description: string
  routerLink: string
  hasReadMore: boolean
  readMoreRoute: string[]
}

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgFor,
    RouterLink,
    PublicFooterComponent,
    PublicHeaderComponent,
  ],
})
export class AboutComponent {

  public showMenu = false

  public features: IFeature[] = [
    {
      title: 'Event Management',
      description: `Manage both in-person and online events using our event management system, designed to handle everything from registration to match scheduling and results tracking. Whether you're hosting local meetups, competitive tournaments, or virtual events, our system offers the tools needed.`,
      icon: faTrophy,
      routerLink: '',
      hasReadMore: false,
      readMoreRoute: [''],
    },
    {
      title: 'Payment Solution',
      description: 'As a tournament organizer you will be able to sign up for a connected Stripe account and then you can manage all the entry fees connected to hosting your events with a good overview of all the payments.',
      icon: faStripe,
      routerLink: '',
      hasReadMore: true,
      readMoreRoute: ['/about', 'payment-solution'],
    },
    {
      title: 'Leagues',
      description: 'Combine multiple events into a league and customize points acquired suited for your own needs.',
      icon: faAward,
      routerLink: '',
      hasReadMore: false,
      readMoreRoute: [''],
    },
    {
      title: 'Card Search',
      description: 'Browse cards by expansion or perform advanced queries to list card to suit your needs.',
      icon: cardsIcon,
      routerLink: '',
      hasReadMore: false,
      readMoreRoute: [''],
    },
    {
      title: 'Card Factory',
      description: 'Design your own custom cards with an old school look and feel.',
      icon: cardFactory,
      routerLink: '',
      hasReadMore: false,
      readMoreRoute: [''],
    },
    // {
    //   title: 'Collection',
    //   description: `Keep track of your Old School Magic: The Gathering collection with ease! Organize, search, and manage your cards, track different editions, and build your ultimate deck. Stay on top of your collection and never lose sight of your favorite treasures!`,
    //   icon: faBoxesPacking,
    //   routerLink: '',
    //   hasReadMore: false,
    //   readMoreRoute: [''],
    // },
    {
      title: 'Deck Builder',
      description: `Craft, test, and perfect your decks like never before! Build your ultimate decks with our powerful deckbuilder! Enjoy a fixed layout for seamless organization, playtest your strategies, track different versions, and fine-tune your builds.`,
      icon: deckStackIcon,
      routerLink: '',
      hasReadMore: false,
      readMoreRoute: [''],
    },
    {
      title: 'Messages',
      description: 'Communicate with your friends with direct messages or create multi person message groups.',
      icon: faMessage,
      routerLink: '',
      hasReadMore: false,
      readMoreRoute: [''],
    },
    {
      title: 'Webcam Play',
      description: 'Jam casual games or attend online events and play matches with our custom built webcam interface featuring life counter, hand count, and many many more helpful features.',
      icon: faCamera,
      routerLink: '',
      hasReadMore: false,
      readMoreRoute: [''],
    },
    {
      title: 'Draft Engine',
      description: 'Featuring both booster draft and rotisserie draft variants that also supports webcam interface.',
      icon: draftIcon,
      routerLink: '',
      hasReadMore: false,
      readMoreRoute: [''],
    },
    // {
    //   title: 'Forum',
    //   description: 'Discuss any topic.',
    //   icon: faMessage,
    //   routerLink: '',
    //   hasReadMore: false,
    //   readMoreRoute: [''],
    // },
    {
      title: 'News',
      description: 'Stay up to date on whats happening in the world of old school magic. Our news sections gather feeds from a variety of blogs, vlogs, and pods.',
      icon: faNewspaper,
      routerLink: '',
      hasReadMore: false,
      readMoreRoute: [''],
    },
    {
      title: 'Life Counter',
      description: 'Keep track of your life totals with the user friendly life tracker. Either as stand alone or connected to an actual match in any of the events you are attending.',
      icon: faHeart,
      routerLink: '',
      hasReadMore: false,
      readMoreRoute: [''],
    },
    {
      title: 'Quiz',
      description: `Test your knowledge of Old School Magic: The Gathering with our interactive quiz! Challenge yourself with questions about classic card art, types, artists, expansions, and casting costs. Answer quickly and correctly to build your streak and prove you're a true old-school planeswalker!`,
      icon: faQuestion,
      routerLink: '',
      hasReadMore: false,
      readMoreRoute: [''],
    },
    {
      title: 'Deckster',
      description: `Discover your favorite Old School Magic: The Gathering cards in this fun swipe game! Swipe right to like and left to dislike as random cards from the classic pool appear. See how your preferences stack up and explore the legendary cards that shaped Magic history!`,
      icon: faThumbsUp,
      routerLink: '',
      hasReadMore: false,
      readMoreRoute: [''],
    },
  ]

  constructor() { }

}

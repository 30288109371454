<div class="mt-2">
  <div class="h6">Rulings</div>
  <ng-container *ngIf="infoLoaded$ | async; else loader">
    <ng-container *ngFor="let rule of rulings">
      <div class="row pt-2 pb-2">
        <div class="col-4 text-secondary">{{ rule.published_at }}</div>
        <div class="col" [innerHTML]="rule.comment | manaParser : { fontClass: 'text-smaller' }"></div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #loader>
    <div class="lds-facebook"><div></div><div></div><div></div></div>
  </ng-template>
</div>

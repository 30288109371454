<div class="modal-body p-4 d-flex flex-column gap-4 rounded-4">
    
    <div class="d-flex align-items-center justify-content-between">
        <div class="text-larger">Team Configuration</div>
        <button class="btn-close" (click)="onDismiss()"></button>
    </div>

    <div class="py-4 d-flex flex-column gap-5" *ngIf="team$ | async as team">

        <div>
            <label for="teamName" class="form-label">Team name</label>
            <input type="text" class="form-control" id="teamName" placeholder="Enter the most awesome team name ever..." [(ngModel)]="team.name">
        </div>

        <div>
            <label for="addTeamPlayers" class="form-label">Assign players to this team</label>
            <input #instance="ngbTypeahead" #searchElement
                type="text"
                class="form-control"
                id="addTeamPlayers"
                aria-describedby="addTeamPlayerInfo"
                placeholder="Search players not assigned to a team"
                [resultTemplate]="rt"
                [inputFormatter]="formatter"
                [editable]="false"
                [(ngModel)]="playerSearch"
                [disabled]="teamIsFull"
                [ngbTypeahead]="search"
                (selectItem)="outputSelected($event)"
                (focus)="focus$.next($any($event).target.value)"
	            (click)="click$.next($any($event).target.value)">
            <div id="addTeamPlayerInfo" class="form-text">
                If you can't find the player you are looking for. Make sure the player is attending the event.
                This list only show attending players that are not already assigned to a team.
            </div>

            <ng-template #rt let-r="result" let-t="term">
                <div class="d-flex align-items-center gap-3">
                    <ngb-highlight [result]="r.eventPlayer.name" [term]="t"></ngb-highlight>
                </div>
            </ng-template>
        </div>

        <div>
            <label for="teamPlayers" class="form-label">Assigned players</label>
            <ng-container *ngIf="players$ | async as players">
                <ul class="list-group" id="teamPlayers" aria-describedby="teamPlayersHelp" *ngIf="players.length > 0; else noPlayerInTeam">
                    <li *ngFor="let player of players"
                        (click)="onSelectPlayer(player)"
                        class="list-group-item list-group-item-action d-flex align-items-center justify-content-between cursor-pointer gap-3" 
                        [ngClass]="{ 'active' : player.selected }"
                        [attr.playerDocId]="player.id">
                        <span class="flex-grow-1">{{ player.name }}</span>
                        <div class="m-0 alert alert-info p-1 px-2" role="alert" *ngIf="player.isSeated">Seated at {{ player.seatLetter }}</div>
                        <button class="btn {{ player.selected ? 'btn-danger' : 'btn-outline-danger' }} btn-sm text-medium" (click)="onUnassignPlayer(player)">Remove</button>
                    </li>
                </ul>
                <div id="teamPlayersHelp" class="form-text">
                    Select a player to enable seating if any seat is unoccupied. If no seats unoccupied, please clear a seat first.
                </div>
                <ng-template #noPlayerInTeam>
                    <ul class="list-group" id="teamPlayers" aria-describedby="teamPlayersHelp">
                        <li class="list-group-item list-group-item-action d-flex align-items-center justify-content-between cursor-pointer gap-3">
                            <span class="flex-grow-1">No players assigned to this team yet.</span>
                        </li>
                    </ul>
                    <div id="teamPlayersHelp" class="form-text">
                        Please assign some players to the team before you can start the seating process.
                    </div>
                </ng-template>
            </ng-container>
        </div>

        <div>
            <label for="teamSeats" class="form-label">Player seating</label>
            <div id="teamSeats" class="d-flex align-items-center justify-content-between gap-3">
    
                <div *ngFor="let seat of seats"
                    class="team-seat col-3 flex-grow-1 d-flex flex-column align-items-center gap-3 p-3 rounded rounded-3 bg-secondary bg-opacity-25 border border-secondary cursor-pointer">
                    <span class="d-flex flex-column align-items-center gap-1">
                        <span class="text-smaller">SEAT</span>
                        <span class="text-xlarge fw-bold">{{ seat.letter }}</span>
                    </span>
                    <div class="text-center">
                        <span [ngClass]="{ 'text-italic text-warning' : !seat.occupied }">{{ seat.occupied ? seat.playerName : 'Unoccupied' }}</span>
                    </div>
                    <div class="d-flex gap-2 align-items-center justify-content-between">
                        <button [disabled]="!seat.occupied" class="btn btn-sm btn-warning flex-grow-1" (click)="onClearSeat(seat.seat)">Clear seat</button>
                        <button [disabled]="seat.occupied || selectedPlayer === null" class="btn btn-sm btn-success flex-grow-1" (click)="onSeatSelected(seat)">Seat player</button>
                    </div>
                </div>
    
            </div>
        </div>


    </div>

    <div class="d-flex align-items-center justify-content-end gap-3">
        <button class="btn btn-blank focus-ring focus-ring-secondary" (click)="onDismiss()">Cancel</button>
        <button class="btn btn-success focus-ring focus-ring-success" [disabled]="disableSave" (click)="onClose()">Save changes</button>
    </div>

</div>
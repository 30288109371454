import { DeckPhotoComponent } from './../deck-photo/deck-photo.component';
import { take } from 'rxjs/operators';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from 'src/app/services/auth.service';
import { faTimesCircle, faExternalLinkAlt, faImage, faExclamationTriangle, faCheckCircle, faEye, faCamera, faFileAlt, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Observable, BehaviorSubject } from 'rxjs';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { IDeckMeta, DecksService, MagicDeck, IDeckList, IDeckVersionMeta } from 'src/app/services/decks.service';
import { GlobalsService } from 'src/app/services';
import { IDeckSubmission } from 'tolaria-cloud-functions/src/_interfaces';
import { DeckListComponent } from '../deck-list/deck-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DeckLinkerPipe } from 'src/app/pipes/deck-linker.pipe';
import { ImageCropperUploaderComponent } from '../../app-structure/image-cropper-uploader/image-cropper-uploader.component';
import { DeckListImporterComponent } from '../deck-builder/deck-list-importer/deck-list-importer.component';

export interface IDeckLinkerResponse {
  selectedDeck: IDeckMeta,
  selectedDeckVersion: IDeckVersionMeta,
  deckPhotoBase64: string;
  newDeck: IDeckList;
}
@Component({
  selector: 'app-deck-linker',
  templateUrl: './deck-linker.component.html',
  styleUrls: ['./deck-linker.component.css'],
  standalone: true,
  imports: [
    FontAwesomeModule,
    CommonModule,
    NgbModule,
    FormsModule,
    DeckLinkerPipe,
    ImageCropperUploaderComponent,
    DeckListImporterComponent,
  ]
})
export class DeckLinkerComponent implements OnInit {
  @Input() mandatoryDeckList: boolean = false;
  @Input() mandatoryDeckPhoto: boolean = false;
  @Input() deckSubmission: IDeckSubmission = null;
  @Input() selectionOnly: boolean = false
  @Input() title: string = 'Deck Submitter'

  public faTimesCircle = faTimesCircle;
  public faExternalLinkAlt = faExternalLinkAlt;
  public faImage = faCamera;
  public faListAlt = faFileAlt;
  public faExclamationTriangle = faExclamationTriangle;
  public faCheckCircle = faCheckCircle;
  public faEye = faEye;
  public iconHelp = faQuestionCircle;

  public deckListItems$: Observable<IDeckMeta[]> = this.deckService.deckMetas$;
  public filterString: string = '';

  public showLinking = true;
  public showRegistrationHelp = true;

  public selectedDeck$: BehaviorSubject<IDeckMeta> = new BehaviorSubject<IDeckMeta>(null);
  public selectedDeckVersion$: BehaviorSubject<IDeckVersionMeta> = new BehaviorSubject<IDeckVersionMeta>(null);
  public newDeck: IDeckList = null;
  public deckPhotoBase64: string = null;

  constructor(
    private activeModal: NgbActiveModal,
    private deckService: DecksService,
    private auth: AuthService,
    public globals: GlobalsService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    // check if deck submission is
    if (this.deckSubmission !== null && this.deckSubmission !== undefined && this.deckSubmission.deckListDocId !== null && this.deckSubmission.deckListDocId !== '') {
      this.deckService.deckMetas$.pipe(take(1)).subscribe(deckMetas => {

        // fetch the selected deck
        const submittedDeck = deckMetas.find(d => d.docId === this.deckSubmission.deckListDocId);
        // set selected deck and version if existing
        if (submittedDeck) {
          this.selectedDeck$.next(submittedDeck);
          if (this.deckSubmission.deckVersionDocId !== null && this.deckSubmission.deckVersionDocId !== '' && this.deckSubmission.deckVersionDocId !== undefined) {
            const submittedVersion = submittedDeck.versions.find(v => v.versionDocId === this.deckSubmission.deckVersionDocId);
            console.log(submittedVersion);
            this.selectedDeckVersion$.next(submittedVersion);
          }
        }
        // remove link from event > player if deck does not exist
        else {

        }
      });
    }
  }


  public openDeckListViewer(): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'xl',
    };

    console.log(this.selectedDeckVersion$.getValue());

    const modalRef: NgbModalRef = this.modalService.open(DeckListComponent, modalOptions);
    modalRef.componentInstance.wrapperClass = 'p-3';
    modalRef.componentInstance.deckListDocId = this.selectedDeck$.getValue().docId;
    modalRef.componentInstance.deckListVersionDocId = this.selectedDeckVersion$.getValue() ? this.selectedDeckVersion$.getValue().versionDocId : null;
  }
  public openDeckPhotoViewer(): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'xl',
    };

    const modalRef: NgbModalRef = this.modalService.open(DeckPhotoComponent, modalOptions);
    modalRef.componentInstance.deckListDocId = this.selectedDeck$.getValue().docId;
    modalRef.componentInstance.deckListVersionDocId = this.selectedDeckVersion$.getValue() ? this.selectedDeckVersion$.getValue().versionDocId : null;
  }
  public selectLinking(val: boolean): void {
    this.showLinking = val;
    this.selectedDeck$.next(null);
    this.selectedDeckVersion$.next(null);
    this.newDeck = null;
    this.deckPhotoBase64 = null;
    this.createNewDeck();
  }
  public loadDeckFromImporter(deck: IDeckList): void {
    deck.name = this.newDeck.name;
    deck.description = this.newDeck.description;

    this.newDeck = deck;

    console.log(this.newDeck);
  }
  public croppedImage(base64: string): void {
    this.deckPhotoBase64 = base64;
  }
  public selectDeck(deck: IDeckMeta): void {
    this.selectedDeck$.next(deck);
    this.selectedDeckVersion$.next(null);
  }
  public selectDeckVersion(version: IDeckVersionMeta): void {
    this.selectedDeckVersion$.next(version);
  }
  public createNewDeck(): void {
    this.newDeck = new MagicDeck(this.auth.user.playerId, this.auth.user.uid).data;
  }
  public get selectedDeckVersionName(): string {
    return this.selectedDeckVersion$.getValue() === null ? 'origin' : this.selectedDeckVersion$.getValue().versionName;
  }
  public close(): void {
    const response: IDeckLinkerResponse = {
      selectedDeck: this.selectedDeck$.getValue(),
      selectedDeckVersion: this.selectedDeckVersion$.getValue(),
      newDeck: this.newDeck,
      deckPhotoBase64: this.deckPhotoBase64,
    };
    this.activeModal.close(response);
  }
  public dismiss(): void {
    this.activeModal.dismiss('dismissed');
  }
  public get deckListValid(): boolean {
    return this.mandatoryDeckList ? this.newDeck.main.length >= 60 && this.newDeck.sideboard.length <= 15 : true;
  }
  public get deckPhotoValid(): boolean {
    return this.mandatoryDeckPhoto ? this.deckPhotoBase64 !== null : true;
  }
  public get selectedDeckPhotoInvalid(): boolean {
    if (!this.mandatoryDeckPhoto) { return false }
    else if (this.selectedDeckVersion$.getValue() !== null && this.selectedDeckVersion$.getValue().deckPhotoValid) { return false }
    else if (this.selectedDeck$.getValue() !== null && this.selectedDeckVersion$.getValue() === null && this.selectedDeck$.getValue().deckPhotoValid) { return false }
    return true;
  }
  public get selectedDeckListInvalid(): boolean {
    if (!this.mandatoryDeckList) { return false }
    else if (this.selectedDeckVersion$.getValue() !== null && this.selectedDeckVersion$.getValue().deckListValid) { return false }
    else if (this.selectedDeck$.getValue() !== null && this.selectedDeckVersion$.getValue() === null && this.selectedDeck$.getValue().deckListValid) { return false }
    return true;
  }
  public get selectedDeckValidity(): any {
    if (this.selectedDeckListInvalid && this.selectedDeckPhotoInvalid) {
      return {
        text: 'Deck photo missing and deck list incomplete',
        class: 'text-red',
        icon: faExclamationTriangle,
      }
    }
    if (!this.selectedDeckListInvalid && this.selectedDeckPhotoInvalid) {
      return {
        text: 'Deck photo missing',
        class: 'text-red',
        icon: faExclamationTriangle,
      }
    }
    if (this.selectedDeckListInvalid && !this.selectedDeckPhotoInvalid) {
      return {
        text: 'Deck list incomplete',
        class: 'text-red',
        icon: faExclamationTriangle,
      }
    }

    return {
      text: 'Deck photo and deck list all good!',
      class: 'text-green',
      icon: faCheckCircle,
    }
  }
  public get disableConfirmButton(): boolean {
    // deck linking active and NO deck selected
    if (this.showLinking) {
      // origin version selected
      if (this.selectedDeck$.getValue() !== null && this.selectedDeckVersion$.getValue() === null) {
        return this.selectedDeckListInvalid || this.selectedDeckPhotoInvalid;
      }
      // deck version selected
      if (this.selectedDeck$.getValue() !== null && this.selectedDeckVersion$.getValue() !== null) {
        return this.selectedDeckListInvalid || this.selectedDeckPhotoInvalid;
      }
    }
    if (!this.showLinking) {

      if (this.newDeck === null) { return true }
      if (this.newDeck.name.trimLeft().length < 5) { return true }
      if (this.newDeck.description.length < 5) { return true }
      if (this.mandatoryDeckList && !this.deckListValid) { return true }
      if (this.mandatoryDeckPhoto && !this.deckPhotoValid) { return true }

    }

    return false;

  }

}

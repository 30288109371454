
<div *ngIf="replyTo$ | async as data"
  class="reply-to-meta d-flex align-items-center gap-2 position-absolute rounded-top-2 border p-2 border-bottom-0"
  style="top: calc(-3rem - 1px); background: var(--bs-body-bg); width: calc(100% - 4rem); margin-left: 1rem; border-color: rgba(--var(--bs-secondary-rgb), 0.325);">
  <span class="text-secondary text-medium text-nowrap">Reply to:</span>
  <app-player-avatar [disablePopover]="true" [border]="false" [playerDocId]="data.playerDocId" size="small"></app-player-avatar>
  <span class="flex-grow-1 text-truncate">{{ data.message.message }}</span>
  <button class="me-1 btn-close" (click)="onReplyToClearPress()"></button>
</div>

<div class="tolaria-wysiwyg-container"
  [ngClass]="{
    'focused' : editorFocused && windowFocused,
    'silent-mode' : silentMode
  }">

  <quill-editor class="tolaria-wysiwyg w-100 pt-1 px-1"
    [ngClass]="{
      'toolbar-hidden' : !toolbarVisible,
      'focused' : editorFocused && windowFocused,
      'only-emojis' : onlyEmojis
    }"
    [placeholder]="placeholder"
    (onEditorCreated)="onEditorCreated($event)"
    (onContentChanged)="onContentChanged($event)"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onEditorChanged)="onEditorChanged($event)"
    (onFocus)="onFocus($event)"
    (onBlur)="onBlur($event)"
    (onNativeFocus)="onNativeFocus($event)"
    (onNativeBlur)="onNativeBlur($event)"
    [debug]="false"
    [modules]="modules">
    <div quill-editor-toolbar>
      <tolaria-wysiwyg-toolbar [show]="toolbarVisible"></tolaria-wysiwyg-toolbar>
    </div>
  </quill-editor>
  
  <div class="images d-flex align-items-center p-2 gap-2" *ngIf="images$ | async as images">
    <ng-container *ngFor="let image of images">
      <div class="message-image position-relative"
        [ngClass]="{ 'uploading-in-progress' : image.isUploading }"
        [style.height.rem]="5"
        [style.width.rem]="5">
        <div class="image-wrapper border border-secondary rounded rounded-3 overflow-hidden d-flex justify-content-center"
          [style.height.rem]="5"
          [style.width.rem]="5">
          <img [src]="image.base64" height="100%">
        </div>
        <div *ngIf="image.isUploading"
          class="upload-overlay w-100 h-100 rounded rounded-3 position-absolute top-0 start-0 bg-black"
          style="--bs-bg-opacity: .75;">
        </div>
        <div *ngIf="image.isUploading"
          class="spinner-wrapper position-absolute top-50 start-50 translate-middle d-flex gap-2 flex-column justify-content-center align-items-center">
          <div role="status" class="spinner-border">
            <span class="visually-hidden">Loading...</span>
          </div>
          <span class="text-smaller">Uploading...</span>
        </div>
        <div class="btn-delete-image position-absolute top-0 start-100 translate-middle d-flex align-items-center justify-content-center"
          ngbTooltip="Remove image"
          container="body"
          placement="top"
          tooltipClass="ql-button-tooltip"
          [openDelay]="250"
          (click)="onDeleteImage(image)">
          <fa-icon [icon]="icon.times"></fa-icon>
        </div>
      </div>
    </ng-container>
  </div>
  
  <ng-container *ngIf="editorReady$ | async">
    <tolaria-wysiwyg-footer
      [editor]="editor"
      [visible]="toolbarVisible"
      [silentMode]="silentMode"
      [composer]="composer"
      [editorEmpty]="isEmpty"
      [disableSend]="disableSend"
      (onButtonPress)="onFooterButtonPress($event)">
    </tolaria-wysiwyg-footer>
  </ng-container>
  <!-- <tolaria-wysiwyg-footer [visible]="toolbarVisible"
    [imageUploadEnabled]="imageUploadEnabled"
    (onButtonPress)="onFooterButtonPress($event)">
  </tolaria-wysiwyg-footer> -->

</div>
  
<div *ngIf="!readonly"
  [ngClass]="{ 'invisible' : isEmpty }"
  class="d-flex align-items-center justify-content-end text-small text-secondary mb-1 gap-1">
  <span class="text-bold">Shift + Return</span>
  <span>to add a new line</span>
</div>
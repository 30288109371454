import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { BehaviorSubject, Observable, OperatorFunction, Subject } from 'rxjs'
import { applicationIcon } from 'src/assets/font-icons/tolaria-icon'
import { debounceTime, distinctUntilChanged, filter, map, tap, takeUntil } from 'rxjs/operators'
import { PlayerNameService } from 'src/app/services/players/player-name.service'
import { IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces'

@Component({
  selector: 'app-tolaria-player-search',
  templateUrl: './tolaria-player-search.component.html',
  styleUrls: ['./tolaria-player-search.component.css']
})
export class TolariaPlayerSearchComponent implements OnInit, OnDestroy {
  @Output() playerDocId = new EventEmitter()
  @Output() toggleBack = new EventEmitter()
  @Input() clearOnSelect: boolean = false 

  public tolariaPlayer: IPlayerMini | string = ''
  public playerSearchReady: boolean = false
  public playerNames$: BehaviorSubject<IPlayerMini[]> = new BehaviorSubject<IPlayerMini[]>(null)
  private destroyed$: Subject<boolean> = new Subject<boolean>()

  public search: OperatorFunction<string, readonly IPlayerMini[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter((term) => term.length >= 2),
      tap((term) => {
        console.log('TolariaPlayerSearchComponent:: search term ->', term)
        const result = this.playerNames$.getValue().filter((v) => v.name.display.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
        console.log('TolariaPlayerSearchComponent:: found these records ->', result)
      }),
      map((term) => this.playerNames$.getValue().filter((v) => v.name.display.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  public formatter = (x: IPlayerMini) => x ? x.name.display ? x.name.display : `${x.name.first} ${x.name.last}` : ''

  public facTolaria = applicationIcon

  constructor(
    private readonly playerNames: PlayerNameService,
  ) {
    this.playerNames.playerMinis$.pipe(takeUntil(this.destroyed$)).subscribe(i => { 
      if (i !== null || i.length !== 0) {
        this.playerSearchReady = true
        this.playerNames$.next(i)
      }
    })
  }

  ngOnInit(): void {
    console.log('[TolariaPlayerSearchComponent] --> init')
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public selectPlayer(): void {
    if (this.tolariaPlayer && this.tolariaPlayer.hasOwnProperty('id')) {
      const player = JSON.parse(JSON.stringify(this.tolariaPlayer)) as IPlayerMini
      this.playerDocId.emit(player.id)
      if (this.clearOnSelect) {
        this.tolariaPlayer = ''
      }
    }
  }

  public toggleInput(): void {
    this.toggleBack.emit(false)
  }

}

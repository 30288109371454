import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const draftIcon: IconDefinition = {
  prefix: 'fac',
  iconName: 'draft',
  icon: [
    500, // SVG view box width
    375.7786, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M134.7,35.8l6,23.9v250.5l-6,23.9v26.7l-54.9,15.1L72,345.1l0-21.8L18,108.4L7.7,89.2L0,58.5l134.7-35.1V35.8z M269.3,177.3 c-0.2-7.1-2.1-17.4-5.9-31.2c-3.4-12.5-5.7-19.2-6.7-20.1h-4.5c-2,0-5.5,0.1-10.5,0.2c-5,0.1-8.4,0.2-10.3,0.2 c-8.1,30.6-12.8,48.5-14,53.6c0.5,0.1,2.3,0.2,5.2,0.2C235.2,180.3,250.8,179.3,269.3,177.3z M145.6,0h208.8v35.8l-6,23.9v250.5 l6,23.9v35.8H145.6V334l6-23.9V59.6l-6-23.9V0z M178.2,255.9c0,1.8,0.1,3.8,0.2,6.2c25.9-1.7,39.8-2.6,41.4-2.9c0-4.7-1-7.3-2.9-7.8 c-1.8,0-3.7,0.1-5.8,0.4c-2.1,0.3-3.5,0.4-4,0.4c-2,0-3.2-1.4-3.5-4.1c0-7.8,1.3-17.8,3.9-29.8c1.7-8,3.2-13.9,4.5-17.8 c1.6-1.8,11-3.9,28.2-6.2c14.8-2,25.1-3.1,31-3.3c1.8,1.9,4.9,20.3,9.5,55.3c-1.1,1.4-4.4,2.8-10,4.1c-4.9,1.1-7.4,2.6-7.4,4.5 c0,1.4,0.8,2.9,2.5,4.5c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.1,2-0.3,5.4-0.8c4-0.5,9.1-0.8,15.4-0.8c8.8,0,15.6,0.3,20.6,0.9 c5,0.6,7.4,0.9,7.2,0.9c1.7,0,4.2-0.3,7.5-1c0-3.7-1-6.1-2.9-7.4c-1.2-0.8-3.2-1.3-6-1.3c-2.8-0.1-4.7-0.4-5.5-1 c-2.8-1.9-5.7-10.6-8.9-26c-1.9-10.6-3.8-21.2-5.8-31.7l-0.2-1c-1-2.2-3-11.2-6.1-27c-3.3-17.3-5-29.1-5-35.5c0-2.4,0.5-3.8,1.4-4.3 c0.3-0.2,1.8-0.5,4.5-0.8c4.9-0.7,8.6-1.9,11.1-3.7c-1.4-3.4-4.5-5.2-9.5-5.2c-1.9,0-8.2,0.6-18.9,1.9c-10.7,1.3-20.3,1.9-28.7,1.8 c-24.7-1-45.2-2-61.4-2.9c0,4.9,4.8,8,14.5,9.4c7.1,0.5,14.1,1,21.2,1.5c3.6,0.5,5.4,1.4,5.6,2.7c-9.1,34-15.2,57.3-18.1,69.8 c-0.6,0-1.1,0.8-1.5,2.3c-8.7,33.8-13.6,51.1-14.8,51.9c-1.5,1.1-2.8,1.6-3.9,1.6c-0.5,0-1.1-0.1-1.6-0.4l-0.7-0.4 c-0.9-0.1-1.2-0.2-1-0.2C178.6,252.8,178.2,253.9,178.2,255.9z M365.3,23.4v12.4l-6,23.9v250.5l6,23.9v26.7l54.9,15.1l7.7-30.7 l0-21.8l54-214.9l10.3-19.2l7.7-30.7L365.3,23.4z M265.8,259.4C265.8,259.4,265.8,259.4,265.8,259.4 C265.7,259.4,265.7,259.4,265.8,259.4z'
  ],
} as any;

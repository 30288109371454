import { AuthService } from 'src/app/services';
import { Component, Input } from '@angular/core';
import { tiskelionIcon } from 'src/assets/font-icons/tiskelion-icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { ManaParserPipe } from 'src/app/pipes/mana-parser.pipe';
import { faMask } from '@fortawesome/free-solid-svg-icons';
import { MessageItem } from 'src/app/private/social/messages/services/message-list.service';

@Component({
  selector: 'app-scryfall-image',
  templateUrl: './scryfall-image.component.html',
  styleUrls: ['../messages-style.css'],
  standalone: true,
  imports: [
    FontAwesomeModule,
    CommonModule,
    ManaParserPipe,
  ]
})
export class ScryfallImageComponent {
  @Input() messageDoc: MessageItem;
  faRobot = tiskelionIcon;
  public whisperMode = faMask
  constructor(public auth: AuthService) { }
  getDate(timestamp: number) {
    return new Date(timestamp * 1000);
  }
}

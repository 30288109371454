import { AuthService } from 'src/app/services/auth.service'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Component, ComponentRef, Input, OnInit } from '@angular/core'
import { faBell, faPalette, faAddressCard, faHatWizard, faTimes, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  @Input() componentRef: ComponentRef<SettingsComponent>
  @Input() startupView: string = 'you'
  @Input() action: string = null

  faBell = faBell
  faPalette = faPalette
  faAddressCard = faAddressCard
  faHatWizard = faHatWizard
  faTimes = faTimes
  faMoneyCheckAlt = faMoneyCheckAlt

  settings = {
    you: false,
    apperance: false,
    notifications: false,
    avatar: false,
    payments: false,
  }

  constructor(
    private activeModal: NgbActiveModal,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.settings[this.startupView] = true
  }

  updateSettings(val: boolean) {
    if (val) {
      this.settingsFalse()
      this.settings.you = val
    }
  }

  settingsFalse() {
    this.settings.avatar = false
    this.settings.you = false
    this.settings.notifications = false
    this.settings.apperance = false
    this.settings.payments = false
  }

  destroySelf() {
    this.activeModal.close()
  }

  public get isOrganizer(): boolean {
    return this.auth.user.role === 'admin' || this.auth.user.role === 'organizer'
  }
}

import { RegisterEventPaymentComponent } from 'src/app/payment/register-event-payment/register-event-payment.component';
import { PaymentService } from 'src/app/payment/payment.service'
import { EventService } from 'src/app/services/event/event.service'
import { DropConfirmationComponent } from './../drop-confirmation/drop-confirmation.component'
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { faCoins, faFileAlt, faCamera, faEllipsisH, faExclamationTriangle,
  faReply, faHourglassHalf, faTicketAlt, faUserCheck, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { IEventPlayerDetails, IEventDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { Component, Input, ViewChild, ElementRef, OnInit, ViewEncapsulation } from '@angular/core'
import { IRegistrationFeePayment, PaymentFactor, PaymentStatus, PaymentType, ProductType } from 'tolaria-cloud-functions/src/_interfaces'
import { DeckPhotoComponent } from 'src/app/components/decks/deck-photo/deck-photo.component'
import { DeckListComponent } from 'src/app/components/decks/deck-list/deck-list.component'
import { MatchService } from 'src/app/services/match.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-event-to-attending-player',
  templateUrl: './event-to-attending-player.component.html',
  styleUrls: ['./event-to-attending-player.component.css']
})
export class EventToAttendingPlayerComponent implements OnInit {
  @Input() player: IEventPlayerDetails
  @Input() event: IEventDetails
  @Input() hideIfAvailable: boolean = false
  @ViewChild('unattendConfirmationModal') unattendConfirmationModal: ElementRef
  @ViewChild('confirmRefunding') confirmRefunding: ElementRef

  faCoins = faCoins
  faFileAlt = faFileAlt
  faCamera = faCamera
  faEllipsisH = faEllipsisH
  faExclamationTriangle = faExclamationTriangle
  faTicket = faTicketAlt
  faCheckedIn = faUserCheck
  faCalendar = faCalendar
  iconRefunded = faReply
  iconPending = faHourglassHalf
  private availableDuringEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  confirmRefundCheckBox: boolean = false
  refundRegistrationFee: boolean = false
  refundCharityAmount: boolean = false

  constructor(
    private modalService: NgbModal,
    private eventService: EventService,
    private payment: PaymentService,
    private matchService: MatchService,
  ) {
  }

  ngOnInit(): void {
    console.log('Init eventToAttendingPlayerComponent')
    if (this.event.details.isMultiDay) {
      this.getEventAvailabilityChecker()
    }
  }

  private async getEventAvailabilityChecker() {
    const available = await this.matchService.playerAvailableDuringEvent(this.player.playerDocId, this.event.docId, this.event)
    this.availableDuringEvent$.next(available)
  }

  public drop(): void {
    console.log(`drop ${this.player.name}`)
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    }
    const modalRef = this.modalService.open(DropConfirmationComponent, modalOptions)
    modalRef.componentInstance.player = this.player
    modalRef.componentInstance.event = this.event
    modalRef.result.then(
      (data: any) => {
        console.log(data)
        switch (data.eventType) {

          case 'group':
            if (data.exchangePlayer) {
              console.log(`Exchange players`)
              this.eventService.exchangePlayerWithPlayerInEvent(this.event, this.event.docId, this.player.playerDocId, data.playerDocIdBeingAdded, data.tolariaSearch)
                .then((success) => console.log(success))
                .catch((error) => console.log(error))
            }
            else {
              console.log(`Just drop player`)
              this.eventService.dropPlayerFromEventAndDeleteMatches(this.event.docId, this.player.playerDocId, this.event)
                .then((success) => console.log(success))
                .catch((error) => console.log(error))
            }
            break

          default:
            console.log(`Drop player from ${data.eventType}`)
            this.eventService.dropPlayerFromEvent(this.event.docId, this.player.playerDocId)
              .then((success) => console.log(success))
              .catch((error) => console.log(error))

        }
      },
      () => {
        console.log('dismiss')
      }
    )
  }

  public toggleCheckIn(checkIn: boolean): void {
    if (checkIn) {
      this.eventService.checkInPlayer(this.event.docId, this.player.playerDocId)
    }
    else {
      this.eventService.checkOutPlayer(this.event.docId, this.player.playerDocId)
    }
  }

  public unDrop(): void {
    this.eventService.undropPlayerFromEvent(this.event.docId, this.player.playerDocId)
  }

  public unattend(): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'sm',
    }
    this.modalService.open(this.unattendConfirmationModal, modalOptions).result
      .then(
        () => {
          console.log('unattendPlayer', this.event.docId, this.player.playerDocId)
          this.eventService.removePlayerFromEvent(this.event.docId, this.player.playerDocId, this.player.name)
            .then((success) => console.log(success))
            .catch((error) => console.log(error))
        },
        () => { }
      )
  }

  public registerPayment(): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    }
    const modalRef: NgbModalRef = this.modalService.open(RegisterEventPaymentComponent, modalOptions);
    modalRef.componentInstance.event = this.event
    modalRef.componentInstance.player = this.player
    modalRef.componentInstance.showAsModal = true
  }

  public issueRefund(): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    }
    this.modalService.open(this.confirmRefunding, modalOptions).result
      .then(
        (amount: number) => {
          const paidBy = this.event.details.registrationFee.paidBy[this.player.playerDocId] as any
          if (paidBy.paymentType === PaymentType.TOLARIA) {
            this.payment.issueRefund({
              amount: parseInt(paidBy.metadata.registrationFeeAmount),
              payment_intent: paidBy.paymentIntent,
            })
              .then(res => console.log(res))
              .catch(err => console.log(err))
          }
          else {
            const fullRefund = (this.event.details.registrationFee.paidBy[this.player.playerDocId].amount + this.event.details.registrationFee.paidBy[this.player.playerDocId].charityAmount) === amount
            this.payment.manualRefund(
              ProductType.REGISTRATION_FEE,
              fullRefund ? PaymentStatus.REFUNDED : PaymentStatus.PARTIALLY_REFUNDED,
              amount,
              {
                eventDocId: this.event.docId,
                playerDocId: this.player.playerDocId,
                playerName: this.player.name,
                paymentType: this.event.details.registrationFee.paidBy[this.player.playerDocId].paymentType,
              })
              .then(res => console.log(res))
              .catch(err => console.log(err))
          }

        },
        () => { }
      )
    this.confirmRefundCheckBox = false
  }

  public openDeckPhoto(): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'xl',
    }
    const modalRef: NgbModalRef = this.modalService.open(DeckPhotoComponent, modalOptions)
    modalRef.componentInstance.deckListDocId = this.player.deckSubmission.deckListDocId
    modalRef.componentInstance.deckListVersionDocId = this.player.deckSubmission.deckVersionDocId
  }

  public openDeckList(): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'xl',
    }
    const modalRef: NgbModalRef = this.modalService.open(DeckListComponent, modalOptions);
    modalRef.componentInstance.wrapperClass = 'p-3';
    modalRef.componentInstance.deckListDocId = this.player.deckSubmission.deckListDocId
    modalRef.componentInstance.deckListVersionDocId = this.player.deckSubmission.deckVersionDocId
  }

  public get hasPaidRegistrationFee(): boolean {
    return this.event.details?.registrationFee?.paidBy && this.event.details?.registrationFee?.paidBy[this.player.playerDocId]
      ? this.event.details.registrationFee.paidBy[this.player.playerDocId].paid
      : false
  }

  public get paymentPending(): boolean {
    return this.event.details?.registrationFee?.paidBy && this.event.details?.registrationFee?.paidBy[this.player.playerDocId]?.status === PaymentStatus.PENDING
      ? this.event.details.registrationFee.paidBy[this.player.playerDocId].paid
      : false
  }

  public get hasBeenRefunded(): boolean {
    return this.event.details?.registrationFee?.paidBy && this.event.details?.registrationFee?.paidBy[this.player.playerDocId]
      ? this.event.details.registrationFee.paidBy[this.player.playerDocId].refunded
      : false
  }

  public get refundPending(): boolean {
    return this.event.details?.registrationFee?.paidBy && this.event.details?.registrationFee?.paidBy[this.player.playerDocId]?.status === PaymentStatus.PENDING
      ? this.event.details.registrationFee.paidBy[this.player.playerDocId].refunded
      : false
  }

  public get registrationFee(): boolean {
    return this.event.details?.registrationFee?.active
  }

  public get disablePayment(): boolean {
    return !this.registrationFee || this.hasPaidRegistrationFee
  }

  public get disableRefund(): boolean {
    return !this.hasPaidRegistrationFee || this.hasBeenRefunded
  }

  public get tolariaPayment(): boolean {
    return this.event.details?.registrationFee?.tolariaPayment
  }

  public get deckPhoto(): boolean {
    return this.event.details.deckPhoto
  }

  public get deckList(): boolean {
    return this.event.details.deckList
  }

  public get hasSubmittedDeckPhoto(): boolean {
    return this.player.deckSubmission.deckPhoto
  }

  public get hasSubmittedDeckList(): boolean {
    return this.player.deckSubmission.deckList
  }

  public get eventStarted(): boolean {
    return this.event.statusCode !== 14 && this.event.statusCode > 0
  }

  public get eventIsGroup(): boolean {
    return this.event.details.structure.isGroup
  }

  public get groupLetter(): string {
    return this.player.playedInGroup === null ? '' : this.player.playedInGroup.substring(this.player.playedInGroup.length - 1)
  }

  public get paidWithProvider(): PaymentType {
    if (this.hasPaidRegistrationFee || this.hasBeenRefunded) {
      return this.event.details?.registrationFee?.paidBy
        ? this.event.details?.registrationFee?.paidBy[this.player.playerDocId]?.paymentType
        : null
    }
    else { return null }
  }

  public get externalPaymentComment(): string {
    if (this.paidWithProvider !== PaymentType.TOLARIA) {
      return this.event.details?.registrationFee?.paidBy
        ? this.event.details.registrationFee.paidBy[this.player.playerDocId].comment
        : 'no comment added'
    }
    return 'no comment added'
  }

  public get paidWithTolariaPayment(): boolean {
    return this.event.details?.registrationFee?.paidBy && this.event.details?.registrationFee?.paidBy[this.player.playerDocId]?.paid
      ? this.event.details.registrationFee.paidBy[this.player.playerDocId].paymentType === PaymentType.TOLARIA
      : false
  }

  public get disableRefunding(): boolean {
    return this.event.details?.registrationFee?.payout?.paid
  }

  public get paidByInfo(): IRegistrationFeePayment {
    return this.event.details?.registrationFee?.paidBy
      ? this.event.details.registrationFee.paidBy[this.player.playerDocId]
      : null
  }

  public get registrationFeeAmountString(): string {
    return (this.paidByInfo.amount / PaymentFactor).toFixed(2)
  }

  public get charityAmountString(): string {
    return (this.paidByInfo.charityAmount / PaymentFactor).toFixed(2)
  }

  public get refundAmount(): number {
    return (this.refundRegistrationFee ? this.paidByInfo.amount : 0) + (this.refundCharityAmount ? this.paidByInfo.charityAmount : 0)
  }

  public get disableRefundButton(): boolean {
    if (this.paidWithProvider === PaymentType.TOLARIA) { return !this.confirmRefundCheckBox }
    else { return !this.confirmRefundCheckBox || !this.refundRegistrationFee }
  }

  public get paidWithTolaria(): boolean {
    return this.event.details?.registrationFee?.paidBy && this.event.details?.registrationFee?.paidBy[this.player.playerDocId]?.paymentType === PaymentType.TOLARIA
  }

  public get paidByTicket(): boolean {
    return this.event.details?.registrationFee?.paidByTicket && this.event.details?.registrationFee?.paidByTicket[this.player.playerDocId] !== undefined
  }

  public get isMultiDay(): boolean {
    return this.event.details.isMultiDay
  }

  public get availableDuringEvent(): boolean {
    return this.availableDuringEvent$.getValue()
  }

  public get hidePlayer(): boolean {
    if (this.availableDuringEvent$.getValue() === true && !this.hideIfAvailable) {
      return false
    }
    return true
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'manaParser',
  standalone: true,
})
export class ManaParserPipe implements PipeTransform {

  transform(value: string, options?: { fontClass?: string, shadowed?: boolean }): string {
    let shadowClass = '';
    let fontClass = '';
    if (options && options.shadowed) {
      shadowClass = ' ms-shadow';
    }
    if (options && options.fontClass) {
      fontClass = options.fontClass;
    }
    if (value !== undefined) {
      value = value
        .replace(/{(.*?)\}/g, (match) => match.toLowerCase())
        // .toLowerCase()
        .replace(/\//g, '')
        .replace(/{t}/g, '{tap}')
        .replace(/{q}/g, '{untap}')
        .replace(/{/g, '<i class="' + fontClass + shadowClass + ' ms ms-cost ms-')
        .replace(/}/g, '"></i>');
      return value;
    }
    else {
      return '';
    }
  }

}

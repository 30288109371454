import { EventService } from 'src/app/services';
import { Observable } from 'rxjs';
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, Input } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-more-actions-round-robin',
  templateUrl: './more-actions-round-robin.component.html',
  styleUrls: ['./more-actions-round-robin.component.css']
})
export class MoreActionsRoundRobinComponent {
  @Input() eventDoc$: Observable<IEventDetails>;

  faChevronRight = faChevronRight
  
  public submenuOpen = false

  constructor(
    private eventService: EventService
  ) { }

  resetEvent(event: IEventDetails): void {
    // should have a confirm here
    this.eventService.eventResetGroupEvent(event);
  }

}

<div class="deckster-outer-wrapper">
  <ng-container *ngIf="decksToRate.length > 0">
    <ng-container *ngFor="let deck of decksToRate; let i = index; trackBy : trackByDeckDocId">
      <div #elemntRef class="deckster-wrapper" ngSwipe (swipeMove)="onSwipeMove($event, elemntRef)" (swipeEnd)="onSwipeEnd($event, deck, elemntRef)"
        [ngClass]="{ 'active' : i === 0 }" [id]="deck.docId">
        <div class="card deckster">
          <!-- <div class="card-header">
            Deckster - Ya or Nay
          </div> -->
          <div class="card-body p-0 no-hover">
            <div class="deck-image-wrapper">
              <app-deck-image [imageUrl]="deck.imageUris.medium"></app-deck-image>
            </div>
            <div class="deck-info-wrapper" [ngClass]="{ 'at-top' : showDeckList }"
              (click)="showDeckList = !showDeckList">
              <div class="deck-title" [innerHtml]="deck.name === '' ? 'UNNAMED DECK' : deck.name"></div>
              <div class="deck-desciption" [ngClass]="{ 'text-secondary text-italic' : deck.description === '' }"
                [innerHtml]="deck.description === '' ? 'no description entered' : deck.description"></div>
              <div class="deck-list mt-3" *ngIf="deck.textList !== ''">
                <app-deck-list [deckListDocId]="deck.docId" [showList]="true"></app-deck-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="text-secondary d-flex align-items-center justify-content-center rating-thumb thumbs-up" #thumbsUp>
    <fa-icon [icon]="faThumbsUp" [fixedWidth]="true"></fa-icon>
  </div>
  <div class="text-secondary d-flex align-items-center justify-content-center rating-thumb thumbs-down" #thumbsDown>
    <fa-icon [icon]="faThumbsDown" [fixedWidth]="true"></fa-icon>
  </div>

</div>

<!-- <ng-container *ngIf="deckToRate$ | async as deck">
  <div class="deckster-wrapper" ngSwipe (swipeMove)="onSwipeMove($event)" (swipeEnd)="onSwipeEnd($event, deck)">
    <div class="dislike"></div> !!! comment out
    <div class="like"></div> !!! comment out
<div class="rating-thumb thumbs-up d-flex align-items-center justify-content-center text-greenyellow" #thumbsUp>
  <fa-icon [icon]="faThumbsUp" [fixedWidth]="true"></fa-icon>
</div>
<div class="rating-thumb thumbs-down d-flex align-items-center justify-content-center text-red" #thumbsDown>
  <fa-icon [icon]="faThumbsDown" [fixedWidth]="true"></fa-icon>
</div>
<div class="card deckster">
  <div class="card-header">
    Deckster - Ya or Nay
  </div>
  <div class="card-body p-0 no-hover">
    <div class="deck-image-wrapper">
      <app-deck-image [imageUrl]="deck.deckPhotoUrl"></app-deck-image>
      <img [src]="deck.deckPhotoUrl"> !!! comment out
    </div>
    <div class="deck-info-wrapper" [ngClass]="{ 'at-top' : showDeckList }" (click)="showDeckList = !showDeckList">
      <div class="deck-title" [innerHtml]="deck.name"></div>
      <div class="deck-desciption" [innerHtml]="deck.description"></div>
      <div class="deck-list mt-3" *ngIf="deck.textList !== '' && showDeckList">
        <app-deck-list [deckListDocId]="deck.docId" [showList]="true"></app-deck-list>
      </div>
    </div>
  </div>
</div>
</div>
</ng-container> -->

<div class="message-wrapper d-flex gap-3 p-2 pe-3 m-3 border border-secondary rounded-2 {{ mobileDevice }}">

  <div class="prefix d-flex align-items-start justify-content-end flex-shrink-0"
    [style.minWidth.rem]="2.5">
    
    <fa-icon [fixedWidth]="true" class="text-xxlarge" [icon]="faBattle"></fa-icon>
    
  </div>


  <div class="d-flex flex-column flex-grow-1">

    <div class="d-flex align-items-center gap-3">

      <div class="text-large text-bold">
        You have been summoned to a duel in pure wisdom!
      </div>

      <app-timestamp-clock class="message-timestamp text-secondary"
        [timestamp]="msg.message.timestamp"
        displayType="time"
        [seconds]="true">
      </app-timestamp-clock>
    
    </div>

    <div class="message-content py-2">
      
      <div class="text-normal mt-2">
        If you wish to accept the challenge just follow the link below to planeswalk to the
        battlefield that has been chosen for you.
      </div>
      
      <div class="mt-3 btn btn-sm btn-secondary" (click)="openMatch()">
        To the battlefield
      </div>

    </div>

  </div>

</div>
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, Input } from '@angular/core';
import { EventService } from 'src/app/services';
import { timer, BehaviorSubject, Subject } from 'rxjs';

export interface IEventTimeMeta {
  roundIsActive: boolean;
  timestampStart: number;
  timestampEnd: number;
  timestampNow: number; // Server timestamp UTC
  roundClock: number;
}

@Component({
  selector: 'app-event-round-timer',
  templateUrl: './event-round-timer.component.html',
  styleUrls: ['./event-round-timer.component.css']
})
export class EventRoundTimerComponent implements OnDestroy {
  @Input() showIcon = true;
  @Input() className = '';
  @Input() topBarWrapper: boolean;

  private source$ = timer(1000, 1000);
  private componentDestroyed$: Subject<boolean> = new Subject<boolean>();
  public roundIsActive$ = new BehaviorSubject<boolean>(false);
  public eventMeta: IEventTimeMeta;
  private eventId: string;

  faClock = faClock;
  faTimes = faTimes;
  floatingTimer = false;
  floatingTimerFontSize = 5;

  constructor(
    private es: EventService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (this.route.firstChild) {
      this.eventId = this.route.firstChild.snapshot.params.eventDocId;
    }
    else {
      this.eventId = this.route.snapshot.params.eventDocId;
    }
    if (this.eventId !== undefined) {
      this.es.getEventTimeMeta(this.eventId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(meta => {
          this.roundIsActive$.next(meta.roundIsActive);
          this.eventMeta = meta;
        });
      this.source$
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          if (this.eventMeta) { this.eventMeta.roundClock--; }
        });
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
  }

  public get isLobbyTimer(): boolean {
    if (this.router.url.includes('event-lobby')) {
      return true;
    }
    return false;
  }

  onSliderChange(selectedValues: number[]) {
    this.floatingTimerFontSize = selectedValues[0];
  }

}

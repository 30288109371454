import { Component } from '@angular/core';

@Component({
  selector: 'app-draft-picks',
  templateUrl: './draft-picks.component.html',
  styleUrls: ['./draft-picks.component.css']
})
export class DraftPicksComponent {

  constructor() { }

}

import { takeUntil } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, EventService } from 'src/app/services';
import { IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { faTimes, faPlus, faMinus, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-report-slip',
  templateUrl: './modal-report-slip.component.html',
  styleUrls: ['../../event-lobby/organizer/event-report-slip/event-report-slip.component.css']
})
export class ModalReportSlipComponent implements OnInit, OnDestroy {
  @Input() matchData$: Observable<IMatchData>;

  faTimes = faTimes;
  faPlus = faPlus;
  faMinus = faMinus;
  faSquare = faSquare;
  faCheckSquare = faCheckSquare;
  faWarning = faExclamationTriangle

  reportSlipIsValid = false;
  matchDocRef: AngularFirestoreDocument;
  matchData: IMatchData;

  private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private es: EventService,
    private activeModal: NgbActiveModal,
    private router: Router,
  ) {
  }

  ngOnInit(): void {

    this.matchData$.pipe(takeUntil(this.componentDestroyed$)).subscribe(data => {
      if (data.reportSlip && !data.reportSlip.showSlip && !this.router.url.includes('event-lobby')) {
        this.activeModal.close();
      }
      console.log(this.matchData)
      this.matchData = data;
      this.matchDocRef = this.afs.collection('matches').doc(this.matchData.docId);
    });

  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
  }

  setMatchResult(isDraw: boolean, player1wins: boolean, player2wins: boolean, player1gamewins: number, player2gamewins: number, drawnGames: number, player1drop: boolean, player2drop: boolean) {

    if (!this.openedByMe && !this.router.url.includes('event-lobby')) { return }

    if (!this.matchData.isByeMatch && !this.matchData.isLossMatch) {
      this.matchData.isDraw = isDraw;

      this.matchData.player1.isWinner = player1wins;
      this.matchData.player2.isWinner = player2wins;

      this.matchData.player1.wins = player1gamewins;
      this.matchData.player2.wins = player2gamewins;

      this.matchData.player1.losses = player2gamewins;
      this.matchData.player2.losses = player1gamewins;

      this.matchData.player1.draws = drawnGames;
      this.matchData.player2.draws = drawnGames;

      this.matchData.player1.drop = player1drop;
      this.matchData.player2.drop = player2drop;

      // reset confirmation
      this.matchData.reportSlip.player1Confirmed = false;
      this.matchData.reportSlip.player2Confirmed = false;

      this.matchDocRef.update(this.matchData);
    }
  }

  changeDrawnGames(operator: string) {

    if (!this.openedByMe) { return }

    if (!this.matchData.isByeMatch && !this.matchData.isLossMatch) {
      if (operator === 'decrease') {
        if (this.matchData.player1.draws > 0) {
          this.matchData.player1.draws = this.matchData.player1.draws - 1;
          this.matchData.player2.draws = this.matchData.player2.draws - 1;
        }
      }
      if (operator === 'increase') {
        this.matchData.player1.draws = this.matchData.player1.draws + 1;
        this.matchData.player2.draws = this.matchData.player2.draws + 1;
      }

      // use SET with merge = true to not overwrite the whole document
      this.matchDocRef.set({
        player1: {
          draws: this.matchData.player1.draws
        },
        player2: {
          draws: this.matchData.player2.draws
        },
        reportSlip: {
          player1Confirmed: false,
          player2Confirmed: false,
        }
      }, { merge: true });
    }
  }

  public get invalidReportSlip(): boolean {

    // block loss and bye matches from being changed
    if (this.matchData.isLossMatch || this.matchData.isByeMatch) {
      return true
    }

    if (this.twoPlayerReportingActive && (!this.matchData.reportSlip.player1Confirmed || !this.matchData.reportSlip.player2Confirmed)) {
      return  true
    }

    // check if we have a winner
    if (
      this.matchData.isDraw === false &&
      this.matchData.player1.isWinner === false &&
      this.matchData.player2.isWinner === false
    ) {
      return true;
    }

    // check if results are reported
    if (
      this.matchData.player1.wins === 0 &&
      this.matchData.player1.losses === 0 &&
      this.matchData.isDraw === false
    ) {
      return true;
    }

    // all seems good form is NOT invalid
    this.reportSlipIsValid = true;
    return false;
  }

  reportMatchResult(matchData: IMatchData) {
    console.log('reporting match result');
    matchData.reportSlip.showSlip = false;
    this.es.reportMatchResult(matchData)
      .then((res: any) => {
        if (res.status) {
          this.activeModal.close()
        }
      })
  }

  closeReportSlipByButton() {
    // use SET with merge = true to not overwrite the whole document
    this.matchDocRef.set({
      reportSlip: {
        player1Confirmed: false,
        player2Confirmed: false,
        showSlip: false
      }
    }, { merge: true })
    .then(() => this.activeModal.close())
    .catch((error) => console.log(error));

  }

  toggleConfirm() {

    // switch (this.playerIs) {
    //   case 'player1':
    //     this.matchData.reportSlip.player1Confirmed = !this.matchData.reportSlip.player1Confirmed;
    //     break;
    //   case 'player2':
    //     this.matchData.reportSlip.player2Confirmed = !this.matchData.reportSlip.player2Confirmed;
    //     break;
    // }

    // use SET with merge = true to not overwrite the whole document
    this.matchDocRef.set({
      reportSlip: {
        [`${this.playerIs}Confirmed`]: !this.matchData.reportSlip[`${this.playerIs}Confirmed`]
      }
    }, { merge: true })

  }

  public get reportSlipFilled(): boolean {

    if (this.matchData.player1.isWinner || this.matchData.player2.isWinner) {
      return (this.matchData.player1.wins + this.matchData.player2.wins) > 0
    }

    if (this.matchData.isDraw) {
      return true
    }

  }

  public get disableConfirmation(): boolean {

    if (this.router.url.includes('event-lobby')) {
      return false
    }

    // if current player opened the report slip, then allow confirmation
    if (this.openedByMe) {
      return !this.reportSlipFilled
    }

    // if initiator has confirmed, then allow confirmation for the non-initiator
    if (!this.openedByMe) {
      return !this.matchData.reportSlip[`${this.opponentIs}Confirmed`]
    }
  }

  toggleDrop() {
    this.matchData[this.playerIs].drop = !this.matchData[this.playerIs].drop;

    // use SET with merge = true to not overwrite the whole document
    this.matchDocRef.set({
      [this.playerIs]: {
        drop: this.matchData[this.playerIs].drop
      }
    }, { merge: true });

  }

  public get playerIs(): string {
    if (this.matchData) {
      return this.matchData.player1.playerDocId === this.auth.user.playerId ? 'player1' : 'player2';
    }
    else {
      return null
    }
  }

  public get opponentIs(): string {
    if (this.matchData) {
      return this.matchData.player1.playerDocId === this.auth.user.playerId ? 'player2' : 'player1'
    }
    else {
      return null
    }
  }

  public get showDropOption(): boolean {
    if (this.matchData) {
      // return this.matchData.isType && this.matchData.isType === 'swiss' || this.matchData.isType && this.matchData.isType === 'batch';
      return this.matchData.isType && this.matchData.isType === 'swiss';
    }
    else {
      return false;
    }
  }

  public get playerAboutToDrop(): boolean {
    return this.matchData[this.playerIs].drop
  }

  public get openedByMe(): boolean {
    if (this.matchData.reportSlip) {
      return this.matchData.reportSlip.openedByPlayerDocId === this.auth.user.playerId
    }
    return false
  }

  public get showConfirmButton(): boolean {
    if (this.router.url.includes('event-lobby') && this.matchData !== undefined && this.matchData.createdByUid === this.auth.user.uid) {
      return true
    }
    else {
      return this.matchData?.reportSlip?.player1Confirmed && this.matchData?.reportSlip?.player2Confirmed && this.openedByMe
    }
  }

  public get twoPlayerReportingActive(): boolean {
    if (this.matchData !== undefined) {
      return this.matchData?.reportSlip && this.matchData.reportSlip.showSlip
    }
    return false
  }

}

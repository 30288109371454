<ng-container *ngIf="event$ | async as event">


  <div class="modal-header d-flex align-items-center">
    <div class="text-large flex-grow-1">Manual Pairings</div>
  </div>


  <div class="modal-body flex-grow-1 full-size no-hover d-flex p-0 manual-pairings">

    <!-- MATCHES TO UN-PAIR -->

      <div class="card">

        <div class="card-header">
          <span>Match List</span>
          <div class="search-bar float-end {{ extendSearchBar || matchFilter !== '' ? 'extended' : '' }}"
            (mouseenter)="extendSearchBar = true" (mouseleave)="extendSearchBar = false">
            <div class="search-icon">
              <fa-icon [fixedWidth]="true" [icon]="faSearch" (click)="extendSearchBar = !extendSearchBar"></fa-icon>
            </div>
            <input #searchBar type="text" class="search-field" (focus)="extendSearchBar = true;"
              (blur)="extendSearchBar = false" [(ngModel)]="matchFilter" placeholder="Search (/)">
            <div class="clear-input-icon">
              <fa-icon [fixedWidth]="true" [icon]="faTimes" (click)="matchFilter = ''; searchBar.focus()"
                *ngIf="matchFilter !== ''" style="cursor: pointer;"></fa-icon>
            </div>
          </div>
        </div>

        <div class="card-body no-hover scrollable match-list p-0">
          <ng-container *ngIf="pairedMatches$ | async as pairedMatches; else loader">
            <div class="match-item" *ngFor="let pairedMatch of pairedMatches | manualPairingsPipe : matchFilter"
            [ngClass]="{'new-pairing' : pairedMatch.newPairing }">
              <div class="players-wrapper">
                <div class="row">

                  <div class="col-2" *ngIf="pairedMatch.match.isType === 'batch'" style="align-self: center;">
                    <div class="segment-number flex-item"
                      [ngClass]="{ 'has-played' : pairedMatch.match.hasPlayedEarlier }">
                      {{ pairedMatch.match.segmentNumber }}</div>
                  </div>

                  <div class="col">

                    <div class="player text-start mb-1">
                      <span class="match-points left">{{ pairedMatch.match.player1.matchPoints }}</span>
                      <span class="display-name">{{ pairedMatch.match.player1.displayName }}</span>
                    </div>

                    <div class="player text-start mt-1">
                      <span class="match-points left">{{ pairedMatch.match.player2.matchPoints }}</span>
                      <span class="display-name">{{ pairedMatch.match.player2.displayName }}</span>
                    </div>

                  </div>

                  <div class="col-4 text-secondary unpair-button" style="align-self: center;">
                    <div class="btn btn-sm btn-outline-secondary" (click)="unpairMatch(pairedMatch)">
                      <!-- <fa-icon [fixedWidth]="true" [icon]="faUnlink" class="me-2"></fa-icon> -->
                      Unpair
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </ng-container>
        </div>

      </div>


    <!-- UNPAIRED PLAYERS -->
    <div class="card align-card">

      <div class="card-header">
        <button disabled class="btn btn-sm btn-secondary me-2">{{ countUnpairedPlayers }}</button>
        Unpaired Players
      </div>

      <div class="card-body no-hover scrollable p-0">
        <ng-container *ngIf="unpairedPlayers$ | async as unpairedPlayers; else loader">
          <div mwlDraggable
            dragActiveClass="being-dragged"
            class="unpaired-player-item draggable d-flex align-items-center"
            *ngFor="let unpairedPlayer of unpairedPlayers" [dropData]="unpairedPlayer.matchPlayer">

            <span class="display-name flex-grow-1">
              {{ unpairedPlayer.matchPlayer.displayName }}
            </span>

            <span class="match-points me-2" ngbTooltip="Match points" container="body">
              {{ unpairedPlayer.eventPlayer.matchPoints }}
            </span>

            <div class="segment-number me-2" ngbTooltip="Segment" container="body" *ngIf="unpairedPlayer.matchPlayer.segmentNumber">
              {{ unpairedPlayer.matchPlayer.segmentNumber }}
            </div>

          </div>
        </ng-container>
      </div>

    </div>

    <!-- MATCHES TO PAIR -->
    <div class="card align-card">

      <div class="card-header">
        <button disabled class="btn btn-sm btn-secondary me-2">{{ countUnpairedMatches }}</button>
        Matches to Pair
        <div mwlDraggable dragActiveClass="being-dragged" [dropData]="lossPlayer" class="btn btn-secondary btn-sm float-end">
          <fa-icon [icon]="faUser" class="me-2"></fa-icon>
          LOSS
        </div>
        <div mwlDraggable dragActiveClass="being-dragged" [dropData]="byePlayer" class="btn btn-secondary btn-sm float-end me-2">
          <fa-icon [icon]="faUser" class="me-2"></fa-icon>
          BYE
        </div>
      </div>

      <div class="card-body matches-to-pair no-hover scrollable">
        <ng-container *ngIf="unpairedMatches$ | async as unpairedMatches; else loader">
          <ng-container *ngFor="let unpairedMatch of unpairedMatches">

            <div class="match-wrapper p-2">

              <div class="title-wrapper d-flex align-items-center">
                <ng-container *ngIf="unpairedMatch.match.isType === 'batch'">
                  <div class="me-3">Segment:</div>
                  <span class="segment-number">{{ unpairedMatch.match.segmentNumber }}</span>
                  <span class="flex-grow-1 text-end text-secondary text-smaller">{{ unpairedMatch.match.docId}}</span>
                  </ng-container>
                <ng-container *ngIf="unpairedMatch.match.isType !== 'batch'">
                  <div class="flex-grow-1">Match</div>
                  <span class="text-secondary text-smaller">{{ unpairedMatch.match.docId}}</span>
                </ng-container>
              </div>

              <div class="match-item unpaired w-100">

                <div mwlDroppable
                  dragOverClass="droppable-dropping"
                  class="droppable d-flex align-items-center"
                  (drop)="onPlayerDroppedInMatchSlot($event, 'player1', 'player2', unpairedMatch.match)">

                  <div class="player float-left d-flex align-items-center w-100"
                    *ngIf="unpairedMatch.match.player1.playerDocId !== ''; else dropPlayer1">

                    <span class="match-points left">
                      {{ unpairedMatch.match.player1.matchPoints }}
                    </span>
                    <span class="display-name flex-grow-1 px-2 text-start">
                      {{ unpairedMatch.match.player1.displayName !== '' ? unpairedMatch.match.player1.displayName : 'Nameless Race' }}
                    </span>
                    <button class="btn btn-sm btn-blank"
                      ngbTooltip="Move back to unpaired players"
                      container="body"
                      (click)="removePlayerFromUnpairedMatch(unpairedMatch, 'player1', unpairedMatch.match.player1)">
                      <fa-icon [icon]="faTimes"></fa-icon>
                    </button>

                  </div>

                  <ng-template #dropPlayer1>
                    <span class="unpaired text-italic text-secondary text-center flex-grow-1">Drop player here</span>
                  </ng-template>

                </div>

                <div mwlDroppable
                  dragOverClass="droppable-dropping"
                  class="droppable d-flex align-items-center"
                  (drop)="onPlayerDroppedInMatchSlot($event, 'player2', 'player1', unpairedMatch.match)">

                  <div class="player float-end d-flex align-items-center w-100"
                    *ngIf="unpairedMatch.match.player2.playerDocId !== ''; else dropPlayer2">

                    <button class="btn btn-sm btn-blank"
                      ngbTooltip="Move back to unpaired players"
                      container="body"
                      (click)="removePlayerFromUnpairedMatch(unpairedMatch, 'player2', unpairedMatch.match.player2)">
                      <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                    <span class="display-name flex-grow-1 px-2 text-end">
                      {{ unpairedMatch.match.player2.displayName !== '' ? unpairedMatch.match.player2.displayName : 'Nameless Race' }}
                    </span>
                    <span class="match-points right">
                      {{ unpairedMatch.match.player2.matchPoints }}
                    </span>

                  </div>

                  <ng-template #dropPlayer2>
                    <span class="unpaired text-italic text-secondary text-center flex-grow-1">Drop player here</span>
                  </ng-template>

                </div>

              </div>
            </div>

          </ng-container>
        </ng-container>
      </div>

      <div class="card-footer d-flex justify-content-between">

        <button class="btn btn-sm btn-secondary w-100 me-1"
          [disabled]="disableClearMatches"
          (click)="clearEmptyMatches()">Clear all empty matches</button>

        <button class="btn btn-sm btn-secondary w-100 ms-1" (click)="createEmptyMatch(event)">Create an empty
          match</button>
      </div>

    </div>

  </div>

  <div class="modal-footer d-flex justify-content-end align-items-center">
    <ng-container *ngIf="true">
      <div class="text-secondary me-3">
        <span class="text-italic">To be able to continue, you need to make sure you have </span><b>NO</b>
        <span class="text-italic"> unpaired player </span>AND <b>NO</b>
        <span class="text-italic"> empty or partially empty match.</span>
      </div>
    </ng-container>
    <button class="btn btn-sm btn-blank me-4" (click)="manualPairingsCancel()">Cancel</button>
    <button class="btn btn-sm btn-blank me-2 text-warning" (click)="resetManualPairings()">Reset</button>
    <button class="btn btn-sm btn-secondary" (click)="manualPairingsDone()"
      [disabled]="disableDone">Done</button>
  </div>

</ng-container>


<ng-template #loader>
  <app-busy-spinner class="p-3" spinnerText="Loading data, please wait"></app-busy-spinner>
</ng-template>


<ng-template #batchSelection let-modal>
  <div class="modal-header text-center">Select segment for the match</div>
  <div class="modal-body p-2">
    <ul class="list-group">
      <li class="list-group-item cursor-pointer text-center"
        *ngFor="let segment of availableSegments"
        (click)="modal.close(segment)">
        Segment {{ segment }}
      </li>
    </ul>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <button class="btn btn-sm btn-blank" (click)="modal.dismiss()">Cancel</button>
  </div>

</ng-template>

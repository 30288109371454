import { CommonModule } from '@angular/common';
import { AfterViewChecked, Component } from '@angular/core'
import { EmojiService } from '../emoji.service';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFileImage } from '@fortawesome/free-regular-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { EmojiItem } from '../emojis';

@Component({
  selector: 'app-tolaria-new-emoji',
  templateUrl: './tolaria-new-emoji.component.html',
  styleUrls: ['./tolaria-new-emoji.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
  ]
})
export class TolariaNewEmojiComponent {

  public filename: string = 'Select an image'
  public shortcode: string = ''
  public placeholder: string = 'avocado'
  public base64: string = ''
  public fileIcon = faFileImage
  public shortcodeHasFocus = false
  public existingEmoji$ = new BehaviorSubject<EmojiItem | null>(null)
  private _shortcodeRegExp = /^[a-z0-9-_]+$/

  constructor(
    private readonly emoji: EmojiService,
    private readonly activeModal: NgbActiveModal,
  ) { }

  public onShortcodeInput(event: any): void {
    if (event.target.value === '') {
      if (!this.shortcodeHasFocus) {
        this.placeholder = 'avocado'
        return
      }
    }
    this.placeholder = this.shortcode

    // fetch any existing emoji
    let existing = this.emoji.emojiByShortcode(`:${event.target.value}:`)
    this.existingEmoji$.next(existing)

    console.log({
      value: event.target.value,
      existing,
      shortcode: this.shortcode,
    })

  }

  public onShortcodeFocus(): void {
    this.shortcodeHasFocus = true
    if (this.shortcode === '') {
      this.placeholder = ''
    }
  }

  public onShortcodeBlur(): void {
    this.shortcodeHasFocus = false
    if (this.shortcode === '') {
      this.placeholder = 'avocado'
    }
  }

  public onDismiss(): void {
    this.activeModal.dismiss()
  }

  public onSave(): void {
    this.activeModal.close({
      base64: this.base64,
      shortcode: this.shortcode,
    })
  }

  public onUploadImage(): void {
    document.getElementById('tolariaEmojiUpload').click()
  }

  public async onInputChange(event: any) {
    this.filename = event.files[0].name
    const reader = new FileReader()
    reader.onload = (event) => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        console.log({
          imgW: img.width,
          imgH: img.height,
        })
        const isPortrait = img.width < img.height
        if (isPortrait) {
          canvas.width = 128 * (img.width / img.height)
          canvas.height = 128
        }
        else {
          canvas.height = 128 * (img.height / img.width)
          canvas.width = 128
        }
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
        this.base64 = canvas.toDataURL()

      }
      img.src = event.target.result as string
    }
    reader.readAsDataURL(event.files[0])
  }

  public get hasImage(): boolean {
    return this.base64 !== ''
  }

  public get emojiExist(): boolean {
    return this.existingEmoji$.getValue() !== null
  }

  public get shortcodeEmpty(): boolean {
    return this.shortcode.length === 0
  }

  public get shortcodeInvalid(): boolean {
    if (this.shortcodeEmpty) { return false }
    if (!this._shortcodeRegExp.test(this.shortcode)) {
      return true
    }
    if (this.emojiExist) {
      return true
    }
    return false
  }

  public get shortcodeValidationText(): string {
    if (this.shortcodeEmpty) { return '' }
    if (!this._shortcodeRegExp.test(this.shortcode)) {
      return 'Names must be lowercase, and can’t contain spaces, periods, or most punctuation.'
    }
    if (this.emojiExist) {
      return 'An emoji with this name already exists. Check if the existing emoji is a duplicate, or try a different name.'
    }
    return ''
  }

  public get formInvalid(): boolean {
    if (this.shortcodeEmpty) {
      return true
    }
    if (this.shortcodeInvalid) {
      return true
    }
    if (this.emojiExist) {
      return true
    }
    if (this.base64 === '') {
      return true
    }
    return false
  }

}

<app-public-header [showAbout]="true"></app-public-header>
<section style="min-height: 100%; height: auto;">
    <div class="container">
        <div class="d-flex align-items-center justify-content-center {{ isMobile ? 'p-0 w-100' : '' }}" [style.marginTop.vh]="topMargin">
    
            <div class="d-flex flex-row align-items-center justify-content-center flex-column login-form shadow-lg">
    
    
                <div class="sign-in-form card o-hover border border-0" [ngClass]="{ 'card-rounded-lg rounded rounded-4' : !isMobile, 'pt-5' : isMobile }">
    
                    <div class="card-body top d-flex flex-column form-signin gap-3 no-hover">
    
                        <div class="logo-wrapper align-self-center" routerLink="/public/about">
                            <fa-icon [icon]="icon.app" [style.fontSize.rem]="8"></fa-icon>
                        </div>
    
                        <div class="h3 text-center">Sign in</div>
    
                        <div class="form-floating">
                            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                                [(ngModel)]="loginData.email" (keydown.enter)="signin('email')">
                            <label for="floatingInput">Email address</label>
                        </div>
                        <div class="form-floating" [style.marginTop.px]="-1">
                            <input type="password" class="form-control" id="floatingPassword" placeholder="Password"
                                [(ngModel)]="loginData.password" (keydown.enter)="signin('email')">
                            <label for="floatingPassword">Password</label>
                        </div>
    
                        <ng-container *ngIf="authMessage !== null">
                            <div class="alert {{ authMessage.success ? 'alert-success' : 'alert-danger'}}">
                                {{ authMessage.message }}
                            </div>
                        </ng-container>
    
                        <button type="submit" class="btn btn-success mt-3 mb-2" (click)="signin('email')"
                            [disabled]="disableLoginButton">
                            Sign in
                        </button>
    
                        <div class="d-flex flex-column gap-1">
                            <div class="btn btn-blank btn-sm w-100" routerLink="/auth/forgot-password">Forgot your password?
                            </div>
                            <div class="btn btn-blank btn-sm w-100" routerLink="/auth/register">Need an account?</div>
                        </div>
    
                    </div>
    
                    <div class="card-body border-top">
                        <div class="text-muted w-100 text-center text-smaller mb-3">Or connect with</div>
                        <div class="social-btn text-center d-flex flex-wrap gap-3">
                            <button class="btn btn-danger btn-lg flex-grow-1" (click)="signin('google')">
                                <fa-icon [icon]="icon.google" [fixedWidth]="true"></fa-icon>
                                Google
                            </button>
                            <button class="btn btn-dark btn-lg flex-grow-1" (click)="signin('github')">
                                <fa-icon [icon]="icon.github" [fixedWidth]="true"></fa-icon>
                                Github
                            </button>
                            <button class="btn btn-info btn-lg flex-grow-1" (click)="signin('twitter')">
                                <fa-icon [icon]="icon.twitter" [fixedWidth]="true"></fa-icon>
                                Twitter
                            </button>
                            <button class="btn btn-primary btn-lg flex-grow-1" (click)="signin('facebook')">
                                <fa-icon [icon]="icon.facebook" [fixedWidth]="true"></fa-icon>
                                Facebook
                            </button>
                        </div>
                    </div>
                </div>
    
            </div>
    
        </div>
    </div>
</section>
<app-public-footer></app-public-footer>
import { Component, ElementRef, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { faAlignCenter, faCaretDown, faDownload, faExclamationTriangle, faFileUpload, faItalic, faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CardFactoryHelperService } from '../card-factory-helper.service'
import { ICardConfig, ICardTextBlock } from '../card-factory.model'
import { nanoid } from 'nanoid'
import html2canvas from 'html2canvas'
import { ICardFrame } from '../card-frames'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Component({
  selector: 'app-edit-created-card',
  templateUrl: './edit-created-card.component.html',
  styleUrls: ['./edit-created-card.component.css']
})
export class EditCreatedCardComponent implements OnInit, OnDestroy {
  @ViewChild('cardPreview') cardPreview: ElementRef
  @ViewChild('frameSelection') frameSelection: ElementRef
  @ViewChild('frameSelectionDropdown') frameSelectionDropdown: ElementRef
  @ViewChild('deleteConfirmation') deleteConfirmation: ElementRef


  public frames = this.cfHelper.frames
  public localCard$: BehaviorSubject<ICardConfig> = new BehaviorSubject<ICardConfig>(null)
  private unchangedCard: ICardConfig = null

  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()
  private card$: Observable<ICardConfig>

  public view = {
    frame: true,
    artwork: false,
    text: false,
    frameSelection: false,
  }

  public icons = {
    save: faSave,
    center: faAlignCenter,
    italic: faItalic,
    delete: faTrash,
    publish: faFileUpload,
    add: faPlus,
    download: faDownload,
    dropDown: faCaretDown,
    warning: faExclamationTriangle,
  }

  public selectedFrame: ICardFrame = null

  constructor(
    private cfHelper: CardFactoryHelperService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    this.route.params.subscribe(i => {
      this.card$ = this.cfHelper.getCard(i.docId)
    })
  }

  ngOnInit(): void {
    this.card$.pipe(takeUntil(this.componentDestroyed$)).subscribe(cardData => {
      this.unchangedCard = JSON.parse(JSON.stringify(cardData))
      this.localCard$.next(cardData)
    })
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  public selectView(view: 'frame' | 'artwork' | 'text'): void {
    Object.keys(this.view).forEach(key => this.view[key] = false)
    this.view[view] = true
  }

  public selectFrame(frame: ICardFrame): void {
    this.selectedFrame = frame
    const card: ICardConfig = JSON.parse(JSON.stringify(this.localCard$.getValue()))
    card.frame = frame.frame
    this.localCard$.next(card)
    this.view.frameSelection = false
    this.frameSelection.nativeElement.value = frame.text
  }

  public publishCard(): void {
    this.cfHelper.publishCard(this.localCard$.getValue())
  }

  public deleteCard(): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'sm',
    }

    this.modalService.open(this.deleteConfirmation, modalOptions).result
      .then(
        () => {
          this.cfHelper.deleteCard(this.localCard$.getValue().docId)
        },
        () => {
          // nothing, user pressed cancel
        }
      )
  }

  public downloadCard(): void {
    html2canvas(this.cardPreview.nativeElement, {
      allowTaint: false,
      useCORS: true,
      scale: 2,
      backgroundColor: null,
    })
      .then((canvas) => {
        const image = canvas
          .toDataURL('image/png', 1)
          .replace('image/png', 'image/octet-stream')
        const downloadLink = document.createElement('a')
        downloadLink.setAttribute('download', `${this.localCard$.getValue().name}.png`)
        downloadLink.setAttribute('href', image)
        downloadLink.click()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  public saveCard(): void {

    html2canvas(this.cardPreview.nativeElement, {
      allowTaint: false,
      useCORS: true,
      scale: 1,
    })
      .then((canvas) => {
        const img = new Image()
        img.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          canvas.width = 350
          canvas.height = 483.4791559
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
          const card = JSON.parse(JSON.stringify(this.localCard$.getValue()))
          card.generatedPic = canvas.toDataURL('image/png', 1)
          this.cfHelper.saveCard(card)
        }
        img.src = canvas.toDataURL()
      })
      .catch((e) => {
        console.log(e)
      })

  }

  public addRulesBlock(): void {
    const rulesBlock: ICardTextBlock = {
      id: nanoid(),
      text: '',
      fontSize: 25,
      lineHeight: 25,
      paddingBottom: 0,
      paddingTop: 0,
      isCenter: false,
      isFlavor: false,
    }

    const card = JSON.parse(JSON.stringify(this.localCard$.getValue()))
    card.rulesText.push(rulesBlock)
    this.localCard$.next(card)
  }

  public deleteRulesText(blockId: string): void {
    const card = JSON.parse(JSON.stringify(this.localCard$.getValue()))
    card.rulesText.splice(card.rulesText.findIndex(i => i.id === blockId), 1)
    this.localCard$.next(card)
  }

  public get cardChanged(): boolean {
    return this.localCard$.getValue() !== null && this.unchangedCard !== null && JSON.stringify(this.localCard$.getValue()) !== JSON.stringify(this.unchangedCard)
  }

  public async processFile(event: any) {
    const reader = new FileReader()
    reader.onload = (event) => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        console.log({
          imgW: img.width,
          imgH: img.height,
        })
        const isPortrait = img.width < img.height
        if (isPortrait) {
          canvas.width = 500 * (img.width / img.height)
          canvas.height = 500
        }
        else {
          canvas.height = 500 * (img.height / img.width)
          canvas.width = 500
        }
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
        const card = JSON.parse(JSON.stringify(this.localCard$.getValue()))
        card.imageUrl = canvas.toDataURL()
        this.localCard$.next(card)
      }
      img.src = event.target.result as string
    }
    reader.readAsDataURL(event.files[0])
  }

  public get isPublishable(): boolean {
    if (this.localCard$.getValue().name === '' || this.localCard$.getValue().name === null) { return false }
    if (this.localCard$.getValue().manaCost === '' || this.localCard$.getValue().manaCost === null) { return false }
    if (this.localCard$.getValue().type === '' || this.localCard$.getValue().type === null) { return false }
    if (this.localCard$.getValue().illustrator === '' || this.localCard$.getValue().illustrator === null) { return false }
    if (this.localCard$.getValue().imageUrl === '' || this.localCard$.getValue().imageUrl === null) { return false }
    if (this.localCard$.getValue().publishedAt > 0) { return false }
    return true
  }

  public get frameUrl(): string {
    return `assets/card-factory/frames/${this.localCard$.getValue().frame}.png`
  }
}

@Pipe({
  name: 'spriteManaSymbol'
})
export class SpriteManaSymbol implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string, block: ICardTextBlock): SafeHtml {

    const fontScaleFactor = 16 * 0.95
    const sizeFactor = 16 * 1.3
    const lineHeightFactor = 16 * 1.35
    const newFontSize = fontScaleFactor / block.fontSize
    const newWH = sizeFactor / block.fontSize
    const newLineHeight = lineHeightFactor / block.lineHeight

    // const marginTop = ( ( ( 36 - block.fontSize ) / 2 ) * -1 ) + 2
    // const marginLR = ( ( 36 - block.fontSize ) / 4 ) * -1
    // const scaleFactor = block.fontSize / 36

    const newText =  `${text}`
      .replace(/{(.*?)\}/g, (match) => match.toLowerCase())
      .replace(/{t}/g, '{tap}')
      .replace(/{q}/g, '{untap}')
      // .replace(/{/g, `<i style="margin-top: ${marginTop}px; margin-left: ${marginLR}px; margin-right: ${marginLR}px; transform: scale(${scaleFactor})" class="mana-symbol mana-symbol-inline mana-symbol-`)
      .replace(/{/g, `<i style="width: ${newWH}em; height: ${newWH}em; line-height: ${newLineHeight}em; font-size: ${newFontSize}em;" class="ms ms-cost ms-`)
      // .replace(/{/g, `<i class="ms ms-cost ms-`)
      .replace(/}/g, `"></i>`)

      return this.sanitizer.bypassSecurityTrustHtml(newText)
  }

}
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { applicationIcon } from 'src/assets/font-icons/tolaria-icon';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Component, Input } from '@angular/core';
import { faExclamationTriangle, faCheckCircle, faInfoCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export interface IModalButton {
  type: 'dismiss' | 'close'
  text: string
  value: string
  icon?: IconDefinition
  class?: string
}

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.css']
})
export class MessageModalComponent {
  // mandatory
  @Input() type: 'warning' | 'danger' | 'success' | 'info' | 'default' = 'default'
  @Input() title: string
  @Input() message: string // html string
  @Input() buttons: IModalButton[]
  // optional
  @Input() hideIcon: boolean = false
  @Input() size: 'sm' | 'lg' | 'xl' = 'sm'
  @Input() confirmInput: boolean = false
  @Input() confirmText: string = ''
  @Input() centerButtons: boolean = false
  @Input() customIcon: IconDefinition
  @Input() imageAsIcon: boolean
  @Input() imageUrl: string

  public confirmationTextInput: string = ''

  constructor(private modal: NgbActiveModal) { }


  public get icon(): IconDefinition {
    if (this.customIcon) {
      return this.customIcon
    }
    switch (this.type) {
      case 'default':
        return applicationIcon
      case 'danger':
        return faExclamationCircle
      case 'info':
        return faInfoCircle
      case 'warning':
        return faExclamationTriangle
      case 'success':
        return faCheckCircle
    }
  }

  public get confirmTextPlaceholder(): string {
    return `Please enter the exact phrase: ${this.confirmText}`
  }

  public onButtonClick(btn: IModalButton): void {
    switch (btn.type) {

      case 'dismiss':
        this.modal.dismiss()
        break

      case 'close':
        this.modal.close(btn.value)
        break

    }
  }

  public get showIcon(): boolean {
    return !this.hideIcon && !this.imageAsIcon
  }


}

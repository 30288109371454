import { ToModalService } from './../to-modal.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faChevronRight, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces';
import { IEventDetails, IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { EventService } from 'src/app/services';

@Component({
  selector: 'app-event-actions-batch',
  templateUrl: './event-actions-batch.component.html',
  styleUrls: ['./event-actions-batch.component.css']
})
export class EventActionsBatchComponent implements OnInit, OnDestroy {
  @Input() eventDoc$: Observable<IEventDetails>;
  @Input() eventMatches$: Observable<IMatchData[]>

  faChevronRight = faChevronRight;
  faEllipsisH = faEllipsisH

  public submenuOpen = false

  private destroyed$: Subject<boolean> = new Subject<boolean>()
  private matches$: BehaviorSubject<IMatchData[]> = new BehaviorSubject<IMatchData[]>(null)
  public event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)

  constructor(
    private eventService: EventService,
    private toModals: ToModalService
  ) { }

  ngOnInit(): void {
    console.log('init')
    this.eventDoc$.pipe(takeUntil(this.destroyed$)).subscribe(e => this.event$.next(e))
    this.eventMatches$.pipe(takeUntil(this.destroyed$)).subscribe(m => this.matches$.next(m))
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public openPlayerRegistration(event: IEventDetails): void {
    event.details.registrationOpen = true;
    event.statusCode = 0;
    event.statusText = this.eventService.statusText[0];
    this.eventService.updateEventData(event);
  }

  public async startEvent(event: IEventDetails) {

    // check if deck submission is active
    if (
      // deck list mandatory and players should be dropped if failed to submit
      event.details.hasOwnProperty('deckList') &&
      event.details.deckList !== undefined &&
      event.details.deckList &&
      event.details.dropPlayersWithoutSubmittedDeckOnStart ||
      // deck photo mandatory and players should be dropped if failed to submit
      event.details.hasOwnProperty('deckPhoto') &&
      event.details.deckPhoto !== undefined &&
      event.details.deckPhoto &&
      event.details.dropPlayersWithoutSubmittedDeckOnStart
    ) {
      // drop all players without deck photo uploaded or deck  listlinked
      await this.eventService.dropPlayersWithoutSubmittedDeck(event.docId, event.details.deckList, event.details.deckPhoto);
    }

    // start the event
    this.eventService.eventStart(event);
  }
  public reOpenRegistration(event: IEventDetails) {
    this.eventService.eventUpdateStatus(event, 0);
  }
  public startRound(event: IEventDetails) {
    this.eventService.eventStartBatchRound(event);
  }
  public endRound(event: IEventDetails) {
    this.eventService.endBatchRound(event);
  }
  public startPlayoff(event: IEventDetails) {
    this.eventService.eventUpdateStatus(event, 6, false);
  }
  public endEvent(event: IEventDetails) {
    this.eventService.calculateStats(event)
      .then((response: IPromiseResponse) => {
        // show message, error message persist longer
        if (response.status) {
          this.eventService.eventUpdateStatus(event, 8, false)
            .then((response2: IPromiseResponse) => console.log(response2));
        }
      });
  }
  public reCalculateStats(event: IEventDetails) {
    this.eventService.calculateStats(event);
  }
  public reopenEvent(event: IEventDetails) {
    const newStatus = event.details.structure.isBatch ? 12 : 5;
    this.eventService.eventUpdateStatus(event, newStatus, false)
      .then((response: IPromiseResponse) => console.log(response));
  }
  public openNewBacthModal(event: IEventDetails): void {
    this.toModals.openNewBacthModal(event);
  }
  public openPlayoffConfigModal(eventDocId: string, isGroupEvent: boolean): void {
    this.toModals.openPlayoffConfigModal(eventDocId, isGroupEvent);
  }
  public openAnnouncementModal(eventDocId: string): void {
    this.toModals.openAnnouncementModal(eventDocId);
  }
  public openPayments(event$: Observable<IEventDetails>): void {
    this.toModals.openPayments(event$)
  }

  public get allMatchesReported(): boolean {
    if (this.matches$.getValue() === null || this.event$.getValue() === null) {
      return false
    }
    const event = this.event$.getValue()
    const matches = this.matches$.getValue()
    return matches.filter(i => !i.deleted && !i.isReported && i.roundNumber === event.activeRound).length === 0
  }

}

import { EventCardComponent } from './components/events/event-card/event-card.component';
import { QuillModule } from 'ngx-quill'
import { SETTINGS as SETTINGS_FIRESTORE } from '@angular/fire/compat/firestore';
import { ToAttendingPlayersPipe } from './pipes/to-attending-players.pipe';
import { RouterModule } from '@angular/router';
import { NgxPrintModule } from 'ngx-print';
import { NewsComponent } from './components/news/news.component';
import { NewsFeedComponent } from './components/dashboard/news-feed/news-feed.component';
import { NewsFeedPipe } from './pipes/news-feed.pipe';
import { RecentBrewsComponent } from './components/dashboard/recent-brews/recent-brews.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BoosterCardInfoComponent } from './components/drafts/draft-room/booster-card-info/booster-card-info.component';
import { BoosterCardComponent } from './components/drafts/draft-room/booster-card/booster-card.component';
import { environment } from './../environments/environment';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { HttpClientModule } from '@angular/common/http';
import { AutosizeModule } from 'ngx-autosize';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SwipeModule } from 'ng-swipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// GUARDS
import { AuthGuard, RoleGuard, PlayerGuard, ContentGuard } from './services';
// COMPONENTS
import {
  TopBarComponent,
  EventLobbyComponent,
  EventPlayersComponent,
  EventPlayerMatchesComponent,
  EventDetailsComponent,
  EventStandingsComponent,
  EventsComponent,
  LoginComponent,
  DialogsComponent,
  CollectionComponent,
  EventPlayoffConfigComponent,
  MatchRoomComponent,
  DeviceSettingsComponent,
  SettingsComponent,
  AvatarComponent,
  YouComponent,
  NotificationsComponent,
  ApperanceComponent,
  NewEventFormComponent,
  PlayerRegistrationFormComponent,
  RegisterAsPlayerComponent,
  EventReportSlipComponent,
  EventPairingsComponent,
  EventSwissPlayoffComponent,
  EventBracketMatchComponent,
  MobileComponent,
  MoreActionsSwissComponent,
  MoreActionsRoundRobinComponent,
  MessageToastComponent,
  OrganizerComponent,
  EventInvitedPlayersComponent,
  EventMainViewComponent,
  EventPlayerDetailsComponent,
} from './components';
import {
  ChatFormatPipe,
  MessageGroupsPipe,
  MatchTypePipe,
  PlayerSearchPipe,
  DeviceTypePipe,
  MediaDevicePipe,
} from './pipes';

import { NgxSortableModule } from 'ngx-sortable';
import { ManualPairingsComponent, ManualPairingsPipe } from './components/events/event-lobby/organizer/manual-pairings/manual-pairings.component';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { ModalReportSlipComponent } from './components/events/match-room/modal-report-slip/modal-report-slip.component';
import { MinSecPipe } from './pipes/min-sec.pipe';
import { VersionHistoryComponent, VersionHtmlPipe } from './components/app-structure/version-history/version-history.component';
import { EventSearchPipe } from './pipes/event-search.pipe';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { EventToGroupConfigComponent } from './components/events/event-lobby/organizer/event-to-group-config/event-to-group-config.component';
import { VersionNumberComponent } from './components/app-structure/version-number/version-number.component';
import { LoadingIndicatorComponent } from './components/app-structure/loading-indicator/loading-indicator.component';
import { AtSearchPipe } from './pipes/at-search.pipe';
import { PlayersFilterPipe } from './pipes/players-filter.pipe';
import { DownToPlaySettingsComponent } from './components/app-structure/top-bar/down-to-play-settings/down-to-play-settings.component';
import { DownToPlayComponent } from './components/down-to-play/down-to-play.component';
import { DeckSubmissionConfigComponent } from './components/events/event-lobby/organizer/deck-submission-config/deck-submission-config.component';
import { PlayersByCheckInPipe } from './pipes/players-by-check-in.pipe';
import { ToastsComponent } from './components/app-structure/toasts/toasts.component';
import { EventFilterPipe } from './pipes/event-filter.pipe';
import { CardsComponent } from './components/cards/cards.component';
import { CardItemComponent } from './components/cards/card-item/card-item.component';
import { CardDetailsComponent } from './components/cards/card-details/card-details.component';
import { NpnSliderModule } from 'node_modules/npn-slider';
import { CardTypesFilterPipe } from './pipes/card-types-filter.pipe';
import { CardBlocksFilterPipe } from './pipes/card-blocks-filter.pipe';
import { CardSetsFilterPipe } from './pipes/card-sets-filter.pipe';
import { CardPrintingsComponent } from './components/cards/card-details/card-printings/card-printings.component';
import { ManaParserPipe } from './pipes/mana-parser.pipe';
import { CardRulingsComponent } from './components/cards/card-details/card-rulings/card-rulings.component';
import { CardTypeParserPipe } from './pipes/card-type-parser.pipe';
import { DeckListParserPipe } from './pipes/deck-list-parser.pipe';
import { DeckListComponent } from './components/decks/deck-list/deck-list.component';
import { CardLanguagesComponent } from './components/cards/card-details/card-languages/card-languages.component';
import { CardLegalityComponent } from './components/cards/card-details/card-legality/card-legality.component';
import { MatchActionsComponent } from './components/events/match-room/match-actions/match-actions.component';
import { LifeTrackerComponent } from './components/events/match-room/life-tracker/life-tracker.component';
import { MatchNotesComponent } from './components/events/match-room/match-notes/match-notes.component';
import { OnlineUsersComponent } from './components/events/match-room/online-users/online-users.component';
import { EventRoundTimerComponent } from './components/events/event-round-timer/event-round-timer.component';
import { PlayerVideoComponent } from './components/events/match-room/player-video/player-video.component';
import { MediaControlsComponent } from './components/events/match-room/media-controls/media-controls.component';
import { EventPlayerActionsComponent } from './components/events/event-lobby/event-details/event-player-actions/event-player-actions.component';
import { StandingsPipe } from './pipes/standings.pipe';
import { EventToCoOrganizersComponent } from './components/events/event-lobby/organizer/event-to-co-organizers/event-to-co-organizers.component';
import { EventToDeckSubmissionComponent } from './components/events/event-lobby/organizer/event-to-deck-submission/event-to-deck-submission.component';
import { EventToPlayersComponent } from './components/events/event-lobby/organizer/event-to-players/event-to-players.component';
import { EventToLogComponent } from './components/events/event-lobby/organizer/event-to-log/event-to-log.component';
import { EventToAnnouncementComponent } from './components/events/event-lobby/organizer/event-to-announcement/event-to-announcement.component';
import { EventToCheckInComponent } from './components/events/event-lobby/organizer/event-to-check-in/event-to-check-in.component';
import { EventPlayerCheckInComponent } from './components/events/event-lobby/organizer/event-player-check-in/event-player-check-in.component';
import { MoreActionsBatchComponent } from './components/events/event-lobby/organizer/more-actions-batch/more-actions-batch.component';
import { MoreActionsGroupComponent } from './components/events/event-lobby/organizer/more-actions-group/more-actions-group.component';
import { EventToBatchConfigComponent } from './components/events/event-lobby/organizer/event-to-batch-config/event-to-batch-config.component';
import { EventActionsSwissComponent } from './components/events/event-lobby/organizer/actions/event-actions-swiss/event-actions-swiss.component';
import { EventActionsGroupComponent } from './components/events/event-lobby/organizer/actions/event-actions-group/event-actions-group.component';
import { EventActionsBatchComponent } from './components/events/event-lobby/organizer/actions/event-actions-batch/event-actions-batch.component';
import { EventActionsRoundRobinComponent } from './components/events/event-lobby/organizer/actions/event-actions-round-robin/event-actions-round-robin.component';
import { EventActionsCheckInComponent } from './components/events/event-lobby/organizer/actions/event-actions-check-in/event-actions-check-in.component';
import { EventActionsAdminComponent } from './components/events/event-lobby/organizer/actions/event-actions-admin/event-actions-admin.component';
import { ReversePipe } from './pipes/reverse.pipe';
import { EventToFeatureMatchConfigComponent } from './components/events/event-lobby/organizer/event-to-feature-match-config/event-to-feature-match-config.component';
import { SafePipe } from './pipes/safe.pipe';
import { MatchMonitorComponent } from './components/dashboard/match-monitor/match-monitor.component';
import { MatchInfoItemComponent } from './components/dashboard/match-monitor/match-info-item/match-info-item.component';
import { TermsOfUseComponent } from './components/app-structure/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './components/app-structure/privacy-policy/privacy-policy.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { WhoStartsModalComponent } from './components/events/match-room/who-starts-modal/who-starts-modal.component';
import { ClubsComponent } from './components/clubs/clubs.component';
import { ClubManagementComponent } from './components/clubs/club-management/club-management.component';
import { ClubPipe } from './pipes/club.pipe';
import { DeckPhotosComponent } from './components/decks/deck-photos/deck-photos.component';
import { DeckImageComponent } from './components/decks/deck-photos/deck-image/deck-image.component';
import { DecksterComponent } from './components/decks/deck-photos/deckster/deckster.component';
import { TolariaPlayerSearchComponent } from './components/players/tolaria-player-search/tolaria-player-search.component';
import { AddPlayerHandleComponent } from './components/modals/settings/add-player-handle/add-player-handle.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DowntimeNoticeComponent } from './components/app-structure/downtime-notice/downtime-notice.component';
import { DraftsComponent } from './components/drafts/drafts.component';
import { DraftSetupComponent } from './components/drafts/draft-setup/draft-setup.component';
import { SetListSelectorComponent } from './components/drafts/set-list-selector/set-list-selector.component';
import { SetsPipe } from './pipes/sets.pipe';
import { DraftRoomComponent } from './components/drafts/draft-room/draft-room.component';
import { PlayersActiveBoosterPipe } from './pipes/players-active-booster.pipe';
import { DraftPicksPipe } from './pipes/draft-picks.pipe';
import { DraftWaitingBoostersPipe } from './pipes/draft-waiting-boosters.pipe';
import { DraftsPipe } from './pipes/drafts.pipe';
import { DraftBoosterComponent } from './components/drafts/draft-room/draft-booster/draft-booster.component';
import { DraftRotisserieComponent } from './components/drafts/draft-room/draft-rotisserie/draft-rotisserie.component';
import { DraftRochesterComponent } from './components/drafts/draft-room/draft-rochester/draft-rochester.component';
import { DraftPicksComponent } from './components/drafts/draft-room/draft-picks/draft-picks.component';
import { DraftRotisserieCardsPipe } from './pipes/draft-rotisserie-cards.pipe';
import { DraftRotisseriePicksPipe } from './pipes/draft-rotisserie-picks.pipe';
import { DecksComponent } from './components/decks/decks.component';
import { DecksPipe } from './pipes/decks.pipe';
import { DeckTestComponent } from './components/decks/deck-test/deck-test.component';
import { CardImageComponent } from './components/cards/card-image/card-image.component';
import { SearchFormComponent } from './components/cards/search-form/search-form.component';
import { DeckBuilderCardListPipe } from './pipes/deck-builder-card-list.pipe';
import { DeckBuilderComponent } from './components/decks/deck-builder/deck-builder.component';
import { DeckItemComponent } from './components/decks/deck-item/deck-item.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { LibrarySearchPipe } from './pipes/library-search.pipe';
import { CardBackFallbackDirective } from './directives/card-back-fallback.directive';
import { DeckVisualEditorComponent } from './components/decks/deck-builder/deck-visual-editor/deck-visual-editor.component';
import { DeckPicEditorComponent } from './components/decks/deck-builder/deck-pic-editor/deck-pic-editor.component';
import { DeckDetailsComponent } from './components/decks/deck-builder/deck-details/deck-details.component';
import { DeckGraphsComponent } from './components/decks/deck-builder/deck-graphs/deck-graphs.component';
import { UserManagementComponent } from './components/app-structure/user-management/user-management.component';
import { DeckListEditorComponent } from './components/decks/deck-builder/deck-list-editor/deck-list-editor.component';
import { DeckPhotoComponent } from './components/decks/deck-photo/deck-photo.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ConfirmationDialogComponent } from './components/modals/confirmation-dialog/confirmation-dialog.component';
import { MatchSlipPrintPipe, PairingsPrintPipe, PrintPairingsComponent } from './components/events/event-lobby/organizer/print-pairings/print-pairings.component';
import { CardOfTheDayComponent } from './components/dashboard/card-of-the-day/card-of-the-day.component';
import { ScryfallImageVersionComponent } from './components/cards/scryfall-image-version/scryfall-image-version.component';
import { PaymentComponent } from './payment/payment.component';
import { PurchasesComponent } from './payment/purchases/purchases.component';
import { StripeConnectedAccountComponent } from './payment/stripe-connected-account/stripe-connected-account.component';
import { BusySpinnerComponent } from './components/app-structure/busy-spinner/busy-spinner.component';
import { PurchaseReceiptComponent } from './payment/purchase-receipt/purchase-receipt.component';
import { RegisterEventPaymentComponent } from './payment/register-event-payment/register-event-payment.component';
import { EventToAttendingPlayerComponent } from './components/events/event-lobby/organizer/event-to-attending-player/event-to-attending-player.component';
import { DropConfirmationComponent } from './components/events/event-lobby/organizer/drop-confirmation/drop-confirmation.component';
import { PayRegistrationFeeComponent } from './payment/pay-registration-fee/pay-registration-fee.component';
import { PayDonationComponent } from './payment/pay-donation/pay-donation.component';
import { EventToPaymentsComponent } from './components/events/event-lobby/organizer/event-to-payments/event-to-payments.component';
import { LifeCounterComponent } from './components/_public/life-counter/life-counter.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { EventTicketsComponent } from './components/tickets/event-tickets/event-tickets.component';
import { TicketsPipe } from './components/tickets/tickets.pipe';
import { PayTicketComponent } from './payment/pay-ticket/pay-ticket.component';
import { NewTicketComponent } from './components/tickets/new-ticket/new-ticket.component';
import { ManageTicketsComponent } from './components/tickets/manage-tickets/manage-tickets.component';
import { TicketDetailsComponent } from './components/tickets/manage-tickets/ticket-details/ticket-details.component';
import { TicketRowComponent } from './components/tickets/manage-tickets/ticket-row/ticket-row.component';
import { EventCheckInKioskComponent } from './components/events/event-check-in-kiosk/event-check-in-kiosk.component';
import { PlayerIdQrcodeComponent } from './components/players/player-id-qrcode/player-id-qrcode.component';
import { QRScannerComponent } from './components/modals/qrscanner/qrscanner.component';
import { CardFactoryComponent } from './components/card-factory/card-factory.component';
import { CreatedCardsComponent, CreatedCardsFilter } from './components/card-factory/created-cards/created-cards.component';
import { EditCreatedCardComponent, SpriteManaSymbol } from './components/card-factory/edit-created-card/edit-created-card.component';
import { MyCreatedCardComponent } from './components/card-factory/my-created-card/my-created-card.component';
import { CreatedCardViewerComponent } from './components/card-factory/created-card-viewer/created-card-viewer.component';
import { DarkmodeSwitchComponent } from './components/_utilities/dark-mode-switch/dark-mode-switch.component';
import { MarkdownEditorComponent } from './components/_utilities/markdown-editor/markdown-editor.component';
import { MarkdownPipe } from './components/_utilities/markdown-editor/markdown.pipe';
import { MatchRowComponent } from './components/events/event-lobby/event-main-view/event-pairings/match-row/match-row.component';
import { HandTrackerComponent } from './components/events/match-room/hand-tracker/hand-tracker.component';
import { PubsComponent } from './pubs/pubs.component';
import { PubComponent } from './pubs/pub/pub.component';
import { NewPubComponent } from './pubs/new-pub/new-pub.component';
import { AddNewTeamComponent } from './components/events/event-lobby/organizer/add-new-team/add-new-team.component';
import { TeamListItemComponent } from './components/events/event-lobby/organizer/team-list-item/team-list-item.component';
import { TeamMatchesPipe } from './pipes/team-matches.pipe';
import { TeamStandingsPipe } from './pipes/team-standings.pipe';
import { TeamStandingsComponent } from './components/events/event-lobby/event-main-view/event-standings/team-standings/team-standings.component';
import { TeamBracketMatchComponent } from './components/events/event-lobby/event-main-view/team-bracket-match/team-bracket-match.component';
import { DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    HoverClassDirective,
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CardOfTheDayComponent,
    TopBarComponent,
    MatchRoomComponent,
    SettingsComponent,
    AvatarComponent,
    YouComponent,
    NotificationsComponent,
    ApperanceComponent,
    EventsComponent,
    NewEventFormComponent,
    EventLobbyComponent,
    DeviceSettingsComponent,
    PlayerRegistrationFormComponent,
    RegisterAsPlayerComponent,
    EventPlayersComponent,
    EventDetailsComponent,
    EventReportSlipComponent,
    EventPairingsComponent,
    EventPlayerMatchesComponent,
    EventStandingsComponent,
    DialogsComponent,
    CollectionComponent,
    EventPlayoffConfigComponent,
    EventSwissPlayoffComponent,
    EventBracketMatchComponent,
    MobileComponent,
    MoreActionsSwissComponent,
    MoreActionsRoundRobinComponent,
    PlayerSearchPipe,
    MessageToastComponent,
    OrganizerComponent,
    EventInvitedPlayersComponent,
    EventMainViewComponent,
    EventPlayerDetailsComponent,
    ManualPairingsComponent,
    ModalReportSlipComponent,
    VersionHistoryComponent,
    ScrollToDirective,
    EventToGroupConfigComponent,
    VersionNumberComponent,
    LoadingIndicatorComponent,
    DownToPlaySettingsComponent,
    DownToPlayComponent,
    DeckSubmissionConfigComponent,
    ToastsComponent,
    DeviceTypePipe,
    MatchTypePipe,
    MessageGroupsPipe,
    MinSecPipe,
    EventSearchPipe,
    PlayersFilterPipe,
    PlayersByCheckInPipe,
    EventFilterPipe,
    CardsComponent,
    CardItemComponent,
    CardDetailsComponent,
    CardTypesFilterPipe,
    CardBlocksFilterPipe,
    CardSetsFilterPipe,
    CardPrintingsComponent,
    CardRulingsComponent,
    CardTypeParserPipe,
    DeckListComponent,
    CardLanguagesComponent,
    CardLegalityComponent,
    MatchActionsComponent,
    LifeTrackerComponent,
    MatchNotesComponent,
    OnlineUsersComponent,
    EventRoundTimerComponent,
    PlayerVideoComponent,
    MediaControlsComponent,
    EventPlayerActionsComponent,
    PairingsPrintPipe,
    MatchSlipPrintPipe,
    EventToCoOrganizersComponent,
    EventToDeckSubmissionComponent,
    EventToPlayersComponent,
    EventToLogComponent,
    EventToAnnouncementComponent,
    EventToCheckInComponent,
    EventPlayerCheckInComponent,
    MoreActionsBatchComponent,
    MoreActionsGroupComponent,
    EventToBatchConfigComponent,
    EventActionsSwissComponent,
    EventActionsGroupComponent,
    EventActionsBatchComponent,
    EventActionsRoundRobinComponent,
    EventActionsCheckInComponent,
    EventActionsAdminComponent,
    ReversePipe,
    EventToFeatureMatchConfigComponent,
    SafePipe,
    MatchMonitorComponent,
    MatchInfoItemComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    ClubsComponent,
    ClubManagementComponent,
    ClubPipe,
    DeckPhotosComponent,
    DeckImageComponent,
    DecksterComponent,
    TolariaPlayerSearchComponent,
    AddPlayerHandleComponent,
    VersionHtmlPipe,
    UserManagementComponent,
    DowntimeNoticeComponent,
    DecksComponent,
    DeckBuilderComponent,
    DeckItemComponent,
    DecksPipe,
    DeckTestComponent,
    CardImageComponent,
    LibrarySearchPipe,
    SearchFormComponent,
    DeckBuilderCardListPipe,
    CardBackFallbackDirective,
    DeckListEditorComponent,
    DeckVisualEditorComponent,
    DeckPicEditorComponent,
    DeckDetailsComponent,
    DeckGraphsComponent,
    DeckPhotoComponent,
    DraftsComponent,
    DraftSetupComponent,
    SetListSelectorComponent,
    SetsPipe,
    ManualPairingsPipe,
    DraftRoomComponent,
    PlayersActiveBoosterPipe,
    DraftPicksPipe,
    DraftWaitingBoostersPipe,
    DraftsPipe,
    DraftBoosterComponent,
    DraftRochesterComponent,
    DraftRotisserieComponent,
    DraftPicksComponent,
    DraftRotisserieCardsPipe,
    DraftRotisseriePicksPipe,
    BoosterCardComponent,
    BoosterCardInfoComponent,
    ConfirmationDialogComponent,
    PrintPairingsComponent,
    RecentBrewsComponent,
    ScryfallImageVersionComponent,
    NewsFeedPipe,
    NewsFeedComponent,
    NewsComponent,
    PaymentComponent,
    PurchasesComponent,
    StripeConnectedAccountComponent,
    PurchaseReceiptComponent,
    RegisterEventPaymentComponent,
    EventToAttendingPlayerComponent,
    DropConfirmationComponent,
    PayRegistrationFeeComponent,
    EventToPaymentsComponent,
    LifeCounterComponent,
    ToAttendingPlayersPipe,
    TicketsComponent,
    EventTicketsComponent,
    TicketsPipe,
    PayTicketComponent,
    NewTicketComponent,
    ManageTicketsComponent,
    TicketDetailsComponent,
    TicketRowComponent,
    EventCheckInKioskComponent,
    PlayerIdQrcodeComponent,
    CardFactoryComponent,
    CreatedCardsComponent,
    EditCreatedCardComponent,
    MyCreatedCardComponent,
    CreatedCardsFilter,
    CreatedCardViewerComponent,
    SpriteManaSymbol,
    DarkmodeSwitchComponent,
    ReportIssueComponent,
    MarkdownEditorComponent,
    MarkdownPipe,
    MatchRowComponent,
    HandTrackerComponent,
    PubComponent,
    PubsComponent,
    NewPubComponent,
    EventCardComponent,
    AddNewTeamComponent,
    TeamListItemComponent,
    TeamMatchesPipe,
    StandingsPipe,
    TeamStandingsPipe,
    TeamStandingsComponent,
    TeamBracketMatchComponent,
    MessageModalComponent,
    BusyIndicatorComponent,
    HoverClassDirective,
    MainMenuComponent,
  ],
  imports: [
    ScryfallImageViewComponent,
    StripeConnectedAccountInfoComponent,
    ClubMapsComponent,
    AutosizeModule,
    AppRoutingModule,
    RouterModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    HttpClientModule,
    PickerModule,
    DragAndDropModule,
    AngularFireMessagingModule,
    NpnSliderModule,
    AngularDraggableModule,
    FullCalendarModule,
    SwipeModule,
    InfiniteScrollModule,
    NgxChartsModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxPrintModule,
    QuillModule.forRoot({
        customModules: [
            { path: 'formats/tolaria-deck', implementation: TolariaDeckBlot, },
            { path: 'formats/tolaria-tournament', implementation: TolariaTournamentBlot, },
            { path: 'formats/tolaria-mention', implementation: TolariaPlayerMentionBlot, },
            { path: 'formats/tolaria-emoji', implementation: TolariaEmojiBlot, },
            { path: 'modules/tolariaEmojiTypehead', implementation: TolariaEmojiTypeheadModule, },
            { path: 'modules/pasteSmart', implementation: QuillPasteSmart, },
        ]
    }),
    NgxSortableModule,
    SharedComponentModule,
    SharedModule,
    QRScannerComponent,
    MediaDevicePipe,
    WhoStartsModalComponent,
    PayDonationComponent,
    BusySpinnerComponent,
    SupportButtonComponent,
    MatchesFilterBarComponent,
    EventsFilterBarComponent,
    AtSearchPipe,
    ChatFormatPipe,
    ManaParserPipe,
    DeckListParserPipe,
    MessageListComponent,
    TolariaWysiwygComponent,
    TolariaMessageFormComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
    }),
    TolariaSupportComponent,
    LeagueHeaderControlsComponent,
    PlayerMatchMonitorComponent
],
  providers: [
    DatePipe,
    DeckListParserPipe,
    AuthGuard,
    PlayerGuard,
    ContentGuard,
    RoleGuard,
    AppComponent,
    NgbActiveModal,
    {
      provide: SETTINGS_FIRESTORE,
      useValue: {
        experimentalforcelongpolling: true,
      } as firebase.default.firestore.Settings,
    },
    StandingsPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import 'quill-mention'
import { TolariaKeyboardModule } from './private/social/messages/components/tolaria-wysiwyg/quill/modules/tolaria-keyboard.module'
import { TolariaEmojiBlot } from './private/social/messages/components/tolaria-wysiwyg/quill/blots/tolaria-emoji.blot';
import TolariaEmojiTypeheadModule from './private/social/messages/components/tolaria-wysiwyg/quill/modules/tolaria-emoji-typehead.module';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import Quill, { StringMap } from 'quill';
import { HoverClassDirective } from './directives/hover-class.directive';
import { ReportIssueComponent } from './components/app-structure/report-issue/report-issue.component';
import { MessageModalComponent } from './components/modals/message-modal/message-modal.component';
import { BusyIndicatorComponent } from './components/modals/busy-indicator.component';
import { MainMenuComponent } from './components/app-structure/main-menu/main-menu.component';
import { StripeConnectedAccountInfoComponent } from './payment/stripe-connected-account/stripe-connected-account-info/stripe-connected-account-info.component';
import { TolariaDeckBlot } from './private/social/messages/components/tolaria-wysiwyg/quill/blots/tolaria-deck.blot';
import { TolariaTournamentBlot } from './private/social/messages/components/tolaria-wysiwyg/quill/blots/tolaria-tournament.blot';
import { TolariaPlayerMentionBlot } from './private/social/messages/components/tolaria-wysiwyg/quill/blots/tolaria-mention.blot';
import { SharedComponentModule } from './shared/shared-component/shared-component.module';
import { SharedModule } from './shared/shared.module';
import { SupportButtonComponent } from './components/app-structure/support-button/support-button.component';
import { EventsFilterBarComponent } from './private/play/events/components/events-filter-bar/events-filter-bar.component';
import { MatchesFilterBarComponent } from './private/play/matches/components/matches-filter-bar/matches-filter-bar.component';
import { tolBold, tolItalic, tolStrike, tolBlockqoute, tolLink, tolCode, tolCodeBlock, tolBulletList, tolNumberList } from './private/social/messages/components/tolaria-wysiwyg/assets';
import { MessageListComponent } from './private/social/messages/components/message-list/message-list.component';
import { TolariaWysiwygComponent } from './private/social/messages/components/tolaria-wysiwyg/tolaria-wysiwyg.component';
import { TolariaMessageFormComponent } from './private/social/messages/components/tolaria-message-form/tolaria-message-form.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ClubMapsComponent } from './components/clubs/club-maps/club-maps.component';
import { TolariaSupportComponent } from './private/_shared/tolaria-support/tolaria-support.component';
import QuillPasteSmart from './private/social/messages/components/tolaria-wysiwyg/quill/modules/quill-clipboard.module';
import { ScryfallImageViewComponent } from './components/decks/deck-builder/deck-visual-editor/scryfall-image-view/scryfall-image-view.component';
import { LeagueHeaderControlsComponent } from "./private/play/leagues/components/league-header-controls/league-header-controls.component";
import { PlayerMatchMonitorComponent } from "./private/play/matches/components/match-monitor/match-monitor.component";

export interface CustomIcon {
  default: string
  new: IconDefinition
}
/**
 * Quill icons
 */
const Icons: StringMap = Quill.import('ui/icons');

/**
 * List of icons to change
 */
const customIcons: CustomIcon[] = [
  {
    default: 'bold',
    new: tolBold,
  },
  {
    default: 'italic',
    new: tolItalic,
  },
  {
    default: 'strike',
    new: tolStrike,
  },
  {
    default: 'blockquote',
    new: tolBlockqoute,
  },
  {
    default: 'link',
    new: tolLink,
  },
  {
    default: 'code',
    new: tolCode,
  },
  {
    default: 'code-block',
    new: tolCodeBlock,
  },
]

/**
 * Convert given icon to SVG element which contains a path element
 *
 * @param icon FortAwesome icon
 * @param classList List of classes to attach to SVG element
 *
 * @see IconDefinition
 * @link https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage.md
 *
 * @returns SVG element based on given icon
 */
export function iconToSVGElement(icon: IconDefinition, classList?: string): SVGSVGElement {
  /**
   * Create SVG element
   */
  const node: SVGSVGElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  /**
   * Add classes
   */
  node.classList.add('svg-inline--fa', 'fa-fw', `${icon.prefix}-${icon.iconName}`);
  if (classList) {
    node.classList.add(classList);
  }
  /**
   * Set node viewBox
   */
  node.viewBox.baseVal.width = icon.icon[0];
  node.viewBox.baseVal.height = icon.icon[1];
  /**
   * Create Path element
   */
  const path: SVGPathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  /**
   * Set `fill` attribute's value to `currentColor` so it can adjustable
   */
  path.setAttributeNS(null, 'fill', 'currentColor');
  /**
   * Set `d` attribute's value to given icon's path commands
   *
   * 'd' is a string containing a series of path commands that define the path to be drawn
   */
  path.setAttributeNS(null, 'd', icon.icon[icon.icon.length - 1] as string);
  /**
   * Append {@link path} to {@link node} as a child
   */
  node.appendChild<SVGPathElement>(path);
  /**
   * Return node
   */
  return node;
}

/**
 * Loop into {@link customIcons} and change default icons
 */
customIcons.forEach((icon: CustomIcon): void => {
  Icons[icon.default] = iconToSVGElement(icon.new).outerHTML
});

Icons.list.bullet = iconToSVGElement(tolBulletList).outerHTML
Icons.list.ordered = iconToSVGElement(tolNumberList).outerHTML

Quill.register(Icons, true)
Quill.register({
  'modules/tolariaKeyboardModule': TolariaKeyboardModule
})

<ng-container *ngIf="(matchDocument$ | async) as matchData">


  <div #dragBounds class="main-view d-flex flex-column overflow-hidden"
    [style.background-image]="'url(' + playerBackground + ')'"
    [style.background-size]="'cover'"
    [ngClass]="{ 'minimized' : isMinimized }">

    <div class="top-bar d-flex align-items-center text-light">
      <div class="px-3">Tolaria - Match Room</div>
      <!-- <div class="btn btn-sm btn-blank text-secondary" ngbTooltip="Share Match Url" (click)="shareMatchUrl()"><fa-icon [icon]="faShareAlt" [fixedWidth]="true"></fa-icon></div> -->
      <span class="flex-grow-1"></span>
      <app-online-users placement="bottom-end" styleClass="btn btn-sm btn-blank" [onlineUsers$]="onlineUsers$"></app-online-users>
      <app-match-notes *ngIf="!isSpectator" [matchDocId]="matchDocId"></app-match-notes>
      <div *ngIf="!isSpectator" class="btn btn-sm btn-blank" [ngClass]="{ 'text-primary' : eventService.eventViewSettings.matchRoomMessages }"
        (click)="toggleChatPanel()" ngbTooltip="Toggle message panel (m)" container="body">
        <fa-icon [fixedWidth]="true" [icon]="faComment"></fa-icon>
      </div>
      <app-match-actions *ngIf="!isSpectator" [matchDocId]="matchDocId" [eventDocId]="eventDocId" [playerIs]="playerIs" [opponentDocId]="opponentDocId"></app-match-actions>
      <div class="btn btn-sm btn-blank" (click)="openSettings()"><fa-icon [icon]="faSettings"></fa-icon></div>
      <div class="btn btn-sm btn-blank ms-2" (click)="isMinimized = true"><fa-icon [icon]="faMinimize"></fa-icon></div>
      <div class="btn btn-sm btn-blank" (click)="close()"><fa-icon [icon]="faClose"></fa-icon></div>
    </div>

    <div class="flex-grow-1 d-flex flex-row overflow-hidden">
      <div #infoSection class="p-2 d-flex flex-column align-items-center" style="z-index: 11;">
        <!-- CHAT PANEL TOGGLER -->
        <!-- <div *ngIf="!isSpectator"
          class="btn btn-sm {{ eventService.eventViewSettings.matchRoomMessages ? 'btn-main' : 'btn-secondary' }} mb-2 w-100"
          (click)="toggleChatPanel()" ngbTooltip="Toggle message panel (m)" placement="right" container="body">
          <fa-icon [fixedWidth]="true" [icon]="faComment"></fa-icon>
        </div> -->
        <!-- PLAYER COUNT -->
        <!-- <app-online-users class="w-100 mb-2" [onlineUsers$]="onlineUsers$"></app-online-users> -->
        <!-- MATCH NOTES -->
        <!-- <app-match-notes *ngIf="!isSpectator" class="w-100 mb-2" [matchDocId]="matchDocId"></app-match-notes> -->
        <!-- MORE ACTIONS -->
        <!-- <app-match-actions *ngIf="!isSpectator" class="w-100 mb-2" [matchDocId]="matchDocId" [eventDocId]="eventDocId"
          [playerIs]="playerIs" [opponentDocId]="opponentDocId"></app-match-actions> -->
        <!-- SHARE MATCH URL -->
        <!-- <div class="btn btn-sm btn-blank" ngbTooltip="Share Match Url" (click)="shareMatchUrl()">
          <fa-icon [icon]="faShareAlt" [fixedWidth]="true"></fa-icon>
        </div> -->

        <div class="filler flex-grow-1"></div>
        <!-- PLAYER BADGES -->
        <div class="badge-wrapper mb-5" [style.minHeight.rem]="4.5">
          <app-player-badges-carousel [playerDocId]="opponentDocId"></app-player-badges-carousel>
        </div>

        <!-- PLAYER TOP    LIFE (either player1 or active opponent) -->
        <div class="life-wrapper w-100 mb-1">
          <app-life-tracker [playerIs]="isPlayer ? opponentIs : 'player1'" [matchDocId]="matchDocId"
            [matchWinsOnTop]="true" [isPlayersLife]="false"></app-life-tracker>
          <app-hand-tracker [playerIs]="isPlayer ? opponentIs : 'player1'" [matchDocId]="matchDocId"></app-hand-tracker>
        </div>
        <!-- ROUND TIMER -->
        <div class="btn btn-sm btn-outline-dark w-100">
          <app-event-round-timer [showIcon]="false" [topBarWrapper]="false"
            *ngIf="matchData.isType === 'swiss' else infinity"></app-event-round-timer>
          <ng-template #infinity>
            <fa-icon [fixedWidth]="true" [icon]="faInfinity"></fa-icon>
          </ng-template>
        </div>
        <!-- PLAYER BOTTOM LIFE (either player2 or active player) -->
        <div class="life-wrapper w-100 mt-1">
          <app-hand-tracker [playerIs]="isPlayer ? playerIs : 'player2'" [matchDocId]="matchDocId"></app-hand-tracker>
          <app-life-tracker [playerIs]="isPlayer ? playerIs : 'player2'" [matchDocId]="matchDocId"
            [matchWinsOnTop]="false" [isPlayersLife]="isPlayer ? true : false"></app-life-tracker>
        </div>

        <div class="badge-wrapper mt-5" [style.minHeight.rem]="4.5"></div>

        <div class="filler flex-grow-1"></div>

        <ng-container *ngIf="isOrganizer">
          <!-- CALL PLAYERS -->
          <div class="btn-controller mb-3" [ngClass]="{ 'off' : matchData.callingPlayers }"
            [ngbTooltip]="matchData.callingPlayers ? 'Hang-up on players' : 'Call players'" placement="bottom-left"
            (click)="organizerCallPlayers()">
            <fa-icon [fixedWidth]="true" [icon]="matchData.callingPlayers ? faPhoneSlash : faPhone">
            </fa-icon>
          </div>

          <!-- STOP PLAYERS -->
          <div class="btn-controller mb-3" [ngClass]="{ 'off' : matchData.holdPlayers }"
            [ngbTooltip]="matchData.holdPlayers ? 'Continue' : 'Hold players'" placement="bottom-left"
            (click)="organizerStopPlayers()">
            <fa-icon [fixedWidth]="true" [icon]="matchData.holdPlayers ? faHandPaper : faHandPaper">
            </fa-icon>
          </div>
        </ng-container>

        <!-- <app-media-controls *ngIf="!isSpectator"></app-media-controls> -->

        <div *ngIf="isSpectator" class="btn btn-sm {{ spectatorMutePlayers ? 'btn-danger' : 'btn-secondary' }} mb-2 w-100"
          (click)="spectatorMutePlayers = !spectatorMutePlayers">
          <fa-icon [fixedWidth]="true" [icon]="spectatorMutePlayers ? faVolumeMute : faVolumeOff"></fa-icon>
        </div>

      </div>

      <div #webrtcSection class="webrtc-section d-flex flex-column flex-grow-1">
        <!-- PLAYER VIEW -->
        <ng-container *ngIf="isPlayer">
          <app-player-video class="{{ focusOpponent ? 'video-large' : 'video-normal' }} pt-2 pb-1 ps-2 pe-2"
            [videoStream$]="isPlayer1 ? player2Stream$ : player1Stream$" [matchDocId]="matchDocId"
            [focused]="focusOpponent" [player]="opponentIs" placement="bottom" [isThisUser]="false"
            [muteVideo]="this.audioOutputMuted$ | async" (dblclick)="toggleFocusOpponent()"
            [resizeTrigger]="resizeTrigger$ | async"></app-player-video>
          <app-player-video class="{{ focusOpponent ? 'video-small' : 'video-normal' }} pt-1 pb-2 ps-2 pe-2"
            [videoStream$]="isPlayer1 ? player1Stream$ : player2Stream$" [matchDocId]="matchDocId"
            [focused]="focusOpponent" [player]="playerIs" placement="top" [isThisUser]="true" [muteVideo]="true"
            (dblclick)="toggleFocusOpponent()" [resizeTrigger]="resizeTrigger$ | async"></app-player-video>
        </ng-container>
        <!-- SPECTATOR & ORGANIZER VIEW -->
        <ng-container *ngIf="isSpectator || isOrganizer">
          <app-player-video class="video-normal pt-2 pb-1 ps-2 pe-2" [videoStream$]="player1Stream$"
            [matchDocId]="matchDocId" [muteVideo]="spectatorMutePlayers" player="player1" placement="bottom"
            [spectator]="true"></app-player-video>
          <app-player-video class="video-normal pt-1 pb-2 ps-2 pe-2" [videoStream$]="player2Stream$"
            [matchDocId]="matchDocId" [muteVideo]="spectatorMutePlayers" player="player2" placement="top"
            [spectator]="true"></app-player-video>
        </ng-container>
      </div>

      <div #messageSection class="message-section"
        [ngClass]="{ 'open' : eventService.eventViewSettings.matchRoomMessages || isSpectator || matchData.callingPlayers || matchData.holdPlayers }">

        <div class="message-section-wrapper d-flex max-height-100 min-height-100">

          <!-- ORGANIZER VIDEO: PREVIEW FOR ORGANIZER AND VIDEO FOR PLAYERS IF ORGANIZER IS CALLING -->
          <div class="organizer-video-wrapper w-100 p-2 {{ isPlayer ? 'player' : '' }}"
            *ngIf="isOrganizer || isPlayer && matchData.callingPlayers">
            <video class="organizer-video" [srcObject]="organizerStream$ | async" autoplay playsinline
              [muted]="isOrganizer"></video>
          </div>
          <!-- ORGANIZER HOLDING PLAYERS -->
          <div
            class="organizer-hold-players-wrapper w-100 p-2 d-flex flex-column align-items-center justify-content-center"
            *ngIf="isPlayer && matchData.holdPlayers">
            <fa-icon [fixedWidth]="true" [icon]="faHandPaper" class="text-jumbo"></fa-icon>
            <div class="h6">PLEASE HOLD YOUR GAME</div>
          </div>
          <!-- ORGANIZER CHAT TOGGLER -->
          <div class="organizer-chat-toggler p-2 w-100" *ngIf="isOrganizer">
            <div class="btn-group w-100" role="group" aria-label="organizerChatToggler">
              <button type="button" class="btn btn-sm {{ organizerPlayerChatActive ? 'btn-main' : 'btn-secondary' }}"
                (click)="organizerPlayerChatActive = true">Players</button>
              <button type="button" class="btn btn-sm {{ !organizerPlayerChatActive ? 'btn-main' : 'btn-secondary' }}"
                (click)="organizerPlayerChatActive = false">Spectators</button>
            </div>
          </div>

          <!-- <app-message-list class="flex-grow-1 overflow-hidden overflow-y-auto" [matchDocId]="matchDocId" [messageGroupDocId]="parsedMessageGroupDocId" [matchChat]="true" [spectatorMode]="chatMode">
          </app-message-list>

          <div class="message-form-wrapper p-2">
            <app-message-form [matchDocId]="matchDocId" [messageGroupDocId]="parsedMessageGroupDocId" [matchChat]="true" [spectatorMode]="chatMode">
            </app-message-form>
          </div> -->

        </div>
      </div>
    </div>


  </div>

  <div #featureMatchSection class="feature-match" [ngClass]="{ 'minimized' : featureMinimized }"
    *ngIf="matchData.isFeatured && isPlayer">
    <div #dragHandle class="card card-auto" style="width: fit-content;">
      <div class="card-header p-1 ps-2 pe-2 text-normal d-flex flex-row align-items-center">
        <div class="flex-grow-1 h6 m-0">Feature Match</div>
        <div class="flex-grow-0" ngbTooltip="Show instructions" container="body">
          <button class="btn btn-sm text-normal" (click)="featureInfoOpen = !featureInfoOpen">
            <fa-icon [fixedWidth]="true" [icon]="faHelp"></fa-icon>
          </button>
        </div>
        <div class="flex-grow-0" ngbTooltip="Refresh the feature match video interface" container="body">
          <button class="btn btn-sm text-normal" *ngIf="!featureMinimized && !featureInfoOpen"
            (click)="refreshFeatureIframe()">
            <fa-icon [fixedWidth]="true" [icon]="faRedoAlt"></fa-icon>
          </button>
        </div>
        <div class="flex-grow-0" ngbTooltip="Hide this dialog" container="body">
          <button class="btn btn-sm text-normal" [disabled]="featureMinimized" (click)="featureMinimized = true">
            <fa-icon [fixedWidth]="true" [icon]="faMinimize"></fa-icon>
          </button>
        </div>
        <div class="flex-grow-0" ngbTooltip="Show this dialog" container="body">
          <button class="btn btn-sm text-normal" [disabled]="!featureMinimized" (click)="featureMinimized = false">
            <fa-icon [fixedWidth]="true" [icon]="faMaximize"></fa-icon>
          </button>
        </div>
      </div>
      <div class="card-body no-hover scrollable p-0">
        <div class="p-3 d-flex flex-column h-100" *ngIf="featureInfoOpen">
          <div class="info flex-grow-1">
            <div class="h3">Greetings <b>Wizard</b>!</div>
            <p>Your match have been selected to be broadcasted on the events live coverage.</p>
            <p>To be able to send your audio and video to the video coverage team, you simply have to follow these
              steps.</p>
            <ol>
              <li>Make sure you have your video and audio set here @ Tolaria first before you continue</li>
              <li>Click the button (Proceed) in the bottom of this dialog to continue</li>
              <li>In the interface that loads</li>
              <ol>
                <li>Set your camera source</li>
                <li>Set your audio input source</li>
                <li>Click the [START] button</li>
              </ol>
              <li>Hide this dialog by pressing the minimize button in the header</li>
            </ol>
          </div>
          <p class="text-secondary text-italic">You will always be able to access these instruction by pressing the help
            button in the header.</p>
          <div class="btn btn-main w-100 mb-2" (click)="featureInfoOpen = false">Proceed</div>
        </div>
        <iframe border="0" width="500" height="400" allow="camera;microphone"
          [src]="matchData.featureUris[playerIs] | safe" *ngIf="!featureInfoOpen && !featureIframRefreshing"></iframe>
      </div>
    </div>
  </div>

  <!-- <app-modal-report-slip [matchData$]="matchDocument$" *ngIf="isPlayer && matchData?.reportSlip?.showSlip">
  </app-modal-report-slip> -->

</ng-container>

<ng-container *ngIf="matchDeleted">
  <div class="match-deleted">
    <div class="h1">Match Not Found</div>
    <div class="text-large">The match is no longer available, the organizer must have deleted it. Please return to the
      event and look for announcements from the organizer.</div>
  </div>
</ng-container>

<ng-template #confirmSpectate let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Spectator</h4>
    <div class="btn-close float-end text-secondary" aria-label="Close" (click)="modal.dismiss('cancel')">
      <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
    </div>
  </div>
  <div class="modal-body">
    <p>You are about to watch the match, you need to interact with this page before we can show you the players video
      feed. Please press OK to continue.</p>
  </div>
  <div class="modal-footer">
    <div type="button" class="btn btn-sm text-secondary" (click)="modal.dismiss('cancel')">OK</div>
  </div>
</ng-template>


<div class="minimized-match d-flex flex-column align-items-center justify-content-center p-3"
  [ngClass]="{ 'minimized' : isMinimized}">

  <div class="mb-3 text-large">Match against</div>
  <app-player-avatar [playerDocId]="opponentDocId"></app-player-avatar>
  <!-- <app-player-display-name [playerDocId]="opponentDocId"></app-player-display-name> -->

  <button class="mt-3 btn btn-sm btn-primary" (click)="isMinimized = false">Return to match</button>

</div>

import { MatchService } from 'src/app/services/match.service';
import { take, takeUntil } from 'rxjs/operators';
import { faEllipsisH, faCheck, faStar } from '@fortawesome/free-solid-svg-icons';
import { firstValueFrom, BehaviorSubject, Subject } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ISingleBracketMatrixMatch, IEventTeam, IMatchData } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-team-bracket-match',
  templateUrl: './team-bracket-match.component.html',
  styleUrls: ['./team-bracket-match.component.css']
})
export class TeamBracketMatchComponent implements OnInit, OnDestroy {
  @Input() matchInfo: ISingleBracketMatrixMatch;
  @Input() round: number;
  @Input() eventDocId: string;
  @Input() isOrganizer: boolean;
  @Input() isOnlineTournament: boolean;
  @Input() pathToVictory: ISingleBracketMatrixMatch = null;

  public exit$: Subject<boolean> = new Subject<boolean>()
  public init$: Subject<boolean> = new Subject<boolean>()
  public teams$: BehaviorSubject<IEventTeam[]> = new BehaviorSubject<IEventTeam[]>(null)
  public matches$: BehaviorSubject<IMatchData[]> = new BehaviorSubject<IMatchData[]>(null)
  public menu = faEllipsisH
  public star = faStar

  private matchColRef: AngularFirestoreCollection<IMatchData>

  constructor(
    private afs: AngularFirestore,
    private matchService: MatchService,
  ) { }

  ngOnInit(): void {
    console.log('TeamBracketMatchComponent --> init')
    console.log({ matchInfo: this.matchInfo, round: this.round })
    this.init()

    // initialize match subscription when init has finished
    this.init$.subscribe(init => {
      if (init) {
        this.matchColRef.valueChanges().pipe(takeUntil(this.exit$)).subscribe(m => this.matches$.next(m.sort((a, b) => a.teamSeat.localeCompare(b.teamSeat))))
      }
    })
  }

  ngOnDestroy(): void {
    this.exit$.next(true)
  }

  public matchTracker(index: number, item: IMatchData) {
    return item.docId
  }

  private async init() {

    let teams: IEventTeam[] = []
    const teamIds = ['blank']
    if (this.matchInfo.teams.teamOne !== null) { teamIds.push(this.matchInfo.teams.teamOne.id) }
    if (this.matchInfo.teams.teamTwo !== null) { teamIds.push(this.matchInfo.teams.teamTwo.id) }
    this.matchColRef = this.afs.collection<IMatchData>('matches', ref => ref
      .where('eventDocId', '==', this.eventDocId)
      .where('teamIds', 'array-contains-any', teamIds)
      .where('isType', '==', 'bracket')
      .where('roundNumber', '==', this.round))

    const teamCol = await firstValueFrom(this.afs.collection('events').doc(this.eventDocId).collection<IEventTeam>('teams', ref => ref.where('id', 'in', teamIds)).get())
    if (!teamCol.empty) {
      teams = teamCol.docs.map(i => i.data())
    }
    const matchCol = await firstValueFrom(this.matchColRef.get())
    const matches = matchCol.docs.map(i => i.data())

    teams.forEach(team => {
      const teamIsPlayer1 = matches.find(i => team.playerDocIds.includes(i.player1.playerDocId))
      const teamIsPlayer2 = matches.find(i => team.playerDocIds.includes(i.player2.playerDocId))
      if (teamIsPlayer1) { team.seed = teamIsPlayer1.player1.seed }
      if (teamIsPlayer2) { team.seed = teamIsPlayer2.player2.seed }
    })

    this.teams$.next(teams)
    this.matches$.next(matches.sort((a, b) => a.teamSeat.localeCompare(b.teamSeat)))

    this.init$.next(true)

    console.log({
      bracketMatch: this.matchInfo.matchDocId,
      matches,
      teams,
    })

  }


  public openReportSlip(seat: 'a' | 'b' | 'c'): void {
    const matchDoc = this.matches$.getValue().find(i => i.teamSeat === seat)
    this.matchService.openReportSlip(matchDoc, {})
  }

  public get hasTeamOne(): boolean {
    return this.matchInfo
      ? this.matchInfo.teams.teamOne !== null
      : false
  }
  public get hasTeamTwo(): boolean {
    return this.matchInfo
      ? this.matchInfo.teams.teamTwo !== null
      : false
  }

  public get teamOne(): IEventTeam {
    return this.teams$.getValue().length > 0
      ? this.teams$.getValue().find(i => i.id === this.matchInfo.teams.teamOne.id)
      : null
  }
  public get teamTwo(): IEventTeam {
    return this.teams$.getValue().length > 0
      ? this.teams$.getValue().find(i => i.id === this.matchInfo.teams.teamTwo.id)
      : null
  }

  public get pathToHereSelected(): boolean {
    return this.pathToVictory === null
      ? false
      : this.matchInfo.matchDocId === this.pathToVictory.feedsMatchDocId
  }

  public get pathToHerePlayerSlot(): string {
    return this.pathToVictory == null
      ? ''
      : this.pathToHereSelected
        ? this.pathToVictory.feedsPlayerSlot
        : ''
  }

}

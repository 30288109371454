<ng-container *ngIf="eventLog$ | async as log">

  <div class="log-list">

    <div class="log-item d-flex alig-items-center" *ngFor="let logItem of log">
      <div class="timestamp me-2 text-secondary" >
        <app-timestamp-clock [timestamp]="logItem.timestamp" displayType="date-time" [seconds]="true"></app-timestamp-clock>
      </div>
      <div class="text flex-grow-1" [innerText]="logItem.text"></div>
    </div>

  </div>

</ng-container>

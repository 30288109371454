import { Pipe, PipeTransform } from '@angular/core';
import { ICardMeta } from '../services/decks.service';

@Pipe({
  name: 'librarySearch'
})
export class LibrarySearchPipe implements PipeTransform {

  transform(items: Array<ICardMeta>): Array<ICardMeta> {
    if (!items) { return []; }

    // sort cards by name
    items.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    // remove duplicates
    // items = items.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name && t.name === item.name));

    return items;
  }

}

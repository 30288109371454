<div class="main-clubs p-0 d-flex flex-column">

  <div class="filter-bar flex-grow d-flex align-items-center">

    <div class="d-inline-block m-1" ngbTooltip="Show clubs on a map" container="body">
      <div class="btn btn-sm {{ showMapView ? 'btn-main' : 'btn-blank text-secondary' }}" (click)="showMapView = !showMapView">
        <fa-icon [icon]="faMapMarkedAlt" [fixedWidth]="true"></fa-icon>
      </div>
    </div>

    <div class="d-inline-block m-1" ngbTooltip="Find nearby clubs" container="body">
      <div class="btn btn-sm btn-blank text-secondary" (click)="findNearbyClubs()">
        <fa-icon [icon]="faMapMarkerAlt" [fixedWidth]="true"></fa-icon>
      </div>
    </div>

    <div class="d-inline-block m-1">
      <input type="text" class="form-control form-control-sm" style="min-width: 20rem;" placeholder="filter clubs (name/region/area/city/country)"
        [(ngModel)]="filterValue">
    </div>

    <div class="d-inline-block m-1">
      <div class="btn btn-sm btn-blank text-red" (click)="clearFilters()">
        Clear filters
      </div>
    </div>

  </div>

  <div class="d-flex flex-row flex-grow-1 overflow-hidden overflow-y-auto">

    <ng-container *ngIf="isResponsibleOfClub">
      <div class="floating-button">
        <div class="btn btn-sm btn-main" (click)="manageYourClub()">Manage your Club</div>
      </div>
    </ng-container>

    <ng-container *ngIf="clubs$ | async as clubs">
      <ng-container *ngIf="!showMapView">
        <div class="clubs-list-wrapper p-1 card-group">
          <div class="card club-card no-hover m-1" *ngFor="let club of clubs | clubFilter : filterValue : coords : useCoords">
            <div class="btn btn-sm btn-secondary admin-manage-club" *ngIf="isAdmin" (click)="manageClub(club.docId)">Manage
            </div>
            <div class="club-logo" *ngIf="club.imageUris.small !== null && club.imageUris.small !== ''">
              <img [src]="club.imageUris.small" class="w-100">
            </div>
            <div class="card-body no-hover p-3 d-flex flex-column">
              <div class="club-name h4">{{ club.name }}</div>
              <div class="row">
                <div class="col" [innerHtml]="club.info"></div>
              </div>
              <hr>
              <div class="row" *ngIf="club.city !== ''">
                <div class="col">{{ club.city }}</div>
              </div>
              <div class="row" *ngIf="club.region !== ''">
                <div class="col">{{ club.region }}</div>
              </div>
              <div class="row" *ngIf="club.country !== ''">
                <div class="col">{{ club.country }}</div>
              </div>
              <div class="row" *ngIf="club.websiteUrl !== ''">
                <div class="col"><a [href]="club.websiteUrl" target="_blank">{{ club.websiteUrl }}</a></div>
              </div>
              <div class="row flex-grow-1 align-items-end mt-2">
                <div class="col">
                  <div class="text-secondary mb-2">Contact</div>
                  <span class="me-1" *ngFor="let playerDocId of club.responsiblePlayerDocIds; let i = index">
                    <app-player-display-name [playerDocId]="playerDocId"></app-player-display-name>
                    <span
                      *ngIf="club.responsiblePlayerDocIds.length > 1 && i < (club.responsiblePlayerDocIds.length - 1)">,</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="card-footer pt-3 pb-3 pt-2 pb-2 d-flex align-items-center">
              <div class="flex-grow-1 cursor-pointer" [routerLink]="'/players'" [queryParams]="{ country: club.name }">
                <span class="text-secondary me-2">Members:</span>{{ club.memberDocIds.length }}
              </div>
              <div class="btn btn-blank btn-sm"
                (click)="lat = club.latitude; lng = club.longitude; showMapView = true; mapZoomLevel = 10">
                <fa-icon [icon]="faMapMarkedAlt" [fixedWidth]="true"></fa-icon>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="showMapView">
        <app-club-maps></app-club-maps>
      </ng-container>
    </ng-container>

  </div>

</div>


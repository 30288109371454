import { Pipe, PipeTransform } from '@angular/core';
import { IRSSFeedItem } from '../components/dashboard/news-feed/news-feed.component';

@Pipe({
  name: 'newsFeed'
})
export class NewsFeedPipe implements PipeTransform {

  transform(items: IRSSFeedItem[], numFeeds?: number): IRSSFeedItem[] {
    if (!items) {return []; }
    console.log(items);

    if (numFeeds) {
      return items.sort((a, b) => (a.pubDate > b.pubDate) ? -1 : ((b.pubDate > a.pubDate) ? 1 : 0)).splice(0, numFeeds);
    }
    return items.sort((a, b) => (a.pubDate > b.pubDate) ? -1 : ((b.pubDate > a.pubDate) ? 1 : 0));
  }

}

import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appHoverClass]'
})
export class HoverClassDirective {

  @Input('appHoverClass') hoverClass: string = ''

  constructor(
    public elementRef: ElementRef,
    private renderer: Renderer2,
  ) { }

  @HostListener('mouseenter') onMouseEnter() {
    // this.renderer.addClass(this.elementRef.nativeElement, this.hoverClass)
    this.elementRef.nativeElement.classList.add(this.hoverClass)
  }

  @HostListener('mouseleave') onMouseLeave() {
    // this.renderer.removeClass(this.elementRef.nativeElement, this.hoverClass)
    this.elementRef.nativeElement.classList.remove(this.hoverClass)
  }

}

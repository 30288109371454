<div class="match-info-container d-flex flex-column w-100 p-3" *ngIf="matchMeta$ | async as meta">

  <div class="d-flex align-items-center mb-2">

    <div class="text-large match-type flex-grow-1" [innerText]="meta.eventName"></div>

    <div class="action-wrapper d-flex gap-2">
      <button class="btn btn-sm"
        [ngClass]="{
          'btn-outline-danger': knockingDenied,
          'btn-outline-success': watchingAllowed,
          'btn-outline-secondary': !knockingDenied && !watchingAllowed
        }"
        [disabled]="disableButton"
        (click)="onButtonPress(meta)">
        <span *ngIf="waitingForAnswer" class="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
        {{ buttonText }}
      </button>
    </div>

  </div>

  <div class="d-flex align-items-center">

    <div class="player-wrapper d-flex">

      <div class="player-item flex-grow-1">
        <span [innerText]="meta.player1.displayName"></span>
      </div>

      <span class="ms-2 text-secondary me-2">vs</span>

      <div class="player-item flex-grow-1">
        <span [innerText]="meta.player2.displayName"></span>
      </div>

    </div>


  </div>

</div>

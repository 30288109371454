<ng-container *ngIf="initDone$ | async">


  <div class="modal-header px-3 text-large">Issue Refund?</div>
  <div class="modal-body px-3">
    <p>You are about to issue a refund of the registration fee paid by <span class="text-bold"
        [innerHtml]="player.name"></span>.
    </p>
    <p>Payment registered through: <span class="text-large">{{ paidWithProvider }}</span></p>
    <p class="btn btn-outline-main text-start" *ngIf="paidWithTolaria">
      Be aware that any fees connected to this payment will still apply to the tolaria.app platform account<br>
    </p>
    <ng-container *ngIf="!paidWithTolaria">
      <p class="btn btn-outline-main mb-3 text-start">
        This registration fee has been paid with an external payment method and you need to handle the refund manually
        as
        there is no automated process and you need to notify the player when you have solved the refund externally.
      </p>
      <div class="row mb-2">
        <div class="col-6">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="form-check-input me-2" id="registrationFeeRefund"
              [(ngModel)]="refundRegistrationFee">
            <label class="form-check-label" for="registrationFeeRefund">Registration fee</label>
          </div>
        </div>
        <div class="col-4">{{ registrationFeeAmountString }} {{ paidByInfo.currency.toUpperCase() }}</div>
      </div>
      <div class="row mb-2" *ngIf="paidByInfo.charityAmount > 0">
        <div class="col-6">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="form-check-input me-2" id="charityRefund" [(ngModel)]="refundCharityAmount">
            <label class="form-check-label" for="charityRefund">Charity extra</label>
          </div>
        </div>
        <div class="col-4">{{ charityAmountString }} {{ paidByInfo.currency.toUpperCase() }}</div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer px-3 py-2 d-flex flex-row align-items-center">
    <div class="custom-control custom-checkbox flex-grow-1 text-start">
      <input type="checkbox" class="form-check-input me-2" id="confirmRefundCheckBox"
        [(ngModel)]="confirmRefundCheckBox">
      <label class="form-check-label" for="confirmRefundCheckBox">Confirm Refunding</label>
    </div>
    <div type="button" class="btn btn-sm text-secondary" (click)="modal.dismiss()">Cancel</div>
    <button type="button" class="btn btn-sm btn-blank"
      (click)="paidWithTolaria ? refundTolariaPayment() : refundManualPayment()"
      [disabled]="disableRefundButton">Refund</button>
  </div>

</ng-container>

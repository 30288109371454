import { AuthService } from 'src/app/services/auth.service';
import { IFormatMeta } from './../components/app-structure/top-bar/down-to-play-settings/down-to-play-settings.component';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { IClub, IPlayerDetails, IPlayerHandle, IPlayerIdentifiers, IPromiseResponse, IUser } from 'tolaria-cloud-functions/src/_interfaces';

export class PlayerIdentifiers {
  hasShark = false
  isDingus = false
  isDrakeGuild = false
  isHero = false
  isGuardian = false
}
export interface IPlayerMeta {
  docId: string
  uid: string
  avatarUrl: string
  country: string
  region: string
  subregion: string
  name: string
  nickName: string
  displayName: string
  detailsRoute: string
  type: string
  UTC: string
  olson: string
  isMemberOfClub: boolean
  clubsSearchString: string
  clubDocId: string
  identifiers: Array<string>
  hasShark: boolean
  isDingus: boolean
  isHero: boolean
  isOnline: boolean
  statusText: string
  downToPlay: any
  downToPlayFormats?: Array<IFormatMeta>
  description: string
  addressIsPublic: boolean
  emailIsPublic: boolean
  phoneIsPublic: boolean
  addressStreet: string
  addressOther: string
  addressZipCode: number
  addressCity: string
  addressRegion: string
  email: string
  phone: string
  isOrganizer: boolean
  handles: IPlayerHandle[]
  statusMessage: string
}

export interface IPlayerSearch {
  displayName: string
  name: string
  nick: string
  docId: string
  country: string
  selected: boolean
  avatar: string
  searchString: string
}

export interface IPlayerLink {
  displayName: string
  first: string
  last: string
  docId: string
}

export interface IOnlinePlayer {
  status: string
  timestamp: number
  downToPlay: boolean
  downToPlayFormats: Array<IFormatMeta>
}

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  public playersOnline$: Observable<IPlayerDetails[]>;
  public playersCache$: Observable<IPlayerDetails[]>;
  public players$: Observable<IPlayerDetails[]>;
  private organizers$: Observable<IUser[]>;
  private clubs$: Observable<IClub[]>;

  public loggedInPlayer$: Observable<IPlayerDetails> = this.afs.collection('players').doc<IPlayerDetails>(this.auth.user.playerId).valueChanges()
  public loggedInPlayerDoc$: BehaviorSubject<IPlayerDetails> = new BehaviorSubject<IPlayerDetails>(null)

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
  ) {

    this.playersOnline$ = this.afs.collection<IPlayerDetails>('players', ref => ref
    .where('presence.status', '!=', 'offline')
    .where('presence.downToPlay', '==', true)
    ).valueChanges()

    this.organizers$ = this.afs.collection<IUser>('users', ref => ref
    .where('role', '==', 'organizer')
    ).valueChanges();

    this.playersCache$ = this.afs.collection<IPlayerDetails>('players', ref => ref
    .where('registered', '==', true)
    .where('type', '==', 'global')
    .orderBy('name', 'asc')
    ).valueChanges()
    .pipe(
      map(i => i.filter(player => player.disabled === undefined || player.disabled === false))
    );

    this.players$ = this.playersCache$.pipe(shareReplay(1));

    this.clubs$ = this.afs.collection<IClub>('clubs').valueChanges()

    this.loggedInPlayer$.subscribe((playerDoc) => {
      this.loggedInPlayerDoc$.next(playerDoc)
      if (!playerDoc.notifications || !playerDoc.notifications.email) {
        this.afs.collection('players').doc(playerDoc.docId).update({
          [`notifications.email`]: {
            eventReminders: true,
            matchAppointmentReminders: true,
            matchAppointments: true,
            newsLetter: false,
            developmentNews: false,
          }
        })
      }
    })
  }
  public getPlayerDisplayNameById(playerDocId: string) {
    return this.players$.pipe(
      map((players) => {
        const player = players.find(p => p.docId === playerDocId)
        return player.name.hasOwnProperty('display') ? player.name.display : player.name.first + ' ' + player.name.last
      })
    )
  }
  public getPlayerMetaDataByEmail(emailAddress: string): any {
    return new Promise((resolve, reject) => {
      this.players$
        .pipe(take(1))
        .subscribe(players => {
          const playerIndex = players.findIndex(p => p.email === emailAddress)
          if (playerIndex > -1) {
            // console.log('playerMeta to return', players[playerIndex])
            resolve({
              status: true,
              text: 'player found',
              data: players[playerIndex]
            })
          }
          else {
            // console.log('No playerMeta to return, email not registered')
            resolve({
              status: false,
              text: 'No player found'
            })
          }
        })
    })

  }
  public getOnlinePlayersMetaData(): Observable<IPlayerMeta[]> {
    return combineLatest([this.playersOnline$, this.clubs$, this.organizers$]).pipe(
      map(([onlinePlayers, clubs, organizers]) => {
        if (onlinePlayers.length === 0) { return [] }
        return onlinePlayers.map((player) => {
          const playerMeta: IPlayerMeta = this.createPlayerMeta(player, clubs, organizers, false)
          return playerMeta
        })
      })
    )
  }
  public getDownToPlayPlayersMetaData(): Observable<IPlayerMeta[]> {
    return combineLatest([this.playersOnline$, this.clubs$, this.organizers$]).pipe(
      map(([players, clubs, organizers]) => {
        if (players.length === 0) { return [] }
        return players.map((player) => {
          const playerMeta: IPlayerMeta = this.createPlayerMeta(player, clubs, organizers, false)
          return playerMeta
        })
      })
    )
  }
  public getPlayersMetaData(isDrakeGuild: boolean = false): Observable<IPlayerMeta[]> {
    return combineLatest([this.players$, this.clubs$, this.organizers$]).pipe(
      map(([players, clubs, organizers]) => {
        return players.map((player) => {
          const playerMeta: IPlayerMeta = this.createPlayerMeta(player, clubs, organizers, isDrakeGuild)
          return playerMeta
        })
      })
    )
  }
  public getPlayerMetaData(playerDocId?: string): Observable<IPlayerMeta> {
    if (playerDocId === undefined) {
      playerDocId = this.auth.user.playerId
    }
    return combineLatest([this.players$, this.clubs$, this.organizers$]).pipe(
      map(([players, clubs, organizers]) => {
        const player = players.find(p => p.docId === playerDocId)
        const playerMeta: IPlayerMeta = this.createPlayerMeta(player, clubs, organizers, false)
        return playerMeta
      })
    )
  }
  public getPlayerMetaDataByUid(playerUid: string): Observable<IPlayerMeta> {
    return combineLatest([this.players$, this.clubs$, this.organizers$]).pipe(
      map(([players, clubs, organizers]) => {
        const player = players.find(p => p.uid === playerUid)
        const playerMeta: IPlayerMeta = this.createPlayerMeta(player, clubs, organizers, false)
        return playerMeta
      })
    )
  }
  public getPlayerDisplayNameByUid(playerUid: string): Observable<IPlayerLink> {
    return this.players$.pipe(
      map(players => {
        const player = players.find(p => p.uid === playerUid)
        if (player !== undefined) {
          const playerLink: IPlayerLink = {
            displayName: player.name.display !== undefined && player.name.display !== '' ? player.name.display : player.name.first + ' ' + player.name.last,
            docId: player.docId,
            first: player.name.first,
            last: player.name.last
          }
          return playerLink
        }
      })
    )
  }
  public getPlayerDisplayNameByUid_Promise(playerUid: string): Promise<string> {
    // return this.afs.collection('players', ref => ref.where('playerUid', '==', playerUid)).valueChanges()
    return new Promise((resolve) => {
      this.players$.pipe(
        map(players => {
          const player = players.find(p => p.uid === playerUid)
          if (player !== undefined) {
            return player.name.display !== undefined && player.name.display !== '' ? player.name.display : player.name.first + ' ' + player.name.last
          }
        })
      ).subscribe(name => resolve(name))
    })
  }
  public getPlayerDisplayNameByPlayerDocId(playerDocId: string): Observable<IPlayerLink> {
    return this.players$.pipe(
      map(players => {
        const player = players.find(p => p.docId === playerDocId)
        if (player !== undefined) {
          const playerLink: IPlayerLink = {
            displayName: player.name.display !== undefined && player.name.display !== '' ? player.name.display : player.name.first + ' ' + player.name.last,
            docId: player.docId,
            first: player.name.first,
            last: player.name.last
          }
          return playerLink
        }
      })
    )
  }
  public getPlayerDisplayNameStringByProperty(value: string, property: string): Promise<string> {
    // property: uid or docId
    return new Promise((resolve, reject) => {
      this.players$.pipe(
        map(players => {
          const player = players.find(p => p[property] === value)
          return player.name.display !== undefined && player.name.display !== '' ? player.name.display : player.name.first + ' ' + player.name.last
        }),
        take(1)
      ).subscribe((playerDisplayName) => {
        resolve(playerDisplayName)
      })
    })

  }
  public createPlayerMeta(player: IPlayerDetails, clubs: IClub[], organizers: IUser[], isDrakeGuild: boolean): IPlayerMeta {
    const isOnline = player.presence !== undefined && player.presence.status !== undefined ? player.presence.status !== 'offline' : false
    const downToPlay = player.presence !== undefined && player.presence.downToPlay !== undefined ? player.presence.downToPlay ? true : false : false
    const downToPlayFormats = player.presence !== undefined && player.presence.downToPlayFormats !== undefined
      ? player.presence.downToPlayFormats
        ? player.presence.downToPlayFormats
        : []
      : []
    const statusText = player.presence !== undefined && player.presence.status !== undefined ? player.presence.status : 'Unknwon status'

    let isHero = false
    let isMemberOfClub = false
    let clubDocId = ''
    let avatarUrl = 'assets/avatars/default.jpg'
    let description = ''

    let clubsSearchString = ''
    if (player.clubDocIds !== undefined && player.clubDocIds.length > 0) {
      player.clubDocIds.forEach((clubDocId: string) => {
        const club = clubs.find(c => c.docId === clubDocId)
        if (club !== undefined) {
          clubsSearchString += `${club.name} `
        }
      })
    }

    // CLUB
    if (player.hasOwnProperty('identifiers') && player.identifiers.hasOwnProperty('memberOfClub')) {
      isMemberOfClub = player.identifiers.memberOfClub
    }
    if (player.hasOwnProperty('identifiers') && player.hasOwnProperty('clubDocIds')) {
      // should get the name from the team collection
      clubDocId = player.clubDocIds[0]
    }
    // HERO
    if (player.hasOwnProperty('identifiers') && player.identifiers.hasOwnProperty('isHero')) {
      isHero = player.identifiers.isHero
    }
    // DESCRIPTION
    if (player.hasOwnProperty('description') && player.description !== '' && player.description !== null) {
      description = player.description
    }
    // AVATAR
    if (player.hasOwnProperty('avatar') && player.avatar !== '' && player.avatar !== null) {
      avatarUrl = player.avatar
    }
    if (player.hasOwnProperty('avatarThumb') && player.avatarThumb !== '' && player.avatarThumb !== null) {
      avatarUrl = player.avatarThumb
    }


    const playerMeta: IPlayerMeta = {
      docId: player.docId,
      uid: player.uid,
      avatarUrl,
      country: player.country.name,
      region: player.country.region,
      subregion: player.country.subRegion,
      name: player.name.first + ' ' + player.name.last,
      nickName: player.name.nick,
      displayName: player.name.display !== undefined && player.name.display !== ''
        ? player.name.display
        : player.name.first + ' ' + player.name.last,
      detailsRoute: 'player/details/' + player.docId,
      type: player.type,
      UTC: player?.deviceUtcOffset ? player.deviceUtcOffset : player.timeZone.UTC,
      olson: player?.deviceUtcTimezoneName ? player.deviceUtcTimezoneName : player.timeZone.olson,
      isMemberOfClub,
      clubDocId,
      clubsSearchString,
      isOrganizer: organizers.find(p => p.playerId === player.docId) !== undefined,
      isOnline,
      identifiers: [],
      hasShark: player?.identifiers?.hasShark,
      isDingus: player?.identifiers?.isDingus,
      isHero: player?.identifiers?.isHero || player?.tolariaSupportUntil > Math.floor(Date.now() / 1000),
      statusText,
      downToPlay: isOnline ? downToPlay : false,
      downToPlayFormats: isOnline ? downToPlayFormats : [],
      description,
      addressIsPublic: player?.addressIsPublic,
      emailIsPublic: player?.emailIsPublic,
      phoneIsPublic: player?.phoneIsPublic,
      phone: player.phone,
      email: player.email,
      addressStreet: player?.address?.street,
      addressOther: player?.address?.other,
      addressZipCode: player?.address?.zipCode,
      addressCity: player?.address?.city,
      addressRegion: player?.address?.region,
      handles: player.hasOwnProperty('handles') ? player.handles : [],
      statusMessage: player.statusMessage && player.statusMessage.length > 0 ? player.statusMessage : null,
    }

    // IDENTIFIERS
    /*
    * 0. Drake
    * 1. Dingus
    * 2. Shark
    * 3. Guardian
    * 4. Hero
    */
    if (player?.identifiers?.isDrakeGuild) { playerMeta.identifiers.push('drake-guild') }
    if (player?.identifiers?.isGuardian) { playerMeta.identifiers.push('guardian') }
    if (playerMeta.isDingus) { playerMeta.identifiers.push('dingus') }
    if (playerMeta.hasShark) { playerMeta.identifiers.push('shark') }
    if (playerMeta.isHero) { playerMeta.identifiers.push('hero') }



    return playerMeta
  }
  public searchPlayers(): Observable<IPlayerSearch[]> {
    return this.players$.pipe(
      map((players) => {
        return players.map((player) => {
          const nick = player.name.nick ? player.name.nick : ''
          const avatar = player.avatar ? player.avatar : 'assets/avatars/default.jpg'
          // compose a search string
          let searchString = ''
          searchString += player.name.display !== undefined ? player.name.display : ''
          searchString += player.name.first !== undefined ? player.name.first : ''
          searchString += player.name.last !== undefined ? player.name.last : ''
          searchString += player.name.nick !== undefined ? player.name.nick : ''

          const data: IPlayerSearch = {
            name: player.name.first + ' ' + player.name.last,
            displayName: player.name.display !== undefined
              ? player.name.display
              : player.name.first + ' ' + player.name.last,
            docId: player.docId,
            country: player.country.name,
            nick,
            selected: false,
            avatar,
            searchString
          }

          // console.log(data)
          return data
        })
      })
    )
  }
  public updatePlayerDoc(playerDocId: string, update: any): Promise<IPromiseResponse> {
    return new Promise((resolve, reject) => {
      this.afs
        .collection('players')
        .doc(playerDocId)
        .set(update, { merge: true })
        .then(() => {
          resolve({
            status: true,
            text: 'Identifier updated'
          })
        })
        .catch(err => {
          console.log(err)
          resolve({
            status: false,
            text: err
          })
        })
    })
  }
  public getPlayersIdentifiers(playerDocId: string): Promise<IPlayerIdentifiers> {
    return new Promise((resolve, reject) => {
      this.players$.pipe(
        map(players => {
          const player = players.find(p => p.docId === playerDocId)
          if (player === undefined || !player.hasOwnProperty('identifiers')) {
            const identifiers = new PlayerIdentifiers()
            identifiers.isHero = identifiers?.isHero === true || player.tolariaSupportUntil > Math.floor(Date.now() / 1000)
            return identifiers
          }
          else {
            player.identifiers.isHero = player.identifiers?.isHero === true || player.tolariaSupportUntil > Math.floor(Date.now() / 1000)
            return player.identifiers
          }
        }),
        take(1)
      ).subscribe(identifiers => {
        resolve(identifiers)
      })
    })
  }
  public getPlayersClubs(playerDocId: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.players$.pipe(
        map(players => {
          const player = players.find(p => p.docId === playerDocId)
          if (player !== undefined) {
            return player.clubDocIds
          }
          else {
            return []
          }
        }),
        take(1)
      ).subscribe(identifiers => {
        resolve(identifiers)
      })
    })
  }
  public get isDST() {
    // var today = new Date
    const today = new Date()
    const yr = today.getFullYear()
    const dstStart = new Date('March 14, ' + yr + ' 02:00:00') // 2nd Sunday in March can't occur after the 14th
    const dstEnd = new Date('November 07, ' + yr + ' 02:00:00') // 1st Sunday in November can't occur after the 7th
    let day = dstStart.getDay() // day of week of 14th
    dstStart.setDate(14 - day) // Calculate 2nd Sunday in March of this year
    day = dstEnd.getDay() // day of the week of 7th
    dstEnd.setDate(7 - day) // Calculate first Sunday in November of this year
    if (today >= dstStart && today < dstEnd) { // does today fall inside of DST period?
      return true // if so then return true
    }
    return false // if not then return false
  }
  public get today() {
    return new Date()
  }
  public getPlayerByDocId_Promise(playerDocId: string): Promise<IPlayerDetails> {
    return new Promise((resolve) => {
      this.afs.collection('players').doc<IPlayerDetails>(playerDocId).get().toPromise()
        .then((playerDoc) => {
          if (playerDoc.exists) resolve(playerDoc.data() as IPlayerDetails)
          else resolve(null)
        })
        .catch(() => resolve(null))
    })
  }

}

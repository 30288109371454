import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct, NgbCalendar, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from 'src/app/services';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.css']
})
export class DateTimePickerComponent implements OnInit {
  @Input() timestamp: number
  @Input() outsideDays: boolean = true
  @Input() displayMonths: number = 1
  @Input() title: string = ''
  @Input() displayAs: 'inline' | 'modal' = 'inline'
  @Input() return: 'seconds' | 'milliseconds' = 'seconds'

  @Output() dateSelectionChanged = new EventEmitter<number>()

  public model: NgbDateStruct
  public date: { year: number; month: number }
  public time: { hour: number; minute: number }

  constructor(
    private calendar: NgbCalendar,
    private modal: NgbActiveModal,
    private globals: GlobalsService,
  ) { }

  ngOnInit(): void {
    console.log('init')
    let date = new Date()
    if (this.timestamp && this.timestamp !== 0) {

      date = new Date(this.timestamp)
      if (Math.abs(Date.now() - date.getTime()) > Math.abs(Date.now() - date.getTime() * 1000)) {
        date = new Date(this.timestamp * 1000)
      }

      this.model = {
        year: date.getUTCFullYear(),
        month: date.getUTCMonth() + 1,
        day: date.getUTCDate()
      }
      this.time = {
        hour: date.getHours(),
        minute: date.getMinutes(),
      }
    }
    else {
      this.model = this.calendar.getToday()
      const nextFullQuarter = Math.ceil(date.getMinutes() / 15) * 15
      this.time = {
        hour: nextFullQuarter === 60 ? date.getHours() + 1 : date.getHours(),
        minute: nextFullQuarter === 60 ? 0 : nextFullQuarter
      }
    }
  }

  public onDismiss(): void {
    this.modal.dismiss()
  }

  public onClose(): void {
    this.modal.close(this.selectedTimestamp)
  }

  public onSelecteToday(): void {
    this.model = this.calendar.getToday()
  }

  public onDateSelected(): void {
    this.dateSelectionChanged.emit(this.selectedTimestamp)
  }

  private get selectedTimestamp(): number {
    if (!this.model || !this.time) {
      return 0
    }
    const date = new Date(this.model.year, this.model.month - 1, this.model.day, this.time.hour, this.time.minute)
    return this.return === 'seconds' ? Math.round(date.getTime() / 1000) : date.getTime()
  }

  public get isMobile(): boolean {
    return this.globals.isMobile
  }


}

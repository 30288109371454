import { Pipe, PipeTransform } from '@angular/core';
import { IDeckMeta } from '../services/decks.service';

@Pipe({
  name: 'deckLinker',
  standalone: true,
})
export class DeckLinkerPipe implements PipeTransform {

  transform(items: IDeckMeta[], filterValue: string): IDeckMeta[] {
    if (!items) { return [] }

    // sort items
    items = items.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));

    if (filterValue && filterValue.length > 3) {
      return items.filter(i => i.filterString.indexOf(filterValue.toLowerCase()) > -1);
    }

    return items;
  }

}

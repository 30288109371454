export interface ICardFrames {
    text: string,
    frames: ICardFrame[]
}

export interface ICardFrame {
    frame: 'w' | 'u' | 'b' | 'r' | 'g' | 'm' | 'a' | 'l-c' | 'l-w' | 'l-u' | 'l-b' | 'l-r' | 'l-g' | 'l-wu' | 'l-ub' | 'l-br' | 'l-rg' | 'l-gw' | 'l-wb' | 'l-ur' | 'l-bg' | 'l-rw' | 'l-gu'
    icon: string
    text: string
    group: string
}

export class CardFrames {

    frames: ICardFrames[]

    constructor() {
        this.frames = [
            {
                text: 'Regular',
                frames: [
                    {
                        frame: 'w',
                        icon: 'w',
                        text: 'White',
                        group: 'Regular'
                    },
                    {
                        frame: 'u',
                        icon: 'u',
                        text: 'Blue',
                        group: 'Regular'
                    },
                    {
                        frame: 'b',
                        icon: 'b',
                        text: 'Black',
                        group: 'Regular'
                    },
                    {
                        frame: 'r',
                        icon: 'r',
                        text: 'Red',
                        group: 'Regular'
                    },
                    {
                        frame: 'g',
                        icon: 'g',
                        text: 'Green',
                        group: 'Regular'
                    },
                    {
                        frame: 'm',
                        icon: 'multicolor',
                        text: 'Multicolor',
                        group: 'Regular'
                    },
                    {
                        frame: 'a',
                        icon: 'artifact',
                        text: 'Artifact',
                        group: 'Regular'
                    },
                    {
                        frame: 'l-c',
                        icon: 'land',
                        text: 'Land',
                        group: 'Regular'
                    },
                ],
            },
            {
                text: 'Colored Land',
                frames: [
                    {
                        frame: 'l-w',
                        icon: 'w',
                        text: 'Land White',
                        group: 'Colored Land'
                    },
                    {
                        frame: 'l-u',
                        icon: 'u',
                        text: 'Land Blue',
                        group: 'Colored Land'
                    },
                    {
                        frame: 'l-b',
                        icon: 'b',
                        text: 'Land Black',
                        group: 'Colored Land'
                    },
                    {
                        frame: 'l-r',
                        icon: 'r',
                        text: 'Land Red',
                        group: 'Colored Land'
                    },
                    {
                        frame: 'l-g',
                        icon: 'g',
                        text: 'Land Green',
                        group: 'Colored Land'
                    },
                ],
            },
            {
                text: 'Multicolored Land',
                frames: [
                    {
                        frame: 'l-wu',
                        icon: 'wu',
                        text: 'Land White/Blue',
                        group: 'Multicolored Land'
                    },
                    {
                        frame: 'l-ub',
                        icon: 'ub',
                        text: 'Land Blue/Black',
                        group: 'Multicolored Land'
                    },
                    {
                        frame: 'l-br',
                        icon: 'br',
                        text: 'Land Black/Red',
                        group: 'Multicolored Land'
                    },
                    {
                        frame: 'l-rg',
                        icon: 'rg',
                        text: 'Land Red/Green',
                        group: 'Multicolored Land'
                    },
                    {
                        frame: 'l-wb',
                        icon: 'wb',
                        text: 'Land White/Black',
                        group: 'Multicolored Land'
                    },
                    {
                        frame: 'l-ur',
                        icon: 'ur',
                        text: 'Land Blue/Red',
                        group: 'Multicolored Land'
                    },
                    {
                        frame: 'l-bg',
                        icon: 'bg',
                        text: 'Land Black/Green',
                        group: 'Multicolored Land'
                    },
                    {
                        frame: 'l-rw',
                        icon: 'rw',
                        text: 'Land Red/White',
                        group: 'Multicolored Land'
                    },
                    {
                        frame: 'l-gu',
                        icon: 'gu',
                        text: 'Land Green/Blue',
                        group: 'Multicolored Land'
                    },
                ]
            },
        ]


    }

}
<div class="tolaria-editor">

    <div class="toolbar d-flex align-items-center">
    
        <ng-container *ngFor="let button of toolbarButtons">
            
            <ng-template #tipContent>
                <div [innerHtml]="button.tooltip" style="white-space: no-wrap;"></div>
            </ng-template>
            <div class="btn btn-sm btn-blank" (click)="button.action()" [ngbTooltip]="tipContent" container="body">
                <fa-icon [icon]="button.icon" [fixedWidth]="true"></fa-icon>
            </div>
            
        </ng-container>
        
        <div class="flex-grow-1"></div>
        
    </div>
    
    <div class="text-editor">

        <textarea #inputArea *ngIf="writing" [style.resize]="'none'" rows="6" [(ngModel)]="textInput"></textarea>

        <div class="preview-area" *ngIf="!writing" [innerHtml]="textInput | markdown"></div>

        <div class="buttons ms-4 d-flex">

            <div class="btn btn-sm btn-blank d-flex align-items-center justify-content-center"
                [ngClass]="{ 'btn-outline-secondary selected' : writing }"
                [style.width.rem]="5"
                ngbTooltip="Show editor"
                container="body"
                (click)="writing = true">
                Write
            </div>
            <div class="btn btn-sm btn-blank d-flex align-items-center justify-content-center"
                [ngClass]="{ 'btn-outline-secondary selected' : !writing }"
                [style.width.rem]="5"
                ngbTooltip="Preview content"
                container="body"
                (click)="writing = false">
                Preview
            </div>

            <div class="flex-grow-1"></div>

            <button class="btn btn-sm btn-outline-main mt-2 px-3" [disabled]="textInput.length === 0">
                {{ buttonText }}
            </button>
    
        </div>

    </div>

</div>
<ng-container *ngIf="isAdmin">

  <ng-container *ngIf="eventDoc$ | async as event">

    <div class="d-flex align-items-center mt-3 mb-2 ms-3 cursor-pointer" (click)="submenuOpen = !submenuOpen">
      <fa-icon [icon]="faChevronRight" class="text-small text-secondary me-2 transition-all-25" [ngClass]="{ 'rotate-90deg' : submenuOpen }"></fa-icon>
      <span class="text-secondary flex-grow-1">Admin</span>
    </div>

    <ng-container *ngIf="submenuOpen">
      <button ngbDropdownItem (click)="reportAllMatches(event)">
        Report all matches 2-0
      </button>
  
      <button ngbDropdownItem (click)="reportAllMatchesRandom(event)">
        Report all matches random result
      </button>
  
      <button ngbDropdownItem (click)="closeEvent(event)">
        Close event
      </button>
    </ng-container>

  </ng-container>

</ng-container>

import { IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'matchType'
})
export class MatchTypePipe implements PipeTransform {

  transform(items: IMatchData[], type?: string): IMatchData[] {
    const res: Array<IMatchData> = [];
    if (items && type !== undefined) {
      res.length = 0;
      items.forEach(match => {
        if (match.isType === type) {
            res.push(match);
        }
      });
    }
    res.sort((a, b) => (a.segmentNumber < b.segmentNumber) ? -1 : ((b.segmentNumber < a.segmentNumber) ? 1 : 0));
    res.sort((a, b) => (a.roundNumber < b.roundNumber) ? -1 : ((b.roundNumber < a.roundNumber) ? 1 : 0));
    return res;
  }

}

import { IEventViewSettings } from './event/event.service';
import { IEventLobbyViewSettings } from './../components/events/event-lobby/event-lobby.component';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(
    private router: Router
  ) {}

  public get eventLobbyViewSettings(): IEventViewSettings | false {
    // get the events document id from the url
    const eventDocId = this.router.url.split('/')[2];
    // see if any settings are stored
    const settings = localStorage.getItem(eventDocId);
    if (settings) {
      return JSON.parse(settings) as IEventViewSettings;
    }
    else {
      return false;
    }
  }

  public setEventLobbyViewSettings(settings: IEventViewSettings) {
    console.log('saving panel settings');
    // get the events document id from the url
    const eventDocId = this.router.url.split('/')[2];
    localStorage.setItem(eventDocId, JSON.stringify(settings))
  }

}

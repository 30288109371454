import { faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import { BehaviorSubject, Subject, takeUntil } from 'rxjs'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { PaymentService } from '../payment.service'
import { IPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { PaymentFactor, ITicketMeta, PlatformFee, ProductType, ITicket } from 'tolaria-cloud-functions/src/_interfaces'
import { PlayerNameService } from 'src/app/services/players/player-name.service'

@Component({
  selector: 'app-pay-ticket',
  templateUrl: './pay-ticket.component.html',
  styleUrls: ['./pay-ticket.component.css']
})
export class PayTicketComponent implements OnInit, OnDestroy {
  @Input() ticket: ITicket

  private destroyed$ = new Subject<boolean>()
  private ticketMeta: ITicketMeta
  public now = Math.floor(new Date().getTime() / 1000)
  public donationAmount: number
  public creatingCheckout: boolean = false
  public player$: BehaviorSubject<IPlayerDetails> = new BehaviorSubject<IPlayerDetails>(null)
  public ticket$: BehaviorSubject<ITicket> = new BehaviorSubject<ITicket>(null)

  public ticketIcon = faTicketAlt

  constructor(
    private payment: PaymentService,
    private readonly playerNames: PlayerNameService,
    private modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.playerNames.serviceReady$.pipe(takeUntil(this.destroyed$)).subscribe((ready) => ready ? this.init() : null)
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  private async init() {
    this.player$.next(this.playerNames.currentUserPlayerDoc$.getValue())
    if (this.ticket !== undefined && this.ticket !== null && this.player$.getValue() !== null) {
      this.ticket$.next(this.ticket)
      this.ticketMeta = {
        productType: ProductType.TICKET,
        currency: this.ticket.salesData.currency.toUpperCase(),
        ticketAmount: this.ticket.salesData.price * PaymentFactor,
        donationAmount: 0,
        platformCostAmount: (this.ticket.salesData.price * PaymentFactor) * PlatformFee,
        platformFee: PlatformFee,
        playerDocId: this.player$.getValue().docId,
        playerUid: this.player$.getValue().uid,
        playerEmail: this.player$.getValue().email,
        ticketDocId: this.ticket.docId,
        ticketCreatedByUid: this.ticket.createdByUid,
        ticketType: this.ticket.type,
        ticketName: this.ticket.name,
        // ticketMeta: JSON.stringify(this.ticket.data),
        ticketMeta: `EMPTY, SHOULD NOT BE NEEDED!`
      }
      console.log(this.ticketMeta)
    }
  }

  public proceedToCheckout(): void {
    // set busy on the checkout button
    this.creatingCheckout = true
    // add donation amount to the meta if any
    this.donationAmount !== undefined
      ? this.ticketMeta.donationAmount = (this.donationAmount * PaymentFactor)
      : this.ticketMeta.donationAmount = 0
    // update the platform cost as this might be changed due to added charity amount
    this.ticketMeta.platformCostAmount = (this.ticket.salesData.price * PaymentFactor) * PlatformFee

    // call service to create checkout session
    this.payment.createCheckoutSession.TICKET(
      this.ticket.salesData.price,
      this.donationAmount === undefined ? 0 : this.donationAmount,
      this.currency,
      this.productName,
      this.ticketMeta)
  }

  public close(): void {
    this.modal.close()
  }

  public get hasStripeAccount(): boolean {
    return this.player$.getValue().stripeCustomerId ? true : false
  }

  public get stripeAccountNumber(): string {
    return this.hasStripeAccount ? this.player$.getValue().stripeCustomerId : ''
  }

  public get totalAmount(): number {
    return this.ticket.salesData.price + (this.donationAmount === undefined ? 0 : this.donationAmount)
  }

  public get currency(): string {
    return this.ticket.salesData.currency.toUpperCase()
  }

  private get productName(): string {
    return `${this.ticket.type}_TICKET: ${this.ticket.name}`
  }

}

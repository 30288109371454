<div class="modal-header">
  <span class="text-larger">Event Payments</span>
</div>

<ng-container *ngIf="eventData$ | async as eventData; else loading">

  <div class="modal-body p-3 d-flex flex-column">


    <ng-container *ngIf="data$ | async as data; else loading">

      <ng-container *ngIf="data.charges.length > 0; else noCharges">

        <table>
          <thead>
            <td></td>
            <td>Time</td>
            <td>Product</td>
            <td>Player</td>
            <td>Status</td>
            <td></td>
            <td class="text-end">Amount</td>
            <td class="text-end">Fees</td>
            <td class="text-end">Transferred</td>
          </thead>
          <tbody>
            <tr *ngFor="let charge of data.charges">

              <td>
                <div class="pe-2 py-2 text-monospace d-flex align-items-center" [ngClass]="{ 'text-secondary': !charge.tolariaPay }"
                  [ngbTooltip]="chargeTooltip[charge.paymentType]" container="body" placement="top-left top">
                  <fa-icon [icon]="chargeIcon[charge.paymentType]" [fixedWidth]="true"></fa-icon>
                </div>
              </td>

              <td>
                <div class="pe-2 py-2 text-monospace" [ngClass]="{ 'text-secondary': !charge.tolariaPay }">
                  <app-timestamp-clock [timestamp]="charge.date" [seconds]="true"></app-timestamp-clock>
                </div>
              </td>

              <td>
                <div class="pe-2 py-2 text-monospace me-2 flex-grow-1" [ngClass]="{ 'text-secondary': !charge.tolariaPay }">
                  {{ charge.productType }}
                </div>
              </td>

              <td>
                <div class="pe-2 py-2 text-monospace me-2 flex-grow-1" [ngClass]="{ 'text-secondary': !charge.tolariaPay }">
                  <app-player-display-name [playerDocId]="charge.player.docId"></app-player-display-name>
                </div>
              </td>

              <td>
                <ng-template #statusTooltip>
                  <div class="d-flex flex-column algin-items-start text-start">
                    <span class="text-bold mb-1 border-bottom border-secondary">{{ charge.isPartiallyRefunded ? 'Partial refund' :
                      charge.isRefunded ? 'Refunded' : 'Paid' }}</span>
                    <div class="d-flex align-items-center w-100 align-items-center">
                      <div class="text-secondary text-smaller flex-grow-1 me-2">Registration fee: </div>
                      <div class="flex-grow-0">{{ charge.isRefunded ||charge.isPartiallyRefunded ?
                        charge.amountString.registrationFee : '0.00' }} {{ charge.currency }}</div>
                    </div>
                    <div class="d-flex align-items-center w-100 align-items-center" *ngIf="charge.amount.charity > 0">
                      <div class="text-secondary text-smaller flex-grow-1 me-2">Event charity support: </div>
                      <div class="flex-grow-0">0.00 {{ charge.currency }}</div>
                    </div>
                    <div class="d-flex align-items-center w-100 align-items-center" *ngIf="charge.amount.donation > 0">
                      <div class="text-secondary text-smaller flex-grow-1 me-2">Platform support: </div>
                      <div class="flex-grow-0">0.00 {{ charge.currency }}</div>
                    </div>
                  </div>
                </ng-template>
                <div class="badge text-monospace text-uppercase"
                  [ngClass]="{ 'text-bg-primary' : charge.isPartiallyRefunded, 'text-bg-danger' : charge.isRefunded, 'text-bg-success' : !charge.isPartiallyRefunded && !charge.isRefunded }"
                  [ngbTooltip]="statusTooltip" container="body">
                  <span class="me-1">{{ charge.isPartiallyRefunded ? 'Partial refund' : charge.isRefunded ? 'Refunded' :
                    'Paid' }}</span>
                  <fa-icon [icon]="charge.isPartiallyRefunded ? infoIcon : charge.isRefunded ? refundedIcon : paidIcon"
                    [fixedWidth]="true"></fa-icon>
                </div>
              </td>

              <td>
                <ng-template #charityTooltip>
                  <div class="d-flex flex-column algin-items-start text-start">
                    <span class="text-bold mb-1 border-bottom border-secondary">{{ charge.amountString.charityAndDonation }} {{ charge.currency }} donated</span>
                    <div class="d-flex align-items-center w-100 align-items-center" *ngIf="charge.amount.charity > 0">
                      <div class="text-secondary text-smaller flex-grow-1 me-2">Event charity support: </div>
                      <div class="flex-grow-0">{{ charge.amountString.charity }} {{ charge.currency }}</div>
                    </div>
                    <div class="d-flex align-items-center w-100 align-items-center" *ngIf="charge.amount.donation > 0">
                      <div class="text-secondary text-smaller flex-grow-1 me-2">Platform support: </div>
                      <div class="flex-grow-0">{{ charge.amountString.donation }} {{ charge.currency }}</div>
                    </div>
                  </div>
                </ng-template>
                <div class="pe-1 py-2 text-secondary" *ngIf="charge.hasDonation" [ngbTooltip]="charityTooltip"
                  container="body">
                  <fa-icon [icon]="donationIcon" [fixedWidth]="true"></fa-icon>
                </div>
              </td>

              <td>
                <ng-template #amountTooltip>
                  <div class="d-flex flex-column algin-items-start text-start">
                    <span class="text-bold mb-1 border-bottom border-secondary">{{ charge.amountString.total }} {{ charge.currency }} in
                      total</span>
                    <div class="d-flex align-items-center w-100 align-items-center">
                      <div class="text-secondary text-smaller flex-grow-1 me-2">Registration fee: </div>
                      <div class="flex-grow-0"
                        [ngClass]="{ 'text-red text-striked': charge.isRefunded || charge.isPartiallyRefunded }">{{
                        charge.amountString.registrationFee }} {{ charge.currency }}</div>
                    </div>
                    <div class="d-flex align-items-center w-100 align-items-center" *ngIf="charge.amount.charity > 0">
                      <div class="text-secondary text-smaller flex-grow-1 me-2">Event charity support: </div>
                      <div class="flex-grow-0">{{ charge.amountString.charity }} {{ charge.currency }}</div>
                    </div>
                    <div class="d-flex align-items-center w-100 align-items-center" *ngIf="charge.amount.donation > 0">
                      <div class="text-secondary text-smaller flex-grow-1 me-2">Platform support: </div>
                      <div class="flex-grow-0">{{ charge.amountString.donation }} {{ charge.currency }}</div>
                    </div>
                  </div>
                </ng-template>
                <div class="pe-1 py-2 text-end" [ngbTooltip]="amountTooltip" container="body"
                  [ngClass]="{ 'text-red text-striked': charge.isRefunded, 'text-warning': charge.isPartiallyRefunded }">
                  {{ charge.amountString.total }} {{ charge.currency }}
                </div>
              </td>

              <td>
                <ng-template #feeTooltip>
                  <div class="d-flex flex-column algin-items-start text-start">
                    <span class="text-bold mb-1 border-bottom border-secondary">{{ charge.amountString.newFee }} {{ charge.currency }} in
                      total</span>
                    <div class="d-flex align-items-center w-100 align-items-center">
                      <div class="text-secondary text-smaller flex-grow-1 me-2">Registration fee: </div>
                      <div class="flex-grow-0"
                        [ngClass]="{ 'text-red text-striked': charge.isRefunded || charge.isPartiallyRefunded }">{{
                        charge.amountString.registrationFeeCost }} {{ charge.currency }}</div>
                    </div>
                    <div class="d-flex align-items-center w-100 align-items-center" *ngIf="charge.amount.charity > 0">
                      <div class="text-secondary text-smaller flex-grow-1 me-2">Event charity support: </div>
                      <div class="flex-grow-0">{{ charge.amountString.charityCost }} {{ charge.currency }}</div>
                    </div>
                    <div class="d-flex align-items-center w-100 align-items-center" *ngIf="charge.amount.donation > 0">
                      <div class="text-secondary text-smaller flex-grow-1 me-2">Platform support: </div>
                      <div class="flex-grow-0">0.00 {{ charge.currency }}</div>
                    </div>
                  </div>
                </ng-template>
                <div class="pe-1 py-2 text-end" [ngbTooltip]="feeTooltip" container="body"
                  [ngClass]="{ 'text-red text-striked': charge.isRefunded || charge.isPartiallyRefunded }"
                  *ngIf="charge.paymentType === 'TOLARIA'">
                  {{ charge.amountString.fee }} {{ charge.currency }}
                </div>
              </td>

              <td>
                <ng-template #transferTooltip>
                  <div class="d-flex flex-column algin-items-start text-start">
                    <span class="text-bold mb-1 border-bottom border-secondary">{{ charge.amountString.newTransferred }} {{
                      charge.currency }} in total</span>
                    <div class="d-flex align-items-center w-100 align-items-center">
                      <div class="text-secondary text-smaller flex-grow-1 me-2">Registration fee: </div>
                      <div class="flex-grow-0"
                        [ngClass]="{ 'text-red text-striked': charge.isRefunded || charge.isPartiallyRefunded }">{{
                        charge.amountString.registrationFeeTransfer }} {{ charge.currency }}</div>
                    </div>
                    <div class="d-flex align-items-center w-100 align-items-center" *ngIf="charge.amount.charity > 0">
                      <div class="text-secondary text-smaller flex-grow-1 me-2">Event charity support: </div>
                      <div class="flex-grow-0">{{ charge.amountString.charityTransfer }} {{ charge.currency }}</div>
                    </div>
                    <div class="d-flex align-items-center w-100 align-items-center" *ngIf="charge.amount.charity > 0">
                      <div class="text-secondary text-smaller flex-grow-1 me-2">Platform support: </div>
                      <div class="flex-grow-0">0.00 {{ charge.currency }}</div>
                    </div>
                  </div>
                </ng-template>
                <div class="pe-1 py-2 text-end" [ngbTooltip]="transferTooltip" container="body"
                  [ngClass]="{ 'text-red text-striked': charge.isRefunded || charge.isPartiallyRefunded }"
                  *ngIf="charge.paymentType === 'TOLARIA'">
                  {{ charge.amountString.transferred }} {{ charge.currency }}
                </div>
              </td>

            </tr>
          </tbody>
        </table>

        <div class="d-flex pt-3 px-5 mt-3 border-top">
          <div class="flex-grow-1"></div>
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center py-1">
              <div class="text-secondary" [style.minWidth.rem]="12">Total registration fees</div>
              <div class="text-large text-end flex-grow-1">{{ data.total.registrationFees }} {{ data.currency }}</div>
            </div>
            <div class="d-flex align-items-center py-1" *ngIf="charityExtraEnabled">
              <div class="text-secondary" [style.minWidth.rem]="12">Total charity</div>
              <div class="text-large text-end flex-grow-1">{{ data.total.charity }} {{ data.currency }}</div>
            </div>
            <div class="d-flex align-items-center py-1">
              <div class="text-secondary" [style.minWidth.rem]="12">Total refunded</div>
              <div class="text-large text-end flex-grow-1">{{ data.total.refunded }} {{ data.currency }}</div>
            </div>
            <div class="d-flex align-items-center py-1">
              <div class="text-secondary" [style.minWidth.rem]="12">Total fees</div>
              <div class="text-large text-end flex-grow-1">{{ data.total.fee }} {{ data.currency }}</div>
            </div>
            <div class="d-flex align-items-center py-1">
              <div class="text-secondary" [style.minWidth.rem]="12">Net (payout)</div>
              <div class="text-large text-end flex-grow-1">{{ data.total.net }} {{ data.currency }}</div>
            </div>

            <div class="d-flex align-items-center py-1 mt-2">
              <div class="text-secondary" [style.minWidth.rem]="12">Platform donations</div>
              <div class="text-end flex-grow-1 text-secondary">{{ data.total.donation }} {{ data.currency }}</div>
            </div>
          </div>
        </div>

      </ng-container>

      <ng-template #noCharges>
        <div class="p-3 text-secondary">There are no payment history</div>
      </ng-template>

    </ng-container>


  </div>


  <ng-container *ngIf="data$ | async as data">
    <div class="modal-footer d-flex align-items-center">

      <ng-container *ngIf="isEventCreator || isAdmin">

        <ng-container *ngIf="payoutDone; else payoutForm">
          <div class="text-secondary d-flex align-items-center">
            <span>Payout of {{ payoutAmountText }} {{ payoutCurrency }} performed</span>
            <span class="mx-1">@</span>
            <app-timestamp-clock [timestamp]="payoutTimestamp" [seconds]="true"></app-timestamp-clock>
            <div class="ms-3 badge text-bg-sm {{ payoutStatus.class }}">
              <span [innerHtml]="payoutStatus.text" class="me-1"></span>
              <fa-icon [icon]="payoutStatus.icon" [fixedWidth]="true"></fa-icon>
            </div>
          </div>
        </ng-container>

        <ng-template #payoutForm>
          <button class="btn btn-sm {{ disablePayout ? 'btn-secondary' : 'btn-main' }} me-2" [disabled]="disablePayout"
            (click)="requestPayout()">
            Request Payout
          </button>
          <ng-container *ngIf="!requestingPayout; else requestingPayoutProgress">
            <ng-container *ngIf="balanceCoversPayout; else insufficientFunds">
              <div class="custom-control custom-checkbox text-start"
                ngbTooltip="Requesting payouts can only be done once per event, and after the payout has been created, refunds on Tolaria payments will not be possible"
                container="body">
                <input type="checkbox" class="form-check-input" id="payoutConfirmation"
                  [(ngModel)]="payoutConfirmation">
                <label class="form-check-label text-secondary" for="payoutConfirmation">
                  Confirm payout request
                  <fa-icon [icon]="roundInfoIcon" [fixedWidth]="true" class="ms-1"></fa-icon>
                </label>
              </div>
              <span class="ms-2 border-left ps-3 text-secondary" [innerHtml]="currentBalance"></span>
            </ng-container>
            <ng-template #insufficientFunds>
              <div class="d-flex border-left ms-2 ps-3 text-warning align-items-center">
                <fa-icon [icon]="warningIcon" [fixedWidth]="true" class="me-1"></fa-icon>
                You have insufficient funds to cover the payout for this event, please check you balance for more details.
              </div>
            </ng-template>
          </ng-container>
          <ng-template #requestingPayoutProgress>
            <div class="text-secondary d-flex align-items-center">
              <app-busy-spinner class="me-2"></app-busy-spinner>
              <span>Payout request in progress, please wait</span>
            </div>
          </ng-template>
        </ng-template>

      </ng-container>

      <div class="flex-grow-1"></div>
      <div class="btn btn-sm btn-blank" (click)="close()">Close</div>

    </div>
  </ng-container>

</ng-container>


<ng-template #loading>
  <div class="d-flex align-items-center justify-content-center p-5">
    <app-busy-spinner></app-busy-spinner>
  </div>
</ng-template>

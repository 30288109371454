<ng-container *ngIf="matches$ | async as matches">

  <div class="h4 text-center">MATCHES</div>
  <!-- <ng-container *ngFor="let match of (matches$ | async)"> -->
  <ng-container *ngIf="matches | matchType : 'swiss' as swissMatches">
    <div class="text-large text-secondary mt-2" *ngIf="swissMatches.length > 0">Swiss Matches</div>
    <ng-container *ngFor="let match of swissMatches">
      <div class="match event-match-list pt-2 pb-2" *ngIf="match.player1.playerDocId === currentValPlayerDocId">
        Round {{ match.roundNumber }} vs {{ match.player2.displayName }}
        <div class="float-end result-wrapper {{ match.player1.isWinner ? 'winner' : 'loser' }}">{{ match.player1.wins }} - {{ match.player1.draws }} - {{ match.player1.losses }}</div>
      </div>
      <div class="match event-match-list pt-2 pb-2" *ngIf="match.player2.playerDocId === currentValPlayerDocId">
        Round {{ match.roundNumber }} vs {{ match.player1.displayName }}
        <div class="float-end result-wrapper {{ match.player2.isWinner ? 'winner' : 'loser' }}">{{ match.player2.wins }} - {{ match.player2.draws }} - {{ match.player2.losses }}</div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="matches | matchType : 'batch' as batchMatches">
    <div class="text-large text-secondary mt-4" *ngIf="batchMatches.length > 0">Batch Matches</div>
    <ng-container *ngFor="let match of batchMatches">
      <div class="match event-match-list pt-2 pb-2" *ngIf="match.player1.playerDocId === currentValPlayerDocId">
        Round {{ match.roundNumber }} : Segment {{ match.segmentNumber }} vs {{ match.player2.displayName }}
        <div class="float-end result-wrapper {{ match.player1.isWinner ? 'winner' : 'loser' }}">{{ match.player1.wins }} - {{ match.player1.draws }} - {{ match.player1.losses }}</div>
      </div>
      <div class="match event-match-list pt-2 pb-2" *ngIf="match.player2.playerDocId === currentValPlayerDocId">
        Round {{ match.roundNumber }} : Segment {{ match.segmentNumber }}  vs {{ match.player1.displayName }}
        <div class="float-end result-wrapper {{ match.player2.isWinner ? 'winner' : 'loser' }}">{{ match.player2.wins }} - {{ match.player2.draws }} - {{ match.player2.losses }}</div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="matches | matchType : 'round-robin' as roundRobinMatches">
    <div class="text-large text-secondary mt-2" *ngIf="roundRobinMatches.length > 0">Round Robin Matches</div>
    <ng-container *ngFor="let match of roundRobinMatches">
      <div class="match event-match-list pt-2 pb-2" *ngIf="match.player1.playerDocId === currentValPlayerDocId">
        Round {{ match.roundNumber }} vs {{ match.player2.displayName }}
        <div class="float-end result-wrapper {{ match.player1.isWinner ? 'winner' : 'loser' }}">{{ match.player1.wins }} - {{ match.player1.draws }} - {{ match.player1.losses }}</div>
      </div>
      <div class="match event-match-list pt-2 pb-2" *ngIf="match.player2.playerDocId === currentValPlayerDocId">
        Round {{ match.roundNumber }} vs {{ match.player1.displayName }}
        <div class="float-end result-wrapper {{ match.player2.isWinner ? 'winner' : 'loser' }}">{{ match.player2.wins }} - {{ match.player2.draws }} - {{ match.player2.losses }}</div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="matches | matchType : 'group' as groupMatches">
    <div class="text-large text-secondary mt-4" *ngIf="groupMatches.length > 0">Group Matches</div>
    <ng-container *ngFor="let match of groupMatches">
      <div class="match event-match-list pt-2 pb-2" *ngIf="match.player1.playerDocId === currentValPlayerDocId">
        {{ match.groupName }} vs {{ match.player2.displayName }}
        <div class="float-end result-wrapper {{ match.player1.isWinner ? 'winner' : 'loser' }}">{{ match.player1.wins }} - {{ match.player1.draws }} - {{ match.player1.losses }}</div>
      </div>
      <div class="match event-match-list pt-2 pb-2" *ngIf="match.player2.playerDocId === currentValPlayerDocId">
        {{ match.groupName }} vs {{ match.player1.displayName }}
        <div class="float-end result-wrapper {{ match.player2.isWinner ? 'winner' : 'loser' }}">{{ match.player2.wins }} - {{ match.player2.draws }} - {{ match.player2.losses }}</div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="matches | matchType : 'bracket' as bracketMatches">
    <div class="text-large text-secondary mt-4" *ngIf="bracketMatches.length > 0">Bracket Matches</div>
    <ng-container *ngFor="let match of bracketMatches">
      <div class="match event-match-list pt-2 pb-2" *ngIf="match.player1.playerDocId === currentValPlayerDocId">
        Round {{ match.roundNumber }} vs {{ match.player2.displayName }}
        <div class="float-end result-wrapper {{ match.player1.isWinner ? 'winner' : 'loser' }}">{{ match.player1.wins }} - {{ match.player1.draws }} - {{ match.player1.losses }}</div>
      </div>
      <div class="match event-match-list pt-2 pb-2" *ngIf="match.player2.playerDocId === currentValPlayerDocId">
        Round {{ match.roundNumber }} vs {{ match.player1.displayName }}
        <div class="float-end result-wrapper {{ match.player2.isWinner ? 'winner' : 'loser' }}">{{ match.player2.wins }} - {{ match.player2.draws }} - {{ match.player2.losses }}</div>
      </div>
    </ng-container>
  </ng-container>

</ng-container>



import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faMinus, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbCarouselModule, NgbSlideEvent, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { tiCloudDownload } from 'src/assets/font-icons/tolaria-cloud-download';
import { PlayerAvatarComponent } from '../../players/player-avatar/player-avatar.component';

@Component({
  selector: 'app-image-previewer',
  templateUrl: './image-previewer.component.html',
  styleUrls: ['./image-previewer.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    NgbTooltipModule,
    NgbCarouselModule,
    FontAwesomeModule,
    // forwardRef(() => PlayerAvatarComponent),
    PlayerAvatarComponent,
    FormsModule,
  ]
})
export class ImagePreviewerComponent implements OnInit, OnDestroy {

  @Input() selectedIndex: string = 'slide-0'
  @Input() imageUris: string[]
  @Input() playerDocId: string

  @HostListener('mousemove', ['$event'])
    onMove(e: any) {
      if (this.movement.mouseDown) {
        if (Math.abs(e.clientX - this.movement.startX) > 10 || Math.abs(e.clientY - this.movement.startY) > 10) {
          this.mouseMoved = true
        }
        if (
          (e.target.clientHeight * (this.imageScale / 100)) > e.target.parentElement.clientHeight ||
          (e.target.clientWidth * (this.imageScale / 100)) > e.target.parentElement.clientWidth
        ) {
          this.translateX = e.clientX - this.movement.startX
          this.translateY = e.clientY - this.movement.startY
        }
      }
    }
  @HostListener('mouseup', ['$event'])
    onMouseUp(e: any) {
      this.resetMovement()
      setTimeout(() => this.mouseMoved = false, 10)
    }
  @HostListener('mouseleave', ['$event'])
    onMouseLeave(e: any) {
      this.resetMovement()
      this.mouseMoved = false
    }

  public onMouseDown(e: any): void {
    this.movement = {
      mouseDown: true,
      startX: e.clientX - this.translateX,
      startY: e.clientY - this.translateY,
      initialX: e.target.offsetLeft,
      initialY: e.target.offsetTop,
    }
  }
  private resetMovement(): void {
    this.movement = {
      mouseDown: false,
      startX: null,
      startY: null,
      initialX: null,
      initialY: null,
    }
  }

  public showPlayerAvatar: boolean = false
  public showNavigation: boolean = true
  public backgroundImage: string = ''
  public imageScale$ = new BehaviorSubject<number>(100)
  private imageScale = 100
  private currentSlideIndex = 0
  private translateX = 0
  private translateY = 0
  private mouseMoved = false
  private movement = {
    mouseDown: false,
    startX: null,
    startY: null,
    initialX: null,
    initialY: null,
  }
  private destroyed$ = new Subject<boolean>()

  constructor(
    private readonly modalService: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    if (this.imageUris.length === 1) {
      this.showNavigation = false;
    }
    if (this.playerDocId !== undefined && this.playerDocId !== '') {
      this.showPlayerAvatar = true
    }
    let index = parseInt(this.selectedIndex.substring(6))
    this.backgroundImage = this.imageUris[index]
    this.currentSlideIndex = parseInt(this.selectedIndex.substring(6))
    this.imageScale$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(value => {
      if (value === 100) {
        this.translateX = 0
        this.translateY = 0
      }
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public get icon() {
    return {
      close: faTimes,
      plus: faPlus,
      minus: faMinus,
      download: tiCloudDownload,
    }
  }

  public get imageSize(): number {
    return this.imageScale / 2
  }

  public async onDownloadImage() {
    const a = document.createElement('a')
    a.href = await this.toDataURL(this.imageUris[this.currentSlideIndex])
    a.download = 'image.png'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  private async toDataURL(url: string) {
    const blob = await fetch(url).then(res => res.blob())
    return URL.createObjectURL(blob)
  }

  public dismiss(): void {
    this.modalService.dismiss()
  }

  public onScaleChanged(value: number): void {
    this.imageScale = value
    this.imageScale$.next(value)
  }

  public onImagePress(): void {
    if (!this.mouseMoved) {
      if (this.imageScale === 100) {
        this.imageScale = 200
      }
      else {
        this.imageScale = 100
      }
      this.imageScale$.next(this.imageScale)
    }
  }

  public onBeforeSlideChange(event: NgbSlideEvent) {
    const nextIndex = parseInt(event.current.substring(6))
    this.currentSlideIndex = parseInt(event.current.substring(6))
    this.backgroundImage = this.imageUris[nextIndex]
    this.resetMovement()
    this.imageScale = 100
    this.imageScale$.next(100)
    this.translateX = 0
    this.translateY = 0
  }

  public updateScale(direction: 'neg' | 'pos'): void {
    
    if (direction === 'pos') {
      if (this.imageScale >= 150) {
        this.imageScale = 200
        this.imageScale$.next(200)
        return
      }
      if (this.imageScale >= 100) {
        this.imageScale = 150
        this.imageScale$.next(150)
        return
      }
    }

    if (direction === 'neg') {
      if (this.imageScale <= 150) {
        this.imageScale = 100
        this.imageScale$.next(100)
        return
      }
      if (this.imageScale <= 200) {
        this.imageScale = 150
        this.imageScale$.next(150)
        return
      }
    }

  }

  public get imageStyle(): string {
    return `transform: translate3d(${this.translateX}px, ${this.translateY}px, 0px) rotate(0deg) scale(${this.imageScale / 100}, ${this.imageScale / 100});`
  }

  public get imageScaleText(): string {
    return this.imageScale + '%'
  }

  public get styleClass(): string {
    return this.imageScale > 100
      ? this.movement.mouseDown
        ? 'mouse-down zoom-out'
        : 'zoom-out'
      : this.movement.mouseDown
        ? 'mouse-down zoom-in'
        : 'zoom-in'
  }

}

<div class="message-wrapper d-flex gap-3 px-3 p-2 position-relative scryfall-bot">
  <div class="user-icon">
    <div class="icon-wrapper">
      <fa-icon [fixedWidth]="true" [icon]="faRobot"></fa-icon>
    </div>
  </div>
  <div class="message">
    <div class="header">
      <div class="scryfall-card-name">{{ messageDoc.message.content.cardName }} — Rules</div>
      <div class="message-timestamp text-secondary" *ngIf="auth.user.settings.clock24">{{ getDate(messageDoc.message.timestamp) | date: 'HH:mm' }}</div>
      <div class="message-timestamp text-secondary" *ngIf="!auth.user.settings.clock24">{{ getDate(messageDoc.message.timestamp) | date: 'HH:mm' }}</div>
      <div class="scryfall-set-name text-secondary">{{ messageDoc.message.content.setName }}</div>
    </div>
    <div class="message-content rulings">
      <div class="rulings" *ngFor="let ruling of messageDoc.message.content.rulings.data">
        <div class="ruling-wrapper">
          <div class="ruling-date">{{ ruling.published_at }}</div>
        <div class="ruling-comment" [innerHTML]="ruling.comment | manaParser : { fontClass: 'text-smaller' }"></div>
      </div>
    </div>
  </div>
</div>

import { faCalendarAlt, faCheck, faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs'
import { tap, take, takeUntil } from 'rxjs/operators'
import { AuthService, EventService, GlobalsService } from 'src/app/services'
import { MatchService } from 'src/app/services/match.service'
import { IEventDetails, IMatchAppointment } from 'tolaria-cloud-functions/src/_interfaces'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { CommonModule } from '@angular/common'
import { PlayerDisplayNameComponent } from 'src/app/components'
import { TimestampClockComponent } from 'src/app/components/app-structure/timestamp-clock/timestamp-clock.component'
import { MessageItem } from '../../../services/message-list.service'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { filter, map } from 'lodash'

@Component({
  selector: 'app-match-appointment',
  templateUrl: './match-appointment.component.html',
  styleUrls: ['./match-appointment.component.css'],
  standalone: true,
  imports: [
    FontAwesomeModule,
    CommonModule,
    TimestampClockComponent,
    PlayerDisplayNameComponent,
    NgbModule,
  ]
})

export class MatchAppointmentComponent implements OnInit, OnDestroy {
  @Input() msg: MessageItem

  public matchAppointment$ = new BehaviorSubject<IMatchAppointment>(null)
  private _appointmentObserver: Observable<IMatchAppointment>
  private _eventObserver: Observable<IEventDetails>
  private _subscription: Subscription
  private destroyed$ = new Subject<boolean>()

  constructor(
    private matchService: MatchService,
    private auth: AuthService,
    private globals: GlobalsService,
    private readonly firestore: AngularFirestore,
  ) { }

  ngOnInit(): void {

    this._appointmentObserver = this.firestore
      .collection('matchAppointments')
      .doc<IMatchAppointment>(this.msg.message.content.appointmentDocId)
      .valueChanges()
      .pipe(
        takeUntil(this.destroyed$),
        tap((appointment) => {
          // player is the opponent and the appointment is read for the first time, mark it as read
          if (!appointment.opponentHasRead && appointment.opponentDocId === this.auth.user.playerId) {
            this.matchService.updateMatchAppointmentAsRead(this.msg.message.content.appointmentDocId)
          }
        })
      )

    this._subscription = this._appointmentObserver.subscribe((appointment) => {
        if (appointment.eventDocId !== null && appointment.eventDocId !== 'casual-match') {
          this._eventObserver = this.firestore.collection('events').doc<IEventDetails>(appointment.eventDocId).valueChanges()
          this.appendEvent()
        }
        else {
          appointment.eventname = 'Casual Match'
        }
        this.matchAppointment$.next(appointment)
      })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  private appendEvent() {
    if (this._subscription) {
      this._subscription.unsubscribe()
    }
    this._subscription = combineLatest([this._appointmentObserver, this._eventObserver])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([appointment, event]) => {
        if (appointment !== null && event !== null && appointment !== undefined && event !== undefined) {
          appointment.eventname = event.details.name
          this.matchAppointment$.next(appointment)
        }
      })
  }

  public get isSender() {
    return this.msg.playerDocId === this.auth.user.playerId
  }

  updateAppointmentStatus(status: string): void {
    this.matchService.updateMatchAppointmentStatus(this.msg.message.content.appointmentDocId, status)
  }

  openMatchRoom(appointment: IMatchAppointment): void {
    this.matchService.getMatchDocById(appointment.matchDocId).pipe(take(1)).subscribe(matchDoc => {
      this.matchService.openMatchRoom(matchDoc)
    })
  }

  public get mobileDevice(): string {
    return this.globals.isMobile ? 'mobile' : 'desktop'
  }

  public get mobile(): boolean {
    return this.globals.isMobile
  }

  public get userPlayerId(): string {
    return this.auth.user.playerId
  }

  public get icon() {
    return {
      calendar: faCalendarAlt,
      decline: faTimes,
      check: faCheck,
      link: faExternalLinkAlt,
    }
  }

}

<ng-container *ngIf="eventDoc$ | async as eventDoc">

  <div class="text-normal mb-2">
    Add one or more Tolaria player as your co-organizer. The players added here will be able to act as an
    organizer for this event.
  </div>
  <div class="global-search-wrapper">

    <div class="input-group input-group-sm">

      <app-tolaria-player-search class="w-100" (playerDocId)="onTolariaPlayerSubmit($event, eventDoc.docId)" [clearOnSelect]="true"></app-tolaria-player-search>

    </div>

  </div>


  <div class="co-organizer-wrapper mt-3" *ngIf="eventDoc?.coOrganizers && eventDoc.coOrganizers.length > 0">

    <div class="list-item" *ngFor="let organizer of eventDoc.coOrganizers">

      <app-player-display-name position="right" [playerUid]="organizer"></app-player-display-name>

      <span class="trash-icon float-end" (click)="removeCoOrganizer(eventDoc.docId, organizer)"
        placement="top" container="body" ngbTooltip="Remove player from Co-Organizer list">
        <fa-icon [fixedWidth]="true" [icon]="faTrash"></fa-icon>
      </span>

    </div>

  </div>

</ng-container>

import { takeUntil } from 'rxjs/operators';
import { PaymentService } from './../payment.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { faCheck, faCheckCircle, faInfoCircle, faTimes, faSync } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { IUser, IPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { faStripe } from '@fortawesome/free-brands-svg-icons';
import { PlayerNameService } from 'src/app/services/players/player-name.service';

@Component({
  selector: 'app-stripe-connected-account',
  templateUrl: './stripe-connected-account.component.html',
  styleUrls: ['./stripe-connected-account.component.css']
})
export class StripeConnectedAccountComponent implements OnInit, OnDestroy {

  @Input() action: string = null
  @Output() updateSettings = new EventEmitter

  public taskOpen = faCircle
  public taskDone = faCheckCircle
  public enabled = faCheck
  public disabled = faTimes
  public iconInfo = faInfoCircle
  public iconStripe = faStripe
  public iconSync = faSync

  public showInfo = false

  public user$: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null)
  public playerDoc$: BehaviorSubject<IPlayerDetails> = new BehaviorSubject<IPlayerDetails>(null)
  public balance$: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()

  public isBusy = {
    accountCreate: false,
    accountOnboarding: false,
    fetchingAccountLoginLinks: false,
    accountOnboardingRedirecting: false,
    fetchingBalance: false,
  }

  constructor(
    private auth: AuthService,
    private playerNames: PlayerNameService,
    private afs: AngularFirestore,
    private payment: PaymentService,
  ) {

    this.auth.user$.pipe(takeUntil(this.componentDestroyed$)).subscribe(user => this.user$.next(user))

    combineLatest([this.user$, this.playerNames.serviceReady$])
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(([user, ready]) => {
        if (user !== null && ready === true) {
          this.playerDoc$.next(this.playerNames.currentUserPlayerDoc$.getValue())
          // fetch the users balance
          this.getBalance()
          // show info if user does not have an stripe account
          if (user.stripe && user.stripe.id) this.showInfo = false
          else this.showInfo = true
        }
      })
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  ngOnInit(): void {
    if (this.action !== null) {
      switch (this.action) {

        case 'stripeAccountLinksRefresh':
          this.onboardAccount()
          break

      }
    }
  }

  public updateCountry(): void {
    this.updateSettings.emit('you');
  }

  public verifyCountry(): void {
    this.afs.collection('users').doc(this.auth.user.uid).update({
      'stripe.country_verified_by_user': true
    })
  }

  public createAccount(): void {
    this.isBusy.accountCreate = true
    this.payment.createConnectedAccount()
      .then(() => this.isBusy.accountCreate = false)
      .catch(() => this.isBusy.accountCreate = false)
  }

  public onboardAccount(): void {
    this.isBusy.accountOnboarding = true
    this.payment.getAccountLinks()
      .then((res) => {
        this.isBusy.accountOnboarding = false
        this.continueOnboarding(res.response.accountLinks.url)
      })
      .catch(() => this.isBusy.accountOnboarding = false)
  }

  public loginToStripe(): void {
    this.isBusy.fetchingAccountLoginLinks = true
    this.payment.connectedDashboardLogin()
      .then(() => this.isBusy.fetchingAccountLoginLinks = false)
      .catch(() => this.isBusy.fetchingAccountLoginLinks = false)
  }

  public continueOnboarding(url: string = null): void {
    this.isBusy.accountOnboardingRedirecting = true
    window.location.replace(url === null ? this.user$.getValue().stripe.account_links.url : url)
  }

  public getBalance(): void {
    this.isBusy.fetchingBalance = true
    this.payment.getBalance()
      .then((balance) => {
        if (balance) this.balance$.next(balance)
        this.isBusy.fetchingBalance = false
      })
      .catch(() => {
        this.isBusy.fetchingBalance = false
      })
  }

  public get disableCreateAccount(): boolean {
    if (this.isBusy.accountCreate) return true
    const user = this.user$.getValue()
    if (
      user !== null &&
      user.stripe !== undefined &&
      user.stripe.country_verified_by_user !== null &&
      user.stripe.country_verified_by_user !== undefined &&
      user.stripe.id === null ||
      user !== null &&
      user.stripe !== undefined &&
      user.stripe.country_verified_by_user !== null &&
      user.stripe.country_verified_by_user !== undefined &&
      user.stripe.id === undefined
    ) return false

    return true
  }

  public get disableStartOnboarding(): boolean {
    if (this.isBusy.accountOnboarding) return true
    const user = this.user$.getValue()
    if (
      user !== null &&
      user.stripe !== undefined &&
      user.stripe.country_verified_by_user !== null &&
      user.stripe.country_verified_by_user !== undefined &&
      user.stripe.id !== null &&
      user.stripe.id !== undefined &&
      !this.accountOnboarded
    ) return false

    return true
  }

  public get accountLinksPresent(): boolean {
    return this.user$.getValue().stripe && this.user$.getValue().stripe.account_links
      ? this.user$.getValue().stripe.account_links.url
      : false
  }

  public get accountOnboarded(): boolean {
    const user = this.user$.getValue()
    if (user !== null && user.stripe !== undefined && user.stripe.charges_enabled && user.stripe.payouts_enabled) return true
    return false
  }

  public get countryVerifiedByUser(): boolean {
    const user = this.user$.getValue()
    if (user !== null && user.stripe !== undefined && user.stripe.country_verified_by_user) return true
    return false
  }

  public get hasStripeId(): boolean {
    const user = this.user$.getValue()
    if (user !== null && user.stripe !== undefined && user.stripe.id) return true
    return false
  }

  public get defaultCurrency(): string {
    const user = this.user$.getValue()
    if (user !== null && user.stripe !== undefined && user.stripe.default_currency !== null) return user.stripe.default_currency.toUpperCase()
    return ''
  }

  public get capabilities(): any {
    const user = this.user$.getValue()
    if (user !== null && user.stripe !== undefined && user.stripe.capabilities !== undefined) {
      const capabilities = []
      Object.keys(user.stripe.capabilities).forEach(c => {
        capabilities.push({
          text: c,
          value: user.stripe.capabilities[c]
        })
      })
      return capabilities
    }

    return []
  }

}

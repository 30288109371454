<div class="flex-grow-1 d-flex flex-wrap scrollable align-content-start">

    <ng-container *ngIf="createdCards$ | async as cards">

        <ng-container *ngFor="let card of cards | createdCardsFilter : searchString; trackBy: tracker">

            <div class="card-wrapper position-relative m-2" [ngClass]="{ 'selected' : card.docId === selectedCard }">
                <img [src]="card.generatedPic">

                <div class="overlay">

                </div>
                <div class="more-actions d-flex align-items-center">
                    <div class="btn btn-sm btn-light me-2" ngbTooltip="Number of comments" container="body">
                        <fa-icon [icon]="icons.comment" class="me-2"></fa-icon>{{ card.comments }}
                    </div>
                    <div class="btn btn-sm btn-light me-2" (click)="likeCard(card.docId)" ngbTooltip="Number of likes"
                        container="body">
                        <fa-icon [icon]="icons.like" class="me-2"></fa-icon>{{ card.likedByPlayerDocId.length }}
                    </div>
                    <div class="btn btn-sm btn-light me-2" (click)="dislikeCard(card.docId)"
                        ngbTooltip="Number of dislikes" container="body">
                        <fa-icon [icon]="icons.dislike" class="me-2"></fa-icon>{{ card.dislikedByPlayerDocId.length }}
                    </div>
                    <div class="flex-grow-1"></div>
                    <div class="btn btn-sm btn-light" [routerLink]="['preview', card.docId]"
                        ngbTooltip="Open card details and show comments" container="body">Details</div>
                </div>
            </div>

        </ng-container>

    </ng-container>

</div>

<div class="previewer">

    <router-outlet></router-outlet>

</div>
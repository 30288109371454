<div class="modal-body d-flex flex-column rounded-top">

  <div class="image-icon d-flex align-items-center mb-3" *ngIf="imageAsIcon">
    <img class="rounded overflow-hidden" [src]="imageUrl">
  </div>

  <div class="text-larger d-flex align-items-center">

    <fa-icon class="text-{{ type }}"
      [icon]="icon"
      [style.minWidth.rem]="2.5"
      *ngIf="showIcon">
    </fa-icon>


    <div class="flex-grow-1" [innerHTML]="title"></div>

  </div>


  <div class="message-wrapper d-flex align-items-center">
    <div [style.minWidth.rem]="2.5" *ngIf="showIcon"></div>
    <div class="flex-grow-1" [innerHTML]="message"></div>
  </div>

  <div class="confirm-input-wrapper mt-3" *ngIf="confirmInput">
    <input type="text"
      [placeholder]="confirmTextPlaceholder"
      class="form-control form-control-sm"
      [(ngModel)]="confirmationTextInput">
  </div>

</div>


<div class="modal-footer d-flex align-items-center {{ centerButtons ? 'justify-content-center' : 'justify-content-end' }}">

  <button *ngFor="let btn of buttons"
    class="btn btn-sm {{ btn.class ? btn.class : 'btn-blank' }} focus-ring focus-ring-primary"
    [disabled]="btn.type === 'close' && confirmInput && confirmText !== confirmationTextInput"
    (click)="onButtonClick(btn)">

    <fa-icon [icon]="btn.icon" *ngIf="btn.icon !== undefined" class="me-2"></fa-icon>
    {{ btn.text }}

  </button>

</div>

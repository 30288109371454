import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IconDefinition, faDesktop, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faClock } from '@fortawesome/free-regular-svg-icons';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { IEventDetails, IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TournamentDescriptionModalComponent } from '../modals/tournament-description-modal.component';
import { UserService } from '../../../_shared/services/user.service';

interface IEventListingIcons {
  [key:string]: IconDefinition
}

interface IEventList {
  [key:string]: IEventTemplate
}

export interface IEventTemplate extends IEventDetails {
  isAttending: boolean
  isInvited: boolean
  isOrganizing: boolean
  isAdmin: boolean
  isCoOrganizer: boolean
  isMultiday: boolean
  isHosting: boolean
  isWaiting: boolean
  listedForUser: boolean
  timespan: {
    start: Date
    end: Date
  }
}

export interface IEventFilter {
  showPanel: boolean
  search: string
  organizer: {
    available: IPlayerMini[]
    selected: IPlayerMini[]
  }
  formats: {
    available: string[]
    selected: string[]
  }
  show: ShowFilter[]
  type: {
    inPerson: boolean
    online: boolean
  }
  // 'in-person' | 'online' | 'both'
  length: {
    singleDay: boolean
    multiDay: boolean
  }
  // 'single-day' | 'multi-day' | 'both'
  date: {
    from: number,
    to: number,
  }
}

interface ShowFilter {
  text: string
  selected: boolean
  property: 'isHosting' | 'isAttending' | 'isInvited' | 'isWaiting' | 'all'
}

@Injectable({
  providedIn: 'root'
})
export class EventListingService {


  private _events: IEventList = {}
  private _events$ = new BehaviorSubject<IEventTemplate[]>(null)

  private initialFilter: IEventFilter = {
    showPanel: false,
    search: '',
    organizer: {
      available: [],
      selected: [],
    },
    formats: {
      available: [],
      selected: [],
    },
    type: {
      inPerson: true,
      online: true,
    },
    length: {
      singleDay: true,
      multiDay: true,
    },
    show: [
      {
        text: 'Hosting',
        selected: true,
        property: 'isHosting'
      },
      {
        text: 'Attending',
        selected: true,
        property: 'isAttending'
      },
      {
        text: 'Invited',
        selected: true,
        property: 'isInvited'
      },
      {
        text: 'Waiting',
        selected: true,
        property: 'isWaiting'
      },
      {
        text: 'Show all',
        selected: true,
        property: 'all'
      },
    ],
    date: {
      from: null,
      to: null,
    }
  }

  public filtering: IEventFilter = JSON.parse(JSON.stringify(this.initialFilter))

  public ready$ = new BehaviorSubject<boolean>(false)
  public filters$ = new BehaviorSubject<IEventFilter>(this.initialFilter)
  public loadingEndedEvents: boolean = false
  public endedEventsLoaded: boolean = false
  public loadingItems: number[] = [0,1,2,3,4,5,6,7,8,9]

  constructor(
    private firestore: AngularFirestore,
    private auth: AuthService,
    private playerNames: PlayerNameService,
    private readonly modalService: NgbModal,
    private readonly user: UserService,
  ) {
    this.playerNames.serviceReady$.subscribe(ready => {
      if (ready && this.ready$.getValue() !== true) {
        this.initService()
      }
    })
    this._events$.subscribe(i => {
      console.log('EventListingService:: _events$ emitted', i, this.ready$.getValue())
      if (i !== null && this.ready$.getValue() !== true) {
        this.ready$.next(true)
      }
    })
    this.filters$.subscribe(i => {
      if (i !== null) {
        this.filtering = i
      }
    })
  }

  private initService(): void {
    const collection = this.firestore.collection<IEventDetails>('events', ref => ref.where('statusCode', '!=', 8)).valueChanges()
    collection.subscribe(async (docs) => {
      console.log('EventListingService:: => open event emitted', docs)
      for await (const doc of docs) {
        const mapped = await this._mapEvent(doc)
        this._events[mapped.docId] = mapped
      }
      console.log(`EventListingService:: => mapped ${docs.length} events, all mapped events:`, this._events)
      this._events$.next(this._mapEvents())
      this._updateOrganizers()
      this._updateFormats()
    })

    this.initFiltering()
  }
  
  private initFiltering() {
    this.user.eventFilter$.subscribe(filters => {
      if (filters) {
        this.filters$.next(filters)
      }
    })
  }

  public async loadEndedEvents() {
    this.loadingEndedEvents = true
    const collection = this.firestore.collection<IEventDetails>('events', ref => ref.where('statusCode', '==', 8)).valueChanges()
    collection.subscribe(async (docs) => {
      console.log('EventListingService:: => ended event emitted', docs)
      const endedEvents = []
      for await (const doc of docs) {
        // console.log('EventListingService:: => mapping event', doc)
        const mapped = await this._mapEvent(doc)
        endedEvents.push(mapped)
      }
      endedEvents.forEach(i => this._events[i.docId] = i)
      this._events$.next(this._mapEvents())
      this.loadingEndedEvents = false
      this.endedEventsLoaded = true
    })
  }

  public updateFilters(): void {
    this._onFiltersChanged()
  }

  private timer = null
  private _onFiltersChanged(): void {
    this._updateOrganizers()
    this._updateFormats()
    if (this.timer !== null) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(() => {
      this.firestore
        .collection('eventFilters')
        .doc(this.playerNames.currentPlayersMini.id)
        .set(this.filters$.getValue())
        .then(() => console.log('EventListingService:: event filters saved'))
        .catch((error) => console.error('EventListingService:: error saving filters ->', error))
    }, 10000)
  }

  public onSearchChanged(): void {
    this._onFiltersChanged()
  }

  public filteringSetShow(property: string): void {
    const filtering = this.filters$.getValue()
    filtering.show.forEach(i => i.selected = false)
    filtering.show.find(i => i.property === property).selected = true
    this.filters$.next(filtering)
    this._onFiltersChanged()
  }

  public onFilterPropertyChanged(property: string, value: any): void {
    const filtering = this.filters$.getValue()
    filtering[property] = value
    this.filters$.next(filtering)
    this._onFiltersChanged()
  }

  public onAddOrganizer(organizer: IPlayerMini): void {
    const filtering = this.filters$.getValue()
    if (filtering.organizer.selected.find(i => i.id === organizer.id) === undefined) {
      filtering.organizer.selected.push(organizer)
      this.filters$.next(filtering)
      this._onFiltersChanged()
    }
  }

  public onRemoveOrganizer(organizer: IPlayerMini): void {
    const filtering = this.filters$.getValue()
    filtering.organizer.selected.splice(filtering.organizer.selected.findIndex(i => i.id === organizer.id), 1)
    this.filters$.next(filtering)
    this._onFiltersChanged()
  }

  public onAddFormat(format: string): void {
    const filtering = this.filters$.getValue()
    if (!filtering.formats.selected.includes(format)) {
      filtering.formats.selected.push(format)
      this.filters$.next(filtering)
      this._onFiltersChanged()
    }
  }

  public onRemoveFormat(format: string): void {
    const filtering = this.filters$.getValue()
    filtering.formats.selected.splice(filtering.formats.selected.indexOf(format), 1)
    this.filters$.next(filtering)
    this._onFiltersChanged()
  }

  private _mapEvents(): IEventTemplate[] {
    return Object.entries(this._events).map(i => {
      const event: IEventTemplate = i[1]
      if (!event?.bannerUrl || event.bannerUrl === '') {
        event.bannerUrl = 'assets/banners/' + event.details.format.toLocaleLowerCase().replace(/ /g, '-') + '.default.jpg';
      }
      return event
    })
  }

  public async getEventById(id: string): Promise<IEventTemplate> {
    let event = this._events[id]

    if (event === undefined) {
      const snap = await firstValueFrom(this.firestore.collection('events').doc<IEventDetails>(id).get())
      if (snap.exists) {
        const mappedEvent = await this._mapEvent(snap.data())
        this._events[id] = mappedEvent
        // remap all events to update the data set
        this._mapEvents()
        return mappedEvent
      }
      else {
        return null
      }
    }
    else {
      return event
    }
  }

  public get events$(): BehaviorSubject<IEventTemplate[]> {
    return this._events$
  }

  public get events(): IEventTemplate[] {
    if (this.ready$) {
      return this._mapEvents()
    }
    return []
  }

  public get hosting(): IEventTemplate[] {
    return this.events.filter(i => i.isOrganizing || i.isCoOrganizer)
  }

  public get attendingOrInvited(): IEventTemplate[] {
    return this.events.filter(i => i.isAttending || i.isInvited)
  }

  public get notAttendingOrInvited(): IEventTemplate[] {
    return this.events.filter(i => !i.isAttending && !i.isInvited && !i.isOrganizing && !i.isCoOrganizer)
  }

  private _updateOrganizers(): void {
    const organizerUids = [...new Set(this.events.map(i => i.createdByUid))]
    const minis = organizerUids.map(i => this.playerNames.getPlayerByUid(i))
    const filtering = this.filters$.getValue()
    filtering.organizer.available = minis.sort((a, b) => a.name.display.localeCompare(b.name.display))
    this.filters$.next(filtering)
  }

  private _updateFormats(): void {
    let formats = [...new Set(this.events.map(i => {
      const tmp = []
      tmp.push(i.details.format)
      if (i.details.ruleset.name !== '') {
        tmp.push(i.details.ruleset.name)
      }
      return tmp.join(' - ')
    }))]
    const filtering = this.filters$.getValue()
    filtering.formats.available = formats.sort((a, b) => a.localeCompare(b))
    this.filters$.next(filtering)
  }

  public async _mapEvent(event: IEventDetails) {
    const temp: IEventTemplate = event as IEventTemplate
    temp.isAdmin = this.auth.user.role === 'admin'
    temp.isOrganizing = event.createdByUid === this.auth.user.uid
    temp.isCoOrganizer = event?.coOrganizers && event.coOrganizers.includes(this.auth.user.uid) || event?.coOrganizers && event.coOrganizers.includes(this.auth.user.playerId)
    temp.isAttending = event.playerDocIds.includes(this.auth.user.playerId)
    temp.isMultiday = event.details.isMultiDay
    temp.isHosting = temp.isOrganizing || temp.isCoOrganizer
    temp.isWaiting = event.waitingList !== undefined
      ? event.waitingList.find(i => i.playerDocId === this.auth.user.playerId) !== undefined
      : false
    temp.listedForUser = true

    // add date objects
    let start = new Date(event.details.datetimeFrom)
    let end = new Date(event.details.datetimeTo)
    temp.timespan = {
      start,
      end,
    }

    // check if event is non-publicly visible and set if it should be listed for the user or not
    // the isPublic flag should only be used to make sure the user cannot attend if not invited
    if (event.details.isPubliclyVisible === false) {
      temp.listedForUser = temp.isOrganizing || temp.isCoOrganizer || temp.isAttending || temp.isInvited || temp.isAdmin
    }

    // get current users minified player doc
    const thisPlayer = this.playerNames.currentPlayersMini

    temp.isInvited = thisPlayer === undefined
      ? false
      : thisPlayer.email !== ''
        ? event.invitedPlayers.filter(i => i.email === thisPlayer.email).length > 0 || (event.invitedEmails && event.invitedEmails.filter(i => i.email === thisPlayer.email).length > 0)
        : false

    return temp
  }

  public get icons(): IEventListingIcons {
    return {
      inPerson: faUsers,
      online: faDesktop,
      multiday: faCalendar,
      oneday: faClock,
    }
  }

  public clearAllFilters(): void {
    this.filtering = JSON.parse(JSON.stringify(this.initialFilter))
    this.filters$.next(this.filtering)
    this._onFiltersChanged()
  }

  public showDetails(data: IEventTemplate): void {
    const modal = this.modalService.open(TournamentDescriptionModalComponent, {
      size: 'lg',
      centered: false,
      backdrop: true,
      keyboard: true,
      animation: true,
    })
    modal.componentInstance.tournament = data
  }

}

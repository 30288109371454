import { EventService } from 'src/app/services/event/event.service'
import { AuthService } from 'src/app/services/auth.service'
import { firstValueFrom, Observable } from 'rxjs'
import { Component, Input } from '@angular/core'
import { IEventDetails, IMatchData } from 'tolaria-cloud-functions/src/_interfaces'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-event-actions-admin',
  templateUrl: './event-actions-admin.component.html',
  styleUrls: ['./event-actions-admin.component.css']
})
export class EventActionsAdminComponent {

  faChevronRight = faChevronRight

  public submenuOpen = false

  @Input() eventDoc$: Observable<IEventDetails>

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private eventService: EventService,
  ) { }

  get isAdmin() {
    return this.auth.user.role === 'admin'
  }

  async reportAllMatches(event: IEventDetails) {
    const batch = this.afs.firestore.batch()
    console.log(event)
    const activeRound = event.details.structure.isGroup ? 0 : event.activeRound
    const matchCollection = await firstValueFrom(this.afs.collection<IMatchData>('matches', ref => ref.where('eventDocId', '==', event.docId).where('roundNumber', '==', activeRound)).get())
    for (const matchDocument of matchCollection.docs) {
      batch.update(matchDocument.ref, {
        isReported: true,
        [`player1.isWinner`]: true,
        [`player1.wins`]: 2,
        [`player2.losses`]: 2,
      })
    }
    batch.commit()
  }

  async reportAllMatchesRandom(event: IEventDetails) {
    const batch = this.afs.firestore.batch()
    console.log(event)
    const matchCollection = await firstValueFrom(this.afs.collection<IMatchData>('matches', ref => ref
      .where('eventDocId', '==', event.docId)
      .where('roundNumber', '==', event.activeRound)
      .where('isReported', '==', false)
    ).get())
    console.log(matchCollection.docs.map(i => i.data()))
    console.log(matchCollection.docs.map(i => i.ref))
    for (const matchDocument of matchCollection.docs) {
      batch.update(matchDocument.ref, this.__getRandomResult())
    }
    batch.commit().then(() => console.log('all matches reported with a random result')).catch((e) => console.log(e))
  }

  closeEvent(event: IEventDetails): void {
    this.eventService.eventUpdateStatus(event, 8).then(() => console.log('event closed')).catch((error) => console.log(error))
  }

  private __getRandomResult() {

    const random = Math.floor(Math.random() * (100 - 1 + 1) + 1)

    if (random > 98) {

      return {
        isReported: true,
        isDraw: true,
        [`player1.losses`]: random % 2,
        [`player1.wins`]: random % 2,
        [`player2.losses`]: random % 2,
        [`player2.wins`]: random % 2,
      }

    }
    else if (random < 49) {

      return {
        isReported: true,
        [`player1.isWinner`]: true,
        [`player1.wins`]: 2,
        [`player1.losses`]: random % 2 ? 0 : 1,
        [`player2.wins`]: random % 2 ? 0 : 1,
        [`player2.losses`]: 2,
      }

    }
    else {

      return {
        isReported: true,
        [`player2.isWinner`]: true,
        [`player2.wins`]: 2,
        [`player2.losses`]: random % 2 ? 0 : 1,
        [`player1.wins`]: random % 2 ? 0 : 1,
        [`player1.losses`]: 2,
      }

    }


  }

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MessageGroupItem } from '../../../services/message-groups.service';
import { BehaviorSubject, Subject, combineLatest, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { PlayerAvatarComponent } from 'src/app/components/players/player-avatar/player-avatar.component';
import { TolariaPlayerMentionComponent } from '../../tolaria-wysiwyg/custom-elements/tolaria-player-mention/tolaria-player-mention.component';
import { IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { RouterLink } from '@angular/router';
import { EventListingService, IEventTemplate } from 'src/app/private/events/services/event-listing.service';
import { CreateTournamentService } from 'src/app/private/events/services/create-tournament.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StripeConnectedAccountInfoComponent } from 'src/app/payment/stripe-connected-account/stripe-connected-account-info/stripe-connected-account-info.component';
import { PlayerListingService } from 'src/app/private/_shared/services/player-listing.service';

type ChatType = 'event-chat' | 'group-chat' | 'single-chat' | 'down-to-play' | 'organizers' | 'draft-chat'

@Component({
  selector: 'app-message-group-info',
  templateUrl: './message-group-info.component.html',
  styleUrls: ['./message-group-info.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    PlayerAvatarComponent,
    TolariaPlayerMentionComponent,
    RouterLink,
  ]
})
export class MessageGroupInfoComponent implements OnInit, OnDestroy {
  @Input() set group(item: MessageGroupItem) {
    if (item.isEventChat) {
      this.chatType = 'event-chat'
    }
    else if (item.isDraftChat) {
      this.chatType = 'draft-chat'
    }
    else if (item.id.includes('downToPlay')) {
      this.chatType = 'down-to-play'
    }
    else if (item.id === 'b4c24d82-736d-4e1d-9434-397089d40023') {
      this.chatType = 'organizers'
    }
    else {
      this.chatType = item.isSingle
      ? 'single-chat'
      : 'group-chat'
    }
    this.group$.next(item)
  }
  
  private destroyed$ = new Subject<boolean>()

  public chatType: ChatType = null
  public group$ = new BehaviorSubject<MessageGroupItem>(null)
  public event$ = new BehaviorSubject<IEventTemplate>(null)

  constructor(
    private readonly playerNames: PlayerNameService,
    private readonly events: EventListingService,
    private readonly eventCreate: CreateTournamentService,
    private readonly modalService: NgbModal,
    private readonly playerList: PlayerListingService,
  ) { }

  ngOnInit() {
    combineLatest([this.group$, this.events.ready$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async ([group, ready]) => {
        console.log('MessageGroupInfoComponent:: group and events ready state emitted', { group, ready })
        if (this.chatType === 'event-chat' && ready) {
          const eventDocId = group.id.replace('eventChatFor[', '').replace(']', '')
          const event = await this.events.getEventById(eventDocId)
          this.event$.next(event)
        }
      })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public onShowPlayerProfile(id: string): void {
    this.playerList.showPofile(id)
  }

  public onCreateTournament(): void {
    this.eventCreate.openNewTournamentForm()
  }

  public onAcceptPayments(): void {
    let modal = this.modalService.open(StripeConnectedAccountInfoComponent, {
      size: 'lg',
    })
    modal.componentInstance.modal = true
  }

  public get otherGroupMembers(): IPlayerMini[] {
    if (this.group$.getValue() === null) { return [] }
    return this.group$.getValue().players.filter(i => i.id !== this.playerNames.currentPlayersMini.id)
  }


}

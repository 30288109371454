import { map, takeUntil, tap } from 'rxjs/operators'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { AngularFirestore } from '@angular/fire/compat/firestore'

@Component({
  selector: 'app-match-monitor',
  templateUrl: './match-monitor.component.html',
  styleUrls: ['./match-monitor.component.css']
})
export class MatchMonitorComponent implements OnDestroy, OnInit {

  private destroyed$ = new Subject<boolean>()
  public onlineMatches$ = new BehaviorSubject<string[]>([])

  constructor(
    private readonly firestore: AngularFirestore,
  ) {}
  
  ngOnInit(): void {
    this.firestore
      .collectionGroup('match-room', ref => ref.where('id', '==', 'online-users'))
      .valueChanges()
      .pipe(
        takeUntil(this.destroyed$),
        tap(docs => console.log('[MatchMonitorComponent] -> collection group emitted new value', docs))
      )
      .subscribe(docs => {
        const validDocs = []
        for (const doc of docs) {
          const propValues = Object.values(doc)
          const player1Online = propValues.find(i => i.hasOwnProperty('callerRole') && i['callerRole'] === 'PLAYER_1')
          const player2Online = propValues.find(i => i.hasOwnProperty('callerRole') && i['callerRole'] === 'PLAYER_2')
          if (player1Online && player2Online) {
            validDocs.push(doc)
          }
        }
        console.log('[MatchMonitorComponent] -> valid documents', validDocs)
        this.onlineMatches$.next(validDocs)
      })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

}

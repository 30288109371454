import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const tiDateTime: IconDefinition = {
  prefix: 'fac',
  iconName: 'dateTime',
  icon: [
    500, // SVG view box width
    478.75, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M311.94,470.48H44.11c-24.35,0-44.11-19.76-44.11-44.11V102.92c0-24.35,19.76-44.11,44.11-44.11h44.11V11.03c0-6.06,4.96-11.03,11.03-11.03h36.76c6.06,0,11.03,4.96,11.03,11.03V58.81h117.62V11.03c0-6.06,4.96-11.03,11.03-11.03h36.76c6.06,0,11.03,4.96,11.03,11.03V58.81h44.11c24.35,0,44.11,19.76,44.11,44.11V249.81c-4.64-.51-9.35-.78-14.12-.78-10.33,0-20.37,1.24-29.98,3.56v-105.56H44.11V420.86c0,3.03,2.48,5.51,5.51,5.51h230.89c7.24,16.9,18.02,31.92,31.43,44.11Zm188.06-94.19c0,56.6-45.86,102.46-102.46,102.46-26.54,0-50.72-10.08-68.92-26.63-10.79-9.81-19.48-21.9-25.3-35.51-5.3-12.37-8.24-26-8.24-40.32,0-48.28,33.38-88.74,78.32-99.59,7.74-1.87,15.82-2.87,24.14-2.87,3.84,0,7.64,.22,11.37,.63,51.24,5.66,91.09,49.07,91.09,101.83Zm-65.28,17.56l-23.96-17.56v-59.49c0-1.78-.71-3.39-1.85-4.58-1.2-1.25-2.89-2.03-4.76-2.03h-13.22c-3.65,0-6.61,2.96-6.61,6.61v64.26c0,5.02,2.28,9.77,6.2,12.9l18.39,13.65,9.29,6.89h0c2.85,2.28,7.01,1.82,9.29-1.03l8.26-10.33h0c2.28-2.86,1.82-7.01-1.03-9.3Z'
  ],
} as any;

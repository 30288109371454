import { StorageService } from 'src/app/services/storage.service';
import { DeckTestComponent } from './../deck-test/deck-test.component';
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import { faArrowsAlt, faEdit, faFish, faFolder, faPlay, faTrash, faTrophy, 
  faStar, faLock, faCodeBranch, faDownload, faCamera } from '@fortawesome/free-solid-svg-icons';
import { IDeckList, DecksService, MagicDeck } from 'src/app/services/decks.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services';
import { ImagePreviewService } from 'src/app/private/_shared/services/image-preview.service';

@Component({
  selector: 'app-deck-item',
  templateUrl: './deck-item.component.html',
  styleUrls: ['./deck-item.component.css']
})
export class DeckItemComponent {
  @ViewChild('deleteDeckConfirmation', { static : false }) deleteDeckConfirmation: ElementRef;
  @ViewChild('cloneDeckModal', { static : false }) cloneDeckModal: ElementRef;
  @ViewChild('cloneInputName', { static : false }) cloneInputName: ElementRef;

  @Input() deck: IDeckList;

  faArrowsAlt = faArrowsAlt;
  faPlay = faPlay;
  faEdit = faEdit;
  faFish = faFish;
  faFolder = faFolder;
  faFileDownload = faDownload;
  faClone = faClone;
  faImage = faCamera;
  faTrash = faTrash;
  faTrophy = faTrophy;
  faStar = faStar;
  faLock = faLock;
  faCodeBranch = faCodeBranch;

  public cloneDeckNewName: string;

  constructor(
    private modalService: NgbModal,
    private storageService: StorageService,
    private deckService: DecksService,
    private auth: AuthService,
    private readonly imagePreview: ImagePreviewService,
  ) { }

  public downloadDeckList(): void {
    this.storageService.downloadDeckList(this.deck);
  }

  public goldfishDeck() {
    const options: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
      windowClass: 'modal-goldfish'
    };
    const modalRef: NgbModalRef = this.modalService.open(DeckTestComponent, options);
    modalRef.componentInstance.deck = this.deck;
  }

  public openDeckPhoto(): void {
    this.imagePreview.show({
      imageUris: [this.deck.deckPhotoUrl]
    })
  }

  public cloneDeckList(): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: false,
      keyboard: false,
      size: 'sm',
    };

    this.modalService.open(this.cloneDeckModal, modalOptions)
    .result
    .then((result) => {
      if (result === 'clone') {
        // create a new deck
        const deckClone = new MagicDeck(this.auth.user.playerId, this.auth.user.uid).data;

        // copy cards from this deck onto the clone
        deckClone.main = this.deck.main;
        deckClone.sideboard = this.deck.sideboard;
        deckClone.maybeboard = this.deck.maybeboard;
        deckClone.name = this.cloneDeckNewName;
        this.deckService.saveDeckList(deckClone);
      }
    });

    setTimeout(() => {
      this.cloneInputName.nativeElement.focus();
    }, 350);
  }

  public get deckName(): string {
    return this.deck.name === '' || this.deck.name === undefined || this.deck.name === null ? 'Untitled deck' : this.deck.name;
  }
  public get noDeckName(): boolean {
    return this.deck.name === '' || this.deck.name === undefined || this.deck.name === null;
  }
  public get deckFolder(): string {
    return this.deck.folderName  ? this.deck.folderName : 'Uncategorized';
  }
  public get deckPhoto(): boolean {
    return this.deck.deckPhotoUrl && this.deck.deckPhotoUrl !== null && this.deck.deckPhotoUrl !== '' ? true : false;
  }

}

<ng-container *ngIf="onlineUsers$ | async as onlineUsers">
  <div ngbDropdown
    [placement]="placement"
    #onlineUsersDropdown="ngbDropdown"
    class="d-inline-block w-100">
    
    <button *ngIf="onlineUsers && onlineUsers.length > 0"
      [ngClass]="styleClass" id="dropdownBasic1"
      ngbDropdownToggle
      ngbTooltip="Players active at the table"
      [placement]="placement"
      container="body">

      <fa-icon [fixedWidth]="true" [icon]="faUsers" class="me-2"></fa-icon>{{ onlineUsers.length }}

    </button>

    <!-- <div ngbDropdownMenu class="custom-drop-down"> -->
    <div ngbDropdownMenu>
      <div ngbDropdownItem *ngFor="let user of onlineUsers">
        <app-player-display-name [playerUid]="user.playerUid" position="right"></app-player-display-name>
      </div>
    </div>
  </div>
</ng-container>

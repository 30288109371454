import { Subject } from 'rxjs';
import { CardSearchService, ICardLanguageItem } from 'src/app/services/card-search.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface ICardMetaLanguage {
  id: string;
  name: string;
  set: string;
  lang: string;
}
@Component({
  selector: 'app-card-languages',
  templateUrl: './card-languages.component.html',
  styleUrls: ['./card-languages.component.css']
})
export class CardLanguagesComponent {
  @Input()
  set cardMeta(val: ICardMetaLanguage) {
    console.log(val);
    this.localCardMeta = val;
    this.getLanguanges();
  }
  @Output() updateCardId = new EventEmitter();

  private localCardMeta: ICardMetaLanguage;
  public languageItems$: Subject<Array<ICardLanguageItem>> = new Subject<Array<ICardLanguageItem>>();

  constructor(
    private cardSearch: CardSearchService
  ) { }

  private getLanguanges() {
    this.cardSearch.getCardLanguages(this.localCardMeta).then(languageItems => {
      console.log(languageItems);
      this.languageItems$.next(languageItems);
    });
  }

  doUpdateCardId(id: string) {
    this.updateCardId.emit(id);
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-scryfall-image-version',
  templateUrl: './scryfall-image-version.component.html',
  styleUrls: ['./scryfall-image-version.component.css']
})
export class ScryfallImageVersionComponent implements OnInit {

  @Input() scryfallId: string;
  @Input() version: string;
  // version	String
  // Optional The image version to return when using the image format:
  // small, normal, large, png, art_crop, or border_crop. Defaults to large.

  constructor() { }

  ngOnInit() {
    if (this.scryfallId === undefined) { this.scryfallId = '878e6fb9-2e8f-46e4-9442-a6bdac41cbde' }
  }

  public get imageUrl(): string {
    return `https://api.scryfall.com/cards/${this.scryfallId}?format=image&version=${this.version}`;
  }

}

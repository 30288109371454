import { IMessageListItemPlayer } from 'src/app/services/messages.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'atSearch',
  standalone: true,
})
export class AtSearchPipe implements PipeTransform {

  transform(items: IMessageListItemPlayer[], searchVal: string): any[] {
    const searchString = searchVal.substr(1, searchVal.length);
    if (searchString === '') {
      return;
    }
    const res: Array<any> = [];
    if (items) {
      items.forEach(player => {
        console.log(player.displayName, searchString);
        if (player.displayName.toLowerCase().includes(searchString.toLowerCase())) {
          res.push(player);
        }
      });
    }
    return res.splice(0, 10);
  }

}

import * as admin from 'firebase-admin'

export interface ICardOfTheDayDocument {
  date: admin.firestore.Timestamp,
  playersDislike: { [playerDocId: string]: boolean };
  playersLike: { [playerDocId: string]: boolean };
  name: string;
  docId: string;
  commentCount: number;
}

<ng-container *ngIf="club$ | async as club">
  <ng-template #tipContent>
    <div style="width: 200px">
      <div class="club-logo">
        <img [src]="club.imageUris.normal">
      </div>
      <div class="info p-1 text-start">
        <div class="row">
          <div class="col"><span class="h3">{{ club.name }}</span></div>
        </div>
        <div class="row">
          <div class="col" [innerHtml]="club.info"></div>
        </div>
        <hr>
        <div class="row" *ngIf="club.city !== ''">
          <div class="col">{{ club.city }}</div>
        </div>
        <div class="row" *ngIf="club.region !== ''">
          <div class="col">{{ club.region }}</div>
        </div>
        <div class="row" *ngIf="club.country !== ''">
          <div class="col">{{ club.country }}</div>
        </div>
        <div class="row" *ngIf="club.websiteUrl !== ''">
          <div class="col"><a [href]="club.websiteUrl" target="_blank">{{ club.websiteUrl }}</a></div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="d-flex align-items-center justify-content-center"
    [ngbTooltip]="tipContent" container="body">
    <div class="hero-club-badge {{ borderClass }}">
      <img [src]="club.imageUris[imageSize]">
    </div>
  </div>
</ng-container>

import { EventService } from 'src/app/services/event/event.service';
import { ConfigService } from 'src/app/services/config.service'
import { Component, Input } from '@angular/core'
import { faBullhorn, faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { MessagesService } from 'src/app/services'
import { EventAnnouncementRecipientType } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-event-to-announcement',
  templateUrl: './event-to-announcement.component.html',
  styleUrls: ['./event-to-announcement.component.css']
})
export class EventToAnnouncementComponent {
  @Input() eventDocId: string

  announcementText = ''

  faPaperPlane = faPaperPlane
  faBullhorn = faBullhorn
  faTimes = faTimes

  ckeConfig = this.config.ckeConfig

  sendConfig = {
    tolaria: true,
    email: false,
    recipients: {
      attendees: false,
      invitees: false,
    }
  }

  timestampNow: number = Date.now()

  constructor(private ms: MessagesService, public activeModal: NgbActiveModal, private config: ConfigService, private eventService: EventService) { }

  get disableSend(): boolean {
    if (!this.sendConfig.tolaria && !this.sendConfig.email) return true
    if (this.announcementText === '' || this.announcementText === undefined || this.announcementText === null) return true

    return false
  }

  sendAnnouncement() {
    if (this.sendConfig.tolaria) {
      this.ms.postAnnouncementMessage(this.announcementText, 'eventChatFor[' + this.eventDocId + ']', false, false, '')
    }

    if (this.sendConfig.email) {
      this.eventService.sendEmailEventAnnouncement(this.eventDocId, this.recipients, this.announcementText)
    }
    this.activeModal.close()
  }

  toggleEmail(): void {
    if (!this.sendConfig.email) {
      this.sendConfig.recipients.attendees = false
      this.sendConfig.recipients.invitees = false
    }
  }

  private get recipients(): EventAnnouncementRecipientType {
    if (this.sendConfig.recipients.attendees && this.sendConfig.recipients.invitees) return EventAnnouncementRecipientType.BOTH
    if (this.sendConfig.recipients.attendees && !this.sendConfig.recipients.invitees) return EventAnnouncementRecipientType.ATTENDEES
    if (!this.sendConfig.recipients.attendees && this.sendConfig.recipients.invitees) return EventAnnouncementRecipientType.INVITEES
  }

}

import { Stripe } from 'stripe'

export const PlatformFee = 0.05 // precentage
export const PaymentFactor = 100 // all amounts should be in cents
export type PayoutStatus = 'paid' | 'pending' | 'in_transit' | 'canceld' | 'failed'
export enum PaymentStatus {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
}
export enum PaymentType {
  TOLARIA = 'TOLARIA',
  PAYPAL = 'PAYPAL',
  CASH = 'CASH',
  OTHER = 'OTHER',
}
export enum ProductType {
  REGISTRATION_FEE = 'REGISTRATION_FEE',
  TICKET = 'TICKET',
  DONATION = 'DONATION',
  UNKNOWN = 'UNKNOWN',
}
export enum StripeRole {
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  CUSTOMER = 'CUSTOMER',
}
export interface IStripeSubscriptionHandlingData {
  subscriptionId: string
  pauseUntil?: number
  pausBehavior?: Stripe.Subscription.PauseCollection.Behavior
}
export interface IStripeGetSubscriptionData {
  playerDocId: string
  playerUid: string
  stripeCustomerId: string
}
export interface IStripeGetSubsctiptionResponse extends IStripeResponse {
  subscriptions: Stripe.Subscription[]
}
export interface IStripeGetEventCargesData {
  eventDocId: string
}
export interface IStripeAccountCreateData {
  uid: string
  accountType: Stripe.AccountCreateParams.Type
}
export interface IStripeAccountLinksData {
  uid: string
  accountId: string
  currentUrl: string
}
export interface IStripeAccountLinksResponse extends IStripeResponse {
  response: {
    accountLinksFetched: boolean
    accountLinksSaved: boolean
    expirationTaskCreated: boolean
    messages: string[]
    accountLinks: any
  }
}
export enum StripePayoutType {
  EVENT = 'EVENT',
  PLATFORM = 'PLATFORM'
}
export enum StripePayoutStatus {
  PAID_OUT = 'PAID_OUT',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}
export interface IStripePayoutData {
  payoutType: StripePayoutType
  amount: number
  currency: string
  eventDocId?: string
}

export interface IStripeGetChargesData {
  playerDocId: string
  limit: number
  chargeId?: string
  checkoutSessionId?: string
}
export interface IStripeGetChargeResponse extends IStripeResponse {
  charges: Stripe.Charge[]
}

export interface IConnectedAccountMeta {
  playerDocId: string;
  playerUid: string;
  email: string;
  phone: string
}
export interface IPaymentMeta {
  productType: ProductType
  eventDocId?: string;
  playerDocId?: string;
  playerUid?: string;
  email?: string;
  phone?: string
}
export interface IDonationMeta extends IPaymentMeta {
  type?: DonationType
  tier_name?: string
  tier_amount?: number
  tier_currency?: string
}
export interface IRegistrationFeeMeta extends IPaymentMeta {
  charityAmount: number
  currency: string
  donationAmount: number
  eventCreatedByUid: string
  eventDocId: string
  platformCostAmount: number
  platformFee: number
  playerDocId: string
  playerEmail: string
  playerUid: string
  productType: ProductType
  registrationFeeAmount: number
}
export interface ITicketMeta extends IPaymentMeta {
  ticketDocId: string
  ticketCreatedByUid: string
  ticketType: 'CONVENTION'
  ticketMeta: string
  ticketName: string
  ticketAmount: number
  currency: string
  donationAmount: number
  platformCostAmount: number
  platformFee: number

  playerDocId: string
  playerUid: string
  playerEmail: string

  refunded?: boolean
}
export interface IConventionTicketMeta {
  eventDocIds: string[]
}

export interface IRegistrationFeePayment {
  paid: boolean;
  refunded: boolean;
  status: PaymentStatus;
  timestamp: number;
  amount: number;
  amountRefunded: number;
  currency: string | null;
  charityAmount: number;
  paymentType: PaymentType;
  comment?: string;
  paymentIntent?: string | Stripe.PaymentIntent | null;
  metadata?: IRegistrationFeeMeta | ITicketMeta
  ticketPurchase?: boolean
}
export interface IStandardCheckoutData {
  productType: ProductType
  metadata: any
  playerDocId: string
  line_items: Stripe.Checkout.SessionCreateParams.LineItem[]
  success_url: string
  cancel_url: string
}
export interface IRegistrationCheckoutData extends IStandardCheckoutData {
  metadata: IRegistrationFeeMeta
}
export interface IDonationCheckoutData extends IStandardCheckoutData {
  donationType: DonationType
}
export interface ITicketCheckoutData extends IStandardCheckoutData {
  metadata: ITicketMeta
}
export interface ICheckoutLineItem {
  price_data: {
    currency: string
    product_data: {
      name: string
    }
    unit_amount: number
  }
  quantity: number
}
export interface IStripeRefundData {
  payment_intent: string
  amount: number
}
export interface IStripeResponse {
  status: boolean
  text: string
  data?: any
}
export interface IStripeCustomerCreateResponse extends IStripeResponse {
  customerId: string
}
export interface ICheckoutResponse extends IStripeResponse {
  session_id?: string
}
export interface IEventPayoutMeta {
  organizerUid: string
  amount: number
  currency: string
  description: string
  eventDocId: string
}

export interface IStripeGetBalanceData {
  stripeAccount: string
}
export interface IStripeResponseBalance extends IStripeResponse {
  balance: IBalanceTemplateMeta
}

export interface IBalanceTemplateMeta {
  available: string[]
  pending: string[]
  inTransit: string[]
  total: string[]
  currency: {
    [key:string]: {
      available: number
      pending: number
      inTransit: number
      total: number
    }
  }
}
export interface IStripeAccountDeleteData {
  accountId: string
  uid: string
}
export interface IStripeAccountUpdateData {
  accountId: string
  property: string
  value: string | boolean | number
}
export enum DonationType {
  ONE_TIME = 'ONE_TIME',
  SUBSCRIPTION = 'SUBSCRIPTION'
}
export interface IDonationTier {
  name: string
  thumbUrl: string
  amount: number
  currency: string
}


export interface ITicket {
  createdAt: number
  createdByUid: string
  createdByPlayerDocId: string
  docId: string
  type: TicketType
  name: string
  description: string
  salesData: {
    from: number
    to: number
    price: number
    currency: string
    currencySymbol?: string
    ticketLimit: number
    ticketPurchases: {
      [key:string]: ITicketPurchase
    }
    sold: number
  }
  data: IConventionTicketData // this will be extended when new ticket types comes to place
  deleted: boolean
  showAsSoldOut: boolean
  hasPurchased?: boolean
  soldOut?: boolean
  selected?: boolean
}
export interface ITicketPurchase {
  paymentIntent: string | Stripe.PaymentIntent | null
  playerDocId: string
  timestamp: number
  refunded: boolean
  price: number
}
export interface IConventionTicketData {
  eventDocIds: string[]
  events?: {
    [key:string]: any
  }
}

export enum TicketType {
  CONVENTION = 'CONVENTION'
}

<div class="card overflow-hidden min-width-100 max-width-100 min-height-100 max-height-100 flex-grow-1">

  <div class="card-header">
    <div class="row d-flex align-items-center">
      <div class="col-1 white-space-nowrap">Rank</div>
      <div class="col-6 white-space-nowrap">Player</div>
      <div class="col-2 white-space-nowrap">Points</div>
      <div class="col-3 d-flex align-items-center white-space-nowrap">
        <span>Record</span>
        <span class="ms-2 text-smaller text-secondary">(wins-draws-losses)</span>
      </div>
    </div>
  </div>
  <div class="card-body ranking-list no-hover overflow-hidden overflow-y-auto scrollable py-0">

    <ng-container *ngIf="players$ | async as players">
      <ng-container *ngFor="let player of players">
        <div class="row player-row py-1 d-flex align-items-center">
          <div class="col-1" [innerHtml]="player.leagueRank"></div>
          <div class="col-6">
            <ng-container *ngIf="player.playerDocId.includes('temp__'); else playerLink">
              {{ player.name }}
            </ng-container>
            <ng-template #playerLink>
              <app-player-display-name [playerDocId]="player.playerDocId"></app-player-display-name>
            </ng-template>
          </div>
          <div class="col-2 ps-4 text-bold text-large" [innerHtml]="player.leaguePoints"
            ngbTooltip="Click for Points Breakdown" placement="left left-top left-bottom" container="body" (click)="showPointsBreakdown(player)"></div>
          <div class="col-3">
            <span class="score-wrapper" [innerHtml]="player.wins"></span>
            -
            <span class="score-wrapper" [innerHtml]="player.draws"></span>
            -
            <span class="score-wrapper" [innerHtml]="player.losses"></span>
          </div>
        </div>
      </ng-container>
    </ng-container>

  </div>
  <div class="card-footer d-flex flex-row align-items-center">
    <button class="btn btn-sm btn-outline-secondary" (click)="refreshPlayerList()">
      <fa-icon [icon]="icons.faSync" [fixedWidth]="true" class="me-2"></fa-icon>Refresh
    </button>
    <div class="flex-grow-1 text-end text-secondary">
      Tiebreakers: Point > Match Wins > Game Wins
    </div>
  </div>

</div>

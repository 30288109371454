import { BehaviorSubject, Observable, tap, OperatorFunction, Subject, combineLatest, debounceTime, distinctUntilChanged, filter, map, merge, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, Output, EventEmitter, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces';
import { NgbModule, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

interface Result extends IPlayerMini {
  manualEntry: boolean
}
@Component({
  selector: 'app-player-search',
  templateUrl: './player-search.component.html',
  styleUrls: ['./player-search.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
  ]
})
export class PlayerSearchComponent implements OnDestroy, OnInit {
  @ViewChild('searchInput', { static: false }) searchInput: NgbTypeahead
  @ViewChild('searchElement', { static: false }) searchElement: ElementRef
  @Output() output = new EventEmitter<IPlayerMini>()
  @Output() string = new EventEmitter<string>()
  @Input() title: string = ''
  @Input() showTitle: boolean = false
  @Input() border: boolean = true
  @Input() background: boolean = true
  @Input() placeholder: string = 'Start typing to search for players'
  @Input() styleClass: string = ''
  @Input() focus: boolean = false
  @Input() focus$ = new BehaviorSubject<boolean>(null)
  @Input() debug: boolean = false
  @Input() manualEntry: boolean = false
  @Input() manualEntryText: string = 'manual entry'

  private log(type: string, text: string, data?: any) {
    if (this.debug) {
      if (data) {
        console[type](text, data)
      }
      else {
        console[type](text)
      }
    }
  }
 
  public searchString: IPlayerMini | string = ''
  public ready$ = new BehaviorSubject<boolean>(false)
  public focused$ = new Subject<string>()
	public clicked$ = new Subject<string>()

  private rawInput: string
  private playerNames$ = new BehaviorSubject<IPlayerMini[]>(null)
  private destroyed$ = new Subject<boolean>()
  private _setInputFocus: boolean = false
  private _focusBeenSet: boolean = false

  constructor(
    private readonly playerNames: PlayerNameService,
  ) {
  }
  
  ngOnInit(): void {
    this.playerNames.serviceReady$.pipe(takeUntil(this.destroyed$)).subscribe(ready => {
      if (ready) {
        this.ready$.next(ready)
        this.playerNames$.next(this.playerNames.playerMinis$.getValue())
      }
    })
    this.ready$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(state => {
        this.log('log', 'PlayerSearchComponent:: ready$ emitted', state)
        if (state === true) {
          if (this.focus && !this._focusBeenSet) {
            this._setInputFocus = true
            this.setFocus()
          }
        }
      })
    combineLatest([this.focus$, this.ready$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([focus, ready]) => {
        this.log('log', 'PlayerSearchComponent:: focus$ emitted:', { focus, ready })
        if (ready && focus) {
          this.log('log', 'PlayerSearchComponent:: ready and focus')
          this._setInputFocus = true
          this._focusBeenSet = false
          this.setFocus()
        }
      })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }
  
  private setFocus(): void {
    this.log('log', 'PlayerSearchComponent:: setFocus', {
      setInputFocus: this._setInputFocus,
      focusBeenSet: this._focusBeenSet,
      element: this.searchElement,
      nativeElement: this.searchElement?.nativeElement
    })
    if (this._setInputFocus && this._focusBeenSet === false) {
      this._focusBeenSet = true
      if (this.searchElement && this.searchElement.nativeElement) {
        setTimeout(() => {
          this.searchElement.nativeElement.focus()
          this.log('log', 'PlayerSearchComponent:: focus set')
        }, 0)
      }
      else {
        this.log('log', 'PlayerSearchComponent:: failed to focus input')
      }
    }
  }

  public search: OperatorFunction<string, readonly Result[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    )
    const clicksWithClosedPopup$ = this.clicked$.pipe(filter(() => !this.searchInput.isPopupOpen()))
		const inputFocus$ = this.focused$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap((term) => this.rawInput = term),
			map((term) => {
        if (term.length < 2) {
          return []
        }
        const result: Result[] = this.playerNames$.getValue()
        .sort((a, b) => a.name.display.localeCompare(b.name.display))
        .filter((v) => v.name.display.toLowerCase().indexOf(term.toLowerCase()) > -1)
        .slice(0, 10) as Result[]
				// (term === '' ? states : states.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10),
        if (result.length === 0 && this.manualEntry) {
          result.unshift({
            id: `temp__${term.toLowerCase()}`,
            name: {
              first: '',
              last: '',
              nick: '',
              display: term,
            },
            email: '',
            avatar: 'assets/avatars/non-tolarian.png',
            uid: '',
            country: '',
            region: '',
            timezone: '',
            utcOffset: '',
            manualEntry: true,
          })
        }
        return result as Result[]
      }),
		);
  }

  public formatter = (x: IPlayerMini) => x ? x.name.display ? x.name.display : `${x.name.first} ${x.name.last}` : ''

  public outputSelected(event?: NgbTypeaheadSelectItemEvent): void {
    if (event) {
      this.output.emit(event.item)
      this.rawInput = ''
      setTimeout(() => this.searchString = '', 250)
    }
  }

  public outputString(): void {
    if (typeof this.rawInput === 'string' && this.rawInput.length >= 3) {
      this.string.emit(this.rawInput)
      this.rawInput = ''
      setTimeout(() => this.searchString = '', 250)
    }
  }


}

<div class="wrapper p-3">
  <ng-container *ngIf="init$ | async; else loader">
    <div class="text-large mb-3 text-center">Registration Fee</div>
    <ng-container *ngIf="tolariaPayment; else nonTolariaPaymentForm">
      <ng-template [ngTemplateOutlet]="tolariaPaymentForm"></ng-template>
    </ng-container>
  </ng-container>
</div>


<ng-template #tolariaPaymentForm>
  <div class="form-group">
    <label for="amount">Registration Fee</label>
    <input type="text" class="form-control text-larger text-center text-secondary" id="amount" [value]="amount"
      [disabled]="true">
  </div>
  <div class="form-group mt-3">
    <label for="extraCharity">Extra - Charity</label>
    <input type="number" class="form-control text-larger text-center" id="extraCharity"
      aria-describedby="extraCharityHelp" [step]="1" [min]="0" [(ngModel)]="charityAmount">
    <div id="extraCharityHelp" class="text-secondary mt-1">
      Any amount added here will be sent together with the registration fee and the tournament organizer will make sure
      they will be used for a great cause.
    </div>
  </div>
  <div class="my-2 border-bottom border-secondary"></div>
  <div class="form-group mt-3">
    <label for="donationAmount">Extra - Platform Support</label>
    <input type="number" class="form-control text-larger text-center" id="donationAmount"
      aria-describedby="donationAmountHelp" [step]="1" [min]="0" [(ngModel)]="donationAmount">
    <div id="donationAmountHelp" class="text-secondary mt-1">
      If you want to contribute to the Tolaria platform, please consider adding some extra here.
      Any amount is helpful to make the plaform a better place for all us old school lovers!</div>
  </div>
  <div class="my-2 border-bottom border-secondary"></div>
  <div class="d-flex flex-row align-items-center" *ngIf="hasStripeAccount">
    <div class="text-secondary flex-grow-1">Customer:</div>
    <div class="text-secondary text-monospaced">{{ stripeAccountNumber }}</div>
  </div>
  <div class="d-flex flex-row align-items-center" *ngIf="!hasStripeAccount">
    <div class="text-secondary">
      This seems to be your first Tolaria payment session. Tolaria Payment is integrated with <a
        href="https://stripe.com/eu-en" target="_blank"><b>stripe</b></a> payment infrastructure. During the checkout a
      <b>stripe</b> customer account will be created for you.</div>
  </div>
  <div class="my-2 border-bottom border-secondary"></div>
  <div class="py-2 mt-3 text-larger d-flex flex-row">
    <div class="flex-grow-1">Total:</div>
    <div class="flex-grow-0">{{ totalAmount }} {{ currency }}</div>
  </div>
  <div class="mt-3">
    <button class="btn w-100 {{  creatingCheckout ? 'btn-outlined-secondary' : 'btn-main' }}"
      (click)="proceedToCheckout()">
      <span *ngIf="!creatingCheckout; else loader">Proceed to checkout</span>
    </button>
  </div>
</ng-template>


<ng-template #nonTolariaPaymentForm>
  <p>The organizer has disabled Tolaria payment for this event. Please see the event description for how to proceed with
    payment of the registration fee.</p>
  <div class="donation-wrapper mt-3">
    <app-pay-donation [currency]="currency" donationConfig="ONE_TIME"></app-pay-donation>
  </div>
  <button class="mt-3 btn btn-blank w-100" (click)="close()">Close</button>
</ng-template>

<ng-template #loader>
  <div class="d-flex flex-row justify-content-center align-items-center">
    <app-busy-spinner></app-busy-spinner>
  </div>
</ng-template>
<div class="bg-body shadow-sm d-flex align-items-center px-3 py-2 text-large" style="z-index: 1;">
    <div class="flex-grow-1">Main menu</div>
    <button class="btn-close" (click)="onClose()"></button>
    <!-- <button type="button" class="btn btn-blank d-flex align-items-center justify-content-center"
        >
        <fa-icon [icon]="faTimes"></fa-icon>
    </button> -->
</div>
<div class="bg-body p-2 flex-grow-1 scrollable">

    <ul class="nav nav-pills flex-column mb-auto">
        <ng-container *ngIf="menuService.menu$ | async as menu">
          <ng-container *ngFor="let item of menu">
            <li class="nav-item" (click)="onMenuItemClick(item)">
              <div class="nav-link d-flex align-items-center cursor-pointer" [ngClass]="{ 'active' : activeRoute === item.link }">
                <div class="transition-all-350" [ngClass]="{ 'rotate-90-deg' : item.expanded}">
                  <fa-icon [fixedWidth]="true" [icon]="item.icon" class="bi pe-none mx-2"></fa-icon>
                </div>
                <span>{{ item.text }}</span>
                </div>
            </li>
  
            <div class="ps-4 ms-1" *ngIf="item.expanded">
              <ng-container *ngFor="let subItem of item.subItems">
                <li class="nav-item" (click)="onMenuItemClick(subItem)">
                  <div class="nav-link cursor-pointer" [ngClass]="{ 'active' : activeRoute === subItem.link }">
                    <fa-icon [fixedWidth]="true" [icon]="subItem.icon" class="bi pe-none mx-2"></fa-icon>
                    {{ subItem.text }}
                  </div>
                </li>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>

        <li class="nav-item" (click)="toAdmin()" *ngIf="isAdmin">
          <div class="nav-link d-flex align-items-center cursor-pointer" [ngClass]="{ 'active' : activeRoute === 'admin' }">
            <div class="transition-all-350">
              <fa-icon [fixedWidth]="true" [icon]="adminIcon" class="bi pe-none mx-2"></fa-icon>
            </div>
            <span>Admin</span>
          </div>
        </li>
      </ul>

</div>
<div class="bg-body border-top border-secondary px-3 py-2 d-flex align-items-center gap-3">
  <button class="btn btn-danger rounded-5 w-50 text-start ps-3" (click)="onReportIssueClick()">
    <fa-icon [icon]="bug" class="me-2"></fa-icon>
    Report issue
  </button>
  <app-support-button class="flex-grow-1 w-50"></app-support-button>
</div>
<ng-container *ngIf="eventDoc$ | async">

  <div class="modal-header text-center text-large px-3 py-2">Playoff Configuration</div>



  <ng-container *ngIf="!isTeamSwissEvent; else teamSwissEvent">

    <div class="modal-body p-0 d-flex flex-column">

      <ng-container *ngIf="playersList$ | async as playerList">
        <div class="playoff-selection px-3 py-2">
          <div class="form-group text-normal">
            <label for="exampleFormControlSelect1">Select Playoff size</label>
            <select class="form-control" [(ngModel)]="playoffSize" (change)="updateSelectedPlayers()">
              <option *ngFor="let bracketSize of playoffSizes$ | async" [value]="bracketSize.size">{{ bracketSize.text
                }}
              </option>
            </select>
            <small class="text-secondary">If you want to create a playoff with a specific number of players, just select a
              larger bracket size and then only select the players you wish to add to the playoffs.</small>
          </div>
        </div>
        <div class="flex-1 scrollable no-hover p-0">
            <ngx-sortable [items]="playerList" name="Edit Players and Seeds" [listStyle]="listStyle" [showHeader]="true"
              (listSorted)="listSorted($event)">
            <ng-template let-player="item">
              <div class="row player p-2 m-0 align-items-center" [ngClass]="{ 'selected' : player.selected }">
                <div class="col-1" (click)="!player.dropped ? selectPlayerForPlayoff(player.rank) : ''">
                  <fa-icon [fixedWidth]="true" [icon]="faCheckSquare" *ngIf="player.selected && !player.dropped">
                  </fa-icon>
                  <fa-icon [fixedWidth]="true" [icon]="faSquare" *ngIf="!player.selected && !player.dropped"></fa-icon>
                </div>
                <div class="col-1">Rank {{ player.rank }}</div>
                <div class="col-1">{{ player.selected ? 'Seed ' + player.seed : '' }}</div>
                <div class="col d-flex align-items-center">
                  <div class="flex-grow-1">{{ player.name }}</div>
                  <span class="drop-wrapper" *ngIf="player.dropped">dropped</span>
                  <ng-container *ngIf="isGroupEvent">
                    <span class="ms-2 group-name" *ngIf="player.groupRank === 1">Group Winner</span>
                    <span class="ms-2 badge badge-secondary text-uppercase">{{ player.playedInGroup }}</span>
                    <span class="ms-2">({{ player.groupRank }})</span>
                  </ng-container>
                </div>
                <div class="col-1 text-secondary text-right">
                  <fa-icon [fixedWidth]="true" [icon]="faBars"></fa-icon>
                </div>
              </div>
            </ng-template>
          </ngx-sortable>
        </div>
      </ng-container>

    </div>

    <div class="modal-footer d-flex align-items-center px-3 py-2">
      <div class="flex-grow-1 info-text {{ getSelectedPlayerQty < playoffSize ? 'not-filled' : '' }}">
        ({{ getSelectedPlayerQty }} selected players)</div>
      <button type="button" class="btn btn-sm btn-main" [disabled]="getSelectedPlayerQty < 2"
        (click)="generatePlayoffBracket()">Generate Playoff Bracket
      </button>
    </div>

  </ng-container>



  <ng-template #teamSwissEvent>

    <div class="modal-body p-0">

      <ng-container *ngIf="teamsList$ | async as teams">

        <ngx-sortable class="sortable-list-playoff" [items]="teams" name="Select teams and configure seeds"
          [showHeader]="true" [listStyle]="listStyle" (listSorted)="listSorted($event)">
          <ng-template let-team="item">
            <div class="row player p-2 m-0 align-items-center" [ngClass]="{ 'selected' : team.selected }">
              <div class="col-1">
                <button class="btn btn-sm btn-blank" [disabled]="team.dropped"
                  (click)="team.selected = !team.selected; listSorted(teams)">
                  <fa-icon [fixedWidth]="true" [icon]="team.selected ? faCheckSquare : faSquare"></fa-icon>
                </button>
              </div>
              <div class="col-2">Rank {{ team.rank }}</div>
              <div class="col-2">{{ team.selected ? 'Seed ' + team.seed : '' }}</div>
              <div class="col-5">{{ team.name }} <span class="drop-wrapper" *ngIf="team.dropped">dropped</span>
              </div>
              <div class="col-2 text-secondary text-end">
                <fa-icon [fixedWidth]="true" [icon]="faBars"></fa-icon>
              </div>
            </div>
          </ng-template>
        </ngx-sortable>

      </ng-container>

    </div>

    <div class="modal-footer d-flex align-items-center px-3 py-2">

      <div class="flex-grow-1 info-text text-warning">{{ canCreateTeamBracketText }}</div>

      <button type="button" class="btn btn-sm btn-main" [disabled]="!canCreateTeamBracket"
        (click)="generateTeamPlayoffBracket()">Generate Playoff Bracket
      </button>

    </div>


  </ng-template>

</ng-container>

<ng-container *ngIf="eventDoc$ | async as event">


  <ng-container *ngIf="showAsModal; else defaultViews">

    <div class="modal-header">Event Details</div>
    <div class="modal-body">
      <app-event-info [eventDocId]="event.docId"></app-event-info>
      <div class="mt-3 flex-grow-1" [innerHTML]="event.details.description | linky: { newWindow: true }"></div>
    </div>
    <div class="modal-footer d-flex align-items-center flex-column">
      <ng-container *ngIf="!event.playerDocIds.includes(auth.user.playerId) && event.statusCode === 0">
        <button class="btn btn-main w-100" (click)="attendEvent(event.docId)" [disabled]="disableAttendButton">Attend Event</button>
      </ng-container>
      <button class="btn btn-sm btn-blank" (click)="closeModal()">Close</button>
    </div>
  </ng-container>

  <ng-template #defaultViews>

    <ng-container *ngIf="globals.isMobile; else desktopView">

      <!-- PLAYER ACTIONS -->
      <ng-container *ngIf="playerDetails$ | async as playerDetails">
        <ng-container *ngIf="event.playerDocIds.includes(playerDetails.playerDocId)">

          <div class="card-header flex-grow-0 accordion-toggler d-flex align-items-center"
            [ngClass]="{ 'red' : hasUnfinisedTasks }"
            (click)="closeAllSections(); sections.playerActions = true">
            <fa-icon class="me-2" [fixedWidth]="true" [icon]="faActions"></fa-icon>
            <div class="flex-grow-1">Player Actions</div>
            <div
              *ngIf="hasUnfinisedTasks"
              class="text-smaller">You have unfinished tasks</div>
          </div>

          <app-event-player-actions class="card-body flex-grow-1 no-hover overflow-hidden overflow-y-auto"
            *ngIf="sections.playerActions" [eventDoc$]="eventDoc$" [playerDetails$]="playerDetails$" [eventDocId]="eventDocId">
          </app-event-player-actions>

        </ng-container>
      </ng-container>

      <!-- INFORMATION -->
      <div class="card-header flex-grow-0 accordion-toggler" (click)="closeAllSections('information')">
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="faInfo"></fa-icon>Event Information
      </div>
      <div class="flex-grow-1 overflow-hidden overflow-y-auto p-3" *ngIf="sections.information">
        <app-event-info [eventDocId]="event.docId"></app-event-info>
        <div class="mt-3 flex-grow-1" [innerHTML]="event.details.description | linky: { newWindow: true }"></div>
        <ng-container *ngIf="!event.playerDocIds.includes(auth.user.playerId) && event.statusCode === 0">
          <button class="btn btn-main w-100" (click)="attendEvent(event.docId)" [disabled]="disableAttendButton">Attend Event</button>
        </ng-container>
      </div>

      <!-- ATTENDING PLAYERS -->
      <ng-container *ngIf="showAttendingPlayers">
        <div class="card-header flex-grow-0 accordion-toggler d-flex align-items-center" (click)="closeAllSections('attendingPlayers')">
          <span class="flex-grow-1">
            <fa-icon class="me-2" [fixedWidth]="true" [icon]="faUsers"></fa-icon>Attending Players
          </span>
          <span class="badge text-bg-secondary flex-grow-0" [innerText]="event.playerDocIds.length"></span>
        </div>
        <app-event-players class="flex-grow-1 overflow-hidden overflow-y-auto pt-3 pb-3" *ngIf="sections.attendingPlayers"
          (selectPlayer)="selectedPlayer = $event"></app-event-players>
      </ng-container>

      <div class="mobile-wrapper-player-details" *ngIf="selectedPlayer !== undefined">
        <app-event-player-details [playerDetailsData]="selectedPlayer" [eventData]="event"
          (closePanel)="selectedPlayer = undefined"></app-event-player-details>
      </div>

    </ng-container>


    <ng-template #desktopView>

      <div class="wrapper d-flex max-height-100 min-height-100">
        <div class="card card-large min-height-100">

          <!-- PLAYER ACTIONS -->
          <ng-container *ngIf="playerDetails$ | async as playerDetails">
            <ng-container *ngIf="event.playerDocIds.includes(playerDetails.playerDocId)">

              <div class="card-header accordion-toggler d-flex align-items-center"
                [ngClass]="{ 'red' : hasUnfinisedTasks }"
                (click)="closeAllSections(); sections.playerActions = true">
                <fa-icon class="me-2" [fixedWidth]="true" [icon]="faActions"></fa-icon>
                <div class="flex-grow-1">Player Actions</div>
                <div
                  *ngIf="hasUnfinisedTasks"
                  class="text-smaller">You have unfinished tasks</div>
              </div>

              <app-event-player-actions class="card-body border-bottom border-secondary scrollable flex-grow-1 no-hover"
                *ngIf="sections.playerActions" [eventDoc$]="eventDoc$" [playerDetails$]="playerDetails$" [eventDocId]="eventDocId">
              </app-event-player-actions>

            </ng-container>
          </ng-container>


          <!-- INFORMATION -->
          <div class="card-header accordion-toggler" (click)="closeAllSections(); sections.information = true">
            <fa-icon class="me-2" [fixedWidth]="true" [icon]="faInfo"></fa-icon>Event Information
          </div>
          <div class="card-body border-bottom border-secondary scrollable flex-grow-1 no-hover d-flex flex-column"
            *ngIf="sections.information || !sections.attendingPlayers && !sections.playerActions && event.statusCode === 0">

            <app-event-info [eventDocId]="event.docId"></app-event-info>

            <div class="mt-3 flex-grow-1" [innerHTML]="event.details.description | linky: { newWindow: true }"></div>

            <ng-container *ngIf="!event.playerDocIds.includes(auth.user.playerId) && event.statusCode === 0">
              <div class="mb-2" *ngIf="!registrationOpen">Registration not open!</div>
              <button class="btn btn-main w-100" (click)="attendEvent(event.docId)" [disabled]="disableAttendButton">Attend Event</button>
            </ng-container>

          </div>


          <!-- ATTENDING PLAYERS -->
          <ng-container *ngIf="showAttendingPlayers">
            <div class="card-header accordion-toggler d-flex align-items-center"
              (click)="closeAllSections(); sections.attendingPlayers = true">
              <span class="flex-grow-1">
                <fa-icon class="me-2" [fixedWidth]="true" [icon]="faUsers"></fa-icon>Attending Players
              </span>
              <span class="badge text-bg-secondary flex-grow-0" [innerText]="event.playerDocIds.length"></span>
            </div>
            <app-event-players class="card-body border-bottom border-secondary scrollable flex-grow-1 no-hover p-0 event-players"
              *ngIf="sections.attendingPlayers" (selectPlayer)="selectedPlayer = $event"></app-event-players>
          </ng-container>


        </div>

        <div class="wrapper" style="min-height: 100%; margin-left: -1px;" *ngIf="selectedPlayer !== undefined">
          <app-event-player-details [playerDetailsData]="selectedPlayer" [eventData]="event"
            (closePanel)="selectedPlayer = undefined"></app-event-player-details>
        </div>

      </div>

    </ng-template>

  </ng-template>




</ng-container>

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { Component, Input } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { PlayerNameService } from 'src/app/services/players/player-name.service'
import { IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces'
import { Pipe, PipeTransform } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { FormsModule } from '@angular/forms'

@Pipe({
  name: 'playerSearcher',
  standalone: true,
})
export class PlayerSearcherPipe implements PipeTransform {

  transform(items: IPlayer[], filter: string, selected?: boolean, refresh?: number): IPlayer[] {
    if (items && selected) {
      return items.filter(p => p.selected)
    }
    else if (items) {
      return items.filter(p => p.name.display.toLowerCase().includes(filter.toLowerCase()))
    }
  }

}

interface IPlayer extends IPlayerMini {
  selected: boolean
}

@Component({
  selector: 'app-add-players',
  templateUrl: './add-players.component.html',
  styleUrls: ['./add-players.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    PlayerSearcherPipe,
    FontAwesomeModule,
    NgbModule,
    FormsModule,
  ]
})
export class AddPlayersComponent {
  @Input() maxSelection: number = null
  @Input()
  set playerDocIds(list: string[]) {
    console.log('input emitted', list)
    this.getPlayers(list)
  }

  faTimes = faTimes
  faCheckCircle = faCheckCircle
  faCircle = faCircle

  public playerSearch = ''
  public players$ = new BehaviorSubject<IPlayer[]>([])
  public refreshPipe = 0

  constructor(
    private activeModal: NgbActiveModal,
    private readonly playerNames: PlayerNameService,
  ) { }

  private getPlayers(playerDocIds: string[]) {
    let players: IPlayer[] = this.playerNames.playerMinis$.getValue().map((player: IPlayer) => {
      player.selected = playerDocIds.includes(player.id)
      return player
    })
    this.players$.next(players)
  }

  public close(): void {
    this.activeModal.dismiss()
  }

  public refresh(): void {
    this.refreshPipe = Math.random()
  }

  public closeWithResult(playerList: IPlayer[]): void {
    const playerDocIds: string[] = playerList.filter(p => p.selected).map(player => player.id)
    this.activeModal.close(playerDocIds)
  }

  public togglePlayer(player: IPlayer): void {

    let players = this.players$.getValue()
    
    if (player.selected || this.maxSelection === null) {
      player.selected = !player.selected
      this.refresh()
      return
    }
    
    if (players.filter(p => p.selected).length < this.maxSelection) {
      player.selected = !player.selected
      this.refresh()
      return
    }

  }


  public get hasMaxSelection(): boolean {
    return this.maxSelection !== null
  }

}

<ng-container *ngIf="matchDocument as match">

    <ng-template [ngTemplateOutlet]="isMobile ? mobile : desktop"></ng-template>


    <ng-template #desktop>
        <div class="row match p-2 flex-nowrap" (click)="openReportSlip(match, selectedRound)"
            [title]="match?.isManuallyPaired ? 'Manually paired' : ''"
            [ngClass]="{ 'logged-in-player' : match.isType === 'swiss' && match.player1.playerDocId === myPlayerId || match.isType === 'swiss' && match.player2.playerDocId === myPlayerId, 'manually-paired' : match?.isManuallyPaired, 'delete-marked': match.deleted !== undefined && match.deleted === true }">

            <div class="col-2 d-flex align-items-center justify-content-start" *ngIf="match.isType === 'batch'">
                <div class="segment-wrapper {{ match.hasPlayedEarlier ? 'has-played' : '' }}">
                    <span class="segment-number me-2">{{ match.segmentNumber }}</span>
                    <span class="segment-type text-secondary">{{ match.segmentType }}</span>
                </div>
            </div>

            <div class="col text-start player-wrapper d-flex align-items-center justify-content-start">
                <span class="match-points left" *ngIf="match.isType === 'swiss'">{{ match.player1.matchPoints }}</span>
                <span class="player-name" [id]="match.player1.playerDocId">
                    <ng-container
                        *ngIf="match.player1.playerDocId.includes('temp__') || match.player2.playerDocId.includes('***'); else playerLink1">
                        <span>
                            {{ match.player1.displayName !== '' ? match.player1.displayName : 'Nameless Race' }}
                        </span>
                    </ng-container>
                    <ng-template #playerLink1>
                        <app-player-display-name [playerDocId]="match.player1.playerDocId"
                            [name]="match.player1.displayName"
                            (click)="$event.stopPropagation(); $event.preventDefault()">
                        </app-player-display-name>
                    </ng-template>
                </span>
                <span class="drop-wrapper" *ngIf="match.player1.drop">drops</span>
            </div>

            <ng-container *ngIf="match.deleted === undefined || match.deleted === false; else matchDeleted">
                <div class="col-2 text-center d-flex align-items-center justify-content-center"
                    *ngIf="!match.isReported && !match.isFeatured && showAppointments" placement="top" container="body"
                    [ngbTooltip]="appointmentTooltip">

                    <ng-template #popAppointmentsTitle>
                        <div class="d-flex align-items-center">
                            <div class="flex-grow-1 text-normal">Appointments</div>
                            <div class="flex-grow-0 ms-3 btn btn-sm btn-blank" container="body"
                                [ngbTooltip]="showCancelled ? 'Hide cancelled appointments' : 'Show cancelled appointments'"
                                (click)="showCancelled = !showCancelled">
                                <fa-icon [icon]="showCancelled ? icons.show : icons.hide" [fixedWidth]="true"
                                    class="me-2"></fa-icon>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #popAppointments>
                        <div class="appointment-list">
                          <!-- TODO: FIX THIS -->
                            <!-- <ng-container *ngFor="let appointment of appointmentList">
                                <app-match-appointment [appointment]="appointment" [matchMeta]="null"
                                    *ngIf="appointment.isCancelled && showCancelled || !appointment.isCancelled">
                                </app-match-appointment>
                            </ng-container> -->
                        </div>
                    </ng-template>
                    <button class="btn btn-sm {{ apoointmentButtonClass }}" [autoClose]="'outside'"
                        [ngbPopover]="popAppointments" [popoverTitle]="popAppointmentsTitle"
                        popoverClass="popover-availablity" container="body"
                        (click)="$event.stopPropagation(); $event.preventDefault();">
                        <span class="badge rounded-pill text-bg-secondary me-2">{{ appointmentList.length }}</span>
                        <fa-icon [icon]="icons.calendar" [fixedWidth]="true"></fa-icon>
                    </button>
                </div>
                <div class="col-2 text-center d-flex align-items-center justify-content-center"
                    *ngIf="!match.isReported && !match.isFeatured && !showAppointments" placement="top" container="body"
                    ngbTooltip="Table: {{ match.tableNumber }}">
                    {{ match.tableNumber }}
                </div>
                <div class="col-2 text-center d-flex align-items-center justify-content-center feature-match"
                    *ngIf="!match.isReported && match.isFeatured" placement="top" container="body"
                    ngbTooltip="Table: {{ match.tableNumber }}">
                    feature match
                </div>
                <div class="col-2 text-center d-flex align-items-center justify-content-center" *ngIf="match.isReported"
                    placement="top" container="body" ngbTooltip="Table: {{ match.tableNumber }}">
                    <span class="result-wrapper">
                        {{ match.player1.wins }} - {{ match.player1.draws }} - {{ match.player1.losses }}
                    </span>
                </div>
            </ng-container>
            <ng-template #matchDeleted>
                <div class="col-2 text-center d-flex align-items-center justify-content-center">
                    <div class="btn btn-sm btn-outline-warning">INACTIVE</div>
                </div>
            </ng-template>

            <div class="col text-end player-wrapper d-flex align-items-center justify-content-end">
                <span class="drop-wrapper" *ngIf="match.player2.drop">drops</span>
                <span class="player-name" [id]="match.player2.playerDocId">
                    <ng-container
                        *ngIf="match.player2.playerDocId.includes('temp__') || match.player2.playerDocId.includes('***'); else playerLink2">
                        <span>
                            {{ match.player2.displayName !== '' ? match.player2.displayName : 'Nameless Race' }}
                        </span>
                    </ng-container>
                    <ng-template #playerLink2>
                        <app-player-display-name [playerDocId]="match.player2.playerDocId"
                            [name]="match.player2.displayName"
                            (click)="$event.stopPropagation(); $event.preventDefault()">
                        </app-player-display-name>
                    </ng-template>
                </span>
                <span class="match-points right" *ngIf="match.isType === 'swiss'">{{ match.player2.matchPoints }}</span>
            </div>

            <div class="col flex-grow-0 text-end" [style.minWidth.rem]="5" *ngIf="!hideActionButton">
                <div class="text-warning failed-report"
                    *ngIf="!match.isReported && match.player1.isWinner || !match.isReported && match.player2.isWinner || !match.isReported && match.isDraw"
                    ngbTooltip="Match result filled out but not confirmed by both players" container="body">
                    <fa-icon [fixedWidth]="true" [icon]="icons.warning"></fa-icon>
                </div>
                <div ngbDropdown class="d-inline-block" container="body" placement="left-top">
                    <button class="btn btn-blank btn-sm m-1" id="ratioSelection" ngbDropdownToggle
                        (click)="$event.stopPropagation(); $event.preventDefault()">
                        <fa-icon [fixedWidth]="true" [icon]="icons.actions"></fa-icon>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem
                            (click)="$event.stopPropagation(); $event.preventDefault(); openReportSlip(match, selectedRound)">
                            Report
                        </button>
                        <button ngbDropdownItem
                            (click)="$event.stopPropagation(); $event.preventDefault(); openFeatureMatchConfig(match.docId)">
                            Feature Match Config
                        </button>
                        <ng-template #activeInactiveInfo>
                            <div class="p-2">
                                <div class="text-larger">Active match</div>
                                <div class="text-secondary">
                                    An active match will be visible for the players in all segments of tolaria.app<br>
                                    It can be reported and <b class="text-success">WILL BE</b> taken into the
                                    calculation
                                    the players tiebreakers and results.
                                </div>
                                <div class="text-larger mt-2">Inactive match</div>
                                <div class="text-secondary">
                                    An inactive match will be hidden for the players in all segments of tolaria.app<br>
                                    It <b class="text-warning">WILL NOT</b> be taken into the calculation of the
                                    players
                                    tiebreakers and results.
                                </div>
                            </div>
                        </ng-template>
                        <button ngbDropdownItem
                            (click)="$event.stopPropagation(); $event.preventDefault(); toggleDeletionMark(match)"
                            [ngbTooltip]="activeInactiveInfo" placement="left" container="body"
                            [disabled]="match.deleted && !match.canBeActivated">
                            {{
                              match.deleted !== undefined && match.deleted === true
                                ? 'Activate match'
                                : 'Inactivate Match'
                            }}
                        </button>
                        <div class="my-1 border-bottom border-secondary"></div>
                        <button ngbDropdownItem
                            (click)="$event.stopPropagation(); $event.preventDefault(); deleteMatch(match.docId)"
                            class="text-danger">
                            Delete
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </ng-template>

    <ng-template #mobile>
        <div class="match-item-wrapper d-flex flex-row p-1 px-3 align-items-center border-bottom border-secondary"
            (click)="openReportSlip(match, selectedRound)">

            <div class="segment-wrapper {{ match.hasPlayedEarlier ? 'has-played' : '' }} me-2"
                *ngIf="match.isType === 'batch'">
                <span class="segment-number me-2">{{ match.segmentNumber }}</span>
                <span class="segment-type text-secondary">{{ match.segmentType }}</span>
            </div>

            <div class="players-wrapper me-2 d-flex flex-column flex-grow-1">
                <div class="player-name py-1 d-flex align-items-center" [id]="match.player1.playerDocId">

                    <span class="match-points" *ngIf="match.isType === 'swiss'">
                        {{ match.player1.matchPoints }}
                    </span>

                    <ng-container *ngIf="match.player1.playerDocId.includes('temp__'); else playerLink1">
                        <span>
                            {{ match.player1.displayName !== '' ? match.player1.displayName : 'Nameless Race' }}
                        </span>
                    </ng-container>
                    <ng-template #playerLink1>
                        <app-player-display-name [playerDocId]="match.player1.playerDocId"
                            [name]="match.player1.displayName" [trueName]="true">
                        </app-player-display-name>
                    </ng-template>

                    <span class="text-secondary ms-2 text-small" *ngIf="match.player1.isWinner">wins</span>

                </div>

                <div class="player-name py-1 d-flex align-items-center" [id]="match.player2.playerDocId">

                    <span class="match-points" *ngIf="match.isType === 'swiss'">
                        {{ match.player2.matchPoints }}
                    </span>

                    <ng-container *ngIf="match.player2.playerDocId.includes('temp__'); else playerLink2">
                        <span>
                            {{ match.player2.displayName !== '' ? match.player2.displayName : 'Nameless Race' }}
                        </span>
                    </ng-container>
                    <ng-template #playerLink2>
                        <app-player-display-name [playerDocId]="match.player2.playerDocId"
                            [name]="match.player2.displayName" [trueName]="true">
                        </app-player-display-name>
                    </ng-template>

                    <span class="text-secondary ms-2 text-small" *ngIf="match.player2.isWinner">wins</span>

                </div>
            </div>

            <div class="table-wrapper" *ngIf="!match.isReported && match.tableNumber > 0"
                [innerHtml]="match.tableNumber">
            </div>

            <div class="result-wrapper" *ngIf="match.isReported">{{ match.player1.wins }} - {{ match.player1.draws }} -
                {{ match.player2.wins }}</div>


        </div>
    </ng-template>

    <ng-template let-modal #matchDeletionConfirmation>
        <div class="modal-body">
            <p class="text-warning">Are you sure you want to delete the match?</p>
            <p class="text-secondary">This action cannot be UNDONE, the match will be deleted and the match document erased
                from
                the server.</p>
        </div>
        <div class="modal-footer">
            <button class="btn btn-blank btn-sm text-secondary" (click)="modal.dismiss()">Cancel</button>
            <button class="btn btn-blank btn-sm text-warning" (click)="modal.close(true)">Delete</button>
        </div>
    </ng-template>

</ng-container>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Question, QuizRank, QuizResult, QuizService, QuizStats } from './quiz.service';
import { BehaviorSubject, interval, map, Subject, Subscription, take, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { PlayerDisplayNameComponent } from 'src/app/components';
import { ManaParserPipe } from 'src/app/pipes/mana-parser.pipe';
import * as confetti from 'canvas-confetti'

type State = 'not_started' | 'answering' | 'correct' | 'incorrect'
interface QuizCounters {
  thisMonth: number
  lastMonth: number
  overall: number
}

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    PlayerDisplayNameComponent,
    ManaParserPipe,
  ]
})
export class QuizComponent implements OnInit, OnDestroy {

  public ready$ = new BehaviorSubject<boolean>(false)
  public question$ = new BehaviorSubject<Question>(null)
  public state$ = new BehaviorSubject<State>('not_started')
  public result$ = new BehaviorSubject<QuizResult>(null)
  public questionCounter$ = new BehaviorSubject<number>(null)
  public stats$ = new BehaviorSubject<QuizStats>(null)
  public imageClass = 'center-center'
  private destroyed$ = new Subject<boolean>()

  constructor(
    private readonly quiz: QuizService,
  ) { }

  ngOnInit() {
    this.quiz.serviceReady$.pipe(takeUntil(this.destroyed$)).subscribe(i => this.ready$.next(i))
    this.stats$ = this.quiz.stats$
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  startQuiz(): void {
    this.questionCounter$.next(0)
    this.result$.next(null)
    this.getQuestion()
  }

  getQuestion(): void {
    const question = this.quiz.getQuestion()
    this.imageClass = question.type === 'artist' ? this.setRandomImageClass() : 'center-center'
    this.question$.next(question)
    this.state$.next('answering')
    this.startCountdown()
    this.questionCounter$.next(this.questionCounter$.getValue() + 1)
  }

  answerQuestion(answer: string) {
    this.timerSubscription.unsubscribe()
    const question = this.question$.getValue()
    if (question.correctAnswer === answer) {
      this.state$.next('correct')
      this.quiz.storeCorrectAnswer(question)
    }
    else {
      this.state$.next('incorrect')
      const result = this.quiz.storeResultAndReset()
      if (result.highscore) {
        this.celebrate()
      }
      this.result$.next(result)
      this.questionCounter$.next(null)
    }
  }

  private setRandomImageClass(): string {
    const classes = [
      'grid-pos-1', 'grid-pos-2', 'grid-pos-3', 'grid-pos-4', 'grid-pos-5', 'grid-pos-6', 'grid-pos-7', 'grid-pos-8',
      'grid-pos-9', 'grid-pos-10', 'grid-pos-11', 'grid-pos-12', 'grid-pos-13', 'grid-pos-14', 'grid-pos-15', 'grid-pos-16',
      'grid-pos-17', 'grid-pos-18', 'grid-pos-19', 'grid-pos-20', 'grid-pos-21', 'grid-pos-22', 'grid-pos-23', 'grid-pos-24',
      'grid-pos-25', 'grid-pos-26', 'grid-pos-27', 'grid-pos-28', 'grid-pos-29', 'grid-pos-30', 'grid-pos-31', 'grid-pos-32',
      'grid-pos-33', 'grid-pos-34', 'grid-pos-35', 'grid-pos-36', 'grid-pos-37', // 'grid-pos-38', 'grid-pos-39', 'grid-pos-40', <-- skipping bottom right
      'grid-pos-41', 'grid-pos-42', 'grid-pos-43', 'grid-pos-44', 'grid-pos-45', // 'grid-pos-46', 'grid-pos-47', 'grid-pos-48', <-- skipping bottom right
    ]
    return classes[Math.floor(Math.random() * classes.length)]
  }

  public get highScoreThisMonth(): BehaviorSubject<QuizRank[]> {
    return this.quiz.highScoreThisMonth$
  }

  public get highScoreLastMonth(): BehaviorSubject<QuizRank[]> {
    return this.quiz.highScoreLastMonth$
  }

  public get highScoreOverall(): BehaviorSubject<QuizRank[]> {
    return this.quiz.highScoreOverAll$
  }

  public progressCounter$ = new BehaviorSubject<number>(null)
  public timerSubscription: Subscription | null = null;

  private startCountdown() {
    // Cancel any existing subscription
    if (this.timerSubscription) { this.timerSubscription.unsubscribe() }

    this.timerSubscription = this.startTimer(100).subscribe({
      next: (count) => this.progressCounter$.next(count),
      complete: () => this.answerQuestion('')
    })
  }

  private startTimer(duration: number) {
    return interval(200).pipe(
      map(elapsed => elapsed),
      take(duration + 1)  // Complete the observable after the duration has elapsed
    )
  }

  private celebrate(): void {
    let element = document.getElementById('confettiCanvase')
      element.classList.remove('hidden')
      if (element) {

        const confettiCanon = confetti.create(element, {
          resize: true,
        })
        const count = 200
        const defaults = {
          origin: { y: 0.75 }
        }
        const fire = (particleRatio, opts) => {
          confettiCanon({
            ...defaults,
            ...opts,
            particleCount: Math.floor(count * particleRatio)
          })
        }
        
        fire(0.25, {
          spread: 26,
          startVelocity: 55,
        })
        fire(0.2, {
          spread: 60,
        })
        fire(0.35, {
          spread: 100,
          decay: 0.91,
          scalar: 0.8
        })
        fire(0.1, {
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2
        })
        fire(0.1, {
          spread: 120,
          startVelocity: 45,
        })

      }
  }

}

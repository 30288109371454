import { Component, OnInit } from '@angular/core';
import { faBug, faCogs, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { MainMenuItem, MainMenuService, MainMenuSubItem } from './main-menu.service';
import { Router } from '@angular/router';
import { PayDonationComponent } from 'src/app/payment/pay-donation/pay-donation.component';
import { ReportIssueComponent } from '../report-issue/report-issue.component';
import { MessageModalService } from '../../modals/message-modal/message-modal.service';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

  public adminIcon = faCogs
  public faTimes = faTimes
  public bug = faBug

  constructor(
    private readonly offcanvasService: NgbOffcanvas,
    private readonly modalService: NgbModal,
    private readonly router: Router,
    public readonly menuService: MainMenuService,
    private readonly confirm: MessageModalService,
    private readonly auth: AuthService,
  ) { }

  ngOnInit(): void {
  }

  onClose(): void {
    this.offcanvasService.dismiss()
  }

  public get isAdmin(): boolean {
    return this.auth.user
      ? this.auth.user.role === 'admin'
      : false
  }

  public toAdmin(): void {
    this.offcanvasService.dismiss()
    this.router.navigate(['admin'])
  }

  public onMenuItemClick(item: MainMenuItem | MainMenuSubItem): void {

    let clickedItem: MainMenuItem | undefined

    if (item.hasOwnProperty('subItems') && item.hasOwnProperty('expandable')) {
      clickedItem = item as MainMenuItem
      if (clickedItem.expandable) {
        this.menuService.toggleExpanded(item)
        return
      }
    }

    this.offcanvasService.dismiss()

    console.log(item)
    if (item.link === 'events') {
      this.confirm.open({
        type: 'info',
        title: `Where do you wan't to go?`,
        message: `The Tolaria event/tournament section is going through some changes.
        <br>In the interim, both the NEW and the OLD version of this section are available to make sure organizers can 
        manage their events/tournaments without any issues.
        <br><br>Where do you wanna go?`,
        buttons: [
          {
            type: 'dismiss',
            text: 'Cancel',
            value: '',
          },
          {
            type: 'close',
            text: 'Old',
            value: 'old',
            class: 'btn-blank'
          },
          {
            type: 'close',
            text: 'New',
            value: 'new',
            class: 'btn-blank'
          },
        ]
      }).then(result => {
        if (result === 'new') {
          this.router.navigate(['events/grid'])
        }
        if (result === 'old') {
          this.router.navigate(['events-old'])
        }
      })
    }
    else {
      this.router.navigate([item.link])
    }

  }

  public onDonateClick(): void {
    const componentRef = this.modalService.open(PayDonationComponent, {
      size: 'sm',
      keyboard: true,
      centered: false,
      backdrop: 'static',
    })
    componentRef.componentInstance.modalView = true
    componentRef.componentInstance.currency = 'USD'
    componentRef.componentInstance.showText = false
    componentRef.componentInstance.showTypeSelector = true
    this.offcanvasService.dismiss()
  }

  public onReportIssueClick(): void {
    const componentRef = this.modalService.open(ReportIssueComponent, {
      size: 'md',
      keyboard: true,
      centered: false,
      backdrop: 'static',
    })
    componentRef.componentInstance.label = 'bug'
    this.offcanvasService.dismiss()
  }

  public get activeRoute(): string {
    return this.router.url.replace(/\(.+?\)/, '').split('/')[1]
  }

}

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ILeaguePlayer } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-league-points',
  templateUrl: './league-points.component.html',
  styleUrls: ['./league-points.component.css']
})
export class LeaguePointsComponent {
  @Input() player: ILeaguePlayer;

  constructor(public modal: NgbActiveModal) { }

}

import { faAt } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faFacebook, faFacebookMessenger, faGithub, faInstagram, faSkype, faSlack, faTwitch, faTwitter, faVimeo, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public ckeConfig = {
    allowedContent: false,
    forcePasteAsPlainText: true,
    toolbarGroups: [
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
      { name: 'forms', groups: ['forms'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'links', groups: ['links'] },
      { name: 'insert', groups: ['insert'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'others', groups: ['others'] },
      { name: 'about', groups: ['about'] }
    ],
    removeButtons: 'Source,Save,NewPage,ExportPdf,Preview,Print,Templates,Cut,Undo,Redo,Copy,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,BidiLtr,BidiRtl,Language,Link,Unlink,Anchor,Image,Flash,HorizontalRule,Smiley,PageBreak,SpecialChar,Iframe,FontSize,Font,TextColor,BGColor,ShowBlocks,Maximize,About,Styles'
  }

  public handleIcons = {
    facebook: faFacebook,
    messenger: faFacebookMessenger,
    skype: faSkype,
    slack: faSlack,
    discord: faDiscord,
    twitter: faTwitter,
    twitch: faTwitch,
    instagram: faInstagram,
    youtube: faYoutube,
    vimeo: faVimeo,
    whatsapp: faWhatsapp,
    github: faGithub,
    email: faAt,
  }

  constructor() { }

}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventGridItemComponent } from 'src/app/private/events/components/event-grid-item/event-grid-item.component';
import { EventListingPipe } from 'src/app/private/events/pipes/event-listing.pipe';
import { EventListingService, IEventTemplate } from 'src/app/private/events/services/event-listing.service';

@Component({
  selector: 'app-tournament-picker',
  standalone: true,
  imports: [
    CommonModule,
    EventGridItemComponent,
    FormsModule,
    EventListingPipe,
  ],
  templateUrl: './tournament-picker.component.html',
  styleUrls: ['./tournament-picker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TournamentPickerComponent {

  constructor(
    private readonly modal: NgbActiveModal,
    public readonly listing: EventListingService,
  ) {}

  onCancel(): void {
    this.modal.dismiss()
  }

  onEventSelected(event: IEventTemplate): void {
    this.modal.close(event)
  }

}

import { CommonModule } from '@angular/common'
import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MessageItem, MessageListService } from '../../services/message-list.service'
import { BehaviorSubject, Subject, Subscription } from 'rxjs'
import { ChatMessageComponent } from '../message-types/chat-message/chat-message.component'
import { TimestampClockComponent } from 'src/app/components/app-structure/timestamp-clock/timestamp-clock.component'
import { MatchInvitationComponent } from '../message-types/match-invitation/match-invitation.component'
import { MatchAppointmentComponent } from '../message-types/match-appointment/match-appointment.component'
import { TolariaEmojiPickerButtonComponent } from '../tolaria-emoji-picker/tolaria-emoji-picker-button/tolaria-emoji-picker-button.component'
import { TolariaWysiwygMention } from '../tolaria-wysiwyg/tolaria-wysiwyg.interfaces'
import { faAngleDown, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { MessageGroupPeopleComponent } from './message-group-people/message-group-people.component'
import { PlayerAvatarComponent } from 'src/app/components/players/player-avatar/player-avatar.component'
import { NgbDropdownModule, NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
import { MessageGroupItem, MessageGroupsService } from '../../services/message-groups.service'
import { TolariaPlayerMentionComponent } from '../tolaria-wysiwyg/custom-elements/tolaria-player-mention/tolaria-player-mention.component'
import { EventAnnouncementComponent } from '../message-types/event-announcement/event-announcement.component'
import { MessageGroupInfoComponent } from './message-group-info/message-group-info.component'
import { PayDonationComponent } from 'src/app/payment/pay-donation/pay-donation.component'
import { ScryfallImageComponent } from 'src/app/private/social/messages/components/message-types/scryfall-image/scryfall-image.component'
import { ScryfallRulesComponent } from 'src/app/private/social/messages/components/message-types/scryfall-rules/scryfall-rules.component'
import { EventInvitationComponent } from '../message-types/event-invitation/event-invitation.component'

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ChatMessageComponent,
    MatchInvitationComponent,
    MatchAppointmentComponent,
    EventAnnouncementComponent,
    EventInvitationComponent,
    TimestampClockComponent,
    TolariaEmojiPickerButtonComponent,
    ScryfallImageComponent,
    ScryfallRulesComponent,
    FontAwesomeModule,
    MessageGroupPeopleComponent,
    PlayerAvatarComponent,
    NgbTooltipModule,
    NgbDropdownModule,
    TolariaPlayerMentionComponent,
    MessageGroupInfoComponent,
  ],
})
export class MessageListComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  @Input() debug: boolean = false
  @Input() matchChat: boolean = false
  @Input() matchDocId: string = ''
  @Input()
  set messageGroupDocId(val: string) {
    this.groupDocId = val
    this.logger('log', 'MessageListComponent:: message group document id emitted -> calling init')
    if (this.componentInitialized) {
      this.init()
    }
    else {
      this.logger('log', 'MessageListComponent:: component not ready, waiting for ngOnInit')
    }
  }
  @Input()
  set spectatorMode(val: boolean) {
    if (val === undefined) {
      this.spectator = false
    }
    else {
      this.logger('log', 'MessageListComponent:: spectator mode emitted -> calling init')
      this.spectator = val
      if (this.componentInitialized) {
        this.init()
      }
      else {
        this.logger('log', 'MessageListComponent:: component not ready, waiting for ngOnInit')
      }
    }
  }

  @ViewChild('scrollWrapper', { static: true }) scrollWrapper: ElementRef
  @ViewChild('dummyScroller', { static: true }) dummyScroller: ElementRef
  @ViewChildren('message') messageElements: QueryList<ElementRef>

  private componentInitialized = false
  private spectator = false
  private destroyed$ = new Subject<boolean>()
  private scrollToRepliedMessage: string = null
  private subscription: Subscription

  public atBottom = true
  public scrollTop$ = new BehaviorSubject<number>(0)
  public showArchiveMessage: boolean = false
  public groupDocId: string = null
  public group$ = new BehaviorSubject<MessageGroupItem>(null)
  public messages$ = new BehaviorSubject<MessageItem[]>(null)
  public loadingMore$ = new BehaviorSubject<boolean>(false)
  public mentionList: TolariaWysiwygMention[] = []
  public isLoadingRepliedTo$ = new BehaviorSubject<boolean>(false)
  public tournamentIcon = faTrophy
  public angleDown = faAngleDown

  private _isRunningInit: boolean = false


  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly list: MessageListService,
    private readonly group: MessageGroupsService,
    private readonly modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.logger('log', 'MessageListComponent:: ngOnInit', {
      matchChat: this.matchChat,
      matchDocId: this.matchDocId,
      messageGroupDocId: this.groupDocId,
      spectatorMode: this.spectatorMode,
    })
    this.init()
    this.componentInitialized = true
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    this.destroyed$.next(true)
  }

  ngAfterViewInit(): void {
  }

  ngAfterViewChecked(): void {
    if (this.scrollPositionBottom) {
      if (this.scrollWrapper.nativeElement.scrollTop !== (this.scrollWrapper.nativeElement.scrollHeight - this.scrollWrapper.nativeElement.clientHeight)) {
        this.logger('log', 'scrolling -> to bottom')
        this.scrollWrapper.nativeElement.scrollTop = this.scrollWrapper.nativeElement.scrollHeight
      }
    }
  }

  private logger(type: string, value: string, data?: any) {
    if (this.debug) {
      if (data) {
        console[type](`[TolariaEmojiTypeheadModule] -> ${value}`, data)
      }
      else {
        console[type](`[TolariaEmojiTypeheadModule] -> ${value}`)
      }
    }
  }

  public onStartSupporting(): void {
    const componentRef = this.modalService.open(PayDonationComponent, {
      size: 'sm',
      keyboard: true,
      centered: false,
      backdrop: 'static',
    })
    componentRef.componentInstance.modalView = true
    componentRef.componentInstance.currency = 'USD'
    componentRef.componentInstance.showText = false
    componentRef.componentInstance.showTypeSelector = true
  }

  public onScollToEmitted(messageDocId: string): void {
    this.scrollToRepliedMessage = messageDocId
    this.isLoadingRepliedTo$.next(true)
    this.logger('log', 'MessageListComponent:: reply to message clicked, looking for element')
    const el = document.getElementById(messageDocId)
    this.logger('log', 'MessageListComponent:: element search result ->', el)
    if (el) {
      this.isLoadingRepliedTo$.next(false)
      this.scrollToRepliedMessage = null
      el.scrollIntoView()
    }
    else {
      this.logger('log', 'MessageListComponent:: handle missing reply to message')
      this.list.loadMoreMessages({
        groupId: this.groupDocId,
        matchChat: this.matchChat,
        matchDocId: this.matchDocId,
        spectatorMode: this.spectator,
      })
    }
  }

  private async init() {

    if (this._isRunningInit) {
      return
    }

    this._isRunningInit = true
    this.logger('log', 'MessageListComponent:: initialize component')
    this.logger('log', 'MessageListComponent:: current route -> ', this.router.url)
    // clear messages and group
    this.group$.next(null)
    this.messages$.next(null)

    if (this.groupDocId === undefined || this.groupDocId === null) {
      this.logger('log', 'MessageListComponent:: input not set, lets check the route')
      if (this.router.url.includes('messages')) {
        this.logger('log', 'MessageListComponent:: route is messages, lets subscribe to the params')
        this.route.params.subscribe(async (route) => {
          this.logger('log', 'MessageListComponent:: route params emitted new value', route)
          if (route.id !== '') {
            this.groupDocId = route.id
            this.logger('log', 'MessageListComponent:: calling setup')
            this.setup()
          }
        })
      }
    }
    else {
      this.logger('log', 'MessageListComponent:: input set, lets fetch messages based on input', this.groupDocId)
      this.logger('log', 'MessageListComponent:: calling setup')
      this.setup()
    }
  }

  private async setup() {

    const exclude = [
      'cardOfTheDay',
      'casualMatch',
    ]

    this.scrollPositionBottom = true

    this.loadingMore$.next(false)

    if (this.groupDocId !== undefined && exclude.some(str => this.groupDocId.includes(str))) {
      this.logger('log', 'MessageListComponent:: group found in the exclusion list, skip fetching the group')
    }
    else {
      const group = await this.group.getGroupById(this.groupDocId)
      this.logger('log', 'MessageListComponent:: group fetched', group)
      this.group$.next(group.data)
    }

    const messageObserver = await this.list.getMessageObserver({
      groupId: this.groupDocId,
      matchChat: this.matchChat,
      matchDocId: this.matchDocId,
      spectatorMode: this.spectator,
    }, this.mentionList)

    if (this.subscription) {
      this.subscription.unsubscribe()
    }

    this.subscription = messageObserver.subscribe(data => {
      this.logger('log', 'MessgeListComponent:: messageObserver emitted a new value -> ', data)
      this.loadingMore$.next(false)
      this.messages$.next(data)
      this.showArchiveMessage = data.find(i => i.showArchivedBlur) !== undefined
      if (this.isLoadingRepliedTo$.getValue() && this.scrollToRepliedMessage !== null) {
        this.logger('log', 'MessgeListComponent:: previous pressed reply to found', this.scrollToRepliedMessage)
        if (data.find(i => i.docId === this.scrollToRepliedMessage)) {
          this.logger('log', 'MessgeListComponent:: the message was found in the list of messages')
          this.isLoadingRepliedTo$.next(false)
          this.scrollToMessage()
        }
        else {
          this.logger('log', 'MessgeListComponent:: the message was NOT found in the list of messages!')
          let moreExists = this.list.loadMoreMessages({
            groupId: this.groupDocId,
            matchChat: this.matchChat,
            matchDocId: this.matchDocId,
            spectatorMode: this.spectator,
          })
          if (!moreExists) {
            this.logger('log', 'MessgeListComponent:: seems the max limit of messages already loaded')
            this.isLoadingRepliedTo$.next(false)
          }
        }
      }
    })

    this._isRunningInit = false

  }

  public tracker(id: number, doc: MessageItem): string {
    return doc.docId
  }

  private currentScrollPosition: number = 0
  public scrollPositionBottom: boolean = true
  onScroll(event: any) {
    if (this.currentScrollPosition === undefined) {
      this.currentScrollPosition = event.target.scrollTop
      return
    }

    const scroll = {
      height: event.target.clientHeight,
      top: event.target.scrollTop,
      bottom: event.target.scrollHeight - (event.target.scrollTop + event.target.clientHeight),
      contentHeight: event.target.children[0].clientHeight,
      topPercentage: ((event.target.scrollTop / event.target.children[0].clientHeight) * 100).toFixed(0) + '%',
      topCheck: (event.target.scrollTop / event.target.children[0].clientHeight) * 100,
      direction: event.target.scrollTop > this.currentScrollPosition
        ? 'down'
        : 'up',
      atTop: event.target.scrollTop === 0,
      atBottom: (event.target.scrollTop + event.target.clientHeight) === event.target.scrollHeight,
    }

    const inView = (elementRef: ElementRef) => {
      const rect = elementRef.nativeElement.getBoundingClientRect()
      const topShown = rect.top >= 0
      const bottomShown = (rect.top + rect.height) >= 0
      return topShown || bottomShown
    }
    const messageElements = this.messageElements.filter(i => i.nativeElement.className.includes('load-more-if-visible') && inView(i))

    if (scroll.direction === 'up' && messageElements.length > 0 && this.loadingMore$.getValue() === false) {
      let loading = this.list.loadMoreMessages({
        groupId: this.groupDocId,
        matchChat: this.matchChat,
        matchDocId: this.matchDocId,
        spectatorMode: this.spectator,
      })
      this.loadingMore$.next(loading)
    }

    this.scrollPositionBottom = scroll.atBottom
    this.currentScrollPosition = event.target.scrollTop
  }

  private scrollToMessage() {
    this.logger('log', 'MessgeListComponent:: scrollToMessage')
    let element = document.getElementById(this.scrollToRepliedMessage)
    if (element === null) {
      this.logger('log', 'MessgeListComponent:: scrollToMessage -> element not found, call again in 250ms')
      setTimeout(() => this.scrollToMessage(), 250)
    }
    else {
      this.logger('log', 'MessgeListComponent:: scrollToMessage -> element found, scoll to it')
      this.scrollToRepliedMessage = null
      setTimeout(() => element.scrollIntoView(), 10)
    }
  }

  public scrollToBottom(forceScroll: boolean = false): void {
    this.logger('log', 'should scroll to bottom', this.scrollPositionBottom)
    if ((this.scrollPositionBottom !== undefined && this.scrollPositionBottom) || forceScroll) {
      setTimeout(() => {
        this.scrollWrapper.nativeElement.scrollTop = this.scrollWrapper.nativeElement.scrollHeight
        // this.dummyScroller.nativeElement.scrollIntoView(false, { behavior: 'instant', block: 'end' })
      }, 0)
    }
  }

  public scrollTo(where: 'yesterday' | 'last-week' | 'last-month'): void {
    document.getElementById(where).scrollIntoView()
  }

  public get messageLoad(): number[] {
    return [...Array(10).keys()]
  }

  public get scrollWrapperTop(): number {
    if (this.scrollPositionBottom) {
      return this.scrollWrapper.nativeElement.scrollHeight
    }
    return this.scrollWrapper.nativeElement.scrollTop
  }

}




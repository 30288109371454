<div class="modal-body p-0">
  <ng-container *ngIf="!error; else showError">
    <ng-container *ngIf="charge$ | async as charge; else loader">

      <div class="d-flex flex-column p-5">

        <div class="text-xlarge text-center mt-2 mb-5" *ngIf="!donation">PAYMENT SUCCESSFUL</div>
        <div class="d-flex flex-column mt-2 mb-5 align-items-center justify-content-center" *ngIf="donation">
          <div class="text-jumbo">🙏</div>
          <div class="text-xlarge text-center">THANKS FOR YOUR DONATION</div>
        </div>
        <!-- <div class="text-large text-secondary text-center mb-5">Receipt #{{ charge.receipt_number }}</div> -->

        <div class="d-flex text-secondary">
          <div class="flex-grow-1 text-start d-flex flex-column">
            <div class=" text-bold">AMOUNT PAID</div>
            <div>{{ (charge.amount_captured / 100).toFixed(2) }}{{ charge.currency.toUpperCase() }}</div>
          </div>
          <div class="flex-grow-1 text-center d-flex flex-column">
            <div class="text-bold">DATE PAID</div>
            <div>{{ (charge.created * 1000) | date : 'mediumDate' }}</div>
          </div>
          <div class="flex-grow-1 text-end">
            <div class="text-bold">PAYMENT METHOD</div>
            <div *ngIf="charge.payment_method_details.type === 'card'; else otherPaymentMethod">
              <fa-icon [icon]="icons[charge.payment_method_details[charge.payment_method_details.type].brand]" [fixedWidth]="true" class="mx-2"></fa-icon>
              <span>**** **** **** {{ charge.payment_method_details[charge.payment_method_details.type].last4 }}</span>
            </div>
            <ng-template #otherPaymentMethod>
              <div class="">
                <fa-icon [icon]="icons[charge.payment_method_details[charge.payment_method_details.type].brand]" [fixedWidth]="true"></fa-icon>
              </div>
            </ng-template>
          </div>
        </div>

        <!-- <div class="d-flex text-secondary text-bold mt-5">
          <div class="flex-grow-1">SUMMARY</div>
        </div>
        <div class="px-2 wrapper my-2">
          <div class="my-3 d-flex">
            <div class="flex-grow-1">{{ charge.metadata.productType }} x 1</div>
            <div class="flex-grow-0">{{ (charge.amount_captured / 100).toFixed(2) }}{{ charge.currency.toUpperCase() }}</div>
          </div>
          <div class="border-bottom border-secondary"></div>
          <div class="my-3 d-flex text-bold">
            <div class="flex-grow-1">Amount carged</div>
            <div class="flex-grow-0">{{ (charge.amount_captured / 100).toFixed(2) }}{{ charge.currency.toUpperCase() }}</div>
          </div>
        </div> -->

        <div class="mt-3 pt-3 text-center border-top">
          If you have any questions, contact us at <a href="mailto:slanfan@gmail.com">slanfan@gmail.com</a> or call at <a href="tel:+46701740080">+46 70 174 00 80</a>.
        </div>


      </div>

      <div class="btn btn-sm btn-blank w-100 mt-5 mb-2" (click)="close()">Close</div>

    </ng-container>

    <ng-template #loader>
      <div class="p-5 d-flex align-items-center justify-content-center">
        <app-busy-spinner></app-busy-spinner>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #showError>
    <div class="text-larger border-bottom border-secondary p-3">Thank you for your purchase</div>
    <div class="text-danger p-3 text-medium">Unfortunately something went wrong when trying to fetch the payment information, try viewing the receipt from your purchase history to see details</div>
  </ng-template>
</div>

import { AuthService } from 'src/app/services';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DatePipe, NgClass } from '@angular/common';

@Component({
  selector: 'app-timestamp-clock',
  templateUrl: './timestamp-clock.component.html',
  styleUrls: ['./timestamp-clock.component.css'],
  standalone: true,
  imports: [
    DatePipe,
    NgClass,
  ],
  encapsulation: ViewEncapsulation.None
})
export class TimestampClockComponent {
  @Input() timestamp: number;
  @Input() timestring: string
  @Input() displayType: string = 'date-time';
  @Input() seconds: boolean = false;

  constructor(
    private auth: AuthService,
  ) {}

  public get dateFilter(): string {
    switch (this.displayType) {
      case 'time':
        return this.auth.user.settings.clock24 ? 'HH:mm' : 'h:mm a';
      case 'year-short':
        return 'yy';
      case 'year':
        return 'yyyy';
      case 'month-short':
        return 'MMM';
      case 'day-short':
        return 'EEE';
      case 'date-single':
        return 'd';
      case 'full-time':
        return this.auth.user.settings.clock24 ? 'HH:mm:ss' : 'h:mm:ss a';
      case 'date':
        return 'YYYY-MM-dd';
      case 'date-long':
        const date = new Date()
        if (date.getFullYear() < this.timestampDate.getFullYear()) {
          return `EEEE, MMMM d'${this.ordinalSuffix}', y`
        }
        return `EEEE, MMMM d'${this.ordinalSuffix}'`
      case 'date-time':
        return this.auth.user.settings.clock24 ? 'YYYY-MM-dd HH:mm' : 'YYYY-MM-dd h:mm a';
      case 'timeTodayOrDate':
        return this.timeTodayOrDate;
      case 'mini-date':
        return this.auth.user.settings.clock24 ? 'MMM d, y' : 'EEE, MMM d, y';
      case 'small-date':
        return this.auth.user.settings.clock24 ? 'EEE, MMM d, y' : 'EEE, MMM d, y';
      case 'small-date-mono':
        return this.auth.user.settings.clock24 ? 'EEE, MMM dd, y' : 'EEE, MMM dd, y';
      case 'compact-mono':
        return this.auth.user.settings.clock24 ? 'EEE, MMM dd, y, HH:mm' : 'EEE, MMM dd, y, hh:mm a';
      case 'compact':
        return this.auth.user.settings.clock24 ? 'EEE, MMM d, y, HH:mm' : 'EEE, MMM d, y, hh:mm a';
      case 'full':
        return this.auth.user.settings.clock24 ? 'EEEE, MMMM d, y, HH:mm' : 'EEEE, MMMM d, y, hh:mm a';

    }
  }

  public get clockType(): string {
    if (this.auth.user) {
      return this.auth.user.settings.clock24 ? 'hour-clock-24' : 'hour-clock-12'
    }
    else {
      return ''
    }
  }

  private get ordinalSuffix() {
    const day = this.timestampDate.getDate()
    if (day >= 11 && day <= 13) {
      return 'th'
    }
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  private get timestampDate(): Date {
    return this.seconds ? new Date(this.timestamp * 1000) : new Date(this.timestamp)
  }

  private get timestampNumber(): number {
    if (this.timestamp !== undefined) {
      return this.timestamp
    }
    if (this.timestring !== undefined) {
      return new Date(this.timestring).getTime()
    }
  }

  public get parsedTimestamp(): number | Date {
    return this.seconds ? this.timestampNumber * 1000 : this.timestampNumber;
  }

  private get timeTodayOrDate(): string {
    const date = new Date(this.parsedTimestamp).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);
    return date === today ? 'HH:mm' : 'MMM dd';
  }

}

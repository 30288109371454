<div class="modal-header d-flex align-items-center">
  <div class="text-large">{{ edit ? 'Edit link' : 'Add link' }}</div>
  <button class="btn btn-blank"></button>
</div>
<div class="modal-body">
  <div class="mb-3">
    <label class="form-label">Text</label>
    <input type="text" class="form-control"
      [(ngModel)]="textString"
      (keydown)="validateForm($event)"
      (keydown.enter)="onInsertPress($event)">
  </div>
  <div class="mb-3">
    <label class="form-label">Link</label>
    <input #linkInput type="text" class="form-control"
      [(ngModel)]="urlString"
      (keydown)="validateForm($event)"
      (keydown.enter)="onInsertPress($event)">
    <div class="invalid-feedback">Url not valid, please enter a valid url</div>
  </div>
</div>
<div class="modal-footer d-flex align-items-center justify-content-end gap-2">
  <button class="btn btn-blank" (click)="onCancelPress()">Cancel</button>
  <button class="btn btn-danger" (click)="onRemovePress()" *ngIf="edit">Remove</button>
  <button class="btn {{ disableForm ? 'btn-secondary' : 'btn-success' }}" (click)="onInsertPress()" [disabled]="disableForm">Save</button>
</div>

<ng-container *ngIf="pub$ | async as pub">

  <div class="pub-wall" [ngStyle]="{'background-image': 'url(' + pub.imageUri + ')'}"></div>

  <div class="pub-floor d-flex flex-row">

    <div class="challenge d-flex flex-row align-items-center" *ngIf="pub.battle.status === 'pending'">
      <span class="flex-grow-1">
        <app-player-display-name [playerDocId]="pub.battle.challengerDocId"></app-player-display-name> wants to battle
      </span>
      <div class="btn btn-sm btn-blank ms-3" (click)="acceptChallenge()" *ngIf="pub.battle.challengerDocId !== myPlayerId">Accept</div>
      <div class="btn btn-sm btn-blank ms-3" (click)="cancelChallenge()" *ngIf="pub.battle.challengerDocId === myPlayerId">Cancel</div>
    </div>

    <div class="webrtc-section p-3 flex-grow-1 d-flex flex-row">

      <div class="battlefield" *ngIf="pub.battle.status === 'active'">

        <div class="webrtc-camera-wrapper" [id]="pub.battle.challengerDocId">

          <ng-container *ngIf="pub.battle.challengerDocId === myPlayerId; else avatarChallenger">
            <video [srcObject]="localStream$ | async" id="your-stream" autoplay [muted]="true" playsinline></video>
          </ng-container>

          <ng-template #avatarChallenger>
            <ng-container *ngIf="getPlayerStream(pub.battle.challengerDocId) as stream">
              <video [srcObject]="stream.stream" [id]="pub.battle.challengerDocId" autoplay playsinline></video>
            </ng-container>
          </ng-template>

          <div class="avatar-overlay px-2 py-1 text-small">
            <app-player-display-name [playerDocId]="pub.battle.challengerDocId"></app-player-display-name>
          </div>

          <div class="game-wins">
            <div class="marker" *ngFor="let win of challengerGameWins"></div>
          </div>

          <div class="life-counter">
            {{ challengerLife }}
          </div>

        </div>


        <div class="webrtc-camera-wrapper" [id]="pub.battle.opponentDocId">

          <ng-container *ngIf="pub.battle.opponentDocId === myPlayerId; else avatarOpponent">
            <video [srcObject]="localStream$ | async" id="your-stream" autoplay [muted]="true" playsinline></video>
          </ng-container>

          <ng-template #avatarOpponent>
            <ng-container *ngIf="getPlayerStream(pub.battle.opponentDocId) as stream">
              <video [srcObject]="stream.stream" [id]="pub.battle.opponentDocId" autoplay playsinline></video>
            </ng-container>
          </ng-template>

          <div class="avatar-overlay opponent px-2 py-1 text-small">
            <app-player-display-name [playerDocId]="pub.battle.opponentDocId"></app-player-display-name>
          </div>

          <div class="game-wins opponent">
            <div class="marker" *ngFor="let win of opponentGameWins"></div>
          </div>

          <div class="life-counter opponent">
            {{ opponentLife }}
          </div>

        </div>

      </div>


      <div class="webrtc-controls d-flex flex-column justify-content-center pe-3">
        <app-media-controls orientation="vertical"></app-media-controls>
        <button class="btn-challenge" (click)="pub.battle.status === 'active' ? cancelChallenge() : initiateChallenge()" [disabled]="disableChallenge" [ngbTooltip]="challengeTooltip" container="body">
          <fa-icon [icon]="challengeIcon" [fixedWidth]="true"></fa-icon>
        </button>
        <button class="btn-challenge btn-leave mt-5" (click)="leave()" ngbTooltip="Leave the pub" container="body">
          <fa-icon [icon]="icons.leave" [fixedWidth]="true"></fa-icon>
        </button>
      </div>


      <ng-container *ngIf="activeUsersInRoom$ | async as playersInRoom">

        <div class="webrtc-elements flex-grow-1 users-{{ playersInRoom.length }}" [ngClass]="{ 'battle-mode': pub.battle.status === 'active' }">

          <ng-container *ngFor="let player of playersInRoom; let i = index; trackBy : trackByPlayerDocId">

            <div class="webrtc-camera-wrapper" [id]="player.playerDocId" [ngClass]="{ 'disconnected' : !player.online }" *ngIf="pub.battle.status !== 'active' || pub.battle.status === 'active' && player.playerDocId !== pub.battle.challengerDocId && player.playerDocId !== pub.battle.opponentDocId">

              <ng-container *ngIf="player.playerDocId === myPlayerId; else avatar">
                <video [srcObject]="localStream$ | async" id="your-stream" autoplay [muted]="true" playsinline></video>
              </ng-container>

              <ng-template #avatar>
                <ng-container *ngIf="getPlayerStream(player.playerDocId) as stream">
                  <video [srcObject]="stream.stream" [id]="player.playerDocId" autoplay playsinline [volume]="player.volume / 10" [muted]="player.muted"></video>


                  <!-- <div class="btn btn-sm btn-main" (click)="player.muted = !player.muted">mute</div>
                    <npn-slider hide-tooltip hide-values [step]="1" [multiRange]="false" [min]="0" [max]="10" [minSelected]="player.volume" [maxSelected]="player.volume" (onChange)="player.volume = $event[0]"
                    class="w-100">
                  </npn-slider> -->

                </ng-container>
              </ng-template>

              <div class="avatar-overlay px-2 py-1 text-small">
                <app-player-display-name [playerDocId]="player.playerDocId"></app-player-display-name>
              </div>

            </div>

          </ng-container>

        </div>

      </ng-container>


    </div>


  </div>


</ng-container>

<div class="card card-xlarge no-hover max-height-100 min-height-100" [style.marginTop.px]="-1">
    <ng-container *ngIf="card$ | async as card">

        <div class="card-body no-hover d-flex align-items-center flex-grow-0 px-3 py-2">
            <div class="flex-grow-1 text-large">{{ card.name }}</div>
            <div class="btn btn-sm btn-blank" [routerLink]="['/card-factory']">
                <fa-icon [icon]="icons.close" [fixedWidth]="true"></fa-icon>
            </div>
        </div>

        <div class="card-body no-hover d-flex flex-column flex-grow-0">

            <div class="row">
                <div class="col-4 text-secondary">
                    Creator:
                </div>
                <div class="col">
                    <app-player-display-name [playerDocId]="card.playerDocId"></app-player-display-name>
                </div>
            </div>
            
            <div class="row mt-2">
                <div class="col-4 text-secondary">
                    Created:
                </div>
                <div class="col text-monospaced">
                    <app-timestamp-clock [timestamp]="card.createdAt" [seconds]="true" displayType="compact"></app-timestamp-clock> 
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-4 text-secondary">
                    Published: 
                </div>
                <div class="col text-monospaced">
                    <app-timestamp-clock [timestamp]="card.publishedAt" [seconds]="true" displayType="compact"></app-timestamp-clock> 
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-4 text-secondary">
                    Likes: 
                </div>
                <div class="col text-monospaced">
                    {{ card.likes }}
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-4 text-secondary">
                    Comments: 
                </div>
                <div class="col text-monospaced">
                    {{ card.comments }}
                </div>
            </div>

        </div>

        <div class="card-header no-hover flex-grow-1 scrollable p-1">
            <ng-container *ngIf="comments$ | async as comments;">
                <ng-container *ngFor="let comment of comments; trackBy: cfTracker">
                    <div class="card-comment mb-1 d-flex flex-row p-2">
                        <app-player-avatar class="me-3" [playerDocId]="comment.playerDocId"></app-player-avatar>
                        <div class="card-comment-inner flex-grow-1">
                            <div class="text" [innerHTML]="comment.text"></div>
                            <app-timestamp-clock [timestamp]="comment.createdAt" [seconds]="true" displayType="compact" class="text-smaller text-secondary text-monospaced"></app-timestamp-clock>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <div class="card-footer border-top no-hover flex-grow-0">
            <div class="input-group">
                <textarea [(ngModel)]="commentText" class="form-control form-control-sm" rows="4" [style.resize]="'none'"></textarea>
                <div class="input-group-append">
                    <button class="btn btn-sm btn-outline-secondary" (click)="addComment()">Send</button>
                </div>
            </div>
        </div>

    </ng-container>
</div>
import { MatchService } from './../../../../services/match.service';
import { MediaStreamsService } from './../../../../services/media-streams.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewChecked, HostListener, AfterViewInit } from '@angular/core';
import { faArrowsAltH, faArrowsAltV } from '@fortawesome/free-solid-svg-icons';

export interface IVideoDimensions {
  width: number;
  height: number;
}
@Component({
  selector: 'app-player-video',
  templateUrl: './player-video.component.html',
  styleUrls: ['./player-video.component.css']
})
export class PlayerVideoComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @ViewChild('videoWrapper', { static: true }) videoWrapper: ElementRef;
  @ViewChild('videoElement', { static: false }) videoElement: ElementRef;
  @Input() videoStream$: Observable<MediaStream>;
  @Input() matchDocId: string;
  @Input() player: string;
  @Input() isThisUser: boolean;
  @Input() focused: boolean;
  @Input() placement: string;
  @Input() muteVideo?: boolean;
  @Input() spectator?: boolean;
  @Input()
  set resizeTrigger(val: boolean) {
    this.resizeVideoElement();
  }

  videoControlsVisible = false;
  mouseTimer: any;

  videoMirrored = false;
  videoFlipped = false;

  faArrowsAltV = faArrowsAltV;
  faArrowsAltH = faArrowsAltH;

  playerName$: Observable<string>;

  public videoSize$: BehaviorSubject<IVideoDimensions> = new BehaviorSubject<IVideoDimensions>({ width: 1024, height: 576 });

  constructor(
    private streamService: MediaStreamsService,
    private matchService: MatchService
  ) {
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: any) {
    clearTimeout(this.mouseTimer);
    this.mouseTimer = setTimeout(() => {
      this.videoControlsVisible = false;
    }, 3000);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    console.log(event);
    this.resizeVideoElement();
  }

  ngOnInit(): void {
    this.playerName$ = this.matchService.getPlayerNameByPosition(this.matchDocId, this.player);
    if (this.spectator === undefined) { this.spectator = false; }
  }

  ngAfterViewInit(): void {
    this.videoSize$.next(this.videoSize);
  }

  ngAfterViewChecked(): void {
    // Unmute opponenet video element when stream is recieved and ready to play
    this.videoElement.nativeElement.onplaying = () => {
      this.streamService.audioOutputMuted$.next(false);
    };
  }

  private resizeVideoElement() {
    setTimeout(() => {
      this.videoSize$.next(this.videoSize);
    }, 10);
  }

  public get videoClass() {
    if (this.focused && this.isThisUser) {
      return 'video-small ' + this.placement;
    }
    else if (this.focused && !this.isThisUser) {
      return 'video-large ' + this.placement;
    }
    else {
      return 'video-normal ' + this.placement;
    }
  }

  public get videoSize(): IVideoDimensions {
    const wrapperWidth = this.videoWrapper.nativeElement.clientWidth;
    const wrapperHeight = this.videoWrapper.nativeElement.clientHeight;

    // Aspect ratio 16/9 with current width is possible, return the width and height
    if (wrapperWidth / wrapperHeight <= 1.77) {
      return {
        width: wrapperWidth,
        height: wrapperWidth / 1.777777778
      };
    }
    // Aspect ratio 16/9 with current width is NOT possible, calculate new width
    if (wrapperWidth / wrapperHeight > 1.77) {
      return {
        width: wrapperHeight / 0.5625,
        height: wrapperHeight
      };
    }

  }

}

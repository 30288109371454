<ng-container *ngIf="versionHistory$ | async as versions">
  <div class="modal-body">

    <div class="text-xxlarge text-center mb-4">Latest News</div>


    <ng-container *ngIf="!showAll; else showAllReleases">

      <ng-container *ngFor="let version of versions; let i = index">

        <ng-container [ngSwitch]="i">

          <div class="version-wrapper mb-5" *ngSwitchCase="0">

            <div class="header pt-1 pb-2 text-large">
              <div class="release-date">{{ version.releaseDate | date : 'medium' }}</div>
              <div class="version-number">
                <span class="version">{{ version.version }}</span>.<span class="build">{{ version.build }}</span>.<span
                  class="sub-build">{{ version.subBuild }}</span>.<span class="beta">{{ version.beta ? 'b' : ''
                  }}</span>
              </div>
            </div>

            <div class="body version-comment max-width-100 overflow-hidden" [innerHtml]="version.comment | versionHtml">
            </div>

          </div>

        </ng-container>

      </ng-container>

    </ng-container>


    <ng-template #showAllReleases>
      <div class="version-wrapper mb-5" *ngFor="let version of versions">

        <div class="header pt-1 pb-2 text-large">
          <div class="release-date">{{ version.releaseDate | date : 'medium' }}</div>
          <div class="version-number">
            <span class="version">{{ version.version }}</span>.<span class="build">{{ version.build }}</span>.<span
              class="sub-build">{{ version.subBuild }}</span>.<span class="beta">{{ version.beta ? 'b' : '' }}</span>
          </div>
        </div>

        <div class="body version-comment max-width-100 overflow-hidden" [innerHtml]="version.comment | versionHtml">
        </div>

      </div>
    </ng-template>


  </div>

  <div class="modal-footer d-flex align-items-center">
    <div class="btn btn-sm btn-blank text-secondary" (click)="showAll = !showAll">{{ showAll ? 'Show latest' : 'Show all release notes' }}</div>
    <div class="flex-grow-1"></div>
    <div class="btn btn-sm btn-blank" (click)="close(versions[0].docId)">Great <span class="text-secondary">, don't show again</span></div>
  </div>


</ng-container>

import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

export interface IOnlineUser {
  online: boolean;
  playerDocId: string;
  playerUid: string;
  timestamp: any;
  callerId: string;
}
@Component({
  selector: 'app-online-users',
  templateUrl: './online-users.component.html',
  styleUrls: ['./online-users.component.css']
})
export class OnlineUsersComponent {
  @Input() onlineUsers$: Observable<IOnlineUser[]>;
  @Input() styleClass: string = 'btn btn-sm btn-secondary btn-online-users w-100'
  @Input() placement: string = 'right'

  faUsers = faUsers;

  constructor() {}

}

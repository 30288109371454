import { BehaviorSubject } from 'rxjs';
import { DecksService, IDeckList } from 'src/app/services/decks.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-deck-list',
  templateUrl: './deck-list.component.html',
  styleUrls: ['./deck-list.component.css']
})
export class DeckListComponent implements OnInit {
  @Input() deckListDocId: string;
  @Input() deckListVersionDocId: string = null;
  @Input() showList: boolean = false;
  @Input() wrapperClass: string = '';
  @Input() showAsModal: boolean = false

  public deckList$: BehaviorSubject<IDeckList> = new BehaviorSubject<IDeckList>(null);

  cardPreviewUrl = '';

  constructor(
    private decks: DecksService,
    public modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.decks.getDeckListByDocId(this.deckListDocId, this.deckListVersionDocId).then(deck => {
      this.deckList$.next(deck);
      console.log(deck);
    });
  }

  public get deckName(): string {
    if (this.deckList$.getValue() === null) {
      return 'Deck name'
    }
    return this.deckList$.getValue().name
  }

}

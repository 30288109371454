<ng-container *ngIf="tickets$ | async as tickets">
  <div class="text-secondary d-flex align-items-start flex-column mb-5" *ngIf="tickets.length > 0">
    <span class="h6 flex-grow-1">Available tickets for this event</span>
    <div class="ticket-wrapper w-100 overflow-hidden overflow-x-auto d-flex flex-row">

      <div class="ticket d-flex flex-column align-items-center"
        [ngClass]="{'purchased' : ticket.hasPurchased || ticket.soldOut }"
        *ngFor="let ticket of tickets"
        (click)="ticket.hasPurchased ? null : buyTicket(ticket)">


        <div class="purchased-marker">
          {{ ticket.hasPurchased ? 'PURCHASED' : 'SOLD OUT' }}
        </div>

        <div class="text-small">
          {{ ticket.type }}
        </div>

        <div class="text-bold text-xlarge">
          {{ ticket.salesData.price }} {{ ticket.salesData.currency.toUpperCase() }}
        </div>

        <div class="text-small max-width-100 text-center text-overflow-ellipsis text-no-wrap overflow-hidden">
          {{ ticket.name }}
        </div>


      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="event$ | async as event">


  <div class="modal-header d-flex align-items-center px-3 py-2">
    <div class="flex-grow-1 text-large">Check-In</div>
    <ng-container *ngIf="!isMobile">
      <div class="search-bar float-end {{ extendSearchBar || playerFilter !== '' ? 'extended' : '' }}"
        (mouseenter)="extendSearchBar = true" (mouseleave)="extendSearchBar = false">
        <div class="search-icon">
          <fa-icon [fixedWidth]="true" [icon]="faSearch" (click)="extendSearchBar = !extendSearchBar"></fa-icon>
        </div>
        <input #searchBar type="text" class="search-field" (focus)="extendSearchBar = true"
          [(ngModel)]="playerFilter" placeholder="Search players">
        <div class="clear-input-icon">
          <fa-icon [fixedWidth]="true" [icon]="faTimes" (click)="playerFilter = ''; searchBar.focus()"
            *ngIf="playerFilter !== ''" style="cursor: pointer;"></fa-icon>
        </div>
      </div>
    </ng-container>
    <div class="btn btn-sm btn-blank"
      [ngbTooltip]="showCheckedInPlayers ? 'Hide checked-in players' : 'Show checked-in players'" container="body"
      (click)="showCheckedInPlayers = !showCheckedInPlayers">
      <fa-icon [fixedWidth]="true" [icon]="showCheckedInPlayers ? faEyeSlash : faEye"></fa-icon>
    </div>
  </div>

  <div class="modal-body no-hover d-flex flex-row overflow-hidden p-0" style="max-height: 60vh; min-height: 40vh;">

    <div class="player-list flex-grow-1 px-3 py-0 scrollable vertical"
      *ngIf="!isMobile || isMobile && playerCheckInScan === null && !nothingFoundOnScan">
      <ng-container *ngFor="let player of players$ | async | playersByCheckIn : showCheckedInPlayers : playerFilter">

        <div class="row">

          <div class="col d-flex align-items-center">

            <fa-icon
              class="{{ player.hasCheckedIn === undefined || !player.hasCheckedIn ? 'text-secondary' : 'text-success' }} me-2"
              [fixedWidth]="true"
              [icon]="player.hasCheckedIn === undefined || !player.hasCheckedIn ? faCircle : faCheckCircle"></fa-icon>

            <app-player-display-name class="flex-grow-1" [playerUid]="player.playerUid"></app-player-display-name>

            <button class="btn btn-sm btn-blank" (click)="toggleCheckIn(player)"
              [innerHTML]="player.hasCheckedIn ? 'Clear' : 'Check-in'">
            </button>

          </div>

        </div>

      </ng-container>

    </div>

    <div class="player-info min-height-100" [style.maxWidth]="isMobile ? '100%' : '20rem'"
      [ngClass]="{ 'flex-grow-1' : isMobile }" *ngIf="playerCheckInScan !== null || nothingFoundOnScan">
      <div class="border-left min-height-100 d-flex" *ngIf="playerCheckInScan && !nothingFoundOnScan">
        <div class="p-3 d-flex flex-column align-items-center" [ngClass]="{ 'flex-grow-1' : isMobile }">
          <div class="text-secondary text-monospace text-smaller">{{ playerCheckInScan.playerDocId }}</div>
          <app-player-avatar class="my-3" [style.maxWidth.rem]="10" [playerDocId]="playerCheckInScan.playerDocId"
            size="fill"></app-player-avatar>
          <div class="text-larger text-center">{{ playerCheckInScan.name }}</div>

          <div class="d-flex align-items-center">
            <div class="deck-photo m-2">
              <fa-icon [icon]="deckPhoto" [fixedWidth]="true"
                class="{{ playerCheckInScan.deckSubmission.deckPhoto ? 'text-success' : 'text-secondary' }} text-huge">
              </fa-icon>
            </div>
            <div class="deck-list m-2">
              <fa-icon [icon]="deckList" [fixedWidth]="true"
                class="{{ playerCheckInScan.deckSubmission.deckList ? 'text-success' : 'text-secondary' }} text-huge">
              </fa-icon>
            </div>
          </div>
          <div class="registration-fee" *ngIf="registrationFeeActive">
            <div class="{{ playerHasPayed ? 'text-success' : 'text-warning' }} text-large">
              {{ playerHasPayed ? 'Registration fee paid' : 'Need to pay registration fee' }}
            </div>
          </div>

          <div class="flex-grow-1 d-flex flex-column justify-content-end pt-5">
            <button class="btn {{ playerCheckInScan.hasCheckedIn ? 'btn-success' : 'btn-main' }}"
              (click)="toggleCheckIn(playerCheckInScan)">
              {{ playerCheckInScan.hasCheckedIn ? 'Player Checked In' : 'Check-in Player' }}
            </button>
            <button class="btn btn-blank text-danger mt-2" (click)="playerCheckInScan = null">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div class="border-left min-height-100 d-flex" *ngIf="playerCheckInScan === null && nothingFoundOnScan">
        <div class="p-5 d-flex flex-column align-items-center">

          <fa-icon [icon]="errorScan" [fixedWidth]="true" class="text-jumbo text-venter text-danger"></fa-icon>

          <div class="text-secondary text-large text-center mt-5">Could not find a player matching the scanned result</div>

        </div>
      </div>
    </div>

  </div>

  <div class="modal-footer d-flex px-3 py-2">
    <div class="extra flex-grow-1">
      <ng-template #checkInInfo>
        <div class="p-2">
          <div class="text-large">Check-in config</div>
          <div class="text-warning text-bold mt-2">Player check-in = OFF</div>
          <div class="text-secondary">With this setting, the players will <span class="text-bold text-danger">NOT</span> be
            notified about check-in when inside the lobby.</div>
          <div class="text-warning text-bold mt-2">Player check-in = ON</div>
          <div class="text-secondary">Players will be prompted to check in as soon as they are inside the lobby, unless
            already checked-in. If you are running an online event, you most likely want to activate this.</div>
        </div>
      </ng-template>
      <button class="btn btn-sm btn-blank" (click)="toggleManualCheckIn(!event.checkInByPlayer)"
        [ngbTooltip]="checkInInfo" container="body">
        <fa-icon [icon]="event.checkInByPlayer ? checked : unchecked" class="me-2"></fa-icon>
        <span class="">Player check-in</span>
      </button>
    </div>
    <div class="btn btn-sm btn-outline-main" (click)="goToKioskMode()" *ngIf="!isMobile">
      <fa-icon [icon]="kioskMode" [fixedWidth]="true" class="me-1"></fa-icon>
      <span class="">Kiosk Mode</span>
    </div>
    <div class="btn btn-sm btn-main" (click)="openQrScanner()" *ngIf="isMobile">
      <fa-icon [icon]="faQrcode" [fixedWidth]="true" class="me-1"></fa-icon>
      <span class="">Scan</span>
    </div>
    <button class="btn btn-sm btn-main" (click)="closeCheckIn()">Close Check-In</button>
  </div>

</ng-container>
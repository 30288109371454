import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { IEventPlayerDetails, IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces';
import { TournamentDataHelperService } from '../../services/tournament-data-helper.service';
import { FormsModule } from '@angular/forms';
import { PlayerAvatarComponent } from 'src/app/components/players/player-avatar/player-avatar.component';
import { PlayerSearchComponent } from 'src/app/private/_shared/player-search/player-search.component';
import { PlayerDisplayNameComponent } from 'src/app/components';

@Component({
  selector: 'app-tournament-drop-actions',
  standalone: true,
  imports: [
    CommonModule,
    NgbModalModule,
    FormsModule,
    PlayerAvatarComponent,
    PlayerDisplayNameComponent,
    PlayerSearchComponent,
  ],
  templateUrl: './tournament-drop-actions.component.html',
  styleUrls: ['./tournament-drop-actions.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TournamentDropActionsComponent {
  @Input() set playerDropping(val: IEventPlayerDetails) {
    console.log('[TournamentDropActionsComponent]:: playerDropping emitted a value ->', val)
    this.playerDropping$.next(val)
  }

  public playerDropping$ = new BehaviorSubject<IEventPlayerDetails>(null)
  public exchangePlayer: boolean = false
  public playerEntering$ = new BehaviorSubject<IPlayerMini>(null)
  
  constructor(
    public readonly modal: NgbActiveModal,
    private readonly helper: TournamentDataHelperService,
  ) {

  }

  public onPlayerSearch(event: IPlayerMini) {
    this.playerEntering$.next(null)
    console.log('[TournamentDropActionsComponent]:: onPlayerSearch emitted a value ->', event)
    this.playerEntering$.next(event)
  }

  public get eventType() {
    return this.helper.eventType
  }

  public get isOnlineTournament(): boolean {
    return this.helper.isOnlineTournament
  }

  public close(): void {
    this.modal.close({
      exchangePlayer: this.exchangePlayer,
      playerEntering: this.playerEntering$.getValue(),
      eventType: this.eventType
    })
  }

  public get playerEnteringAlreadyAttending(): boolean {
    const playerEntering = this.playerEntering$.getValue()
    return playerEntering === null
      ? false
      : this.helper.players$.getValue().map(i => i.eventPlayer.playerDocId).includes(playerEntering.id)
  }

  public get disableForm(): boolean {
    const playerEntering = this.playerEntering$.getValue()
    return this.exchangePlayer && playerEntering === null || this.exchangePlayer && this.playerEnteringAlreadyAttending
  }


}

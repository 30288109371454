import { Component, Input } from '@angular/core';
import { IMatchMeta } from '../../utilitiez/matches.interfaces';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatchRoomOpenService } from '../../services/match-room-open.service';
import { PlayerDisplayNameComponent } from 'src/app/components';
import { faCalendar, faChair, faTrash, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { PlayerAvatarComponent } from 'src/app/components/players/player-avatar/player-avatar.component';
import { MatchReportingService } from 'src/app/private/play/tournament/services/match-reporting.service';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchAppointmentItemComponent } from '../match-appointment-item/match-appointment-item.component';
import { IMatchAppointment } from 'tolaria-cloud-functions/src/_interfaces';
import { Options } from '@popperjs/core';
import { AppointmentType, NewMatchAppointmentComponent } from 'src/app/private/_shared/new-match-appointment/new-match-appointment.component';
import { TournamentMatchService } from 'src/app/private/play/tournament/services/tournament-match.service';
import { RouterLink } from '@angular/router';


@Component({
  selector: 'app-match-card',
  templateUrl: './match-card.component.html',
  styleUrls: ['./match-card.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    PlayerDisplayNameComponent,
    PlayerAvatarComponent,
    NgbModule,
    MatchAppointmentItemComponent,
    RouterLink,
  ]
})
export class MatchCardComponent {
  @Input() matchDocId: string
  @Input() match: IMatchMeta
  @Input() styleClass: string

  constructor(
    private readonly matchOpen: MatchRoomOpenService,
    private readonly reporting: MatchReportingService,
    private readonly modalService: NgbModal,
    private readonly matchManagement: TournamentMatchService,
  ) { }

  public icon = {
    calendar: faCalendar,
    delete: faTrash,
    tournament: faTrophy,
    chair: faChair,
  }

  public onDelete(): void {
    this.matchManagement.delete(this.matchDocId)
  }

  public onOpenRoom(): void {
    this.matchOpen.openMatchRoom(this.matchDocId)
  }

  public onReportMatch(): void {
    this.reporting.openReportSlip({
      matchDocId: this.matchDocId,
      isOrganizer: false,
    })
  }

  public get appointments(): IMatchAppointment[] {
    if (this.match) {
      return [...this.match.appointments.unread, ...this.match.appointments.accepted, ...this.match.appointments.rejected]
    }
    else {
      return []
    }
  }

  public get hasAcceptedAppointments(): boolean {
    return this.match.acceptedAppointments
  }

  public popperOptions = (options: Partial<Options>) => {
    options.modifiers.push({
      name: 'fixedWidth',
      enabled: true,
      fn: ({ state }) => {
        state.styles.popper.width = '320px'
      },
      phase: 'beforeWrite',
      requires: ["computeStyles"]
    })
    return options
  }

  public newMatchAppointment() {
    const modalRef = this.modalService.open(NewMatchAppointmentComponent, {
      size: 'xl',
      centered: true,
      backdrop: true,
      keyboard: true,
      animation: true,
      fullscreen: window.innerWidth < 500
    })
    modalRef.componentInstance.type = AppointmentType.MATCH_APPOINTMENT
    modalRef.componentInstance.referenceDocument = this.match.matchDocId
    modalRef.componentInstance.opponentDocId = this.match.opponentDocId
    modalRef.componentInstance.data = this.match
  }

}

import * as firestore from 'firebase/firestore'
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { tap, map, switchMap, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { IFormatMeta } from '../components/app-structure/top-bar/down-to-play-settings/down-to-play-settings.component';

// 1. Signed-in and using app (online)
// 2. Signed-in but app is closed (offline)
// 3. Signed-in but on a different browser tab (away)
// 4. Signed-out but app is still opened (offline)
// 5. Signed-out and app closed (offline)
export interface IPresence {
  status: string;
  timestamp: number;
  downToPlay?: boolean;
  downToPlayFormats?: Array<IFormatMeta>;
}

@Injectable({
  providedIn: 'root',
})
export class PresenceService {
  awayTimer: any;
  downToPlayResetAfterMinutes = 30;

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFireDatabase
  ) {
    // console.log('let there be presence');
    this.updateOnUser().subscribe();
    this.updateOnDisconnect().subscribe();
    this.updateOnAway();

    /*
     *
     * BELOW === https://cloud.google.com/firestore/docs/solutions/presence
     * I don't know if mine is actually working as intended and therefore also added below.
     *
    */

    // // Fetch the current user's ID from Firebase Authentication.
    // const uid = firebase.auth().currentUser.uid;

    // // Create a reference to this user's specific status node.
    // // This is where we will store data about being online/offline.
    // const userStatusDatabaseRef = firebase.database().ref('/status/' + uid);

    // // We'll create two constants which we will write to
    // // the Realtime database when this device is offline
    // // or online.
    // const isOfflineForDatabase = {
    //   state: 'offline',
    //   last_changed: firestore.Timestamp.now().seconds,
    // };

    // const isOnlineForDatabase = {
    //   state: 'online',
    //   last_changed: firestore.Timestamp.now().seconds,
    // };

    // // Create a reference to the special '.info/connected' path in
    // // Realtime Database. This path returns `true` when connected
    // // and `false` when disconnected.
    // firebase.database().ref('.info/connected').on('value', function (snapshot) {
    //   // If we're not currently connected, don't do anything.
    //   if (snapshot.val() === false) {
    //     return;
    //   }

    //   // If we are currently connected, then use the 'onDisconnect()'
    //   // method to add a set which will only trigger once this
    //   // client has disconnected by closing the app,
    //   // losing internet, or any other means.
    //   this.userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(() => {
    //     // The promise returned from .onDisconnect().set() will
    //     // resolve as soon as the server acknowledges the onDisconnect()
    //     // request, NOT once we've actually disconnected:
    //     // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

    //     // We can now safely set ourselves as 'online' knowing that the
    //     // server will mark us as offline once we lose connection.
    //     userStatusDatabaseRef.set(isOnlineForDatabase);
    //   });
    // });
  }



  getPresence(uid: string) {
    return this.db.object<IPresence>(`status/${uid}`).valueChanges();
  }

  getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  async setPresence(status: string) {
    const user = await this.getUser();
    let newStatus = {
      status: status,
      timestamp: this.timestamp
    }
    if (status === 'offline') {
      newStatus['downToPlay'] = false
    }
    if (user) {
      return this.db
        .object(`status/${user.uid}`)
        .update(newStatus);
    }
  }

  async toggleDownToPlay(state: boolean) {
    const user = await this.getUser();
    if (user) {
      return this.db
        .object(`status/${user.uid}`)
        .update({ downToPlay: state, timestamp: this.timestamp });
    }
  }

  get timestamp() {
    return firestore.Timestamp.now().seconds;
  }

  get timeoutSetting() {
    return this.downToPlayResetAfterMinutes * 60 * 1000;
    // min * sec * millisec
  }

  // Updates status when logged-in connection to Firebase starts
  updateOnUser() {
    const connection = this.db
      .object('.info/connected')
      .valueChanges()
      .pipe(map((connected) => (connected ? 'online' : 'offline')));

    return this.afAuth.authState.pipe(
      switchMap((user) => (user ? connection : of('offline'))),
      tap((status) => this.setPresence(status))
    );
  }

  // User closes the app, case 2 and 5
  updateOnDisconnect() {
    return this.afAuth.authState.pipe(
      tap((user) => {
        if (user) {
          this.db.object(`status/${user.uid}`).query.ref.onDisconnect().update({
            downToPlay: false,
            status: 'offline',
            timestamp: this.timestamp,
          });
        }
      })
    );
  }

  // User navigates to a new tab, case 3
  updateOnAway() {
    document.onvisibilitychange = (e) => {
      if (document.visibilityState === 'hidden') {
        this.setPresence('away');
        clearTimeout(this.awayTimer);
        console.log('starting timer');
        this.awayTimer = setInterval(() => {
          this.toggleDownToPlay(false);
        }, this.timeoutSetting); // remove down to play if idle for more than 30 min (1 800 000 ms)
      } else {
        console.log('clearing timer');
        clearTimeout(this.awayTimer);
        this.setPresence('online');
      }
    };
  }

  async updateDownToPlayFormats(formats: any) {
    const user = await this.getUser();
    if (user) {
      return this.db
        .object(`status/${user.uid}`)
        .update({
          downToPlayFormats: formats
        });
    }

  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { IEventFilter, IEventTemplate } from '../services/event-listing.service';

@Pipe({
  name: 'eventListing',
  standalone: true,
  pure: false,
})
export class EventListingPipe implements PipeTransform {

  transform(items: IEventTemplate[], filter: IEventFilter): IEventTemplate[] {
    
    // only show items that are for the current user
    items = items.filter(i => i.listedForUser)

    // date settings
    if (filter.date.from !== null  && filter.date.to !== null) {
      let start = new Date(filter.date.from * 1000)
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)
      start.setMilliseconds(0)
      let end = new Date(filter.date.to * 1000)
      end.setHours(23)
      end.setMinutes(59)
      end.setSeconds(59)
      end.setMilliseconds(999)
      items = items.filter(i => i.timespan.start >= start && i.timespan.start <= end)
    }

    // view settings
    const selectedFilters = filter.show.filter(i => i.selected)
    if (selectedFilters.find(i => i.property === 'all')) {
        items = items
    }
    else {
        items = items.filter(item => {
            return selectedFilters.some(f => {
                return item[f.property] === f.selected
            })
        })
    }

    // in person and/or online
    let length = filter.length.singleDay && filter.length.multiDay
        ? 'both'
        : filter.length.singleDay && !filter.length.multiDay
            ? 'single-day'
            : !filter.length.singleDay && filter.length.multiDay
                ? 'multi-day'
                : 'none'
    switch (length) {
        case 'both':
            break
        case 'single-day':
            items = items.filter(i => !i.isMultiday)
            break
        case 'multi-day':
            items = items.filter(i => i.isMultiday)
            break
        case 'none':
            items = []
            break
    }

    // in person and/or online
    let type = filter.type.inPerson && filter.type.online
        ? 'both'
        : filter.type.inPerson && !filter.type.online
            ? 'in-person'
            : !filter.type.inPerson && filter.type.online
                ? 'online'
                : 'none'
    switch (type) {
        case 'both':
            break
        case 'in-person':
            items = items.filter(i => !i.isOnlineTournament)
            break
        case 'online':
            items = items.filter(i => i.isOnlineTournament)
            break
        case 'none':
            items = []
            break
    }

    // organizer
    if (filter.organizer.selected.length > 0) {
        items = items.filter(i => filter.organizer.selected.map(o => o.uid).includes(i.createdByUid))
    }

    // format
    if (filter.formats.selected.length > 0) {
        items = items.filter(i => filter.formats.selected.includes(i.details.ruleset.name !== '' ? `${i.details.format} - ${i.details.ruleset.name}` : i.details.format))
    }

    // search
    if (filter.search !== '') {
        items = items.filter(i => i.details.name.toLowerCase().includes(filter.search.toLowerCase()))
    }

    // return data
    return items.sort((a, b) => a.details.datestampFrom - b.details.datestampFrom)

  }

}

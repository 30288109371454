import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const tolCrossingSwords: IconDefinition = {
  prefix: 'tol',
  iconName: 'crossingSwords',
  icon: [
    415.1683, // SVG view box width
    512, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M392.2,326.8l-74.8,74.8c-1,1-1,2.6,0,3.6l22.3,22.3c1,1,2.6,1,3.6,0l11.4-11.4c1-1,2.6-1,3.6,0l60.9,60.9c30.6,30.6,32.4,28.7,54.7,6.4c22.3-22.3,24.2-24.2-6.4-54.7l-60.9-60.9c-1-1-1-2.6,0-3.6l11.4-11.4c1-1,1-2.6,0-3.6l-22.3-22.3 C394.8,325.8,393.2,325.8,392.2,326.8z M449.2,437.1l-21.4,21.4c-2.8,2.8-7.3,2.8-10,0c-2.8-2.8-2.8-7.3,0-10l21.4-21.4c2.8-2.8,7.3-2.8,10,0C452,429.8,452,434.3,449.2,437.1z M418.4,406.3L397,427.7c-2.8,2.8-7.3,2.8-10,0c-2.8-2.8-2.8-7.3,0-10l21.4-21.4c2.8-2.8,7.3-2.8,10,0C421.1,399,421.1,403.5,418.4,406.3z M309.4,285.2c-15.3,13.3-28.8,24.4-43,35.9c-0.7,0.6-1.8,2-0.4,3c24.5,19.3,42.3,32.4,51,38.8c3.3,2.4,5.1,3.7,7.3,1.5c7.4-7.4,21.5-21.5,29.4-29.4c2.2-2.2,1.6-4-0.5-6.3c-6.4-6.9-21.7-23.4-40.4-43.6C312.4,284.5,311.4,283.5,309.4,285.2z M189.2,256.7c16.3-17.6,31.1-33.5,47.4-51.1c0.8-1.1,0.1-2.8-0.5-3.5c-12.6-13.6-23.6-25.4-31.5-33.8C166.5,127.5,68.4,3.7,68.4,3.7S61.5-5.3,59,4.8c-4.2,16.9-11.8,55.7,6.3,108.9c13.8,40.5,66,94.8,119.7,142.8C185.8,257.2,187.6,258.1,189.2,256.7z M441,4.8c-2.5-10.2-9.4-1.1-9.4-1.1s-98.1,123.8-136.2,164.6c-34.3,36.7-129.9,140-148.7,160.3c-2.1,2.3-2.7,4.1-0.5,6.3c7.9,7.9,22.1,22.1,29.4,29.4c2.2,2.2,4,0.9,7.3-1.5c33.5-24.5,223.3-166.2,251.7-249.2C452.8,60.6,445.2,21.7,441,4.8z M104.2,326.8l-22.3,22.3c-1,1-1,2.6,0,3.6l11.4,11.4c1,1,1,2.6,0,3.6l-60.9,60.9C1.8,459.2,3.7,461.1,26,483.4c22.3,22.3,24.1,24.1,54.7-6.4l60.9-60.9c1-1,2.6-1,3.6,0l11.4,11.4c1,1,2.6,1,3.6,0l22.3-22.3c1-1,1-2.6,0-3.6l-74.8-74.8C106.8,325.8,105.2,325.8,104.2,326.8z M50.8,427.1c2.8-2.8,7.3-2.8,10,0l21.4,21.4c2.8,2.8,2.8,7.3,0,10c-2.8,2.8-7.3,2.8-10,0l-21.4-21.4C48,434.3,48,429.8,50.8,427.1z M81.6,396.3c2.8-2.8,7.3-2.8,10,0l21.4,21.4c2.8,2.8,2.8,7.3,0,10c-2.8,2.8-7.3,2.8-10,0l-21.4-21.4C78.9,403.5,78.8,399,81.6,396.3z'
  ],
} as any;
500

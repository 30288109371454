import { AuthService } from './../services/auth.service';
import { IMatchData, IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { Pipe, PipeTransform } from '@angular/core';

interface IMatchDataTemplate extends IMatchData {
  canBeActivated: boolean
}

@Pipe({
  name: 'match',
})
export class MatchPipe implements PipeTransform {

  constructor(private auth: AuthService) {}

  transform(items: IMatchDataTemplate[], filter: string, round: number, showReportedMatches: boolean, group: number | string, event?: IEventDetails): IMatchDataTemplate[] {

    console.log('[MatchPipe] --> starting')

    // debugger

    // if ((round === undefined || round === null) || (group !== undefined && group !== null)) {
    //   round = 0;
    // }

    const groupNames: Array<string> = '-ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    let res: IMatchDataTemplate[] = [] = items;

    if (event.details.structure.isGroup) {
      if (!showReportedMatches) {
        if (typeof group === 'string') {
          return items.filter(m => (m.groupName === `GROUP ${group.toUpperCase()}` || m.groupLetter === group.toUpperCase()) && !m.isReported && m.isType !== 'bracket' && m.playerFilterValue.toLowerCase().includes(filter.toLowerCase()));
        }
        return items.filter(m => (m.groupName === 'GROUP ' + groupNames[group] || m.groupLetter === groupNames[group]) && !m.isReported && m.isType !== 'bracket' && m.playerFilterValue.toLowerCase().includes(filter.toLowerCase()));
      }
      else {
        if (typeof group === 'string') {
          return items.filter(m => (m.groupName === `GROUP ${group.toUpperCase()}` || m.groupLetter === group.toUpperCase()) && m.isType !== 'bracket' && m.playerFilterValue.toLowerCase().includes(filter.toLowerCase()));
        }
        return items.filter(m => (m.groupName === 'GROUP ' + groupNames[group] || m.groupLetter === groupNames[group]) && m.isType !== 'bracket' && m.playerFilterValue.toLowerCase().includes(filter.toLowerCase()));
      }
    }

    if (!showReportedMatches) {
      res = items.filter(m => m.playerFilterValue.toLowerCase().includes(filter.toLowerCase()) && m.roundNumber === round && !m.isReported && m.isType !== 'bracket');
    }
    else {
      res = items.filter(m => m.playerFilterValue.toLowerCase().includes(filter.toLowerCase()) && m.roundNumber === round && m.isType !== 'bracket');
    }

    // hide deleted matches (INACTIVE) if NOT organiser or admin
    if (event.createdByUid !== this.auth.user.uid && !event.coOrganizers.includes(this.auth.user.uid) && !event.coOrganizers.includes(this.auth.user.playerId)) {
      res = res.filter(m => !m.deleted)
    }

    if (res.length > 0 && res[0].isType !== undefined && res[0].isType === 'batch') {
      res.sort((a, b) => (a.roundNumber < b.roundNumber) ? -1 : ((b.roundNumber < a.roundNumber) ? 1 : 0));
      res.sort((a, b) => (a.segmentNumber < b.segmentNumber) ? -1 : ((b.segmentNumber < a.segmentNumber) ? 1 : 0));
    }
    if (res.length > 0 && res[0].isType !== undefined && res[0].isType === 'swiss') {
      res = res.sort((a, b) => a.tableNumber - b.tableNumber);
    }
    if (res.length > 0 && res[0].isType !== undefined && res[0].isType === 'swiss-team') {
      res = res.sort((a, b) => a.tableNumber - b.tableNumber);
    }

    // put active players match on top if not undefined
    const playerMatchIndex = res.findIndex(p => p.player1.playerDocId === this.auth.user.playerId || p.player2.playerDocId === this.auth.user.playerId);
    if (playerMatchIndex > -1 && res[0].isType === 'swiss') {
      // console.log('logged in player IS active in pairings, move to top');
      const playerMatch = res.splice(playerMatchIndex, 1);
      res.unshift(playerMatch[0]);
    }
    else {
      // console.log('logged in player not active in pairings or the event is not swiss');
    }
    // console.log('filtered matched', res);

    console.log('[MatchPipe] --> done')
    return res;
  }

}

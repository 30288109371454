import { Component, OnInit } from '@angular/core';
import { PublicHeaderComponent } from '../public-header/public-header.component';
import { PublicFooterComponent } from '../public-footer/public-footer.component';
import { CommonModule } from '@angular/common';
import { StripeConnectedAccountInfoComponent } from "../../payment/stripe-connected-account/stripe-connected-account-info/stripe-connected-account-info.component";

@Component({
  selector: 'app-payment-solution',
  templateUrl: './payment-solution.component.html',
  styleUrls: ['./payment-solution.component.css'],
  imports: [
    PublicHeaderComponent,
    PublicFooterComponent,
    CommonModule,
    StripeConnectedAccountInfoComponent
],
  standalone: true,
})
export class PaymentSolutionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

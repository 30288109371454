import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthService } from 'src/app/services';
import { applicationIcon } from 'src/assets/font-icons/tolaria-icon';
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces';
import { PublicHeaderComponent } from '../../public-header/public-header.component';
import { PublicFooterComponent } from '../../public-footer/public-footer.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    PublicHeaderComponent,
    PublicFooterComponent,
    RouterLink,
  ]
})
export class ForgotPasswordComponent {

  public isMobile = window.innerWidth < 500
  public topMargin = this.isMobile ? 0 : 10

  public appIcon = applicationIcon

  public forgotPasswordEmail = '';
  public authMessage = null;

  constructor(
    private auth: AuthService,
  ) { }

  public resetPassword() {
    this.auth.sendPasswordResetEmail(this.forgotPasswordEmail).then((res: IPromiseResponse) => {
      console.log('ForgotPasswordComponent:: resetPassword -> response:', res)
      this.authMessage = {
        message: res.text,
        success: res.status,
      }
    })
  }

  public get emailValid(): boolean {
    return /^\w+([+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.forgotPasswordEmail)
  }

}

import { Observable } from 'rxjs';
import { EventService, GlobalsService } from 'src/app/services';
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, Input } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-more-actions-swiss',
  templateUrl: './more-actions-swiss.component.html',
  styleUrls: ['./more-actions-swiss.component.css']
})
export class MoreActionsSwissComponent {
  @Input() eventDoc$: Observable<IEventDetails>;

  public faChevronRight = faChevronRight
  public submenuOpen = false

  constructor(
    private globals: GlobalsService,
    private es: EventService
  ) { }

  // MORE ACTIONS
  unpairSwissRound(event: IEventDetails) {
    // set app busy and show info text
    this.globals.isBusy.status = true;
    this.globals.isBusy.message = 'Deleting all pairings for current round and updating event status';
    // call event service to execute the unpairing
    this.es.eventUnpairSwissRound(event).then((response: IPromiseResponse) => {
      // returning from event service
      console.log(response);
      if (response.status) {
        // update event status to unpaired
        this.globals.isBusy.message = response.text;
        this.es.eventUpdateStatus(event, 1, false).then((lowerResponse: IPromiseResponse) => {
          if (lowerResponse.status) {
            this.globals.isBusy.message = lowerResponse.text;
            // set app to not busy
            setTimeout(() => {
              this.globals.isBusy.status = false;
            }, 1000);
          }
          else {
            this.globals.isBusy.message = lowerResponse.text;
            // set app to not busy
            setTimeout(() => {
              this.globals.isBusy.status = false;
            }, 3000);
          }
        });
      }
      else {
        this.globals.isBusy.message = 'Error deleting paired matches for active round...';
        console.log(response);
        setTimeout(() => {
          this.globals.isBusy.status = false;
        }, 3000);
      }
    });

  }
  addSwissRound(event: IEventDetails) {
    // set app busy
    this.globals.isBusy.status = true;
    this.es.addSwissRound(event).then((response: IPromiseResponse) => {
      if (response.status) {
        this.globals.isBusy.message = response.text;
        this.globals.isBusy.status = false;
      }
      else {
        console.log(response.text);
        this.globals.isBusy.message = 'Somwthing went wring, could not add another round.';
        setTimeout(() => {
          this.globals.isBusy.status = false;
        }, 3000);
      }
    });
  }
  removeSwissRound(event: IEventDetails) {
    // set app busy
    this.globals.isBusy.status = true;
    this.es.removeSwissRound(event).then((response: IPromiseResponse) => {
      if (response.status) {
        this.globals.isBusy.message = response.text;
        this.globals.isBusy.status = false;
      }
      else {
        console.log(response.text);
        this.globals.isBusy.message = 'Somwthing went wrong, could not remove round.';
        setTimeout(() => {
          this.globals.isBusy.status = false;
        }, 3000);
      }
    });
  }
  reactivatePreviousRound(event: IEventDetails) {
    this.es.reactivatePreviousRound(event);
    // set app busy
    this.globals.isBusy.status = true;
    this.globals.isBusy.message = 'Reactivating previous round.';
    this.es.reactivatePreviousRound(event).then((response: IPromiseResponse) => {
      if (response.status) {
        this.globals.isBusy.message = response.text;
        this.globals.isBusy.status = false;
      }
      else {
        console.log(response.text);
        this.globals.isBusy.message = 'Somwthing went wrong, could not remove round.';
        setTimeout(() => {
          this.globals.isBusy.status = false;
        }, 3000);
      }
    });
  }
  addPlayoffBracket(event: IEventDetails) {
    this.es.addPlayoffBracket(event);
  }
  removePlayoffBracket(event: IEventDetails) {
    console.log('removePlayoffBracket');
    if (event.statusCode === 6) {
      console.log('... update event status');
      this.es.eventUpdateStatus(event, 5, false);
      console.log('... switch the hasBracketAfterSwiss to false');
      this.es.removePlayoffBracket(event);
    }
    else if (event.statusCode === 7) {
      // need to remove the matches before removing the playoff
      console.log('... remove matches');
      this.es.deleteAllBracketMatches(event.docId).then((response) => {
        console.log(response);
        this.es.eventUpdateStatus(event, 5, false);
        this.es.removePlayoffBracket(event);
      });
    }
    else if (event.statusCode < 6) {
      this.es.removePlayoffBracket(event);
    }
  }
  manualPairingMode(event: IEventDetails) {
    this.es.eventUpdateStatus(event, 11);
  }
}

import { PaymentService } from './payment.service'
import { Component, OnInit, Input } from '@angular/core';
import { ProductType } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  @Input() amount: number
  @Input() currency: string = 'usd'
  @Input() description: string = ''
  @Input() productType: ProductType
  @Input() paymentMeta: any
  @Input() buttonText: string = 'Pay Now'
  @Input() buttonClass: string = 'btn btn-danger'
  @Input() valueInput: boolean = false

  constructor(private payment: PaymentService) {}

  ngOnInit(): void {

    console.log({
      amount: this.amount,
      currency: this.currency,
      description: this.description,
      productType: this.productType,
      paymentMeta: this.paymentMeta,
      buttonText: this.buttonText,
      buttonClass: this.buttonClass,
      valueInput: this.valueInput
    })

  }

  createCheckoutSession() {
    this.payment.createCheckoutSession.standard(this.currency, this.amount, this.productType, this.paymentMeta)
  }

  get disablePurchaseButton(): boolean {
    if (this.productType === undefined || this.productType === null) return true
    if (this.amount === undefined || this.amount === null) return true
    return false
  }

  get mainTooltip(): string {
    return this.productType === undefined || this.productType === null
      ? 'Purchase disabled due to missing product type'
      : this.amount === undefined || this.amount === null
        ? 'Purchase disabled due to missing amount'
        : this.buttonText
  }

}

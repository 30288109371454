import { CommonModule } from '@angular/common'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { EmojiService } from './emoji.service'
import { BehaviorSubject } from 'rxjs'
import { EmojiItem } from './emojis'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { FormsModule } from '@angular/forms'

@Component({
  selector: 'tolaria-emoji-picker',
  templateUrl: './tolaria-emoji-picker.component.html',
  styleUrls: ['./tolaria-emoji-picker.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
  ],
})
export class TolariaEmojiPickerComponent implements OnInit {
  @Output() onSelected = new EventEmitter<EmojiItem>()
  @Output() onClose = new EventEmitter<boolean>()

  public previewEmoji$ = new BehaviorSubject<EmojiItem>(null)
  private timeout: any

  constructor(
    public readonly emoji: EmojiService
  ) {}

  ngOnInit(): void {
    this.emoji.selectTab('search')
  }

  onNewEmoji(): void {
    this.emoji.newEmoji()
    this.onClose.emit(true)
  }

  onSelect(emoji: EmojiItem): void {
    this.onSelected.emit(emoji)
  }

  onSetPreview(emoji: EmojiItem): void {
    if (this.timeout) {
      clearInterval(this.timeout)
    }
    this.previewEmoji$.next(emoji)
  }

  onClearPreview(): void {
    if (this.timeout) {
      clearInterval(this.timeout)
    }
    this.timeout =  setTimeout(() => {
      this.previewEmoji$.next(null)
    }, 250)
  }

}

import { TicketDetailsComponent } from './components/tickets/manage-tickets/ticket-details/ticket-details.component';
import { ManageTicketsComponent } from './components/tickets/manage-tickets/manage-tickets.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { PubsComponent } from './pubs/pubs.component';
import { LifeCounterComponent } from './components/_public/life-counter/life-counter.component';
import { NewsComponent } from './components/news/news.component';
import { DeckBuilderComponent } from './components/decks/deck-builder/deck-builder.component';
import { DecksComponent } from './components/decks/decks.component';
import { ClubsComponent } from './components/clubs/clubs.component';
import { DecksterComponent } from './components/decks/deck-photos/deckster/deckster.component';
import { DeckPhotosComponent } from './components/decks/deck-photos/deck-photos.component';
import { TermsOfUseComponent } from './components/app-structure/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './components/app-structure/privacy-policy/privacy-policy.component';
import { DraftRoomComponent } from './components/drafts/draft-room/draft-room.component';
import { DraftsComponent } from './components/drafts/drafts.component';
import { CardsComponent } from './components/cards/cards.component';
import { DownToPlayComponent } from './components/down-to-play/down-to-play.component';
import { PlayerGuard, AuthGuard } from './services';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserManagementComponent } from './components/app-structure/user-management/user-management.component';
import {
  CollectionComponent,
  MobileComponent,
  EventLobbyComponent,
  EventsComponent,
  RegisterAsPlayerComponent,
  LoginComponent,
  DashboardComponent,
} from './components';
import { EventCheckInKioskComponent } from './components/events/event-check-in-kiosk/event-check-in-kiosk.component';
import { CardFactoryComponent } from './components/card-factory/card-factory.component';
import { CreatedCardsComponent } from './components/card-factory/created-cards/created-cards.component';
import { EditCreatedCardComponent } from './components/card-factory/edit-created-card/edit-created-card.component';
import { MyCreatedCardComponent } from './components/card-factory/my-created-card/my-created-card.component';
import { CreatedCardViewerComponent } from './components/card-factory/created-card-viewer/created-card-viewer.component';
import { PubComponent } from './pubs/pub/pub.component';
import { DeckViewerComponent } from './public/deck-viewer/deck-viewer.component';
import { AdminGuard } from './services/admin.guard';
import { LoadingScreenComponent } from './private/loading-screen/loading-screen.component';
import { ContentGuard } from './services/content.guard';
import { QuizComponent } from './private/quiz/quiz.component';
import { AboutComponent } from './public/about/about.component';
import { SignInComponent } from './public/auth/login/sign-in.component';
import { ForgotPasswordComponent } from './public/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './public/auth/reset-password/reset-password.component';
import { RegisterComponent } from './public/auth/register/register.component';
import { PaymentSolutionComponent } from './public/payment-solution/payment-solution.component';
// import { PlayersComponent } from './private/social/players/players.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'about',
  },
  {
    path: 'about',
    children: [
      {
        path: 'payment-solution',
        component: PaymentSolutionComponent,
      },
      {
        path: 'home',
        component: AboutComponent,
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'terms-of-use',
        component: TermsOfUseComponent
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'payment-solution',
    component: PaymentSolutionComponent,
  },
  {
    path: 'auth',
    children: [
      {
        path: 'sign-in',
        component: SignInComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
    ]
  },
  {
    path: 'life-counter',
    component: LifeCounterComponent,
  },
  {
    path: 'deck-viewer/:id',
    component: DeckViewerComponent,
  },
  {
    path: 'down-to-play/swedish',
    redirectTo: '/down-to-play',
    pathMatch: 'full'
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
  },
  {
    path: 'register',
    component: RegisterAsPlayerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'loading',
    component: LoadingScreenComponent,
    canActivate: [AuthGuard, PlayerGuard]
  },
  {
    path: 'dashboard',
    redirectTo: 'dashboard/',
    pathMatch: 'full'
  },
  {
    path: 'dashboard/:action',
    component: DashboardComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'news',
    component: NewsComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'pubs',
    component: PubsComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'pubs/pub/:pubDocId',
    component: PubComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'deck-photos',
    component: DeckPhotosComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'deck-photos/deckster',
    component: DecksterComponent,
  },
  {
    path: 'drafts',
    component: DraftsComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'draft-room/:draftType/:draftDocId',
    component: DraftRoomComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'down-to-play',
    component: DownToPlayComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'events-old',
    component: EventsComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },


  {
    path: 'leagues',
    loadChildren: () => import('./private/play/leagues/leagues.module').then(m => m.LeaguesModule),
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'events',
    loadChildren: () => import('./private/play/events/events.module').then(m => m.EventsModule),
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'tournament',
    loadChildren: () => import('./private/play/tournament/tournament.module').then(m => m.TournamentModule),
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'matches',
    loadChildren: () => import('./private/play/matches/matches.module').then(m => m.MatchesModule),
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'event-check-in/:eventDocId',
    component: EventCheckInKioskComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'event-lobby/:eventDocId/:name',
    component: EventLobbyComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'event-lobby/:eventDocId/:name/:eventAction',
    component: EventLobbyComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'clubs',
    component: ClubsComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  // {
  //   path: 'players',
  //   redirectTo: 'players/',
  //   pathMatch: 'full'
  // },
  {
    path: 'players',
    loadChildren: () => import('./private/social/players/players.module').then(m => m.PlayersModule),
    canActivate: [AuthGuard, PlayerGuard, ContentGuard],
  },
  // {
  //   path: 'players/:clubName',
  //   component: PlayersComponent,
  //   canActivate: [AuthGuard, PlayerGuard]
  // },
  {
    path: 'cards',
    component: CardsComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  // {
  //   path: 'match-room/:eventDocId/:matchDocId/:player1DocId/:player2DocId/:createdByUid',
  //   component: MatchRoomComponent,
  //   canActivate: [AuthGuard, PlayerGuard]
  // },
  {
    // path: 'match/:matchDocId/event/:eventDocId',
    path: 'match/:matchDocId',
    loadChildren: () => import('./private/match-room/match-room.module').then(m => m.MatchRoomModule),
    canActivate: [AuthGuard, PlayerGuard, ContentGuard],
    outlet: 'match-room',
  },
  // {
  //   path: 'watch/:matchRef',
  //   component: WatchComponent,
  //   canActivate: [AuthGuard, PlayerGuard]
  // },
  {
    path: 'collection',
    component: CollectionComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard]
  },
  {
    path: 'messages',
    loadChildren: () => import('./private/social/messages/messages.module').then(m => m.MessagesModule),
    canActivate: [AuthGuard, PlayerGuard, ContentGuard],
  },
  {
    path: 'decks',
    pathMatch: 'full',
    component: DecksComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard],
  },
  {
    path: 'decks/edit/:deckDocId/version/:versionDocId',
    component: DeckBuilderComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard],
  },
  {
    path: 'tickets',
    component: TicketsComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard],
  },
  {
    path: 'tickets/manage',
    component: ManageTicketsComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard],
    children: [
      {
        path: ':ticketDocId',
        component: TicketDetailsComponent,
        canActivate: [AuthGuard, PlayerGuard, ContentGuard],
      }
    ],
  },
  {
    path: 'card-factory',
    component: CardFactoryComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard],
    children: [
      {
        path: '',
        component: CreatedCardsComponent,
        canActivate: [AuthGuard, PlayerGuard, ContentGuard],
        children: [
          {
            path: 'preview/:docId',
            canActivate: [AuthGuard, PlayerGuard, ContentGuard],
            component: CreatedCardViewerComponent,
          }
        ]
      },
      {
        path: 'edit/:docId',
        component: EditCreatedCardComponent,
        canActivate: [AuthGuard, PlayerGuard, ContentGuard],
      },
      {
        path: 'your-cards',
        component: MyCreatedCardComponent,
        canActivate: [AuthGuard, PlayerGuard, ContentGuard],
      },
    ]
  },
  {
    path: 'quiz',
    component: QuizComponent,
    canActivate: [AuthGuard, PlayerGuard, ContentGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./private/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard, PlayerGuard, ContentGuard, AdminGuard],
  },
  {
    path: 'mobile',
    component: MobileComponent
  },
  {
    path: '**',
    redirectTo: 'dashboard/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Observable } from 'rxjs';
import { IPresence, PresenceService } from './../../services/presence.service';
import { faGlobe, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Component, Input, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.css'],
  standalone: true,
  imports: [
    FontAwesomeModule,
    NgbTooltip,
    CommonModule,
  ]
})
export class UserStatusComponent implements OnInit {
  @Input() playerUid: string;

  faGlobe = faGlobe;
  faUserCircle = faUserCircle;

  presence$: Observable<IPresence>;

  constructor(
    public presence: PresenceService
  ) { }

  ngOnInit(): void {
    this.presence$ = this.presence.getPresence(this.playerUid);
  }

}

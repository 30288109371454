<div class="player-avatar rounded d-flex align-items-center justify-content-center {{ size }}"
  [ngClass]="{
      'round' : round,
      'square' : !round,
      'hover-border' : border,
      'cursor-pointer': !disableClick
    }">

  <ng-container *ngIf="player$ | async as player; else tempAvatar">
    <ng-template #tipContent>
      <app-player-popover [id]="player.id" [showControls]="true"></app-player-popover>
    </ng-template>
    
    <div class="image-wrapper"
      [stickyPopover]="tipContent"
      [disablePopover]="disablePopover"
      [openDelay]="500"
      [autoClose]="false"
      popoverClass="tolaria-player-popover"
      container="body"
      (click)="openPlayerDetails()">

      <img class=""
        [src]="player.avatar"
        [ngbTooltip]="player.name.display"
        container="body"
        placement="top bottom">

    </div>
  </ng-container>

  <ng-template #tempAvatar>

    <div class="image-wrapper" *ngIf="avatarUrl; else loader">
      <img [src]="avatarUrl">
    </div>

    <ng-template #loader>
      <div class="text-center">
        <div class="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </ng-template>
    
  </ng-template>

</div>

<div class="form-group row mb-1">
  <div class="col">
    <label class="col-form-label-sm">Download all deck lists</label>
  </div>
  <div class="col-3">
    <button class="btn btn-sm btn-secondary w-100" (click)="downloadDeckLists()">Download</button>
  </div>
</div>

<div class="form-group row mb-1">
  <div class="col">
    <label class="col-form-label-sm">Download Cards by Player list</label>
  </div>
  <div class="col-3">
    <button class="btn btn-sm btn-secondary w-100" (click)="downloadCardsByPlayerList()">Download</button>
  </div>
</div>

<div class="form-group row mb-1">
  <div class="col">
    <label class="col-form-label-sm">Download all deck photos</label>
  </div>
  <div class="col-3">
    <button class="btn btn-sm btn-secondary w-100" (click)="downloadDeckPhotos()">Download</button>
  </div>
</div>

<div class="form-group row mb-1">
  <div class="col">
    <label class="col-form-label-sm">Download event information</label>
  </div>
  <div class="col-3">
    <button class="btn btn-sm btn-secondary w-100" (click)="downloadEventInformation()">Download</button>
  </div>
</div>

<div class="form-group row mb-2">
  <div class="col">
    <label class="col-form-label-sm">Reminders players to submit deck info</label>
  </div>
  <div class="col-3">
    <button class="btn btn-sm btn-secondary w-100" (click)="sendReminderMessage()">Send</button>
  </div>
</div>

<div class="form-group row mb-3">
  <div class="col">
    <span class="">Drop players without submitted deck on event start</span>
  </div>
  <div class="col-1">
    <div class="form-check form-switch float-end">
      <input type="checkbox" class="form-check-input" id="dropPlayersWithoutSubmittedDeckOnStart"
        [(ngModel)]="event.details.dropPlayersWithoutSubmittedDeckOnStart" (change)="updateEventDeckInfo(event)">
      <label class="form-check-label" for="dropPlayersWithoutSubmittedDeckOnStart"></label>
    </div>
  </div>
</div>

<div class="form-group row mb-2">
  <div class="col">
    <label class="">Deck Photos and Lists public for all players</label>
  </div>
  <div class="col-2">
    <div class="form-check form-switch float-end">
      <input type="checkbox" class="form-check-input" id="deckInfoIsPublic"
        [(ngModel)]="event.details.deckInfoIsPublic" (change)="updateEventDeckInfo(event)">
      <label class="form-check-label" for="deckInfoIsPublic"></label>
    </div>
  </div>
</div>

<section class="card-details p-2">
  <div class="card card-large full-height" *ngIf="cardMeta as card">
    <div class="card-header relative p-0">
      <div class="image-wrapper">
        <ng-container *ngIf="card.card_faces.length === 1; else multiFacedImage">
          <img [src]="card.image_uris.art_crop">
        </ng-container>
        <ng-template #multiFacedImage>
          <img [src]="card.card_faces[0].image_uris.art_crop">
        </ng-template>
      </div>
      <div class="btn-close" (click)="destroySelf()">
        <fa-icon [fixedWidth]="true" [icon]="icons.faTimes"></fa-icon>
      </div>
    </div>

    <div class="card-header">
      <div class="h5 d-flex align-items-center">
        <ng-container *ngIf="card.card_faces.length === 1; else multiFacedName">
          <span class="card-name flex-grow-1 m-0"
            [innerText]="card.lang !== 'en' ? card.printed_name : card.name"></span><span class="h6 mana-cost m-0 ms-3"
            [innerHTML]="card.mana_cost | manaParser : { shadowed: true }"></span>
        </ng-container>
        <ng-template #multiFacedName>
          <span class="card-name flex-grow-1 m-0"
            [innerText]="card.lang !== 'en' ? card.card_faces[0].printed_name : card.card_faces[0].name"></span><span
            class="h6 mana-cost m-0 ms-3"
            [innerHTML]="card.card_faces[0].mana_cost | manaParser : { shadowed: true }"></span>
        </ng-template>
      </div>
    </div>
    <div class="card-body scrollable no-hover p-0">
      <!-- SINGLE FACED CARD -->
      <ng-container *ngIf="card.card_faces.length === 1; else multiFacedContent">
        <div class="card-content">
          <div class="mt-2 mb-2 h6">
            <ng-container
              *ngFor="let type of card.lang !== 'en' ? card.printed_type_line.split(' ') : card.type_line.split(' '); let i = index">
              <span class="link me-1" (click)="searchType(card.type_line.split(' ')[i].toLowerCase())"
                *ngIf="type !== '—' && type !== ':'; else typeWithoutLink">{{ type }}</span>
              <ng-template #typeWithoutLink><span class="me-1">{{ type }}</span></ng-template>
            </ng-container>
          </div>
          <div class="mt-2 mb-2 card-text"
            [innerHTML]="card.lang !== 'en' ? card.printed_text : card.oracle_text | manaParser: { fontClass: 'text-small' }">
          </div>
          <div class="mt-3 mb-3 h6" *ngIf="card.type_line.includes('Creature')"><span
              class="text-secondary me-2">P/T:</span>{{ card.power + '/' +
            card.toughness }}</div>
          <div class="mt-3 mb-3 h6" *ngIf="card.type_line.includes('Planeswalker')"><span
              class="text-secondary me-2">Loyalty:</span>{{ card.loyalty }}</div>
        </div>
      </ng-container>
      <!-- MULTI FACED CARD -->
      <ng-template #multiFacedContent>
        <ng-container *ngFor="let cardFace of card.card_faces; let i = index">
          <div class="card-header" *ngIf="i > 0">
            <div class="h5 d-flex align-items-center">
              <span class="card-name flex-grow-1 m-0"
                [innerText]="card.lang !== 'en' ? cardFace.printed_name : cardFace.name"></span><span
                class="h6 mana-cost m-0 ms-3" [innerHTML]="cardFace.mana_cost | manaParser : { shadowed: true }"></span>
            </div>
          </div>
          <div class="card-content">
            <div class="mt-2 mb-2 h6">
              <ng-container
                *ngFor="let type of card.lang !== 'en' ? cardFace.printed_type_line.split(' ') : cardFace.type_line.split(' '); let i = index">
                <span class="link me-1" (click)="searchType(cardFace.type_line.split(' ')[i].toLowerCase())"
                  *ngIf="type !== '—' && type !== ':'; else typeWithoutLink">{{ type }}</span>
                <ng-template #typeWithoutLink><span class="me-1">{{ type }}</span></ng-template>
              </ng-container>
            </div>
            <div class="mt-2 mb-2 card-text"
              [innerHTML]="card.lang !== 'en' ? cardFace.printed_text : cardFace.oracle_text | manaParser: { fontClass: 'text-small' }">
            </div>
            <div class="mt-3 mb-3 h6" *ngIf="cardFace.type_line.includes('Creature')"><span
                class="text-secondary me-2">P/T</span>{{ cardFace.power +
              '/' +
              cardFace.toughness }}</div>
            <div class="mt-3 mb-3 h6" *ngIf="cardFace.type_line.includes('Planeswalker')"><span
                class="text-secondary me-2">Loyalty:</span>{{ cardFace.loyalty }}</div>
          </div>
        </ng-container>

      </ng-template>

      <div class="card-content">
        <div class="mt-2 mb-2 text-italic text-secondary" [innerHTML]="card.flavor_text"></div>
        <div class="mt-2 h6"><span class="text-secondary text-smaller me-2">Illustrated by</span><span class="link"
            (click)="searchArtist(card.artist)">{{ card.artist}}</span></div>
        <div class="mt-2 h6"><span class="text-secondary text-smaller me-2">Set</span><span class="link"
            (click)="searchSet(card.set)">{{ card.set_name}} ({{ card.set.toUpperCase() }})</span></div>
        <button class="mt-2 mb-2 btn btn-sm btn-outline-light" disabled *ngIf="card.reserved">RESERVED LIST</button>
        <hr>
        <div class="h6">
          <div class="text-smaller text-secondary">Prices</div>
          <div class="row pt-2">
            <div class="col">
              <div class="price-tag non-foil" *ngIf="card.prices.eur !== null">€{{ card.prices.eur }}</div>
            </div>
            <div class="col">
              <div class="price-tag foil" *ngIf="card.prices.eur_foil !== null">€{{ card.prices.eur_foil }}</div>
            </div>
            <div class="col">
              <div class="price-tag non-foil" *ngIf="card.prices.usd !== null">${{ card.prices.usd }}</div>
            </div>
            <div class="col">
              <div class="price-tag foil" *ngIf="card.prices.usd_foil !== null">${{ card.prices.usd_foil }}</div>
            </div>
          </div>
        </div>
        <hr>
        <app-card-languages [cardMeta]="{ id: card.id, name: card.name, set: card.set, lang: card.lang }"
          (updateCardId)="updateCardId($event)"></app-card-languages>
        <hr>
        <app-card-legality [cardLegalities]="card.legalities"></app-card-legality>
        <hr>
        <app-card-printings [id]="card.id" (updateCardId)="updateCardId($event)"></app-card-printings>
        <hr>
        <app-card-rulings [id]="card.id"></app-card-rulings>

      </div>
    </div>


    <div class="card-footer">
      <button class="btn btn-sm text-secondary">
        <fa-icon [icon]="icons.faLink" [fixedWidth]="true" class="me-2"></fa-icon>
        <span class="text-secondary" [innerText]="card.id"></span>
      </button>
    </div>
  </div>
</section>

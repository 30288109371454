import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IPlayerDetails, IPlayerIdentifiers } from 'tolaria-cloud-functions/src/_interfaces';
import { PlayerIdentifiers } from 'src/app/services';
import { IPlayerBadgeMeta } from '../player-interfaces';
import { defaultBages } from '../default-badges';

@Injectable({
  providedIn: 'root'
})
export class PlayerBadgeService {

  private _playerBadges = new Map<string, Observable<IPlayerBadgeMeta[]>>()

  constructor(
    private readonly firestore: AngularFirestore,
  ) { }

  public getBadges(playerId: string) {

    if (this._playerBadges.get(playerId)) {
      return this._playerBadges.get(playerId)
    }

    const observer = this.firestore
      .collection('players')
      .doc<IPlayerDetails>(playerId)
      .valueChanges()
      .pipe(map(player => this._mapBadges(player))
    )

    this._playerBadges.set(playerId, observer)
    return this._playerBadges.get(playerId)

  }

  private _getIdentifiers(doc: IPlayerDetails): IPlayerIdentifiers {
    
    // check for identifiers
    if (!doc.hasOwnProperty('identifiers')) {
      const identifiers = new PlayerIdentifiers()
      identifiers.isHero = identifiers?.isHero === true || doc.tolariaSupportUntil > Math.floor(Date.now() / 1000)
      return identifiers
    }
    else {
      doc.identifiers.isHero = doc.identifiers?.isHero === true || doc.tolariaSupportUntil > Math.floor(Date.now() / 1000)
      return doc.identifiers
    }

  }

  private _getClubs(doc: IPlayerDetails): string[] {

    return doc.clubDocIds === undefined ? [] : doc.clubDocIds

  }

  private _mapBadges(player: IPlayerDetails): IPlayerBadgeMeta[] {

    // loop through identifiers and add any to the badges array
    const identifierBadges: IPlayerBadgeMeta[] = Object
      .entries(this._getIdentifiers(player)).filter(i => defaultBages.find(x => x.name === i[0]) && i[1])
      .map(identifier => {
        // try finding the identifier among default badges
        const badge = defaultBages.find(i => i.name === identifier[0])
        // check if found
        if (badge) {
          return badge
        }
    })

    // create club badges
    const clubBadges: IPlayerBadgeMeta[] = this._getClubs(player).map(i => {
      const badge: IPlayerBadgeMeta = {
        name: 'clubBadge',
        clubDocId: i,
        isClub: true
      }
      return badge
    })

    // update the observer
    return [...identifierBadges, ...clubBadges]


  }


}

import { EventToPaymentsComponent } from './../event-to-payments/event-to-payments.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { EventToAnnouncementComponent } from '../event-to-announcement/event-to-announcement.component';
import { IEventDetails, IEventPlayerDetails, IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { EventPlayoffConfigComponent, NewEventFormComponent } from 'src/app/components';
import { EventToBatchConfigComponent } from '../event-to-batch-config/event-to-batch-config.component';
import { EventToGroupConfigComponent } from '../event-to-group-config/event-to-group-config.component';
import { ManualPairingsComponent } from '../manual-pairings/manual-pairings.component';
import { EventToCheckInComponent } from '../event-to-check-in/event-to-check-in.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToModalService {

  constructor(
    private modalService: NgbModal
  ) { }

  public openAnnouncementModal(eventDocId: string): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'xl',
      windowClass: 'modal-full-size'
    };
    const modalRef: NgbModalRef = this.modalService.open(EventToAnnouncementComponent, modalOptions);
    modalRef.componentInstance.eventDocId = eventDocId;
  }

  public openPlayoffConfigModal(eventDocId: string, isGroupEvent: boolean): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'xl',
      windowClass: 'playoff-config'
    };
    const modalRef: NgbModalRef = this.modalService.open(EventPlayoffConfigComponent, modalOptions);
    modalRef.componentInstance.eventDocId = eventDocId;
    modalRef.componentInstance.isGroupEvent = isGroupEvent;
  }

  public openManualPairingsModal(eventDocId: string, eventMatches$: Observable<IMatchData[]>, eventPlayers$: Observable<IEventPlayerDetails[]>): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
      windowClass: 'modal-full-size'
    };
    const modalRef = this.modalService.open(ManualPairingsComponent, modalOptions);
    modalRef.componentInstance.eventDocId = eventDocId;
    // modalRef.componentInstance._eventPlayers$ = eventPlayers$;
    // modalRef.componentInstance._eventMatches$ = eventMatches$;
  }

  public openNewBacthModal(event: IEventDetails): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
      // windowClass: 'modal-full-size'
    };
    const modalRef: NgbModalRef = this.modalService.open(EventToBatchConfigComponent, modalOptions);
    modalRef.componentInstance.event = event;
  }

  public openGroupConfigModal(eventDocId: string): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'xl',
      windowClass: 'modal-full-size'
    };
    const modalRef: NgbModalRef = this.modalService.open(EventToGroupConfigComponent, modalOptions);
    modalRef.componentInstance.eventDocId = eventDocId;
  }

  public openCheckInModal(eventDocId: string): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      // windowClass: 'modal-full-size'
    };
    const modalRef = this.modalService.open(EventToCheckInComponent, modalOptions);
    modalRef.componentInstance.eventDocId = eventDocId;
  }

  public openEventEdit(eventDoc: IEventDetails): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: 'static',
      keyboard: true,
      size: 'xl',
      // windowClass: 'modal-full-size'
    };
    const modalRef: NgbModalRef = this.modalService.open(NewEventFormComponent, modalOptions);
    modalRef.componentInstance.eventDoc = eventDoc;
    modalRef.componentInstance.editMode = true;
  }

  public openPayments(event$: Observable<IEventDetails>): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: 'static',
      keyboard: true,
      size: 'xl',
    }
    const modalRef: NgbModalRef = this.modalService.open(EventToPaymentsComponent, modalOptions);
    modalRef.componentInstance.event$ = event$;
  }

}

<div class="tolaria-deck-viewer border border-secondary rounded m-1 mb-3"
  contenteditable="false"
  [ngClass]="{ 'show-full' : showAll, 'is-focused' : isFocused }">

  <div class="wrapper position-relative">
    <ng-container *ngIf="deck$ | async as deck">

      <div class="deck-viewer-cards px-3 py-2 d-flex flex-column gap-3">

        <div class="d-flex align-items-center gap-2">
          <div class="text-larger flex-grow-1">{{ deck.name }}</div>
          <app-player-display-name [playerDocId]="deck.designer" class="text-secondary"></app-player-display-name>
          <app-player-avatar [playerDocId]="deck.designer" size="small"></app-player-avatar>
        </div>

        <div class="deck-list d-flex flex-wrap gap-3">

          <div class="main-deck flex-grow-1">
            <div class="text-large text-bold">Main deck</div>
            <div class="d-flex flex-column text-monospaced text-secondary text-small">
              <ng-container *ngFor="let card of deck.main">
                <ng-template #tipContentMain>
                  <img [src]="card.imageUrl">
                </ng-template>
                <div class="d-flex gap-1">
                  <span [style.minWidth.rem]="2">{{ card.qty }}</span>
                  <span [ngbTooltip]="tipContentMain"
                    tooltipClass="tolaria-wysiwyg-deck-viewer-card-tooltip">
                    {{ card.name }}
                  </span>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="sideboard flex-grow-1">
            <div class="text-large text-bold">Sideboard</div>
            <div class="d-flex flex-column text-monospaced text-secondary text-small">
              <ng-container *ngFor="let card of deck.sideboard">
                <ng-template #tipContentMain>
                  <img [src]="card.imageUrl">
                </ng-template>
                <div class="d-flex gap-1">
                  <span [style.minWidth.rem]="2">{{ card.qty }}</span>
                  <span [ngbTooltip]="tipContentMain"
                    tooltipClass="tolaria-wysiwyg-deck-viewer-card-tooltip">
                    {{ card.name }}
                  </span>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="tolaria-deck-photo-preview p-3 mb-3" *ngIf="hasDeckPhoto">
            <img [src]="deckPhotoUrl" class="rounded rounded-3" width="100%">
          </div>

        </div>

      </div>

      <div class="btn btn-sm btn-secondary text-bg-secondary position-absolute top-100 start-50 translate-middle rounded rounded-4 text-small px-3 p-0"
        contenteditable="false"
        (click)="showAll = !showAll">
        {{ showAll ? 'Show less' : 'Show more' }}
      </div>

    </ng-container>
  </div>

</div>

<div class="wrapper p-2 d-flex flex-column w-100 h-100 overflow-hidden">

  <div class="">
    <div class="btn btn-sm btn-outline-secondary" [routerLink]="['/tickets']">
      <fa-icon [icon]="backIcon" [fixedWidth]="true" class="me-2"></fa-icon>back to Tickets
    </div>
  </div>

  <div class="mt-2 flex-grow-1 d-flex overflow-hidden overflow-x-auto">

    <div class="card flex-grow-1 max-width-100">

      <div class="card-body p-0 no-hover">
        <table class="w-100">
          <thead class="thead">
            <tr class="border-bottom border-secondary row m-0">
              <th class="p-2 col-1"></th>
              <th class="p-2 col-4">Name</th>
              <th class="p-2 col-2">Type</th>
              <th class="p-0 py-2 col-1 text-end">Price</th>
              <th class="p-0 py-2 col-1 text-end">Limit</th>
              <th class="p-0 py-2 col-1 text-end">Sold</th>
              <th class="p-2 pe-3 col-2 text-end">Revenue</th>
            </tr>
          </thead>


          <ng-container *ngIf="tickets$ | async as tickets">
            <tbody>
              <div class="ticket-row" *ngFor="let ticket of tickets" (click)="select(ticket)"
                [ngClass]="{ 'selected': ticket.selected }" [routerLink]="[ticket.docId]">

                <app-ticket-row [ticket]="ticket"></app-ticket-row>
              </div>

            </tbody>

          </ng-container>

        </table>

      </div>

    </div>

    <div class="ms-2">
      <router-outlet></router-outlet>
    </div>

  </div>

</div>

import { BehaviorSubject } from 'rxjs';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { Component, Input } from '@angular/core';
import { IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-player-country',
  templateUrl: './player-country.component.html',
  styleUrls: ['./player-country.component.css']
})
export class PlayerCountryComponent {
  @Input() set playerDocId(data: string) {
    this.setPlayerMini(data)
  }

  public player$: BehaviorSubject<IPlayerMini> = new BehaviorSubject<IPlayerMini>(null)

  constructor(
    private readonly playerNameService: PlayerNameService
  ) { }

  private setPlayerMini(playerDocId: string) {
    if (playerDocId !== '') {
      const player = this.playerNameService.getPlayerById(playerDocId)
      if (player) {
        this.player$.next(player)
      }
    }
    else {
      console.log('[PlayerCountryComponent] --> invalid player document identifier')
    }
  }

  public get value(): string {
    let player = this.player$.getValue()
    if (player === null) {
      return ''
    }
    return player.region
      ? player.country + ' — ' + player.region
      : player.country
  }

}

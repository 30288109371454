import { ToastService } from './toast.service';
import { AuthService } from 'src/app/services';
import { mergeMapTo } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as firestore from 'firebase/firestore'

export interface INotification {
  notification: {
    title: string;
    body: string;
    icon: string;
    route?: string;
  };
  hasRead?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  messages = [];
  currentMessage$ = new BehaviorSubject<INotification>(null);
  token: string;
  // messaging = firebase.messaging();
  permissionGranted$ = new Subject<boolean>();
  private subscription: Subscription

  constructor(
    private afDB: AngularFireDatabase,
    private firestore: AngularFirestore,
    private afMessaging: AngularFireMessaging,
    private auth: AuthService,
    private toastService: ToastService
  ) {
  }

  private updateToken(token: string) {
    const data = {
      [this.auth.user.playerId]: token,
      [this.auth.user.uid]: token
    };
    this.afDB.object('fcmTokens/').update(data);
    this.firestore
      .collection('users')
      .doc(this.auth.user.uid)
      .set({
        fcmTokens: {
          [`${token}`]: firestore.Timestamp.now().seconds,
        }
      }, { merge: true })
      .then(() => {
        console.log('PushNotificationHandler:: MessagingService -> fcmToken updated for user -> ', token)
      })
      .catch((error) => console.error(`PushNotificationHandler:: MessagingService -> ${error}`))
  }

  public getPermission() {
    this.afMessaging.requestPermission
      .pipe(mergeMapTo(this.afMessaging.tokenChanges))
      .subscribe(
        (token) => {
          console.log('PushNotificationHandler:: MessagingService -> Push messaging access granted', token);
          this.permissionGranted$.next(true);
          this.updateToken(token);
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  public receiveMessage() {
    console.log('PushNotificationHandler:: MessagingService -> starting to subscribe to new push messages');
    // this.messaging.onMessage((payload: INotification) => {
    //   console.log('Message received: ', payload);
    //   this.currentMessage$.next(payload);
    // });
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    this.subscription = this.afMessaging.messages.subscribe((payload: any) => {
      console.log('PushNotificationHandler:: MessagingService -> Message received: ', payload);
      const notification: INotification = {
        notification: {
          icon: payload.data.imageUrl,
          body: payload.data.body,
          title: payload.data.title,
          route: payload.data && payload.data['gcm.notification.route'] ? payload.data['gcm.notification.route'] : ''
        },
        hasRead: false
      };
      this.currentMessage$.next(notification);
      const msg = notification.notification.title + ': ' + notification.notification.body;
      this.toastService.show(msg, { classname: 'standard-toast', delay: 3000 });
    });
  }
}

<ng-template let-modal #modalResetPassword>
  <div class="modal-header">Reset Password</div>
  <div class="modal-body">
    <form>
      <div class="input-group input-group-sm">
        <input [(ngModel)]="newPassword" [type]="showNew ? 'text' : 'password'" placeholder="New Password"
          class="form-control {{ newPasswordCheck ? 'is-valid' : 'is-invalid' }}" name="newPassword" autocomplete="new-password">
        <div class="input-group-append" (mousedown)="showNew = true" (mouseup)="showNew = false">
          <button class="btn btn-sm btn-outline-secondary" tabindex="-1">
            <fa-icon [icon]="faEye" [fixedWidth]="true"></fa-icon>
          </button>
        </div>
      </div>
      <div class="input-group input-group-sm mt-3">
        <input [(ngModel)]="confirmPassword" [type]="showConfirm ? 'text' : 'password'" placeholder="Confirm Password"
          class="form-control {{ resetPasswordForm.valid ? 'is-valid' : 'is-invalid' }}" name="confirmPassword" autocomplete="new-password">
        <div class="input-group-append" (mousedown)="showConfirm = true" (mouseup)="showConfirm = false">
          <button class="btn btn-sm btn-outline-secondary" tabindex="-1">
            <fa-icon [icon]="faEye" [fixedWidth]="true"></fa-icon>
          </button>
        </div>
      </div>
      <button class="btn btn-sm btn-secondary mt-3 w-100" (click)="handleResetPassword()"
        [disabled]="resetPasswordForm.invalid">Confirm</button>
    </form>
  </div>
</ng-template>

import { DecksService } from 'src/app/services/decks.service';
import { faThumbsDown, faThumbsUp, faComments, faBriefcase, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';
import { map, tap } from 'rxjs/operators';
import { CardSearchService } from 'src/app/services/card-search.service';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import Card from 'scryfall-client/dist/models/card';

export interface ICardOfTheDayDocument {
  date: any,
  playersDislike: { [playerDocId: string]: boolean };
  playersLike: { [playerDocId: string]: boolean };
  name: string;
  docId: string;
  commentCount: number;
}
export interface ICardOfTheDayMeta {
  docId: string;
  name: string;
  numLikes: number;
  numDislikes: number;
  likedByYou: boolean;
  dislikedByYou: boolean;
  commentCount: number;
}

@Component({
  selector: 'app-card-of-the-day',
  templateUrl: './card-of-the-day.component.html',
  styleUrls: ['./card-of-the-day.component.css']
})
export class CardOfTheDayComponent implements OnDestroy {

  public cardData$: BehaviorSubject<Card> = new BehaviorSubject<Card>(null);
  public deckCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public cardOfTheDay$: Observable<ICardOfTheDayMeta>;

  private currentCardOfTodayDocId: string;
  private todaysDocId: string;
  private componentWasDestroyed$: Subject<boolean> = new Subject<boolean>();

  public cardW = 20;
  public cardH = 27.86943436;

  public icon = {
    like: faThumbsUp,
    dislike: faThumbsDown,
    comments: faComments,
    decks: faBriefcase,
    yesterday: faChevronLeft,
    tomorrow: faChevronRight,
  }

  constructor(
    private afs: AngularFirestore,
    private cardSearch: CardSearchService,
    private auth: AuthService,
    private decksService: DecksService,
  ) {
    const date = new Date(Date.now());
    this.currentCardOfTodayDocId = date.getFullYear() + '-' + this.pad2(date.getMonth() + 1) + '-' + this.pad2(date.getDate());
    this.todaysDocId = date.getFullYear() + '-' + this.pad2(date.getMonth() + 1) + '-' + this.pad2(date.getDate());
    this.fetchCardOfTheDay(this.currentCardOfTodayDocId);
  }

  ngOnDestroy() {
    this.componentWasDestroyed$.next(true);
  }

  public loadToday(): void {
    this.currentCardOfTodayDocId = this.todaysDocId;
    this.fetchCardOfTheDay(this.currentCardOfTodayDocId);
  }

  public loadYesterday(): void {
    this.currentCardOfTodayDocId = this.yesterday;
    this.fetchCardOfTheDay(this.currentCardOfTodayDocId);
  }

  public loadTomorrow(): void {
    this.currentCardOfTodayDocId = this.tomorrow;
    this.fetchCardOfTheDay(this.currentCardOfTodayDocId);
  }

  public likeCard(): void {
    this.afs.collection('cardOfTheDay').doc(this.currentCardOfTodayDocId).update({
      [`playersLike.${this.auth.user.playerId}`]: true,
      [`playersDislike.${this.auth.user.playerId}`]: false,
    })
  }
  public dislikeCard(): void {
    this.afs.collection('cardOfTheDay').doc(this.currentCardOfTodayDocId).update({
      [`playersLike.${this.auth.user.playerId}`]: false,
      [`playersDislike.${this.auth.user.playerId}`]: true,
    })
  }

  private fetchCardOfTheDay(docId: string): void {
    console.log(`changing card of today to ${docId}`);
    this.cardData$.next(null);
    this.cardOfTheDay$ = this.afs.collection('cardOfTheDay').doc<ICardOfTheDayDocument>(docId).valueChanges()
      .pipe(
        tap(document => {
          if (document !== null) {
            const currentCard = this.cardData$.getValue();
            if (currentCard === null || currentCard.name !== document.name) {
              this.fetchCardFromScryfall(document.name);
            }
          }
        }),
        map(document => {
          let likes = 0;
          Object.keys(document.playersLike).forEach((k) => document.playersLike[k] ? likes++ : null)
          let dislikes = 0;
          Object.keys(document.playersDislike).forEach((k) => document.playersDislike[k] ? dislikes++ : null)
          const meta: ICardOfTheDayMeta = {
            docId: document.docId,
            name: document.name,
            numLikes: likes,
            numDislikes: dislikes,
            likedByYou: document.playersLike[this.auth.user.playerId] ? document.playersLike[this.auth.user.playerId] : false,
            dislikedByYou: document.playersDislike[this.auth.user.playerId] ? document.playersDislike[this.auth.user.playerId] : false,
            commentCount: document.commentCount,
          }
          return meta;
        })
      );
  }

  private fetchCardFromScryfall(name: string): void {
    this.cardSearch.getFirstPrintedCardByName(name)
      .then(card => {
        this.cardData$.next(card);
        this.fetchDeckCounter(card.id);
      })
      .catch((error) => console.log(error));
  }

  private fetchDeckCounter(scryfallId: string): void {
    this.decksService.getCountOfDecksContainingCardWithScryfallId(scryfallId)
      .then(count => this.deckCounter$.next(count))
      .catch((error) => console.log(error));
  }

  private pad2(n: number) { return n < 10 ? '0' + n : n; }

  private get yesterday(): string {
    const date = new Date(this.currentCardOfTodayDocId);
    date.setDate(date.getDate() - 1);
    return date.getFullYear() + '-' + this.pad2(date.getMonth() + 1) + '-' + this.pad2(date.getDate());
  }

  private get tomorrow(): string {
    const date = new Date(this.currentCardOfTodayDocId);
    date.setDate(date.getDate() + 1);
    return date.getFullYear() + '-' + this.pad2(date.getMonth() + 1) + '-' + this.pad2(date.getDate());
  }

  public get showYesterdayNav(): boolean {
    return new Date(this.currentCardOfTodayDocId) > new Date('2021-10-01');
  }
  public get showTomorrowNav(): boolean {
    return new Date(this.currentCardOfTodayDocId) < new Date(this.todaysDocId);
  }
  public get messageGroupDocId(): string {
    return `cardOfTheDay[${this.currentCardOfTodayDocId}]`;
  }


}

import { EventService } from 'src/app/services/event/event.service'
import { faTimes, faTimesCircle, faCheckCircle, faCircle, faQuestionCircle, faAt, faComment, faCommentSlash, faTrash } from '@fortawesome/free-solid-svg-icons'
import { IInvitedPlayer } from 'tolaria-cloud-functions/src/_interfaces'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-event-invited-players',
  templateUrl: './event-invited-players.component.html',
  styleUrls: ['./event-invited-players.component.css']
})
export class EventInvitedPlayersComponent {
  @Input() invitedPlayers: IInvitedPlayer[]

  faTimes = faTimes
  faTimesCircle = faTimesCircle
  faCheckCircle = faCheckCircle
  faQuestionCircle = faQuestionCircle
  faCircle = faCircle
  faAt = faAt
  faComment = faComment
  faCommentSlash = faCommentSlash
  faTrash = faTrash

  constructor(
    private eventService: EventService
  ) { }

  public tracker(index: number, item: IInvitedPlayer): string {
    return item.email
  }

  public removeInvitatedPlayer(invitedPlayer: IInvitedPlayer): void {
    this.eventService.removeInvitatedPlayer(invitedPlayer)
  }

}

import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModal, NgbModule, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { PayDonationComponent } from 'src/app/payment/pay-donation/pay-donation.component';
import { faDonate } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-support-button',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgbModule,
    PayDonationComponent,
  ],
  templateUrl: './support-button.component.html',
  styleUrls: ['./support-button.component.css']
})
export class SupportButtonComponent {

  public support = faDonate

  constructor(
    private readonly offcanvasService: NgbOffcanvas,
    private readonly modalService: NgbModal,
  ) { }

  
  public onDonateClick(): void {
    const componentRef = this.modalService.open(PayDonationComponent, {
      size: 'sm',
      keyboard: true,
      centered: false,
      backdrop: 'static',
    })
    componentRef.componentInstance.modalView = true
    componentRef.componentInstance.currency = 'USD'
    componentRef.componentInstance.showText = false
    componentRef.componentInstance.showTypeSelector = true
    this.offcanvasService.dismiss()
  }

}

import { IEventPlayerDetails, IEventTeam } from 'tolaria-cloud-functions/src/_interfaces';
import { Match } from './match.interface';
import { shuffle } from './shuffle';
import blossom from 'edmonds-blossom-fixed';
import { PlayerTieBreakers } from './tiebreakers';

export interface Player {
  id: string | number,
  score: number,
  pairedUpDown?: boolean,
  receivedBye?: boolean,
  avoid?: (string | number)[],
  rating?: number | null
}

export function Swiss(players: IEventPlayerDetails[], round: number, rated: boolean = false): Match[] {

  // // create a shuffled players copy excluding any dropped player
  // let playersCopy: IEventPlayerDetails[] = JSON.parse(JSON.stringify(players))

  // // define the array of pairings to be used for match creation
  // let pairings: Match[] = []
  // let regularMatches: Match[] = []
  // let byeMatches: Match[] = []

  // console.log(``)
  // console.log(``)
  // console.log(`Swiss --> pairing started`)
  // console.log(`        📒 round number..........: ${round}`)
  // console.log(`        🧙 players to be paired..: ${playersCopy.filter(i => !i.dropped).length}`)
  // console.log(`        🤝 expected matches count: ${Math.ceil(playersCopy.filter(i => !i.dropped).length / 2)}`)
  // console.log(`        ⚙️ rated pairings........: ${rated}`)


  // // start pairing
  // if (round > 1) {

  //   // set checkers
  //   let pairingsDone = false
  //   let successfulPairing = false
  //   let pairingAttempt = 1
  //   let attemptFailed = false
  //   const maxAttempts = 20


  //   /**
  //    * This loop will run until pairings are done
  //    * Pairings will be done if a successful pairing
  //    * was found, or if the maximum number of attempts
  //    * has been reached.
  //    */
  //   do {

  //     /**
  //      * This is the attempts loop.
  //      * Each iteration of this will be an attempt to
  //      * find a suitable pairings for the players.
  //      */
  //     do {

  //       console.log(`        ➰ starting pairing attempt ${pairingAttempt} of ${maxAttempts}`)

  //       // update checker
  //       attemptFailed = false

  //       // clear matches
  //       regularMatches = []
  //       byeMatches = []

  //       // fresh copy of the players array
  //       playersCopy = JSON.parse(JSON.stringify(players.filter(i => !i.dropped)))

  //       // sort players by match points or standings depending on configuration
  //       if (rated) {
  //         // the final round of a MTG Swiss tournament should be paired top-down according
  //         // to standing while following the regular rules with allowed number of bye's
  //         // and avoiding the same opponent more than once
  //         playersCopy = PlayerTieBreakers.compute(playersCopy)
  //         playersCopy = PlayerTieBreakers.sort(playersCopy)
  //         playersCopy = PlayerTieBreakers.rank(playersCopy, false)
  //       }
  //       else {
  //         playersCopy = shuffle(playersCopy)
  //         playersCopy = playersCopy.sort((a, b) => b.matchPoints - a.matchPoints)
  //       }

  //       // check if odd number of players and assign bye
  //       if (playersCopy.length & 1) {

  //         // get last player that has NOT yet received a bye
  //         const matchNum = Math.ceil(playersCopy.length / 2)
  //         const byePlayer = playersCopy.filter(i => !i.haveByeMatch).pop()

  //         // add match to array
  //         byeMatches.push({
  //           player1: byePlayer.playerDocId,
  //           player2: null,
  //           round: round,
  //           match: matchNum
  //         })

  //         // remove player from the player copy array
  //         playersCopy.splice(playersCopy.findIndex(i => i.playerDocId === byePlayer.playerDocId), 1)

  //       }


  //       /**
  //        * For each player starting from the top of the players array
  //        * 1. filter out all opponents
  //        * 2. if opponent found, remove both and add them as a pairing,
  //        *    else fail the attempt and continue with next attempt
  //        * 3. all done, set successful pairing to true
  //        */
  //       do {

  //         // get first player of array
  //         const player1 = playersCopy.splice(0, 1)[0]
  //         let opponentDocId = null

  //         // get valid opponents
  //         // if none, break look and start next attempt
  //         if (playersCopy.filter(i => !i.avoid.includes(player1.playerDocId)).length === 0) {
  //           // pairingsFailed = true
  //           // pairingAttempt++
  //           // break
  //           // if it's the last 2 players and the final round,
  //           // ignore the avoid rule, aka only break if NOT rated
  //           if (rated && playersCopy.length === 1) {
  //             opponentDocId = playersCopy[0].playerDocId
  //           }
  //           else {
  //             attemptFailed = true
  //             break
  //           }
  //         }

  //         if (opponentDocId === null) {
  //           opponentDocId = playersCopy.filter(i => !i.avoid.includes(player1.playerDocId))[0].playerDocId
  //         }
  //         const player2 = playersCopy.splice(playersCopy.findIndex(i => i.playerDocId === opponentDocId), 1)[0]

  //         // add match to array
  //         regularMatches.push({
  //           player1: player1.playerDocId,
  //           player2: player2.playerDocId,
  //           round: round,
  //           match: regularMatches.length + 1
  //         })

  //       }
  //       while (playersCopy.length > 0)

  //       // check if attempt was successful and flag successful pairing
  //       if (!attemptFailed) {
  //         console.log(`        👍 pairing attemt ${pairingAttempt} was successful!`)
  //         successfulPairing = true
  //       }
  //       else {
  //         console.log(`        👎 pairing attemt ${pairingAttempt} failed!`)
  //         successfulPairing = false
  //       }

  //       // increment pairing attempt
  //       pairingAttempt++

  //     }
  //     while (pairingAttempt <= maxAttempts && !successfulPairing)

  //     // check number of failed attempts
  //     if (pairingAttempt > maxAttempts && !successfulPairing) {
  //       console.log(`        ⚠️ maximum number of attemts (${maxAttempts}) was reached without a successful pairing`)
  //       break
  //     }
  //     else {
  //       // success
  //       console.log(`        ✔️ pairing attemt ${pairingAttempt} was successful!`)
  //       pairingsDone = true
  //     }

  //     // combine matches into the pairings to return putting any bye matches at the end
  //     pairings = [...regularMatches, ...byeMatches]


  //   }
  //   while (!pairingsDone)

  //   // check if pairings was successful
  //   if (!pairingsDone) {
  //     console.log(`        ❌ pairings failed, handle pairings manually`)
  //     return []
  //   }


  // }
  // else {

  //   // create a shuffled copy of the players array
        // const playerDocIds = shuffle(JSON.parse(JSON.stringify(players.filter(i => !i.dropped)))).map(i => i.playerDocId)

  //   /**
  //    * loop and remove the two first players of the players array copy
  //    * and pair them against each other until the array is empty
  //    */
  //   do {
  //     const pair = playerDocIds.splice(0, 2)
  //     const match: Match = {
  //       player1: pair[0],
  //       player2: pair[1],
  //       match: pairings.length + 1,
  //       round: round
  //     }
  //     pairings.push(match)

  //     // check if bye needed
  //     if (playerDocIds.length === 1) {
  //       const match: Match = {
  //         player1: playerDocIds.splice(0, 1)[0],
  //         player2: null,
  //         match: pairings.length + 1,
  //         round: round
  //       }
  //       pairings.push(match)
  //     }

  //   }
  //   while (playerDocIds.length > 0)

  // }

  // // return the pairings

  // return pairings

  // Shawns way of doing stuff
  // https://docs.google.com/spreadsheets/d/1bYRbfL4jgCKkHO-q9jXeTgZKnrzGGXp3MKu7epIA7R4/edit#gid=0

  // create match array
  const matches = []

  // get bye score
  const byeScore = Math.min(...[...new Set(players.filter(i => !i.haveByeMatch && !i.dropped).map(i => i.matchPoints))])

  // create local array of the passed players
  let playerArray: IEventPlayerDetails[] = JSON.parse(JSON.stringify(players.filter(i => !i.dropped)))
  if (rated) {
    playerArray.filter(p => !p.hasOwnProperty('rating') || p.rating === null).forEach(p => p.rating = 0)
  }

  // shuffle the players
  playerArray = shuffle(playerArray)

  // add bye player if odd number of players
  if (playerArray.length % 2 !== 0) {
    playerArray.push(byePlayer)
  }

  // add index to all players
  playerArray.forEach((p, i) => p.index = i)

  let pairs = []
  for (let i = 0; i < playerArray.length; i++) {
    const curr = playerArray[i]
    const next = playerArray.slice(i + 1)
    for (let j = 0; j < next.length; j++) {
      const opp = next[j]

      if (curr.hasOwnProperty('avoid') && curr.avoid.includes(opp.playerDocId)) {
        continue
      }

      if (curr.matchPoints !== byeScore && opp.playerDocId === '*** BYE ***' || curr.playerDocId === '*** BYE ***' && opp.matchPoints !== byeScore) {
        continue
      }

      // harmonic mean
      let wt = Math.sqrt(curr.matchPoints * opp.matchPoints)

      if (opp.playerDocId === '*** BYE ***' || curr.playerDocId === '*** BYE ***') {
        wt -= 100 * curr.opponentsDocIds.filter(i => i === '*** BYE ***').length
      }

      pairs.push([curr.index, opp.index, wt])

    }
  }

  const blossomPairs = blossom(pairs, true)
  let playerCopy = [...playerArray]
  let byeArray: IEventPlayerDetails[] = []
  let match = 1
  do {
    const indexA = playerCopy[0].index
    const indexB = blossomPairs[indexA]
    if (indexB === -1) {
      byeArray.push(playerCopy.splice(0, 1)[0])
      continue
    }
    playerCopy.splice(0, 1)
    playerCopy.splice(playerCopy.findIndex(p => p.index === indexB), 1)
    matches.push({
      round: round,
      match: match++,
      player1: playerArray.find(p => p.index === indexA).playerDocId,
      player2: playerArray.find(p => p.index === indexB).playerDocId
    })
  } while (playerCopy.length > blossomPairs.reduce((sum, idx) => idx === -1 ? sum + 1 : sum, 0))
  if (playerCopy.length === 1 && playerCopy[0].playerDocId === '*** BYE ***') {
    byeArray = [...byeArray]
  }
  else {
    byeArray = [...byeArray, ...playerCopy]
  }
  for (let i = 0; i < byeArray.length; i++) {
    matches.push({
      round: round,
      match: match++,
      player1: byeArray[i].playerDocId,
      player2: null
    })
  }

  console.log(matches)
  return matches

}

export function SwissTeam(players: IEventTeam[], round: number, rated: boolean = false): Match[] {
  const matches = [];
  let playerArray: IEventTeam[] = [];
  if (Array.isArray(players)) {
    playerArray = players;
  } else {
    // playerArray = [...new Array(players)].map((_, i) => i + 1);
    return []
  }
  if (rated) {
    playerArray.filter(p => !p.hasOwnProperty('rating') || p.rating === null).forEach(p => p.rating = 0);
  }
  playerArray = shuffle(playerArray);
  playerArray.forEach((p, i) => p.index = i);
  const scoreGroups = [...new Set(playerArray.map(p => p.matchPoints))].sort((a, b) => a - b);
  const scoreSums = [...new Set(scoreGroups.map((s, i, a) => {
    let sums = [];
    for (let j = i; j < a.length; j++) {
      sums.push(s + a[j]);
    }
    return sums;
  }).flat())].sort((a, b) => a - b);
  let pairs = [];
  for (let i = 0; i < playerArray.length; i++) {
    const curr = playerArray[i];
    const next = playerArray.slice(i + 1);
    const sorted = rated ? [...next].sort((a, b) => Math.abs(curr.rating - a.rating) - Math.abs(curr.rating - b.rating)) : [];
    for (let j = 0; j < next.length; j++) {
      const opp = next[j];
      if (curr.hasOwnProperty('avoid') && curr.avoid.includes(opp.id)) {
        continue;
      }
      let wt = 12 * Math.log10(scoreSums.findIndex(s => s === curr.matchPoints + opp.matchPoints) + 1);
      const scoreGroupDiff = Math.abs(scoreGroups.findIndex(s => s === curr.matchPoints) - scoreGroups.findIndex(s => s === opp.matchPoints));
      wt += scoreGroupDiff < 2 ? 5 / (2 * Math.log10(scoreGroupDiff + 2)) : 1 / Math.log10(scoreGroupDiff + 2);
      if (scoreGroupDiff === 1 && curr.hasOwnProperty('pairedUpDown') && curr.pairedUpDown === false && opp.hasOwnProperty('pairedUpDown') && opp.pairedUpDown === false) {
        wt += 1.1;
      }
      if (rated) {
        wt += (1 / 3) * (Math.log2(sorted.length) - Math.log2(sorted.findIndex(p => p.id === opp.id) + 1));
      }
      if ((curr.hasOwnProperty('haveByeMatch') && curr.haveByeMatch) || (opp.hasOwnProperty('haveByeMatch') && opp.haveByeMatch)) {
        wt *= 1.25;
      }
      pairs.push([curr.index, opp.index, wt]);
    }
  }
  const blossomPairs = blossom(pairs, 1);
  // const blossomPairs = blossom(pairs);
  let playerCopy = [...playerArray];
  let byeArray = [];
  let match = 1;
  do {
    const indexA = playerCopy[0].index;
    const indexB = blossomPairs[indexA];
    if (indexB === -1) {
      byeArray.push(playerCopy.splice(0, 1)[0]);
      continue;
    }
    playerCopy.splice(0, 1);
    playerCopy.splice(playerCopy.findIndex(p => p.index === indexB), 1);
    matches.push({
      round: round,
      match: match++,
      player1: playerArray.find(p => p.index === indexA).id,
      player2: playerArray.find(p => p.index === indexB).id
    });
  } while (playerCopy.length > blossomPairs.reduce((sum, idx) => idx === -1 ? sum + 1 : sum, 0));
  byeArray = [...byeArray, ...playerCopy];
  for (let i = 0; i < byeArray.length; i++) {
    matches.push({
      round: round,
      match: match++,
      player1: byeArray[i].id,
      player2: null
    })
  }
  console.log(matches)
  return matches;
}

export const byePlayer: IEventPlayerDetails = {
  playerDocId: '*** BYE ***',
  playerUid: '*** BYE ***',
  name: '*** BYE ***',
  dropped: false,
  disqualified: false,
  haveByeMatch: false,
  wins: 0,
  losses: 0,
  draws: 0,
  matchPoints: 0,
  gamePoints: 0,
  matchWinPercentage: 0,
  gameWinPercentage: 0,
  opponentMatchWinPercentage: 0,
  opponentGameWinPercentage: 0,
  adj_gp: 0,
  adj_gwp: 0,
  adj_mp: 0,
  adj_mwp: 0,
  opponentsDocIds: [],
  deckSubmission: undefined
}

import { IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'playersByCheckIn'
})
export class PlayersByCheckInPipe implements PipeTransform {

  transform(items: IEventPlayerDetails[], showCheckedInPlayers: boolean, searchString: string): IEventPlayerDetails[] {

    if (items) {

      // filter on status
      if (!showCheckedInPlayers) {
        items = items.filter(i => !i.hasCheckedIn || i.hasCheckedIn === undefined)
      }

      // filter on search string
      if (searchString !== undefined && searchString !== '') {
        items = items.filter(i => i.name.toLowerCase().replace(/\s/g, '').includes(searchString.toLowerCase().replace(/\s/g, '')))
      }

    }

    return items.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

  }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMessageReactionMeta, MessageItem } from '../../../services/message-list.service';
import { PlayerDisplayNameComponent } from 'src/app/components';
import { PlayerAvatarComponent } from 'src/app/components/players/player-avatar/player-avatar.component';
import { TimestampClockComponent } from 'src/app/components/app-structure/timestamp-clock/timestamp-clock.component';
import { ChatFormatPipe } from 'src/app/pipes';
import { LinkyModule } from 'ngx-linky';
import { MessageActionComponent } from '../../message-action/message-action.component';
import { MessageSenderService } from '../../../services/message-sender.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faMask, faThumbTack } from '@fortawesome/free-solid-svg-icons';
import { ImagePreviewService } from 'src/app/private/_shared/services/image-preview.service';
import { QuillViewComponent } from 'ngx-quill';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TolariaWysiwygComponent } from '../../tolaria-wysiwyg/tolaria-wysiwyg.component';
import { BehaviorSubject } from 'rxjs';
import { deltaOnlyEmojis } from '../../tolaria-wysiwyg/tolaria-wysiwyg-helper';
import { TolariaWysiwygOutput } from '../../tolaria-wysiwyg/tolaria-wysiwyg.interfaces';
import { TolariaEmojiComponent } from '../../tolaria-emoji-picker/tolaria-emoji/tolaria-emoji.component';
import { TolariaEmojiPickerButtonComponent } from '../../tolaria-emoji-picker/tolaria-emoji-picker-button/tolaria-emoji-picker-button.component';
import { EmojiItem } from '../../tolaria-emoji-picker/emojis';
import { MessageActionService } from '../../../services/message-action.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    PlayerDisplayNameComponent,
    PlayerAvatarComponent,
    TimestampClockComponent,
    ChatFormatPipe,
    LinkyModule,
    MessageActionComponent,
    TolariaWysiwygComponent,
    FontAwesomeModule,
    QuillViewComponent,
    TolariaEmojiComponent,
    TolariaEmojiPickerButtonComponent,
    NgbTooltipModule,
  ],
})
export class ChatMessageComponent implements OnInit {
  @Input() msg: MessageItem
  @Output() scrollTo = new EventEmitter<string>()

  public whisperMode = faMask
  public pin = faThumbTack
  public editMode$ = new BehaviorSubject<boolean>(false)
  public onlyEmojis: boolean = false

  constructor(
    private readonly sender: MessageSenderService,
    private readonly action: MessageActionService,
    private readonly imagePreview: ImagePreviewService,
    private readonly sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    if (this.msg.message.delta && this.msg.message.delta.ops) {
      this.onlyEmojis = deltaOnlyEmojis(this.msg.message.delta.ops)
    }
  }

  public onReplyToPressed(messageDocId: string): void {
    this.scrollTo.emit(messageDocId)
  }

  public onEditMessageEmitted(readonly: boolean): void {
    this.editMode$.next(true)
  }

  public handleSave(output: TolariaWysiwygOutput): void {
    this.sender.save({
      msg: this.msg,
      delta: JSON.parse(JSON.stringify(output.delta)),
      images: output.images,
    })
    this.msg.message.delta = output.delta
    this.editMode$.next(false)
  }

  public handleCancelEdit(value: boolean): void {
    this.editMode$.next(false)
  }

  public updateReaction(item: IMessageReactionMeta): void {
    if (item.youReacted) {
      this.action.removeRaction(this.msg.docId, item.reaction)
    }
    else {
      this.action.addReaction(this.msg.docId, item.reaction)
    }
  }

  public onReaction(emoji: EmojiItem): void {
    this.action.addReaction(this.msg.docId, emoji.shortcode)
  }

  public onImagePress(image: any): void {
    const index = this.msg.message.images.findIndex(i => i.downloadUrl === image.downloadUrl)
    const imageUris = this.msg.message.images.map(i => i.downloadUrl)
    this.imagePreview.show({
      imageUris: imageUris,
      index: `slide-${index}`,
      playerDocId: this.msg.message.playerDocId,
    })
  }

  public get html(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.msg.message.html)
  }


}

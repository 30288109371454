<div class="clubs-and-other-badges" *ngIf="playerBadges$ | async as badges">
  <ngb-carousel [showNavigationArrows]="false" [showNavigationIndicators]="false" [keyboard]="false"
    [animation]="false">
    <ng-container *ngFor="let badge of badges">
      <ng-container *ngIf="!badge.isClub; else club">
        <ng-template ngbSlide>
          <ng-template #tipContent>
            <div class="p-2" [innerHtml]="badge.tooltipHtmlContent"></div>
          </ng-template>
          <div class="d-flex align-items-center justify-content-center" [ngbTooltip]="tipContent" container="body">
            <div class="hero-club-badge {{ borderClass }}">
              <img [src]="badge.imageUrl">
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #club>
        <ng-template ngbSlide>
          <app-club-avatar [clubDocId]="badge.clubDocId"></app-club-avatar>
        </ng-template>
      </ng-template>
    </ng-container>
  </ngb-carousel>
</div>

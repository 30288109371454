import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { PlayerListingService } from '../services/player-listing.service';
import { IPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { QuickMessageComponent } from 'src/app/private/social/messages/components/quick-message/quick-message.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthService } from 'src/app/services';
import { faClock, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { NgbActiveModal, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from 'src/app/services/config.service';
import { faEllipsisV, faPhone } from '@fortawesome/free-solid-svg-icons';
// import { CasualMatchService } from '../services/casual-match.service';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { CasualMatchButtonComponent } from '../casual-match-button/casual-match-button.component';

@Component({
  selector: 'app-player-profile',
  templateUrl: './player-profile.component.html',
  styleUrls: ['./player-profile.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    QuickMessageComponent,
    FontAwesomeModule,
    NgbTooltipModule,
    NgbDropdownModule,
    forwardRef(() => CasualMatchButtonComponent),
  ]
})
export class PlayerProfileComponent implements OnInit, OnDestroy {
  @Input() set playerDocId(id: string) {
    console.log('PlayerProfileComponent:: playerDocId emitted', id)
    this.id = id
    this.initPlayerObserver(id)
  }
  @Input() modal: boolean = true

  private id: string
  private destroyed$ = new Subject<boolean>()
  private subscripton: Subscription
  public player$: Observable<IPlayerDetails>
  public handleIcons = this.configService.handleIcons
  
  constructor(
    private readonly players: PlayerListingService,
    private readonly playerNames: PlayerNameService,
    private readonly auth: AuthService,
    private readonly configService: ConfigService,
    private readonly activeModal: NgbActiveModal,
    // private readonly casual: CasualMatchService,
  ) { }

  ngOnInit() {
    console.log('PlayerProfileComponent:: ngOnInit')
    if (this.id) {
      this.initPlayerObserver(this.id)
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    if (this.subscripton) {
      this.subscripton.unsubscribe()
    }
  }

  private initPlayerObserver(id: string) {
    this.player$ = this.players.getPlayer(id)
  }

  public onClose(): void {
    this.activeModal.close()
  }

  public onCopyPlayerId(id: string): void {
    navigator.clipboard.writeText(id)
  }

  public onCasualGame(): void {
    const mini = this.playerNames.getPlayerById(this.id)
    // this.casual.create(mini).then((result) => {
    //   console.log(result)
    // })
  }
  
  public onEmailPlayer(email: string): void {
    console.log('Should email player', email)
    const mailtoLink = 'mailto:' + email
    const link = document.createElement('a')
    link.href = mailtoLink
    link.target = '_blank'
    link.click()
  }

  public get displayType(): string {
    return this.auth.user.settings.clock24 ? 'HH:mm' : 'h:mm a';
  }

  public get todayDate(): Date {
    return new Date()
  }

  public get icon() {
    return {
      clock: faClock,
      phone: faPhone,
      email: faEnvelope,
      menu: faEllipsisV,
    }
  }

}

import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CardFactoryHelperService } from '../card-factory-helper.service';
import { ICardConfig } from '../card-factory.model';
import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { faComment, faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-created-cards',
  templateUrl: './created-cards.component.html',
  styleUrls: ['./created-cards.component.css']
})
export class CreatedCardsComponent {

  public createdCards$: Observable<ICardConfig[]> = this.cfHelper.createdCards

  public icons = {
    like: faThumbsUp,
    dislike: faThumbsDown,
    comment: faComment,
  }

  constructor(
    private cfHelper: CardFactoryHelperService,
    private router: Router,
  ) { }

  public tracker(index: number, item: ICardConfig): string {
    return item.docId
  }

  public get searchString(): string {
    return this.cfHelper.filterValue
  }

  public get selectedCard(): string {
    return this.router.url.split('/')[3] === undefined ? '' : this.router.url.split('/')[3]
  }

  public likeCard(docId: string): void {
    this.cfHelper.likeCard(docId)
  }

  public dislikeCard(docId: string): void {
    this.cfHelper.dislikeCard(docId)
  }

}

@Pipe({
  name: 'createdCardsFilter'
})
export class CreatedCardsFilter implements PipeTransform {

  transform(items: ICardConfig[], searchVal: string): ICardConfig[] {
    if (!items) { return [] }

    return items.filter(i => i.name.toLowerCase().includes(searchVal.toLowerCase()))
  }

}

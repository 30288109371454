import { Pipe, PipeTransform } from '@angular/core';
import { IPick } from 'tolaria-cloud-functions/src/_interfaces';

@Pipe({
  name: 'draftRotisseriePicks'
})
export class DraftRotisseriePicksPipe implements PipeTransform {

  transform(items: IPick[], playerDocId: string): IPick[] {
    return items.filter((p) => p.playerDocId === playerDocId).sort((a, b) => a.pickedTimestamp - b.pickedTimestamp);
  }

}

import { MatchService } from './../../../../../services/match.service';
import { takeUntil, filter } from 'rxjs/operators'
import { IDeckSubmission, IMatchData } from 'tolaria-cloud-functions/src/_interfaces'
import { DecksService } from 'src/app/services/decks.service'
import { StorageService } from 'src/app/services/storage.service'
import { DeckLinkerComponent, IDeckLinkerResponse } from 'src/app/components/decks/deck-linker/deck-linker.component'
import { EventService, AuthService } from 'src/app/services'
import { DialogsService } from 'src/app/services/dialogs.service'
import { faCircle, faCheckCircle, faListAlt, faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import { Observable, BehaviorSubject, Subject } from 'rxjs'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { IEventDetails, IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { ProductType } from 'tolaria-cloud-functions/src/_interfaces'
import { PayRegistrationFeeComponent } from 'src/app/payment/pay-registration-fee/pay-registration-fee.component'

interface IRegistrationFeeMeta {
  active: boolean
  amount: number
  currency: string
  productType: ProductType
  hasPaid: boolean
  tolariaPayment: boolean
  ticketPurchase: boolean
}
@Component({
  selector: 'app-event-player-actions',
  templateUrl: './event-player-actions.component.html',
  styleUrls: ['./event-player-actions.component.css']
})
export class EventPlayerActionsComponent implements OnInit, OnDestroy {
  @Input() eventDocId: string
  @Input() eventDoc$: Observable<IEventDetails>
  @Input() playerDetails$: Observable<IEventPlayerDetails>

  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()
  private __event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  private __matches$: BehaviorSubject<IMatchData[]> = new BehaviorSubject<IMatchData[]>(null)

  registrationFee$: BehaviorSubject<IRegistrationFeeMeta> = new BehaviorSubject<IRegistrationFeeMeta>(null)

  faUpload = faUpload
  faCircle = faCircle
  faCheckCircle = faCheckCircle
  faSquare = faSquare
  faCheckSquare = faCheckSquare
  faListAlt = faListAlt

  constructor(
    private modalService: NgbModal,
    private dialogs: DialogsService,
    private eventService: EventService,
    private storageService: StorageService,
    private deckService: DecksService,
    private auth: AuthService,
    private matchService: MatchService,
  ) { }

  ngOnInit(): void {

    console.log(this.eventDocId)

    this.matchService
      .getPlayersMatchesInEvent(this.auth.user.playerId, this.eventDocId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(m => this.__matches$.next(m))

    this.eventDoc$.pipe(takeUntil(this.componentDestroyed$)).subscribe(event => {
      this.__event$.next(event)
      this.registrationFee$.next({
        active: event.details.registrationFee ? event.details.registrationFee.active : false,
        amount: event.details.registrationFee ? event.details.registrationFee.amount : null,
        currency: event.details.registrationFee ? event.details.registrationFee.currency : null,
        productType: event.details.registrationFee ? event.details.registrationFee.productType : null,
        tolariaPayment: event.details?.registrationFee?.tolariaPayment,
        hasPaid: event.details?.registrationFee?.paidBy
          && event.details.registrationFee.paidBy[this.auth.user.playerId] !== undefined
          ? event.details.registrationFee.paidBy[this.auth.user.playerId].paid
          : false,
        ticketPurchase: event.details?.registrationFee?.paidByTicket
          && event.details.registrationFee.paidByTicket[this.auth.user.playerId] !== undefined
          ? true
          : false,
      })
    })
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  // DECK LIST
  public openDeckLinker(event: IEventDetails, playerDetails: IEventPlayerDetails) {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    }
    const modalRef: NgbModalRef = this.modalService.open(DeckLinkerComponent, modalOptions)
    modalRef.componentInstance.mandatoryDeckList = event.details.deckList
    modalRef.componentInstance.mandatoryDeckPhoto = event.details.deckPhoto
    modalRef.componentInstance.deckSubmission = playerDetails.deckSubmission

    modalRef.result.then(
      async (result: IDeckLinkerResponse) => {
        console.log('result', result)

        // check if a deck was selected
        if (result.selectedDeck !== null) {
          // check if a deck version was selected
          if (result.selectedDeckVersion !== null) {
            // add the deck version as the deck to play
            this.updateDeckSubmissionInfo(event.docId, {
              deckPhoto: result.selectedDeckVersion.deckPhotoValid,
              deckList: result.selectedDeckVersion.deckListValid,
              deckListDocId: result.selectedDeck.docId,
              deckVersionDocId: result.selectedDeckVersion.versionDocId,
            },
              playerDetails.deckSubmission)
          }
          else {
            // add the deck original version as the deck to play
            this.updateDeckSubmissionInfo(event.docId, {
              deckPhoto: result.selectedDeck.deckPhotoValid,
              deckList: result.selectedDeck.deckListValid,
              deckListDocId: result.selectedDeck.docId,
              deckVersionDocId: null,
            },
              playerDetails.deckSubmission)
          }
        }
        // check if a new deck has been registered
        else if (result.newDeck !== null) {

          // check if deck photo needs to be uploaded
          if (result.deckPhotoBase64 !== null && result.deckPhotoBase64.length > 0) {
            const downloadUrl = await this.storageService.uploadImage(`decks/${result.newDeck.docId}.png`, result.deckPhotoBase64)
            result.newDeck.deckPhotoUrl = downloadUrl
            // add the event doc id to disable DELETE of the deck
            result.newDeck.eventDocIds.push(event.docId)
          }

          // save deck list
          this.deckService.saveDeckList(result.newDeck)
            .then((response) => {
              // update event player doc
              if (response.status) {
                this.updateDeckSubmissionInfo(event.docId, {
                  deckPhoto: result.newDeck.deckPhotoUrl !== null,
                  deckList: result.newDeck.main.length >= 60 && result.newDeck.sideboard.length <= 15,
                  deckListDocId: result.newDeck.docId,
                  deckVersionDocId: null
                },
                  playerDetails.deckSubmission)
              }
              else {
                console.log(response)
              }
            })
            .catch((error) => {
              console.log(error)
            })
        }
        // update the deck submission with NOTHING as nothing was selected or created
        else {
          this.updateDeckSubmissionInfo(event.docId, {
            deckPhoto: false,
            deckList: false,
            deckListDocId: null,
            deckVersionDocId: null
          },
            playerDetails.deckSubmission)
        }
      },
      (reason) => {
        console.log('reason', reason)
      }
    )
  }

  private updateDeckSubmissionInfo(eventDocId: string, deckSubmission: IDeckSubmission, deckSubmissionBefore: IDeckSubmission): void {
    this.eventService.playerActions.updateDeckSubmission(eventDocId, deckSubmission, deckSubmissionBefore)
  }

  public unattendEvent(event: IEventDetails, playerDetails: IEventPlayerDetails): void {
    this.dialogs.openDialog(
      'Unattend Event?',
      `You are about to unattend the event "${event.details.name}", are you sure?`,
      'ConfirmCancel'
    )
      .then((dialogResponse: any) => {
        if (dialogResponse.pressed !== 'cancel') {
          this.eventService.removePlayerFromEvent(event.docId, playerDetails.playerDocId)
        }
      })
  }

  public dropFromEvent(event: IEventDetails, playerDetails: IEventPlayerDetails): void {
    this.dialogs.openDialog(
      'Drop from Event?',
      `You are about to drop from the event "${event.details.name}", are you sure?`,
      'ConfirmCancel'
    )
      .then((dialogResponse: any) => {
        if (dialogResponse.pressed !== 'cancel') {
          this.eventService.dropPlayerFromEvent(event.docId, playerDetails.playerDocId)
        }
      })
  }

  public payRegistrationFee(): void {

    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'sm',
    }

    this.modalService.open(PayRegistrationFeeComponent, modalOptions)

  }

  public get playerCanDrop(): boolean {

    if (this.__event$.getValue() === null) { return false }
    else {
      const event = this.__event$.getValue()
      if (event.details.structure.isBatch) {
        if (this.__matches$.getValue() === null) { return false }
        else {
          const matches = this.__matches$.getValue()
          return matches.filter(i => i.roundNumber === event.activeRound && !i.isReported).length === 0
        }
      }
      return event.statusCode !== 1 && event.statusCode !== 5
    }
  }

}

import { IEventDetails, IEventPlayerDetails } from './event.interfaces'

export interface ILeaguePlayer {
  playerDocId: string;
  playerUid: string;
  name: string;
  wins: number;
  losses: number;
  draws: number;
  matchPoints: number;
  gamePoints: number;
  leagueDocId: string;
  leagueRank: number;
  leaguePoints: number;
  leaguePointsInfo: ILeaguePointInfo[]
}

export interface ILeagueEventPlayerDetails extends IEventPlayerDetails {
  eventDocId: string;
  eventName: string;
}

export interface ILeagueDocument {
  allowManualPoints: boolean;
  bannerUrl?: string;
  createdByPlayerDocId: string;
  createdByUid: string;
  createdTimestamp: number;
  description: string;
  docId: string;
  eventDocIds: string[];
  manualPoints: ILeagueManualPoint[];
  name: string;
  pointStructure: ILeaguePoint[];
  timestampEnd: number;
  timestampStart: number;
}

export interface ILeaguePoint {
  rankFrom: number;
  rankTo: number;
  rankFromSuffix: string;
  rankToSuffix: string;
  isRange: boolean;
  points: number;
  type: LeaguePointType;
  customExplaination: string;
}
export enum LeaguePointType {
  RANK = 'RANK',
  MATCH_FULFILMENT = 'MATCH_FULFILMENT', // used for group events
  MATCH_WIN = 'MATCH_WIN',
  GAME_WIN = 'GAME_WIN',
  BRACKET_WIN = 'BRACKET_WIN',
  CUSTOM = 'CUSTOM',
}
export interface ILeagueManualPoint {
  playerDocId: string;
  points: number;
  comment: string;
}
export interface ILeaguePointInfo {
  points: number;
  comment: string;
}
export interface ILeagueMeta extends ILeagueDocument {
  eventDoc: IEventDetails;
  players: IEventPlayerDetails[];
}

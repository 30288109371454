<div class="card rounded-3 shadow-sm" aria-hidden="true" [style.minWidth.%]="100">
  <div class="card-body d-flex flex-column gap-2">
    <div class="d-flex align-items-center mb-2 my-0 gap-2">
      <div class="placeholder-glow">
        <span class="placeholder rounded-circle" [style.minWidth.rem]="2" [style.minHeight.rem]="2"></span>
      </div>
      <div class="placeholder-glow col-6">
        <span class="placeholder rounded placeholder-lg col-12" [style.minHeigh.rem]="1.75"></span>
      </div>
    </div>
    <div class="d-flex align-items-center text-secondary gap-2">
      <div class="placeholder-glow col-1">
        <span class="placeholder rounded col-12"></span>
      </div>
      <div class="placeholder-glow col-8">
        <span class="placeholder rounded placeholder-lg col-12"></span>
      </div>
    </div>
    <div class="d-flex align-items-center gap-2">
      <div class="placeholder-glow col-6">
        <span class="placeholder rounded col-12"></span>
      </div>
    </div>
    <div class="d-flex align-items-center gap-2" *ngIf="default">
      <div class="placeholder-glow col-4">
        <span class="placeholder rounded col-12"></span>
      </div>
    </div>
    <div class="footer">
      <div class="placeholder-glow col-12 d-flex justify-content-end">
        <span class="btn btn-primary disabled placeholder col-2"></span>
      </div>
    </div>
  </div>
</div>
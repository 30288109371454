import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { tolDeckStack, tolCards, tolSend, tolEmoji, tolAtSign } from '../assets';
import { faMask, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { faImage, faSmileWink } from '@fortawesome/free-regular-svg-icons';
import { Options } from '@popperjs/core';
import { TolariaEmojiPickerButtonComponent } from '../../tolaria-emoji-picker/tolaria-emoji-picker-button/tolaria-emoji-picker-button.component';
import QuillType from 'quill';
import { EmojiItem } from '../../tolaria-emoji-picker/emojis';
import { tolFormat } from '../assets/tol-format';

export type ComposerAction =
'send-message' |
'toggle-silent-mode' |
'toggle-format' |
'insert-emoji' |
'insert-deck' |
'insert-card' |
'insert-tourney' |
'mention-someone' |
'cancel-edit' |
'save-message' |
'upload-image'

export interface ButtonResult {
  action: ComposerAction
  data?: {
    emoji?: EmojiItem
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tolaria-wysiwyg-footer',
  templateUrl: './tolaria-wysiwyg-footer.component.html',
  styleUrls: [],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgbTooltipModule,
    TolariaEmojiPickerButtonComponent,
  ]
})
export class TolariaWysiwygFooterComponent {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onButtonPress = new EventEmitter<ButtonResult>()
  @Input() set visible(val: boolean) {
    this.showFormatting = val
  }
  @Input() set formatting(val: boolean) {
    this.showFormatting = val
  }
  @Input() set editorEmpty(val: boolean) {
    this.isEmpty = val
  }
  @Input() set silentMode(val: boolean) {
    this.isSilentMode = val
  }
  @Input() composer: boolean
  @Input() editor: QuillType
  @Input() imageUploadEnabled: boolean = true
  @Input() disableSend: boolean = false

  public ctrlKey = navigator.userAgent.toLowerCase().includes('mac') ? '⌘' : 'Ctrl'
  public isSilentMode: boolean = false
  public showFormatting: boolean = true
  public isEmpty: boolean = true
  public disableImageUpload: boolean = false
  public popperOptions = (options: Partial<Options>) => options //{
    // const modifier = {
    //   name: 'offset',
    //   enabled: true,
    //   phase: main,
    //   options: {
    //     offset: [0, 20],
    //   },
    //   fn: (_ref) => {
    //     console.log(_ref)
    //     return [0, 20]
    //   }
    // }
  //   options.modifiers.unshift({
  //     name: 'offset',
  //     phase: main,
  //     options: {
  //       offset: ({ placement, reference, popper }) => {
  //         console.log({placement, reference, popper})
  //         return [0, 20]
  //       }
  //     },
  //     fn: (_ref) => {
  //       console.log(_ref)
  //       _ref.state.modifiersData.offset = {
  //         'top': {
  //           x: 200,
  //           y: 200,
  //         },
  //       }
  //     },
  //     enabled: true,
  //   })
  //   for (const modifier of options.modifiers || []) {
  //     // disable flip
  //     if (modifier.name === 'flip') {
  //       modifier.enabled = false
  //     }
  //   }
  //   options.strategy = 'fixed'
  //   console.log(options)
  //   return options
  // }
  public placement = 'top'
  public container = 'body'
  public openDelay = 250
  public tooltipClass = 'ql-button-tooltip'

  constructor() {}

  onButtonPressed(button: ComposerAction): void {
    if (button === 'toggle-silent-mode') {
      this.isSilentMode = !this.isSilentMode
    }
    if (button === 'toggle-format') {
      this.showFormatting = !this.showFormatting
    }
    if (button === 'upload-image') {
      this.disableImageUpload = true
      setTimeout(() => this.disableImageUpload = false, 1000)
    }
    this.onButtonPress.emit({ action: button })
  }

  onEmojiSelected(emoji: EmojiItem) {
    this.onButtonPress.emit({ action: 'insert-emoji', data: { emoji } })
  }

  public icon = {
    send: tolSend,
    format: tolFormat,
    deck: tolDeckStack,
    card: tolCards,
    at: tolAtSign,
    silent: faMask,
    tourney: faTrophy,
    emoji: tolEmoji,
    image: faImage,
    wink: faSmileWink,
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {

  showModalDialog = false;
  message = 'Message goes here...';
  title = 'Title goes here...';
  buttons = {
    OkOnly: false,
    OkCancel: false,
    YesNo: false,
    YesNoCancel: false,
    RetryCancel: false,
    ConfirmCancel: false,
    SendCancel: false,
  };
  buttonPressed = false;
  response: string;

  constructor() { }


  async openDialog(title: string, message: string, buttons: string) {
    this.buttons[buttons] = true;
    this.title = title;
    this.message = message;
    this.showModalDialog = true;

    return new Promise(async (resolve, reject) => {
      const loop = setInterval(() => {
        if (this.checkButtonPressed()) {
          clearInterval(loop);
          this.showModalDialog = false;
          this.buttonPressed = false;
          resolve({
            pressed: this.response
          });
        }
      }, 250);
    });
  }

  checkButtonPressed() {
    console.log('check button pressed', this.buttonPressed);
    if (this.buttonPressed) {
      return true;
    }
    else {
      return false;
    }
  }

  public get buttonCancelText() {
    if (this.buttons.OkOnly) { return ''; }
    else if (this.buttons.YesNo) { return 'No'; }
    else if (this.buttons.ConfirmCancel) { return 'Cancel'; }
    else if (this.buttons.OkCancel) { return 'Cancel'; }
    else if (this.buttons.RetryCancel) { return 'Cancel'; }
    else if (this.buttons.YesNoCancel) { return 'Cancel'; }
    else if (this.buttons.SendCancel) { return 'Cancel'; }
  }
  public get buttonConfirmText() {
    if (this.buttons.OkOnly) { return 'OK'; }
    else if (this.buttons.YesNo) { return 'Yes'; }
    else if (this.buttons.ConfirmCancel) { return 'Confirm'; }
    else if (this.buttons.OkCancel) { return 'Ok'; }
    else if (this.buttons.RetryCancel) { return 'Retry'; }
    else if (this.buttons.YesNoCancel) { return 'Yes'; }
    else if (this.buttons.SendCancel) { return 'Send'; }
  }

}

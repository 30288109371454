import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faEllipsisH, faExclamationTriangle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { AuthService, GlobalsService } from 'src/app/services';
import { MatchService } from 'src/app/services/match.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { IMatchAppointment } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-match-row',
  templateUrl: './match-row.component.html',
  styleUrls: ['./match-row.component.css']
})
export class MatchRowComponent implements OnInit, OnDestroy {
  @Input() matchDocument: IMatchData
  @Input() selectedRound: number
  @Input() showAppointments: boolean = false
  @Input() hideActionButton: boolean = false
  @ViewChild('matchDeletionConfirmation') matchDeletionConfirmation: ElementRef

  public icons = {
    warning: faExclamationTriangle,
    actions: faEllipsisH,
    calendar: faCalendar,
    show: faEye,
    hide: faEyeSlash,
  }
  public showCancelled: boolean = false

  public appointments$: BehaviorSubject<IMatchAppointment[]> = new BehaviorSubject<IMatchAppointment[]>(null)
  private destroyed$ = new Subject<boolean>()

  constructor(
    private globals: GlobalsService,
    private matchService: MatchService,
    private modalService: NgbModal,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    if (this.showAppointments) {
      this.matchService.getAppointmentsByMatchDocId(this.matchDocument.docId).pipe(takeUntil(this.destroyed$)).subscribe(i => this.appointments$.next(i))
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public get myPlayerId(): string {
    return this.auth.user.playerId
  }

  public get isMobile(): boolean {
    return this.globals.isMobile
  }

  public get apoointmentButtonClass(): string {
    return this.appointmentList.length > 0
      ? 'btn-outline-success'
      : 'btn-outline-secondary'
  }

  public get appointmentList(): IMatchAppointment[] {
    return this.appointments$.getValue() === null ? [] : this.appointments$.getValue()
  }

  public get appointmentTooltip(): string {
    const appointments = this.appointments$.getValue()
    if (appointments === null) { return 'No appointments found' }
    else {
      return `${appointments.length} appointments found`
    }
  }

  public showPlayerLink(playerUid: string): boolean {
    if (
      playerUid.substring(0, 6) === 'temp__' ||
      playerUid === '*** BYE ***' ||
      playerUid === '*** LOSS ***' ||
      playerUid === ''
    ) {
      return false
    }
    else {
      return true
    }
  }

  public openReportSlip(matchDoc: IMatchData, selectedRound: number) {
    if (matchDoc?.deleted && matchDoc.deleted) { return }
    if (this.isMobile) {
      this.matchService.openReportSlip(matchDoc, { selectedRound, setOpenedBy: true })
    }
    else {
      this.matchService.openReportSlip(matchDoc, { selectedRound })
    }
  }

  public openFeatureMatchConfig(matchDocId: string): void {
    this.matchService.openFeatureMatchConfig(matchDocId)
  }

  public toggleDeletionMark(match: IMatchData) {
    if (match.deleted) {
      this.matchService.removeMarkAsDeleted(match.docId)
    }
    else{
      this.matchService.markAsDeleted(match.docId)
    }
  }

  public deleteMatch(docId: string) {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'sm',
    }
    this.modalService.open(this.matchDeletionConfirmation, modalOptions).result.then((result) => {
      if (result) {
        this.matchService.deleteMatchDoc(docId)
      }
    })
  }

}

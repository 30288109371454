import { IEventDetails, IEventPlayerDetails, IEventStructureSettings, IMatchData, MatchType } from "tolaria-cloud-functions/src/_interfaces"


export const PlayerExtender = {
  
  __getEventTypeText: (eventStructure: IEventStructureSettings): MatchType => {
    if (eventStructure.isBatch) { return 'batch'; }
    if (eventStructure.isBracket) { return 'bracket'; }
    if (eventStructure.isGroup) { return 'group'; }
    if (eventStructure.isRoundRobin) { return 'round-robin'; }
    if (eventStructure.isSwiss) { return 'swiss'; }
  },

  /**
   * Extend a list of players with detailed stats according to the
   * results from the given match documents.
   * 
   * @param players IEventPlayerDetails[] - a list of all the event player documents
   * @param matches IMatchData[] - a list of all the match documents
   * @param event IEventDetails - the event document
   * @returns IEventPlayerDetails[] - a list of the players with extended properties
   */
  getList: (players: IEventPlayerDetails[], matches: IMatchData[], event: IEventDetails): IEventPlayerDetails[] => {
  
    let playersData: IEventPlayerDetails[] = []
  
    switch (PlayerExtender.__getEventTypeText(event.details.structure)) {
  
  
      case 'group':
      case 'batch':
      case 'round-robin':
      default:
  
        playersData = []
  
        for (const player of players) {
  
          // get matches as player1
          const stageMatches = matches.filter(i => i.isType !== 'bracket' && i.isReported && !i.deleted)
          const playoffMatches = [
            ...matches.filter(i => i.isType === 'bracket' && i.player1.playerDocId === player.playerDocId).map(i => i.player1),
            ...matches.filter(i => i.isType === 'bracket' && i.player2.playerDocId === player.playerDocId).map(i => i.player2),
          ]
          const matchesAsPlayer1 = stageMatches.filter(i => i.player1.playerDocId === player.playerDocId).map(i => {
            return {
              player: i.player1,
              match: i,
            }
          })
          const matchesAsPlayer2 = stageMatches.filter(i => i.player2.playerDocId === player.playerDocId).map(i => {
            return {
              player: i.player2,
              match: i,
            }
          })
          const playersMatchData = [...matchesAsPlayer1, ...matchesAsPlayer2]
  
          // create the extended team object
          const playerData = player as IEventPlayerDetails
          playerData.opponentsDocIds = [
            ...stageMatches.filter(i => i.player1.playerDocId === player.playerDocId).map(i => i.player2.playerDocId),
            ...stageMatches.filter(i => i.player2.playerDocId === player.playerDocId).map(i => i.player1.playerDocId)
          ]
          playerData.gamesWon = playersMatchData.map(i => i.player.wins).reduce((sum, val) => sum + val, 0)
          playerData.gamesLost = playersMatchData.map(i => i.player.losses).reduce((sum, val) => sum + val, 0)
          playerData.gamesDrawn = playersMatchData.map(i => i.player.draws).reduce((sum, val) => sum + val, 0)
          playerData.gamesPlayed = (playerData.gamesWon + playerData.gamesLost + playerData.gamesDrawn)
          playerData.gamePoints = (playerData.gamesWon * 3) + (playerData.gamesDrawn * 1)
          playerData.matchesWon = playersMatchData.filter(i => i.player.isWinner && !i.match.isDraw).length
          playerData.matchesLost = playersMatchData.filter(i => !i.player.isWinner && !i.match.isDraw).length
          playerData.matchesDrawn = playersMatchData.filter(i => i.match.isDraw).length
          playerData.matchesPlayed = playersMatchData.length
          playerData.matchPoints = (playerData.matchesWon * 3) + (playerData.matchesDrawn * 1)
          playerData.avoid = playerData.opponentsDocIds
          playerData.selected = false
          playerData.dropped = playersMatchData.filter(i => i.player.drop).length > 0 || player.dropped
          playerData.haveByeMatch = playersMatchData.filter(i => i.match.isByeMatch).length > 0
          playerData.playoffMatchesPlayed = playoffMatches.length
          playerData.playoffMatchesWon = playoffMatches.filter(i => i.isWinner).length
          playerData.playedInGroup = player.playedInGroup !== undefined && player.playedInGroup !== null && player.playedInGroup !== ''
            ? player.playedInGroup
            : ''
  
          playersData.push(playerData)
  
        }
  
        return playersData
  
    }
  
  
  }

}

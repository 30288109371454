import { BehaviorSubject } from 'rxjs';
import { IReleaseNumber, VersionService } from 'src/app/services/version.service'
import { Component } from '@angular/core'

@Component({
  selector: 'app-version-number',
  templateUrl: './version-number.component.html',
  styleUrls: ['./version-number.component.css']
})
export class VersionNumberComponent {

  versionText$: BehaviorSubject<IReleaseNumber>

  constructor(
    private version: VersionService
  ) {
    this.versionText$ = this.version.current
  }

}

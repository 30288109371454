import { WhoStartsModalComponent } from './who-starts-modal/who-starts-modal.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MediaStreamsService } from 'src/app/services/media-streams.service';
import { ToastService } from './../../../services/toast.service';
import { IICEServer } from './match-room.component';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { Peer, DataConnection, MediaConnection } from 'peerjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { v4 as uuidv4 } from 'node_modules/uuid';

export interface IWebRtcIncomingStream {
  call: MediaConnection;
  connection?: DataConnection;
  stream: MediaStream;
}

export interface IPlayerStream {
  // playerDocId: string;
  // playerUid: string;
  peerId: string;
  mediaStream: MediaStream;
}

export enum WebRtcCallerRole {
  PLAYER_1 = 'PLAYER_1',
  PLAYER_2 = 'PLAYER_2',
  ORGANIZER = 'ORGANIZER',
  SPECTATOR = 'SPECTATOR'
}
export enum WebRtcDataCommands {
  ROLLD6 = 'ROLLD6',
  ROLLD20 = 'ROLLD20',
  FLIPCOIN = 'FLIPCOIN',
  WHOSTART = 'WHOSTART',
  MYBACKGROUND = 'MYBACKGROUND'
}
export interface IWebRtcDataObject {
  command: WebRtcDataCommands;
  value: string;
}

export interface IIceServerDoc {
  docId: string;
  name: string;
  iceServer: IIceServer;
  active: boolean;
}
export interface IIceServer {
  credential: string;
  username: string;
  urls: string[];
}

@Injectable({
  providedIn: 'root'
})
export class WebRtcHelperService {

  private peer: Peer;
  public initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public dataConnectionEstablished$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public opponentBackgroundUrl: string = null
  private incomingResponderWebRtcStream$ = new Subject<IWebRtcIncomingStream>();
  private incomingCallerWebRtcStream$ = new Subject<IWebRtcIncomingStream>();

  private iceServersDoc$: Observable<IIceServerDoc[]> = this.afs.collection<IIceServerDoc>('iceServers', ref =>
    ref.where('active', '==', true)).valueChanges();
  private activeIceServers$: Observable<IIceServer[]> = this.iceServersDoc$.pipe(
    map((iceServerDocs) => {
      return iceServerDocs.map((iceServer: IIceServerDoc) => iceServer.iceServer);
    })
  );

  public player1Stream$: Observable<MediaStream> = merge(
    this.incomingResponderWebRtcStream$.pipe(
      filter((incoming: IWebRtcIncomingStream) => incoming.call.metadata.responderRole === WebRtcCallerRole.PLAYER_1),
      tap((incoming) => console.log('WebRTC:: Recieved ANSWER [STREAM] from Player 1', incoming)),
      map((incoming) => incoming.stream)
    ),
    this.incomingCallerWebRtcStream$.pipe(
      filter((incoming: IWebRtcIncomingStream) => incoming.call.metadata.callerRole === WebRtcCallerRole.PLAYER_1),
      tap((incoming) => console.log('WebRTC:: Recieved CALLER [STREAM] from Player 1', incoming)),
      map((incoming) => incoming.stream)
    ),
  );
  public player2Stream$: Observable<MediaStream> = merge(
    this.incomingResponderWebRtcStream$.pipe(
      filter((incoming: IWebRtcIncomingStream) => incoming.call.metadata.responderRole === WebRtcCallerRole.PLAYER_2),
      tap((incoming) => console.log('WebRTC:: Recieved ANSWER [STREAM] from Player 2', incoming)),
      map((incoming) => incoming.stream)
    ),
    this.incomingCallerWebRtcStream$.pipe(
      filter((incoming: IWebRtcIncomingStream) => incoming.call.metadata.callerRole === WebRtcCallerRole.PLAYER_2),
      tap((incoming) => console.log('WebRTC:: Recieved CALLER [STREAM] from Player 2', incoming)),
      map((incoming) => incoming.stream)
    ),
  );
  public organizerStream$: Observable<MediaStream> = merge(
    this.incomingResponderWebRtcStream$.pipe(
      filter((incoming: IWebRtcIncomingStream) => incoming.call.metadata.responderRole === WebRtcCallerRole.ORGANIZER),
      tap((incoming) => console.log('WebRTC:: Recieved ANSWER [STREAM] from ORGANIZER', incoming)),
      map((incoming) => incoming.stream)
    ),
    this.incomingCallerWebRtcStream$.pipe(
      filter((incoming: IWebRtcIncomingStream) => incoming.call.metadata.callerRole === WebRtcCallerRole.ORGANIZER),
      tap((incoming) => console.log('WebRTC:: Recieved CALLER [STREAM] from ORGANIZER', incoming)),
      map((incoming) => incoming.stream)
    ),
  );
  public playerCalls$: Observable<IPlayerStream> = merge(
    this.incomingResponderWebRtcStream$.pipe(
      tap((incoming) => console.log(`WebRTC:: Recieved ANSWER [STREAM] from ${incoming.call.metadata.responderPeerId}`, incoming)),
      map((incoming) => {
        return {
          peerId: incoming.call.metadata.responderPeerId,
          mediaStream: new MediaStream(incoming.stream)
        } as IPlayerStream;
      })
    ),
    this.incomingCallerWebRtcStream$.pipe(
      tap((incoming) => console.log(`WebRTC:: Recieved CALLER [STREAM] from ${incoming.call.metadata.callerPeerId}`, incoming)),
      map((incoming) => {
        return {
          peerId: incoming.call.metadata.callerPeerId,
          mediaStream: new MediaStream(incoming.stream)
        } as IPlayerStream;
      })
    ),
  );

  private localStream: MediaStream;
  private activeCalls = new Map<string, MediaConnection>();
  private activeConnection: DataConnection;

  constructor(
    private mediaService: MediaStreamsService,
    private afs: AngularFirestore,
    private toasteService: ToastService,
    private modalService: NgbModal
  ) {

    // Whenever we get some active ICE Servers, we create our Peer Object
    this.initPeer()

    // Subscribe to changes in local Media Stream
    this.mediaService.localStream$.subscribe((stream) => {
      this.localStream = stream;
      if (this.peer?.connections) {
        this.replaceMediaTracks(stream);
      }
    });
  }

  public get peerId() {
    return this.peer?.id;
  }

  public initPeer() {
    this.activeIceServers$.pipe(
      filter((iceServers) => iceServers?.length > 0),
      take(1)
    ).subscribe((iceServers) => {
      console.log('WebRTC:: Got a bunch of Ice Servers!');
      this.createPeer(iceServers);
    });
  }

  private createPeer = (iceServers: Array<IICEServer>) => {
    console.log('WebRTC:: Checking for existing Peer instance');
    if (this.peer) {
      console.log('WebRTC:: Peer already exist, Destroying...');
      this.destroyPeer();
      this.createPeer(iceServers);
    }
    else {
      console.log('WebRTC:: Creating PEER');
      const peerId = uuidv4();
      this.peer = new Peer(peerId, {
        host: 'tolaria-mtg.uc.r.appspot.com',
        secure: true,
        port: 443,
        config: {
          iceServers
        },
        debug: 0
      });
      this.setupPeerEventListeners();
    }
  }

  public callUserWithId = (
    responderPeerId: string,
    callerRole: WebRtcCallerRole,
    responderRole: WebRtcCallerRole
  ): MediaConnection => {
    console.log('WebRTC:: [CALLING...]', responderPeerId);
    const call = this.peer.call(
      responderPeerId,
      this.localStream,
      {
        metadata: {
          responderPeerId,
          responderRole,
          callerPeerId: this.peerId,
          callerRole
        }
      }
    );
    this.bindOutboundCallEvents(call);
    this.activeCalls.set(responderPeerId, call);

    // if this is player1 calling and the responder is player2, also setup data connection
    if (callerRole === WebRtcCallerRole.PLAYER_1 && responderRole === WebRtcCallerRole.PLAYER_2) {
      this.connectToUserWithId(responderPeerId);
    }

    return call;
  }

  public sendData = (data: IWebRtcDataObject) => {
    if (data.command === WebRtcDataCommands.WHOSTART) {
      console.log('WebRTC:: sendData: ',JSON.parse(data.value));
      setTimeout(() => {
        this.showWhoStarts(data);
      }, 1000);
      this.activeConnection.send(data);
    }
    else {
      this.showDataToast(data);
      this.activeConnection.send(data);
    }
  }

  public connectToUserWithId = (responderPeerId: string): DataConnection => {
    console.log('WebRTC:: [CONNECTING...]', responderPeerId);
    const connection = this.peer.connect(responderPeerId);
    this.bindConnectionEvents(connection);
    return connection;
  }

  private setupPeerEventListeners() {
    this.peer.on('open', (id: string) => {
      console.log('WebRTC:: Peer [OPENED] with id: ', id);
      this.initialized$.next(true);
    });
    this.peer.on('call', (call) => {
      console.log(`WebRTC:: [INCOMING CALL]... from ${call.metadata.callerRole}`);
      call.answer(this.localStream);
      this.bindIncomingCallEvents(call);

      this.activeCalls.set(call.metadata.callerPeerId, call);
    });
    this.peer.on('error', (err) => {
      console.error('WebRTC:: Peer [ERROR]: ', err);
    });
    this.peer.on('connection', (connection: DataConnection) => {
      console.log(`WebRTC:: [INCOMING CONNECTION]... from ${connection.peer}`);
      this.bindConnectionEvents(connection);
    });
  }

  private bindOutboundCallEvents = (call: MediaConnection) => {
    console.log('WebRTC:: BINDING CALL EVENTS FOR OUTBOUND');
    call.on('stream', (stream: MediaStream) => {
      console.log('WebRTC:: Got STREAM AFTER CALLING', JSON.stringify(stream), call);
      this.incomingResponderWebRtcStream$.next({ call, stream });
    });
    call.on('close', () => console.log(`WebRTC:: Call-[${call.peer}] CLOSED`));
    call.on('error', (err) => console.error(`WebRTC:: Call-[${call.peer}] ERROR:`, err));
  }

  private bindIncomingCallEvents = (call: MediaConnection) => {
    call.on('stream', (stream: MediaStream) => {
      console.log('WebRTC:: Got STREAM AFTER RESPONDING to call:', call);
      this.incomingCallerWebRtcStream$.next({ call, stream });
    });
    call.on('close', () => console.log(`WebRTC:: Call-[${call.peer}] CLOSED`));
    call.on('error', (err) => console.error(`WebRTC:: Call-[${call.peer}] ERROR:`, err));
  }

  private bindConnectionEvents = (connection: DataConnection) => {
    connection.on('open', () => {
      this.activeConnection = connection;
      console.log(`WebRTC:: Connection-[${connection.peer}] OPENED`);
      this.dataConnectionEstablished$.next(true);
    });
    connection.on('data', (data: IWebRtcDataObject) => {
      console.log(`WebRTC:: [CONNECTION] Received data from peer ${connection.peer}:`, data);
      switch (data.command) {

        case WebRtcDataCommands.WHOSTART:
          console.log('WebRTC:: who starts: ',JSON.parse(data.value))
          this.showWhoStarts(data)
          break

        case WebRtcDataCommands.MYBACKGROUND:
          console.log('WebRTC:: matchRoomBackgroundUrl url:', data.value)
          this.opponentBackgroundUrl = data.value
          break

        default:
          this.showDataToast(data)
      }
    });
    connection.on('close', () => {
      console.log(`WebRTC:: Connection-[${connection.peer}] CLOSED`);
      this.dataConnectionEstablished$.next(false);
    });
    connection.on('error', (err) => console.error(`WebRTC:: Connection-[${connection.peer}] ERROR:`, err));
  }

  private showWhoStarts(data: IWebRtcDataObject) {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
      windowClass: 'who-starts-match'
    };
    const modalRef: NgbModalRef = this.modalService.open(WhoStartsModalComponent, modalOptions);
    modalRef.componentInstance.data = data;
  }

  private showDataToast(data: IWebRtcDataObject) {
    switch (data.command) {
      case WebRtcDataCommands.FLIPCOIN:
        console.log('WebRTC:: Flip a god damn coin!', data.value);
        this.toasteService.show(data.value, { classname: 'standard-toast webrtc-data-coin-flip', delay: 10000 });
        break;
      case WebRtcDataCommands.ROLLD6:
        console.log('WebRTC:: Roll a god damn D6', data.value);
        this.toasteService.show(data.value, { classname: 'standard-toast webrtc-data-dice-roll', delay: 10000 });
        break;
      case WebRtcDataCommands.ROLLD20:
        console.log('WebRTC:: Roll a god damn D20', data.value);
        this.toasteService.show(data.value, { classname: 'standard-toast webrtc-data-dice-roll', delay: 10000 });
        break;
    }
  }

  public replaceMediaTracks = (stream: MediaStream) => {
    Object.keys(this.peer.connections).forEach((connection) => {
      const peerCon = this.peer.connections[connection];
      console.log('WebRTC:: replaceMediaTracks > peer connection: ', peerCon);
      peerCon.forEach(conn => {
        console.log('WebRTC:: [REPLACING TRACKS] on call with player: ', conn.peer);
        conn.peerConnection.getSenders().forEach((sender) => {
          if (sender?.track?.kind === 'audio') {
            sender.replaceTrack(stream.getAudioTracks()[0]);
          }
          if (sender?.track?.kind === 'video') {
            sender.replaceTrack(stream.getVideoTracks()[0]);
          }
        });
      });
    });
  }

  private destroyPeer = () => {
    this.peer.destroy();
    for (const [key] of Object.entries(this.activeCalls)) {
      this.hangupOnCall(key);
    }
    this.peer = undefined;
  }

  public hangupOnCall(webRtcPeerId: string) {
    console.log('WebRTC:: Hanging UP on:', webRtcPeerId);
    const call = this.activeCalls.get(webRtcPeerId);
    console.log(this.activeCalls);
    if (call) {
      console.log('WebRTC:: closing call!');
      this.activeCalls.delete(webRtcPeerId);
      call.close();
    }
  }

  public destroy() {
    if (this.peer) {
      this.destroyPeer();
    }
  }

}

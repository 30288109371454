import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tolaria-wysiwyg-insert-link',
  templateUrl: './tolaria-wysiwyg-insert-link.component.html',
  styleUrls: ['./tolaria-wysiwyg-insert-link.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ]
})
export class TolariaWysiwygInsertLinkComponent implements OnInit, AfterViewInit {
  @Input() edit: boolean = false
  @Input() set url(val: string)  { this.urlString  = val }
  @Input() set text(val: string) { this.textString = val }
  @Input() retain: boolean

  @ViewChild('linkInput', { static: true }) linkInputElement: ElementRef

  public disableForm = true
  public urlString = ''
  public textString = ''

  constructor(
    private readonly modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => this.linkInputElement.nativeElement.focus(), 100)    
    this.validateForm()
  }

  onCancelPress(): void {
    this.modal.dismiss()
  }

  onInsertPress(event?: any): void {
    if (event) {
      event.preventDefault()
    }
    if (this.disableForm) {
      return
    }
    this.modal.close({
      url: this.urlString,
      text: this.textString,
      retain: this.retain,
      remove: false,
    })
  }

  onRemovePress(): void {
    this.modal.close({
      url: this.urlString,
      text: this.textString,
      retain: this.retain,
      remove: true,
    })
  }

  validateForm(event?: any): void {
    this.disableForm = this.urlString === '' || this.textString == ''
  }

}

<div class="modal-header d-flex align-items-center">

  <div class="modal-title flex-grow-1 h5" id="modal-basic-title">Drop Player?</div>

  <div class="btn-close text-secondary" aria-label="Close" (click)="modal.dismiss('cancel')">
    <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
  </div>

</div>


<div class="modal-body scrollable">

  <ng-container [ngSwitch]="eventType">


    <ng-container *ngSwitchCase="'group'">

      <div class="">You are about to drop a <span class="text-bold">{{ player.name }}</span> from this ongoing Group event, dropping can't be <b>UNDONE!</b></div>
      <div class="mb-3">Please choose how you would like to resolve this drop.</div>

      <form>
        <div class="row">
          <div class="col-4 text-large font-weight-500 mb-2">
            <div class="custom-control custom-radio">
              <input type="radio" id="radioDropJustDrop" name="radioDropType" class="form-check-input"
                [(ngModel)]="exchangePlayer" [value]="false">
              <label class="form-check-label" for="radioDropJustDrop">Just drop</label>
            </div>
          </div>
          <div class="col-8 text-secondary">Dropping the player will mark the player as dropped and all matches will be
            inacivated (inactive matches are ignored when calculating standings).</div>
        </div>

        <div class="row">
          <div class="col-4 text-large font-weight-500 mb-2">
            <div class="custom-control custom-radio">
              <input type="radio" id="radioDropExchange" name="radioDropType" class="form-check-input"
                [(ngModel)]="exchangePlayer" [value]="true">
              <label class="form-check-label" for="radioDropExchange">Exchange</label>
            </div>
          </div>
          <div class="col-8 text-secondary">
            Exchanging player will change all the matches and the player being dropped will be replaced with the player
            being added.
          </div>
        </div>
      </form>

      <div class="d-flex flex-column mt-3" *ngIf="exchangePlayer">

        <ng-container *ngIf="isOnlineTournament || tolariaSearch; else nonOnlineInput">
          <app-tolaria-player-search class="mb-2" (playerDocId)="setExchangePlayer($event)" (toggleBack)="tolariaSearch = $event"></app-tolaria-player-search>
        </ng-container>
        <ng-template #nonOnlineInput>
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend" (click)="tolariaSearch = !tolariaSearch">
              <button class="btn btn-sm btn-outline-secondary">
                <fa-icon [fixedWidth]="true" [icon]="appIcon"></fa-icon>
              </button>
            </div>

            <input type="text" class="form-control" placeholder="Enter a player name" [(ngModel)]="playerDocIdBeingAdded">
          </div>
        </ng-template>


        <div class="d-flex flex-row align-items-center mt-3">

          <ng-container *ngIf="isOnlineTournament; else nonOnlineEvent">

            <app-player-avatar [playerDocId]="player.playerDocId"></app-player-avatar>

            <div class="mx-3 text-secondary">
              <fa-icon [fixedWidth]="true" [icon]="faChevronRight"></fa-icon>
            </div>

            <app-player-avatar [playerDocId]="playerDocIdBeingAdded"
              *ngIf="playerDocIdBeingAdded !== ''; else tempAvatar"></app-player-avatar>
            <ng-template #tempAvatar>
              <div class="temp-avatar">
                <fa-icon [fixedWidth]="true" [icon]="faQuestion"></fa-icon>
              </div>
            </ng-template>

          </ng-container>

          <ng-template #nonOnlineEvent>

            <div class="">{{ player.name }}</div>

            <div class="mx-3 text-secondary">
              <fa-icon [fixedWidth]="true" [icon]="faChevronRight"></fa-icon>
            </div>

            <app-player-display-name [playerDocId]="playerDocIdBeingAdded"
              *ngIf="tolariaSearch; else tempPlayerBeingAdded"></app-player-display-name>
            <ng-template #tempPlayerBeingAdded>
              <div class="" *ngIf="playerDocIdBeingAdded !== ''; else tempName">{{ playerDocIdBeingAdded }}</div>
              <ng-template #tempName>
                <div class="temp-name text-secondary">Enter a player name</div>
              </ng-template>
            </ng-template>

          </ng-template>

        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      You are about to drop <span class="bold">{{ player.name }}</span>, are you sure?
    </ng-container>

  </ng-container>

</div>


<div class="modal-footer">

  <button class="btn btn-sm text-red" (click)="modal.dismiss('cancel')">Cancel</button>

  <button class="btn btn-sm btn-blank" (click)="close()" [disabled]="exchangePlayer && playerDocIdBeingAdded.length < 5">OK</button>
</div>

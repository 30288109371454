import { Router } from '@angular/router';
import { AppComponent } from './../app.component'
import { ModalReportSlipComponent } from './../components/events/match-room/modal-report-slip/modal-report-slip.component'
import { GlobalsService } from './globals.service'
import { MessagesService } from 'src/app/services'
import { EventToFeatureMatchConfigComponent } from './../components/events/event-lobby/organizer/event-to-feature-match-config/event-to-feature-match-config.component'
import { EventReportSlipComponent } from './../components/events/event-lobby/organizer/event-report-slip/event-report-slip.component'
import { ToastService } from './toast.service'
import { AuthService } from './auth.service'
import { IEventDetails, IFeatureMatchUris, IMatchAppointment, IMatchAvailabilityMeta, IMatchData, IMatchPlayer, MatchType } from 'tolaria-cloud-functions/src/_interfaces'
import { WebRtcCallerRole } from './../components/events/match-room/webrtc-helper.service'
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces'
import { distinctUntilChanged, map, shareReplay, take } from 'rxjs/operators'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Injectable } from '@angular/core'
import { v4 as uuidv4 } from 'node_modules/uuid'
import * as firestore from 'firebase/firestore'
import { firstValueFrom, Observable } from 'rxjs'
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { nanoid } from 'nanoid'
import { EventService } from './event/event.service'
import { PlayerNameService } from './players/player-name.service';
import { MatchRoomOpenService } from '../private/matches/services/match-room-open.service';

export interface IPlayerLife {
  current: number
  history: Array<number | null>
  gameWins: number
}
export interface IMatchNotes {
  playerIs: string
  notes: Array<string>
}
export class MatchMeta {
  eventDocId: string
  matchDocId: string
  player1DocId: string
  player2DocId: string
  createdByUid: string
  isPlayer: boolean
  playerIs: string

  constructor(
    eventDocId: string,
    matchDocId: string,
    player1DocId: string,
    player2DocId: string,
    createdByUid: string,
    playerDocId: string,
  ) {
    this.eventDocId = eventDocId
    this.matchDocId = matchDocId
    this.player1DocId = player1DocId
    this.player2DocId = player2DocId
    this.createdByUid = createdByUid
    this.isPlayer = playerDocId === player1DocId || playerDocId === player2DocId
    if (playerDocId === player1DocId) {
      this.playerIs = 'player1'
    }
    if (playerDocId === player2DocId) {
      this.playerIs = 'player2'
    }
  }

}

@Injectable({
  providedIn: 'root'
})
export class MatchService {

  private matchesCache$: Observable<IMatchData[]>
  public matches$: Observable<IMatchData[]>
  public matchAppointments$: Observable<IMatchAppointment[]>
  public allMatches$: Observable<IMatchData[]>

  constructor(
    private afs: AngularFirestore,
    private readonly playerNames: PlayerNameService,
    private auth: AuthService,
    private toastService: ToastService,
    private es: EventService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    private globals: GlobalsService,
    private app: AppComponent,
    private router: Router,
    private readonly matchRoom: MatchRoomOpenService,
  ) {

    this.matchesCache$ = this.afs.collection<IMatchData>('matches', ref => ref
      .where('playerDocIds', 'array-contains', this.auth.user.playerId)
      // .where('deleted', '==', false)
      .orderBy('eventDocId', 'asc')
      .orderBy('roundNumber', 'asc')
    ).valueChanges()

    this.matches$ = this.matchesCache$.pipe(shareReplay(1))

    this.matchAppointments$ = this.afs.collection<IMatchAppointment>('matchAppointments', ref => ref
      .where('playerDocIds', 'array-contains', this.auth.user.playerId)
      .orderBy('timestampCreated', 'desc')
    ).valueChanges()

    this.allMatches$ = this.afs.collection<IMatchData>('matches').valueChanges()

    // this.matchUpdater()
  }

  /**
   * Check if the player has availablity during a specific event
   *
   * @param playerDocId the players document id
   * @param eventDocId the events document id
   */
  public async playerAvailableDuringEvent(playerDocId: string, eventDocId: string, eventDoc: IEventDetails = null): Promise<boolean> {

    if (eventDoc === null) {
      const eventSnap = await firstValueFrom(this.afs.collection('events').doc<IEventDetails>(eventDocId).get())
      if (!eventSnap.exists) {
        // console.log('[matchService > playerAvailableDuringEvent]:: could not find the event')
        return false
      }

      eventDoc = eventSnap.data()
    }

    // console.log(`[matchService > playerAvailableDuringEvent]:: checking avaiability for ${playerDocId}`)
    const availabilitySnap = await firstValueFrom(this.afs.collection<IMatchAvailabilityMeta>('matchAvailability', ref => ref
      .where('playerDocId', '==', playerDocId)
    ).get())

    if (availabilitySnap.empty) {
      // console.log('[matchService > playerAvailableDuringEvent]:: could not find any availability')
      return false
    }
    else {
      // console.log(`[matchService > playerAvailableDuringEvent]:: found a total of ${availabilitySnap.docs.length} availability slots`)
      const slotsWithinEvent = availabilitySnap.docs.map(i => i.data()).filter(i => i.timestampFrom >= (eventDoc.details.datestampFrom / 1000) && i.timestampTo <= (eventDoc.details.datestampTo / 1000))
      // console.log(`[matchService > playerAvailableDuringEvent]:: ${slotsWithinEvent.length} within the event period`)
      return slotsWithinEvent.length > 0
    }



  }

  public async matchUpdater() {
    const matches = await this.afs.collection<IMatchData>('matches').get().toPromise()
    const batch = this.afs.firestore.batch()
    let counter = 0
    console.log(`${matches.docs.filter(d => d.data().deleted === undefined).length} document missing deleted flag`)
    for await (const doc of matches.docs) {
      if (!doc.exists) { continue }
      const data = doc.data() as IMatchData
      if (data.deleted === undefined) {
        counter++
        batch.update(doc.ref, {
          deleted: false
        })
      }
      if (counter === 500) {
        break
      }
    }

    if (counter > 0) {
      batch.commit()
        .then(() => console.log(`${counter} documents updated`))
        .catch((error) => console.log(error))
    }
  }

  createNewCasualMatch(player1DocId: string, player2DocId: string) {
    return new Promise((resolve, reject) => {
      this.newMatchDoc(player1DocId, player2DocId).then((res: IPromiseResponse) => {
        const matchDoc: IMatchData = res.data
        console.log(matchDoc)
        this.afs
          .collection('matches')
          .doc(matchDoc.docId)
          .set(matchDoc)
          .then(() => {
            resolve({
              status: true,
              text: 'Match successfully created',
              data: matchDoc
            })
          })
          .catch((error) => {
            reject({
              status: false,
              text: error
            })
          })
      })
    })
  }
  newMatchDoc(player1DocId: string, player2DocId: string) {
    return new Promise(async (resolve, reject) => {

      // make sure the player name service has initialized
      if (this.playerNames.serviceReadyCheck$.getValue() === false) {
        reject({
          status: false,
          text: 'Player name service not ready, please try again later...'
        })
      }

      // create the match doc
      const player1 = this.playerNames.getPlayerById(player1DocId)
      const player2 = this.playerNames.getPlayerById(player2DocId)
      const timestampCreated = firestore.Timestamp.now().seconds
      const matchDoc: IMatchData = {
        docId: uuidv4(),
        deleted: false,
        eventDocId: 'casual-match',
        createdByUid: this.auth.user.uid,
        feedsMatchDocId: 'none',
        feedsMatchPlayer: 'none',
        winnerGoToMatchDoc: 'none',
        winnerGoToPlayerSlot: 'none',
        loserGoToMatchDoc: 'none',
        loserGoToPlayerSlot: 'none',
        isDraw: false,
        isReported: false,
        isByeMatch: false,
        isLossMatch: false,
        isType: 'casual-match',
        groupName: '',
        player1: {
          playerDocId: player1.id,
          playerUid: player1.uid,
          displayName: player1.name.display,
          isWinner: false,
          wins: 0,
          draws: 0,
          losses: 0,
          drop: false,
          matchPoints: 0,
          lifePoints: [20]
        },
        player2: {
          playerDocId: player2.id,
          playerUid: player2.uid,
          displayName: player2.name.display,
          isWinner: false,
          wins: 0,
          draws: 0,
          losses: 0,
          drop: false,
          matchPoints: 0,
          lifePoints: [20]
        },
        playerFilterValue: 'casual-match',
        roundNumber: 0,
        segmentNumber: 0,
        segmentType: 'no-segment',
        tableNumber: 0,
        playerDocIds: [
          player1.id,
          player2.id
        ],
        timestampCreated,
        timestampReported: null,
        reportSlipOpenedBy: null,
      }
      resolve({
        status: true,
        text: 'match generated successfully',
        data: matchDoc as IMatchData
      })
    })
  }
  getMatchDocById(matchDocId: string): Observable<IMatchData> {
    return this.afs.collection<IMatchData>('matches').doc(matchDocId).valueChanges()
  }
  getMatchesForEventByEventId(eventDocId: string): Observable<IMatchData[]> {
    const matchesColRef = this.afs.collection<IMatchData>('matches', ref => ref
      .where('eventDocId', '==', eventDocId))
    return matchesColRef.valueChanges()
  }
  async getMatchesForEventByEventIdData(eventDocId: string): Promise<IMatchData[]> {
    const snap = await firstValueFrom(this.afs.collection<IMatchData>('matches', ref => ref.where('eventDocId', '==', eventDocId)).get())
    if (snap.empty) {
      return []
    }
    else {
      return snap.docs.map(i => i.data())
    }
  }
  getMatchesForPlayerByDocId(playerDocId: string): Observable<IMatchData[]> {
    const matchesColRef = this.afs.collection<IMatchData>('matches', ref => ref
      .where('isReported', '==', true)
      .where('playerDocIds', 'array-contains', playerDocId)
    )
    return matchesColRef.valueChanges()
  }
  getAllMatchesForPlayerByDocId(playerDocId: string): Observable<IMatchData[]> {
    const matchesColRef = this.afs.collection<IMatchData>('matches', ref => ref
      .where('playerDocIds', 'array-contains', playerDocId)
      .orderBy('eventDocId', 'asc')
      .orderBy('roundNumber', 'asc')
    )
    return matchesColRef.valueChanges()
  }
  getMatchDocObserver(matchDocId: string): Observable<IMatchData> {
    return this.afs.collection('matches').doc<IMatchData>(matchDocId).valueChanges()
  }
  getMatchDoc(matchDocId: string): Promise<IMatchData> {
    const matchDocRef = this.afs.collection('matches').doc<IMatchData>(matchDocId)
    return matchDocRef.get()
      .pipe(
        take(1),
        map(doc => {
          if (doc.exists) {
            return doc.data()
          }
        })
      ).toPromise()
  }
  async updateGameWins(matchDocId: string, playerIs: string, add: boolean) {
    const opponentIs: string = playerIs === 'player1' ? 'player2' : 'player1'
    console.log('getting match document')
    // match document reference
    const matchDocRef = this.afs.collection('matches').doc<IMatchData>(matchDocId)
    // get the match document
    const matchDoc = await this.getMatchDoc(matchDocId).then(matchDocument => {
      return matchDocument
    })

    let doUpdate = false

    // perform update on game wins if possible
    switch (add) {
      case true:
        console.log('add game win?')
        if (matchDoc[playerIs].wins < 2) {
          console.log(true)
          matchDoc[playerIs].wins++
          matchDoc[opponentIs].losses++
          doUpdate = true
        }
        else {
          console.log(false)
        }
        break
      case false:
        console.log('remove game win?')
        if (matchDoc[playerIs].wins > 0) {
          console.log(true)
          matchDoc[playerIs].wins--
          matchDoc[opponentIs].losses--
          doUpdate = true
        }
        else {
          console.log(false)
        }
        break

    }

    // check if update is about to happen
    if (doUpdate) {
      console.log('update about to be performed')
      // clear all winners
      matchDoc.isDraw = false
      matchDoc.player1.isWinner = false
      matchDoc.player2.isWinner = false
      // set winner if either has 2 wins
      if (matchDoc.player1.wins === 2) {
        console.log('player 1 is the winner, toggling winner state')
        matchDoc.player1.isWinner = true
        matchDoc.player2.isWinner = false
      }
      if (matchDoc.player2.wins === 2) {
        console.log('player 2 is the winner, toggling winner state')
        matchDoc.player1.isWinner = false
        matchDoc.player2.isWinner = true
      }
      console.log('performing update')
      matchDocRef.set(matchDoc, { merge: true })
        .then(() => console.log('done!'))
        .catch((err) => console.log(err))
    }
    else {
      console.log('no update to perform!')
    }
  }
  async updateLife(matchDocId: string, playerIs: string, change: number, reset: boolean = false) {

    console.log(`[MatchService] --> About to update life point with change ${change} for ${playerIs} on match ${matchDocId}`)
    // match document reference
    const playersInfoRef = this.afs.collection('matches').doc(matchDocId).collection('match-room').doc<any>('players-info')

    // get the match-room > players-info document
    let docSnap = await firstValueFrom(playersInfoRef.get())

    let doc = {
      player1: {
        lifePoints: [20],
        handSize: 7,
      },
      player2: {
        lifePoints: [20],
        handSize: 7,
      }
    }

    // check if exist, else create it
    if (!docSnap.exists) {

      await playersInfoRef.set(doc)
    }
    else {
      doc = docSnap.data()
    }

    const lifePoints: Array<number | null> = typeof doc[playerIs].lifePoints === 'object' ? doc[playerIs].lifePoints : []

    // reset life to 20
    if (reset) {
      console.log('resetting life points')
      lifePoints.unshift(change, null)
      // add new starting life
      playersInfoRef.update({
        [`${playerIs}.lifePoints`]: lifePoints
      })
        .then(() => console.log('added new starting life'))
        .catch((err) => console.log(err))
    }
    else {
      const currentLife = doc[playerIs].lifePoints[0]
      const newLife = currentLife + change
      lifePoints.unshift(newLife)
      playersInfoRef.update({
        [`${playerIs}.lifePoints`]: lifePoints
      })
        .then(() => console.log('updated life points to: ', newLife))
        .catch((err) => console.log(err))
    }
  }
  async updateHandSize(matchDocId: string, playerIs: string, size: number) {
    // match document reference
    const playersInfoRef = this.afs.collection('matches').doc(matchDocId).collection('match-room').doc('players-info')

    // get the match-room > players-info document
    const docSnap = await firstValueFrom(playersInfoRef.get())

    // check if exist, else create it
    if (docSnap.exists) {
      console.log(`[MatchService] --> About to update hand size to ${size} for ${playerIs} on match ${matchDocId}`)
      this.afs.collection('matches').doc(matchDocId)
        .collection('match-room')
        .doc('players-info')
        .update({
          [`${playerIs}.handSize`]: size
        })
        .then(() => this.toastService.show('Hand size updated', { classname: 'success-toast', delay: 1000 }))
        .catch((err) => console.log(err))
    }
    else {
      console.log(`[MatchService] --> About to update hand size to ${size} for ${playerIs} on match ${matchDocId}`)
      const doc = {
        player1: {
          lifePoints: [20],
          handSize: 7,
        },
        player2: {
          lifePoints: [20],
          handSize: 7,
        }
      }
      doc[playerIs].handSize = size
      this.afs.collection('matches').doc(matchDocId)
        .collection('match-room')
        .doc('players-info')
        .set(doc)
        .then(() => this.toastService.show('Hand size updated', { classname: 'success-toast', delay: 1000 }))
        .catch((err) => console.log(err))
    }

  }
  addMatchNote(matchDocId: string, playerIs: string, note: string): Promise<IPromiseResponse> {
    return new Promise((resolve, reject) => {
      const matchDocRef = this.afs.collection('matches').doc<IMatchData>(matchDocId)
      matchDocRef.update({
        [`${playerIs}.notes`]: firestore.arrayUnion(note)
      })
        .then(() => {
          console.log('note added')
          this.toastService.show('Note added', { classname: 'success-toast', delay: 1500 })
          resolve({
            status: true,
            text: 'note added'
          })
        })
        .catch((err) => {
          console.log(err)
          this.toastService.show('Something went wrong, please try again', { classname: 'error-toast', delay: 5000 })
          resolve({
            status: false,
            text: err
          })
        })
    })
  }
  deleteMatchNote(matchDocId: string, playerIs: string, note: string) {
    const matchDocRef = this.afs.collection('matches').doc<IMatchData>(matchDocId)
    matchDocRef.update({
      [`${playerIs}.notes`]: firestore.arrayRemove(note)
    })
      .then(() => {
        console.log('note deleted')
        this.toastService.show('Note deleted', { classname: 'success-toast', delay: 1500 })
      })
      .catch((err) => {
        console.log(err)
        this.toastService.show('Something went wrong, please try again', { classname: 'error-toast', delay: 5000 })
      })
  }
  getPlayerLifeData(matchDocId: string, playerIs: string): Observable<IPlayerLife> {
    const matchDocRef$ = this.afs.collection('matches').doc<IMatchData>(matchDocId).valueChanges()
    return matchDocRef$.pipe(
      distinctUntilChanged((prev: IMatchData, curr: IMatchData) => {
        return curr[playerIs].lifePoints.length === prev[playerIs].lifePoints.length && curr[playerIs].wins === prev[playerIs].wins
      }),
      map(matchDoc => {
        const player: IMatchPlayer = matchDoc[playerIs]
        return {
          current: player.lifePoints[0],
          history: player.lifePoints,
          gameWins: player.wins
        } as IPlayerLife
      })
    )
  }
  getMatchNotes(matchDocId: string, playerIs: string): Observable<IMatchNotes> {
    const matchDocRef$ = this.afs.collection('matches').doc<IMatchData>(matchDocId).valueChanges()
    console.log('returning observable')
    return matchDocRef$.pipe(
      distinctUntilChanged((prev: IMatchData, curr: IMatchData) => {
        return curr[playerIs].notes.length === prev[playerIs].notes.length
      }),
      map(matchDoc => {
        const player: IMatchPlayer = matchDoc[playerIs]
        if (player.notes) {
          return {
            notes: player.notes,
            playerIs
          } as IMatchNotes
        }
        else {
          return {
            notes: [],
            playerIs
          } as IMatchNotes
        }
      })
    )
  }
  public async getPlayerIs(matchDocId: string): Promise<string> {
    console.log('getPlayerIs | fetching match document')
    const matchDoc = await this.getMatchDoc(matchDocId)
    if (this.auth.user.playerId === matchDoc.player1.playerDocId) {
      console.log('getPlayerIs | player is: player1')
      return 'player1'
    }
    if (this.auth.user.playerId === matchDoc.player2.playerDocId) {
      console.log('getPlayerIs | player is: player2')
      return 'player2'
    }
    console.log('getPlayerIs | player is: ', undefined)
    return undefined
  }
  getPlayerWebRtcRoleForMatchWithId(matchDocId: string): Promise<WebRtcCallerRole> {
    return new Promise((resolve, reject) => {
      this.afs.collection('matches').doc<IMatchData>(matchDocId)
        .get()
        .pipe(take(1))
        .subscribe((doc) => {
          // check if match exists
          if (doc.exists) {
            // check if player is PLAYER_1
            if (doc.data().player1.playerDocId === this.auth.user.playerId) { resolve(WebRtcCallerRole.PLAYER_1) }
            // check if player is PLAYER_2
            else if (doc.data().player2.playerDocId === this.auth.user.playerId) { resolve(WebRtcCallerRole.PLAYER_2) }
            // check if player is SPECTATOR or ORGANIZER
            else {
              this.es.getOrganizerIdsForEventWithId(doc.data().eventDocId)
                .then((ids) => {
                  if (ids.includes(this.auth.user.playerId) || ids.includes(this.auth.user.uid)) {
                    resolve(WebRtcCallerRole.ORGANIZER)
                  }
                  else {
                    resolve(WebRtcCallerRole.SPECTATOR)
                  }
                })
            }
          }
          else {
            resolve(undefined)
          }
        })
    })
  }
  getPlayerOrganizerStatusForMatchWithId(matchDocId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afs.collection('matches').doc<IMatchData>(matchDocId)
        .get()
        .pipe(take(1)
        )
        .subscribe((doc) => {
          // check if match exists
          if (doc.exists) {
            this.es.getOrganizerIdsForEventWithId(doc.data().eventDocId).then((ids) => {
              resolve(ids.includes(this.auth.user.uid))
            })
          }
          // return false as match does not exist
          else {
            resolve(false)
          }
        })
    })
  }
  getPlayerUidByDocId(matchDocId: string, playerDocId: string): Observable<string> {
    return this.afs.collection('matches').doc<IMatchData>(matchDocId)
      .valueChanges()
      .pipe(
        map((doc) => {
          if (doc.player1.playerDocId === playerDocId) {
            return doc.player1.playerUid
          }
          if (doc.player2.playerDocId === playerDocId) {
            return doc.player2.playerUid
          }
        }),
        distinctUntilChanged()
      )
  }
  getPlayerNameByDocId(matchDocId: string, playerDocId: string): Observable<string> {
    return this.afs.collection('matches').doc<IMatchData>(matchDocId)
      .valueChanges()
      .pipe(
        map((doc) => {
          if (doc.player1.playerDocId === playerDocId) {
            return doc.player1.displayName
          }
          if (doc.player2.playerDocId === playerDocId) {
            return doc.player2.displayName
          }
        }),
        distinctUntilChanged()
      )
  }
  getPlayerNameByPosition(matchDocId: string, player: string): Observable<string> {
    return this.afs.collection('matches').doc<IMatchData>(matchDocId)
      .valueChanges()
      .pipe(
        map((doc) => {
          return doc[player].displayName
        }),
        distinctUntilChanged()
      )
  }
  getPlayersMatchesInEvent(playerDocId: string, eventDocId: string): Observable<IMatchData[]> {
    return this.afs.collection<IMatchData>('matches', ref => ref
      .where('eventDocId', '==', eventDocId)
      .where('playerDocIds', 'array-contains', playerDocId)
    ).valueChanges()
  }
  openFeatureMatchConfig(matchDocId: string): void {
    // create the modal for the report slip
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      fullscreen: true,
      windowClass: 'modal-report-slip'
    }
    // open the report slip
    const modalRef: NgbModalRef = this.modalService.open(EventToFeatureMatchConfigComponent, modalOptions)
    modalRef.componentInstance.matchDoc$ = this.afs.collection('matches').doc<IMatchData>(matchDocId).valueChanges()
    modalRef.componentInstance.matchDocId = matchDocId
  }
  openReportSlip(matchDoc: IMatchData, options: {
    selectedRound?: number,
    setOpenedBy?: boolean,
  }): void {

    // init default options
    if (!options || options && !options.selectedRound) {
      options.selectedRound = null
    }
    if (!options || options && !options.setOpenedBy) {
      options.setOpenedBy = false
    }

    // fetch the event document
    this.es.getEventByIdPromise(matchDoc.eventDocId)
      .then((eventDoc) => {

        // check if user is organizer
        if (
          (this.router.url.includes('event-lobby') || this.router.url.includes('event')) &&
          (
            this.auth.user.role === 'admin' ||
            eventDoc.coOrganizers.includes(this.auth.user.playerId) ||
            eventDoc.coOrganizers.includes(this.auth.user.uid) ||
            eventDoc.createdByUid === this.auth.user.uid
          )
        ) {
          // user is organizer
          // create the modal for the report slip
          const modalOptions: NgbModalOptions = {
            centered: true,
            animation: true,
            backdrop: true,
            keyboard: true,
            // size: 'sm',
            windowClass: 'modal-report-slip'
          }
          // open the report slip
          const modalRef: NgbModalRef = this.modalService.open(EventReportSlipComponent, modalOptions)
          modalRef.componentInstance.matchDoc = matchDoc
          modalRef.componentInstance.matchDocId = matchDoc.docId
          modalRef.componentInstance.event = eventDoc
          modalRef.componentInstance.activeRound = eventDoc.activeRound
          modalRef.componentInstance.selectedRound = options.selectedRound
          // check if user is also the player
          if (matchDoc.player1.playerDocId === this.auth.user.playerId && eventDoc.isOnlineTournament ||
            matchDoc.player2.playerDocId === this.auth.user.playerId && eventDoc.isOnlineTournament) {
            this.openMatchRoom(matchDoc)
          }
        }
        // check if MOBILE device and if user is playing the match
        else if (this.globals.isMobile && matchDoc.playerDocIds.includes(this.auth.user.playerId)) {

          if (matchDoc.isReported) {
            this.toastService.show('Match already reported', { classname: 'success-toast', delay: 2000 })
            return
          }

          if (options.setOpenedBy) {
            this.setShowReportSlip(matchDoc.docId)
          }

          // create the modal for the report slip
          const modalOptions: NgbModalOptions = {
            centered: true,
            animation: true,
            backdrop: 'static',
            keyboard: false,
            windowClass: 'modal-report-slip'
          }
          // open the report slip
          const modalRef: NgbModalRef = this.modalService.open(ModalReportSlipComponent, modalOptions)
          modalRef.componentInstance.matchData$ = this.getMatchDocById(matchDoc.docId)

        }
        // NOT organizer and NOT mobile device
        else {
          console.log('user is player, nothing to report and therefore not opening match slip')
          // user is not organizer, open webrtc room if event is ONLINE
          // check event details for Online Event Flag
          if (eventDoc.isOnlineTournament) {
            this.openMatchRoom(matchDoc)
          }
        }
      })
      .catch((error) => console.log(error))

  }
  openMatchRoom(matchDoc: IMatchData): void {

    this.matchRoom.openMatchRoom(matchDoc.docId)
    // Open WebRTC session
    // this.router.navigate(['event-room', match.docId])
    // window.open('match-room/' +
    //   matchDoc.eventDocId + '/' +
    //   matchDoc.docId + '/' +
    //   matchDoc.player1.playerDocId + '/' +
    //   matchDoc.player2.playerDocId + '/' +
    //   matchDoc.createdByUid, 'Match Room', 'height=600,width=800')
  }
  clearEmptyEventMatches(eventDocId: string, roundNumber: number): void {
    this.afs.collection<IMatchData>('matches', ref => ref
      .where('eventDocId', '==', eventDocId)
      .where('roundNumber', '==', roundNumber)
    )
      .get()
      .pipe(take(1))
      .subscribe((snapShot) => {
        snapShot.docs.forEach(doc => {
          if (doc.exists) {
            if (doc.data().player1.playerUid === '' || doc.data().player2.playerUid === '') {
              doc.ref.delete()
            }
          }
        })
      })
  }
  newEmptyMatchDoc(eventDocId: string, roundNumber: number, isType: MatchType) {

    const timestampCreated = firestore.Timestamp.now().seconds

    // create the match doc
    const matchDoc: IMatchData = {
      docId: uuidv4(),
      deleted: false,
      eventDocId,
      createdByUid: this.auth.user.uid,
      feedsMatchDocId: 'none',
      feedsMatchPlayer: 'none',
      winnerGoToMatchDoc: 'none',
      winnerGoToPlayerSlot: 'none',
      loserGoToMatchDoc: 'none',
      loserGoToPlayerSlot: 'none',
      isDraw: false,
      isReported: false,
      isByeMatch: false,
      isLossMatch: false,
      isType,
      groupName: '',
      player1: {
        playerDocId: '',
        playerUid: '',
        displayName: '',
        isWinner: false,
        wins: 0,
        draws: 0,
        losses: 0,
        drop: false,
        matchPoints: 0,
        lifePoints: [20]
      },
      player2: {
        playerDocId: '',
        playerUid: '',
        displayName: '',
        isWinner: false,
        wins: 0,
        draws: 0,
        losses: 0,
        drop: false,
        matchPoints: 0,
        lifePoints: [20]
      },
      playerFilterValue: '',
      roundNumber,
      segmentNumber: 0,
      segmentType: 'no-segment',
      tableNumber: 0,
      playerDocIds: [],
      timestampCreated,
      timestampReported: null,
      reportSlipOpenedBy: null,
    }

    this.afs.collection('matches').doc(matchDoc.docId).set(matchDoc)
  }
  async unpairMatch(match: IMatchData) {
    // store key variables
    const eventDocId = match.eventDocId
    const matchDocId = match.docId

    // create empyt player object
    const emptyPlayer: IMatchPlayer = {
      playerDocId: '',
      playerUid: '',
      displayName: '',
      isWinner: false,
      wins: 0,
      draws: 0,
      losses: 0,
      drop: false,
      matchPoints: 0,
      lifePoints: [20],
      segmentNumber: match.segmentNumber
    }
    // store player 1 and player 2
    const player1 = JSON.parse(JSON.stringify(emptyPlayer))
    player1.playerDocId = match.player1.playerDocId
    player1.playerUid = match.player1.playerUid
    player1.displayName = match.player1.displayName
    player1.matchPoints = match.player1.matchPoints
    const player2 = JSON.parse(JSON.stringify(emptyPlayer))
    player2.playerDocId = match.player2.playerDocId
    player2.playerUid = match.player2.playerUid
    player2.displayName = match.player2.displayName
    player2.matchPoints = match.player2.matchPoints
    if (match.isType === 'batch') {
      player1.segmentNumber = match.segmentNumber
      player2.segmentNumber = match.segmentNumber
    }

    // clear players for the match
    match.player1 = emptyPlayer
    match.player2 = emptyPlayer
    match.playerDocIds = []
    match.playerFilterValue = ''

    // update basic settings of the match
    match.isByeMatch = false
    match.isDraw = false
    match.isLossMatch = false
    match.isReported = false

    // add flag for manual pairings
    match.isManuallyPaired = true

    // update the match
    await this.afs.collection('matches').doc(matchDocId).set(match)


    // ===> Add to UNPAIRED players
    if (player1.playerUid !== '*** BYE ***' && player1.playerUid !== '*** LOSS ***') {

      // update playerDocuments > opponentDocIds array
      await this.afs.collection('events').doc(eventDocId).collection('players').doc(player1.playerDocId)
        .update({
          opponentDocIds: firestore.arrayRemove(player2.playerDocId)
        })
        .catch((error) => {
          console.log(error)
        })

      // add players 1 to the unpaired players
      await this.afs.collection('events').doc(eventDocId)
        .update({
          unpairedPlayers: firestore.arrayUnion(player1)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    // ===> Add to UNPAIRED players
    if (player2.playerUid !== '*** BYE ***' && player2.playerUid !== '*** LOSS ***') {

      // update playerDocuments > opponentDocIds array
      await this.afs.collection('events').doc(eventDocId).collection('players').doc(player2.playerDocId)
        .update({
          opponentDocIds: firestore.arrayRemove(player1.playerDocId)
        })
        .catch((error) => {
          console.log(error)
        })

      // add players 2 to the unpaired players
      await this.afs.collection('events').doc(eventDocId)
        .update({
          unpairedPlayers: firestore.arrayUnion(player2)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
  async addPlayerToMatchSlotByMatchId(matchDocId: string, dropSlot: string, matchPlayer: IMatchPlayer) {
    this.afs.collection('matches').doc(matchDocId)
      .update({
        [dropSlot]: matchPlayer,
        playerDocIds: firestore.arrayUnion(matchPlayer.playerDocId)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  async updateMatchDocument(match: IMatchData) {
    this.afs.collection('matches').doc(match.docId)
      .update(match)
      .catch((error) => {
        console.log(error)
      })
  }
  toggleFeatureMatch(matchDocId: string, isFeatured: boolean) {
    this.afs.collection('matches').doc(matchDocId).update({
      isFeatured
    })
  }
  updateFeatureMatchUris(matchDocId: string, featureUris: IFeatureMatchUris) {
    this.afs.collection('matches').doc(matchDocId).update({
      featureUris
    })
  }
  deleteMatchDoc(matchDocId: string): void {
    console.log(`About to delete match document with document id: ${matchDocId}`)
    this.afs.collection('matches').doc(matchDocId).delete()
      .then((res) => {
        this.toastService.show('Match Document successfully deleted', { classname: 'success-toast', delay: 2000 })
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
        this.toastService.show(err, { classname: 'success-toast', delay: 10000 })
      })
  }
  markAsDeleted(matchDocId: string): void {
    console.log(`About to delete [MARK] match document with document id: ${matchDocId}`)
    this.afs.collection('matches').doc(matchDocId)
      .update({
        deleted: true
      })
      .then((res) => {
        this.toastService.show('Match Document successfully marked as deleted', { classname: 'success-toast', delay: 2000 })
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
        this.toastService.show(err, { classname: 'success-toast', delay: 10000 })
      })
  }
  removeMarkAsDeleted(matchDocId: string): void {
    console.log(`About to remove the delete [MARK] on match document with document id: ${matchDocId}`)
    this.afs.collection('matches').doc(matchDocId)
      .update({
        deleted: false,
      })
      .then((res) => {
        this.toastService.show('Deletion mark successfully removed from the match document', { classname: 'success-toast', delay: 2000 })
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
        this.toastService.show(err, { classname: 'success-toast', delay: 10000 })
      })
  }
  addMatchAvailability(matchAvailability: IMatchAvailabilityMeta): void {
    this.afs.collection('matchAvailability').doc(matchAvailability.docId)
      .set(matchAvailability)
      .then((res) => {
        this.toastService.show('Availability added', { classname: 'success-toast', delay: 2000 })
      })
      .catch((err) => {
        this.toastService.show(err, { classname: 'error-toast', delay: 10000 })
      })
  }
  removeMatchAvailability(docId: string): void {
    this.afs.collection('matchAvailability').doc(docId)
      .delete()
      .then((res) => {
        this.toastService.show('Availability removed', { classname: 'success-toast', delay: 2000 })
      })
      .catch((err) => {
        this.toastService.show(err, { classname: 'error-toast', delay: 10000 })
      })
  }
  getAvailabilityByPlayerAndMatch(playerDocId: string): Observable<IMatchAvailabilityMeta[]> {
    const d = new Date()
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    d.setMilliseconds(0)
    d.setDate(d.getDate() - 1)
    const t = parseInt((d.getTime() / 1000).toFixed(0), 10)
    console.log(`Fetching all availability greater than ${t} for player with doc id ${playerDocId}`)
    console.log(t)

    return this.afs.collection<IMatchAvailabilityMeta>('matchAvailability', ref => ref
      // .where('matchDocId', '==', matchDocId) // NO NEED TO SPECIFY MATCHDOC, THE AVAILABILITY SHOULD BE GLOBAL!
      .where('timestampFrom', '>=', t)
      .where('playerDocId', '==', playerDocId)
      .orderBy('timestampFrom', 'asc')
    ).valueChanges()
  }
  async createMatchAppointment(appointmentData: IMatchAppointment): Promise<boolean> {
    appointmentData.timestampCreated = firestore.Timestamp.now().seconds
    return this.afs.collection('matchAppointments').doc(appointmentData.docId)
      .set(appointmentData)
      .then((res) => {
        this.toastService.show('Appointment sent to your opponent', { classname: 'success-toast', delay: 2000 })
        // send message to the user as well
        this.messageService.postMatchAppointment(appointmentData)
        return true
      })
      .catch((err) => {
        this.toastService.show(err, { classname: 'error-toast', delay: 10000 })
        return false
      })
  }
  getAppointmentsByPlayerDocId(playerDocId: string): Observable<IMatchAppointment[]> {
    return this.afs.collection<IMatchAppointment>('matchAppointments', ref => ref
      .where('playerDocIds', 'array-contains', playerDocId)
      .orderBy('timestampCreated', 'desc')
    ).valueChanges()
  }
  getAppointmentsByMatchDocId(matchDocId: string): Observable<IMatchAppointment[]> {
    return this.afs.collection<IMatchAppointment>('matchAppointments', ref => ref
      .where('matchDocId', '==', matchDocId)
      .orderBy('timestampCreated', 'desc')
    ).valueChanges()
  }
  getMatchAppointmentByDocId(appointmentDocId: string): Observable<IMatchAppointment> {
    return this.afs.collection('matchAppointments').doc<IMatchAppointment>(appointmentDocId).valueChanges()
  }
  updateMatchAppointmentAsRead(appointmentDocId: string): void {
    this.afs.collection<IMatchAppointment>('matchAppointments').doc(appointmentDocId).update({
      opponentHasRead: true,
      timestampRead: firestore.Timestamp.now().seconds
    })
  }
  updateMatchAppointmentStatus(appointmentDocId: string, status: string): void {
    switch (status) {
      case 'reject':
        this.afs.collection<IMatchAppointment>('matchAppointments').doc(appointmentDocId).update({
          isAccepted: false,
          isRejected: true,
          timestampRejected: firestore.Timestamp.now().seconds
        })
        break

      case 'accept':
        this.afs.collection<IMatchAppointment>('matchAppointments').doc(appointmentDocId).update({
          isAccepted: true,
          isRejected: false,
          timestampAccepted: firestore.Timestamp.now().seconds
        })
        break

      case 'cancel':
        this.afs.collection<IMatchAppointment>('matchAppointments').doc(appointmentDocId).update({
          isCancelled: true,
          timestampCancelled: firestore.Timestamp.now().seconds
        })
        break
    }
  }
  async createMatchRoomShortUrl(matchDocId: string, matchRoomLink: string) {
    // get the url if available
    const matchUrlSnap = await this.afs.collection<IMatchUrl>('matchUrls', ref => ref.where('matchDocId', '==', matchDocId).limit(1)).get().toPromise()
    if (matchUrlSnap.empty) {
      // create a new
      const nanoId = nanoid(10)
      const matchUrl: IMatchUrl = {
        url: matchRoomLink,
        matchDocId: matchDocId,
        matchRef: nanoId,
      }
      this.afs.collection('matchUrls').doc(nanoId).set(matchUrl)
        .then(() => this.app.copyTextToClipboard(`https://tolaria.app/watch/${nanoId}`))
        .catch((error) => console.log(error))
    }
    else {
      // copy the url to clipboard
      this.app.copyTextToClipboard(`https://tolaria.app/watch/${matchUrlSnap.docs[0].data().matchRef}`)
    }
  }
  public async getMatchesAgainstPlayerDocId(playerDocId: string) {

    const matches = await this.afs.collection<IMatchData>('matches', ref => ref
      .where('playerDocIds', 'array-contains', this.auth.user.playerId)
      .where('isReported', '==', false)
    ).get().toPromise()
    if (matches.empty) { return [] }

    return matches.docs.map(i => i.data() as IMatchData).filter(i => i.playerDocIds.includes(playerDocId))

  }
  public matchReportingObserer(): Observable<IMatchData[]> {
    return this.afs.collection<IMatchData>('matches', ref => ref
      .where('playerDocIds', 'array-contains', this.auth.user.playerId)
      // .where('eventDocId', '!=', 'casual-match')
      .where('reportSlip.showSlip', '==', true)
      .where('isReported', '==', false)
    ).valueChanges()
  }
  setShowReportSlip(matchDocId: string): void {

    this.afs.collection('matches').doc<IMatchData>(matchDocId)
      .update({
        reportSlip: {
          showSlip: true,
          player1Confirmed: false,
          player2Confirmed: false,
          openedByPlayerDocId: this.auth.user.playerId,
        }
      })
      .then(() => console.log('report slip updated to be opened'))
      .catch((error) => console.log(error))

  }

}


export interface IMatchUrl {
  url: string
  matchDocId: string
  matchRef: string
}

import { Component, Input } from '@angular/core'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { Observable } from 'rxjs'
import { DialogsService, EventService } from 'src/app/services'

@Component({
  selector: 'app-event-actions-check-in',
  templateUrl: './event-actions-check-in.component.html',
  styleUrls: ['./event-actions-check-in.component.css']
})
export class EventActionsCheckInComponent {
  @Input() eventDoc$: Observable<IEventDetails>

  faChevronRight = faChevronRight
  
  public submenuOpen = false

  constructor(
    private eventService: EventService,
    private dialogs: DialogsService
  ) { }

  startCheckIn(eventDocId: string) {
    this.eventService.startCheckIn(eventDocId)
  }
  dropPlayersWitCheckInFalse(event: IEventDetails) {
    this.dialogs.openDialog(
      'Drop no-show players?',
      'This action will drop all players who have not checked in, are you sure?',
      'ConfirmCancel'
    ).then((dialogResponse: any) => {
      console.log('Dialog Response, ', dialogResponse)
      if (dialogResponse.pressed !== 'cancel') {
        this.eventService.dropPlayersWitCheckInFalse(event.docId)
      }
    })
  }
}

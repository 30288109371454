<div class="outer-wrapper" [ngClass]="{ 'fullscreen' : fullscreen }">

  <div #deckContainer [id]="deck.docId"
    class="visual-deck-wrapper position-relative min-width-100 max-width-100 min-height-100 max-height-100 overflow-hidden">

    <!-- <ng-container *ngIf="deck$ | async as deck"> -->

      <div class="main-deck position-absolute" [style.top]="0"
        [style.right.rem]="(defaultCardSize.width * deck.cardZoomLevel) + 1"
        [style.bottom.rem]="(defaultCardSize.width * deck.cardZoomLevel) + 1" [style.left]="0">

        <div class="text-secondary text-start position-absolute min-width-100 p-2" [style.top]="0" [style.left]="0">Main Deck
          ({{ deck.main.length }})</div>

        <div #mainDeckDropZone class="drop-zone p-2 pt-5" mwlDroppable dragOverClass="drop-zone-active"
          (drop)="cardDropped($event.dropData, DECKPART.MAIN)">

          <ng-container *ngFor="let card of deck.main; let i = index; trackBy: mainDeckCardTracker">
            <div class="card-wrapper {{ card.setCode }}" mwlDraggable dragActiveClass="card-being-dragged"
              (dragEnd)="cardDragEnd($event)" [dragSnapGrid]="{ x: gridSizeX, y: gridSizeY }"
              [style.left.px]="card.coords.x" [style.top.px]="card.coords.y"
              [style.width.rem]="defaultCardSize.width * deck.cardZoomLevel"
              [style.height.rem]="defaultCardSize.height * deck.cardZoomLevel"
              [dropData]="{ fromZone: DECKPART.MAIN, card: card, index: i }" [id]="card.metaUuid">

              <app-scryfall-image-view [scryfallId]="card.scryfallId"></app-scryfall-image-view>

            </div>
          </ng-container>

        </div>


      </div>

      <div class="sideboard position-absolute border-top" [style.bottom]="0" [style.left]="0"
        [style.right.rem]="(defaultCardSize.width * deck.cardZoomLevel) + 1"
        [style.height.rem]="(defaultCardSize.width * deck.cardZoomLevel + 1)">

        <div class="text-secondary text-start position-absolute min-width-100 p-2" [style.marginTop.rem]="-2">Sideboard ({{
          deck.sideboard.length }})</div>

        <div class="drop-zone d-flex flex-row ps-2 pt-2" mwlDroppable dragOverClass="drop-zone-active"
          [style.paddingRight.rem]="(defaultCardSize.width * deck.cardZoomLevel) - 3"
          (drop)="cardDropped($event.dropData, DECKPART.SIDEBOARD)">

          <ng-container *ngFor="let card of deck.sideboard; let i = index; trackBy: mainDeckCardTracker;">
            <div class="sideboard outer-card-wrapper">
              <div class="sideboard card-wrapper {{ card.setCode }}" mwlDraggable dragActiveClass="card-being-dragged"
                [dropData]="{ fromZone: DECKPART.SIDEBOARD, card: card, index: i }" [id]="card.metaUuid"
                [style.width.rem]="defaultCardSize.width * deck.cardZoomLevel"
                [style.minWidth.rem]="defaultCardSize.width * deck.cardZoomLevel">

                <img [src]="'https://api.scryfall.com/cards/' + card.scryfallId + '?format=image'" draggable="false" crossorigin>

              </div>
            </div>
          </ng-container>

        </div>

      </div>

      <div class="trash overflow-hidden position-absolute border-top border-left" [style.bottom]="0" [style.right]="0"
        [style.height.rem]="(defaultCardSize.width * deck.cardZoomLevel) + 1"
        [style.width.rem]="(defaultCardSize.width * deck.cardZoomLevel) + 1">

        <div class="trash d-flex align-items-center justify-content-center min-height-100 min-width-100" mwlDroppable
          dragOverClass="drop-zone-trash-active" (drop)="cardDropped($event.dropData, DECKPART.TRASH)">
          <fa-icon [icon]="takingScreenShot ? icons.tolaria : icons.faTrashAlt" [fixedWidth]="true" class="text-secondary"
            [style.fontSize.rem]="takingScreenShot ? 7 : (defaultCardSize.width * deck.cardZoomLevel) / 3"></fa-icon>
        </div>
      </div>

      <div class="maybeboard position-absolute border-left" [style.right]="0" [style.top]="0"
        [style.bottom.rem]="(defaultCardSize.width * deck.cardZoomLevel) + 1"
        [style.width.rem]="(defaultCardSize.width * deck.cardZoomLevel) + 1">

        <div class="text-secondary text-center position-absolute min-width-100 p-2">Maybes ({{ deck.maybeboard.length }})
        </div>

        <div class="drop-zone px-2 pt-5 d-flex flex-column"
          [style.width.rem]="defaultCardSize.height * deck.cardZoomLevel" mwlDroppable dragOverClass="drop-zone-active"
          (drop)="cardDropped($event.dropData, DECKPART.MAYBEBOARD)">

          <ng-container *ngFor="let card of deck.maybeboard; let i = index; trackBy: mainDeckCardTracker">
            <div class="maybeboard outer-card-wrapper">
              <div class="card-wrapper {{ card.setCode }}" mwlDraggable dragActiveClass="card-being-dragged"
                [dropData]="{ fromZone: DECKPART.MAYBEBOARD, card: card, index: i }" [id]="card.metaUuid"
                [style.width.rem]="defaultCardSize.width * deck.cardZoomLevel"
                [style.minWidth.rem]="defaultCardSize.width * deck.cardZoomLevel">

                <img [src]="'https://api.scryfall.com/cards/' + card.scryfallId + '?format=image'" draggable="false" crossorigin>

              </div>
            </div>
          </ng-container>

        </div>

      </div>

    <!-- </ng-container> -->
    
  </div>


  <div class="changes-wrapper d-flex align-items-center">

    <div ngbDropdown class="d-inline-block" placement="bottom-right">
      <button class="btn btn-sm btn-blank text-secondary me-2 no-caret" ngbDropdownToggle id="dropdownArrangement"
        ngbTooltip="Arrange deck" container="body">
        <fa-icon [icon]="icons.faLayerGroup" [fixedWidth]="true"></fa-icon>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownArrangement">
        <button ngbDropdownItem (click)="rearangeCards('color')">by Color</button>
        <button ngbDropdownItem (click)="rearangeCards('cmc')">by Converted Mana Cost</button>
        <button ngbDropdownItem (click)="rearangeCards('type')">by Type</button>
        <button ngbDropdownItem (click)="rearangeCards('10by6')">in a 10 by 6 grid</button>
      </div>
    </div>

    <ng-template #zoomLevelTooltip>
      <input type="range" class="form-control form-control-sm form-control-range" [(ngModel)]="deck.cardZoomLevel"
        min="0.2" max="1.5" step="0.1">
    </ng-template>
    <div class="tooltip-wrapper" ngbTooltip="Adjust Zoom level" container="body">
      <button class="btn btn-sm btn-blank text-secondary me-2" [ngbTooltip]="zoomLevelTooltip" triggers="click"
        [autoClose]="'outside'" container="body" placement="bottom-right">
        <fa-icon [icon]="icons.faSearchPlus" [fixedWidth]="true"></fa-icon>
      </button>
    </div>


    <div class="btn btn-sm btn-blank text-secondary me-2" (click)="fullscreen = !fullscreen"
      [ngbTooltip]="fullscreen ? 'Compress view' : 'Expand view'" container="body">
      <fa-icon [icon]="fullscreen ? icons.faCompress : icons.faExpand" [fixedWidth]="true"></fa-icon>
    </div>

    <button class="btn btn-sm btn-blank text-secondary" (click)="takeScreenShot()" ngbTooltip="Download Screenshot"
      container="body" position="top-right">
      <fa-icon [icon]="icons.faCamera" [fixedWidth]="true"></fa-icon>
    </button>


  </div>

</div>



<!-- <div class="info-wrapper">
  <pre>{{ infoData | json }}</pre>
</div> -->

<!-- <div class="btn btn-sm white-space-nowrap {{ changesMade ? 'btn-outline-main' : 'btn-outline-secondary' }}"
  (click)="saveDeck(deck)">
  <fa-icon [icon]="faSave" [fixedWidth]="true" class="me-2"></fa-icon>
  <span>Save changes</span>
</div> -->
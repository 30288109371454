import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { EmojiService } from '../../../tolaria-emoji-picker/emoji.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tolaria-emoji',
  templateUrl: './tolaria-emoji.component.html',
  styleUrls: ['./tolaria-emoji.component.css']
})
export class TolariaEmojiComponent implements OnInit {
  @Input() set shortcode(id: string) {
    this.id = id
  }
  
  public id!: string
  public emojiImage: string
  
  constructor(
    private readonly host: ElementRef<HTMLImageElement>,
    private readonly emojiService: EmojiService,
    // private readonly renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    let emoji = this.emojiService.emojiByShortcode(this.id)
    this.emojiImage = emoji.image
    // this.host.nativeElement.src = emoji.image
    // this.host.nativeElement.classList.add('ql-emoji')
    console.log(this.host.nativeElement)
    // this.renderer.setAttribute(this.host.nativeElement, 'src', emoji.image)
  }

  public destroy(): void {
    this.host.nativeElement.remove()
  }

  
}

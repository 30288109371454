import { ToastService } from 'src/app/services/toast.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService, ImageService, GlobalsService } from 'src/app/services';
import { Component, OnDestroy } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css']
})
export class AvatarComponent implements OnDestroy {
  faCamera = faCamera;

  public hasCustomAvatar: boolean;
  public customAvatarUrl: string;
  public avatar = true;
  public change = false;
  progress: number;
  infoMessage: any;
  isUploading = false;
  file: File;
  private fileExt: string;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  saveDisabled = true;
  downloadUrl: Observable<string>;

  imageUrl: string | ArrayBuffer = 'https://bulma.io/images/placeholders/480x480.png';
  fileName = 'No file selected';

  private componentWasDestroyed$ = new Subject<boolean>();

  constructor(
    public img: ImageService,
    public auth: AuthService,
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private toastService: ToastService,
    private globals: GlobalsService
  ) {
    this.hasCustomAvatar = this.auth.user.customAvatar;
    if (this.hasCustomAvatar) {
      this.customAvatarUrl = this.auth.user.avatar;
      this.change = false;
    }
  }

  ngOnDestroy(): void {
    this.componentWasDestroyed$.next(true);
  }

  fileChangeEvent(event: any): void {
    const name = event.target.files[0].name;
    const lastDot = name.lastIndexOf('.');
    this.fileExt = name.substring(lastDot + 1);
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
    console.log('imageLoaded');
    this.saveDisabled = false;
  }
  cropperReady() {
    // cropper ready
    console.log('cropperReady');
  }
  loadImageFailed() {
    // show message
    console.log('loadImageFailed');
  }

  async saveImage() {
    this.globals.isBusy.message = 'Uploading your image, please wait';
    this.globals.isBusy.status = true;
    this.globals.isBusy.showMessage = true;
    const filePath = 'user-avatars/' + this.auth.user.uid + '.' + this.fileExt;
    const ref = this.storage.ref(filePath);
    const file = this.imageToDataUri(this.croppedImage, 256, 256);
    const thumb = this.imageToDataUri(this.croppedImage, 64, 64);
    const task = ref.putString(file, 'data_url');

    task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadUrl = ref.getDownloadURL();
        // store the download url as the avatar link for both user and player doc.
        this.downloadUrl.pipe(takeUntil(this.componentWasDestroyed$)).subscribe(async (url) => {
          this.globals.isBusy.message = 'Uploading done, updating your avatar url';
          await this.updatePlayerAvatar(url, thumb);

          this.globals.isBusy.message = 'Finished!';
          setTimeout(() => {
            this.globals.isBusy.status = false;
            this.globals.isBusy.showMessage = false;
          }, 1500);
        });
      })
    ).subscribe();

    this.change = false;
    this.croppedImage = '';
    this.saveDisabled = true;
    this.imageChangedEvent = '';
  }

  async updatePlayerAvatar(url: string, thumb: string) {
    this.afs.collection('users').doc(this.auth.user.uid)
      .update({
        avatar: url,
        avatarThumb: thumb,
        customAvatar: true
      })
      .then(() => {

      })
      .catch((err) => {
        console.log(err);
      });
    this.afs.collection('players').doc(this.auth.user.playerId)
      .update({
        avatar: url,
        avatarThumb: thumb,
        customAvatar: true
      })
      .then(() => {
        this.toastService.show('Avatar image uploaded successfully!', { classname: 'succes-toast', delay: 2000 });
      })
      .catch((err) => {
        console.log(err);
      });
    this.change = false;
  }

  imageToDataUri(base64: string, width: number, height: number) {
    // Create and initialize two canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const canvasCopy = document.createElement('canvas');
    const copyContext = canvasCopy.getContext('2d');

    // Create original image
    const img = new Image();
    img.src = base64;

    // Draw original image in second canvas
    canvasCopy.width = img.width;
    canvasCopy.height = img.height;
    copyContext.drawImage(img, 0, 0);

    // Copy and resize second canvas to first canvas
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL();
  }


}

import { distinctUntilChanged, map, take, takeUntil, tap } from 'rxjs/operators'
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs'
import { IMatchData, IEventDetails, IEventPlayerDetails, IEventTeam } from 'tolaria-cloud-functions/src/_interfaces'
import { AuthService, EventService, GlobalsService } from 'src/app/services'
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { faBroadcastTower, faEllipsisH, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { MatchService } from 'src/app/services/match.service'
import { ITeamMatchPipeConfig, ITeamMatchTemplate } from 'src/app/pipes/team-matches.pipe'

export interface IMatchDataTemplate extends IMatchData {
  canBeActivated: boolean
}

@Component({
  selector: 'app-event-pairings',
  templateUrl: './event-pairings.component.html',
  styleUrls: ['./event-pairings.component.css']
})
export class EventPairingsComponent implements OnInit, OnDestroy {
  @ViewChild('matchDeletionConfirmation') matchDeletionConfirmation: ElementRef
  @Input() selectedRound: number
  @Input() selectedGroup?: number
  @Input() filterValue: string
  @Input() showReportedMatches: boolean
  @Input() eventMatches$: Observable<IMatchData[]>
  @Input() eventDoc$: Observable<IEventDetails>
  @Input() isOrganizer: boolean
  @Input() showTeamPlayerMatches: boolean
  @Input() showPairingsView?: boolean
  @Output() showMe = new EventEmitter()

  matches$: Observable<IMatchDataTemplate[]>
  players$: Observable<IEventPlayerDetails[]>

  private oneTimeEventDoc$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  private oneTimePlayers$: BehaviorSubject<IEventPlayerDetails[]> = new BehaviorSubject<IEventPlayerDetails[]>(null)
  public swissTeams$: Observable<IEventTeam[]>
  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()


  public closePairingsView(value: boolean): void {
    this.showMe.emit(value)
  }

  faBroadcast = faBroadcastTower
  faWarning = faExclamationTriangle
  moreAction = faEllipsisH

  constructor(
    public auth: AuthService,
    public globals: GlobalsService,
    private eventService: EventService,
    private matchService: MatchService,
  ) {
  }

  ngOnDestroy(): void {
      this.componentDestroyed$.next(true)
  }

  ngOnInit(): void {
    this.eventDoc$.pipe(take(1)).subscribe(e => {
      console.log('event document -->', e)
      this.oneTimeEventDoc$.next(e)
      this.players$ = this.eventService.getEventPlayersByEventId(e.docId)

      if (e.details.structure.swiss?.teams && e.details.structure.isSwiss) {
        console.log(' event is swiss team event')
        this.players$.pipe(takeUntil(this.componentDestroyed$)).subscribe(p => {
          console.log('--> player list', p)
          this.oneTimePlayers$.next(p)
        })
        this.swissTeams$ = this.eventService.getEventTeamsByEventId(e.docId)
      }

      this.matches$ = combineLatest([this.players$, this.eventMatches$]).pipe(
        // TODO --> Remove this code block if not needed
        //
        // The distinctUntilChanged should no longer be needed as I have move the
        // frequently updated properties to a sub collection document instead
        // -> player.handSize
        // -> player.lifePoints

        // tap(([players, matches]) => {
        //   console.log(`EventPairings --> Players/Matches emitted a change`, { players, matches })
        // }),
        // distinctUntilChanged((prev: [IEventPlayerDetails[], IMatchData[]], next: [IEventPlayerDetails[], IMatchData[]]) => {
        //   if (!next) {
        //     return false
        //   }
        //   return prev && next &&
        //     prev[1].length === next[1].length &&
        //     prev[1].map(i => i.player1.lifePoints.length + i.player2.lifePoints.length).reduce((a, b) => a + b, 0) !== next[1].map(i => i.player1.lifePoints.length + i.player2.lifePoints.length).reduce((a, b) => a + b, 0) &&
        //     prev[1].map(i => i.player1.handSize + i.player2.handSize).reduce((a, b) => a + b, 0) !== next[1].map(i => i.player1.handSize + i.player2.handSize).reduce((a, b) => a + b, 0)
        //     /** https://github.com/Slanfan/MTG-Tolaria/issues/457
        //      *
        //      * Event should now update matches on any change except
        //      * lifePoints and handSize
        //      *****/
        //     // prev[1].length === next[1].length &&
        //     // prev[0].filter(p => p.dropped).length === next[0].filter(p => p.dropped).length &&
        //     // prev[1].filter(m => m?.isManuallyPaired && m.player1.playerDocId !== '' && m.player2.playerDocId).length === next[1].filter(m => m?.isManuallyPaired && m.player1.playerDocId !== '' && m.player2.playerDocId).length &&
        //     // prev[1].filter(m => m.isReported).length  === next[1].filter(m => m.isReported).length &&
        //     // prev[1].filter(m => m.isFeatured).length  === next[1].filter(m => m.isFeatured).length &&
        //     // prev[1].filter(m => m.deleted).length     === next[1].filter(m => m.deleted).length
        // }),
        // tap(() => console.log('emitted')),

        map(([players, matches]) => {
          const newMatches: IMatchDataTemplate[] = []
          matches.forEach(m => {
            const match = m as IMatchDataTemplate
            const p1 = players.find(i => i.playerDocId === match.player1.playerDocId)
            const p2 = players.find(i => i.playerDocId === match.player2.playerDocId)
            match.canBeActivated = m.deleted &&
              p1 !== undefined && p1 !== null && !p1.dropped &&
              p2 !== undefined && p2 !== null && !p2.dropped
            newMatches.push(match)
          })
          return newMatches
        })
        )

    })

  }

  public get myPlayerDocId(): string {
    return this.auth.user.playerId
  }

  public matchTracker(index: number, item: IMatchData) {
    return `${index}--${item.docId}`
  }

  public teamMatchTracker(index: number, item: ITeamMatchTemplate) {
    return `${index}--${item.table}`
  }

  public get isSwissTeamEvent(): boolean {
    return this.oneTimeEventDoc$.getValue().details.structure.swiss?.teams
  }

  public get teamMatchPipeConfig(): ITeamMatchPipeConfig {
    const players = this.oneTimePlayers$.getValue()

    if (players === null || players.length < 2) { return null }

    return {
      selectedGroup: this.selectedGroup,
      selectedRound: this.selectedRound,
      event: this.oneTimeEventDoc$.getValue(),
      showReportedMatches: this.showReportedMatches,
      filter: this.filterValue,
      players: players,
    }
  }

  public openReportSlip(matchDoc: IMatchData, selectedRound: number) {
    console.log(matchDoc)
    if (matchDoc?.deleted && matchDoc.deleted) { return }
    this.matchService.openReportSlip(matchDoc, { selectedRound })
  }

  public get isAdmin(): boolean {
    return this.auth.user.role === 'admin'
  }

  public get showPairingsFullView(): boolean {
    return this.showPairingsView !== undefined && this.showPairingsView
  }

}

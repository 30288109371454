import { StripeRole } from './stripe.interfaces'

export interface IPlayerDetails {
  disabled?: boolean
  attendingEvents: Array<string>;
  country: IPlayerCountry;
  createdByUid: string;
  docId: string;
  email: string;
  name: IPlayerNameDetails;
  phone: string;
  timeZone: ITimeZone;
  type: string;
  uid: string;
  userWithThisPlayerStored: Array<string>;

  deviceUtcOffset?: string;
  deviceUtcOffsetUpdated?: number;
  deviceUtcTimezoneName?: string;

  description?: string;
  avatar?: string;
  avatarThumb?: string
  customAvatar?: boolean;
  identifiers?: IPlayerIdentifiers;
  clubDocIds?: string[];
  // identifiers?: { [identifier: string]: boolean };
  titles?: Array<IPlayerTitles>;
  registered: boolean;
  address?: {
    street: string;
    other: string,
    zipCode: number;
    city: string;
    region: string;
  }
  emailIsPublic?: boolean;
  addressIsPublic?: boolean;
  phoneIsPublic?: boolean;
  handles?: Array<IPlayerHandle>;
  statusMessage?: string;
  notifications: {
    email: {
      matchAppointments: boolean;
      matchAppointmentReminders: boolean;
      eventReminders: boolean;
      newsLetter: boolean;
      developmentNews: boolean;
    }
  }
  presence?: IOnlinePlayer;
  stripeCustomerId?: string;
  tolariaSupportUntil?: number
  matchRoomBackgroundUrl?: string
}
export interface IOnlinePlayer {
  status: string;
  timestamp: number;
  downToPlay: boolean;
  downToPlayFormats: Array<IFormatMeta>;
}
export interface IFormatMeta {
  name: string;
  selected: boolean;
}
export interface IPlayerNameDetails {
  first: string;
  last: string;
  nick?: string;
  display?: string;
}
export interface IPlayerCountry {
  name: string;
  code: string;
  callingCode: string;
  region: string;
  subRegion?: string;
}
export interface IPlayerIdentifiers {
  dci?: string;
  nci?: string;
  memberOfClub?: boolean;
  clubDocId?: string;
  hasShark?: boolean;
  isDingus?: boolean;
  isDrakeGuild?: boolean;
  isHero?: boolean;
  isGuardian?: boolean;
}
export interface IPlayerTitles {
  name: string;
  year: number;
  eventName: number;
}
export interface IPlayerHandle {
  platform: string;
  handle: string;
  url: string;
  placeholder: string;
}
export interface ICountry {
  name: string;
  code: string;
  callingCodes: Array<string>;
  region: string;
  subregion: string;
}
export interface ITimeZone {
  countryCode: string;
  countryName: string;
  zoneCode: string;
  zoneName: string;
  olson: string;
  UTC: string;
  DST: string;
}
export interface IPromiseResponse {
  status: boolean;
  text: string;
  value?: boolean;
  data?: any;
}
export interface IUser {
  disabled?: boolean
  avatar?: string;
  createdOn?: number; // updated 2020-12-21
  customAvatar?: boolean;
  email: string;
  isRegisteredAsPlayer?: boolean;
  playerId?: string;
  role?:
    | 'user'
    | 'guardian'
    | 'player'
    | 'organizer'
    | 'admin';
  stripe?: IStripeMeta;
  uid: string;
  settings?: IUserSettings;
  deviceUtcOffset?: string;
  deviceUtcOffsetUpdated?: number;
  deviceUtcTimezoneName?: string;
  fcmTokens?: FcmToken
}
export interface FcmToken {
  [key:string]: number
}
export interface IStripeMeta {
  id: string;
  role: StripeRole;
  account_links: any;
  charges_enabled: boolean;
  payouts_enabled: boolean;
  default_currency: string;
  capabilities: {
    [key: string]: string;
  }
  error_message: string;
  country_verified_by_user: boolean;
}
export interface IUserRegistration {
  email: string;
  password: string;
  confirmPassword: string;
  firstName?: string;
  lastName?: string;
  nickname?: string;
}
export interface IUserLogin {
  email: string;
  password: string;
}
export interface IUserSettings {
  darkMode?: boolean;
  clock24?: boolean;
  textSize?: string;
  isDrakeGuild?: boolean;
}
export interface ISenderInfo {
  displayName: string;
  uid?: string;
  avatar?: string;
}
export interface IClub {
  docId: string;
  createdByUid: string;
  createdTimestamp: number;
  updatedTimestamp: number;
  updatedByPlayerDocId: string;

  name: string;
  country: string;
  region: string;
  city: string;
  websiteUrl: string;
  info: string;
  imageUris: IClubImages;
  responsiblePlayerDocIds: string[];
  memberDocIds: string[];

  longitude: number;
  latitude: number;
}
export interface IClubImages {
  thumb: string;
  small: string;
  normal: string;
  large: string;
}



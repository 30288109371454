import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const tiFlipLeft: IconDefinition = {
  prefix: 'fac',
  iconName: 'flipLeft',
  icon: [
    470.27, // SVG view box width
    512, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M417.53,336.68c-.49,20.46-7.32,37.39-21.17,51.26-34.3,34.35-68.47,68.83-103.13,102.81-29.11,28.54-73.42,28.25-102.4-.37Q139.62,439.82,89,388.71c-29.75-30-29.52-74.41.33-104.33q49.82-49.95,99.87-99.67c30.94-30.75,75.2-30.78,106.2,0,33.26,33,66.3,66.19,99.51,99.22C409.8,298.77,417.48,316.57,417.53,336.68Zm-48.87.18c.06-7.44-2.94-13.29-8-18.31Q310.31,268.34,260,218.11c-10.85-10.8-24.82-10.77-35.68,0Q173.69,268.52,123.22,319c-10.5,10.51-10.67,24.82-.23,35.29q50.81,51,101.87,101.67c10,9.95,24.45,10,34.51,0q51.12-50.65,101.92-101.63C366.18,349.51,368.88,343.65,368.66,336.86ZM92.6,118.34c.94-1.18,1.86-2.39,2.88-3.5,52.36-56.86,116.88-78,191.86-60.18,65.89,15.65,110.8,57.46,135.38,120.62,5.79,14.87,18.81,21.71,32.19,16.61s18.74-18.52,13.15-33.67C429.07,52.49,322.22-12.59,210.2,2.05c-64.27,8.39-117,38.25-157.89,88.63a29.92,29.92,0,0,0-2.15,3.53l-1.74-1.13c0-7.51.44-15-.1-22.52A24.09,24.09,0,0,0,24.09,48.3C11.75,48.46.7,58,.42,70.48q-.84,38.46,0,77c.29,12.66,11,21.78,23.78,21.83q36.3.12,72.61,0c12.56,0,22.72-9,24.09-21,1.47-13-6.26-23.89-19.08-26.69-3.24-.71-6.63-.79-10.5-1.22C92,119.27,92.26,118.77,92.6,118.34Z'
  ],
} as any;

import { Subject, Observable, BehaviorSubject, takeUntil, combineLatest, take } from 'rxjs';
import { IEventDetails, IEventPlayerDetails, IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event/event.service';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { PlayerTieBreakers } from 'src/app/services/event/tiebreakers';
import { faSitemap } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-event-standings',
  templateUrl: './event-standings.component.html',
  styleUrls: ['./event-standings.component.css']
})
export class EventStandingsComponent implements OnInit, OnDestroy {
  @Input() eventDocId: string;
  @Input() isGroupEvent$: Subject<boolean>;
  @Input() eventPlayers$: Observable<IEventPlayerDetails[]>;
  @Input() eventMatches$: Observable<IMatchData[]>;
  @Input() showDroppedPlayers: boolean;
  @Input() set filterValue(val: string) {
    this.filter = val;
  }

  public filter: string = '';
  public event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()

  public standings$: BehaviorSubject<IEventPlayerDetails[]> = new BehaviorSubject<IEventPlayerDetails[]>(null)
  public playoff = faSitemap

  constructor(
    private es: EventService,
    public auth: AuthService,
    public globals: GlobalsService
  ) {}

  ngOnInit(): void {
    const event = this.es.getEventById(this.eventDocId)

    event.pipe(takeUntil(this.componentDestroyed$)).subscribe(e => this.event$.next(e))

    combineLatest([event, this.eventPlayers$, this.eventMatches$])
    .pipe(
      take(1),
      // takeUntil(this.componentDestroyed$)
    )
    .subscribe(([event, players, matches]) => {
      this.constructStandings(event, players, matches)
    })

  }

  ngOnDestroy(): void {
      this.componentDestroyed$.next(true)
  }

  getPercentage(value: number): string {
    return this.es.getPercentage(value);
  }

  showPlayerLink(playerUid: string): boolean {
    if (playerUid.substr(0, 6) === 'temp__' || playerUid === '*** BYE ***') {
      return false;
    }
    else {
      return true;
    }
  }

  public get isSwissTeamEvent(): boolean {
    return this.event$.getValue().details.structure.isSwiss && this.event$.getValue().details.structure.swiss?.teams
  }


  private constructStandings(event: IEventDetails, players: IEventPlayerDetails[], matches: IMatchData[]): void {
    let playersData = this.es.__getExtendedPlayers(players, matches, event)
    playersData = PlayerTieBreakers.compute(playersData)
    playersData = PlayerTieBreakers.sort(playersData)
    playersData = PlayerTieBreakers.rank(playersData, event.details.structure.isGroup)
    this.standings$.next(playersData)
  }

}

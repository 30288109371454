import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import Quill from 'quill';
import { ButtonResult, TolariaWysiwygFooterComponent } from './tolaria-wysiwyg-footer/tolaria-wysiwyg-footer.component';
import { v4 as uuidv4 } from 'node_modules/uuid'
import { BehaviorSubject, Subject, finalize, takeUntil } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { EmojiService } from '../tolaria-emoji-picker/emoji.service';
import { EmojiItem } from '../tolaria-emoji-picker/emojis';
import { DeckLinkerComponent, IDeckLinkerResponse } from 'src/app/components/decks/deck-linker/deck-linker.component';
import { TolariaWysiwygToolbarComponent } from './tolaria-wysiwyg-toolbar/tolaria-wysiwyg-toolbar.component';
import { CardResponse, CardSearchSelectComponent } from 'src/app/private/_shared/card-search-select/card-search-select.component';
import { MessageItem } from '../../services/message-list.service';
import { PlayerAvatarComponent } from 'src/app/components/players/player-avatar/player-avatar.component';
import { onlyEmojis } from './tolaria-wysiwyg-helper';
import { TolariaWysiwygMention, TolariaWysiwygOutput } from './tolaria-wysiwyg.interfaces';
import { TolariaWysiwygInsertLinkComponent } from './tolaria-wysiwyg-insert-link/tolaria-wysiwyg-insert-link.component';
import Delta from 'quill-delta';
import { QuillEditorComponent } from 'ngx-quill';
import { ImagePreviewService } from 'src/app/private/_shared/services/image-preview.service';
import { TournamentPickerComponent } from 'src/app/private/tournament/components/tournament-picker/tournament-picker.component';
import { IEventTemplate } from 'src/app/private/events/services/event-listing.service';
import { GlobalsService } from 'src/app/services';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tolaria-wysiwyg',
  templateUrl: './tolaria-wysiwyg.component.html',
  styleUrls: ['./tolaria-wysiwyg.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    QuillEditorComponent,
    TolariaWysiwygToolbarComponent,
    TolariaWysiwygFooterComponent,
    FontAwesomeModule,
    NgbTooltip,
    PlayerAvatarComponent,
  ]
})
export class TolariaWysiwygComponent implements OnInit, OnDestroy {
  @Output() deltaComplete = new EventEmitter<TolariaWysiwygOutput>()
  @Output() cancelEdit = new EventEmitter<boolean>()

  @Input() set readOnly(value: boolean) {
    this.readonly = value
    if (this.editor) {
      if (value === true) {
        this.disableEditor()
      }
      else {
        this.enableEditor()
      }
    }
  }
  @Input() set message(value: any) {
    if (this.editor) {
      this.delta = value
      this.editor.setContents(value)
    }
    else {
      this.delta = value
    }
  }
  @Input() set messageGroupDocId(id: string) {
    this.logger('info', 'message group document id emitted', id)
    this._messageGroupDocId = id
  }
  @Input() replyTo$: BehaviorSubject<MessageItem>
  @Input() debug: boolean = false
  @Input() composer: boolean = true
  @Input() richText: boolean = true
  @Input() set mentionList(data: TolariaWysiwygMention[]) {
    this.logger('info', 'mention list emitted new value', data)
    this.mentionList$.next(data)
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    if (event.target.hasOwnProperty('__blot') && event.target.nodeName === 'A') {
      console.log('clicked a link')
      this.handleLinkClicked(event)
    }
    else {
      this.linkClicked = false
    }
    if (event.target.nodeName === 'IMG' && !event.target.className.includes('ql-emoji')) {
      this.imagePreview.show({ imageUris: [event.target.src] })
    }
  }

  private _messageGroupDocId: string = null
  private emojiSet: any = 'google'
  private delta: any
  private linkClicked: boolean = false
  private mentionList$ = new BehaviorSubject<TolariaWysiwygMention[]>([])
  
  public editorReady$ = new Subject<boolean>()
  public editor: Quill
  public editorFocused: boolean = false
  public windowFocused: boolean = true
  public mentionOpen: boolean = false
  public readonly: boolean = false
  public toolbarVisible: boolean = false
  public onlyEmojis: boolean = false
  public silentMode: boolean = false
  public isEmpty: boolean = false
  public images$ = new BehaviorSubject<TolariaWysiwygImage[] | null>(null)
  public modules = {}
  public placeholder: string = ''

  constructor(
    private readonly storage: AngularFireStorage,
    private readonly playerNames: PlayerNameService,
    private readonly emojiService: EmojiService,
    private readonly modalService: NgbModal,
    private readonly imagePreview: ImagePreviewService,
    private readonly zone: NgZone,
    private readonly globals: GlobalsService,
  ) {
  }

  private logger(type: string, text: string, data?: any) {
    if (this.debug || type === 'error') {
      if (data) {
        console[type](`[TolariaWysiwygComponent] -> ${text}`, data)
      }
      else {
        console[type](`[TolariaWysiwygComponent] -> ${text}`)
      }
    }
  }
  private handleFocus = () => {
    this.windowFocused = true
  }
  private handleBlur = () => {
    this.windowFocused = false
  }

  ngOnInit(): void {
    this.setPlaceholderText()
    window.addEventListener('focus', this.handleFocus)
    window.addEventListener('blur', this.handleBlur)

    this.modules = {
      // toolbar: toolbar,
      tolariaKeyboardModule: {
        onSend: () => {
          this.logger('log', 'on send')
          this.onDeltaCompleted()
          this.setPlaceholderText()
        },
        onFormatToggle: (format: string, context: any, value: string) => {
          this.logger('log', 'on format toggle', { format, context, value })
          if (format === 'link') {
            this.tolariaLink({})
          }
          else {
            this.toggleFormatting(format, context, value)
          }
        }
      },
      tolariaEmojiTypehead: {
        emojiList: this.emojiService.allEmojis$.getValue(),
        preventDrag: true,
        showTitle: true,
        indicator: ':',
        convertEmoticons: true,
        convertShortNames: true,
        set: () => this.emojiSet,
        onClose: (emoji) => {
          this.logger('log', 'emoji typehead on close', emoji)
        },
        debug: this.debug,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        blotName: 'tolaria-mention',
        mentionDenotationChars: ['@'],
        offsetTop: 8,
        isolateCharacter: true,
        spaceAfterInsert: true,
        dataAttributes: ['id', 'value', 'playerDisplayName', 'denotationChar', 'playerDocId'],
        onOpen: () => this.mentionOpen = true,
        onClose: () => this.mentionOpen = false,
        source: (searchTerm: string, renderList: any, mentionChar: string) => {
          if (searchTerm.length === 0) {
            renderList(this.mentionList$.getValue(), searchTerm)
          }
          else {
            const matches = this.mentionList$.getValue().filter(i => i.playerDisplayName.toLowerCase().includes(searchTerm.toLowerCase()))
            renderList(matches, searchTerm)
          }
        }
      },
      pasteSmart: {
        onImagePaste: (data: any) => {
          this.logger('log', 'pasteSmart -> onImagePaste', data.getAsFile())
          const reader = new FileReader()
          reader.onload = (e) => {
            const imageData = e.target?.result as string
            this.logger('log', 'pasteSmart -> onImagePaste -> base64', imageData)
            this.handleImageDropPaste(imageData, null, null)
          }
          reader.readAsDataURL(data.getAsFile())
        }
      }
    }
    this.logger('log', 'modules constructed', this.modules)
  }

  ngOnDestroy(): void {
    window.removeEventListener('focus', this.handleFocus)
    window.removeEventListener('blur', this.handleBlur)
  }

  private onDeltaCompleted(): void {

    // make sure a message group is defined
    if (this._messageGroupDocId === undefined || this._messageGroupDocId === null || this._messageGroupDocId === '') {
      return
    }

    // emit the delta
    this.deltaComplete.emit({
      // message: this.editor.getText(),
      message: this.editor.getText(),
      html: this.editor.root.innerHTML,
      delta: this.editor.getContents(),
      whisperMode: this.silentMode,
      mentionedPlayers: this.getMentions(),
      images: this.images$.getValue(),
      replyTo: this.replyTo$ ? this.replyTo$.getValue() : null,
    })

    // clear reply to
    this.replyTo$.next(null)
    
    // clear delta
    this.editor.setText('')
    
    // clear images
    this.images$.next(null)
    
    // remove silent mode
    this.silentMode = false

    // focus editor
    setTimeout(() => this.editor.setSelection(0, 0, Quill.sources.USER), 50)

  }

  private getMentions(): string[] {
    return this.editor.getContents().ops.filter(i => i.insert && i.insert['tolaria-mention']).map(i => {
      return i.insert['tolaria-mention'].playerDocId
    })
  }

  private setPlaceholderText(): void {

    // Define placeholders
    let placeholders = [
      'Write your spell here...',
      'Tap here to express yourself...',
      'Summon your thoughts here...',
      'Play your chat card wisely..',
      'Unleash the power of your words...',
      'Draw into the chat zone...',
      'Your message: the rarest of drafts...',
      'Draw one, or draw seven...',
    ]

    // Generate a random index
    const randomIndex = Math.floor(Math.random() * placeholders.length)

    // Set the placeholder
    this.placeholder = placeholders[randomIndex]

  }

  private disableEditor() {
    if (this.editor) {
      this.editor.disable()
    }
    this.editorReady$.next(false)
  }

  private enableEditor() {
    if (this.editor) {
      this.editor.enable()
    }
    this.editorReady$.next(true)
  }

  onEditorCreated(editor) {
    this.logger('log', 'onEditorCreated', editor)
    this.editor = editor
    this.editorReady$.next(true)

    if (this.delta) {
      this.editor.setContents(this.delta)
    }

    this.checkEmptiness()
    this.initCustomHandlers()
  }
  onContentChanged(event) {
    this.logger('log', 'onContentChanged', event)
    this.onlyEmojis = onlyEmojis(this.editor)
    this.checkEmptiness()
  }
  onSelectionChanged(event) {
    this.logger('log', 'onSelectionChanged', event)
    this.checkEmptiness()
  }
  onEditorChanged(event) {
    this.logger('log', 'onEditorChanged', event)
    if (this.linkClicked) {
      this.linkClicked = false
      let operation = this.editor.getFormat()
      let selectionIsLink = operation.hasOwnProperty('link')
      if (selectionIsLink) {
        let text = this.editor.getContents().ops.find(i => i.attributes && i.attributes.link === operation.link).insert as string
        this.tolariaLink({
          edit: true,
          text: text === '' ? operation.link : text,
          url: operation.link,
        })
      }
    }
  }
  onFocus(event) {
    this.logger('log', 'onFocus', event)
    this.globals.disableHotKeys$.next(true)
    this.editorFocused = true
  }
  onBlur(event) {
    this.logger('log', 'onBlur', event)
    this.editorFocused = false
    this.globals.disableHotKeys$.next(false)
  }
  onNativeFocus(event) {
    this.logger('log', 'onNativeFocus', event)
    this.editorFocused = true
  }
  onNativeBlur(event) {
    this.logger('log', 'onNativeBlur', event)
    this.editorFocused = false
  }
  onFooterButtonPress(result: ButtonResult) {
    this.logger('log', 'onFooterButtonPress', result)

    switch (result.action) {
      case 'toggle-format':
        this.toolbarVisible = !this.toolbarVisible
        break

      case 'toggle-silent-mode':
        this.silentMode = !this.silentMode
        break

      case 'insert-card':
        this.onInsertCard()
        break

      case 'insert-deck':
        this.onInsertDeck()
        break

      case 'upload-image':
        this.onInsertImage()
        break

      case 'insert-emoji':
        this.onInsertEmoji(result.data.emoji)
        break

      case 'save-message':
        this.onSaveMessage()
        break

      case 'send-message':
        this.onSendMessage()
        break

      case 'cancel-edit':
        this.readonly = true
        this.cancelEdit.emit(true)
        break

      case 'insert-tourney':
        this.onInsertTourney()
        break

      case 'mention-someone':
        this.editor.getModule('mention').openMenu('@')
        break

    }
  }
  onReplyToClearPress(): void {
    this.replyTo$.next(null)
  }


  private initCustomHandlers(): void {
    const toolbar = this.editor.getModule('toolbar')
    toolbar.addHandler('link', (value: any) => this.tolariaLink({}))
  }
  private toggleFormatting(format: string, context: any, value?: string) {
    this.editor.focus()
    let delta
    if (context === null) {
      let selection = this.editor.getSelection()
      let formatting
      if (selection) {
        formatting = this.editor.getFormat(selection)
      }
      else {
        formatting = this.editor.getFormat()
      }
      context = {
        format: formatting
      }
    }
    if (format === 'list') {
      if (value === 'ordered') {
        if (context.format.list === 'ordered') {
          delta = this.editor.format('list', '', Quill.sources.USER)
        }
        else {
          delta = this.editor.format('list', 'ordered', Quill.sources.USER)
        }
      }
      if (value === 'bullet') {
        if (context.format.list === 'bullet') {
          delta = this.editor.format('list', '', Quill.sources.USER)
        }
        else {
          delta = this.editor.format('list', 'bullet', Quill.sources.USER)
        }
      }
    }
    else {
      delta = this.editor.format(format, !context.format[format], Quill.sources.USER)
    }
  }

  private checkEmptiness(): void {
    this.isEmpty = (this.editor.getText() === '\n' && !this.onlyEmojis) || this._messageGroupDocId === undefined || this._messageGroupDocId === ''
  }

  private onInsertCard(): void {
    const modal = this.modalService.open(CardSearchSelectComponent, {
      centered: true,
      animation: false,
    })
    modal.componentInstance.modal = true
    modal.componentInstance.showResponseSelector = true
    modal.result.then((response: CardResponse)  => {
      let text = `[[${response.card.name.toLowerCase()} | ${response.card.set}]]`
      if (response.rules) {
        text += ` [[?${response.card.name.toLowerCase()}]]`
      }
      this.editor.insertText(this.editor.getSelection(true).index, text)
    })
  }

  private onInsertDeck(): void {
    // handle deck selection here and return deckDocId and deckVersionDocId
    const modalRef = this.modalService
      .open(DeckLinkerComponent, {
        size: 'lg',
        centered: true,
        backdrop: true,
      })
    modalRef.componentInstance.title = 'Select a deck to insert'
    modalRef.componentInstance.selectionOnly = true

    modalRef.result
      .then((result: IDeckLinkerResponse) => {
        if (result) {
          let deckDocId = result.selectedDeck.docId
          let deckVersionDocId: string
          if (result.selectedDeckVersion) {
            deckVersionDocId = result.selectedDeckVersion.versionDocId
          }
          let selection = this.editor.getSelection(true)
          this.editor.insertText(selection.index, '\n')
          this.editor.insertEmbed(selection.index + 1, 'tolaria-deck', { deckDocId, deckVersionDocId })
          this.editor.insertText(selection.index + 2, '\n')
          this.editor.setSelection(selection.index + 3, 0)
        }
      })
  }

  private onInsertTourney(): void {
    this.zone.run(() => {
      const modal = this.modalService.open(TournamentPickerComponent, {
        centered: true,
        animation: false,
      })
      modal.result.then((result: IEventTemplate)  => {
        if (result) {
          let docId = result.docId
          let selection = this.editor.getSelection(true)
          this.editor.insertText(selection.index, '\n')
          this.editor.insertEmbed(selection.index + 1, 'tolaria-tournament', { docId })
          this.editor.insertText(selection.index + 2, '\n')
          this.editor.setSelection(selection.index + 3, 0)
        }
      })
    })
  }

  private onInsertEmoji(emoji: EmojiItem): void {
    this.logger('log', 'onInsertEmoji', emoji)
    const selection = this.editor.getSelection(true)
    const index = selection.index
    this.editor.insertEmbed(index, 'tolaria-emoji', emoji, Quill.sources.USER)
    this.editor.setSelection(index + 1, 0, Quill.sources.USER)
  }

  private onInsertImage(): void {
    if (this._messageGroupDocId === null) {
      this.logger('error', 'onInsertImage -> No message group document id set')
      return
    }
    else {
      this.logger('info', 'onInsertImage')
    }

    const inputElement = document.createElement('input')
    inputElement.type = 'file'
    inputElement.accept = 'image/jpeg, image/jpg, image/png, image/bmp, image/gif'
    inputElement.addEventListener('change', () => {
      const file = inputElement.files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            const base64String = reader.result
            this.logger('info', 'onInsertImage -> result from reader', base64String)
            this.handleImageDropPaste(base64String, null, null)
            inputElement.remove()
          }
        }
        reader.readAsDataURL(file)
      }
    })
    inputElement.click()
  }

  private onSendMessage(): void {
    
    // make sure a message group is defined
    if (this._messageGroupDocId === undefined || this._messageGroupDocId === null || this._messageGroupDocId === '') {
      return
    }

    // emit the delta
    this.deltaComplete.emit({
      // message: this.editor.getText(),
      message: this.editor.getText(),
      html: this.editor.root.innerHTML,
      delta: this.editor.getContents(),
      whisperMode: this.silentMode,
      mentionedPlayers: this.getMentions(),
      images: this.images$.getValue(),
      replyTo: this.replyTo$.getValue(),
    })
    // clear reply to
    this.replyTo$.next(null)
    // clear delta
    this.editor.setText('')
    // clear images
    this.images$.next(null)
    // remove silent mode
    this.silentMode = false
  }

  private onSaveMessage(): void {
    this.readonly = true
    this.deltaComplete.emit({
      message: this.editor.getText(),
      html: this.editor.root.innerHTML,
      delta: this.editor.getContents(),
      whisperMode: this.silentMode,
      mentionedPlayers: this.getMentions(),
      images: this.images$.getValue(),
      replyTo: this.replyTo$ ? this.replyTo$.getValue() : null,
    })
    // clear images
    this.images$.next(null)
  }

  private handleImageDropPaste(imageDataUrl: string, type: any, imageData: any) {
    if (this._messageGroupDocId === null) {
      this.logger('error', 'handleImageDropPaste -> No message group document id set')
      return
    }
    else {
      this.logger('info', 'handleImageDropPaste', { imageDataUrl, type, imageData })
    }

    // create image
    const image: TolariaWysiwygImage = {
      id: uuidv4(),
      downloadUrl: '',
      isUploading: true,
      base64: imageDataUrl,
    }
    if (this.images$.getValue() === null) {
      this.images$.next([image])
    }
    else {
      this.images$.getValue().push(image)
    }
    this.imageUploader(image)
  }

  private imageUploader(image: TolariaWysiwygImage): void {
    if (this._messageGroupDocId === null) {
      this.logger('error', 'imageUploader -> No message group document id set')
      return
    }
    else {
      this.logger('info', 'imageUploader -> ', image)
    }

    const ref = this.storage.ref(`message-attachments/${this._messageGroupDocId}/${image.id}.png`)
    const task = ref.putString(image.base64, 'data_url', {
      customMetadata: {
        playerDocId: this.playerNames.currentPlayersMini.id,
        playerUid: this.playerNames.currentPlayersMini.uid,
      }
    })
    const done$ = new Subject<boolean>()
    this.logger('info', 'imageUploader -> task created', task)
    task
      .snapshotChanges()
      .pipe(
        takeUntil(done$),
        finalize(() => {
          const downloadUrl = ref.getDownloadURL()
          this.logger('info', 'imageUploader -> task finalized, getting download url')
          // store the download url as the avatar link for both user and player doc.
          const downlaodUrlSubscription = downloadUrl.subscribe(async (url: string) => {
            done$.next(true)
            const images = this.images$.getValue()
            let insertedImage = images.find(i => i.id === image.id)
            insertedImage.downloadUrl = url
            insertedImage.isUploading = false
            this.images$.next(images)
            this.logger('info', 'imageUploader -> got download url', url)
            downlaodUrlSubscription.unsubscribe()
          })
        })
      ).subscribe()
  }

  public onDeleteImage(image: TolariaWysiwygImage): void {
    this.logger('info', 'onDeleteImage', image)
    const ref = this.storage.ref(`message-attachments/${this._messageGroupDocId}/${image.id}.png`)
    const task = ref.delete()
    const done$ = new Subject<boolean>()
    this.logger('info', 'onDeleteImage -> task created', task)
    task
      .pipe(
        takeUntil(done$),
        finalize(() => {
          this.logger('info', 'onDeleteImage -> task finalized, removing image locally')
          done$.next(true)
          const images = this.images$.getValue()
          let newImages = images.filter(i => i.id !== image.id)
          if (newImages.length > 0) {
            this.images$.next(newImages)
          }
          else {
            this.images$.next(null)
          }
          this.logger('info', 'onDeleteImage -> image deleted')
        })
      ).subscribe()
  }

  private tolariaLink(options: {
    edit?: boolean,
    text?: string,
    url?: string,
  }): void {

    const selection = this.editor.getSelection(true)
    const text = options.text ? options.text : this.editor.getText(this.editor.getSelection().index, this.editor.getSelection().length)
    
    this.editor.blur()
    
    this.zone.run(() => {

      const modal = this.modalService.open(TolariaWysiwygInsertLinkComponent, {
        centered: true,
        size: 'md',
        backdrop: 'static',
        animation: false,
      })
  
      
      // remove linked clicked flag
      this.linkClicked = false
      modal.componentInstance.text = text
      modal.componentInstance.url = options.url ? options.url : ''
      modal.componentInstance.edit = options.edit ? options.edit : false
      modal.componentInstance.retain = text.replace(/[\s\n]/g, '') !== ''
      modal.result
        .then(
          (data) => {
            
            if (data.remove) {
              this.editor.removeFormat(selection.index, selection.length)
              // this.editor.setSelection(selection.index - selection.length, 0)
              this.editor.focus()
              return
            }
            if (data.retain) {
              let retainAfter = this.editor.getLength() - (selection.index + selection.length)
              this.editor.updateContents(new Delta()
                .retain(selection.index)
                .delete(selection.length)
                .insert(data.text)
                .retain(retainAfter)
              )
              this.editor.formatText(selection.index, selection.length, 'link', data.url, 'silent')
              // this.editor.setSelection(selection.index + selection.length, 0)
              this.editor.focus()
              return
            }
            this.editor.insertText(selection.index, data.text, 'link', data.url, 'silent')
            // this.editor.setSelection(selection.index + data.text.length, 0)
            this.editor.focus()
          },
          () => {
            this.editor.setSelection(selection)
            this.editor.focus()
          }
        )
        
    })
    
  }
  private handleLinkClicked(event: any): void {
    // upward traverser
    const __findAncestorWithContentEditable = (element: HTMLElement) => {
      while (element) {
        element = element.parentElement;
        if (element && element.getAttribute('contentEditable') === 'true') {
          return element
        }
      }
      return null
    }
    // create flag for content editable
    let contentEditable = false
    // check if clicked target has content editable set to true
    if (event.target.contentEditable === 'true' || event.target.contentEditable === true) {
      contentEditable = true
    }
    // if inherited, check if any ancestor has it set to true
    else if (event.target.contentEditable === 'inherit') {
      const ancestor = __findAncestorWithContentEditable(event.target)
      if (ancestor) {
        contentEditable = true
      }
    }
    // if target element or an ancester has content editable, mark flag linkClicked as true
    if (contentEditable) {
      const range = document.createRange()
      range.selectNodeContents(event.target)
      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      this.linkClicked = true
    }
  }

  public get imageUploadEnabled(): boolean {
    return this._messageGroupDocId !== null
  }

  public icon = {
    times: faTimes,
  }

  public get disableSend(): boolean {
    return this._messageGroupDocId === undefined || this._messageGroupDocId === null || this._messageGroupDocId === ''
  }

}

export interface TolariaWysiwygImage {
  base64: string
  downloadUrl: string
  id: string
  isUploading: boolean
}

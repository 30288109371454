import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { faUsers, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { IEventStructureSettings, IFormat } from 'tolaria-cloud-functions/src/_interfaces';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { tiPlayoffBracket } from 'src/assets/font-icons/playoff-bracket';

export interface IStructure {
  name: 'Swiss' | 'Group' | 'Batch' | 'Bracket' | 'Round Robin'
  description: string
}
export interface IStructureSetting {
  title: string
  description: string
  icon: IconDefinition
  property: string
  value: boolean | number
  type: 'switch' | 'input' | 'radio'
}

@Injectable({
  providedIn: 'root'
})
export class TournamentConfigService {

  private formats$: BehaviorSubject<IFormat[]> = new BehaviorSubject<IFormat[]>(null)

  constructor(
    private readonly firestore: AngularFirestore,
  ) {
    this.init()
  }

  private async init() {

    const formats = await firstValueFrom(this.firestore.collection<IFormat>('formats').get())
    if (formats.empty) {
      this.formats$.next([])
    }
    else {
      this.formats$.next(formats.docs.map(i => i.data()))
    }

  }



  public get formats(): IFormat[] {
    if (this.formats$.getValue() === null) {
      return []
    }
    return this.formats$.getValue()
  }

  public getEventStructure(structure: IEventStructureSettings): IStructure {
    if (structure.isSwiss) {
      return this.structures.find(i => i.name === 'Swiss')
    }
    if (structure.isGroup) {
      return this.structures.find(i => i.name === 'Group')
    }
    if (structure.isBatch) {
      return this.structures.find(i => i.name === 'Batch')
    }
    if (structure.isRoundRobin) {
      return this.structures.find(i => i.name === 'Round Robin')
    }
    if (structure.isBracket) {
      return this.structures.find(i => i.name === 'Bracket')
    }
  }

  public getStructureConfig(structure: IEventStructureSettings): IStructureSetting[] {
    if (structure.isSwiss) {
      return [
        {
          title: 'Playoff after swiss rounds',
          description: `After the swiss rounds, you can configure a single elimination playoff bracket.`,
          icon: tiPlayoffBracket,
          property: 'hasBracketAfterSwiss',
          value: structure.swiss.hasBracketAfterSwiss,
          type: 'switch',
        },
        {
          title: 'Teams',
          description: `After the swiss rounds, you can configure a single elimination playoff bracket.`,
          icon: faUsers,
          property: 'teams',
          value: structure.swiss.teams ? structure.swiss.teams : false,
          type: 'switch',
        },
      ]
    }
    if (structure.isGroup) {
      return [
        {
          title: 'Playoff after group stage',
          description: `After the group stage has finished, you can configure a single elimination playoff bracket.`,
          icon: tiPlayoffBracket,
          property: 'hasBracketAfterGroupStage',
          value: structure.group.hasBracketAfterGroupStage,
          type: 'switch',
        },
      ]
    }
    if (structure.isBatch) {
      return [
        {
          title: 'Playoff after batch stage',
          description: `After the batch stage has finished, you can configure a single elimination playoff bracket.`,
          icon: tiPlayoffBracket,
          property: 'hasBracketAfterBatch',
          value: structure.batch.hasBracketAfterBatch,
          type: 'switch',
        },
      ]
    }
    if (structure.isRoundRobin) {
      return []
    }
    if (structure.isBracket) {
      return [
        {
          title: 'Single elimination',
          description: `Pair players for a single elimination bracket.`,
          icon: tiPlayoffBracket,
          property: 'singleElimination',
          value: structure.bracket.singleElimination,
          type: 'radio',
        },
        {
          title: 'Single elimination',
          description: `Pair players for a single elimination bracket.`,
          icon: tiPlayoffBracket,
          property: 'doubleElimination',
          value: structure.bracket.doubleElimination,
          type: 'radio',
        },
      ]
    }

    return []
  }

  public get structureNames(): string[] {
    return this.structures.map(i => i.name)
  }

  public get structures(): IStructure[] {
    return [
      {
        name: 'Swiss',
        description: `A Swiss-system tournament is a non-eliminating tournament format that features a fixed number of rounds of competition, 
        but considerably fewer than for a round-robin tournament; thus each competitor (team or individual) does not play all the other competitors. 
        Competitors meet one-on-one in each round and are paired using a set of rules designed to ensure that each competitor plays opponents with 
        a similar running score, but does not play the same opponent more than once. The winner is the competitor with the highest aggregated points 
        earned in all rounds. With an even number of participants, all competitors play in each round.`,
      },
      {
        name: 'Group',
        description: `A group stage (also known as pool play or the pool stage) is a round-robin stage in a multi-stage tournament. 
        The competitors are divided into multiple groups, which play separate round-robins in parallel. 
        Measured by a points-based ranking system, the top competitors in each group qualify for the next stage.`,
      },
      {
        name: 'Batch',
        description: `With a batch tournament, you will create groups or segments of matches either by random pairings or by standings.
        Players will be paired to a different opponent in each batch and segment. Dropping will ONLY be possible for the players after
        all matches within the current bacth have been played out.`,
      },
      {
        name: 'Bracket',
        description: 'A regular bracket system where players will advance forward to the next round by winning.',
      },
      {
        name: 'Round Robin',
        description: 'Each player will be paired against all other player. Suitable for smaller events with less than 7 players.'
      }
    ]
  }



}

import { DialogsService } from 'src/app/services';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dialogs',
  templateUrl: './dialogs.component.html',
  styleUrls: ['./dialogs.component.css']
})
export class DialogsComponent {

  constructor(
    public dialogs: DialogsService
  ) { }

  onButtonPressed(value: string) {
    console.log('a dialog button was pressed', value);
    this.dialogs.buttonPressed = true;
    this.dialogs.response = value;
  }

}

<ng-container *ngIf="event$ | async as event">

  <div class="modal-header d-flex align-items-center">
    <span class="flex-grow-1 text-large">Create new tournament event</span>
    <button class="btn-close" (click)="onCancel()"></button>
  </div>


  <div class="modal-body">

    
    <app-event-document-form
      [eventDocument]="event"
      [showValidationInForm]="true"
      (documentChanged)="onEventDocumentFormChanged($event)">
    </app-event-document-form>

    
  </div>


  <div class="modal-footer d-flex align-item-center justify-content-end">

    <button class="btn btn-sm btn-blank" (click)="onCancel()">Cancel</button>

    <button class="btn btn-sm btn-main" (click)="onCreate()" [disabled]="formInvalid">Create</button>

  </div>

</ng-container>
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const tiDoubleElimination: IconDefinition = {
  prefix: 'fac',
  iconName: 'doubleElimination',
  icon: [
    512, // SVG view box width
    397.71, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M151.74,212.05c0-77.26,57.84-142.57,137.33-164C258.66,18.53,214.93,0,166.33,0,74.47,0,0,66.2,0,147.85c0,53.74,32.6,100.34,80.85,126.23L74.53,304c-3.09,14.57,6.26,28.7,19,28.7H207.9C173.19,301.69,151.74,259.08,151.74,212.05Zm-50.09-8.76a37,37,0,1,1,37-37A37,37,0,0,1,101.65,203.29ZM260.19,339.12,253.86,369c-3.08,14.57,6.27,28.7,19,28.7H418.5c12.74,0,22.08-14.13,19-28.7l-6.35-29.89C479.4,313.24,512,266.64,512,212.9c0-81.65-74.47-147.85-166.33-147.85S179.34,131.25,179.34,212.9C179.34,266.64,211.94,313.24,260.19,339.12Zm150.16-144.7a37,37,0,1,1-37,37A37,37,0,0,1,410.35,194.42Zm-129.36,0a37,37,0,1,1-37,37A37,37,0,0,1,281,194.42Z'
  ],
} as any;

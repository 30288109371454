<div class="card border-secondary border-opacity-50 rounded position-relative" [style.minWidth.%]="100">
  <div class="card-header text-larger">Tolaria Cost & Support</div>
  <div class="card-body position-relative no-hover d-flex flex-column gap-2 overflow-x-hidden scrollable" [style.minHeight.rem]="25" [style.maxHeight.rem]="25">
    <div class="text-small text-secondary">Latest supporters</div>
    <ng-container *ngIf="latestSupport$ | async as latest">
      <div class="d-flex align-items-center" *ngFor="let item of latest">
        <div class="text-large col-6 text-truncate">
          <app-player-display-name [playerDocId]="item.playerDocId" [ellipse]="true" name="Tolarian Hero"></app-player-display-name>
        </div>
        <div class="m-0 p-0 px-2 text-small text-truncate alert alert-{{ item.style }} col-3" role="alert">
          {{ item.type }}
        </div>
        <div class="d-flex flex-column align-items-end col-3" [style.minWidth.rem]="6">
          <span class="">{{ item.amount }}</span>
          <app-timestamp-clock class="text-smaller text-secondary" [timestamp]="item.timestamp" [seconds]="true" displayType="mini-date"></app-timestamp-clock>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="card-footer d-flex flex-column gap-2 px-3">
    <div class="d-flex align-items-center text-large">
      <span class="col-6">Period</span>
      <span class="col-3 text-end">Costs</span>
      <span class="col-3 text-end">Support</span>
    </div>
    <ng-container *ngIf="totals$ | async as totals">
      <div class="d-flex align-items-center" *ngFor="let item of totals">
        <span class="col-6 d-flex align-items-center gap-2">
          <span>{{ item.text }}</span>
          <fa-icon [icon]="icon.info" class="text-info cursor-pointer" (click)="showDetails(item)"></fa-icon>
        </span>
        <span class="col-3 text-end">{{ item.cost }}</span>
        <span class="col-3 text-end">{{ item.support }}</span>
      </div>
    </ng-container>
  </div>
  <div class="heroship position-absolute bottom-0 end-0" [ngClass]="{ 'user-is-hero' : isHero }">
    <div class="speech-bubble d-flex flex-column gap-3">
      <span>Why don't you become a hero like all these fine people?</span>
      <span>Start subscribing today!</span>
      <app-support-button style="transform: rotate(-3.4deg); align-self: center;"></app-support-button>
    </div>
  </div>
</div>

<ng-template #itemDetails let-modal>
	<div class="modal-header p-4">
		<h4 class="modal-title" id="modal-basic-title">Cost details</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body p-4" *ngIf="details$ | async as data">
		<div class="d-flex align-items-center py-1" *ngFor="let item of data.details">
      <span class="col-9 d-flex align-items-center justify-content-between gap-2">
        <span>{{ item.data.description }}</span>
        <span class="alert alert-info m-0 p-0 px-1 text-small">{{ item.data.type }}</span>
      </span>
      <span class="col-3 text-end">{{ item.amount }}</span>
    </div>
	</div>
	<div class="modal-footer px-4">
		<button type="button" class="btn btn-blank" (click)="modal.close('Save click')">Close</button>
	</div>
</ng-template>
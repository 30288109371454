<div class="card card-large no-hover mb-2 d-flex flex-column overflow-hidden"
  [ngClass]="{ 'mobile' : globals.isMobile, 'min-height-100' : !globals.isMobile }">

  <div class="btn btn-sm btn-blank close-button mobile" *ngIf="globals.isMobile" (click)="close()">
    <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>
  </div>

  <div class="card-header no-hover flex-grow-0">

    <div class="h5 text-center">
      <app-player-display-name [playerUid]="playerDetails.playerUid" [name]="playerDetails.name" position="left"
        *ngIf="!isTempPlayer; else temp"></app-player-display-name>
      <ng-template #temp>
        <span>{{ playerDetails.name }}</span>
      </ng-template>
    </div>

    <div class="row p-3">
      <div class="col text-center">
        <div class="score-wrapper">
          {{ playerDetails.wins }} - {{ playerDetails.draws }} - {{ playerDetails.losses }}
        </div>
      </div>
    </div>

    <div class="row stats pb-3">
      <div class="col">
        <div class="stat-wrapper">
          <div class="stat-value">
            {{ getPercentage(playerDetails.opponentMatchWinPercentage) }}
          </div>
          <div class="stat-name text-secondary">
            OMW%
          </div>
        </div>
      </div>
      <div class="col">
        <div class="stat-wrapper">
          <div class="stat-value">
            {{ getPercentage(playerDetails.gameWinPercentage) }}
          </div>
          <div class="stat-name text-secondary">
            GW%
          </div>
        </div>
      </div>
      <div class="col">
        <div class="stat-wrapper">
          <div class="stat-value">
            {{ getPercentage(playerDetails.opponentGameWinPercentage) }}
          </div>
          <div class="stat-name text-secondary">
            OGW%
          </div>
        </div>
      </div>
    </div>
    <!-- PLAYER STATUS -->
    <div class="text-center mb-2 mt-2">
      <div class="btn {{ playerDetails.dropped ? 'btn-danger' : 'btn-success' }} text-center" style="min-width: 15rem;">
        {{ playerDetails.dropped ? 'DROPPED' : 'ACTIVE' }}</div>
    </div>
  </div>

  <ng-container *ngIf="globals.isMobile; else desktopView">
    <div class="flex-grow-1 overflow-hidden overflow-y-auto p-3">
      <app-event-player-matches [eventDocId]="eventData.docId" [playerDocId]="playerDetails.playerDocId">
      </app-event-player-matches>
    </div>
  </ng-container>
  <ng-template #desktopView>
    <div class="card-body scrollable no-hover">
      <div class="row">
        <div class="col">
          <app-event-player-matches [eventDocId]="eventData.docId" [playerDocId]="playerDetails.playerDocId">
          </app-event-player-matches>
        </div>
      </div>
    </div>
  </ng-template>
</div>

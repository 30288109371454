import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DecksService, IDeckList } from 'src/app/services/decks.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { DeckListParserPipe } from 'src/app/pipes/deck-list-parser.pipe';

@Component({
  selector: 'app-deck-list-importer',
  templateUrl: './deck-list-importer.component.html',
  styleUrls: ['./deck-list-importer.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    DeckListParserPipe,
  ]
})
export class DeckListImporterComponent {
  @Input()
  set deckList(deck: IDeckList) {
    console.log('deckList emitted new value', deck);
    this.deckListParsed = JSON.parse(JSON.stringify(deck));
  }
  @Input()
  set toggleHelp(val: boolean) {
    this.showDeckRegistrationHelp = val;
  }
  @Input() modal: boolean = true;
  @Input() showDeckPreviewHeader: boolean = true;
  @Input() showCardPreview: boolean = true;
  @Input() showInputHeader: boolean = true;
  @Output() deckListOutput = new EventEmitter();


  public showDeckRegistrationHelp = true;
  public deckListParsed: IDeckList;
  public notFoundCards: Array<string> = [];
  public cardPreviewUrl: string = '';
  public cardPreviewSetCode: string = '';

  // font icons
  faQuestionCircle = faQuestionCircle;

  constructor(
    public globals: GlobalsService,
    public activeModal: NgbActiveModal,
    private deckService: DecksService,
  ) { }

  private sendOutput() {
    this.deckListOutput.emit(this.deckListParsed);
  }

  public async parseDeckList() {
    // update global status busy and show the loader
    this.globals.isBusy.status = true;
    this.globals.isBusy.showMessage = true;
    this.globals.isBusy.message = 'Fetching card data, please wait...';

    this.deckService.parseDeckList(this.deckListParsed).then((res: IPromiseResponse) => {
      // update the text
      this.globals.isBusy.message = res.text;

      if (res.status) {
        // write the temporary decklist as the new decklist
        this.deckListParsed = res.data.deckList;
        // update Not found cards
        this.notFoundCards = res.data.cardsNotFound;
      }

      setTimeout(() => {
        // close the loader and set busy to false
        this.globals.isBusy.status = false;
        this.globals.isBusy.showMessage = false;
      }, res.status ? 1500 : 4000);

      // turn off help
      this.showDeckRegistrationHelp = false;

      // send output
      this.sendOutput();
    });

  }

  public importDeckList() {
    this.activeModal.close(this.deckListParsed);
  }

  public get saveDeckListDisabled() {
    // main deck less than 60 cards
    if (this.deckListParsed.main === undefined || this.deckListParsed.main.length < 60) {
      return true;
    }
    // sideboard more than 15 cards
    if (this.deckListParsed.sideboard === undefined || this.deckListParsed.sideboard.length > 15) {
      return true;
    }
    // unmatched cards
    if (this.notFoundCards === undefined || this.notFoundCards.length > 0) {
      return true;
    }
    return false;
  }


}

export function canSendMessage(quill: any): boolean {
    const textContent = quill.getContents().filter(i => typeof i.insert === 'string')
    const emojiContent = quill.getContents().filter(i => i.insert.hasOwnProperty('tolaria-emoji'))
    const nonTextOrEmojiContent = quill.getContents().filter(i => typeof i.insert !== 'string' && !i.insert.hasOwnProperty('tolaria-emoji'))
    const textOnlySpaces = textContent.map(i => i.insert).join('').replace(/ /g, '') === '\n'
    const hasEmojis = quill.getContents().filter(i => i.insert.hasOwnProperty('tolaria-emoji')).length > 0
    const hasNonEmojiOrTextContent = nonTextOrEmojiContent.length > 0
    const onlyEmojis = hasEmojis && textOnlySpaces && hasNonEmojiOrTextContent === false && emojiContent.length <= 20
    return onlyEmojis || !textOnlySpaces
}

export function onlyEmojis(quill: any): boolean {
    const textContent = quill.getContents().filter(i => typeof i.insert === 'string')
    const emojiContent = quill.getContents().filter(i => i.insert.hasOwnProperty('tolaria-emoji'))
    const nonTextOrEmojiContent = quill.getContents().filter(i => typeof i.insert !== 'string' && !i.insert.hasOwnProperty('tolaria-emoji'))
    const textOnlySpaces = textContent.map(i => i.insert).join('').replace(/ /g, '') === '\n'
    const hasEmojis = quill.getContents().filter(i => i.insert.hasOwnProperty('tolaria-emoji')).length > 0
    const hasNonEmojiOrTextContent = nonTextOrEmojiContent.length > 0
    return hasEmojis && textOnlySpaces && hasNonEmojiOrTextContent === false && emojiContent.length <= 20
}

export function deltaOnlyEmojis(operations: any): boolean {
    const textContent = operations.filter(i => typeof i.insert === 'string')
    const emojiContent = operations.filter(i => i.insert.hasOwnProperty('tolaria-emoji'))
    const nonTextOrEmojiContent = operations.filter(i => typeof i.insert !== 'string' && !i.insert.hasOwnProperty('tolaria-emoji'))
    const textOnlySpaces = textContent.map(i => i.insert).join('').replace(/ /g, '') === '\n'
    const hasEmojis = operations.filter(i => i.insert.hasOwnProperty('tolaria-emoji')).length > 0
    const hasNonEmojiOrTextContent = nonTextOrEmojiContent.length > 0
    return hasEmojis && textOnlySpaces && hasNonEmojiOrTextContent === false && emojiContent.length <= 20
}
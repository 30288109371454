<div class="container d-flex flex-column p-3" *ngIf="setList$ | async as sets">

  <!-- <div class="search-field mb-3">

    <input type="text" class="form-control" [(ngModel)]="filterValue" placeholder="Filter by set name">

  </div> -->

  <div class="mt-2 mb-2 text-secondary text-center">Core Sets</div>
  <div class="set-list d-flex flex-wrap justify-content-center">

    <div class="set-selector btn btn-blank" [ngClass]="{ 'selected' : set.selected }"
      *ngFor="let set of sets | sets : { isCore: true }"
      (click)="multiSelect ? set.selected = !set.selected : close(set)" [ngbTooltip]="set.name">
      <i class="ss ss-fw ss-2x ss-{{ set.keyruneCode.toLowerCase() }}"></i>
    </div>

  </div>

  <div class="mt-5 mb-2 text-secondary text-center">Expansions</div>
  <div class="set-list d-flex flex-wrap justify-content-center">
    <ng-container *ngFor="let set of sets | sets : { isExpansion: true }">
      <div class="set-selector btn btn-blank" [ngClass]="{ 'selected' : set.selected }"
        (click)="multiSelect ? set.selected = !set.selected : close(set)" [ngbTooltip]="set.name" *ngIf="set.boosterSize !== null">
        <i class="ss ss-fw ss-2x ss-{{ set.keyruneCode.toLowerCase() }}"></i>
      </div>
    </ng-container>

  </div>

  <ng-container *ngIf="!multiSelect">
    <div class="mt-5 mb-3 text-secondary text-center">Others</div>
    <div class="set-list d-flex flex-wrap justify-content-center">

      <div class="set-selector btn btn-blank" [ngClass]="{ 'selected' : set.selected }"
        *ngFor="let set of sets | sets : { isCustom: true, draftAble: true }"
        (click)="multiSelect ? set.selected = !set.selected : close(set)" [ngbTooltip]="set.name">
        <ng-container *ngIf="set.customIcon; else keyruneIcon">
          <fa-icon [icon]="fontIcons.setIcons[set.keyruneCode]" [fixedWidth]="true" style="font-size: 2em; line-height: 1em;"></fa-icon>
        </ng-container>
        <ng-template #keyruneIcon>
          <i class="ss ss-fw ss-2x ss-{{ set.keyruneCode.toLowerCase() }}"></i>
        </ng-template>
      </div>

    </div>
  </ng-container>

  <ng-container *ngIf="multiSelect">
    <div class="mt-5 mb-3 text-secondary text-center">Others</div>
    <div class="set-list d-flex flex-wrap justify-content-center">

      <div class="set-selector btn btn-blank" [ngClass]="{ 'selected' : set.selected }"
        *ngFor="let set of sets | sets : { isCustom: true }"
        (click)="multiSelect ? set.selected = !set.selected : close(set)" [ngbTooltip]="set.name">
        <ng-container *ngIf="set.customIcon; else keyruneIcon">
          <fa-icon [icon]="fontIcons.setIcons[set.keyruneCode]" [fixedWidth]="true" style="font-size: 2em; line-height: 1em;"></fa-icon>
        </ng-container>
        <ng-template #keyruneIcon>
          <i class="ss ss-fw ss-2x ss-{{ set.keyruneCode.toLowerCase() }}"></i>
        </ng-template>
      </div>

    </div>
  </ng-container>

  <div class="mt-2 text-center" *ngIf="multiSelect">
    <div class="btn btn-blank" (click)="closeMultiSelect(sets)">Add selected set{{ getMultipleSetsSelected(sets) ? 's' : '' }}</div>
  </div>

</div>

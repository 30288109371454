import { applicationIcon } from 'src/assets/font-icons/tolaria-icon';
import { GlobalsService } from 'src/app/services/globals.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faChevronRight, faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Component, Input } from '@angular/core';
import { IEventPlayerDetails, IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-drop-confirmation',
  templateUrl: './drop-confirmation.component.html',
  styleUrls: ['./drop-confirmation.component.css']
})
export class DropConfirmationComponent {
  @Input() player: IEventPlayerDetails
  @Input() event: IEventDetails

  public exchangePlayer: boolean = false
  public tolariaSearch: boolean = false
  public playerDocIdBeingAdded: string = ''

  faChevronRight = faChevronRight
  faQuestion = faQuestion
  faTimes = faTimes
  appIcon = applicationIcon

  constructor(
    public modal: NgbActiveModal,
    public globals: GlobalsService
  ) { }

  public get isOnlineTournament(): boolean {
    return this.event.details.isOnlineTournament
  }

  public get eventType(): 'group' | 'swiss' | 'round-robin' | 'batch' | 'bracket' {
    if (this.event.details.structure.isGroup) return 'group'
    if (this.event.details.structure.isSwiss) return 'swiss'
    if (this.event.details.structure.isRoundRobin) return 'round-robin'
    if (this.event.details.structure.isBatch) return 'batch'
    if (this.event.details.structure.isBracket) return 'bracket'

    return null
  }

  public setExchangePlayer(playerDocId: string): void {
    this.tolariaSearch = true
    this.playerDocIdBeingAdded = playerDocId
  }

  public close(): void {
    this.modal.close({
      exchangePlayer: this.exchangePlayer,
      playerDocIdBeingAdded: this.playerDocIdBeingAdded,
      tolariaSearch: this.tolariaSearch,
      eventType: this.eventType
    })
  }

}

import { GlobalsService } from 'src/app/services/globals.service'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faBan, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { filter, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable, Subject, BehaviorSubject, combineLatest } from 'rxjs'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core'
import { ConstraintsLevel, MediaStreamsService } from 'src/app/services/media-streams.service'
import { WebRtcHelperService } from 'src/app/components/events/match-room/webrtc-helper.service'
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database'
import { AuthService, IPlayerLink } from 'src/app/services'
import * as firestore from 'firebase/firestore'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { IOnlineUser } from 'src/app/components'
import { IPub, IOnlinePubPlayer } from '../pubs.service'
import { PlayerNameService } from 'src/app/services/players/player-name.service'
import { tolCrossingSwords } from 'src/assets/font-icons/crossing-swords-icon'


interface IWebrtcUser {
  webRtcPeerId: string
  playerDocId: string
  playerUid: string
  mediaStream: MediaStream
  videoMuted: boolean
  audioMuted: boolean
}

@Component({
  selector: 'app-pub',
  templateUrl: './pub.component.html',
  styleUrls: ['./pub.component.css']
})
export class PubComponent implements OnInit, OnDestroy {

  public pub$: BehaviorSubject<IPub> = new BehaviorSubject<IPub>(null)
  private _pub$: Observable<IPub>
  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()

  private playerDocInitialized$ = new BehaviorSubject<boolean>(false)
  private readyToConnect$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private dbRef: AngularFireList<IOnlineUser>
  public activeUsersInRoom$: Observable<IOnlinePubPlayer[]>
  private webRtcInitialized$: Observable<boolean> = this.webRtcHelper.initialized$.pipe(
    filter((isInitialized) => isInitialized),
    tap(() => console.log('WebRTC is INITIALIZED'))
  )
  public localStream$: Observable<MediaStream> = this.streamService.localStream$.pipe(
    takeUntil(this.componentDestroyed$),
    filter((stream: MediaStream) => stream !== undefined),
  )
  public activeStreams = new Map<string, MediaStream>()

  public icons = {
    challenge: tolCrossingSwords,
    cancel: faBan,
    leave: faSignOutAlt,
  }

  private lifeUpdateTimer: any
  private lifeUpdate = 0

  constructor(
    private readonly streamService: MediaStreamsService,
    private readonly webRtcHelper: WebRtcHelperService,
    private readonly playerNames: PlayerNameService,
    private afs: AngularFirestore,
    private afDb: AngularFireDatabase,
    private afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private auth: AuthService,
    private globals: GlobalsService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {

    console.log('init done')

    this.route.params.pipe(takeUntil(this.componentDestroyed$)).subscribe((params: any) => {
      this._pub$ = this.afs.collection<IPub>('pubs').doc(params.pubDocId).valueChanges()
    })

    this._pub$.subscribe(p => {
      if (p !== null) {
        this.pub$.next(p)

        // check if battle is active and if player is participating
        if (p.battle.status === 'active' && p.battle.challengerDocId === this.myPlayerId) {
          this.lifeUpdate = p.battle.challengerStats.life[0]
        }
        if (p.battle.status === 'active' && p.battle.opponentDocId === this.myPlayerId) {
          this.lifeUpdate = p.battle.opponentStats.life[0]
        }

        // update ready to connect ONCE when the first emit happens
        if (!this.readyToConnect$.getValue()) { this.readyToConnect$.next(true) }
      }
    })

    // Trigger ONCE when player is attending and draft document is emitted
    this.readyToConnect$.subscribe((ready) => {
      if (ready) {
        // set timer
        let timeout = 0
        console.log('isAttending$ emitted.')
        // create peer if needed
        if (this.webRtcHelper.peerId === undefined || this.webRtcHelper.peerId === null) {
          this.webRtcHelper.initPeer()
          timeout = 1000
        }
        // init database and player
        setTimeout(() => {
          this.initDatabaseObservable()
          this.initPlayer()
        }, timeout)
      }
    })

  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)

    // remove presence
    this.afDb.object(`pubPresence/${this.pub$.getValue().docId}/onlineUsers/${this.auth.user.playerId}`).remove()

    // destroy peer
    this.webRtcHelper.destroy()

    // kill stream
    this.streamService.killLocalStream()
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.globals.hotkeysEnabled && this.isBattling) {
      if (event.key === 'ArrowDown' && event.shiftKey) {
        this.updateLife(-5)
      }
      else if (event.key === 'ArrowDown') {
        this.updateLife(-1)
      }
      else if (event.key === 'ArrowUp' && event.shiftKey) {
        this.updateLife(5)
      }
      else if (event.key === 'ArrowUp') {
        this.updateLife(1)
      }
      else if (event.key === 'c') {
        this.updateLife(20, true)
      }
      else if (event.key === 'g') {
        this.updateGameWin(true)
      }
      else if (event.key === 'h') {
        this.updateGameWin(false)
      }
    }
  }

  public trackByPlayerDocId(index: number, player: IOnlinePubPlayer): string {
    return player.playerDocId
  }
  public getPlayerStream(playerDocId: string): any {
    const stream = this.activeStreams.get(playerDocId)
    const resp = {
      stream,
    }
    return resp
  }

  public leave(): void {
    // remove presence
    this.afDb.object(`pubPresence/${this.pub$.getValue().docId}/onlineUsers/${this.auth.user.playerId}`).remove()

    // destroy peer
    this.webRtcHelper.destroy()

    // kill stream
    this.streamService.killLocalStream()

    setTimeout(() => {
      this.router.navigate(['/pubs'])
    }, 1000)
  }

  public get imageUri(): string {
    return `url(${this.pub$.getValue().imageUri})`
  }
  public get myPlayerId(): string {
    return this.auth.user.playerId
  }
  public get challengeTooltip(): string {
    if (this.pub$.getValue().battle.status === 'active' && this.pub$.getValue().battle.challengerDocId === this.myPlayerId) {
      return 'Close battle'
    }
    if (this.pub$.getValue().battle.status === 'pending' && this.pub$.getValue().battle.challengerDocId === this.myPlayerId) {
      return 'Cancel or accept the current announcements'
    }
    return 'Announce that you want to play'
  }
  public get disableChallenge(): boolean {
    if (this.pub$.getValue().battle.status === 'active' && this.pub$.getValue().battle.challengerDocId === this.myPlayerId) {
      return false
    }
    if (this.pub$.getValue().battle.status === 'active' && this.pub$.getValue().battle.opponentDocId === this.myPlayerId) {
      return false
    }
    if (this.pub$.getValue().battle.status === 'none') {
      return false
    }
    return true
  }
  public get challengeIcon(): IconDefinition {
    if (
      this.pub$.getValue().battle.status === 'active' && this.pub$.getValue().battle.challengerDocId === this.myPlayerId ||
      this.pub$.getValue().battle.status === 'active' && this.pub$.getValue().battle.opponentDocId === this.myPlayerId
    ) {
      return this.icons.cancel
    }
    return this.icons.challenge
  }
  public get challengerLife(): number {
    return this.pub$.getValue().battle.challengerDocId === this.myPlayerId ? this.lifeUpdate : this.pub$.getValue().battle.challengerStats.life[0]
  }
  public get opponentLife(): number {
    return this.pub$.getValue().battle.opponentDocId === this.myPlayerId ? this.lifeUpdate : this.pub$.getValue().battle.opponentStats.life[0]
  }
  public get isBattling(): boolean {
    return this.pub$.getValue().battle.status === 'active' && this.pub$.getValue().battle.challengerDocId === this.myPlayerId ||
           this.pub$.getValue().battle.status === 'active' && this.pub$.getValue().battle.opponentDocId === this.myPlayerId
  }
  public get challengerGameWins(): number[] {
    return [...Array(this.pub$.getValue().battle.challengerStats.wins).keys()]
  }
  public get opponentGameWins(): number[] {
    return [...Array(this.pub$.getValue().battle.opponentStats.wins).keys()]
  }

  // Initializations
  private initDatabaseObservable = () => {
    console.log('init database observables')
    this.dbRef = this.afDb.list(`pubPresence/${this.pub$.getValue().docId}/onlineUsers`)
    // Start Observing the online users to be able to view a list of spectators
    const activeUsers$ = this.dbRef.valueChanges()
    this.activeUsersInRoom$ = combineLatest([activeUsers$, this.pub$]).pipe(
      tap(([onlineUsers, pubDoc]) => console.log('[TESTING] activeUsersInRoom emitted', onlineUsers, pubDoc)),
      map(([onlineUsers, pubDoc]) => {
        const presentPlayers: IOnlinePubPlayer[] = []

        onlineUsers.forEach((user) => {
          presentPlayers.push({
            online: user.online,
            playerDocId: user.playerDocId,
            playerUid: user.playerUid,
            timestamp: user.timestamp,
            webRtcPeerId: user.webRtcPeerId,
            callerRole: user.callerRole,
            displayName: user.displayName,
            volume: 10,
            muted: false,
          })
        })

        return presentPlayers

      })
    )
  }
  private initDatabaseEventListeners = () => {
    // EventListener: Player is entering the room
    this.dbRef
      .stateChanges(['child_added'])
      .pipe(
        filter(user => user.key !== this.auth.user.playerId),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(action => {
        const user = action.payload.val()
        console.log('user joined the room', user)

        // call user if not self
        this.webRtcHelper.callUserWithId(user.webRtcPeerId, null, user.callerRole)
      })
    // EventListener: Player is leaving the room
    this.dbRef
      .stateChanges(['child_removed'])
      .pipe(
        filter(user => user.key !== this.auth.user.playerId),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe(action => {
        const user = action.payload.val()
        console.log('user left the room', user)

        // hang up on user
        this.webRtcHelper.hangupOnCall(user.webRtcPeerId)
      })

  }
  private initPlayer = () => {

    this.playerNames.serviceReady$.subscribe(ready => {

      if (ready && !this.playerDocInitialized$.getValue()) {

        this.playerDocInitialized$.next(true)

        combineLatest([this.webRtcInitialized$, this.localStream$]).pipe(
          take(1)
        ).subscribe(() => {
          console.log('WebRTC is INITIALIZED and MediaStream is PRESENT. Lets GO!')
          this.initDatabaseEventListeners()
          this.getOnline()
        })
  
        this.streamService.initUserMedia(ConstraintsLevel.MEDIUM)
  
        this.webRtcHelper.playerCalls$.pipe(
          withLatestFrom(this.activeUsersInRoom$),
          map(([stream, activeUsers]) => {
            return {
              webRtcPeerId: stream.peerId,
              mediaStream: stream.mediaStream,
              playerDocId: activeUsers.find(u => u.webRtcPeerId === stream.peerId).playerDocId,
              playerUid: activeUsers.find(u => u.webRtcPeerId === stream.peerId).playerUid,
            } as IWebrtcUser
          }),
          takeUntil(this.componentDestroyed$),
        ).subscribe((data: IWebrtcUser) => {
          console.log(`DraftComponent => Received stream from ${data.playerDocId}`)
          this.activeStreams.set(data.playerDocId, data.mediaStream)
          console.log(this.activeStreams)
        })

      }

    })


  }
  private getOnline = () => {
    let myDetails: IPlayerLink = {
      displayName: this.playerNames.currentPlayersMini.name.display,
      first: this.playerNames.currentPlayersMini.name.first,
      last: this.playerNames.currentPlayersMini.name.last,
      docId: this.playerNames.currentPlayersMini.id,
    }
    // get online and subscribe to disconnection <--- ALL USERS WILL ACTIVATE THIS
    console.log('Get online and make your Presence known to the world!', myDetails)
    this.updateUserStatusOnline(myDetails)
    this.updateUserStatusOnDisconnect().subscribe()
  }

   // Realtime Database function for presence
   private async updateUserStatusOnline(myDetails: IPlayerLink) {
    // set the userOnline object
    const userOnlineStatus = {
      online: true,
      timestamp: this.timestamp,
      playerDocId: this.auth.user.playerId,
      playerUid: this.auth.user.uid,
      webRtcPeerId: this.webRtcHelper.peerId,
      callerRole: null,
      displayName: myDetails.displayName,
    }

    console.log(userOnlineStatus)

    return this.afDb
      .object(`pubPresence/${this.pub$.getValue().docId}/onlineUsers/${this.auth.user.playerId}`)
      .update(userOnlineStatus)
  }
  private updateUserStatusOnDisconnect() {
    return this.afAuth.authState.pipe(
      tap((user) => {
        if (user) {
          this.afDb.object(`pubPresence/${this.pub$.getValue().docId}/onlineUsers/${this.auth.user.playerId}`)
            .query.ref.onDisconnect().remove()
        }
      })
    )
  }
  private get timestamp() {
    return firestore.Timestamp.now().seconds
  }

  // In app actions
  public acceptChallenge(): void {

    this.afs
      .collection('pubs')
      .doc(this.pub$.getValue().docId)
      .update({
        'battle.status': 'active',
        'battle.opponentDocId': this.auth.user.playerId,
      })
      .catch((error) => console.log(error))

  }
  public initiateChallenge(): void {

    this.afs
      .collection('pubs')
      .doc(this.pub$.getValue().docId)
      .update({
        'battle.status': 'pending',
        'battle.challengerDocId': this.auth.user.playerId,
      })
      .catch((error) => console.log(error))

  }
  public cancelChallenge(): void {

    this.afs
      .collection('pubs')
      .doc(this.pub$.getValue().docId)
      .update({
        'battle': {
          challengerDocId: null,
          challengerStats: {
            life: [20],
            wins: 0,
          },
          opponentDocId: null,
          opponentStats: {
            life: [20],
            wins: 0,
          },
          status: 'none',
        }
      })
      .catch((error) => console.log(error))

  }
  private updateLife(change: number, reset: boolean = false): void {

    const playerStats = this.pub$.getValue().battle.challengerDocId === this.myPlayerId ? 'challengerStats' : 'opponentStats'
    const updateString = `battle.${playerStats}.life`

    if (reset) {
      const update = this.pub$.getValue().battle[playerStats].life
      update.unshift(null)
      update.unshift(20)
      this.afs
        .collection('pubs')
        .doc(this.pub$.getValue().docId)
        .update({
          [updateString]: update
        })
    }
    else {
      this.lifeUpdate = this.lifeUpdate + change
      clearTimeout(this.lifeUpdateTimer)
      this.lifeUpdateTimer = setTimeout(() => {
        const update = this.pub$.getValue().battle[playerStats].life
        update.unshift(this.lifeUpdate)
        this.afs
        .collection('pubs')
        .doc(this.pub$.getValue().docId)
        .update({
          [updateString]: update
        })
      }, 2000)
    }
  }
  private updateGameWin(add: boolean): void {
    const playerStats = this.pub$.getValue().battle.challengerDocId === this.myPlayerId ? 'challengerStats' : 'opponentStats'
    const updateString = `battle.${playerStats}.wins`

    if (add && this.pub$.getValue().battle[playerStats].wins + 1 <= 5) {
      this.afs
          .collection('pubs')
          .doc(this.pub$.getValue().docId)
          .update({
            [updateString]: this.pub$.getValue().battle[playerStats].wins + 1
          })
    }
    if (!add && this.pub$.getValue().battle[playerStats].wins - 1 >= 0) {
      this.afs
          .collection('pubs')
          .doc(this.pub$.getValue().docId)
          .update({
            [updateString]: this.pub$.getValue().battle[playerStats].wins - 1
          })
    }

  }

}

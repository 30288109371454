import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { BehaviorSubject, firstValueFrom } from 'rxjs'
import { faSearch, faTimes, faUnlink, faUser } from '@fortawesome/free-solid-svg-icons'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastService } from 'src/app/services/toast.service'
import { IEventDetails, IMatchData, IEventPlayerDetails, IMatchPlayer, MatchType, IPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { Pipe, PipeTransform } from '@angular/core'
import { EventMatch } from 'src/app/services/event/match'
import { EventService } from 'src/app/services/event/event.service'

export interface IManualPairingMatch {
  match: IMatchData
  player1MP: number
  player2MP: number
  newPairing?: boolean
}
export interface IManualPairingPlayer {
  matchPlayer: IMatchPlayer
  eventPlayer: IEventPlayerDetails
}


@Pipe({
  name: 'manualPairingsPipe'
})
export class ManualPairingsPipe implements PipeTransform {

  transform(items: IManualPairingMatch[], searchString: string): IManualPairingMatch[] {
    if (!items) {
      return []
    }

    let res = items
    if (searchString !== '') {
      res = res.filter(i => i.match.playerFilterValue.toLowerCase().includes(searchString.toLowerCase()))
    }
    res = res.sort((a, b) => {
      return Number(b.newPairing) - Number(a.newPairing) || Number(b.match.hasPlayedEarlier) - Number(a.match.hasPlayedEarlier)
    })
    return res
  }

}


@Component({
  selector: 'app-manual-pairings',
  templateUrl: './manual-pairings.component.html',
  styleUrls: ['./manual-pairings.component.css']
})
export class ManualPairingsComponent implements OnInit {
  @ViewChild('batchSelection') batchSelection: ElementRef
  @Input() eventDocId: string

  private _eventDocument: IEventDetails
  private _eventMatches: IMatchData[]
  private _eventPlayers: IEventPlayerDetails[]

  public event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  private matchList$: BehaviorSubject<IMatchData[]> = new BehaviorSubject<IMatchData[]>(null)
  private playerList$: BehaviorSubject<IEventPlayerDetails[]> = new BehaviorSubject<IEventPlayerDetails[]>(null)

  unpairedPlayers$: BehaviorSubject<IManualPairingPlayer[]> = new BehaviorSubject<IManualPairingPlayer[]>(null)
  unpairedMatches$: BehaviorSubject<IManualPairingMatch[]> = new BehaviorSubject<IManualPairingMatch[]>(null)
  pairedMatches$: BehaviorSubject<IManualPairingMatch[]> = new BehaviorSubject<IManualPairingMatch[]>(null)
  availableSegments: number[]

  faSearch = faSearch
  faTimes = faTimes
  faUnlink = faUnlink
  faUser = faUser

  extendSearchBar = false
  matchFilter = ''

  public lossPlayer: IMatchPlayer = {
    playerDocId: '*** LOSS ***',
    playerUid: '*** LOSS ***',
    displayName: '*** LOSS ***',
    isWinner: false,
    wins: 0,
    draws: 0,
    losses: 0,
    drop: false,
    matchPoints: 0,
    lifePoints: [20],
  }
  public byePlayer: IMatchPlayer = {
    playerDocId: '*** BYE ***',
    playerUid: '*** BYE ***',
    displayName: '*** BYE ***',
    isWinner: false,
    wins: 0,
    draws: 0,
    losses: 0,
    drop: false,
    matchPoints: 0,
    lifePoints: [20],
  }

  constructor(
    private toastService: ToastService,
    public activeModal: NgbActiveModal,
    private eventService: EventService,
    private afs: AngularFirestore,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {

    this.unpairedMatches$.subscribe(i => console.log('unpairedMatched$ emitted', i))
    this.unpairedPlayers$.subscribe(i => console.log('unpairedPlayers$ emitted', i))
    this.pairedMatches$.subscribe(i => console.log('pairedMatches$ emitted', i))

    this.init()

  }

  // get all relevant docments to perform manual pairings
  private async init() {


    console.log(`[ManualPairingsComponent] --> getting event document`)
    const eventSnap = await firstValueFrom(this.afs.collection('events').doc<IEventDetails>(this.eventDocId).get())
    if (!eventSnap.exists) {
      console.log(`[ManualPairingsComponent] --> could not fetch event document`)
      return
    }
    const event = eventSnap.data()
    this._eventDocument = JSON.parse(JSON.stringify(event))
    this.event$.next(event)
    console.log(`[ManualPairingsComponent] --> event document fetched`, event)


    console.log(`[ManualPairingsComponent] --> getting event players`)
    const playerSnap = await firstValueFrom(this.afs.collection('events').doc(this.eventDocId).collection<IEventPlayerDetails>('players').get())
    if (playerSnap.empty) {
      console.log(`[ManualPairingsComponent] --> no event players found`)
      return
    }
    const players = playerSnap.docs.map(i => i.data())
    this._eventPlayers = JSON.parse(JSON.stringify(players))
    this.playerList$.next(JSON.parse(JSON.stringify(players)))
    console.log(`[ManualPairingsComponent] --> event players fetched`, players)


    console.log(`[ManualPairingsComponent] --> getting event matches`)
    const matchSnap = await firstValueFrom(this.afs.collection<IMatchData>('matches', ref => ref
      .where('eventDocId', '==', this.eventDocId)
      .where('roundNumber', '==', event.activeRound)
      ).get())
    if (matchSnap.empty) {
      console.log(`[ManualPairingsComponent] --> no matches found`)
      return
    }
    const matches = matchSnap.docs.map(i => i.data())
    for await (const match of matches) {
      match.hasPlayedEarlier = matches.filter(p =>
        p.player1.playerDocId === match.player1.playerDocId &&
        p.player2.playerDocId === match.player2.playerDocId ||
        p.player2.playerDocId === match.player1.playerDocId &&
        p.player1.playerDocId === match.player2.playerDocId
      ).length > 1
    }
    console.log(`[ManualPairingsComponent] --> matches fetched`, matches)
    this._eventMatches = JSON.parse(JSON.stringify(matches))
    this.matchList$.next(JSON.parse(JSON.stringify(matches)))
    if (event.details.structure.isBatch) {
      this.availableSegments = [...new Set(matches.map(i => i.segmentNumber))].sort((a, b) => a - b)
    }

    const pairedMatches: IManualPairingMatch[] = this.__mapPairedMatches(matches.filter(i => i.roundNumber === event.activeRound))
    console.log(`[ManualPairingsComponent] --> paired matches`, pairedMatches)
    this.pairedMatches$.next(pairedMatches)

    const playersWithMissingMatches: IManualPairingPlayer[] = []
    // get players that does NOT have all matches
    if (event.details.structure.isBatch) {
      for (let player of players) {
        let matchCount = matches.filter(m => m.playerDocIds.includes(player.playerDocId) && m.roundNumber === event.activeRound).length
        let numSegments = event.details.structure.batch.batches.find(i => i.roundNumber === event.activeRound).numberOfMatches
        if (matchCount < numSegments) {
          for (let segment of [...Array(numSegments).keys()]) {
            // check if match for segment exist
            const segmentMatch = matches.find(m => m.playerDocIds.includes(player.playerDocId) && m.roundNumber === event.activeRound && m.segmentNumber === segment + 1)
            if (!segmentMatch) {
              let matchPlayer = this.__createMatchPlayer(player, segment + 1)
              playersWithMissingMatches.push({
                eventPlayer: player,
                matchPlayer: matchPlayer,
              })
            }
          }
        }
      }
    }

    this.unpairedMatches$.next([])
    this.unpairedPlayers$.next(playersWithMissingMatches)

  }

  private __createMatchPlayer(player: IEventPlayerDetails, segment: number): IMatchPlayer {
    return {
      playerDocId: player.playerDocId,
      playerUid: player.playerUid,
      displayName: player.name,
      isWinner: false,
      wins: 0,
      draws: 0,
      losses: 0,
      drop: false,
      matchPoints: player.matchPoints,
      rank: player.rank,
      seed: player.seed,
      segmentNumber: segment,
    }
  }

  private __mapPairedMatches(matches: IMatchData[]): IManualPairingMatch[] {
    const pairedMatches: IManualPairingMatch[] = matches.filter(i => i.roundNumber === this._eventDocument.activeRound).map(match => {
      // let player1 = this._eventPlayers.find(i => i.playerDocId === match.player1.playerDocId)
      // let player2 = this._eventPlayers.find(i => i.playerDocId === match.player2.playerDocId)
      // || player1 === undefined
      // || player2 === undefined
      return {
        match: JSON.parse(JSON.stringify(match)),
        player1MP: match.player1.playerDocId === '*** BYE ***' || match.player1.playerDocId === '*** LOSS ***'
          ? 0
          : match.player1.matchPoints,
        player2MP: match.player2.playerDocId === '*** BYE ***' || match.player2.playerDocId === '*** LOSS ***'
          ? 0
          : match.player2.matchPoints,
        newPairing: false,
      }
    })
    return pairedMatches
  }

  public manualPairingsCancel() {

    // this.eventService.eventUpdateStatus(this._eventDocument, 2, false) // Not really sure why this would ever be needed...
    this.activeModal.close()

  }

  public async manualPairingsDone() {

    const matchesToUpdate = this.pairedMatches$.getValue().filter(i => i.newPairing)
    const updateMatchDocIds = [...new Set(matchesToUpdate.map(i => i.match.docId))]
    const pairedMatchDocIds = [...new Set(this.pairedMatches$.getValue().map(i => i.match.docId))]
    const originMatchDocIds = [...new Set(this._eventMatches.map(i => i.docId))]
    const removeMatchDocIds = originMatchDocIds.filter(i => pairedMatchDocIds.indexOf(i) === -1)

    console.log({
      matchesToUpdate,
      updateMatchDocIds,
      removeMatchDocIds,
      pairedMatchDocIds,
      originMatchDocIds,
    })

    // create firestore batch writer
    const batchWrites: firebase.default.firestore.WriteBatch[] = []
    let batchWriteIndex = 0
    let writeCount = 0

    // create local method to update counter and index of the batch writer
    const __incrementWriteCounter = () => {
      writeCount++
      if ((writeCount % 500) === 0 && (writeCount / 500) === (batchWriteIndex + 1)) {
        batchWrites.push(this.afs.firestore.batch())
        batchWriteIndex++
      }
    }

    // add the first batch
    batchWrites.push(this.afs.firestore.batch())

    // add all match update transactions
    for await (const data of matchesToUpdate) {

      // make sure the match is cleared from results
      if (!data.match.isByeMatch && !data.match.isLossMatch) {
        data.match.player1.isWinner = false
        data.match.player1.wins = 0
        data.match.player1.losses = 0
        data.match.player2.isWinner = false
        data.match.player2.wins = 0
        data.match.player2.losses = 0
        data.match.isReported = false
      }

      // set manually paired flag
      data.match.isManuallyPaired = true

      __incrementWriteCounter()
      const updateRef = this.afs.collection('matches').doc(data.match.docId).ref
      batchWrites[batchWriteIndex].set(updateRef, data.match)
    }

    // add all match delete transactions
    for await (const docId of removeMatchDocIds) {
      __incrementWriteCounter()
      const updateRef = this.afs.collection('matches').doc(docId).ref
      batchWrites[batchWriteIndex].delete(updateRef)
    }

    // add all event player update transactions
    const tempPlayerList: IEventPlayerDetails[] = JSON.parse(JSON.stringify(this._eventPlayers))
    for await (const player of tempPlayerList) {

      const originOpponentIds = this._eventPlayers.find(i => i.playerDocId === player.playerDocId).opponentsDocIds
      const previousRoundOpponentDocIds = this._eventMatches
        .filter(i => i.roundNumber !== this._eventDocument.activeRound)
        .filter(i => i.player1.playerDocId === player.playerDocId || i.player2.playerDocId === player.playerDocId)
        .map(i => {
          if (i.player1.playerDocId === player.playerDocId) {
            return i.player2.playerDocId
          }
          else {
            return i.player1.playerDocId
          }
        })
      const currentRoundOpponentDocIds = this.pairedMatches$.getValue()
        .filter(i => i.match.player1.playerDocId === player.playerDocId || i.match.player2.playerDocId === player.playerDocId)
        .map(i => {
          if (i.match.player1.playerDocId === player.playerDocId) {
            return i.match.player2.playerDocId
          }
          else {
            return i.match.player1.playerDocId
          }
        })
      const newOpponentDocIds = [...currentRoundOpponentDocIds, ...previousRoundOpponentDocIds]

      console.log({
        newOpponentDocIds,
        originOpponentIds,
      })

      if (originOpponentIds.filter(i => newOpponentDocIds.indexOf(i) === -1).length > 0) {
        __incrementWriteCounter()
        const updateRef = this.afs.collection('events').doc(this._eventDocument.docId).collection('players').doc(player.playerDocId).ref
        batchWrites[batchWriteIndex].update(updateRef, {
          opponentsDocIds: newOpponentDocIds
        })
      }

    }

    // perform writes
    let writeErrors = false
    console.log('...writing matches and player updates to firebase')
    for await (const [index, batch] of batchWrites.entries()) {
      batch
        .commit()
        .then(() => console.log(`...batchWrite #${index + 1} successful`))
        .catch((e) => {
          console.log(e)
          writeErrors = true
        })
    }

    // not sure why this is here at all, updaing the status should not be done as the same state should be held.
    // if (!writeErrors) {
    //   this.eventService.eventUpdateStatus(this._eventDocument, 2, false)
    // }

    this.activeModal.close()

  }

  // UNPAIRING
  public unpairMatch(data: IManualPairingMatch) {

    if (data.match.player1.playerDocId === '' && data.match.player2.playerDocId === '') {
      this.afs.collection('matches').doc(data.match.docId).delete()
      this.pairedMatches$.next(this.pairedMatches$.getValue().filter(i => i.match.docId !== data.match.docId))
      return
    }

    if (!['*** BYE ***', '*** LOSS ***'].includes(data.match.player1.playerDocId)) {
      // store the player from the unpaired match as both MatchPlayer and EventPlayer objects
      const removedMatchPlayer1: IMatchPlayer = JSON.parse(JSON.stringify(data.match.player1))
      const removedEventPlayer1: IEventPlayerDetails = JSON.parse(JSON.stringify(this._eventPlayers.find(i => i.playerDocId === data.match.player1.playerDocId)))
      // update oppnent list for the player
      removedEventPlayer1.opponentsDocIds.splice(removedEventPlayer1.opponentsDocIds.indexOf(data.match.player2.playerDocId), 1)
      // add match points to the player
      removedEventPlayer1.matchPoints = removedMatchPlayer1.matchPoints
      // add the players to the list of unpaired players
      this.__addPlayersToUnpairedPlayers(removedEventPlayer1, removedMatchPlayer1)
    }


    // check if player2 is NOT BYE or LOSS
    if (!['*** BYE ***', '*** LOSS ***'].includes(data.match.player2.playerDocId)) {
      // store the player from the unpaired match as both MatchPlayer and EventPlayer objects
      const removedMatchPlayer2: IMatchPlayer = JSON.parse(JSON.stringify(data.match.player2))
      const removedEventPlayer2: IEventPlayerDetails = JSON.parse(JSON.stringify(this._eventPlayers.find(i => i.playerDocId === data.match.player2.playerDocId)))
      // update oppnent list for the player
      removedEventPlayer2.opponentsDocIds.splice(removedEventPlayer2.opponentsDocIds.indexOf(data.match.player1.playerDocId), 1)
      // add match points to the player
      removedEventPlayer2.matchPoints = removedMatchPlayer2.matchPoints
      // add the players to the list of unpaired players
      this.__addPlayersToUnpairedPlayers(removedEventPlayer2, removedMatchPlayer2)
    }

    // remove match from the list of paired matches
    this.__removeMatchFromPairedMatches(data.match)

    // add the match to the list of unpaired matches
    this.__addMatchToUnpairedMatches(data.match)

  }
  private __removeMatchFromPairedMatches(match: IMatchData) {
    const pairedMatches = this.pairedMatches$.getValue()
    this.pairedMatches$.next(pairedMatches.filter(i => i.match.docId !== match.docId))
  }
  private __addMatchToUnpairedMatches(match: IMatchData) {

    // create an empty player object
    const emptyPlayer: IMatchPlayer = {
      playerDocId: '',
      playerUid: '',
      displayName: '',
      isWinner: false,
      wins: 0,
      draws: 0,
      losses: 0,
      drop: false,
      matchPoints: 0,
      lifePoints: [20],
      segmentNumber: match.segmentNumber
    }

    // update match
    match.player1 = emptyPlayer
    match.player2 = emptyPlayer
    match.playerDocIds = []
    match.playerFilterValue = null
    match.isByeMatch = false
    match.isLossMatch = false
    match.isDraw = false
    match.isReported = false


    // get the current list of unpaired matches
    const unpairedMatches = this.unpairedMatches$.getValue()

    // create a new data object
    const unpairedMatch: IManualPairingMatch = {
      match: match,
      player1MP: 0,
      player2MP: 0,
      newPairing: false,
    }

    // add the data to the list
    unpairedMatches.push(unpairedMatch)

    // update the list of unpaired matches
    this.unpairedMatches$.next(unpairedMatches)

  }
  private __addPlayersToUnpairedPlayers(removedEventPlayer: IEventPlayerDetails, removedMatchPlayer: IMatchPlayer) {

    const unpairedPlayers = this.unpairedPlayers$.getValue()

    const data: IManualPairingPlayer = {
      eventPlayer: removedEventPlayer,
      matchPlayer: removedMatchPlayer,
    }

    unpairedPlayers.push(data)

    this.unpairedPlayers$.next(unpairedPlayers)

  }

  // PAIRING
  public onPlayerDroppedInMatchSlot(event: any, droppedSlot: 'player1' | 'player2', otherSlot: 'player1' | 'player2', match: IMatchData) {

    const player: IMatchPlayer = event.dropData

    // check if dropped slot is empty
    if (match[droppedSlot].playerUid !== '') {
      // slot is taken, show message toast
      console.log('dropped on non-empty slot')
      this.toastService.show('Slot is already taken!', { classname: 'error-toast', delay: 5000 })
      return false
    }

    // update segment number if player is BYE or LOSS
    if (player.playerDocId === '*** BYE ***' || player.playerDocId === '*** LOSS ***') {
      player.segmentNumber = match.segmentNumber
    }

    // check if segment numbers match
    if (match.isType === 'batch' && player.segmentNumber !== match.segmentNumber) {
      // segment number mismatch
      console.log('dropped on mismatching segment number')
      this.toastService.show('You need to match segment number when pairing', { classname: 'error-toast', delay: 5000 })
      return false
    }

    // check if both BYE or LOSS
    if ((player.playerDocId === '*** BYE ***' || player.playerDocId === '*** LOSS ***') && (match[otherSlot].playerDocId === '*** BYE ***' || match[otherSlot].playerDocId === '*** LOSS ***')) {
      console.log('dropped player and other player both LOSS or BYE')
      this.toastService.show('A match must contain at least ONE real player', { classname: 'error-toast', delay: 5000 })
      return false
    }


    this.__removePlayerFromUnpairedPlayers(player)
    this.__addPlayerToMatchDocument(droppedSlot, otherSlot, match, player)

  }
  public removePlayerFromUnpairedMatch(data: IManualPairingMatch, playerSlot: 'player1' | 'player2', removedMatchPlayer: IMatchPlayer) {

    // remove player from the slot
    data.match[playerSlot] = {
      playerDocId: '',
      playerUid: '',
      displayName: '',
      isWinner: false,
      wins: 0,
      draws: 0,
      losses: 0,
      drop: false,
      matchPoints: 0,
      lifePoints: [20],
      segmentNumber: data.match.segmentNumber
    }
    playerSlot === 'player1'
      ? data.player1MP = 0
      : data.player2MP = 0

    // get unpaired matches and exchange the match with the new object
    const unpairedMatches = this.unpairedMatches$.getValue()
    unpairedMatches.splice(unpairedMatches.findIndex(i => i.match.docId === data.match.docId), 1, data)
    this.unpairedMatches$.next(unpairedMatches)

    // add the removed player to the list of unpaired players
    if (removedMatchPlayer.playerDocId !== '*** BYE ***' && removedMatchPlayer.playerDocId !== '*** LOSS ***') {
      const removedEventPlayer = this._eventPlayers.find(i => i.playerDocId === removedMatchPlayer.playerDocId)
      this.__addPlayersToUnpairedPlayers(removedEventPlayer, removedMatchPlayer)
    }

  }
  private __removePlayerFromUnpairedPlayers(player: IMatchPlayer) {

    const unpairedPlayers = this.unpairedPlayers$.getValue()
    const playerIndex = unpairedPlayers.findIndex(i => i.matchPlayer.playerDocId === player.playerDocId && i.matchPlayer.segmentNumber === player.segmentNumber)
    unpairedPlayers.splice(playerIndex, 1)
    this.unpairedPlayers$.next(unpairedPlayers)

  }
  private __addPlayerToMatchDocument(droppedSlot: 'player1' | 'player2', otherSlot: 'player1' | 'player2', match: IMatchData, player: IMatchPlayer) {

    // get player document
    const addedEventPlayer = this.playerList$.getValue().find(i => i.playerDocId === player.playerDocId)

    // get unpaired matches and update the match
    const unpairedMatches = this.unpairedMatches$.getValue()
    const unpairedMatch = unpairedMatches.find(i => i.match.docId === match.docId)
    unpairedMatch.match[droppedSlot] = player
    droppedSlot === 'player1'
      ? unpairedMatch.player1MP = addedEventPlayer !== undefined
        ? addedEventPlayer.matchPoints
        : 0
      : unpairedMatch.player2MP = addedEventPlayer !== undefined
        ? addedEventPlayer.matchPoints
        : 0

    if (unpairedMatch.match.player1.playerDocId !== '' && unpairedMatch.match.player2.playerDocId !== '') {

      // remove match from unpaired matches
      unpairedMatches.splice(unpairedMatches.findIndex(i => i.match.docId === match.docId), 1)
      this.unpairedMatches$.next(unpairedMatches)

      // set match as newly paired
      unpairedMatch.newPairing = true

      // set match attributes
      unpairedMatch.match.player1.wins = 0
      unpairedMatch.match.player1.losses = 0
      unpairedMatch.match.player1.isWinner = false
      unpairedMatch.match.player2.wins = 0
      unpairedMatch.match.player2.losses = 0
      unpairedMatch.match.player1.isWinner = false
      unpairedMatch.match.playerDocIds = [match.player1.playerDocId, match.player2.playerDocId]
      unpairedMatch.match.segmentType = 'manual'

      // check if BYE or LOSS
      if (
        unpairedMatch.match.player1.playerDocId === '*** BYE ***' ||
        unpairedMatch.match.player1.playerDocId === '*** LOSS ***' ||
        unpairedMatch.match.player2.playerDocId === '*** BYE ***' ||
        unpairedMatch.match.player2.playerDocId === '*** LOSS ***'
      ) {
        // check if BYE or LOSS is placed as player1, and if so => SWITCH
        if (unpairedMatch.match.player1.playerDocId === '*** BYE ***' || unpairedMatch.match.player1.playerDocId === '*** LOSS ***') {
          const player1 = JSON.parse(JSON.stringify(unpairedMatch.match.player1))
          const player2 = JSON.parse(JSON.stringify(unpairedMatch.match.player2))
          unpairedMatch.match.player1 = player2
          unpairedMatch.match.player2 = player1
        }
        // update match results
        unpairedMatch.match.isReported = true
        unpairedMatch.match.isByeMatch = unpairedMatch.match.player2.playerDocId === '*** BYE ***'
        unpairedMatch.match.isLossMatch = unpairedMatch.match.player2.playerDocId === '*** LOSS ***'
        unpairedMatch.match.player1.isWinner = unpairedMatch.match.player2.playerDocId === '*** BYE ***' ? true : false
        unpairedMatch.match.player1.wins = unpairedMatch.match.player2.playerDocId === '*** BYE ***' ? 2 : 0
        unpairedMatch.match.player1.losses = unpairedMatch.match.player2.playerDocId === '*** BYE ***' ? 0 : 2
        unpairedMatch.match.player2.isWinner = unpairedMatch.match.player2.playerDocId === '*** BYE ***' ? false : true
        unpairedMatch.match.player2.wins = unpairedMatch.match.player2.playerDocId === '*** BYE ***' ? 0 : 2
        unpairedMatch.match.player2.losses = unpairedMatch.match.player2.playerDocId === '*** BYE ***' ? 2 : 0
      }

      // update filter value
      unpairedMatch.match.playerFilterValue = `${match.player1.displayName} ${match.player2.displayName} table: ${match.tableNumber} s${match.segmentNumber}`

      // get paired matches
      let pairedMatches: IManualPairingMatch[] = JSON.parse(JSON.stringify(this.pairedMatches$.getValue()))

      // update hasPlayedEarlier
      const matchesPlayedBefore = pairedMatches.filter(p =>
        p.match.player1.playerDocId === unpairedMatch.match.player1.playerDocId &&
        p.match.player2.playerDocId === unpairedMatch.match.player2.playerDocId ||
        p.match.player2.playerDocId === unpairedMatch.match.player1.playerDocId &&
        p.match.player1.playerDocId === unpairedMatch.match.player2.playerDocId
      ).length
      unpairedMatch.match.hasPlayedEarlier = (matchesPlayedBefore + 1) > 1

      // check if BYE/LOSS match
      if (unpairedMatch.match.playerDocIds.includes('*** BYE ***')) {
        unpairedMatch.match.isByeMatch = true
      }
      if (unpairedMatch.match.playerDocIds.includes('*** LOSS ***')) {
        unpairedMatch.match.isLossMatch = true
      }


      // add new paired match to paired matches array
      pairedMatches.push(unpairedMatch)
      this.pairedMatches$.next(pairedMatches)

    }
    else {
      unpairedMatches.splice(unpairedMatches.findIndex(i => i.match.docId === match.docId), 1, unpairedMatch)
    }

  }


  public clearEmptyMatches(): void {
    this.unpairedMatches$.next([])
  }
  public async createEmptyMatch(event: IEventDetails) {

    let isType: MatchType
    let segment = null

    if (event.details.structure.isBatch) { isType = 'batch' }
    if (event.details.structure.isBracket) { isType = 'bracket' }
    if (event.details.structure.isGroup) { isType = 'group' }
    if (event.details.structure.isRoundRobin) { isType = 'round-robin' }
    if (event.details.structure.isSwiss) { isType = 'swiss' }

    if (isType === 'batch') {
      await this.modalService.open(this.batchSelection, {
        centered: true,
        animation: true,
        backdrop: true,
        keyboard: true,
        size: 'sm',
      }).result.then(
        (res) => {
          console.log('close', res)
          segment = res
        },
        () => {
          console.log('dissmiss')
        })
    }

    const tempMatch = new EventMatch(
      event.docId,
      event.createdByUid,
      event.activeRound,
      segment,
      0,
      isType,
      null,
      null,
      null,
    )

    const unpairedMatches = this.unpairedMatches$.getValue()
    unpairedMatches.push({
      match: tempMatch.document,
      player1MP: 0,
      player2MP: 0,
      newPairing: true
    })

    this.unpairedMatches$.next(unpairedMatches)

  }

  public resetManualPairings(): void {
    this.pairedMatches$.next(this.__mapPairedMatches(JSON.parse(JSON.stringify(this._eventMatches))))
    this.playerList$.next(JSON.parse(JSON.stringify(this._eventPlayers)))

    this.unpairedMatches$.next([])
    this.unpairedPlayers$.next([])
  }

  public get disableDone(): boolean {
    if (this.unpairedMatches$.getValue() === null || this.unpairedPlayers$.getValue() === null) {
      return true
    }
    if (this.unpairedMatches$.getValue().length > 0) {
      return true
    }
    if (this.unpairedPlayers$.getValue().length > 0) {
      return true
    }

    return false
  }

  public get disableClearMatches(): boolean {

    if (this.unpairedMatches$.getValue() === null) {
      return true
    }

    if (this.unpairedPlayers$.getValue().length > 0) {
      return true
    }

    if (this.unpairedMatches$.getValue().filter(i =>
      (i.match.player1.playerDocId !== '*** BYE ***' && i.match.player2.playerDocId !== '') ||
      (i.match.player2.playerDocId !== '*** LOSS ***' && i.match.player2.playerDocId !== '')
    ).length > 0) {
      return true
    }

    return false
  }

  public get countUnpairedMatches(): number {
    return this.unpairedMatches$.getValue() === null
      ? 0
      : this.unpairedMatches$.getValue().length
  }

  public get countUnpairedPlayers(): number {
    return this.unpairedPlayers$.getValue() === null
      ? 0
      : this.unpairedPlayers$.getValue().length
  }

}

import { CardSearchService } from 'src/app/services/card-search.service'
import { DecksService, IDeckList } from 'src/app/services/decks.service'
import { DeckListParserPipe } from 'src/app/pipes/deck-list-parser.pipe'
import { IEventDetails, IEventPlayerDetails, IMatchData } from 'tolaria-cloud-functions/src/_interfaces'
import { map, take, finalize } from 'rxjs/operators'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { HttpClient } from '@angular/common/http'
import { AngularFireStorage } from '@angular/fire/compat/storage'
import { Injectable } from '@angular/core'
import { FileDownloadEntity } from './file-download.entity'
import { saveAs } from 'file-saver/dist/FileSaver'
import JSZip from 'JSZip'
import { IPromiseResponse, IPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { firstValueFrom, Observable, Subscription } from 'rxjs'
import { GlobalsService } from './globals.service'
import { Router } from '@angular/router'
import { EventService, IPlayerDeckPhoto, IPlayerDeckSubmission } from './event/event.service'
import { PlayerTieBreakers } from './event/tiebreakers';
import { StandingsPipe } from '../pipes/standings.pipe';

export interface IDeckListMeta {
  playerName: string
  playerDocId: string
  eventName: string
  eventDocId: string
  deckText: string
  name: string
  description: string
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private downloadUrl$: Observable<string>

  constructor(
    private angularFireStorage: AngularFireStorage,
    private afs: AngularFirestore,
    private httpClient: HttpClient,
    private es: EventService,
    private deckParser: DeckListParserPipe,
    private deckService: DecksService,
    private globals: GlobalsService,
    private cards: CardSearchService,
    private router: Router,
    private standingsPipe: StandingsPipe,
  ) { }

  public uploadImage(filePath: string, imageString: string): Promise<string> {

    return new Promise((resolve, reject) => {
      this.globals.isBusy.message = 'Uploading your image, please wait'
      this.globals.isBusy.status = true
      this.globals.isBusy.showMessage = true

      const ref = this.angularFireStorage.ref(filePath)
      console.log('create ref', ref)
      const task = ref.putString(imageString, 'data_url')
      console.log('crate task', task)
      const taskSub: Subscription = task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            taskSub.unsubscribe()
            console.log('task finalized, getting download url')
            const downloadUrl = ref.getDownloadURL()
            // store the download url as the avatar link for both user and player doc.
            const downloadSub: Subscription = downloadUrl
              .subscribe(async (url) => {
                downloadSub.unsubscribe()
                console.log('downlaod url emitted')
                this.globals.isBusy.status = false
                this.globals.isBusy.showMessage = false
                // return image download url
                resolve(url)
              })
          }),
        ).subscribe()
    })
  }

  public uploadFile(file: File, path: string): Promise<string> {
    return new Promise((resolve) => {
      this.globals.isBusy.message = 'Uploading file, please wait...'
      this.globals.isBusy.status = true
      this.globals.isBusy.showMessage = true

      // console.log(`about to upload a file to ${path}`)
      const ref = this.angularFireStorage.ref(path)
      const task = this.angularFireStorage.upload(path, file)
      const sub: Subscription = task.snapshotChanges().pipe(
        finalize(() => {
          // console.log('uploaded')
          this.downloadUrl$ = ref.getDownloadURL()
          this.downloadUrl$.pipe(take(1)).subscribe((downloadUrl: string) => {
            // console.log(downloadUrl)
            sub.unsubscribe()
            this.globals.isBusy.showMessage = false
            this.globals.isBusy.status = false
            resolve(downloadUrl)
          })
        })
      ).subscribe()
    })
  }

  public downloadZippedFiles(files: FileDownloadEntity[], zipName: string): void {

    const zipFile: JSZip = new JSZip()
    let count = 0

    files.forEach(file => {
      this.angularFireStorage.ref('/' + file.name).getDownloadURL().subscribe(url => {
        this.httpClient.get(url, { responseType: 'blob' }).subscribe(response => {

          zipFile.file(file.fileName + file.extension, response, { binary: true })

          count++
          if (count === files.length) {
            zipFile.generateAsync({ type: 'blob' }).then(content => {
              saveAs(content, zipName + '.zip')
            })
          }
        })
      })
    })

  }

  public async downloadZippedDeckPhotos(files: IPlayerDeckPhoto[], zipName: string): Promise<IPromiseResponse> {

    return new Promise(async (resolve, reject) => {

      const zipFile: JSZip = new JSZip()

      let count = 1

      const filesToHandle = files.filter(f => f.downloadUrl !== null)

      filesToHandle.forEach((file) => {

        console.log(`${count}: Getting deck photo ${file.fileName}`)

        this.httpClient
          .get(file.downloadUrl, { responseType: 'blob' })
          .pipe(take(1))
          .subscribe(response => {

            console.log('got the file', response)
            this.globals.isBusy.message = `Downloading Deck Photo: ${file.fileName} (${count} of ${filesToHandle.length})`
            saveAs(response, file.fileName)

            // const url = window.URL.createObjectURL(response)
            // window.open(url)

            // this.globals.isBusy.message = `Preparing deck photo ${count} of ${filesToHandle.length}`

            // zipFile.file(file.fileName + '.jpg', response, { binary: true })

            if (count === filesToHandle.length) {


              // zipFile.generateAsync({ type: 'blob', compression: 'STORE' }, (metadata) => {
              //   console.log('callBack on zip')
              //   console.log(metadata)
              //   this.globals.isBusy.message = `Generating Zip-file... ${metadata.percent.toFixed(2)}%`
              // }).then(content => {

              // this.globals.isBusy.message = 'Zip-file ready, downloading...'

              // saveAs(content, zipName + '.zip')

              resolve({
                status: true,
                text: 'Zip file with all deck photos generated successfully, downloading'
              })

              // })

            }

            count++

          })

      })

    })

  }

  public async downloadDeckPhoto(playerDeck: IPlayerDeckSubmission) {
    this.globals.isBusy.message = `Downloading Deck Photo: ${playerDeck.fileName}`

    return new Promise(async (resolve, reject) => {

      this.getDeckListByDocId(playerDeck.deckListDocId, playerDeck.deckVersionDocId).then(deck => {
        this.httpClient
          .get(deck.imageUris.large, { responseType: 'blob' })
          .pipe(take(1))
          .subscribe(async (response) => {

            console.log('got the file', response)
            await saveAs(response, playerDeck.fileName)

            resolve({
              status: true,
              text: `Deck Photo: ${playerDeck.fileName} downloaded`
            })

          })
      })
    })
  }

  private getDeckListByDocId(docId: string, versionDocId: string = null): Promise<IDeckList> {
    return new Promise((resolve, reject) => {
      let docRef = this.afs.collection('decks').doc<IDeckList>(docId)
      if (versionDocId) {
        docRef = this.afs.collection('decks').doc(docId).collection('versions').doc<IDeckList>(versionDocId)
      }

      docRef.get().pipe(take(1)).subscribe(doc => resolve(doc.data()))
    })
  }

  public async downloadZippedDeckListsFromEvent(eventDetails: IEventDetails): Promise<IPromiseResponse> {

    return new Promise(async (resolve) => {

      this.globals.isBusy.status = true
      this.globals.isBusy.showMessage = true
      this.globals.isBusy.message = `Preparing your download`
      this.globals.isBusy.loaderWidth = 20

    // create reusable variables
      const d = new Date()
      const dateText = d.getUTCFullYear() + '.' + this.pad2(d.getUTCMonth()) + '.' + this.pad2(d.getUTCDate())
      const suffixText = '\n\nVisit Tolaria @ https://tolaria.app\n\n\n'
      const zipName = dateText + '__' + eventDetails.details.name.replace(' ', '-').toLowerCase()

      // create the zip file
      const zipFile: JSZip = new JSZip()

      // get attendee list for event
      const playerSnap = await this.afs.collection('events').doc(eventDetails.docId).collection<IEventPlayerDetails>('players').get().toPromise()
      if (playerSnap.empty) {
        this.globals.isBusy.status = false
        this.globals.isBusy.showMessage = false
        this.globals.isBusy.loaderWidth = 10
        resolve({
          status: false,
          text: `Could not get attendee list for event`
        })
      }

      // get all the player documents
      const players: IEventPlayerDetails[] = playerSnap.docs.map(i => i.data())

      // get all the submitted decks
      const decks: IDeckListMeta[] = []
      const total = players.filter(i => i.deckSubmission.deckListDocId !== '' && i.deckSubmission.deckListDocId !== null).length
      let count = 0
      for await (const player of players.filter(i => i.deckSubmission.deckListDocId !== '' && i.deckSubmission.deckListDocId !== null)) {
        count++
        this.globals.isBusy.message = `<p>Downloading Deck Lists</p>
        <p class="text-secondary"><span class="text-warning">${count}</span> of <span class="text-bold">${total}</span></p>
        <h5>${player.name}</h5>`

        const notVersion = player.deckSubmission.deckVersionDocId === '' || player.deckSubmission.deckVersionDocId === null
        const deckRef = notVersion
          ? this.afs.collection('decks').doc<IDeckList>(player.deckSubmission.deckListDocId)
          : this.afs.collection('decks').doc(player.deckSubmission.deckListDocId).collection('versions').doc<IDeckList>(player.deckSubmission.deckVersionDocId)

        const deckSnap = await deckRef.get().toPromise()
        if (deckSnap.exists) {
          const deck = deckSnap.data()
          const deckMeta: IDeckListMeta = {
            playerName: player.name,
            playerDocId: player.playerDocId,
            eventName: eventDetails.details.name,
            eventDocId: eventDetails.docId,
            deckText: this.getDeckTextList(deck, !notVersion),
            description: deck.description,
            name: deck.name
          }
          decks.push(deckMeta)
        }

      }

      // create the string for the event info file
      this.globals.isBusy.message = `Zipping up your files...`
      let eventInfoFile = ''
      eventInfoFile += 'Event......: ' + eventDetails.details.name + '\n'
      eventInfoFile += 'Format.....: ' + eventDetails.details.format + '\n'
      eventInfoFile += 'Date.......: ' + dateText + '\n\n\n'
      eventInfoFile += 'Player name                             Deck Name' + '\n'

      // loop through all decks and add to zip file
      for await (const player of players) {

        // add the attendee to the event info file
        eventInfoFile += this.pad('                                        ', player.name, false)

        if (player.deckSubmission.deckListDocId !== null && player.deckSubmission.deckListDocId !== '') {

          // get the deck from the decks array
          const deck = decks.find(i => i.playerDocId === player.playerDocId)

          if (deck !== undefined && deck !== null) {

            // add the deck name to the info file
            eventInfoFile += deck.name + '\n'

            // create the deck list to attach in the zip file
            let deckText = ''
            deckText += 'Event......: ' + eventDetails.details.name + '\n'
            deckText += 'Date.......: ' + dateText + '\n'
            deckText += 'Player.....: ' + deck.playerName + '\n'
            deckText += 'Deck name..: ' + deck.name + '\n'
            deckText += 'Description: ' + deck.description + '\n\n\n'
            deckText += deck.deckText + '\n\n'
            deckText += suffixText

            // create the blob text list
            const deckBlob = new Blob([deckText], { type: 'text/plain' })

            // add the text list to the zip file
            zipFile.file(eventDetails.details.name.replace(' ', '-').toLowerCase() + '__' + player.name.replace(' ', '-').toLowerCase() + '.txt', deckBlob, { binary: true })

          }
          else {

            // add the deck name to the info file
            eventInfoFile += '---> no deck submitted !\n'

          }

        }
        else {

          // add the deck name to the info file
          eventInfoFile += '---> no deck submitted !\n'

        }

      }

      // create the blob for event info text
      const eventInfoFileBlob = new Blob([eventInfoFile], { type: 'text/plain' })

      // add the text list to the zip file
      zipFile.file('EVENT-INFORMATION.txt', eventInfoFileBlob, { binary: true })

      // download the zip file
      zipFile.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, zipName + '.zip')
        this.globals.isBusy.status = false
        this.globals.isBusy.showMessage = false
        this.globals.isBusy.loaderWidth = 10
        resolve({
          status: true,
          text: 'success'
        })
      })

    })

  }

  public async downloadEventInformation(eventDetails: IEventDetails, eventPlayers: Array<IEventPlayerDetails>) {

    this.globals.isBusy.message = `<p class="text-larger">Creating export data</p>`
    this.globals.isBusy.loaderWidth = 20
    this.globals.isBusy.status = true
    this.globals.isBusy.showMessage = true

    // console.log(decks)
    // create a date instance
    const d = new Date()
    // create date text string
    const dateText = d.getUTCFullYear() + '.' + this.pad2(d.getUTCMonth()) + '.' + this.pad2(d.getUTCDate())
    // create the string for the event info file
    let eventInfoFile = ''
    eventInfoFile += 'Event......: ' + eventDetails.details.name + '\n'
    eventInfoFile += 'Format.....: ' + eventDetails.details.format + '\n'
    eventInfoFile += 'Date.......: ' + dateText + '\n\n\n'
    eventInfoFile += 'Player name                             Deck Name' + '\n'

    // sort player list by name asc
    eventPlayers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))

    // write player list
    for await (const player of eventPlayers) {
      this.globals.isBusy.message = `<p class="text-larger">Creating export data</p><p>fetching <b>${player.name}'s</b> deck...</p>`
      const deck = await this.deckService.getDeckById_Promise(player.deckSubmission.deckListDocId, player.deckSubmission.deckVersionDocId)
      eventInfoFile += this.pad('                                        ', player.name, false)
      if (deck !== null) { eventInfoFile += deck.name + '\n' }
      else { eventInfoFile += ' ERROR --> deck list not found\n' }
    }

    // create the blob for event info text
    const eventInfoFileBlob = new Blob([eventInfoFile], { type: 'text/plain' })
    saveAs(eventInfoFileBlob, 'EVENT-INFORMATION.txt')

    this.globals.isBusy.status = false
    this.globals.isBusy.showMessage = false
  }

  public async downloadEventAttendeeList(eventDetails: IEventDetails, eventPlayers: IEventPlayerDetails[]) {


    const registrationFee = eventDetails.details && eventDetails.details.registrationFee && eventDetails.details.registrationFee.active
    const deckList = eventDetails.details && eventDetails.details.deckList
    const deckPhoto = eventDetails.details && eventDetails.details.deckPhoto

    let eventInfoFile: string = ''

    // add event information
    eventInfoFile += 'Event: ' + eventDetails.details.name + '\n\n'
    eventInfoFile += 'Attendee                                '
    registrationFee ? eventInfoFile += 'Paid? ' : null
    deckList ? eventInfoFile += 'List? ' : null
    deckPhoto ? eventInfoFile += 'Photo?' : null
    eventInfoFile += '\n'

    this.globals.isBusy.status = true
    this.globals.isBusy.showMessage = true
    this.globals.isBusy.loaderWidth = 25

    // write player list
    let counter = 1
    for await (const player of eventPlayers) {

      this.globals.isBusy.message = `
      <p>Getting player data</p>
      <p class="text-large mt-3">${player.name}</p>
      <p class="text-small text-secondary text-monospaced">${counter} / ${eventPlayers.length}</p>`

      const playerDoc = await firstValueFrom(this.afs.collection('players').doc<IPlayerDetails>(player.playerDocId).get())
      const playerDetails = playerDoc.data()

      let hasPaid = false
      if (registrationFee) {
        if (eventDetails.details.registrationFee && eventDetails.details.registrationFee.active) {
          if (eventDetails.details.registrationFee.paidBy !== undefined && eventDetails.details.registrationFee.paidBy[player.playerDocId] !== undefined) {
            hasPaid = eventDetails.details.registrationFee.paidBy[player.playerDocId].paid && !eventDetails.details.registrationFee.paidBy[player.playerDocId].refunded
          }
          if (eventDetails.details.registrationFee.paidByTicket !== undefined && eventDetails.details.registrationFee.paidByTicket[player.playerDocId] !== undefined) {
            hasPaid = !eventDetails.details.registrationFee.paidByTicket[player.playerDocId].refunded
          }
        }
      }
      const hasDeckList = deckList && player.deckSubmission.deckList
      const hasDeckPhoto = deckPhoto && player.deckSubmission.deckPhoto

      eventInfoFile += this.pad('                                        ', player.name, false)
      registrationFee
        ? hasPaid
          ? eventInfoFile += 'Yes   '
          : eventInfoFile += 'No    '
        : null

      deckList
        ? hasDeckList
          ? eventInfoFile += 'Yes   '
          : eventInfoFile += 'No    '
        : null

      deckPhoto
        ? hasDeckPhoto
          ? eventInfoFile += 'Yes   '
          : eventInfoFile += 'No    '
        : null


      eventInfoFile += `      ${playerDetails.country.code} - ${playerDetails.country.name}`

      eventInfoFile += '\n'

      counter++
    }

    // create the blob for event info text
    const eventInfoFileBlob = new Blob([eventInfoFile], { type: 'text/plain' })
    saveAs(eventInfoFileBlob, 'ATTENDE-LIST.txt')

    this.globals.isBusy.status = false

  }

  public async downloadEventInviteeList(eventDetails: IEventDetails, eventPlayers: IEventPlayerDetails[]) {

    let eventInfoFile: string = ''

    // get the max length of an invited players email
    let maxEmailLength = 0
    eventDetails.invitedPlayers.forEach(p => p.email.length > maxEmailLength ? maxEmailLength = p.email.length : null)

    const padLength = Array(maxEmailLength + 10).join(' ')

    // add event information
    eventInfoFile += 'Event: ' + eventDetails.details.name + '\n\n'
    eventInfoFile += this.pad(padLength, 'Invitee', false) + 'Accepted?\n'

    // write player list
    eventDetails.invitedPlayers.forEach((player) => {
      eventInfoFile += this.pad(padLength, player.email, false)
      eventInfoFile += player.accepted ? 'Yes\n' : 'No\n'
    })

    // create the blob for event info text
    const eventInfoFileBlob = new Blob([eventInfoFile], { type: 'text/plain' })
    saveAs(eventInfoFileBlob, 'INVITEE-LIST.txt')
  }

  private getDeckTextList(deck: IDeckList, isVersion: boolean): string {

    const main = this.deckParser.transform(deck.main, false, false)
    const sideboard = this.deckParser.transform(deck.sideboard, true, false)

    // console.log('main', main)
    // console.log('side', sideboard)

    let deckListText = ''

    let totalCardsMain = 0
    // go through all sections for main deck
    main.forEach(section => {
      totalCardsMain += section.cardCount
      // deckListText += section.text + ' (' + section.cardCount + ')\n'
      section.cards.forEach(card => {
        deckListText += card.qty + ' ' + card.name + '\n'
      })
    })

    deckListText = 'Main Deck (' + totalCardsMain + ')\n\n' + deckListText + '\n\n'

    // go through sideboard
    sideboard.forEach(section => {
      deckListText += section.text + ' (' + section.cardCount + ')\n\n'
      section.cards.forEach(card => {
        deckListText += card.qty + ' ' + card.name + '\n'
      })
      deckListText += '\n'
    })

    return deckListText
  }

  public async downloadDeckList(deck: IDeckList) {
    const isVersion: boolean = !this.router.url.includes('origin')
    const deckDocId: string = this.router.url.split('/')[3]

    if (isVersion) {
      // get the origin deck name and prefix the deck name
      const originDeck = await this.deckService.getDeckById_Promise(deckDocId)
      deck.name = `${originDeck.name} version ${deck.name}`
    }

    const deckList: string = this.getDeckTextList(deck, isVersion)

    const fileContent = `
Deck built with the Tolaria Deck Builder - visit us @ https://tolaria.app

Deck name: ${deck.name}


` + deckList + `
https://tolaria.app/decks/edit/${deckDocId}/version/${isVersion ? deck.docId : 'origin'}`

    // create the blob for event info text
    const deckListBlob = new Blob([fileContent], { type: 'text/plain' })
    saveAs(deckListBlob, `${deck.name}.txt`)
  }

  public async downloadCardsByPlayerList(eventDetails: IEventDetails) {

    // create export object
    let dataExport = {
      players: {},
      cards: {},
      standings: [],
    }

    const addCard = async (scryfallId: string, cardName: string, deckPart: string) => {
      // create card object if not exist
      if (!dataExport.cards[cardName]) {

        this.globals.isBusy.message = `<p class="text-larger">Creating export data</p><p>fetching ${cardName}...</p>`

        const card = await this.cards.getCardById(scryfallId)
        // const simpleCard = simpleCards.find(c => c.name === cardName)
        dataExport.cards[cardName] = {
          total: 0,
          main: 0,
          sideboard: 0,
          type: this.cards.getCardTypes(card.type_line).types[0],
          cmc: card.cmc,
          artist: card.artist,
          colors: {
            colorless: card.colors.length === 0,
            white: card.colors.includes('W'),
            blue: card.colors.includes('U'),
            black: card.colors.includes('B'),
            red: card.colors.includes('R'),
            green: card.colors.includes('G'),
          },
        }

      }

      // increase the count
      dataExport.cards[cardName].total++
      dataExport.cards[cardName][deckPart]++

      return true
    }

    const addCardToPlayer = async (cardName: string, playerName: string, deckPart: string) => {
      // create the player if not present already
      if (!dataExport.players[playerName]) {
        dataExport.players[playerName] = {
          main: {},
          sideboard: {},
        }
      }
      // create the card if not present already
      if (!dataExport.players[playerName][deckPart][cardName]) {
        dataExport.players[playerName][deckPart][cardName] = 0
      }
      // increment the card
      dataExport.players[playerName][deckPart][cardName]++

      return true
    }


    this.globals.isBusy.status = true
    this.globals.isBusy.showMessage = true

    // fetch players
    this.globals.isBusy.message = `<p class="text-larger">Getting players</p>`
    const eventPlayers = await firstValueFrom(this.afs.collection('events').doc(eventDetails.docId).collection<IEventPlayerDetails>('players').get().pipe(
      map(snap => {
        const players: IEventPlayerDetails[] = []
        snap.docs.forEach(doc => {
          if (doc.exists) {
            players.push(doc.data() as IEventPlayerDetails)
          }
        })
        return players
      })
    ))

    // for each player, fetch the deck list (deck or version)
    const decks: IDeckList[] = []
    for await (const player of eventPlayers) {
      this.globals.isBusy.message = `<p class="text-larger">Getting decks</p><p>${player.name}</p>`
      if (player.deckSubmission.deckListDocId !== null && player.deckSubmission.deckListDocId !== undefined && player.deckSubmission.deckListDocId !== '') {
        let deckRef = this.afs.collection('decks').doc(player.deckSubmission.deckListDocId)
        if (player.deckSubmission.deckVersionDocId !== null && player.deckSubmission.deckVersionDocId !== undefined && player.deckSubmission.deckVersionDocId !== '') {
          deckRef.collection('versions').doc(player.deckSubmission.deckVersionDocId)
        }
        const deckList = await firstValueFrom(deckRef.get().pipe(
          map(snap => {
            if (snap.exists) {
              return snap.data() as IDeckList
            }
          })
        ))
        // add the deck to the collection
        decks.push(deckList)
      }
    }

    // create the total card list
    for await (const deck of decks) {
      // store the player name
      const playerName = eventPlayers.find(p => p.playerDocId === deck.playerDocId).name

      // loop through main deck
      for await (const card of deck.main) {
        await addCard(card.scryfallId, card.name, 'main')
        await addCardToPlayer(card.name, playerName, 'main')
      }

      // loop through sideboard deck
      for await (const card of deck.sideboard) {
        await addCard(card.scryfallId, card.name, 'sideboard')
        await addCardToPlayer(card.name, playerName, 'sideboard')
      }
    }

    // get matches for the event
    const matchSnap = await firstValueFrom(this.afs.collection<IMatchData>('matches', ref => ref.where('eventDocId', '==', eventDetails.docId)).get())

    if (matchSnap.empty) {
      console.log('no matches found for event')
    }
    else {

      const eventMatches = matchSnap.docs.map(i => i.data())

      let playersData = this.es.__getExtendedPlayers(eventPlayers, eventMatches, eventDetails)
      playersData = PlayerTieBreakers.compute(playersData)
      playersData = PlayerTieBreakers.sort(playersData)
      playersData = PlayerTieBreakers.rank(playersData, eventDetails.details.structure.isGroup)

      const standings = this.standingsPipe.transform(playersData, { showDroppedPlayers: true, searchString: '' })

      dataExport.standings = standings.map(i => {

        return {
          name: i.name,
          matchPoints: i.matchPoints,
          gamePoints: i.gamePoints,
          opponentMatchWinPercentage: i.opponentMatchWinPercentage,
          gameWinPercentage: i.gameWinPercentage,
          opponentGameWinPercentage: i.opponentGameWinPercentage,
          matchesPlayed: i.matchesPlayed,
          matchesWon: i.matchesWon,
          matchesLost: i.matchesLost,
          matchesDrawn: i.matchesDrawn,
          gamesPlayed: i.gamesPlayed,
          gamesWon: i.gamesWon,
          gamesLost: i.gamesLost,
          gamesDrawn: i.gamesDrawn,
          rank: i.rank,
          groupRank: i.groupRank,
        }

      })

    }


    this.globals.isBusy.message = `Done`
    this.globals.isBusy.showMessage = false
    this.globals.isBusy.status = false

    const json = new Blob([JSON.stringify(dataExport)], { type: 'application/json' })
    saveAs(json, eventDetails.details.name + '.json')


  }

  private pad2(n: number) { return n < 10 ? '0' + n : n }
  private pad(pad: string, str: string, padLeft: boolean) {
    if (typeof str === undefined) {
      return pad
    }
    if (padLeft) {
      return (pad + str).slice(-pad.length)
    } else {
      return (str + pad).substring(0, pad.length)
    }
  }
}

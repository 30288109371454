import { GlobalsService } from 'src/app/services/globals.service'
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { AppComponent } from 'src/app/app.component'
import { faTimes, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { AuthService } from 'src/app/services'
import { IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'app-event-player-details',
  templateUrl: './event-player-details.component.html',
  styleUrls: ['./event-player-details.component.css']
})
export class EventPlayerDetailsComponent {
  @Output() closePanel = new EventEmitter<boolean>()
  @Output() selectPlayer = new EventEmitter<IEventPlayerDetails>()
  @Input() eventData: IEventDetails
  @Input()
  set playerDetailsData(val: IEventPlayerDetails) {
    this.playerDetails = val
  }

  playerDetails: IEventPlayerDetails

  faTimes = faTimes
  faChevronRight = faChevronRight

  constructor(
    private auth: AuthService,
    private app: AppComponent,
    public globals: GlobalsService,
  ) { }

  get isOrganizer(): boolean {
    if (
      this.auth.user.uid === this.eventData.createdByUid ||
      this.eventData.coOrganizers.includes(this.auth.user.playerId) ||
      this.eventData.coOrganizers.includes(this.auth.user.uid)
    ) {
      return true
    }
    return false
  }

  getPercentage(value: number): string {
    const newValue = value * 100
    return newValue.toFixed(2)
  }

  public get isTempPlayer(): boolean {
    return this.playerDetails.playerUid.includes('temp__')
  }

  copyText(text: string) {
    this.app.copyTextToClipboard(text)
  }

  close() {
    this.closePanel.emit(true)
  }

  public get isGroupEvent() {
    return this.eventData.details.structure.isGroup
  }

}

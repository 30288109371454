import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const speakerSlashIcon: IconDefinition = {
  prefix: 'fac',
  iconName: 'speaker-slash',
  icon: [
    640, // SVG view box width
    512, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M636.6,480.5L617,505.8c-5.4,7-15.5,8.2-22.5,2.8L192,197.5l0,0L6.2,53.9c-7-5.4-8.2-15.5-2.8-22.5L23,6.2 c5.4-7,15.5-8.2,22.5-2.8l202,156.1H318l89-89c15-15,41-4.5,41,17v227l28,21.7c0,0,0,0,0,0l157.8,122 C640.8,463.5,642,473.6,636.6,480.5z M192,278.4v49.1c0,13.3,10.7,24,24,24h70.6L192,278.4z'
  ],
} as any;

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-stripe-connected-account-info',
  templateUrl: './stripe-connected-account-info.component.html',
  styleUrls: ['./stripe-connected-account-info.component.css'],
  standalone: true,
  imports: [
    CommonModule,
  ]
})
export class StripeConnectedAccountInfoComponent {
  @Input() modal: boolean = false

  constructor(
    private readonly activeModal: NgbActiveModal,
  ) { }

  public onClose(): void {
    this.activeModal.close()
  }

}

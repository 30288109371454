import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ImagePreviewerComponent } from 'src/app/components/modals/image-previewer/image-previewer.component';

interface ImagePreviewOptions {
  imageUris: string[]
  index?: string
  playerDocId?: string
}


@Injectable({
  providedIn: 'root'
})
export class ImagePreviewService {

  constructor(
    private readonly modalService: NgbModal,
  ) { }

  /**
   * Show a dialog with an image carousel
   * 
   * @param imageUris: string[] -> An array of image urls.
   * @param index: string       -> The index of the image url in the array
   *                               that should be displayed in load.
   *                               Number is zero-based starting at slide-0.
   * @param playerDocId: string -> The player id of the player who uploaded the
   *                               image. If set, a player avatar will be shown
   *                               in the header of the dialog.
   */
  public show({
    imageUris,
    index = 'slide-1',
    playerDocId,
  }: ImagePreviewOptions): void {

    console.log('ImagePreviewService:: show -> ', {
      imageUris,
      index,
      playerDocId,
    })

    const options: NgbModalOptions = {
      centered: true,
      size: 'xl',
      backdrop: true,
      animation: false,
      keyboard: true,
      modalDialogClass: 'tolaria-image-preview-dialog',
      windowClass: 'tolaria-image-preview-window',
    }

    const modal = this.modalService.open(ImagePreviewerComponent, options)
    modal.componentInstance.selectedIndex = index
    modal.componentInstance.imageUris = imageUris
    modal.componentInstance.playerDocId = playerDocId

  }

}

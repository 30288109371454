import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardTypeParser'
})
export class CardTypeParserPipe implements PipeTransform {

  transform(value: string): string {
    const types = value.split(' ');
    let innerHTML = '';
    types.forEach(type => {
      if (type === ' —') {
        innerHTML += type;
      }
      else {
        innerHTML += '<span class="link" (click)="searchType(\'' + type.toLowerCase() +  '\')">' + type +  '</span> ';
      }
    });
    return innerHTML;
  }
}

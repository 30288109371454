import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as firestore from 'firebase/firestore'

export enum NoteType {
  MATCH_NOTE = 'match-note',
  PLAYER_NOTE = 'player-note',
  EVENT_NOTE = 'event-note'
}

export interface INote {
  createdByUid: string;
  createdByPlayerId: string;
  type: NoteType;
  note: string;
  timestamp: number;
  docRefs: INoteDocRefs;
}
export interface INoteDocRefs {
  eventDocId: string | null;
  matchDocId: string | null;
  playerDocId: string | null;
}
export interface INoteMeta extends INote {
  docId: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  private noteColRef: AngularFirestoreCollection<INote>;
  private notes$: Observable<INoteMeta[]>;

  constructor(
    private auth: AuthService,
    private afs: AngularFirestore
  ) {
    this.noteColRef = this.afs.collection<INote>('notes', ref => ref
      .where('createdByUid', '==', this.auth.user.uid)
      .orderBy('timestamp', 'asc'));
    this.notes$ = this.noteColRef.snapshotChanges()
      .pipe(
        map((docs) => {
          return docs.map((doc) => {
            const docId = doc.payload.doc.id;
            const data = doc.payload.doc.data() as INote;
            const docMeta: INoteMeta = {
              docId,
              createdByUid: data.createdByUid,
              createdByPlayerId: data.createdByPlayerId,
              type: data.type,
              note: data.note,
              docRefs: {
                eventDocId: data.docRefs.eventDocId,
                playerDocId: data.docRefs.playerDocId,
                matchDocId: data.docRefs.matchDocId,
              },
              timestamp: data.timestamp
            };
            return docMeta;
          });
        }));
  }

  public createNote(type: NoteType, docRefs: INoteDocRefs, note: string): void {

    // create the not object
    const noteToAdd: INote = {
      createdByUid: this.auth.user.uid,
      createdByPlayerId: this.auth.user.playerId,
      type,
      note,
      docRefs,
      timestamp: firestore.Timestamp.now().seconds
    };

    // add the note to firebase
    this.noteColRef.add(noteToAdd)
      .then(() => {
        console.log('Note added', noteToAdd);
      })
      .catch((err) => {
        console.log(err);
      });

  }

  public deleteNote(docId: string): void {

    // delete the note if it exist
    this.noteColRef.doc(docId).delete()
      .then(() => {
        console.log(`Note with document id ${docId} deleted.`);
      })
      .catch((err) => {
        console.log(err);
      });

  }

  public getNotesForMatchWithId(matchDocId: string): Observable<INoteMeta[]> {
    return this.notes$.pipe(
      map(notes => notes.filter(n => n.docRefs.matchDocId === matchDocId && n.type === NoteType.MATCH_NOTE))
    );
  }
  public getNotesForPlayerWithId(playerDocId: string, allTypes: boolean = false): Observable<INoteMeta[]> {
    if (allTypes) {
      return this.notes$.pipe(
        map(notes => notes.filter(n => n.docRefs.playerDocId === playerDocId))
      );
    }
    return this.notes$.pipe(
      map(notes => notes.filter(n => n.docRefs.playerDocId === playerDocId && n.type === NoteType.PLAYER_NOTE))
    );
  }
  public getNotesForEventWithId(eventDocId: string): Observable<INoteMeta[]> {
    return this.notes$.pipe(
      map(notes => notes.filter(n => n.docRefs.eventDocId === eventDocId && n.type === NoteType.EVENT_NOTE))
    );
  }
}

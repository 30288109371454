<ng-container *ngIf="showAsModal; else content">

  <div class="modal-header">{{ deckName }}</div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button class="btn btn-sm btn-blank" (click)="modal.dismiss()">Close</button>
  </div>

</ng-container>

<ng-template #content>

  <ng-container *ngIf="deckList$ | async as deck">
  
    <ng-container *ngIf="deckPhotoUrlAvailable; else sadFace">
      <img [src]="deck.deckPhotoUrl" [style.width.%]="100">
    </ng-container>
  
    <ng-template #sadFace>
      <div class="d-flex flex-column align-items-center justify-content-center text-secondary">
        <fa-icon [icon]="sadIcon" [fixedWidth]="true" class="text-jumbo "></fa-icon>
        <div class="text-large">Deck photo not available</div>
      </div>
    </ng-template>
  
  </ng-container>

</ng-template>

<div #videoWrapper class="video-element-wrapper {{ videoClass }}">
  <div class="player-name" *ngIf="playerName$ | async as playerName" [innerText]="playerName"></div>

  <div class="video-controls-wrapper d-flex align-items-center justify-content-center flex-column"
    [style.width.px]="(videoSize$ | async).width" [style.height.px]="(videoSize$ | async).height"
    (mouseenter)="videoControlsVisible = true" (mousemove)="videoControlsVisible = true"
    (mouseleave)="videoControlsVisible = false" [ngClass]="{ 'show-controls' : videoControlsVisible }">
    <div class="inner-wrapper d-flex align-items-center justify-content-center flex-row">
      <div class="video-controller d-flex flex-column align-items-center p-3 m-2"
        (click)="videoFlipped = !videoFlipped">
        <fa-icon [fixedWidth]="true" [icon]="faArrowsAltV" class="mb-2"></fa-icon>
        Flip Video
      </div>
      <div class="video-controller d-flex flex-column align-items-center p-3 m-2"
        (click)="videoMirrored = !videoMirrored">
        <fa-icon [fixedWidth]="true" [icon]="faArrowsAltH" class="mb-2"></fa-icon>
        Mirror Video
      </div>
    </div>
    <div class="focus-info" *ngIf="!spectator">Focus opponent video by double clicking or by pressing the <span
        class="key">f</span> key.</div>
  </div>

  <video #videoElement [muted]="muteVideo" [srcObject]="videoStream$ | async"
    [ngClass]="{'mirrored' : videoMirrored, 'flipped' : videoFlipped}" [width]="(videoSize$ | async).width"
    [height]="(videoSize$ | async).height" autoplay playsinline></video>

</div>

<ng-container *ngIf="modal; else nonModal">
  <div class="modal-header">
    Card search
  </div>
  <div class="modal-body p-0">
    <ng-container [ngTemplateOutlet]="nonModal"></ng-container>
  </div>
  <div class="modal-footer d-flex align-items-center jusfify-content-start">
    <div class="form-check form-switch" *ngIf="showResponseSelector && modal">
      <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" [(ngModel)]="insertRules">
      <label class="form-check-label" for="flexSwitchCheckDefault">Insert rules</label>
    </div>
    <span class="flex-grow-1"></span>
    <button class="btn btn-sm btn-blank" (click)="onCancelPress()">Cancel</button>
    <button class="btn btn-sm btn-blank" (click)="onConfirmPress()">Confirm</button>
  </div>
</ng-container>


<ng-template #nonModal>
  <div class="d-flex flex-column scrollable" [ngClass]="styleClass">
    
    <div class="p-3 position-sticky border-secondary"
      style="--bs-border-opacity: .25;"
      [style.top.rem]="0"
      [ngClass]="{ 'border-bottom' : hasResults }">
      <input type="text"
        class="form-control form-control-sm w-100"
        placeholder="Enter card name and hit enter to search"
        (keydown.enter)="search()"
        [(ngModel)]="searchString">
    </div>
  
    <div id="card-search-result" class="d-flex flex-column">
      <ng-container *ngIf="searchResult$ | async as result">
  
        <div class="card-row w-100 d-flex align-items-center p-2 gap-2" *ngFor="let card of result" (click)="onCardSelected(card)">
          
          <img class="rounded rounded-2"
            [style.height.rem]="2.5"
            [style.width.rem]="3.5"
            [src]="card.image_uris.art_crop">
  
          <div class="d-flex flex-grow-1 flex-column">
            <div class="d-flex">
              <div class="flex-grow-1">{{ card.name }}</div>
              <svg class="set-icon">
                <use [attr.xlink:href]="'#sets-' + card.set + '-svg'"></use>
              </svg>
            </div>
            <div class="d-flex text-small text-secondary">
              <div class="flex-grow-1">{{ card.type_line }}</div>
              <div class="">{{ card.set_name }}</div>
            </div>
          </div>
          
  
        </div>
        
      </ng-container>
    </div>
  
  </div>
</ng-template>
<ng-container *ngIf="!onlyCard; else bare">
  <div class="card-wrapper zoom-level-{{ zoomLevel }} {{ card.keyruneCode }}" [ngClass]="{ 'selected' : selected }"
    [ngbTooltip]="card.name" container="body" (dblClick)="pickCard()"
    (contextmenu)="$event.preventDefault(); selectedCard()">

    <div class="picked-card" *ngIf="card.isPicked">
      <div class="picked-card-player-wrapper">
        <app-player-avatar [playerDocId]="card.pickedByPlayerDocId" [round]="false" size="fill" [border]="false">
        </app-player-avatar>
      </div>
    </div>

    <div class="card-image-wrapper">
      <img [src]="card.imageUrl" />
    </div>

    <div class="actions d-flex align-items-center" *ngIf="!card.isPicked">
      <button class="btn btn-blank flex-grow-1" ngbTooltip="Show info" container="body" (click)="openDetails()">
        <fa-icon [icon]="faInfoCircle" [fixedWidth]="true"></fa-icon>
      </button>
      <button class="btn btn-blank flex-grow-1" ngbTooltip="Pick card" container="body"
        [ngClass]="{ 'text-secondary' : disabled }" [disabled]="disabled" (click)="pickCard()">
        <fa-icon [icon]="faCheckCircle" [fixedWidth]="true"></fa-icon>
      </button>
    </div>

  </div>
</ng-container>

<ng-template #bare>

  <div class="card-wrapper zoom-level-{{ zoomLevel }} {{ card.keyruneCode }}">

    <div class="card-image-wrapper">
      <img [src]="card.imageUrl" />
    </div>

  </div>

</ng-template>

import { BehaviorSubject } from 'rxjs';
import { NgbActiveModal, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { IPurchaseMeta, ISubscriptionMeta, PaymentService } from 'src/app/payment/payment.service';
import { faInfo, faPause, faReceipt, faReply, faPlay, faEllipsisH } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.css']
})
export class PurchasesComponent {
  @ViewChild('pauseUntilSelector') pauseUntilSelector: ElementRef
  @ViewChild('cancelConfirmation') cancelConfirmation: ElementRef

  charges$: BehaviorSubject<IPurchaseMeta[]> = new BehaviorSubject<IPurchaseMeta[]>(null)
  subscriptions$: BehaviorSubject<ISubscriptionMeta[]> = new BehaviorSubject<ISubscriptionMeta[]>(null)

  public receiptIcon = faReceipt
  public refundedIcon = faReply
  public infoIcon = faInfo
  public pauseIcon = faPause
  public playIcon = faPlay
  public actionIcon = faEllipsisH

  public showPurchases: boolean = true
  public pauseUntilmodel: NgbDateStruct

  constructor(
    private paymentService: PaymentService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) {
    this.getCharges()
    this.getSubscriptions()
  }

  private getCharges(): void {
    this.paymentService.getCharges().then((res: IPurchaseMeta[]) => this.charges$.next(res))
  }
  private getSubscriptions(): void {
    this.paymentService.getSubscriptions().then((res: ISubscriptionMeta[]) => this.subscriptions$.next(res))
  }

  public pauseSubscription(id: string): void {
    this.modalService.open(this.pauseUntilSelector, {
      animation: true,
      centered: true,
      backdrop: true,
      size: 'sm'
    }).result.then(
      () => {
        // check if date was selected
        if (!this.pauseUntilmodel) { return }
        const pauseUntil = new Date(this.pauseUntilmodel.year, (this.pauseUntilmodel.month - 1), this.pauseUntilmodel.day, 23, 59, 59).getTime() / 1000
        this.paymentService.pauseSubscription(id, pauseUntil).then(() => this.getSubscriptions())
      },
      () => {}
    )
  }

  public cancelSubscription(id: string): void {
    this.modalService.open(this.cancelConfirmation, {
      animation: true,
      centered: true,
      backdrop: true,
      size: 'sm'
    }).result.then(
      () => {
        this.paymentService.cancelSubscription(id)
          .then(() => this.getSubscriptions())
      },
      () => {}
    )
  }

  public resumeSubscription(id: string): void {

  }

  public close(): void {
    this.activeModal.close();
  }

}

import Quill from 'quill'
import { TolariaWysiwygMention } from '../../tolaria-wysiwyg.interfaces'

const Parchment: any = Quill.import('parchment')

export class TolariaPlayerMentionBlot extends Parchment.Embed {

  static blotName = 'tolaria-mention'
  static tagName = 'tolaria-mention'
  static className = 'ql-tolaria-mention'

  static create(data: TolariaWysiwygMention) {
    const node = super.create() as HTMLElement
    node.setAttribute('showControls', String('true'))
    node.setAttribute('playerDocId', String(data.playerDocId))
    node.setAttribute('playerDisplayName', String(data.playerDisplayName))
    return node
  }

  static value(node: HTMLElement) {
    return {
      playerDocId: node.getAttribute('playerDocId') || '',
      playerDisplayName: node.getAttribute('playerDisplayName') || '',
    }
  }

}

<div *ngIf="auth.user$ | async as user" class="p-3">
  <ul class="nav nav-pills mb-2">
    <li class="nav-item">
      <div class="nav-link {{ avatar ? 'active' : '' }}" (click)="avatar = true;">Avatars</div>
    </li>
    <li class="nav-item ms-1">
      <div class="nav-link {{ avatar ? '' : 'active' }}" (click)="avatar = false;">Custom</div>
    </li>
  </ul>
  <ng-container *ngIf="avatar">
    <h1>Select your avatar</h1>
    <div *ngFor="let avatar of img.avatars" class="avatar-selector {{ user.avatar === avatar ? 'selected' : '' }}"
      (click)="auth.updateUserAvatar(avatar)">
      <img [src]="avatar">
    </div>
  </ng-container>
  <ng-container *ngIf="!avatar">
    <h1>Custom avatar</h1>
    <ng-container *ngIf="hasCustomAvatar && !change">
      <div class="row">
        <div class="col">
          <div class="custom-avatar" (click)="change = true;">
            <img class="avatar-image" [src]="user.avatar">
            <div class="edit-button">
              <fa-icon [fixedWidth]="true" [icon]="faCamera"></fa-icon>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!hasCustomAvatar || change && hasCustomAvatar">
      <div class="row">
        <div class="col-8">
          <input type="file" id="customAvatar" name="file" #fileInput (change)="fileChangeEvent($event)">
        </div>
        <div class="col-4">
          <button class="btn btn-main" [disabled]="saveDisabled" (click)="saveImage()">Upload</button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-8">
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="1 / 1"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"></image-cropper>
        </div>
        <div class="col-4">
          <img class="upload-preview" [src]="croppedImage">
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

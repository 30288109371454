import { MessagesService, MessageType } from 'src/app/services/messages.service'
import { Component, ElementRef, Input, OnDestroy, ViewChild, OnInit } from '@angular/core'
import { faPlus, faTimes, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { IPlayerMeta, EventService, GlobalsService } from 'src/app/services'
import { ToastService } from 'src/app/services/toast.service'
import { applicationIcon } from 'src/assets/font-icons/tolaria-icon'
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces'
import { PlayerNameService } from 'src/app/services/players/player-name.service'


@Component({
  selector: 'app-event-to-players',
  templateUrl: './event-to-players.component.html',
  styleUrls: ['./event-to-players.component.css']
})
export class EventToPlayersComponent implements OnDestroy, OnInit {
  @ViewChild('searchInputNoTolaria', { static: false }) searchInputNoTolaria: ElementRef
  @ViewChild('searchInputTolaria', { static: false }) searchInputTolaria: ElementRef
  @ViewChild('addPlayerAfterStart', { static: false }) addPlayerAfterStart: ElementRef

  @Input() eventDoc$: Observable<IEventDetails>
  @Input() eventDocId: string

  showAddPlayerTextarea = false
  showInvitePlayerTextarea = false

  inviteInput = ''
  inviteCustomMessage = ''
  attendeeName = ''
  componentWasDestroyed$ = new Subject<boolean>()
  addToStartedEventPlayerName = ''

  private localEventDoc: IEventDetails
  public typeOfEvent$: BehaviorSubject<string> = new BehaviorSubject<string>(null)
  public exchangePlayer: boolean
  private groupNames: Array<string> = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
  groups: string[] = []
  selectedGroup: string = null

  // font icons
  facTolaria = applicationIcon
  faUser = faUser
  faUsers = faUsers
  faPlus = faPlus
  faTimes = faTimes

  constructor(
    private es: EventService,
    private readonly playerNames: PlayerNameService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private messageService: MessagesService,
    public globals: GlobalsService,
  ) {
  }

  ngOnInit(): void {
    this.eventDoc$.pipe(takeUntil(this.componentWasDestroyed$))
      .subscribe(event => {
        this.localEventDoc = event
        this.setEventType(event)
        if (this.isGroup) {
          this.groups = []
          for (let i = 0; i < event.details.structure.group.numberOfGroups; i++) {
            this.groups.push('GROUP ' + this.groupNames[i])
          }
        }
      })
  }

  ngOnDestroy(): void {
    this.componentWasDestroyed$.next(true)
  }

  public async onTolariaPlayerSubmit(playerDocId: string) {
    let playerMini = this.playerNames.getPlayerById(playerDocId)
    let meta: any = {
      docId: playerMini.id,
      uid: playerMini.uid,
      name: playerMini.name.display,
    }

    // add the player
    this.addTolariaPlayer(this.localEventDoc, meta)

  }

  private setEventType(event: IEventDetails): void {
    if (event.details.structure.isBatch) { this.typeOfEvent$.next('batch') }
    if (event.details.structure.isGroup) { this.typeOfEvent$.next('group') }
    if (event.details.structure.isRoundRobin) { this.typeOfEvent$.next('round-robin') }
    if (event.details.structure.isBracket) { this.typeOfEvent$.next('bracket') }
    if (event.details.structure.isSwiss) { this.typeOfEvent$.next('swiss') }
  }

  public get isGroup(): boolean {
    return this.localEventDoc.details.structure.isGroup
  }
  public get isBracket(): boolean {
    return this.localEventDoc.details.structure.isBracket
  }
  public get isSwiss(): boolean {
    return this.localEventDoc.details.structure.isSwiss
  }
  public get isRoundRobin(): boolean {
    return this.localEventDoc.details.structure.isRoundRobin
  }
  public get isBatch(): boolean {
    return this.localEventDoc.details.structure.isBatch
  }

  public get inviteEnabled(): boolean {
    return this.localEventDoc.statusCode === 0 || this.localEventDoc.statusCode === 14
  }

  addTolariaPlayer(eventDoc: IEventDetails, playerToAdd: IPlayerMeta, overrideEventStarted: boolean = false): void {
    // check if event has started
    if (eventDoc.statusCode > 1 && eventDoc.statusCode !== 14 && !overrideEventStarted) {
      // open the add player event started actions
      this.openModal(eventDoc, playerToAdd, null, true)
      return
    }

    // add the player
    this.es.addPlayerToEvent(eventDoc.docId, playerToAdd.uid, playerToAdd.docId, null, this.selectedGroup)

  }

  addNonTolariaPlayer(eventDoc: IEventDetails, overrideEventStarted: boolean = false): void {


    // prepare the temporary players array
    console.log('... adding player(s)')
    const playersToAdd = this.attendeeName.split('\n')
    console.log(playersToAdd)
    this.showAddPlayerTextarea = false

    if (playersToAdd.length > 1) {

      // check if event has started
      if (eventDoc.statusCode > 1 && eventDoc.statusCode !== 14 && !overrideEventStarted) {
        // open the add player event started actions
        this.toastService.show('Adding multiple players not allowed when the event has been started', { classname: 'error-toast', delay: 5000 })
        return
      }

      const playerNames = []
      playersToAdd.forEach(player => {


        // check if player name exists in players array
        const tempDocId = 'temp__' + player.toLocaleLowerCase()

        if (eventDoc.playerDocIds.indexOf(tempDocId) > -1) {
          this.toastService.show('Player  is already attending', { classname: 'error-toast', delay: 5000 })
        }

        else if (player.length === 0) {
          this.toastService.show('You are not allowed to add players without a name', { classname: 'error-toast', delay: 5000 })
        }

        else if (player.length > 0) {

          playerNames.push(player)

        }

      })


      this.globals.isBusy.message = `Adding ${playersToAdd.length} players to the event, please wait`
      this.globals.isBusy.showMessage = true
      this.globals.isBusy.status = true
      this.es.addMultiplePlayersToEvent(eventDoc.docId, playerNames, this.selectedGroup).then((res: IPromiseResponse) => {
        this.toastService.show(res.text, { classname: 'error-toast', delay: 5000 })
        this.globals.isBusy.showMessage = false
        this.globals.isBusy.status = false
        setTimeout(() => {
          this.attendeeName = ''
          this.searchInputNoTolaria.nativeElement.focus()
        }, 200)
      })


    }
    else {
      // add the player(s)
      playersToAdd.forEach(player => {
        // check if player name exists in players array
        const tempDocId = 'temp__' + player.toLocaleLowerCase()
        if (eventDoc.playerDocIds.indexOf(tempDocId) > -1) {
          this.toastService.show('Player  is already attending', { classname: 'error-toast', delay: 5000 })
        }
        else if (player.length === 0) {
          this.toastService.show('You are not allowed to add players without a name', { classname: 'error-toast', delay: 5000 })
        }
        else if (player.length > 0) {

          // check if event has started
          if (eventDoc.statusCode > 1 && eventDoc.statusCode !== 14 && !overrideEventStarted) {
            // open the add player event started actions
            this.openModal(eventDoc, null, player, false)
            return
          }

          this.es
            .addPlayerToEvent(eventDoc.docId, tempDocId, tempDocId, player, this.selectedGroup)
            .then(() => {
              this.toastService.show('Player added', { classname: 'success-toast', delay: 2000 })

              setTimeout(() => {
                this.attendeeName = ''
                this.searchInputNoTolaria.nativeElement.focus()
              }, 200)
            })
        }
      })
    }
  }

  private openModal(eventDoc: IEventDetails, playerToAdd: IPlayerMeta, playerNameToAdd: string, tolariaPlayer: boolean): void {

    if (this.isBracket) {
      return
    }

    this.addToStartedEventPlayerName = playerToAdd !== null ? playerToAdd.name : playerNameToAdd

    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: 'static',
      keyboard: false,
      size: 'sm',
    }

    this.modalService.open(this.addPlayerAfterStart, modalOptions).result
      .then(
        (result) => {
          console.log(result)
        },
        (selectedAction) => {
          console.log({ selectedAction, eventDoc, playerToAdd, playerNameToAdd })
          switch (selectedAction) {
            case 'cancel':
              break

            default:

              // add the player
              if (tolariaPlayer) {
                this.addTolariaPlayer(eventDoc, playerToAdd, true)
              }
              else {
                this.addNonTolariaPlayer(eventDoc, true)
              }

              // perform more actions depending on the selection
              this.es.performMoreActionsOnAddingPlayer(eventDoc, playerToAdd === null ? playerNameToAdd : playerToAdd, selectedAction, this.selectedGroup)
          }
        })
  }

  async sendInvitations(event: IEventDetails) {

    if (this.validateInvitePlayers()) {
      const emailAddresses = this.inviteInput.split('\n')
      console.log(emailAddresses)
      for (const email of emailAddresses) {
        console.log(`about to send invite to ${email}`)
        if (email.length > 0 && event.invitedPlayers.findIndex(p => p.email === email) === -1) {
          const res = await this.es.addInvitedPlayer(event.docId, event, email, true, true, this.inviteCustomMessage)
          console.log('Invitation response', res)
          if (res.sendTolariaMessage) {
            const msg = 'event-invitation'
            const messageGroupDocId = `eventChatFor[${event.docId}]`
            // send invitation message
            console.log('send message on tolaria')
            this.messageService.postChatMessage(
              msg,
              messageGroupDocId,
              MessageType.EVENT_INVITATION,
              {
                invitedPlayer: res.invitedPlayer,
                eventInvitation: true,
                mentionedPlayerDocIds: [
                  res.invitedPlayer.playerDocId
                ],
                messageDocId: res.messageDocId
              }
            )
          }
          console.log(`...done`)
        }
        else {
          console.log(`...skipped (player already invited)`)
        }
      }

      // all done, clear the input
      this.inviteInput = ''
    }
    else {
      this.toastService.show('Check the entered values, one or more address might be invalid.', { classname: 'error-toast', delay: 6000 })
    }


  }
  
  validateInvitePlayers(): boolean {
    if (this.inviteInput.length > 0) {
      const playersToInvite = this.inviteInput.split('\n')
      let rowCount = 1
      for (const player of playersToInvite) {
        if (player.length > 0 && !this.validateEmail(player)) {
          console.log(`error on row ${rowCount} (${player})`)
          return false
        }
        rowCount++
      }
    }
    return true
  }
  validateEmail(emailAddress: string): boolean {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
      return (true)
    }
    return (false)
  }

}

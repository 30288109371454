<div class="main cards p-0 d-flex flex-column">

  <div class="card-search-bar flex-grow">
    <!-- SIMPLE SEARCH -->
    <ng-container #simpleSearch>
      <div class="cards-filter-panel d-flex align-items-center justify-content-center"
        *ngIf="!cardSearch.showAdvancedSearch">
        <div class="d-inline-block m-1">
          <input type="text" class="form-control form-control-sm" [(ngModel)]="cardSearch.searchString"
            placeholder="Search for Magic cards here..." (keydown.enter)="cardSearch.searchCards()"
            style="width: 30rem;">
        </div>
        <button class="btn btn-sm btn-outline-secondary m-1"
          (click)="cardSearch.showAdvancedSearch = !cardSearch.showAdvancedSearch">
          <fa-icon [icon]="faSlidersH" [fixedWidth]="true" class="me-2"></fa-icon>Advanced
        </button>
      </div>
    </ng-container>
    <!-- ADVANCED SEARCH -->
    <ng-container #advancedSearch>
      <div class="cards-filter-panel p-2 card-filter-panel-advanced" *ngIf="cardSearch.showAdvancedSearch">
        <div class="header-row">
          <div class="p-3 h3 d-inline-block">Advanced Search</div>
        </div>

        <app-search-form class="row p-3" [singleCol]="false"></app-search-form>

        <div class="row pe-3 ps-3 pt-3 pb-2" style="border-top: 1px solid var(--content-inner-border);">
          <div class="col-6">
            <div class="row">
              <div class="col-3"></div>
              <div class="col">
                <button class="btn btn-sm btn-outline-secondary me-2" (click)="cardSearch.searchCards()">Search with
                  these
                  options</button>
                  <button class="btn btn-sm btn-outline-secondary me-2"
                  (click)="cardSearch.clearAdvancedForm()">Clear</button>
                  <button class="btn btn-sm me-2 text-secondary"
                    (click)="cardSearch.showAdvancedSearch = !cardSearch.showAdvancedSearch">Simple search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="card-search-content d-flex flex-row flex-grow-1">
    <section class="search-results scrollable">

      <ng-container *ngIf="cardSearch.cards$ | async as searchResult">
        <div class="search-result p-2 text-center">
          <div class="text-secondary mb-2" *ngIf="searchResult.total_cards > 0; else noCardsFoundOrError">
            Found {{searchResult.total_cards }} cards
          </div>
          <ng-template #noCardsFoundOrError>
            <div class="text-secondary">No results found matching your search criterias.</div>
          </ng-template>
          <ng-container *ngFor="let card of searchResult">
            <app-card-item [cardData]="card" (click)="showCardDetails($event, card)"></app-card-item>
          </ng-container>
        </div>

        <div class="pagination-container">
          <div class="pagination-wrapper">
            <ngb-pagination [collectionSize]="cardSearch.getPageNumbers(searchResult.total_cards).length"
              [(page)]="currentPage" [pageSize]="1" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
              (pageChange)="cardSearch.searchCards({ pageNum: $event })">
              <ng-template ngbPaginationFirst>««</ng-template>
              <ng-template ngbPaginationLast>»»</ng-template>
              <ng-template ngbPaginationPrevious>«</ng-template>
              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
              <ng-template ngbPaginationNext>»</ng-template>
            </ngb-pagination>
          </div>
        </div>
      </ng-container>
    </section>
    <section class="card-details">
      <template #cardDetails></template>
    </section>
  </div>

</div>

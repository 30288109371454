<div class="d-flex flex-column gap-3">
  <div class="d-flex align-items-center btn btn-sm btn-blank px-0" *ngIf="showChatLink" [routerLink]="chatUrl">
    <fa-icon [fixedWidth]="true" [icon]="chat" class="me-2"></fa-icon>
    <span class="">Chat with player</span>
  </div>
  <div class="input-group input-group-sm w-100">
    <input
      id="input"
      class="form-control form-control-sm"
      placeholder="Send a quick message"
      type="text"
      [disabled]="isBusy"
      [(ngModel)]="message"
      (keydown.enter)="sendQuickMessage()">
  
    <button
      class="btn btn-sm btn-outline-secondary"
      [disabled]="isBusy"
      (click)="sendQuickMessage()">
  
      <fa-icon [fixedWidth]="true" [icon]="faPaperPlane" *ngIf="!isBusy; else loader"></fa-icon>
      <ng-template #loader>
        <div class="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </ng-template>
    </button>
  </div>
</div>

import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { MatchService } from 'src/app/services/match.service';
import { AppComponent } from './../../../../../app.component';
import { Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { IFeatureMatchUris, IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { IOnlineUser } from 'src/app/components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-event-to-feature-match-config',
  templateUrl: './event-to-feature-match-config.component.html',
  styleUrls: ['./event-to-feature-match-config.component.css']
})
export class EventToFeatureMatchConfigComponent implements OnInit {
  @Input() matchDocId: string;
  @Input() matchDoc$: Observable<IMatchData>;

  faLink = faLink;

  featureUris: IFeatureMatchUris = {
    player1: '',
    player2: ''
  };

  dbRef: AngularFireList<IOnlineUser>;
  onlineUsers$: Observable<IOnlineUser[]>;

  constructor(
    private app: AppComponent,
    private ms: MatchService,
    private afDb: AngularFireDatabase,
    public modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.dbRef = this.afDb.list(`webrtc/${this.matchDocId}/onlineUsers`);
    // Start Observing the online users to be able to view a list of spectators
    this.onlineUsers$ = this.dbRef.valueChanges();
  }

  copyText(text: string) {
    this.app.copyTextToClipboard(text);
  }

  toggleFeatureMatch(matchDoc: IMatchData) {
    this.ms.toggleFeatureMatch(matchDoc.docId, !matchDoc.isFeatured);
  }

  updateFeatureMatchUris(matchDocId: string) {
    this.ms.updateFeatureMatchUris(matchDocId, this.featureUris);
  }

}

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Component, Input } from '@angular/core'
import { Observable } from 'rxjs'
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { EventService, GlobalsService } from 'src/app/services'
import { PlayerNameService } from 'src/app/services/players/player-name.service'

@Component({
  selector: 'app-event-to-co-organizers',
  templateUrl: './event-to-co-organizers.component.html',
  styleUrls: ['./event-to-co-organizers.component.css']
})
export class EventToCoOrganizersComponent {
  @Input() eventDoc$: Observable<IEventDetails>

  // font icons
  faTrash = faTrash

  constructor(
    private eventService: EventService,
    private readonly playerNames: PlayerNameService,
    public globals: GlobalsService,
  ) {
  }

  onTolariaPlayerSubmit(id: string, eventDocId: string) {
    let player = this.playerNames.getPlayerById(id)
    this.eventService.addCoOrganizerToEvent(eventDocId, player.uid)
  }

  removeCoOrganizer(eventDocId: string, organizerUid: string) {
    this.eventService.removeCoOrganizerToEvent(eventDocId, organizerUid)
  }

}

import { Injectable } from '@angular/core'
import { AngularFirestore, DocumentChangeAction, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore'
import { BehaviorSubject, Observable, Subscription, combineLatest, firstValueFrom, map, switchMap, tap } from 'rxjs'
import { AuthService } from 'src/app/services'
import { IMessageDocument, IReplyToMeta } from 'tolaria-cloud-functions/src/_interfaces'
import { TolariaWysiwygMention } from '../components/tolaria-wysiwyg/tolaria-wysiwyg.interfaces'
import Quill from 'quill'
import { PlayerNameService } from 'src/app/services/players/player-name.service'

interface GroupMessages {
  id: string
  oldestSnap: QueryDocumentSnapshot<IMessageDocument> | null
  allLoaded: boolean
  hasLoadedArchivePreview: boolean
  observables$: BehaviorSubject<Observable<DocumentChangeAction<IMessageDocument>[]>[]>
  observer$: Observable<MessageItem[]>
  query: Query
}
@Injectable({
  providedIn: 'root'
})
export class MessageListService {

  private debug = false

  private logger(type: string, value: string, data?: any) {
    if (this.debug || type === 'error') {
      if (data) {
        console[type](`[MessageListService] -> ${value}`, data)
      }
      else {
        console[type](`[MessageListService] -> ${value}`)
      }
    }
  }

  // Source data
  private _done = new BehaviorSubject(false)
  private _loading = new BehaviorSubject(false)

  // Observable data
  data: Observable<any>
  done: Observable<boolean> = this._done.asObservable()
  loading: Observable<boolean> = this._loading.asObservable()

  private _currentMessageLoad: number = 50
  private _mentionsList: TolariaWysiwygMention[] = []

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly auth: AuthService,
    private readonly playerNames: PlayerNameService,
  ) { }

  private _messagesByGroups = new Map<string, GroupMessages>()
  private async _createGroup(config: IMessageListConfig): Promise<GroupMessages> {
    this.logger('log', 'creating group data according to config', config)

    // store the query object
    let query: Query
    
    // get the initial load of 1 message
    const initSnap = await firstValueFrom(this.firestore.collection<IMessageDocument>('messages', ref => {
      query = ref
      query = query.where('messageGroupDocId', '==', config.groupId)
      query = query.where('matchChat', '==', config.matchChat)
      if (config.matchChat) {
        query = query.where('matchDocId', '==', config.matchDocId)
        query = query.where('spectatorMode', '==', config.spectatorMode)
      }
      query = query.limit(1)
      query = query.orderBy('timestamp', 'desc')
      return query
    }).get())

    this.logger('log', initSnap.empty
      ? 'no data found'
      : initSnap.docs[0].data().archived
        ? 'init load message archived'
        : 'init load message active')

    // create new message observer
    let observers = []
    let newMessages: Observable<DocumentChangeAction<IMessageDocument>[]>

    // check if a message was found or not
    if (initSnap.empty) {
      // set the observer for new messages
      newMessages = this.firestore.collection<IMessageDocument>('messages', ref => {
        query = ref
        query = query.where('messageGroupDocId', '==', config.groupId)
        if (!this.playerNames.currentPlayerIsHero) {
          query = query.where('archived', '==', false)
        }
        query = query.where('matchChat', '==', config.matchChat)
        if (config.matchChat) {
          query = query.where('matchDocId', '==', config.matchDocId)
          query = query.where('spectatorMode', '==', config.spectatorMode)
        }
        query = query.orderBy('timestamp', 'asc')
        return query
      }).snapshotChanges()
      // add the observer to the array of observers
      observers.push(newMessages)
    }
    else {
      // set the observer for new messages
      newMessages = this.firestore.collection<IMessageDocument>('messages', ref => {
        let query: Query = ref
        query = query.where('messageGroupDocId', '==', config.groupId)
        if (!this.playerNames.currentPlayerIsHero) {
          query = query.where('archived', '==', false)
        }
        query = query.where('matchChat', '==', config.matchChat)
        if (config.matchChat) {
          query = query.where('matchDocId', '==', config.matchDocId)
          query = query.where('spectatorMode', '==', config.spectatorMode)
        }
        query = query.orderBy('timestamp', 'asc')
        query = query.startAfter(initSnap.docs[0])
        return query
      }).snapshotChanges()
      // add the observer to the array of observers
      observers.push(newMessages)

      // set the observer for historic messages
      let messageHistory = this.firestore.collection<IMessageDocument>('messages', ref => {
        let query: Query = ref
        query = query.where('messageGroupDocId', '==', config.groupId)
        if (!this.playerNames.currentPlayerIsHero) {
          query = query.where('archived', '==', false)
        }
        query = query.where('matchChat', '==', config.matchChat)
        if (config.matchChat) {
          query = query.where('matchDocId', '==', config.matchDocId)
          query = query.where('spectatorMode', '==', config.spectatorMode)
        }
        query = query.orderBy('timestamp', 'desc')
        query = query.limit(50)
        query = query.startAt(initSnap.docs[0])
        return query
      }).snapshotChanges()
      // add the observer to the array of observers
      observers.push(messageHistory)

    }

    // create a behavior subject to hold all the message observers
    const dynamicObservables$ = new BehaviorSubject<Observable<DocumentChangeAction<IMessageDocument>[]>[]>(observers)
    // create the observable that will dynamically conmbine all the message observers
    const dynamicCombined$ = dynamicObservables$
      .pipe(
        switchMap(obsList => {
          let observer = combineLatest(obsList)
            .pipe(tap((data) => {        
              // get group from Map
              let group = this._messagesByGroups.get(this.getMapId(config))
              // flatten the data
              let dataFlattened = data.flat()
              // get the oldest data object
              let oldest = dataFlattened.pop()
              let oldestSnap = oldest !== undefined ? oldest.payload.doc : null
              if (group.oldestSnap === null) {
                // set the new oldest snap on the group object
                group.oldestSnap = oldestSnap
              }
              else {
                // check if we have reached the end of the message history
                if (group.oldestSnap.id === oldestSnap.id) {
                  // set the all loaded flag
                  group.allLoaded = true
                }
                else {
                  // set the new oldest snap on the group object
                  group.oldestSnap = oldestSnap
                }
              }
            }))
          return observer
        }),
        map((data) => {
          // get all message documents as a flattened array
          let messageDocs = data.flat().map(i => {
            let doc = i.payload.doc.data()
            doc.docId = i.payload.doc.id // make sure to add the document id to the document
            return doc
          })
          // sort the documents
          let sorted = messageDocs
            .sort((a, b) => a.timestamp - b.timestamp)
            .filter(i => i.whisperMode === undefined || i.whisperMode === this.auth.user.playerId || i.whisperMode === '' || i.whisperMode === null)
          // create an array for hold all items
          let items: MessageItem[] = []
          // loop through the sorted documents and map them to messge items
          for (let [index, item] of sorted.entries()) {
            let tmp = this.mapMessage(item, index === 0 ? null : sorted[index - 1])
            items.push(tmp)
          }
          // return the message items
          return items
        })
      )
      

    // create the group
    const groupMap: GroupMessages = {
      id: config.groupId,
      oldestSnap: null,
      allLoaded: initSnap.empty,
      hasLoadedArchivePreview: false,
      observables$: dynamicObservables$,
      observer$: dynamicCombined$,
      query: query,
    }

    // store the group in the map
    this._messagesByGroups.set(this.getMapId(config), groupMap)

    // if init load has been archived and user is not a supporter, load archived preview
    if (!initSnap.empty && initSnap.docs[0].data().archived && !this.playerNames.currentPlayerIsHero) {
      // create a new observer for messages
      let archivePreview = this.firestore.collection<IMessageDocument>('messages', ref => {
        let query: Query = ref
        query = query.where('messageGroupDocId', '==', config.groupId)
        query = query.where('archived', '==', true)
        query = query.where('matchChat', '==', config.matchChat)
        if (config.matchChat) {
          query = query.where('matchDocId', '==', config.matchDocId)
          query = query.where('spectatorMode', '==', config.spectatorMode)
        }
        query = query.where('type', '==', 'chat-message')
        query = query.where('whisperMode', '==', null)
        query = query.orderBy('timestamp', 'desc')
        query = query.limit(3)
        return query
      }).snapshotChanges()

      let observables = groupMap.observables$.getValue()
      observables.push(archivePreview)
      groupMap.observables$.next(observables)
      groupMap.hasLoadedArchivePreview = true
    }

    
    return groupMap

  }
  public loadMoreMessages(config: IMessageListConfig) {
    this.logger('log', 'loadMoreMessages')
    // get mapped group holding the group data if it exist
    let groupMap = this._messagesByGroups.get(this.getMapId(config))

    // check if group map exist
    if (groupMap === undefined) {
      this.logger('log', 'group map not found')
      return false
    }

    if (groupMap.allLoaded) {
      this.logger('log', 'all messages for this group already loaded, lets check if archive preview has been initialized')
      if (groupMap.hasLoadedArchivePreview || this.playerNames.currentPlayerIsHero) {
        this.logger('log', 'archive preview already initialized or player has hero status (access to full history)')
        return false
      }
      else {
        this.logger('log', 'initialize archive preview')
        // create a new observer for messages
        let archivePreview = this.firestore.collection<IMessageDocument>('messages', ref => {
          let query: Query = ref
          query = query.where('messageGroupDocId', '==', config.groupId)
          query = query.where('archived', '==', true)
          query = query.where('matchChat', '==', config.matchChat)
          if (config.matchChat) {
            query = query.where('matchDocId', '==', config.matchDocId)
            query = query.where('spectatorMode', '==', config.spectatorMode)
          }
          query = query.where('type', '==', 'chat-message')
          query = query.where('whisperMode', '==', null)
          query = query.orderBy('timestamp', 'desc')
          query = query.limit(3)
          query = query.startAfter(groupMap.oldestSnap)
          return query
        }).snapshotChanges()

        let observables = groupMap.observables$.getValue()
        observables.push(archivePreview)
        groupMap.observables$.next(observables)
        groupMap.hasLoadedArchivePreview = true
        this.logger('log', 'final observer -> archive preview added')
        return false
      }
    }

    // create a new observer for messages
    let loadMore = this.firestore.collection<IMessageDocument>('messages', ref => {
      let query: Query = groupMap.query
      query = query.limit(11)
      query = query.startAfter(groupMap.oldestSnap)
      return query
    }).snapshotChanges()

    let observables = groupMap.observables$.getValue()
    observables.push(loadMore)
    groupMap.observables$.next(observables)
    this.logger('log', 'new observer additional messages added')
    return true
  }
  public async getMessageObserver(config: IMessageListConfig, mentionList: TolariaWysiwygMention[]) {

    // get mapped group holding the group data if it exist
    let groupMap = this._messagesByGroups.get(this.getMapId(config))

    // check if group map exist
    if (groupMap === undefined) {
      groupMap = await this._createGroup(config)
    }

    return groupMap.observer$
  }

  public mapMessage(doc: IMessageDocument, last: IMessageDocument): MessageItem {

    // convert string message to delta to support new WYSIWYG composer
    if (doc.type === 'chat-message' && doc.delta === undefined) {
      doc.delta = null
      // doc.delta = {
      //   ops: this.htmlToDelta(doc.message)
      // }
    }

    let msg: MessageItem = {
      docId: doc.docId,
      playerDocId: doc.playerDocId,
      isSender: doc.playerDocId === this.auth.user.playerId,
      showHeader: last === null || last === undefined || doc.replyToMeta !== null
        ? true
        : last.playerDocId !== doc.playerDocId,
      showDateSeparator: doc.archived
        ? false
        : last === null || last === undefined
          ? true
          : false,
      dateSeparatorId: this.getTimeFrame(doc.timestamp),
      replyingTo: false,
      message: doc,
      hasReactions: false,
      reactions: [],
      isWhispered: doc.whisperMode !== undefined && doc.whisperMode === this.auth.user.playerId,
      mentionList: this._mentionsList,
      archived: doc.archived,
      showArchivedBlur: doc.archived && !this.playerNames.currentPlayerIsHero,
      isPinned: doc.isPinned ? doc.isPinned : false,
      pinnedBy: doc.pinnedBy !== undefined && doc.pinnedBy !== null
        ? doc.pinnedBy === this.playerNames.currentPlayersMini.id
          ? 'Pinned by you'
          : 'Pinned by ' + this.playerNames.getPlayerById(doc.pinnedBy).name.first
        : ''
    }

    if (last !== null && last !== undefined) {
      const thisDate = new Date(doc.timestamp * 1000)
      const lastDate = new Date(last.timestamp * 1000)
      msg.showDateSeparator = thisDate.getDate() > lastDate.getDate()
    }

    if (!msg.showHeader && last.playerDocId === doc.playerDocId && (doc.timestamp - last.timestamp) > 600) {
      msg.showHeader = true
    }

    if (doc.replyToMeta !== undefined && doc.replyToMeta !== null) {
      msg.replyToMeta = doc.replyToMeta
      msg.showHeader = true
      msg.replyingTo = true
    }

    if (doc.reactions !== undefined && doc.reactions !== null) {
      msg.hasReactions = true
      Object.keys(doc.reactions).forEach((reaction) => {
        // check if any has reacted with this
        if (doc.reactions[reaction].length > 0) {
          let players = doc.reactions[reaction]
            .filter((i: string) => i !== this.playerNames.currentPlayersMini.id)
            .map((i: string) => `${this.playerNames.getPlayerById(i).name.first} ${this.playerNames.getPlayerById(i).name.last}`)
          if (doc.reactions[reaction].includes(this.playerNames.currentPlayersMini.id)) {
            players.push(doc.reactions[reaction].length === 1 ? 'You' : 'you')
          }

          msg.reactions.push({
            isNative: !reaction.includes(':'),
            isShortcode: reaction.includes(':'),
            reaction,
            youReacted: doc.reactions[reaction].includes(this.playerNames.currentPlayersMini.id),
            playerDocIds: doc.reactions[reaction],
            count: doc.reactions[reaction].length,
            reactedBy: this.joinWithCommasAnd(players),
          })
          msg.reactions.sort((a, b) =>
            (a.reaction > b.reaction) ? -1 :
              ((b.reaction > a.reaction) ? 1 : 0))
        }
      })
    }

    return msg

  }
  

  private localQill = null
  private htmlToDelta(html: string): any {
    let htmlTagsPattern = /<\/?[\w\s="/.'-]*>/g
    if (htmlTagsPattern.test(html)) {
      if (this.localQill === null) {
        const div = document.createElement('div')
        this.localQill = new Quill(div, { debug: 'warn', readOnly: true, })
      }
      const delta = this.localQill.clipboard.convert(html)
      return JSON.parse(JSON.stringify(delta.ops))
    }
    return [{insert: html}]
  }

  private getMapId(config: IMessageListConfig): string {
    return `${config.groupId}>matchChat:${config.matchChat}>matchDocId:${config.matchDocId}>spectatorMode:${config.spectatorMode}`
  }
  public get currentLimit(): number {
    return this._currentMessageLoad
  }
  private getTimeFrame(timestamp: number): 'yesterday' | 'last-week' | 'last-month' | null {
    const currentDate = new Date()
    const inputDate = new Date(timestamp * 1000)

    // Calculate the difference in days
    const timeDifference = (currentDate.getTime() - inputDate.getTime()) / (1000 * 60 * 60 * 24)

    if (timeDifference === 1) {
      return 'yesterday'
    }
    else if (timeDifference > 1 && timeDifference < 7) {
      return 'last-week'
    }
    else if (timeDifference >= 7 && timeDifference < 30) {
      return 'last-month'
    }
    return null
  }
  private joinWithCommasAnd(array: string[]) {
    if (array.length === 0) {
      return ''
    }
    else if (array.length === 1) {
      return array[0]
    }
    else {
      const lastElement = array.pop() // Remove the last element
      const joinedString = array.join(', ') + 'and ' + lastElement
      return joinedString
    }
  }

}


export interface MessageGroup {
  id: string
  oldest: QueryDocumentSnapshot<IMessageDocument>
  new: {
    observer: Observable<DocumentChangeAction<IMessageDocument>[]>
    subscription: Subscription
  }
  old: BehaviorSubject<MessageItem[]>
  loaded: number
  query: Query
}

export interface MessageDocs {
  new: BehaviorSubject<MessageItem[]>
  old: BehaviorSubject<MessageItem[]>
  all: BehaviorSubject<MessageItem[]>
}

export interface MessageItem {
  docId: string
  playerDocId: string
  isSender: boolean
  showHeader: boolean
  showDateSeparator: boolean
  dateSeparatorId: string
  replyingTo: boolean
  message: IMessageDocument
  hasReactions: boolean
  reactions?: IMessageReactionMeta[]
  replyToMeta?: IReplyToMeta
  isWhispered: boolean
  mentionList: TolariaWysiwygMention[]
  archived: boolean
  showArchivedBlur: boolean
  isPinned: boolean
  pinnedBy: string
}

export interface IMessageReactionMeta {
  reaction: string
  isNative: boolean
  isShortcode: boolean
  youReacted: boolean
  playerDocIds: string[]
  count: number
  reactedBy: string
}

export interface IMessageListConfig {
  groupId: string
  matchChat: boolean
  matchDocId: string
  spectatorMode: boolean
  setLimit?: boolean
  limitToSet?: number
}

interface IQueryOptions {
  limit: number
  startingAt: number
  type: 'old' | 'new'
  latest: QueryDocumentSnapshot<IMessageDocument>
}


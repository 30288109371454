import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastService } from 'src/app/services/toast.service'
import { ActivatedRoute } from '@angular/router'
import { applicationIcon } from 'src/assets/font-icons/tolaria-icon'
import { faClone } from '@fortawesome/free-regular-svg-icons'
import { faAngleDown, faAngleRight, faSave, faTrash, faPlus, faCompress, faExpand, faAngleLeft, faAngleUp, faTrashAlt, faCamera, faLayerGroup, faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import { ICardMeta, IDeckList, DecksService, DeckPart } from 'src/app/services/decks.service'
import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import { DragEndEvent } from 'angular-draggable-droppable'
import html2canvas from 'html2canvas'
export interface IDropData {
  card: ICardMeta
  fromZone: string
  index: number
}
@Component({
  selector: 'app-deck-visual-editor',
  templateUrl: './deck-visual-editor.component.html',
  styleUrls: ['./deck-visual-editor.component.css']
})
export class DeckVisualEditorComponent {
  @Input()
  set deckList(deck: IDeckList) {
    
    this.deckOriginString = JSON.stringify(deck)

    if (!deck.cardZoomLevel) { deck.cardZoomLevel = 1 }

    const lastMod = `?${Date.now()}`

    // remove any params suffixed on image urls
    deck.main.forEach(i => i.imageUrl = i.imageUrl.replace(/\?.*/, '').replace(/\?/g, '') + lastMod)
    deck.sideboard.forEach(i => i.imageUrl = i.imageUrl.replace(/\?.*/, '').replace(/\?/g, '') + lastMod)
    deck.maybeboard.forEach(i => i.imageUrl = i.imageUrl.replace(/\?.*/, '').replace(/\?/g, '') + lastMod)

    this.deck = deck

  }

  @ViewChild('mainDeckDropZone', { static: true }) mainDeckDropZone: ElementRef
  @ViewChild('deckContainer', { static: true }) deckContainer: ElementRef

  public icons = {
    faTrash: faTrash,
    faTrashAlt: faTrashAlt,
    faSave: faSave,
    faAngleRight: faAngleRight,
    faAngleDown: faAngleDown,
    faAngleLeft: faAngleLeft,
    faAngleUp: faAngleUp,
    faPlus: faPlus,
    faCompress: faCompress,
    faExpand: faExpand,
    faClone: faClone,
    faCamera: faCamera,
    faLayerGroup: faLayerGroup,
    tolaria: applicationIcon,
    faSearchPlus: faSearchPlus,
  }
  public defaultCardSize = {
    width: 10,
    height: 13.93471718,
  }

  private deckOriginString: string
  private dropCoords: DragEndEvent

  public DECKPART = DeckPart
  public deck: IDeckList
  public selectedIndexes: number[] = []
  public selectedIndex: number
  public selectedSBIndex: number
  public selectedMBIndex: number
  public fullscreen = false
  public mouseSelectionEnabled = true
  public multiSelectionActive = false
  public takingScreenShot = false


  constructor(private deckService: DecksService, private route: ActivatedRoute, private toastService: ToastService, private modalService: NgbModal) { }

  public takeScreenShot(): void {
    this.takingScreenShot = true
    
    setTimeout(() => {
      // const screenShotArea = document.getElementById(this.deck.docId)
      const screenShotArea = this.deckContainer.nativeElement
      html2canvas(screenShotArea, {
        allowTaint: false,
        useCORS: true,
      })
        .then((canvas) => {

          const image = canvas
            .toDataURL('image/png', 0.75)
            .replace('image/png', 'image/octet-stream')

          let downloadLink = document.createElement('a')
          downloadLink.setAttribute('download', `${this.deck.docId}.png`)
          downloadLink.setAttribute('href', image)
          downloadLink.click()

        })
        .then(() => {
          this.takingScreenShot = false
        })
    }, 150)

  }
  public mainDeckCardTracker(index: number, item: ICardMeta): string {
    return `${item.metaUuid}`
  }
  public cardDropped(dropData: IDropData, zone: DeckPart): void {
    if (this.modalService.hasOpenModals()) { return }
    console.log(`card dropped into zone: ${zone}`, dropData)
    switch (zone) {
      case DeckPart.TRASH:
        // check if deck is locked
        if (this.deck.isLocked) {
          this.toastService.show('Deck is LOCKED, no card added', { classname: 'error-toast', delay: 2000 })
          break
        }
        this.deck[dropData.fromZone].splice(this.deck[dropData.fromZone].findIndex((c: ICardMeta) => c.metaUuid === dropData.card.metaUuid), 1)
        this.saveDeck(this.deck)
        break

      default:
        // update coords
        if (zone === DeckPart.MAIN && dropData.fromZone === DeckPart.MAIN) {
          // remove the card from the array
          this.deck[dropData.fromZone].splice(this.deck[dropData.fromZone].findIndex((c: ICardMeta) => c.metaUuid === dropData.card.metaUuid), 1)

          let newX = dropData.card.coords.x + this.dropCoords.x
          let newY = dropData.card.coords.y + this.dropCoords.y
          const dropZoneW = this.mainDeckWidth
          const dropZoneH = this.mainDeckHeight
          newX > dropZoneW ? dropData.card.coords.x = dropZoneW - this.cardWidth : newX < 0 ? dropData.card.coords.x = 0 : dropData.card.coords.x = newX
          newY > dropZoneW ? dropData.card.coords.y = dropZoneH - this.cardHeight : newY < 0 ? dropData.card.coords.y = 0 : dropData.card.coords.y = newY
        }
        else if (zone === DeckPart.MAIN && dropData.fromZone !== DeckPart.MAIN) {
          // check if deck is locked
          if (this.deck.isLocked) {
            this.toastService.show('Deck is LOCKED, no card added', { classname: 'error-toast', delay: 2000 })
            break
          }

          // remove the card from the array
          this.deck[dropData.fromZone].splice(this.deck[dropData.fromZone].findIndex((c: ICardMeta) => c.metaUuid === dropData.card.metaUuid), 1)
          dropData.card.coords = this.deckService.calculateCoords(this.deck.main.length, this.deck.cardZoomLevel)
        }
        else {
          // check if deck is locked
          if (this.deck.isLocked) {
            this.toastService.show('Deck is LOCKED, no card added', { classname: 'error-toast', delay: 2000 })
            break
          }

          // remove the card from the array
          this.deck[dropData.fromZone].splice(this.deck[dropData.fromZone].findIndex((c: ICardMeta) => c.metaUuid === dropData.card.metaUuid), 1)
          dropData.card.coords.x = 0
          dropData.card.coords.y = 0
        }

        this.deck[zone].push(dropData.card)
        this.saveDeck(this.deck)
        break
    }
  }
  public cardDragEnd(event: DragEndEvent): void {
    if (this.modalService.hasOpenModals()) { return }
    this.dropCoords = event
  }
  public rearangeCards(type: string): void {
    let tmpCards: ICardMeta[] = JSON.parse(JSON.stringify(this.deck.main))
    let coords = {
      x: this.gridSizeX,
      y: this.gridSizeY * 2,
    }

    switch (type) {

      case 'color':
        tmpCards = tmpCards
          .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          .sort((a, b) => this.getSortOrderColor(a.colors) - this.getSortOrderColor(b.colors))
        console.log(tmpCards)


        for (let i = 0; i < tmpCards.length; i++) {
          if (i > 0) {
            if (JSON.stringify(tmpCards[i - 1].colors) === JSON.stringify(tmpCards[i].colors)) {
              tmpCards[i].coords = JSON.parse(JSON.stringify(coords))
              coords.y += this.gridSizeY
            }
            else {
              coords.y = this.gridSizeY * 2
              coords.x += this.gridSizeX + this.cardWidth
              tmpCards[i].coords = JSON.parse(JSON.stringify(coords))
              coords.y += this.gridSizeY
            }
          }
          else {
            tmpCards[i].coords = JSON.parse(JSON.stringify(coords))
            coords.y += this.gridSizeY
          }
        }

        console.log(tmpCards)

        this.deck.main = tmpCards
        this.saveDeck(this.deck)
        break

      case 'cmc':
        tmpCards = tmpCards.sort((a, b) => a.cmc - b.cmc)
        console.log(tmpCards)

        for (let i = 0; i < tmpCards.length; i++) {
          if (i > 0) {
            if (JSON.stringify(tmpCards[i - 1].cmc) === JSON.stringify(tmpCards[i].cmc)) {
              tmpCards[i].coords = JSON.parse(JSON.stringify(coords))
              coords.y += this.gridSizeY
            }
            else {
              coords.y = this.gridSizeY * 2
              coords.x += this.gridSizeX + this.cardWidth
              tmpCards[i].coords = JSON.parse(JSON.stringify(coords))
              coords.y += this.gridSizeY
            }
          }
          else {
            tmpCards[i].coords = JSON.parse(JSON.stringify(coords))
            coords.y += this.gridSizeY
          }
        }

        console.log(tmpCards)

        this.deck.main = tmpCards
        this.saveDeck(this.deck)
        break

      case 'type':
        tmpCards = tmpCards
          .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          .sort((a, b) => this.getSortOrderType(a.typeLine) - this.getSortOrderType(b.typeLine))
        console.log(tmpCards)

        for (let i = 0; i < tmpCards.length; i++) {
          if (i > 0) {
            if (this.getSortOrderType(tmpCards[i - 1].typeLine) === this.getSortOrderType(tmpCards[i].typeLine)) {
              tmpCards[i].coords = JSON.parse(JSON.stringify(coords))
              coords.y += this.gridSizeY
            }
            else {
              coords.y = this.gridSizeY * 2
              coords.x += this.gridSizeX + this.cardWidth
              tmpCards[i].coords = JSON.parse(JSON.stringify(coords))
              coords.y += this.gridSizeY
            }
          }
          else {
            tmpCards[i].coords = JSON.parse(JSON.stringify(coords))
            coords.y += this.gridSizeY
          }
        }

        console.log(tmpCards)

        this.deck.main = tmpCards
        this.saveDeck(this.deck)
        break

      case '10by6':
        tmpCards = tmpCards
          .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          .sort((a, b) => this.getSortOrderType(a.typeLine) - this.getSortOrderType(b.typeLine))
        console.log(tmpCards)

        for (let i = 0; i < tmpCards.length; i++) {
          tmpCards[i].coords = this.deckService.calculateCoords(i, this.deck.cardZoomLevel)
        }

        this.deck.main = tmpCards
        this.saveDeck(this.deck)
        break

    }
  }
  private getSortOrderColor(colors: string[]): number {
    if (colors.length === 1 && colors.includes('W')) { return 1 }
    if (colors.length === 1 && colors.includes('U')) { return 2 }
    if (colors.length === 1 && colors.includes('B')) { return 3 }
    if (colors.length === 1 && colors.includes('R')) { return 4 }
    if (colors.length === 1 && colors.includes('G')) { return 5 }
    if (colors.length > 1) { return 6 }
    if (colors.length === 0) { return 7 }

    return 8
  }
  private getSortOrderType(typeLine: string): number {
    if (typeLine.toLowerCase().includes('creature')) { return 1 }
    if (typeLine.toLowerCase().includes('enchantment')) { return 2 }
    if (typeLine.toLowerCase().includes('artifact')) { return 3 }
    if (typeLine.toLowerCase().includes('sorcery')) { return 4 }
    if (typeLine.toLowerCase().includes('instant')) { return 5 }
    if (typeLine.toLowerCase().includes('planeswalker')) { return 6 }
    if (typeLine.toLowerCase().includes('land')) { return 7 }

    return 8
  }
  public saveDeck(deck: IDeckList): void {
    if (this.changesMade) {
      this.deckService.saveDeckList(deck)
    }
  }
  // GETTERS
  public get changesMade(): boolean {
    return JSON.stringify(this.deck) !== this.deckOriginString
  }
  public get gridSize(): number {
    return ((this.defaultCardSize.width * this.deck.cardZoomLevel) * 16) / 10
  }
  public get gridSizeX(): number {
    return ((this.defaultCardSize.width * this.deck.cardZoomLevel) * 16) / 10
  }
  public get gridSizeY(): number {
    return ((this.defaultCardSize.height * this.deck.cardZoomLevel) * 16) / 10
  }
  public get infoData(): any {
    return {
      selectedIndex: this.selectedIndex,
      selectedSBIndex: this.selectedSBIndex,
      selectedMBIndex: this.selectedMBIndex,
      selectedIndexes: this.selectedIndexes,
      mouseSelectionEnabled: this.mouseSelectionEnabled,
      multiSelectionActive: this.multiSelectionActive,

    }
  }
  private get cardWidth(): number {
    return (this.defaultCardSize.width * this.deck.cardZoomLevel) * 16
  }
  private get cardHeight(): number {
    return (this.defaultCardSize.height * this.deck.cardZoomLevel) * 16
  }
  private get mainDeckWidth(): number {
    return this.mainDeckDropZone.nativeElement.getBoundingClientRect().width
  }
  private get mainDeckHeight(): number {
    return this.mainDeckDropZone.nativeElement.getBoundingClientRect().height
  }
  public get isVersion(): boolean {
    return this.route.snapshot.params.versionDocId !== undefined
  }
  public get versionDocId(): string {
    return this.route.snapshot.params.versionDocId !== undefined ? this.route.snapshot.params.versionDocId : null
  }
  public get deckDocId(): string {
    return this.route.snapshot.params.deckDocId
  }

}

<div class="card rounded-3 shadow-sm" [ngClass]="styleClass">

  <div class="card-body no-hover d-flex flex-column gap-2">

    <div class="d-flex align-items-center mb-2 my-0 gap-2">
      <app-player-avatar [playerDocId]="match.opponentDocId" size="small"></app-player-avatar>
      <app-player-display-name class="text-larger fw-normal text-truncate" [playerDocId]="match.opponentDocId" [name]="match.opponentName"></app-player-display-name>
    </div>

    <div class="d-flex align-items-center text-secondary gap-2 cursor-pointer link" [routerLink]="match.tournamentLink">
      <fa-icon [icon]="icon.tournament"></fa-icon>
      <span class="flex-grow-1">{{ match.eventName }}</span>
    </div>
    
    <div class="d-flex align-items-center text-secondary gap-3" *ngIf="match.table">
      <span class="alert alert-info m-0 p-0 px-2 d-flex align-items-center gap-2" [style.lineHeight.rem]="1.5">
        <span class="text-medium">Table</span>
        <span class="fw-bold">{{ match.table }}</span>
      </span>
      <span class="d-flex align-items-center gap-2" *ngIf="match.seat" [style.lineHeight.rem]="1.5">
        <fa-icon [icon]="icon.chair"></fa-icon>
        <span>{{ match.seat }}</span>
      </span>
    </div>

    <div class="d-flex align-items-center gap-2" *ngIf="match.isScheduleable">
      <div class="text-secondary flex-grow-1">Appointments:</div>
      <button *ngIf="match.hasAppointments"
        class="btn btn-sm"
        [ngClass]="{ 'btn-outline-success' : hasAcceptedAppointments , 'btn-outline-secondary' : !hasAcceptedAppointments }"
        container="body"
        triggers="manual"
        popoverClass="popover-no-arrow popover-no-padding"
        #p="ngbPopover"
        (click)="p.open()"
        [popperOptions]="popperOptions"
        [autoClose]="'outside'"
        [ngbPopover]="popAppointments">
        <fa-icon [icon]="icon.calendar"></fa-icon>
        <span class="ms-2 badge"
          [ngClass]="{ 'text-bg-secondary' : !hasAcceptedAppointments, 'text-bg-success' : hasAcceptedAppointments }">
          {{ match.appointments.total }}
        </span>
      </button>
      <button class="btn btn-sm btn-outline-secondary" [disabled]="match.isReported" (click)="newMatchAppointment()">New</button>
    </div>

    <div class="flex-grow-1"></div>

    <div class="d-none d-sm-flex footer flex-grow-1 mt-3 align-items-end" *ngIf="match.showMatchRoomLink">
      <div class="d-flex align-items-center gap-2 w-100">
        <button type="button"
          class="btn btn-outline-primary d-flex align-items-center flex-grow-1 text-no-wrap"
          (click)="onOpenRoom()">
          <span class="ms-2 flex-grow-1">Open match room</span>
        </button>
        <ng-container *ngIf="match.isCasual">
          <button type="button" class="btn btn-sm btn-danger text-no-wrap" (click)="onDelete()">
            <fa-icon [icon]="icon.delete" class="me-2"></fa-icon>Delete
          </button>
        </ng-container>
      </div>
    </div>

    <div class="d-flex align-items-center gap-2">
      <div class="text-secondary">Result:</div>
      <ng-container *ngIf="match.isReported; else reportButton">
        <div class="flex-grow-1 text-start">
          <div class="btn btn-sm py-0 {{ match.isWin ? 'btn-outline-success' : match.isDraw ? 'btn-outline-warning' : 'btn-outline-danger' }}">
            {{ match.isWin ? 'Win' : match.isDraw ? 'Draw' : 'Loss' }}
          </div>
        </div>
        <span class="badge text-bg-success" ngbTooltip="Games won" container="body" [innerHTML]="match.gameWins"></span>
        <span class="badge text-bg-danger" ngbTooltip="Games lost" container="body" [innerHTML]="match.gameLosses"></span>
        <span class="badge text-bg-secondary" ngbTooltip="Drawn games" container="body" [innerHTML]="match.gameDraws"></span>
      </ng-container>
      <ng-template #reportButton>
        <div class="flex-grow-1"></div>
        <button class="btn btn-primary" (click)="onReportMatch()">Report</button>
      </ng-template>
    </div>    

  </div>

</div>



<ng-template #popAppointments>
  <div class="d-flex flex-column pb-3">

    <div class="text-larger p-3">Appointments</div>

    <ng-container *ngIf="match.appointments.total > 0">
      <app-match-appointment-item *ngFor="let item of appointments" [appointment]="item"></app-match-appointment-item>
    </ng-container>
    
  </div>
</ng-template>
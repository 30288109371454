export interface IPlayerMinified {
  [key:string]: IPlayerMini
}

export interface IPlayerNameMap {
  [key:string]: string
}

export interface IPlayerMini {
  id: string
  name: {
    first: string
    last: string
    nick: string
    display: string
  },
  email: string
  avatar: string
  uid: string
  country: string
  region: string
  timezone: string
  utcOffset: string
}

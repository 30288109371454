import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { faPatreon, faPaypal } from '@fortawesome/free-brands-svg-icons';
import { NgbCarouselModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ClubAvatarComponent } from '../../clubs/club-avatar/club-avatar.component';
import { CommonModule } from '@angular/common';
import { IPlayerBadgeMeta } from '../_utilz/player-interfaces';
import { PlayerBadgeService } from '../_utilz/services/player-badge.service';


@Component({
  selector: 'app-player-badges-carousel',
  templateUrl: './player-badges-carousel.component.html',
  styleUrls: ['./player-badges-carousel.component.css'],
  standalone: true,
  imports: [
    NgbCarouselModule,
    ClubAvatarComponent,
    NgbTooltip,
    CommonModule,
  ]
})
export class PlayerBadgesCarouselComponent implements OnInit {
  @Input() playerDocId: string;
  @Input() borderClass: string = 'border border-3 border-dark';

  faPaypal = faPaypal;
  faPatreon = faPatreon;

  public playerBadges$: Observable<IPlayerBadgeMeta[]>

  constructor(
    private readonly playerBadges: PlayerBadgeService,
  ) {
  }

  ngOnInit(): void {
    this.playerBadges$ = this.playerBadges.getBadges(this.playerDocId)
  }

}

<ng-container [ngSwitch]="chatType">
  

  <div *ngSwitchCase="'event-chat'" class="d-flex flex-column gap-3">
    <ng-container *ngIf="event$ | async as event">
      
      <img [src]="event.bannerUrl" class="rounded rounded-4" [style.width.rem]="25">

      <div>
        This is the main message group for the tournament {{ event.details.name }}.<br>
        Have a nice time, be friendly, and be nice to each other! 🤗
      </div>

      <div class="d-flex">
        <div class="btn btn-outline-secondary" [routerLink]="['/tournament', event.docId]">
          To the lobby
        </div>
      </div>

    </ng-container>
  </div>
  
  
  <div *ngSwitchCase="'single-chat'" class="d-flex flex-column gap-3 p-3">
    <ng-container *ngIf="otherGroupMembers.length > 0; else userIsAlone">
      <div class="d-flex align-items-center gap-3">
        <app-player-avatar
            [playerDocId]="otherGroupMembers[0].id"
            [border]="false"
            [round]="false"
            size="mega"
            class="rounded-4 overflow-hidden">
          </app-player-avatar>
      </div>
      <div class="group-message">
        This conversation is just between 
        <tolaria-player-mention class="ql-tolaria-mention" [playerdisplayname]="otherGroupMembers[0].name.display" [playerdocid]="otherGroupMembers[0].id"></tolaria-player-mention>
        and you. Check out their profile to learn more about them.
      </div>
      <div class="d-flex">
        <button class="btn btn-outline-secondary" (click)="onShowPlayerProfile(otherGroupMembers[0].id)">View Profile</button>
      </div>
    </ng-container>
    <ng-template #userIsAlone>
      <div class="group-message">
        You seem to be the only one left in this conversation.
      </div>
    </ng-template>
  </div>
  
  
  <div *ngSwitchCase="'group-chat'" class="d-flex flex-column gap-3">
    <div class="d-flex align-items-center gap-3">
      <app-player-avatar *ngFor="let player of otherGroupMembers"
        [playerDocId]="player.id"
        [border]="false"
        [round]="false"
        class="rounded-3 overflow-hidden">
      </app-player-avatar>
    </div>
    <div class="group-message">
      This is the very beginning of your direct message history with 
      <ng-container *ngFor="let player of otherGroupMembers; let last = last">
        <tolaria-player-mention class="ql-tolaria-mention" [playerdisplayname]="player.name.first" [playerdocid]="player.id"></tolaria-player-mention>
        <span *ngIf="last"> and </span>
        <span *ngIf="!last">, </span>
      </ng-container>
      and you. Check out their profile to learn more about them.
    </div>
  </div>
  
  
  <div *ngSwitchCase="'down-to-play'" class="d-flex flex-column gap-3">

    <img [style.width.rem]="25"
      class="rounded rounded-4"
      src="assets/down-to-play-mat.png">

    <div>
      Are you ready to dive into the magical realms for a battle of wits? ✨
    </div>

    <div>
      Adjust your camera for a crystal-clear view, unfurl your trusty playmat,
      and let the shuffling symphony begin.
      Gather your decks, summon your strategy, and prepare for battle!
    </div>

    <div>
      This is the general message group where you can communicate with others for pickup-games.
      Have fun and let the mana flow!
    </div>

    <div class="d-flex">
      <div class="btn btn-outline-secondary" [routerLink]="['/down-to-play']">
        To casual play
      </div>
    </div>

  </div>


  <div *ngSwitchCase="'organizers'" class="d-flex flex-column gap-3">

    <img [style.width.rem]="25"
      class="rounded rounded-4"
      src="https://cards.scryfall.io/art_crop/front/e/4/e48b1c51-c0fd-4c08-8631-80f507b04d28.jpg">

    <div>
      This is the message group for all the organizers of Tolaria.app
    </div>

    <div>
      If you have any questions, feel free to pop them in here. We all can help eachother out.
    </div>

    <div class="d-flex gap-3">
      <div class="btn btn-outline-secondary" (click)="onCreateTournament()">
        Create a Tournament
      </div>
      <div class="btn btn-outline-secondary" (click)="onAcceptPayments()">
        How to accept payments
      </div>
    </div>

  </div>


</ng-container>
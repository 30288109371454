import { MessageModalComponent, IModalButton } from './message-modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class MessageModalService {

  constructor(private modalService: NgbModal) { }

  /**
   * Open a confirmation prompt with various look and feel.
   * Available types are Warning, Danger, Success, Info, and Default.
   *
   *
   * @param config
   * @returns null | string (the value defined by the button.value)
   */
  public open(config: {
    type: 'warning' | 'danger' | 'success' | 'info' | 'default',
    title: string,
    message: string,
    buttons: IModalButton[],
    size?: 'sm' | 'lg' | 'xl',
    hideIcon?: boolean,
    confirmInput?: boolean,
    confirmText?: string,
    centered?: boolean,
    centerButtons?: boolean
    backdropStatic?: boolean
    customIcon?: IconDefinition
    imageAsIcon?: boolean
    imageUrl?: string
  }): Promise<string | null> {

    return new Promise(async (resolve) => {

      if (config.confirmInput && (!config.confirmText || config.confirmText === '')) {
        console.error('[MessageModalService] --> confirmation input active without a confirmation text property set!')
        resolve(null)
      }

      const options: NgbModalOptions = {
        fullscreen: false,
        animation: true,
        size: config.size ? config.size : 'lg',
        centered: config.centered ? config.centered : false,
        backdrop: config.backdropStatic ? config.backdropStatic : true
      }

      const modalInstance = this.modalService.open(MessageModalComponent, options)
      modalInstance.componentInstance.type = config.type
      modalInstance.componentInstance.title = config.title
      modalInstance.componentInstance.message = config.message
      modalInstance.componentInstance.buttons = config.buttons
      if (config.hideIcon) {
        modalInstance.componentInstance.hideIcon = config.hideIcon
      }
      if (config.size) {
        modalInstance.componentInstance.size = config.size
      }
      if (config.confirmInput) {
        modalInstance.componentInstance.confirmInput = config.confirmInput
      }
      if (config.confirmText) {
        modalInstance.componentInstance.confirmText = config.confirmText
      }
      if (config.centerButtons) {
        modalInstance.componentInstance.centerButtons = config.centerButtons
      }
      if (config.customIcon) {
        modalInstance.componentInstance.customIcon = config.customIcon
      }
      if (config.imageAsIcon && config.imageUrl && config.imageUrl !== '') {
        modalInstance.componentInstance.imageAsIcon = config.imageAsIcon
        modalInstance.componentInstance.imageUrl = config.imageUrl
      }

      modalInstance.result.then(
        (res: string) => resolve(res),
        () => resolve(null)
      )

    })


  }

  public closeAll(): void {
    this.modalService.dismissAll()
  }

}

<div id="checkInWrapper" class="wrapper d-flex flex-column align-items-center min-height-100">

  <div class="branding text-secondary">
    <div class="app-icon">
      <fa-icon [icon]="appIcon" [fixedWidth]="true" class="text-mega"></fa-icon>
    </div>
    <div class="app-title text-mega">Tolaria</div>
  </div>

  <div class="px-5 mb-3 text-secondary" [style.fontSize.rem]="4">PLAYER CHECK IN</div>

  <div class="flex-grow-0 d-flex flex-column align-items-center">

    <div class="text-secondary h3">Welcome to</div>

    <app-event-name [eventDocId]="eventDocId" class="h1"></app-event-name>

  </div>

  <div class="flex-grow-1 d-flex flex-column align-items-center">

    <ng-container *ngIf="playerCheckingIn$ | async as player">

      <div class="text-huge mt-3 text-orange">
        {{ player.name }}
      </div>

      <ng-container *ngIf="showWelcomeWrapper">

        <div class="text-xxlarge mb-3 text-center">
          You are being checked in
        </div>

        <div class="mt-3 check-in d-flex flex-column align-items-center" *ngIf="checkInSuccess">

          <fa-icon [icon]="success" class="text-mega text-success"></fa-icon>
          <div class="text-xxlarge text-success text-center">
            Check in completed,<br>
            please enjoy the event
          </div>

        </div>
        <div class="mt-3 check-in d-flex flex-column align-items-center" *ngIf="checkInFailed">

          <fa-icon [icon]="error" class="text-mega text-warning"></fa-icon>
          <div class="text-xxlarge text-warning text-center">
            Could not perform check in,<br>
            please contact the organizer
          </div>

        </div>

      </ng-container>

      <ng-container *ngIf="showWelcomeBackWrapper">

        <div class="text-xxlarge mb-3 text-center">
          You are already checked in
        </div>

      </ng-container>

    </ng-container>

    <ng-container *ngIf="nothingFoundOnScan">

      <fa-icon [icon]="error" class="mt-3 text-mega text-warning"></fa-icon>
      <div class="text-xxlarge mb-3 text-center text-warning">
        It seems you are not registered in the event,<br>
        please contact the organizer to be enrolled.
      </div>

    </ng-container>

    <ng-container *ngIf="showAnimation">
      <div class="video-wrapper {{ isPortrait ? 'portrait' : 'landscape' }}">
        <!-- <video #videoInstruction [autoplay]="true" [loop]="true"
          src="assets/video/1920x1080_check-in-instructions.mp4"></video> -->
        <img [src]="animationImageUrl">
      </div>
    </ng-container>

  </div>

</div>
import { Observable } from 'rxjs';
import { Component } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
// import * as data from './LEG.json'; // GET SET.json FROM mtgjson.com

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.css']
})
export class CollectionComponent {

  cardCollection: AngularFirestoreCollection<any>;
  cards$: Observable<any>;

  constructor(
    private afs: AngularFirestore
  ) {
    this.cardCollection = afs.collection<any>('cards', ref => ref
    .limit(100)
    );
    this.cards$ = this.cardCollection.valueChanges();
  }

  getImageUri(scryfallId: string, size: string, face: string = 'front'): string {
    const uriStart = 'https://img.scryfall.com/cards/';
    const hash = scryfallId.substring(0, 1) + '/' + scryfallId.substring(1, 2);
    switch (size) {
      case 'png':
        return uriStart + size + '/' + face + '/' + hash + '/' + scryfallId + '.png';
      default:
        return uriStart + size + '/' + face + '/' + hash + '/' + scryfallId + '.jpg';
    }
  }
}

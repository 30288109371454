<div class="card deck-item min-width-100 max-width-100">

  <!-- <div class="card-header p-0 m-0 overflow-hidden" *ngIf="deck.main && deck.main.length > 0" [style.height.rem]="10">
    <img [src]="deck.main[0]?.imageUris?.art_crop" width="100%">
  </div> -->

  <div class="card-body p-0 d-flex">

    <div class="info flex-grow-1 p-3" (click)="$event.preventDefault(); $event.stopPropagation();"
      [routerLink]="'/decks/edit/' + deck.docId + '/version/origin'">
      <div class="deck-name d-flex align-items-center">
        <span class="flex-grow-1 text-large me-2" [innerHtml]="deckName"
          [ngClass]="{ 'text-italic text-secondary' : noDeckName }"></span>
      </div>

      <div class="d-flex align-items-center mt-2">
        <span class="text-secondary me-2">
          <fa-icon [icon]="faFolder" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="flex-grow-1 text-secondary me-2" [innerHtml]="deckFolder"
          [ngClass]="{ 'text-italic' : deckFolder === 'Uncategorized' }"></span>
        <span class="badge rounded-pill text-bg-secondary" *ngIf="deck.rating" ngbTooltip="Deckster Rating" container="body">
          <fa-icon [icon]="faStar" [fixedWidth]="true" class="me-2"></fa-icon>{{ deck.rating }}
        </span>
      </div>

      <div class="deck-colors event-info mt-2 d-flex align-items-center">
        <ul class="color-list flex-grow-1">
          <li class="color-item" *ngIf="deck.colors ? deck.colors.includes('WHITE') : false"><i
              class="ms ms-w ms-cost"></i></li>
          <li class="color-item" *ngIf="deck.colors ? deck.colors.includes('BLUE') : false"><i
              class="ms ms-u ms-cost"></i></li>
          <li class="color-item" *ngIf="deck.colors ? deck.colors.includes('BLACK') : false"><i
              class="ms ms-b ms-cost"></i></li>
          <li class="color-item" *ngIf="deck.colors ? deck.colors.includes('RED') : false"><i
              class="ms ms-r ms-cost"></i>
          </li>
          <li class="color-item" *ngIf="deck.colors ? deck.colors.includes('GREEN') : false"><i
              class="ms ms-g ms-cost"></i></li>
        </ul>
        <span class="badge rounded-pill text-bg-secondary" *ngIf="deck.versions?.length > 0" ngbTooltip="Number of versions" container="body">
          <fa-icon [icon]="faCodeBranch" [fixedWidth]="true" class="me-2"></fa-icon>{{ deck.versions.length + 1 }}
        </span>
      </div>
    </div>

    <div class="drag-handle pt-3 pe-3">
      <span class="move-icon text-secondary" ngbTooltip="Drag to move to another folder" placement="bottom-right">
        <fa-icon [icon]="faArrowsAlt" [fixedWidth]="true"></fa-icon>
      </span>
    </div>

  </div>

  <div class="card-footer p-2 d-flex align-items-center" (click)="$event.preventDefault(); $event.stopPropagation()">
    <button class="btn btn-sm btn-blank text-secondary" ngbTooltip="Edit this deck" placement="top-left" container="body"
      [routerLink]="'/decks/edit/' + deck.docId + '/version/origin'">
      <fa-icon [icon]="faEdit" [fixedWidth]="true"></fa-icon>
    </button>
    <button class="btn btn-sm btn-blank text-secondary" ngbTooltip="Test the deck (goldfish)" placement="top-left"
      container="body" (click)="goldfishDeck()" [disabled]="deck.main.length < 10">
      <fa-icon [icon]="faFish" [fixedWidth]="true"></fa-icon>
    </button>
    <button class="btn btn-sm btn-blank text-secondary" ngbTooltip="Create a clone of this deck" placement="top-left"
      container="body">
      <fa-icon [icon]="faClone" [fixedWidth]="true" (click)="cloneDeckList()"></fa-icon>
    </button>
    <button class="btn btn-sm btn-blank text-secondary" ngbTooltip="Download deck list" placement="top-left"
      container="body" (click)="downloadDeckList()">
      <fa-icon [icon]="faFileDownload" [fixedWidth]="true"></fa-icon>
    </button>
    <button class="btn btn-sm btn-blank text-secondary" ngbTooltip="View deck photo" placement="top-left" container="body"
      (click)="openDeckPhoto()" *ngIf="deckPhoto">
      <fa-icon [icon]="faImage" [fixedWidth]="true"></fa-icon>
    </button>
    <div class="flex-grow-1"></div>
  </div>
</div>


<ng-template #deleteDeckConfirmation let-modal>
  <div class="modal-header">Delete Deck?</div>
  <div class="moda-body p-3">
    Are you sure you want to delete the deck <span class="text-italic text-bold">{{ deck.name }}</span>?
  </div>
  <div class="modal-footer d-flex align-items-center">
    <button class="btn btn-sm btn-blank text-secondary" (click)="modal.close()">Cancel</button>
    <button class="btn btn-sm btn-blank text-red" (click)="modal.close('delete')">Delete</button>
  </div>
</ng-template>

<ng-template #cloneDeckModal let-modal>
  <div class="modal-header">Clone Deck</div>
  <div class="moda-body p-3">
    <input #cloneInputName class="form-control" [(ngModel)]="cloneDeckNewName" (keydown.enter)="modal.close('clone')">
    <div class="text-secondary text-italic mt-3">
      Links to event, rating, and deckphoto will NOT be cloned.
    </div>
  </div>
  <div class="modal-footer d-flex align-items-center py-2">
    <button class="btn btn-sm btn-blank text-secondary" (click)="modal.close()">Cancel</button>
    <button class="btn btn-sm btn-blank" (click)="modal.close('clone')">Clone</button>
  </div>
</ng-template>

import { Pipe, PipeTransform } from '@angular/core';
import { ICardTypes } from '../services/card-search.service';

@Pipe({
  name: 'cardTypesFilter'
})
export class CardTypesFilterPipe implements PipeTransform {

  transform(items: ICardTypes[], filter: string): ICardTypes[] {
    if (!items) {
      return [];
    }
    if (items && filter === '') {
      return items;
    }

    const res = [];

    items.forEach(type => {
      const subTypes = type.list.filter(t => t.toLowerCase().includes(filter.toLowerCase()));
      if (subTypes.length > 0) {
        const resType = {
          name: type.name,
          list: subTypes
        } as ICardTypes;
        res.push(resType);
      }
    });
    return res;
  }

}

import { FontIconsService } from './../../../services/font-icons.service';
import { AddPlayersComponent } from './../../modals/add-players/add-players.component';
import { SetListSelectorComponent } from './../set-list-selector/set-list-selector.component';
import { AuthService } from 'src/app/services';
import { NgbActiveModal, NgbDate, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { faCheckCircle, faTimes, faTrash, faUserPlus, faExclamationCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DraftService } from './../../../services/draft.service';
import { Component, Input, OnInit } from '@angular/core';
import { IDateSelection, ITimeSelection } from '../../modals/new-event-form/new-event-form.component';
import { IDraft, IDraftSet } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-draft-setup',
  templateUrl: './draft-setup.component.html',
  styleUrls: ['./draft-setup.component.css']
})
export class DraftSetupComponent implements OnInit {
  @Input() draftDocId: string;

  faTimes = faTimes;
  faCheckCircle = faCheckCircle;
  faExclamationCircle = faExclamationCircle;
  faTrash = faTrash;
  faUserPlus = faUserPlus;
  faPlus = faPlus;

  public isEditing = false;
  public draft: IDraft;
  public dateSelection: IDateSelection = {
    fromDate: null,
    toDate: null,
  };
  timeSelection: ITimeSelection = {
    fromTime: null,
    toTime: null
  };

  constructor(
    private draftService: DraftService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public auth: AuthService,
    public fontIcons: FontIconsService
  ) { }

  ngOnInit(): void {
    if (this.draftDocId) {
      this.draftService.getDraftByDocId(this.draftDocId)
        .then(draftDoc => {
          this.isEditing = true;
          this.draft = draftDoc;
          const dateFrom = new Date(this.draft.datestamp * 1000);
          this.dateSelection.fromDate = new NgbDate(dateFrom.getFullYear(), dateFrom.getMonth() + 1, dateFrom.getDate());
          this.timeSelection.fromTime = {
            hour: dateFrom.getHours(),
            minute: dateFrom.getMinutes(),
            second: 0
          };
          console.log(this.timeSelection, this.dateSelection);
        })
        .catch((err) => console.error(err));
    }
    else {
      const dateFrom = new Date();
      this.draft = this.draftService.newDraft();
      this.dateSelection.fromDate = new NgbDate(dateFrom.getFullYear(), dateFrom.getMonth() + 1, dateFrom.getDate());
      this.timeSelection.fromTime = {
        hour: dateFrom.getHours(),
        minute: 0,
        second: 0
      };
    }
  }


  public unattendPlayer(playerDocId: string): void {
    this.draftService.unattendPlayerFromDraft(this.draft.docId, playerDocId)
      .then((status) => {
        if (status) {
          this.draft.playerDocIds.splice(this.draft.playerDocIds.findIndex(p => p === playerDocId), 1);
        }
      })
      .catch((err) => console.log('something went wrong...'));
  }

  public createDraft() {

    if (!this.isEditing && this.draft.statusCode === 0) {
      this.draft.statusCode = 1;
      this.draft.statusText = this.draftService.statuses[1];
    }

    this.setDateAndTime();

    // clean up the document
    if (this.draft.isRotisserie) {
      this.draft.packs.a = null;
      this.draft.packs.b = null;
      this.draft.packs.c = null;
    }
    else if (this.draft.isBooster || this.draft.isRochester) {
      this.draft.sets = [];
    }

    this.draftService.saveDraftDocument(this.draft, this.isEditing).then((response) => {
      if (response.status) {
        this.closeModal();
      }
    });
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public onDateSelection(date: NgbDate) {
    this.dateSelection.fromDate = date;
    this.setDateAndTime();
  }

  public setDateAndTime() {
    const fromDate = this.fromDate;
    this.draft.datestamp = new Date(fromDate).getTime() / 1000;
    console.log(this.draft);
  }

  private get fromDate(): string {
    return this.dateSelection.fromDate.year + '-' +
      this.pad2(this.dateSelection.fromDate.month) + '-' +
      this.pad2(this.dateSelection.fromDate.day) + 'T' +
      this.pad2(this.timeSelection.fromTime.hour) + ':' +
      this.pad2(this.timeSelection.fromTime.minute) + this.auth.user.deviceUtcOffset;
  }
  private pad2(n: number) { return n < 10 ? '0' + n : n; }

  public changeDraftType(type: string): void {
    this.draft.isBooster = false;
    this.draft.isRochester = false;
    this.draft.isRotisserie = false;

    switch (type) {
      case 'booster':
        this.draft.isBooster = true;
        this.draft.isLive = true;
        break;
      case 'rochester':
        this.draft.isRochester = true;
        this.draft.isLive = true;
        break;
      case 'rotisserie':
        this.draft.isRotisserie = true;
        break;
    }

  }

  public showSetList(pack: string): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    };

    const modalRef = this.modalService.open(SetListSelectorComponent, modalOptions);
    modalRef.componentInstance.multiSelect = this.draft.isRotisserie;
    modalRef.result
      .then((set: IDraftSet | IDraftSet[]) => {
        console.log(set);
        if (this.draft.isRotisserie) {
          const sets = set as IDraftSet[];
          sets.forEach((set) => {
            if (this.draft.sets.find((s) => s.code === set.code) === undefined) {
              this.draft.sets.push(set);
            }
          });
        }
        else {
          const setInfo = set as IDraftSet;
          // check if cube is selected
          if (setInfo.uniqueBoosters && setInfo.isCube) {
            // set all packs as the same
            this.draft.packs.a = setInfo;
            this.draft.packs.b = setInfo;
            this.draft.packs.c = setInfo;
          }
          else {
            this.draft.packs[pack] = set as IDraftSet;
          }

          // if alla packs selected and name is unset, add the set codes as the name
          if (this.draft.packs.a && this.draft.packs.b && this.draft.packs.c && this.draft.name === '') {
            this.draft.name = this.draft.packs.a.code + '/' + this.draft.packs.b.code + '/' + this.draft.packs.c.code;
          }
        }
      })
      .catch((err) => {
        // nothing
      });
  }

  public openAddPlayersModal(): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    };

    const modalRef = this.modalService
      .open(AddPlayersComponent, modalOptions);
    modalRef.componentInstance.playerDocIds = this.draft.playerDocIds;

    modalRef.result
      .then((playerDocIds: Array<string>) => {
        this.draft.playerDocIds = playerDocIds.splice(0, 8);
      })
      .catch((err) => {
        // nothing
      });

  }

  get nameOK() {
    return this.draft.name.length >= 5;
  }

  get typeOK() {
    return this.draft.isBooster || this.draft.isRochester || this.draft.isRotisserie;
  }

  get packsOK() {
    return this.draft.isBooster || this.draft.isRochester ? this.draft.packs.a !== null && this.draft.packs.b !== null && this.draft.packs.c !== null : true;
  }

  get setsOK() {
    return this.draft.isRotisserie ? this.draft.sets.length > 0 : true;
  }

  get dateOK() {
    return this.draft.datestamp !== undefined;
  }

  get timeOK() {
    return this.draft.datestamp !== undefined;
  }

  get numberOfPicksOK() {
    return this.draft.isRotisserie ? this.totalCardPoolSize / (this.draft.numberOfPicks * 8) >= 1 : true;
  }

  get totalCardPoolSize(): number {
    let response = 0;
    this.draft.sets.forEach((s) => {
      response = response + s.totalSetSize
    });
    if (!this.draft.includeBasicLands) {
      this.draft.sets.forEach((s) => {
        response = response - s.numberOfBasicLands;
      });
    }
    if (!this.draft.includeAnteCards) {
      this.draft.sets.forEach((s) => {
        response = response - s.numberOfAnteCards;
      });
    }

    return response;
  }

  trackBySet(index: number, item: IDraftSet): string {
    return item.code;
  }

}

<div class="modal-dialog-overlay">
  <div class="modal-dialog-wrapper">
    <div class="card">
      <div class="card-header">{{ dialogs.title }}</div>
      <div class="card-body">{{ dialogs.message }}</div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-sm btn-blank w-100" (click)="onButtonPressed('cancel')" *ngIf="!dialogs.buttons.OkOnly">{{ dialogs.buttonCancelText }}</button>
          </div>
          <div class="col">
            <button type="button" class="btn btn-sm btn-blank w-100" (click)="onButtonPressed('no')" *ngIf="dialogs.buttons.YesNoCancel">No</button>
          </div>
          <div class="col">
            <button type="button" class="btn btn-sm btn-secondary w-100" (click)="onButtonPressed('confirm')">{{ dialogs.buttonConfirmText }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="showAsModal; else content">
  <div class="modal-header">{{ deckName }}</div>
  <div class="modal-header">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button class="btn btn-sm btn-blank" (click)="modal.dismiss()">Close</button>
  </div>
</ng-container>

<ng-template #content>
  <div class="wrapper {{ wrapperClass }}">
    <!-- PLAIN TEXT LIST -->
    <ng-container *ngIf="showList; else default">
      <ng-container *ngIf="deckList$ | async as deck">
  
        <ng-container *ngIf="deck.main.length > 0">
          <div class="h3 mt-5">Main<span class="ms-3" [innerHtml]="deck.main.length"></span></div>
          <ng-container *ngFor="let typeSection of deck.main | deckListParser : false : false">
            <div class="d-flex p-1" *ngFor="let card of typeSection.cards">
              <div class="flex-grow-0" [style.width.rem]="1.5" [innerHtml]="card.qty"></div>
              <div class="flex-grow-1" [innerHtml]="card.name"></div>
            </div>
          </ng-container>
        </ng-container>
  
        <ng-container *ngIf="deck.sideboard.length > 0">
          <div class="h3 mt-3">Sideboard<span class="ms-3" [innerHtml]="deck.sideboard.length"></span></div>
          <ng-container *ngFor="let typeSection of deck.sideboard | deckListParser : true : false">
            <div class="d-flex p-1" *ngFor="let card of typeSection.cards">
              <div class="flex-grow-0" [style.width.rem]="1.5" [innerHtml]="card.qty"></div>
              <div class="flex-grow-1" [innerHtml]="card.name"></div>
            </div>
          </ng-container>
        </ng-container>
  
      </ng-container>
    </ng-container>
  
    <!-- SORTED LIST WITH PREVIEW CARD -->
    <ng-template #default>
      <ng-container *ngIf="deckList$ | async as deck">
  
        <div class="h3">{{ deck.name }}</div>
        <div class="text-secondary text-italic">{{ deck.description }}</div>
  
        <div class="deck-preview mt-5 d-flex overflow-hidden">
  
          <div class="deck-part main-deck flex-grow-1" *ngIf="deck.main.length > 0">
            <div class="h3">Main<span class="ms-3">{{ deck.main.length }}</span></div>
  
            <div class="deck-section-wrapper">
              <ng-container *ngFor="let typeSection of deck.main | deckListParser : false : false">
                <div class="deck-section">
                  <div class="h6">
                    <span class="me-3">{{ typeSection.cardCount }}</span>{{ typeSection.text }}
                  </div>
                  <div class="card-item" *ngFor="let card of typeSection.cards" (mouseenter)="cardPreviewUrl = card.imageUrl"
                    (mouseleave)="cardPreviewUrl = null">
                    <div class="card-qty">{{ card.qty }}</div>
                    <div class="card-name">{{ card.name }}</div>
                  </div>
                </div>
              </ng-container>
            </div>
  
          </div>
  
          <div class="deck-part sideboard pe-5" *ngIf="deck.sideboard.length > 0">
            <div class="h3 text-secondary">Sideboard<span class="ms-3">{{ deck.sideboard.length }}</span></div>
            <ng-container *ngFor="let typeSection of deck.sideboard | deckListParser : true : false">
              <div class="deck-section">
                <div class="card-item text-secondary" *ngFor="let card of typeSection.cards" (mouseenter)="cardPreviewUrl = card.imageUrl"
                (mouseleave)="cardPreviewUrl = null">
                  <div class="card-qty">{{ card.qty }}</div>
                  <div class="card-name">{{ card.name }}</div>
                </div>
              </div>
            </ng-container>
          </div>
  
          <div class="card-preview">
            <img [src]="cardPreviewUrl && cardPreviewUrl !== null ? cardPreviewUrl : 'assets/card-back.jpg'">
            <!-- <object data="/src/assets/card-back.jpg" type="image/png">
            </object> -->
          </div>
  
        </div>
      </ng-container>
    </ng-template>
  </div>
</ng-template>

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const tiUserNumber: IconDefinition = {
  prefix: 'fac',
  iconName: 'userNumber',
  icon: [
    640.24, // SVG view box width
    512, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M223.76,256a128,128,0,1,0-128-128A128,128,0,0,0,223.76,256Zm89.6,32h-16.7a174.08,174.08,0,0,1-145.8,0h-16.7A134.43,134.43,0,0,0-.24,422.4V464a48,48,0,0,0,48,48h352a48,48,0,0,0,48-48V422.4A134.43,134.43,0,0,0,313.36,288Zm291-63.36-10.31,60.64h20.32a13.88,13.88,0,0,1,14.06,14.07,11.74,11.74,0,0,1-4.06,9.22,14.55,14.55,0,0,1-10,3.59h-25l-7.19,41.58a12.44,12.44,0,0,1-4.22,8.13,12.68,12.68,0,0,1-8.6,3.12,13.34,13.34,0,0,1-9.84-3.9,13.73,13.73,0,0,1-3.91-10.16,14.76,14.76,0,0,1,.31-3.44l5.94-35.33H506.52l-7.19,41.58A13.26,13.26,0,0,1,494.8,362a14.16,14.16,0,0,1-18.6-1.09,14.63,14.63,0,0,1-3.75-10.32,10.9,10.9,0,0,1,.31-3.12L479,312.16H461.82a13.87,13.87,0,0,1-14.06-14.06,11.93,11.93,0,0,1,3.9-9.22,14.41,14.41,0,0,1,10.16-3.6h21.57L494,224.64H473.7a13.88,13.88,0,0,1-14.07-14.07,12,12,0,0,1,3.91-9.22,14.43,14.43,0,0,1,10.16-3.59h25l6.87-40.33a12.49,12.49,0,0,1,4.22-8.13,12.7,12.7,0,0,1,8.6-3.12,13.35,13.35,0,0,1,9.85,3.91,13.75,13.75,0,0,1,3.91,10.15,14.67,14.67,0,0,1-.32,3.44l-5.94,34.08h55.64l6.88-40.33a12.44,12.44,0,0,1,4.22-8.13,14.12,14.12,0,0,1,18.76.94,14.31,14.31,0,0,1,3.91,10.32,10.77,10.77,0,0,1-.32,3.12l-5.94,34.08h16.88A13.39,13.39,0,0,1,640,211.82a11.94,11.94,0,0,1-3.91,9.22,14.41,14.41,0,0,1-10.16,3.6Zm-37.82,60.64,10.32-60.64H521.53L510.9,285.28Z'
  ],
} as any;

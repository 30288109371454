<ng-container *ngIf="eventDoc$ | async as event">
  <ng-container *ngIf="playerDetails$ | async as playerDetails">
    <ng-container *ngIf="registrationFee$ | async as registrationFee">

      <div class="task-list d-flex flex-column">

        <div class="wrapper">

          <app-event-tickets [eventDocId]="event.docId" *ngIf="registrationFee.active"></app-event-tickets>

          <div class="check-in" *ngIf="playerDetails?.hasCheckedIn">
            You are checked in
          </div>

          <div class="text-secondary d-flex align-items-center">
            <span class="h6 flex-grow-1">Your tasks</span>
            <span class="text-secondary text-italic">* mandatory</span>
          </div>

          <div class="task-wrapper mt-2 d-flex align-items-center" *ngIf="registrationFee.active">

            <div class="sub-task-wrapper flex-grow-1 d-flex flex-column">
              <div class="task-item d-flex align-items-center">
                <fa-icon [icon]="registrationFee.hasPaid || registrationFee.ticketPurchase ? faCheckSquare : faSquare" [fixedWidth]="true" class="me-2">
                </fa-icon>
                <div class="flex-grow-1">Pay registration fee <span class="mandatory text-secondary"
                    *ngIf="registrationFee.active">*</span></div>
              </div>
            </div>

            <ng-container *ngIf="registrationFee.hasPaid || registrationFee.ticketPurchase; else payment">
              <button class="btn btn-sm btn-outline-success" [disabled]="true" [style.minWidth.rem]="6.5">
                {{ registrationFee.ticketPurchase ? 'Ticket purchased' : 'Fee Paid' }}
              </button>
            </ng-container>
            <ng-template #payment>
              <button class="btn btn-sm btn-outline-warning" [style.minWidth.rem]="6.5"
                (click)="payRegistrationFee()">Pay Fee</button>
            </ng-template>
          </div>



          <div class="task-wrapper mt-2 d-flex align-items-center">
            <div class="sub-task-wrapper flex-grow-1 d-flex flex-column">
              <div class="task-item d-flex align-items-center" [ngClass]="{ 'text-secondary' : !event.details.deckPhoto }">
                <fa-icon [icon]="playerDetails.deckSubmission.deckPhoto ? faCheckSquare : faSquare" [fixedWidth]="true"
                  class="me-2"></fa-icon>
                <div class="flex-grow-1">Upload deck photo <span class="mandatory text-secondary"
                    *ngIf="event.details.deckPhoto">*</span></div>
              </div>
              <div class="task-item d-flex align-items-center" [ngClass]="{ 'text-secondary' : !event.details.deckList }">
                <fa-icon [icon]="playerDetails.deckSubmission.deckList ? faCheckSquare : faSquare" [fixedWidth]="true"
                  class="me-2"></fa-icon>
                <div class="flex-grow-1">Register deck list <span class="mandatory text-secondary"
                    *ngIf="event.details.deckList">*</span></div>
              </div>
            </div>
            <div class="btn btn-sm {{ event.details.deckList || event.details.deckPhoto ? 'btn-outline-warning' : 'btn-outline-secondary' }}"
              (click)="openDeckLinker(event, playerDetails)"
              container="body" placement="top" ngbTooltip="Register deck list and/or upload deck photo"
              style="min-width: 6.5rem;">Open
              task
            </div>
          </div>


          <div class="footer mb-2">
          </div>

        </div>

        <div class="mt-3 wrapper flex-grow-1 d-flex flex-column">

          <div class="mt-5 h6 text-secondary">Actions</div>

          <button class="btn btn-sm btn-danger w-100 mt-2"
            [disabled]="event.statusCode > 0 || registrationFee.hasPaid"
            (click)="unattendEvent(event, playerDetails)">Unnattend Event</button>

          <!-- player can only drop when round is waiting to be paired or when swiss is finished before playoff is configured -->
          <button class="btn btn-sm btn-danger w-100 mt-2" [disabled]="playerDetails.dropped || !playerCanDrop"
            (click)="dropFromEvent(event, playerDetails)">Drop</button>

        </div>

      </div>



    </ng-container>
  </ng-container>
</ng-container>

import { Pipe, PipeTransform } from '@angular/core'
import { IEventDetails, IEventPlayerDetails, IEventTeam } from 'tolaria-cloud-functions/src/_interfaces'
import { IMatchDataTemplate } from '../components/events/event-lobby/event-main-view/event-pairings/event-pairings.component'

export interface ITeamMatchPipeConfig {
  filter: string
  selectedRound: number
  selectedGroup: number
  event: IEventDetails
  showReportedMatches: boolean
  players: IEventPlayerDetails[]
}

export interface ITeamMatchTemplate {
  teams: string[]
  team1: IEventTeam
  team2: IEventTeam
  seatPairing: {
    a: string[]
    b: string[]
    c: string[]
  }
  isFinished: boolean
  isReported: boolean
  result: {
    team1wins: number
    draws: number
    team2wins: number
  }
  matches: IMatchDataTemplate[]
  table: number
  filterValue: string
}

@Pipe({
  name: 'teamMatches'
})
export class TeamMatchesPipe implements PipeTransform {

  transform(matches: IMatchDataTemplate[], teams: IEventTeam[], config: ITeamMatchPipeConfig): ITeamMatchTemplate[] {

    // return empty array if not correct data present
    if (config === null) { return [] }
    if (matches === null || matches === undefined || matches.length === 0) { return [] }
    if (teams === null || teams === undefined || teams.length === 0) { return [] }
    if (config.players.length < config.event.playerDocIds.length) { return [] }
    if (matches.filter(i => i.roundNumber === config.selectedRound).length === 0) { return [] }

    const tmpTeamIds = JSON.parse(JSON.stringify(teams.map(i => i.id)))

    const pairings: ITeamMatchTemplate[] = []

    while (tmpTeamIds.length > 0) {

      // get the first team available among the team ids in the temporary array
      const team = teams.find(i => i.id === tmpTeamIds[0])

      // get the matches for the team
      const teamMatches = matches.filter(i => i.isType !== 'bracket').filter(i => i.teamIds.includes(team.id) && i.roundNumber === config.selectedRound)

      // set initial stats for the team
      team.gamesWon = 0
      team.gamesDrawn = 0
      team.gamesLost = 0

      // check all matches and update the stats
      teamMatches.forEach(match => {
        const teamIs = match.player1.teamId === team.id ? 'player1' : 'player2'
        const opponentIs = match.player1.teamId === team.id ? 'player2' : 'player1'
        if (match[teamIs].isWinner) { team.gamesWon = team.gamesWon + 1 }
        if (match[opponentIs].isWinner) { team.gamesLost = team.gamesLost + 1 }
        if (match.isDraw) { team.gamesDrawn = team.gamesDrawn + 1 }
      })

      // get the first match on seat A to get the opponent team id
      const matchAtSeatA = matches.find(i => i.roundNumber === config.selectedRound && i.teamSeat === 'a' && i.teamIds.includes(team.id))
      const matchAtSeatB = matches.find(i => i.roundNumber === config.selectedRound && i.teamSeat === 'b' && i.teamIds.includes(team.id))
      const matchAtSeatC = matches.find(i => i.roundNumber === config.selectedRound && i.teamSeat === 'c' && i.teamIds.includes(team.id))
      const opponentId = matchAtSeatA.teamIds.filter(i => i !== team.id)[0]

      // check if opponent is BYE and handle accordingly
      if (opponentId !== '*** BYE ***') {

        // get the opponent team
        const opponent = teams.find(i => i.id === opponentId)

        // set initial stats for the opponent team
        opponent.gamesWon = 0
        opponent.gamesDrawn = 0
        opponent.gamesLost = 0

        // check all matches and update the stats
        teamMatches.forEach(match => {
          const teamIsPlayer = match.player1.teamId === opponent.id ? 'player1' : 'player2'
          match[teamIsPlayer].isWinner
            ? opponent.gamesWon = opponent.gamesWon + 1
            : opponent.gamesLost = opponent.gamesLost + 1
          match.isDraw
            ? opponent.gamesDrawn = opponent.gamesDrawn + 1
            : null
        })

        // get player names for both teams
        const playerNames = [...team.playerDocIds, ...opponent.playerDocIds].map(i => config.players.find(p => p.playerDocId === i).name)

        // construct the template object
        const teamMatch: ITeamMatchTemplate = {
          teams: matchAtSeatA.player1.teamId === team.id ? [team.id, opponent.id] : [opponent.id, team.id],
          team1: matchAtSeatA.player1.teamId === team.id ? team : opponent,
          team2: matchAtSeatA.player1.teamId === team.id ? opponent : team,
          seatPairing: {
            a: matchAtSeatA.player1.teamId === team.id ? [team.player.a, opponent.player.a] : [opponent.player.a, team.player.a],
            b: matchAtSeatB.player1.teamId === team.id ? [team.player.b, opponent.player.b] : [opponent.player.b, team.player.b],
            c: matchAtSeatC.player1.teamId === team.id ? [team.player.c, opponent.player.c] : [opponent.player.c, team.player.c],
          },
          table: matchAtSeatA.tableNumber,
          isFinished: teamMatches.filter(i => !i.isReported).length === 0,
          isReported: teamMatches.filter(i => !i.isReported).length === 0,
          result: {
            team1wins: matchAtSeatA.player1.teamId === team.id ? team.gamesWon : opponent.gamesWon,
            draws: team.gamesDrawn,
            team2wins: matchAtSeatA.player1.teamId === team.id ? opponent.gamesWon : team.gamesWon,
          },
          matches: teamMatches.sort((a, b) => a.teamSeat.localeCompare(b.teamSeat)),
          filterValue: [team.name, opponent.name, ...playerNames].join(' ')
        }

        // add the template object to the pairings array
        pairings.push(teamMatch)

        // remove both teams from the temporary team array
        tmpTeamIds.splice(0, 1)
        tmpTeamIds.splice(tmpTeamIds.findIndex(i => i === opponent.id), 1)

      }
      else {

        // create the BYE opponent team
        const opponent = {
          id: '*** BYE ***',
          name: '*** BYE ***',
          player: {
            a: '*** BYE ***',
            b: '*** BYE ***',
            c: '*** BYE ***',
          },
          playerDocIds: ['*** BYE ***'],
          dropped: false,
          gamesWon: 0,
          gamesDrawn: 0,
          gamesLost: 0,
        }

        // get player names for the team
        const playerNames = [...team.playerDocIds].map(i => config.players.find(p => p.playerDocId === i).name)

        // add the bye player name
        playerNames.push('*** BYE ***')

        // construct the template object
        const teamMatch: ITeamMatchTemplate = {
          teams: [team.id, opponent.id],
          team1: team,
          team2: opponent,
          seatPairing: {
            a: [team.player.a, opponent.player.a],
            b: [team.player.b, opponent.player.b],
            c: [team.player.c, opponent.player.c],
          },
          table: matchAtSeatA.tableNumber,
          isFinished: teamMatches.filter(i => !i.isReported).length === 0,
          isReported: teamMatches.filter(i => !i.isReported).length === 0,
          result: {
            team1wins: team.gamesWon,
            draws: team.gamesDrawn,
            team2wins: opponent.gamesWon,
          },
          matches: teamMatches.sort((a, b) => a.teamSeat.localeCompare(b.teamSeat)),
          filterValue: [team.name, opponent.name, ...playerNames].join(' ')
        }

        // add the template object to the pairings array
        pairings.push(teamMatch)

        // remove the team
        tmpTeamIds.splice(0, 1)
      }


    }

    return pairings.filter(i => i.filterValue.toLowerCase().includes(config.filter.toLowerCase())).sort((a, b) => a.table - b.table)

  }



}

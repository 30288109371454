<section class="text-bg-dark" style="min-height: auto;max-height: auto;height: auto;">
  <div class="container px-4 py-5">
    <div class="bottom-links links d-flex flex-wrap mb-5 gap-5">
      <div class="btn btn-blank text-light" routerLink="/about">About</div>
      <div class="btn btn-blank text-light" routerLink="/about/privacy-policy">Privacy Policy</div>
      <div class="btn btn-blank text-light" routerLink="/about/terms-of-use">Terms of Use</div>
      <div class="btn btn-blank text-light" routerLink="/auth/sign-in">Sign-in</div>
      <div class="btn btn-blank text-light" routerLink="/auth/register">Register</div>
    </div>
    <div class="p-3">
      <div>Magic: The Gathering is © <a class="plain-link" href="https://company.wizards.com/">Wizards of the
          Coast</a>.</div>
      <div>This site is not affiliated nor produced nor endorsed by Wizards of the Coast.</div>
      <div>All card images, mana symbols, expansions and art related to Magic the Gathering is a property of Wizards
        of the Coast/Hasbro.</div>
      <div>All other content Copyright © 2020 Tolaria.app</div>
    </div>
  </div>
</section>
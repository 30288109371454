import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { Injectable } from '@angular/core'
import * as scryfall from 'scryfall-client'
import Card from 'scryfall-client/dist/models/card'
import GenericScryfallResponse from 'scryfall-client/dist/models/generic-scryfall-response'
import List from 'scryfall-client/dist/models/list'
import { ICardMetaLanguage } from '../components/cards/card-details/card-languages/card-languages.component'
import { ImageUris } from 'scryfall-client/dist/types/api/constants'
import { GlobalsService } from './globals.service'

declare type GetCardKind = 'id' | 'scryfall' | 'multiverse' | 'arena' | 'mtgo' | 'tcg' | 'name' | 'exactName' | 'fuzzyName'

const includeAllWotcBans = true
@Injectable({
  providedIn: 'root'
})
export class CardSearchService {


  public cleanSetList: Array<IMagicSetListItem> = []
  public setList: Array<IMagicSet> = [
    {
      name: 'Expansions',
      type: 'expansion',
      list: []
    },
    {
      name: 'Core Set',
      type: 'core',
      list: []
    },
    {
      name: 'Masters',
      type: 'masters',
      list: []
    },
    {
      name: 'Draft Innovation',
      type: 'draft_innovation',
      list: []
    },
    {
      name: 'Duel Decks',
      type: 'duel_deck',
      list: []
    },
    {
      name: 'Archenemy',
      type: 'archenemy',
      list: []
    },
    {
      name: 'Box',
      type: 'box',
      list: []
    },
    {
      name: 'Commander',
      type: 'commander',
      list: []
    },
    {
      name: 'From The Vault',
      type: 'from_the_vault',
      list: []
    },
    {
      name: 'Funny',
      type: 'funny',
      list: []
    },
    {
      name: 'Masterpiece',
      type: 'masterpiece',
      list: []
    },
    {
      name: 'Memorabilia',
      type: 'memorabilia',
      list: []
    },
    {
      name: 'Planechase',
      type: 'planechase',
      list: []
    },
    {
      name: 'Premium Deck',
      type: 'premium_deck',
      list: []
    },
    {
      name: 'Promo',
      type: 'promo',
      list: []
    },
    {
      name: 'Spellbook',
      type: 'spellbook',
      list: []
    },
    {
      name: 'Starter',
      type: 'starter',
      list: []
    },
    {
      name: 'Token',
      type: 'token',
      list: []
    },
    {
      name: 'Treasure Chest',
      type: 'treasure_chest',
      list: []
    },
    {
      name: 'Vanguard',
      type: 'vanguard',
      list: []
    },
    {
      name: 'Arsenal',
      type: 'arsenal',
      list: []
    },
    {
      name: 'Alchemy',
      type: 'alchemy',
      list: []
    }
  ]
  public blockList: Array<IMagicBlock> = [
    {
      name: 'Friday Night Magic',
      code: 'fnm'
    },
    {
      name: 'Arena League',
      code: 'parl'
    },
    {
      name: 'Judge Gift Cards',
      code: 'jgp'
    },
    {
      name: 'Commander',
      code: 'cmd'
    },
    {
      name: 'Core Set',
      code: 'lea'
    },
    {
      name: 'Magic Player Rewards',
      code: 'mpr'
    },
    {
      name: 'Guilds of Ravnica',
      code: 'grn'
    },
    {
      name: 'Ixalan',
      code: 'xln'
    },
    {
      name: 'Amonkhet',
      code: 'akh'
    },
    {
      name: 'Kaladesh',
      code: 'kld'
    },
    {
      name: 'Shadows over Innistrad',
      code: 'soi'
    },
    {
      name: 'Battle for Zendikar',
      code: 'bfz'
    },
    {
      name: 'Khans of Tarkir',
      code: 'ktk'
    },
    {
      name: 'Theros',
      code: 'ths'
    },
    {
      name: 'Return to Ravnica',
      code: 'rtr'
    },
    {
      name: 'Innistrad',
      code: 'isd'
    },
    {
      name: 'Scars of Mirrodin',
      code: 'som'
    },
    {
      name: 'Zendikar',
      code: 'zen'
    },
    {
      name: 'Alara',
      code: 'ala'
    },
    {
      name: 'Shadowmoor',
      code: 'shm'
    },
    {
      name: 'Lorwyn',
      code: 'lrw'
    },
    {
      name: 'Time Spiral',
      code: 'tsp'
    },
    {
      name: 'Ravnica',
      code: 'rav'
    },
    {
      name: 'Kamigawa',
      code: 'chk'
    },
    {
      name: 'Mirrodin',
      code: 'mrd'
    },
    {
      name: 'Onslaught',
      code: 'ons'
    },
    {
      name: 'Odyssey',
      code: 'ody'
    },
    {
      name: 'Invasion',
      code: 'inv'
    },
    {
      name: 'Masques',
      code: 'mmq'
    },
    {
      name: 'Urza',
      code: 'usg'
    },
    {
      name: 'Tempest',
      code: 'tmp'
    },
    {
      name: 'Mirage',
      code: 'mir'
    },
    {
      name: 'Ice Age',
      code: 'ice'
    }
  ]
  public typesList: Array<ICardTypes> = [
    {
      name: 'Types',
      list: ['Artifact', 'Conspiracy', 'Creature', 'Emblem', 'Enchantment', 'Hero', 'Instant', 'Land',
        'Phenomenon', 'Plane', 'Planeswalker', 'Scheme', 'Sorcery', 'Tribal', 'Vanguard']
    },
    {
      name: 'Supertypes',
      list: ['Basic', 'Elite', 'Legendary', 'Ongoing', 'Snow', 'Token', 'World']
    },
    {
      name: 'Artifact Types',
      list: ['Clue', 'Contraption', 'Equipment', 'Food', 'Fortification', 'Gold', 'Treasure', 'Vehicle']
    },
    {
      name: 'Enchantment Types',
      list: ['Aura', 'Cartouche', 'Curse', 'Rune', 'Saga', 'Shrine']
    },
    {
      name: 'Land Types',
      list: ['Desert', 'Forest', 'Gate', 'Island', 'Lair', 'Locus', 'Mine', 'Mountain', 'Plains', 'Power-Plant', 'Swamp', 'Tower', 'Urza’s']
    },
    {
      name: 'Spell Types',
      list: ['Adventure', 'Arcane', 'Trap']
    },
    {
      name: 'Planeswalker Types',
      list: ['Abian', 'Ajani', 'Aminatou', 'Angrath', 'Arlinn', 'Ashiok', 'B.O.B.', 'Basri', 'Bolas', 'Calix', 'Chandra', 'Dack', 'Daretti', 'Davriel', 'Domri', 'Dovin', 'Duck', 'Dungeon', 'Elspeth', 'Estrid', 'Freyalise', 'Garruk', 'Gideon', 'Huatli', 'Inzerva', 'Jace', 'Jaya', 'Jeska', 'Karn', 'Kasmina', 'Kaya', 'Kiora', 'Koth', 'Liliana', 'Lukka', 'Master', 'Nahiri', 'Narset', 'Niko', 'Nissa', 'Nixilis', 'Oko', 'Ral', 'Rowan', 'Saheeli', 'Samut', 'Sarkhan', 'Serra', 'Sorin', 'Szat', 'Tamiyo', 'Teferi', 'Teyo', 'Tezzeret', 'Tibalt', 'Tyvar', 'Ugin', 'Urza', 'Venser', 'Vivien', 'Vraska', 'Will', 'Windgrace', 'Wrenn', 'Xenagos', 'Yanggu', 'Yanling']
    },
    {
      name: 'Creature Types',
      list: ['Advisor', 'Aetherborn', 'Alicorn', 'Alien', 'Ally', 'Angel', 'Antelope', 'Ape', 'Archer', 'Archon',
        'Army', 'Art', 'Artificer', 'Assassin', 'Assembly-Worker', 'Atog', 'Aurochs', 'Autobot', 'Avatar', 'Azra',
        'Baddest,', 'Badger', 'Barbarian', 'Basilisk', 'Bat', 'Bear', 'Beast', 'Beaver', 'Beeble', 'Beholder', 'Berserker',
        'Biggest,', 'Bird', 'Blinkmoth', 'Boar', 'Brainiac', 'Bringer', 'Brushwagg', 'Bureaucrat', 'Camarid', 'Camel', 'Caribou',
        'Carrier', 'Cat', 'Centaur', 'Cephalid', 'Chameleon', 'Chicken', 'Child', 'Chimera', 'Citizen', 'Clamfolk', 'Cleric',
        'Cockatrice', 'Construct', 'Cow', 'Coward', 'Crab', 'Crocodile', 'Cyborg', 'Cyclops', 'Dauthi', 'Deer', 'Demigod',
        'Demon', 'Deserter', 'Designer', 'Devil', 'Dinosaur', 'Djinn', 'Dog', 'Donkey', 'Dragon', 'Drake', 'Dreadnought',
        'Drone', 'Druid', 'Dryad', 'Dwarf', 'Efreet', 'Egg', 'Elder', 'Eldrazi', 'Elemental', 'Elemental?', 'Elephant',
        'Elf', 'Elk', 'Elves', 'Etiquette', 'Eye', 'Faerie', 'Ferret', 'Fish', 'Flagbearer', 'Fox', 'Frog', 'Fungus',
        'Gamer', 'Gargoyle', 'Germ', 'Giant', 'Gnome', 'Goat', 'Goblin', 'God', 'Golem', 'Gorgon', 'Grandchild',
        'Graveborn', 'Gremlin', 'Griffin', 'Gus', 'Hag', 'Harpy', 'Hatificer', 'Head', 'Hellion', 'Hero', 'Hippo',
        'Hippogriff', 'Homarid', 'Homunculus', 'Hornet', 'Horror', 'Horse', 'Human', 'Hydra', 'Hyena', 'Illusion',
        'Imp', 'Incarnation', 'Insect', 'Island', 'Jackal', 'Jellyfish', 'Juggernaut', 'Kangaroo', 'Kavu', 'Killbot',
        'Kirin', 'Kithkin', 'Knight', 'Kobold', 'Kor', 'Kraken', 'Lady', 'Lamia', 'Lammasu', 'Leech', 'Leviathan',
        'Lhurgoyf', 'Licid', 'Lizard', 'Lobster', 'Locus', 'Mammoth', 'Manticore', 'Masticore', 'Mercenary', 'Merfolk',
        'Metathran', 'Mime', 'Minion', 'Minotaur', 'Mole', 'Monger', 'Mongoose', 'Monk', 'Monkey', 'Moonfolk', 'Mouse',
        'Mummy', 'Mutant', 'Myr', 'Mystic', 'Naga', 'Nastiest,', 'Nautilus', 'Nephilim', 'Nightmare', 'Nightstalker',
        'Ninja', 'Noble', 'Noggle', 'Nomad', 'Nymph', 'Octopus', 'Ogre', 'Ooze', 'Orb', 'Orc', 'Orgg', 'Otter', 'Ouphe',
        'Ox', 'Oyster', 'Pangolin', 'Paratrooper', 'Peasant', 'Pegasus', 'Pentavite', 'Pest', 'Phelddagrif', 'Phoenix',
        'Phyrexian', 'Pilot', 'Pincher', 'Pirate', 'Plant', 'Praetor', 'Prism', 'Processor', 'Proper', 'Rabbit', 'Raccoon',
        'Rat', 'Rebel', 'Reflection', 'Reveler', 'Rhino', 'Rigger', 'Rogue', 'Rukh', 'Sable', 'Salamander', 'Samurai',
        'Sand', 'Saproling', 'Satyr', 'Scarecrow', 'Scientist', 'Scion', 'Scorpion', 'Scout', 'Sculpture', 'Serf', 'Serpent',
        'Servo', 'Shade', 'Shaman', 'Shapeshifter', 'Shark', 'Sheep', 'Ship', 'Siren', 'Skeleton', 'Slith', 'Sliver', 'Slug',
        'Snake', 'Soldier', 'Soltari', 'Spawn', 'Specter', 'Spellshaper', 'Sphinx', 'Spider', 'Spike', 'Spirit', 'Splinter',
        'Sponge', 'Spy', 'Squid', 'Squirrel', 'Starfish', 'Surrakar', 'Survivor', 'Teddy', 'Tentacle', 'Tetravite', 'Thalakos',
        'The', 'Thopter', 'Thrull', 'Townsfolk', 'Treefolk', 'Trilobite', 'Triskelavite', 'Troll', 'Turtle', 'Unicorn', 'Vampire',
        'Vampyre', 'Vedalken', 'Viashino', 'Villain', 'Volver', 'Waiter', 'Wall', 'Warlock', 'Warrior', 'Wasp', 'Weird', 'Werewolf',
        'Whale', 'Wizard', 'Wolf', 'Wolverine', 'Wombat', 'Worm', 'Wraith', 'Wrestler', 'Wurm', 'Yeti', 'Zombie', 'Zubera']
    },
    {
      name: 'Planar Types',
      list: ['Alara', 'Arkhos', 'Azgol', 'Belenon', 'Bolas’s Meditation Realm', 'Dominaria', 'Equilor', 'Ergamon', 'Fabacin', 'Innistrad', 'Iquatana', 'Ir', 'Kaldheim', 'Kamigawa', 'Karsus', 'Kephalai', 'Kinshala', 'Kolbahan', 'Kyneth', 'Lorwyn', 'Luvion', 'Mercadia', 'Mirrodin', 'Moag', 'Mongseng', 'Muraganda', 'New Phyrexia', 'Phyrexia', 'Pyrulea', 'Rabiah', 'Rath', 'Ravnica', 'Regatha', 'Segovia', 'Serra’s Realm', 'Shadowmoor', 'Shandalar', 'Ulgrotha', 'Valla', 'Vryn', 'Wildfire', 'Xerex', 'Zendikar']
    }
  ]
  public formatList: Array<ICardFormat> = [
    {
      code: 'oldschool',
      name: 'Old School 93/94'
    },
    {
      code: 'premodern',
      name: 'Premodern'
    },
    {
      code: 'vintage',
      name: 'Vintage'
    },
    {
      code: 'legacy',
      name: 'Legacy'
    },
    {
      code: 'commander',
      name: 'Commander'
    },
    {
      code: 'brawl',
      name: 'Brawl'
    },
    {
      code: 'duel',
      name: 'Duel Commander'
    },
    {
      code: 'standard',
      name: 'Standard'
    },
    {
      code: 'modern',
      name: 'Moden'
    },
    {
      code: 'historic',
      name: 'Historic'
    },
    {
      code: 'gladiator',
      name: 'Gladiator'
    },
    {
      code: 'pioneer',
      name: 'Pioneer'
    },
    {
      code: 'pauper',
      name: 'Pauper'
    },
    {
      code: 'penny',
      name: 'Penny Dreadful'
    }
  ]

  public advanced: any
  public searchString = ''
  public showAdvancedSearch = false
  public cards$ = new BehaviorSubject<List<Card>>(null)
  public simpleCardList$: BehaviorSubject<ISimpleCardExtended[]> = new BehaviorSubject<ISimpleCardExtended[]>(null)

  constructor(
    private globals: GlobalsService,
    private router: Router,
  ) {
    this.getSets()
  }

  searchCards(options?: ISearchOptions) {
    let pageNum = 1
    if (this.showAdvancedSearch) {
      this.searchString = this.advancedSearchString.replace(/  +/g, ' ')
      this.showAdvancedSearch = false
    }
    if (options && options.showLoader) {
      this.globals.isBusy.message = 'Fetching data, please wait'
      this.globals.isBusy.showMessage = true
      this.globals.isBusy.status = true
    }
    if (options && options.pageNum) {
      pageNum = options.pageNum
    }

    scryfall
      .search(this.searchString, {
        include_multilingual: false,
        include_variations: false,
        include_extras: includeAllWotcBans ? true : false,
        // unique: 'prints',
        page: pageNum
      })
      .then((list: List<Card>) => {
        if (options && options.showLoader) {
          this.globals.isBusy.message = `Found ${list.length} cards`
          setTimeout(() => {
            this.globals.isBusy.showMessage = false
            this.globals.isBusy.status = false
          }, 1000)
        }
        console.log(list)
        this.cards$.next(list)
      })
      .catch((err: any) => {
        if (options && options.showLoader) {
          this.globals.isBusy.message = 'Found no cards matching your criteras'
          setTimeout(() => {
            this.globals.isBusy.showMessage = false
            this.globals.isBusy.status = false
          }, 3000)
        }
        console.log(err)
      })
  }

  getCardsByString(searchVal: string, pageNum: number = 1): Promise<Card[]> {
    return new Promise((resolve) => {
      scryfall
        .search(searchVal, {
          include_multilingual: false,
          include_variations: true,
          include_extras: includeAllWotcBans ? true : false,
          unique: 'prints',
          page: pageNum,
          order: 'released',
          dir: 'asc',
        })
        .then((list: List<Card>) => {
          resolve(list)
        })
        .catch((err: any) => {
          console.log(err)
        })
    })
  }

  getCardById(id: string | number, source?: GetCardKind): Promise<Card> {
    return new Promise((resolve, reject) => {
      scryfall.getCard(id, source)
        .then((card) => {
          resolve(card)
        })
        .catch(err => {
          console.log(err)
          resolve(null)
        })
    })
  }

  getFirstPrintedCardByName(name: string): Promise<Card> {
    return new Promise((resolve, reject) => {
      scryfall.search(name, { order: 'released', dir: 'asc', include_variations: true, unique: 'prints' })
        .then((card) => {
          resolve(card[0])
        })
        .catch(err => {
          console.log(err)
          resolve(null)
        })
    })
  }

  getCardByName(name: string, options: ISearchOptions): Promise<Card> {
    return new Promise((resolve, reject) => {
      scryfall.search('!' + name.replace(/\s+/g, ''), { order: 'released', dir: 'asc', unique: 'prints', include_extras: true })
      // scryfall.getCardNamed(name, { set: options.setCode, kind: 'exact' })
        .then((cards) => {
          const cardFromSet = cards.find(c => c.set === options.setCode)
          if (cardFromSet) {
            console.log('card from set', cardFromSet)
            resolve(cardFromSet)
          }
          else {
            console.log('oldest print', cards[0])
            resolve(cards[0])
          }
        })
        .catch(err => {
          console.log(err)
          resolve(null)
        })
    })
  }

  getCardByNameExact(name: string): Promise<Card> {
    return new Promise((resolve) => {
      setTimeout(() => {
        scryfall.getCard(name.replace(/\s+/g, ''), 'exactName')
        // scryfall.getCardNamed(name, { set: options.setCode, kind: 'exact' })
          .then((card) => {
            if (!!card) {
              resolve(card)
            }
            else {
              resolve(null)
            }
          })
          .catch(err => {
            console.log(err)
            resolve(null)
          })
      }, 200)
    })
  }

  getCardRulingById(id: string): Promise<List<GenericScryfallResponse>> {
    return new Promise((resolve, reject) => {
      scryfall
        .getCard(id)
        .then((card: Card) => {
          return card.getRulings()
        })
        .then((list: List<GenericScryfallResponse>) => {
          console.log(list)
          resolve(list)
        })
        .catch(err => {
          console.log(err)
          resolve(null)
        })
    })
  }

  getPrints(id: string): Promise<Array<IMagicCardSet>> {
    return new Promise((resolve, reject) => {
      scryfall
        .getCard(id)
        .then((card: Card) => {
          return card.getPrints()
        })
        .then((list: Array<Card>) => {
          const sets = list.map((card) => {
            const set = this.cleanSetList.find(s => s.code === card.set)
            const cardSet: IMagicCardSet = {
              id: card.id,
              name: card.name,
              set_name: card.set_name,
              set_code: card.set,
              set_released_at: card.released_at,
              set_icon_code: set.icon_code,
              set_icon_svg_uri: set.icon_svg_uri,
            }
            return cardSet
          })
          resolve(sets.reverse())
        })
        .catch(err => {
          console.log(err)
          resolve(null)
        })
    })
  }

  getSets() {
    scryfall.getSets().then(async (sets) => {
      for await (const set of sets) {
        const typeIndex = this.setList.findIndex(s => s.type === set.set_type)
        const setItem: IMagicSetListItem = {
          id: set.id,
          name: set.name,
          code: set.code,
          released_at: set.released_at,
          set_type: set.set_type,
          icon_svg_uri: set.icon_svg_uri,
          icon_code: set.icon_svg_uri.match(new RegExp('sets/' + '(.*)' + '.svg'))[1]
        }
        if (this.setList[typeIndex]) {
          this.setList[typeIndex].list.push(setItem)
        }
        else {
          this.setList.push({
            name: set.set_type.toLowerCase().charAt(0).toUpperCase() + set.set_type.toLowerCase().slice(1),
            type: set.set_type.toLowerCase(),
            list: [setItem]
          })
        }
        this.cleanSetList.push(setItem)
      }
    })
  }

  getCardLanguages(cardMeta: ICardMetaLanguage): Promise<Array<ICardLanguageItem>> {
    return new Promise((resolve, reject) => {
      const searchString = `!"${cardMeta.name}" set:${cardMeta.set} lang:any unique:prints`
      scryfall.search(searchString)
        .then((cards) => {
          const res: Array<ICardLanguageItem> = []
          cards.forEach((card: Card) => {
            const languageItem: ICardLanguageItem = {
              name: card.printed_name ? card.printed_name : card.name,
              lang: card.lang.toUpperCase(),
              id: card.id,
              selected: cardMeta.lang === card.lang
            }
            res.push(languageItem)
          })
          resolve(res)
        })
        .catch(err => {
          console.log(err)
          resolve([])
        })
    })
  }
  getCardImageUrlsById(id: string | number, source?: GetCardKind): Promise<ImageUris> {
    return new Promise((resolve, reject) => {
      scryfall.getCard(id, source)
        .then((card) => {
          resolve(card.image_uris)
        })
        .catch(err => {
          console.log(err)
          resolve(null)
        })
    })
  }

  public clearAdvancedForm() {
    this.advanced = this.advancedSettings
  }
  public addManaSymbol(symbol: string) {
    this.advanced.manaCost += '{' + symbol + '}'
  }
  public addManaSymbolToText(symbol: string) {
    if (this.advanced.text !== '') {
      this.advanced.text += ' '
    }
    switch (symbol) {
      case 'untap':
        this.advanced.text += '{q}'
        break
      case 'tap':
        this.advanced.text += '{t}'
        break
      case 'planeswalker':
        this.advanced.text += '{pw}'
        break
      default:
        this.advanced.text += '{' + symbol + '}'
        break
    }
  }
  public onChangeCMC(event: any) {
    this.advanced.cmc.min = event[0]
    this.advanced.cmc.max = event[1]
  }
  public onChangePower(event: any) {
    this.advanced.power.min = event[0]
    this.advanced.power.max = event[1]
  }
  public onChangeToughness(event: any) {
    this.advanced.toughness.min = event[0]
    this.advanced.toughness.max = event[1]
  }
  public onChangeLoyalty(event: any) {
    this.advanced.loyalty.min = event[0]
    this.advanced.loyalty.max = event[1]
  }
  public addCardType(event: MouseEvent, cardType: string) {
    event.stopPropagation()
    this.advanced.selectedCardTypes.push({
      name: cardType,
      is: true
    } as ISelectedCardType)
    this.advanced.type = ''
  }
  public removeCardType(index: number) {
    this.advanced.selectedCardTypes.splice(index, 1)
  }
  public addSet(event: MouseEvent, code: string, name: string) {
    event.stopPropagation()
    this.advanced.selectedSets.push({
      name,
      code
    } as ISelectedSet)
    this.advanced.sets = ''
  }
  public removeSet(index: number) {
    this.advanced.selectedSets.splice(index, 1)
  }
  public addBlock(event: MouseEvent, code: string, name: string) {
    event.stopPropagation()
    this.advanced.selectedBlocks.push({
      name,
      code
    } as ISelectedBlock)
    this.advanced.blocks = ''
  }
  public removeBlock(index: number) {
    this.advanced.selectedBlocks.splice(index, 1)
  }
  public addFormat(event: MouseEvent, format: ICardFormat) {
    const selectedFormat: ISelectedFormat = {
      code: format.code,
      name: format.name,
      isLegal: true,
      isBanned: false,
      isRestricted: false
    }
    this.advanced.selectedFormats.push(selectedFormat)
  }
  public removeFormat(index: number) {
    this.advanced.selectedFormats.splice(index, 1)
  }
  public getPageNumbers(totalCards: number) {
    const chunks = 175
    const pages = Math.ceil(totalCards / chunks)
    const start = 1
    return Array(pages - start + 1).fill(start, 0, pages).map((_, idx) => start + idx)
  }
  public get advancedSearchString() {
    // name
    const name = this.advanced.name

    // text
    let text = ''
    if (this.advanced.text !== '') {
      const tmpText = this.advanced.text.split(' ')
      tmpText.forEach(val => {
        if (text === '') {
          text += '('
        }
        else {
          text += ' '
        }
        text += 'oracle:' + val
      })
      if (text !== '') {
        text += ')'
      }
    }

    // type
    let type = ''
    this.advanced.selectedCardTypes.forEach(t => {
      if (type !== '') {
        type += ' '
      }
      if (!t.is) {
        type += '-'
      }
      type += 'type:' + t.name.toLowerCase()
    })

    // flavor
    let flavor = ''
    if (this.advanced.flavor !== '') {
      const tmpText = this.advanced.flavor.split(' ')
      tmpText.forEach(val => {
        if (flavor === '') {
          flavor += '('
        }
        else {
          flavor += ' '
        }
        flavor += 'oracle:' + val
      })
      if (flavor !== '') {
        flavor += ')'
      }
    }

    // artist
    let artist = ''
    if (this.advanced.artist !== '') {
      const tmpText = this.advanced.artist.split(' ')
      tmpText.forEach(val => {
        if (artist === '') {
          artist += '('
        }
        else {
          artist += ' '
        }
        artist += 'artist:' + val
      })
      if (artist !== '') {
        artist += ')'
      }
    }

    // color
    let color = ''
    Object.keys(this.advanced.color).forEach(c => {
      if (this.advanced.color[c] === true) {
        if (color === '') {
          switch (this.advanced.selectedColorsOperator) {
            case 'Excatly these colors':
              color += 'color='
              break
            case 'Including these colors':
              color += 'color>='
              break
            case 'At most these colors':
              color += 'color<='
              break
          }
        }
        color += c
      }
    })

    // mana
    let manaCost = ''
    if (this.advanced.manaCost !== '') {
      const tmpText = this.advanced.manaCost.split(' ')
      tmpText.forEach(val => {
        if (manaCost === '') {
          manaCost += 'mana:'
        }
        manaCost += val
      })
    }

    // cmc
    let cmc = ''
    if (this.advanced.cmc.min > 0 || this.advanced.cmc.max < 20) {
      cmc = 'cmc<=' + this.advanced.cmc.max + ' cmc>=' + this.advanced.cmc.min
    }

    // power
    let power = ''
    if (this.advanced.power.min > 0 || this.advanced.power.max < 20) {
      power = 'pow<=' + this.advanced.power.max + ' pow>=' + this.advanced.power.min
    }

    // toughness
    let toughness = ''
    if (this.advanced.toughness.min > 0 || this.advanced.toughness.max < 20) {
      toughness = 'tou<=' + this.advanced.toughness.max + ' tou>=' + this.advanced.toughness.min
    }

    // loyalty
    let loyalty = ''
    if (this.advanced.loyalty.min > 0 || this.advanced.loyalty.max < 20) {
      loyalty = 'loy<=' + this.advanced.loyalty.max + ' loy>=' + this.advanced.loyalty.min
    }

    // rarity
    let rarity = ''
    Object.keys(this.advanced.rarity).forEach(k => {
      if (this.advanced.rarity[k] === true) {
        if (rarity === '') {
          rarity += '(' + 'rarity:' + k
        }
        else {
          rarity += ' OR ' + 'rarity:' + k
        }
      }
    })
    if (rarity !== '') {
      rarity += ')'
    }

    // sets
    let sets = ''
    this.advanced.selectedSets.forEach((s: ISelectedSet) => {
      if (sets === '') {
        sets += '(' + 'set:' + s.code
      }
      else {
        sets += ' OR ' + 'set:' + s.code
      }
    })
    if (sets !== '') {
      sets += ')'
    }

    // blocks
    let blocks = ''
    this.advanced.selectedBlocks.forEach((b: ISelectedBlock) => {
      if (blocks === '') {
        blocks += '(' + 'block:' + b.code
      }
      else {
        blocks += ' OR ' + 'block:' + b.code
      }
    })
    if (blocks !== '') {
      blocks += ')'
    }

    // formats
    let formats = ''
    this.advanced.selectedFormats.forEach((f: ISelectedFormat) => {
      if (f.isLegal) {
        formats += ' legal:' + f.code
      }
      else if (f.isRestricted) {
        formats += ' restricted:' + f.code
      }
      else if (f.isBanned) {
        formats += ' banned:' + f.code
      }
    })

    // show all prints
    let showAllPrints = ''
    if (this.advanced.showAllPrints || this.router.url.includes('decks/edit')) {
      showAllPrints = 'unique:prints'
    }

    // include extras
    let includeExtras = ''
    if (this.advanced.includeExtras) {
      includeExtras = 'include:extras'
    }

    return name +
      ' ' + rarity +
      ' ' + text +
      ' ' + type +
      ' ' + flavor +
      ' ' + artist +
      ' ' + color +
      ' ' + cmc +
      ' ' + power +
      ' ' + toughness +
      ' ' + loyalty +
      ' ' + sets +
      ' ' + blocks +
      ' ' + formats +
      ' ' + includeExtras +
      ' ' + showAllPrints
  }
  public get advancedSettings() {
    return {
      showCardTypesPanel: false,
      showSetsPanel: false,
      showBlocksPanel: false,
      showFormatsPanel: false,
      selectedColorsOperator: 'Excactly these colors',
      selectedCardTypes: [] as Array<ISelectedCardType>,
      selectedSets: [] as Array<ISelectedSet>,
      selectedBlocks: [] as Array<string>,
      selectedFormats: [] as Array<ICardFormat>,
      type: '',
      sets: '',
      blocks: '',
      formats: '',
      name: '',
      text: '',
      flavor: '',
      artist: '',
      manaCost: '',
      showAllPrints: false,
      includeExtras: false,
      rarity: {
        c: false,
        u: false,
        r: false,
        mr: false,
      },
      color: {
        w: false,
        u: false,
        b: false,
        r: false,
        g: false,
        c: false,
      },
      cmc: {
        min: 0,
        max: 20
      },
      power: {
        min: 0,
        max: 20
      },
      toughness: {
        min: 0,
        max: 20
      },
      loyalty: {
        min: 0,
        max: 20
      },
      colorsOperator: ['Excatly these colors', 'Including these colors', 'At most these colors'],
      manaSymbols: ['w', 'u', 'b', 'r', 'g', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', 'wu', 'wb', 'br', 'bg', 'ub', 'ur', 'rg', 'rw', 'gw', 'gu', '2w', '2u', '2b', '2r', '2g', 'wp', 'up', 'bp', 'rp', 'gp', 's', 'c'],
      otherSymbols: ['tap', 'untap', 'e', 'planeswalker', 'chaos']
    }
  }
  public getCardTypes(type_line: string): CardTypes {
    const all = type_line.split(' — ')
    const types = all[0].split(' ')
    const subTypes = all.length > 1
      ? all[1].split(' ')
      : []
    return { types: types, subTypes: subTypes } as CardTypes
  }
}

export interface CardTypes {
  types: string[]
  subTypes: string[]
}

export interface ISimpleCard {
  colors: string[]
  convertedManaCost: number
  manaCost: string
  name: string
  printings: ISimpleCardPrinting[]
  text: string
  type: string
  types: string[]
}
export interface ISimpleCardExtended extends ISimpleCard {
  isWhite: boolean
  isBlue: boolean
  isBlack: boolean
  isRed: boolean
  isGreen: boolean
  isColorless: boolean
  isMulticolored: boolean
}
export interface ISimpleCardPrinting {
  keyruneCode: string
  layout: string
  scryfallId: string
  scryfallIllustrationId: string
  set: string
  setCode: string
  uuid: string
  iconUrl?: string
}
export interface ISearchOptions {
  source?: GetCardKind
  setCode?: string
  pageNum?: number
  showLoader?: boolean
}
export interface ICardTypes {
  name: string
  list: Array<string>
}
export interface IMagicSet {
  name: string
  type: string
  list: Array<IMagicSetListItem>
}
export interface IMagicSetData {
  arena_code: string
  block: string
  block_code: string
  card_count: number
  code: string
  digital: boolean
  foil_only: boolean
  icon_svg_uri: string
  id: string
  mtgo_code: string
  name: string
  nonfoil_only: boolean
  object: string
  released_at: string
  scryfall_uri: string
  search_uri: string
  set_type: string
  tcgplayer_id: number
  uri: string
}
export interface IMagicSetListItem {
  id: string
  code: string
  name: string
  released_at: string
  set_type: string
  icon_svg_uri: string
  icon_code: string
}
export interface IMagicCardSet {
  id: string
  name: string
  set_name: string
  set_code: string
  set_released_at: string
  set_icon_code: string
  set_icon_svg_uri: string
}
export interface IMagicBlock {
  name: string
  code: string
}
export interface ISelectedCardType {
  name: string
  is: boolean
}
export interface ISelectedSet {
  name: string
  code: string
}
export interface ISelectedBlock {
  name: string
  code: string
}
export interface ICardLanguageItem {
  id: string
  name: string
  lang: string
  selected: boolean
}
export interface ICardFormat {
  code: string
  name: string
}
export interface ISelectedFormat extends ICardFormat {
  isLegal: boolean
  isBanned: boolean
  isRestricted: boolean
}

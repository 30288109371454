import { EventDetailsComponent } from './event-details/event-details.component';
import { LocalStorageService } from 'src/app/services/local-storage.service'
import { MessagesService } from 'src/app/services/messages.service'
import { EventPlayerCheckInComponent } from './organizer/event-player-check-in/event-player-check-in.component'
import { MatchService } from 'src/app/services/match.service'
import { map, pairwise, takeUntil, take } from 'rxjs/operators'
import { AuthService, EventService, GlobalsService } from 'src/app/services'
import { IEventDetails, IMatchData, IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { combineLatest, Observable, Subject } from 'rxjs'
import { faTimes, faBell, faBellSlash, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { Location } from '@angular/common'

export interface IMobileViews {
  main: boolean
  details: boolean
  organizer: boolean
}
export interface IEventLobbyViewSettings {
  details: boolean
  organizer: boolean
  messages: boolean
}
@Component({
  selector: 'app-event',
  templateUrl: './event-lobby.component.html',
  styleUrls: ['./event-lobby.component.css']
})
export class EventLobbyComponent implements OnInit, OnDestroy {
  @ViewChild('tryTheNewLayout', { static: false }) tryTheNewLayout: ElementRef
  // font icons
  icons = {
    faTimes,
    faBell,
    faBellSlash,
    faChevronLeft
  }

  // properties and models
  eventDocId: string
  eventDoc$: Observable<IEventDetails>
  eventPlayers$: Observable<IEventPlayerDetails[]>
  eventMatches$: Observable<IMatchData[]>
  playerDoc$: Observable<IEventPlayerDetails>
  isOrganizer = false
  isPlayer = false
  muteMessages = false
  public mobileView: IMobileViews = {
    main: false,
    details: true,
    organizer: false,
  }

  // modal refs
  playerCheckInModalRef: NgbModalRef

  private componentWasDestroyed$ = new Subject<boolean>()

  constructor(
    public eventService: EventService,
    private matchService: MatchService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private modalService: NgbModal,
    public globals: GlobalsService,
    private router: Router,
    private messageService: MessagesService,
    private location: Location,
    private lss: LocalStorageService,
  ) {

    this.initViewSettings()

    this.eventDocId = this.route.snapshot.params.eventDocId
    this.playerDoc$ = this.eventService.getSinglePlayerDocument(this.eventDocId, this.auth.user.playerId)
    this.eventDoc$ = this.eventService.getEventById(this.eventDocId)
    this.eventPlayers$ = this.eventService.getEventPlayersByEventId(this.eventDocId)
    this.eventMatches$ = this.matchService.getMatchesForEventByEventId(this.eventDocId)
      .pipe(
        // mapping up any batch match with the flag hasPlayedEarlier
        map((matches) => {
          const returnObj: Array<IMatchData> = []
          // if the first match is a batch, then all will be
          if (matches.length > 0 && matches[0].isType === 'batch') {
            matches.forEach((match: IMatchData) => {
              match.hasPlayedEarlier = matches.filter(p =>
                p.player1.playerDocId === match.player1.playerDocId &&
                p.player2.playerDocId === match.player2.playerDocId ||
                p.player2.playerDocId === match.player1.playerDocId &&
                p.player1.playerDocId === match.player2.playerDocId
              ).length > 1
              returnObj.push(match)
            })
            return returnObj
          }
          // return without manipulation
          else {
            return matches
          }
        })
      )
    this.eventService.getOrganizerIdsForEventWithId(this.eventDocId).then((ids) => {
      this.isOrganizer =
        this.auth.user.role === 'admin' ||
        ids.includes(this.auth.user.playerId) ||
        ids.includes(this.auth.user.uid)
    })
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.globals.hotkeysDisabled || this.modalService.hasOpenModals()) {
      return
    }
    // toggle chat
    if (event.key === 'm' && !this.globals.isChatting) {
      event.preventDefault()
      this.eventService.eventViewSettings.messages = !this.eventService.eventViewSettings.messages
      this.saveViewSettings()
    }
    // toggle info
    if (event.key === 'd') {
      event.preventDefault()
      this.eventService.eventViewSettings.details = !this.eventService.eventViewSettings.details
      this.saveViewSettings()
    }
    // toggle players
    if (event.key === 'o' && !this.globals.isChatting) {
      event.preventDefault()
      this.eventService.eventViewSettings.organizer = !this.eventService.eventViewSettings.organizer
      this.saveViewSettings()
    }
  }

  ngOnInit(): void {
    // for mobile view, select the correct view depending on status
    combineLatest([this.eventDoc$, this.playerDoc$])
    .pipe(take(1)).subscribe(([doc, player]) => {

      this.modalService
      .open(this.tryTheNewLayout, {
        animation: true,
        keyboard: true,
        backdrop: true,
        size: 'sm',
      })
      .result
      .then(() => this.router.navigate(['/event', doc.docId, 'details']))

      if (doc.statusCode > 0) {
        this.changeView('main')
      }
      // set isPlayer && isOrganizer
      this.isOrganizer = this.auth.user.role === 'admin' ||
        doc.createdByUid === this.auth.user.uid ||
        doc.coOrganizers.includes(this.auth.user.playerId) ||
        doc.coOrganizers.includes(this.auth.user.uid)
      this.isPlayer = doc.playerDocIds.includes(this.auth.user.playerId)
      if (this.isPlayer && !this.isOrganizer && doc.checkInHasStarted && !player.hasCheckedIn) {
        this.openPlayerCheckIn()
      }
    })


    // check if this is a invitation response
    // console.log(this.route.snapshot.params)
    if (this.route.snapshot.params.eventAction !== undefined) {
      switch (this.route.snapshot.params.eventAction) {

        case 'accept-invitation':
          this.answerInvitation()
          break

        case 'decline-invitation':
          this.answerInvitation()
          break

        case 'show-details':
          setTimeout(() => {
            this.openEventDetails()
          }, 2000)
          break

      }
    }
    else {

      combineLatest([this.eventDoc$, this.playerDoc$]).pipe(
        pairwise(),
        takeUntil(this.componentWasDestroyed$)
      ).subscribe(([[pe, prevPlayer], [ce, currPlayer]]) => {

        const currEvent = typeof ce !== 'number' ? ce : null
        const prevEvent = typeof pe !== 'number' ? pe : null

        console.log('eventDoc$ emitted', { ce: currEvent , pe: prevEvent, cp: currPlayer, pp: prevPlayer })

        // set isPlayer && isOrganizer
        this.isOrganizer =
          this.auth.user.role === 'admin' ||
          currEvent.createdByUid === this.auth.user.uid ||
          currEvent.coOrganizers.includes(this.auth.user.playerId) ||
          currEvent.coOrganizers.includes(this.auth.user.uid)
        this.isPlayer = currEvent.playerDocIds.includes(this.auth.user.playerId)


        if (this.isPlayer && !this.isOrganizer && currEvent.checkInHasStarted && currEvent.checkInByPlayer) {
          if (!currPlayer.hasCheckedIn && this.playerCheckInModalRef) {
            console.log('should open the player check in')
            this.openPlayerCheckIn()
          }
          else {
            console.log('player already checked in')
            if (this.playerCheckInModalRef !== undefined) {
              this.playerCheckInModalRef.close()
            }
          }
        }
        else {
          if (this.playerCheckInModalRef !== undefined) {
            this.playerCheckInModalRef.close()
          }
        }
      })

    }

  }

  private openEventDetails(): void {
    console.log('should open event details as a modal dialog', this.eventDocId)
    this.modalService.dismissAll()
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: false,
      size: 'lg',
    }
    const modal = this.modalService.open(EventDetailsComponent, modalOptions)
    modal.componentInstance.showAsModal = true
    modal.componentInstance.eventDocId = this.eventDocId
  }

  private answerInvitation(): void {
    this.eventDoc$.pipe(
      // withLatestFrom(this.playerDoc$),
      take(1)
    ).subscribe((event) => {
      const invitationResponse = this.route.snapshot.params.eventAction
      const invitedPlayer = event.invitedPlayers.find(p => p.playerUid === this.auth.user.uid)
      console.log(`Player is responding (${invitationResponse}) to an invitation, lets handle the response`)
      if (invitedPlayer !== undefined) {
        console.log(`Found player with uid: ${this.auth.user.uid} in the invitation list`, invitedPlayer)
        this.eventService.answerInvitation(invitationResponse === 'accept-invitation', invitedPlayer, event.docId, this.auth.user.playerId)
          .then(async (response) => {
            if (response.status) {
              // update the message as well
              invitedPlayer.accepted = invitationResponse === 'accept-invitation'
              invitedPlayer.declined = invitationResponse !== 'accept-invitation'
              this.messageService.updateEventInvitationMessage(invitedPlayer).then((res) => {
                this.router.navigate(['event-lobby', event.docId, event.details.name])
              })
            }
          })
          .catch((err) => console.log(err))
      }
      else {
        console.log(`cant find player with uid: ${this.auth.user.uid} in the invitation list`, event.invitedPlayers)
        this.router.navigate(['event-lobby', event.docId, event.details.name])
      }
    })
  }

  ngOnDestroy(): void {
    this.componentWasDestroyed$.next(true)
  }

  private initViewSettings(): void {
    const settings = this.lss.eventLobbyViewSettings
    typeof settings === 'boolean' && !settings ? this.saveViewSettings() : this.eventService.eventViewSettings = settings
  }
  public saveViewSettings(): void {
    this.lss.setEventLobbyViewSettings(this.eventService.eventViewSettings)
  }

  navBack() {
    this.location.back()
  }

  changeView(view: string): void {
    Object.keys(this.mobileView).forEach(key => {
      this.mobileView[key] = false
    })
    this.mobileView[view] = true
  }

  openPlayerCheckIn(): void {

    this.modalService.dismissAll()

    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: false,
      size: 'large',
      // windowClass: 'modal-full-size'
    }
    this.playerCheckInModalRef = this.modalService.open(EventPlayerCheckInComponent, modalOptions)
    this.playerCheckInModalRef.componentInstance.eventDocId = this.eventDocId
  }

  public get isAdmin(): boolean {
    if (this.auth.user) {
      return this.auth.user.role === 'admin'
    }
    return false
  }

}

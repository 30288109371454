<ng-container *ngIf="standings$ | async as teams">

    <ng-container *ngFor="let team of teams">

        <div class="row player p-2 m-0">
            
            <div class="col-1">
                {{ team.rank }}
            </div>
            
            <div class="col-5 d-flex align-items-center">
                <div class="flex-grow-1">{{ team.name }}</div>
                <div class="flex-grow-o d-flex align-items-center" ngbTooltip="Non-playoff match stats [Wins][Losses][Draws]" container="body">
                    <span class="badge text-bg-dark ms-1">{{ team.matchesWon }}</span>
                    <span class="badge text-bg-dark ms-1">{{ team.matchesLost }}</span>
                    <span class="badge text-bg-dark ms-1">{{ team.matchesDrawn }}</span>
                </div>
            </div>
            
            <div class="col-1-5 text-end">
                {{ team.matchPoints }}
                <span class="text-secondary"> p</span>
            </div>
            
            <div class="col-1-5 text-secondary text-end">
                {{ (team.tiebreakers.oppMatchWinPct * 100).toFixed(2) }}%
            </div>

            <div class="col-1-5 text-secondary text-end">
                {{ (team.tiebreakers.gameWinPct * 100).toFixed(2) }}%
            </div>

            <div class="col-1-5 text-secondary text-end">
                {{ (team.tiebreakers.oppGameWinPct * 100).toFixed(2) }}%
            </div>
        </div>

    </ng-container>

</ng-container>
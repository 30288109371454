import { ToastService } from 'src/app/services/toast.service'
import { faEllipsisH, faReply, faCopy, faEdit, faUsers, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { takeUntil, tap } from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router'
import { TicketService } from 'src/app/services/ticket.service'
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core'
import { Subject } from 'rxjs/internal/Subject'
import { BehaviorSubject, Observable } from 'rxjs'
import { ITicket, ITicketPurchase } from 'tolaria-cloud-functions/src/_interfaces'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { NewTicketComponent } from '../../new-ticket/new-ticket.component'

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.css']
})
export class TicketDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('deleteConfirmation') deleteConfirmation: ElementRef

  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()
  private ticketDocId: string
  public ticket$: BehaviorSubject<ITicket> = new BehaviorSubject<ITicket>(null)
  private ticketDoc$: Observable<ITicket>
  public showEdit = false

  public playerAction = faEllipsisH
  public refundIcon = faReply
  public copyIcon = faCopy
  public editIcon = faEdit
  public attendeesIcon = faUsers
  public warningIcon = faExclamationTriangle

  constructor(
    private ticketService: TicketService,
    private route: ActivatedRoute,
    private toast: ToastService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.componentDestroyed$)).subscribe(params => {
      if (this.ticketDocId !== params.ticketDocId) {
        this.ticketDoc$ = this.ticketService.getTicket(params.ticketDocId)
      }
      this.ticketDocId = params.ticketDocId
      this.ticketDoc$.pipe(takeUntil(this.componentDestroyed$)).subscribe(t => this.ticket$.next(t))
    })

  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  public issueRefund(ticket: ITicket, paymentIntent: string): void {
    this.ticketService.refundTicket(ticket, paymentIntent)
  }

  public get ticketsPurchased(): ITicketPurchase[] {
    return Object.values(this.ticket$.getValue().salesData.ticketPurchases)
  }

  public copyTextToClipboard(text: string) {
    const el = document.createElement('textarea')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    this.toast.show('Text copied to clipboard', { classname: 'standard-toast', delay: 2000 })
  }

  public editTicket(ticket: ITicket): void {

    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: 'static',
      keyboard: true,
      size: 'lg',
    }

    const ref = this.modalService.open(NewTicketComponent, modalOptions)
    ref.componentInstance.edit = true
    ref.componentInstance.data = ticket

  }

  public deleteTicket(docId: string): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: false,
      keyboard: false,
      size: 'sm',
    }

    this.modalService.open(this.deleteConfirmation, modalOptions).result
      .then((result) => {
        if (result) {
          this.ticketService.deleteTicket(docId)
        }
      });
  }

  public get totalSales(): string {
    const ticket = this.ticket$.getValue()
    const sales = Object.values(ticket.salesData.ticketPurchases).filter(i => !i.refunded).reduce((acc, i) => acc + i.price, 0)
    return `${sales} ${ticket.salesData.currency.toUpperCase()}`
  }

}

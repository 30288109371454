import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IMatchData } from './../../../../../../tolaria-cloud-functions/src/_interfaces';
import { takeUntil } from 'rxjs/operators'
import { ToastService } from 'src/app/services/toast.service'
import { faCheckCircle, faEnvelopeOpenText, faInfoCircle, faUsers, faTimes } from '@fortawesome/free-solid-svg-icons'
import { IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { EventService, AuthService, GlobalsService } from 'src/app/services'
import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core'
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { Observable, Subject, BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('deckUploadModal', { static: false }) deckUploadModal: ElementRef
  @ViewChild('deckRegistrationModal', { static: false }) deckRegistrationModal: ElementRef
  @Input() eventDocId: string
  @Input() showAsModal: boolean = false

  eventDoc$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  playerDetails$: BehaviorSubject<IEventPlayerDetails> = new BehaviorSubject<IEventPlayerDetails>(null)

  // font icons
  faActions = faCheckCircle
  faInfo = faInfoCircle
  faUsers = faUsers
  faEnvelopeOpenText = faEnvelopeOpenText
  faTimes = faTimes

  componentDestroyed$: Subject<boolean> = new Subject<boolean>()
  player$: Observable<IEventPlayerDetails>
  selectedPlayer: IEventPlayerDetails

  public sections = {
    playerActions: true,
    information: false,
    attendingPlayers: false,
  }

  constructor(
    public es: EventService,
    public auth: AuthService,
    private toastService: ToastService,
    public globals: GlobalsService,
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.es.getEventById(this.eventDocId).pipe(takeUntil(this.componentDestroyed$)).subscribe(event => this.eventDoc$.next(event))
    this.es.getSinglePlayerDocument(this.eventDocId, this.auth.user.playerId).pipe(takeUntil(this.componentDestroyed$)).subscribe(p => this.playerDetails$.next(p))
  }
  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  closeModal(): void {
    this.activeModal.close()
  }

  closeAllSections(section: string = null) {
    this.selectedPlayer = undefined
    const currentState = this.sections[section]
    console.log(`current state of ${section} = ${currentState}`)
    Object.keys(this.sections).forEach(key => {
      this.sections[key] = false
    })
    console.log(this.sections)

    if (section !== null) {
      console.log(`current state of ${section} = ${currentState}`)
      this.sections[section] = !currentState
      console.log(`current state of ${section} = ${this.sections[section]}`)
    }
  }
  attendEvent(eventDocId: string) {
    // fail safe if anyone removes the disabled attribute on the button in the dev console
    if (this.disableAttendButton) { return }
    this.es.addPlayerToEvent(eventDocId, this.auth.user.uid, this.auth.user.playerId, null)
      .then(response => {
        if (response.status) {
          this.toastService.show('You have been signed up to attend the event.', { classname: 'success-toast', delay: 2000 })
        }
      })
  }
  public get registrationOpen(): boolean {
    const now = new Date()
    const event = this.eventDoc$.getValue()
    if (event.details.registrationOpensTimestamp > now.getTime() || event.details.registrationClosesTimestamp < now.getTime()) {
      return false
    }
    return true
  }
  public get disableAttendButton(): boolean {
    // if the event has a cap, check the number of attending players
    const event = this.eventDoc$.getValue()
    const now = new Date()
    if (event.details.registrationOpensTimestamp > now.getTime() || event.details.registrationClosesTimestamp < now.getTime()) {
      return true
    }
    if (event.details.hasAttendeeCap) {
      return this.eventDoc$.getValue().playerDocIds.length >= this.eventDoc$.getValue().details.attendeeCap
    }
    return false
  }
  public get isInvited(): boolean {
    return this.eventDoc$.getValue().invitedPlayers.find(p => p.playerDocId === this.auth.user.playerId) !== undefined
  }
  public get isAttending(): boolean {
    return this.eventDoc$.getValue().playerDocIds.includes(this.auth.user.playerId)
  }
  public get isOrganizer(): boolean {
    if (this.eventDoc$.getValue().createdByUid === this.auth.user.uid) return true
    if (this.eventDoc$.getValue().coOrganizers.includes(this.auth.user.uid)) return true
    if (this.eventDoc$.getValue().coOrganizers.includes(this.auth.user.playerId)) return true
  }
  public get isAdmin(): boolean {
    return this.auth.user.role === 'admin'
  }
  public get showInvitedPlayers(): boolean {
    return this.eventDoc$.getValue().invitedPlayers !== undefined
      && this.eventDoc$.getValue().invitedPlayers.length > 0
      && this.eventDoc$.getValue().details.registrationOpen
      || this.eventDoc$.getValue().invitedPlayers !== undefined
      && this.eventDoc$.getValue().invitedPlayers.length > 0
      && this.eventDoc$.getValue().details.registrationOpen === undefined
      || this.isInvited
      || this.isAttending
      || this.isOrganizer
      || this.isAdmin
  }
  public get showAttendingPlayers(): boolean {
    return this.eventDoc$.getValue().details.registrationOpen === undefined
      || this.eventDoc$.getValue().details.registrationOpen
      || this.isInvited
      || this.isAttending
      || this.isOrganizer
      || this.isAdmin
  }
  public get hasUnfinisedTasks(): boolean {
    const event = this.eventDoc$.getValue()
    const player = this.playerDetails$.getValue()
    if (event.details.deckPhoto && !player.deckSubmission.deckPhoto) return true
    if (event.details.deckList && !player.deckSubmission.deckList) return true
    if (event.details.registrationFee
      && event.details.registrationFee.active
      && event.details.registrationFee.paidBy
      && event.details.registrationFee.paidBy[player.playerDocId]
      && !event.details.registrationFee.paidBy[player.playerDocId].paid
      || event.details.registrationFee
      && event.details.registrationFee.active
      && event.details.registrationFee.paidBy
      && !event.details.registrationFee.paidBy[player.playerDocId]
      || event.details.registrationFee
      && event.details.registrationFee.active
      && !event.details.registrationFee.paidBy) return true

    return false
  }

}

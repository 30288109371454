import { Pipe, PipeTransform } from '@angular/core';
import { IPick } from 'tolaria-cloud-functions/src/_interfaces';

export interface IDraftPickPipeOptions {
  isAscending: boolean;
  sortBy: string;
  groupCards: boolean;
  colors: {
    white: boolean;
    blue: boolean;
    black: boolean;
    red: boolean;
    green: boolean;
    colorless: boolean;
  }
}
export interface IPickMeta extends IPick {
  qty: number;
}

@Pipe({
  name: 'draftPicks',
  // pure: false
})
export class DraftPicksPipe implements PipeTransform {

  transform(cards: IPick[], options: IDraftPickPipeOptions, refresh: number): IPick[] {

    console.log(cards);

    if (options.isAscending) {
      switch (options.sortBy) {
        case 'pickOrder':
          cards.sort((a, b) => a.pickedTimestamp - b.pickedTimestamp);
          break;
        case 'cmc':
          cards.sort((a, b) => a.card.convertedManaCost - b.card.convertedManaCost);
          break;
        case 'type':
          cards.sort((a, b) => (a.card.typeSymbol < b.card.typeSymbol) ? -1 : ((b.card.typeSymbol < a.card.typeSymbol) ? 1 : 0));
          break;
      }
    }
    else {
      switch (options.sortBy) {
        case 'pickOrder':
          cards.sort((a, b) => b.pickedTimestamp - a.pickedTimestamp);
          break;
        case 'cmc':
          cards.sort((a, b) => b.card.convertedManaCost - a.card.convertedManaCost);
          break;
        case 'type':
          cards.sort((a, b) => (b.card.typeSymbol < a.card.typeSymbol) ? -1 : ((a.card.typeSymbol < b.card.typeSymbol) ? 1 : 0));
          break;
      }
    }


    // check if cards should be grouped or not
    if (options.groupCards) {
      const tmpCards: IPickMeta[] = JSON.parse(JSON.stringify(cards));
      const tmpReturn: IPickMeta[] = [];

      tmpCards.forEach((pick) => {
        // get card index in the temp array
        const pickIndex = tmpReturn.findIndex(p => p.card.name === pick.card.name);
        if (pickIndex !== -1) {
          if (tmpReturn[pickIndex].qty) {
            tmpReturn[pickIndex].qty++;
          }
        }
        else {
          pick.qty = 1;
          tmpReturn.push(pick);
        }
      });

      cards = tmpReturn;

    }

    // colors
    const colorFilter: IPick[] = [];
    for (const c of cards) {
      if (options.colors.white && c.card.colors.includes('White') || options.colors.white && c.card.colors.includes('W')) {
        colorFilter.push(c);
        continue;
      }
      if (options.colors.blue && c.card.colors.includes('Blue') || options.colors.blue && c.card.colors.includes('U')) {
        colorFilter.push(c);
        continue;
      }
      if (options.colors.black && c.card.colors.includes('Black') || options.colors.black && c.card.colors.includes('B')) {
        colorFilter.push(c);
        continue;
      }
      if (options.colors.red && c.card.colors.includes('Red') || options.colors.red && c.card.colors.includes('R')) {
        colorFilter.push(c);
        continue;
      }
      if (options.colors.green && c.card.colors.includes('Green') || options.colors.green && c.card.colors.includes('G')) {
        colorFilter.push(c);
        continue;
      }
      if (options.colors.colorless && c.card.colors.length === 0) {
        colorFilter.push(c);
        continue;
      }
    }

    cards = colorFilter;


    return cards;

  }

}

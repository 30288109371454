import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import {
  faCheckSquare,
  faBold,
  faCode,
  faHeading,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faQuoteRight,
  faAt
} from '@fortawesome/free-solid-svg-icons'
import { DeviceDetectorService } from 'ngx-device-detector'

@Component({
  selector: 'app-markdown-editor',
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.css']
})
export class MarkdownEditorComponent implements OnInit {
  @Input() buttonText: string = 'Send'
  @ViewChild('inputArea') inputArea: ElementRef

  public writing: boolean = true
  public textInput: string = ''
  private get actionKey(): string {
    return this.device.os.includes('Mac')
      ? 'Cmd'
      : 'Ctrl'
  }

  public toolbarButtons = [
    {
      action: () => {
        this.inputArea.nativeElement.focus()
        this.inputArea.nativeElement.setRangeText('### ', this.inputArea.nativeElement.selectionStart, this.inputArea.nativeElement.selectionStart)
        this.inputArea.nativeElement.selectionStart = this.inputArea.nativeElement.selectionStart + 4
      },
      tooltip: `Add heading text`,
      icon: faHeading,
    },
    {
      action: () => {
        this.inputArea.nativeElement.focus()
        this.inputArea.nativeElement.setRangeText('**', this.inputArea.nativeElement.selectionStart, this.inputArea.nativeElement.selectionStart)
        this.inputArea.nativeElement.selectionStart = this.inputArea.nativeElement.selectionEnd + 2
        this.inputArea.nativeElement.setRangeText('**', this.inputArea.nativeElement.selectionStart, this.inputArea.nativeElement.selectionStart)
        if (this.inputArea.nativeElement.selectionStart !== this.inputArea.nativeElement.selectionStart) {
          this.inputArea.nativeElement.selectionStart = this.inputArea.nativeElement.selectionEnd + 2
        }
      },
      tooltip: `Add bold text, <span class="badge badge-light">${this.actionKey} + b</span>`,
      icon: faBold,
    },
    {
      action: () => {
        alert('test')
      },
      tooltip: `Add italic text, <span class="badge badge-light">${this.actionKey} + i</span>`,
      icon: faItalic,
    },
    {
      action: () => {
        alert('test')
      },
      tooltip: `Add qoute, <span class="badge badge-light">${this.actionKey} + Shift + .</span>`,
      icon: faQuoteRight,
    },
    {
      action: () => {
        alert('test')
      },
      tooltip: `Add code, <span class="badge badge-light">${this.actionKey} + e</span>`,
      icon: faCode,
    },
    {
      action: () => {
        alert('test')
      },
      tooltip: `Add link, <span class="badge badge-light">${this.actionKey} + k</span>`,
      icon: faLink,
    },
    {
      action: () => {
        alert('test')
      },
      tooltip: `Add a bullet list, <span class="badge badge-light">${this.actionKey} + Shift + 8</span>`,
      icon: faListUl,
    },
    {
      action: () => {
        alert('test')
      },
      tooltip: `Add a numbered list, <span class="badge badge-light">${this.actionKey} + Shift + 7</span>`,
      icon: faListOl,
    },
    {
      action: () => {
        alert('test')
      },
      tooltip: `Add a task list, <span class="badge badge-light">${this.actionKey} + Shift + t</span>`,
      icon: faCheckSquare,
    },
    {
      action: () => {
        alert('test')
      },
      tooltip: `Directly mention someone`,
      icon: faAt,
    },
  ]

  constructor(private device: DeviceDetectorService) { }

  ngOnInit(): void {
    console.log('test')
  }

}



import { FontIconsService } from 'src/app/services/font-icons.service';
import { DraftService } from 'src/app/services/draft.service';
import { Observable } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { IDraftSet } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-set-list-selector',
  templateUrl: './set-list-selector.component.html',
  styleUrls: ['./set-list-selector.component.css']
})
export class SetListSelectorComponent implements OnInit {
  @Input() multiSelect = false;

  public setList$: Observable<IDraftSet[]>;

  public filterValue = '';

  constructor(
    private activeModal: NgbActiveModal,
    private draftService: DraftService,
    public fontIcons: FontIconsService
  ) {
  }

  ngOnInit(): void {
    this.setList$ = this.draftService.getSetList();
  }

  close(set: IDraftSet): void {
    this.activeModal.close(set);
  }

  closeMultiSelect(sets: IDraftSet[]): void {
    const setCodes = sets.filter(s => s.selected);
    this.activeModal.close(setCodes);
  }

  public getMultipleSetsSelected(sets: IDraftSet[]): boolean {
    return sets.filter((s) => s.selected).length > 0;
  }

}

<div class="border border-secondary border-opacity-75 rounded action-wrapper bg-body p-1">

  <tolaria-emoji-picker-button
    ngbTooltip="Add reaction..."
    container="body"  
    buttonStyle="btn btn-sm"
    [tooltipDisabled]="true"
    buttonHoverStyle="bg-secondary bg-opacity-10"
    (onEmojiSelected)="onReaction($event)">
  </tolaria-emoji-picker-button>

  <button class="btn btn-sm btn-message-action"
    ngbTooltip="Reply to message"
    container="body"
    (click)="onReplyToMessagePress()">
    <fa-icon [fixedWidth]="true" [icon]="icon.reply"></fa-icon>
  </button>

  <button *ngIf="msg.isSender"
    class="btn btn-sm btn-message-action"
    ngbTooltip="Edit message"
    container="body"
    (click)="onEditMessagePress()">
    <fa-icon [fixedWidth]="true" [icon]="icon.edit"></fa-icon>
  </button>

  <div class="d-inline" ngbDropdown container="body">
    <button ngbDropdownToggle
      id="more-actions-menu"
      class="btn btn-sm btn-message-action no-arrow"
      ngbTooltip="More actions"
      container="body">
      <fa-icon [fixedWidth]="true" [icon]="icon.more"></fa-icon>
    </button>
    <div ngbDropdownMenu
      aria-labelledby="more-actions-menu"
      class="">
      <button ngbDropdownItem (click)="onReplyToMessagePress()">Reply to message</button>
      <div class="dropdown-divider"></div>
      <button ngbDropdownItem (click)="onCopyId()">Copy message id</button>
      <div class="dropdown-divider"></div>
      <button ngbDropdownItem (click)="onMarkAsUnread()">Mark as unread</button>
      <div class="dropdown-divider"></div>
      <button ngbDropdownItem (click)="onPinMessage()" *ngIf="!msg.isPinned">Pin message to group</button>
      <button ngbDropdownItem (click)="onUnpinMessage()" *ngIf="msg.isPinned">Un-pin message</button>
      <ng-container *ngIf="msg.isSender">
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem (click)="onEditMessagePress()">Edit message</button>
        <button ngbDropdownItem (click)="deleteMessage()" class="dropdown-error">Delete message</button>
      </ng-container>
    </div>
  </div>

</div>

<ng-template #tooltipper>
  <span class="text-secondary">Press <span class="badge text-bg-dark text-monospaced">Esc</span> to close</span>
</ng-template>

<div class="modal-wrapper" #tooltip="ngbTooltip" [ngbTooltip]="tooltipper" [autoClose]="false" container="body"
  triggers="manual" (mouseenter)="tooltip.open()">


  <div class="modal-body">

    <ng-container *ngIf="ticket$ | async as ticket">


      <div class="ticket-info-wrapper d-flex align-items-center flex-column">
        <div class="text-larger text-secondary">{{ ticket.type }} TICKET</div>
        <fa-icon [icon]="ticketIcon" [fixedWidth]="true" class="text-mega text-secondary"></fa-icon>

        <ng-container [ngSwitch]="ticket.type">

          <ng-container *ngSwitchCase="'CONVENTION'">
            <div class="text-huge">{{ ticket.salesData.price }} {{ currency }}</div>
            <div class="text-xlarge">{{ ticket.name }}</div>
            <div class="text-normal">{{ ticket.description }}</div>
            <div class="flex-grow-1 mt-3 w-100">
              <div class="text-normal text-secondary">Events covered by this ticket</div>
              <div class="btn btn-sm btn-blank" *ngFor="let eventDocId of ticket.data.eventDocIds">
                <app-event-name [eventDocId]="eventDocId"></app-event-name>
              </div>
            </div>
          </ng-container>

        </ng-container>

      </div>



      <div class="my-2 border-bottom border-secondary"></div>
      <div class="form-group mt-3">
        <label for="donationAmount">Extra - Platform Support</label>
        <input
          type="number"
          class="form-control text-larger text-center"
          id="donationAmount"
          aria-describedby="donationAmountHelp"
          [step]="1"
          [min]="0"
          [(ngModel)]="donationAmount">
        <div id="donationAmountHelp" class="text-secondary mt-1">
          If you want to contribute to the Tolaria platform, please consider adding some extra here.
          Any amount is helpful to make the plaform a better place for all us old school lovers!
        </div>
      </div>

      <ng-container *ngIf="player$ | async as player">
        <div class="my-2 border-bottom border-secondary"></div>
        <div class="d-flex flex-row align-items-center" *ngIf="!hasStripeAccount">
          <div class="text-secondary">This seems to be your first Tolaria payment session. Tolaria Payment is integrated with <a href="https://stripe.com/eu-en" target="_blank"><b>stripe</b></a> payment infrastructure. During the checkout a <b>stripe</b> customer account will be created for you.</div>
        </div>
        <div class="d-flex flex-row align-items-center" *ngIf="hasStripeAccount">
          <div class="text-secondary flex-grow-1">Your customer id:</div>
          <div class="text-secondary text-monospaced">{{ stripeAccountNumber }}</div>
        </div>
      </ng-container>

      <div class="my-2 border-bottom border-secondary"></div>
      <div class="py-2 mt-3 text-larger d-flex flex-row">
        <div class="flex-grow-1">Total:</div>
        <div class="flex-grow-0">{{ totalAmount }} {{ currency }}</div>
      </div>

      <div class="mt-3">
        <button class="btn w-100 {{ creatingCheckout ? 'btn-outlined-secondary' : 'btn-main' }}"
          (click)="proceedToCheckout()"
          [disabled]="ticket.salesData.from > now">
          <span *ngIf="!creatingCheckout; else loader">Proceed to checkout</span>
        </button>
      </div>
      <div class="btn btn-blank w-100 text-secondary" *ngIf="ticket.salesData.from > now">
        Sales start on <app-timestamp-clock [timestamp]="ticket.salesData.from" displayType="date" [seconds]="true"></app-timestamp-clock>
      </div>
      <div class="text-secondary py-2 border-top w-100">
        Seller: <app-player-display-name [playerDocId]="ticket.createdByPlayerDocId"></app-player-display-name>
      </div>

    </ng-container>

  </div>


</div>

<ng-template #loader>
  <div class="d-flex flex-row justify-content-center align-items-center">
    <app-busy-spinner></app-busy-spinner>
  </div>
</ng-template>

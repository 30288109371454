<ng-container *ngIf="ready$ | async as ready">

  <ng-template #rt let-r="result" let-t="term">
    <div class="d-flex align-items-center gap-3">
      <div class="avatar-wrapper rounded-circle overflow-hidden"
        [style.width.px]="32"
        [style.height.px]="32">
        <img [src]="r.avatar" [style.width.%]="100" />
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <ngb-highlight [result]="r.name.display" [term]="t"></ngb-highlight>
        <div class="text-secondary text-small d-flex gap-2">
          <span *ngIf="r.country">{{ r.country }}</span>
          <span *ngIf="r.country !== '' && r.region !== ''">—</span>
          <span *ngIf="r.region">{{ r.region }}</span>
        </div>
      </div>
      <div class="m-0 text-small alert alert-warning p-1 px-2" *ngIf="manualEntry && r.manualEntry">{{ manualEntryText }}</div>
    </div>
  </ng-template>

  <div class="d-flex flex-column">
    <div class="mb-2" *ngIf="showTitle">{{ title }}</div>
    <input #searchInput="ngbTypeahead" #searchElement
      type="text"
      class="form-control"
      [class]="styleClass"
      [ngClass]="{ 'border-0 rounded-0': !border, 'bg-transparent' : !background }"
      id="enrollTolariaPlayer"
      [placeholder]="placeholder"
      [(ngModel)]="searchString"
      [disabled]="!ready"
      [resultTemplate]="rt"
      [ngbTypeahead]="search"
      [inputFormatter]="formatter"
      [editable]="false"
      popupClass="player-search-popup"
      container="body"
      (focus)="focused$.next($any($event).target.value)"
	    (click)="clicked$.next($any($event).target.value)"
      (selectItem)="outputSelected($event)"
      (keydown.enter)="outputString()">
  </div>

</ng-container>

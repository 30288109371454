import { Component, Input } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IBatchConfig, IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { EventService } from 'src/app/services';

@Component({
  selector: 'app-event-to-batch-config',
  templateUrl: './event-to-batch-config.component.html',
  styleUrls: ['./event-to-batch-config.component.css']
})
export class EventToBatchConfigComponent {
  @Input() event: IEventDetails;

  faTimes = faTimes;

  batchConfig: IBatchConfig = {
    numberOfMatches: 0,
    numberOfRandomMatches: 0,
    numberOfStandingsMatches: 0,
    roundNumber: null
  };

  constructor(
    private activeModal: NgbActiveModal,
    private eventService: EventService
  ) { }

  public get disableGenerateBatchPairings() {
    if (
      this.batchConfig.numberOfRandomMatches + this.batchConfig.numberOfStandingsMatches !== this.batchConfig.numberOfMatches
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  closeModal(): void {
    this.activeModal.close();
  }

  addBatch(): void {
    this.eventService.addBatchToEvent(this.event, this.batchConfig).then(() => this.activeModal.close());
  }

}

<ng-container *ngIf="leagues$ | async as leagues">
  <div class="card-group p-1">

    <div class="league-card cursor-pointer card m-1" *ngFor="let league of leagues" [routerLink]="'/league/' + league.docId">

      <ng-template #eventListTooltip>
        <div class="d-flex flex-column justify-content-start">
          <div class="d-flex align-items-center p-2 white-space-nowrap" [innerHtml]="event.name"
            *ngFor="let event of league.events"></div>
        </div>
      </ng-template>

      <div class="card-header p-0 position-relative overflow-hidden">
        <img [src]="league.bannerUrl">
        <div class="league-title" [innerHtml]="league.name"></div>
        <div class="league-count-wrapper" [ngbTooltip]="eventListTooltip" container="body" tooltipClass="fit-content">
          <div class="league-count counter" [innerHtml]="league.eventDocIds.length"></div>
          <div class="league-count footer">events</div>
        </div>
      </div>

      <div class="card-body no-hover p-2 px-3">

        <div class="d-flex align-items-center mb-3">
          <div class="flex-grow-0 me-2">
            <fa-icon [icon]="faHatWizard" [fixedWidth]="true"></fa-icon>
          </div>
          <div class="flex-grow-1">
            <app-player-display-name [playerUid]="league.createdByUid"></app-player-display-name>
          </div>
        </div>

        <div class="d-flex align-items-center mb-2 text-secondary">
          <div class="flex-grow-1 text-start">
            <app-timestamp-clock [timestamp]="league.timestampStart" displayType="date" [seconds]="true"></app-timestamp-clock>
          </div>
          <div class="flex-grow-0 text-center">
            <fa-icon [icon]="faCalendarAlt" [fixedWidth]="true"></fa-icon>
          </div>
          <div class="flex-grow-1 text-end">
            <app-timestamp-clock [timestamp]="league.timestampEnd" displayType="date" [seconds]="true"></app-timestamp-clock>
          </div>
        </div>

      </div>
    </div>

  </div>

  <div class="btn btn-main btn-sm new-league-button" *ngIf="isOrganizer" (click)="createLeague()">Create a League</div>
</ng-container>

<ng-container *ngIf="eventDoc$ | async as event">

  <ng-container *ngIf="event.details.structure.swiss?.teams; else regularForms">

    <app-add-new-team [event]="event"></app-add-new-team>

  </ng-container>


  <ng-template #regularForms>

    <div class="attendee-form text-normal">

      <div class="text-secondary text-normal mb-2">Add Tolaria player</div>

      <div class="global-search-wrapper">

        <app-tolaria-player-search (playerDocId)="onTolariaPlayerSubmit($event)" [clearOnSelect]="true"></app-tolaria-player-search>

      </div>

      <ng-container *ngIf="!event.isOnlineTournament">
        <div class="text-secondary text-normal mt-3 mb-2 d-flex align-items-center">
          <div class="flex-grow-1">Add non-Tolaria player{{ showAddPlayerTextarea ? 's' : '' }}</div>
          <div class="wrapper d-flex align-items-center justify-content-end"
            [ngbTooltip]="event.statusCode > 0 ? 'Mass entry is not allowed after event has started' : 'Toggle mass entry on/off'"
            placement="top-left" container="body">
            <div class="flex-grow-0 text-secondary me-2">Mass entry</div>
            <div class="form-check form-switch mt-1">
              <input type="checkbox" class="form-check-input" id="multiInput" [(ngModel)]="showAddPlayerTextarea"
                [disabled]="event.statusCode > 0">
              <label class="form-check-label" for="multiInput"></label>
            </div>
          </div>
        </div>

        <div class="input-group input-group-sm">

          <div class="input-group-prepend" container="body" placement="top-left" ngbTooltip="Toggle single/multi input"
            [ngbTooltip]="event.statusCode > 0 ? 'Mass entry is not allowed after event has started' : 'Toggle mass entry on/off'"
            placement="top-left" container="body"
            (click)="event.statusCode > 0 ? null : showAddPlayerTextarea = !showAddPlayerTextarea;">
            <button class="btn btn-sm btn-outline-secondary">
              <fa-icon [fixedWidth]="true" [icon]="faUsers" *ngIf="showAddPlayerTextarea"></fa-icon>
              <fa-icon [fixedWidth]="true" [icon]="faUser" *ngIf="!showAddPlayerTextarea"></fa-icon>
            </button>
          </div>

          <input #searchInputNoTolaria required type="text" class="form-control fix-rounded-right"
            *ngIf="!showAddPlayerTextarea" placeholder="Enter the name of the player" [(ngModel)]="attendeeName"
            (keydown.enter)="addNonTolariaPlayer(event)">

          <textarea *ngIf="showAddPlayerTextarea" type="text" rows="6" required class="form-control fix-rounded-right"
            placeholder="One name per row to be able to add multiple players" [(ngModel)]="attendeeName"></textarea>


          <div class="input-group-append" (click)="addNonTolariaPlayer(event)">
            <button class="btn btn-sm btn-outline-secondary"
              [ngbTooltip]="showAddPlayerTextarea ? 'Add players' : 'Add player'" container="body">
              <fa-icon [fixedWidth]="true" [icon]="faPlus"></fa-icon>
            </button>
          </div>

        </div>

      </ng-container>

    </div>


    <!-- INVITED PLAYERS -->
    <ng-container *ngIf="inviteEnabled">

      <hr class="mt-5 mb-3">

      <div class="text-secondary text-normal mb-2 d-flex align-items-center">
        <div class="flex-grow-1">Invite player{{ showInvitePlayerTextarea ? 's' : '' }}</div>
        <div class="wrapper d-flex align-items-center justify-content-end"
          [ngbTooltip]="event.statusCode > 0 ? 'Mass entry is not allowed after event has started' : 'Toggle mass entry on/off'"
          placement="top-left" container="body">
          <div class="flex-grow-0 text-secondary me-2">Mass entry</div>
          <div class="form-check form-switch mt-1">
            <input type="checkbox" class="form-check-input" id="multiInputInvite"
              [(ngModel)]="showInvitePlayerTextarea" [disabled]="event.statusCode > 0">
            <label class="form-check-label" for="multiInputInvite"></label>
          </div>
        </div>
      </div>

      <div class="input-group input-group-sm">

        <div class="input-group-prepend" container="body" placement="top-left" ngbTooltip="Toggle single/multi input"
          [ngbTooltip]="event.statusCode > 0 ? 'Mass entry is not allowed after event has started' : 'Toggle mass entry on/off'"
          placement="top-left" container="body"
          (click)="event.statusCode > 0 ? null : showInvitePlayerTextarea = !showInvitePlayerTextarea;">
          <button class="btn btn-sm btn-outline-secondary">
            <fa-icon [fixedWidth]="true" [icon]="faUsers" *ngIf="showInvitePlayerTextarea"></fa-icon>
            <fa-icon [fixedWidth]="true" [icon]="faUser" *ngIf="!showInvitePlayerTextarea"></fa-icon>
          </button>
        </div>

        <input #searchInputNoTolaria required type="text" class="form-control fix-rounded-right"
          *ngIf="!showInvitePlayerTextarea" placeholder="Enter an email address" [(ngModel)]="inviteInput"
          (keydown.enter)="sendInvitations(event)">

        <textarea *ngIf="showInvitePlayerTextarea" type="text" rows="6" required class="form-control fix-rounded-right"
          placeholder="One name per row to be able to add multiple players" [(ngModel)]="inviteInput"></textarea>


        <div class="input-group-append" (click)="sendInvitations(event)">
          <button class="btn btn-sm btn-outline-secondary"
            [ngbTooltip]="showInvitePlayerTextarea ? 'Invite players' : 'Invite player'" container="body">
            <fa-icon [fixedWidth]="true" [icon]="faPlus"></fa-icon>
          </button>
        </div>

      </div>

      <div class="organizer-message mt-3 d-flex flex-column">
        <div class="flex-grow-0 text-secondary mb-2">Custom message</div>
        <textarea type="text" rows="6" class="form-control fix-rounded-right"
          placeholder="This text will be appended at the top of the email invitation" [(ngModel)]="inviteCustomMessage">
        </textarea>
      </div>

      <div class="text-secondary text-italic mt-2">Players will be invited by email. Registered players will also get a
        Tolaria message.</div>

    </ng-container>


    <ng-template #addPlayerAfterStart let-modal>
      <div class="modal-header d-flex align-items-center">
        <div class="text-large flex-grow-1">Add player to event</div>
        <div class="btn btn-sm btn-blank text-secondary" (click)="modal.dismiss('cancel')">
          <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
        </div>
      </div>
      <div class="modal-body">

        <ng-container [ngSwitch]="typeOfEvent$ | async">

          <div class="mb-2 text-secondary">Your event is already started.</div>
          <div class="text-secondary">How do you wish to proceed with adding the below player?</div>

          <div class="my-2 text-large text-bold" [innerText]="addToStartedEventPlayerName"></div>

          <div class="button-wrapper mt-4">

            <ng-container *ngSwitchCase="'group'">

              <div class="custom-control custom-radio">
                <input type="radio" id="customRadio1" name="customRadio" class="form-check-input"
                  [(ngModel)]="exchangePlayer" [value]="false">
                <label class="form-check-label" for="customRadio1">Add player to a group</label>
              </div>
              <div class="custom-control custom-radio">
                <input type="radio" id="customRadio2" name="customRadio" class="form-check-input"
                  [(ngModel)]="exchangePlayer" [value]="true">
                <label class="form-check-label" for="customRadio2">Exchange for another player</label>
              </div>

              <div class="action-wrapper mt-3 border-top">
                <ng-container [ngSwitch]="exchangePlayer">

                  <ng-container *ngSwitchCase="false">

                    <div class="text-secondary py-2">Select a group for the player to enter</div>

                    <div class="d-flex flex-wrap">
                      <button
                        class="btn btn-sm flex-grow-1 m-1 {{ selectedGroup === group ? 'btn-main' : 'btn-secondary' }}"
                        *ngFor="let group of groups" (click)="selectedGroup = group">{{ group }}</button>
                    </div>

                    <div class="form-group mt-3 border-top pt-3" *ngIf="selectedGroup !== null">
                      <button class="btn btn-secondary w-100 me-1" (click)="modal.dismiss('add-into-group')">Add to {{ selectedGroup }}</button>
                      <small class="form-text text-secondary">Player will be added paired against all others in the selected group.</small>
                    </div>

                  </ng-container>

                  <ng-container *ngSwitchCase="true">

                    <p class="my-3 text-secondary">To exchange a player with another, please drop the player you would like to exchange and you will get the option to make an exchange</p>

                  </ng-container>

                </ng-container>
              </div>

            </ng-container>

            <ng-container *ngSwitchCase="'swiss'">
              <div class="form-group">
                <button class="btn btn-secondary w-100" (click)="modal.dismiss('add')">Just add</button>
                <small class="form-text text-secondary">Player will be added without any matches and will be paired in the
                  next round as normal.</small>
              </div>
              <div class="form-group">
                <button class="btn btn-secondary w-100" (click)="modal.dismiss('add-unpaired')">Add as unpaired</button>
                <small class="form-text text-secondary">Player will be added unpaired and you have to manually pair the player
                  (for batch event, player will be added for each segment).</small>
              </div>
              <div class="form-group">
                <button class="btn btn-secondary w-100" (click)="modal.dismiss('add-loss')">Add with LOSS</button>
                <small class="form-text text-secondary">Player will be added with a LOSS match (for batch event, a match for
                  each segment will be added).</small>
              </div>
              <div class="form-group">
                <button class="btn btn-secondary w-100" (click)="modal.dismiss('add-bye')">Add with BYE</button>
                <small class="form-text text-secondary">Player will be added with a BYE match (for batch event, a match for
                  each segment will be added).</small>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'round-robin'">
              <div class="d-flex flex-column">

                <p>Adding a player to a Round Robin event will pair the player against all other players in the event.</p>

                <button class="btn btn-secondary w-100" (click)="modal.dismiss('add-pair-all-other')">
                  Add paired against all others
                </button>

              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'batch'">
              <div class="form-group">
                <button class="btn btn-secondary w-100" (click)="modal.dismiss('add')">Just add</button>
                <small class="form-text text-secondary">Player will be added without any matches and will be paired in the
                  next round as normal.</small>
              </div>
              <div class="form-group">
                <button class="btn btn-secondary w-100" (click)="modal.dismiss('add-unpaired')">Add as unpaired</button>
                <small class="form-text text-secondary">Player will be added unpaired and you have to manually pair the player
                  (for batch event, player will be added for each segment).</small>
              </div>
              <div class="form-group">
                <button class="btn btn-secondary w-100" (click)="modal.dismiss('add-loss')">Add with LOSS</button>
                <small class="form-text text-secondary">Player will be added with a LOSS match (for batch event, a match for
                  each segment will be added).</small>
              </div>
              <div class="form-group">
                <button class="btn btn-secondary w-100" (click)="modal.dismiss('add-bye')">Add with BYE</button>
                <small class="form-text text-secondary">Player will be added with a BYE match (for batch event, a match for
                  each segment will be added).</small>
              </div>
            </ng-container>

          </div>

        </ng-container>

      </div>


      <div class="modal-footer">
        <div type="button" class="btn btn-sm text-secondary" (click)="modal.dismiss('cancel')">Cancel</div>
      </div>
    </ng-template>

  </ng-template>


</ng-container>

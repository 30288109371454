import { Observable } from 'rxjs';
import { MatchService } from 'src/app/services/match.service';
import { Component, Input } from '@angular/core';
import { IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
@Component({
  selector: 'app-event-player-matches',
  templateUrl: './event-player-matches.component.html',
  styleUrls: ['./event-player-matches.component.css']
})
export class EventPlayerMatchesComponent {

  @Input() eventDocId: string;

  @Input()
  set playerDocId(val: string) {
    this.currentValPlayerDocId = val;
    this.getMatches();
  }

  currentValPlayerDocId: string;

  public matches$: Observable<IMatchData[]>;

  constructor(
    private matchService: MatchService
  ) {
  }

  getMatches() {
    this.matches$ = this.matchService.getPlayersMatchesInEvent(this.currentValPlayerDocId, this.eventDocId);
  }
}

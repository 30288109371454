<div class="counter-container d-flex flex-column max-height-100 overflow-hidden"
  [ngClass]="{ 'mobile' : isMobile, 'logged-in' : isLoggedIn }">


  <ng-template #nameForm let-modal>
    <div class="modal-header text-large">Player name</div>
    <div class="modal-body">
      <input type="text" class="form-control form-control-sm w-100" placeholder="Enter a player name"
        [(ngModel)]="newPlayerName" (keydown.enter)="modal.close()">
    </div>
    <div class="modal-body">
      <button class="btn btn-sm btn-blank" (click)="modal.close()">Save</button>
    </div>
  </ng-template>

  <ng-container *ngIf="viewMode; else lifeCounterMode">
    <div class="view-mode p-5">
      <ng-container *ngIf="players$ | async as players; else loader">
        <div class="counter-title text-larger">Tolaria Life Counter - View mode</div>

        <div class="counter d-flex py-5 flex-row player-{{ i % 2 === 0 ? 'even' : 'odd' }}"
          *ngFor="let player of players; let i = index">
          <div class="player-name-wrapper d-flex flex-column" [style.minWidth.vw]="50">
            <div class="player-label text-secondary text-small">Player {{ player.index }}</div>
            <div class="player-name">{{ player.name }}</div>
          </div>
          <div class="player-life flex-grow-1 text-larger">{{ player.life[0] }}</div>
          <div class="player-wins flex-grow-1 text-larger">{{ player.wins }}</div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #lifeCounterMode>

    <ng-container *ngIf="lifeCounterDoc$ | async as lifeCounter; else newCounter">

      <ng-template #shareSelector let-modal>
        <div class="modal-header text-large">Share counter</div>
        <div class="modal-body d-flex flex-column">
          <ng-container *ngIf="players$ | async as players">
            <div class="d-flex align-items-center" *ngFor="let player of players">
              <div class="flex-grow-1">
                {{ player.name }}
              </div>
              <button class="btn btn-sm btn-blank" (click)="copyShareLink(player)">
                <fa-icon [icon]="icon.copy" [fixedWidth]="true"></fa-icon>
              </button>
            </div>
          </ng-container>
        </div>
        <div class="modal-footer justify-content-center">
          <div class="btn btn-sm btn-blank" (click)="modal.close()">Close</div>
        </div>
      </ng-template>

      <ng-template #lifeHistory let-modal>
        <div class="modal-header text-large">History</div>
        <div class="modal-body d-flex flex-column">
          <ng-container *ngIf="playerHistory$ | async as life; else loader">
            <div class="d-flex align-items-center px-2 py-3" *ngFor="let points of life; let i = index">
              <div class="text-secondary text-small text-monospace" [style.minWidth.rem]="5">
                <ng-container *ngIf="i < (life.length - 1)">
                  {{ points - life[i + 1] > 0 ? '+' : '' }}{{ points === null ? 'reset' : points - life[i + 1] }}
                </ng-container>
              </div>
              <div class="flex-grow-1 text-center text-larger text-monospace">
                {{ points === null ? '' : points }}
              </div>
              <div class="text-secondary text-small text-monospace" [style.minWidth.rem]="5"></div>
            </div>
          </ng-container>
        </div>
        <div class="modal-footer justify-content-center">
          <div class="btn btn-sm btn-blank" (click)="modal.close()">Close</div>
        </div>
      </ng-template>


      <div
        class="counter-wrapper flex-grow-1 overflow-hidden overflow-y-auto d-flex {{ useTwoCols ? 'flex-row flex-wrap' : 'flex-column' }}">
        <ng-container *ngIf="players$ | async as players">
          <div class="counter position-relative d-flex flex-column flex-grow-1" *ngFor="let player of players"
            [ngClass]="{ 'two-cols': useTwoCols }">

            <div class="life-changer-wrapper d-flex">
              <div class="life-changer flex-grow-1" (click)="changeLife(player, -1)"></div>
              <div class="life-changer flex-grow-1" (click)="changeLife(player, 1)"></div>
            </div>

            <div class="counter-header d-flex align-items-center">
              <div class="btn btn-sm btn-blank">
                <fa-icon [icon]="icon.reset" [fixedWidth]="true" (click)="resetLife(player)"></fa-icon>
              </div>
              <div class="flex-grow-1 text-center">{{ player.name }}</div>
              <div class="btn btn-sm btn-blank">
                <fa-icon [icon]="icon.delete" [fixedWidth]="true" (click)="deletePlayer(player)"></fa-icon>
              </div>
            </div>

            <div class="counter-life flex-grow-1 text-larger text-center d-flex align-items-center">
              <div class="life-wrapper flex-grow-1">{{ player.life[0] }}</div>
            </div>

            <div class="counter-footer d-flex align-items-center">
              <div class="flex-grow-1 text-start">
                <div class="btn btn-sm btn-blank" *ngIf="!isLocal">
                  <fa-icon [icon]="icon.copy" [fixedWidth]="true" (click)="copyShareLink(player)"></fa-icon>
                </div>
              </div>
              <div class="text-center d-flex align-items-center">
                <div class="btn btn-sm btn-blank">
                  <fa-icon [icon]="icon.minus" [fixedWidth]="true" (click)="gameWins(player, false)"></fa-icon>
                </div>
                <div class="{{ useTwoCols ? 'mx-1' : 'mx-3' }}">
                  <span class="game-wins-label">Game Wins: </span>{{ player.wins }}
                </div>
                <div class="btn btn-sm btn-blank">
                  <fa-icon [icon]="icon.plus" [fixedWidth]="true" (click)="gameWins(player, true)"></fa-icon>
                </div>
              </div>
              <div class="flex-grow-1 text-end">
                <div class="btn btn-sm btn-blank">
                  <fa-icon [icon]="icon.history" [fixedWidth]="true" (click)="showHistory(player)"></fa-icon>
                </div>
              </div>
            </div>

          </div>
        </ng-container>
      </div>

      <div class="controller px-1 pb-1">
        <!-- <div class="d-flex flex-row align-items-center">
          <div class="m-1 flex-grow-1 btn btn-success">+5</div>
          <div class="m-1 flex-grow-1 btn btn-success">+1</div>
          <div class="m-1 flex-grow-1 btn btn-danger">-1</div>
          <div class="m-1 flex-grow-1 btn btn-danger">-5</div>
        </div> -->
        <div class="d-flex flex-row align-items-center">
          <button class="m-1 flex-grow-1 w-50 btn btn-secondary" (click)="resetAll()">Reset all</button>
          <button class="m-1 flex-grow-1 w-50 btn btn-primary" (click)="addPlayer()">Add player</button>
        </div>
        <div class="d-flex flex-row align-items-center">
          <button class="m-1 flex-grow-1 w-50 btn btn-warning" (click)="shareCounter()"
            [disabled]="lifeCounter.players.length === 1" *ngIf="!isLocal">Share</button>
          <button class="m-1 flex-grow-1 w-50 btn btn-info" (click)="createNewCounter()">New counter</button>
        </div>
      </div>


    </ng-container>
  </ng-template>

  <ng-template #newCounter>
    <div class="d-flex min-height-100 min-width-100 align-items-center justify-content-center flex-column p-4">
      <div class="btn btn-main mb-3 w-100" (click)="createNewCounterDocument()">Global Life Counter</div>
      <div class="btn btn-primary w-100" (click)="createNewCounterDocument(true)">Local Life Counter</div>
    </div>
  </ng-template>

  <ng-template #loader>
    <div class="d-flex align-items-center justify-content-center">
      <app-busy-spinner></app-busy-spinner>
    </div>
  </ng-template>


</div>

<ng-template #mainMenu let-offcanvas>
  <app-main-menu class="d-flex flex-column text-normal h-100 overflow-hidden"></app-main-menu>
</ng-template>

<div class="top-bar">

  <div class="top-bar-item d-flex align-items-center justify-content-center text-large">
    <button class="btn btn-blank"
      (click)="openMenu(mainMenu)">
      <fa-icon [icon]="icons.faBars"></fa-icon>
    </button>
  </div>

  <div class="top-bar-item title" routerLink="/dashboard" routerLinkActive="active">Tolaria</div>

  <ng-container *ngIf="globals.getRouterLink() === 'events'">
    <div class="d-none d-lg-flex top-bar-item matches-filer-bar align-items-center flex-grow-1 gap-2 px-5">
      <app-events-filter-bar></app-events-filter-bar>
    </div>
    <div class="top-bar-item d-flex d-lg-none flex-grow-1"></div>
  </ng-container>

  <ng-container *ngIf="globals.getRouterLink() === 'matches'">
    <div class="d-none d-lg-flex top-bar-item matches-filer-bar align-items-center flex-grow-1 gap-2 px-5">
      <app-matches-filter-bar></app-matches-filter-bar>
    </div>
    <div class="top-bar-item d-flex d-lg-none flex-grow-1"></div>
  </ng-container>

  <div class="top-bar-item event-name" *ngIf="globals.getRouterLink() === 'event-lobby' && !globals.isMobile">
    <fa-icon [fixedWidth]="true" [icon]="icons.faTrophy" class="me-2"></fa-icon>
    <app-event-name></app-event-name>
  </div>

  <div class="top-bar-item separator" *ngIf="globals.getRouterLink() !== 'matches' && globals.getRouterLink() !== 'events'">
  </div>

  <div class="top-bar-item separator flex-grow-1 d-block d-sm-none"></div>

  <ng-container *ngIf="parsedRoute === 'event-lobby' && !globals.isMobile">
    <app-event-round-timer class="d-none d-sm-block top-bar-item router-actions" [topBarWrapper]="true"></app-event-round-timer>
  </ng-container>

  <ng-container *ngIf="parsedRoute === 'collection'">
    <div class="top-bar-item router-actions">
      <div class="btn-action" (click)="globals.showNewEventForm = true" placement="bottom"
        ngbTooltip="Create new collection (c)">Create</div>
    </div>
  </ng-container>

  <ng-container *ngIf="parsedRoute === 'players'">

  </ng-container>

  <div class="d-none d-sm-block top-bar-item action-icons">
    <app-dark-mode-switch></app-dark-mode-switch>
  </div>

  <div *ngIf="presence$ | async as presence"
    class="d-none d-sm-block top-bar-item action-icons"
    ngbTooltip="Down to play?"
    placement="bottom"
    container="body">
    <div ngbDropdown
      triggers="maual"
      autoClose="outside"
      container="body"
      #downToPlayMenu="ngbDropdown"
      (click)="downToPlayMenu.open()">
      <div class="d-inline-block" ngbDropdownToggle id="downToPlayDropdown">
        <div class="btn-action d-flex align-items-center gap-2">
          <fa-icon [fixedWidth]="true" [icon]="icons.faHandSparkles" [ngClass]="{ 'text-body' : presence.downToPlay }"></fa-icon>
          <span *ngIf="presence.downToPlay" class="alert alert-info text-smal m-0 p-0 px-2">ACTIVE</span>
        </div>
        <div ngbDropdownMenu aria-labelledby="downToPlayDropdown" class="top-bar-dropdown">
          <app-down-to-play-settings [presence]="presence" [uid]="user.uid"></app-down-to-play-settings>
        </div>
      </div>
    </div>
  </div>


  <div class="d-none top-bar-item action-icons">
    <div class="btn-action">
      <fa-icon [fixedWidth]="true" [icon]="icons.faQuestionCircle"></fa-icon>
    </div>
  </div>


  <div class="d-none d-sm-block top-bar-item action-icons"
    ngbTooltip="Toggle fullscreen mode"
    container="body">
    <div class="btn-action" (click)="toggleFullscreen()">
      <fa-icon [fixedWidth]="true" [icon]="showFullscreen ? icons.faCompress : icons.faExpand"></fa-icon>
    </div>
  </div>


  <div #notificationButton class="d-none d-sm-block top-bar-item action-icons">
    <div class="btn-action" [ngClass]="{ 'active' : showMessageList }" (click)="showMessageList = !showMessageList">
      <fa-icon [fixedWidth]="true" [icon]="icons.faBell" [ngClass]="{ 'rotate-20deg' : unreadNotifications > 0 }">
      </fa-icon>
    </div>
    <div class="notification-badge" *ngIf="unreadNotifications > 0"></div>
    <div #notificationList class="message-list" *ngIf="showMessageList">
      <div class="message" *ngFor="let message of messages; let i = index" (click)="notificationClick(i)"
        [ngClass]="{ 'unread' : !message.hasRead}">
        <div class="icon">
          <img [src]="message?.notification.icon">
        </div>
        <div class="content">
          <div class="notifiction-title">{{ message?.notification.title }}</div>
          <div class="notifiction-body">{{ message?.notification.body }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="top-bar-item user" ngbDropdown container="body" placement="bottom-right" #downToPlayMenu="ngbDropdown">
    <div class="d-inline-block" ngbDropdownToggle id="settingsDropdown">
      <div class="user-photo cursor-pointer">
        <img [src]="user.avatar">
      </div>
      <div ngbDropdownMenu aria-labelledby="settingsDropdown" class="top-bar-dropdown">
        <button ngbDropdownItem (click)="showInfo('versionHistory')">
          <fa-icon [fixedWidth]="true" [icon]="icons.faHistory" class="me-2"></fa-icon>Version History
        </button>
        <button ngbDropdownItem (click)="showInfo('privacyPolicy')">
          <fa-icon [fixedWidth]="true" [icon]="icons.faIdBadge" class="me-2"></fa-icon>Privacy Policy
        </button>
        <button ngbDropdownItem (click)="showInfo('termsOfUse')">
          <fa-icon [fixedWidth]="true" [icon]="icons.faFileSignature" class="me-2"></fa-icon>Terms of Use
        </button>
        <button ngbDropdownItem (click)="showInfo('purchaseHistory')">
          <fa-icon [fixedWidth]="true" [icon]="icons.faReceipt" class="me-2"></fa-icon>Purchases
        </button>
        <button ngbDropdownItem (click)="openSettings()">
          <fa-icon [fixedWidth]="true" [icon]="icons.faCog" class="me-2"></fa-icon>Settings
        </button>
        <button ngbDropdownItem (click)="signOut()" class="text-red">
          <fa-icon [fixedWidth]="true" [icon]="icons.faSignOutAlt" class="me-2"></fa-icon>Sign out
        </button>
        <div class="m-1 border-top pt-2">
          <app-version-number></app-version-number>
        </div>
      </div>
    </div>
  </div>

</div>

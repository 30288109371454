import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, takeUntil } from 'rxjs';
import { PlayerDisplayNameComponent } from 'src/app/components';
import { SupportButtonComponent } from 'src/app/components/app-structure/support-button/support-button.component';
import { TimestampClockComponent } from 'src/app/components/app-structure/timestamp-clock/timestamp-clock.component';
import { PaymentService, TolariaCostDoc } from 'src/app/payment/payment.service';
import { UserService } from '../services/user.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tolaria-support',
  templateUrl: './tolaria-support.component.html',
  styleUrls: ['./tolaria-support.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    PlayerDisplayNameComponent,
    TimestampClockComponent,
    SupportButtonComponent,
    FontAwesomeModule,
    NgbModalModule,
  ]
})
export class TolariaSupportComponent implements OnInit, OnDestroy {
  @ViewChild('itemDetails', { static: true }) itemDetails: ElementRef

  private destroyed$ = new Subject<boolean>()
  private support$ = new BehaviorSubject(null)
  private mappedSupport$ = new BehaviorSubject(null)
  
  public cost$ = new BehaviorSubject<TolariaCostDoc[]>(null)
  public totals$ = new BehaviorSubject(null)
  public latestSupport$ = new BehaviorSubject(null)

  private hasInit = false

  constructor(
    private readonly paymentService: PaymentService,
    private readonly user: UserService,
    private readonly modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.paymentService.ready$.subscribe(state => {
      if (state && !this.hasInit) {
        this.hasInit = true
        this.init()
      }
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  private init() {
    const support = this.paymentService.getTolariaSupport()
    const costs = this.paymentService.getTolariaCosts()
    combineLatest([costs, support])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([costs, support]) => {
        console.log('[TolariaSupportComponent] -> cost & support emitted -> ', { costs, support })
        if (costs === null || support === null) { return }
        this.cost$.next(costs)
        this.support$.next(support)
        this.updateLatest()
        this.updateTotals()
      })
  }

  private updateTotals(): void {
    const today = new Date()
    const thisMonth = today.getMonth() + 1
    const lastMonth = today.getMonth()
    const rates = this.paymentService.exchangeRates$.getValue()
    const costs = this.cost$.getValue().map(i => {
      const date = new Date(i.timestamp * 1000)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const usdAmount = i.currency.toUpperCase() === 'USD' ? i.amount : i.amount / rates[i.currency]
      return {
        year: year,
        month: month,
        data: i,
        isLastMonth: month === lastMonth,
        amount: `${(usdAmount / 100).toFixed(2)} USD`,
        value: (usdAmount / 100),
      }
    })
    console.log('[TolariaSupportComponent] -> costs mapped -> ', costs)
    const support = this.mappedSupport$.getValue()
    const costCurrentMonth = costs.filter(i => i.month === thisMonth).map(i => i.value).reduce((a, c) => a += c, 0)
    const costLastMonth = costs.filter(i => i.isLastMonth).map(i => i.value).reduce((a, c) => a += c, 0)
    const supportCurrentMonth = support.filter(i => i.month === thisMonth).map(i => i.value).reduce((a, c) => a += c, 0)
    const supportLastMonth = support.filter(i => i.isLastMonth).map(i => i.value).reduce((a, c) => a += c, 0)

    const totals = []
    totals.push({
      text: 'Current month',
      cost: `${costCurrentMonth.toFixed(2)} USD`,
      support: `${supportCurrentMonth.toFixed(2)} USD`,
      details: costs.filter(i => i.month === thisMonth),
    })
    totals.push({
      text: 'Last month',
      cost: `${costLastMonth.toFixed(2)} USD`,
      support: `${supportLastMonth.toFixed(2)} USD`,
      details: costs.filter(i => i.isLastMonth),
    })

    this.totals$.next(totals)
  }

  private updateLatest(): void {
    const support = this.support$.getValue()
    const today = new Date()
    const lastMonth = today.getMonth()
    const mapped = support.sort((a, b) => b.date - a.date).map(i => {
      const year = i.date.getFullYear()
      const month = i.date.getMonth() + 1
      return {
        date: i.date,
        amount: `${(i.converted / 100).toFixed(2)} USD`,
        value: (i.converted / 100),
        playerDocId: i.data.playerId,
        timestamp: i.data.timestamp,
        year,
        month,
        isLastMonth: month === lastMonth,
        type: i.data.type,
        style: i.data.type === 'ONE_TIME' ? 'info' : 'success'
      }
    })
    this.mappedSupport$.next(mapped)
    // this.latestSupport$.next(mapped.splice(0, 5))
    this.latestSupport$.next(mapped)
    console.log('[TolariaSupportComponent] -> support documents sorted -> ', mapped)
  }

  public details$ = new BehaviorSubject<any>(null)
  public showDetails(item: any): void {
    this.details$.next(item)
    console.log(item)
    this.modalService.open(this.itemDetails, {
      size: 'lg'
    })
  }

  public get isHero(): boolean {
    return this.user.isHero
  }

  public icon = {
    info: faInfoCircle,
  }

}

<ng-container *ngIf="!isModal; else modalView">
  <div class="text-large mb-2">Add new team</div>
  <ng-template [ngTemplateOutlet]="teamForm"></ng-template>
  <div class="d-flex align-items-center justify-content-end py-3">
    <div class="me-3 text-warning">{{ errorText }}</div>
    <button class="btn btn-sm btn-main" [disabled]="disabled" (click)="addTeam()">Enroll team</button>
  </div>
</ng-container>

<ng-template #modalView>
  <div class="modal-header px-3 py-2 d-flex align-items-center">
    <div class="text-large">Edit team</div>
  </div>
  <div class="modal-body">
    <ng-template [ngTemplateOutlet]="teamForm"></ng-template>
  </div>
  <div class="modal-footer px-3 py-2 d-flex align-items-center justify-content-end">
    <button class="btn btn-sm btn-blank text-secondary" (click)="closeModal()">Cancel</button>
    <button class="btn btn-sm btn-blank" (click)="saveTeam()" [disabled]="disableSave">Save</button>
  </div>
</ng-template>




<ng-template #teamForm>

  <!-- <pre class="text-small text-warning">{{ team | json }}</pre> -->
  <!-- <pre class="text-small text-warning" *ngIf="playerList$ | async as list">{{ list | json }}</pre> -->

  <div class="form-group">
    <div class="input-group">
      <input type="text" placeholder="Team name" [(ngModel)]="team.name" class="form-control form-control-sm">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary btn-sm d-flex align-items-center" (click)="openPlayerSelection()"
          [disabled]="disablePlayerChange">
          {{ isModal ? 'Change players' : 'Add Tolaria players' }}
        </button>
      </div>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary btn-sm d-flex align-items-center"
          [ngClass]="{ 'text-orange' : showLocalPlayerInput }" (click)="showLocalPlayerInput = !showLocalPlayerInput"
          [disabled]="disablePlayerChange">
          {{ isModal ? 'Change players' : 'Add players' }}
        </button>
      </div>
    </div>

    <div class="input-group mt-2" *ngIf="showLocalPlayerInput">
      <input type="text"
        placeholder="Enter a name of the non-Tolaria player"
        class="form-control form-control-sm"
        [(ngModel)]="localPlayerName"
        (keydown.enter)="addLocalPlayer()">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary btn-sm d-flex align-items-center" [disabled]="disableLocalPlayerAdd" (click)="addLocalPlayer()">
          Add
        </button>
      </div>
    </div>

    <small id="teamHelp" class="form-text text-secondary" *ngIf="!isModal">
      <p>Create a new team by:</p>
      <ol>
        <li>Entering a team name</li>
        <li>Add players</li>
        <li>Drag players to seating slots</li>
      </ol>
    </small>
    <small id="teamHelp" class="form-text text-secondary" *ngIf="isModal">
      <p>You can change</p>
      <ul>
        <li>Team name</li>
        <li>Players <span class="text-warning ms-3" *ngIf="disablePlayerChange">Disabled when event has started</span>
        </li>
        <li>Change seating slots of players (drag&drop) <span class="text-warning ms-3"
            *ngIf="disablePlayerChange">Disabled when event has started</span></li>
      </ul>
    </small>
  </div>

  <ng-container *ngIf="team.playerDocIds.length > 0">

    <div class="py-3 cursor-wrapper" [style.cursor]="currentCursor">

      <ul class="list-group">

        <li class="list-group-item" *ngFor="let player of playerList$ | async">
          <div class="d-flex align-items-center player-list-item" mwlDraggable [dropData]="player.docId"
            dragActiveClass="dragging" (dragStart)="setCursor('grabbing')" (dragEnd)="setCursor('grab')">
            <span class="badge text-bg-secondary text-uppercase me-3">{{ player.seat }}</span>
            <ng-container *ngIf="player.docId.includes('temp__'); else tolariaPlayer">
              <span class="" [innerText]="getLocalPlayerName(player.docId)"></span>
            </ng-container>
            <ng-template #tolariaPlayer>
              <app-player-display-name [playerDocId]="player.docId" class="flex-grow-1"></app-player-display-name>
            </ng-template>
            <div class="btn btn-sm btn-blank text-warning" *ngIf="player.alreadyEnrolled"
              ngbTooltip="Player already enrolled in another team" container="body">
              <fa-icon [fixedWidth]="true" [icon]="warningIcon"></fa-icon>
            </div>
          </div>
        </li>

      </ul>

      <div class="row mt-3">

        <div class="col-4">
          <div class="player-bubble" mwlDroppable (drop)="onDrop($event.dropData, 'a')" dragOverClass="dropping">
            <span class="player-seat">Seat A</span>
            <ng-container *ngIf="team.player.a !== null">
              <ng-container *ngIf="!team.player.a.includes('temp__'); else localPlayerSeatA">
                <app-player-avatar [playerDocId]="team.player.a"></app-player-avatar>
              </ng-container>
              <ng-template #localPlayerSeatA>
                <span class="select-player-bubble">{{ getLocalPlayerInitials(team.player.a) }}</span>
              </ng-template>
            </ng-container>
          </div>
        </div>

        <div class="col-4">
          <div class="player-bubble" mwlDroppable (drop)="onDrop($event.dropData, 'b')" dragOverClass="dropping">
            <span class="player-seat">Seat B</span>
            <ng-container *ngIf="team.player.b !== null">
              <ng-container *ngIf="team.player.b !== null; else noPlayer">
                <ng-container *ngIf="!team.player.b.includes('temp__'); else localPlayerSeatB">
                  <app-player-avatar [playerDocId]="team.player.b"></app-player-avatar>
                </ng-container>
                <ng-template #localPlayerSeatB>
                  <span class="select-player-bubble">{{ getLocalPlayerInitials(team.player.b) }}</span>
                </ng-template>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div class="col-4">
          <div class="player-bubble" mwlDroppable (drop)="onDrop($event.dropData, 'c')" dragOverClass="dropping">
            <span class="player-seat">Seat C</span>
            <ng-container *ngIf="team.player.c !== null">
              <ng-container *ngIf="team.player.c !== null; else noPlayer">
                <ng-container *ngIf="!team.player.c.includes('temp__'); else localPlayerSeatC">
                  <app-player-avatar [playerDocId]="team.player.c"></app-player-avatar>
                </ng-container>
                <ng-template #localPlayerSeatC>
                  <span class="select-player-bubble">{{ getLocalPlayerInitials(team.player.c) }}</span>
                </ng-template>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <ng-template #noPlayer>
          <span class="select-player-bubble">?</span>
        </ng-template>


      </div>

    </div>

  </ng-container>

</ng-template>
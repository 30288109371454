<div class="main" [ngClass]="{ 'mobile' : globals.isMobile }">
  <div class="tolaria-title" *ngIf="!globals.isMobile">Tolaria</div>

  <div class="login-panel {{ showSignIn ? 'show-background' : '' }}">

    <div (click)="showSignIn = !showSignIn" class="panel-toggler {{ showSignIn ? '' : 'pulsate' }}"
      *ngIf="!globals.isMobile">{{ showSignIn ?
      'close panel' : 'Sign in' }}</div>

    <div class="tolaria-title" *ngIf="globals.isMobile">Tolaria</div>

    <ng-container *ngIf="showSignIn">
      <div>
        <!-- EMAIL REGISTRATION -->
        <ng-container *ngIf="isRegistering && !forgotPassword">
          <div class="h4 mb-3">Registration</div>
          <div class="registration">
            <!-- <div class="form-group">
              <input type="text" class="form-control input-lg" name="nickname" placeholder="Nickname" required="required" [(ngModel)]="auth.registrationData.nickname">
            </div> -->
            <!-- <div class="form-group">
              <input type="text" class="form-control input-lg" name="firstName" placeholder="First name" required="required" [(ngModel)]="auth.registrationData.firstName">
            </div> -->
            <!-- <div class="form-group">
              <input type="text" class="form-control input-lg" name="lastName" placeholder="Last name" required="required" [(ngModel)]="auth.registrationData.lastName">
            </div> -->
            <div class="form-group">
              <input type="email" class="{{ emailIsValid ? 'is-valid' : 'is-invalid' }} form-control input-lg"
                name="email" placeholder="Email address" required="required" [(ngModel)]="registrationData.email">
            </div>
            <div class="form-group">
              <input type="password"
                class="{{ registrationData.password !== '' ? 'is-valid' : 'is-invalid' }} form-control input-lg"
                name="password" placeholder="Password" required="required" [(ngModel)]="registrationData.password">
            </div>
            <div class="form-group">
              <input type="password"
                class="{{ registrationData.confirmPassword !== '' && registrationData.confirmPassword === registrationData.password ? 'is-valid' : 'is-invalid' }} form-control input-lg"
                name="confirm_password" placeholder="Confirm password" required="required"
                [(ngModel)]="registrationData.confirmPassword">
              <div class="invalid-feedback">Passwords mismatch</div>
            </div>
            <div class="form-group">
              <button type="submit"
                class="btn {{ registrationFormIsValid() ? 'btn-success' : 'btn-secondary' }} btn-lg btn-block signup-btn"
                [disabled]="!registrationFormIsValid()" (click)="emailSignup()">Sign Up</button>
            </div>
          </div>
        </ng-container>
        <!-- EMAIL LOGIN -->
        <ng-container *ngIf="!isRegistering && !forgotPassword">
          <div class="h4 mb-3">Login with email and password</div>
          <div class="login">
            <div class="form-group">
              <input type="email" class="form-control input-lg" name="email" placeholder="Email address"
                required="required" [(ngModel)]="loginData.email" (keydown.enter)="signin('email')">
            </div>
            <div class="form-group">
              <input type="password" class="form-control input-lg" name="password" placeholder="Password"
                required="required" [(ngModel)]="loginData.password" (keydown.enter)="signin('email')">
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-success btn-lg btn-block signup-btn"
                (click)="signin('email')">Login</button>
            </div>
          </div>
        </ng-container>
        <!-- RESET PASSWORD -->
        <ng-container *ngIf="forgotPassword">
          <div class="forgot-password">
            <div class="h4">Forgot your password?</div>
            <div class="mb-3">Don't worry! Just fill in your email and we'll send you a link to reset your password.
            </div>
            <div class="form-group">
              <input type="email" class="form-control input-lg" name="email" placeholder="Email address"
                required="required" [(ngModel)]="forgotPasswordEmail">
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-success btn-lg btn-block signup-btn" (click)="resetPassword()">Reset
                password</button>
            </div>
          </div>
        </ng-container>
        <!-- AUTH MESSAGE -->
        <div class="auth-error" *ngIf="authMessage.length > 0">
          {{ authMessage }}
        </div>
        <!-- SWITCHER -->
        <div class="switcher">
          <div class="switcher-text"
            (click)="forgotPassword ? isRegistering = false : isRegistering = !isRegistering; forgotPassword = false">{{
            isRegistering || forgotPassword ? 'Back to Login' : 'Need an account?' }}</div>
        </div>

        <ng-container *ngIf="!isRegistering && !forgotPassword">
          <div class="switcher">
            <div class="switcher-text" (click)="forgotPassword = !forgotPassword">Can't remember your password?</div>
          </div>
          <div class="or-seperator"><b>or</b></div>
          <div class="social-btn text-center">
            <button class="btn btn-primary btn-lg" (click)="signin('facebook')">
              <fa-icon [icon]="faFacebook"></fa-icon> Facebook
            </button>
            <button class="btn btn-info btn-lg" (click)="signin('twitter')">
              <fa-icon [icon]="faTwitter"></fa-icon> Twitter
            </button>
            <button class="btn btn-danger btn-lg" (click)="signin('google')">
              <fa-icon [icon]="faGoogle"></fa-icon> Google
            </button>
            <button class="btn btn-dark btn-lg" (click)="signin('github')">
              <fa-icon [icon]="faGithub"></fa-icon> Github
            </button>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div class="disclamer-wrapper mobile mt-4 p-2 text-secondary text-smaller" *ngIf="globals.isMobile">
      <p>Magic: The Gathering is © <a class="footer-link" href="https://company.wizards.com/">Wizards of the Coast</a>.
      </p>
      <p>This site is not affiliated nor produced nor endorsed by Wizards of the Coast. All card images, mana symbols,
        expansions and
        art related to Magic the Gathering is a property of Wizards of the Coast/Hasbro. All other content Copyright ©
        2020
        Tolaria.app</p>
    </div>


  </div>
</div>
<div class="center footer-text" *ngIf="!globals.isMobile">
  <p class="center" style="text-transform: uppercase;">
    <!-- <a class="footer-link" href="">Privacy Policy</a> | <a class="footer-link" href="">Terms & Conditions</a> | <a class="footer-link" href="">Cookies</a> -->
    <!-- <br> -->
  <p>Magic: The Gathering is © <a class="footer-link" href="https://company.wizards.com/">Wizards of the Coast</a>.</p>
  <p>This site is not affiliated nor produced nor endorsed by Wizards of the Coast. All card images, mana symbols,
    expansions and
    art related to Magic the Gathering is a property of Wizards of the Coast/Hasbro. All other content Copyright © 2020
    Tolaria.app</p>
</div>

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const tolCode: IconDefinition = {
  prefix: 'tol',
  iconName: 'code',
  icon: [
    20, // SVG view box width
    20, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M12.48 2.926a.75.75 0 0 0-1.46-.352l-3.5 14.5a.75.75 0 0 0 1.46.352zM5.763 5.204a.75.75 0 0 0-1.06.032l-4 4.25a.75.75 0 0 0 0 1.028l4 4.25a.75.75 0 0 0 1.092-1.028L2.28 10l3.516-3.736a.75.75 0 0 0-.032-1.06m8.472 0a.75.75 0 0 1 1.06.032l4 4.25a.75.75 0 0 1 0 1.028l-4 4.25a.75.75 0 0 1-1.092-1.028L17.72 10l-3.516-3.736a.75.75 0 0 1 .032-1.06'
  ],
} as any;


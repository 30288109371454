import { CardDetailsComponent } from './card-details/card-details.component';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { CardSearchService } from 'src/app/services/card-search.service';
import { Component, ComponentRef, ViewChild, ViewContainerRef, ComponentFactory, ComponentFactoryResolver } from '@angular/core';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent {
  @ViewChild('cardDetails', { read: ViewContainerRef }) cardDetails;
  private cardDetailsComponentRef: ComponentRef<CardDetailsComponent>;

  faSlidersH = faSlidersH;

  public currentPage = 1;

  constructor(
    public cardSearch: CardSearchService,
    private resolver: ComponentFactoryResolver,
  ) {
    this.cardSearch.clearAdvancedForm();
  }

  showCardDetails(event: MouseEvent, cardData: any) { // strong type
    event.stopPropagation();
    event.preventDefault();
    console.log(cardData);
    this.cardDetails.clear();
    const factory: ComponentFactory<CardDetailsComponent> = this.resolver.resolveComponentFactory(CardDetailsComponent);
    this.cardDetailsComponentRef = this.cardDetails.createComponent(factory);
    this.cardDetailsComponentRef.instance.cardData = cardData;
    this.cardDetailsComponentRef.instance.componentRef = this.cardDetailsComponentRef;
  }

}

<ng-container *ngIf="eventDoc$ | async as event">

  <div class="d-flex align-items-center mt-3 mb-2 ms-3 cursor-pointer" (click)="submenuOpen = !submenuOpen">
    <fa-icon [icon]="faChevronRight" class="text-small text-secondary me-2 transition-all-25" [ngClass]="{ 'rotate-90deg' : submenuOpen }"></fa-icon>
    <span class="text-secondary flex-grow-1">Check-In</span>
  </div>
  
  <ng-container *ngIf="submenuOpen">
    <button ngbDropdownItem (click)="startCheckIn(event.docId)" [disabled]="event.statusCode > 1">
      Start Check-in
    </button>
  
    <button ngbDropdownItem (click)="dropPlayersWitCheckInFalse(event)" [disabled]="event.statusCode > 1">
      Drop no-shows
    </button>
  </ng-container>

</ng-container>

import { Pipe, PipeTransform } from '@angular/core';
import { IBooster } from 'tolaria-cloud-functions/src/_interfaces';

@Pipe({
  name: 'playersActiveBooster'
})
export class PlayersActiveBoosterPipe implements PipeTransform {

  transform(boosters: IBooster[], options: { pack: string, playerDocId: string }): IBooster {

    console.log(options);
    console.log(boosters);

    const booster = boosters.find(b => b.activePlayerDocId === options.playerDocId && b.pack === options.pack);

    console.log(booster);

    return booster;

  }

}

import { Component, Input, OnInit } from '@angular/core';
import { faHeartBroken } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { DecksService, IDeckList } from 'src/app/services/decks.service';

@Component({
  selector: 'app-deck-photo',
  templateUrl: './deck-photo.component.html',
  styleUrls: ['./deck-photo.component.css']
})
export class DeckPhotoComponent implements OnInit {
  @Input() deckListDocId: string;
  @Input() deckListVersionDocId: string = null;
  @Input() showAsModal: boolean = false

  public deckList$: BehaviorSubject<IDeckList> = new BehaviorSubject<IDeckList>(null);

  public sadIcon = faHeartBroken;

  cardPreviewUrl = '';

  constructor(
    private decks: DecksService,
    public modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.decks.getDeckListByDocId(this.deckListDocId, this.deckListVersionDocId).then(deck => {
      this.deckList$.next(deck);
      console.log(deck);
    });
  }

  public get deckPhotoUrlAvailable(): boolean {
    const deck = this.deckList$.getValue();
    return deck.deckPhotoUrl === undefined || deck.deckPhotoUrl === null || deck.deckPhotoUrl === '' ? false : true;
  }

  public get deckName(): string {
    if (this.deckList$.getValue() === null) {
      return 'Deck name'
    }
    return this.deckList$.getValue().name
  }

}

<div class="player-list">
  <ng-container *ngFor="let player of invitedPlayers; trackBy: tracker">
    <div class="player event-player-list pt-2 pb-2 d-flex align-items-center">

      <ng-container *ngIf="player.playerUid !== ''; else email">
        <div class="flex-grow-1">
          <app-player-display-name [playerUid]="player.playerUid" position="left" [name]="player.email">
          </app-player-display-name>
        </div>
        <div class="flex-grow-0 btn btn-sm btn-blank" container="body"
          ngbTooltip="Tolaria message invitation sent">
          <fa-icon [fixedWidth]="true" [icon]="faComment"></fa-icon>
        </div>
        <div class="flex-grow-0 btn btn-sm btn-blank" container="body"
          ngbTooltip="Email invitation sent">
          <fa-icon [fixedWidth]="true" [icon]="faAt"></fa-icon>
        </div>
      </ng-container>

      <ng-template #email>
        <div class="flex-grow-1">
          <span>{{ player.email }}</span>
        </div>
        <div class="flex-grow-0 btn btn-sm btn-blank text-secondary" container="body"
          ngbTooltip="Tolaria message invitation NOT sent">
          <fa-icon [fixedWidth]="true" [icon]="faCommentSlash"></fa-icon>
        </div>
        <div class="flex-grow-0 btn btn-sm btn-blank" container="body"
          ngbTooltip="Email invitation sent">
          <fa-icon [fixedWidth]="true" [icon]="faAt"></fa-icon>
        </div>
      </ng-template>


      <div class="flex-grow-0 btn btn-sm btn-blank">

        <fa-icon [fixedWidth]="true" class="text-warning" [icon]="faQuestionCircle"
          *ngIf="!player.declined && !player.accepted" container="body" ngbTooltip="Invitation pending"></fa-icon>

        <fa-icon [fixedWidth]="true" class="text-red" [icon]="faTimesCircle" *ngIf="player.declined && !player.accepted"
          container="body" ngbTooltip="Declined the invitation"></fa-icon>

        <fa-icon [fixedWidth]="true" class="text-green" [icon]="faCheckCircle"
          *ngIf="player.accepted && !player.declined" container="body" ngbTooltip="Accepted the invitation"></fa-icon>

      </div>

      <div class="flex-grow-0 btn btn-sm btn-blank delete-button">
        <fa-icon [fixedWidth]="true" class="text-red" [icon]="faTrash" (click)="removeInvitatedPlayer(player)" ngbTooltip="Remove invitation" container="body"></fa-icon>
      </div>

    </div>



  </ng-container>
</div>

import { NgIf } from '@angular/common';
import { Component, Input, } from '@angular/core';

@Component({
  selector: 'app-match-card-placeholder',
  templateUrl: './match-card-placeholder.component.html',
  styleUrls: ['./match-card-placeholder.component.css'],
  standalone: true,
  imports: [NgIf]
})
export class MatchCardPlaceholderComponent {
  @Input() default: boolean = true
  constructor() { }
}

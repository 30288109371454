<ng-container *ngIf="eventDocument$ | async as event">

  <ng-container #desktopView *ngIf="!globals.isMobile; else mobileView">

    <section class="event-main">

      <div class="card card-xxxlarge max-height-100 min-height-100 max-width-100">

        <!-- HEADER with information -->
        <div class="card-header">
          <span *ngIf="event.details.structure.isBracket">Bracket</span>
          <span *ngIf="event.details.structure.isGroup">Group <span class="event-status text-secondary ms-2">{{
              event.statusText
              }}</span></span>

          <!-- SWISS -->
          <ng-container *ngIf="event.details.structure.isSwiss">
            <span *ngIf="event.statusCode < 5">Swiss Round: {{ event.activeRound }}</span>
            <span *ngIf="event.statusCode < 5" class="event-status ms-3 text-secondary">{{ event?.checkInHasStarted ?
              'Player Check-In' : event.statusText }}</span>
            <span *ngIf="event.statusCode >= 5" class="event-status">{{ event.statusText }}</span>
          </ng-container>

          <!-- BATCH -->
          <ng-container *ngIf="event.details.structure.isBatch">
            <span>Batch: {{ event.activeRound }}</span><span class="ms-3 text-secondary event-status">{{ event.statusText
              }}</span>
          </ng-container>

          <!-- ROUND ROBIN -->
          <ng-container *ngIf="event.details.structure.isRoundRobin">
            <span>Round Robin</span>
            <span class="ms-3 text-secondary">{{ event.statusText }}</span>
          </ng-container>

          <!-- Registration not open -->
          <ng-container *ngIf="event.statusCode === 14">
            <app-timestamp-clock [timestamp]="event.details.registrationOpensTimestamp"></app-timestamp-clock>
          </ng-container>



          <!-- BUTTON VISIBLE WITH PAIRINGS / STANDINGS -->
          <div class="btn-toggle-matches float-end"  *ngIf="!showStandings && isOrganizer || !showStandings && isAdmin"
            (click)="showPairingsView = !showPairingsView"
            placement="bottom-right" container="body" ngbTooltip="Show pairings view in fullscreen">
            <fa-icon [fixedWidth]="true" [icon]="faExpand"></fa-icon>
          </div>
          <div class="btn-toggle-matches float-end" (click)="showReportedMatches = !showReportedMatches"
            placement="bottom-right" container="body" [ngbTooltip]="showReportedMatches ? 'Hide reported matches' : 'Show reported matches'" *ngIf="!showStandings">
            <fa-icon [fixedWidth]="true" [icon]="showReportedMatches ? faEyeSlash : faEye"></fa-icon>
          </div>
          <div class="btn-toggle-matches float-end" (click)="showDroppedPlayers = !showDroppedPlayers"
            placement="bottom-right" container="body" [ngbTooltip]="showDroppedPlayers ? 'Hide dropped players' : 'Show dropped players'" *ngIf="showStandings">
            <fa-icon [fixedWidth]="true" [icon]="showDroppedPlayers ? faEyeSlash : faEye"></fa-icon>
          </div>
          <ng-container *ngIf="isSwissTeamEvent">
            <div class="btn-toggle-matches float-end" (click)="showTeamPlayerMatches = !showTeamPlayerMatches"
              placement="bottom-right" container="body" [ngbTooltip]="showTeamPlayerMatches ? 'Hide player matches' : 'Show player matches'" *ngIf="!showStandings">
              <fa-icon [fixedWidth]="true" [icon]="showTeamPlayerMatches ? faTeamsGroup : faTeamsUngroup"></fa-icon>
            </div>
          </ng-container>

          <!-- BUTTON VISIBLE WITH PAIRINGS -->
          <div class="search-bar float-end {{ extendSearchBar || pairingsFilter !== '' ? 'extended' : '' }}"
            (mouseenter)="extendSearchBar = true" (mouseleave)="extendSearchBar = false">
            <div class="search-icon">
              <fa-icon [fixedWidth]="true" [icon]="faSearch" (click)="extendSearchBar = !extendSearchBar"></fa-icon>
            </div>
            <input #searchBar type="text" class="search-field"
              (focus)="extendSearchBar = true; globals.pairingsSearchBarFocused = true;"
              (blur)="globals.pairingsSearchBarFocused = false;"
              [(ngModel)]="pairingsFilter" placeholder="Search (/)">
            <div class="clear-input-icon">
              <fa-icon [fixedWidth]="true" [icon]="faTimes" (click)="pairingsFilter = ''; searchBar.focus()"
                *ngIf="pairingsFilter !== ''" style="cursor: pointer;"></fa-icon>
            </div>
          </div>
        </div>

        <!-- SWISS PAIRINGS & STANDINGS -->
        <ng-container *ngIf="event.statusCode > 0 && event.details.structure.isSwiss">

          <div class="card-header round-selector">

            <button class="btn btn-sm btn-round-selector me-2
          {{ showStandings ? 'selected' : '' }}
          {{ event.statusCode === 5 ? 'active' : '' }}"
              *ngIf="isOrganizer || event.activeRound === event.details.structure.swiss.roundsToPlay"
              (click)="showStandings = !showStandings; showBracket = false">Standings</button>

            <button
              class="btn btn-sm btn-round-selector me-2 {{ event.activeRound === round && event.statusCode < 5 ? 'active' : '' }} {{ round === selectedRound && !showStandings && !showBracket ? 'selected' : '' }}"
              *ngFor="let round of newArray(event.details.structure.swiss.roundsToPlay)"
              [disabled]="round > event.activeRound"
              (click)="selectedRound = round; showStandings = false; showBracket = false;">Round
              {{ round }}</button>

            <button *ngIf="event.statusCode >= 7 && event.details.structure.swiss.hasBracketAfterSwiss" class="btn btn-sm btn-round-selector me-2
            {{ showBracket ? 'selected' : '' }}
            {{ event.statusCode === 7 ? 'active' : '' }}" (click)="showStandings = false; showBracket = !showBracket">
              <fa-icon [fixedWidth]="true" [icon]="faTrophy"></fa-icon> Playoff
            </button>

          </div>

          <div class="card-header standings" *ngIf="showStandings && !showBracket">

            <div class="row">

              <div class="col-1   text-no-wrap text-start">#</div>
              <div class="col-5   text-no-wrap text-start">Attendee</div>
              <div class="col-1-5 text-no-wrap text-end">Points</div>
              <div class="col-1-5 text-no-wrap text-end">OMW%</div>
              <div class="col-1-5 text-no-wrap text-end">GW%</div>
              <div class="col-1-5 text-no-wrap text-end">OGW%</div>

            </div>

          </div>

          <div class="card-header pairings" *ngIf="!showStandings && !showBracket">
            <div class="row">
              <div class="col">{{ isSwissTeamEvent ? 'Team 1' : 'Player 1' }}</div>
              <div class="col-2 text-center text-no-wrap">Table/Result</div>
              <div class="col text-end">{{ isSwissTeamEvent ? 'Team 2' : 'Player 2' }}</div>
              <div class="col flex-grow-0 text-center" [style.minWidth.rem]="5" *ngIf="isOrganizer && !isSwissTeamEvent"></div>
            </div>
          </div>

          <div class="card-body p-0 no-hover scrollable">

            <ng-container *ngIf="showStandings && !showBracket">
              <app-event-standings [eventDocId]="eventDocId"
                [eventPlayers$]="eventPlayers$" [showDroppedPlayers]="showDroppedPlayers" [isGroupEvent$]="isGroupEvent$"
                [filterValue]="pairingsFilter" [eventMatches$]="eventMatches$"></app-event-standings>
            </ng-container>

            <ng-container *ngIf="!showStandings && !showBracket">
              <app-event-pairings
                *ngIf="isOrganizer || event.statusCode !== 13"
                [eventMatches$]="eventMatches$" [eventDoc$]="eventDoc$" [isOrganizer]="isOrganizer"
                [selectedRound]="selectedRound" [filterValue]="pairingsFilter"
                [showReportedMatches]="showReportedMatches"
                [showTeamPlayerMatches]="showTeamPlayerMatches"
                [showPairingsView]="showPairingsView"
                (showMe)="closePairingsView($event)">
              </app-event-pairings>
            </ng-container>

            <ng-container *ngIf="!showStandings && showBracket">
              <app-event-swiss-playoff [eventDocId]="event.docId"
                [eventDetails]="event" [bracketMatrix]="event.details.structure.bracketMatrix"
                [isOrganizer]="isOrganizer">
              </app-event-swiss-playoff>
            </ng-container>

          </div>

        </ng-container>

        <!-- GROUP -->
        <ng-container *ngIf="event.details.structure.isGroup">

          <div class="card-header round-selector">
            <button class="btn btn-sm btn-round-selector me-2
          {{ showStandings ? 'selected' : '' }}
          {{ event.statusCode === 5 ? 'active' : '' }}"
              (click)="showStandings = !showStandings; showBracket = false">Standings</button>

            <button
              class="btn btn-sm btn-round-selector me-2 {{ group === selectedGroup && !showStandings && !showBracket ? 'selected' : '' }}"
              *ngFor="let group of newArray(event.details.structure.group.numberOfGroups)"
              (click)="selectedGroup = group; showStandings = false; showBracket = false;">
              Group {{ groupNames[group] }}</button>

            <button *ngIf="event.statusCode >= 7 && event.details.structure.group.hasBracketAfterGroupStage" class="btn btn-sm btn-round-selector me-2
            {{ showBracket ? 'selected' : '' }}
            {{ event.statusCode === 7 ? 'active' : '' }}" (click)="showStandings = false; showBracket = !showBracket">
              <fa-icon [fixedWidth]="true" [icon]="faTrophy"></fa-icon> Playoff
            </button>
          </div>

          <div class="card-header" *ngIf="showStandings && !showBracket">

            <div class="row">

              <div class="col-1   text-no-wrap text-start ">#</div>
              <div class="col-5   text-no-wrap text-start ">Attendee</div>
              <div class="col-1-5 text-no-wrap text-end">Points</div>
              <div class="col-1-5 text-no-wrap text-end">OMW%</div>
              <div class="col-1-5 text-no-wrap text-end">GW%</div>
              <div class="col-1-5 text-no-wrap text-end">OGW%</div>

            </div>

          </div>

          <div class="card-header" *ngIf="!showStandings && !showBracket">

            <div class="row">

              <div class="col">Player 1</div>
              <div class="col-2 text-center text-no-wrap">Table/Result</div>
              <div class="col text-end">Player 2</div>
              <div class="col flex-grow-0 text-center" [style.minWidth.rem]="5" *ngIf="isOrganizer"></div>

            </div>

          </div>

          <div class="card-body p-0 no-hover scrollable" [ngClass]="{ 'standings' : showStandings }">

            <app-event-standings *ngIf="showStandings && !showBracket" [showDroppedPlayers]="showDroppedPlayers"
              [eventDocId]="eventDocId" [eventPlayers$]="eventPlayers$" [isGroupEvent$]="isGroupEvent$"
              [filterValue]="pairingsFilter" [eventMatches$]="eventMatches$">
            </app-event-standings>

            <app-event-pairings *ngIf="!showStandings && !showBracket" [eventMatches$]="eventMatches$"
              [isOrganizer]="isOrganizer" [eventDoc$]="eventDoc$" [selectedRound]="selectedRound"
              [filterValue]="pairingsFilter" [showReportedMatches]="showReportedMatches"
              [selectedGroup]="selectedGroup"
              [showTeamPlayerMatches]="showTeamPlayerMatches"
              (showMe)="closePairingsView($event)">
            </app-event-pairings>

            <app-event-swiss-playoff [eventDocId]="event.docId" *ngIf="!showStandings && showBracket && event.details.structure.isGroup"
              [eventDetails]="event" [bracketMatrix]="event.details.structure.bracketMatrix"
              [isOrganizer]="isOrganizer">
            </app-event-swiss-playoff>

          </div>

        </ng-container>

        <!-- ROUND ROBIN -->
        <ng-container *ngIf="event.statusCode > 0 && event.details.structure.isRoundRobin">

          <div class="card-header round-selector">
            <button class="btn btn-sm btn-round-selector me-2 {{ showStandings ? 'selected' : '' }}"
              (click)="showStandings = true;">Standings</button>
            <button class="btn btn-sm btn-round-selector me-2 {{ !showStandings ? 'selected' : '' }}"
              (click)="showStandings = false;">Matches</button>
          </div>

          <div class="card-header" *ngIf="showStandings">

            <div class="row">

              <div class="col-1   text-no-wrap text-start">#</div>
              <div class="col-5   text-no-wrap text-start">Attendee</div>
              <div class="col-1-5 text-no-wrap text-end">Points</div>
              <div class="col-1-5 text-no-wrap text-end">OMW%</div>
              <div class="col-1-5 text-no-wrap text-end">GW%</div>
              <div class="col-1-5 text-no-wrap text-end">OGW%</div>

            </div>

          </div>

          <div class="card-header" *ngIf="!showStandings">

            <div class="row">

              <div class="col">Player 1</div>
              <div class="col-2 text-center text-no-wrap">Table/Result</div>
              <div class="col text-end">Player 2</div>
              <div class="col flex-grow-0 text-center" [style.minWidth.rem]="5" *ngIf="isOrganizer"></div>

            </div>

          </div>

          <div class="card-body p-0 no-hover scrollable">

            <app-event-standings *ngIf="showStandings && !showBracket" [showDroppedPlayers]="showDroppedPlayers"
              [eventDocId]="eventDocId" [eventPlayers$]="eventPlayers$" [isGroupEvent$]="isGroupEvent$"
              [filterValue]="pairingsFilter" [eventMatches$]="eventMatches$">
            </app-event-standings>

            <app-event-pairings *ngIf="!showStandings && !showBracket" [eventMatches$]="eventMatches$"
              [isOrganizer]="isOrganizer" [selectedRound]="selectedRound" [filterValue]="pairingsFilter"
              [showReportedMatches]="showReportedMatches" [eventDoc$]="eventDoc$"
              [showTeamPlayerMatches]="showTeamPlayerMatches"
              (showMe)="closePairingsView($event)">
            </app-event-pairings>

          </div>

        </ng-container>

        <!-- BATCH -->
        <ng-container *ngIf="event.details.structure.isBatch">

          <div class="card-header round-selector">

            <button class="btn btn-sm btn-round-selector me-2
          {{ showStandings ? 'selected' : '' }}
          {{ event.statusCode === 5 ? 'active' : '' }}"
              *ngIf="event.createdByUid === auth.user.uid || event.activeRound === event.details.structure.batch.batches.length"
              (click)="showStandings = !showStandings; showBracket = false">Standings</button>
            <button
              class="btn btn-sm btn-round-selector me-2 {{ event.activeRound === round && event.statusCode < 5 ? 'active' : '' }} {{ round === selectedRound && !showStandings && !showBracket ? 'selected' : '' }}"
              *ngFor="let round of newArray(event.details.structure.batch.batches.length)"
              [disabled]="round > event.activeRound"
              (click)="selectedRound = round; showStandings = false; showBracket = false;">Batch {{ round }}</button>

            <button *ngIf="event.statusCode >= 7 && event.details.structure.batch.hasBracketAfterBatch" class="btn btn-sm btn-round-selector me-2
            {{ showBracket ? 'selected' : '' }}
            {{ event.statusCode === 7 ? 'active' : '' }}" (click)="showStandings = false; showBracket = !showBracket">
              <fa-icon [fixedWidth]="true" [icon]="faTrophy"></fa-icon> Playoff
            </button>

          </div>

          <div class="card-header" *ngIf="showStandings && !showBracket">

            <div class="row">

              <div class="col-1   text-no-wrap text-start">#</div>
              <div class="col-5   text-no-wrap text-start">Attendee</div>
              <div class="col-1-5 text-no-wrap text-end">Points</div>
              <div class="col-1-5 text-no-wrap text-end">OMW%</div>
              <div class="col-1-5 text-no-wrap text-end">GW%</div>
              <div class="col-1-5 text-no-wrap text-end">OGW%</div>

            </div>

          </div>

          <div class="card-header" *ngIf="!showStandings && !showBracket">

            <div class="row">

              <div class="col-2 text-start text-no-wrap">Segment</div>
              <div class="col">Player 1</div>
              <div class="col-2 text-center text-no-wrap">Table/Result</div>
              <div class="col text-end">Player 2</div>
              <div class="col flex-grow-0 text-center" [style.minWidth.rem]="5" *ngIf="isOrganizer"></div>

            </div>

          </div>

          <div class="card-body p-0 no-hover scrollable">

            <app-event-standings *ngIf="showStandings && !showBracket" [showDroppedPlayers]="showDroppedPlayers"
              [eventDocId]="eventDocId" [eventPlayers$]="eventPlayers$" [isGroupEvent$]="isGroupEvent$"
              [filterValue]="pairingsFilter" [eventMatches$]="eventMatches$">
            </app-event-standings>

            <app-event-pairings *ngIf="!showStandings && !showBracket" [eventMatches$]="eventMatches$"
              [isOrganizer]="isOrganizer" [selectedRound]="selectedRound" [filterValue]="pairingsFilter"
              [showReportedMatches]="showReportedMatches" [eventDoc$]="eventDoc$"
              [showTeamPlayerMatches]="showTeamPlayerMatches"
              (showMe)="closePairingsView($event)">
            </app-event-pairings>

            <app-event-swiss-playoff [eventDocId]="event.docId" *ngIf="!showStandings && showBracket && event.details.structure.isBatch"
              [eventDetails]="event" [bracketMatrix]="event.details.structure.bracketMatrix"
              [isOrganizer]="isOrganizer">
            </app-event-swiss-playoff>

          </div>

        </ng-container>

      </div>
    </section>

  </ng-container>

  <ng-template #mobileView>

    <!-- HEADER with information -->
    <div class="card-header d-flex flex-row align-items-center">

      <div class="flex-grow-1">
        <span *ngIf="event.details.structure.isBracket">Bracket</span>
        <span *ngIf="event.details.structure.isGroup">Group</span>
        <span *ngIf="event.details.structure.isSwiss">Swiss Round: {{ event.activeRound }}</span>
        <span *ngIf="event.details.structure.isBatch">Batch: {{ event.activeRound }}</span>
        <span *ngIf="event.details.structure.isRoundRobin">Round Robin</span>
      </div>

      <!-- BUTTON VISIBLE WITH PAIRINGS -->
      <div class="search-bar float-end {{ extendSearchBar || pairingsFilter !== '' ? 'extended' : '' }}"
        (mouseenter)="extendSearchBar = true" (mouseleave)="extendSearchBar = false">
        <div class="search-icon">
          <fa-icon [fixedWidth]="true" [icon]="faSearch" (click)="extendSearchBar = !extendSearchBar"></fa-icon>
        </div>
        <input #searchBar type="text" class="search-field"
          (focus)="extendSearchBar = true; globals.pairingsSearchBarFocused = true;"
          (blur)="globals.pairingsSearchBarFocused = false;" [(ngModel)]="pairingsFilter"
          placeholder="Search (/)">
        <div class="clear-input-icon">
          <fa-icon [fixedWidth]="true" [icon]="faTimes" (click)="pairingsFilter = ''; searchBar.focus()"
            *ngIf="pairingsFilter !== ''" style="cursor: pointer;"></fa-icon>
        </div>
      </div>

      <!-- BUTTON VISIBLE WITH PAIRINGS / STANDINGS -->
      <div class="btn btn-blank flex-grow-o" (click)="showReportedMatches = !showReportedMatches"
        *ngIf="!showStandings">
        <fa-icon [fixedWidth]="true" [icon]="showReportedMatches ? faEyeSlash : faEye"></fa-icon>
      </div>
      <div class="btn btn-blank flex-grow-o" (click)="showDroppedPlayers = !showDroppedPlayers" *ngIf="showStandings">
        <fa-icon [fixedWidth]="true" [icon]="showDroppedPlayers ? faEyeSlash : faEye"></fa-icon>
      </div>

    </div>

    <!-- SWISS PAIRINGS & STANDINGS -->
    <ng-container *ngIf="event.statusCode > 0 && event.details.structure.isSwiss">

      <div class="card-header mobile round-selector">

        <button class="btn btn-sm btn-round-selector me-2
      {{ showStandings ? 'selected' : '' }}
      {{ event.statusCode === 5 ? 'active' : '' }}"
          *ngIf="isOrganizer || event.activeRound === event.details.structure.swiss.roundsToPlay"
          (click)="showStandings = !showStandings; showBracket = false">Standings</button>

        <button
          class="btn btn-sm btn-round-selector me-2 {{ event.activeRound === round && event.statusCode < 5 ? 'active' : '' }} {{ round === selectedRound && !showStandings && !showBracket ? 'selected' : '' }}"
          *ngFor="let round of newArray(event.details.structure.swiss.roundsToPlay)"
          [disabled]="round > event.activeRound"
          (click)="selectedRound = round; showStandings = false; showBracket = false;">Round
          {{ round }}</button>

        <button *ngIf="event.statusCode >= 7 && event.details.structure.swiss.hasBracketAfterSwiss" class="btn btn-sm btn-round-selector me-2
        {{ showBracket ? 'selected' : '' }}
        {{ event.statusCode === 7 ? 'active' : '' }}" (click)="showStandings = false; showBracket = !showBracket">
          <fa-icon [fixedWidth]="true" [icon]="faTrophy"></fa-icon> Playoff
        </button>

      </div>

      <div class="card-header" *ngIf="!showStandings && !showBracket">
        <div class="row">
          <div class="col flex-grow-1">Matches</div>
          <div class="col flex-grow-0">Result/Table</div>
        </div>
      </div>

      <div class="flex-grow-1 overflow-hidden overflow-y-auto p-0 no-scrollbar standings mobile">

        <ng-container *ngIf="showStandings && !showBracket">
          <app-event-standings [eventDocId]="eventDocId"
            [eventPlayers$]="eventPlayers$" [showDroppedPlayers]="showDroppedPlayers" [isGroupEvent$]="isGroupEvent$"
            [filterValue]="pairingsFilter" [eventMatches$]="eventMatches$"></app-event-standings>
        </ng-container>

        <ng-container *ngIf="!showStandings && !showBracket">
          <app-event-pairings
            *ngIf="isOrganizer || event.statusCode !== 13"
            [eventMatches$]="eventMatches$" [eventDoc$]="eventDoc$" [isOrganizer]="isOrganizer"
            [selectedRound]="selectedRound" [filterValue]="pairingsFilter"
            [showReportedMatches]="showReportedMatches"
            [showTeamPlayerMatches]="showTeamPlayerMatches"
            (showMe)="closePairingsView($event)">
          </app-event-pairings>
        </ng-container>

        <ng-container *ngIf="!showStandings && showBracket">
          <app-event-swiss-playoff [eventDocId]="event.docId"
            [eventDetails]="event" [bracketMatrix]="event.details.structure.bracketMatrix"
            [isOrganizer]="isOrganizer">
          </app-event-swiss-playoff>
        </ng-container>

      </div>

    </ng-container>

    <!-- GROUP -->
    <ng-container *ngIf="event.details.structure.isGroup">

      <div class="card-header mobile round-selector">
        <button class="btn btn-sm btn-round-selector me-2
      {{ showStandings ? 'selected' : '' }}
      {{ event.statusCode === 5 ? 'active' : '' }}"
          (click)="showStandings = !showStandings; showBracket = false">Standings</button>

        <button
          class="btn btn-sm btn-round-selector me-2 {{ group === selectedGroup && !showStandings && !showBracket ? 'selected' : '' }}"
          *ngFor="let group of newArray(event.details.structure.group.numberOfGroups)"
          (click)="selectedGroup = group; showStandings = false; showBracket = false;">
          Group {{ groupNames[group] }}</button>

        <button *ngIf="event.statusCode >= 7 && event.details.structure.group.hasBracketAfterGroupStage" class="btn btn-sm btn-round-selector me-2
        {{ showBracket ? 'selected' : '' }}
        {{ event.statusCode === 7 ? 'active' : '' }}" (click)="showStandings = false; showBracket = !showBracket">
          <fa-icon [fixedWidth]="true" [icon]="faTrophy"></fa-icon> Playoff
        </button>
      </div>

      <div class="card-header" *ngIf="!showStandings && !showBracket">
        <div class="row">
          <div class="col flex-grow-1">Matches</div>
          <div class="col flex-grow-0">Result</div>
        </div>
      </div>

      <div class="flex-grow-1 overflow-hidden overflow-y-auto p-0 no-scrollbar standings mobile">

        <app-event-standings *ngIf="showStandings && !showBracket" [eventDocId]="eventDocId"
          [eventPlayers$]="eventPlayers$" [showDroppedPlayers]="showDroppedPlayers" [isGroupEvent$]="isGroupEvent$"
          [filterValue]="pairingsFilter" [eventMatches$]="eventMatches$">
        </app-event-standings>

        <app-event-pairings *ngIf="!showStandings && !showBracket" [eventMatches$]="eventMatches$"
          [isOrganizer]="isOrganizer" [eventDoc$]="eventDoc$" [selectedRound]="selectedRound"
          [filterValue]="pairingsFilter" [showReportedMatches]="showReportedMatches" [selectedGroup]="selectedGroup"
          [showTeamPlayerMatches]="showTeamPlayerMatches"
          (showMe)="closePairingsView($event)">
        </app-event-pairings>

        <app-event-swiss-playoff [eventDocId]="event.docId" *ngIf="!showStandings && showBracket && event.details.structure.isGroup"
          [eventDetails]="event" [bracketMatrix]="event.details.structure.bracketMatrix" [isOrganizer]="isOrganizer">
        </app-event-swiss-playoff>

      </div>

    </ng-container>

    <!-- ROUND ROBIN -->
    <ng-container *ngIf="event.statusCode > 0 && event.details.structure.isRoundRobin">

      <div class="card-header round-selector">
        <button class="btn btn-sm btn-round-selector me-2 {{ showStandings ? 'selected' : '' }}"
          (click)="showStandings = true;">Standings</button>
        <button class="btn btn-sm btn-round-selector me-2 {{ !showStandings ? 'selected' : '' }}"
          (click)="showStandings = false;">Matches</button>
      </div>

      <div class="card-header" *ngIf="!showStandings && !showBracket">
        <div class="row">
          <div class="col flex-grow-1">Matches</div>
          <div class="col flex-grow-0">Result</div>
        </div>
      </div>

      <div class="flex-grow-1 overflow-hidden overflow-y-auto p-0 no-scrollbar standings mobile">

        <app-event-standings *ngIf="showStandings && !showBracket" [showDroppedPlayers]="showDroppedPlayers"
          [eventDocId]="eventDocId" [eventPlayers$]="eventPlayers$" [isGroupEvent$]="isGroupEvent$"
          [filterValue]="pairingsFilter" [eventMatches$]="eventMatches$">
        </app-event-standings>

        <app-event-pairings *ngIf="!showStandings && !showBracket" [eventMatches$]="eventMatches$"
          [isOrganizer]="isOrganizer" [selectedRound]="selectedRound" [filterValue]="pairingsFilter"
          [showReportedMatches]="showReportedMatches" [eventDoc$]="eventDoc$"
          [showTeamPlayerMatches]="showTeamPlayerMatches"
          (showMe)="closePairingsView($event)">
        </app-event-pairings>

      </div>

    </ng-container>

    <!-- BATCH -->
    <ng-container *ngIf="event.details.structure.isBatch">

      <div class="card-header round-selector">

        <button class="btn btn-sm btn-round-selector me-2
      {{ showStandings ? 'selected' : '' }}
      {{ event.statusCode === 5 ? 'active' : '' }}"
          *ngIf="event.createdByUid === auth.user.uid || event.activeRound === event.details.structure.batch.batches.length"
          (click)="showStandings = !showStandings; showBracket = false">Standings</button>
        <button
          class="btn btn-sm btn-round-selector me-2 {{ event.activeRound === round && event.statusCode < 5 ? 'active' : '' }} {{ round === selectedRound && !showStandings && !showBracket ? 'selected' : '' }}"
          *ngFor="let round of newArray(event.details.structure.batch.batches.length)"
          [disabled]="round > event.activeRound"
          (click)="selectedRound = round; showStandings = false; showBracket = false;">Batch {{ round }}</button>

        <button *ngIf="event.statusCode >= 7 && event.details.structure.batch.hasBracketAfterBatch" class="btn btn-sm btn-round-selector me-2
        {{ showBracket ? 'selected' : '' }}
        {{ event.statusCode === 7 ? 'active' : '' }}" (click)="showStandings = false; showBracket = !showBracket">
          <fa-icon [fixedWidth]="true" [icon]="faTrophy"></fa-icon> Playoff
        </button>

      </div>

      <div class="card-header" *ngIf="!showStandings && !showBracket">
        <div class="row">
          <div class="col flex-grow-1">Matches</div>
          <div class="col flex-grow-0">Result</div>
        </div>
      </div>

      <div class="flex-grow-1 overflow-hidden overflow-y-auto p-0 no-scrollbar standings mobile">

        <app-event-standings *ngIf="showStandings && !showBracket" [showDroppedPlayers]="showDroppedPlayers"
          [eventDocId]="eventDocId" [eventPlayers$]="eventPlayers$" [isGroupEvent$]="isGroupEvent$"
          [filterValue]="pairingsFilter" [eventMatches$]="eventMatches$">
        </app-event-standings>

        <app-event-pairings *ngIf="!showStandings && !showBracket" [eventMatches$]="eventMatches$"
          [isOrganizer]="isOrganizer" [selectedRound]="selectedRound" [filterValue]="pairingsFilter"
          [showReportedMatches]="showReportedMatches" [eventDoc$]="eventDoc$"
          [showTeamPlayerMatches]="showTeamPlayerMatches"
          (showMe)="closePairingsView($event)">
        </app-event-pairings>

        <app-event-swiss-playoff [eventDocId]="event.docId" *ngIf="!showStandings && showBracket && event.details.structure.isBatch"
          [eventDetails]="event" [bracketMatrix]="event.details.structure.bracketMatrix" [isOrganizer]="isOrganizer">
        </app-event-swiss-playoff>

      </div>

    </ng-container>

  </ng-template>

</ng-container>

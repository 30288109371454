import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Subject, Subscription, throttleTime } from 'rxjs';
@Directive({
  selector: '[appPreventDoubleClick]'
})
export class PreventDoubleClickDirective implements OnInit, OnDestroy {
  @Input() delay = 500
  @Output() throttledClick = new EventEmitter()

  private clicks = new Subject()
  private subscription: Subscription

  constructor() { }

  emitThrottledClick(e: any) {
    this.throttledClick.emit(e)
  }
  
  ngOnInit() {
    this.subscription = this.clicks.pipe(throttleTime(this.delay)).subscribe(e => this.emitThrottledClick(e))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  @HostListener('click', ['$event'])
  clickEvent(event: MouseEvent) {
    console.log('[appPreventDoubleClick] --> single click fired')
    event.preventDefault()
    event.stopPropagation()
    this.clicks.next(event)
  }

  @HostListener('dblclick', ['$event'])
  dblClickEvent(event: MouseEvent) {
    console.log('[appPreventDoubleClick] --> double click fired')
    event.preventDefault()
    event.stopImmediatePropagation()
  }
}

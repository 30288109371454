import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deviceType'
})
export class DeviceTypePipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(items: Array<any>, kind: string): any[] {
    const res: Array<any> = [];
    if (items) {
      items.forEach(device => {
        if (device.kind === kind) {
            res.push(device);
        }
      });
    }
    return res;
  }

}

import * as firestore from 'firebase/firestore'
import { IEventPlayerDetails, MatchAssignement, MatchType, IMatchData, IMatchPlayer } from 'tolaria-cloud-functions/src/_interfaces'
import { v4 as uuidv4 } from 'uuid'
import { IEventTeamMeta } from '../../tournament-data-helper.service'

export class EventMatch {

  document: IMatchData = {
    docId: null,
    createdByUid: null,
    eventDocId: null,
    feedsMatchDocId: null,
    feedsMatchPlayer: null,
    winnerGoToMatchDoc: null,
    winnerGoToPlayerSlot: null,
    loserGoToMatchDoc: null,
    loserGoToPlayerSlot: null,
    roundNumber: null, // USED AS BATCH NUMBER WHEN BATCH MATCH
    tableNumber: null,
    groupName: null,
    segmentNumber: null,
    segmentType: null,
    isReported: false,
    isByeMatch: false,
    isLossMatch: false,
    isDraw: false,
    isType: null,
    player1: null,
    player2: null,
    playerDocIds: [],
    playerFilterValue: '',
    timestampCreated: null,
    timestampReported: null,
    deleted: false,
    reportSlipOpenedBy: null,
  }

  constructor(
    eventDocId: string,
    createdByUid: string,
    roundNumber: number,
    segmentNumber: number,
    tableNumber: number,
    type: MatchType,
    playerOne: IEventPlayerDetails | IEventTeamMeta,
    playerTwo: IEventPlayerDetails | IEventTeamMeta,
    assignment: MatchAssignement,
    documentId?: string,
  ) {

    this.document.docId = documentId === undefined ? uuidv4() : documentId
    this.document.createdByUid = createdByUid
    this.document.eventDocId = eventDocId
    this.document.roundNumber = roundNumber
    this.document.segmentNumber = segmentNumber
    this.document.tableNumber = tableNumber
    this.document.isType = type

    switch (type) {

      case 'swiss-team':
        // locally store the players as teams data
        const t1 = playerOne as IEventTeamMeta
        const t2 = playerTwo as IEventTeamMeta

        // create player one or an empty match if not set
        this.document.player1 = (t1 === null || t1 === undefined) ? CreateEmptyMatchPlayer(segmentNumber) : CreateMatchPlayer({ name: t1.name, pid: t1.id, uid: t1.id, segment: segmentNumber, matchPoints: t1.matchPoints, rank: t1.rank, seed: t1.seed })

        // handle the assignment for player two
        switch (assignment) {

          case 'Bye':
            this.document.player2 = CreateMatchPlayer({ name: 'BYE', pid: '*** BYE ***', uid: '*** BYE ***', segment: segmentNumber })
            this.document.isReported = true
            this.document.player1.isWinner = true
            this.document.player1.wins = 2
            this.document.player2.losses = 2
            this.document.player2.isWinner = false
            this.document.isByeMatch = true

            break

          case 'Loss':
            this.document.player2 = CreateMatchPlayer({ name: 'LOSS', pid: '*** LOSS ***', uid: '*** LOSS ***', segment: segmentNumber })
            this.document.isReported = true
            this.document.player1.losses = 2
            this.document.player1.isWinner = false
            this.document.player2.wins = 2
            this.document.player2.isWinner = true
            this.document.isLossMatch = true

            break

          default:
            this.document.player2 = (playerTwo === null || playerTwo === undefined)
              ? CreateEmptyMatchPlayer(segmentNumber)
              : CreateMatchPlayer({ name: t2.name, pid: t2.id, uid: t2.id, segment: segmentNumber, matchPoints: t2.matchPoints, rank: t2.rank, seed: t1.seed })

            break

        }

        this.document.teamIds = [this.document.player1.playerDocId, this.document.player2.playerDocId]
        this.document.player1.teamId = this.document.player1.playerDocId
        this.document.player2.teamId = this.document.player2.playerDocId

        break

      default:
        let p1 = playerOne as IEventPlayerDetails
        let p2 = playerTwo as IEventPlayerDetails

        this.document.player1 = (p1 === null || p1 === undefined)
          ? (p1 === null || p1 === undefined) && type === 'bracket' || (p1 === null || p1 === undefined) && type === 'bracket-team'
            ? CreateEmptyMatchPlayer(segmentNumber)
            : null
          : CreateMatchPlayer({ name: p1.name, pid: p1.playerDocId, uid: p1.playerUid, segment: segmentNumber, matchPoints: p1.matchPoints, rank: p1.rank, seed: p1.seed })

        switch (assignment) {

          case 'Double Bye':
            this.document.player1 = CreateMatchPlayer({ name: 'BYE', pid: '*** BYE ***', uid: '*** BYE ***', segment: segmentNumber })
            this.document.player2 = CreateMatchPlayer({ name: 'BYE', pid: '*** BYE ***', uid: '*** BYE ***', segment: segmentNumber })
            this.document.player1.isWinner = true
            this.document.isReported = true
            this.document.isByeMatch = true

            break

          case 'Bye':
            this.document.player2 = CreateMatchPlayer({ name: 'BYE', pid: '*** BYE ***', uid: '*** BYE ***', segment: segmentNumber })
            this.document.isReported = true
            this.document.player1.isWinner = true
            this.document.player1.wins = 2
            this.document.player2.losses = 2
            this.document.player2.isWinner = false
            this.document.isByeMatch = true

            break

          case 'Loss':
            this.document.player2 = CreateMatchPlayer({ name: 'LOSS', pid: '*** LOSS ***', uid: '*** LOSS ***', segment: segmentNumber })
            this.document.isReported = true
            this.document.player1.losses = 2
            this.document.player1.isWinner = false
            this.document.player2.wins = 2
            this.document.player2.isWinner = true
            this.document.isLossMatch = true

            break

          default:
            this.document.player2 = (playerTwo === null || playerTwo === undefined)
              ? (playerTwo === null || playerTwo === undefined) && type === 'bracket' || (playerTwo === null || playerTwo === undefined) && type === 'bracket-team'
                ? CreateEmptyMatchPlayer(segmentNumber)
                : null
              : CreateMatchPlayer({ name: p2.name, pid: p2.playerDocId, uid: p2.playerUid, segment: segmentNumber, matchPoints: p2.matchPoints, rank: p2.rank, seed: p2.seed })
            break
        }


        break

    }


    if (this.document.player1 !== null && this.document.player2 !== null) {
      this.document.playerDocIds = [this.document.player1.playerDocId, this.document.player2.playerDocId]
      this.document.playerFilterValue = segmentNumber !== null
        ? `${this.document.player1.displayName.toLowerCase()} ${this.document.player2.displayName.toLowerCase()} table:${this.document.tableNumber} s${segmentNumber}`
        : `${this.document.player1.displayName.toLowerCase()} ${this.document.player2.displayName.toLowerCase()} table:${this.document.tableNumber}`
    }


    if (playerOne === null && this.document.player1 === null) {
      this.document.player1 = CreateEmptyMatchPlayer()
    }
    if (playerTwo === null && this.document.player2 === null) {
      this.document.player2 = CreateEmptyMatchPlayer()
    }

    this.document.timestampCreated = firestore.Timestamp.now().seconds

  }


}

export function CreateMatchPlayer(config: {
  name: string,
  uid: string,
  pid: string,
  segment?: number
  matchPoints?: number
  rank?: number
  seed?: number
}): IMatchPlayer {
  return {
    playerDocId: config.pid,
    playerUid: config.uid,
    displayName: config.name,
    isWinner: false,
    wins: 0,
    draws: 0,
    losses: 0,
    drop: false,
    matchPoints: config.matchPoints ? config.matchPoints : 0,
    rank: config.rank ? config.rank : 0,
    seed: config.seed ? config.seed : 0,
    lifePoints: [20],
    segmentNumber: config.segment ? config.segment : null
  }
}
export function CreateEmptyMatchPlayer(segment: number = null): IMatchPlayer {
  return CreateMatchPlayer({ name: '', uid: '', pid: '', segment: segment })
}

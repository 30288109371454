<div class="input-group mb-3 d-flex align-items-center">
  
  <button class="btn btn-sm btn-outline-secondary" (click)="toggleInput()">
    <fa-icon [fixedWidth]="true" [icon]="facTolaria"></fa-icon>
  </button>

  <ng-container *ngIf="playerNames$ | async as players; else loading">

    <ng-template #rt let-r="result" let-t="term">
      <div class="d-flex align-items-center">
        <div class="avatar-wrapper rounded-circle overflow-hidden me-3"
          [style.width.px]="32"
          [style.height.px]="32">
          <img [src]="r.avatar" [style.width.%]="100" />
        </div>
        <div class="d-flex flex-column flex-grow-1">
          <ngb-highlight [result]="r.name.display" [term]="t"></ngb-highlight>
          <div class="text-secondary text-small">
            {{ r.country }} - {{ r.region }}
          </div>
        </div>
      </div>
    </ng-template>

    <input type="text"
      class="form-control form-control-sm"
      id="tolariaPlayer"
      placeholder="Start typing to search for players"
      [disabled]="!playerSearchReady"
      [(ngModel)]="tolariaPlayer"
      [resultTemplate]="rt"
      [ngbTypeahead]="search"
      [inputFormatter]="formatter"
      [editable]="false"
      (keydown.enter)="selectPlayer()">

  </ng-container>

  <ng-template #loading>
    <div class="position-relative flex-grow-1">
      <input class="form-control form-control-sm">
      <div class="position-absolute d-flex align-items-center">
        <div class="position-absolute top-50 start-0 translate-middle-y ms-2 text-secondary" role="status">
        </div>
        <span class="text-secondary">Loading Tolaria player search...</span>
      </div>
    </div>
  </ng-template>

</div>
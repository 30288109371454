  <div class="d-flex flex-column p-3">

    <div class="text-larger mb-3">Email Notifications</div>

    <div class="d-flex align-items-center ps-2 py-2" *ngFor="let setting of emailNotificationSettings; trackBy: tracker">

      <span class="flex-grow-1" [innerHtml]="setting.text"></span>

      <div class="form-check form-switch d-flex justify-content-end">
        <input type="checkbox" class="form-check-input" [id]="setting.property" [(ngModel)]="setting.value" (change)="updateProperty(setting)">
        <label class="form-check-label" [for]="setting.property"></label>
      </div>

    </div>

  </div>

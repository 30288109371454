<ng-container *ngIf="!isMobile; else mobileView">

  <ng-template #eventCardTooltip>
    <div class="info-wrapper" (click)="$event.stopPropagation(); $event.preventDefault();">
      <div class="p-3 card-body no-hover" [routerLink]="event.isInvited ? ['/event-lobby', event.docId, event.name] : null">
        <div class="h4 mb-3" [innerText]="event.name"></div>
        <app-event-info [eventDocId]="event.docId"></app-event-info>
      </div>
      <div class="card-footer">
        <!-- Attend public event -->
        <ng-container *ngIf="!event.swissTeams; else swissTeamsInfo">

          <ng-container *ngIf="event.isPublic && event.statusCode === 0">
            <button class="btn btn-sm btn-secondary" *ngIf="!event.isAttending && !event.playerCapReached"
              (click)="attendEvent()">
              Attend
            </button>
            <!-- Unattend public event if attending -->
            <button class="btn btn-sm btn-danger" *ngIf="event.isAttending"
              (click)="unattendEvent()">
              Unattend
            </button>
            <!-- Not attending and therefore it must be a player cap -->
            <button class="btn btn-sm btn-read-more" *ngIf="event.playerCapReached && !event.isAttending">
              Player cap is reached.
            </button>
          </ng-container>

          <!-- Attend private event if invited -->
          <ng-container *ngIf="!event.isPublic && event.statusCode === 0">
            <button class="btn btn-sm btn-secondary" *ngIf="event.isInvited && !event.isAttending"
              (click)="attendEvent()">
              Attend
            </button>
            <!-- Unattend private event if invited and attending -->
            <button class="btn btn-sm btn-danger" *ngIf="event.isAttending"
              (click)="unattendEvent()">
              Unattend
            </button>
            <button class="btn btn-sm btn-read-more" *ngIf="!event.isInvited && !event.isAttending">
              Sorry, it seems you are not invited.
            </button>
          </ng-container>

          <!-- WHEN EVENT IS STARTED -->
          <button disabled="true" class="btn btn-sm btn-blank text-secondary" *ngIf="event.statusCode > 0">
            <span [innerHtml]="event.statusText"></span>
            <app-timestamp-clock [timestamp]="event.registrationOpensTimestamp" [seconds]="false"
              *ngIf="event.statusCode === 14"></app-timestamp-clock>
          </button>

        </ng-container>

        <ng-template #swissTeamsInfo>

          <button disabled="true" class="btn btn-sm btn-blank text-secondary" *ngIf="event.statusCode > 0">
            <span [innerHtml]="event.statusText"></span>
            <app-timestamp-clock [timestamp]="event.registrationOpensTimestamp" [seconds]="false"
              *ngIf="event.statusCode === 14"></app-timestamp-clock>
          </button>

          <div  class="btn btn-sm btn-blank text-secondary" *ngIf="event.statusCode === 0">
            This is a team event, you are not able to attend this event yourself. Please contact the organizer to attend.
            <p class="mt-2 text-italic">When contacting the organizer, please include the name of your team and the three players attending as well as the A/B/C seat each player should be assigned to.</p>
          </div>

        </ng-template>

      </div>
    </div>
  </ng-template>

  <div class="card event-card" [ngClass]="{ 'mobile' : isMobile }"
    *ngIf="isAdmin || event.isOrganizing || event.isPubliclyVisible || event.isInvited || event.isAttending"
    [style.opacity]="isAdmin && !event.isOrganizing && !event.isPublic && !event.isPubliclyVisible ? 0.35 : 1"
    [ngbTooltip]="eventCardTooltip" container="body" placement="right-top left-top right-bottom left-bottom"
    tooltipClass="event-info-tooltip" (mouseenter)="resizeTooltip($event)">

    <div class="card-header overflow-hidden m-0 p-0" [id]="event.statusCode"
      [routerLink]="isAdmin || event.isOrganizing || event.isInvited || event.isAttending ? ['/event-lobby', event.docId, event.name] : null"
      style="cursor: pointer;">
      <img [src]="bannerUrl">
      <div class="date-wrapper">
        <div class="date text-bold" [innerText]="event.datestampFrom | date: 'dd'"></div>
        <div class="month text-small" [innerText]="event.datestampFrom | date: 'MMM'"></div>
      </div>
      <h5 class="event-title">
        {{ event.name }}
      </h5>
      <div class="event-public-icon">
        <fa-icon [fixedWidth]="true" [icon]="icons.faCalendarPlus" *ngIf="event.isMultiDay" class="me-2"
          placement="bottom-right" tooltipClass="tooltip-large"
          ngbTooltip="This is a multi-day event that will run over a period of time.">
        </fa-icon>
        <fa-icon [fixedWidth]="true" [icon]="icons.faVideo" *ngIf="event.isOnline" class="me-2"
          placement="bottom-right" tooltipClass="tooltip-large"
          ngbTooltip="This is a online event which is played out here at Tolaria with webcam">
        </fa-icon>
        <fa-icon [fixedWidth]="true" [icon]="icons.faLock" *ngIf="!event.isPublic" placement="bottom-right"
          tooltipClass="tooltip-large" ngbTooltip="This is a private event, you will need an invitation to attend">
        </fa-icon>
        <fa-icon [fixedWidth]="true" [icon]="icons.faUnlock" *ngIf="event.isPublic" placement="bottom-right"
          tooltipClass="tooltip-large" ngbTooltip="This is a public event">
        </fa-icon>
      </div>

      <div class="attend-button p-3" *ngIf="!event.isAttending">

        <!-- Attend public event -->
        <ng-container *ngIf="event.isPublic && event.statusCode === 0 && !event.swissTeams">
          <button class="btn btn-main w-100" *ngIf="!event.isAttending && !event.playerCapReached"
            (click)="$event.stopPropagation(); $event.preventDefault(); attendEvent()">
            Attend Event
          </button>
        </ng-container>

        <!-- Attend private event if invited -->
        <ng-container *ngIf="!event.isPublic && event.statusCode === 0 && !event.swissTeams">
          <button class="btn btn-main w-100" *ngIf="event.isInvited && !event.isAttending"
            (click)="$event.stopPropagation(); $event.preventDefault(); attendEvent()">
            Attend Event
          </button>
        </ng-container>

      </div>

    </div>



  </div>
</ng-container>

<ng-template #mobileView>

  <div class="card mb-1" [ngClass]="{ 'mobile' : isMobile }" *ngIf="isAdmin || event.isAttending || event.isInvited || event.isPubliclyVisible"
    [style.opacity]="isAdmin && !event.isOrganizing && !event.isPublic && !event.isPubliclyVisible ? 0.35 : 1">

    <div class="card-header m-0 p-0"
      [routerLink]="isAdmin || event.isOrganizing || event.isInvited || event.isAttending ? ['/event-lobby', event.docId, event.name] : null">

      <img [src]="bannerUrl">

      <div class="date-wrapper">
        <div class="date text-bold" [innerText]="event.datestampFrom | date: 'dd'"></div>
        <div class="month text-small" [innerText]="event.datestampFrom | date: 'MMM'"></div>
      </div>

      <h5 class="event-title" [innerHtml]="event.name"></h5>

      <div class="event-public-icon">
        <fa-icon [fixedWidth]="true" [icon]="icons.faCalendarPlus" *ngIf="event.isMultiDay" class="me-2"></fa-icon>
        <fa-icon [fixedWidth]="true" [icon]="icons.faVideo" *ngIf="event.isOnline" class="me-2"></fa-icon>
        <fa-icon [fixedWidth]="true" [icon]="icons.faLock" *ngIf="!event.isPublic"></fa-icon>
        <fa-icon [fixedWidth]="true" [icon]="icons.faUnlock" *ngIf="event.isPublic"></fa-icon>
      </div>

    </div>

  </div>

</ng-template>



<ng-template let-modal #confirmUnattend>
  <div class="modal-body p-3 text-warning">
    Are you sure you want to <b>unattend</b> the event?
  </div>
  <div class="modal-footer p-2">
    <button class="btn btn-sm btn-blank text-secondary" (click)="modal.close()">Cancel</button>
    <button class="btn btn-sm btn-blank text-warning" (click)="modal.dismiss(true)">Unattend</button>
  </div>
</ng-template>


import { takeUntil } from 'rxjs/operators'
import { faDoorOpen, faUserLock, faUsers } from '@fortawesome/free-solid-svg-icons'
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs'
import { Component, OnInit, OnDestroy } from '@angular/core'
import { AuthService } from '../services'
import { IPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces'
import { IPub, PubsService } from './pubs.service'
import { AngularFirestore } from '@angular/fire/compat/firestore'

@Component({
  selector: 'app-pubs',
  templateUrl: './pubs.component.html',
  styleUrls: ['./pubs.component.css']
})
export class PubsComponent implements OnInit, OnDestroy {

  private playerDoc$: BehaviorSubject<IPlayerDetails> = new BehaviorSubject<IPlayerDetails>(null)
  public pubs$: BehaviorSubject<IPub[]> = new BehaviorSubject<IPub[]>(null)

  public icon = {
    users: faUsers,
    enter: faDoorOpen,
    private: faUserLock,
  }

  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private auth: AuthService,
    private pubService: PubsService,
    private readonly firestore: AngularFirestore,
  ) {
    this.init()
  }

  ngOnInit(): void {
    console.log('pubs initialized')
    this.pubService.getPubs().pipe(takeUntil(this.componentDestroyed$)).subscribe(p => {
      this.pubs$.next(p)
    })
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  private async init() {
    let playerSnap = await firstValueFrom(this.firestore.collection('players').doc<IPlayerDetails>(this.auth.user.playerId).get())
    if (playerSnap.exists) {
      this.playerDoc$.next(playerSnap.data())
    }
  }

  public createPub(): void {
    this.pubService.createPub()
  }

  public deletePub(pubDocId: string): void {
    this.pubService.deletePub(pubDocId)
  }

  public get maxPubsReached(): boolean {
    if (this.canCreate && this.auth.user.role !== 'admin') {
      return this.pubs$.getValue().filter(i => i.createdByUid === this.auth.user.uid).length >= 1
    }
    return false
  }

  public get canCreate(): boolean {

    if (this.playerDoc$.getValue() === null) {
      return false
    }

    if (this.pubs$.getValue() === null) {
      return false
    }

    if (this.playerDoc$.getValue()?.identifiers?.isHero || this.auth.user.role === 'admin') {
      return true
    }


  }

  public get myUid(): string {
    return this.auth.user.uid
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { IDeckList } from '../services/decks.service';

@Pipe({
  name: 'decksFilter'
})
export class DecksPipe implements PipeTransform {

  transform(items: Array<IDeckList>, deckFolderDocId: string, searchString?: string): Array<IDeckList> {

    if (!items) {
      return [];
    }

    if (searchString && searchString.length > 3) {
      return items.filter(d => d.name.replace(/ /g, '').toLowerCase().includes(searchString.replace(/ /g, '').toLowerCase()) || d.textList && d.textList.replace(/ /g, '').toLowerCase().includes(searchString.replace(/ /g, '').toLowerCase()));
    }

    if (deckFolderDocId === undefined) {
      return items;
    }

    if (deckFolderDocId === null) {
      return items.filter(d => d.deckFolderDocId === undefined || d.deckFolderDocId === null);
    }


    return items.filter(d => d.deckFolderDocId === deckFolderDocId);
  }

}

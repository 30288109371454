import { takeUntil } from 'rxjs/operators'
import { SettingsComponent } from './components/modals/settings/settings.component'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ToastService } from './services/toast.service'
import { AuthService, DialogsService, GlobalsService } from './services'
import { ActivatedRoute, Router } from '@angular/router'
import { AfterViewInit, Component, OnDestroy } from '@angular/core'
import { DeviceDetectorService } from 'ngx-device-detector'
import { faChrome } from '@fortawesome/free-brands-svg-icons'
import { NgbModal, NgbModalOptions, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap'
import { Subject } from 'rxjs'
import { PurchaseReceiptComponent } from './payment/purchase-receipt/purchase-receipt.component'
import { PayDonationComponent } from './payment/pay-donation/pay-donation.component'
import { ReportslipWatcherService } from './private/matches/services/reportslip-watcher.service'
import { EmojiService } from './private/social/messages/components/tolaria-emoji-picker/emoji.service'
import { PlayerNameService } from './services/players/player-name.service'
import { UserService } from './private/_shared/services/user.service'
import { RouteWatcherService } from './services/route-watcher.service'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy, AfterViewInit {

  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()

  title = 'tolaria'
  deviceInfo = null

  faTimes = faTimes
  faChrome = faChrome

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public globals: GlobalsService,
    public auth: AuthService,
    public dialogs: DialogsService,
    private deviceService: DeviceDetectorService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private popCofig: NgbTooltipConfig,
    private readonly reportslipWatcher: ReportslipWatcherService,
    private readonly emojis: EmojiService,
    private readonly players: PlayerNameService,
    private readonly user: UserService,
    private readonly routeWatcher: RouteWatcherService,
  ) {
    this.init()
    this.players.serviceReady$.subscribe(i => console.log('AppComponent:: player name service ready state emitted', i))
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  ngAfterViewInit(): void {
    this.initRouteWatcher()
  }

  private clearQueryParams(): void {
    this.router.navigate([], {})
  }

  private initRouteWatcher(): void {
    this.route.queryParams.pipe(takeUntil(this.componentDestroyed$)).subscribe(q => {
      console.log(q)
      Object.keys(q).forEach(k => {
        if (k === 'action') {

          console.log('app.component -> action params found', q)
          switch (q[k]) {

            case 'stripeAccountLinksRefresh':
              console.log('[ACTION] stripeAccountLinksRefresh')
              this.openSettings('payments', 'stripeAccountLinksRefresh')
              break

            case 'stripeAccountLinksReturn':
              console.log('[ACTION] stripeAccountLinksReturn')
              this.openSettings('payments')
              break

            case 'checkoutSuccess':
              console.log('[ACTION] stripeCheckoutSuccess')
              const modalOptions: NgbModalOptions = {
                centered: false,
                animation: true,
                backdrop: true,
                keyboard: true,
                size: 'lg',
              }

              // close all open modals before opening a new one
              this.modalService.dismissAll()
              this.modalService.open(PurchaseReceiptComponent, modalOptions).result
                .then(() => { this.clearQueryParams() }, () => { this.clearQueryParams() },)
                .catch((error) => console.log(error))
              break

            case 'checkoutCancel':
              console.log('[ACTION] stripeCheckoutCancel')
              this.toastService.show('Checkout session cancelled', { classname: 'error-toast', delay: 6000 })
              this.router.navigate([], { queryParams: {} })
              break


            case 'support-tolaria':
              const componentRef = this.modalService.open(PayDonationComponent, {
                size: 'sm',
                keyboard: true,
                centered: false,
                backdrop: true,
              })
              componentRef.componentInstance.modalView = true
              componentRef.componentInstance.currency = 'USD'
              componentRef.componentInstance.showText = false
              componentRef.componentInstance.showTypeSelector = true
              break

          }

        }
      })
    })
  }

  private init() {
    document.documentElement.setAttribute('data-theme', 'dark')
    this.deviceInfo = this.deviceService.getDeviceInfo()
    const isMobile = this.deviceService.isMobile()
    const isTablet = this.deviceService.isTablet()
    const isDesktopDevice = this.deviceService.isDesktop()
    if (isMobile || isTablet) {
      // this.router.navigate(['mobile'])
      this.globals.isMobile = true
      document.documentElement.setAttribute('data-device', 'mobile')
      this.popCofig.disableTooltip = true
    }
    if (this.deviceInfo.deviceType.toLowerCase() === 'desktop' && this.deviceInfo.browser.toLowerCase() !== 'chrome') {
      this.toastService.show('We recommend using Tolaria.app with Chrome browser.', {
        classname: 'bg-danger text-light',
        autohide: false,
        delay: 15000
      })
    }

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)

  }

  public copyTextToClipboard(text: string) {
    const el = document.createElement('textarea')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    this.toastService.show('Text copied to clipboard', { classname: 'standard-toast', delay: 2000 })
  }

  private openSettings(startupView: string, action: string = null): void {
    // close all open modals before opening a new one
    this.modalService.dismissAll()

    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: 'static',
      keyboard: true,
      size: 'xl',
    }
    const modalRef = this.modalService.open(SettingsComponent, modalOptions)
    modalRef.componentInstance.startupView = startupView
    modalRef.componentInstance.action = action
    modalRef.result.then(() => this.clearQueryParams(), () => this.clearQueryParams())
  }

}

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const cardsIcon: IconDefinition = {
  prefix: 'fac',
  iconName: 'cards',
  icon: [
    400, // SVG view box width
    395, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M398.7,124l-71.8,221.4c-4.6,14.1-19.7,21.8-33.8,17.3l-29.6-9.6l11,1.2c14.8,1.6,28-9.1,29.5-23.9l27-256.5 l50.4,16.3C395.5,94.8,403.3,109.9,398.7,124z M294.1,33l-85.5-8.9l45.8,265c2.5,14.6-7.3,28.5-21.9,31l-94.5,16.3l109,11.4 l11.2,1.2c15,1.6,28.4-8.8,30-23.2l28.3-249.7l1.6-14.3C319.9,47.5,309.1,34.6,294.1,33z M233.6,281.4L192.4,25.1l-0.6-3.6 C189.6,7.4,177.1-2.1,164,0.4L20,27.2C6.9,29.6-1.9,43,0.4,57.2l41.7,259.9c2.3,14.1,14.7,23.6,27.9,21.2l59.1-11l84.9-15.8 C227,309,235.8,295.6,233.6,281.4z'
  ],
} as any;

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IMatchAppointment } from 'tolaria-cloud-functions/src/_interfaces';
import { MessageModalService } from 'src/app/components/modals/message-modal/message-modal.service';
import { Timestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid'
import { ToastService } from 'src/app/services/toast.service';
import { MessagesService } from 'src/app/services';

export interface IMatchAppointmentInput {
  playerDocId: string
  playerUid: string
  opponentDocId: string
  matchDocId: string
  eventDocId?: string
  from: number
  to: number
}
@Injectable({
  providedIn: 'root'
})
export class MatchAppointmentHelperService {

constructor(
  private readonly firestore: AngularFirestore,
  private readonly confirm: MessageModalService,
  private readonly toast: ToastService,
  private readonly messageService: MessagesService,
) { }

public cancel(appointment: IMatchAppointment): void {
  this.confirm.open({
    type: 'warning',
    title: 'Cancel appointment?',
    message: '',
    buttons: [
      {
        type: 'dismiss',
        text: 'Abort',
        value: null
      },
      {
        type: 'close',
        text: 'Cancel Appointment',
        value: 'cancel-appointment'
      },
    ]
  })
  .then((val: string) => {
    if (val === 'cancel-appointment') {
      console.log('MatchAppointmentHelper:: initiate cancelation process')
      this.firestore.collection<IMatchAppointment>('matchAppointments')
        .doc(appointment.docId)
        .update({
          isAccepted: false,
          isRejected: false,
          isCancelled: true,
          timestampCancelled: Timestamp.now().seconds
        })
    }
  })
}

public accept(appointment: IMatchAppointment): void {
  this.firestore.collection<IMatchAppointment>('matchAppointments')
    .doc(appointment.docId)
    .update({
      isAccepted: true,
      isRejected: false,
      timestampAccepted: Timestamp.now().seconds
    })
}

public reject(appointment: IMatchAppointment): void {
  this.firestore.collection<IMatchAppointment>('matchAppointments')
    .doc(appointment.docId)
    .update({
      isAccepted: false,
      isRejected: true,
      timestampRejected: Timestamp.now().seconds
    })
}

public create(data: IMatchAppointmentInput): Promise<boolean> {

  return new Promise((resolve) => {

    const appointment: IMatchAppointment = {
      docId: uuidv4(),
      matchDocId: data.matchDocId,
      playerUid: data.playerUid,
      playerDocId: data.playerDocId,
      opponentDocId: data.opponentDocId,
      playerDocIds: [data.playerDocId, data.opponentDocId],
      eventDocId: data.eventDocId,
      timestampFrom: data.from,
      timestampTo: data.to,
      timestampCreated: Timestamp.now().seconds,
      opponentHasRead: false,
      isAccepted: false,
      isRejected: false,
      isCancelled: false,
      timestampAccepted: null,
      timestampRejected: null,
      timestampCancelled: null,
      timestampRead: null,
    }

    this.firestore
      .collection('matchAppointments')
      .doc(appointment.docId)
      .set(appointment)
      .then(() => {
        this.toast.show('Appointment sent to your opponent', { classname: 'success-toast', delay: 2000 })
        // send message to the user as well
        this.messageService.postMatchAppointment(appointment)
        resolve(true)
      })
      .catch((error) => {
        this.toast.show(error, { classname: 'error-toast', delay: 10000 })
        resolve(false)
      })

  })

}

}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { PublicHeaderComponent } from '../../public-header/public-header.component';
import { PublicFooterComponent } from '../../public-footer/public-footer.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { applicationIcon } from 'src/assets/font-icons/tolaria-icon';
import { IPromiseResponse, IUserRegistration } from 'tolaria-cloud-functions/src/_interfaces';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from 'src/app/services';

interface IAuthMessage {
  success: boolean | null
  message: string
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    PublicHeaderComponent,
    PublicFooterComponent,
    FontAwesomeModule,
    FormsModule,
    RouterLink,
  ]
})
export class RegisterComponent {

  public isMobile = window.innerWidth < 500
  public topMargin = this.isMobile ? 0 : 10

  public appIcon = applicationIcon

  public authMessage: IAuthMessage = {
    success: null,
    message: '',
  }

  public registrationData: IUserRegistration = {
    email: '',
    password: '',
    confirmPassword: ''
  }

  constructor(
    private afAuth: AngularFireAuth,
    private auth: AuthService,
    private router: Router,
  ) { }

  public emailSignUp() {
    this.afAuth
      .createUserWithEmailAndPassword(this.registrationData.email, this.registrationData.password)
      .then((credential) => {
        console.log('RegisterComponent:: createUserWithEmailAndPassword -> credential:', credential)
        this.authMessage.success = true
        this.authMessage.message = 'User registration successful, redirecting...'
        this.auth.emailSignin({ email: this.registrationData.email, password: this.registrationData.password })
          .then((response: IPromiseResponse) => {
            this.authMessage.success = response.status
            this.authMessage.message = response.text
            setTimeout(() => this.router.navigate(['/register']), 1000)
          })
          .catch((error) => {
            this.authMessage.success = error.status
            this.authMessage.message = error.text
          })
      })
      .catch((error) => {
        console.error('RegisterComponent:: createUserWithEmailAndPassword -> error:', error)
        this.authMessage.success = false
        this.authMessage.message = error
      })
  }

  public get emailValid(): boolean {
    return /^\w+([+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.registrationData.email)
  }

  public get passwordValid(): boolean {
    return this.registrationData.password.length >= 6
  }

  public get passwordMatch(): boolean {
    return this.registrationData.password === this.registrationData.confirmPassword && this.registrationData.confirmPassword.length >= 6
  }

  public get formValid(): boolean {
    return this.emailValid && this.registrationData.password === this.registrationData.confirmPassword
  }

}

<div class="d-flex align-items-center">
  <div ngbDropdown #matchActionDropdown="ngbDropdown" class="d-inline-block w-100">
    <button class="btn btn-sm btn-blank" id="dropdownBasic1" ngbDropdownToggle ngbTooltip="Match Actions" container="body">
      <fa-icon [fixedWidth]="true" [icon]="icons.faPlayerAction"></fa-icon>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" [style.width.rem]="25">
      <button ngbDropdownItem (click)="updateLife(20, true); $event.stopPropagation()">
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.faUndo"></fa-icon><span>Reset Life Points
          (20)</span><span class="key float-end">c</span>
      </button>
      <button ngbDropdownItem (click)="updateLife(1); $event.stopPropagation()">
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.faPlusSquare"></fa-icon><span>Life Points
          (+1)</span><span class="key float-end">arrow up</span>
      </button>
      <button ngbDropdownItem (click)="updateLife(5); $event.stopPropagation()">
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.faPlusSquare"></fa-icon><span>Life Points
          (+5)</span><span class="key float-end">arrow up</span><span class="float-end me-2 ms-2">+</span><span
          class="key float-end">Shift</span>
      </button>
      <button ngbDropdownItem (click)="updateLife(-5); $event.stopPropagation()">
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.faMinusSquare"></fa-icon><span>Life Points
          (-5)</span><span class="key float-end">arrow down</span><span class="float-end me-2 ms-2">+</span><span
          class="key float-end">Shift</span>
      </button>
      <button ngbDropdownItem (click)="updateLife(-1); $event.stopPropagation()">
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.faMinusSquare"></fa-icon><span>Life Points
          (-1)</span><span class="key float-end">arrow down</span>
      </button>
      <div class="separator"></div>
      <button ngbDropdownItem (click)="updateGameWin(true); $event.stopPropagation()">
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.faPlusSquare"></fa-icon><span>Add a game
          win</span><span class="key float-end">g</span>
      </button>
      <button ngbDropdownItem (click)="updateGameWin(false); $event.stopPropagation()">
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.faMinusSquare"></fa-icon><span>Remove a game
          win</span><span class="key float-end">h</span>
      </button>
      <button ngbDropdownItem (click)="openReportSlip(); $event.stopPropagation()">
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.faHandshake"></fa-icon><span>Report result</span><span
          class="key float-end">r</span>
      </button>
      <div class="separator"></div>
      <button ngbDropdownItem (click)="flipCoin(); $event.stopPropagation()"
        >
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.facCoin"></fa-icon><span>Flip a coin</span><span
          class="key float-end">i</span>
      </button>
      <button ngbDropdownItem (click)="rollD6(); $event.stopPropagation()"
        >
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.faDiceD6"></fa-icon><span>Roll a dice (D6)</span><span
          class="key float-end">o</span>
      </button>
      <button ngbDropdownItem (click)="rollD20(); $event.stopPropagation()"
        >
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.faDiceD20"></fa-icon><span>Roll a dice (D20)</span><span
          class="key float-end">p</span>
      </button>
      <div class="separator"></div>
      <button ngbDropdownItem (click)="addMatchNote(); $event.stopPropagation();">
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.faEdit"></fa-icon><span>Add note</span><span
          class="key float-end">n</span>
      </button>
      <div class="separator"></div>
      <button ngbDropdownItem (click)="showPopOver = true">
        <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.faExternalLinkAlt"></fa-icon><span>Open this as a floating
          panel</span>
      </button>
    </div>
  </div>
  
  <button class="btn btn-sm btn-blank" (click)="openReportSlip()" ngbTooltip="Open the Report slip to report the result" container="body">
    <fa-icon [fixedWidth]="true" [icon]="icons.faHandshake"></fa-icon>
  </button>
</div>

<ng-template #addMatchNoteForm let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add a match note</h4>
    <div class="btn-close float-end text-secondary" aria-label="Close" (click)="modal.dismiss('cancel')">
      <fa-icon [fixedWidth]="true" [icon]="icons.faTimes"></fa-icon>
    </div>
  </div>
  <div class="modal-body">
    <textarea [(ngModel)]="newMatchNote" rows="5" class="w-100"></textarea>
  </div>
  <div class="modal-footer">
    <div type="button" class="btn btn-sm text-secondary" (click)="modal.dismiss('cancel')">Cancel</div>
    <div type="button" class="btn btn-sm btn-secondary" (click)="modal.dismiss('addNote')">Save</div>
  </div>
</ng-template>

<div #popOver ngDraggable [handle]="dragHandle" class="card popover" *ngIf="showPopOver">

  <div class="card-header d-flex">
    <div #dragHandle class="btn btn-sm grabber" style="color: var(--content-text);">
      <fa-icon [fixedWidth]="true" [icon]="icons.faGripLines"></fa-icon>
    </div>
    <div class="flex-grow-1 text-center">Match Actions</div>
    <div class="btn btn-sm" style="color: var(--content-text);" (click)="showPopOver = false">
      <fa-icon [fixedWidth]="true" [icon]="icons.faTimes"></fa-icon>
    </div>
  </div>

  <div class="card-body no-hover">

    <div class="h6 text-center">Life</div>
    <div class="action-group d-flex">
      <button class="btn btn-success w-100 mb-2 me-1" (click)="updateLife(1); $event.stopPropagation()">+1</button>
      <button class="btn btn-success w-100 mb-2 ms-1 me-1" (click)="updateLife(5); $event.stopPropagation()">+5</button>
      <button class="btn btn-danger w-100 mb-2 ms-1 me-1" (click)="updateLife(-1); $event.stopPropagation()">-1</button>
      <button class="btn btn-danger w-100 mb-2 ms-1" (click)="updateLife(-5); $event.stopPropagation()">-5</button>
    </div>
    <button class="btn btn-secondary w-100" (click)="updateLife(20, true); $event.stopPropagation()">
      <fa-icon class="me-2" [fixedWidth]="true" [icon]="icons.faUndo"></fa-icon><span>Reset Life Points</span>
    </button>

    <div class="h6 text-center mt-3">Game Wins & Report</div>
    <div class="action-group d-flex">
      <button class="btn btn-secondary me-1 flex-grow-1" (click)="updateGameWin(true); $event.stopPropagation()">ADD</button>
      <button class="btn btn-secondary ms-1 me-1 flex-grow-1"(click)="updateGameWin(false); $event.stopPropagation()">REMOVE</button>
      <button class="btn btn-secondary ms-1 flex-grow-1"(click)="openReportSlip(); $event.stopPropagation()">REPORT</button>
    </div>

    <div class="h6 text-center mt-3">Various</div>
    <div class="action-group d-flex">
      <ng-container *ngIf="webRtcService.dataConnectionEstablished$ | async">
        <button class="btn btn-secondary w-100 me-1" (click)="flipCoin(); $event.stopPropagation()">
          <fa-icon [fixedWidth]="true" [icon]="icons.facCoin"></fa-icon>
        </button>
        <button class="btn btn-secondary w-100 ms-1 me-1" (click)="rollD6(); $event.stopPropagation()">
          <fa-icon [fixedWidth]="true" [icon]="icons.faDiceD6"></fa-icon>
        </button>
        <button class="btn btn-secondary w-100 ms-1 me-1" (click)="rollD20(); $event.stopPropagation()">
          <fa-icon [fixedWidth]="true" [icon]="icons.faDiceD20"></fa-icon>
        </button>
      </ng-container>
      <button class="btn btn-secondary w-100 ms-1" (click)="addMatchNote(); $event.stopPropagation();">
        <fa-icon [fixedWidth]="true" [icon]="icons.faEdit"></fa-icon>
      </button>
    </div>

  </div>

</div>

import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { MessageModalService } from 'src/app/components/modals/message-modal/message-modal.service'
import { ToastService } from 'src/app/services/toast.service'
import { IMessageDocument } from 'tolaria-cloud-functions/src/_interfaces'
import * as firestore from 'firebase/firestore'
import { PlayerNameService } from 'src/app/services/players/player-name.service'
import { MessageItem } from './message-list.service'

@Injectable({
  providedIn: 'root'
})
export class MessageActionService {

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly toast: ToastService,
    private readonly confirm: MessageModalService,
    private readonly playerNames: PlayerNameService,
  ) { }

  public delete(msg: MessageItem) {
    this.confirm.open({
      type: 'danger',
      centered: true,
      title: 'Delete message?',
      message: `Are you sure you want to delete this message, <span class="text-danger text-bold">this cannot be undone!</span>`,
      buttons: [
        {
          type: 'dismiss',
          text: 'Cancel',
          value: '',
        },
        {
          type: 'close',
          text: 'Delete',
          value: 'delete',
        },
      ]
    })
    .then(value => {
      if (value === 'delete') {

        let batch: firebase.default.firestore.WriteBatch
        
        if (msg.isPinned) {
          batch = this.unpinMessage(msg, true) as firebase.default.firestore.WriteBatch
        }
        else {
          batch = this.firestore.firestore.batch()
        }

        // add message deletion
        batch.delete(this.firestore.collection('messages').doc(msg.docId).ref)
        
        batch.commit()
          .then(() => this.toast.show('Message deleted', { classname: 'success-toast' }))
          .catch((e) => this.toast.show(e, { classname: 'success-toast', delay: 10000 }))
      }
    })
  }

  public update(message: IMessageDocument) {
    this.firestore
      .collection('messages')
      .doc(message.docId)
      .set(message, { merge: true })
      .then(() => this.toast.show('Message updated', { classname: 'success-toast' }))
      .catch((e) => this.toast.show(e, { classname: 'success-toast', delay: 10000 }))
  }

  public addReaction(id: string, reaction: string): void {
    this.firestore.collection('messages').doc(id)
      .update({
        [`reactions.${reaction}`]: firestore.arrayUnion(this.playerNames.currentPlayersMini.id)
      })
      .then(() => console.log('reaction added'))
      .catch((error) => console.error(error))
  }

  public removeRaction(id: string, reaction: string): void {
    this.firestore.collection('messages').doc(id)
      .update({
        [`reactions.${reaction}`]: firestore.arrayRemove(this.playerNames.currentPlayersMini.id)
      })
      .then(() => console.log('reaction added'))
      .catch((error) => console.error(error))
  }

  public markAsUnread(msg: MessageItem): void {
    this.firestore
      .collection('messageGroups')
      .doc(msg.message.messageGroupDocId)
      .update({
        [`playersLastVisit.${this.playerNames.currentPlayersMini.id}`]: msg.message.timestamp
      })
      .catch((error) => console.error(error))
  }

  public pinMessage(msg: MessageItem): void {
    
    const batch = this.firestore.firestore.batch()
    
    // update message group
    batch.update(this.firestore.collection('messageGroups').doc(msg.message.messageGroupDocId).ref, {
      pinnedMessages: firestore.arrayUnion(msg.docId)
    })

    // add message to message group sub collection
    batch.set(this.firestore.collection('messageGroups').doc(msg.message.messageGroupDocId).collection('pinned').doc(msg.docId).ref, msg.message)

    // update message
    batch.update(this.firestore.collection('messages').doc(msg.docId).ref, {
      isPinned: true,
      pinnedBy: this.playerNames.currentPlayersMini.id
    })
      
    batch.commit().catch((error) => console.error(error))

  }

  public unpinMessage(msg: MessageItem, commit: boolean = true): firebase.default.firestore.WriteBatch | void {

    const batch = this.firestore.firestore.batch()
    
    // update message group
    batch.update(this.firestore.collection('messageGroups').doc(msg.message.messageGroupDocId).ref, {
      pinnedMessages: firestore.arrayRemove(msg.docId)
    })

    // remove message from message group sub collection
    batch.delete(this.firestore.collection('messageGroups').doc(msg.message.messageGroupDocId).collection('pinned').doc(msg.docId).ref)

    // update message
    batch.update(this.firestore.collection('messages').doc(msg.docId).ref, {
      isPinned: false,
      pinnedBy: null,
    })


    if (commit) {

      this.confirm.open({
        type: 'danger',
        centered: true,
        title: 'Un-pin message',
        message: `Are you sure you want to un-pin the message?`,
        buttons: [
          {
            type: 'dismiss',
            text: 'Cancel',
            value: '',
          },
          {
            type: 'close',
            text: 'Un-pin',
            value: 'unpin',
          },
        ]
      })
      .then(value => {
        if (value === 'unpin') {
  
          batch.commit().catch((error) => console.error(error))
          
        }
      })
    
    }
    else {
      return batch
    }

  }


}

<ng-container *ngIf="eventDoc$ | async as event">

  <div class="d-flex align-items-center mt-3 mb-2 ms-3 cursor-pointer" (click)="submenuOpen = !submenuOpen">
    <fa-icon [icon]="faChevronRight" class="text-small text-secondary me-2 transition-all-25" [ngClass]="{ 'rotate-90deg' : submenuOpen }"></fa-icon>
    <span class="text-secondary flex-grow-1">Swiss</span>
  </div>

  <ng-container *ngIf="submenuOpen">
    <!-- VISIBLE IF PLAYOFF IS FALSE -->
    <button ngbDropdownItem (click)="addPlayoffBracket(event)"
      [disabled]="event.details.structure.swiss.hasBracketAfterSwiss || event.statusCode >= 6 && event.statusCode !== 13">
      Add playoffs
    </button>
  
    <!-- VISIBLE IF PLAYOFF IS TRUE AND NOT STARTED -->
    <button ngbDropdownItem (click)="removePlayoffBracket(event)"
      [disabled]="!event.details.structure.swiss.hasBracketAfterSwiss">
      Remove playoffs
    </button>
  
    <!-- VISIBLE AS LONG AS SWISS IS NOT FINISHED -->
    <button ngbDropdownItem (click)="addSwissRound(event)" [disabled]="event.statusCode < 2 || event.statusCode >= 6 && event.statusCode !== 13">
      Add swiss round
    </button>
  
    <!-- DISABLED IF ACTIVE ROUND IS LAST ROUND -->
    <button ngbDropdownItem (click)="removeSwissRound(event)"
      [disabled]="event.activeRound === event.details.structure.swiss.roundsToPlay && event.statusCode !== 13 || event.statusCode === 11 || event.statusCode < 2">
      Remove swiss Round
    </button>
  
    <!-- DISABLED IF EVENT NOT STARTED OR SWISS IS FINISHED  -->
    <button ngbDropdownItem (click)="reactivatePreviousRound(event)"
      [disabled]="event.statusCode < 1 && event.statusCode !== 13 || event.statusCode > 5 && event.statusCode !== 13 || event.activeRound === 1">
      Reactivate previous round
    </button>
  
    <!-- DISBALED IF ROUND IS NOT PAIRED OR IF SWISS IS FINISHED -->
    <button ngbDropdownItem (click)="unpairSwissRound(event)"
      [disabled]="event.statusCode < 2 && event.statusCode !== 13 || event.statusCode > 4 && event.statusCode !== 13">
      Delete active pairings
    </button>
  
    <!-- DISBALED IF ROUND IS NOT PAIRED OR IF SWISS IS FINISHED -->
    <button ngbDropdownItem (click)="manualPairingMode(event)"
      [disabled]="event.statusCode < 2 && event.statusCode !== 13 || event.statusCode > 4 && event.statusCode !== 13">
      Manual Pairing
    </button>
  </ng-container>

</ng-container>

import { map, takeUntil } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Component, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { IDeckList } from 'src/app/services/decks.service';
import { AuthService } from 'src/app/services';
import { SwipeEvent } from 'ng-swipe';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import * as firestore from 'firebase/firestore'

@Component({
  selector: 'app-deckster',
  templateUrl: './deckster.component.html',
  styleUrls: ['./deckster.component.css']
})
export class DecksterComponent implements OnDestroy {

  @ViewChild('thumbsUp', { static: false }) thumbsUp: ElementRef;
  @ViewChild('thumbsDown', { static: false }) thumbsDown: ElementRef;

  private componentWasDestroyed$ = new Subject<boolean>();
  public deckToRate$: BehaviorSubject<IDeckList> = new BehaviorSubject<IDeckList>(null);
  public decks$: Observable<IDeckList[]>;
  private decksCollection: AngularFirestoreCollection<IDeckList>;
  public showDeckList: boolean = false;
  public swiping = false;

  public decksToRate: IDeckList[] = [];
  private initialized = false;

  faThumbsUp = faThumbsUp;
  faThumbsDown = faThumbsDown;

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService
  ) {

    this.decksCollection = this.afs.collection<IDeckList>('decks', ref => ref
      .where('deckPhotoUrl', '!=', '')
    );

    this.decks$ = this.decksCollection.valueChanges().pipe(
      map((decks) => {
        return this.shuffleDeckLists(decks.filter(d => d.ratedByPlayerDocIds === undefined || !d.ratedByPlayerDocIds.includes(this.auth.user.playerId))).splice(0, 100);
      })
    );

    this.decks$.pipe(takeUntil(this.componentWasDestroyed$)).subscribe((decks) => {
      console.log('decks emitted');
      console.log(decks);
      const randomIndex = this.getRandomInt(decks.length);
      // this.deckToRate$.next(decks[randomIndex]);

      if (this.initialized) {
        if (this.decksToRate.find(d => d.docId === decks[randomIndex].docId) === undefined) {
          this.decksToRate.push(decks[randomIndex]);
        }
      }
      else {
        this.decksToRate = decks.splice(0, 10);
        this.initialized = true;
      }

    });
  }

  private shuffleDeckLists(decks: IDeckList[]): IDeckList[] {
    let currentIndex = decks.length
    let randomIndex: number;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [decks[currentIndex], decks[randomIndex]] = [
        decks[randomIndex], decks[currentIndex]];
    }

    return decks;
  }

  ngOnDestroy(): void {
    this.componentWasDestroyed$.next(true);
  }

  public trackByDeckDocId(index: number, item: IDeckList) {
    return `${index}--${item.docId}`;
  }
  private getRandomInt(max: number): number {
    return Math.floor(Math.random() * max);
  }

  private addRating(increment: boolean, deck: IDeckList): void {
    let update: any = {
      ratedByPlayerDocIds: firestore.arrayUnion(this.auth.user.playerId)
    };
    if (increment) {
      update.rating = firestore.increment(1)
    }
    this.afs.collection('decks').doc(deck.docId).update(update);
  }

  public onSwipeEnd(event: SwipeEvent, deck: IDeckList, elemntRef: any): void {

    const screenWidth = window.innerWidth;
    this.swiping = false;

    // turn of being dragged
    const decksterCard = document.getElementById(elemntRef.id);
    decksterCard.classList.remove('being-dragged');

    if (event.direction === 'x') {
      this.thumbsUp.nativeElement.style.opacity = 0;
      this.thumbsDown.nativeElement.style.opacity = 0;
      if (event.distance / screenWidth > 0.3 || event.distance / screenWidth < -0.3) {
        decksterCard.style.setProperty('left', event.distance > 0 ? '120vw' : '-120vw');
        setTimeout(() => {
          this.addRating(event.distance > 0, deck);
          this.decksToRate.shift();
          this.thumbsDown.nativeElement.style.display = 'none';
          this.thumbsUp.nativeElement.style.display = 'none';
        }, 1200);
        setTimeout(() => {
          document.getElementById(this.decksToRate[1].docId).classList.add('active');
        }, 350);
      }
      else {
        decksterCard.style.setProperty('left', '0');
        this.thumbsDown.nativeElement.style.display = 'none';
        this.thumbsUp.nativeElement.style.display = 'none';
      }
    }

    if (event.direction === 'y' && event.distance < -50) {
      this.showDeckList = true;
    }
    if (event.direction === 'y' && event.distance > 75) {
      this.showDeckList = false;
    }
  }

  public onSwipeMove(event: SwipeEvent, elemntRef: any) {
    this.swiping = true;

    if (event.direction = 'x') {
      this.thumbsUp.nativeElement.style.display = event.distance > 0 ? 'block' : 'none';
      this.thumbsUp.nativeElement.style.opacity = event.distance > 0 ? 1 : 0;
      this.thumbsDown.nativeElement.style.display = event.distance < 0 ? 'block' : 'none';
      this.thumbsDown.nativeElement.style.opacity = event.distance < 0 ? 1 : 0;
      const decksterCard = document.getElementById(elemntRef.id);
      decksterCard.classList.add('being-dragged');
      decksterCard.style.setProperty('left', `${event.distance}px`);
    }


  }

}

<div quill-toolbar-element class="tolaria-wysiwyg-toolbar d-flex align-items-center gap-1 py-1"
    [ngClass]="{ 'hidden' : toolbarHidden}">
    
    <ng-container *ngIf="richText">

        <div class="ql-formats d-flex align-items-center">
            <button class="btn btn-sm ql-header ql-button" value="" [ngbTooltip]="tipContentAlignLeft">
                <fa-icon [icon]="icon.normal" [fixedWidth]="true"></fa-icon>
            </button>
            <button class="btn btn-sm ql-header ql-button" value="1" [ngbTooltip]="tipContentAlignLeft">
                <fa-icon [icon]="icon.heading1" [fixedWidth]="true"></fa-icon>
            </button>
            <button class="btn btn-sm ql-header ql-button" value="3" [ngbTooltip]="tipContentAlignLeft">
                <fa-icon [icon]="icon.heading2" [fixedWidth]="true"></fa-icon>
            </button>
            <!-- <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-sm ql-button" id="dropdownHeading" ngbDropdownToggle>
                    {{ selectedFormat }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownHeading">
                    <button class="ql-header" value="1" ngbDropdownItem>Heading 1</button>
                    <button class="ql-header" value="2" ngbDropdownItem>Heading 2</button>
                    <button class="ql-header" value="3" ngbDropdownItem>Heading 3</button>
                    <button class="ql-header" value=""  ngbDropdownItem>Normal</button>
                </div>
            </div> -->
            <!-- <span class="ql-header ql-picker ql-expanded btn btn-sm">
                <span class="ql-picker-label" tabindex="0" role="button" aria-expanded="true" aria-controls="ql-picker-options-0">
                    <svg viewBox="0 0 18 18">
                        <polygon class="ql-stroke" points="7 11 9 13 11 11 7 11"></polygon>
                        <polygon class="ql-stroke" points="7 7 9 5 11 7 7 7"></polygon>
                    </svg>
                </span>
                <span class="ql-picker-options" aria-hidden="false" tabindex="-1" id="ql-picker-options-0">
                    <span tabindex="0" role="button" class="ql-picker-item" data-value="1"></span>
                    <span tabindex="0" role="button" class="ql-picker-item" data-value="2"></span>
                    <span tabindex="0" role="button" class="ql-picker-item"></span>
                </span>
            </span> -->
        </div>
        <div class="toolbar-separator"></div>

        <div class="ql-formats d-flex align-items-center">
            <button class="btn btn-sm ql-align ql-button" value="" [ngbTooltip]="tipContentAlignLeft">
                <fa-icon [icon]="icon.alignLeft" [fixedWidth]="true"></fa-icon>
            </button>
            <button class="btn btn-sm ql-align ql-button" value="center" [ngbTooltip]="tipContentAlignCenter">
                <fa-icon [icon]="icon.alignCenter" [fixedWidth]="true"></fa-icon>
            </button>
            <button class="btn btn-sm ql-align ql-button" value="right" [ngbTooltip]="tipContentAlignRight">
                <fa-icon [icon]="icon.alignRight" [fixedWidth]="true"></fa-icon>
            </button>
        </div>
        <div class="toolbar-separator"></div>
    
    </ng-container>


    

    <div class="ql-formats d-flex align-items-center">
        <button class="btn btn-sm ql-bold ql-button" [ngbTooltip]="tipContentBold">
            <fa-icon [icon]="icon.bold" [fixedWidth]="true"></fa-icon>
        </button>
        <button class="btn btn-sm ql-italic ql-button" [ngbTooltip]="tipContentItalic">
            <fa-icon [icon]="icon.italic" [fixedWidth]="true"></fa-icon>
        </button>
        <button class="btn btn-sm ql-strike ql-button" [ngbTooltip]="tipContentStrike">
            <fa-icon [icon]="icon.strike" [fixedWidth]="true"></fa-icon>
        </button>
    </div>
    <div class="toolbar-separator"></div>

    <div class="ql-formats d-flex align-items-center">
        <button class="btn btn-sm ql-link ql-button" [ngbTooltip]="tipContentLink">
            <fa-icon [icon]="icon.link" [fixedWidth]="true"></fa-icon>
        </button>
    </div>
    <div class="toolbar-separator"></div>

    <div class="ql-formats d-flex align-items-center">
        <button class="btn btn-sm ql-list ql-button" value="ordered" [ngbTooltip]="tipContentOrderedList">
            <fa-icon [icon]="icon.ol" [fixedWidth]="true"></fa-icon>
        </button>
        <button class="btn btn-sm ql-list ql-button" value="bullet" [ngbTooltip]="tipContentBulletedList">
            <fa-icon [icon]="icon.ul" [fixedWidth]="true"></fa-icon>
        </button>
    </div>
    <div class="toolbar-separator"></div>

    <div class="ql-formats d-flex align-items-center">
        <button class="btn btn-sm ql-blockquote ql-button" [ngbTooltip]="tipContentBlockquote">
            <fa-icon [icon]="icon.blockQuote" [fixedWidth]="true"></fa-icon>
        </button>
    </div>
    <div class="toolbar-separator"></div>

    <div class="ql-formats d-flex align-items-center">
        <button class="btn btn-sm ql-code ql-button" [ngbTooltip]="tipContentCode">
            <fa-icon [icon]="icon.code" [fixedWidth]="true"></fa-icon>
        </button>
        <button class="btn btn-sm ql-code-block ql-button" [ngbTooltip]="tipContentCodeBlock">
            <!-- <fa-icon [icon]="icon.codeBlock" [fixedWidth]="true"></fa-icon> -->
            code
        </button>
    </div>
</div>




<ng-template #tipContentBold>
    <div class="d-flex flex-column gap-2 p-2">
        <div class="fw-bold">Bold</div>
        <div class="d-flex gap-2 justify-content-center">
            <div class="keyboard-key" [ngClass]="{ 'command-key' : ctrlKey === '⌘' }">{{ ctrlKey }}</div>
            <div class="keyboard-key">B</div>
        </div>
    </div>
</ng-template>
<ng-template #tipContentItalic>
    <div class="d-flex flex-column gap-2 p-2">
        <div class="fw-bold">Italic</div>
        <div class="d-flex gap-2 justify-content-center">
            <div class="keyboard-key" [ngClass]="{ 'command-key' : ctrlKey === '⌘' }">{{ ctrlKey }}</div>
            <div class="keyboard-key">I</div>
        </div>
    </div>
</ng-template>
<ng-template #tipContentStrike>
    <div class="d-flex flex-column gap-2 p-2">
        <div class="fw-bold">Strikethrough</div>
        <div class="d-flex gap-2 justify-content-center">
            <div class="keyboard-key" [ngClass]="{ 'command-key' : ctrlKey === '⌘' }">{{ ctrlKey }}</div>
            <div class="keyboard-key">Shift</div>
            <div class="keyboard-key">X</div>
        </div>
    </div>
</ng-template>
<ng-template #tipContentLink>
    <div class="d-flex flex-column gap-2 p-2">
        <div class="fw-bold">Link</div>
        <div class="d-flex gap-2 justify-content-center">
            <div class="keyboard-key" [ngClass]="{ 'command-key' : ctrlKey === '⌘' }">{{ ctrlKey }}</div>
            <div class="keyboard-key">Shift</div>
            <div class="keyboard-key">U</div>
        </div>
    </div>
</ng-template>
<ng-template #tipContentOrderedList>
    <div class="d-flex flex-column gap-2 p-2">
        <div class="fw-bold">Ordered list</div>
        <div class="d-flex gap-2 justify-content-center">
            <div class="keyboard-key" [ngClass]="{ 'command-key' : ctrlKey === '⌘' }">{{ ctrlKey }}</div>
            <div class="keyboard-key">Shift</div>
            <div class="keyboard-key">7</div>
        </div>
    </div>
</ng-template>
<ng-template #tipContentBulletedList>
    <div class="d-flex flex-column gap-2 p-2">
        <div class="fw-bold">Bulleted list</div>
        <div class="d-flex gap-2 justify-content-center">
            <div class="keyboard-key" [ngClass]="{ 'command-key' : ctrlKey === '⌘' }">{{ ctrlKey }}</div>
            <div class="keyboard-key">Shift</div>
            <div class="keyboard-key">8</div>
        </div>
    </div>
</ng-template>
<ng-template #tipContentBlockquote>
    <div class="d-flex flex-column gap-2 p-2">
        <div class="fw-bold">Blockquote</div>
        <div class="d-flex gap-2 justify-content-center">
            <div class="keyboard-key" [ngClass]="{ 'command-key' : ctrlKey === '⌘' }">{{ ctrlKey }}</div>
            <div class="keyboard-key">Shift</div>
            <div class="keyboard-key">9</div>
        </div>
    </div>
</ng-template>
<ng-template #tipContentCode>
    <div class="d-flex flex-column gap-2 p-2">
        <div class="fw-bold">Code block</div>
        <div class="d-flex gap-2 justify-content-center">
            <div class="keyboard-key" [ngClass]="{ 'command-key' : ctrlKey === '⌘' }">{{ ctrlKey }}</div>
            <div class="keyboard-key">Shift</div>
            <div class="keyboard-key">C</div>
        </div>
    </div>
</ng-template>
<ng-template #tipContentCodeBlock>
    <div class="d-flex flex-column gap-2 p-2">
        <div class="fw-bold">Code block</div>
        <div class="d-flex gap-2 justify-content-center">
            <div class="keyboard-key" [ngClass]="{ 'command-key' : ctrlKey === '⌘' }">{{ ctrlKey }}</div>
            <div class="keyboard-key">{{ altKey }}</div>
            <div class="keyboard-key">Shift</div>
            <div class="keyboard-key">C</div>
        </div>
    </div>
</ng-template>
<ng-template #tipContentAlignLeft>
    <div class="d-flex flex-column gap-2 p-2">
        <div class="fw-bold">Alignment left</div>
    </div>
</ng-template>
<ng-template #tipContentAlignCenter>
    <div class="d-flex flex-column gap-2 p-2">
        <div class="fw-bold">Alignment center</div>
    </div>
</ng-template>
<ng-template #tipContentAlignRight>
    <div class="d-flex flex-column gap-2 p-2">
        <div class="fw-bold">Alignment right</div>
    </div>
</ng-template>
<ng-template #tipContentAlignJustify>
    <div class="d-flex flex-column gap-2 p-2">
        <div class="fw-bold">Alignment justified</div>
    </div>
</ng-template>


<div class="filter-bar d-flex align-items-center">

  <div class="d-flex flex-grow-1 justify-content-center">
    <div class="d-inline-block m-1" [style.width]="isMobile ? '100%' : '30rem'">
      <input type="text" class="form-control form-control-sm" [(ngModel)]="searchString"
        placeholder="Search for tickets here..." width="100%">
    </div>
  </div>

  <div class="btn btn-sm btn-blank text-secondary text-no-wrap" [routerLink]="['/tickets/manage']">My tickets</div>

  <div class="btn btn-sm btn-outline-main text-no-wrap" (click)="createNewTicket()">New ticket</div>

</div>
<section class="p-1" *ngIf="tickets$ | async as tickets">

  <div class="background d-flex align-items-center justify-content-center">
    <fa-icon [icon]="ticketIcon" fixedWidth="true"></fa-icon>
  </div>


  <div class="card-group">
    <div class="card m-1" *ngFor="let ticket of tickets | ticketFilter : searchString; trackBy : tracker" [ngClass]="{ 'mobile' : isMobile }">
      <div class="card-body no-hover p-3 d-flex flex-column">

        <div class="type text-center text-secondary d-flex align-items-center {{ ticket.type }}">
          <div class="flex-grow-1">{{ ticket.type }} TICKET</div>
        </div>

        <div class="py-3 text-huge text-bold text-center">
          {{ ticket.salesData.price }} {{ ticket.salesData.currency.toUpperCase() }}
        </div>

        <div class="my-2 border-bottom border-secondary"></div>
        <div class="text-large">{{ ticket.name }}</div>
        <div class="text-secondary">{{ ticket.description }}</div>
        <div class="text-secondary py-2 border-bottom border-secondary">Seller: <app-player-display-name [playerDocId]="ticket.createdByPlayerDocId"></app-player-display-name></div>

        <div class="flex-grow-1 mt-3">
          <ng-container [ngSwitch]="ticket.type">
            <div class="version-wrapper d-flex flex-column align-items-start" *ngSwitchCase="'CONVENTION'">
              <div class="text-normal text-secondary">Events covered by this ticket</div>
              <div class="btn btn-sm btn-blank" *ngFor="let eventDocId of ticket.data.eventDocIds">
                <app-event-name [eventDocId]="eventDocId"></app-event-name>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="mt-2 mb-3 border-bottom border-secondary"></div>

        <ng-container *ngIf="!ticket.hasPurchased; else hasPurchased">
          <ng-container *ngIf="!ticket.soldOut && !ticket.showAsSoldOut; else soldOut">
            <div class="btn btn-main w-100" (click)="buyTicket(ticket)" *ngIf="ticket.salesData.from < now; else waitingForRelease">
              BUY NOW
            </div>
          </ng-container>
        </ng-container>

      </div>



      <ng-template #waitingForRelease>
        <div class="btn btn-blank w-100 text-warning" *ngIf="ticket.salesData.from > now">
          Sales start on <app-timestamp-clock [timestamp]="ticket.salesData.from" displayType="date" [seconds]="true"></app-timestamp-clock>
        </div>
      </ng-template>
      <ng-template #soldOut>
        <div class="btn btn-blank w-100 text-danger">
          Sorry, this ticket is <b>sold out</b>
        </div>
      </ng-template>
      <ng-template #hasPurchased>
        <div class="btn btn-blank w-100 text-success">
          <fa-icon [icon]="purchasedIcon" [fixedWidth]="true"></fa-icon> ticket purchased
        </div>
      </ng-template>

    </div>
  </div>


</section>



import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services';
import { MatchReportingService } from '../../tournament/services/match-reporting.service';
import { IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PlayerNameService } from 'src/app/services/players/player-name.service';

@Injectable({
  providedIn: 'root'
})
export class ReportslipWatcherService {

  private init$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private subscription: Subscription

  constructor(
    private readonly auth: AuthService,
    private readonly firestore: AngularFirestore,
    private readonly reporting: MatchReportingService,
    private readonly playerNames: PlayerNameService,
  ) {
    combineLatest([this.auth.user$, this.playerNames.serviceReady$, this.init$])
    .subscribe(([user, playersReady, init]) => {
      if (user && playersReady && init === false) {
        this.init()
      }
    })
  }
  
  private init(): void {
    this.init$.next(true)
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe()
    }

    console.log('[ReportslipWatcherService] --> init report slip watcher')
    this.subscription = this.firestore
      .collection<IMatchData>('matches', ref => ref.where('playerReporting.waitingForPlayerDocId', 'array-contains', this.auth.user.playerId))
      .valueChanges()
      .subscribe(m => {
        console.log('[ReportslipWatcherService] watcher emitted', m)
        if (m.length > 0) {
          console.log('[ReportslipWatcherService] should open report slip for a match ->', m)
          if (m.length > 1) {
            console.log('[ReportslipWatcherService] found multiple matches, open the first unreported')
          }
          const toOpen = m.find(i => !i.isReported && i.reportSlipOpenedBy !== this.auth.user.playerId)
          if (toOpen) {
            console.log('[ReportslipWatcherService] found an unreported match to open ->', toOpen)
            this.reporting.openReportSlip({ matchData: toOpen, isOrganizer: false, matchDocId: toOpen.docId })
          }
          else {
            console.log('[ReportslipWatcherService] found no unreported match to open, skipping', m)
          }
        }
      })
  }


}

<div class="card" style="min-width: 100%;" *ngIf="draft !== undefined">

  <div class="card-header d-flex align-items-center">
    <span class="flex-grow-1">Draft Configuration</span>
    <div class="btn-close text-secondary" aria-label="Close" (click)="closeModal()">
      <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
    </div>
  </div>

  <div class="card-body no-hover d-flex p-0">

    <div class="left-panel flex-grow-1 p-3">

      <div class="row">
        <div class="col">
          <div class="form-label mb-1">
            Name
            <fa-icon class="float-end text-red" [fixedWidth]="true"
              ngbTooltip="The name must be defined and contain at least five characters" placement="top-right"
              container="body" [icon]="faExclamationCircle" *ngIf="!nameOK"></fa-icon>
          </div>
          <input type="text" class="form-control" [(ngModel)]="draft.name">
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <div class="form-label mb-1">
            Type
            <fa-icon class="float-end text-red" [fixedWidth]="true"
              ngbTooltip="Select a draft type to be able to continue" placement="top-right" container="body"
              [icon]="faExclamationCircle" *ngIf="!typeOK"></fa-icon>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button class="w-100 btn {{ draft.isBooster ? 'btn-main' : 'btn-secondary' }}"
            (click)="changeDraftType('booster')" [disabled]="draft.statusCode > 2">Booster Draft</button>
        </div>
        <div class="col">
          <button class="w-100 btn {{ draft.isRochester ? 'btn-main' : 'btn-secondary' }}"
            (click)="changeDraftType('rochester')" [disabled]="draft.statusCode > 2">Rochester Draft</button>
        </div>
        <div class="col">
          <button class="w-100 btn {{ draft.isRotisserie ? 'btn-main' : 'btn-secondary' }}"
            (click)="changeDraftType('rotisserie')" [disabled]="draft.statusCode > 2">Rotisserie Draft</button>
        </div>
      </div>

      <!-- BOOSTER DRAFT -->
      <ng-container *ngIf="draft.isBooster || draft.isRochester">

        <div class="row mt-3">
          <div class="col">
            <div class="form-label mb-1">
              Packs
              <fa-icon class="float-end text-red" [fixedWidth]="true"
                ngbTooltip="All available packs need to be configured to be able to continue" placement="top-right"
                container="body" [icon]="faExclamationCircle" *ngIf="!packsOK"></fa-icon>
            </div>
          </div>
        </div>

        <div class="row align-items-center mt-3">

          <div class="col-2 text-secondary">Pack 1:</div>

          <div class="col">
            <button class="btn btn-sm btn-secondary pack-button text-start" (click)="showSetList('a')"  [disabled]="draft.statusCode > 2">
              <span class="set" *ngIf="draft.packs.a !== null">
                <ng-container *ngIf="draft.packs.a.customIcon; else keyruneIconA">
                  <fa-icon [icon]="fontIcons.setIcons[draft.packs.a.keyruneCode]" [fixedWidth]="true"></fa-icon>
                </ng-container>
                <ng-template #keyruneIconA>
                  <i class="ss ss-fw ss-{{ draft.packs.a.keyruneCode.toLowerCase() }}"></i>
                </ng-template>
                {{ draft.packs.a.name }}
              </span>
              <span *ngIf="draft.packs.a === null">Select Set</span>
            </button>
            <span class="ms-2 text-secondary" *ngIf="draft.packs.a !== null">{{ draft.packs.a.boosterSize }} card booster</span>
          </div>

        </div>

        <div class="row align-items-center mt-3">

          <div class="col-2 text-secondary">Pack 2:</div>

          <div class="col">
            <button class="btn btn-sm btn-secondary pack-button text-start" (click)="showSetList('b')" [disabled]="draft.statusCode > 2">
              <span class="set" *ngIf="draft.packs.b !== null">
                <ng-container *ngIf="draft.packs.b.customIcon; else keyruneIconB">
                  <fa-icon [icon]="fontIcons.setIcons[draft.packs.b.keyruneCode]" [fixedWidth]="true"></fa-icon>
                </ng-container>
                <ng-template #keyruneIconB>
                  <i class="ss ss-fw ss-{{ draft.packs.b.keyruneCode.toLowerCase() }}"></i>
                </ng-template>
                {{ draft.packs.b.name }}
              </span>
              <span *ngIf="draft.packs.b === null">Select Set</span>
            </button>
            <span class="ms-2 text-secondary" *ngIf="draft.packs.b !== null">{{ draft.packs.b.boosterSize }} card booster</span>
          </div>

        </div>

        <div class="row align-items-center mt-3">

          <div class="col-2 text-secondary">Pack 3:</div>

          <div class="col">
            <button class="btn btn-sm btn-secondary pack-button text-start" (click)="showSetList('c')" [disabled]="draft.statusCode > 2">
              <span class="set" *ngIf="draft.packs.c !== null">
                <ng-container *ngIf="draft.packs.c.customIcon; else keyruneIconC">
                  <fa-icon [icon]="fontIcons.setIcons[draft.packs.c.keyruneCode]" [fixedWidth]="true"></fa-icon>
                </ng-container>
                <ng-template #keyruneIconC>
                  <i class="ss ss-fw ss-{{ draft.packs.c.keyruneCode.toLowerCase() }}"></i>
                </ng-template>
                {{ draft.packs.c.name }}
              </span>
              <span *ngIf="draft.packs.c === null">Select Set</span>
            </button>
            <span class="ms-2 text-secondary" *ngIf="draft.packs.c !== null">{{ draft.packs.c.boosterSize }} card booster</span>
          </div>

        </div>

      </ng-container>

      <ng-container *ngIf="draft.isRotisserie">

        <div class="row mt-3">

          <div class="col">
            <div class="form-label mb-1">
              <span>Sets</span>
              <span class="ms-2 text-secondary">{{ draft.sets.length }} sets selected.</span>
              <span class="ms-1 text-secondary" *ngIf="draft.sets.length > 0">({{ totalCardPoolSize }} cards will be used)</span>
              <fa-icon class="float-end text-red" [fixedWidth]="true"
                ngbTooltip="All available packs need to be configured to be able to continue" placement="top-right"
                container="body" [icon]="faExclamationCircle" *ngIf="!setsOK"></fa-icon>
            </div>
          </div>

        </div>

        <div class="row mt-3 mb-3">

          <div class="col d-flex align-items-center flex-wrap">

            <ng-container *ngFor="let set of draft.sets; let i = index; trackBy : trackBySet">
              <button class="btn btn-sm btn-secondary me-2" [ngbTooltip]="set.name + ' (' + set.totalSetSize + ')'" container="body" (click)="draft.sets.splice(i, 1)" [disabled]="draft.statusCode > 2">
                <ng-container *ngIf="set.customIcon; else keyruneIcon">
                  <fa-icon [icon]="fontIcons.setIcons[set.keyruneCode]" [fixedWidth]="true"></fa-icon>
                </ng-container>
                <ng-template #keyruneIcon>
                  <i class="ss ss-fw ss-{{ set.keyruneCode.toLowerCase() }}"></i>
                </ng-template>
              </button>
            </ng-container>

            <button class="btn btn-sm btn-outline-secondary" ngbTooltip="Add sets" container="body" (click)="showSetList(null)" [disabled]="draft.statusCode > 2">
              <fa-icon [icon]="faPlus" [fixedWidth]="true" class="text-larger"></fa-icon>
            </button>

          </div>

        </div>

      </ng-container>


      <!-- OTHER SETTINGS -->
      <ng-container *ngIf="draft.isBooster || draft.isRochester || draft.isRotisserie">

        <div class="row mt-3">
          <div class="col">
            <div class="form-label mb-1">Other settings</div>
          </div>
        </div>

        <div class="row">

          <!-- SETTINGS FOR ALL DRAFT TYPES -->
          <div class="col">

            <div class="row mt-3">
              <div class="col">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="isPublic" [(ngModel)]="draft.isPublic">
                  <label class="form-check-label" for="isPublic">Public</label>
                </div>
              </div>
            </div>

            <div class="row mt-3" *ngIf="draft.isRotisserie">
              <div class="col">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="isTimed"
                    (change)="draft.isLive ? null : draft.isTimed = false; draft.isWebcam = false" [(ngModel)]="draft.isLive">
                  <label class="form-check-label" for="isTimed">Live Draft</label>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="isWebcam" [(ngModel)]="draft.isWebcam"
                    [disabled]="!draft.isLive">
                  <label class="form-check-label" for="isWebcam">Webcam Interface</label>
                </div>
              </div>
            </div>

            <!-- <div class="row mt-3">
              <div class="col">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="isLive" [(ngModel)]="draft.isTimed"
                    [disabled]="!draft.isLive">
                  <label class="form-check-label" for="isLive">Timed Picks</label>
                </div>
              </div>
            </div> -->

            <ng-container *ngIf="draft.isRotisserie">

              <div class="row mt-3">
                <div class="col">
                  <div class="form-check form-switch">
                    <input type="checkbox" class="form-check-input" id="includeBasicLands" [(ngModel)]="draft.includeBasicLands">
                    <label class="form-check-label" for="includeBasicLands">Include Basic lands</label>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col">
                  <div class="form-check form-switch">
                    <input type="checkbox" class="form-check-input" id="includeAnteCards" [(ngModel)]="draft.includeAnteCards">
                    <label class="form-check-label" for="includeAnteCards">Include Ante cards</label>
                  </div>
                </div>
              </div>

            </ng-container>

          </div>

          <!-- ROTISSERIE SPECIFIC SETTINGS -->
          <div class="col" *ngIf="draft.isRotisserie">

            <div class="row mt-2">
              <div class="col d-flex align-items-center">
                <span class="flex-grow-1">Number of picks per player</span>
                <div class="d-inline-block h-100">
                  <input type="number" maxlength="2" min="30" max="60" class="form-control-sm form-control me-3" [(ngModel)]="draft.numberOfPicks" style="width: 4rem;">
                </div>
              </div>
            </div>
            <div class="row" *ngIf="!numberOfPicksOK">
              <div class="col text-secondary text-italic">
                Total number of cards must be greater than {{ draft.numberOfPicks * 8 }} to be able to fill a full 8-player draft.
              </div>
            </div>

          </div>

        </div>

      </ng-container>

    </div>

    <div class="right-panel flex-grow-0 p-3 d-flex flex-column align-items-center">

      <div class="form-label w-100 mb-1">
        Date
        <fa-icon class="float-end text-red" [fixedWidth]="true"
          ngbTooltip="You have to assign a date to be able to continue" placement="top-right" container="body"
          [icon]="faExclamationCircle" *ngIf="!dateOK"></fa-icon>
      </div>
      <ngb-datepicker class="w-100" [(ngModel)]="dateSelection.fromDate" [displayMonths]="1" [showWeekNumbers]="true"
        navigation="arrows" outsideDays="collapsed" (dateSelect)="onDateSelection($event)">
      </ngb-datepicker>

      <div class="form-label w-100 mt-3 mb-1">
        Time
        <fa-icon class="float-end text-red" [fixedWidth]="true"
          ngbTooltip="You have to assign a starting time to be able to continue" placement="top-right" container="body"
          [icon]="faExclamationCircle" *ngIf="!timeOK"></fa-icon>
      </div>
      <ngb-timepicker [(ngModel)]="timeSelection.fromTime" [meridian]="!auth.user.settings.clock24"
        (ngModelChange)="setDateAndTime()">
      </ngb-timepicker>

    </div>

    <!-- EDITING -->
    <div class="player-panel ms-3" *ngIf="isEditing" style="min-width: 20rem;">

      <div class="player-content-wrapper d-flex flex-column p-3 h-100">

        <div class="content-header d-flex align-items-center">
          <div class="flex-grow-0 badge text-bg-secondary rounded-pill me-2">{{ draft.playerDocIds.length }}</div>
          <div class="flex-grow-1 me-2">Attending players</div>
          <div class="flex-grow-0">
            <button class="btn btn-sm btn-blank" [disabled]="draft.playerDocIds.length === 8 || draft.statusCode > 2"
              ngbTooltip="Add players to draft" placement="top-right" container="body" (click)="openAddPlayersModal()">
              <fa-icon [fixedWidth]="true" [icon]="faUserPlus"></fa-icon>
            </button>
          </div>
        </div>

        <div class="flex-grow-1">

          <div class="player-list-wrapper mt-3">

            <div class="list-item" *ngFor="let player of draft.playerDocIds">

              <app-player-display-name [playerDocId]="player"></app-player-display-name>

              <span class="trash-icon float-end" placement="top" (click)="unattendPlayer(player)" container="body"
                ngbTooltip="Remove player from draft">
                <fa-icon [fixedWidth]="true" [icon]="faTrash"></fa-icon>
              </span>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

  <div class="modal-footer mt-2 mb-2">
    <button type="button" class="btn btn-main" (click)="createDraft()"
      [disabled]="!nameOK || !typeOK || !packsOK || !setsOK || !dateOK || !numberOfPicksOK">{{ isEditing ? 'Save' : 'Create' }}</button>
  </div>

</div>

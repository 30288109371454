<div class="mobile-main back">
</div>
<div class="mobile-main front">
  <div class="h1 title">Tolaria</div>
  <div class="info">
    <p>Unfortunately we are not supporting Tolaria on mobile device through the browser.</p>
    <p>Stay tuned for the native apps we are working on instead.</p>
    <ul class="app-list">
      <li>Tolaria: Event</li>
      <li>Tolaria: Collection</li>
    </ul>
  </div>
</div>

import { speakerSlashIcon } from './../../../../../assets/font-icons/speaker-slash'
import { faVideo, faVideoSlash, faVolumeOff, faVolumeMute, faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons'
import { MediaStreamsService } from './../../../../services/media-streams.service'
import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-media-controls',
  templateUrl: './media-controls.component.html',
  styleUrls: ['./media-controls.component.css']
})
export class MediaControlsComponent implements OnInit {

  @Input() orientation: 'horizontal' | 'vertical'

  icons = {
    faVideo,
    faVideoSlash,
    faCheckCircle,
    faCircle,
    faVolumeOff,
    faVolumeMute,
    faMicrophone,
    faMicrophoneSlash,
    facSpeakerSlashIcon: speakerSlashIcon
  }

  menuPlacements = [
    'left-top',
    'left-bottom',
    'right-top',
    'right-bottom',
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end',
  ]

  private timeoutRef: any

  public playerCamOn$ = this.streamService.videoEnabled$
  public playerMicOn$ = this.streamService.audioInputEnabled$
  public callingPlayers = false
  public stoppedPlayers = false
  public configDone = false

  public connectedMediaDevices$: Observable<MediaDeviceInfo[]> = this.streamService.connectedMediaDevices$
  public selectedVideoInputDeviceId$: Observable<string> = this.streamService.selectedVideoInputDeviceId$
  public selectedAudioInputDeviceId$: Observable<string> = this.streamService.selectedAudioInputDeviceId$
  public selectedAudioOutputDeviceId$: Observable<string> = this.streamService.selectedAudioOutputDeviceId$
  public audioOutputMuted$: Observable<boolean> = this.streamService.audioOutputMuted$

  constructor(
    public streamService: MediaStreamsService,
    private dropDownConfig: NgbDropdownConfig
  ) {
  }

  ngOnInit(): void {
    console.log(this.orientation, this.placement)
    this.dropDownConfig.placement = this.placement
    this.configDone = true
  }

  // Triggered when hovering media controls, to detect any new devices connected after page load
  public updateConnectedMediaDevices = () => {
    this.streamService.getConnectedMediaDevices()
  }
  public setVideoDevice = (deviceId: string) => {
    this.streamService.setVideoDevice(deviceId)
  }
  public setAudioInputDevice = (deviceId: string) => {
    this.streamService.setAudioInputDevice(deviceId)
  }
  public setAudioOutputDevice = (deviceId: string) => {
    this.streamService.setAudioOutputDevice(deviceId)
  }
  public toggleVideo() {
    this.streamService.toggleVideoEnabled()
  }
  public toggleAudioInput() {
    this.streamService.toggleAudioInputEnabled()
  }
  public toggleAudioOutput() {
    this.streamService.toggleAudioOutputMuted()
  }

  public openDropDown() {
    clearTimeout(this.timeoutRef)
  }

  public delayClose(element: any) {
    this.timeoutRef = setTimeout(() => {
      element.close()
    }, 50)
  }

  public get isHorizontal() {
    return this.orientation !== undefined && this.orientation === 'horizontal'
  }

  public get placement() {
    return this.isHorizontal ? 'bottom-right' : 'right-bottom'
  }

  public get dropDownMargin() {
    return this.isHorizontal ? 'ml-3' : 'mb-3'
  }

  public get dropDownMenuMargin() {
    return 'm-1'
    return this.isHorizontal ? 'mt-2' : 'ml-2'
  }

}

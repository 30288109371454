import { CardSearchService } from './../../../services/card-search.service';
import { Component, ComponentRef, Input, OnInit } from '@angular/core';
import { faLink, faTimes } from '@fortawesome/free-solid-svg-icons';
import Card from 'scryfall-client/dist/models/card';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.css']
})
export class CardDetailsComponent implements OnInit {
  @Input() cardData: Card; // strong type
  @Input() componentRef: ComponentRef<CardDetailsComponent>;

  public cardMeta: Card;

  public icons = {
    faLink,
    faTimes,
  };

  constructor(
    private cardSearch: CardSearchService
  ) { }

  ngOnInit(): void {
    this.cardMeta = this.cardData;
  }

  destroySelf() {
    this.componentRef.destroy();
  }

  updateCardId(id: string) {
    // fetch new card data
    this.cardSearch.getCardById(id).then(card => {
      // update card meta with new data
      if (card !== null) {
        this.cardMeta = card;
        console.log(card);
      }
    });
  }

  searchArtist(artist: string) {
    let searchString = '';
    const tmpText = artist.split(' ');
    tmpText.forEach(val => {
      if (searchString === '') {
        searchString += '(';
      }
      else {
        searchString += ' ';
      }
      searchString += 'artist:' + val;
    });
    searchString += ')';
    this.cardSearch.searchString = searchString;
    this.cardSearch.searchCards();
  }

  searchSet(set: string) {
    this.cardSearch.searchString = 'set:' + set;
    this.cardSearch.searchCards();
  }

  searchType(type: string) {
    this.cardSearch.searchString = 'type:' + type;
    this.cardSearch.searchCards();
  }

}

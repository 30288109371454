<div class="spinner-wrapper d-flex flex-row align-items-center">
  <div class="lds-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="ms-2 text-secondary" [innerHtml]="spinnerText" *ngIf="showSpinnerText"></div>
</div>

<div class="d-flex jusitfy-content-center background-blur" [ngClass]="{ 'p-5' : !isMobile }">

  <div class="{{ isMobile ? 'container-fluid' : 'container rounded shadow' }} bg-main text-normal d-flex flex-column gap-3 p-0">

    <ng-container *ngIf="loaded$ | async; else loading">

      <div class="deck-name d-flex flex-column p-3">
        <div class="text-larger">{{ deck.name }}</div>
        <div class="text-small text-secondary">by <b>{{ deck.playerName }}</b></div>
      </div>

      <div class="d-flex gap-3 p-3" [ngClass]="{ 'flex-column' : isMobile }">

        <div class="deck-wrapper d-flex gap-3 pe-3 flex-grow-1 flex-column">
          <ng-container *ngIf="deck.main.length > 0">
            <div class="main-deck flex-grow-1">
              <div class="h3">Main<span class="ms-3" [innerHtml]="deck.main.length"></span></div>
              <div class="sections d-flex gap-4 flex-wrap" [ngClass]="{ 'flex-column' : isMobile }">
                <ng-container *ngFor="let typeSection of deck.main | deckListParser : false : false">
                  <div class="card-section  flex-grow-1">
                    <div class="section-text text-large text-secondary">{{ typeSection.text }}</div>
                    <div class="d-flex" *ngFor="let card of typeSection.cards"
                      (mouseenter)="setCardHover(card)"
                      (mouseleave)="setCardHover(null)">
                      <div class="flex-grow-0" [style.width.rem]="1.5" [innerHtml]="card.qty"></div>
                      <div class="flex-grow-1" [innerHtml]="card.name"></div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="deck.sideboard.length > 0">
            <div class="sideboard">
              <div class="h3">Sideboard<span class="ms-3" [innerHtml]="deck.sideboard.length"></span></div>
              <ng-container *ngFor="let typeSection of deck.sideboard | deckListParser : true : false">
                <ng-container *ngFor="let card of typeSection.cards">
                  <div class="d-flex"
                    (mouseenter)="setCardHover(card)"
                    (mouseleave)="setCardHover(null)">
                    <div class="flex-grow-0" [style.width.rem]="1.5" [innerHtml]="card.qty"></div>
                    <div class="flex-grow-1" [innerHtml]="card.name"></div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <div class="card-preview ms-4">
          <div class="image-wrapper shadow">
            <img [src]="previewCardUrl">
          </div>
        </div>

      </div>

      <div class="mt-5 m-3 rounded-3 shadow overflow-hidden" *ngIf="showImage">
        <img [src]="deck.deckPhotoUrl" [style.width.%]="100">
      </div>

    </ng-container>
  </div>


</div>


<ng-template #loading>
  <div class="h-100 w-100 d-flex align-items-center justify-content-center p-5 flex-column gap-3">
    <div class="spinner-border mb-3" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <span class="text-large">Getting deck list, please wait...</span>
  </div>
</ng-template>

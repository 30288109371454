<div class="card-header d-flex flex-align-center">
  <div class="flex-grow-1">Send Event Announcement</div>
  <div class="btn-close text-secondary" aria-label="Close" (click)="activeModal.close()">
    <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
  </div>
</div>
<div class="card-body no-hover p-3 d-flex flex-row">
  <div class="w-50">
    <ckeditor #editor [config]="ckeConfig" [(ngModel)]="announcementText" id="announcementText" name="announcementText"
      type="divarea">
    </ckeditor>
    <div class="my-3 border-bottom border-secondary"></div>
    <div class="d-flex flex-row">
      <div class="config-wrapper d-flex flex-column">
        <div class="d-flex flex-row align-items-center mb-3">
          <div class="flex-grow-1 me-4" [ngClass]="{ 'text-secondary' : !sendConfig.tolaria }">Tolaria Event Announcement</div>
          <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input mt-1" id="tolariaMessage" [(ngModel)]="sendConfig.tolaria">
            <label class="form-check-label" for="tolariaMessage"> </label>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center mb-3">
          <div class="flex-grow-1 me-4" [ngClass]="{ 'text-secondary' : !sendConfig.email }">Email Announcement</div>
          <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input mt-1" id="email" [(ngModel)]="sendConfig.email"
              (change)="toggleEmail()">
            <label class="form-check-label" for="email"> </label>
          </div>
        </div>
        <div class="ps-2 d-flex flex-row align-items-center mb-3">
          <div class="flex-grow-1 me-4" [ngClass]="{ 'text-secondary' : !sendConfig.recipients.attendees || !sendConfig.email }">
            Attending players</div>
          <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input mt-1" id="attendees"
              [(ngModel)]="sendConfig.recipients.attendees" [disabled]="!sendConfig.email">
            <label class="form-check-label" for="attendees"> </label>
          </div>
        </div>
        <div class="ps-2 d-flex flex-row align-items-center mb-3">
          <div class="flex-grow-1 me-4" [ngClass]="{ 'text-secondary' : !sendConfig.recipients.invitees || !sendConfig.email }">
            Invited players</div>
          <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input mt-1" id="invitees"
              [(ngModel)]="sendConfig.recipients.invitees" [disabled]="!sendConfig.email">
            <label class="form-check-label" for="invitees"> </label>
          </div>
        </div>
      </div>
      <div class="flex-grow-1"></div>
    </div>
  </div>
  <div class="flex-grow-1 ps-5">
    <div class="h6">Announcement Preview</div>
    <div class="event-announcement">
      <div class="icon">
        <fa-icon [fixedWidth]="true" [icon]="faBullhorn"></fa-icon>
      </div>
      <div class="title">
        <span>ANNOUNCEMENT</span>
      </div>
      <div class="content-wrapper">
        <div [innerHTML]="announcementText"></div>
      </div>
      <div class="message-timestamp">
        <app-timestamp-clock [timestamp]="timestampNow" displayType="date-time" [seconds]="true"></app-timestamp-clock>
      </div>
    </div>
  </div>
</div>
<div class="card-footer d-flex justify-content-end">
  <button class="btn btn-sm btn-main" [disabled]="disableSend" (click)="sendAnnouncement()">
    <fa-icon [fixedWidth]="true" [icon]="faPaperPlane" class="me-2"></fa-icon>Send
  </button>
</div>

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from 'src/app/services';

@Component({
  selector: 'app-event-player-check-in',
  templateUrl: './event-player-check-in.component.html',
  styleUrls: ['./event-player-check-in.component.css']
})
export class EventPlayerCheckInComponent {
  @Input() eventDocId: string;

  constructor(private eventService: EventService, private activeModal: NgbActiveModal) { }

  playerCheckIn(): void {
    this.eventService.checkMeIn(this.eventDocId);
    this.activeModal.close();
  }

}

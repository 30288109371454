<ng-container *ngIf="initDone$ | async">

    <div class="modal-header">Create new Pub</div>

    <div class="modal-body">

        <div class="form-group">
            <label for="pubName">Pub name</label>
            <input type="text" class="form-control" id="pubName" placeholder="Enter an awesome name of your pub"
                [(ngModel)]="pub.name">
        </div>

        <app-image-cropper-uploader (base64)="croppedImage($event)"></app-image-cropper-uploader>


        <div class="form-check form-switch mb-3">
            <input type="checkbox" class="form-check-input" id="isExpansion" [(ngModel)]="pub.isPublic">
            <label class="form-check-label" for="isExpansion">Public</label>
        </div>

        <ng-container *ngIf="!pub.isPublic">

            <app-tolaria-player-search (playerDocId)="invitePlayer($event)"></app-tolaria-player-search>

            <div class="h4 mt-3">Invited Players</div>
            <div class="flex-grow-1 player-list-wrapper ps-2 pe-2 pt-2 pb-3">

                <div class="player-item m-2 d-inline-block" *ngFor="let playerDocId of pub.invitedPlayerDocIds">
                    <app-player-avatar [playerDocId]="playerDocId" position="left"></app-player-avatar>
                    <div class="actions-wrapper">
                        <div class="btn btn-sm btn-danger w-100" (click)="removePlayer(playerDocId)">Delete</div>
                    </div>
                </div>

            </div>


        </ng-container>

    </div>

    <div class="modal-footer d-flex align-items-center justify-content-end">
        <button class="btn btn-sm text-secondary" (click)="cancel()">Cancel</button>
        <button class="btn btn-sm btn-main" [disabled]="!canCreate" (click)="create()">Create</button>
    </div>


</ng-container>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minSec'
})
export class MinSecPipe implements PipeTransform {

  transform(value: number): string {
    const minutes: number = Math.floor(Math.abs(value) / 60);
    const seconds: number = Math.abs(value) - ( minutes * 60 );
    return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
  }

}

<ng-container *ngIf="displayAs === 'modal'; else content">

  <div class="modal-header d-flex align-items-center justify-content-center">
    <span class="text-large">{{ title }}</span>
  </div>

  <div class="modal-body">
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </div>

  <div class="modal-footer d-flex align-items-center justify-content-end">

    <div class="form-group" *ngIf="!isMobile">
      <select class="form-select form-select-sm" [(ngModel)]="displayMonths">
          <option [value]="1">1 Month</option>
          <option [value]="2">2 Months</option>
          <option [value]="3">3 Months</option>
          <option [value]="4">4 Months</option>
        </select>
    </div>

    <div class="flex-grow-1 px-3"></div>
    
    <button class="btn btn-sm btn-blank"
      (click)="onDismiss()">
      Cancel
    </button>
    
    <button class="btn btn-sm btn-outline-main"
      (click)="onClose()">
      Use selected
    </button>

  </div>


</ng-container>

<ng-template #content>
  <div class="d-flex flex-column align-items-center">
  
    <ngb-datepicker #dp
      [(ngModel)]="model"
      (navigate)="date = $event.next"
      [showWeekNumbers]="true"
      (dateSelect)="onDateSelected()"
      [outsideDays]="outsideDays"
      [displayMonths]="displayMonths"
    ></ngb-datepicker>
    
    <ngb-timepicker #tp
      [(ngModel)]="time"
      [minuteStep]="15"
      (ngModelChange)="onDateSelected()"
    ></ngb-timepicker>
      
  </div>
</ng-template>
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faFacebook, faFacebookMessenger, faGithub, faInstagram, faSkype, faSlack, faTwitch, faTwitter, faVimeo, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { GlobalsService } from 'src/app/services/globals.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component } from '@angular/core';
import { IPlayerHandle } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-add-player-handle',
  templateUrl: './add-player-handle.component.html',
  styleUrls: ['./add-player-handle.component.scss']
})
export class AddPlayerHandleComponent {

  // don't forget to update the configService if handles are added!
  public handleIcons = [
    {
      name: 'Email',
      handle: 'email',
      icon: faAt,
      placeholder: 'mailto:youremail@domain.ending',
    },
    {
      name: 'Facebook',
      handle: 'facebook',
      icon: faFacebook,
      placeholder: 'https://www.facebook.com/YOUR-PROFILE-NAME',
    },
    {
      name: 'Facebook Messenger',
      handle: 'messenger',
      icon: faFacebookMessenger,
      placeholder: 'https://m.me/YOUR-PROFILE-NAME',
    },
    {
      name: 'Skype',
      handle: 'skype',
      icon: faSkype,
      placeholder: 'skype:YOUR-SKYPE-NAME?chat',
    },
    {
      name: 'Discord',
      handle: 'discord',
      icon: faDiscord,
      placeholder: 'https://discordapp.com/users/USER-PROFILE-NUMBER',
    },
    {
      name: 'Twitter',
      handle: 'twitter',
      icon: faTwitter,
      placeholder: 'http://twitter.com/USERNAME',
    },
    {
      name: 'Twitch',
      handle: 'twitch',
      icon: faTwitch,
      placeholder: 'https://twitch.tv/YOUR-USERNAME',
    },
    {
      name: 'Instagram',
      handle: 'instagram',
      icon: faInstagram,
      placeholder: 'https://instagram.com/YOUR-USERNAME',
    },
    {
      name: 'YouTube',
      handle: 'youtube',
      icon: faYoutube,
      placeholder: 'https://youtube.com/user/YOUR-USERNAME',
    },
    {
      name: 'Vimeo',
      handle: 'vimeo',
      icon: faVimeo,
      placeholder: 'https://vimeo.com/YOUR-ACCOUNT-NAME',
    },
    {
      name: 'WhatsApp',
      handle: 'whatsapp',
      icon: faWhatsapp,
      placeholder: 'https://wa.me/YOUR-NUMBER',
    },
    {
      name: 'GitHub',
      handle: 'github',
      icon: faGithub,
      placeholder: 'https://github.com/YOUR-USERNAME',
    },
  ];

  public handle: IPlayerHandle = {
    url: '',
    handle: '',
    platform: '',
    placeholder: '',
  }

  constructor(
    private activeModal: NgbActiveModal,
    public globals: GlobalsService
  ) { }

  close(): void {
    this.activeModal.dismiss();
  }

  addHandle(): void {
    this.activeModal.close(this.handle);
  }

  public get formInvalid() {
    return this.handle.handle.length === 0 || this.handle.platform.length === 0;
  }

}

<ng-container *ngIf="matchDoc$ | async as matchDoc; else emptyMatchWrapper">

  <div class="bracket-match-wrapper d-flex flex-row align-items-center" [ngClass]="{ 'path-to-victory' : pathToHereSelected }">


    <div class="bracket-match {{ matchInfo.matchDocId }}"
    [ngClass]="{ 'bye-match' : matchInfo.byeMatch, 'reported' : matchDoc.reported }">


      <div class="player-wrapper top player1" [ngClass]="{ 'path-to-here' : pathToHerePlayerSlot === 'player1' }">

        <div class="seed" [ngClass]="{ 'winner' : matchDoc.player1.isWinner }">
          {{ matchDoc.player1.seed !== null ? matchDoc.player1.seed : '' }}
        </div>

        <ng-container *ngIf="player1$ | async as player">
          <div class="deck" *ngIf="isOrganizer && deckList || isOrganizer && deckPhoto || deckInfoPublic">
            <button class="btn btn-sm btn-blank" (click)="$event.stopPropagation(); openDeck('player1', 'list')" *ngIf="deckList" [disabled]="!player.deckSubmission.deckList">
              <fa-icon [icon]="icons.deckList"></fa-icon>
            </button>
            <button class="btn btn-sm btn-blank" (click)="$event.stopPropagation(); openDeck('player1', 'photo')" *ngIf="deckPhoto" [disabled]="!player.deckSubmission.deckPhoto">
              <fa-icon [icon]="icons.deckPhoto"></fa-icon>
            </button>
          </div>
        </ng-container>

        <div class="player {{ matchDoc.player1.displayName !== '' ? '' : 'text-italic' }}">

          <span *ngIf="matchDoc.player1.playerUid.substr(0, 6) === 'temp__'; else playerDisplayName1">{{
            matchDoc.player1.displayName }}</span>

          <ng-template #playerDisplayName1>
            <app-player-display-name [playerUid]="matchDoc.player1.playerUid" [name]="matchDoc.player1.displayName"
              *ngIf="matchDoc.player1.displayName !== ''; else waitingPlayer1" position="left">
            </app-player-display-name>
            <ng-template #waitingPlayer1>Waiting for result</ng-template>
          </ng-template>

        </div>

        <div class="result">{{ matchDoc.player1.displayName !== '' ? matchDoc.player1.wins : '' }}</div>

      </div>


      <div class="player-wrapper bottom player2" [ngClass]="{ 'path-to-here' : pathToHerePlayerSlot === 'player2' }">

        <div class="seed" [ngClass]="{ 'winner' : matchDoc.player2.isWinner }">
          {{ matchDoc.player2.seed !== null ? matchDoc.player2.seed : '' }}
        </div>

        <ng-container *ngIf="player2$ | async as player">
          <div class="deck" *ngIf="isOrganizer && deckList || isOrganizer && deckPhoto || deckInfoPublic">
            <button class="btn btn-sm btn-blank" (click)="$event.stopPropagation(); openDeck('player2', 'list')" *ngIf="deckList" [disabled]="!player.deckSubmission.deckList">
              <fa-icon [icon]="icons.deckList"></fa-icon>
            </button>
            <button class="btn btn-sm btn-blank" (click)="$event.stopPropagation(); openDeck('player2', 'photo')" *ngIf="deckPhoto" [disabled]="!player.deckSubmission.deckPhoto">
              <fa-icon [icon]="icons.deckPhoto"></fa-icon>
            </button>
          </div>
        </ng-container>

        <div class="player {{ matchDoc.player2.displayName !== '' ? '' : 'text-italic' }}">

          <span *ngIf="matchDoc.player2.playerUid.substr(0, 6) === 'temp__'; else playerDisplayName2">{{
            matchDoc.player2.displayName }}</span>

          <ng-template #playerDisplayName2>
            <app-player-display-name [playerUid]="matchDoc.player2.playerUid" [name]="matchDoc.player2.displayName"
              *ngIf="matchDoc.player2.displayName !== ''; else waitingPlayer2" position="left">
            </app-player-display-name>
            <ng-template #waitingPlayer2>Waiting for result</ng-template>
          </ng-template>

        </div>

        <div class="result">{{ matchDoc.player2.displayName !== '' ? matchDoc.player2.wins : '' }}</div>

      </div>

    </div>

    <div class="actions me-5" *ngIf="isOrganizer && !matchInfo.byeMatch; else playerReport">
      <div ngbDropdown class="d-inline-block" container="body">
        <button class="btn btn-sm btn-blank" id="actionMenu" ngbDropdownToggle>
          <fa-icon [fixedWidth]="true" [icon]="icons.menu"></fa-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="actionMenu">
          <button ngbDropdownItem (click)="openReportSlip(matchDoc)">Report</button>
          <div class="my-1 border-bottom border-secondary"></div>
          <button ngbDropdownItem (click)="openFeatureMatchConfig(matchDoc.docId)">Feature Match Config</button>
          <button ngbDropdownItem (click)="copyMatchId(matchDoc.docId)">Copy id</button>
        </div>
      </div>
    </div>

    <ng-template #playerReport>
      <div class="actions me-2">
        <div class="btn btn-sm btn-outline-secondary" (click)="openReportSlip(matchDoc)">REPORT</div>
      </div>
    </ng-template>


  </div>

</ng-container>

<ng-template #emptyMatchWrapper>

  <div class="bracket-match-wrapper d-flex flex-row align-items-center">
    <div class="bracket-match bye-match">

    </div>
  </div>

</ng-template>

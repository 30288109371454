import { Component, Input } from '@angular/core';
import { faCheckCircle, faExclamationTriangle, faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-message-toast',
  templateUrl: './message-toast.component.html',
  styleUrls: ['./message-toast.component.css']
})
export class MessageToastComponent {
  @Input() message: string;
  @Input() type: string;
  @Input() fade: boolean;

  faCheckCircle = faCheckCircle;
  faExclamationTriangle = faExclamationTriangle;
  faExclamationCircle = faExclamationCircle;
  faInfoCircle = faInfoCircle;

  constructor() { }

}

import * as firestore from 'firebase/firestore'
import { faTimes, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaStreamsService } from 'src/app/services/media-streams.service';
import { Observable, Subject, filter, finalize, takeUntil } from 'rxjs';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-device-settings',
  templateUrl: './device-settings.component.html',
  styleUrls: ['./device-settings.component.css']
})
export class DeviceSettingsComponent implements OnInit {
  @ViewChild('testVideo') private testVideo: ElementRef
  @ViewChild('testSound') private testSound: ElementRef

  public faTimes = faTimes
  public arrowIcon = faChevronRight

  public settingsContent: ContentType  = 'devices'

  public connectedMediaDevices$: Observable<MediaDeviceInfo[]> = this.streamService.connectedMediaDevices$
  public selectedVideoInputDeviceId$: Observable<string> = this.streamService.selectedVideoInputDeviceId$
  public selectedAudioInputDeviceId$: Observable<string> = this.streamService.selectedAudioInputDeviceId$
  public selectedAudioOutputDeviceId$: Observable<string> = this.streamService.selectedAudioOutputDeviceId$
  public localStream$: Observable<MediaStream> = this.streamService.localStream$.pipe(
    filter((stream: MediaStream) => stream !== undefined)
  )

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly playerNames: PlayerNameService,
    public readonly streamService: MediaStreamsService,
    private readonly firestorage: AngularFireStorage,
    private readonly firestore: AngularFirestore,
  ) {

  }

  public close(): void {
    this.modal.close()
  }

  ngOnInit() {
    console.log('[DeviceSettingsComponent] -> Init')
  }


  public audioOutputSelect: any = this.streamService.selectedAudioOutputDeviceId$.getValue()
  public onChangeAudioOutput(): void {
    console.log(this.audioOutputSelect)
    this.streamService.setAudioOutputDevice(this.audioOutputSelect)
  }

  public audioInputSelect: any = this.streamService.selectedAudioInputDeviceId$.getValue()
  public onChangeAudioInput(): void {
    console.log(this.audioInputSelect)
    this.streamService.setAudioInputDevice(this.audioInputSelect)
  }

  public videoInputSelect: any = this.streamService.selectedVideoInputDeviceId$.getValue()
  public onChangeVideoInput(): void {
    console.log(this.videoInputSelect)
    this.streamService.setVideoDevice(this.videoInputSelect)
  }


  public testAudio() {
    this.testSound.nativeElement.setSinkId(this.audioOutputSelect)
    this.testSound.nativeElement.play()
  }

  public imageToUpload: string = null
  public isUploading: boolean = false
  public async onImageCropped(base64: string) {
    console.log('[DeviceSettingsComponent.onImageCropped] --> emitted', base64)
    this.imageToUpload = base64
  }
  public get readyToUpload(): boolean {
    return this.imageToUpload !== null
  }

  public async onImageUpload() {

    this.isUploading = true

    const __imageToDataUri = (base64: string, width: number, height: number) => {
      // Create and initialize two canvas
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const canvasCopy = document.createElement('canvas')
      const copyContext = canvasCopy.getContext('2d')

      // Create original image
      const img = new Image()
      img.src = base64

      // Draw original image in second canvas
      canvasCopy.width = img.width
      canvasCopy.height = img.height
      copyContext.drawImage(img, 0, 0)

      // Copy and resize second canvas to first canvas
      canvas.width = width
      canvas.height = height
      ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height)

      return canvas.toDataURL()
    }

    const filePath = 'user-images/' + this.playerNames.currentPlayersMini.id + '/match-room-background.png'
    console.log('create filePath', filePath)
    const ref = this.firestorage.ref(filePath)
    console.log('create ref', ref)
    const file = __imageToDataUri(this.imageToUpload, 1600, 900)

    console.log('create file', file)
    const task = ref.putString(file, 'data_url')
    console.log('crate task', task)
    const done$: Subject<boolean> = new Subject<boolean>()
    task
      .snapshotChanges()
      .pipe(
        takeUntil(done$),
        finalize(() => {
          console.log('task finalized, getting download url')
          const downloadUrl = ref.getDownloadURL()
          // store the download url as the avatar link for both user and player doc.
          downloadUrl.subscribe(async (url) => {
            console.log('downlaod url emitted', url)

            this.firestore.collection('players').doc(this.playerNames.currentPlayersMini.id)
              .update({
                matchRoomBackgroundUrl: url
              })
              .then(() => console.log('image url stored on player document'))
              .catch((e) => console.log(e))

            setTimeout(() => {
              done$.next(true)
              this.isUploading = false
              this.imageToUpload = null
            }, 1500)

          })
        })
      ).subscribe()

  }

  public clearImage(): void {
    this.firestore.collection('players').doc(this.playerNames.currentPlayersMini.id)
      .update({
        matchRoomBackgroundUrl: firestore.deleteField()
      })
      .then(() => console.log('image url stored on player document'))
      .catch((e) => console.log(e))
  }

  public get isHero(): boolean {
    return this.playerNames.currentPlayerIsHero
  }

  public get hasBackground(): boolean {
    return this.playerNames.currentPlayerHasMatchRoomBackground
  }

  public get backgroundUrl(): string {
    return this.playerNames.currentPlayersMatchRoomBackgroundUrl
  }

  public menuTracker(item: INavLink, index: number): string {
    return item.content
  }

  public get menu(): INavLink[] {
    const menu: INavLink[] = [
      {
        label: 'Video & Audio',
        icon: this.arrowIcon,
        active: this.settingsContent === 'devices',
        content: 'devices',
        visible: true,
      },
      {
        label: 'Layout',
        icon: this.arrowIcon,
        active: this.settingsContent === 'layout',
        content: 'layout',
        visible: false,
      },
      {
        label: 'Appearance',
        icon: this.arrowIcon,
        active: this.settingsContent === 'appearance',
        content: 'appearance',
        visible: false,
      },
      {
        label: 'Background',
        icon: this.arrowIcon,
        active: this.settingsContent === 'background',
        content: 'background',
        visible: this.isHero,
      }
    ]

    return menu.filter(i => i.visible)
  }

}

export interface INavLink {
  label: string
  icon: IconDefinition
  active: boolean
  visible: boolean
  content: ContentType
}

type ContentType = 'devices' | 'background' | 'appearance' | 'layout'

export interface IDraft {
  docId: string;
  createdByUid: string;
  createdByPlayerDocId: string;
  createdTimestamp: number;
  statusCode: number;
  statusText: string;
  name: string;
  datestamp: number;
  playerDocIds: string[];
  seatedPlayers: string[];
  playerCap: number;
  isPublic: boolean;
  isTimed: boolean;
  isLive: boolean; // used for live draft (players taking turns or waiting)
  isWebcam: boolean; // used for webcam interface
  isRochester: boolean;
  isBooster: boolean;
  isRotisserie: boolean;
  includeBasicLands: boolean;
  includeAnteCards: boolean;
  sets: IDraftSet[]; // used for rotisserie to determine which sets to include
  numberOfPicks: number; // used for rotisserie to determine number of picks per player
  packs: {
    a: IDraftSet;
    b: IDraftSet;
    c: IDraftSet;
  };
  activePack: string;
  activeBoosterDocId: string;
  activePlayerDocId: string;
}
export interface IDraftSet {
  docId: string;
  name: string;
  cardModel: string;
  code: string;
  keyruneCode: string;
  customIcon: boolean;
  releaseDate: string;
  totalSetSize: number;
  numberOfAnteCards: number;
  numberOfBasicLands: number;
  boosterSize: number;
  isCore: boolean;
  isExpansion: boolean;
  isCustom: boolean;
  isCube: boolean;
  storageUrl: string;
  downloadUrl: string;
  selected?: boolean;
  uniqueBoosters: boolean;
}

export interface IBooster {
  docId: string;
  pack: string;
  setCode: string;
  keyruneCode: string;
  openingPackPlayerDocId: string;
  activePlayerDocId: string;
  nextPlayerDocId: string;
  latestPick: number;
  latestPickPlayerDocId: string;
  cards: Array<IBoosterCard>;
}
export interface IBoosterCard {
  cardId: string;
  colors: string[];
  convertedManaCost: number;
  imageUrl: string;
  isPicked: boolean;
  keyruneCode: string;
  manaCost: string;
  multiverseid?: string;
  name: string;
  pickedByPlayerDocId: string;
  power: string;
  rarity: string,
  scryfallCardId?: string;
  text: string;
  toughness: string;
  type: string;
  typeSymbol: string;
  types: string[];
}

export interface IPick {
  boosterDocId: string;
  card: IBoosterCard;
  docId: string;
  draftDocId: string;
  pack: string;
  pickedTimestamp: number;
  playerDocId: string;
}

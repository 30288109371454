import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { IEventTemplate } from '../services/event-listing.service';

@Component({
  selector: 'app-tournament-description-modal',
  template: `
  <ng-container *ngIf="data$ | async as data">
    <div class="modal-header text-large">
      {{ data.details.name }}
    </div>
    <div class="modal-body">
      <div [innerHTML]="data.details.description"></div>
    </div>
    <div class="modal-footer d-flex align-items-center justify-content-end">
      <button class="btn btn-blank" (click)="close()">Close</button>
    </div>
  </ng-container>
  `,
  styles: [``],
})
export class TournamentDescriptionModalComponent {

  @Input() set tournament(data: IEventTemplate) {
    this.data$.next(data)
  }

  public data$: BehaviorSubject<IEventTemplate> = new BehaviorSubject<IEventTemplate>(null)

  constructor(private readonly activeModal: NgbActiveModal) { }

  public close(): void {
    this.activeModal.close()
  }

}

import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { CardSearchService } from './card-search.service';
import { GlobalsService, AuthService, EventService } from 'src/app/services';
import { ToastService } from './toast.service';
import { take, map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces';
import { v4 as uuidv4 } from 'node_modules/uuid';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { ImageUris } from 'scryfall-client/dist/types/api/constants';
import * as firestore from 'firebase/firestore'
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DecksService {

  private decksColRef: AngularFirestoreCollection<IDeckList>;
  private deckFoldersColRef: AngularFirestoreCollection<IDeckFolder>;
  public decks$: Observable<IDeckList[]>;
  public deckMetas$: BehaviorSubject<IDeckMeta[]> = new BehaviorSubject<IDeckMeta[]>(null);
  public deckFolders$: Observable<IDeckFolder[]>;
  public deckFolderList$: Observable<IDeckFolderMeta[]>;
  public deckBuilderMainView: string = 'deck-visual-editor';

  constructor(
    private afs: AngularFirestore,
    private toastService: ToastService,
    private globals: GlobalsService,
    private cardSearch: CardSearchService,
    private auth: AuthService,
    private storage: AngularFireStorage,
    private router: Router,
    private eventService: EventService,
  ) {

    // fetch decks
    this.decksColRef = this.afs.collection<IDeckList>('decks', ref => ref
      .where('playerDocId', '==', this.auth.user.playerId)
      .orderBy('timestampCreated', 'desc'));
    this.decks$ = this.decksColRef.valueChanges();

    // fetch deck folders
    this.deckFoldersColRef = this.afs.collection<IDeckFolder>('deckFolders', ref => ref
      .where('playerDocId', '==', this.auth.user.playerId));
    this.deckFolders$ = this.deckFoldersColRef.valueChanges();

    // subscribe to decks to be able to create decks meta for the DeckLinker
    this.initDeckMetaObserver();

    this.deckFolderList$ = combineLatest([this.deckFolders$, this.decks$]).pipe(
      map(([deckFolders, decks]) => {
        const deckFolderList: Array<IDeckFolderMeta> = [];
        deckFolders.forEach((deckFolder) => {
          const deckFolderMeta: IDeckFolderMeta = {
            name: deckFolder.name,
            docId: deckFolder.docId,
            decks: decks.filter(d => d.deckFolderDocId === deckFolder.docId).length
          };
          deckFolderList.push(deckFolderMeta);
        });
        const myDecksfolder: IDeckFolderMeta = {
          docId: null,
          name: 'Uncategorized',
          decks: decks.filter(d => d.deckFolderDocId === undefined || d.deckFolderDocId === null).length
        };
        deckFolderList.unshift(myDecksfolder);

        return deckFolderList;
      })
    );
  }

  private initDeckMetaObserver(): void {
    this.decks$.subscribe(async (decks) => {

      const deckMetas: IDeckMeta[] = [];
      for await (const deck of decks) {
        const tempMeta: IDeckMeta = {
          name: deck.name,
          docId: deck.docId,
          deckFolderDocId: deck.deckFolderDocId ? deck.deckFolderDocId : '',
          deckFolderName: deck.folderName ? deck.folderName : '',
          versions: deck.versions ? await this.getDeckVersionsMeta(deck.docId) : [],
          filterString: deck.name + ' ' + this.getFilterString(deck),
          deckListValid: deck.main.length >= 60 && deck.sideboard.length <= 15,
          deckPhotoValid: deck.deckPhotoUrl !== null && deck.deckPhotoUrl !== '',
        }
        deckMetas.push(tempMeta);
      }

      this.deckMetas$.next(deckMetas);

    });
  }

  public getPublicDecks(numberOfDecks: number): Observable<IDeckList[]> {
    return this.afs.collection<IDeckList>('decks', ref => ref
      .where('isPrivate', '==', false)
      .where('eventDocIds', '==', [])
      .orderBy('timestampCreated', 'desc')
      .limit(numberOfDecks)
    ).valueChanges();
  }

  public addRating(increment: boolean, deckDocId: string): void {
    let update: any = {
      ratedByPlayerDocIds: firestore.arrayUnion(this.auth.user.playerId)
    };
    if (increment) {
      update.rating = firestore.increment(1)
    }
    this.afs.collection('decks').doc(deckDocId).update(update);
  }

  private get isDeckBuilder(): boolean {
    return this.router.url.split('/')[1] === 'decks' && this.router.url.split('/')[2] === 'edit';
  }
  private get deckDocId(): string {
    return this.router.url.split('/')[1] === 'decks' && this.router.url.split('/')[2] === 'edit' ? this.router.url.split('/')[3] : null;
  }
  private get versionDocId(): string {
    return this.router.url.split('/')[5] === 'origin' ? null : this.router.url.split('/')[5];
  }
  private get isVersion(): boolean {
    return this.router.url.split('/')[5] !== 'origin';
  }
  private get deckDocumentRef(): AngularFirestoreDocument<any> {
    // get versionDocId from the route params
    const isDeckbiulder = this.isDeckBuilder;
    const deckDocId = isDeckbiulder ? this.deckDocId : null;
    const versionDocId = this.versionDocId;

    if (isDeckbiulder && versionDocId !== null) {
      return this.afs.collection('decks').doc(deckDocId).collection('versions').doc<IDeckListVersion>(versionDocId);
    }
    else if (deckDocId !== null) {
      return this.afs.collection('decks').doc<IDeckList>(deckDocId);
    }
    else {
      return null;
    }
  }
  private getFilterString(deck: IDeckList): string {
    const cardNames: string[] = [];
    // main deck
    deck.main.forEach(c => !cardNames.includes(c.name.toLowerCase()) ? cardNames.push(c.name.toLowerCase()) : null);
    // sideboard
    deck.sideboard.forEach(c => !cardNames.includes(c.name.toLowerCase()) ? cardNames.push(c.name.toLowerCase()) : null);

    return cardNames.join(' ');
  }
  public updateAllDecksMissingTimestamp(): void {
    // update createdTimestamp on ALL DECKS!
    const decksWithoutTimestamp = this.afs.collection<IDeckList>('decks');
    decksWithoutTimestamp.get().subscribe(snap => {
      const docs: IDeckList[] = [];
      snap.docs.forEach(doc => {
        docs.push(doc.data() as IDeckList);
      });
      console.log(docs.filter(d => !d.timestampCreated).length);
      snap.docs.forEach(doc => {
        if (!doc.data().timestampCreated) {
          doc.ref.update({
            timestampCreated: 1577833200
          });
        }
      })
    });
  }
  public saveDeckList(deck: IDeckList): Promise<IPromiseResponse> {

    return new Promise((resolve, reject) => {

      // set the document reference to save
      let documentRef = this.deckDocumentRef;
      if (documentRef === null) {
        documentRef = this.afs.collection('decks').doc(deck.docId);
      }

      const parsedDeck = JSON.parse(JSON.stringify(deck)) as IDeckList;
      // add deck colors
      if (parsedDeck.main && parsedDeck.main.length > 0) {
        if (parsedDeck.main.filter(c => c?.colors && c.colors.includes('W')).length > 0 && !parsedDeck.colors.includes(MagicColor.WHITE)) {
          parsedDeck.colors.push(MagicColor.WHITE);
        }
        if (parsedDeck.main.filter(c => c?.colors && c.colors.includes('U')).length > 0 && !parsedDeck.colors.includes(MagicColor.BLUE)) {
          parsedDeck.colors.push(MagicColor.BLUE);
        }
        if (parsedDeck.main.filter(c => c?.colors && c.colors.includes('B')).length > 0 && !parsedDeck.colors.includes(MagicColor.BLACK)) {
          parsedDeck.colors.push(MagicColor.BLACK);
        }
        if (parsedDeck.main.filter(c => c?.colors && c.colors.includes('R')).length > 0 && !parsedDeck.colors.includes(MagicColor.RED)) {
          parsedDeck.colors.push(MagicColor.RED);
        }
        if (parsedDeck.main.filter(c => c?.colors && c.colors.includes('G')).length > 0 && !parsedDeck.colors.includes(MagicColor.GREEN)) {
          parsedDeck.colors.push(MagicColor.GREEN);
        }
      }

      // check and update cardDocIds to only include unique values
      parsedDeck.cardIds = _.map(_.uniqBy(_.concat(deck.main, deck.sideboard, deck.maybeboard), 'scryfallId'), 'scryfallId');

      documentRef.set(parsedDeck)
        .then(() => {
          this.toastService.show('Successfully saved the deck list', { classname: 'success-toast', delay: 1500 });
          resolve({
            status: true,
            text: 'Successfully saved the deck'
          });
        })
        .catch((err) => {
          this.toastService.show('Something went wrong, please try again', { classname: 'error-toast', delay: 8000 });
          console.log(err);
          resolve({
            status: false,
            text: 'Something went wrong, please try again'
          });
        });
    });
  }
  public generateVersion(deck: IDeckList, newVersionName: string, deckVersion: IDeckList = null): void {
    console.log('generate deck version from deck: ', deck)
    // create the new deck version document
    const newVersion: IDeckListVersion = {
      docId: uuidv4(),
      name: newVersionName,
      deckDocId: deck.docId,
      cardZoomLevel: deckVersion ? deckVersion.cardZoomLevel ? deckVersion.cardZoomLevel : 1 : deck.cardZoomLevel ? deck.cardZoomLevel : 1,
      isLocked: false,
      playerDocId: deck.playerDocId,
      playerUid: deck.playerUid,
      timestampCreated: firestore.Timestamp.now().seconds,
      eventDocIds: [],
      colors: deck.colors ? deck.colors : [],
      deckPhotoUrl: null,
      cardIds: deckVersion !== null ? deckVersion.cardIds : deck.cardIds,
      main: deckVersion !== null ? deckVersion.main : deck.main,
      sideboard: deckVersion !== null ? deckVersion.sideboard : deck.sideboard,
      maybeboard: deckVersion !== null ? deckVersion.maybeboard : deck.maybeboard,
    };

    console.log(newVersion);

    this.afs.collection('decks').doc(newVersion.deckDocId).collection('versions').doc(newVersion.docId)
      .set(newVersion)
      .then(() => {
        this.afs.collection('decks').doc(newVersion.deckDocId)
          .update({
            versions: firestore.arrayUnion({
              versionDocId: newVersion.docId,
              versionName: newVersion.name,
            })
          })
          .then(() => {
            this.toastService.show('New version created', { classname: 'success-toast', delay: 2000 });
            this.router.navigate(['decks', 'edit', newVersion.deckDocId, 'version', newVersion.docId])
          })
          .catch((error) => {
            console.log(error);
            this.toastService.show(error, { classname: 'error-toast', delay: 8000 });
          });
      })
      .catch((error) => {
        console.log(error);
        this.toastService.show(error, { classname: 'error-toast', delay: 8000 });
      });


  }
  public async deleteDeckList(originDeck: IDeckList, workingDeck: IDeckList) {
    // set the document reference to save
    let documentRef = this.afs.collection('decks').doc(originDeck.docId);
    let isVersion = false;
    if (workingDeck.docId !== originDeck.docId) {
      isVersion = true;
      documentRef = this.afs.collection('decks').doc(originDeck.docId).collection('versions').doc(workingDeck.docId);
      // navigate away right away so that no template error occurs
      this.router.navigate(['decks/edit/', originDeck.docId, 'version', 'origin']);
    }
    else {
      // navigate away right away so that no template error occurs
      this.router.navigate(['decks/']);
    }

    // delete any versions
    if (!isVersion) { await this.deleteDeckVersions(originDeck) }

    // delete the document
    documentRef
      .delete()
      .then(() => {
        // update the deck document and delete the version
        if (isVersion) {
          this.afs.collection('decks').doc(originDeck.docId).update({
            versions: firestore.arrayRemove({
              versionDocId: workingDeck.docId,
              versionName: workingDeck.name,
            })
          })
            .then(() => this.toastService.show(`Version successfully deleted`, { classname: 'succes-toast', delay: 2000 }))
            .catch((err) => {
              this.toastService.show(`Something went wrong!\n\n[ERROR]=> ${err}`, { classname: 'error-toast', delay: 6000 })
              console.log(err)
            })
        }
        else {
          this.toastService.show(`Deck successfully deleted`, { classname: 'succes-toast', delay: 2000 });
        }
      })
      .catch((err) => {
        this.toastService.show(`Something went wrong!\n\n[ERROR]=> ${err}`, { classname: 'error-toast', delay: 6000 })
        console.log(err);
      })
  }
  private async deleteDeckPhotos(originDeck: IDeckList, workingDeck: IDeckList, isVersion: boolean) {

    const filePaths = [];
    if (isVersion) {
      filePaths.push('decks/' + workingDeck.docId + '.jpg');
      filePaths.push('decks/thumbs/' + workingDeck.docId + '_200x200.jpg');
      filePaths.push('decks/thumbs/' + workingDeck.docId + '_400x400.jpg');
    }
    else {
      for await (const version of originDeck.versions) {
        filePaths.push('decks/' + version.versionDocId + '.jpg');
        filePaths.push('decks/thumbs/' + version.versionDocId + '_200x200.jpg');
        filePaths.push('decks/thumbs/' + version.versionDocId + '_400x400.jpg');
      }
    }

    for await (const filePath of filePaths) {
      const ref = this.storage.ref(filePath);
      ref.getDownloadURL().toPromise()
        .then((url) => { ref.delete() })
        .catch((err) => console.log(err))
    }

    return filePaths.length;
  }
  private async deleteDeckVersions(originDeck: IDeckList) {
    const deckRef = this.afs.collection('decks').doc(originDeck.docId);
    for await (const version of originDeck.versions) {
      await deckRef.collection('versions').doc(version.versionDocId).delete()
        .catch((error) => console.log(error));
    }
    return originDeck.versions.length;
  }
  public getDeckListByDocId(docId: string, versionDocId: string = null): Promise<IDeckList> {
    return new Promise((resolve, reject) => {
      let docRef = this.afs.collection('decks').doc<IDeckList>(docId);
      if (versionDocId) {
        docRef = this.afs.collection('decks').doc(docId).collection('versions').doc<IDeckList>(versionDocId);
      }

      docRef.get().pipe(take(1)).subscribe(doc => resolve(doc.data()));
    });
  }
  public getDeckById(deckDocId: string, versionDocId: string = null): Observable<IDeckList> {
    if (versionDocId !== null) {
      // lets load a deck version as the deckDocId is specified
      return this.afs.collection('decks').doc(deckDocId).collection('versions').doc<IDeckList>(versionDocId).valueChanges();
    }
    else {
      return this.afs.collection('decks').doc<IDeckList>(deckDocId).valueChanges();
    }
  }
  public getDeckById_Promise(deckDocId: string, versionDocId: string = null): Promise<IDeckList> {
    return new Promise(async (resolve) => {
      // return null if deckDocId not present
      if (deckDocId === null || deckDocId === undefined || deckDocId === '') { resolve(null) }
      // return deck version
      else if (versionDocId !== null && versionDocId !== '' && versionDocId !== undefined) {
        // lets load a deck version as the deckDocId is specified
        this.afs
          .collection('decks').doc(deckDocId)
          .collection('versions').doc<IDeckList>(versionDocId)
          .get().subscribe(doc => {
            if (doc.exists) {
              resolve(doc.data() as IDeckList)
            }
            else {
              resolve(null)
            }
          })
      }
      // return deck
      else {
        this.afs
          .collection('decks').doc<IDeckList>(deckDocId)
          .get().subscribe(doc => {
            if (doc.exists) {
              resolve(doc.data() as IDeckList)
            }
            else {
              resolve(null)
            }
          });
      }

    })
  }
  private getDeckVersionsMeta(deckDocId: string): Promise<IDeckVersionMeta[]> {
    return new Promise((resolve, reject) => {

      const docRef = this.afs.collection('decks').doc(deckDocId).collection<IDeckListVersion>('versions');
      docRef.get().toPromise().then(async (snap) => {
        const versionMetas: IDeckVersionMeta[] = [];
        for await (const doc of snap.docs) {
          const tempDeck = doc.data();
          const tempMeta: IDeckVersionMeta = {
            versionDocId: tempDeck.docId,
            versionName: tempDeck.name,
            deckListValid: tempDeck.main.length >= 60 && tempDeck.sideboard.length <= 15,
            deckPhotoValid: tempDeck.deckPhotoUrl !== null && tempDeck.deckPhotoUrl !== '',
            isLocked: tempDeck.isLocked,
            isBlockedForDeletion: tempDeck.isLocked || tempDeck.eventDocIds.length > 0,
            eventDocIds: tempDeck.eventDocIds ? tempDeck.eventDocIds : [],
          }
          versionMetas.push(tempMeta);
        }

        resolve(versionMetas);
      })

    });
  }
  public parseDeckList(deckList: IDeckList): Promise<IPromiseResponse> {
    return new Promise(async (resolve, reject) => {

      try {
        // create a temporary decklist
        const tmpDeckList: IDeckList = JSON.parse(JSON.stringify(deckList));

        // clear main and sideboard for the temp deck
        tmpDeckList.main = [];
        tmpDeckList.sideboard = [];
        const tmpCardsNotFound = [];

        // split all lines in the textarea
        const lines = deckList.textList.split('\n');

        // set main deck as the default deck part
        let isMainDeck = true;

        // loop through all lines to process them
        for await (const line of lines) {

          // skip if line does not contain any letters or is empty
          const regex = new RegExp('[A-Za-z]');
          if (!regex.test(line) || line === '') {
            continue;
          }

          // if the text is main deck, just toggle the isMainDeck boolean
          if (line.toLowerCase().includes('main deck')) {
            isMainDeck = true;
            continue;
          }

          // if the text is sideboard, just toggle the isMainDeck boolean
          if (line.toLowerCase().includes('sideboard')) {
            isMainDeck = false;
            continue;
          }

          // split the line into quantity and name
          const qty = parseInt(line);
          const lineData = line.split(qty.toString());
          let name = lineData[1].replace(/\s+/g, ''); // remove whitespace
          let setCode = '';

          // check if correct pattern ([card qty][space][card name]) = will fail if above split did not return an array
          if (name === undefined) {
            tmpCardsNotFound.push(line);
            continue;
          }

          // check if edition is specified and split
          if (name.includes('|')) {
            const tmpName = name.split('|');
            name = tmpName[0].replace(/\s+/g, ''); // remove whitespace
            setCode = tmpName[1];
          }

          // update loader message
          this.globals.isBusy.message = 'processing: ' + name;

          // fetch the card from the card service
          await this.cardSearch.getCardByName(name, { setCode }).then(card => {
            if (card === null) {

              // no card found, add the line to the notFoundCards
              tmpCardsNotFound.push(line);

            }
            else {

              // add the card to the deck part array which is the currently selected one (main or sideboard)
              const pushArr = Array.from(new Array(qty), (x, i) => i);
              pushArr.forEach(p => {
                const cardMeta = this.getCardMetaFromCardObject(card);
                isMainDeck ? tmpDeckList.main.push(cardMeta) : tmpDeckList.sideboard.push(cardMeta);
              });

              // check color
              if (card.colors.includes('W') && tmpDeckList.colors && !tmpDeckList.colors.includes(MagicColor.WHITE)) {
                tmpDeckList.colors.push(MagicColor.WHITE);
              }
              if (card.colors.includes('U') && tmpDeckList.colors && !tmpDeckList.colors.includes(MagicColor.BLUE)) {
                tmpDeckList.colors.push(MagicColor.BLUE);
              }
              if (card.colors.includes('B') && tmpDeckList.colors && !tmpDeckList.colors.includes(MagicColor.BLACK)) {
                tmpDeckList.colors.push(MagicColor.BLACK);
              }
              if (card.colors.includes('R') && tmpDeckList.colors && !tmpDeckList.colors.includes(MagicColor.RED)) {
                tmpDeckList.colors.push(MagicColor.RED);
              }
              if (card.colors.includes('G') && tmpDeckList.colors && !tmpDeckList.colors.includes(MagicColor.GREEN)) {
                tmpDeckList.colors.push(MagicColor.GREEN);
              }

              // add the card id to the cardIds array if not already present
              if (!tmpDeckList.cardIds.includes(card.id)) { tmpDeckList.cardIds.push(card.id); }

            }
          });

        }

        // return the parsed deck list and any mismatched cards
        resolve({
          status: true,
          text: 'Deck list successfully parsed into a deck list object',
          data: {
            deckList: tmpDeckList,
            cardsNotFound: tmpCardsNotFound
          }
        });

      }
      catch (err) {

        console.log(err);

        resolve({
          status: false,
          text: err
        });

      }

    });

  }
  public newDecksFolder(name: string): Promise<string> {
    return new Promise((resolve) => {
      const decksFolder: IDeckFolder = {
        docId: uuidv4(),
        name,
        playerDocId: this.auth.user.playerId,
        playerUid: this.auth.user.uid
      };
      this.afs.collection('deckFolders')
        .doc(decksFolder.docId)
        .set(decksFolder)
        .then(() => resolve(decksFolder.docId))
        .catch((err) => console.log(err));
    });
  }
  public deleteDeckFolder(deckFolderDocId: string): void {
    this.afs.collection('deckFolders')
      .doc(deckFolderDocId)
      .delete()
      .then(() => this.toastService.show('Folder deleted', { classname: 'success-toast', delay: 2000 }))
      .catch((err) => {
        console.log(err);
        this.toastService.show('Something went wrong, please try again.', { classname: 'error-toast', delay: 6000 });
      });
  }
  public moveDeckToFolder(deckDocId: string, deckFolderDocId: string) {
    this.afs.collection('decks').doc(deckDocId)
      .update({
        deckFolderDocId
      })
      .then(() => {
        this.toastService.show('Successfully moved the deck list', { classname: 'success-toast', delay: 1500 });
      })
      .catch((err) => {
        console.log(err);
        this.toastService.show('Error, something went wrong when trying to move the deck', { classname: 'success-toast', delay: 1500 });
      });
  }
  public getCardMetaFromCardObject(card: any): ICardMeta {
    const cardMeta: ICardMeta = {
      cmc: card.cmc,
      name: card.card_faces[0].name,
      typeLine: card.card_faces[0].type_line,
      scryfallId: card.id,
      imageUrl: card.card_faces[0].image_uris.normal,
      imageUris: card.card_faces[0].image_uris,
      setCode: card.set.toLowerCase(),
      colors: card.card_faces[0].colors,
      metaUuid: uuidv4(),
      coords: {
        x: 0,
        y: 0,
      }
    };
    return cardMeta;
  }
  public addCardToDeck(deckDocId: string, cardMeta: ICardMeta, deckPart: DeckPart, versionDocId: string = null): void {
    this.afs.collection('decks').doc(deckDocId).update({
      cardIds: firestore.arrayUnion(cardMeta.scryfallId),
      [deckPart]: firestore.arrayUnion(cardMeta),
    });
  }
  public updateDeckPhotoUrl(docId: string, url: string): void {

    // set the document reference to save
    let documentRef = this.deckDocumentRef;
    if (documentRef === null) {
      documentRef = this.afs.collection('decks').doc(docId);
    }

    documentRef.update({
      deckPhotoUrl: url
    })
      .then(() => {
        this.toastService.show('Deck phot successfully updated', { classname: 'success-toast', delay: 2000 });
      })
      .catch((err) => {
        console.log(err);
        this.toastService.show('Something went wrong, please try again.', { classname: 'error-toast', delay: 6000 });
      });
  }
  public updateDeckProperty(docId: string, property: string, newValue: any): void {
    // set the document reference to save
    let documentRef = this.deckDocumentRef;
    if (documentRef === null) {
      documentRef = this.afs.collection('decks').doc(docId);
    }

    documentRef.update({
      [property]: newValue
    })
      .then(() => {
        this.toastService.show('Deck successfully updated', { classname: 'success-toast', delay: 2000 });
      })
      .catch((err) => {
        console.log(err);
        this.toastService.show('Something went wrong, please try again.', { classname: 'error-toast', delay: 6000 });
      });
  }
  public calculateCoords(nextIndex: number, zoomLevel: number): any {
    const defaultCardSizePX = {
      width: 10 * 16,
      height: 13.93471718 * 16,
    }
    const cardWidth = defaultCardSizePX.width * zoomLevel;
    const cardHeight = defaultCardSizePX.height * zoomLevel;
    const cardSpacingWidth = cardWidth / 10;
    const cardSpacingHeight = cardHeight / 2;
    const containerPaddingX = cardWidth / 10;
    const containerPaddingY = (cardHeight / 10) * 2;

    // calculate position (row & column)
    const row = Math.floor(nextIndex / 10);
    const col = Math.floor(nextIndex - (10 * row));

    // get coordinates
    const x = containerPaddingX + (cardWidth * col);
    const y = containerPaddingY + (cardSpacingHeight * row);

    // return the new coords
    return { x, y }
  }
  public checkIfDeckCanBeDeleted(): Promise<any> {
    return new Promise(async (resolve) => {
      const params = this.router.url.split('/');
      const docId = params[3];
      const versionDocId = params[5];

      const deckDoc = await this.getDeckListByDocId(docId);
      const metas = await this.getDeckVersionsMeta(docId);


      if (versionDocId !== 'origin') {
        const versionMeta = metas.find(v => v.versionDocId === versionDocId);
        const canBeDeleted = !versionMeta.isBlockedForDeletion;
        resolve({
          text: canBeDeleted ? 'Delete' : 'Version blocked for deletion',
          status: canBeDeleted,
        });
      }
      else {
        const canBeDeleted = metas.filter(v => v.isLocked || v.isBlockedForDeletion).length === 0 && !deckDoc.isLocked && deckDoc.eventDocIds.length === 0;
        resolve({
          text: canBeDeleted ? 'Delete' : 'One or more versions blocked for deletion, delete disabled',
          status: canBeDeleted,
        });
      }


    });
  }
  public getDeckEventInformation(originDeck: IDeckList): Promise<any> {
    return new Promise(async (resolve) => {

      const eventInfos: any[] = [];
      const versionMetas = await this.getDeckVersionsMeta(originDeck.docId);

      for await (const eventDocId of originDeck.eventDocIds) {
        const event = await this.eventService.getEventByIdPromise(eventDocId);
        eventInfos.push({
          eventName: event.details.name,
          deckVersionPlayed: 'origin',
        });
      }

      for await (const versionMeta of versionMetas) {
        for await (const eventDocId of versionMeta.eventDocIds) {
          const event = await this.eventService.getEventByIdPromise(eventDocId);
          eventInfos.push({
            eventName: event.details.name,
            eventDocId,
            routerLink: ['/event-lobby', eventDocId, event.details.name],
            deckVersionPlayed: versionMeta.versionName,
          });
        }
      }


      resolve(eventInfos);

    })
  }
  public getDraftDeckByDraftDocId(draftDocId: string): Observable<IDeckList[]> {
    const draftDeckRef = this.afs.collection<IDeckList>('decks', ref => ref.where('draftDocId', '==', draftDocId).where('playerDocId', '==', this.auth.user.playerId));
    return draftDeckRef.valueChanges();
  }
  public getCountOfDecksContainingCardWithScryfallId(scryfallId: string): Promise<number> {
    return new Promise((resolve, reject) => {
      console.log('getting decks containing card id', scryfallId)
      this.afs.collection('decks', ref => ref.where('cardIds', 'array-contains', scryfallId).where('isPrivate', '==', false)).get().toPromise()
        .then(snap => resolve(snap.docs.length))
        .catch(() => resolve(0));
    })
  }
}

export enum MagicColor {
  WHITE = 'WHITE',
  BLUE = 'BLUE',
  BLACK = 'BLACK',
  RED = 'RED',
  GREEN = 'GREEN'
}
export enum DeckPart {
  MAIN = 'main',
  SIDEBOARD = 'sideboard',
  MAYBEBOARD = 'maybeboard',
  TRASH = 'trash'
}
export interface IDeckList {
  name: string;
  description: string;
  docId: string;
  playerDocId: string;
  playerUid: string;
  timestampCreated: number;
  main: Array<ICardMeta>;
  sideboard: Array<ICardMeta>;
  maybeboard: Array<ICardMeta>;
  textList: string | null;
  deckPhotoUrl: string | null;
  imageUris: {
    small: string,
    medium: string,
    large: string,
    origin: string,
  }
  eventDocIds: Array<string>;
  cardIds: Array<string>; // scryfall uuid
  ratedByPlayerDocIds?: Array<string>;
  colors: Array<string>;
  isPrivate: boolean;
  isLocked: boolean;

  deckFolderDocId?: string;
  cardZoomLevel?: number;
  rating?: number;
  folderName?: string;
  versions?: IDeckVersion[];
  draftDocId?: string;
  sharingId?: string
}
export interface IDeckListVersion {
  deckDocId: string;
  docId: string;
  name: string;
  cardZoomLevel: number;
  isLocked: boolean;
  playerDocId: string;
  playerUid: string;
  timestampCreated: number;
  eventDocIds: Array<string>;
  colors: Array<string>;
  deckPhotoUrl: string | null;
  cardIds: Array<string>; // scryfall uuid
  main: Array<ICardMeta>;
  sideboard: Array<ICardMeta>;
  maybeboard: Array<ICardMeta>;
  sharingId?: string
}
export interface IDeckVersion {
  versionDocId: string;
  versionName: string;
}
export interface IDeckVersionMeta extends IDeckVersion {
  deckListValid: boolean;
  deckPhotoValid: boolean;
  isLocked: boolean;
  isBlockedForDeletion: boolean;
  eventDocIds: string[];
}
export interface ICardMeta {
  cmc: number;
  name: string;
  scryfallId: string;
  typeLine: string;
  imageUrl: string;
  imageUris: ImageUris;
  metaUuid: string;
  setCode: string;
  colors: string[];
  coords?: {
    x: number;
    y: number;
  }
  cardId?: string;
}
export interface IDeckMeta {
  name: string;
  docId: string;
  deckFolderDocId: string;
  deckFolderName: string;
  versions: IDeckVersionMeta[];
  filterString: string;
  deckPhotoValid: boolean;
  deckListValid: boolean;
}
export class MagicDeck {
  data: IDeckList;
  constructor(playerDocId: string, playerUid: string, eventDocId: string = null) {
    this.data = {
      cardIds: [] as Array<string>,
      colors: [] as Array<string>,
      deckPhotoUrl: null,
      imageUris: {
        small: null,
        medium: null,
        large: null,
        origin: null,
      },
      description: '',
      docId: uuidv4(),
      eventDocIds: [] as Array<string>,
      main: [] as Array<ICardMeta>,
      maybeboard: [] as Array<ICardMeta>,
      name: '',
      playerDocId,
      playerUid,
      sideboard: [] as Array<ICardMeta>,
      timestampCreated: firestore.Timestamp.now().seconds,
      textList: null,
      isPrivate: true,
      isLocked: false,
      versions: [],
      deckFolderDocId: null,
      cardZoomLevel: 1,
      rating: 0,
    };

    if (eventDocId !== null) {
      this.data.eventDocIds.push(eventDocId);
    }
  }
}
export interface IDeckFolder {
  docId: string;
  playerDocId: string;
  playerUid: string;
  name: string;
}
export interface IPlayerDecksList {
  deckFolders: IDeckFolderMeta[];
}
export interface IDeckFolderMeta {
  name: string;
  docId: string;
  decks: number;
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import Delta from 'quill-delta';
import { AuthService, ICardSearchParams } from 'src/app/services';
import { IInvitedPlayer, IMatchData, IMessageDocument, IMessageImage } from 'tolaria-cloud-functions/src/_interfaces';
import * as firestore from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'
import { MessageItem } from './message-list.service';
import { CardSearchService } from 'src/app/services/card-search.service';
import CardApiResponse from 'scryfall-client/dist/types/api/card';
import { TolariaWysiwygImage } from '../components/tolaria-wysiwyg/tolaria-wysiwyg.interfaces';


export enum MessageType {
  CHAT_MESSAGE = 'chat-message',
  EVENT_INVITATION = 'event-invitation',
  SCRYFALL_IMAGE = 'scryfall-image',
  SCRYFALL_RULES = 'scryfall-rules',
  EVENT_ANNOUNCEMENT = 'event-announcement',
  MATCH_INVITATION = 'match-invitation',
  MATCH_APPOINTMENT = 'match-appointment',
  MATCH_ROOM_ACTION = 'match-room-action'
}

@Injectable({
  providedIn: 'root'
})
export class MessageSenderService {

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly auth: AuthService,
    private readonly cardSearch: CardSearchService,
  ) { }


  public send(options: {
    message: string,
    whisperMode: boolean,
    messageGroupDocId: string,
    replyTo: MessageItem,
    images: TolariaWysiwygImage[] | null,
    matchChat: boolean,
    matchDocId: string,
    spectatorMode: boolean,
    html?: string,
    delta?: Delta,
    mentionedPlayerDocIds?: string[] | null
    type?: MessageType,
    invitedPlayer?: IInvitedPlayer,
    matchDoc?: IMatchData,
  }) {

    if (options.type === undefined) {
      options.type = MessageType.CHAT_MESSAGE
    }

    const msg: IMessageDocument = {
      docId: uuidv4(),
      images: [],
      message: options.message,
      html: options.html === undefined ? null : options.html,
      messageGroupDocId: options.messageGroupDocId,
      playerDocId: this.auth.user.playerId,
      playerUid: this.auth.user.uid,
      matchChat: options.matchChat,
      spectatorMode: options.spectatorMode,
      matchDocId: options.matchDocId,
      type: options.type,
      timestamp: firestore.Timestamp.now().seconds,
      replyToMeta: options.replyTo === null ? null : {
        messageDocId: options.replyTo.docId,
        playerDocId: options.replyTo.playerDocId,
        message: options.replyTo.message.message,
      },
      mentionedPlayerDocIds: [],
      content: {
        matchDoc: options.matchDoc ? options.matchDoc : null,
      },
      whisperMode: null,
      archived: false,
    }
    if (options.delta) {
      // convert delta to object that firestore can handle
      msg.delta = JSON.parse(JSON.stringify(options.delta))
    }
    if (options.whisperMode) {
      msg.whisperMode = this.auth.user.playerId
    }
    if (options.mentionedPlayerDocIds && options.mentionedPlayerDocIds !== null) {
      msg.mentionedPlayerDocIds = options.mentionedPlayerDocIds
    }

    if (options.images !== null) {
      const newImages: IMessageImage[] = options.images.map(i => {
        return {
          guid: i.id,
          downloadUrl: i.downloadUrl,
        }
      })
      msg.images = newImages
    }

    // check for message type and perform updates to the document before saving
    switch (options.type) {
      case  MessageType.MATCH_INVITATION:
        msg.matchDocId = options.matchDoc.docId
        break
      case MessageType.EVENT_INVITATION:
        msg.invitedPlayer = options.invitedPlayer
        break
      case MessageType.MATCH_ROOM_ACTION:
        msg.playerDocId = 'match-room-action'
        msg.playerUid = 'match-room-action'
        break
    }

    // send the message
    this.firestore
      .collection('messages')
      .doc(msg.docId)
      .set(msg)
      .then(() => {
        console.log('MessageSenderService:: message sent ->', msg)
      })
      .catch((error) => console.log(error))

    // let trikeyboy send his messages for cards if needed
    this.handleCardReferences(msg)

  }

  public save(options: {
    msg: MessageItem,
    delta: Delta,
    images: TolariaWysiwygImage[] | null
  }) {

    const messageDoc = {
      delta: options.delta,
    }

    if (options.images !== null) {
      if (options.msg.message.images !== undefined) {
        const newImages: IMessageImage[] = options.images.map(i => {
          return {
            guid: i.id,
            downloadUrl: i.downloadUrl,
          }
        })
        messageDoc['images'] = [...options.msg.message.images, ...newImages]
      }
    }

    this.firestore
      .collection('messages')
      .doc(options.msg.docId)
      .update(messageDoc)
      .catch((error) => console.log(error))

  }

  private handleCardReferences(messageDoc: IMessageDocument) {

    // check for mentioned cards [[cardname]]
    const cardMatches = messageDoc.message.match(/\[\[.*?\]\]/g)
    let cardObjects = []
    if (cardMatches !== undefined && cardMatches !== null && cardMatches.length > 0) {
      cardObjects = cardMatches.map((string: string) => {
        const cardObj: ICardSearchParams = {
          searchString: string.match(/\[\[([^)]+)\]\]/)[1].trim(),
          setString: '',
          searchRules: false,
          searchSet: false
        }
        if (cardObj.searchString.indexOf('?') > -1) {
          cardObj.searchRules = true
          cardObj.searchString = cardObj.searchString.substring(1)
        }
        if (cardObj.searchString.indexOf('|') > -1) {
          cardObj.searchSet = true
          cardObj.setString = cardObj.searchString.substring(cardObj.searchString.lastIndexOf('|') + 1).trim()
          cardObj.searchString = cardObj.searchString.substring(0, cardObj.searchString.indexOf('|')).trim()
        }
        return cardObj
      })
    }

    if (cardObjects.length > 0) {
      cardObjects.forEach(i => this.fetchScryfallData(i, messageDoc))
    }

  }

  private fetchScryfallData(cardObj: ICardSearchParams, messageDoc: IMessageDocument) {
    this.cardSearch.getCardByName(cardObj.searchString, cardObj.searchSet ? { setCode: cardObj.setString } : {})
      .then(async (card: CardApiResponse) => {

        // define the message object
        let msg: IMessageDocument = {
          docId: uuidv4(),
          timestamp: firestore.Timestamp.now().seconds + 1, // add one to get this after the original message
          messageGroupDocId: messageDoc.messageGroupDocId,
          playerDocId: 'scryfall-robot',
          playerUid: 'scryfall-robot',
          message: 'This is what I got for you!',
          matchChat: messageDoc.matchChat,
          spectatorMode: messageDoc.spectatorMode,
          matchDocId: messageDoc.matchDocId,
          whisperMode: messageDoc.whisperMode !== undefined ? messageDoc.whisperMode : '',
          type: cardObj.searchRules ? 'scryfall-rules' : 'scryfall-image',
          content: null,
          archived: false,
        }

        // rules search
        if (cardObj.searchRules) {
          const rulings = await this.cardSearch.getCardRulingById(card.id)
          const tempRules = {
            data: rulings.map(r => {
              return {
                comment: r.comment,
                oracle_id: r.oracle_id,
                published_at: r.published_at,
                source: r.source,
                object: r.object,
              }
            })
          }

          // update the content of the message object
          msg.content = {
            cardName: card.name,
            rulings: tempRules,
            setCode: card.set,
            setName: card.set_name,
          }
        }
        // regular card search
        else {
          // update the content of the message object
          msg.content = {
            cardName: card.name,
            imageUrl: card.image_uris ? card.image_uris.normal : null,
            setCode: card.set,
            setName: card.set_name,
            typeLine: card.type_line,
            manaCost: card.mana_cost,
            oracleText: card.oracle_text ? card.oracle_text : null,
            scryfallUri: card.uri,
            flavorText: card.flavor_text ? card.flavor_text : null,
            power: card.power ? card.power : null,
            toughness: card.toughness ? card.toughness : null,
            loyalty: card.loyalty ? card.loyalty : null,
          }
        }

        console.log('Create trike message --> ', msg)

        // send the message
        this.firestore
          .collection('messages')
          .doc(msg.docId)
          .set(msg)
          .then(() => console.log('Message sent ->', msg))
          .catch((error) => console.log(error))

      })
  }


}

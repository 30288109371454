import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { TolariaDeckViewerComponent } from './app/private/social/messages/components/tolaria-wysiwyg/custom-elements/tolaria-deck-viewer/tolaria-deck-viewer.component';
import { TolariaTournamentViewerComponent } from './app/private/social/messages/components/tolaria-wysiwyg/custom-elements/tolaria-tournament-viewer/tolaria-tournament-viewer.component';
import { TolariaPlayerMentionComponent } from './app/private/social/messages/components/tolaria-wysiwyg/custom-elements/tolaria-player-mention/tolaria-player-mention.component';
import { createCustomElement } from '@angular/elements';
import { TolariaEmojiComponent } from './app/private/social/messages/components/tolaria-wysiwyg/custom-elements/tolaria-emoji/tolaria-emoji.component';

if (environment.production) {
  enableProdMode();
}

const ngModuleRef = platformBrowserDynamic().bootstrapModule(AppModule)
  .then((ngModuleRef) => {

    // Convert Angular components to custom elements
    const TolariaDeck = createCustomElement(TolariaDeckViewerComponent, { injector: ngModuleRef.injector });
    const TolariaTournament = createCustomElement(TolariaTournamentViewerComponent, { injector: ngModuleRef.injector });
    const TolariaPlayerMention = createCustomElement(TolariaPlayerMentionComponent, { injector: ngModuleRef.injector });
    const TolariaEmoji = createCustomElement(TolariaEmojiComponent, { injector: ngModuleRef.injector });

    // Define the custom elements
    customElements.define('tolaria-deck', TolariaDeck)
    customElements.define('tolaria-tournament', TolariaTournament)
    customElements.define('tolaria-mention', TolariaPlayerMention)
    // customElements.define('tolaria-emoji', TolariaEmoji, { extends: 'span' })
    customElements.define('tolaria-emoji', TolariaEmoji)

  })
  .catch(err => console.error(err));

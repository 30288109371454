<div class="form-wraper p-3" *ngIf="localPlayerDoc as player">
  <div class="row">
    <div class="col">
      <div class="form-group">
        <input class="form-control text-secondary" [(ngModel)]="player.email" disabled="true">
      </div>
    </div>
    <div class="col-4" *ngIf="editMode && canChangeEmail">
      <button class="btn btn-outline-danger ms-2" (click)="changeEmail()">Change Email</button>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <div class="form-group">
        <label>Status Message</label>
        <input class="form-control"
          [(ngModel)]="player.statusMessage" [disabled]="!editMode" [ngClass]="{'text-secondary' : !editMode}"
          placeholder="Status message that will be shown on you player card" maxlength="50">
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <div class="form-group">
        <label>Description</label>
        <textarea rows="5"
          class="form-control" [(ngModel)]="player.description" [disabled]="!editMode"
          [ngClass]="{'text-secondary' : !editMode}" placeholder="Write something about yourself maybe"
          style="resize: none;" maxlength="300"></textarea>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <div class="form-group">
        <label>First name</label>
        <input class="form-control"
          [(ngModel)]="player.name.first" [disabled]="!editMode" [ngClass]="{'text-secondary' : !editMode}">
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>Last name</label>
        <input type="text"
          class="form-control" [(ngModel)]="player.name.last" [disabled]="!editMode"
          [ngClass]="{'text-secondary' : !editMode}">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label>Nick name</label>
        <input class="form-control"
          [(ngModel)]="player.name.nick" [disabled]="!editMode" [ngClass]="{'text-secondary' : !editMode}">
      </div>
    </div>
    <div class="col">
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label>Display name</label>
        <select class="custom-select" [(ngModel)]="player.name.display" [compareWith]="byValue" [disabled]="!editMode"
          [ngClass]="{'text-secondary' : !editMode}">
          <option [ngValue]="player.name.first + ' ' + player.name.last">
            {{ player.name.first + ' ' + player.name.last }}</option>
          <option [ngValue]="player.name.last + ', ' + player.name.first">
            {{ player.name.last + ' ' + player.name.first }}</option>
          <option [ngValue]="player.name.first + ' ' + player.name.last + ' (' + player.name.nick + ')'">
            {{ player.name.first + ' ' + player.name.last + ' (' + player.name.nick + ')' }}</option>
          <option [ngValue]="player.name.nick + ' (' + player.name.first + ' ' + player.name.last + ')'">
            {{ player.name.nick + ' (' + player.name.first + ' ' + player.name.last + ')' }}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-7">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Address</label>
            <input type="text"
              class="form-control text-secondary" [(ngModel)]="player.address.street" [disabled]="!editMode"
              [ngClass]="{'text-secondary' : !editMode}">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Apt / Suite / Other</label>
            <input type="text"
              class="form-control text-secondary" [(ngModel)]="player.address.other" [disabled]="!editMode"
              [ngClass]="{'text-secondary' : !editMode}">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <label>Zip code</label>
            <input type="text"
              class="form-control text-secondary" [(ngModel)]="player.address.zipCode" [disabled]="!editMode"
              [ngClass]="{'text-secondary' : !editMode}">
          </div>
        </div>
        <div class="col-8">
          <div class="form-group">
            <label>City</label>
            <input type="text"
              class="form-control text-secondary" [(ngModel)]="player.address.city" [disabled]="!editMode"
              [ngClass]="{'text-secondary' : !editMode}">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>State / Province / Region</label>
            <input type="text"
              class="form-control text-secondary" [(ngModel)]="player.address.region" [disabled]="!editMode"
              [ngClass]="{'text-secondary' : !editMode}">
          </div>
        </div>
      </div>
    </div>
    <div class="col-5">
      <div class="form-group">
        <label>Phone</label>
        <input type="tel"
          class="form-control" [(ngModel)]="player.phone" [disabled]="!editMode" [ngClass]="{'text-secondary' : !editMode}">
      </div>
    </div>
  </div>

  <div class="row mt-3 text-secondary">
    <div class="col">
      <div class="form-group">
        <label>Country</label>
        <select class="custom-select" [(ngModel)]="player.country" [compareWith]="byName" [disabled]="!editMode"
          [ngClass]="{'text-secondary' : !editMode}">
          <option [ngValue]="country" *ngFor="let country of countries">{{ country.name }}</option>
        </select>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>Time zone</label>
        <select class="custom-select" [(ngModel)]="player.timeZone" [compareWith]="byOlson" [disabled]="!editMode"
          [ngClass]="{'text-secondary' : !editMode}" [disabled]="true">
          <option [ngValue]="timezone" *ngFor="let timezone of timeZones">
            {{ timezone.UTC + ' | ' + timezone.olson }}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <div class="d-flex align-items-center">
        <label class="flex-grow-1 text-secondary">Handles</label>
        <button class="btn btn-sm btn-blank" (click)="addHandle()" *ngIf="editMode">
          <fa-icon [icon]="icons.faPlusCircle" [fixedWidth]="true"></fa-icon>
        </button>
      </div>
      <div class="handles-wrapper d-flex flex-column" *ngIf="player?.handles as handles">
        <ng-container *ngFor="let handle of handles">
          <div class="d-flex align-items-center handle-item">
            <div class="flex-grow-0 my-1 me-3">
              <fa-icon [icon]="handleIcons[handle.platform]" [fixedWidth]="true"></fa-icon>
            </div>
            <div class="flex-grow-1" [innerHtml]="handle.handle"></div>
            <div class="flex-grow-0 text-secondary">
              <a [href]="handle.url" *ngIf="handle.url !== ''" target="_blank">
                <fa-icon [icon]="icons.faExternalLinkAlt" [fixedWidth]="true"></fa-icon>
              </a>
            </div>
            <div class="flex-grow-0" *ngIf="editMode">
              <div class="ms-2 btn btn-sm btn-blank text-red" (click)="deleteHandle(handle)">
                <fa-icon [icon]="icons.faTrashAlt" [fixedWidth]="true"></fa-icon>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>



  <div class="row mt-5">
    <div class="col"><span class="h6">Public information</span></div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <button class="btn btn-sm btn-secondary text-start w-100" [disabled]="!editMode"
        (click)="player.phoneIsPublic = !player.phoneIsPublic" [ngClass]="{'btn-main' : player.phoneIsPublic}">
        <fa-icon [fixedWidth]="true" [icon]="player.phoneIsPublic ? icons.faCheckSquare : icons.faSquare" class="me-2">
        </fa-icon>Phone
      </button>
    </div>
    <div class="col">
      <button class="btn btn-sm btn-secondary text-start w-100" [disabled]="!editMode"
        (click)="player.emailIsPublic = !player.emailIsPublic" [ngClass]="{'btn-main' : player.emailIsPublic}">
        <fa-icon [fixedWidth]="true" [icon]="player.emailIsPublic ? icons.faCheckSquare : icons.faSquare" class="me-2">
        </fa-icon>Email
      </button>
    </div>
    <div class="col">
      <button class="btn btn-sm btn-secondary text-start w-100" [disabled]="!editMode"
        (click)="player.addressIsPublic = !player.addressIsPublic" [ngClass]="{'btn-main' : player.addressIsPublic}">
        <fa-icon [fixedWidth]="true" [icon]="player.addressIsPublic ? icons.faCheckSquare : icons.faSquare"
          class="me-2"></fa-icon>Address
      </button>
    </div>
  </div>

  <div class="separator"></div>
  <div class="row mt-5">
    <div class="col">
      <button class="btn btn-sm btn-secondary" (click)="editMode = !editMode">Edit</button>
      <button class="btn btn-sm btn-main float-end" *ngIf="editMode" (click)="saveSetting(player)">Save</button>
    </div>
  </div>
</div>

<ng-template let-modal #newEmailInput>
  <div class="modal-body">
    <div class="d-flex align-items-center justify-content-center">
      <fa-icon [icon]="icons.faExclamationTriangle" class="text-huge text-warning"></fa-icon>
    </div>
    <div class="text-warning mt-3">
      Changing your email will update both your user profile and your player profile.
      This will also mean that you, from now on will use the new email to login.
    </div>
    <div class="form-group mt-3">
      <input class="form-control {{ emailValid ? 'is-valid' : 'is-invalid' }}" type="text" [(ngModel)]="newEmailAddress">
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn btn-sm btn-blank text-secondary" (click)="modal.dismiss()">Cancel</div>
    <button class="btn btn-sm btn-blank text-warning" (click)="modal.close(true)" [disabled]="!emailValid">Change Email</button>
  </div>
</ng-template>
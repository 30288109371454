import { take, map } from 'rxjs/operators';
import { AuthService } from 'src/app/services';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayerGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  canActivate(): Observable<boolean> {
    const canDoIt = this.auth.user$.pipe(
      take(1),
      map(user => !!user.isRegisteredAsPlayer),
    );
    if (!canDoIt) {
      this.router.navigate(['register']);
    }
    return canDoIt;
    // if (!this.auth.user.isRegisteredAsPlayer) {
    //   this.router.navigate(['register']);
    //   return false;
    // }
    // else {
    //   return true;
    // }
  }

}

<div class="d-flex flex-row h-100 overflow-hidden" *ngIf="ticket$ | async as ticket">

  <div class="card card-xlarge p-0 h-100">
    <div class="card-header deleted" *ngIf="ticket.deleted">DELETED</div>

    <div class="card-body no-hover p-3 flex-grow-0">
      <div class="d-flex align-items-center">
        <div class="text-secondary flex-grow-1">{{ ticket.type }} TICKET</div>
        <button class="btn btn-sm btn-outline-secondary"
          *ngIf="!ticket.deleted"
          (click)="editTicket(ticket)">
          <fa-icon [icon]="editIcon" [fixedWidth]="true" class="me-2"></fa-icon>Edit
        </button>
      </div>
      <div class="text-large">{{ ticket.name }}</div>
      <div class="text-secondary">{{ ticket.description }}</div>

      <div class="mt-3">Sales price: {{ ticket.salesData.price }} {{ ticket.salesData.currency.toUpperCase() }}</div>

      <div class="my-2 border-bottom border-secondary"></div>

      <div class="flex-grow-1 mt-2">
        <ng-container [ngSwitch]="ticket.type">
          <div class="version-wrapper d-flex flex-column align-items-start" *ngSwitchCase="'CONVENTION'">
            <div class="text-normal text-secondary">Events covered by this ticket</div>
            <div class="d-flex align-items-center px-0 py-1 w-100" *ngFor="let eventDocId of ticket.data.eventDocIds">
              <app-event-name class="flex-grow-1" [eventDocId]="eventDocId"></app-event-name>
              <div class="flex-grow-0 text-secondary d-flex align-items-center" ngbTooltip="Attending players" container="body" [style.minWidth.rem]="8">
                <fa-icon [icon]="attendeesIcon" [fixedWidth]="true" class="flex-grow-0 px-2"></fa-icon>
                <div class="flex-grow-1">{{ ticket.data.events[eventDocId].attendingPlayers }} of {{ ticket.data.events[eventDocId].attendeeCap }}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="card-body no-hover flex-grow-0 text-large border-top border-bottom border-secondary px-3 py-2 d-flex align-items-center">
      <span class="flex-grow-1">Sales</span>
      <span class="flex-grow-0">{{ totalSales }}</span>
    </div>
    <div class="card-body no-hover p-0 flex-grow-1 overflow-hidden overflow-y-auto scrollable">

      <div class="row purchase py-1 px-3 m-0 d-flex align-items-center border-bottom border-secondary" *ngFor="let purchase of ticketsPurchased" [ngClass]="{ 'refunded': purchase.refunded }">
        <div class="flex-grow-1">
          <app-player-display-name [playerDocId]="purchase.playerDocId"></app-player-display-name>
        </div>
        <div class="text-secondary" ngbTooltip="Ticket has been refunded" container="body" *ngIf="purchase.refunded">
          <fa-icon [icon]="refundIcon" [fixedWidth]="true"></fa-icon>
        </div>
        <div class="text-small text-monospace text-secondary px-2">
          <app-timestamp-clock [timestamp]="purchase.timestamp" [seconds]="true" displayType="date-time"></app-timestamp-clock>
        </div>
        <div ngbDropdown class="d-inline-block" container="body">
          <div class="btn btn-sm btn-blank" id="playerAction" ngbDropdownToggle>
            <fa-icon [icon]="playerAction" [fixedWidth]="true"></fa-icon>
          </div>
          <div ngbDropdownMenu aria-labelledby="playerAction">
            <button ngbDropdownItem (click)="issueRefund(ticket, purchase.paymentIntent)" [disabled]="purchase.refunded">Issue refund</button>
            <div class="dropdown-divider"></div>
            <button ngbDropdownItem [disabled]="true">Contact player</button>
          </div>
        </div>
      </div>

    </div>
    <div class="card-footer p-2 d-flex align-items-center">
      <div class="flex-grow-1">
        <button disabled="true" class="btn btn-sm text-secondary text-small lext-left">{{ ticket.docId }}</button>
        <button (click)="copyTextToClipboard(ticket.docId)" class="btn btn-sm text-secondary m-0">
          <fa-icon [icon]="copyIcon" [fixedWidth]="true"></fa-icon>
        </button>
      </div>
      <button class="btn btn-sm btn-danger" *ngIf="!ticket.deleted" (click)="deleteTicket(ticket.docId)">Delete</button>
    </div>

  </div>



  <ng-template #deleteConfirmation let-modal>
    <div class="modal-body d-flex flex-column justify-items-center align-items-center">
      <fa-icon class="text-huge text-warning" [icon]="warningIcon" [fixedWidth]="true"></fa-icon>
      <div class="text-warning">
        Are you sure you want to delete the ticket, this can't be undone!
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-sm btn-blank" (click)="modal.close()">Cancel</button>
      <button class="btn btn-sm btn-danger" (click)="modal.close(true)">Delete</button>
    </div>
  </ng-template>

  <!-- <div class="card card-xlarge p-0 h-100" *ngIf="showEdit" [style.marginLeft.px]="-1">
    <app-new-ticket [data]="ticket" [edit]="true"></app-new-ticket>
  </div> -->

</div>

import { CardSearchService } from 'src/app/services/card-search.service';
import { Component, Input } from '@angular/core';
import { faFilter, faSlidersH, faQuestionCircle, faRandom, faChevronLeft, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.css']
})
export class SearchFormComponent {

  @Input() singleCol: boolean;

  faFilter = faFilter;
  faSlidersH = faSlidersH;
  faQuestionCircle = faQuestionCircle;
  faRandom = faRandom;
  faChevronLeft = faChevronLeft;
  faPlus = faPlus;
  faTimesCircle = faTimesCircle;

  constructor(
    public cardSearch: CardSearchService
  ) {
    this.cardSearch.clearAdvancedForm();
  }

}

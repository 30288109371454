<fa-icon [fixedWidth]="true" [icon]="faGlobe" *ngIf="presence$ | async as presence; else default" class="status-bubble"
[ngClass]="{
  'is-online':  presence.status  === 'online',
  'is-away': presence.status  === 'away',
  'is-offline':  presence.status  === 'offline'
}" container="body" placement="top-right" [ngbTooltip]="presence.status"></fa-icon>

<ng-template #default>
  <fa-icon [fixedWidth]="true" [icon]="faUserCircle" class="status-bubble is-offline" ngbModal="Offline" placement="top-right" container="body"></fa-icon>
</ng-template>

<div class="mt-2">
  <div class="h6">Printings</div>
  <ng-container *ngIf="infoLoaded$ | async; else loader">
    <ng-container *ngFor="let printing of setList">
      <button class="btn btn-sm btn-blank set-button p-2" (click)="doUpdateCardId(printing.id)" [ngbTooltip]="printing.set_name">
        <svg class="set-icon">
          <use [attr.xlink:href]="'#sets-' + printing.set_icon_code + '-svg'"></use>
        </svg>
      </button>
    </ng-container>
  </ng-container>
  <ng-template #loader>
    <div class="lds-facebook"><div></div><div></div><div></div></div>
  </ng-template>
</div>

<ng-template #tipContent>
    <div class="p-2">
        <div class="pb-2 text-large">
            Hand size
        </div>
        <div class="text-secondary">
            To update your hand size, use the number keys
        </div>
        <div class="">
            <span class="badge text-bg-light text-monospaced me-1">0</span>
            <span class="badge text-bg-light text-monospaced me-1">1</span>
            <span class="badge text-bg-light text-monospaced me-1">2</span>
            <span class="badge text-bg-light text-monospaced me-1">3</span>
            <span class="badge text-bg-light text-monospaced me-1">4</span>
            <span class="badge text-bg-light text-monospaced me-1">5</span>
            <span class="badge text-bg-light text-monospaced me-1">6</span>
            <span class="badge text-bg-light text-monospaced me-1">7</span>
            <span class="badge text-bg-light text-monospaced me-1">8</span>
            <span class="badge text-bg-light text-monospaced me-1">9</span>
        </div>
    </div>
</ng-template>
<div class="hand-size" [ngbTooltip]="tipContent" container="body">
    {{ handSize }}
</div>
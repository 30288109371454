import { faMinusSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { faCopy, faHistory, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastService } from 'src/app/services/toast.service'
import { Router, ActivatedRoute } from '@angular/router'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core'
import { customAlphabet } from 'nanoid'
import { BehaviorSubject, Subject } from 'rxjs'
import * as firestore from 'firebase/firestore'
import { GlobalsService } from 'src/app/services';

@Component({
  selector: 'app-life-counter',
  templateUrl: './life-counter.component.html',
  styleUrls: ['./life-counter.component.css']
})
export class LifeCounterComponent implements OnInit, OnDestroy {
  @ViewChild('nameForm') nameForm: ElementRef
  @ViewChild('shareSelector') shareSelector: ElementRef
  @ViewChild('lifeHistory') lifeHistory: ElementRef

  public lifeCounterDoc$ = new BehaviorSubject<LifeCounterDocument>(null)
  private playersUntouched$ = new BehaviorSubject<LifeCounterPlayer[]>(null)
  public players$ = new BehaviorSubject<LifeCounterPlayer[]>(null)
  public playerHistory$ = new BehaviorSubject<Array<number | null>>(null)
  public newPlayerName: string = null
  private params: LifeCounterQueryParams
  private nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 10)
  private componentDestroyed$ = new Subject<boolean>()
  private lifeUpdateTimer: any

  public icon = {
    delete: faTrash,
    reset: faUndo,
    copy: faCopy,
    history: faHistory,
    plus: faPlusSquare,
    minus: faMinusSquare
  }

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private modalService: NgbModal,
    private globals: GlobalsService,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: LifeCounterQueryParams) => {
      // store the params
      this.params = params

      if (params.id) {
        // get the player docunment
        this.afs.collection<LifeCounterDocument>('tolaria-life-counter').doc(params.id).valueChanges()
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(doc => {
            // store life counter document
            this.lifeCounterDoc$.next(doc)
          })
      }
      if (params.match && params.view) {
        console.log(`Watching life counter for match with id ${params.match}`)
        this.afs.collection('matches').doc<IMatchData>(params.match).valueChanges()
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(doc => {
            console.log(doc)
            const players: LifeCounterPlayer[] = []
            const player1: LifeCounterPlayer = {
              name: doc.player1.displayName,
              life: doc.player1.lifePoints,
              wins: doc.player1.wins,
              id: doc.player1.playerDocId,
            }
            players.push(player1)
            const player2: LifeCounterPlayer = {
              name: doc.player2.displayName,
              life: doc.player2.lifePoints,
              wins: doc.player2.wins,
              id: doc.player2.playerDocId,
            }
            players.push(player2)
            this.players$.next(players)
          })
        // store the params
        this.params = params
      }
      if (params.local) {
        // get local document
        const doc = JSON.parse(localStorage.getItem('tolaria-life-counter')) as LifeCounterDocument
        // store life counter document
        this.lifeCounterDoc$.next(doc)
      }
    })

    this.lifeCounterDoc$.subscribe(doc => {
      if (doc !== null) {
        // create array with players
        const players: LifeCounterPlayer[] = []
        Object.keys(doc.players).forEach(k => players.push(doc.players[k]))
        // if player id param exist, move that player to the first record in the array of players
        if (this.params.player !== null && this.params.player !== undefined) {
          const player = players.splice(players.findIndex(p => p.id === this.params.player), 1)
          players.push(player[0])
        }
        players.sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0)
        // store the player list
        this.players$.next(players)
        this.playersUntouched$.next(JSON.parse(JSON.stringify(players)))
      }
    })
  }
  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  private newCounter(): LifeCounterDocument {
    const emptyDoc: LifeCounterDocument = {
      created: firestore.Timestamp.now().seconds,
      docId: this.nanoid(),
      players: {},
    }
    const id = this.nanoid()
    return emptyDoc
  }

  public async createNewCounterDocument(local: boolean = false) {
    const doc = this.newCounter()

    if (local) {
      localStorage.setItem('tolaria-life-counter', JSON.stringify(doc))
      this.router.navigate([], { queryParams: { local: true } })
    }
    else {
      this.afs.collection('tolaria-life-counter').doc(doc.docId)
        .set(doc)
        .then(() => this.router.navigate([], { queryParams: { id: doc.docId } }))
        .catch((error) => console.log(`[ERROR] -> ${error}`))
    }
  }

  private updateCounterDocument(doc: LifeCounterDocument): Promise<boolean> {
    return new Promise((resolve) => {
      this.afs.collection('tolaria-life-counter').doc(doc.docId)
        .update(doc)
        .then(() => resolve(true))
        .catch((error) => {
          console.log(`[ERROR] -> ${error}`)
          resolve(false)
        })
    })
  }

  public createNewCounter(): void {
    this.lifeCounterDoc$.next(null)
    this.router.navigate([], { queryParams: {} })
  }

  public shareCounter(): void {
    this.modalService.open(this.shareSelector, {
      size: 'sm',
      centered: false,
      backdrop: true,
      keyboard: true,
      animation: true,
    })
  }

  public showHistory(player: LifeCounterPlayer): void {
    this.playerHistory$.next(player.life)
    this.modalService.open(this.lifeHistory, {
      size: 'sm',
      centered: false,
      backdrop: true,
      keyboard: true,
      animation: true,
    })
  }

  public copyShareLink(player: LifeCounterPlayer): void {
    this.copyTextToClipboard(`${window.location.origin}/life-counter?id=${this.lifeCounterDoc$.getValue().docId}&player=${player.id}`)
  }

  public async addPlayer() {
    const doc = this.lifeCounterDoc$.getValue()
    this.modalService.open(this.nameForm, {
      size: 'sm',
      centered: false,
      backdrop: true,
      keyboard: true,
      animation: true,
    })
      .result.then(
        async () => {
          if (this.newPlayerName.length >= 3) {
            // create the player
            const id = this.nanoid()
            const player = {
              id,
              name: this.newPlayerName,
              life: [20],
              wins: 0,
            }

            if (this.params.local) {
              // define new doc
              const doc = JSON.parse(JSON.stringify(this.lifeCounterDoc$.getValue())) as LifeCounterDocument
              // add player
              doc.players[id] = player
              // save doc
              localStorage.setItem('tolaria-life-counter', JSON.stringify(doc))
              // update local doc
              this.lifeCounterDoc$.next(doc)
            }
            else {

              this.afs.collection('tolaria-life-counter').doc(doc.docId).update({
                [`players.${id}`]: player
              })

              // create player link
              const playerLink = `${window.location.origin}/life-counter?id=${doc.docId}&player=${id}`

              // copy player link to clipboard
              this.copyTextToClipboard(playerLink)

            }

            // clear form
            this.newPlayerName = null
          }
        },
        () => { }
      )

  }

  public gameWins(player: LifeCounterPlayer, add: boolean): void {
    if (player.wins === 0 && !add) { return }
    else {
      if (this.params.local) {
        // define new doc
        const doc = JSON.parse(JSON.stringify(this.lifeCounterDoc$.getValue())) as LifeCounterDocument
        // add player
        doc.players[player.id].wins = add ? doc.players[player.id].wins + 1 : doc.players[player.id].wins - 1
        // save doc
        localStorage.setItem('tolaria-life-counter', JSON.stringify(doc))
        // update local doc
        this.lifeCounterDoc$.next(doc)
      }
      else {
        this.afs.collection('tolaria-life-counter').doc(this.lifeCounterDoc$.getValue().docId).update({
          [`players.${player.id}.wins`]: add ? player.wins + 1 : player.wins - 1
        })
      }
    }
  }

  public changeLife(player: LifeCounterPlayer, change: number): void {
    // calculate new life
    const currentLife = player.life[0]
    const newLife = currentLife + change

    // get player and add change to history
    const players = this.players$.getValue()
    players.find(p => p.id === player.id).life.unshift(newLife)
    this.players$.next(players)

    // clear timer
    console.log(`clear timer`)
    clearTimeout(this.lifeUpdateTimer)
    console.log(`set timer ${new Date().getTime()}`)

    // start timer
    this.lifeUpdateTimer = setTimeout(() => {
      // get untouched player
      const untouchedPlayer = this.playersUntouched$.getValue().find(p => p.id === player.id)
      console.log(untouchedPlayer)
      // add change
      untouchedPlayer.life.unshift(newLife)
      // get life counter doc
      const doc = JSON.parse(JSON.stringify(this.lifeCounterDoc$.getValue())) as LifeCounterDocument
      console.log(`perform update`)

      if (this.params.local) {
        // update player
        doc.players[player.id].life = untouchedPlayer.life
        // store document
        localStorage.setItem('tolaria-life-counter', JSON.stringify(doc))
        // update local doc
        this.lifeCounterDoc$.next(doc)
      }
      else {
        // update players life history
        this.afs.collection('tolaria-life-counter').doc(doc.docId).update({
          [`players.${player.id}.life`]: untouchedPlayer.life
        })
      }
    }, 2000)

  }

  public resetLife(player: LifeCounterPlayer): void {
    // get life counter
    const doc = JSON.parse(JSON.stringify(this.lifeCounterDoc$.getValue())) as LifeCounterDocument

    // perform update
    if (this.params.local) {
      // add life
      doc.players[player.id].life.unshift(20, null)
      // update document
      localStorage.setItem('tolaria-life-counter', JSON.stringify(doc))
      // update life counter
      this.lifeCounterDoc$.next(doc)
    }
    else {
      // add new life
      player.life.unshift(20, null)
      this.afs.collection('tolaria-life-counter').doc(doc.docId).update({
        [`players.${player.id}.life`]: player.life
      })
    }
  }

  public deletePlayer(player: LifeCounterPlayer): void {
    if (this.params.local) {
      // get life counter
      const doc = JSON.parse(JSON.stringify(this.lifeCounterDoc$.getValue())) as LifeCounterDocument
      // delete player
      delete doc.players[player.id]
      // update document
      localStorage.setItem('tolaria-life-counter', JSON.stringify(doc))
      // update life counter
      this.lifeCounterDoc$.next(doc)
    }
    else {
      this.afs.collection('tolaria-life-counter').doc(this.lifeCounterDoc$.getValue().docId).update({
        [`players.${player.id}`]: firestore.deleteField()
      })
    }
  }

  public resetAll(): void {
    // get life counter
    const doc = JSON.parse(JSON.stringify(this.lifeCounterDoc$.getValue())) as LifeCounterDocument
    Object.keys(doc.players).forEach(key => {
      doc.players[key].life.unshift(20, null)
    })

    if (this.params.local) {
      // update document
      localStorage.setItem('tolaria-life-counter', JSON.stringify(doc))
      // update life counter
      this.lifeCounterDoc$.next(doc)
    }
    else {
      this.updateCounterDocument(doc)
    }
  }

  private copyTextToClipboard(text: string) {
    const el = document.createElement('textarea')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    this.toastService.show('Player link copied to clipboard', { classname: 'standard-toast', delay: 2000 })
  }

  public get viewMode(): boolean {
    return this.params?.view
  }

  public get useTwoCols(): boolean {
    return Object.keys(this.lifeCounterDoc$.getValue().players).length > 3
  }

  public get isMobile(): boolean {
    return this.globals.isMobile
  }

  public get isLoggedIn(): boolean {
    return this.auth.user !== undefined
  }

  public get isLocal(): boolean {
    return this.params.local
  }

}

interface LifeCounterDocument {
  created: number
  docId: string
  players: {
    [key: string]: LifeCounterPlayer
  }
}
interface LifeCounterPlayer {
  id: string
  name: string
  life: Array<number | null>
  wins: number
}
interface LifeCounterQueryParams {
  id?: string
  match?: string
  player?: string
  view?: boolean
  local?: boolean
}

<div class="filter-panel d-flex align-items-center">
  <!-- DRAFT NAME -->
  <div class="d-inline-block m-1 h-100">
    <input type="text" class="form-control form-control-sm" placeholder="Filter by name" [(ngModel)]="draftService.filter.nameString" style="min-width: 16rem; font-size: .875rem !important;">
  </div>
  <!-- STATUS -->
  <div ngbDropdown class="d-inline-block m-1">
    <button class="btn btn-outline-secondary btn-sm" id="dropDownStatus" ngbDropdownToggle>
      <fa-icon [icon]="faFilter" [fixedWidth]="true" class="me-2"></fa-icon>Status
    </button>
    <div ngbDropdownMenu aria-labelledby="dropDownStatus" class="drop-down-panel">
      <ng-container *ngFor="let item of draftService.filter.status">
        <div class="row pt-1 pb-1 {{ item.selected ? '' : 'text-secondary' }} drop-down-item"
          (click)="item.selected = !item.selected">
          <div class="col">{{ item.text }}</div>
          <div class="col-2 text-end me-2">
            <fa-icon [fixedWidth]="true" [icon]="item.selected ? faCheckSquare : faSquare"></fa-icon>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <!-- CLEAR -->
  <button class="btn text-red btn-sm m-1 float-end" (click)="draftService.clearFilters()">Clear filters</button>
  <div class="flex-grow-1"></div>
  <button class="btn btn-sm btn-outline-secondary m-1" (click)="newDraft()" *ngIf="isOrganizer">New Draft
  </button>
</div>

<section class="p-1" *ngIf="drafts$ | async | drafts : draftService.filter as drafts">

  <div class="background">
    <fa-icon [icon]="draftIcon" fixedWidth="true"></fa-icon>
  </div>

  <div class="card-group">

    <div class="card m-1" *ngFor="let draft of drafts; trackBy : tracker">

      <div class="card-body no-hover p-3">

        <div class="inner-header d-flex align-items-center mb-3">
          <div class="h3 flex-grow-1">
            <span>{{ draft.name }}</span>
          </div>
          <div class="action-wrapper" *ngIf="draft.createdByUid === auth.user.uid || isAdmin">
            <div ngbDropdown class="d-inline-block" placement="bottom-right" container="body">
              <button class="btn btn-blank btn-sm" id="dropdownBasic1" ngbDropdownToggle ngbTooltip="Draft actions"
                container="body">
                <fa-icon [icon]="faEllipsisH" [fixedWidth]="true"></fa-icon>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="seatPlayers(draft)"
                  [disabled]="draft.playerDocIds.length < 2 || draft.statusCode !== 1">Seat players</button>
                <button ngbDropdownItem (click)="editDraft(draft.docId)">Edit</button>
                <div class="dropdown-divider"></div>
                <button ngbDropdownItem (click)="deleteDraft(draft.docId)"><span
                    class="text-danger">Delete</span></button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <h6 class="card-subtitle organizer mb-2" container="body" placement="top-left" ngbTooltip="Organizer">
              <fa-icon [fixedWidth]="true" [icon]="faHatWizard" class="me-2"></fa-icon>
              <app-player-display-name [playerUid]="draft.createdByUid" position="right"></app-player-display-name>
            </h6>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <h6 class="card-subtitle mb-2 text-secondary" container="body" placement="top-left" ngbTooltip="Draft date">
              <fa-icon [fixedWidth]="true" [icon]="faCalendarAlt" class="me-2"></fa-icon>
              <app-timestamp-clock [timestamp]="draft.datestamp" [seconds]="true" displayType="date">
              </app-timestamp-clock>
            </h6>
          </div>
          <div class="col-6">
            <h6 class="card-subtitle mb-2 text-secondary" container="body" placement="top-left"
              ngbTooltip="Starting time (local time)">
              <fa-icon [fixedWidth]="true" [icon]="faClock" class="me-2"></fa-icon>
              <app-timestamp-clock [timestamp]="draft.datestamp" [seconds]="true" displayType="time">
              </app-timestamp-clock>
            </h6>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <h6 class="card-subtitle text-secondary mb-2" container="body" placement="top-left"
              ngbTooltip="Attending players">
              <fa-icon [fixedWidth]="true" [icon]="faUsers" class="me-2"></fa-icon>{{ draft.playerDocIds.length }} /
              {{ draft.playerCap === 0 || draft.playerCap === null ? '8' : draft.playerCap }}
            </h6>
          </div>
        </div>

        <div class="type me-3 mb-3">
          <div class="text-secondary" *ngIf="draft.isRotisserie">Rotisserie Draft</div>
          <div class="text-secondary" *ngIf="draft.isBooster">Booster Draft</div>
          <div class="text-secondary" *ngIf="draft.isRochester">Rochester Draft</div>
        </div>

        <ng-container *ngIf="draft.isRotisserie">
          <div class="packs-wrapper d-flex">
            <ng-container *ngFor="let set of draft.sets">
              <div class="text-center me-2" [ngbTooltip]="set.name" placement="top" container="body">
                <ng-container *ngIf="set.customIcon; else keyruneIconB">
                  <fa-icon [icon]="fontIcons.setIcons[set.keyruneCode]" [fixedWidth]="true"></fa-icon>
                </ng-container>
                <ng-template #keyruneIconB>
                  <i class="ss ss-2x ss-{{ set.keyruneCode.toLowerCase() }} ss-fw"></i>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="draft.isBooster || draft.isRochester">
          <div class="packs-wrapper d-flex">
            <div class="text-center me-2" [ngbTooltip]="draft.packs.a.name" placement="top" container="body">
              <ng-container *ngIf="draft.packs.a.customIcon; else keyruneIconA">
                <fa-icon [icon]="fontIcons.setIcons[draft.packs.a.keyruneCode]" [fixedWidth]="true"></fa-icon>
              </ng-container>
              <ng-template #keyruneIconA>
                <i class="ss ss-2x ss-{{ draft.packs.a.keyruneCode.toLowerCase() }} ss-fw"></i>
              </ng-template>
            </div>
            <div class="text-center me-2" [ngbTooltip]="draft.packs.b.name" placement="top" container="body">
              <ng-container *ngIf="draft.packs.b.customIcon; else keyruneIconB">
                <fa-icon [icon]="fontIcons.setIcons[draft.packs.b.keyruneCode]" [fixedWidth]="true"></fa-icon>
              </ng-container>
              <ng-template #keyruneIconB>
                <i class="ss ss-2x ss-{{ draft.packs.b.keyruneCode.toLowerCase() }} ss-fw"></i>
              </ng-template>
            </div>
            <div class="text-center me-2" [ngbTooltip]="draft.packs.c.name" placement="top" container="body">
              <ng-container *ngIf="draft.packs.c.customIcon; else keyruneIconC">
                <fa-icon [icon]="fontIcons.setIcons[draft.packs.c.keyruneCode]" [fixedWidth]="true"></fa-icon>
              </ng-container>
              <ng-template #keyruneIconC>
                <i class="ss ss-2x ss-{{ draft.packs.c.keyruneCode.toLowerCase() }} ss-fw"></i>
              </ng-template>
            </div>
          </div>
        </ng-container>

      </div>

      <!-- STATUS & ACTIONS -->
      <div class="card-footer p-2 ps-3 pe-3">

        <div class="text-secondary w-100 text-center" [innerText]="draft.statusText"></div>

        <ng-container *ngIf="!draft.playerDocIds.includes(auth.user.playerId)">

          <div class="mt-2 wrapper">

            <ng-container *ngIf="draft.playerDocIds.length < draft.playerCap; else playerCapReached">
              <button class="btn btn-sm w-100 btn-secondary me-3"
                *ngIf="!draft.playerDocIds.includes(auth.user.playerId) && draft.playerDocIds.length < draft.playerCap && draft.statusCode === 1"
                (click)="attendDraft(draft.docId)">Attend</button>
            </ng-container>

            <ng-template #playerCapReached>
              <div class="text-secondary w-100 text-center" *ngIf="!draft.playerDocIds.includes(auth.user.playerId)">Player
                cap reached, no
                more
                seats left.</div>
            </ng-template>

          </div>
        </ng-container>

        <ng-container *ngIf="draft.playerDocIds.includes(auth.user.playerId)">

          <button class="btn btn-sm btn-blank mt-2 w-100 text-danger" *ngIf="draft.statusCode < 3"
            (click)="unattendDraft(draft.docId)">Unattend</button>

          <button class="btn btn-sm btn-main mt-2 w-100" *ngIf="draft.statusCode > 1"
            (click)="openDraftRoom(draft)">Open Draft Room</button>

        </ng-container>

      </div>

    </div>

  </div>


</section>

import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { EmojiCategory, EmojiItem, emojis } from './emojis'
import { IconDefinition, faFlag, faFootballBall, faGrinAlt, faHamburger, faLeaf, faLightbulb, faPeace, faPlane, faSearch } from '@fortawesome/free-solid-svg-icons'
import { applicationIcon } from 'src/assets/font-icons/tolaria-icon'
import { PlayerNameService } from 'src/app/services/players/player-name.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TolariaNewEmojiComponent } from './tolaria-new-emoji/tolaria-new-emoji.component'
import { Octokit } from '@octokit/rest'
import { nanoid } from 'nanoid'
import { AngularFirestore } from '@angular/fire/compat/firestore'
// import { UpdateEmojiMap } from '../tolaria-message-editor/emoji-map'

interface PickerTabLink {
  category: string
  icon: IconDefinition
  selected: boolean
}
@Injectable({
  providedIn: 'root'
})
export class EmojiService {

  public allEmojis$ = new BehaviorSubject<EmojiItem[]>(null)
  public emojis$ = new BehaviorSubject<EmojiCategory[]>(null)
  public tabs$ = new BehaviorSubject<PickerTabLink[]>(null)
  public searchResult$ = new BehaviorSubject<EmojiItem[]>(null)
  public search: string = ''

  constructor(
    private readonly playerNames: PlayerNameService,
    private readonly modalService: NgbModal,
    private readonly firestore: AngularFirestore,
  ) {
    this.initEmojis()
    this.emojis$.subscribe(i => {
      if (i !== null) {
        this.updateAllEmojis(i)
      }
    })
    this.firestore
      .collection('app')
      .doc('custom-emojis')
      .valueChanges()
      .subscribe(doc => this.updateCustomEmojis(doc))
    this.allEmojis$.subscribe(i => {
      localStorage.setItem('tolaria-emojis', JSON.stringify(i))
    })
  }

  private async initEmojis() {

    // color indexes
    const emojiColorIndex = [1,2,3,4,5,6]

    // standard emoji list
    const data = emojis.map((c, i) => {
      c.icons = c.icons.map((e, a) => {
        e.colorindex = emojiColorIndex[a % emojiColorIndex.length]
        return e
      })
      return c
    })

    // add custom emojis category
    const category: EmojiCategory = {
      category: 'custom',
      text: 'Custom emojis',
      icons: [],
    }
    data.push(category)


    // set data
    this.emojis$.next(data)


    const tabs: PickerTabLink[] = data.map((c) => {
      return {
        category: c.category,
        icon: this.tabIcons[c.category],
        selected: false,
      }
    })
    tabs.unshift({
      category: 'search',
      icon: this.tabIcons.search,
      selected: true,
    })
    this.tabs$.next(tabs)

  }

  private updateAllEmojis(data: EmojiCategory[]) {

    // create the list of all individual icons
    const all = data.map(i => i.icons).flat()
    this.allEmojis$.next(all)
    console.log('EmojiService:: updating all emojis', all)
    this.updateEmojiStyles(all)

  }

  private updateEmojiStyles(data: EmojiItem[]) {

    // Try to find the emoji sheet
    let emojiSheet = Object.values(document.styleSheets).find(i => i.title === 'tolariaEmojiStyleSheet')

    // Create a new sheet if it does not exist
    if (emojiSheet === undefined) {
      let tmp = document.createElement('style')
      tmp.title = 'tolariaEmojiStyleSheet'
      document.head.appendChild(tmp)
      emojiSheet = Object.values(document.styleSheets).find(i => i.title === 'tolariaEmojiStyleSheet')
    }

    // Remove existing styles
    while (emojiSheet.cssRules.length > 0) {
      emojiSheet.deleteRule(0)
    }

    // Add or update styles based on the array
    data.forEach((item: EmojiItem) => {
      const rule = `img.te-${item.name} { content: url('${encodeURI(item.image)}'); }`
      emojiSheet.insertRule(rule, 0)
    })

  }

  private updateCustomEmojis(doc: any) {
    const emojis: EmojiItem[] = Object.entries(doc).map(([key, val]) => {
      return val as EmojiItem
    })
    let data = this.emojis$.getValue()
    let custom = data.find(i => i.category === 'custom')
    custom.icons = emojis
    this.emojis$.next(data)
  }

  public selectTab(selectedTab: string) {
    if (this.tabs$.getValue() !== null) {
      let tabs = this.tabs$.getValue()
      let tab = tabs.find(i => i.category === selectedTab)
      if (tab) {
        tabs.forEach(i => i.selected = false)
        tab.selected = true
        this.tabs$.next(tabs)
      }
    }
  }

  public updateSearchResult(): void {
    let s = this.search.toLowerCase()
    const allEmojis = this.allEmojis$.getValue()
    const result = allEmojis.filter(i => {
      if (i.unicodes.join('').toLowerCase().includes(s)) {
        return i
      }
      if (i.name.toLowerCase().includes(s)) {
        return i
      }
      if (i.search.join('').toLowerCase().includes(s)) {
        return i
      }
    })
    this.searchResult$.next(result)
  }

  public newEmoji() {
    const modal = this.modalService.open(TolariaNewEmojiComponent, {
      centered: true,
    })

    modal
      .result
      .then((data: any) => {
        if (data.base64 && data.shortcode) {
          this.saveEmojiData(data.base64, data.shortcode)
        }
      })
      .catch((e) => console.error(e))
  }

  private async saveEmojiData(base64: string, shortcode: string) {
    let octokit = new Octokit({
      auth: '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'
    })

    // create strict base64 string as this is demanded by github
    const strictBase64 = base64.replace(/^data:image\/png;base64,/, '')

    // Create payload params
    const owner = 'Slanfan'
    const repo = 'TolariaEmojis'
    const message = `New emoji with shortcode :${shortcode}: created by ${this.playerNames.currentPlayersMini.name.display}`
    const content = strictBase64
    const id = nanoid(10)
    const filename = `${id}.png`

    // Create payload
    const payload = {
      owner,
      repo,
      message,
      path: `emojis/${filename}`,
      content,
    }

    // Upload content to github
    const result = await octokit.repos.createOrUpdateFileContents(payload)

    // Update custom emojid document
    await this.firestore.collection('app').doc('custom-emojis').update({
      [`${shortcode}`]: {
        image: `https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/${filename}`,
        name: shortcode,
        shortcode: `:${shortcode}:`,
        search: shortcode.split('_'),
        unicodes: [],
      }
    })

    console.log(`Created commit at ${result.data.commit.html_url}`)

  }

  public emojiByShortcode(short: string): EmojiItem | null {
    let e = this.allEmojis$.getValue().find(i => i.shortcode === short)
    if (e) { return e }
    return null
  }

  public tabIcons = {
    search: faSearch,
    smileys_and_people: faGrinAlt,
    animals_and_nature: faLeaf,
    food_and_drink: faHamburger,
    travel_and_places: faPlane,
    activities: faFootballBall,
    objects: faLightbulb,
    symbols: faPeace,
    flags: faFlag,
    custom: applicationIcon,
  }

  public get selectedTab(): string {
    let tabs = this.tabs$.getValue()
    if (tabs === null) { return 'search' }
    let selected = tabs.find(i => i.selected)
    if (!selected) { return 'search' }
    return selected.category
  }

  public get isSearch(): boolean {
    return this.selectedTab === 'search'
  }

  public get isSearching(): boolean {
    return this.search !== ''
  }

  public get isHero(): boolean {
    return this.playerNames.currentPlayerIsHero || this.playerNames.currentPlayerIsAdmin
  }

}

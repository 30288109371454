import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-draft-rotisserie',
  templateUrl: './draft-rotisserie.component.html',
  styleUrls: ['./draft-rotisserie.component.css']
})
export class DraftRotisserieComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    const init = true;
  }

}

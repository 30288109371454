<ng-template #pickerPopover=>
  <tolaria-emoji-picker (onSelected)="onEmojiSelect($event)" (onClose)="popover.close()"></tolaria-emoji-picker>
</ng-template>
<span #popover="ngbPopover"
  [ngbPopover]="pickerPopover"
  [placement]="placement"
  [popperOptions]="popperOptions"
  triggers="manual"
  container="body"
  autoClose="outside"
  popoverClass="tolaria-emoji-picker-popover"
></span>
<button
  [ngbTooltip]="tooltipText"
  [disableTooltip]="tooltipDisabled"
  container="body"
  (click)="popover.open()"
  class="{{ buttonStyle }} {{ isHovered ? buttonHoverStyle : '' }}"
  (mouseenter)="isHovered = true"
  (mouseleave)="isHovered = false">
  <span [innerText]="buttonText" *ngIf="showText"></span>
  <fa-icon [ngClass]="iconStyle" [icon]="isHovered ? buttonHoverIcon : buttonIcon" *ngIf="showIcon" [fixedWidth]="true"></fa-icon>
</button>
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { IPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { PlayerMetaService } from './players/player-meta.service';
import { IPlayerMeta } from './player.service';

@Injectable({
  providedIn: 'root'
})
export class DownToPlayService {

  public players$: Observable<IPlayerDetails[]>
  public playerMeta$ = new BehaviorSubject<IPlayerMeta[]>(null)

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly playerMeta: PlayerMetaService,
  ) {

    this.players$ = this.firestore.collection<IPlayerDetails>('players', ref => ref
        .where('presence.downToPlay', '==', true)
        .where('presence.status', 'in', ['away', 'online'])
      ).valueChanges()

    this.players$.subscribe(async (docs) => {
      console.log('DownToPlayService:: players$ emitted a new value', docs)
      let playersMeta: IPlayerMeta[] = []
      for await (const doc of docs) {
        console.log('DownToPlayService:: fetching meta data for player with document id:', doc.docId)
        let meta = await this.playerMeta.getPlayerMeta(doc.docId)
        playersMeta.push(meta)
      }
      this.playerMeta$.next(playersMeta)
    })

    this.playerMeta$.subscribe(players => {
      console.log('DownToPlayService:: playerMeta$ emitted a new value', players)
    })

  }

}

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input } from '@angular/core';
import { IRegistrationFeePayment, ITicketMeta, PaymentFactor } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-registration-fee-details',
  template: `
  <div class="modal-header">Payment Details</div>
  <div class="modal-body">
    <!-- <pre>{{ paymentDetails | json }}</pre> -->
    <div class="mb-2 row">
      <div class="col-4 text-secondary">Payment Type</div>
      <div class="col">{{ paymentType }}</div>
    </div>
    <div class="mb-2 row">
      <div class="col-4 text-secondary">Amount</div>
      <div class="col">{{ amount }}<span class="text-secondary text-small ms-2">{{ currency }}</span></div>
    </div>
    <div class="mb-2 row" *ngIf="hasComment">
      <div class="col-4 text-secondary">Comment</div>
      <div class="col">{{ comment }}</div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-sm btn-blank" (click)="modal.dismiss()">Close</button>
  </div>
  `,
  styles: [`

  `]
})
export class RegistrationFeeDetailsComponent {
  @Input() paymentDetails: IRegistrationFeePayment | ITicketMeta


  constructor(
    public modal: NgbActiveModal
  ) { }


  public get paymentType(): string {
    if (this.paymentDetails.hasOwnProperty('productType')) {
      const details = this.paymentDetails as ITicketMeta
      return details.productType
    }
    else {
      const details = this.paymentDetails as IRegistrationFeePayment
      return details.paymentType
    }
  }

  public get currency(): string {
    return this.paymentDetails.currency
  }

  public get amount(): number {
    if (this.paymentDetails.hasOwnProperty('productType')) {
      const details = this.paymentDetails as ITicketMeta
      return details.ticketAmount / PaymentFactor
    }
    else {
      const details = this.paymentDetails as IRegistrationFeePayment
      return details.amount / PaymentFactor
    }
  }

  public get hasComment(): boolean {
    if ((this.paymentDetails as IRegistrationFeePayment)?.comment) {
      return (this.paymentDetails as IRegistrationFeePayment).comment.length > 0
    }
    return false
  }

  public get comment(): string {
    if (this.hasComment) {
      return (this.paymentDetails as IRegistrationFeePayment).comment
    }
    return ''
  }

  public get isRefunded(): boolean {
    return this.paymentDetails?.refunded
      ? this.paymentDetails.refunded
      : false
  }

  public get refundAmount(): number {
    if (this.isRefunded) {
      if (this.paymentDetails.hasOwnProperty('productType')) {
        const details = this.paymentDetails as ITicketMeta
        return (details.ticketAmount * -1) / PaymentFactor
      }
      else {
        const details = this.paymentDetails as IRegistrationFeePayment
        return (details.amountRefunded * -1) / PaymentFactor
      }
    }
  }


//   paid: boolean;
//   refunded: boolean;
//   status: PaymentStatus;
//   timestamp: number;
//   amount: number;
//   amountRefunded: number;
//   currency: string | null;
//   charityAmount: number;
//   paymentType: PaymentType;
//   comment?: string;
//   paymentIntent?: string | Stripe.PaymentIntent | null;
//   metadata?: IRegistrationFeeMeta | ITicketMeta
//   ticketPurchase?: boolean

}

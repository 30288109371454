import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { EventService } from 'src/app/services';
import { faSquare, faCheckSquare, faQuestionCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces';
import { IEventPlayerDetails, IEventDetails, IEventGroup, IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IEventPlayoffPlayer } from '../event-playoff-config/event-playoff-config.component';
import { Match } from 'autolinker';

@Component({
  selector: 'app-event-to-group-config',
  templateUrl: './event-to-group-config.component.html',
  styleUrls: ['./event-to-group-config.component.css']
})


export class EventToGroupConfigComponent implements OnInit, OnDestroy {

  @Input() eventDocId: string;

  numberOfGroups = null;
  matchesPerPlayer = null;
  roundRobin = false;
  allowByes = false;
  autoGenerateGroups = true;
  showHelp = false;

  private groupNames: Array<string> = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  groups: Array<IEventGroup> = [];

  faSquare = faSquare;
  faCheckSquare = faCheckSquare;
  faQuestionCircle = faQuestionCircle;
  faExclamationTriangle = faExclamationTriangle;

  eventDoc$: Observable<IEventDetails>;
  eventDoc: IEventDetails;

  players$: Observable<IEventPlayerDetails[]>;
  players: Array<IEventPlayerDetails>;

  private componentWasDestroyed$ = new Subject<boolean>();

  constructor(
    private eventService: EventService,
    private activeModal: NgbActiveModal
  ) {
  }

  public tracker(i: number, item: IEventPlayerDetails) {
    return item.playerDocId
  }

  ngOnInit(): void {
    console.log(this.eventDocId);
    this.eventDoc$ = this.eventService.getEventById(this.eventDocId);
    this.eventDoc$.pipe(
      takeUntil(this.componentWasDestroyed$),
    ).subscribe((event) => {
      this.eventDoc = event;
      console.log(event);
    });

    this.players$ = this.eventService.getEventPlayersByEventId(this.eventDocId);
    this.players$.pipe(
      takeUntil(this.componentWasDestroyed$),
    ).subscribe((players) => {
      console.log(players);
      this.players = players;
    });

  }

  ngOnDestroy(): void {
    console.log('destroy component');
    this.componentWasDestroyed$.next(true);
  }

  createMatches(event: IEventDetails) {
    event.details.structure.group.matchesPerGroup = this.matchesPerPlayer;
    event.details.structure.group.numberOfGroups = this.numberOfGroups;
    this.eventService.eventPairGroup(event, this.groups, this.roundRobin, this.allowByes)
      .then((res: IPromiseResponse) => {
        if (res.status) {
          // update event data
          this.eventService.updateEventData(event).then(() => {
            // update event status
            this.eventService.eventUpdateStatus(event, 3, false);
            // close the modal
            this.activeModal.close();
          });
        }
        else {
          // handle errors
        }
      });
  }
  get groupsInvalid() {
    if (this.groups.length === 0) {
      return true;
    }
    return this.groups.filter(g => !g.canBePaired).length > 0;
  }
  get maxPlayersPerGroup() {
    return Math.ceil(this.playerCount / 2);
  }
  get playerCount() {
    return this.players ? this.players.filter(p => p.dropped === false).length : null;
  }
  get playersPerGroup() {
    return Math.ceil(this.playerCount / this.numberOfGroups);
  }
  get minPlayersInGroups() {
    return Math.floor(this.playerCount / this.numberOfGroups);
  }
  get maxMatchesPerPlayer() {
    return this.minPlayersInGroups - 1;
  }
  get newGroups() {
    const groups: Array<IEventGroup> = [];

    const playerListOutput = [];
    this.players.forEach(player => {
      playerListOutput.push(player.name);
    });
    console.log(JSON.stringify(playerListOutput));

    const playerList: Array<IEventPlayerDetails> = this.shuffle(JSON.parse(JSON.stringify(this.players.filter(p => p.dropped === false))));
    for (let i = 0; i < this.numberOfGroups; i++) {
      const players = playerList.splice(0, this.playersPerGroup);
      groups.push({
        name: 'GROUP ' + this.groupNames[i],
        players,
        canBePaired: this.canBePaired(players.length),
        warningText: this.compileWarningText(players.length)
      });
    }
    return groups;
  }
  public canBePaired(numberOfPlayersInGroup: number): boolean {
    if (this.roundRobin) {
      // round robin should be OK as long as there are 2 or more players
      return numberOfPlayersInGroup > 1;
    }
    if (this.allowByes) {
      // with byes allowed the number of players can be as many as the matches to be played but not more
      return numberOfPlayersInGroup > 1 && this.matchesPerPlayer <= numberOfPlayersInGroup;
    }
    if (!this.allowByes && !this.roundRobin) {
      if (this.matchesPerPlayer > 0) {
        // without byes allowed the number of unique matches needs to be a whole number
        return numberOfPlayersInGroup > 1 && numberOfPlayersInGroup > this.matchesPerPlayer && (this.matchesPerPlayer * (numberOfPlayersInGroup / 2)) % 1 === 0;
      }
      else {
        return false;
      }
    }
  }
  private compileWarningText(numberOfPlayersInGroup: number): string {

    if (numberOfPlayersInGroup < 2) {
      return '<p>You need to have at least 2 players in a group to be able to create pairings.</p>';
    }

    if (this.allowByes) {
      // with byes allowed the number of players can be as many as the matches to be played but not more
      if (this.matchesPerPlayer > numberOfPlayersInGroup) {
        return '<p>The number of matches to be played cannot be greater than the number of players within a group. (even with byes allowed)</p>';
      }
    }
    if (!this.allowByes && !this.roundRobin) {
      // without byes allowed the number of unique matches needs to be a whole number (x % 1 === 0)
      if ((this.matchesPerPlayer * (numberOfPlayersInGroup / 2)) % 1 !== 0) {
        return '<p>There is no possible way to create pairings for a group with odd number of players and odd number of matches without allowing byes.</p>';
      }
      if (this.matchesPerPlayer >= numberOfPlayersInGroup) {
        return '<p>Number of matches must be less than the number of players in a group unless you allow byes.</p>';
      }
    }

    return '';
  }
  public checkGroups() {
    this.groups.forEach(group => {
      group.canBePaired = this.canBePaired(group.players.length);
      group.warningText = this.compileWarningText(group.players.length);
    });
  }
  public generateGroups() {
    this.groups = this.newGroups;
  }
  private shuffle(array: any) {
    let currentIndex = array.length;
    let temporaryValue: any;
    let randomIndex: number;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
  dropped(event: any, groupIndex: number) {
    const player: IEventPlayerDetails = event.dropData;
    // remove player from previous group
    this.removePlayer(player.playerDocId).then((res: IPromiseResponse) => {
      if (res.status) {
        // add the player to the new group
        console.log(event.dropData);
        this.groups[groupIndex].players.push(player);
        console.log(this.groups);
      }
      else {
        console.log('error');
      }
    });
  }
  removePlayer(playerDocId: string) {
    return new Promise((resolve, reject) => {
      console.log(this.groups);
      const groupIndex = this.groups.findIndex(g => g.players.findIndex(p => p.playerDocId === playerDocId) !== -1);
      console.log(groupIndex);
      const playerIndex = this.groups[groupIndex].players.findIndex(p => p.playerDocId === playerDocId);
      if (playerIndex > -1 && groupIndex > -1) {
        this.groups[groupIndex].players.splice(playerIndex, 1);
        resolve({
          status: true,
          text: 'success'
        });
      }
      else {
        reject({
          status: false,
          text: 'error'
        });
      }
    });
  }
}

<ng-container *ngIf="eventDoc$ | async as event">
  <ng-container *ngIf="matches$ | async as matches">

    <ng-container *ngIf="!showPairingsFullView">
      <ng-container *ngIf="!isSwissTeamEvent; else swissTeamEvent">

        <div class="match-list" *ngIf="!globals.isMobile; else mobileView">
          <ng-container
            *ngFor="let match of matches | match: filterValue : selectedRound : showReportedMatches : selectedGroup : event; trackBy : matchTracker">

            <app-match-row [matchDocument]="match" [selectedRound]="selectedRound"
              [showAppointments]="event.details.isMultiDay && isOrganizer || event.details.isMultiDay && match.playerDocIds.includes(myPlayerDocId)">
            </app-match-row>


          </ng-container>
        </div>

        <ng-template #mobileView>
          <div class="match-list-wrapper mobile">
            <ng-container
              *ngFor="let match of matches | match: filterValue : selectedRound : showReportedMatches : selectedGroup : event; trackBy: matchTracker">

              <app-match-row [matchDocument]="match" [selectedRound]="selectedRound"></app-match-row>

            </ng-container>
          </div>
        </ng-template>

      </ng-container>

      <ng-template #swissTeamEvent>

        <ng-container *ngIf="swissTeams$ | async as eventTeams">

          <div class="team-match-list">

            <ng-container
              *ngFor="let match of matches | teamMatches : eventTeams : teamMatchPipeConfig; trackBy : matchTracker">

              <div class="team-match-list-item" *ngIf="!match.isReported || match.isReported && showReportedMatches">


                <div class="row text-large py-2">
                  <div class="col text-start">{{ match.team1.name }}</div>
                  <div class="col-2 text-center" *ngIf="!match.isFinished">{{ match.table }}</div>
                  <div class="col-2 text-center justify-content-center d-flex align-items-center"
                    *ngIf="match.isFinished">
                    <span class="badge text-bg-secondary px-2">
                      {{ match.result.team1wins > 2 ? 2 : match.result.team1wins }} - {{ match.result.draws }} - {{ match.result.team2wins > 2 ? 2 : match.result.team2wins }}
                    </span>
                  </div>
                  <div class="col text-end d-flex flex-column">
                    {{ match.team2.name }}
                  </div>
                </div>

                <div class="row px-3">

                  <div class="col team-player-matches mb-3" *ngIf="showTeamPlayerMatches">

                    <ul class="list-group">
                      <ng-container *ngFor="let seat of match.matches">
                        <li class="list-group-item cursor-pointer"
                          (click)="openReportSlip(seat, selectedRound)"
                          ngbTooltip="Click to open the report slip"
                          container="body">

                          <div class="row">
                            <div class="col text-start ">
                              <app-player-display-name [playerDocId]="seat.player1.playerDocId" [name]="seat.player1.displayName"></app-player-display-name>
                            </div>
                            <div class="col-2 text-center justify-content-center d-flex align-items-center">
                              <span class="badge text-bg-success px-2" *ngIf="!seat.isReported">
                                Seat {{ seat.teamSeat.toUpperCase() }}
                              </span>
                              <span class="badge text-bg-dark px-2" *ngIf="seat.isReported">
                                {{ seat.player1.wins }} - {{ seat.player1.draws }} - {{ seat.player1.losses }}
                              </span>
                            </div>
                            <div class="col text-end">
                              <app-player-display-name [playerDocId]="seat.player2.playerDocId" [name]="seat.player2.displayName"></app-player-display-name>
                            </div>
                          </div>

                        </li>
                      </ng-container>
                    </ul>

                  </div>



                  <!--
                  <div class="col">

                    <div class="team-seat-pairing d-flex flex-column align-items-center mb-2">
                      <div class="team-seat badge text-bg-primary">Seat {{ seat.teamSeat.toUpperCase() }}</div>
                      <div class="d-flex flex-column align-items-center">
                        <app-player-display-name [playerDocId]="seat.player1.playerDocId"></app-player-display-name>
                        <span class="text-secondary">versus</span>
                        <app-player-display-name [playerDocId]="seat.player2.playerDocId"></app-player-display-name>
                      </div>
                      <div class="my-3 badge text-bg-secondary" *ngIf="seat.isReported">
                        {{ seat.player1.wins }} - {{ seat.player1.draws }} - {{ seat.player1.losses }}
                      </div>
                      <div class="btn btn-sm btn-secondary mt-3" (click)="openReportSlip(seat, selectedRound)" *ngIf="isOrganizer">Report</div>
                    </div>

                  </div>
                    <div class="col">
                    <div class="team-seat-pairing d-flex flex-column align-items-center mb-2">
                      <div class="team-seat badge text-bg-success">Seat B</div>
                      <div class="d-flex flex-column align-items-center">
                        <app-player-display-name [playerDocId]="match.team1.player.b"></app-player-display-name>
                        <span class="text-secondary">versus</span>
                        <app-player-display-name [playerDocId]="match.team2.player.b"></app-player-display-name>
                      </div>
                    </div>

                  </div>

                  <div class="col">

                    <div class="team-seat-pairing d-flex flex-column align-items-center mb-2">
                      <div class="team-seat badge text-bg-info">Seat C</div>
                      <div class="d-flex flex-column align-items-center">
                        <app-player-display-name [playerDocId]="match.team1.player.c"></app-player-display-name>
                        <span class="text-secondary">versus</span>
                        <app-player-display-name [playerDocId]="match.team2.player.c"></app-player-display-name>
                      </div>
                    </div>

                  </div> -->

                </div>


              </div>

            </ng-container>

          </div>

        </ng-container>

      </ng-template>


      <ng-template let-modal #matchDeletionConfirmation>
        <div class="modal-body">
          <p class="text-warning">Are you sure you want to delete the match?</p>
          <p class="text-secondary">This action cannot be UNDONE, the match will be deleted and the match document erased from
            the server.</p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-blank btn-sm text-secondary" (click)="modal.dismiss()">Cancel</button>
          <button class="btn btn-blank btn-sm text-warning" (click)="modal.close(true)">Delete</button>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="showPairingsFullView">
      <ng-container *ngIf="matches | pairingsView: selectedRound as matches">
        <!-- <app-event-pairings-view [matches]="matches" [eventName]="event.details.name" [activeRound]="selectedRound" (showMe)="closePairingsView($event)"></app-event-pairings-view> -->
      </ng-container>
    </ng-container>


  </ng-container>
</ng-container>



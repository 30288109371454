import { PaymentService } from 'src/app/payment/payment.service';
import { BehaviorSubject, Subject, combineLatest, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { IEventDetails, IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { IRegistrationFeePayment, PaymentFactor, PaymentStatus, PaymentType, ProductType } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-issue-refund-registration-fee',
  templateUrl: './issue-refund-registration-fee.component.html',
  styleUrls: ['./issue-refund-registration-fee.component.css']
})
export class IssueRefundRegistrationFeeComponent implements OnInit, OnDestroy {
  @Input() set eventData(data: IEventDetails) {
    this.event$.next(data)
  }
  @Input() set playerData(data: IEventPlayerDetails) {
    this.player$.next(data)
  }

  public initDone$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null)
  public confirmRefundCheckBox: boolean = false
  public refundRegistrationFee: boolean = false
  public refundCharityAmount: boolean = false
  public event: IEventDetails
  public player: IEventPlayerDetails
  private event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  private player$: BehaviorSubject<IEventPlayerDetails> = new BehaviorSubject<IEventPlayerDetails>(null)
  private destroyed$: Subject<boolean> = new Subject<boolean>()
  private isRefunding: boolean = false

  constructor(
    public modal: NgbActiveModal,
    public toast: ToastService,
    private payment: PaymentService,
  ) { }

  ngOnInit(): void {

    combineLatest([this.event$, this.player$]).pipe(takeUntil(this.destroyed$)).subscribe(([event, player]) => {
      if (event !== null) { this.event = event }
      if (player !== null) { this.player = player }

      if (this.event !== undefined && this.player !== undefined) {
        this.initDone$.next(true)
      }

    })

  }
  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public refundTolariaPayment(): void {
    this.isRefunding = true
    const paidBy = this.event.details.registrationFee.paidBy[this.player.playerDocId] as any
    this.payment
      .issueRefund({
        amount: parseInt(paidBy.metadata.registrationFeeAmount),
        payment_intent: paidBy.paymentIntent,
      })
      .then(res => {
        console.log(res)
        this.modal.close(true)
      })
      .catch(err => {
        this.isRefunding = false
        console.log(err)
        this.toast.show(err, { classname: 'error-toast', delay: 6000 })
      })
  }

  public refundManualPayment(): void {
    this.isRefunding = true
    const registrationFee = this.event.details.registrationFee
    const fullRefund = (registrationFee.paidBy[this.player.playerDocId].amount + registrationFee.paidBy[this.player.playerDocId].charityAmount) === this.refundAmount
    this.payment
      .manualRefund(
        ProductType.REGISTRATION_FEE,
        fullRefund ? PaymentStatus.REFUNDED : PaymentStatus.PARTIALLY_REFUNDED,
        this.refundAmount,
        {
          eventDocId: this.event.docId,
          playerDocId: this.player.playerDocId,
          playerName: this.player.name,
          paymentType: this.event.details.registrationFee.paidBy[this.player.playerDocId].paymentType,
        })
      .then(res => {
        console.log(res)
        this.modal.close(true)
      })
      .catch(err => {
        this.isRefunding = false
        console.log(err)
        this.toast.show(err, { classname: 'error-toast', delay: 6000 })
      })
  }

  public get hasPaidRegistrationFee(): boolean {
    return this.event.details?.registrationFee?.paidBy && this.event.details?.registrationFee?.paidBy[this.player.playerDocId]
      ? this.event.details.registrationFee.paidBy[this.player.playerDocId].paid
      : false
  }
  public get hasBeenRefunded(): boolean {
    return this.event.details?.registrationFee?.paidBy && this.event.details?.registrationFee?.paidBy[this.player.playerDocId]
      ? this.event.details.registrationFee.paidBy[this.player.playerDocId].refunded
      : false
  }
  public get paidWithProvider(): PaymentType {
    if (this.hasPaidRegistrationFee || this.hasBeenRefunded) {
      return this.event.details?.registrationFee?.paidBy
        ? this.event.details?.registrationFee?.paidBy[this.player.playerDocId]?.paymentType
        : null
    }
    return null
  }
  public get paidWithTolaria(): boolean {
    return this.event.details?.registrationFee?.paidBy && this.event.details?.registrationFee?.paidBy[this.player.playerDocId]?.paymentType === PaymentType.TOLARIA
  }
  public get registrationFeeAmountString(): string {
    return (this.paidByInfo.amount / PaymentFactor).toFixed(2)
  }
  public get paidByInfo(): IRegistrationFeePayment {
    return this.event.details?.registrationFee?.paidBy
      ? this.event.details.registrationFee.paidBy[this.player.playerDocId]
      : null
  }
  public get charityAmountString(): string {
    return (this.paidByInfo.charityAmount / PaymentFactor).toFixed(2)
  }
  public get refundAmount(): number {
    return (this.refundRegistrationFee ? this.paidByInfo.amount : 0) + (this.refundCharityAmount ? this.paidByInfo.charityAmount : 0)
  }
  public get disableRefundButton(): boolean {
    if (this.isRefunding) { return true }
    if (this.paidWithProvider === PaymentType.TOLARIA) { return !this.confirmRefundCheckBox }
    else { return !this.confirmRefundCheckBox || !this.refundRegistrationFee }
  }

}

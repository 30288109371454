import * as firestore from 'firebase/firestore'
import { IEventPlayerDetails, MatchAssignement, MatchType, IMatchData, IMatchPlayer } from 'tolaria-cloud-functions/src/_interfaces'
import { v4 as uuidv4 } from 'uuid'

export class EventMatch {

  document: IMatchData = {
    docId: null,
    createdByUid: null,
    eventDocId: null,
    feedsMatchDocId: null,
    feedsMatchPlayer: null,
    winnerGoToMatchDoc: null,
    winnerGoToPlayerSlot: null,
    loserGoToMatchDoc: null,
    loserGoToPlayerSlot: null,
    roundNumber: null, // USED AS BATCH NUMBER WHEN BATCH MATCH
    tableNumber: null,
    groupName: null,
    segmentNumber: null,
    segmentType: null,
    isReported: false,
    isByeMatch: false,
    isLossMatch: false,
    isDraw: false,
    isType: null,
    player1: null,
    player2: null,
    playerDocIds: [],
    playerFilterValue: '',
    timestampCreated: null,
    timestampReported: null,
    deleted: false,
    reportSlipOpenedBy: null,
  }

  constructor(
    eventDocId: string,
    createdByUid: string,
    roundNumber: number,
    segmentNumber: number,
    tableNumber: number,
    type: MatchType,
    playerOne: IEventPlayerDetails,
    playerTwo: IEventPlayerDetails,
    assignment: MatchAssignement,
    documentId?: string,
  ) {
    this.document.docId = documentId === undefined ? uuidv4() : documentId
    this.document.createdByUid = createdByUid
    this.document.eventDocId = eventDocId
    this.document.roundNumber = roundNumber
    this.document.segmentNumber = segmentNumber
    this.document.tableNumber = tableNumber
    this.document.isType = type
    this.document.player1 = (playerOne === null || playerOne === undefined)
        ? (playerOne === null || playerOne === undefined) && type === 'bracket' || (playerOne === null || playerOne === undefined) && type === 'bracket-team'
          ? this.createEmptyMatchPlayer()
          : null
        : this.createMatchPlayer(playerOne, segmentNumber)


    if (assignment === null) {
      this.document.player2 = (playerTwo === null || playerTwo === undefined)
        ? (playerTwo === null || playerTwo === undefined) && type === 'bracket' || (playerTwo === null || playerTwo === undefined) && type === 'bracket-team'
          ? this.createEmptyMatchPlayer()
          : null
        : this.createMatchPlayer(playerTwo, segmentNumber)
    }
    if (assignment === 'Bye') {
      this.document.player2 = this.createMatchPlayerBYE(segmentNumber)
      this.document.isReported = true
      this.document.player1.isWinner = true
      this.document.player1.wins = 2
      this.document.player2.losses = 2
      this.document.player2.isWinner = false
      this.document.isByeMatch = true
    }
    if (assignment === 'Loss') {
      this.document.player2 = this.createMatchPlayerLOSS(segmentNumber)
      this.document.isReported = true
      this.document.player1.losses = 2
      this.document.player1.isWinner = false
      this.document.player2.wins = 2
      this.document.player2.isWinner = true
      this.document.isLossMatch = true
    }

    if (type === 'swiss-team') {
      this.document.teamIds = [playerOne.teamId, playerTwo === null ? '*** BYE ***' : playerTwo.teamId]
      this.document.player1.teamId = playerOne.teamId
      this.document.player2.teamId = playerTwo === null ? '*** BYE ***' : playerTwo.teamId
    }

    if (this.document.player1 !== null && this.document.player2 !== null) {
      this.document.playerDocIds = [this.document.player1.playerDocId, this.document.player2.playerDocId]
      this.document.playerFilterValue = segmentNumber !== null
        ? `${this.document.player1.displayName.toLowerCase()} ${this.document.player2.displayName.toLowerCase()} table:${this.document.tableNumber} s${segmentNumber}`
        : `${this.document.player1.displayName.toLowerCase()} ${this.document.player2.displayName.toLowerCase()} table:${this.document.tableNumber}`
    }

    if (playerOne === null && this.document.player1 === null) {
      this.document.player1 = this.createEmptyMatchPlayer()
    }
    if (playerTwo === null && this.document.player2 === null) {
      this.document.player2 = this.createEmptyMatchPlayer()
    }

    this.document.timestampCreated = firestore.Timestamp.now().seconds

  }

  createEmptyMatchPlayer(segment: number = null): IMatchPlayer {
    return {
      playerDocId: '',
      playerUid: '',
      displayName: '',
      isWinner: false,
      wins: 0,
      draws: 0,
      losses: 0,
      drop: false,
      matchPoints: 0,
      rank: 0,
      seed: 0,
      lifePoints: [20],
      segmentNumber: segment
    }
  }

  createMatchPlayer(player: IEventPlayerDetails, segment: number): IMatchPlayer {
    return {
      playerDocId: player.playerDocId,
      playerUid: player.playerUid,
      displayName: player.name,
      isWinner: false,
      wins: 0,
      draws: 0,
      losses: 0,
      drop: false,
      matchPoints: player.matchPoints ? player.matchPoints : 0,
      rank: player.rank ? player.rank : 0,
      seed: player.seed ? player.seed : 0,
      lifePoints: [20],
      segmentNumber: segment
    }
  }

  createMatchPlayerBYE(segment: number): IMatchPlayer {
    return {
      playerDocId: '*** BYE ***',
      playerUid: '*** BYE ***',
      displayName: '*** BYE ***',
      isWinner: false,
      wins: 0,
      draws: 0,
      losses: 0,
      drop: false,
      matchPoints: 0,
      rank: null,
      seed: null,
      lifePoints: [20],
      segmentNumber: segment
    }
  }

  createMatchPlayerLOSS(segment: number): IMatchPlayer {
    return {
      playerDocId: '*** LOSS ***',
      playerUid: '*** LOSS ***',
      displayName: '*** LOSS ***',
      isWinner: false,
      wins: 0,
      draws: 0,
      losses: 0,
      drop: false,
      matchPoints: 0,
      rank: null,
      seed: null,
      lifePoints: [20],
      segmentNumber: segment
    }
  }

}

<ng-container *ngIf="leagueData as league">

  <div class="modal-header text-large">League Configuration</div>

  <div class="modal-body p-0 d-flex" #editorWrapper>

    <div class="p-3 border-right main-view-wrapper">

      <div class="nav-wrapper d-flex flex-row align-items-center mb-3">
        <div class="nav-button h5 me-5" [ngClass]="{ 'selected' : view.details }" (click)="changeView('details')">
          Details</div>
        <div class="nav-button h5 me-5" [ngClass]="{ 'selected' : view.description }"
          (click)="changeView('description')">Description</div>
        <div class="nav-button h5 me-5" [ngClass]="{ 'selected' : view.banner }" (click)="changeView('banner')">Banner
        </div>
      </div>

      <ng-container *ngIf="view.details">

        <div class="form-group mb-3">
          <input type="text" class="form-control" id="name" placeholder="Name" [(ngModel)]="league.name">
        </div>

        <ngb-datepicker #dp class="w-100" [displayMonths]="2" [showWeekNumbers]="true" [dayTemplate]="t"
          navigation="arrows" outsideDays="collapsed" (dateSelect)="onDateSelection($event)">
        </ngb-datepicker>
        <ng-template #t let-date let-focused="focused" let-selected>
          <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>

        <div class="text-secondary d-flex align-items-center">
          <div *ngIf="league.timestampStart !== null">
            <app-timestamp-clock [timestamp]="league.timestampStart" displayType="date" [seconds]="true">
            </app-timestamp-clock>
          </div>
          <div *ngIf="league.timestampStart === null">
            <i>Please select a date</i>
          </div>
          <span class="mx-2">-</span>
          <div *ngIf="league.timestampEnd !== null">
            <app-timestamp-clock [timestamp]="league.timestampEnd" displayType="date" [seconds]="true">
            </app-timestamp-clock>
          </div>
          <div *ngIf="league.timestampEnd === null">
            <i>Please select a date</i>
          </div>
        </div>

      </ng-container>

      <ng-container *ngIf="view.description">
        <ckeditor #editor [config]="ckeConfig" [(ngModel)]="league.description" id="description" name="description"
          type="divarea">
        </ckeditor>
      </ng-container>

      <ng-container *ngIf="view.banner">
        <ng-container *ngIf="!change">
          <div class="row mt-3">
            <div class="col-9">
              <input type="file" id="customAvatar" name="file" #fileInput (change)="fileChangeEvent($event)">
            </div>
            <div class="col-3">
              <button class="btn btn-sm btn-secondary w-100" [disabled]="saveDisabled" (click)="saveImage()">Upload</button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <div class="text-secondary text-italic" width="100%" *ngIf="!showImagePreview">Select an image to upload as
                the event banner.</div>
              <image-cropper class="image-cropper" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                [aspectRatio]="2 / 1" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" *ngIf="showImagePreview">
              </image-cropper>
            </div>
            <div class="col-6">
              <img class="upload-preview" width="100%" [src]="croppedImage">
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="change">
          <div class="row">
            <div class="col-9">
              <div class="banner-image-wrapper">
                <img [src]="league.bannerUrl ? league.bannerUrl : ''">
              </div>
            </div>
            <div class="col-3">
              <button class="btn btn-sm btn-secondary w-100" (click)="change = false">Change</button>
              <button class="btn btn-sm btn-danger w-100 mt-2" (click)="removeBannerImage()">Delete</button>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>

    <div class="p-3 w-100">

      <div class="d-flex align-items-center mb-3">
        <div class="h5 flex-grow-1 m-0">Point Structure</div>
        <div class="btn btn-sm btn-blank" (click)="openNewPointModal()">
          <fa-icon [icon]="icons.faPlusCircle" [fixedWidth]="true"></fa-icon>
        </div>
      </div>

      <div class="league-point-wrapper">

        <div class="list-item d-flex align-items-center" *ngFor="let point of league.pointStructure; let i = index">

          <span class="px-2">{{ point.points }}p</span>
          <span class="px-2" [ngbTooltip]="point.type === 'CUSTOM' ? point.customExplaination : translations[point.type].tooltip" container="body">{{
            translations[point.type].text }}</span>
          <ng-container *ngIf="point.type === 'RANK'">
            <span class="px-2">{{ point.isRange ? point.rankFrom + ' - ' + point.rankTo : point.rankTo }}</span>
          </ng-container>
          <span class="trash-icon ms-2 flex-grow-1 text-end" (click)="removePoint(i)" placement="top" container="body"
            ngbTooltip="Delete">
            <fa-icon [fixedWidth]="true" [icon]="icons.faTrash"></fa-icon>
          </span>

        </div>

      </div>

    </div>

  </div>

  <div class="modal-footer d-flex align-items-center justify-content-end">
    <button class="btn btn-sm btn-blank text-red" (click)="close()">Cancel</button>
    <button class="btn btn-sm {{ dataChanged ? 'btn-main' : 'btn-blank' }}" (click)="save()"
      [disabled]="formInvalid">Save</button>
  </div>




  <ng-template #newPointModal let-modal>
    <div class="modal-header">
      New Point
    </div>

    <div class="modal-body">

      <div class="form-group">
        <label>Type</label>
        <select class="custom-select" aria-describedby="typeInfo" [(ngModel)]="newPoint.type">
          <option *ngFor="let type of pointTypes" [value]="type">{{ translations[type].text }}</option>
        </select>
        <small id="typeInfo" *ngIf="newPoint.type !== null" class="form-text text-secondary">{{
          translations[newPoint.type].tooltip }}</small>
      </div>

      <div class="form-group">
        <label>Points</label>
        <input type="number" class="form-control" [(ngModel)]="newPoint.points">
      </div>

      <ng-container *ngIf="newPoint.type === 'RANK'">
        <div class="form-group">
          <label>Ranking</label>
          <div class="form-group">
            <div class="form-check form-switch">
              <input type="checkbox" class="form-check-input" id="rangeRanking" [(ngModel)]="newPoint.isRange">
              <label class="form-check-label" for="rangeRanking">Ranged ranking?</label>
            </div>
          </div>
          <div class="d-flex align-items-center flex-row">
            <div class="flex-grow-1 form-group" *ngIf="!newPoint.isRange; else rangedRanking">
              <label>Rank</label>
              <input type="number" class="form-control" [(ngModel)]="newPoint.rankFrom"
                (change)="newPoint.rankTo = newPoint.rankFrom">
            </div>
            <ng-template #rangedRanking>
              <div class="flex-grow-1 form-group me-1">
                <label>Rank from</label>
                <input type="number" class="form-control" [(ngModel)]="newPoint.rankFrom">
              </div>
              <div class="flex-grow-1 form-group ms-1">
                <label>Rank to</label>
                <input type="number" class="form-control" [(ngModel)]="newPoint.rankTo">
              </div>
            </ng-template>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="newPoint.type === 'CUSTOM'">
        <div class="form-group">
          <label>Explaination</label>
          <textarea rows="3" maxlength="50" class="form-control" [(ngModel)]="newPoint.customExplaination"></textarea>
        </div>
      </ng-container>

    </div>

    <div class="modal-footer d-flex align-items-center justify-content-end">
      <button class="btn btn-sm btn-blank text-red" (click)="modal.close()">Cancel</button>
      <button class="btn btn-sm btn-blank" (click)="modal.dismiss('save')">Add Point</button>
    </div>
  </ng-template>

</ng-container>

<section class="player-details p-2">
  <div class="card card-larger full-height" *ngIf="player$ | async as player">
    <div class="card-header">
      <span [innerText]="player.displayName"></span>
      <div class="btn-close float-end" (click)="destroySelf()">
        <fa-icon [fixedWidth]="true" [icon]="icons.faTimes"></fa-icon>
      </div>
    </div>
    <div class="card-header position-relative">
      <div class="d-flex">
        <div class="image-wrapper">
          <img [src]="player.avatarUrl">
        </div>
        <div class="info-wrapper flex-grow-1 text-normal">
          <div class="description" [innerHTML]="player.description"></div>
        </div>
      </div>
    </div>
    <div class="card-body scrollable no-hover p-0">
      <div class="card-header position-relative">

        <div class="text-bg-wrapper">
          <app-player-badges-carousel [playerDocId]="player.docId"></app-player-badges-carousel>
        </div>

        <div class="info-wrapper text-normal">
          <div class="row mb-2">
            <div class="col-3 text-secondary">Country:</div>
            <div class="col" [innerText]="player.country"></div>
          </div>
          <div class="row mb-2">
            <div class="col-3 text-secondary">Region:</div>
            <div class="col" [innerText]="player.region"></div>
          </div>
          <div class="row mb-2">
            <div class="col-3 text-secondary">Sub-region:</div>
            <div class="col" [innerText]="player.subregion"></div>
          </div>
          <div class="row mb-2">
            <div class="col-3 text-secondary">Timezone:</div>
            <div class="col">
              {{ player.olson }} (UTC {{ player.UTC }})
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-3 text-secondary">Local time:</div>
            <div class="col" *ngIf="auth.user$ | async as user">
              <div *ngIf="user?.settings.clock24">
                {{ today | date: 'HH:mm' : player.UTC }}
              </div>
              <div *ngIf="!user?.settings.clock24">
                {{ today | date: 'h:mm a' : player.UTC }}
              </div>
            </div>
          </div>
          <ng-container *ngIf="player.addressIsPublic">
            <div class="row mb-2">
              <div class="col-3 text-secondary">Address: </div>
              <div class="col">
                <div class="row">
                  <div class="col">{{ player.addressStreet }}</div>
                </div>
                <div class="row">
                  <div class="col">{{ player.addressOther }}</div>
                </div>
                <div class="row">
                  <div class="col">{{ player.addressZipCode }} {{ player.addressCity }}</div>
                </div>
                <div class="row">
                  <div class="col">{{ player.addressRegion }}</div>
                </div>
                <div class="row">
                  <div class="col">{{ player.country }}</div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="player.emailIsPublic || player.phoneIsPublic">
            <div class="row mb-2" *ngIf="player.emailIsPublic">
              <div class="col-3 text-secondary">Email: </div>
              <div class="col">{{ player.email }}</div>
            </div>
            <div class="row mb-2" *ngIf="player.phoneIsPublic">
              <div class="col-3 text-secondary">Phone:</div>
              <div class="col">{{ player.phone }}</div>
            </div>
          </ng-container>
          <div class="row mt-4 mb-2">
            <div class="col">
              <app-quick-message [playerDocId]="player.docId"></app-quick-message>
            </div>
          </div>
          <ng-container *ngIf="player.handles.length > 0">
            <div class="row mb-2">
              <div class="col d-flex align-items-center flex-wrap">
                <div class="player-handle btn btn-blank text-secondary" *ngFor="let handle of player.handles" [ngbTooltip]="handle.handle" container="body">
                  <a [href]="handle.url" target="_blank" class="text-secondary" *ngIf="handle.url !== ''; else handleNoUrl">
                    <fa-icon [icon]="handleIcons[handle.platform]" [fixedWidth]="true"></fa-icon>
                  </a>
                  <ng-template #handleNoUrl>
                    <fa-icon [icon]="handleIcons[handle.platform]" [fixedWidth]="true"></fa-icon>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="card-header d-flex align-items-center" *ngIf="matches$ | async as matches" (click)="showDetailsMatches = !showDetailsMatches">
        <span class="flex-grow-1">Matches</span>
        <span class="badge text-bg-secondary rounded-pill float-end" [innerText]="matches.length"></span>
        <span class="text-secondary" [ngClass]="{ 'rotate-normal-90deg' : showDetailsMatches}">
          <fa-icon [fixedWidth]="true" [icon]="icons.faChevronRight"></fa-icon>
        </span>
      </div>
      <div class="card-header" *ngIf="showDetailsMatches">
        <div class="h6 mb-2 mt-4">Overall</div>
        <div class="wrapper d-flex" *ngIf="matchDetails$ | async as matchDetails">
          <div class="statics flex-grow-1 text-normal">
            <div class="row">
              <div class="col text-secondary">Matches Played</div>
              <div class="col-4" [innerHTML]="matchDetails.overall.matches"></div>
            </div>
            <div class="row">
              <div class="col text-secondary">Matches Won</div>
              <div class="col-4" [innerHTML]="matchDetails.overall.matchesWon"></div>
            </div>
            <div class="row">
              <div class="col text-secondary">Games Played</div>
              <div class="col-4" [innerHTML]="matchDetails.overall.gamesWon + matchDetails.overall.gamesLost"></div>
            </div>
            <div class="row">
              <div class="col text-secondary">Games Won</div>
              <div class="col-4" [innerHTML]="matchDetails.overall.gamesWon"></div>
            </div>
          </div>
          <div class="calculated d-flex flex-grow-1 align-items-center justify-content-center">
            <div class="stat-wrapper flex-grow-1">
              <div class="stat-intro text-secondary">Game Win %</div>
              <div class="stat" [innerHTML]="matchDetails.overall.gameWinPercentage"></div>
            </div>
            <div class="stat-wrapper flex-grow-1">
              <div class="stat-intro text-secondary">Match Win %</div>
              <div class="stat" [innerHTML]="matchDetails.overall.matchWinPercentage"></div>
            </div>
          </div>
        </div>
        <div class="h6 mb-2 mt-4">Competitive</div>
        <div class="wrapper d-flex" *ngIf="matchDetails$ | async as matchDetails">
          <div class="statics flex-grow-1 text-normal">
            <div class="row">
              <div class="col text-secondary">Matches Played</div>
              <div class="col-4" [innerHTML]="matchDetails.competitive.matches"></div>
            </div>
            <div class="row">
              <div class="col text-secondary">Matches Won</div>
              <div class="col-4" [innerHTML]="matchDetails.competitive.matchesWon"></div>
            </div>
            <div class="row">
              <div class="col text-secondary">Games Played</div>
              <div class="col-4" [innerHTML]="matchDetails.competitive.gamesWon + matchDetails.competitive.gamesLost"></div>
            </div>
            <div class="row">
              <div class="col text-secondary">Games Won</div>
              <div class="col-4" [innerHTML]="matchDetails.competitive.gamesWon"></div>
            </div>
          </div>
          <div class="calculated d-flex flex-grow-1 align-items-center justify-content-center">
            <div class="stat-wrapper flex-grow-1">
              <div class="stat-intro text-secondary">Game Win %</div>
              <div class="stat" [innerHTML]="matchDetails.competitive.gameWinPercentage"></div>
            </div>
            <div class="stat-wrapper flex-grow-1">
              <div class="stat-intro text-secondary">Match Win %</div>
              <div class="stat" [innerHTML]="matchDetails.competitive.matchWinPercentage"></div>
            </div>
          </div>
        </div>
        <div class="h6 mb-2 mt-4">Casual</div>
        <div class="wrapper d-flex" *ngIf="matchDetails$ | async as matchDetails">
          <div class="statics flex-grow-1 text-normal">
            <div class="row">
              <div class="col text-secondary">Matches Played</div>
              <div class="col-4" [innerHTML]="matchDetails.casual.matches"></div>
            </div>
            <div class="row">
              <div class="col text-secondary">Matches Won</div>
              <div class="col-4" [innerHTML]="matchDetails.casual.matchesWon"></div>
            </div>
            <div class="row">
              <div class="col text-secondary">Games Played</div>
              <div class="col-4" [innerHTML]="matchDetails.casual.gamesWon + matchDetails.casual.gamesLost"></div>
            </div>
            <div class="row">
              <div class="col text-secondary">Games Won</div>
              <div class="col-4" [innerHTML]="matchDetails.casual.gamesWon"></div>
            </div>
          </div>
          <div class="calculated d-flex flex-grow-1 align-items-center justify-content-center">
            <div class="stat-wrapper flex-grow-1">
              <div class="stat-intro text-secondary">Game Win %</div>
              <div class="stat" [innerHTML]="matchDetails.casual.gameWinPercentage"></div>
            </div>
            <div class="stat-wrapper flex-grow-1">
              <div class="stat-intro text-secondary">Match Win %</div>
              <div class="stat" [innerHTML]="matchDetails.casual.matchWinPercentage"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="card-header d-flex align-items-center">
        <span class="flex-grow-1">Decks</span>
        <span class="badge text-bg-secondary rounded-pill float-end" [innerText]=""></span>
        <span class="text-secondary ">
          <fa-icon [fixedWidth]="true" [icon]="icons.faChevronRight"></fa-icon>
        </span>
      </div>
      <div class="card-header d-flex align-items-center">
        <span class="flex-grow-1">Collection</span>
        <span class="badge text-bg-secondary rounded-pill float-end" [innerText]=""></span>
        <span class="text-secondary ">
          <fa-icon [fixedWidth]="true" [icon]="icons.faChevronRight"></fa-icon>
        </span>
      </div>
      <div class="card-header d-flex align-items-center">
        <span class="flex-grow-1">Trades</span>
        <span class="badge text-bg-secondary rounded-pill float-end" [innerText]=""></span>
        <span class="text-secondary ">
          <fa-icon [fixedWidth]="true" [icon]="icons.faChevronRight"></fa-icon>
        </span>
      </div>
      <div class="card-header d-flex align-items-center">
        <span class="flex-grow-1">In search of</span>
        <span class="badge text-bg-secondary rounded-pill float-end" [innerText]=""></span>
        <span class="text-secondary ">
          <fa-icon [fixedWidth]="true" [icon]="icons.faChevronRight"></fa-icon>
        </span>
      </div> -->
    </div>
    <div class="card-footer">
      <button disabled="true" class="btn btn-sm btn-blank text-secondary text-small">{{ playerDocId }}</button>
      <button (click)="copyText(playerDocId)" class="btn btn-sm btn-blank text-end text-secondary m-0">
        <fa-icon [icon]="icons.faCopy" [fixedWidth]="true"></fa-icon>
      </button>
    </div>
  </div>
</section>

import { IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { Pipe, PipeTransform } from '@angular/core';

export interface IEventStandings {
  playerDocId: string;
  playerUid: string;
  name: string;
  dropped: boolean;
  matchPoints: number;
  gameWinPercentage: string;
  opponentMatchWinPercentage: string;
  opponentGameWinPercentage: string;
  groupRank: number;
  showGroupWinner: boolean;
  group: string;
  filterString: string;
  rank: number;
  matchesWon: number;
  matchesLost: number;
  matchesDrawn: number;
}

export interface StandingsPipeConfig {
  showDroppedPlayers: boolean
  searchString: string
}

@Pipe({
  name: 'standings'
})
export class StandingsPipe implements PipeTransform {

  transform(players: IEventPlayerDetails[], config?: StandingsPipeConfig): IEventPlayerDetails[] {

    console.log(config)

    if (players === undefined) { return [] }
    if (players === null) { return [] }
    if (players.length === 0) { return [] }

    if (config !== undefined && config.searchString !== undefined && config.searchString.length > 0) {
      players = players.filter(p => (p.name + p.playedInGroup).toLowerCase().includes(config.searchString.toLowerCase()))
    }

    if (config !== undefined && config.showDroppedPlayers !== undefined && !config.showDroppedPlayers) {
      players = players.filter(p => !p.dropped)
    }

    return players

  }

}

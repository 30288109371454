import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { IPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { PlayerProfileComponent } from '../player-profile/player-profile.component';

@Injectable({
  providedIn: 'root'
})
export class PlayerListingService {

  private players = new Map<string, Observable<IPlayerDetails>>()

  constructor(
    private readonly firetore: AngularFirestore,
    private readonly modalService: NgbModal,
  ) { }

  public getPlayer(id: string): Observable<IPlayerDetails> {
    console.log('PlayerListingService:: get player')
    // check if player document exist in map
    if (!this.players.get(id)) {
      console.log('PlayerListingService:: player not found, creating observer')
      const observer = this.firetore.collection('players').doc<IPlayerDetails>(id).valueChanges()
      this.players.set(id, observer)
    }
    console.log('PlayerListingService:: returning observer')
    return this.players.get(id)
  }

  public showPofile(id: string): void {
    let modal = this.modalService.open(PlayerProfileComponent, {
      centered: true,
      animation: false,
    })
    modal.componentInstance.playerDocId = id
  }
}

<div class="mb-3 d-flex align-items-center flex-wrap">
  
  <input
    class="flex-grow-1"
    type="file"
    id="deckPhoto"
    #fileInput
    [style.minWidth.rem]="15"
    (change)="fileChangeEvent($event)">
  
  <div class="flex-grow-1" [style.minWidth.rem]="15">
    
    <button class="btn btn-sm btn-blank"
      ngbTooltip="Rotate left"
      container="body"
      (click)="rotateLeft()">
      <fa-icon [icon]="icon.flipL" [fixedWidth]="true"></fa-icon>
    </button>

    <button class="btn btn-sm btn-blank" 
      ngbTooltip="Rotate right" 
      container="body"
      (click)="rotateRight()">
      <fa-icon [icon]="icon.flipR" [fixedWidth]="true"></fa-icon>
    </button>

    <button class="btn btn-sm btn-blank"
      ngbTooltip="Flip horizontally"
      container="body"
      (click)="flipHorizontal()">
      <fa-icon [icon]="icon.flipH" [fixedWidth]="true"></fa-icon>
    </button>

    <button class="btn btn-sm btn-blank"
      ngbTooltip="Flip vertically"
      container="body"
      (click)="flipVertical()">
      <fa-icon [icon]="icon.flipV" [fixedWidth]="true"></fa-icon>
    </button>

    <button class="btn btn-sm btn-blank"
      ngbTooltip="Reset"
      container="body"
      (click)="resetImage()">
      <fa-icon [icon]="icon.undo" [fixedWidth]="true"></fa-icon>
    </button>

  </div>

</div>

<div class="message-wrapper d-flex flex-column" *ngIf="showError">
  <div class="alert alert-warning" role="alert">
    Error loading image, if you are on iOS, try changing the image size and try again.
  </div>
  <img src="assets/ios-image-upload.png">
</div>

<image-cropper class="image-cropper mb-3"
  [imageBase64]="imageFile"
  [disabled]="false"
  [format]="format"
  [maintainAspectRatio]="maintainAspectRatio"
  [aspectRatio]="aspectRatio"
  [containWithinAspectRatio]="containWithinAspectRatio"
  [canvasRotation]="canvasRotation"
  [resizeToWidth]="resizeToWidth"
  [transform]="transform"
  (imageCropped)="imageCropped($event)"
  (imageLoaded)="imageLoaded($event)"
  (cropperReady)="cropperReady()"
  (loadImageFailed)="loadImageFailed($event)">
</image-cropper>

<div class="wrapper overflow-hidden" [style.width.px]="8" [style.height.px]="8" style="visibility: hidden">
  <canvas #canvas width="10" height="10"></canvas>
</div>
import { IEventListItem } from 'src/app/services';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventSearch'
})
export class EventSearchPipe implements PipeTransform {

  transform(items: IEventListItem[], showClosed: boolean, showAttending: boolean, isOrganizing: boolean, filter: string): IEventListItem[] {
    // console.log(filter);
    let res: Array<IEventListItem> = [];
    if (items) {
      if (isOrganizing) {
        if (showClosed) {
          res = items.filter(e => e.isOrganizing);
        }
        else {
          res = items.filter(e => e.isOrganizing && !e.isEnded);
        }
      }
      else if (showAttending) {
        if (showClosed) {
          res = items.filter(e => e.isAttending);
        }
        else {
          res = items.filter(e => e.isAttending && !e.isEnded);
        }
      }
      else {
        if (showClosed) {
          res = items;
        }
        else {
          res = items.filter(e => !e.isEnded);
        }
      }
      // items.forEach(event => {
      //   if (event.statusCode === 8) {
      //     if (showClosed) {
      //       if (showAttending && event.isAttending) {
      //         res.push(event);
      //       }
      //       if (!showAttending) {
      //         res.push(event);
      //       }
      //     }
      //   }
      //   else {
      //     if (showAttending && event.isAttending) {
      //       res.push(event);
      //     }
      //     if (!showAttending) {
      //       res.push(event);
      //     }
      //   }
      // });
    }
    return res;
  }

}

import { faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import { Component, Input } from '@angular/core';
import { ICardMeta, IDeckList, DecksService } from 'src/app/services/decks.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-deck-list-editor',
  templateUrl: './deck-list-editor.component.html',
  styleUrls: ['./deck-list-editor.component.css']
})
export class DeckListEditorComponent {
  @Input() deckList: IDeckList;
  @Input() cardPreviewer: boolean = true;

  public icons = {
    faClone: faClone,
    faPlus: faPlus,
    faMinus: faMinus,
    faAngleRight: faAngleRight,
    faAngleLeft: faAngleLeft,
    faAngleDoubleRight: faAngleDoubleRight,
    faAngleDoubleLeft: faAngleDoubleLeft,
  }

  public cardPreviewUrl: string;
  public cardPreviewSetCode: string;
  public actionOffsetX: number = 0;
  public hoveredCardName: string;

  constructor(private deckService: DecksService) { }

  cardTracker(index: number, item: ICardMeta): string {
    return item.name;
  }

  public get editHoverClass(): string {
    return this.deckList.isLocked ? '' : 'show-hover'
  }

  public onMouseEnter(event: any, card: ICardMeta): void {
    if (card.name !== this.hoveredCardName) {
      this.hoveredCardName = card.name;
      this.cardPreviewUrl = card.imageUrl;
      this.cardPreviewSetCode = card.setCode;
      this.actionOffsetX = event.offsetX + 20;
    }
  }

  public addCopy(cardName: String, origin: string): void {
    const tmpDeck: IDeckList = JSON.parse(JSON.stringify(this.deckList)) as IDeckList;
    const cardIndex = tmpDeck[origin].findIndex((c: ICardMeta) => c.name === cardName);
    if (cardIndex > -1) {
      const tmpCard: ICardMeta = tmpDeck[origin][cardIndex];
      const copy: ICardMeta = JSON.parse(JSON.stringify(tmpDeck[origin][cardIndex]));
      copy.coords = {
        x: tmpCard.coords ? tmpCard.coords.x + 10 : 0,
        y: tmpCard.coords ? tmpCard.coords.y + 10 : 0,
      }
      copy.metaUuid = uuidv4();
      tmpDeck[origin].push(copy);
      this.saveDeck(tmpDeck);
    }
  }

  public moveCard(cardName: string, origin: string, destination: string): void {
    const tmpDeck: IDeckList = JSON.parse(JSON.stringify(this.deckList)) as IDeckList;
    const cardIndex = tmpDeck[origin].findIndex((c: ICardMeta) => c.name === cardName);
    if (cardIndex > -1) {
      tmpDeck[destination].push(tmpDeck[origin][cardIndex]);
      tmpDeck[origin].splice(cardIndex, 1);
      this.saveDeck(tmpDeck);
    }
  }

  public deleteCard(cardName: string, origin: string): void {
    const tmpDeck: IDeckList = JSON.parse(JSON.stringify(this.deckList)) as IDeckList;
    const cardIndex = tmpDeck[origin].findIndex((c: ICardMeta) => c.name === cardName);
    if (cardIndex > -1) {
      tmpDeck[origin].splice(cardIndex, 1);
      this.saveDeck(tmpDeck);
    }
  }

  private saveDeck(tmpDeck: IDeckList): void {
    this.deckService.saveDeckList(tmpDeck);
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { nanoid } from 'nanoid';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services';
import { ToastService } from 'src/app/services/toast.service';
import { v4 as uuidv4 } from 'uuid'
import { ICardComment, ICardConfig } from './card-factory.model';
import { CardFrames, ICardFrames } from './card-frames';
import * as firestore from 'firebase/firestore'
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class CardFactoryHelperService {

  private createdCards$: Observable<ICardConfig[]>
  public filterValue: string = ''

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private toast: ToastService,
    private router: Router,
  ) {
    this.createdCards$ = this.afs.collection<ICardConfig>('card-factory', ref => ref.where('publishedAt', '>', 0)).valueChanges()
  }

  public newCard(): Promise<string> {

    return new Promise((resolve, reject) => {

      const cardDocument: ICardConfig = {
        docId: uuidv4(),
        playerDocId: this.auth.user.playerId,
        playerUid: this.auth.user.uid,
        name: '',
        type: '',
        illustrator: '',
        pt: '',
        rulesText: [],
        frame: 'w',
        manaCost: null,
        config: {
          artwork: {
            size: 100,
            x: 0,
            y: 0,
            raster: true
          },
          rules: {
            fontSize: 10.0,
            lineHeight: 1.0,
          },
          flavor: {
            fontSize: 10.0,
            lineHeight: 1.0,
          },
        },
        imageUrl: null,
        generatedPic: null,
        likedByPlayerDocId: [],
        dislikedByPlayerDocId: [],
        comments: 0,
        createdAt: firestore.Timestamp.now().seconds,
        publishedAt: 0,
      }

      this.afs.collection('card-factory').doc(cardDocument.docId).set(cardDocument)
        .then(() => resolve(cardDocument.docId))
        .catch((e) => reject(e))

    })

  }

  public getCard(docId: string): Observable<ICardConfig> {
    return this.afs.collection('card-factory').doc<ICardConfig>(docId).valueChanges()
  }

  public likeCard(docId: string): void {
    this.afs.collection('card-factory').doc(docId).update({
      likedByPlayerDocId: firestore.arrayUnion(this.auth.user.playerId),
      dislikedByPlayerDocId: firestore.arrayRemove(this.auth.user.playerId),
    })
  }

  public dislikeCard(docId: string): void {
    this.afs.collection('card-factory').doc(docId).update({
      likedByPlayerDocId: firestore.arrayRemove(this.auth.user.playerId),
      dislikedByPlayerDocId: firestore.arrayUnion(this.auth.user.playerId),
    })
  }

  public getMyCards(): Observable<ICardConfig[]> {
    return this.afs.collection<ICardConfig>('card-factory', ref => ref.where('playerUid', '==', this.auth.user.uid)).valueChanges()
  }

  public saveCard(document: ICardConfig): void {
    this.afs.collection('card-factory').doc(document.docId).set(document)
      .then(() => this.toast.show(`Successfully saved the changes`, { classname: 'success-toast', duration: 3000 }))
      .catch((e) => {
        console.log(e)
        this.toast.show(`Somwthing went wrong => ${e}`, { classname: 'error-toast', duration: 8000 })
      })
  }

  public publishCard(document: ICardConfig): void {
    this.afs.collection('card-factory').doc(document.docId).update({
      publishedAt: firestore.Timestamp.now().seconds
    })
      .then(() => this.toast.show(`Successfully published the card`, { classname: 'success-toast', duration: 3000 }))
      .catch((e) => {
        console.log(e)
        this.toast.show(`Somwthing went wrong => ${e}`, { classname: 'error-toast', duration: 8000 })
      })
  }

  public commentOnCard(cardDocId: string, text: string): void {
    const comment: ICardComment = {
      id: nanoid(),
      text: text,
      playerDocId: this.auth.user.playerId,
      playerUid: this.auth.user.uid,
      createdAt: firestore.Timestamp.now().seconds,
    }
    this.afs.collection('card-factory').doc(cardDocId).collection('comments').add(comment)
      .then(() => this.afs.collection('card-factory').doc(cardDocId).update({ comments: firestore.increment(1) }))
      .catch((e) => {
        console.log(e)
        this.toast.show(`Somwthing went wrong => ${e}`, { classname: 'error-toast', duration: 8000 })
      })
  }

  public getCardComments(docId: string): Observable<ICardComment[]> {
    return this.afs.collection('card-factory').doc(docId).collection<ICardComment>('comments', ref => ref.orderBy('createdAt', 'asc')).valueChanges()
  }

  public deleteCard(cardDocId: string): void {
    this.afs.collection('card-factory').doc(cardDocId).delete()
      .then(() => {
        this.toast.show(`Card successfully deleted`, { classname: 'success-toast', duration: 3000 })
        if (!this.router.url.includes('your-cards')) {
          this.router.navigate(['card-factory/your-cards'])
        }
      })
      .catch((e) => {
        console.log(e)
        this.toast.show(`Somwthing went wrong => ${e}`, { classname: 'error-toast', duration: 8000 })
      })
  }

  public get createdCards(): Observable<ICardConfig[]> {
    return this.createdCards$
  }

  public get frames(): ICardFrames[] {

    return new CardFrames().frames

  }

  public setFilterString(string: string) {
    this.filterValue = string
  }

}

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const tolSend: IconDefinition = {
  prefix: 'tol',
  iconName: 'send',
  icon: [
    496.8698, // SVG view box width
    500, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M179,274c40,0,80,.07,120,0,12.23,0,21.73-8.75,23.66-21.13,1.7-10.86-5.74-22.46-16.94-25.74A38.59,38.59,0,0,0,295.08,226q-117.16-.09-234.34.08c-5.8,0-7.75-1.23-9.33-7.42-5.68-22.32-12.58-44.33-19.14-66.43C22.92,120.7,13.43,89.22,4.1,57.7c-2.95-10-3.55-20.2-.61-30.27C9.62,6.41,29.75-4.33,52.67,1.64a117.38,117.38,0,0,1,22.57,9q193.08,96.33,386,192.93a114,114,0,0,1,17.59,10.33c26.14,19.61,26.11,52.59,0,72.19a92.44,92.44,0,0,1-14.06,8.51Q267.56,393.32,70.21,491.91c-11.85,5.92-24.09,9.93-37.71,7.23-16.4-3.24-29.4-16.55-30.14-33.2a86.14,86.14,0,0,1,3-27.38c14.3-49.36,29.3-98.52,44-147.76,1.08-3.61,2.55-7.33,2.55-11,0-5.46,2.68-5.86,7.09-5.84,40,.13,80,.07,120,.07Z'
  ],
} as any;

import List from 'scryfall-client/dist/models/list';
import { Pipe, PipeTransform } from '@angular/core';
import Card from 'scryfall-client/dist/models/card';

@Pipe({
  name: 'deckBuilderCardList'
})
export class DeckBuilderCardListPipe implements PipeTransform {

  transform(cards: List<Card>): List<Card> {
    if (!cards) {
      return null;
    }

    return cards;
  }

}

import { IEventPlayerDetails, ISingleBracketMatrixMatch, ISingleBracketMatrixRound, IMatchData, IEventTeam } from 'tolaria-cloud-functions/src/_interfaces';
import { v4 as uuidv4 } from 'uuid'

export interface BracketMatch {
  matchId: string
  round: number
  // matching: number
  playerOne: IEventPlayerDetails
  playerTwo: IEventPlayerDetails
  feedsMatchDocId: string
  feedsPlayerSlot: 'player1' | 'player2'
  playerDocIds: string[]
  active?: boolean
  byeMatch?: boolean;
}
export interface TeamBracketMatch {
  matchId: string
  round: number
  matching: number
  teamOne: IEventTeam
  teamTwo: IEventTeam
  feedsMatchDocId: string
  feedsPlayerSlot: string
  teamIds: string[]
  active?: boolean
}
export interface EliminationBracketResponse {
  matches: BracketMatch[]
  bracketMatrix: ISingleBracketMatrixRound[]
}
export interface TeamEliminationBracketResponse {
  matches: TeamBracketMatch[]
  bracketMatrix: ISingleBracketMatrixRound[]
}


/**
 * Create a set of matches for a single emlination bracket and a bracket matrix including bye matches for templating
 * @param players
 * @returns EliminationBracketResponse -> matches + bracketMatrix
 */
export const SingleElimination = (players: IEventPlayerDetails[]): EliminationBracketResponse => {

  // modification of the Single Elimintaion of https://github.com/slashinfty/tournament-pairings
  const __createSimpleBracket = (playerCount: number, consolation: boolean = false) => {
    const startingRound = 1
    const matches = []
    const playerArray = [...new Array(playerCount)].map((_, i) => i + 1)
    const exponent = Math.log2(playerArray.length)
    const remainder = Math.round(2 ** exponent) % (2 ** Math.floor(exponent))
    const bracket = exponent < 2 ? [1, 2] : [1, 4, 2, 3]
    for (let i = 3; i <= Math.floor(exponent); i++) {
      for (let j = 0; j < bracket.length; j += 2) {
        bracket.splice(j + 1, 0, 2 ** i + 1 - bracket[j]);
      }
    }
    let round = startingRound;
    if (remainder !== 0) {
      for (let i = 0; i < remainder; i++) {
        matches.push({
          round: round,
          match: i + 1,
          player1: null,
          player2: null
        })
      }
      round++;
    }
    let matchExponent = Math.floor(exponent) - 1;
    let iterated = false;
    do {
      for (let i = 0; i < 2 ** matchExponent; i++) {
        matches.push({
          round: round,
          match: i + 1,
          player1: null,
          player2: null
        });
      }
      if (!iterated) {
        iterated = true;
      }
      else {
        matches.filter(m => m.round === round - 1).forEach(m => m.win = {
          round: round,
          match: Math.ceil(m.match / 2)
        });
      }
      round++;
      matchExponent--;
    } while (round < startingRound + Math.ceil(exponent));
    const startRound = startingRound + (remainder === 0 ? 0 : 1);
    matches.filter(m => m.round === startRound).forEach((m, i) => {
      m.player1 = playerArray[bracket[2 * i] - 1];
      m.player2 = playerArray[bracket[2 * i + 1] - 1];
    });
    if (remainder !== 0) {
      matches.filter(m => m.round === startingRound).forEach((m, i) => {
        m.player1 = playerArray[2 ** Math.floor(exponent) + i];
        const p2 = playerArray[2 ** Math.floor(exponent) - i - 1];
        const nextMatch = matches.filter(n => n.round === startingRound + 1).find(n => n.player1 === p2 || n.player2 === p2);
        if (nextMatch.player1 === p2) {
          nextMatch.player1 = null;
        }
        else {
          nextMatch.player2 = null;
        }
        m.player2 = p2;
        m.win = {
          round: startingRound + 1,
          match: nextMatch.match
        };
      });
    }
    if (consolation) {
        const lastRound = matches.reduce((max, curr) => Math.max(max, curr.round), 0);
        const lastMatch = matches.filter(m => m.round === lastRound).reduce((max, curr) => Math.max(max, curr.match), 0);
        matches.push({
            round: lastRound,
            match: lastMatch + 1,
            player1: null,
            player2: null
        });
        matches.filter(m => m.round === lastRound - 1).forEach(m => m.loss = {
            round: lastRound,
            match: lastMatch + 1
        });
    }
    return matches;
  }

  // get player count for an even bracket
  const playerCountForEvenBracket = Math.pow(2, Math.ceil(Math.log2(players.length)))


  // create a bracket pairing
  const pairing = __createSimpleBracket(playerCountForEvenBracket)


  // add match id for each match in the pairing
  for (const m of pairing) {
    m.id = uuidv4()
  }


  // add feedsMatchDocId
  for (const m of pairing) {
    if (m.win) {
      const feedsMatch = pairing.find((i: any) => i.round === m.win.round && i.match === m.win.match)
      m.feedsMatchDocId = feedsMatch.id
    }
    else {
      m.feedsMatchDocId = null
    }
  }


  // add feedsMatchSlot
  for (const m of pairing) {
    if (m.win) {
      const matchesWithSameFeed = pairing.filter((i: any) => i.win && i.win.round === m.win.round && i.win.match === m.win.match)
      m.feedsMatchSlot = matchesWithSameFeed[0].id === m.id && matchesWithSameFeed.length > 1 ? 'player1' : 'player2'
    }
    else {
      m.feedsMatchSlot = null
    }
  }


  // create matches
  const matches: BracketMatch[] = []
  for (const m of pairing) {
    const player1 = players.find(i => i.seed === m.player1)
    const player2 = players.find(i => i.seed === m.player2)
    const match: BracketMatch = {
      matchId: m.id,
      round: m.round,
      feedsMatchDocId: m.feedsMatchDocId,
      feedsPlayerSlot: m.feedsMatchSlot,
      playerOne: player1 ? player1 : null,
      playerTwo: player2 ? player2 : null,
      playerDocIds: [] as string[],
      byeMatch: false,
    }
    if (player1) { match.playerDocIds.push(player1.playerDocId) }
    if (player2) { match.playerDocIds.push(player2.playerDocId) }
    matches.push(match)
  }


  // populate matches
  for (const m of matches.filter(i => i.playerDocIds.length < 2 && i.round === 1)) {

    const mIndex = matches.findIndex(i => i.matchId === m.feedsMatchDocId)
    if (m.playerOne !== null) {
      matches[mIndex][m.feedsPlayerSlot === 'player1' ? 'playerOne' : 'playerTwo'] = m.playerOne
      matches[mIndex].playerDocIds.push(m.playerOne.playerDocId)
    }
    if (m.playerTwo !== null) {
      matches[mIndex][m.feedsPlayerSlot === 'player1' ? 'playerOne' : 'playerTwo'] = m.playerTwo
      matches[mIndex].playerDocIds.push(m.playerTwo.playerDocId)
    }

    m.byeMatch = m.playerDocIds.length !== 2

  }


  // create bracket matrix
  const bracketMatrix: ISingleBracketMatrixRound[] = []
  for (const round of [...new Set(matches.map(i => i.round))]) {

    let roundMatches: ISingleBracketMatrixMatch[] = matches.filter(i => i.round === round).map(m => {
      return {
        byeMatch: m.playerDocIds.length < 2 && m.round === 1,
        feedsMatch: null,
        feedsPlayerSlot: m.feedsPlayerSlot,
        feedsMatchDocId: m.feedsMatchDocId,
        matchDocId: m.matchId,
        active: m.playerDocIds.length === 2,
      }
    })

    bracketMatrix.push({
      round,
      matches: roundMatches
    })

  }


  return {
    matches,
    bracketMatrix,
  }

}

export const DoubleElimination = () => {

}

/**
 * Create a set of matches for a single emlination bracket and a bracket matrix including bye matches for templating
 * @param players
 * @returns EliminationBracketResponse -> matches + bracketMatrix
 */
export const SingleEliminationTeams = (teams: IEventTeam[]): TeamEliminationBracketResponse => {

  // The match object to return
  const matches: TeamBracketMatch[] = []

  // Empty match
  const _matchObj = (i: number) => {
    return {
      matchId: uuidv4(),
      round,
      matching: i + 1,
      teamOne: null,
      teamTwo: null,
      teamIds: [],
      feedsMatchDocId: null,
      feedsPlayerSlot: null,
    } as TeamBracketMatch
  }

  // Sort players
  teams.sort((a, b) => a.seed - b.seed)


  // Important values (determines bracket sizing)
  const startingRound = 1
  const exponent = Math.log2(teams.length)
  const remainder = Math.round(2 ** exponent) % (2 ** Math.floor(exponent))
  const rounds = Math.ceil(Math.log2(teams.length))

  // Create bracket
  const bracket = exponent < 2 ? [1, 2] : [1, 4, 2, 3]
  for (let i = 3; i <= Math.floor(exponent); i++) {
    for (let j = 0; j < bracket.length; j += 2) {
      bracket.splice(j + 1, 0, 2 ** i + 1 - bracket[j])
    }
  }
  console.log(bracket)

  // Create first round, if number of player not even power of 2 (2/4/8/16/32/etc)
  let round = startingRound
  if (remainder !== 0) {
    for (let i = 0; i < remainder; i++) {
      matches.push(_matchObj(i))
    }
    round++;
  }

  // Create all other rounds
  let matchExponent = Math.floor(exponent) - 1
  let firstIterationComplete = false
  do {
    for (let i = 0; i < 2 ** matchExponent; i++) {
      matches.push(_matchObj(i))
    }
    if (firstIterationComplete === false) firstIterationComplete = true
    else {
      matches.filter(match => match.round === round - 1).forEach(match => {
        match.feedsMatchDocId = matches.find(m => m.round === round && m.matching === Math.ceil(match.matching / 2)).matchId
        match.feedsPlayerSlot = match.matching % 2 === 1 ? 'player1' : 'player2'
      })
    }
    round++
    matchExponent--
  } while (round < startingRound + Math.ceil(exponent))

  // Assign players to starting matches
  let roundToAssign = remainder === 0 ? startingRound : startingRound + 1
  matches.filter(match => match.round === roundToAssign).forEach((match, i) => {
    match.teamOne = teams[bracket[2 * i] - 1]
    match.teamTwo = teams[bracket[2 * i + 1] - 1]
    match.active = true
  })

  // create bracket matrix
  const bracketMatrix: ISingleBracketMatrixRound[] = []
  for (const round of [...new Set(matches.map(i => i.round))]) {

    // create a match object for each match created for round
    let roundMatches: ISingleBracketMatrixMatch[] = matches.filter(i => i.round === round).map(i => {
      return {
        active: i.round === 1,
        byeMatch: false,
        matchDocId: i.matchId,
        feedsMatch: null,
        feedsMatchDocId: i.feedsMatchDocId,
        teams: {
          teamOne: i.round === 1
            ? {
                id: i.teamOne.id,
                seed: i.teamOne.seed,
              }
            : null,
          teamTwo: i.round === 1
            ? {
                id: i.teamTwo.id,
                seed: i.teamTwo.seed,
              }
            : null,
        }
      }
    })

    for (const [i, val] of roundMatches.entries()) {
      val.feedsPlayerSlot = i % 2 === 0 ? 'teamOne' : 'teamTwo'
    }

    bracketMatrix.push({
      round,
      matches: roundMatches
    })

  }

  return {
    matches,
    bracketMatrix,
  }
}

<div class="card border-secondary border-opacity-50 rounded min-width-100 max-height-100">
  <div class="card-body no-hover border-bottom border-secondary">
    <div class="text-larger text-center">News Feed</div>
  </div>
  <div class="card-body no-hover p-0 flex-grow-1 overflow-y-auto scrollable">

    <ng-container *ngIf="rssFeed$ | async as feeds; else loader">
      <ng-container *ngFor="let feed of feeds | newsFeed : numFeeds">

        <div class="d-flex flex-row align-items-center feed-item" (click)="showReader(feed)">

          <div class="flex-grow-0 logo me-3">
            <img [src]="feed.feedMeta.logoUrl">
          </div>

          <div class="flex-grow-1 content-wrap">
            <div class="feed-title flex-grow-1 mt-2">{{ feed.title }}</div>
            <div class="d-flex align-items-center">
              <div class="feed-origin">{{ feed.feedMeta.name }}</div>
              <div class="feed-type {{ feed.feedMeta.type }}">{{ feed.feedMeta.type }}</div>
            </div>
          </div>

        </div>

        <!-- <div class="news-card d-flex flex-column overflow-hidden {{ feed.feedMeta.class }}" (click)="showReader(feed)">

          <div class="feed-logo">
            <img [src]="feed.feedMeta.logoUrl">
          </div>
          <div class="flex-grow-1 d-flex flex-column p-3">
            <div class="d-flex align-items-center">
              <div class="feed-origin">{{ feed.feedMeta.name }}</div>
              <div class="feed-type {{ feed.feedMeta.type }}">{{ feed.feedMeta.type }}</div>
            </div>
            <div class="feed-title flex-grow-1 mt-2">{{ feed.title }}</div>
          </div>
          <div class="feed-published p-3 text-secondary text-smaller">{{ feed.pubDate | date : 'medium' }}</div>


        </div> -->

      </ng-container>

      <!-- <div class="news-card more-content d-flex flex-column align-items-center justify-content-center"
        *ngIf="showMoreButton">
        <span class="text-xxlarge text-uppercase text-bold mb-5">More content</span>
        <span class="">Want to add your content here?</span>
        <span class="">Contact <app-player-display-name playerDocId="d37c411c-2004-44ef-958d-c2781eea2429">
          </app-player-display-name></span>
      </div> -->

      <div class="d-flex flex-row align-items-center feed-item more">

        <div class="flex-grow-0 logo me-3"></div>

        <div class="flex-grow-1 content-wrap">
          <div class="feed-title flex-grow-1 mt-2 text-large" [routerLink]="['/news']">
            Click here for event more content
          </div>
          <div class="feed-origin">
            Want to add you content here?
          </div>
          <div class="">
            Contact <app-player-display-name playerDocId="d37c411c-2004-44ef-958d-c2781eea2429">
            </app-player-display-name>
          </div>
        </div>

      </div>

    </ng-container>


    <ng-template #loader>
      <div class="d-flex flex-column align-items-center m-5">
        <div class="d-flex flex-column align-items-center justify-content-center flex-grow-1">
          <div class="spinner-wrapper">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="text-secondary">loading awesome old shcool content from the underground...</div>
        </div>
      </div>
    </ng-template>

  </div>
</div>

<ng-template #readerModal>
  <div class="modal-header">{{ reader.title }} @ {{ reader.feedMeta.name }}</div>
  <div class="modal-body" [innerHtml]="reader.content"></div>
  <div class="modal-footer text-start"><a [href]="reader.link" target="_blank" class="text-secondary"
      [innerHtml]="reader.link"></a></div>
</ng-template>

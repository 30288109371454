import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from './../../../services/toast.service';
import { GlobalsService, AuthService, EventService } from 'src/app/services';
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { IEventListItem } from 'src/app/services';
import { faCalendarPlus, faVideo, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.css']
})
export class EventCardComponent {
  @Input() event: IEventListItem;

  @ViewChild('unattendConfirmation') unattendConfirmation: ElementRef

  public icons = {
    faCalendarPlus: faCalendarPlus,
    faVideo: faVideo,
    faLock: faLock,
    faUnlock: faUnlock,
  }

  constructor(
    private globals: GlobalsService,
    private auth: AuthService,
    private es: EventService,
    private toastService: ToastService,
    private modalService: NgbModal,
  ) { }

  public get isMobile(): boolean {
    return this.globals.isMobile;
  }

  public get isAdmin(): boolean {
    return this.auth.user.role === 'admin'
  }

  public get bannerUrl(): string {
    return this.event.banner
      ? this.event.banner
      : 'assets/banners/' + this.event.format.toLowerCase().replace(/ /g, '-') + '.default.jpg';
  }

  public resizeTooltip(event: any): void {
    document.documentElement.style.cssText = `--event-tooltip-width: ${event.target.clientWidth + 2}px !important;`
  }

  public attendEvent() {
    this.es.addPlayerToEvent(this.event.docId, this.auth.user.uid, this.auth.user.playerId, null)
      .then(response => {
        if (response.status) {
          this.toastService.show('You have been signed up to attend the event.', { classname: 'success-toast', delay: 2000 });
        }
      });
  }

  public unattendEvent() {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'sm',
    }
    this.modalService.open(this.unattendConfirmation, modalOptions).result
      .then((result) => {
        if (result) {
          // call function to remove attending player
          this.es.removePlayerFromEvent(this.event.docId, this.auth.user.playerId)
            .then((response: IPromiseResponse) => {
              if (response.status) {
                this.toastService.show('You are no longer attending the event.', { classname: 'success-toast', delay: 3000 });
              }
            });
        }
      })
  }

}

<ng-container *ngIf="modal; else info">

  <div class="modal-header d-flex align-items-center">
    <div class="text-large flex-grow-1">Stripe connected account for organizers</div>
    <button class="btn-close" (click)="onClose()"></button>
  </div>

  <div class="modal-body rounded rounded-4">

    <div class="pe-4">
      <ng-container [ngTemplateOutlet]="info"></ng-container>
    </div>

  </div>

  <div class="modal-footer"></div>

</ng-container>


<ng-template #info>

  <div class="d-flex flex-column gap-5">

    <!-- Accept Payments Section -->
    <div class="">
      <h3 class="">Accept Payments</h3>
      <p>
        Tolaria.app is integrated with <strong>Stripe's payment infrastructure</strong> and operates as a
        <strong>platform account</strong>. As an <strong>event organizer</strong>, you can sign up for a 
        <strong>connected Stripe account</strong> to accept payments for <strong>event tickets and registration fees</strong>.
      </p>
    </div>

    <!-- Connected Account Section -->
    <div class="">
      <h3 class="">Connected Account</h3>
      <p>
        By creating a <strong>connected account</strong>, you unlock the ability to <strong>accept registration fees</strong>
        and <strong>sell event tickets</strong> directly through Tolaria.app.
      </p>
      <ul class="">
        <li class="">Use the <strong>integrated Stripe checkout system</strong> for secure card payments.</li>
        <li class="">Handle payments <strong>manually</strong> using external methods.</li>
      </ul>
      <p class="mt-3">
        Once your connected account is successfully onboarded, payments will be routed directly to your
        <strong>Stripe account</strong>. Payouts to your bank can be requested as soon as funds are available.
        Any applicable <strong>transaction fees</strong> will be deducted before transfer.
      </p>
    </div>

    <!-- Handle Payments Section -->
    <div class="">
      <h3 class="">Handle Payments</h3>
      <p>Payments can be managed in two ways:</p>
      <ul class="">
        <li class=""><strong>Automatically</strong> through the built-in <strong>Stripe checkout system</strong>.</li>
        <li class=""><strong>Manually</strong> via an easy-to-use interface.</li>
      </ul>
      <p class="mt-3">
        For manual handling, you can mark <strong>registration fees as paid</strong> using predefined payment methods 
        (e.g., <strong>PayPal, cash, bank transfer</strong>). These payments will appear as completed, and refunds can be issued when necessary.
      </p>
    </div>

    <!-- Fees Section -->
    <div class="">
      <h3 class="">Fees</h3>
      <p>
        Most payment processing services, including Stripe, charge a fee per transaction. This includes:
      </p>
      <ul class="">
        <li class=""><strong>Card processing fees</strong> for debit and credit card payments.</li>
        <li class=""><strong>Currency conversion fees</strong> for international payments.</li>
      </ul>
      <p class="mt-3">
        To cover these costs, <strong>Tolaria Payment</strong> applies a <strong>fixed fee of 5%</strong> per transaction.
      </p>
      <p>
        This fee helps cover:
      </p>
      <ul class="">
        <li class="">The <strong>basic costs of Stripe's payment system</strong>.</li>
        <li class=""><strong>Losses from refunds</strong> and processing expenses.</li>
      </ul>
      <p class="mt-3">
        I am committed to <strong>offering a fee-free solution in the future</strong>, but for now, this fee is necessary to sustain the service.
      </p>
      <p class="m-4">
        <img class="w-100" src="assets/payment-flow-01.png">
      </p>
      <p>
        For the latest Stripe pricing details, visit: 
        <a href="https://stripe.com/en-se/pricing#pricing-details" target="_blank">Stripe Pricing</a>.
      </p>
    </div>
    
    <!-- Issue Refunds Section -->
    <div class="">
      <h3 class="">Issue Refunds</h3>
      <p>
        Issuing refunds is as simple as accepting payments.
      </p>
      <ul class="">
        <li class="">
          If the user has paid through <strong>Tolaria Payment</strong> (Stripe-integrated checkout), the refund process will be <strong>automated</strong>.
        </li>
        <li class="">
          Once the refund is completed, the payment will be marked as <strong>refunded</strong>.
        </li>
        <li class="">
          For <strong>manual handling</strong>, you will need to mark the payment as refunded and process it externally through your chosen method.
        </li>
      </ul>
      <div class="alert alert-warning mt-3">
        <strong>Note:</strong> Payment processing fees will still apply to the <strong>Tolaria platform account</strong>, even when a refund is issued.
      </div>
      <p class="m-4">
        <img class="w-100" src="assets/payment-flow-02.png">
      </p>
    </div>

  </div>

  

  <!-- <div class="d-flex flex-column gap-5">

    <div class="">
      <h4>Accept Payments</h4>
      <p>
        Tolaria.app is integrated with the <a href="https://stripe.com/en-se" target="_blank"><span
            class="text-bold">stripe</span></a> payment infrastructure and
        has a so-called <i>platform account</i>.<br>
        As an organizer, you can sign up for a <i>connected account</i> and accept payments for event tickets and 
        registration fees.
      </p>
    </div>

    <div class="">
      <h4>Connected Account</h4>
      <p>
        With a connected account, you unlock the feature to accepting registration fees and the possibility to sell event tickets directly through
        Tolaria.app<br>
        You can either use the integrated checkout system for card payments, or manually <i>handle</i> them externally.
      </p>
      <p>
        As soon as your connected account has successfully been created and onboarded, payments will be routed directly to
        your <span class="text-bold">stripe</span> connected account and payouts to your bank account can be requested as soon as funds are available.
        Any <i>fees</i> connected to the payments will be subtracted before the funds are moved to your connected account.
      </p>
    </div>

    <div class="">
      <h4>Handle Payments</h4>
      <p>
        Payments can be handled automatically with the built-in <span class="text-bold">stripe</span> checkout system,
        or manually handle them with an easy to use interface.
      </p>
      <h5>Checkout system</h5>
      <p>
        Payment that are processed through the integrated checkout will <i>marked</i> as paid as soon as the payment proceess is completed.
        Refunds can be initiataed through the user interface and will be processed and returned to card used to pay.
      </p>
      <h5>Manual handling</h5>
      <p>
        For manual handling, you simply <i>mark</i> registration fees as paid with a set of predefined payment methods (PayPal, Cash, etc).
        They will appear as paid and you will be able to <i>issue refunds</i> however you see fit.
      </p>
    </div>

    <div class="">
      <h4>Fees</h4>
      <p>
        Unfortunately, not many things are free and <span class="text-bold">stripe</span> charges a fee for every card
        transaction, just as any other payment solution out there.
        In addition to fees for charging debit and credit cards, there is also a currency exchange rate when payments
        are handled in other currencies.
        With Tolaria Payment I will try my very best to offer a fee-less solution in the end. However, I need to start
        somewhere and unfortunately that means charging a fee in the beginning.
      </p>
      <p>
        For the sake of simplicity, I will add a <span class="text-orange">fixed fee of 5%</span>. This will cover the
        basic costs of the stripe payment system, as well as losses after refunds are issued.
      </p>
      <p class="m-4">
        <img class="w-100" src="assets/payment-flow-01.png">
      </p>
      <p>
        For the latest updated prices that <b>stripe</b> charges the Tolaria <i>platform account</i>, see <a
          href="https://stripe.com/en-se/pricing" target="_blank">https://stripe.com/en-se/pricing#pricing-details</a>
      </p>
    </div>

    <div class="">
      <h4>Issue Refunds</h4>
      <p>
        Issuing refunds are as simple as accepting payments. If the user has paid with <b>Tolaria Payment</b> (stripe
        integrated checkout) there will be an automated
        flow. When the refund has successfully completed all steps, the payment will be marked as refunded.<br>
        For manual handling, you simply <i>mark</i> the payment as refunded as you manually need to handle this with
        your desired external methods.
      </p>
      <p class="m-4">
        <img class="w-100" src="assets/payment-flow-02.png">
      </p>
      <p>
        <b class="text-red">NOTE: Fees connected to the payment will still apply for the Tolaria <i>platform
            account</i>.</b>
      </p>
    </div>

  </div> -->


</ng-template>
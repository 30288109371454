import { PaymentService } from 'src/app/payment/payment.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faDonate } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { BusySpinnerComponent } from 'src/app/components/app-structure/busy-spinner/busy-spinner.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DonationType, IDonationMeta, IDonationTier, IPlayerDetails, ProductType } from 'tolaria-cloud-functions/src/_interfaces';
import { PlayerNameService } from 'src/app/services/players/player-name.service';

@Component({
  selector: 'app-pay-donation',
  templateUrl: './pay-donation.component.html',
  styleUrls: ['./pay-donation.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    BusySpinnerComponent,
    NgbModule,
  ]
})
export class PayDonationComponent implements OnInit, OnDestroy {
  @Input() currency: string = 'USD'
  @Input() modalView: boolean = false
  @Input() showText: boolean = true
  @Input() showTypeSelector: boolean = false
  @Input() set donationConfig(val: DonationType) {
    this.donationType = val
  }

  public donationIcon = faDonate
  public donationAmount: number = 0
  public creatingCheckout: boolean = false
  public init$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public donationType: DonationType = DonationType.SUBSCRIPTION

  private destroyed$ = new Subject<boolean>()
  private player: IPlayerDetails
  private donationMeta: IDonationMeta
  public donationTiers$: BehaviorSubject<IDonationTier[]> = this.payment.getDonationTiers()
  public selectedTier: IDonationTier = null

  constructor(
    private payment: PaymentService,
    private readonly playerNames: PlayerNameService,
    private modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.playerNames.serviceReady$.pipe(takeUntil(this.destroyed$)).subscribe((ready) => ready ? this.init() : null)
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public dismiss(): void {
    this.modal.dismiss()
  }

  private async init() {
    // get player document
    this.player = this.playerNames.currentUserPlayerDoc$.getValue()
    // set donation meta
    this.donationMeta = {
      productType: ProductType.DONATION,
      playerDocId: this.player.docId,
      playerUid: this.player.uid,
      email: this.player.email,
    }

    // ready template
    this.init$.next(true)
  }

  public setDonationType(type: string): void {
    switch (type) {
      case 'ONE_TIME':
        this.donationType = DonationType.ONE_TIME
        break
      case 'SUBSCRIPTION':
        this.donationType = DonationType.SUBSCRIPTION
        break
    }
  }

  public proceedToCheckout(): void {
    // set busy on the checkout button
    this.creatingCheckout = true
    // add meta if subscription
    this.donationMeta.type = this.donationType
    this.donationMeta.tier_name = this.selectedTier ? this.selectedTier.name : ''
    this.donationMeta.tier_amount = this.selectedTier ? this.selectedTier.amount : 0
    this.donationMeta.tier_currency = this.selectedTier ? this.selectedTier.currency : 'not set'
    // call service to create checkout session
    this.payment.createCheckoutSession.DONATION(
      this.donationType === DonationType.SUBSCRIPTION ? this.donationMeta.tier_currency : this.currency,
      this.donationAmountTotal,
      'PLATFORM DONATION',
      this.donationMeta,
      this.donationType
    ).then(() => this.creatingCheckout = false)
  }

  public get donationAmountTotal(): number {
    if (this.donationType === DonationType.ONE_TIME) return this.donationAmount
    if (this.donationType === DonationType.SUBSCRIPTION && this.selectedTier) {
      if (this.selectedTier.name === 'Chaos Orb') return this.donationAmount
      else return this.selectedTier.amount
    }
    return null
  }

  public get checkoutFormDisabled(): boolean {
    switch (this.donationType) {

      case DonationType.ONE_TIME:
        if (this.donationAmount === 0 || this.donationAmount === null || this.donationAmount === undefined) return true
        return false

      case DonationType.SUBSCRIPTION:
        if (!this.selectedTier) return true
        if (this.chaosTier && this.donationAmount === 0 || this.chaosTier && this.donationAmount === null || this.chaosTier && this.donationAmount === undefined) return true
        return false

    }
  }

  public get chaosTier(): boolean {
    return this.selectedTier
      ? this.selectedTier.name === 'Chaos Orb'
        ? true
        : false
      : false
  }

  public get exchangeRatesLoaded(): boolean {
    return this.payment.exchangeRates$.getValue() !== null
  }

  public get exchangeRateInfo(): string {
    const rates = this.payment.exchangeRates$.getValue()
    if (!this.selectedTier) { return '' }
    else if (rates === null) { return '' }
    else {

      // TIER Currency > EUR
      const EUR = this.selectedTier.name === 'Chaos Orb'
        ? this.donationAmount / rates[this.selectedTier.currency]
        : this.selectedTier.amount / rates[this.selectedTier.currency]

      // EUR > USD
      const USD = EUR * rates['USD']

      // EUR > SEK
      const SEK = EUR * rates['SEK']

      return `<span class="text-medium">approx. ${EUR.toFixed(2)}<span class="text-smaller">EUR</span> | ${USD.toFixed(2)}<span class="text-smaller">USD</span> | ${SEK.toFixed(2)}<span class="text-smaller">SEK</span></span>`
    }
  }

}


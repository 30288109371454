<div class="modal-header">
  <h4 class="modal-title">{{ deck.name }}</h4>
  <div class="btn-close float-end text-secondary" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
  </div>
</div>
<div class="modal-body p-0" (mousemove)="mouseMoving($event)">

  <div class="battlefield d-flex">
    <div #battlefield class="battlefield-drop-zone" mwlDroppable dragOverClass="droppable-dropping"
      (mouseup)="releaseMouse($event)" (drop)="dropped($event, 'battlefield')">
      <ng-container *ngFor="let card of zones.battlefield; let i = index">
        <div class="card-wrapper" [ngClass]="{ 'card-tapped' : card.tapped}" (click)="card.tapped = !card.tapped"
          mwlDraggable dragActiveClass="being-dragged" (dragEnd)="rePositionCard($event, i)"
          [dropData]="{ card: card, fromZone: 'battlefield', index: i }" [style.left]="getPixels(card.x)"
          [style.top]="getPixels(card.y)" [style.z-index]="i">
          <div class="click-wrapper" (mousedown)="touchCard($event)"></div>
          <div class="battlefield-tap-untap d-flex flex-column">
            <div class="btn-tap text-xxlarge" *ngIf="!card.tapped">
              <i class="ms ms-tap"></i>
            </div>
            <div class="btn-untap text-xxlarge" *ngIf="card.tapped">
              <i class="ms ms-untap"></i>
            </div>
          </div>
          <app-card-image [cardId]="card.scryfallId" [imageUris]="card.imageUris" size="small"></app-card-image>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="other-zones d-flex flex-row align-items-end">

    <!-- GRAVEYARD -->
    <div class="zone-graveyard flex-grow-0 PLACEHOLDER" *ngIf="zones.viewExtended.graveyard"></div>
    <div class="zone-graveyard flex-grow-0" mwlDroppable dragOverClass="droppable-dropping"
      (mouseup)="releaseMouse($event)" (drop)="dropped($event, 'graveyard')"
      [ngClass]="{ 'extended' : zones.viewExtended.graveyard }">
      <div class="content-wrapper relative">
        <div class="zone-title" (click)="zones.viewExtended.graveyard = !zones.viewExtended.graveyard">Graveyard</div>
        <div class="zone-toggler left" [ngClass]="{ 'rotated' : zones.viewExtended.graveyard }"
          (click)="zones.viewExtended.graveyard = !zones.viewExtended.graveyard">
          <fa-icon [fixedWidth]="true" [icon]="faChevronUp"></fa-icon>
        </div>
        <ng-container *ngFor="let card of zones.graveyard; let i = index">
          <div class="card-wrapper" mwlDraggable dragActiveClass="being-dragged" (mousedown)="touchCard($event)"
            [dropData]="{ card: card, fromZone: 'graveyard', index: i }">
            <app-card-image [cardId]="card.scryfallId" [imageUris]="card.imageUris" size="small"></app-card-image>
          </div>
        </ng-container>
        <div class="card-count" [innerText]="zones.graveyard.length"></div>
      </div>
    </div>

    <!-- LIBRARY -->
    <div class="zone-library flex-grow-0" mwlDroppable dragOverClass="droppable-dropping"
      (mouseup)="releaseMouse($event)" (drop)="dropped($event, 'library')" (click)="drawCards(1)">
      <div class="content-wrapper relative">
        <img src="assets/card-back.png" mwlDraggable dragActiveClass="being-dragged" (mousedown)="touchCard($event)"
          [dropData]="{ card: zones.library[0], fromZone: 'library', index: 0 }">
        <div class="card-count" [innerText]="zones.library.length"></div>
        <div class="actions-wrapper">
          <div click="action-button" (click)="zones.viewExtended.library = true; $event.stopPropagation(); $event.preventDefault();">
            <fa-icon [fixedWidth]="true" [icon]="faSearch" class="me-2"></fa-icon>Search library
          </div>
        </div>
      </div>
    </div>

    <!-- HAND -->
    <div class="zone-hand flex-grow-1 d-flex flex-row" mwlDroppable dragOverClass="droppable-dropping"
      (mouseup)="releaseMouse($event)" (drop)="dropped($event, 'hand')">
      <ng-container *ngFor="let card of zones.hand; let i = index">
        <div class="card-wrapper" mwlDraggable dragActiveClass="being-dragged" (mousedown)="touchCard($event)"
          [dropData]="{ card: card, fromZone: 'hand', index: i }"
          (click)="mulliganCount > 0 ? putCardOnBottomOfLibrary(i) : null">
          <ng-container *ngIf="mulliganCount > 0">
            <div class="mulligan-to-bottom">Click to put on bottom</div>
          </ng-container>
          <div class="card-wrapper-inner">
            <app-card-image [cardId]="card.scryfallId" [imageUris]="card.imageUris" size="small"></app-card-image>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- EXILED -->
    <div class="zone-exiled flex-grow-0 PLACEHOLDER" *ngIf="zones.viewExtended.exiled"></div>
    <div class="zone-exiled flex-grow-0" mwlDroppable dragOverClass="droppable-dropping"
      (mouseup)="releaseMouse($event)" (drop)="dropped($event, 'exiled')"
      [ngClass]="{ 'extended' : zones.viewExtended.exiled }">
      <div class="content-wrapper relative">
        <div class="zone-title" (click)="zones.viewExtended.exiled = !zones.viewExtended.exiled">Exiled</div>
        <div class="zone-toggler right" [ngClass]="{ 'rotated' : zones.viewExtended.exiled }"
          (click)="zones.viewExtended.exiled = !zones.viewExtended.exiled">
          <fa-icon [fixedWidth]="true" [icon]="faChevronUp"></fa-icon>
        </div>
        <ng-container *ngFor="let card of zones.exiled; let i = index">
          <div class="card-wrapper" mwlDraggable dragActiveClass="being-dragged" (mousedown)="touchCard($event)"
            [dropData]="{ card: card, fromZone: 'exiled', index: i }">
            <app-card-image [cardId]="card.scryfallId" [imageUris]="card.imageUris" size="small"></app-card-image>
          </div>
        </ng-container>
        <div class="card-count" [innerText]="zones.exiled.length"></div>
      </div>
    </div>
  </div>

  <!-- ABSOLUTE POSITIONED -->
  <div class="opened-library d-flex" *ngIf="zones.viewExtended.library">
    <div class="close-library-button" (click)="zones.viewExtended.library = false; shuffleLibrary()">
      <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
    </div>
    <ng-container *ngFor="let card of zones.library | librarySearch; let i = index">
      <div class="card-wrapper" mwlDraggable dragActiveClass="being-dragged" (mousedown)="touchCard($event)"
        [dropData]="{ card: card, fromZone: 'library', index: i }">
        <div class="card-wrapper-inner">
          <app-card-image [cardId]="card.scryfallId" [imageUris]="card.imageUris" size="small"></app-card-image>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- ABSOLUTE POSITIONED -->
  <div class="opened-library d-flex" *ngIf="zones.viewExtended.library">
    <div class="close-library-button" (click)="zones.viewExtended.library = false; shuffleLibrary()">
      <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
    </div>
    <ng-container *ngFor="let card of zones.library | librarySearch; let i = index">
      <div class="card-wrapper" mwlDraggable dragActiveClass="being-dragged" (mousedown)="touchCard($event)"
        [dropData]="{ card: card, fromZone: 'library', index: i }">
        <div class="card-wrapper-inner">
          <app-card-image [cardId]="card.scryfallId" [imageUris]="card.imageUris" size="small"></app-card-image>
        </div>
      </div>
    </ng-container>
  </div>

</div>


<div class="modal-footer d-flex flex-row align-items-center">
  <div class="flex-grow-1">
    <button class="btn btn-secondary btn-sm me-2" (click)="restart()">
      <fa-icon [fixedWidth]="true" [icon]="faUndo" class="me-2"></fa-icon>Restart
    </button>
    <button class="btn btn-secondary btn-sm me-2" (click)="mulligan()" [disabled]="zonesFilled">
      <fa-icon [fixedWidth]="true" [icon]="faHand" class="me-2"></fa-icon>Mulligan
    </button>
    <span class="me-2 text-secondary">|</span>
    <button class="btn btn-secondary btn-sm me-2" (click)="untapAll()" [disabled]="mulliganCount > 0 && zonesFilled">
      <i class="ms ms-untap me-2"></i>Untap
    </button>
    <button class="btn btn-secondary btn-sm me-2" (click)="drawCard()" [disabled]="mulliganCount > 0 && zonesFilled">
      <i class="ms ms-ability-transform me-2"></i>Draw
    </button>
    <span class="me-2 text-secondary">|</span>
    <button class="btn btn-secondary btn-sm me-2" (click)="zones.viewExtended.library = true">
      <fa-icon [fixedWidth]="true" [icon]="faSearch" class="me-2"></fa-icon>Library
    </button>
    <button class="btn btn-secondary btn-sm me-2" (click)="shuffleLibrary()">
      <fa-icon [fixedWidth]="true" [icon]="faRandom" class="me-2"></fa-icon>Library
    </button>
    <span class="me-5"></span>
    <span class="text-secondary" *ngIf="mulliganCount > 0">Mulligans: {{ mulliganCount }} (resolve your
      mulligan to be able to continue)</span>
  </div>
  <!-- <div class="flex-grow-0 text-secondary">{{ mouseMovingPos.x }} : {{ mouseMovingPos.y }}</div> -->
  <div class="flex-grow-0">
    <button type="button" class="btn btn-sm btn-blank text-red"
      (click)="activeModal.close('Close click')">Close</button>
  </div>
</div>

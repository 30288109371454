<ng-container *ngIf="localCard$ | async as card">

    <div class="d-flex flex-column overflow-hidden max-height-100 min-height-100">

        <div class="menu d-flex align-items-center flex-wrap">

            <div class="flex-grow-1 m-1">
                <div class="btn btn-sm btn-blank text-secondary">
                    Card Settings
                </div>
            </div>

            <div class="action-wrapper m-1">

                <button class="btn btn-sm {{ cardChanged ? 'btn-outline-main' : 'btn-blank' }}"
                    [disabled]="!cardChanged" (click)="saveCard()">
                    <fa-icon [icon]="icons.save" [fixedWidth]="true" class="me-2"></fa-icon>Save
                </button>

                <button class="btn btn-sm btn-blank me-2" (click)="downloadCard()">
                    <fa-icon [icon]="icons.download" [fixedWidth]="true" class="me-2"></fa-icon>Download
                </button>

                <button class="btn btn-sm btn-blank me-2"
                    [disabled]="!isPublishable" (click)="publishCard()">
                    <fa-icon [icon]="icons.publish" [fixedWidth]="true" class="me-2"></fa-icon>Publish
                </button>

                <span class="me-3 border-muted-right"></span>

                <button class="btn btn-sm btn-outline-danger" (click)="deleteCard()">
                    <fa-icon [icon]="icons.delete" [fixedWidth]="true" class="me-2"></fa-icon>Delete
                </button>

            </div>

        </div>

        <div class="content d-flex flex-row flex-grow-1 p-2 overflow-hidden">

            <div class="config flex-grow-1 d-flex flex-wrap max-height-100 scrollable">

                <div class="section p-3 flex-grow-1">

                    <!-- FRAME -->
                    <div class="mb-3 frame-selector position-relative">

                        <div class="form-group">
                            <label for="artworkUpload">Frame</label>
                            <div class="input-group">
                                <input #frameSelection type="text" class="form-control" [disabled]="true">
                                <div #frameSelectionDropdown class="btn btn-outline-secondary"
                                    (click)="view.frameSelection = true">
                                    <span class="me-2">Select frame</span>
                                    <fa-icon [icon]="icons.dropDown" [fixedWidth]="true"></fa-icon>
                                </div>
                            </div>
                        </div>

                        <div class="frames-list py-2 scrollable" *ngIf="view.frameSelection">
                            <div class="frame-group" *ngFor="let group of frames">
                                <div class="frame-group-title">{{ group.text }}</div>
                                <div class="frame-group-text" *ngFor="let frame of group.frames"
                                    (click)="selectFrame(frame)">
                                    <i class="ms ms-cost ms-{{ frame.icon }} me-2"></i>
                                    {{ frame.text }}
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- TEXTS -->
                    <div class="wrapper">

                        <div class="mb-3 form-group">
                            <label for="title">Name</label>
                            <input type="text" class="form-control form-control-sm" id="title" [(ngModel)]="card.name">
                        </div>
                        <div class="mb-3 form-group">
                            <label for="title">Type</label>
                            <input type="text" class="form-control form-control-sm" id="title" [(ngModel)]="card.type">
                        </div>
                        <div class="mb-3 form-group">
                            <label for="title">Mana cost</label>
                            <input type="text" class="form-control form-control-sm" id="title"
                                [(ngModel)]="card.manaCost" placeholder="Separate symbols with comma (w,u,b,g,r,0-9,x)">
                        </div>
                        <div class="mb-3 form-group">
                            <label for="title">Power & Toughness</label>
                            <input type="text" class="form-control form-control-sm" id="title" [(ngModel)]="card.pt">
                        </div>

                    </div>

                    <!-- ARTWORK -->
                    <div class="wrapper">

                        <div class="mb-3 form-group">
                            <label for="artworkUpload">Artwork upload</label>
                            <input #imageInput type="file" accept="image/*" (change)="processFile(imageInput)"
                                id="artworkUpload" class="form-control-file">
                        </div>

                        <div class="mb-3 form-group">
                            <label for="title">Illustrator</label>
                            <input type="text" class="form-control form-control-sm" id="title"
                                [(ngModel)]="card.illustrator">
                        </div>

                        <div class="mb-3 form-group">
                            <label for="artworkSize">Artwork Size</label>
                            <input type="range" [min]="1" [max]="300" id="artworkSize"
                                [(ngModel)]="card.config.artwork.size" class="slider">
                        </div>

                        <div class="mb-3 form-group">
                            <label for="artworkPosX">Artwork Position X</label>
                            <input type="range" [min]="-100" [max]="100" id="artworkPosX"
                                [(ngModel)]="card.config.artwork.x" class="slider">
                        </div>

                        <div class="mb-3 form-group">
                            <label for="artwotkPosY">Artwork Position Y</label>
                            <input type="range" [min]="-100" [max]="100" id="artwotkPosY"
                                [(ngModel)]="card.config.artwork.y" class="slider">
                        </div>

                        <div class="mb-3 form-check form-switch">
                            <input type="checkbox" class="form-check-input" id="artworkRaster"
                                [(ngModel)]="card.config.artwork.raster">
                            <label class="form-check-label" for="artworkRaster">Raster overlay on artwork</label>
                        </div>

                    </div>

                </div>

                <div class="section p-3 flex-grow-1">

                    <!-- TEXTS -->
                    <div class="wrapper">

                        <div class="d-flex align-items-center">
                            <div class="flex-grow-1">Rules</div>
                            <div class="btn btn-sm btn-outline-main" (click)="addRulesBlock()">
                                <fa-icon [icon]="icons.add" [fixedWidth]="true" class="me-2"></fa-icon>Add text block
                            </div>
                        </div>

                        <div class="card-rules-block" *ngFor="let block of card.rulesText">

                            <div class="d-flex align-items-center mb-2">
                                <div class="flex-grow-1">Text block</div>
                                <div class="badge text-bg-secondary text-monospaced">{{ block.id }}</div>
                            </div>
                            <div class="input-group" [style.marginBottom.px]="-1">
                              <div class="btn btn-sm {{ block.isCenter ? 'btn-secondary' : 'btn-outline-secondary' }}"
                                  (click)="block.isCenter = !block.isCenter" ngbTooltip="Center text"
                                  container="body">
                                  <fa-icon [icon]="icons.center" [fixedWidth]="true"></fa-icon>
                              </div>
                              <div class="btn btn-sm {{ block.isFlavor ? 'btn-secondary' : 'btn-outline-secondary' }}"
                                  (click)="block.isFlavor = !block.isFlavor" ngbTooltip="Flavor text"
                                  container="body">
                                  <fa-icon [icon]="icons.italic" [fixedWidth]="true"></fa-icon>
                              </div>
                              <div class="btn btn-sm btn-outline-secondary" ngbTooltip="Add various symbols"
                                  container="body">
                                  <span class="me-2">Add symbol</span>
                                  <fa-icon [icon]="icons.dropDown" [fixedWidth]="true"></fa-icon>
                              </div>
                              <input type="text" [disabled]="true" class="form-control form-control-sm">
                              <div class="btn btn-sm btn-outline-secondary text-danger"
                                  (click)="deleteRulesText(block.id)" ngbTooltip="Delete text block"
                                  container="body">
                                  <fa-icon [icon]="icons.delete" [fixedWidth]="true"></fa-icon>
                              </div>
                            </div>
                            <textarea class="form-control form-control-sm rules-box-text" id="title"
                                [(ngModel)]="block.text" rows="4"></textarea>

                            <div class="d-flex align-items-center mt-3">
                                <div class="flex-grow-1">Font size</div>
                                <div class="badge text-bg-secondary text-monospaced">{{ block.fontSize }}px</div>
                            </div>
                            <input type="range" [min]="5" [max]="100" id="artworkSize" [(ngModel)]="block.fontSize"
                                class="slider">

                            <div class="d-flex align-items-center mt-2">
                                <div class="flex-grow-1">Line height</div>
                                <div class="badge text-bg-secondary text-monospaced">{{ block.lineHeight }}px</div>
                            </div>
                            <input type="range" [min]="0" [max]="100" id="artworkSize" [(ngModel)]="block.lineHeight"
                                class="slider">

                            <div class="d-flex align-items-center mt-2">
                                <div class="flex-grow-1">Padding top</div>
                                <div class="badge text-bg-secondary text-monospaced">{{ block.paddingTop }}px</div>
                            </div>
                            <input type="range" [min]="0" [max]="50" id="artworkSize" [(ngModel)]="block.paddingTop"
                                class="slider">

                            <div class="d-flex align-items-center mt-2">
                                <div class="flex-grow-1">Padding bottom</div>
                                <div class="badge text-bg-secondary text-monospaced">{{ block.paddingBottom }}px</div>
                            </div>
                            <input type="range" [min]="0" [max]="50" id="artworkSize" [(ngModel)]="block.paddingBottom"
                                class="slider">
                        </div>

                    </div>

                </div>

            </div>


            <div class="preview ms-3">

                <div class="card-preview" #cardPreview>


                    <i class="card-frame card-frame-{{ card.frame }}" *ngIf="card.frame !== 'l'"></i>
                    <i class="card-frame card-frame-l-{{ card.landColor }}" *ngIf="card.frame === 'l'"></i>

                    <i class="card-frame card-frame-raster" *ngIf="card.config.artwork.raster"></i>

                    <div class="card-image">
                        <!-- <img [src]="card.imageUrl"> -->
                        <img [src]="card.imageUrl" [style.width.%]="card.config.artwork.size"
                            [style.marginLeft.%]="card.config.artwork.x" [style.marginTop.%]="card.config.artwork.y">
                    </div>
                    <div class="card-cost" *ngIf="card.manaCost !== null">
                        <i class="mana-symbol mana-symbol-{{symbol}}"
                            *ngFor="let symbol of card.manaCost.toUpperCase().split(',')"></i>
                    </div>
                    <div class="card-name">{{ card.name }}</div>
                    <div class="card-type">{{ card.type }}</div>
                    <div class="card-pt">{{ card.pt }}</div>
                    <div class="card-illus">{{ card.illustrator }}</div>

                    <div class="card-text-wrapper">
                        <div *ngFor="let block of card.rulesText" class="card-text"
                            [ngClass]="{ 'text-center' : block.isCenter, 'text-italic' : block.isFlavor }"
                            [style.fontSize.px]="block.fontSize" [style.lineHeight.px]="block.lineHeight"
                            [style.paddingTop.px]="block.paddingTop" [style.paddingBottom.px]="block.paddingBottom"
                            [innerHTML]="block.text | spriteManaSymbol : block">
                        </div>
                    </div>

                </div>

            </div>

        </div>


    </div>

    <ng-template #deleteConfirmation let-modal>

        <div class="modal-body text-warning d-flex flex-column align-items-center">

            <fa-icon [icon]="icons.warning" [fixedWidth]="true" class="text-huge"></fa-icon>

            <div class="mt-3">You are about to delete this card, are you sure?</div>

        </div>

        <div class="modal-footer d-flex align-items-center">
            <div class="btn btn-sm btn-blank" (click)="modal.dismiss()">Cancel</div>
            <div class="btn btn-sm btn-blank text-warning" (click)="modal.close()">Delete</div>
        </div>

    </ng-template>

</ng-container>

import { ICardMeta, IDeckList } from './../../../services/decks.service';
import { Component, Input, OnInit, ViewChild, ElementRef, AfterContentChecked } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes, faChevronUp, faUndo, faSearch, faRandom } from '@fortawesome/free-solid-svg-icons';
import { cardsIcon } from 'src/assets/font-icons/cards-icon';

export interface IGoldFishCard extends ICardMeta {
  tapped: boolean;
  x: number;
  y: number;
}
export interface IRect {
  w: number | null;
  h: number | null;
  pageX: number | null;
  pageY: number | null;
  top: number | null;
  right: number | null;
  bottom: number | null;
  left: number | null;
}

@Component({
  selector: 'app-deck-test',
  templateUrl: './deck-test.component.html',
  styleUrls: ['./deck-test.component.css']
})
export class DeckTestComponent implements OnInit, AfterContentChecked {
  @Input() deck: IDeckList;
  @ViewChild('battlefield', { static: true }) battlefield: ElementRef;

  faTimes = faTimes;
  faChevronUp = faChevronUp;
  faUndo = faUndo;
  faHand = cardsIcon;
  faSearch = faSearch;
  faRandom = faRandom;

  zones = {
    library: [] as Array<IGoldFishCard>,
    graveyard: [] as Array<IGoldFishCard>,
    battlefield: [] as Array<IGoldFishCard>,
    exiled: [] as Array<IGoldFishCard>,
    hand: [] as Array<IGoldFishCard>,
    viewExtended: {
      graveyard: false,
      exiled: false,
      library: false
    }
  };

  latestTouch = {
    x: 0,
    y: 0,
    clientW: 0,
    clientH: 0,
    offsetX: 0,
    offsetY: 0
  };

  latestDrop = {
    x: 0,
    y: 0
  };

  mulliganCount = 0;

  battlefieldSize: IRect = {
    w: null,
    h: null,
    pageX: null,
    pageY: null,
    left: null,
    top: null,
    right: null,
    bottom: null,
  };
  mouseMovingPos = {
    x: 0,
    y: 0,
    outsideRect: false,
    outsideRectT: false,
    outsideRectB: false,
    outsideRectR: false,
    outsideRectL: false,
  };

  gridSize = 20;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.initDeck();
    // const rect = this.dragZone.nativeElement.getBoundingClientRect();
    // const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    // const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    // console.log({ top: rect.top + scrollTop, left: rect.left + scrollLeft, rect });
    // console.log({
    //   w: this.dragZone.nativeElement.clientWidth,
    //   h: this.dragZone.nativeElement.clientHeight,
    //   offsetWidth: this.dragZone.nativeElement.offsetWidth,
    //   offsetHeight: this.dragZone.nativeElement.offsetHeight,
    //   scrollWidth: this.dragZone.nativeElement.scrollWidth,
    //   scrollHeight: this.dragZone.nativeElement.scrollHeight,
    //   scrollLeft: this.dragZone.nativeElement.scrollLeft,
    //   clientWidth: this.dragZone.nativeElement.clientWidth
    // });
  }

  ngAfterContentChecked(): void {
    const rect = this.battlefield.nativeElement.getBoundingClientRect();
    // console.log({ top: rect.top + scrollTop, left: rect.left + scrollLeft, rect });
    // console.log({
    //   w: this.battlefield.nativeElement.clientWidth,
    //   h: this.battlefield.nativeElement.clientHeight,
    //   offsetWidth: this.battlefield.nativeElement.offsetWidth,
    //   offsetHeight: this.battlefield.nativeElement.offsetHeight,
    //   scrollWidth: this.battlefield.nativeElement.scrollWidth,
    //   scrollHeight: this.battlefield.nativeElement.scrollHeight,
    //   scrollLeft: this.battlefield.nativeElement.scrollLeft,
    //   clientWidth: this.battlefield.nativeElement.clientWidth
    // });
    this.battlefieldSize.w = this.battlefield.nativeElement.clientWidth;
    this.battlefieldSize.h = this.battlefield.nativeElement.clientHeight;
    this.battlefieldSize.pageX = rect.x;
    this.battlefieldSize.pageY = rect.y;
    this.battlefieldSize.top = rect.y;
    this.battlefieldSize.right = rect.x + this.battlefield.nativeElement.clientWidth;
    this.battlefieldSize.bottom = rect.y + this.battlefield.nativeElement.clientHeight;
    this.battlefieldSize.left = rect.x;
  }
  mouseMoving(e: MouseEvent) {
    this.mouseMovingPos.outsideRect = false;
    this.mouseMovingPos.outsideRectT = false;
    this.mouseMovingPos.outsideRectB = false;
    this.mouseMovingPos.outsideRectL = false;
    this.mouseMovingPos.outsideRectR = false;
    // console.log(e);
    this.mouseMovingPos.x = e.x;
    this.mouseMovingPos.y = e.y;
    // outside left
    if ((e.x - this.latestTouch.offsetX) < this.battlefieldSize.left) {
      this.mouseMovingPos.outsideRectL = true;
      this.mouseMovingPos.outsideRect = true;
    }
    // outside right
    if (((e.x - this.latestTouch.offsetX) + this.latestTouch.clientW) > this.battlefieldSize.right) {
      this.mouseMovingPos.outsideRectR = true;
      this.mouseMovingPos.outsideRect = true;
    }
    // outside top
    if ((e.y - this.latestTouch.offsetY) < this.battlefieldSize.top) {
      this.mouseMovingPos.outsideRectT = true;
      this.mouseMovingPos.outsideRect = true;
    }
    // outside bottom
    if (((e.y - this.latestTouch.offsetY) + this.latestTouch.clientH) > this.battlefieldSize.bottom) {
      this.mouseMovingPos.outsideRectB = true;
      this.mouseMovingPos.outsideRect = true;
    }
  }
  private initDeck() {
    console.log('initializing deck');

    // clear all zones
    this.zones.battlefield = [];
    this.zones.hand = [];
    this.zones.library = [];
    this.zones.graveyard = [];
    this.zones.exiled = [];

    this.deck.main.forEach(card => {
      const newCard: IGoldFishCard = {
        cmc: card.cmc,
        name: card.name,
        scryfallId: card.scryfallId,
        imageUris: card.imageUris,
        imageUrl: card.imageUris.normal,
        typeLine: card.typeLine,
        tapped: false,
        metaUuid: card.metaUuid ? card.metaUuid : '',
        x: 100,
        y: 100,
        setCode: card.setCode ? card.setCode : '',
        colors: card.colors ? card.colors : [],
      };
      this.zones.library.push(newCard);
    });

    this.shuffleLibrary();
    this.drawCards(7);
  }
  public rePositionCard(event, i: number) {

    console.log(event);
    this.zones.battlefield[i].x = (Math.round( (this.zones.battlefield[i].x + event.x) / this.gridSize ) * this.gridSize );
    this.zones.battlefield[i].y = (Math.round( (this.zones.battlefield[i].y + event.y) / this.gridSize ) * this.gridSize );

    if (this.mouseMovingPos.outsideRectL) {
      this.zones.battlefield[i].x = 0;
    }
    if (this.mouseMovingPos.outsideRectR) {
      this.zones.battlefield[i].x = (this.battlefieldSize.w - this.latestTouch.clientW);
    }
    if (this.mouseMovingPos.outsideRectT) {
      this.zones.battlefield[i].y = 0;
    }
    if (this.mouseMovingPos.outsideRectB) {
      this.zones.battlefield[i].y = (this.battlefieldSize.h - this.latestTouch.clientH);
    }

    console.log(this.zones.battlefield[i]);
    // catch drop coordinates to drop where release is happening
    const x = this.latestDrop.x - this.latestTouch.x;
    const y = this.latestDrop.y - this.latestTouch.y;
    console.log('Positioning card on the battlefield: ', { x, y });
  }
  public releaseMouse(event) {
    console.log('releaseMouse:', { layerX: event.layerX, layerY: event.layerY, event });
    this.latestDrop.x = event.pageX - this.battlefieldSize.top;
    this.latestDrop.y = event.pageY - this.battlefieldSize.left;
  }
  public touchCard(event) {
    console.log('touchCard:', { layerX: event.layerX, layerY: event.layerY, event });
    this.latestTouch.x = event.layerX;
    this.latestTouch.y = event.layerY;
    this.latestTouch.clientH = event.target.clientHeight;
    this.latestTouch.clientW = event.target.clientWidth;
    this.latestTouch.offsetX = event.offsetX;
    this.latestTouch.offsetY = event.offsetY;
  }
  public getPixels(num: number) {
    return num + 'px';
  }
  test(event, i: number) {
    console.log(event);
    this.zones.battlefield[i].x += event.x;
    this.zones.battlefield[i].y += event.y;
    console.log(this.zones.battlefield[i]);
  }
  public async shuffleLibrary() {
    console.log('shuffle library');
    let currentIndex = this.zones.library.length;
    let temporaryValue: any;
    let randomIndex: number;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = this.zones.library[currentIndex];
      this.zones.library[currentIndex] = this.zones.library[randomIndex];
      this.zones.library[randomIndex] = temporaryValue;
    }

    return this.zones.library;
  }
  public drawCards(num: number) {
    for (let i = 0; i < num; i++) {
      console.log('drawing a card');
      this.zones.hand.unshift(this.zones.library.splice(0, 1)[0]);
    }
  }
  public drawCard() {
    // if mulligan is not resolved return
    if (this.mulliganCount > 0) {
      return false;
    }
    console.log('draw a card');
    this.zones.hand.unshift(this.zones.library.splice(0, 1)[0]);
  }
  public dropped(event: any, toZone: string) {
    // if mulligan is not resolved return
    if (this.mulliganCount > 0) {
      return false;
    }

    const index: number = event.dropData.index;
    const fromZone: string = event.dropData.fromZone;
    const card: ICardMeta = event.dropData.card;
    console.log(`moving ${card.name} from ${fromZone} to ${toZone}`);

    // do not move cards to the same zone
    if (toZone === fromZone) {
      if (fromZone === 'battlefield') {
        // move card to top
        this.zones[toZone].push(this.zones[fromZone].splice(index, 1)[0]);
      }
      return false;
    }

    // untap the card
    this.zones[fromZone][index].tapped = false;

    // move the card
    if (toZone === 'library') {
      this.zones[toZone].unshift(this.zones[fromZone].splice(index, 1)[0]);
    }
    else {

      if (toZone === 'battlefield' && this.isNonPermanent(card.typeLine.toLocaleLowerCase())) {
        if (card.name === 'Ancestral Recall') {
          this.zones.graveyard.push(this.zones[fromZone].splice(index, 1)[0]);
          this.drawCards(3);
        }
        else if (card.name === 'Demonic Tutor') {
          this.zones.graveyard.push(this.zones[fromZone].splice(index, 1)[0]);
          this.zones.viewExtended.library = true;
        }
        else if (card.name === 'Wheel of Fortune') {
          // discard hand
          this.discardHand().then(() => {
            // draw 7
            this.drawCards(7);
          });
        }
        else if (card.name === 'Timetwister') {
          // exile card
          console.log(`exiling ${card.name}`);
          this.zones.exiled.unshift(this.zones[fromZone].splice(index, 1)[0]);
          // discard hand
          this.discardHand()
          .then(() => {
            // shuffle grave into library
            this.shuffleGraveyardIntoLibrary();
          })
          .then(() => {
            // draw 7
            this.drawCards(7);
          })
          .then(() => {
            // put twister in grave
            console.log(`putting ${card.name} into the graveyard`);
            this.zones.graveyard.push(this.zones.exiled.splice(0, 1)[0]);
          });
        }
        else {
          this.zones.graveyard.push(this.zones[fromZone].splice(index, 1)[0]);
        }
      }
      // permanent, place on battlefield
      else if (toZone === 'battlefield') {
        // catch drop coordinates to drop where release is happening
        let x = this.latestDrop.x - this.latestTouch.x;
        let y = this.latestDrop.y - this.latestTouch.y;
        console.log('Positioning card at [Battlefield]: ', { x, y });

        if (this.mouseMovingPos.outsideRectL) {
          x = 0;
        }
        if (this.mouseMovingPos.outsideRectR) {
          x = (this.battlefieldSize.w - this.latestTouch.clientW);
        }
        if (this.mouseMovingPos.outsideRectT) {
          y = 0;
        }
        if (this.mouseMovingPos.outsideRectB) {
          y = (this.battlefieldSize.h - this.latestTouch.clientH);
        }

        this.zones[fromZone][index].x = Math.round( x / this.gridSize ) * this.gridSize;
        this.zones[fromZone][index].y = Math.round( y / this.gridSize ) * this.gridSize;

        this.zones[toZone].push(this.zones[fromZone].splice(index, 1)[0]);
      }
      else {
        this.zones[toZone].push(this.zones[fromZone].splice(index, 1)[0]);
      }

    }

  }
  public untapAll() {
    console.log('untapping all permanents on the battlefield');
    this.zones.battlefield.forEach(c => c.tapped = false);
  }
  public restart() {
    this.initDeck();
    // clear mulligan count
    console.log('reset mulligan counter');
    this.mulliganCount = 0;
  }
  public mulligan() {
    console.log('taking a mulligan');
    this.initDeck();
    this.mulliganCount++;
  }
  public putCardOnBottomOfLibrary(index: number) {
    console.log('putting card from hand on bottom of library');
    this.zones.library.push(this.zones.hand.splice(index, 1)[0]);
    this.mulliganCount--;
  }
  public get zonesFilled(): boolean {
    return this.zones.battlefield.length > 0 || this.zones.graveyard.length > 0 || this.zones.exiled.length > 0;
  }
  private isNonPermanent(type: string) {
    return type.includes('sorcery') || type.includes('instant');
  }
  private async discardHand() {
    console.log('discarding hand');
    while (this.zones.hand.length > 0) {
      // remove the card from hand and add the card to graveyard
      console.log(`...discarding ${this.zones.hand[0].name}`);
      this.zones.graveyard.push(this.zones.hand.splice(0, 1)[0]);
    }
    return;
  }
  private async shuffleGraveyardIntoLibrary() {
    console.log('put graveyard into library');
    while (this.zones.graveyard.length > 0) {
      // remove the card from hand and add the card to graveyard
      console.log(`...putting ${this.zones.graveyard[0].name} ont the bottom of library`);
      this.zones.library.push(this.zones.graveyard.splice(0, 1)[0]);
    }

    this.shuffleLibrary().then(() => {
      return;
    });
  }


}

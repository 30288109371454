<ng-container *ngIf="modal; else inline">
  <div class="modal-header text-large">Import Deck List</div>

  <div class="modal-body deck-registration-wrapper p-0 d-flex flex-row">
    <ng-template [ngTemplateOutlet]="deckRegistrationForm"></ng-template>
    <ng-container *ngIf="showDeckRegistrationHelp; else deckRegistration">
      <ng-template [ngTemplateOutlet]="deckRegistrationHelp"></ng-template>
    </ng-container>
  </div>

  <div class="modal-footer">
    <div type="button" class="btn btn-sm text-red" (click)="activeModal.close()">Cancel</div>
    <button class="btn btn-sm btn-main ms-2" (click)="importDeckList()" [disabled]="saveDeckListDisabled">Save</button>
  </div>
</ng-container>
<ng-template #inline>
  <div class="d-flex deck-registration-container">
    <div class="deck-registration">
      <ng-template [ngTemplateOutlet]="deckRegistrationForm"></ng-template>
    </div>
    <div class="deck-preview-help">
      <ng-template [ngTemplateOutlet]="deckRegistrationHelp" *ngIf="showDeckRegistrationHelp"></ng-template>
      <ng-template [ngTemplateOutlet]="deckRegistration" *ngIf="!showDeckRegistrationHelp"></ng-template>
    </div>
  </div>
</ng-template>



<ng-template #deckRegistrationForm>
  <div class="deck-registration-form px-3 pb-3 me-5 mh-100 scrollable d-flex flex-column min-height-100 max-height-100">
    <label class="text-secondary d-flex mt-3" *ngIf="showInputHeader">
      <span class="flex-grow-1">Deck List</span>
      <div class="btn btn-sm btn-blank text-secondary" placement="bottom-right" ngbTooltip="Show/Hide help" (click)="showDeckRegistrationHelp = !showDeckRegistrationHelp">
        <fa-icon [fixedWidth]="true" [icon]="faQuestionCircle"></fa-icon>
      </div>
    </label>
    <textarea class="w-100 monospace flex-grow-1 deck-list-input" [(ngModel)]="deckListParsed.textList"></textarea>
    <button class="btn btn-sm btn-secondary mt-2 w-100" (click)="parseDeckList()">Generate Deck List</button>
  </div>
</ng-template>
<ng-template #deckRegistration>
  <div class="deck-registration-preview p-3 flex-grow-1 h-100 scrollable">
    <div class="preview-header" *ngIf="showDeckPreviewHeader">
      <div class="h3">
        {{ deckListParsed.name !== '' ? deckListParsed.name : 'The name of your awesome deck' }}
      </div>
      <div class="text-secondary text-italic">
        {{ deckListParsed.description !== '' ? deckListParsed.description : 'A description of your well oiledmachine...'
        }}
      </div>
    </div>
    <div class="mt-3 cards-not-found" *ngIf="notFoundCards.length > 0">
      <div class="h6 text-red">
        Card{{ notFoundCards.length > 1 ? 's' : '' }} not found, you need to correct the error{{
        notFoundCards.length > 1 ? 's' : '' }} to be able to save the deck list
      </div>
      <ul>
        <li *ngFor="let card of notFoundCards">{{ card }}</li>
      </ul>
    </div>
    <div class="deck-preview mt-5 flex-grow-1 d-flex" *ngIf="deckListParsed">
      <div class="inner-deck-wrapper d-flex w-100">
        <div class="deck-part main-deck flex-grow-1 d-flex flex-wrap align-content-start" *ngIf="deckListParsed.main.length > 0">
          <div class="h3 min-width-100">Main<span class="ms-3">{{ deckListParsed.main.length }}</span></div>
          <ng-container *ngFor="let typeSection of deckListParsed.main | deckListParser : false : false">
            <div class="deck-section mb-3">
              <div class="h6">
                <span class="me-3">{{ typeSection.cardCount }}</span>{{ typeSection.text }}
              </div>
              <div class="card-item" *ngFor="let card of typeSection.cards" (mouseenter)="cardPreviewUrl = card.imageUrl; cardPreviewSetCode = card.setCode">
                <div class="card-qty">{{ card.qty }}</div>
                <div class="card-name">{{ card.name }}</div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="preview-and-sideboard d-flex flex-column">

          <div class="card-preview mb-3 {{ cardPreviewSetCode }}" *ngIf="showCardPreview">
            <img [src]="cardPreviewUrl">
          </div>

          <div class="deck-part sideboard" *ngIf="deckListParsed.sideboard.length > 0">
            <div class="h3 text-secondary">Sideboard<span class="ms-3">{{ deckListParsed.sideboard.length }}</span></div>
            <ng-container *ngFor="let typeSection of deckListParsed.sideboard | deckListParser : true : false">
              <div class="deck-section">
                <div class="card-item text-secondary" *ngFor="let card of typeSection.cards"
                  (mouseenter)="cardPreviewUrl = card.imageUrl; cardPreviewSetCode = card.setCode">
                  <div class="card-qty">{{ card.qty }}</div>
                  <div class="card-name">{{ card.name }}</div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #deckRegistrationHelp>
  <div class="deck-registration-help p-3">
    <div class="mt-3 h3">Syntax</div>
    <div class="text-secondary">
      You can separate your deck by adding <span class="text-normal">Main Deck</span> and <span
        class="text-normal">Sideboard</span><br>
      When writing cards you should follow this syntax <span class="text-normal">[card quantity][space][card
        name]</span>.<br>
      Optionally you can specify an edition by adding <span class="text-normal">[space][|][space][set
        code]</span>.
    </div>
    <div class="text-secondary text-monospace p-3">
      <div class="text-italic">e.g.</div>
      <pre class="text-secondary">
      Main Deck

      4 City of Brass
      1 Ancestral Recall | lea
      4 Strip Mine
      1 Mox Jet
      ...

      Sideboard

      2 Circle of Protection: Red
      4 Shatterstorm
      ...
      </pre>
    </div>
  </div>
</ng-template>

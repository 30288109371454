import { BehaviorSubject } from 'rxjs';
import { EventService } from 'src/app/services';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-event-name',
  templateUrl: './event-name.component.html',
  styleUrls: ['./event-name.component.css']
})
export class EventNameComponent implements OnInit {
  @Input() eventDocId: string = null
  @Input() routerLinkEnabled: boolean = true

  public eventName$: BehaviorSubject<string> = new BehaviorSubject<string>(null)
  public event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)

  constructor(
    private router: Router,
    private eventService: EventService,
  ) {
  }

  ngOnInit(): void {
    this.init()
  }

  private init() {
    if (this.eventDocId === null) {
      this.eventDocId = decodeURIComponent(this.router.url.split('/')[2])
    }
    if (this.eventDocId === 'casual-match') {
      this.eventName$.next('Casual Match')
      this.event$.next(null)
    }
    else {
      this.eventService.getEventByIdPromise(this.eventDocId).then(event => {
        this.eventName$.next(event.details.name)
        this.event$.next(event)
      })
    }

  }

  public parseDefaultBanner(format: string): string {
    return 'assets/banners/' + format.toLowerCase().replace(/ /g, '-') + '.default.jpg';
  }

  public get routerLink(): string {
    return this.eventDocId === null
      ? this.router.url
      : `/event-lobby/${this.eventDocId}/${this.eventName$.getValue()}`
  }

  public get eventName(): string {
    return this.eventName$.getValue()
  }

}

import { IMessageListItem } from 'src/app/services';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'messageGroups'
})
export class MessageGroupsPipe implements PipeTransform {

  transform(items: IMessageListItem[], filter: string): IMessageListItem[] {
    // console.log(filter);
    const res: Array<IMessageListItem> = [];
    if (items) {
      items.forEach(messageGroup => {
        if (messageGroup.chatName.toLowerCase().includes(filter.toLowerCase())) {
          res.push(messageGroup);
        }
      });
    }
    return res;
  }

}

import { NewTicketComponent } from './new-ticket/new-ticket.component'
import { AuthService } from 'src/app/services/auth.service'
import { ActivatedRoute } from '@angular/router'
import { GlobalsService } from 'src/app/services'
import { BehaviorSubject, Observable } from 'rxjs'
import { TicketService } from './../../services/ticket.service'
import { Component, OnInit } from '@angular/core'
import { faTicketAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { faEdit, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { ITicket } from 'tolaria-cloud-functions/src/_interfaces'

enum StripeRole {
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  CUSTOMER = 'CUSTOMER',
}

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {

  now = Math.floor(new Date().getTime() / 1000)
  tickets$: Observable<ITicket[]>
  ticketIcon = faTicketAlt
  editIcon = faEdit
  deleteIcon = faTrash
  purchasedIcon = faCheckCircle
  searchString: string = ''
  userIsServiceProvider: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null)

  constructor(
    private ticketService: TicketService,
    private globals: GlobalsService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {

    this.tickets$ = this.ticketService.getTickets()

    // check if user has a connected account
    if (this.auth.user.stripe && this.auth.user.stripe.role === StripeRole.SERVICE_PROVIDER) {
      this.userIsServiceProvider.next(true)
    }

    // check if there's a search passed in the url
    this.route.queryParams
      .subscribe(params => {
        if (params.searchString) {
          this.searchString = params.searchString
        }
      }
    )
  }

  public createNewTicket(): void {

    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: 'static',
      keyboard: true,
      size: 'lg',
    }

    this.modalService.open(NewTicketComponent, modalOptions)

  }

  public buyTicket(ticket: ITicket): void {

    this.ticketService.buyTicket(ticket)

  }

  public tracker(index: number, item: ITicket) {
    return `${item.docId}-${index}`
  }

  public get isMobile(): boolean {
    return this.globals.isMobile
  }

  public get userUid(): string {
    return this.auth.user.uid
  }

  public get isAdmin(): boolean {
    return this.auth.user.role === 'admin'
  }

}

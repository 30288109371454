import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { CardFactoryHelperService } from '../card-factory-helper.service';
import { ICardComment, ICardConfig } from '../card-factory.model';


@Component({
  selector: 'app-created-card-viewer',
  templateUrl: './created-card-viewer.component.html',
  styleUrls: ['./created-card-viewer.component.css']
})
export class CreatedCardViewerComponent {

  public card$: Observable<ICardConfig>
  public comments$: Observable<ICardComment[]>

  public commentText: string = ''
  private currentId: string = null

  public icons = {
    close: faTimes,
  }

  constructor(
    private route: ActivatedRoute,
    private cfHelper: CardFactoryHelperService,
  ) {
    this.route.params.subscribe(i => {
      this.card$ = this.cfHelper.getCard(i.docId)
      this.currentId = i.docId
      this.comments$ = this.cfHelper.getCardComments(i.docId)
    })
  }

  public cfTracker(index: number, item: ICardComment) {
    return `${item.createdAt}-${item.id}`
  }

  public addComment(): void {
    this.cfHelper.commentOnCard(this.currentId, this.commentText)
    this.commentText = ''
  }

}

import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Octokit } from '@octokit/rest'
import { PlayerNameService } from 'src/app/services/players/player-name.service'

@Component({
  selector: 'app-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.css']
})
export class ReportIssueComponent implements OnInit {
  @Input() label: 'bug' | 'improvement'

  private octokit: any
  private playerName: string = ''

  public sendingData: boolean = false
  public title: string = ''
  public body: string = ''
  public errorMessage: string = ''
  public showError: boolean = false
  public validationMessage: string = ''

  constructor(
    private activeModal: NgbActiveModal,
    private readonly playerNames: PlayerNameService,
  ) {
    this.octokit = new Octokit({
      auth: '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'
    })
  }

  ngOnInit(): void {
    this.playerName = this.playerNames.currentPlayersMini.name.display
  }
  
  public cancel(): void {
    this.activeModal.dismiss()
  }
  
  public async createIssue() {

    this.sendingData = true

    this.showError = false

    let response: any
    
    try {
      response = await this.octokit.request('POST /repos/{owner}/{repo}/issues', {
        owner: 'Slanfan',
        repo: 'MTG-Tolaria',
        title: this.title,
        body: this.bodyText,
        assignees: ['Slanfan'],
        labels: [this.label]
      })
    }
    catch(error) {
      this.sendingData = false
      this.showError = true
      this.errorMessage = error.message
    }
    
    if (response.status === 201) {
      this.activeModal.close()
    }

  }

  public get issueFormValid(): boolean {
    
    this.validationMessage = ''

    if (this.title === '' || this.title.length < 10) {
      this.validationMessage = 'The summary must be at least 10 characters'
      return false
    }
    
    if (this.body === '' || this.body.length < 50) {
      this.validationMessage = 'Description must be at least 50 characters'
      return false
    }


    if (this.playerName === '') {
      this.validationMessage = 'Error loading player name, contact site administrator'
      return false
    }

    return true

  }

  private get bodyText(): string {
    
    return `${this.body}
    
    Reporter: ${this.playerNames.currentPlayersMini.name.display}
    ReporterUid: ${this.playerNames.currentPlayersMini.uid}
    RpoerterPlayerId: ${this.playerNames.currentPlayersMini.id}`
  }



}

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-hand-tracker',
  templateUrl: './hand-tracker.component.html',
  styleUrls: ['./hand-tracker.component.css']
})
export class HandTrackerComponent implements OnInit, OnDestroy {
  @Input() playerIs: string
  @Input() matchDocId: string

  private destroyed$: Subject<boolean> = new Subject<boolean>()
  private matchDoc$: BehaviorSubject<IMatchData> = new BehaviorSubject<IMatchData>(null)

  constructor(
    private readonly firestore: AngularFirestore
  ) {
  }

  ngOnInit(): void {
    this.firestore
      .collection('matches')
      .doc(this.matchDocId)
      .collection('match-room')
      .doc<any>('players-info')
      .valueChanges()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(doc => this.matchDoc$.next(doc))
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public get handSize(): number {
    const match = this.matchDoc$.getValue()
    if (match === null || match === undefined) {
      return 7
    }
    else if (match !== null && match[this.playerIs] && match[this.playerIs].handSize === undefined) {
      return 7
    }
    return this.matchDoc$.getValue()[this.playerIs].handSize
  }

}

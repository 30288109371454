<ng-container *ngIf="event !== null, else placeholder">
    <div class="card shadow-sm position-relative" [ngClass]="{ 'border-danger rounded' : event.statusCode === 8 }" [routerLink]="['/tournament', event.docId]">
    
        <div *ngIf="event.statusCode === 8" class="position-absolute top-0 end-0 me-1 mt-1">
            <span class="badge text-bg-danger">ENDED</span>
        </div>
    
        <img class="bd-placeholder-img card-img-top" width="100%" [src]="event.bannerUrl">
    
        <div class="card-body d-flex flex-column">
            <div class="card-title d-flex flex-grow-1 align-items-start">
                <span class="text-large">
                    {{ event.details.name }}
                </span>
            </div>
            <div class="mb-3 w-100 d-flex align-items-center">
                <span class="text-bold">{{ event.details.format }}</span>
                <ng-container *ngIf="event.details.ruleset.name !== ''">
                    <span class="mx-2">—</span>
                    <span>{{ event.details.ruleset.name }}</span>
                </ng-container>
            </div>
            <div class="event-details d-flex flex-wrap align-items-end gap-2">
                <span class="badge {{ !event.isOnlineTournament ? 'text-bg-primary' : 'text-bg-warning' }} text-medium">
                    <fa-icon [icon]="!event.isOnlineTournament ? listing.icons.inPerson : listing.icons.online"
                        [fixedWidth]="true" class="me-1"></fa-icon>
                    {{ !event.isOnlineTournament ? 'IN PERSON' : 'ONLINE' }}
                </span>
                <span class="badge text-bg-success text-medium" *ngIf="event.isAttending">
                    ATTENDING
                </span>
                <span class="badge text-bg-info text-medium" *ngIf="event.isInvited">
                    INVITED
                </span>
                <span class="badge text-bg-dark text-medium" *ngIf="event.isHosting">
                    HOSTING
                </span>
            </div>
            <div class="mt-3 d-flex justify-content-between align-items-center gap-3 flex-wrap">
                <button *ngIf="lobbyLink"
                    class="btn btn-outline-primary text-nowrap" type="button"
                    [routerLink]="['/tournament', event.docId]">
                    To the lobby
                </button>
                <button *ngIf="selectLink"
                    class="btn btn-outline-primary text-nowrap" type="button"
                    (click)="onSelect()">
                    Select
                </button>
                <div class="btn btn-dark text-nowrap text-medium">
                    <ng-container *ngIf="event.isMultiday">
                        <div class="d-flex align-items-center">
                            <fa-icon [icon]="listing.icons.multiday" [fixedWidth]="true" class="text-large me-2"></fa-icon>
                            <div class="d-flex flex-column align-items-end font-monospace">
                                <app-timestamp-clock [timestamp]="event.details.datestampFrom"
                                    displayType="small-date-mono"></app-timestamp-clock>
                                <app-timestamp-clock [timestamp]="event.details.datestampTo"
                                    displayType="small-date-mono"></app-timestamp-clock>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!event.isMultiday">
                        <div class="d-flex align-items-center">
                            <fa-icon [icon]="listing.icons.oneday" [fixedWidth]="true" class="text-large me-2"></fa-icon>
                            <div class="d-flex flex-column align-items-end font-monospace">
                                <app-timestamp-clock [timestamp]="event.details.datestampFrom"
                                    displayType="small-date"></app-timestamp-clock>
                                <div class="d-flex align-items-center">
                                    <app-timestamp-clock [timestamp]="event.details.datestampFrom"
                                        displayType="time"></app-timestamp-clock>
                                    <span class="mx-2">—</span>
                                    <app-timestamp-clock [timestamp]="event.details.datestampTo"
                                        displayType="time"></app-timestamp-clock>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #placeholder>
    <div class="card shadow-sm position-relative placeholder-glow">
        <div class="placeholder" width="100%" style="aspect-ratio: 2 / 1;"></div>
        <div class="card-body d-flex flex-column placeholder-glow">
            <span class="mb-3 text-large col-12 placeholder placeholder-lg rounded"></span>
            <div class="mb-3 placeholder rounded col-6"></div>
            <span class="placeholder rounded col-6"></span>
            <div class="mt-3 d-flex justify-content-between align-items-center gap-3 placeholder-glow">
                <div class="btn placeholder col-4" type="button"></div>
                <div class="btn placeholder col-4"></div>
            </div>
        </div>
    </div>
</ng-template>
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const setIconXSDC: IconDefinition = {
  prefix: 'fac',
  iconName: 'xsdc',
  icon: [
    462.917, // SVG view box width
    500, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M176.1,120.5c5.8,2.4,12.1,3.6,18.9,3.6c6.8,0,13-1.4,18.7-4c4.3-2,7.1-4.2,8.5-6.8c1.4-2.5,1.7-5.1,0.8-7.6 c-0.9-2.6-2.2-5.2-4-8c-1.8-2.8-3.4-5.6-5-8.5c-1.6-2.9-2.5-5.7-2.9-8.5c-0.4-2.8,0.6-5.7,2.8-8.6c2.3-2.9,6.1-5.7,11.6-8.2 c11.6-5.4,24.7-8,39.1-7.9c14.5,0.2,27.7,2.8,39.9,7.8c6.2,2.6,11.5,5.5,16.2,8.8c4.6,3.3,8,6.4,10.4,9.4l-36.3,14.4l-0.7-0.3 c3.1-2.8,5.5-5.3,7.2-7.7c1.7-2.3,2.8-4.6,3.2-7c0.4-2.3-0.4-4.5-2.3-6.6c-1.9-2.1-4.9-4-9.1-5.7c-4.8-2-10.4-2.9-16.8-2.8 c-6.5,0.1-12,1.2-16.7,3.4c-3.8,1.8-6.2,3.9-7.3,6.3c-1,2.4-1,4.9,0.2,7.4c1.1,2.6,2.6,5.3,4.5,8.1c1.9,2.9,3.7,5.8,5.4,8.9 c1.7,3.1,2.8,6.1,3.2,9.1c0.4,3-0.6,6-3,9.1c-2.4,3.1-6.5,6-12.2,8.7c-6.1,2.8-12.9,4.9-20.4,6.2c-7.5,1.3-15,1.8-22.5,1.5 c-7.5-0.3-15-1.2-22.3-2.8c-7.4-1.5-14.2-3.6-20.6-6.3c-13.4-5.6-23.1-12-29.2-19.3l37.1-14.9l0.7,0.3 C160,104.7,160.9,114.2,176.1,120.5 M454.3,91.5L233.4,0L13.1,102.5L234,194L454.3,91.5 M88.8,246.4l-39.1-49.3l0-1l52.2,21.6 c12.9,5.3,24.1,13.5,33.6,24.6c9.6,11.1,16.9,24.1,22,39.1c5.1,15,7.6,30.9,7.7,47.8c0.1,27.2-5.5,45.9-16.6,56.2 c-11.1,10.3-26.6,11.3-46.3,3.1l-52.2-21.6l0-0.9l10.5-4l-0.4-132.8l28.5,18.5l0.3,119.5l13.3,5.5c10.8,4.5,18.7,2,23.9-7.3 c5.1-9.3,7.7-25.7,7.6-49.2c-0.1-24.3-2.7-43-7.8-56.2c-5.2-13.1-13.2-21.9-24.1-26.4L88.7,228L88.8,246.4 M220.9,216.2L0,124.7 l0.8,283.7l220.9,91.5L220.9,216.2 M363.3,384.2c-8,3.7-15.4,5.2-22.1,4.5c-6.7-0.7-12.5-3.5-17.5-8.4c-5-4.9-8.8-12.4-11.6-22.4 c-2.8-10-4.2-22.1-4.2-36.4c0-13.1,1.4-25.9,4.3-38.3c2.9-12.4,6.8-23.5,11.8-33.3c5-9.8,10.9-18.4,17.7-25.7 c6.8-7.3,14-12.7,21.8-16.3c12.9-5.9,24.7-7,35.3-3.3l-4.3,49l-0.7,0.3c-1.1-4.1-2.1-7.8-3.2-11c-1-3.2-2.4-6.5-4.1-9.8 c-1.7-3.4-3.5-6-5.4-7.9c-1.9-1.9-4.1-3.2-6.7-3.8c-2.6-0.6-5.4-0.2-8.4,1.1c-9.5,4.3-16.6,13.5-21.4,27.5 c-4.8,14-7.1,32.4-7.1,55.3c0,49.7,9.5,70.2,28.4,61.5c3.8-1.7,7.2-4.5,10.3-8.3c3.1-3.7,5.7-8.4,8-13.9c2.3-5.5,4.2-11,5.8-16.3 c1.6-5.4,3.1-11.6,4.7-18.7l0.8-0.4l4.3,43.8C388.9,367.7,376.8,378,363.3,384.2 M462.7,117.5l-208.5,95.7l0.1,284.5L462.8,402 L462.7,117.5'
  ],
} as any;

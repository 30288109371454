import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NewEventComponent } from 'src/app/components/events/new-event/new-event.component';
import { ToastService } from 'src/app/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class CreateTournamentService {

  constructor(
    private readonly modalService: NgbModal,
    private readonly toast: ToastService,
    private readonly router: Router,
  ) { }


  public openNewTournamentForm(): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: 'static',
      keyboard: true,
      size: 'xl',
      fullscreen: window.innerWidth > 500 ? false : true,
    }
    const modalRef: NgbModalRef = this.modalService.open(NewEventComponent, modalOptions)
    modalRef.result.then(
      (documentId: string) => {
        this.toast.show('Event created successfully, redirecting', { classname: 'success-toast' })
        setTimeout(() => { this.router.navigate(['tournament', documentId]) }, 1000)
      },
      () => {
        console.log('cancel pressed on the new tournament event creation dialog')
      })
  }


}

import Quill from 'quill'

const Parchment: any = Quill.import('parchment')

export class TolariaDeckBlot extends Parchment.Embed {

  static blotName = 'tolaria-deck'
  static tagName = 'tolaria-deck'
  static className = 'ql-tolaria-deck'

  static create(data: { deckDocId: string, deckVersionDocId: string }) {
    const node = super.create() as HTMLElement
    node.setAttribute('deckdocid', String(data.deckDocId))
    node.setAttribute('deckversiondocid', String(data.deckVersionDocId))
    return node
  }

  static value(node: HTMLElement) {
    return {
      deckDocId: node.getAttribute('deckdocid') || '',
      deckVersionDocId: node.getAttribute('deckversiondocid') || '',
    }
  }

}

// Quill.register({
//   'formats/tolaria-deck': TolariaDeckBlot
// })
import { Router } from '@angular/router'
import { EventService } from './event/event.service'
import { ToastService } from 'src/app/services/toast.service'
import { AuthService } from 'src/app/services/auth.service'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { mergeMap } from 'rxjs/operators'
import { PaymentService } from 'src/app/payment/payment.service'
import { v4 as uuidv4 } from 'uuid'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { PayTicketComponent } from '../payment/pay-ticket/pay-ticket.component'
import { IConventionTicketData, ITicket, TicketType, IStripeRefundData, PaymentFactor } from 'tolaria-cloud-functions/src/_interfaces'
import * as firestore from 'firebase/firestore'




@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    private afs: AngularFirestore,
    private paymentService: PaymentService,
    private eventService: EventService,
    private auth: AuthService,
    private toast: ToastService,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  private async createTicketData(ticket: ITicket): Promise<ITicket> {
    return new Promise(async (resolve) => {

      // get currency symbol
      const symbol = this.paymentService.getCurrencySymbol(ticket.salesData.currency)
      ticket.salesData.currencySymbol = symbol

      // mark if user has purchased ticket or not
      if (ticket.salesData.ticketPurchases) {
        ticket.hasPurchased = ticket.salesData.ticketPurchases[this.auth.user.playerId] !== undefined
      }
      else {
        ticket.hasPurchased = false
      }

      // depending on ticket type, do stuff
      switch (ticket.type) {
        case TicketType.CONVENTION:

          // check if soldOut been set, else set to false before checking attendee caps
          if (ticket.soldOut === undefined) {
            ticket.soldOut = false
          }
          if (ticket.salesData.ticketLimit > 0 && ticket.salesData.sold >= ticket.salesData.ticketLimit) {
            ticket.soldOut = true
          }

          // get event attendee count of all events
          ticket.data.events = {}
          for await (const eventDocId of ticket.data.eventDocIds) {
            const event = await this.eventService.getEventByIdPromise(eventDocId)
            if (!event) { continue }
            if (event && event.details && event.details.hasAttendeeCap && event.details.attendeeCap <= event.playerDocIds.length) {
              ticket.soldOut = true
            }
            ticket.data.events[eventDocId] = {
              attendeeCap: event.details && event.details.hasAttendeeCap ? event.details.attendeeCap : '∞',
              attendingPlayers: event.playerDocIds.length,
              name: event.details.name,
            }
          }

          break
      }

      resolve(ticket)
    })
  }

  public getTickets(): Observable<ITicket[]> {
    // console.log(firestore.Timestamp.now().seconds)
    const now = Math.floor(new Date().getTime() / 1000)
    return this.afs.collection<ITicket>('tickets', ref => ref
      .where('salesData.to', '>=', now)
      .where('deleted', '==', false))
      .valueChanges()
      .pipe(
        mergeMap(async (tickets) => {
          for (let ticket of tickets) {
            ticket = await this.createTicketData(ticket)
          }
          return tickets
        })
      )
  }

  public getMyTickets(): Observable<ITicket[]> {
    // console.log(firestore.Timestamp.now().seconds)
    const now = Math.floor(new Date().getTime() / 1000)
    return this.afs.collection<ITicket>('tickets', ref => ref
      // .where('salesData.to', '>=', now)
      // .where('deleted', '==', false)
      .where('createdByUid', '==', this.auth.user.uid))
      .valueChanges()
      .pipe(
        mergeMap(async (tickets) => {
          for (let ticket of tickets) {
            ticket = await this.createTicketData(ticket)
          }
          return tickets
        })
      )
  }

  public getTicket(ticketDocId: string): Observable<ITicket> {
    return this.afs.collection('tickets').doc<ITicket>(ticketDocId).valueChanges().pipe(
      mergeMap(async (ticket) => {
        return await this.createTicketData(ticket)
      })
    )
  }

  public getTicketsForEvent(eventDocId: string): Promise<ITicket[]> {
    // console.log(`getTicketsForEvent --> fetching data for event ${eventDocId}`)
    return new Promise((resolve) => {
      this.afs.collection('tickets', ref => ref
        .where('data.eventDocIds', 'array-contains', eventDocId)
        .where('deleted', '==', false))
        .get().subscribe(async (query) => {
          // console.log(query)
          if (query.empty) {
            resolve([])
          }
          else {
            const tickets: ITicket[] = []
            for (const doc of query.docs) {
              let ticket = doc.data() as ITicket
              ticket = await this.createTicketData(ticket)
              tickets.push(ticket)
            }
            resolve(tickets)
          }
        })
    })
  }

  /**
   * Create a new ticket with the data from the user inputs
   *
   * @param type - The ticket type (CONVENTION | DRAFT)
   * @param name - The name of the ticket that will be searchable and displayed in the ticket list
   * @param price - The price of the ticket (will be in the users default currency)
   * @param from - Unix timestamp of a date set to hours 00:00:00
   * @param to - Unix timestamp of a date set to hours 23:59:59
   * @param ticketLimit - a number or ZERO (0) for unlimited
   * @param ticketData - Depending on type (IConventionTicketData)
   * @param description - A description that explains some more about the ticket
   * @param showAsSoldOut - Toggler for showing the ticket as sold out or not, if false the ticket can still be marked as sold out if actually sold out
   * @param docId - A document id if the ticket is to be updated and not created
   *
   */
  public createTicket(
    type: TicketType,
    name: string,
    price: number,
    from: number,
    to: number,
    ticketLimit: number,
    ticketData: IConventionTicketData,
    description: string,
    showAsSoldOut: boolean,
    docId?: string): Promise<boolean> {

    return new Promise((resolve, reject) => {
      if (docId === undefined || docId === null) {
        docId = uuidv4()
        const ticket: ITicket = {
          docId,
          createdAt: firestore.Timestamp.now().seconds,
          createdByPlayerDocId: this.auth.user.playerId,
          createdByUid: this.auth.user.uid,
          type,
          name,
          description: description === undefined
            ? ''
            : description,
          salesData: {
            from,
            to,
            price,
            currency: this.auth.user.stripe.default_currency,
            ticketLimit,
            ticketPurchases: {},
            sold: 0,
          },
          data: ticketData,
          deleted: false,
          showAsSoldOut: false,
        }

        this.afs.collection('tickets').doc(docId)
          .set(ticket)
          .then((res) => {
            // console.log(res)
            this.toast.show(`Ticket successfully created`, { classname: 'success-toast', duration: 4000 })
            resolve(true)
          })
          .catch((error) => {
            // console.log(error)
            this.toast.show(`Ticket could not be created -> ${error}`, { classname: 'error-toast', duration: 8000 })
            reject(true)
          })
      }
      else {
        const ticket: any = {
          docId,
          name,
          description: description === undefined
            ? ''
            : description,
          salesData: {
            from,
            to,
            price,
            ticketLimit,
          },
          data: ticketData,
          showAsSoldOut,
        }

        this.afs.collection('tickets').doc(docId)
          .set(ticket, { merge: true })
          .then((res) => {
            // console.log(res)
            this.toast.show(`Ticket successfully saved`, { classname: 'success-toast', duration: 4000 })
            resolve(true)
          })
          .catch((error) => {
            // console.log(error)
            this.toast.show(`Ticket could not be saved -> ${error}`, { classname: 'error-toast', duration: 8000 })
            reject(true)
          })
      }
    })
  }

  public deleteTicket(docId: string): void {
    this.afs.collection('tickets').doc(docId)
      .update({
        deleted: true
      })
      .then(() => {
        this.toast.show(`Ticket successfully deleted`, { classname: 'success-toast', duration: 4000 })
        this.router.navigate(['/tickets/manage'])
      })
      .catch((error) => {
        this.toast.show(`Ticket could not be deleted -> ${error}`, { classname: 'error-toast', duration: 8000 })
      })
  }

  /**
   * This method will open up the payment modal that will show the full information about the ticket
   * and have the link to be able to create a checkout session through stripe (tolaria payment)
   *
   * @param ticket - The ticket object to be passed into the payment component
   */
  public buyTicket(ticket: ITicket): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: 'static',
      keyboard: true,
      size: 'sm',
    }
    const ref = this.modalService.open(PayTicketComponent, modalOptions)
    ref.componentInstance.ticket = ticket
  }

  public refundTicket(ticket: ITicket, paymentIntent: string): void {
    const data: IStripeRefundData = {
      amount: ticket.salesData.price * PaymentFactor,
      payment_intent: paymentIntent
    }
    this.paymentService.issueRefund(data)
  }

}

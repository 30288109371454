import { StripePayoutStatus, IRegistrationFeePayment, ProductType, ITicketMeta } from './stripe.interfaces'

// Events
export type EventType = 'swiss' | 'batch' | 'group' | 'round-robin' | 'bracket' | 'single-bracket' | 'double-bracket'

export interface ITournamentMiniCollection {
  [key: string]: ITournamentMinified | string[]
}
export interface ITournamentMinified {
  docId: string
  name: string
  createdBy: string
  organizer: string
  isOnline: boolean
  allowSpectators: boolean
  isInPerson: boolean
}
export interface IEventDetails {
  docId: string
  createdByUid: string
  details: INewEventForm
  statusCode: number
  statusText: string
  activeRound: number
  startingTable: number
  roundTimer: number
  isOnlineTournament: boolean
  coOrganizers: string[]
  playerDocIds: string[]
  invitedPlayers: IInvitedPlayer[]
  invitedEmails?: IInvitedEmail[]
  teamIds?: string[]
  checkInHasStarted?: boolean
  checkInByPlayer?: boolean
  statusTimestamp?: string
  activeRoundStartedTimestamp?: number
  activeRoundEndingTimestamp?: number
  waitingList?: IWaitListPlayer[]
  unpairedPlayers?: IMatchPlayer[]
  reserveDocIds?: string[]
  bannerUrl?: string
  ticketDocIds?: string[]
  usersManagingEvent?: string[]
  eventFormValid?: boolean
  isArchived: boolean
  isDeleted: boolean
  log: IEventLog[]
  sendPush?: boolean
}
export interface INewEventForm {
  attendeeCap: number;
  dateStamp?: number;
  datestampFrom: number;
  datestampTo: number;
  datetime: string;
  datetimeFrom: string;
  datetimeTo: string;
  deckInfoIsPublic: boolean;
  publishDecksOnEventEnd?: boolean;
  deckList: boolean;
  deckPhoto: boolean;
  description: string;
  dropPlayersWithoutSubmittedDeckOnStart: boolean;
  format: string;
  GMT_offset: string;
  hasAttendeeCap: boolean;
  isMultiDay: boolean;
  isOnlineTournament: boolean;
  allowSpectators: boolean
  isPasswordProtected: boolean;
  isPublic: boolean;
  isPubliclyVisible: boolean;
  location: IEventLocationDetails;
  name: string;
  password: string;
  registrationFee: {
    active: boolean;
    tolariaPayment: boolean;
    forcePaymentWhenAttending: boolean;
    charityExtra: boolean;
    amount: number;
    currency: string;
    organizerDefaultCurrency?: string;
    productId: string;
    productType: ProductType;
    paidBy?: {
      [key: string]: IRegistrationFeePayment
    };
    paidByTicket?: {
      [key: string]: ITicketMeta
    }
    payout?: {
      status?: StripePayoutStatus;
      paid?: boolean;
      amount?: number;
      currency?: string;
      created?: number;
      id?: string;
      transferred?: boolean;
      timestamp?: number;
    }
  };
  registrationOpen: boolean;
  registrationOpensTimestamp: number;
  registrationClosesTimestamp: number;
  reprintPolicy: IReprintPolicy;
  roundTimer: number;
  ruleset: IRuleset;
  startingTable: number;
  structure: IEventStructureSettings;
  type: 'Constructed' | 'Limited' | '';
  provideFood: boolean;

}
export interface IEventTeam {
  id: string;
  dropped: boolean;
  name: string;
  player: {
    a: string;
    b: string;
    c: string;
  }
  playerDocIds: string[];
  localPlayers?: IEventTeamLocalPlayer[];
  rank?: number;
  seed?: number;
  isWinner?: boolean;
  isLoser?: boolean;
  matchPoints?: number;
  matchesWon?: number;
  matchesLost?: number;
  matchesDrawn?: number;
  matchesPlayed?: number;
  gamePoints?: number;
  gamesWon?: number;
  gamesLost?: number;
  gamesDrawn?: number;
  gamesPlayed?: number;
  haveByeMatch?: boolean;
  pairedUpDown?: boolean;
  teamsPlayed?: string[];
  avoid?: string[];
  tiebreakers?: {
    gameWinPct: number,
    gameWinPctBye: number,
    matchWinPct: number,
    matchWinPctBye: number,
    oppGameWinPct: number
    oppMatchWinPct: number,
  }
  selected?: boolean;
  rating?: number;
  index?: number;
}
export interface IEventTeamLocalPlayer {
  name: string
  id: string
}
export interface IWaitListPlayer {
  added: number
  playerDocId: string
  name: string
  selected?: boolean
}
export interface IInvitedPlayer {
  email: string;
  isRegistered: boolean;
  playerUid: string;
  playerDocId: string;
  accepted: boolean;
  declined: boolean;
  sendEmail: boolean;
  sendMessage: boolean;
  eventDocId: string;
  messageDocId?: string;
  createdAt?: number
  answeredAt?: number
  selected?: boolean
}
export interface IInvitedEmail {
  email: string
  playerDocId: string | null
  playerUid: string | null
}
export interface IMatchPlayer {
  playerDocId: string;
  playerUid: string;
  displayName: string;
  isWinner: boolean;
  wins: number;
  draws: number;
  losses: number;
  drop: boolean;
  matchPoints?: number;
  rank?: number;
  seed?: number;
  lifePoints?: Array<number | null>;
  segmentNumber?: number;
  notes?: Array<string>;
  handSize?: number;
  teamId?: string;
  teamPlayerDocIds?: string[]
  teamPlayerNames?: string[]
}

export type LogType = 'status-update'
| 'round-update'
| 'batch-config'
| 'group-config'
| 'match-update'
| 'payment'
| 'deck-submission'
| 'invitation'
| 'attending'
| 'team'
| 'check-in'
| 'pairing'
| 'config'
| 'other'
export interface IEventLog {
  timestamp: number
  text: string
  type: LogType
  error?: string
  metadata?: {
    [key:string]: string | number | any
  }
}
export interface IEventLocationDetails {
  name: string;
  address: string;
  extra: string;
  postalCode: string;
  postalArea: string;
  url: string;
  longitude: string;
  latitude: string;
  geocode: string;
}
export interface IRuleset {
  name: string;
  url: string;
  downToPlay?: boolean;
  imageUris?: {
    downToPlay: string;
    eventBanner: string;
  };
}
export interface IReprintPolicy {
  name: string;
  url: string;
}
export interface IEventStructureSettings {
  isSwiss: boolean;
  isBracket: boolean;
  isGroup: boolean;
  isRoundRobin: boolean;
  isBatch: boolean;
  swiss: ISwissSettings;
  bracket: IBracketSettings;
  group: IGroupSettings;
  batch?: IBatchSettings;
  bracketMatrix?: Array<ISingleBracketMatrixRound>;
}
export interface ISwissSettings {
  hideStandingsUntilPosted: boolean;
  hasBracketAfterSwiss: boolean;
  bracketSize: number;
  defaultNumberOfSwissRounds?: number;
  roundsToPlay: number;
  teams?: boolean
  standingsPosted?: boolean
}
export interface IBracketSettings {
  singleElimination: boolean;
  doubleElimination: boolean;
}
export interface IGroupSettings {
  hasBracketAfterGroupStage: boolean;
  matchesPerGroup: number;
  playersPerGroup: number;
  numberOfGroups: number;
  playersAdvancingPerGroup: number;
  bracketSize: number;
  groupConfig?: ITournamentGroupConfig
  groups?: ITournamentGroup[]
  preferSameTimeZonePlayerDocIds?: string[]
}
export interface ITournamentGroupConfig {
  numberOfGroups: number,
  matchesPerPlayer: number,
  roundRobin: boolean,
  allowByes: boolean
  autoGenerate: boolean,
  showHelp: boolean,
}
export interface ITournamentGroup {
  id: string
  letter: string
  name: string
  description: string
  playerDocIds: string[]
  // optional
  messageGroupDocId?: string
  // used during config
  playerDocs?: IEventPlayerDetails[]
  canBePaired?: boolean
  warningText?: string
  pairingSuccessful?: boolean
  hovered?: boolean
  isEditing?: boolean
}
export interface IEventGroup {
  name: string;
  players: Array<IEventPlayerDetails>;
  canBePaired?: boolean;
  warningText?: string;
  pairingSuccessful?: boolean;
}
export interface IBatchSettings {
  hasBracketAfterBatch: boolean;
  bracketSize: number;
  batches: Array<IBatchConfig>;
}
export interface IBatchConfig {
  numberOfMatches: number;
  numberOfRandomMatches: number;
  numberOfStandingsMatches: number;
  roundNumber: number;
  batchId?: string;
}
export interface ISingleBracketMatrixRound {
  round: number;
  matches: Array<ISingleBracketMatrixMatch>;
}
export interface ISingleBracketMatrixMatch {
  byeMatch: boolean;
  feedsMatch: number;
  feedsPlayerSlot?: string;
  feedsMatchDocId: string;
  matchDocId: string;
  teams?: {
    teamOne: {
      id: string
      seed: number
    },
    teamTwo: {
      id: string
      seed: number
    }
  }
  active?: boolean
}
export interface IBracketMeta {
  title: string
  round: number
  matches: IMatchData[]
}
export interface IEventPlayerDetails {
  eventDocId?: string;
  eventName?: string;
  playerDocId: string;
  playerUid: string;
  name: string;
  dropped: boolean;
  disqualified?: boolean;
  haveByeMatch?: boolean;
  wins?: number;
  losses?: number;
  draws?: number;
  matchPoints?: number;
  gamePoints?: number;
  matchWinPercentage?: number;
  gameWinPercentage?: number;
  opponentMatchWinPercentage?: number;
  opponentGameWinPercentage?: number;
  adj_gp?: number;
  adj_gwp?: number;
  adj_mp?: number;
  adj_mwp?: number;
  opponentsDocIds?: Array<string>;
  teamId?: string;
  teamSeat?: 'a' | 'b' | 'c';
  pairedUpDown?: boolean;
  selected?: boolean;
  rank?: number;
  rankText?: string;
  finalStanding?: number | string;
  seed?: number;
  playedInGroup?: string;
  playedInGroupId?: string
  groupRank?: number;
  deckSubmission?: IDeckSubmission;
  hasCheckedIn?: boolean;
  dietaryInformation?: {
    hasRestrictions: boolean
    information: string
  }
  gamesWon?: number;
  gamesLost?: number;
  gamesDrawn?: number;
  gamesPlayed?: number;
  matchesWon?: number;
  matchesLost?: number;
  matchesDrawn?: number;
  matchesPlayed?: number;
  avoid?: string[];
  rating?: number;
  index?: number;
  playoffMatchesPlayed?: number
  playoffMatchesWon?: number
}
export type MatchAssignement =
  | 'Bye'
  | 'Loss'
  | 'Double Bye'
export type MatchType =
  | 'swiss'
  | 'bracket'
  | 'batch'
  | 'group'
  | 'round-robin'
  | 'double-bracket'
  | 'casual-match'
  | 'swiss-team'
  | 'bracket-team'
export interface IMatchData {
  docId: string;
  deleted: boolean
  eventDocId: string;
  createdByUid: string;
  feedsMatchDocId: string;
  feedsMatchPlayer: string;
  winnerGoToMatchDoc: string;
  winnerGoToPlayerSlot: string;
  loserGoToMatchDoc: string;
  loserGoToPlayerSlot: string;
  isDraw: boolean;
  isReported: boolean;
  isByeMatch: boolean;
  isLossMatch: boolean;
  isType: MatchType;
  isManuallyPaired?: boolean;
  player1: IMatchPlayer;
  player2: IMatchPlayer;
  playerFilterValue: string;
  roundNumber: number;
  segmentNumber: number;
  segmentType: string;
  tableNumber: number;
  playerDocIds: Array<string>;
  groupName: string;
  groupId?: string
  groupLetter?: string
  timestampCreated: number;
  timestampReported: number | null;
  // optional properties
  roundEndingTimestamp?: number;
  reportSlip?: IMatchReportSlip;
  reportSlipOpenedBy: string | null
  playerReporting?: IMatchPlayerReporing;
  holdPlayers?: boolean;
  callingPlayers?: boolean;
  hasPlayedEarlier?: boolean; // used for flagging >>> Should most likley be moved into anoter interface
  playedThisOpponentInRound?: any
  isFeatured?: boolean,
  featureUris?: IFeatureMatchUris
  teamIds?: string[]
  teamSeat?: 'a' | 'b' | 'c'
  bracketMatrixMatchDocId?: string
  bracketMatrixPathToVictory?: string
  bracketMatrixPathToVictorySlot?: string
  disableReport?: boolean
  disableReset?: boolean
  bracket?: 'winner' | 'loser'
  matchIndex?: number
}
export interface IFeatureMatchUris {
  player1: string;
  player2: string;
}
export interface IMatchReportSlip {
  player1Confirmed: boolean;
  player2Confirmed: boolean;
  showSlip: boolean;
  openedByPlayerDocId: string;
}

export interface IMatchPlayerReporing {
  initiatedByPlayerDocId: string
  initiatedAt: number
  waitingForPlayerDocId: string[]
  confirmedByInitiator: boolean
  confirmedByOpponent?: boolean
  rejectedByOpponent?: boolean
}
export interface IBracketSize {
  size: number;
  text: string;
}
export interface IFormat {
  name: string;
  reprintPolicies: Array<IReprintPolicy>;
  ruleSets: Array<IRuleset>;
  imageUris: {
    downToPlay: string,
    eventBanner: string
  },
  downToPlay: boolean;
}

export interface IDeckSubmission {
  deckPhoto: boolean;
  deckList: boolean;
  deckListDocId: string | null;
  deckVersionDocId: string | null;

  deckPhotoUrl?: string;
  deckName?: string;
  deckDescription?: string;
}

export interface IMatchRoomKnock {
  docId: string
  allowedBy: string[]
  deniedBy: string[]
  knockingPlayerDocId: string
  matchDocId: string
}

export interface IMatchRoomPlayersInfo {
  player1: {
    lifePoints: Array<number | null>
    handSize: number
    playerDocId: string
  },
  player2: {
    lifePoints: Array<number | null>
    handSize: number
    playerDocId: string
  }
}

import { ToastService } from './../../services/toast.service';
import { faMapMarkedAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { ClubManagementComponent } from './club-management/club-management.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ClubsService } from 'src/app/services/clubs.service';
import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/services';
import { IClub } from 'tolaria-cloud-functions/src/_interfaces';

export interface IClubCoords {
  longitude: number;
  latitude: number;
}
@Component({
  selector: 'app-clubs',
  templateUrl: './clubs.component.html',
  styleUrls: ['./clubs.component.css']
})
export class ClubsComponent implements OnInit {

  public faMapMarkedAlt = faMapMarkedAlt;
  public faMapMarkerAlt = faMapMarkerAlt;
  public clubs$: Observable<IClub[]>;
  public isResponsibleOfClub = false;
  public filterValue: string = '';
  public coords: IClubCoords = {
    longitude: null,
    latitude: null
  }
  public useCoords: boolean = false;
  private clubDocId: string;

  public showMapView = false;
  public mapZoomLevel = 2;
  public lat = 31.897257;
  public lng = -36.073363;
  public iconUrl = 'https://firebasestorage.googleapis.com/v0/b/tolaria-mtg.appspot.com/o/location-pin-2965.png?alt=media&token=b7388bd4-0c59-4968-b994-e3a873e3f3f8';

  constructor(
    private clubsService: ClubsService,
    private auth: AuthService,
    private modalService: NgbModal,
    private toastService: ToastService
  ) {
    this.clubs$ = this.clubsService.getClubs()
  }

  ngOnInit(): void {

    this.clubs$.pipe(take(1)).subscribe(clubs => {
      // console.log(clubs);
      const playerDocId = this.auth.user.playerId;
      if (clubs.filter(c => c.responsiblePlayerDocIds.includes(playerDocId)).length > 0) {
        this.isResponsibleOfClub = true;
        this.clubDocId = clubs.filter(c => c.responsiblePlayerDocIds.includes(playerDocId))[0].docId;
      }
    })

  }

  clearFilters() {
    this.coords.longitude = null;
    this.coords.latitude = null;
    this.useCoords = false;
    this.filterValue = '';
  }

  findNearbyClubs(): void {
    // get users current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        this.coords.longitude = position.coords.longitude;
        this.coords.latitude = position.coords.latitude;
        this.useCoords = true;
      });
    } else {
      this.toastService.show('Geolocation is not supported by this browser.', { delay: 6000, classname: 'error-toast' });
    }
  }

  manageYourClub(): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    };

    const modalRef: NgbModalRef = this.modalService.open(ClubManagementComponent, modalOptions);
    modalRef.componentInstance.clubDocId = this.clubDocId;

  }

  manageClub(clubDocId: string): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    };

    const modalRef: NgbModalRef = this.modalService.open(ClubManagementComponent, modalOptions);
    modalRef.componentInstance.clubDocId = clubDocId;

  }

  public get isAdmin() {
    return this.auth.user.role === 'admin';
  }

}

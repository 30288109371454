<div class="d-flex flex-column"
  [ngbTooltip]="mainTooltip">

  <input type="number" class="form-control form-control-lg my-3" [(ngModel)]="amount" *ngIf="valueInput">

  <button
    [class]="buttonClass"
    [disabled]="disablePurchaseButton"
    (click)="createCheckoutSession()"
    [innerHtml]="buttonText">
  </button>

</div>

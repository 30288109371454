import { ToastService } from './../../../../services/toast.service'
import { PresenceService } from './../../../../services/presence.service'
import { IPresence } from 'src/app/services/presence.service'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { EventService, AuthService } from 'src/app/services'
import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { IFormat } from 'tolaria-cloud-functions/src/_interfaces'
import { map, take, takeUntil } from 'rxjs/operators'
import { combineLatest, Observable, Subject } from 'rxjs'

export interface IFormatMeta {
  name: string
  selected: boolean
}
export interface IFormatData {
  formats: Array<IFormat>
  presence: IPresence
}

@Component({
  selector: 'app-down-to-play-settings',
  templateUrl: './down-to-play-settings.component.html',
  styleUrls: ['./down-to-play-settings.component.css']
})
export class DownToPlaySettingsComponent implements OnInit, OnDestroy {
  @Input() presence: IPresence
  @Input() uid: string

  faCheckCircle = faCheckCircle
  faCircle = faCircle

  public formatForm: Array<IFormatMeta> = []
  private formatData$: Observable<IFormatData>

  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()
  public active: boolean = false

  constructor(
    public es: EventService,
    private presenceService: PresenceService,
    private auth: AuthService,
    private toastService: ToastService
  ) {
    this.formatData$ = combineLatest([this.es.formats$, this.presenceService.getPresence(this.auth.user.uid)])
      .pipe(
        map(([formats, presence]) => {
          return {
            formats,
            presence
          }
        }),
        take(1)
      )
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  ngOnInit(): void {
    this.formatData$.pipe(takeUntil(this.componentDestroyed$)).subscribe((formatData) => {
      this.initFormats(formatData.formats, formatData.presence)
    })
    this.presenceService.getPresence(this.uid).pipe(takeUntil(this.componentDestroyed$)).subscribe(i => {
      this.active = i.downToPlay === undefined ? false : i.downToPlay
    })
  }
  public tracker(index: number, item: IFormat) {
    return `${item.name}-${index}`
  }
  async initFormats(formats: Array<IFormat>, presence: IPresence) {
    const res = []
    for await (const format of formats) {
      if (format.ruleSets !== undefined && format.ruleSets.length > 0) {
        for (const ruleset of format.ruleSets) {
          if (ruleset.downToPlay === undefined || ruleset.downToPlay === true) {
            res.push({
              name: format.name + ': ' + ruleset.name,
              selected: presence.hasOwnProperty('downToPlayFormats') && presence.downToPlayFormats.findIndex(f => f.name === format.name + ': ' + ruleset.name) > -1
                ? presence.downToPlayFormats.find(f => f.name === format.name + ': ' + ruleset.name).selected
                : false
            })
          }
        }
      }
      else {
        res.push({
          name: format.name,
          selected: presence.hasOwnProperty('downToPlayFormats') && presence.downToPlayFormats.findIndex(f => f.name === format.name) > -1
            ? presence.downToPlayFormats.find(f => f.name === format.name).selected
            : false
        })
      }
    }

    this.formatForm = res
  }
  updateFormatList() {
    this.presenceService.updateDownToPlayFormats(this.formatForm)
      .then(() => {
        this.toastService.show('Down to play formats updated successfully', { classname: 'success-toast', delay: 2000 })
      })
  }
  toggleDownToPlay(state?: boolean) {
    this.presenceService.toggleDownToPlay(state ? state : !this.active)
  }

}

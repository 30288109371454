export interface EmojiCategory {
  category: string
  text: string
  icons: EmojiItem[]
}
export interface EmojiItem {
  search: string[]
  name: string
  image: string
  icon: string
  shortcode: string
  unicodes: string[]
  colorindex?: number
}

export const emojis: EmojiCategory[] = [
  {
    "category": "smileys_and_people",
    "text": "Smileys & People",
    "icons": [
      {
        "search": [
          "grinning",
          "face"
        ],
        "name": "grinning_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i6cqjosd.png",
        "icon": "😀",
        "shortcode": ":grinning_face:",
        "unicodes": [
          "u1f600"
        ]
      },
      {
        "search": [
          "grinning",
          "face",
          "with",
          "big",
          "eyes"
        ],
        "name": "grinning_face_with_big_eyes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jumnrm9f.png",
        "icon": "😃",
        "shortcode": ":grinning_face_with_big_eyes:",
        "unicodes": [
          "u1f603"
        ]
      },
      {
        "search": [
          "grinning",
          "face",
          "with",
          "smiling",
          "eyes"
        ],
        "name": "grinning_face_with_smiling_eyes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ip60aku2.png",
        "icon": "😄",
        "shortcode": ":grinning_face_with_smiling_eyes:",
        "unicodes": [
          "u1f604"
        ]
      },
      {
        "search": [
          "beaming",
          "face",
          "with",
          "smiling",
          "eyes"
        ],
        "name": "beaming_face_with_smiling_eyes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vjog1bm3.png",
        "icon": "😁",
        "shortcode": ":beaming_face_with_smiling_eyes:",
        "unicodes": [
          "u1f601"
        ]
      },
      {
        "search": [
          "grinning",
          "squinting",
          "face"
        ],
        "name": "grinning_squinting_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0abqdgj1.png",
        "icon": "😆",
        "shortcode": ":grinning_squinting_face:",
        "unicodes": [
          "u1f606"
        ]
      },
      {
        "search": [
          "grinning",
          "face",
          "with",
          "sweat"
        ],
        "name": "grinning_face_with_sweat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/obvhr3zv.png",
        "icon": "😅",
        "shortcode": ":grinning_face_with_sweat:",
        "unicodes": [
          "u1f605"
        ]
      },
      {
        "search": [
          "rolling",
          "on",
          "the",
          "floor",
          "laughing"
        ],
        "name": "rolling_on_the_floor_laughing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v15th6hq.png",
        "icon": "🤣",
        "shortcode": ":rolling_on_the_floor_laughing:",
        "unicodes": [
          "u1f923"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "tears",
          "of",
          "joy"
        ],
        "name": "face_with_tears_of_joy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jn9d7u5i.png",
        "icon": "😂",
        "shortcode": ":face_with_tears_of_joy:",
        "unicodes": [
          "u1f602"
        ]
      },
      {
        "search": [
          "slightly",
          "smiling",
          "face"
        ],
        "name": "slightly_smiling_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4hd3brfw.png",
        "icon": "🙂",
        "shortcode": ":slightly_smiling_face:",
        "unicodes": [
          "u1f642"
        ]
      },
      {
        "search": [
          "upside_down",
          "face"
        ],
        "name": "upside_down_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/asymbq1q.png",
        "icon": "🙃",
        "shortcode": ":upside_down_face:",
        "unicodes": [
          "u1f643"
        ]
      },
      {
        "search": [
          "melting",
          "face"
        ],
        "name": "melting_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8umb0rgt.png",
        "icon": "🫠",
        "shortcode": ":melting_face:",
        "unicodes": [
          "u1fae0"
        ]
      },
      {
        "search": [
          "winking",
          "face"
        ],
        "name": "winking_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tz4qbsj6.png",
        "icon": "😉",
        "shortcode": ":winking_face:",
        "unicodes": [
          "u1f609"
        ]
      },
      {
        "search": [
          "smiling",
          "face",
          "with",
          "smiling",
          "eyes"
        ],
        "name": "smiling_face_with_smiling_eyes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j84x6yw6.png",
        "icon": "😊",
        "shortcode": ":smiling_face_with_smiling_eyes:",
        "unicodes": [
          "u1f60a"
        ]
      },
      {
        "search": [
          "smiling",
          "face",
          "with",
          "halo"
        ],
        "name": "smiling_face_with_halo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xep2r48o.png",
        "icon": "😇",
        "shortcode": ":smiling_face_with_halo:",
        "unicodes": [
          "u1f607"
        ]
      },
      {
        "search": [
          "smiling",
          "face",
          "with",
          "hearts"
        ],
        "name": "smiling_face_with_hearts",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0zgjb3yj.png",
        "icon": "🥰",
        "shortcode": ":smiling_face_with_hearts:",
        "unicodes": [
          "u1f970"
        ]
      },
      {
        "search": [
          "smiling",
          "face",
          "with",
          "heart_eyes"
        ],
        "name": "smiling_face_with_heart_eyes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fhbqk9gc.png",
        "icon": "😍",
        "shortcode": ":smiling_face_with_heart_eyes:",
        "unicodes": [
          "u1f60d"
        ]
      },
      {
        "search": [
          "star_struck"
        ],
        "name": "star_struck",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iz3twiy5.png",
        "icon": "🤩",
        "shortcode": ":star_struck:",
        "unicodes": [
          "u1f929"
        ]
      },
      {
        "search": [
          "face",
          "blowing",
          "a",
          "kiss"
        ],
        "name": "face_blowing_a_kiss",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gf19gdgz.png",
        "icon": "😘",
        "shortcode": ":face_blowing_a_kiss:",
        "unicodes": [
          "u1f618"
        ]
      },
      {
        "search": [
          "kissing",
          "face"
        ],
        "name": "kissing_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qxxrna3j.png",
        "icon": "😗",
        "shortcode": ":kissing_face:",
        "unicodes": [
          "u1f617"
        ]
      },
      {
        "search": [
          "smiling",
          "face"
        ],
        "name": "smiling_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0ob9au4w.png",
        "icon": "☺",
        "shortcode": ":smiling_face:",
        "unicodes": [
          "u263a"
        ]
      },
      {
        "search": [
          "kissing",
          "face",
          "with",
          "closed",
          "eyes"
        ],
        "name": "kissing_face_with_closed_eyes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/l5nwdpro.png",
        "icon": "😚",
        "shortcode": ":kissing_face_with_closed_eyes:",
        "unicodes": [
          "u1f61a"
        ]
      },
      {
        "search": [
          "kissing",
          "face",
          "with",
          "smiling",
          "eyes"
        ],
        "name": "kissing_face_with_smiling_eyes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hakastta.png",
        "icon": "😙",
        "shortcode": ":kissing_face_with_smiling_eyes:",
        "unicodes": [
          "u1f619"
        ]
      },
      {
        "search": [
          "smiling",
          "face",
          "with",
          "tear"
        ],
        "name": "smiling_face_with_tear",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/41qqf99p.png",
        "icon": "🥲",
        "shortcode": ":smiling_face_with_tear:",
        "unicodes": [
          "u1f972"
        ]
      },
      {
        "search": [
          "face",
          "savoring",
          "food"
        ],
        "name": "face_savoring_food",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/voeg9gtu.png",
        "icon": "😋",
        "shortcode": ":face_savoring_food:",
        "unicodes": [
          "u1f60b"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "tongue"
        ],
        "name": "face_with_tongue",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s4kjjmf6.png",
        "icon": "😛",
        "shortcode": ":face_with_tongue:",
        "unicodes": [
          "u1f61b"
        ]
      },
      {
        "search": [
          "winking",
          "face",
          "with",
          "tongue"
        ],
        "name": "winking_face_with_tongue",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/58xpds9a.png",
        "icon": "😜",
        "shortcode": ":winking_face_with_tongue:",
        "unicodes": [
          "u1f61c"
        ]
      },
      {
        "search": [
          "zany",
          "face"
        ],
        "name": "zany_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v5w14wkl.png",
        "icon": "🤪",
        "shortcode": ":zany_face:",
        "unicodes": [
          "u1f92a"
        ]
      },
      {
        "search": [
          "squinting",
          "face",
          "with",
          "tongue"
        ],
        "name": "squinting_face_with_tongue",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/r3cj4yx9.png",
        "icon": "😝",
        "shortcode": ":squinting_face_with_tongue:",
        "unicodes": [
          "u1f61d"
        ]
      },
      {
        "search": [
          "money_mouth",
          "face"
        ],
        "name": "money_mouth_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tdt7ldh0.png",
        "icon": "🤑",
        "shortcode": ":money_mouth_face:",
        "unicodes": [
          "u1f911"
        ]
      },
      {
        "search": [
          "smiling",
          "face",
          "with",
          "open",
          "hands"
        ],
        "name": "smiling_face_with_open_hands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rfi4ts8e.png",
        "icon": "🤗",
        "shortcode": ":smiling_face_with_open_hands:",
        "unicodes": [
          "u1f917"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "hand",
          "over",
          "mouth"
        ],
        "name": "face_with_hand_over_mouth",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zl8tf2oe.png",
        "icon": "🤭",
        "shortcode": ":face_with_hand_over_mouth:",
        "unicodes": [
          "u1f92d"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "open",
          "eyes",
          "and",
          "hand",
          "over",
          "mouth"
        ],
        "name": "face_with_open_eyes_and_hand_over_mouth",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/da57e54r.png",
        "icon": "🫢",
        "shortcode": ":face_with_open_eyes_and_hand_over_mouth:",
        "unicodes": [
          "u1fae2"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "peeking",
          "eye"
        ],
        "name": "face_with_peeking_eye",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a0tut99j.png",
        "icon": "🫣",
        "shortcode": ":face_with_peeking_eye:",
        "unicodes": [
          "u1fae3"
        ]
      },
      {
        "search": [
          "shushing",
          "face"
        ],
        "name": "shushing_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uqtjf99i.png",
        "icon": "🤫",
        "shortcode": ":shushing_face:",
        "unicodes": [
          "u1f92b"
        ]
      },
      {
        "search": [
          "thinking",
          "face"
        ],
        "name": "thinking_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cm7lhilb.png",
        "icon": "🤔",
        "shortcode": ":thinking_face:",
        "unicodes": [
          "u1f914"
        ]
      },
      {
        "search": [
          "saluting",
          "face"
        ],
        "name": "saluting_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lbw52eow.png",
        "icon": "🫡",
        "shortcode": ":saluting_face:",
        "unicodes": [
          "u1fae1"
        ]
      },
      {
        "search": [
          "zipper_mouth",
          "face"
        ],
        "name": "zipper_mouth_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ljfxr66v.png",
        "icon": "🤐",
        "shortcode": ":zipper_mouth_face:",
        "unicodes": [
          "u1f910"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "raised",
          "eyebrow"
        ],
        "name": "face_with_raised_eyebrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2pddyczf.png",
        "icon": "🤨",
        "shortcode": ":face_with_raised_eyebrow:",
        "unicodes": [
          "u1f928"
        ]
      },
      {
        "search": [
          "neutral",
          "face"
        ],
        "name": "neutral_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/p3r6w05b.png",
        "icon": "😐",
        "shortcode": ":neutral_face:",
        "unicodes": [
          "u1f610"
        ]
      },
      {
        "search": [
          "expressionless",
          "face"
        ],
        "name": "expressionless_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yv0qrlr5.png",
        "icon": "😑",
        "shortcode": ":expressionless_face:",
        "unicodes": [
          "u1f611"
        ]
      },
      {
        "search": [
          "face",
          "without",
          "mouth"
        ],
        "name": "face_without_mouth",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lwzhnni6.png",
        "icon": "😶",
        "shortcode": ":face_without_mouth:",
        "unicodes": [
          "u1f636"
        ]
      },
      {
        "search": [
          "dotted",
          "line",
          "face"
        ],
        "name": "dotted_line_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/paule1d4.png",
        "icon": "🫥",
        "shortcode": ":dotted_line_face:",
        "unicodes": [
          "u1fae5"
        ]
      },
      {
        "search": [
          "face",
          "in",
          "clouds"
        ],
        "name": "face_in_clouds",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cl5tad1d.png",
        "icon": "😶‍🌫️",
        "shortcode": ":face_in_clouds:",
        "unicodes": [
          "u1f636",
          "u200d",
          "u1f32b",
          "ufe0f"
        ]
      },
      {
        "search": [
          "smirking",
          "face"
        ],
        "name": "smirking_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vthq06gz.png",
        "icon": "😏",
        "shortcode": ":smirking_face:",
        "unicodes": [
          "u1f60f"
        ]
      },
      {
        "search": [
          "unamused",
          "face"
        ],
        "name": "unamused_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5km45yxm.png",
        "icon": "😒",
        "shortcode": ":unamused_face:",
        "unicodes": [
          "u1f612"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "rolling",
          "eyes"
        ],
        "name": "face_with_rolling_eyes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6xa8z790.png",
        "icon": "🙄",
        "shortcode": ":face_with_rolling_eyes:",
        "unicodes": [
          "u1f644"
        ]
      },
      {
        "search": [
          "grimacing",
          "face"
        ],
        "name": "grimacing_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e5en3px1.png",
        "icon": "😬",
        "shortcode": ":grimacing_face:",
        "unicodes": [
          "u1f62c"
        ]
      },
      {
        "search": [
          "face",
          "exhaling"
        ],
        "name": "face_exhaling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nvx6khs9.png",
        "icon": "😮‍💨",
        "shortcode": ":face_exhaling:",
        "unicodes": [
          "u1f62e",
          "u200d",
          "u1f4a8"
        ]
      },
      {
        "search": [
          "shaking",
          "face"
        ],
        "name": "shaking_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/prsgh121.png",
        "icon": "🤥",
        "shortcode": ":shaking_face:",
        "unicodes": [
          "u1f925"
        ]
      },
      {
        "search": [
          "relieved",
          "face"
        ],
        "name": "relieved_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jsebn9os.png",
        "icon": "😌",
        "shortcode": ":relieved_face:",
        "unicodes": [
          "u1f60c"
        ]
      },
      {
        "search": [
          "pensive",
          "face"
        ],
        "name": "pensive_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yml2j2ov.png",
        "icon": "😔",
        "shortcode": ":pensive_face:",
        "unicodes": [
          "u1f614"
        ]
      },
      {
        "search": [
          "sleepy",
          "face"
        ],
        "name": "sleepy_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tmbqt6og.png",
        "icon": "😪",
        "shortcode": ":sleepy_face:",
        "unicodes": [
          "u1f62a"
        ]
      },
      {
        "search": [
          "drooling",
          "face"
        ],
        "name": "drooling_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sgf3eqh5.png",
        "icon": "🤤",
        "shortcode": ":drooling_face:",
        "unicodes": [
          "u1f924"
        ]
      },
      {
        "search": [
          "sleeping",
          "face"
        ],
        "name": "sleeping_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e8olp9yx.png",
        "icon": "😴",
        "shortcode": ":sleeping_face:",
        "unicodes": [
          "u1f634"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "medical",
          "mask"
        ],
        "name": "face_with_medical_mask",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/houuvkgl.png",
        "icon": "😷",
        "shortcode": ":face_with_medical_mask:",
        "unicodes": [
          "u1f637"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "thermometer"
        ],
        "name": "face_with_thermometer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vcdyr272.png",
        "icon": "🤒",
        "shortcode": ":face_with_thermometer:",
        "unicodes": [
          "u1f912"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "head_bandage"
        ],
        "name": "face_with_head_bandage",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ndc97qw7.png",
        "icon": "🤕",
        "shortcode": ":face_with_head_bandage:",
        "unicodes": [
          "u1f915"
        ]
      },
      {
        "search": [
          "nauseated",
          "face"
        ],
        "name": "nauseated_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mdy094bn.png",
        "icon": "🤢",
        "shortcode": ":nauseated_face:",
        "unicodes": [
          "u1f922"
        ]
      },
      {
        "search": [
          "face",
          "vomiting"
        ],
        "name": "face_vomiting",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yiijkusv.png",
        "icon": "🤮",
        "shortcode": ":face_vomiting:",
        "unicodes": [
          "u1f92e"
        ]
      },
      {
        "search": [
          "sneezing",
          "face"
        ],
        "name": "sneezing_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8bq2z9d3.png",
        "icon": "🤧",
        "shortcode": ":sneezing_face:",
        "unicodes": [
          "u1f927"
        ]
      },
      {
        "search": [
          "hot",
          "face"
        ],
        "name": "hot_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ewfcn2dz.png",
        "icon": "🥵",
        "shortcode": ":hot_face:",
        "unicodes": [
          "u1f975"
        ]
      },
      {
        "search": [
          "cold",
          "face"
        ],
        "name": "cold_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xwr5qmbq.png",
        "icon": "🥶",
        "shortcode": ":cold_face:",
        "unicodes": [
          "u1f976"
        ]
      },
      {
        "search": [
          "woozy",
          "face"
        ],
        "name": "woozy_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/t0kgcbxb.png",
        "icon": "🥴",
        "shortcode": ":woozy_face:",
        "unicodes": [
          "u1f974"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "crossed_out",
          "eyes"
        ],
        "name": "face_with_crossed_out_eyes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/q27rxkzr.png",
        "icon": "😵",
        "shortcode": ":face_with_crossed_out_eyes:",
        "unicodes": [
          "u1f635"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "spiral",
          "eyes"
        ],
        "name": "face_with_spiral_eyes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/z33bs8cz.png",
        "icon": "😵‍💫",
        "shortcode": ":face_with_spiral_eyes:",
        "unicodes": [
          "u1f635",
          "u200d",
          "u1f4ab"
        ]
      },
      {
        "search": [
          "exploding",
          "head"
        ],
        "name": "exploding_head",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/o0xd2kqk.png",
        "icon": "🤯",
        "shortcode": ":exploding_head:",
        "unicodes": [
          "u1f92f"
        ]
      },
      {
        "search": [
          "cowboy",
          "hat",
          "face"
        ],
        "name": "cowboy_hat_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lu3dm1ye.png",
        "icon": "🤠",
        "shortcode": ":cowboy_hat_face:",
        "unicodes": [
          "u1f920"
        ]
      },
      {
        "search": [
          "partying",
          "face"
        ],
        "name": "partying_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qljlkvgh.png",
        "icon": "🥳",
        "shortcode": ":partying_face:",
        "unicodes": [
          "u1f973"
        ]
      },
      {
        "search": [
          "disguised",
          "face"
        ],
        "name": "disguised_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/esrdm2nr.png",
        "icon": "🥸",
        "shortcode": ":disguised_face:",
        "unicodes": [
          "u1f978"
        ]
      },
      {
        "search": [
          "smiling",
          "face",
          "with",
          "sunglasses"
        ],
        "name": "smiling_face_with_sunglasses",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/54h58pnn.png",
        "icon": "😎",
        "shortcode": ":smiling_face_with_sunglasses:",
        "unicodes": [
          "u1f60e"
        ]
      },
      {
        "search": [
          "nerd",
          "face"
        ],
        "name": "nerd_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1lnwcdgb.png",
        "icon": "🤓",
        "shortcode": ":nerd_face:",
        "unicodes": [
          "u1f913"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "monocle"
        ],
        "name": "face_with_monocle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8xw7qhjw.png",
        "icon": "🧐",
        "shortcode": ":face_with_monocle:",
        "unicodes": [
          "u1f9d0"
        ]
      },
      {
        "search": [
          "confused",
          "face"
        ],
        "name": "confused_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/eipiiul8.png",
        "icon": "😕",
        "shortcode": ":confused_face:",
        "unicodes": [
          "u1f615"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "diagonal",
          "mouth"
        ],
        "name": "face_with_diagonal_mouth",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d1h1n9g9.png",
        "icon": "🫤",
        "shortcode": ":face_with_diagonal_mouth:",
        "unicodes": [
          "u1fae4"
        ]
      },
      {
        "search": [
          "worried",
          "face"
        ],
        "name": "worried_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nyxgvrrh.png",
        "icon": "😟",
        "shortcode": ":worried_face:",
        "unicodes": [
          "u1f61f"
        ]
      },
      {
        "search": [
          "slightly",
          "frowning",
          "face"
        ],
        "name": "slightly_frowning_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/losila40.png",
        "icon": "🙁",
        "shortcode": ":slightly_frowning_face:",
        "unicodes": [
          "u1f641"
        ]
      },
      {
        "search": [
          "frowning",
          "face"
        ],
        "name": "frowning_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lrr15wut.png",
        "icon": "☹",
        "shortcode": ":frowning_face:",
        "unicodes": [
          "u2639"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "open",
          "mouth"
        ],
        "name": "face_with_open_mouth",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/54yqzwnq.png",
        "icon": "😮",
        "shortcode": ":face_with_open_mouth:",
        "unicodes": [
          "u1f62e"
        ]
      },
      {
        "search": [
          "hushed",
          "face"
        ],
        "name": "hushed_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uokp2er4.png",
        "icon": "😯",
        "shortcode": ":hushed_face:",
        "unicodes": [
          "u1f62f"
        ]
      },
      {
        "search": [
          "astonished",
          "face"
        ],
        "name": "astonished_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gc2ewhq2.png",
        "icon": "😲",
        "shortcode": ":astonished_face:",
        "unicodes": [
          "u1f632"
        ]
      },
      {
        "search": [
          "flushed",
          "face"
        ],
        "name": "flushed_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bfvrgjhk.png",
        "icon": "😳",
        "shortcode": ":flushed_face:",
        "unicodes": [
          "u1f633"
        ]
      },
      {
        "search": [
          "pleading",
          "face"
        ],
        "name": "pleading_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2ygdn9cs.png",
        "icon": "🥺",
        "shortcode": ":pleading_face:",
        "unicodes": [
          "u1f97a"
        ]
      },
      {
        "search": [
          "face",
          "holding",
          "back",
          "tears"
        ],
        "name": "face_holding_back_tears",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iwv46usj.png",
        "icon": "🥹",
        "shortcode": ":face_holding_back_tears:",
        "unicodes": [
          "u1f979"
        ]
      },
      {
        "search": [
          "frowning",
          "face",
          "with",
          "open",
          "mouth"
        ],
        "name": "frowning_face_with_open_mouth",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/p9efitgi.png",
        "icon": "😦",
        "shortcode": ":frowning_face_with_open_mouth:",
        "unicodes": [
          "u1f626"
        ]
      },
      {
        "search": [
          "anguished",
          "face"
        ],
        "name": "anguished_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jyqrr2ea.png",
        "icon": "😧",
        "shortcode": ":anguished_face:",
        "unicodes": [
          "u1f627"
        ]
      },
      {
        "search": [
          "fearful",
          "face"
        ],
        "name": "fearful_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bm9yw4bv.png",
        "icon": "😨",
        "shortcode": ":fearful_face:",
        "unicodes": [
          "u1f628"
        ]
      },
      {
        "search": [
          "anxious",
          "face",
          "with",
          "sweat"
        ],
        "name": "anxious_face_with_sweat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gft3ea95.png",
        "icon": "😰",
        "shortcode": ":anxious_face_with_sweat:",
        "unicodes": [
          "u1f630"
        ]
      },
      {
        "search": [
          "sad",
          "but",
          "relieved",
          "face"
        ],
        "name": "sad_but_relieved_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qoghumjt.png",
        "icon": "😥",
        "shortcode": ":sad_but_relieved_face:",
        "unicodes": [
          "u1f625"
        ]
      },
      {
        "search": [
          "crying",
          "face"
        ],
        "name": "crying_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g730970q.png",
        "icon": "😢",
        "shortcode": ":crying_face:",
        "unicodes": [
          "u1f622"
        ]
      },
      {
        "search": [
          "loudly",
          "crying",
          "face"
        ],
        "name": "loudly_crying_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3clmgdvx.png",
        "icon": "😭",
        "shortcode": ":loudly_crying_face:",
        "unicodes": [
          "u1f62d"
        ]
      },
      {
        "search": [
          "face",
          "screaming",
          "in",
          "fear"
        ],
        "name": "face_screaming_in_fear",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gbvlj3uj.png",
        "icon": "😱",
        "shortcode": ":face_screaming_in_fear:",
        "unicodes": [
          "u1f631"
        ]
      },
      {
        "search": [
          "confounded",
          "face"
        ],
        "name": "confounded_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/owmlshe8.png",
        "icon": "😖",
        "shortcode": ":confounded_face:",
        "unicodes": [
          "u1f616"
        ]
      },
      {
        "search": [
          "persevering",
          "face"
        ],
        "name": "persevering_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pal6bpv4.png",
        "icon": "😣",
        "shortcode": ":persevering_face:",
        "unicodes": [
          "u1f623"
        ]
      },
      {
        "search": [
          "disappointed",
          "face"
        ],
        "name": "disappointed_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7mn3w55y.png",
        "icon": "😞",
        "shortcode": ":disappointed_face:",
        "unicodes": [
          "u1f61e"
        ]
      },
      {
        "search": [
          "downcast",
          "face",
          "with",
          "sweat"
        ],
        "name": "downcast_face_with_sweat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qg7dwrqv.png",
        "icon": "😓",
        "shortcode": ":downcast_face_with_sweat:",
        "unicodes": [
          "u1f613"
        ]
      },
      {
        "search": [
          "weary",
          "face"
        ],
        "name": "weary_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/q4adhii4.png",
        "icon": "😩",
        "shortcode": ":weary_face:",
        "unicodes": [
          "u1f629"
        ]
      },
      {
        "search": [
          "tired",
          "face"
        ],
        "name": "tired_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bef8lda0.png",
        "icon": "😫",
        "shortcode": ":tired_face:",
        "unicodes": [
          "u1f62b"
        ]
      },
      {
        "search": [
          "yawning",
          "face"
        ],
        "name": "yawning_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e9wlp867.png",
        "icon": "🥱",
        "shortcode": ":yawning_face:",
        "unicodes": [
          "u1f971"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "steam",
          "from",
          "nose"
        ],
        "name": "face_with_steam_from_nose",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ebqqxu0k.png",
        "icon": "😤",
        "shortcode": ":face_with_steam_from_nose:",
        "unicodes": [
          "u1f624"
        ]
      },
      {
        "search": [
          "enraged",
          "face"
        ],
        "name": "enraged_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0fqw7y0m.png",
        "icon": "😡",
        "shortcode": ":enraged_face:",
        "unicodes": [
          "u1f621"
        ]
      },
      {
        "search": [
          "angry",
          "face"
        ],
        "name": "angry_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hif7brnm.png",
        "icon": "😠",
        "shortcode": ":angry_face:",
        "unicodes": [
          "u1f620"
        ]
      },
      {
        "search": [
          "face",
          "with",
          "symbols",
          "on",
          "mouth"
        ],
        "name": "face_with_symbols_on_mouth",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i4gvxk3r.png",
        "icon": "🤬",
        "shortcode": ":face_with_symbols_on_mouth:",
        "unicodes": [
          "u1f92c"
        ]
      },
      {
        "search": [
          "smiling",
          "face",
          "with",
          "horns"
        ],
        "name": "smiling_face_with_horns",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3pjlzhnx.png",
        "icon": "😈",
        "shortcode": ":smiling_face_with_horns:",
        "unicodes": [
          "u1f608"
        ]
      },
      {
        "search": [
          "angry",
          "face",
          "with",
          "horns"
        ],
        "name": "angry_face_with_horns",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mgalpll1.png",
        "icon": "👿",
        "shortcode": ":angry_face_with_horns:",
        "unicodes": [
          "u1f47f"
        ]
      },
      {
        "search": [
          "skull"
        ],
        "name": "skull",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6g4ynjbc.png",
        "icon": "💀",
        "shortcode": ":skull:",
        "unicodes": [
          "u1f480"
        ]
      },
      {
        "search": [
          "skull",
          "and",
          "crossbones"
        ],
        "name": "skull_and_crossbones",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kx5vr0pw.png",
        "icon": "☠",
        "shortcode": ":skull_and_crossbones:",
        "unicodes": [
          "u2620"
        ]
      },
      {
        "search": [
          "pile",
          "of",
          "poo"
        ],
        "name": "pile_of_poo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vnw52z23.png",
        "icon": "💩",
        "shortcode": ":pile_of_poo:",
        "unicodes": [
          "u1f4a9"
        ]
      },
      {
        "search": [
          "clown",
          "face"
        ],
        "name": "clown_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d3605e97.png",
        "icon": "🤡",
        "shortcode": ":clown_face:",
        "unicodes": [
          "u1f921"
        ]
      },
      {
        "search": [
          "ogre"
        ],
        "name": "ogre",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i07td488.png",
        "icon": "👹",
        "shortcode": ":ogre:",
        "unicodes": [
          "u1f479"
        ]
      },
      {
        "search": [
          "goblin"
        ],
        "name": "goblin",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rp31oql9.png",
        "icon": "👺",
        "shortcode": ":goblin:",
        "unicodes": [
          "u1f47a"
        ]
      },
      {
        "search": [
          "ghost"
        ],
        "name": "ghost",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/aw2awcgd.png",
        "icon": "👻",
        "shortcode": ":ghost:",
        "unicodes": [
          "u1f47b"
        ]
      },
      {
        "search": [
          "alien"
        ],
        "name": "alien",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6d8f084p.png",
        "icon": "👽",
        "shortcode": ":alien:",
        "unicodes": [
          "u1f47d"
        ]
      },
      {
        "search": [
          "alien",
          "monster"
        ],
        "name": "alien_monster",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qkg61hls.png",
        "icon": "👾",
        "shortcode": ":alien_monster:",
        "unicodes": [
          "u1f47e"
        ]
      },
      {
        "search": [
          "robot"
        ],
        "name": "robot",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jm36j3df.png",
        "icon": "🤖",
        "shortcode": ":robot:",
        "unicodes": [
          "u1f916"
        ]
      },
      {
        "search": [
          "grinning",
          "cat"
        ],
        "name": "grinning_cat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/guguqpo2.png",
        "icon": "😺",
        "shortcode": ":grinning_cat:",
        "unicodes": [
          "u1f63a"
        ]
      },
      {
        "search": [
          "grinning",
          "cat",
          "with",
          "smiling",
          "eyes"
        ],
        "name": "grinning_cat_with_smiling_eyes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xuflfej1.png",
        "icon": "😸",
        "shortcode": ":grinning_cat_with_smiling_eyes:",
        "unicodes": [
          "u1f638"
        ]
      },
      {
        "search": [
          "cat",
          "with",
          "tears",
          "of",
          "joy"
        ],
        "name": "cat_with_tears_of_joy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8rgcuptk.png",
        "icon": "😹",
        "shortcode": ":cat_with_tears_of_joy:",
        "unicodes": [
          "u1f639"
        ]
      },
      {
        "search": [
          "smiling",
          "cat",
          "with",
          "heart_eyes"
        ],
        "name": "smiling_cat_with_heart_eyes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s5ap6539.png",
        "icon": "😻",
        "shortcode": ":smiling_cat_with_heart_eyes:",
        "unicodes": [
          "u1f63b"
        ]
      },
      {
        "search": [
          "cat",
          "with",
          "wry",
          "smile"
        ],
        "name": "cat_with_wry_smile",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6wwis95v.png",
        "icon": "😼",
        "shortcode": ":cat_with_wry_smile:",
        "unicodes": [
          "u1f63c"
        ]
      },
      {
        "search": [
          "kissing",
          "cat"
        ],
        "name": "kissing_cat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/298wcd0x.png",
        "icon": "😽",
        "shortcode": ":kissing_cat:",
        "unicodes": [
          "u1f63d"
        ]
      },
      {
        "search": [
          "weary",
          "cat"
        ],
        "name": "weary_cat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bgrx953q.png",
        "icon": "🙀",
        "shortcode": ":weary_cat:",
        "unicodes": [
          "u1f640"
        ]
      },
      {
        "search": [
          "crying",
          "cat"
        ],
        "name": "crying_cat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bxn7n2ax.png",
        "icon": "😿",
        "shortcode": ":crying_cat:",
        "unicodes": [
          "u1f63f"
        ]
      },
      {
        "search": [
          "pouting",
          "cat"
        ],
        "name": "pouting_cat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/n4upbx5a.png",
        "icon": "😾",
        "shortcode": ":pouting_cat:",
        "unicodes": [
          "u1f63e"
        ]
      },
      {
        "search": [
          "see_no_evil",
          "monkey"
        ],
        "name": "see_no_evil_monkey",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/321emkcv.png",
        "icon": "🙈",
        "shortcode": ":see_no_evil_monkey:",
        "unicodes": [
          "u1f648"
        ]
      },
      {
        "search": [
          "hear_no_evil",
          "monkey"
        ],
        "name": "hear_no_evil_monkey",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7phrq5jj.png",
        "icon": "🙉",
        "shortcode": ":hear_no_evil_monkey:",
        "unicodes": [
          "u1f649"
        ]
      },
      {
        "search": [
          "speak_no_evil",
          "monkey"
        ],
        "name": "speak_no_evil_monkey",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/whl161nc.png",
        "icon": "🙊",
        "shortcode": ":speak_no_evil_monkey:",
        "unicodes": [
          "u1f64a"
        ]
      },
      {
        "search": [
          "love",
          "letter"
        ],
        "name": "love_letter",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3w0d0j93.png",
        "icon": "💌",
        "shortcode": ":love_letter:",
        "unicodes": [
          "u1f48c"
        ]
      },
      {
        "search": [
          "heart",
          "with",
          "arrow"
        ],
        "name": "heart_with_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/reisjgea.png",
        "icon": "💘",
        "shortcode": ":heart_with_arrow:",
        "unicodes": [
          "u1f498"
        ]
      },
      {
        "search": [
          "heart",
          "with",
          "ribbon"
        ],
        "name": "heart_with_ribbon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jqcsimqq.png",
        "icon": "💝",
        "shortcode": ":heart_with_ribbon:",
        "unicodes": [
          "u1f49d"
        ]
      },
      {
        "search": [
          "sparkling",
          "heart"
        ],
        "name": "sparkling_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/teav2ocn.png",
        "icon": "💖",
        "shortcode": ":sparkling_heart:",
        "unicodes": [
          "u1f496"
        ]
      },
      {
        "search": [
          "growing",
          "heart"
        ],
        "name": "growing_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6wskjxaq.png",
        "icon": "💗",
        "shortcode": ":growing_heart:",
        "unicodes": [
          "u1f497"
        ]
      },
      {
        "search": [
          "beating",
          "heart"
        ],
        "name": "beating_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fnk0oepa.png",
        "icon": "💓",
        "shortcode": ":beating_heart:",
        "unicodes": [
          "u1f493"
        ]
      },
      {
        "search": [
          "revolving",
          "hearts"
        ],
        "name": "revolving_hearts",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9dvc3i44.png",
        "icon": "💞",
        "shortcode": ":revolving_hearts:",
        "unicodes": [
          "u1f49e"
        ]
      },
      {
        "search": [
          "two",
          "hearts"
        ],
        "name": "two_hearts",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ic3r133t.png",
        "icon": "💕",
        "shortcode": ":two_hearts:",
        "unicodes": [
          "u1f495"
        ]
      },
      {
        "search": [
          "heart",
          "decoration"
        ],
        "name": "heart_decoration",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g32nzl9y.png",
        "icon": "💟",
        "shortcode": ":heart_decoration:",
        "unicodes": [
          "u1f49f"
        ]
      },
      {
        "search": [
          "heart",
          "exclamation"
        ],
        "name": "heart_exclamation",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sy82wb1c.png",
        "icon": "❣",
        "shortcode": ":heart_exclamation:",
        "unicodes": [
          "u2763"
        ]
      },
      {
        "search": [
          "broken",
          "heart"
        ],
        "name": "broken_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0hzz99gx.png",
        "icon": "💔",
        "shortcode": ":broken_heart:",
        "unicodes": [
          "u1f494"
        ]
      },
      {
        "search": [
          "heart",
          "on",
          "fire"
        ],
        "name": "heart_on_fire",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bwmkggif.png",
        "icon": "❤️‍🔥",
        "shortcode": ":heart_on_fire:",
        "unicodes": [
          "u2764",
          "ufe0f",
          "u200d",
          "u1f525"
        ]
      },
      {
        "search": [
          "mending",
          "heart"
        ],
        "name": "mending_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kg7owdif.png",
        "icon": "❤️‍🩹",
        "shortcode": ":mending_heart:",
        "unicodes": [
          "u2764",
          "ufe0f",
          "u200d",
          "u1fa79"
        ]
      },
      {
        "search": [
          "pink",
          "heart"
        ],
        "name": "pink_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xqy6cx7j.png",
        "icon": "❤",
        "shortcode": ":pink_heart:",
        "unicodes": [
          "u2764"
        ]
      },
      {
        "search": [
          "orange",
          "heart"
        ],
        "name": "orange_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dzt3948u.png",
        "icon": "🧡",
        "shortcode": ":orange_heart:",
        "unicodes": [
          "u1f9e1"
        ]
      },
      {
        "search": [
          "yellow",
          "heart"
        ],
        "name": "yellow_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ze0gydas.png",
        "icon": "💛",
        "shortcode": ":yellow_heart:",
        "unicodes": [
          "u1f49b"
        ]
      },
      {
        "search": [
          "green",
          "heart"
        ],
        "name": "green_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xr8r8j0p.png",
        "icon": "💚",
        "shortcode": ":green_heart:",
        "unicodes": [
          "u1f49a"
        ]
      },
      {
        "search": [
          "blue",
          "heart"
        ],
        "name": "blue_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b9c1cz3u.png",
        "icon": "💙",
        "shortcode": ":blue_heart:",
        "unicodes": [
          "u1f499"
        ]
      },
      {
        "search": [
          "purple",
          "heart"
        ],
        "name": "purple_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3sn7a6z0.png",
        "icon": "💜",
        "shortcode": ":purple_heart:",
        "unicodes": [
          "u1f49c"
        ]
      },
      {
        "search": [
          "brown",
          "heart"
        ],
        "name": "brown_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/92mlukyy.png",
        "icon": "🤎",
        "shortcode": ":brown_heart:",
        "unicodes": [
          "u1f90e"
        ]
      },
      {
        "search": [
          "grey",
          "heart"
        ],
        "name": "grey_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d3rfzscs.png",
        "icon": "🖤",
        "shortcode": ":grey_heart:",
        "unicodes": [
          "u1f5a4"
        ]
      },
      {
        "search": [
          "white",
          "heart"
        ],
        "name": "white_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f5mlg9s1.png",
        "icon": "🤍",
        "shortcode": ":white_heart:",
        "unicodes": [
          "u1f90d"
        ]
      },
      {
        "search": [
          "kiss",
          "mark"
        ],
        "name": "kiss_mark",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a32uet80.png",
        "icon": "💋",
        "shortcode": ":kiss_mark:",
        "unicodes": [
          "u1f48b"
        ]
      },
      {
        "search": [
          "hundred",
          "points"
        ],
        "name": "hundred_points",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v4s59m1o.png",
        "icon": "💯",
        "shortcode": ":hundred_points:",
        "unicodes": [
          "u1f4af"
        ]
      },
      {
        "search": [
          "anger",
          "symbol"
        ],
        "name": "anger_symbol",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yvyr5o33.png",
        "icon": "💢",
        "shortcode": ":anger_symbol:",
        "unicodes": [
          "u1f4a2"
        ]
      },
      {
        "search": [
          "collision"
        ],
        "name": "collision",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mtwdp6ej.png",
        "icon": "💥",
        "shortcode": ":collision:",
        "unicodes": [
          "u1f4a5"
        ]
      },
      {
        "search": [
          "dizzy"
        ],
        "name": "dizzy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3s7djmfb.png",
        "icon": "💫",
        "shortcode": ":dizzy:",
        "unicodes": [
          "u1f4ab"
        ]
      },
      {
        "search": [
          "sweat",
          "droplets"
        ],
        "name": "sweat_droplets",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hrnfcmxw.png",
        "icon": "💦",
        "shortcode": ":sweat_droplets:",
        "unicodes": [
          "u1f4a6"
        ]
      },
      {
        "search": [
          "dashing",
          "away"
        ],
        "name": "dashing_away",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/y1e9ppwa.png",
        "icon": "💨",
        "shortcode": ":dashing_away:",
        "unicodes": [
          "u1f4a8"
        ]
      },
      {
        "search": [
          "hole"
        ],
        "name": "hole",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mb7ti0ax.png",
        "icon": "🕳",
        "shortcode": ":hole:",
        "unicodes": [
          "u1f573"
        ]
      },
      {
        "search": [
          "speech",
          "balloon"
        ],
        "name": "speech_balloon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5ibicjqn.png",
        "icon": "💬",
        "shortcode": ":speech_balloon:",
        "unicodes": [
          "u1f4ac"
        ]
      },
      {
        "search": [
          "eye",
          "in",
          "speech",
          "bubble"
        ],
        "name": "eye_in_speech_bubble",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s8imree8.png",
        "icon": "👁️‍🗨️",
        "shortcode": ":eye_in_speech_bubble:",
        "unicodes": [
          "u1f441",
          "ufe0f",
          "u200d",
          "u1f5e8",
          "ufe0f"
        ]
      },
      {
        "search": [
          "left",
          "speech",
          "bubble"
        ],
        "name": "left_speech_bubble",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j7qpmazf.png",
        "icon": "🗨",
        "shortcode": ":left_speech_bubble:",
        "unicodes": [
          "u1f5e8"
        ]
      },
      {
        "search": [
          "right",
          "anger",
          "bubble"
        ],
        "name": "right_anger_bubble",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wt4jf1cc.png",
        "icon": "🗯",
        "shortcode": ":right_anger_bubble:",
        "unicodes": [
          "u1f5ef"
        ]
      },
      {
        "search": [
          "thought",
          "balloon"
        ],
        "name": "thought_balloon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sfx4fdmn.png",
        "icon": "💭",
        "shortcode": ":thought_balloon:",
        "unicodes": [
          "u1f4ad"
        ]
      },
      {
        "search": [
          "ZZZ"
        ],
        "name": "ZZZ",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/k2fmqkvr.png",
        "icon": "💤",
        "shortcode": ":ZZZ:",
        "unicodes": [
          "u1f4a4"
        ]
      },
      {
        "search": [
          "waving",
          "hand"
        ],
        "name": "waving_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/guey1yd1.png",
        "icon": "👋",
        "shortcode": ":waving_hand:",
        "unicodes": [
          "u1f44b"
        ]
      },
      {
        "search": [
          "raised",
          "back",
          "of",
          "hand"
        ],
        "name": "raised_back_of_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nue5sud1.png",
        "icon": "🤚",
        "shortcode": ":raised_back_of_hand:",
        "unicodes": [
          "u1f91a"
        ]
      },
      {
        "search": [
          "hand",
          "with",
          "fingers",
          "splayed"
        ],
        "name": "hand_with_fingers_splayed",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1qxnyhxq.png",
        "icon": "🖐",
        "shortcode": ":hand_with_fingers_splayed:",
        "unicodes": [
          "u1f590"
        ]
      },
      {
        "search": [
          "raised",
          "hand"
        ],
        "name": "raised_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/slxbragr.png",
        "icon": "✋",
        "shortcode": ":raised_hand:",
        "unicodes": [
          "u270b"
        ]
      },
      {
        "search": [
          "vulcan",
          "salute"
        ],
        "name": "vulcan_salute",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fkdqghrk.png",
        "icon": "🖖",
        "shortcode": ":vulcan_salute:",
        "unicodes": [
          "u1f596"
        ]
      },
      {
        "search": [
          "rightwards",
          "hand"
        ],
        "name": "rightwards_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xbfmi549.png",
        "icon": "🫱",
        "shortcode": ":rightwards_hand:",
        "unicodes": [
          "u1faf1"
        ]
      },
      {
        "search": [
          "leftwards",
          "hand"
        ],
        "name": "leftwards_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ic15l5u1.png",
        "icon": "🫲",
        "shortcode": ":leftwards_hand:",
        "unicodes": [
          "u1faf2"
        ]
      },
      {
        "search": [
          "palm",
          "down",
          "hand"
        ],
        "name": "palm_down_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kqqvc67b.png",
        "icon": "🫳",
        "shortcode": ":palm_down_hand:",
        "unicodes": [
          "u1faf3"
        ]
      },
      {
        "search": [
          "rightwards",
          "pushing",
          "hand"
        ],
        "name": "rightwards_pushing_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gjnpyx09.png",
        "icon": "🫴",
        "shortcode": ":rightwards_pushing_hand:",
        "unicodes": [
          "u1faf4"
        ]
      },
      {
        "search": [
          "ok",
          "hand"
        ],
        "name": "OK_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zolnrdc2.png",
        "icon": "👌",
        "shortcode": ":OK_hand:",
        "unicodes": [
          "u1f44c"
        ]
      },
      {
        "search": [
          "pinched",
          "fingers"
        ],
        "name": "pinched_fingers",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ooig70jr.png",
        "icon": "🤌",
        "shortcode": ":pinched_fingers:",
        "unicodes": [
          "u1f90c"
        ]
      },
      {
        "search": [
          "pinching",
          "hand"
        ],
        "name": "pinching_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ftphsaan.png",
        "icon": "🤏",
        "shortcode": ":pinching_hand:",
        "unicodes": [
          "u1f90f"
        ]
      },
      {
        "search": [
          "victory",
          "hand"
        ],
        "name": "victory_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kk4yjgrn.png",
        "icon": "✌",
        "shortcode": ":victory_hand:",
        "unicodes": [
          "u270c"
        ]
      },
      {
        "search": [
          "crossed",
          "fingers"
        ],
        "name": "crossed_fingers",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kfgojdnw.png",
        "icon": "🤞",
        "shortcode": ":crossed_fingers:",
        "unicodes": [
          "u1f91e"
        ]
      },
      {
        "search": [
          "hand",
          "with",
          "index",
          "finger",
          "and",
          "thumb",
          "crossed"
        ],
        "name": "hand_with_index_finger_and_thumb_crossed",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v7kgix3q.png",
        "icon": "🫰",
        "shortcode": ":hand_with_index_finger_and_thumb_crossed:",
        "unicodes": [
          "u1faf0"
        ]
      },
      {
        "search": [
          "love_you",
          "gesture"
        ],
        "name": "love_you_gesture",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cqxvnx5t.png",
        "icon": "🤟",
        "shortcode": ":love_you_gesture:",
        "unicodes": [
          "u1f91f"
        ]
      },
      {
        "search": [
          "sign",
          "of",
          "the",
          "horns"
        ],
        "name": "sign_of_the_horns",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/q19xhozk.png",
        "icon": "🤘",
        "shortcode": ":sign_of_the_horns:",
        "unicodes": [
          "u1f918"
        ]
      },
      {
        "search": [
          "call",
          "me",
          "hand"
        ],
        "name": "call_me_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bl6zd2rn.png",
        "icon": "🤙",
        "shortcode": ":call_me_hand:",
        "unicodes": [
          "u1f919"
        ]
      },
      {
        "search": [
          "backhand",
          "index",
          "pointing",
          "left"
        ],
        "name": "backhand_index_pointing_left",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/psiwiufs.png",
        "icon": "👈",
        "shortcode": ":backhand_index_pointing_left:",
        "unicodes": [
          "u1f448"
        ]
      },
      {
        "search": [
          "backhand",
          "index",
          "pointing",
          "right"
        ],
        "name": "backhand_index_pointing_right",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xjc3t1go.png",
        "icon": "👉",
        "shortcode": ":backhand_index_pointing_right:",
        "unicodes": [
          "u1f449"
        ]
      },
      {
        "search": [
          "backhand",
          "index",
          "pointing",
          "up"
        ],
        "name": "backhand_index_pointing_up",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gbgdxo0g.png",
        "icon": "👆",
        "shortcode": ":backhand_index_pointing_up:",
        "unicodes": [
          "u1f446"
        ]
      },
      {
        "search": [
          "middle",
          "finger"
        ],
        "name": "middle_finger",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w7rkk8mj.png",
        "icon": "🖕",
        "shortcode": ":middle_finger:",
        "unicodes": [
          "u1f595"
        ]
      },
      {
        "search": [
          "backhand",
          "index",
          "pointing",
          "down"
        ],
        "name": "backhand_index_pointing_down",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ro3ahcxj.png",
        "icon": "👇",
        "shortcode": ":backhand_index_pointing_down:",
        "unicodes": [
          "u1f447"
        ]
      },
      {
        "search": [
          "index",
          "pointing",
          "up"
        ],
        "name": "index_pointing_up",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7vz0b6pt.png",
        "icon": "☝",
        "shortcode": ":index_pointing_up:",
        "unicodes": [
          "u261d"
        ]
      },
      {
        "search": [
          "index",
          "pointing",
          "at",
          "the",
          "viewer"
        ],
        "name": "index_pointing_at_the_viewer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cj3ubp9t.png",
        "icon": "🫵",
        "shortcode": ":index_pointing_at_the_viewer:",
        "unicodes": [
          "u1faf5"
        ]
      },
      {
        "search": [
          "thumbs",
          "up"
        ],
        "name": "thumbs_up",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/agjmu9z9.png",
        "icon": "👍",
        "shortcode": ":thumbs_up:",
        "unicodes": [
          "u1f44d"
        ]
      },
      {
        "search": [
          "thumbs",
          "down"
        ],
        "name": "thumbs_down",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ukozvgrj.png",
        "icon": "👎",
        "shortcode": ":thumbs_down:",
        "unicodes": [
          "u1f44e"
        ]
      },
      {
        "search": [
          "raised",
          "fist"
        ],
        "name": "raised_fist",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0he952o8.png",
        "icon": "✊",
        "shortcode": ":raised_fist:",
        "unicodes": [
          "u270a"
        ]
      },
      {
        "search": [
          "oncoming",
          "fist"
        ],
        "name": "oncoming_fist",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6iwgr4n0.png",
        "icon": "👊",
        "shortcode": ":oncoming_fist:",
        "unicodes": [
          "u1f44a"
        ]
      },
      {
        "search": [
          "left_facing",
          "fist"
        ],
        "name": "left_facing_fist",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iwqjv0t6.png",
        "icon": "🤛",
        "shortcode": ":left_facing_fist:",
        "unicodes": [
          "u1f91b"
        ]
      },
      {
        "search": [
          "right_facing",
          "fist"
        ],
        "name": "right_facing_fist",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/oxd4pqge.png",
        "icon": "🤜",
        "shortcode": ":right_facing_fist:",
        "unicodes": [
          "u1f91c"
        ]
      },
      {
        "search": [
          "clapping",
          "hands"
        ],
        "name": "clapping_hands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/69oxx8hz.png",
        "icon": "👏",
        "shortcode": ":clapping_hands:",
        "unicodes": [
          "u1f44f"
        ]
      },
      {
        "search": [
          "raising",
          "hands"
        ],
        "name": "raising_hands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a76saoa9.png",
        "icon": "🙌",
        "shortcode": ":raising_hands:",
        "unicodes": [
          "u1f64c"
        ]
      },
      {
        "search": [
          "heart",
          "hands"
        ],
        "name": "heart_hands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4iyr2bb2.png",
        "icon": "🫶",
        "shortcode": ":heart_hands:",
        "unicodes": [
          "u1faf6"
        ]
      },
      {
        "search": [
          "open",
          "hands"
        ],
        "name": "open_hands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rh8d4fq0.png",
        "icon": "👐",
        "shortcode": ":open_hands:",
        "unicodes": [
          "u1f450"
        ]
      },
      {
        "search": [
          "palms",
          "up",
          "together"
        ],
        "name": "palms_up_together",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qdn16nig.png",
        "icon": "🤲",
        "shortcode": ":palms_up_together:",
        "unicodes": [
          "u1f932"
        ]
      },
      {
        "search": [
          "handshake"
        ],
        "name": "handshake",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cwrvefz7.png",
        "icon": "🤝",
        "shortcode": ":handshake:",
        "unicodes": [
          "u1f91d"
        ]
      },
      {
        "search": [
          "folded",
          "hands"
        ],
        "name": "folded_hands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3xqgz3s3.png",
        "icon": "🙏",
        "shortcode": ":folded_hands:",
        "unicodes": [
          "u1f64f"
        ]
      },
      {
        "search": [
          "writing",
          "hand"
        ],
        "name": "writing_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1na8be1e.png",
        "icon": "✍",
        "shortcode": ":writing_hand:",
        "unicodes": [
          "u270d"
        ]
      },
      {
        "search": [
          "nail",
          "polish"
        ],
        "name": "nail_polish",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9kohaln0.png",
        "icon": "💅",
        "shortcode": ":nail_polish:",
        "unicodes": [
          "u1f485"
        ]
      },
      {
        "search": [
          "selfie"
        ],
        "name": "selfie",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vioyjtjl.png",
        "icon": "🤳",
        "shortcode": ":selfie:",
        "unicodes": [
          "u1f933"
        ]
      },
      {
        "search": [
          "flexed",
          "biceps"
        ],
        "name": "flexed_biceps",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ip8tiyxs.png",
        "icon": "💪",
        "shortcode": ":flexed_biceps:",
        "unicodes": [
          "u1f4aa"
        ]
      },
      {
        "search": [
          "mechanical",
          "arm"
        ],
        "name": "mechanical_arm",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8ngljhig.png",
        "icon": "🦾",
        "shortcode": ":mechanical_arm:",
        "unicodes": [
          "u1f9be"
        ]
      },
      {
        "search": [
          "mechanical",
          "leg"
        ],
        "name": "mechanical_leg",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/br1fcowr.png",
        "icon": "🦿",
        "shortcode": ":mechanical_leg:",
        "unicodes": [
          "u1f9bf"
        ]
      },
      {
        "search": [
          "leg"
        ],
        "name": "leg",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/up1bejv9.png",
        "icon": "🦵",
        "shortcode": ":leg:",
        "unicodes": [
          "u1f9b5"
        ]
      },
      {
        "search": [
          "foot"
        ],
        "name": "foot",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vihesfbu.png",
        "icon": "🦶",
        "shortcode": ":foot:",
        "unicodes": [
          "u1f9b6"
        ]
      },
      {
        "search": [
          "ear"
        ],
        "name": "ear",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ehy3f1e6.png",
        "icon": "👂",
        "shortcode": ":ear:",
        "unicodes": [
          "u1f442"
        ]
      },
      {
        "search": [
          "ear",
          "with",
          "hearing",
          "aid"
        ],
        "name": "ear_with_hearing_aid",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e13xwth4.png",
        "icon": "🦻",
        "shortcode": ":ear_with_hearing_aid:",
        "unicodes": [
          "u1f9bb"
        ]
      },
      {
        "search": [
          "nose"
        ],
        "name": "nose",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5zzb53i7.png",
        "icon": "👃",
        "shortcode": ":nose:",
        "unicodes": [
          "u1f443"
        ]
      },
      {
        "search": [
          "brain"
        ],
        "name": "brain",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/aw1v9yig.png",
        "icon": "🧠",
        "shortcode": ":brain:",
        "unicodes": [
          "u1f9e0"
        ]
      },
      {
        "search": [
          "anatomical",
          "heart"
        ],
        "name": "anatomical_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2w95vqy5.png",
        "icon": "🫀",
        "shortcode": ":anatomical_heart:",
        "unicodes": [
          "u1fac0"
        ]
      },
      {
        "search": [
          "lungs"
        ],
        "name": "lungs",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kklhidok.png",
        "icon": "🫁",
        "shortcode": ":lungs:",
        "unicodes": [
          "u1fac1"
        ]
      },
      {
        "search": [
          "tooth"
        ],
        "name": "tooth",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/c1kdbjms.png",
        "icon": "🦷",
        "shortcode": ":tooth:",
        "unicodes": [
          "u1f9b7"
        ]
      },
      {
        "search": [
          "bone"
        ],
        "name": "bone",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ffjyswr1.png",
        "icon": "🦴",
        "shortcode": ":bone:",
        "unicodes": [
          "u1f9b4"
        ]
      },
      {
        "search": [
          "eyes"
        ],
        "name": "eyes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/t6rlj1cp.png",
        "icon": "👀",
        "shortcode": ":eyes:",
        "unicodes": [
          "u1f440"
        ]
      },
      {
        "search": [
          "eye"
        ],
        "name": "eye",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h5h5jyu0.png",
        "icon": "👁",
        "shortcode": ":eye:",
        "unicodes": [
          "u1f441"
        ]
      },
      {
        "search": [
          "tongue"
        ],
        "name": "tongue",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xq8z67zk.png",
        "icon": "👅",
        "shortcode": ":tongue:",
        "unicodes": [
          "u1f445"
        ]
      },
      {
        "search": [
          "mouth"
        ],
        "name": "mouth",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3rc74zgk.png",
        "icon": "👄",
        "shortcode": ":mouth:",
        "unicodes": [
          "u1f444"
        ]
      },
      {
        "search": [
          "biting",
          "lip"
        ],
        "name": "biting_lip",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bzsow7zb.png",
        "icon": "🫦",
        "shortcode": ":biting_lip:",
        "unicodes": [
          "u1fae6"
        ]
      },
      {
        "search": [
          "baby"
        ],
        "name": "baby",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qm9c3rgb.png",
        "icon": "👶",
        "shortcode": ":baby:",
        "unicodes": [
          "u1f476"
        ]
      },
      {
        "search": [
          "child"
        ],
        "name": "child",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ddfp2kz6.png",
        "icon": "🧒",
        "shortcode": ":child:",
        "unicodes": [
          "u1f9d2"
        ]
      },
      {
        "search": [
          "boy"
        ],
        "name": "boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4umrtcxj.png",
        "icon": "👦",
        "shortcode": ":boy:",
        "unicodes": [
          "u1f466"
        ]
      },
      {
        "search": [
          "girl"
        ],
        "name": "girl",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rtbgsssb.png",
        "icon": "👧",
        "shortcode": ":girl:",
        "unicodes": [
          "u1f467"
        ]
      },
      {
        "search": [
          "person"
        ],
        "name": "person",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lm9239w0.png",
        "icon": "🧑",
        "shortcode": ":person:",
        "unicodes": [
          "u1f9d1"
        ]
      },
      {
        "search": [
          "person",
          "blond",
          "hair"
        ],
        "name": "person_blond_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d2tx10kx.png",
        "icon": "👱",
        "shortcode": ":person_blond_hair:",
        "unicodes": [
          "u1f471"
        ]
      },
      {
        "search": [
          "man"
        ],
        "name": "man",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/78d9zct2.png",
        "icon": "👨",
        "shortcode": ":man:",
        "unicodes": [
          "u1f468"
        ]
      },
      {
        "search": [
          "person",
          "beard"
        ],
        "name": "person_beard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e46ou5fa.png",
        "icon": "🧔",
        "shortcode": ":person_beard:",
        "unicodes": [
          "u1f9d4"
        ]
      },
      {
        "search": [
          "man",
          "beard"
        ],
        "name": "man_beard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9u6zjtb5.png",
        "icon": "🧔‍♂️",
        "shortcode": ":man_beard:",
        "unicodes": [
          "u1f9d4",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "beard"
        ],
        "name": "woman_beard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uc0l81u8.png",
        "icon": "🧔‍♀️",
        "shortcode": ":woman_beard:",
        "unicodes": [
          "u1f9d4",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "man",
          "red",
          "hair"
        ],
        "name": "man_red_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f93r8n2j.png",
        "icon": "👨‍🦰",
        "shortcode": ":man_red_hair:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f9b0"
        ]
      },
      {
        "search": [
          "man",
          "curly",
          "hair"
        ],
        "name": "man_curly_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vc9wple4.png",
        "icon": "👨‍🦱",
        "shortcode": ":man_curly_hair:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f9b1"
        ]
      },
      {
        "search": [
          "man",
          "white",
          "hair"
        ],
        "name": "man_white_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/32srhldy.png",
        "icon": "👨‍🦳",
        "shortcode": ":man_white_hair:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f9b3"
        ]
      },
      {
        "search": [
          "man",
          "bald"
        ],
        "name": "man_bald",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tw11hivr.png",
        "icon": "👨‍🦲",
        "shortcode": ":man_bald:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f9b2"
        ]
      },
      {
        "search": [
          "woman"
        ],
        "name": "woman",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qzr0qfo5.png",
        "icon": "👩",
        "shortcode": ":woman:",
        "unicodes": [
          "u1f469"
        ]
      },
      {
        "search": [
          "woman",
          "red",
          "hair"
        ],
        "name": "woman_red_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/77sg7mek.png",
        "icon": "👩‍🦰",
        "shortcode": ":woman_red_hair:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f9b0"
        ]
      },
      {
        "search": [
          "person",
          "red",
          "hair"
        ],
        "name": "person_red_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6dyiv3rc.png",
        "icon": "🧑‍🦰",
        "shortcode": ":person_red_hair:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f9b0"
        ]
      },
      {
        "search": [
          "woman",
          "curly",
          "hair"
        ],
        "name": "woman_curly_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ag39lwmu.png",
        "icon": "👩‍🦱",
        "shortcode": ":woman_curly_hair:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f9b1"
        ]
      },
      {
        "search": [
          "person",
          "curly",
          "hair"
        ],
        "name": "person_curly_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/djkswu7l.png",
        "icon": "🧑‍🦱",
        "shortcode": ":person_curly_hair:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f9b1"
        ]
      },
      {
        "search": [
          "woman",
          "white",
          "hair"
        ],
        "name": "woman_white_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uep0qhvu.png",
        "icon": "👩‍🦳",
        "shortcode": ":woman_white_hair:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f9b3"
        ]
      },
      {
        "search": [
          "person",
          "white",
          "hair"
        ],
        "name": "person_white_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4l4ivzc8.png",
        "icon": "🧑‍🦳",
        "shortcode": ":person_white_hair:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f9b3"
        ]
      },
      {
        "search": [
          "woman",
          "bald"
        ],
        "name": "woman_bald",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lxciaeq9.png",
        "icon": "👩‍🦲",
        "shortcode": ":woman_bald:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f9b2"
        ]
      },
      {
        "search": [
          "person",
          "bald"
        ],
        "name": "person_bald",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w6mr2vq4.png",
        "icon": "🧑‍🦲",
        "shortcode": ":person_bald:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f9b2"
        ]
      },
      {
        "search": [
          "woman",
          "blond",
          "hair"
        ],
        "name": "woman_blond_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s6w0mw1f.png",
        "icon": "👱‍♀️",
        "shortcode": ":woman_blond_hair:",
        "unicodes": [
          "u1f471",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "man",
          "blond",
          "hair"
        ],
        "name": "man_blond_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v3ay3v8n.png",
        "icon": "👱‍♂️",
        "shortcode": ":man_blond_hair:",
        "unicodes": [
          "u1f471",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "older",
          "person"
        ],
        "name": "older_person",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jc5wiwpc.png",
        "icon": "🧓",
        "shortcode": ":older_person:",
        "unicodes": [
          "u1f9d3"
        ]
      },
      {
        "search": [
          "old",
          "man"
        ],
        "name": "old_man",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/r6pg4cdh.png",
        "icon": "👴",
        "shortcode": ":old_man:",
        "unicodes": [
          "u1f474"
        ]
      },
      {
        "search": [
          "old",
          "woman"
        ],
        "name": "old_woman",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/058yj30x.png",
        "icon": "👵",
        "shortcode": ":old_woman:",
        "unicodes": [
          "u1f475"
        ]
      },
      {
        "search": [
          "person",
          "frowning"
        ],
        "name": "person_frowning",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/t2scqau6.png",
        "icon": "🙍",
        "shortcode": ":person_frowning:",
        "unicodes": [
          "u1f64d"
        ]
      },
      {
        "search": [
          "man",
          "frowning"
        ],
        "name": "man_frowning",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/099b3eho.png",
        "icon": "🙍‍♂️",
        "shortcode": ":man_frowning:",
        "unicodes": [
          "u1f64d",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "frowning"
        ],
        "name": "woman_frowning",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/n1kvazkp.png",
        "icon": "🙍‍♀️",
        "shortcode": ":woman_frowning:",
        "unicodes": [
          "u1f64d",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "pouting"
        ],
        "name": "person_pouting",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8mw7kgcq.png",
        "icon": "🙎",
        "shortcode": ":person_pouting:",
        "unicodes": [
          "u1f64e"
        ]
      },
      {
        "search": [
          "man",
          "pouting"
        ],
        "name": "man_pouting",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rw2pr6hl.png",
        "icon": "🙎‍♂️",
        "shortcode": ":man_pouting:",
        "unicodes": [
          "u1f64e",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "pouting"
        ],
        "name": "woman_pouting",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kuy05wll.png",
        "icon": "🙎‍♀️",
        "shortcode": ":woman_pouting:",
        "unicodes": [
          "u1f64e",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "gesturing",
          "no"
        ],
        "name": "person_gesturing_NO",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hbc4p9lr.png",
        "icon": "🙅",
        "shortcode": ":person_gesturing_NO:",
        "unicodes": [
          "u1f645"
        ]
      },
      {
        "search": [
          "man",
          "gesturing",
          "no"
        ],
        "name": "man_gesturing_NO",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/avnpm5i8.png",
        "icon": "🙅‍♂️",
        "shortcode": ":man_gesturing_NO:",
        "unicodes": [
          "u1f645",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "gesturing",
          "no"
        ],
        "name": "woman_gesturing_NO",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/aet7yxb1.png",
        "icon": "🙅‍♀️",
        "shortcode": ":woman_gesturing_NO:",
        "unicodes": [
          "u1f645",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "gesturing",
          "ok"
        ],
        "name": "person_gesturing_OK",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bnpb5pu8.png",
        "icon": "🙆",
        "shortcode": ":person_gesturing_OK:",
        "unicodes": [
          "u1f646"
        ]
      },
      {
        "search": [
          "man",
          "gesturing",
          "ok"
        ],
        "name": "man_gesturing_OK",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vets49c2.png",
        "icon": "🙆‍♂️",
        "shortcode": ":man_gesturing_OK:",
        "unicodes": [
          "u1f646",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "gesturing",
          "ok"
        ],
        "name": "woman_gesturing_OK",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/to7iaz0q.png",
        "icon": "🙆‍♀️",
        "shortcode": ":woman_gesturing_OK:",
        "unicodes": [
          "u1f646",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "tipping",
          "hand"
        ],
        "name": "person_tipping_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rudxkq2b.png",
        "icon": "💁",
        "shortcode": ":person_tipping_hand:",
        "unicodes": [
          "u1f481"
        ]
      },
      {
        "search": [
          "man",
          "tipping",
          "hand"
        ],
        "name": "man_tipping_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/l3s83lzf.png",
        "icon": "💁‍♂️",
        "shortcode": ":man_tipping_hand:",
        "unicodes": [
          "u1f481",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "tipping",
          "hand"
        ],
        "name": "woman_tipping_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/arwr8oms.png",
        "icon": "💁‍♀️",
        "shortcode": ":woman_tipping_hand:",
        "unicodes": [
          "u1f481",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "raising",
          "hand"
        ],
        "name": "person_raising_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g7jqx73y.png",
        "icon": "🙋",
        "shortcode": ":person_raising_hand:",
        "unicodes": [
          "u1f64b"
        ]
      },
      {
        "search": [
          "man",
          "raising",
          "hand"
        ],
        "name": "man_raising_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vykqloau.png",
        "icon": "🙋‍♂️",
        "shortcode": ":man_raising_hand:",
        "unicodes": [
          "u1f64b",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "raising",
          "hand"
        ],
        "name": "woman_raising_hand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wxpmhfcp.png",
        "icon": "🙋‍♀️",
        "shortcode": ":woman_raising_hand:",
        "unicodes": [
          "u1f64b",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "deaf",
          "person"
        ],
        "name": "deaf_person",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/twnmyawa.png",
        "icon": "🧏",
        "shortcode": ":deaf_person:",
        "unicodes": [
          "u1f9cf"
        ]
      },
      {
        "search": [
          "deaf",
          "man"
        ],
        "name": "deaf_man",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rct3pwcg.png",
        "icon": "🧏‍♂️",
        "shortcode": ":deaf_man:",
        "unicodes": [
          "u1f9cf",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "deaf",
          "woman"
        ],
        "name": "deaf_woman",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qjm16fnn.png",
        "icon": "🧏‍♀️",
        "shortcode": ":deaf_woman:",
        "unicodes": [
          "u1f9cf",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "bowing"
        ],
        "name": "person_bowing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/y1su3irv.png",
        "icon": "🙇",
        "shortcode": ":person_bowing:",
        "unicodes": [
          "u1f647"
        ]
      },
      {
        "search": [
          "man",
          "bowing"
        ],
        "name": "man_bowing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d2rqcouw.png",
        "icon": "🙇‍♂️",
        "shortcode": ":man_bowing:",
        "unicodes": [
          "u1f647",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "bowing"
        ],
        "name": "woman_bowing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4ycx0tsx.png",
        "icon": "🙇‍♀️",
        "shortcode": ":woman_bowing:",
        "unicodes": [
          "u1f647",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "facepalming"
        ],
        "name": "person_facepalming",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2pn3lz3s.png",
        "icon": "🤦",
        "shortcode": ":person_facepalming:",
        "unicodes": [
          "u1f926"
        ]
      },
      {
        "search": [
          "man",
          "facepalming"
        ],
        "name": "man_facepalming",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0563smke.png",
        "icon": "🤦‍♂️",
        "shortcode": ":man_facepalming:",
        "unicodes": [
          "u1f926",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "facepalming"
        ],
        "name": "woman_facepalming",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1g7exgw2.png",
        "icon": "🤦‍♀️",
        "shortcode": ":woman_facepalming:",
        "unicodes": [
          "u1f926",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "shrugging"
        ],
        "name": "person_shrugging",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f94mk2cl.png",
        "icon": "🤷",
        "shortcode": ":person_shrugging:",
        "unicodes": [
          "u1f937"
        ]
      },
      {
        "search": [
          "man",
          "shrugging"
        ],
        "name": "man_shrugging",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ers1j0oq.png",
        "icon": "🤷‍♂️",
        "shortcode": ":man_shrugging:",
        "unicodes": [
          "u1f937",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "shrugging"
        ],
        "name": "woman_shrugging",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/295eu44g.png",
        "icon": "🤷‍♀️",
        "shortcode": ":woman_shrugging:",
        "unicodes": [
          "u1f937",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "health",
          "worker"
        ],
        "name": "health_worker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lu6jym0l.png",
        "icon": "🧑‍⚕️",
        "shortcode": ":health_worker:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u2695",
          "ufe0f"
        ]
      },
      {
        "search": [
          "man",
          "health",
          "worker"
        ],
        "name": "man_health_worker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s1z9rxts.png",
        "icon": "👨‍⚕️",
        "shortcode": ":man_health_worker:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u2695",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "health",
          "worker"
        ],
        "name": "woman_health_worker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vapxv05c.png",
        "icon": "👩‍⚕️",
        "shortcode": ":woman_health_worker:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u2695",
          "ufe0f"
        ]
      },
      {
        "search": [
          "student"
        ],
        "name": "student",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0drojqce.png",
        "icon": "🧑‍🎓",
        "shortcode": ":student:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f393"
        ]
      },
      {
        "search": [
          "man",
          "student"
        ],
        "name": "man_student",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kyxar34j.png",
        "icon": "👨‍🎓",
        "shortcode": ":man_student:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f393"
        ]
      },
      {
        "search": [
          "woman",
          "student"
        ],
        "name": "woman_student",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j0yofytg.png",
        "icon": "👩‍🎓",
        "shortcode": ":woman_student:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f393"
        ]
      },
      {
        "search": [
          "teacher"
        ],
        "name": "teacher",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dbyo8hcq.png",
        "icon": "🧑‍🏫",
        "shortcode": ":teacher:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f3eb"
        ]
      },
      {
        "search": [
          "man",
          "teacher"
        ],
        "name": "man_teacher",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qceq6z2e.png",
        "icon": "👨‍🏫",
        "shortcode": ":man_teacher:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f3eb"
        ]
      },
      {
        "search": [
          "woman",
          "teacher"
        ],
        "name": "woman_teacher",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9yutoeru.png",
        "icon": "👩‍🏫",
        "shortcode": ":woman_teacher:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f3eb"
        ]
      },
      {
        "search": [
          "judge"
        ],
        "name": "judge",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9vf92uuo.png",
        "icon": "🧑‍⚖️",
        "shortcode": ":judge:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u2696",
          "ufe0f"
        ]
      },
      {
        "search": [
          "man",
          "judge"
        ],
        "name": "man_judge",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s1a1nbgs.png",
        "icon": "👨‍⚖️",
        "shortcode": ":man_judge:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u2696",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "judge"
        ],
        "name": "woman_judge",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/10rnneey.png",
        "icon": "👩‍⚖️",
        "shortcode": ":woman_judge:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u2696",
          "ufe0f"
        ]
      },
      {
        "search": [
          "farmer"
        ],
        "name": "farmer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v630szjp.png",
        "icon": "🧑‍🌾",
        "shortcode": ":farmer:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f33e"
        ]
      },
      {
        "search": [
          "man",
          "farmer"
        ],
        "name": "man_farmer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/k3tywu8o.png",
        "icon": "👨‍🌾",
        "shortcode": ":man_farmer:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f33e"
        ]
      },
      {
        "search": [
          "woman",
          "farmer"
        ],
        "name": "woman_farmer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/08uy7qqj.png",
        "icon": "👩‍🌾",
        "shortcode": ":woman_farmer:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f33e"
        ]
      },
      {
        "search": [
          "cook"
        ],
        "name": "cook",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qghyc1bq.png",
        "icon": "🧑‍🍳",
        "shortcode": ":cook:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f373"
        ]
      },
      {
        "search": [
          "man",
          "cook"
        ],
        "name": "man_cook",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jmq4vtok.png",
        "icon": "👨‍🍳",
        "shortcode": ":man_cook:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f373"
        ]
      },
      {
        "search": [
          "woman",
          "cook"
        ],
        "name": "woman_cook",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nyqs7y1j.png",
        "icon": "👩‍🍳",
        "shortcode": ":woman_cook:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f373"
        ]
      },
      {
        "search": [
          "mechanic"
        ],
        "name": "mechanic",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/deloxky1.png",
        "icon": "🧑‍🔧",
        "shortcode": ":mechanic:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f527"
        ]
      },
      {
        "search": [
          "man",
          "mechanic"
        ],
        "name": "man_mechanic",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gu67k7qc.png",
        "icon": "👨‍🔧",
        "shortcode": ":man_mechanic:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f527"
        ]
      },
      {
        "search": [
          "woman",
          "mechanic"
        ],
        "name": "woman_mechanic",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/p2k67voz.png",
        "icon": "👩‍🔧",
        "shortcode": ":woman_mechanic:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f527"
        ]
      },
      {
        "search": [
          "factory",
          "worker"
        ],
        "name": "factory_worker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/agw3mij3.png",
        "icon": "🧑‍🏭",
        "shortcode": ":factory_worker:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f3ed"
        ]
      },
      {
        "search": [
          "man",
          "factory",
          "worker"
        ],
        "name": "man_factory_worker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/18psjuu4.png",
        "icon": "👨‍🏭",
        "shortcode": ":man_factory_worker:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f3ed"
        ]
      },
      {
        "search": [
          "woman",
          "factory",
          "worker"
        ],
        "name": "woman_factory_worker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2h3o3vsf.png",
        "icon": "👩‍🏭",
        "shortcode": ":woman_factory_worker:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f3ed"
        ]
      },
      {
        "search": [
          "office",
          "worker"
        ],
        "name": "office_worker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3a36mh1u.png",
        "icon": "🧑‍💼",
        "shortcode": ":office_worker:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f4bc"
        ]
      },
      {
        "search": [
          "man",
          "office",
          "worker"
        ],
        "name": "man_office_worker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1psmjb3e.png",
        "icon": "👨‍💼",
        "shortcode": ":man_office_worker:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f4bc"
        ]
      },
      {
        "search": [
          "woman",
          "office",
          "worker"
        ],
        "name": "woman_office_worker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dal77z88.png",
        "icon": "👩‍💼",
        "shortcode": ":woman_office_worker:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f4bc"
        ]
      },
      {
        "search": [
          "scientist"
        ],
        "name": "scientist",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w11nku02.png",
        "icon": "🧑‍🔬",
        "shortcode": ":scientist:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f52c"
        ]
      },
      {
        "search": [
          "man",
          "scientist"
        ],
        "name": "man_scientist",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/k59gkqw4.png",
        "icon": "👨‍🔬",
        "shortcode": ":man_scientist:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f52c"
        ]
      },
      {
        "search": [
          "woman",
          "scientist"
        ],
        "name": "woman_scientist",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xqfj2kn1.png",
        "icon": "👩‍🔬",
        "shortcode": ":woman_scientist:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f52c"
        ]
      },
      {
        "search": [
          "technologist"
        ],
        "name": "technologist",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j8z9j22j.png",
        "icon": "🧑‍💻",
        "shortcode": ":technologist:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f4bb"
        ]
      },
      {
        "search": [
          "man",
          "technologist"
        ],
        "name": "man_technologist",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ahjrb52y.png",
        "icon": "👨‍💻",
        "shortcode": ":man_technologist:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f4bb"
        ]
      },
      {
        "search": [
          "woman",
          "technologist"
        ],
        "name": "woman_technologist",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zwnacgya.png",
        "icon": "👩‍💻",
        "shortcode": ":woman_technologist:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f4bb"
        ]
      },
      {
        "search": [
          "singer"
        ],
        "name": "singer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qt08l41u.png",
        "icon": "🧑‍🎤",
        "shortcode": ":singer:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f3a4"
        ]
      },
      {
        "search": [
          "man",
          "singer"
        ],
        "name": "man_singer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4l3zf870.png",
        "icon": "👨‍🎤",
        "shortcode": ":man_singer:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f3a4"
        ]
      },
      {
        "search": [
          "woman",
          "singer"
        ],
        "name": "woman_singer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/adnkclrb.png",
        "icon": "👩‍🎤",
        "shortcode": ":woman_singer:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f3a4"
        ]
      },
      {
        "search": [
          "artist"
        ],
        "name": "artist",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ia9668wb.png",
        "icon": "🧑‍🎨",
        "shortcode": ":artist:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f3a8"
        ]
      },
      {
        "search": [
          "man",
          "artist"
        ],
        "name": "man_artist",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ehm0zmuj.png",
        "icon": "👨‍🎨",
        "shortcode": ":man_artist:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f3a8"
        ]
      },
      {
        "search": [
          "woman",
          "artist"
        ],
        "name": "woman_artist",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pj9odu22.png",
        "icon": "👩‍🎨",
        "shortcode": ":woman_artist:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f3a8"
        ]
      },
      {
        "search": [
          "pilot"
        ],
        "name": "pilot",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/81zwa4jm.png",
        "icon": "🧑‍✈️",
        "shortcode": ":pilot:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u2708",
          "ufe0f"
        ]
      },
      {
        "search": [
          "man",
          "pilot"
        ],
        "name": "man_pilot",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ew8xdfa8.png",
        "icon": "👨‍✈️",
        "shortcode": ":man_pilot:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u2708",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "pilot"
        ],
        "name": "woman_pilot",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2171n186.png",
        "icon": "👩‍✈️",
        "shortcode": ":woman_pilot:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u2708",
          "ufe0f"
        ]
      },
      {
        "search": [
          "astronaut"
        ],
        "name": "astronaut",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/28qfoyfu.png",
        "icon": "🧑‍🚀",
        "shortcode": ":astronaut:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f680"
        ]
      },
      {
        "search": [
          "man",
          "astronaut"
        ],
        "name": "man_astronaut",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/z6perye6.png",
        "icon": "👨‍🚀",
        "shortcode": ":man_astronaut:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f680"
        ]
      },
      {
        "search": [
          "woman",
          "astronaut"
        ],
        "name": "woman_astronaut",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yne6ycyf.png",
        "icon": "👩‍🚀",
        "shortcode": ":woman_astronaut:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f680"
        ]
      },
      {
        "search": [
          "firefighter"
        ],
        "name": "firefighter",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/parjn2to.png",
        "icon": "🧑‍🚒",
        "shortcode": ":firefighter:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f692"
        ]
      },
      {
        "search": [
          "man",
          "firefighter"
        ],
        "name": "man_firefighter",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0pziu3k2.png",
        "icon": "👨‍🚒",
        "shortcode": ":man_firefighter:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f692"
        ]
      },
      {
        "search": [
          "woman",
          "firefighter"
        ],
        "name": "woman_firefighter",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/160ayc4o.png",
        "icon": "👩‍🚒",
        "shortcode": ":woman_firefighter:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f692"
        ]
      },
      {
        "search": [
          "police",
          "officer"
        ],
        "name": "police_officer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jckqb3x9.png",
        "icon": "👮",
        "shortcode": ":police_officer:",
        "unicodes": [
          "u1f46e"
        ]
      },
      {
        "search": [
          "man",
          "police",
          "officer"
        ],
        "name": "man_police_officer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jg8vs2as.png",
        "icon": "👮‍♂️",
        "shortcode": ":man_police_officer:",
        "unicodes": [
          "u1f46e",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "police",
          "officer"
        ],
        "name": "woman_police_officer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2ovif2j5.png",
        "icon": "👮‍♀️",
        "shortcode": ":woman_police_officer:",
        "unicodes": [
          "u1f46e",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "detective"
        ],
        "name": "detective",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8l6dv0u4.png",
        "icon": "🕵",
        "shortcode": ":detective:",
        "unicodes": [
          "u1f575"
        ]
      },
      {
        "search": [
          "man",
          "detective"
        ],
        "name": "man_detective",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gy0v5nbw.png",
        "icon": "🕵️‍♂️",
        "shortcode": ":man_detective:",
        "unicodes": [
          "u1f575",
          "ufe0f",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "detective"
        ],
        "name": "woman_detective",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/u4sw01zj.png",
        "icon": "🕵️‍♀️",
        "shortcode": ":woman_detective:",
        "unicodes": [
          "u1f575",
          "ufe0f",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "guard"
        ],
        "name": "guard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s1mioi27.png",
        "icon": "💂",
        "shortcode": ":guard:",
        "unicodes": [
          "u1f482"
        ]
      },
      {
        "search": [
          "man",
          "guard"
        ],
        "name": "man_guard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7h0sgqvo.png",
        "icon": "💂‍♂️",
        "shortcode": ":man_guard:",
        "unicodes": [
          "u1f482",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "guard"
        ],
        "name": "woman_guard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pszpzlmw.png",
        "icon": "💂‍♀️",
        "shortcode": ":woman_guard:",
        "unicodes": [
          "u1f482",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "ninja"
        ],
        "name": "ninja",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mkj9xkwi.png",
        "icon": "🥷",
        "shortcode": ":ninja:",
        "unicodes": [
          "u1f977"
        ]
      },
      {
        "search": [
          "construction",
          "worker"
        ],
        "name": "construction_worker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b58cym2u.png",
        "icon": "👷",
        "shortcode": ":construction_worker:",
        "unicodes": [
          "u1f477"
        ]
      },
      {
        "search": [
          "man"
        ],
        "name": "man",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/01wprzf1.png",
        "icon": "👷‍♂️",
        "shortcode": ":man:",
        "unicodes": [
          "u1f477",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "construction",
          "worker"
        ],
        "name": "woman_construction_worker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1p27b2ch.png",
        "icon": "👷‍♀️",
        "shortcode": ":woman_construction_worker:",
        "unicodes": [
          "u1f477",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "with",
          "crown"
        ],
        "name": "person_with_crown",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kl5hvy2k.png",
        "icon": "🫅",
        "shortcode": ":person_with_crown:",
        "unicodes": [
          "u1fac5"
        ]
      },
      {
        "search": [
          "prince"
        ],
        "name": "prince",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/07spx8ef.png",
        "icon": "🤴",
        "shortcode": ":prince:",
        "unicodes": [
          "u1f934"
        ]
      },
      {
        "search": [
          "princess"
        ],
        "name": "princess",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5hz4xfet.png",
        "icon": "👸",
        "shortcode": ":princess:",
        "unicodes": [
          "u1f478"
        ]
      },
      {
        "search": [
          "person",
          "wearing",
          "turban"
        ],
        "name": "person_wearing_turban",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ggmw4ytb.png",
        "icon": "👳",
        "shortcode": ":person_wearing_turban:",
        "unicodes": [
          "u1f473"
        ]
      },
      {
        "search": [
          "man",
          "wearing",
          "turban"
        ],
        "name": "man_wearing_turban",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rp0e2ruj.png",
        "icon": "👳‍♂️",
        "shortcode": ":man_wearing_turban:",
        "unicodes": [
          "u1f473",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "wearing",
          "turban"
        ],
        "name": "woman_wearing_turban",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ozz5irlg.png",
        "icon": "👳‍♀️",
        "shortcode": ":woman_wearing_turban:",
        "unicodes": [
          "u1f473",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "with",
          "skullcap"
        ],
        "name": "person_with_skullcap",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b6htl19c.png",
        "icon": "👲",
        "shortcode": ":person_with_skullcap:",
        "unicodes": [
          "u1f472"
        ]
      },
      {
        "search": [
          "woman",
          "with",
          "headscarf"
        ],
        "name": "woman_with_headscarf",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v5dhkmsl.png",
        "icon": "🧕",
        "shortcode": ":woman_with_headscarf:",
        "unicodes": [
          "u1f9d5"
        ]
      },
      {
        "search": [
          "person",
          "in",
          "tuxedo"
        ],
        "name": "person_in_tuxedo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uq6xeqs3.png",
        "icon": "🤵",
        "shortcode": ":person_in_tuxedo:",
        "unicodes": [
          "u1f935"
        ]
      },
      {
        "search": [
          "man",
          "in",
          "tuxedo"
        ],
        "name": "man_in_tuxedo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pe9bxsw3.png",
        "icon": "🤵‍♂️",
        "shortcode": ":man_in_tuxedo:",
        "unicodes": [
          "u1f935",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "in",
          "tuxedo"
        ],
        "name": "woman_in_tuxedo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/imetcc6r.png",
        "icon": "🤵‍♀️",
        "shortcode": ":woman_in_tuxedo:",
        "unicodes": [
          "u1f935",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "with",
          "veil"
        ],
        "name": "person_with_veil",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2u39noip.png",
        "icon": "👰",
        "shortcode": ":person_with_veil:",
        "unicodes": [
          "u1f470"
        ]
      },
      {
        "search": [
          "man",
          "with",
          "veil"
        ],
        "name": "man_with_veil",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/o6ffkrpe.png",
        "icon": "👰‍♂️",
        "shortcode": ":man_with_veil:",
        "unicodes": [
          "u1f470",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "with",
          "veil"
        ],
        "name": "woman_with_veil",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5e1ejvs3.png",
        "icon": "👰‍♀️",
        "shortcode": ":woman_with_veil:",
        "unicodes": [
          "u1f470",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "pregnant",
          "woman"
        ],
        "name": "pregnant_woman",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kxz4xemq.png",
        "icon": "🤰",
        "shortcode": ":pregnant_woman:",
        "unicodes": [
          "u1f930"
        ]
      },
      {
        "search": [
          "pregnant",
          "man"
        ],
        "name": "pregnant_man",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/r2w7iqhn.png",
        "icon": "🫃",
        "shortcode": ":pregnant_man:",
        "unicodes": [
          "u1fac3"
        ]
      },
      {
        "search": [
          "pregnant",
          "person"
        ],
        "name": "pregnant_person",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/q2w1l5fh.png",
        "icon": "🫄",
        "shortcode": ":pregnant_person:",
        "unicodes": [
          "u1fac4"
        ]
      },
      {
        "search": [
          "breast_feeding"
        ],
        "name": "breast_feeding",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0zhjkl9o.png",
        "icon": "🤱",
        "shortcode": ":breast_feeding:",
        "unicodes": [
          "u1f931"
        ]
      },
      {
        "search": [
          "woman",
          "feeding",
          "babay"
        ],
        "name": "woman_feeding_babay",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9nld2zsn.png",
        "icon": "👩‍🍼",
        "shortcode": ":woman_feeding_baby:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f37c"
        ]
      },
      {
        "search": [
          "man",
          "feeding",
          "baby"
        ],
        "name": "man_feeding_baby",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tg5v6noo.png",
        "icon": "👨‍🍼",
        "shortcode": ":man_feeding_baby:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f37c"
        ]
      },
      {
        "search": [
          "person",
          "feeding",
          "baby"
        ],
        "name": "person_feeding_baby",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dfxh4r8f.png",
        "icon": "🧑‍🍼",
        "shortcode": ":person_feeding_baby:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f37c"
        ]
      },
      {
        "search": [
          "baby",
          "angel"
        ],
        "name": "baby_angel",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/aqy15snl.png",
        "icon": "👼",
        "shortcode": ":baby_angel:",
        "unicodes": [
          "u1f47c"
        ]
      },
      {
        "search": [
          "Santa",
          "Claus"
        ],
        "name": "Santa_Claus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/waxyp71n.png",
        "icon": "🎅",
        "shortcode": ":Santa_Claus:",
        "unicodes": [
          "u1f385"
        ]
      },
      {
        "search": [
          "Mrs.",
          "Claus"
        ],
        "name": "Mrs._Claus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/z0470ey3.png",
        "icon": "🤶",
        "shortcode": ":Mrs._Claus:",
        "unicodes": [
          "u1f936"
        ]
      },
      {
        "search": [
          "mx",
          "claus"
        ],
        "name": "mx_claus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5oq9p87a.png",
        "icon": "🧑‍🎄",
        "shortcode": ":mx_claus:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f384"
        ]
      },
      {
        "search": [
          "superhero"
        ],
        "name": "superhero",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/m33qaaun.png",
        "icon": "🦸",
        "shortcode": ":superhero:",
        "unicodes": [
          "u1f9b8"
        ]
      },
      {
        "search": [
          "man",
          "superhero"
        ],
        "name": "man_superhero",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zhj6v33y.png",
        "icon": "🦸‍♂️",
        "shortcode": ":man_superhero:",
        "unicodes": [
          "u1f9b8",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "superhero"
        ],
        "name": "woman_superhero",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ggg67rm7.png",
        "icon": "🦸‍♀️",
        "shortcode": ":woman_superhero:",
        "unicodes": [
          "u1f9b8",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "supervillain"
        ],
        "name": "supervillain",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7h9ocf5z.png",
        "icon": "🦹",
        "shortcode": ":supervillain:",
        "unicodes": [
          "u1f9b9"
        ]
      },
      {
        "search": [
          "man",
          "supervillain"
        ],
        "name": "man_supervillain",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h2pxecul.png",
        "icon": "🦹‍♂️",
        "shortcode": ":man_supervillain:",
        "unicodes": [
          "u1f9b9",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "supervillain"
        ],
        "name": "woman_supervillain",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e4al7wdb.png",
        "icon": "🦹‍♀️",
        "shortcode": ":woman_supervillain:",
        "unicodes": [
          "u1f9b9",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "mage"
        ],
        "name": "mage",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cd7hfjcs.png",
        "icon": "🧙",
        "shortcode": ":mage:",
        "unicodes": [
          "u1f9d9"
        ]
      },
      {
        "search": [
          "man",
          "mage"
        ],
        "name": "man_mage",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vg2nd9l6.png",
        "icon": "🧙‍♂️",
        "shortcode": ":man_mage:",
        "unicodes": [
          "u1f9d9",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "mage"
        ],
        "name": "woman_mage",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/q3p20p2p.png",
        "icon": "🧙‍♀️",
        "shortcode": ":woman_mage:",
        "unicodes": [
          "u1f9d9",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "fairy"
        ],
        "name": "fairy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/51p3uhjn.png",
        "icon": "🧚",
        "shortcode": ":fairy:",
        "unicodes": [
          "u1f9da"
        ]
      },
      {
        "search": [
          "man",
          "fairy"
        ],
        "name": "man_fairy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qm1e8164.png",
        "icon": "🧚‍♂️",
        "shortcode": ":man_fairy:",
        "unicodes": [
          "u1f9da",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "fairy"
        ],
        "name": "woman_fairy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/csbsfdgs.png",
        "icon": "🧚‍♀️",
        "shortcode": ":woman_fairy:",
        "unicodes": [
          "u1f9da",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "vampire"
        ],
        "name": "vampire",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qofnyrvy.png",
        "icon": "🧛",
        "shortcode": ":vampire:",
        "unicodes": [
          "u1f9db"
        ]
      },
      {
        "search": [
          "man",
          "vampire"
        ],
        "name": "man_vampire",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/50htsedh.png",
        "icon": "🧛‍♂️",
        "shortcode": ":man_vampire:",
        "unicodes": [
          "u1f9db",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "vampire"
        ],
        "name": "woman_vampire",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v74vkl9z.png",
        "icon": "🧛‍♀️",
        "shortcode": ":woman_vampire:",
        "unicodes": [
          "u1f9db",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "merperson"
        ],
        "name": "merperson",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ex6uxxli.png",
        "icon": "🧜",
        "shortcode": ":merperson:",
        "unicodes": [
          "u1f9dc"
        ]
      },
      {
        "search": [
          "merman"
        ],
        "name": "merman",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3tau8cmf.png",
        "icon": "🧜‍♂️",
        "shortcode": ":merman:",
        "unicodes": [
          "u1f9dc",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "mermaid"
        ],
        "name": "mermaid",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mxb3jgo1.png",
        "icon": "🧜‍♀️",
        "shortcode": ":mermaid:",
        "unicodes": [
          "u1f9dc",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "elf"
        ],
        "name": "elf",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fsfazkg5.png",
        "icon": "🧝",
        "shortcode": ":elf:",
        "unicodes": [
          "u1f9dd"
        ]
      },
      {
        "search": [
          "man",
          "elf"
        ],
        "name": "man_elf",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ezb0pm8f.png",
        "icon": "🧝‍♂️",
        "shortcode": ":man_elf:",
        "unicodes": [
          "u1f9dd",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "elf"
        ],
        "name": "woman_elf",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fdtofmx6.png",
        "icon": "🧝‍♀️",
        "shortcode": ":woman_elf:",
        "unicodes": [
          "u1f9dd",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "genie"
        ],
        "name": "genie",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/l4we54w0.png",
        "icon": "🧞",
        "shortcode": ":genie:",
        "unicodes": [
          "u1f9de"
        ]
      },
      {
        "search": [
          "man",
          "genie"
        ],
        "name": "man_genie",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/l0u46bov.png",
        "icon": "🧞‍♂️",
        "shortcode": ":man_genie:",
        "unicodes": [
          "u1f9de",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "genie"
        ],
        "name": "woman_genie",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rncnaevx.png",
        "icon": "🧞‍♀️",
        "shortcode": ":woman_genie:",
        "unicodes": [
          "u1f9de",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "zombie"
        ],
        "name": "zombie",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h2oxcf46.png",
        "icon": "🧟",
        "shortcode": ":zombie:",
        "unicodes": [
          "u1f9df"
        ]
      },
      {
        "search": [
          "man",
          "zombie"
        ],
        "name": "man_zombie",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2p4rimy1.png",
        "icon": "🧟‍♂️",
        "shortcode": ":man_zombie:",
        "unicodes": [
          "u1f9df",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "zombie"
        ],
        "name": "woman_zombie",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kgslsg86.png",
        "icon": "🧟‍♀️",
        "shortcode": ":woman_zombie:",
        "unicodes": [
          "u1f9df",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "troll"
        ],
        "name": "troll",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d5ng4gmt.png",
        "icon": "🧌",
        "shortcode": ":troll:",
        "unicodes": [
          "u1f9cc"
        ]
      },
      {
        "search": [
          "person",
          "getting",
          "massage"
        ],
        "name": "person_getting_massage",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fqfn3ng8.png",
        "icon": "💆",
        "shortcode": ":person_getting_massage:",
        "unicodes": [
          "u1f486"
        ]
      },
      {
        "search": [
          "man",
          "getting",
          "massage"
        ],
        "name": "man_getting_massage",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kry50uqu.png",
        "icon": "💆‍♂️",
        "shortcode": ":man_getting_massage:",
        "unicodes": [
          "u1f486",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "getting",
          "massage"
        ],
        "name": "woman_getting_massage",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/60yypxwz.png",
        "icon": "💆‍♀️",
        "shortcode": ":woman_getting_massage:",
        "unicodes": [
          "u1f486",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "getting",
          "haircut"
        ],
        "name": "person_getting_haircut",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nxlj8jyi.png",
        "icon": "💇",
        "shortcode": ":person_getting_haircut:",
        "unicodes": [
          "u1f487"
        ]
      },
      {
        "search": [
          "man",
          "getting",
          "haircut"
        ],
        "name": "man_getting_haircut",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fm7rldzd.png",
        "icon": "💇‍♂️",
        "shortcode": ":man_getting_haircut:",
        "unicodes": [
          "u1f487",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "getting",
          "haircut"
        ],
        "name": "woman_getting_haircut",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zdcu5f1q.png",
        "icon": "💇‍♀️",
        "shortcode": ":woman_getting_haircut:",
        "unicodes": [
          "u1f487",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "walking"
        ],
        "name": "person_walking",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hfh3l2ah.png",
        "icon": "🚶",
        "shortcode": ":person_walking:",
        "unicodes": [
          "u1f6b6"
        ]
      },
      {
        "search": [
          "man",
          "walking"
        ],
        "name": "man_walking",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/chydhbcq.png",
        "icon": "🚶‍♂️",
        "shortcode": ":man_walking:",
        "unicodes": [
          "u1f6b6",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "walking"
        ],
        "name": "woman_walking",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kcpv6buu.png",
        "icon": "🚶‍♀️",
        "shortcode": ":woman_walking:",
        "unicodes": [
          "u1f6b6",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "standing"
        ],
        "name": "person_standing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/z0oim0wh.png",
        "icon": "🧍",
        "shortcode": ":person_standing:",
        "unicodes": [
          "u1f9cd"
        ]
      },
      {
        "search": [
          "man",
          "standing"
        ],
        "name": "man_standing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i7aeu4el.png",
        "icon": "🧍‍♂️",
        "shortcode": ":man_standing:",
        "unicodes": [
          "u1f9cd",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "standing"
        ],
        "name": "woman_standing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b6net4n6.png",
        "icon": "🧍‍♀️",
        "shortcode": ":woman_standing:",
        "unicodes": [
          "u1f9cd",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "kneeling"
        ],
        "name": "person_kneeling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kx3f39op.png",
        "icon": "🧎",
        "shortcode": ":person_kneeling:",
        "unicodes": [
          "u1f9ce"
        ]
      },
      {
        "search": [
          "man",
          "kneeling"
        ],
        "name": "man_kneeling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5sk0n3op.png",
        "icon": "🧎‍♂️",
        "shortcode": ":man_kneeling:",
        "unicodes": [
          "u1f9ce",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "kneeling"
        ],
        "name": "woman_kneeling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iw8t6guw.png",
        "icon": "🧎‍♀️",
        "shortcode": ":woman_kneeling:",
        "unicodes": [
          "u1f9ce",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "with",
          "white",
          "cane"
        ],
        "name": "person_with_white_cane",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/c5ftt91h.png",
        "icon": "🧑‍🦯",
        "shortcode": ":person_with_white_cane:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f9af"
        ]
      },
      {
        "search": [
          "man",
          "with",
          "white",
          "cane"
        ],
        "name": "man_with_white_cane",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1igql0wn.png",
        "icon": "👨‍🦯",
        "shortcode": ":man_with_white_cane:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f9af"
        ]
      },
      {
        "search": [
          "woman",
          "with",
          "white",
          "cane"
        ],
        "name": "woman_with_white_cane",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ek7g3e64.png",
        "icon": "👩‍🦯",
        "shortcode": ":woman_with_white_cane:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f9af"
        ]
      },
      {
        "search": [
          "person",
          "in",
          "motorized",
          "wheelchair"
        ],
        "name": "person_in_motorized_wheelchair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xy8lpbku.png",
        "icon": "🧑‍🦼",
        "shortcode": ":person_in_motorized_wheelchair:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f9bc"
        ]
      },
      {
        "search": [
          "man",
          "in",
          "motorized",
          "wheelchair"
        ],
        "name": "man_in_motorized_wheelchair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3tzwtpyg.png",
        "icon": "👨‍🦼",
        "shortcode": ":man_in_motorized_wheelchair:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f9bc"
        ]
      },
      {
        "search": [
          "woman",
          "in",
          "motorized",
          "wheelchair"
        ],
        "name": "woman_in_motorized_wheelchair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bmfns65a.png",
        "icon": "👩‍🦼",
        "shortcode": ":woman_in_motorized_wheelchair:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f9bc"
        ]
      },
      {
        "search": [
          "person",
          "in",
          "manual",
          "wheelchair"
        ],
        "name": "person_in_manual_wheelchair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8pqypfo8.png",
        "icon": "🧑‍🦽",
        "shortcode": ":person_in_manual_wheelchair:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f9bd"
        ]
      },
      {
        "search": [
          "man",
          "in",
          "manual",
          "wheelchair"
        ],
        "name": "man_in_manual_wheelchair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/alegxmqw.png",
        "icon": "👨‍🦽",
        "shortcode": ":man_in_manual_wheelchair:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f9bd"
        ]
      },
      {
        "search": [
          "woman",
          "in",
          "manual",
          "wheelchair"
        ],
        "name": "woman_in_manual_wheelchair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/10im9lyv.png",
        "icon": "👩‍🦽",
        "shortcode": ":woman_in_manual_wheelchair:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f9bd"
        ]
      },
      {
        "search": [
          "person",
          "running"
        ],
        "name": "person_running",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/euk50deq.png",
        "icon": "🏃",
        "shortcode": ":person_running:",
        "unicodes": [
          "u1f3c3"
        ]
      },
      {
        "search": [
          "man",
          "running"
        ],
        "name": "man_running",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yjplhljp.png",
        "icon": "🏃‍♂️",
        "shortcode": ":man_running:",
        "unicodes": [
          "u1f3c3",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "running"
        ],
        "name": "woman_running",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/beloqzd0.png",
        "icon": "🏃‍♀️",
        "shortcode": ":woman_running:",
        "unicodes": [
          "u1f3c3",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "dancing"
        ],
        "name": "woman_dancing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1huvnt77.png",
        "icon": "💃",
        "shortcode": ":woman_dancing:",
        "unicodes": [
          "u1f483"
        ]
      },
      {
        "search": [
          "man",
          "dancing"
        ],
        "name": "man_dancing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wm28b1nr.png",
        "icon": "🕺",
        "shortcode": ":man_dancing:",
        "unicodes": [
          "u1f57a"
        ]
      },
      {
        "search": [
          "person",
          "in",
          "suit",
          "levitating"
        ],
        "name": "person_in_suit_levitating",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sgr0xre3.png",
        "icon": "🕴",
        "shortcode": ":person_in_suit_levitating:",
        "unicodes": [
          "u1f574"
        ]
      },
      {
        "search": [
          "people",
          "with",
          "bunny",
          "ears"
        ],
        "name": "people_with_bunny_ears",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rnul8s8n.png",
        "icon": "👯",
        "shortcode": ":people_with_bunny_ears:",
        "unicodes": [
          "u1f46f"
        ]
      },
      {
        "search": [
          "men",
          "with",
          "bunny",
          "ears"
        ],
        "name": "men_with_bunny_ears",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/31uddseq.png",
        "icon": "👯‍♂️",
        "shortcode": ":men_with_bunny_ears:",
        "unicodes": [
          "u1f46f",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "women",
          "with",
          "bunny",
          "ears"
        ],
        "name": "women_with_bunny_ears",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ecvf9r0k.png",
        "icon": "👯‍♀️",
        "shortcode": ":women_with_bunny_ears:",
        "unicodes": [
          "u1f46f",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "in",
          "steamy",
          "room"
        ],
        "name": "person_in_steamy_room",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/11kfszcv.png",
        "icon": "🧖",
        "shortcode": ":person_in_steamy_room:",
        "unicodes": [
          "u1f9d6"
        ]
      },
      {
        "search": [
          "man",
          "in",
          "steamy",
          "room"
        ],
        "name": "man_in_steamy_room",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/oinjd166.png",
        "icon": "🧖‍♂️",
        "shortcode": ":man_in_steamy_room:",
        "unicodes": [
          "u1f9d6",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "in",
          "steamy",
          "room"
        ],
        "name": "woman_in_steamy_room",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pk0jfmwe.png",
        "icon": "🧖‍♀️",
        "shortcode": ":woman_in_steamy_room:",
        "unicodes": [
          "u1f9d6",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "climbing"
        ],
        "name": "person_climbing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9bkoseo4.png",
        "icon": "🧗",
        "shortcode": ":person_climbing:",
        "unicodes": [
          "u1f9d7"
        ]
      },
      {
        "search": [
          "man",
          "climbing"
        ],
        "name": "man_climbing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/aubga9i7.png",
        "icon": "🧗‍♂️",
        "shortcode": ":man_climbing:",
        "unicodes": [
          "u1f9d7",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "climbing"
        ],
        "name": "woman_climbing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zit3265m.png",
        "icon": "🧗‍♀️",
        "shortcode": ":woman_climbing:",
        "unicodes": [
          "u1f9d7",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "fencing"
        ],
        "name": "person_fencing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rsk08bzl.png",
        "icon": "🤺",
        "shortcode": ":person_fencing:",
        "unicodes": [
          "u1f93a"
        ]
      },
      {
        "search": [
          "horse",
          "racing"
        ],
        "name": "horse_racing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/c1irrwyy.png",
        "icon": "🏇",
        "shortcode": ":horse_racing:",
        "unicodes": [
          "u1f3c7"
        ]
      },
      {
        "search": [
          "skier"
        ],
        "name": "skier",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tocvp8wf.png",
        "icon": "⛷",
        "shortcode": ":skier:",
        "unicodes": [
          "u26f7"
        ]
      },
      {
        "search": [
          "snowboarder"
        ],
        "name": "snowboarder",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wn1vw6ip.png",
        "icon": "🏂",
        "shortcode": ":snowboarder:",
        "unicodes": [
          "u1f3c2"
        ]
      },
      {
        "search": [
          "person",
          "golfing"
        ],
        "name": "person_golfing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lo6gxpyo.png",
        "icon": "🏌",
        "shortcode": ":person_golfing:",
        "unicodes": [
          "u1f3cc"
        ]
      },
      {
        "search": [
          "man",
          "golfing"
        ],
        "name": "man_golfing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/450wirjb.png",
        "icon": "🏌️‍♂️",
        "shortcode": ":man_golfing:",
        "unicodes": [
          "u1f3cc",
          "ufe0f",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "golfing"
        ],
        "name": "woman_golfing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kxo49xwh.png",
        "icon": "🏌️‍♀️",
        "shortcode": ":woman_golfing:",
        "unicodes": [
          "u1f3cc",
          "ufe0f",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "surfing"
        ],
        "name": "person_surfing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1smjkj0m.png",
        "icon": "🏄",
        "shortcode": ":person_surfing:",
        "unicodes": [
          "u1f3c4"
        ]
      },
      {
        "search": [
          "man",
          "surfing"
        ],
        "name": "man_surfing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tarr1mal.png",
        "icon": "🏄‍♂️",
        "shortcode": ":man_surfing:",
        "unicodes": [
          "u1f3c4",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "surfing"
        ],
        "name": "woman_surfing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ny1srxel.png",
        "icon": "🏄‍♀️",
        "shortcode": ":woman_surfing:",
        "unicodes": [
          "u1f3c4",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "rowing",
          "boat"
        ],
        "name": "person_rowing_boat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j5v56nfe.png",
        "icon": "🚣",
        "shortcode": ":person_rowing_boat:",
        "unicodes": [
          "u1f6a3"
        ]
      },
      {
        "search": [
          "man",
          "rowing",
          "boat"
        ],
        "name": "man_rowing_boat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4s679oti.png",
        "icon": "🚣‍♂️",
        "shortcode": ":man_rowing_boat:",
        "unicodes": [
          "u1f6a3",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "rowing",
          "boat"
        ],
        "name": "woman_rowing_boat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/77cyu0zm.png",
        "icon": "🚣‍♀️",
        "shortcode": ":woman_rowing_boat:",
        "unicodes": [
          "u1f6a3",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "swimming"
        ],
        "name": "person_swimming",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/o7f4xeea.png",
        "icon": "🏊",
        "shortcode": ":person_swimming:",
        "unicodes": [
          "u1f3ca"
        ]
      },
      {
        "search": [
          "man",
          "swimming"
        ],
        "name": "man_swimming",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cuwklehk.png",
        "icon": "🏊‍♂️",
        "shortcode": ":man_swimming:",
        "unicodes": [
          "u1f3ca",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "swimming"
        ],
        "name": "woman_swimming",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v99qjupn.png",
        "icon": "🏊‍♀️",
        "shortcode": ":woman_swimming:",
        "unicodes": [
          "u1f3ca",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "bouncing",
          "ball"
        ],
        "name": "person_bouncing_ball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kq8aji9i.png",
        "icon": "⛹",
        "shortcode": ":person_bouncing_ball:",
        "unicodes": [
          "u26f9"
        ]
      },
      {
        "search": [
          "man",
          "bouncing",
          "ball"
        ],
        "name": "man_bouncing_ball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e6c5lvq0.png",
        "icon": "⛹️‍♂️",
        "shortcode": ":man_bouncing_ball:",
        "unicodes": [
          "u26f9",
          "ufe0f",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "bouncing",
          "ball"
        ],
        "name": "woman_bouncing_ball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tooncsmd.png",
        "icon": "⛹️‍♀️",
        "shortcode": ":woman_bouncing_ball:",
        "unicodes": [
          "u26f9",
          "ufe0f",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "lifting",
          "weights"
        ],
        "name": "person_lifting_weights",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/perc4rkt.png",
        "icon": "🏋",
        "shortcode": ":person_lifting_weights:",
        "unicodes": [
          "u1f3cb"
        ]
      },
      {
        "search": [
          "man",
          "lifting",
          "weights"
        ],
        "name": "man_lifting_weights",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s4007072.png",
        "icon": "🏋️‍♂️",
        "shortcode": ":man_lifting_weights:",
        "unicodes": [
          "u1f3cb",
          "ufe0f",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "lifting",
          "weights"
        ],
        "name": "woman_lifting_weights",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ll9e4lnr.png",
        "icon": "🏋️‍♀️",
        "shortcode": ":woman_lifting_weights:",
        "unicodes": [
          "u1f3cb",
          "ufe0f",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "biking"
        ],
        "name": "person_biking",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ug1jmeq1.png",
        "icon": "🚴",
        "shortcode": ":person_biking:",
        "unicodes": [
          "u1f6b4"
        ]
      },
      {
        "search": [
          "man",
          "biking"
        ],
        "name": "main_biking",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i4ze19v9.png",
        "icon": "🚴‍♂️",
        "shortcode": ":man_biking:",
        "unicodes": [
          "u1f6b4",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "biking"
        ],
        "name": "woman_biking",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fbxl0n0h.png",
        "icon": "🚴‍♀️",
        "shortcode": ":woman_biking:",
        "unicodes": [
          "u1f6b4",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "mountain",
          "biking"
        ],
        "name": "person_mountain_biking",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/39yj7xh6.png",
        "icon": "🚵",
        "shortcode": ":person_mountain_biking:",
        "unicodes": [
          "u1f6b5"
        ]
      },
      {
        "search": [
          "man",
          "mountain",
          "biking"
        ],
        "name": "man_mountain_biking",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zr1e05l3.png",
        "icon": "🚵‍♂️",
        "shortcode": ":man_mountain_biking:",
        "unicodes": [
          "u1f6b5",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "mountain",
          "biking"
        ],
        "name": "woman_mountain_biking",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5ha74x7d.png",
        "icon": "🚵‍♀️",
        "shortcode": ":woman_mountain_biking:",
        "unicodes": [
          "u1f6b5",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "cartwheeling"
        ],
        "name": "person_cartwheeling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/c6fkkwc8.png",
        "icon": "🤸",
        "shortcode": ":person_cartwheeling:",
        "unicodes": [
          "u1f938"
        ]
      },
      {
        "search": [
          "man",
          "cartwheeling"
        ],
        "name": "man_cartwheeling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3i6sqh7d.png",
        "icon": "🤸‍♂️",
        "shortcode": ":man_cartwheeling:",
        "unicodes": [
          "u1f938",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "cartwheeling"
        ],
        "name": "woman_cartwheeling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cjjlgbjn.png",
        "icon": "🤸‍♀️",
        "shortcode": ":woman_cartwheeling:",
        "unicodes": [
          "u1f938",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "people",
          "wrestling"
        ],
        "name": "people_wrestling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ixdt5iv2.png",
        "icon": "🤼",
        "shortcode": ":people_wrestling:",
        "unicodes": [
          "u1f93c"
        ]
      },
      {
        "search": [
          "men",
          "wrestling"
        ],
        "name": "men_wrestling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sspkejej.png",
        "icon": "🤼‍♂️",
        "shortcode": ":men_wrestling:",
        "unicodes": [
          "u1f93c",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "women",
          "wrestling"
        ],
        "name": "women_wrestling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nki92m11.png",
        "icon": "🤼‍♀️",
        "shortcode": ":women_wrestling:",
        "unicodes": [
          "u1f93c",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "playing",
          "water",
          "polo"
        ],
        "name": "person_playing_water_polo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/12r9qkjv.png",
        "icon": "🤽",
        "shortcode": ":person_playing_water_polo:",
        "unicodes": [
          "u1f93d"
        ]
      },
      {
        "search": [
          "man",
          "playing",
          "water",
          "polo"
        ],
        "name": "man_playing_water_polo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dgxc12vi.png",
        "icon": "🤽‍♂️",
        "shortcode": ":man_playing_water_polo:",
        "unicodes": [
          "u1f93d",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "playing",
          "water",
          "polo"
        ],
        "name": "woman_playing_water_polo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vgd64839.png",
        "icon": "🤽‍♀️",
        "shortcode": ":woman_playing_water_polo:",
        "unicodes": [
          "u1f93d",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "playing",
          "handball"
        ],
        "name": "person_playing_handball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w2jzxat7.png",
        "icon": "🤾",
        "shortcode": ":person_playing_handball:",
        "unicodes": [
          "u1f93e"
        ]
      },
      {
        "search": [
          "man",
          "playing",
          "handball"
        ],
        "name": "man_playing_handball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ya7qudpm.png",
        "icon": "🤾‍♂️",
        "shortcode": ":man_playing_handball:",
        "unicodes": [
          "u1f93e",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          ""
        ],
        "name": "woman_",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zvuephl5.png",
        "icon": "🤾‍♀️",
        "shortcode": ":woman:",
        "unicodes": [
          "u1f93e",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "juggling"
        ],
        "name": "person_juggling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8kvnc3x3.png",
        "icon": "🤹",
        "shortcode": ":person_juggling:",
        "unicodes": [
          "u1f939"
        ]
      },
      {
        "search": [
          "man",
          "juggling"
        ],
        "name": "man_juggling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/npqr828u.png",
        "icon": "🤹‍♂️",
        "shortcode": ":man_juggling:",
        "unicodes": [
          "u1f939",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "juggling"
        ],
        "name": "woman_juggling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cfcrved2.png",
        "icon": "🤹‍♀️",
        "shortcode": ":woman_juggling:",
        "unicodes": [
          "u1f939",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "in",
          "lotus",
          "position"
        ],
        "name": "person_in_lotus_position",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jlfqurd4.png",
        "icon": "🧘",
        "shortcode": ":person_in_lotus_position:",
        "unicodes": [
          "u1f9d8"
        ]
      },
      {
        "search": [
          "man",
          "in",
          "lotus",
          "position"
        ],
        "name": "man_in_lotus_position",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wnfs0mzs.png",
        "icon": "🧘‍♂️",
        "shortcode": ":man_in_lotus_position:",
        "unicodes": [
          "u1f9d8",
          "u200d",
          "u2642",
          "ufe0f"
        ]
      },
      {
        "search": [
          "woman",
          "in",
          "lotus",
          "position"
        ],
        "name": "woman_in_lotus_position",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dmckyl0y.png",
        "icon": "🧘‍♀️",
        "shortcode": ":woman_in_lotus_position:",
        "unicodes": [
          "u1f9d8",
          "u200d",
          "u2640",
          "ufe0f"
        ]
      },
      {
        "search": [
          "person",
          "taking",
          "bath"
        ],
        "name": "person_taking_bath",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2wmto2l2.png",
        "icon": "🛀",
        "shortcode": ":person_taking_bath:",
        "unicodes": [
          "u1f6c0"
        ]
      },
      {
        "search": [
          "person",
          "in",
          "bed"
        ],
        "name": "person_in_bed",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9m28hif5.png",
        "icon": "🛌",
        "shortcode": ":person_in_bed:",
        "unicodes": [
          "u1f6cc"
        ]
      },
      {
        "search": [
          "people",
          "holding",
          "hands"
        ],
        "name": "people_holding_hands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zfs96p5j.png",
        "icon": "🧑‍🤝‍🧑",
        "shortcode": ":people_holding_hands:",
        "unicodes": [
          "u1f9d1",
          "u200d",
          "u1f91d",
          "u200d",
          "u1f9d1"
        ]
      },
      {
        "search": [
          "women",
          "holding",
          "hands"
        ],
        "name": "women_holding_hands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wmxsaxlu.png",
        "icon": "👭",
        "shortcode": ":women_holding_hands:",
        "unicodes": [
          "u1f46d"
        ]
      },
      {
        "search": [
          "woman",
          "and",
          "man",
          "holding",
          "hands"
        ],
        "name": "woman_and_man_holding_hands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/upbs8zdf.png",
        "icon": "👫",
        "shortcode": ":woman_and_man_holding_hands:",
        "unicodes": [
          "u1f46b"
        ]
      },
      {
        "search": [
          "men",
          "holding",
          "hands"
        ],
        "name": "men_holding_hands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rd16fs9o.png",
        "icon": "👬",
        "shortcode": ":men_holding_hands:",
        "unicodes": [
          "u1f46c"
        ]
      },
      {
        "search": [
          "kiss"
        ],
        "name": "kiss",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qzluyfke.png",
        "icon": "💏",
        "shortcode": ":kiss:",
        "unicodes": [
          "u1f48f"
        ]
      },
      {
        "search": [
          "kiss",
          "woman,",
          "man"
        ],
        "name": "kiss_woman,_man",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ya8vuf7g.png",
        "icon": "👩‍❤️‍💋‍👨",
        "shortcode": ":kiss_woman_man:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u2764",
          "ufe0f",
          "u200d",
          "u1f48b",
          "u200d",
          "u1f468"
        ]
      },
      {
        "search": [
          "kiss",
          "man,",
          "man"
        ],
        "name": "kiss_man,_man",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xmudnhb5.png",
        "icon": "👨‍❤️‍💋‍👨",
        "shortcode": ":kiss_man_man:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u2764",
          "ufe0f",
          "u200d",
          "u1f48b",
          "u200d",
          "u1f468"
        ]
      },
      {
        "search": [
          "kiss",
          "woman,",
          "woman"
        ],
        "name": "kiss_woman,_woman",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bjee29zc.png",
        "icon": "👩‍❤️‍💋‍👩",
        "shortcode": ":kiss_woman_woman:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u2764",
          "ufe0f",
          "u200d",
          "u1f48b",
          "u200d",
          "u1f469"
        ]
      },
      {
        "search": [
          "couple",
          "with",
          "heart"
        ],
        "name": "couple_with_heart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fon1pqws.png",
        "icon": "💑",
        "shortcode": ":couple_with_heart:",
        "unicodes": [
          "u1f491"
        ]
      },
      {
        "search": [
          "couple",
          "with",
          "heart",
          "woman,",
          "man"
        ],
        "name": "couple_with_heart_woman,_man",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gdwrcfer.png",
        "icon": "👩‍❤️‍👨",
        "shortcode": ":couple_with_heart_woman_man:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u2764",
          "ufe0f",
          "u200d",
          "u1f468"
        ]
      },
      {
        "search": [
          "couple",
          "with",
          "heart",
          "man,",
          "man"
        ],
        "name": "couple_with_heart_man,_man",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1t0m97vq.png",
        "icon": "👨‍❤️‍👨",
        "shortcode": ":couple_with_heart_man_man:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u2764",
          "ufe0f",
          "u200d",
          "u1f468"
        ]
      },
      {
        "search": [
          "couple",
          "with",
          "heart",
          "woman,",
          "woman"
        ],
        "name": "couple_with_heart_woman,_woman",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/k0xf6z8e.png",
        "icon": "👩‍❤️‍👩",
        "shortcode": ":couple_with_heart_woman_woman:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u2764",
          "ufe0f",
          "u200d",
          "u1f469"
        ]
      },
      {
        "search": [
          "family"
        ],
        "name": "family",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fwrwfjmp.png",
        "icon": "👪",
        "shortcode": ":family:",
        "unicodes": [
          "u1f46a"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          "woman,",
          "boy"
        ],
        "name": "family_man,_woman,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kr30x2zm.png",
        "icon": "👨‍👩‍👦",
        "shortcode": ":family_man_woman_boy:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f469",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          "woman,",
          "girl"
        ],
        "name": "family_man,_woman,_girl",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h8h3onm4.png",
        "icon": "👨‍👩‍👧",
        "shortcode": ":family_man_woman_girl:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f469",
          "u200d",
          "u1f467"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          "woman,",
          "girl,",
          "boy"
        ],
        "name": "family_man,_woman,_girl,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/edyatggp.png",
        "icon": "👨‍👩‍👧‍👦",
        "shortcode": ":family_man_woman_girl_boy:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f469",
          "u200d",
          "u1f467",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          "woman,",
          "boy,",
          "boy"
        ],
        "name": "family_man,_woman,_boy,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7ygryp69.png",
        "icon": "👨‍👩‍👦‍👦",
        "shortcode": ":family_man_woman_boy_boy:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f469",
          "u200d",
          "u1f466",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          "woman,",
          "girl,",
          "girl"
        ],
        "name": "family_man,_woman,_girl,_girl",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3esijktf.png",
        "icon": "👨‍👩‍👧‍👧",
        "shortcode": ":family_man_woman_girl_girl:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f469",
          "u200d",
          "u1f467",
          "u200d",
          "u1f467"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          "man,",
          "boy"
        ],
        "name": "family_man,_man,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ggvlajo1.png",
        "icon": "👨‍👨‍👦",
        "shortcode": ":family_man_man_boy:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f468",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          ""
        ],
        "name": "family_man,_",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/y7o8httd.png",
        "icon": "👨‍👨‍👧",
        "shortcode": ":family_man:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f468",
          "u200d",
          "u1f467"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          "man,",
          "girl,",
          "boy"
        ],
        "name": "family_man,_man,_girl,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2m1wlghs.png",
        "icon": "👨‍👨‍👧‍👦",
        "shortcode": ":family_man_man_girl_boy:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f468",
          "u200d",
          "u1f467",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          "man,",
          "boy,",
          "boy"
        ],
        "name": "family_man,_man,_boy,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pvixb2vl.png",
        "icon": "👨‍👨‍👦‍👦",
        "shortcode": ":family_man_man_boy_boy:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f468",
          "u200d",
          "u1f466",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          "man,",
          "girl,",
          "girl"
        ],
        "name": "family_man,_man,_girl,_girl",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8ac2745z.png",
        "icon": "👨‍👨‍👧‍👧",
        "shortcode": ":family_man_man_girl_girl:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f468",
          "u200d",
          "u1f467",
          "u200d",
          "u1f467"
        ]
      },
      {
        "search": [
          "family",
          "woman,",
          "woman,",
          "boy"
        ],
        "name": "family_woman,_woman,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mjfl4doz.png",
        "icon": "👩‍👩‍👦",
        "shortcode": ":family_woman_woman_boy:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f469",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "woman,",
          "woman,",
          "girl"
        ],
        "name": "family_woman,_woman,_girl",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/m3rdsi6x.png",
        "icon": "👩‍👩‍👧",
        "shortcode": ":family_woman_woman_girl:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f469",
          "u200d",
          "u1f467"
        ]
      },
      {
        "search": [
          "family",
          "woman,",
          "woman,",
          "girl,",
          "boy"
        ],
        "name": "family_woman,_woman,_girl,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ype4140u.png",
        "icon": "👩‍👩‍👧‍👦",
        "shortcode": ":family_woman_woman_girl_boy:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f469",
          "u200d",
          "u1f467",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "woman,",
          "woman,",
          "boy,",
          "boy"
        ],
        "name": "family_woman,_woman,_boy,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0l0seyp5.png",
        "icon": "👩‍👩‍👦‍👦",
        "shortcode": ":family_woman_woman_boy_boy:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f469",
          "u200d",
          "u1f466",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "woman,",
          "woman,",
          "girl,",
          "girl"
        ],
        "name": "family_woman,_woman,_girl,_girl",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hys5qacd.png",
        "icon": "👩‍👩‍👧‍👧",
        "shortcode": ":family_woman_woman_girl_girl:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f469",
          "u200d",
          "u1f467",
          "u200d",
          "u1f467"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          "boy"
        ],
        "name": "family_man,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3f48fxdp.png",
        "icon": "👨‍👦",
        "shortcode": ":family_man_boy:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          "boy,",
          "boy"
        ],
        "name": "family_man,_boy,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3rsczdma.png",
        "icon": "👨‍👦‍👦",
        "shortcode": ":family_man_boy_boy:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f466",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          "girl"
        ],
        "name": "family_man,_girl",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xadtwc6a.png",
        "icon": "👨‍👧",
        "shortcode": ":family_man_girl:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f467"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          "girl,",
          "boy"
        ],
        "name": "family_man,_girl,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iwrj4ec7.png",
        "icon": "👨‍👧‍👦",
        "shortcode": ":family_man_girl_boy:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f467",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "man,",
          "girl,",
          "girl"
        ],
        "name": "family_man,_girl,_girl",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e4pclzmx.png",
        "icon": "👨‍👧‍👧",
        "shortcode": ":family_man_girl_girl:",
        "unicodes": [
          "u1f468",
          "u200d",
          "u1f467",
          "u200d",
          "u1f467"
        ]
      },
      {
        "search": [
          "family",
          "woman,",
          "boy"
        ],
        "name": "family_woman,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qluv01yl.png",
        "icon": "👩‍👦",
        "shortcode": ":family_woman_boy:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "woman,",
          "boy,",
          "boy"
        ],
        "name": "family_woman,_boy,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/71le9pu7.png",
        "icon": "👩‍👦‍👦",
        "shortcode": ":family_woman_boy_boy:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f466",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "woman,",
          "girl"
        ],
        "name": "family_woman,_girl",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yrdn9q1x.png",
        "icon": "👩‍👧",
        "shortcode": ":family_woman_girl:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f467"
        ]
      },
      {
        "search": [
          "family",
          "woman,",
          "girl,",
          "boy"
        ],
        "name": "family_woman,_girl,_boy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sv9dq1ep.png",
        "icon": "👩‍👧‍👦",
        "shortcode": ":family_woman_girl_boy:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f467",
          "u200d",
          "u1f466"
        ]
      },
      {
        "search": [
          "family",
          "woman,",
          "girl,",
          "girl"
        ],
        "name": "family_woman,_girl,_girl",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qj4ougw2.png",
        "icon": "👩‍👧‍👧",
        "shortcode": ":family_woman_girl_girl:",
        "unicodes": [
          "u1f469",
          "u200d",
          "u1f467",
          "u200d",
          "u1f467"
        ]
      },
      {
        "search": [
          "speaking",
          "head"
        ],
        "name": "speaking_head",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nt05vkrm.png",
        "icon": "🗣",
        "shortcode": ":speaking_head:",
        "unicodes": [
          "u1f5e3"
        ]
      },
      {
        "search": [
          "bust",
          "in",
          "silhouette"
        ],
        "name": "bust_in_silhouette",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4pmbz9uc.png",
        "icon": "👤",
        "shortcode": ":bust_in_silhouette:",
        "unicodes": [
          "u1f464"
        ]
      },
      {
        "search": [
          "busts",
          "in",
          "silhouette"
        ],
        "name": "busts_in_silhouette",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/alypoigx.png",
        "icon": "👥",
        "shortcode": ":busts_in_silhouette:",
        "unicodes": [
          "u1f465"
        ]
      },
      {
        "search": [
          "people",
          "hugging"
        ],
        "name": "people_hugging",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nhgnzxk3.png",
        "icon": "🫂",
        "shortcode": ":people_hugging:",
        "unicodes": [
          "u1fac2"
        ]
      },
      {
        "search": [
          "footprints"
        ],
        "name": "footprints",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fjti7flg.png",
        "icon": "👣",
        "shortcode": ":footprints:",
        "unicodes": [
          "u1f463"
        ]
      },
      {
        "search": [
          "red",
          "hair"
        ],
        "name": "red_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gw4encgm.png",
        "icon": "🦰",
        "shortcode": ":red_hair:",
        "unicodes": [
          "u1f9b0"
        ]
      },
      {
        "search": [
          "curly",
          "hair"
        ],
        "name": "curly_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ojcudec7.png",
        "icon": "🦱",
        "shortcode": ":curly_hair:",
        "unicodes": [
          "u1f9b1"
        ]
      },
      {
        "search": [
          "white",
          "hair"
        ],
        "name": "white_hair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4e3afze6.png",
        "icon": "🦳",
        "shortcode": ":white_hair:",
        "unicodes": [
          "u1f9b3"
        ]
      },
      {
        "search": [
          "bald"
        ],
        "name": "bald",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ironwpx8.png",
        "icon": "🦲",
        "shortcode": ":bald:",
        "unicodes": [
          "u1f9b2"
        ]
      }
    ]
  },
  {
    "category": "animals_and_nature",
    "text": "Animals & Nature",
    "icons": [
      {
        "search": [
          "monkey",
          "face"
        ],
        "name": "monkey_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pusgecby.png",
        "icon": "🐵",
        "shortcode": ":monkey_face:",
        "unicodes": [
          "u1f435"
        ]
      },
      {
        "search": [
          "monkey"
        ],
        "name": "monkey",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dett1ru3.png",
        "icon": "🐒",
        "shortcode": ":monkey:",
        "unicodes": [
          "u1f412"
        ]
      },
      {
        "search": [
          "gorilla"
        ],
        "name": "gorilla",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ac83f108.png",
        "icon": "🦍",
        "shortcode": ":gorilla:",
        "unicodes": [
          "u1f98d"
        ]
      },
      {
        "search": [
          "orangutan"
        ],
        "name": "orangutan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/k93516kn.png",
        "icon": "🦧",
        "shortcode": ":orangutan:",
        "unicodes": [
          "u1f9a7"
        ]
      },
      {
        "search": [
          "dog",
          "face"
        ],
        "name": "dog_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hwxi8s6q.png",
        "icon": "🐶",
        "shortcode": ":dog_face:",
        "unicodes": [
          "u1f436"
        ]
      },
      {
        "search": [
          "dog"
        ],
        "name": "dog",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bk06u1nk.png",
        "icon": "🐕",
        "shortcode": ":dog:",
        "unicodes": [
          "u1f415"
        ]
      },
      {
        "search": [
          "guide",
          "dog"
        ],
        "name": "guide_dog",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/48k4fu6z.png",
        "icon": "🦮",
        "shortcode": ":guide_dog:",
        "unicodes": [
          "u1f9ae"
        ]
      },
      {
        "search": [
          "service",
          "dog"
        ],
        "name": "service_dog",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0svdgk0y.png",
        "icon": "🐕‍🦺",
        "shortcode": ":service_dog:",
        "unicodes": [
          "u1f415",
          "u200d",
          "u1f9ba"
        ]
      },
      {
        "search": [
          "poodle"
        ],
        "name": "poodle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7judebd8.png",
        "icon": "🐩",
        "shortcode": ":poodle:",
        "unicodes": [
          "u1f429"
        ]
      },
      {
        "search": [
          "wolf"
        ],
        "name": "wolf",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hs23upho.png",
        "icon": "🐺",
        "shortcode": ":wolf:",
        "unicodes": [
          "u1f43a"
        ]
      },
      {
        "search": [
          "fox"
        ],
        "name": "fox",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s52mc8wa.png",
        "icon": "🦊",
        "shortcode": ":fox:",
        "unicodes": [
          "u1f98a"
        ]
      },
      {
        "search": [
          "raccoon"
        ],
        "name": "raccoon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qlxc1b5q.png",
        "icon": "🦝",
        "shortcode": ":raccoon:",
        "unicodes": [
          "u1f99d"
        ]
      },
      {
        "search": [
          "cat",
          "face"
        ],
        "name": "cat_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ljjbep4t.png",
        "icon": "🐱",
        "shortcode": ":cat_face:",
        "unicodes": [
          "u1f431"
        ]
      },
      {
        "search": [
          "cat"
        ],
        "name": "cat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8312mnw7.png",
        "icon": "🐈",
        "shortcode": ":cat:",
        "unicodes": [
          "u1f408"
        ]
      },
      {
        "search": [
          "black",
          "cat"
        ],
        "name": "black_cat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ljn5fa1g.png",
        "icon": "🐈‍⬛",
        "shortcode": ":black_cat:",
        "unicodes": [
          "u1f408",
          "u200d",
          "u2b1b"
        ]
      },
      {
        "search": [
          "lion"
        ],
        "name": "lion",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/m1vp324a.png",
        "icon": "🦁",
        "shortcode": ":lion:",
        "unicodes": [
          "u1f981"
        ]
      },
      {
        "search": [
          "tiger",
          "face"
        ],
        "name": "tiger_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/oynpxp9t.png",
        "icon": "🐯",
        "shortcode": ":tiger_face:",
        "unicodes": [
          "u1f42f"
        ]
      },
      {
        "search": [
          "tiger"
        ],
        "name": "tiger",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3gjop1kw.png",
        "icon": "🐅",
        "shortcode": ":tiger:",
        "unicodes": [
          "u1f405"
        ]
      },
      {
        "search": [
          "leopard"
        ],
        "name": "leopard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b9iqcs8z.png",
        "icon": "🐆",
        "shortcode": ":leopard:",
        "unicodes": [
          "u1f406"
        ]
      },
      {
        "search": [
          "horse",
          "face"
        ],
        "name": "horse_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5fb9kwb0.png",
        "icon": "🐴",
        "shortcode": ":horse_face:",
        "unicodes": [
          "u1f434"
        ]
      },
      {
        "search": [
          "horse"
        ],
        "name": "horse",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vn5n9850.png",
        "icon": "🐎",
        "shortcode": ":horse:",
        "unicodes": [
          "u1f40e"
        ]
      },
      {
        "search": [
          "unicorn"
        ],
        "name": "unicorn",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/u8tqkwth.png",
        "icon": "🦄",
        "shortcode": ":unicorn:",
        "unicodes": [
          "u1f984"
        ]
      },
      {
        "search": [
          "zebra"
        ],
        "name": "zebra",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yt3tg9pe.png",
        "icon": "🦓",
        "shortcode": ":zebra:",
        "unicodes": [
          "u1f993"
        ]
      },
      {
        "search": [
          "deer"
        ],
        "name": "deer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pmwacbrj.png",
        "icon": "🦌",
        "shortcode": ":deer:",
        "unicodes": [
          "u1f98c"
        ]
      },
      {
        "search": [
          "bison"
        ],
        "name": "bison",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a4cjs7gj.png",
        "icon": "🦬",
        "shortcode": ":bison:",
        "unicodes": [
          "u1f9ac"
        ]
      },
      {
        "search": [
          "cow",
          "face"
        ],
        "name": "cow_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/irgm162f.png",
        "icon": "🐮",
        "shortcode": ":cow_face:",
        "unicodes": [
          "u1f42e"
        ]
      },
      {
        "search": [
          "ox"
        ],
        "name": "ox",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s1pdbgzv.png",
        "icon": "🐂",
        "shortcode": ":ox:",
        "unicodes": [
          "u1f402"
        ]
      },
      {
        "search": [
          "water",
          "buffalo"
        ],
        "name": "water_buffalo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3kg2mi6s.png",
        "icon": "🐃",
        "shortcode": ":water_buffalo:",
        "unicodes": [
          "u1f403"
        ]
      },
      {
        "search": [
          "cow"
        ],
        "name": "cow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/k7sg3fwp.png",
        "icon": "🐄",
        "shortcode": ":cow:",
        "unicodes": [
          "u1f404"
        ]
      },
      {
        "search": [
          "pig",
          "face"
        ],
        "name": "pig_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/89voydeu.png",
        "icon": "🐷",
        "shortcode": ":pig_face:",
        "unicodes": [
          "u1f437"
        ]
      },
      {
        "search": [
          "pig"
        ],
        "name": "pig",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jxpgc4yp.png",
        "icon": "🐖",
        "shortcode": ":pig:",
        "unicodes": [
          "u1f416"
        ]
      },
      {
        "search": [
          "boar"
        ],
        "name": "boar",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/35atjgvb.png",
        "icon": "🐗",
        "shortcode": ":boar:",
        "unicodes": [
          "u1f417"
        ]
      },
      {
        "search": [
          "pig",
          "nose"
        ],
        "name": "pig_nose",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zycd98xm.png",
        "icon": "🐽",
        "shortcode": ":pig_nose:",
        "unicodes": [
          "u1f43d"
        ]
      },
      {
        "search": [
          "ram"
        ],
        "name": "ram",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/19hxvrba.png",
        "icon": "🐏",
        "shortcode": ":ram:",
        "unicodes": [
          "u1f40f"
        ]
      },
      {
        "search": [
          "ewe"
        ],
        "name": "ewe",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w2s7smqy.png",
        "icon": "🐑",
        "shortcode": ":ewe:",
        "unicodes": [
          "u1f411"
        ]
      },
      {
        "search": [
          "goat"
        ],
        "name": "goat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/19d0a88c.png",
        "icon": "🐐",
        "shortcode": ":goat:",
        "unicodes": [
          "u1f410"
        ]
      },
      {
        "search": [
          "camel"
        ],
        "name": "camel",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1cupl7f8.png",
        "icon": "🐪",
        "shortcode": ":camel:",
        "unicodes": [
          "u1f42a"
        ]
      },
      {
        "search": [
          "two_hump",
          "camel"
        ],
        "name": "two_hump_camel",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/71xat59e.png",
        "icon": "🐫",
        "shortcode": ":two_hump_camel:",
        "unicodes": [
          "u1f42b"
        ]
      },
      {
        "search": [
          "llama"
        ],
        "name": "llama",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zg2bm5to.png",
        "icon": "🦙",
        "shortcode": ":llama:",
        "unicodes": [
          "u1f999"
        ]
      },
      {
        "search": [
          "giraffe"
        ],
        "name": "giraffe",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wedg5i2b.png",
        "icon": "🦒",
        "shortcode": ":giraffe:",
        "unicodes": [
          "u1f992"
        ]
      },
      {
        "search": [
          "elephant"
        ],
        "name": "elephant",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/n2z9extn.png",
        "icon": "🐘",
        "shortcode": ":elephant:",
        "unicodes": [
          "u1f418"
        ]
      },
      {
        "search": [
          "mammoth"
        ],
        "name": "mammoth",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9rn8m675.png",
        "icon": "🦣",
        "shortcode": ":mammoth:",
        "unicodes": [
          "u1f9a3"
        ]
      },
      {
        "search": [
          "rhinoceros"
        ],
        "name": "rhinoceros",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0stupwab.png",
        "icon": "🦏",
        "shortcode": ":rhinoceros:",
        "unicodes": [
          "u1f98f"
        ]
      },
      {
        "search": [
          "hippopotamus"
        ],
        "name": "hippopotamus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9e76cuwu.png",
        "icon": "🦛",
        "shortcode": ":hippopotamus:",
        "unicodes": [
          "u1f99b"
        ]
      },
      {
        "search": [
          "mouse",
          "face"
        ],
        "name": "mouse_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gctuqy0n.png",
        "icon": "🐭",
        "shortcode": ":mouse_face:",
        "unicodes": [
          "u1f42d"
        ]
      },
      {
        "search": [
          "mouse"
        ],
        "name": "mouse",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/auucsx1d.png",
        "icon": "🐁",
        "shortcode": ":mouse:",
        "unicodes": [
          "u1f401"
        ]
      },
      {
        "search": [
          "rat"
        ],
        "name": "rat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uscx3i9s.png",
        "icon": "🐀",
        "shortcode": ":rat:",
        "unicodes": [
          "u1f400"
        ]
      },
      {
        "search": [
          "hamster"
        ],
        "name": "hamster",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jfyl6bob.png",
        "icon": "🐹",
        "shortcode": ":hamster:",
        "unicodes": [
          "u1f439"
        ]
      },
      {
        "search": [
          "rabbit",
          "face"
        ],
        "name": "rabbit_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nkqtoa7h.png",
        "icon": "🐰",
        "shortcode": ":rabbit_face:",
        "unicodes": [
          "u1f430"
        ]
      },
      {
        "search": [
          "rabbit"
        ],
        "name": "rabbit",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d4olk2hw.png",
        "icon": "🐇",
        "shortcode": ":rabbit:",
        "unicodes": [
          "u1f407"
        ]
      },
      {
        "search": [
          "chipmunk"
        ],
        "name": "chipmunk",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pxqsxl17.png",
        "icon": "🐿",
        "shortcode": ":chipmunk:",
        "unicodes": [
          "u1f43f"
        ]
      },
      {
        "search": [
          "beaver"
        ],
        "name": "beaver",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/l730pmbz.png",
        "icon": "🦫",
        "shortcode": ":beaver:",
        "unicodes": [
          "u1f9ab"
        ]
      },
      {
        "search": [
          "hedgehog"
        ],
        "name": "hedgehog",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jjj5i1b8.png",
        "icon": "🦔",
        "shortcode": ":hedgehog:",
        "unicodes": [
          "u1f994"
        ]
      },
      {
        "search": [
          "bat"
        ],
        "name": "bat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8wbt9d2q.png",
        "icon": "🦇",
        "shortcode": ":bat:",
        "unicodes": [
          "u1f987"
        ]
      },
      {
        "search": [
          "bear"
        ],
        "name": "bear",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f4t4xq6e.png",
        "icon": "🐻",
        "shortcode": ":bear:",
        "unicodes": [
          "u1f43b"
        ]
      },
      {
        "search": [
          "polar",
          "bear"
        ],
        "name": "polar_bear",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4wzseqyk.png",
        "icon": "🐻‍❄️",
        "shortcode": ":polar_bear:",
        "unicodes": [
          "u1f43b",
          "u200d",
          "u2744",
          "ufe0f"
        ]
      },
      {
        "search": [
          "koala"
        ],
        "name": "koala",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3huomj03.png",
        "icon": "🐨",
        "shortcode": ":koala:",
        "unicodes": [
          "u1f428"
        ]
      },
      {
        "search": [
          "panda"
        ],
        "name": "panda",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f0qvoroq.png",
        "icon": "🐼",
        "shortcode": ":panda:",
        "unicodes": [
          "u1f43c"
        ]
      },
      {
        "search": [
          "sloth"
        ],
        "name": "sloth",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/omnqmlyh.png",
        "icon": "🦥",
        "shortcode": ":sloth:",
        "unicodes": [
          "u1f9a5"
        ]
      },
      {
        "search": [
          "otter"
        ],
        "name": "otter",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tnmezq6p.png",
        "icon": "🦦",
        "shortcode": ":otter:",
        "unicodes": [
          "u1f9a6"
        ]
      },
      {
        "search": [
          "skunk"
        ],
        "name": "skunk",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3ra280ns.png",
        "icon": "🦨",
        "shortcode": ":skunk:",
        "unicodes": [
          "u1f9a8"
        ]
      },
      {
        "search": [
          "kangaroo"
        ],
        "name": "kangaroo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5lz6f8b1.png",
        "icon": "🦘",
        "shortcode": ":kangaroo:",
        "unicodes": [
          "u1f998"
        ]
      },
      {
        "search": [
          "badger"
        ],
        "name": "badger",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jyrad7m5.png",
        "icon": "🦡",
        "shortcode": ":badger:",
        "unicodes": [
          "u1f9a1"
        ]
      },
      {
        "search": [
          "paw",
          "prints"
        ],
        "name": "paw_prints",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i7t01kmx.png",
        "icon": "🐾",
        "shortcode": ":paw_prints:",
        "unicodes": [
          "u1f43e"
        ]
      },
      {
        "search": [
          "turkey"
        ],
        "name": "turkey",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qo95db54.png",
        "icon": "🦃",
        "shortcode": ":turkey:",
        "unicodes": [
          "u1f983"
        ]
      },
      {
        "search": [
          "chicken"
        ],
        "name": "chicken",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vf0h7mhx.png",
        "icon": "🐔",
        "shortcode": ":chicken:",
        "unicodes": [
          "u1f414"
        ]
      },
      {
        "search": [
          "rooster"
        ],
        "name": "rooster",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kthfj9kl.png",
        "icon": "🐓",
        "shortcode": ":rooster:",
        "unicodes": [
          "u1f413"
        ]
      },
      {
        "search": [
          "hatching",
          "chick"
        ],
        "name": "hatching_chick",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g9seibdf.png",
        "icon": "🐣",
        "shortcode": ":hatching_chick:",
        "unicodes": [
          "u1f423"
        ]
      },
      {
        "search": [
          "baby",
          "chick"
        ],
        "name": "baby_chick",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0xp1tvqw.png",
        "icon": "🐤",
        "shortcode": ":baby_chick:",
        "unicodes": [
          "u1f424"
        ]
      },
      {
        "search": [
          "front_facing",
          "baby",
          "chick"
        ],
        "name": "front_facing_baby_chick",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a0ac2x52.png",
        "icon": "🐥",
        "shortcode": ":front_facing_baby_chick:",
        "unicodes": [
          "u1f425"
        ]
      },
      {
        "search": [
          "bird"
        ],
        "name": "bird",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v1aaw3ra.png",
        "icon": "🐦",
        "shortcode": ":bird:",
        "unicodes": [
          "u1f426"
        ]
      },
      {
        "search": [
          "penguin"
        ],
        "name": "penguin",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/y2wfh80j.png",
        "icon": "🐧",
        "shortcode": ":penguin:",
        "unicodes": [
          "u1f427"
        ]
      },
      {
        "search": [
          "dove"
        ],
        "name": "dove",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ougndus5.png",
        "icon": "🕊",
        "shortcode": ":dove:",
        "unicodes": [
          "u1f54a"
        ]
      },
      {
        "search": [
          "eagle"
        ],
        "name": "eagle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ksfukvdn.png",
        "icon": "🦅",
        "shortcode": ":eagle:",
        "unicodes": [
          "u1f985"
        ]
      },
      {
        "search": [
          "duck"
        ],
        "name": "duck",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zq6jf5yg.png",
        "icon": "🦆",
        "shortcode": ":duck:",
        "unicodes": [
          "u1f986"
        ]
      },
      {
        "search": [
          "swan"
        ],
        "name": "swan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/olf2slhy.png",
        "icon": "🦢",
        "shortcode": ":swan:",
        "unicodes": [
          "u1f9a2"
        ]
      },
      {
        "search": [
          "owl"
        ],
        "name": "owl",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ibb73jh2.png",
        "icon": "🦉",
        "shortcode": ":owl:",
        "unicodes": [
          "u1f989"
        ]
      },
      {
        "search": [
          "dodo"
        ],
        "name": "dodo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/axkm67g3.png",
        "icon": "🦤",
        "shortcode": ":dodo:",
        "unicodes": [
          "u1f9a4"
        ]
      },
      {
        "search": [
          "feather"
        ],
        "name": "feather",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pn9v9rj4.png",
        "icon": "🪶",
        "shortcode": ":feather:",
        "unicodes": [
          "u1fab6"
        ]
      },
      {
        "search": [
          "flamingo"
        ],
        "name": "flamingo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/k6j22v2w.png",
        "icon": "🦩",
        "shortcode": ":flamingo:",
        "unicodes": [
          "u1f9a9"
        ]
      },
      {
        "search": [
          "peacock"
        ],
        "name": "peacock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vahl0165.png",
        "icon": "🦚",
        "shortcode": ":peacock:",
        "unicodes": [
          "u1f99a"
        ]
      },
      {
        "search": [
          "parrot"
        ],
        "name": "parrot",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mrnoazdu.png",
        "icon": "🦜",
        "shortcode": ":parrot:",
        "unicodes": [
          "u1f99c"
        ]
      },
      {
        "search": [
          "frog"
        ],
        "name": "frog",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/x1jli9wr.png",
        "icon": "🐸",
        "shortcode": ":frog:",
        "unicodes": [
          "u1f438"
        ]
      },
      {
        "search": [
          "crocodile"
        ],
        "name": "crocodile",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/paiab5dd.png",
        "icon": "🐊",
        "shortcode": ":crocodile:",
        "unicodes": [
          "u1f40a"
        ]
      },
      {
        "search": [
          "turtle"
        ],
        "name": "turtle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3zye2sp8.png",
        "icon": "🐢",
        "shortcode": ":turtle:",
        "unicodes": [
          "u1f422"
        ]
      },
      {
        "search": [
          "lizard"
        ],
        "name": "lizard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d9j1luaj.png",
        "icon": "🦎",
        "shortcode": ":lizard:",
        "unicodes": [
          "u1f98e"
        ]
      },
      {
        "search": [
          "snake"
        ],
        "name": "snake",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i0gc0jb9.png",
        "icon": "🐍",
        "shortcode": ":snake:",
        "unicodes": [
          "u1f40d"
        ]
      },
      {
        "search": [
          "dragon",
          "face"
        ],
        "name": "dragon_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ikn2n41i.png",
        "icon": "🐲",
        "shortcode": ":dragon_face:",
        "unicodes": [
          "u1f432"
        ]
      },
      {
        "search": [
          "dragon"
        ],
        "name": "dragon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/otskmhk5.png",
        "icon": "🐉",
        "shortcode": ":dragon:",
        "unicodes": [
          "u1f409"
        ]
      },
      {
        "search": [
          "sauropod"
        ],
        "name": "sauropod",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/p61twl7x.png",
        "icon": "🦕",
        "shortcode": ":sauropod:",
        "unicodes": [
          "u1f995"
        ]
      },
      {
        "search": [
          "t_rex"
        ],
        "name": "t_rex",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rv6qqo1f.png",
        "icon": "🦖",
        "shortcode": ":t_rex:",
        "unicodes": [
          "u1f996"
        ]
      },
      {
        "search": [
          "spouting",
          "whale"
        ],
        "name": "spouting_whale",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/q73nbhxt.png",
        "icon": "🐳",
        "shortcode": ":spouting_whale:",
        "unicodes": [
          "u1f433"
        ]
      },
      {
        "search": [
          "whale"
        ],
        "name": "whale",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ivvz7gv3.png",
        "icon": "🐋",
        "shortcode": ":whale:",
        "unicodes": [
          "u1f40b"
        ]
      },
      {
        "search": [
          "dolphin"
        ],
        "name": "dolphin",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e94uxm6a.png",
        "icon": "🐬",
        "shortcode": ":dolphin:",
        "unicodes": [
          "u1f42c"
        ]
      },
      {
        "search": [
          "seal"
        ],
        "name": "seal",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h79kbg2n.png",
        "icon": "🦭",
        "shortcode": ":seal:",
        "unicodes": [
          "u1f9ad"
        ]
      },
      {
        "search": [
          "fish"
        ],
        "name": "fish",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ua91lvl5.png",
        "icon": "🐟",
        "shortcode": ":fish:",
        "unicodes": [
          "u1f41f"
        ]
      },
      {
        "search": [
          "tropical",
          "fish"
        ],
        "name": "tropical_fish",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/itdaymzl.png",
        "icon": "🐠",
        "shortcode": ":tropical_fish:",
        "unicodes": [
          "u1f420"
        ]
      },
      {
        "search": [
          "blowfish"
        ],
        "name": "blowfish",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0x8a8ltk.png",
        "icon": "🐡",
        "shortcode": ":blowfish:",
        "unicodes": [
          "u1f421"
        ]
      },
      {
        "search": [
          "shark"
        ],
        "name": "shark",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/u7qznt9w.png",
        "icon": "🦈",
        "shortcode": ":shark:",
        "unicodes": [
          "u1f988"
        ]
      },
      {
        "search": [
          "octopus"
        ],
        "name": "octopus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nw8l4om4.png",
        "icon": "🐙",
        "shortcode": ":octopus:",
        "unicodes": [
          "u1f419"
        ]
      },
      {
        "search": [
          "spiral",
          "shell"
        ],
        "name": "spiral_shell",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3oerfcr4.png",
        "icon": "🐚",
        "shortcode": ":spiral_shell:",
        "unicodes": [
          "u1f41a"
        ]
      },
      {
        "search": [
          "snail"
        ],
        "name": "snail",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/u7262dmg.png",
        "icon": "🐌",
        "shortcode": ":snail:",
        "unicodes": [
          "u1f40c"
        ]
      },
      {
        "search": [
          "butterfly"
        ],
        "name": "butterfly",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6wugjkjp.png",
        "icon": "🦋",
        "shortcode": ":butterfly:",
        "unicodes": [
          "u1f98b"
        ]
      },
      {
        "search": [
          "bug"
        ],
        "name": "bug",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a9lssryw.png",
        "icon": "🐛",
        "shortcode": ":bug:",
        "unicodes": [
          "u1f41b"
        ]
      },
      {
        "search": [
          "ant"
        ],
        "name": "ant",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6tha2xrk.png",
        "icon": "🐜",
        "shortcode": ":ant:",
        "unicodes": [
          "u1f41c"
        ]
      },
      {
        "search": [
          "honeybee"
        ],
        "name": "honeybee",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9yuweifx.png",
        "icon": "🐝",
        "shortcode": ":honeybee:",
        "unicodes": [
          "u1f41d"
        ]
      },
      {
        "search": [
          "beetle"
        ],
        "name": "beetle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/exu975wm.png",
        "icon": "🪲",
        "shortcode": ":beetle:",
        "unicodes": [
          "u1fab2"
        ]
      },
      {
        "search": [
          "lady",
          "beetle"
        ],
        "name": "lady_beetle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fxno7d7z.png",
        "icon": "🐞",
        "shortcode": ":lady_beetle:",
        "unicodes": [
          "u1f41e"
        ]
      },
      {
        "search": [
          "cricket"
        ],
        "name": "cricket",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/le70bg64.png",
        "icon": "🦗",
        "shortcode": ":cricket:",
        "unicodes": [
          "u1f997"
        ]
      },
      {
        "search": [
          "cockroach"
        ],
        "name": "cockroach",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gjxsf809.png",
        "icon": "🪳",
        "shortcode": ":cockroach:",
        "unicodes": [
          "u1fab3"
        ]
      },
      {
        "search": [
          "spider"
        ],
        "name": "spider",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/df6ktkwg.png",
        "icon": "🕷",
        "shortcode": ":spider:",
        "unicodes": [
          "u1f577"
        ]
      },
      {
        "search": [
          "spider",
          "web"
        ],
        "name": "spider_web",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zg737yk9.png",
        "icon": "🕸",
        "shortcode": ":spider_web:",
        "unicodes": [
          "u1f578"
        ]
      },
      {
        "search": [
          "scorpion"
        ],
        "name": "scorpion",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2bvc9llu.png",
        "icon": "🦂",
        "shortcode": ":scorpion:",
        "unicodes": [
          "u1f982"
        ]
      },
      {
        "search": [
          "mosquito"
        ],
        "name": "mosquito",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zive3psi.png",
        "icon": "🦟",
        "shortcode": ":mosquito:",
        "unicodes": [
          "u1f99f"
        ]
      },
      {
        "search": [
          "fly"
        ],
        "name": "fly",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kmicuhez.png",
        "icon": "🪰",
        "shortcode": ":fly:",
        "unicodes": [
          "u1fab0"
        ]
      },
      {
        "search": [
          "worm"
        ],
        "name": "worm",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mgfghqs9.png",
        "icon": "🪱",
        "shortcode": ":worm:",
        "unicodes": [
          "u1fab1"
        ]
      },
      {
        "search": [
          "microbe"
        ],
        "name": "microbe",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b7ju4ts2.png",
        "icon": "🦠",
        "shortcode": ":microbe:",
        "unicodes": [
          "u1f9a0"
        ]
      },
      {
        "search": [
          "bouquet"
        ],
        "name": "bouquet",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/613u5ad2.png",
        "icon": "💐",
        "shortcode": ":bouquet:",
        "unicodes": [
          "u1f490"
        ]
      },
      {
        "search": [
          "cherry",
          "blossom"
        ],
        "name": "cherry_blossom",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/on4eouj2.png",
        "icon": "🌸",
        "shortcode": ":cherry_blossom:",
        "unicodes": [
          "u1f338"
        ]
      },
      {
        "search": [
          "white",
          "flower"
        ],
        "name": "white_flower",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rkucx2jt.png",
        "icon": "💮",
        "shortcode": ":white_flower:",
        "unicodes": [
          "u1f4ae"
        ]
      },
      {
        "search": [
          "lotus"
        ],
        "name": "lotus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mcn8d51j.png",
        "icon": "🪷",
        "shortcode": ":lotus:",
        "unicodes": [
          "u1fab7"
        ]
      },
      {
        "search": [
          "rosette"
        ],
        "name": "rosette",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ehpnumog.png",
        "icon": "🏵",
        "shortcode": ":rosette:",
        "unicodes": [
          "u1f3f5"
        ]
      },
      {
        "search": [
          "rose"
        ],
        "name": "rose",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yefbnhhr.png",
        "icon": "🌹",
        "shortcode": ":rose:",
        "unicodes": [
          "u1f339"
        ]
      },
      {
        "search": [
          "wilted",
          "flower"
        ],
        "name": "wilted_flower",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/z3usp2dt.png",
        "icon": "🥀",
        "shortcode": ":wilted_flower:",
        "unicodes": [
          "u1f940"
        ]
      },
      {
        "search": [
          "hibiscus"
        ],
        "name": "hibiscus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3njvicpy.png",
        "icon": "🌺",
        "shortcode": ":hibiscus:",
        "unicodes": [
          "u1f33a"
        ]
      },
      {
        "search": [
          "sunflower"
        ],
        "name": "sunflower",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tj4m1kw6.png",
        "icon": "🌻",
        "shortcode": ":sunflower:",
        "unicodes": [
          "u1f33b"
        ]
      },
      {
        "search": [
          "blossom"
        ],
        "name": "blossom",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0i8itaky.png",
        "icon": "🌼",
        "shortcode": ":blossom:",
        "unicodes": [
          "u1f33c"
        ]
      },
      {
        "search": [
          "tulip"
        ],
        "name": "tulip",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/71i9xqi6.png",
        "icon": "🌷",
        "shortcode": ":tulip:",
        "unicodes": [
          "u1f337"
        ]
      },
      {
        "search": [
          "seedling"
        ],
        "name": "seedling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ez5s6e2m.png",
        "icon": "🌱",
        "shortcode": ":seedling:",
        "unicodes": [
          "u1f331"
        ]
      },
      {
        "search": [
          "potted",
          "plant"
        ],
        "name": "potted_plant",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/eukk1al7.png",
        "icon": "🪴",
        "shortcode": ":potted_plant:",
        "unicodes": [
          "u1fab4"
        ]
      },
      {
        "search": [
          "evergreen",
          "tree"
        ],
        "name": "evergreen_tree",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hkusczsu.png",
        "icon": "🌲",
        "shortcode": ":evergreen_tree:",
        "unicodes": [
          "u1f332"
        ]
      },
      {
        "search": [
          "deciduous",
          "tree"
        ],
        "name": "deciduous_tree",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1z0s19iz.png",
        "icon": "🌳",
        "shortcode": ":deciduous_tree:",
        "unicodes": [
          "u1f333"
        ]
      },
      {
        "search": [
          "palm",
          "tree"
        ],
        "name": "palm_tree",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/th26pjno.png",
        "icon": "🌴",
        "shortcode": ":palm_tree:",
        "unicodes": [
          "u1f334"
        ]
      },
      {
        "search": [
          "cactus"
        ],
        "name": "cactus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/owvmogjt.png",
        "icon": "🌵",
        "shortcode": ":cactus:",
        "unicodes": [
          "u1f335"
        ]
      },
      {
        "search": [
          "sheaf",
          "of",
          "rice"
        ],
        "name": "sheaf_of_rice",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lxejov5d.png",
        "icon": "🌾",
        "shortcode": ":sheaf_of_rice:",
        "unicodes": [
          "u1f33e"
        ]
      },
      {
        "search": [
          "herb"
        ],
        "name": "herb",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zip12m4l.png",
        "icon": "🌿",
        "shortcode": ":herb:",
        "unicodes": [
          "u1f33f"
        ]
      },
      {
        "search": [
          "shamrock"
        ],
        "name": "shamrock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mh116a5j.png",
        "icon": "☘",
        "shortcode": ":shamrock:",
        "unicodes": [
          "u2618"
        ]
      },
      {
        "search": [
          "four",
          "leaf",
          "clover"
        ],
        "name": "four_leaf_clover",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ivgjd45j.png",
        "icon": "🍀",
        "shortcode": ":four_leaf_clover:",
        "unicodes": [
          "u1f340"
        ]
      },
      {
        "search": [
          "maple",
          "leaf"
        ],
        "name": "maple_leaf",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wn9sx1kc.png",
        "icon": "🍁",
        "shortcode": ":maple_leaf:",
        "unicodes": [
          "u1f341"
        ]
      },
      {
        "search": [
          "fallen",
          "leaf"
        ],
        "name": "fallen_leaf",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rer5i2dz.png",
        "icon": "🍂",
        "shortcode": ":fallen_leaf:",
        "unicodes": [
          "u1f342"
        ]
      },
      {
        "search": [
          "leaf",
          "fluttering",
          "in",
          "wind"
        ],
        "name": "leaf_fluttering_in_wind",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ikirxlrn.png",
        "icon": "🍃",
        "shortcode": ":leaf_fluttering_in_wind:",
        "unicodes": [
          "u1f343"
        ]
      },
      {
        "search": [
          "empty",
          "nest"
        ],
        "name": "empty_nest",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yuvufsmg.png",
        "icon": "🪹",
        "shortcode": ":empty_nest:",
        "unicodes": [
          "u1fab9"
        ]
      },
      {
        "search": [
          "nest",
          "with",
          "eggs"
        ],
        "name": "nest_with_eggs",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9t7dxkcz.png",
        "icon": "🪺",
        "shortcode": ":nest_with_eggs:",
        "unicodes": [
          "u1faba"
        ]
      },
      {
        "search": [
          "mushroom"
        ],
        "name": "mushroom",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a41ebg0u.png",
        "icon": "🍄",
        "shortcode": ":mushroom:",
        "unicodes": [
          "u1f344"
        ]
      }
    ]
  },
  {
    "category": "food_and_drink",
    "text": "Food & Drink",
    "icons": [
      {
        "search": [
          "grapes"
        ],
        "name": "grapes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mw37pxnj.png",
        "icon": "🍇",
        "shortcode": ":grapes:",
        "unicodes": [
          "u1f347"
        ]
      },
      {
        "search": [
          "melon"
        ],
        "name": "melon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v3v3s400.png",
        "icon": "🍈",
        "shortcode": ":melon:",
        "unicodes": [
          "u1f348"
        ]
      },
      {
        "search": [
          "watermelon"
        ],
        "name": "watermelon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/37oods8c.png",
        "icon": "🍉",
        "shortcode": ":watermelon:",
        "unicodes": [
          "u1f349"
        ]
      },
      {
        "search": [
          "tangerine"
        ],
        "name": "tangerine",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zfk2cte7.png",
        "icon": "🍊",
        "shortcode": ":tangerine:",
        "unicodes": [
          "u1f34a"
        ]
      },
      {
        "search": [
          "lemon"
        ],
        "name": "lemon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5jzw3l44.png",
        "icon": "🍋",
        "shortcode": ":lemon:",
        "unicodes": [
          "u1f34b"
        ]
      },
      {
        "search": [
          "banana"
        ],
        "name": "banana",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d01z9ozo.png",
        "icon": "🍌",
        "shortcode": ":banana:",
        "unicodes": [
          "u1f34c"
        ]
      },
      {
        "search": [
          "pineapple"
        ],
        "name": "pineapple",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iv2rtoiz.png",
        "icon": "🍍",
        "shortcode": ":pineapple:",
        "unicodes": [
          "u1f34d"
        ]
      },
      {
        "search": [
          "mango"
        ],
        "name": "mango",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rcztex25.png",
        "icon": "🥭",
        "shortcode": ":mango:",
        "unicodes": [
          "u1f96d"
        ]
      },
      {
        "search": [
          "red",
          "apple"
        ],
        "name": "red_apple",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/92vvtke6.png",
        "icon": "🍎",
        "shortcode": ":red_apple:",
        "unicodes": [
          "u1f34e"
        ]
      },
      {
        "search": [
          "green",
          "apple"
        ],
        "name": "green_apple",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/u62k7gdf.png",
        "icon": "🍏",
        "shortcode": ":green_apple:",
        "unicodes": [
          "u1f34f"
        ]
      },
      {
        "search": [
          "pear"
        ],
        "name": "pear",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/55ij40wp.png",
        "icon": "🍐",
        "shortcode": ":pear:",
        "unicodes": [
          "u1f350"
        ]
      },
      {
        "search": [
          "peach"
        ],
        "name": "peach",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5qs6mqeu.png",
        "icon": "🍑",
        "shortcode": ":peach:",
        "unicodes": [
          "u1f351"
        ]
      },
      {
        "search": [
          "cherries"
        ],
        "name": "cherries",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0kfv32j4.png",
        "icon": "🍒",
        "shortcode": ":cherries:",
        "unicodes": [
          "u1f352"
        ]
      },
      {
        "search": [
          "strawberry"
        ],
        "name": "strawberry",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tdwodcg3.png",
        "icon": "🍓",
        "shortcode": ":strawberry:",
        "unicodes": [
          "u1f353"
        ]
      },
      {
        "search": [
          "blueberries"
        ],
        "name": "blueberries",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cc378mz5.png",
        "icon": "🫐",
        "shortcode": ":blueberries:",
        "unicodes": [
          "u1fad0"
        ]
      },
      {
        "search": [
          "kiwi",
          "fruit"
        ],
        "name": "kiwi_fruit",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bc3rwnmg.png",
        "icon": "🥝",
        "shortcode": ":kiwi_fruit:",
        "unicodes": [
          "u1f95d"
        ]
      },
      {
        "search": [
          "tomato"
        ],
        "name": "tomato",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/blpl1o8s.png",
        "icon": "🍅",
        "shortcode": ":tomato:",
        "unicodes": [
          "u1f345"
        ]
      },
      {
        "search": [
          "olive"
        ],
        "name": "olive",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9a25k2wl.png",
        "icon": "🫒",
        "shortcode": ":olive:",
        "unicodes": [
          "u1fad2"
        ]
      },
      {
        "search": [
          "coconut"
        ],
        "name": "coconut",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ffa9dhxi.png",
        "icon": "🥥",
        "shortcode": ":coconut:",
        "unicodes": [
          "u1f965"
        ]
      },
      {
        "search": [
          "avocado"
        ],
        "name": "avocado",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/efv05k50.png",
        "icon": "🥑",
        "shortcode": ":avocado:",
        "unicodes": [
          "u1f951"
        ]
      },
      {
        "search": [
          "eggplant"
        ],
        "name": "eggplant",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a7gks345.png",
        "icon": "🍆",
        "shortcode": ":eggplant:",
        "unicodes": [
          "u1f346"
        ]
      },
      {
        "search": [
          "potato"
        ],
        "name": "potato",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hldkafm0.png",
        "icon": "🥔",
        "shortcode": ":potato:",
        "unicodes": [
          "u1f954"
        ]
      },
      {
        "search": [
          "carrot"
        ],
        "name": "carrot",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/y30p4i9h.png",
        "icon": "🥕",
        "shortcode": ":carrot:",
        "unicodes": [
          "u1f955"
        ]
      },
      {
        "search": [
          "ear",
          "of",
          "corn"
        ],
        "name": "ear_of_corn",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kuh1eqvl.png",
        "icon": "🌽",
        "shortcode": ":ear_of_corn:",
        "unicodes": [
          "u1f33d"
        ]
      },
      {
        "search": [
          "hot",
          "pepper"
        ],
        "name": "hot_pepper",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mq17wdtn.png",
        "icon": "🌶",
        "shortcode": ":hot_pepper:",
        "unicodes": [
          "u1f336"
        ]
      },
      {
        "search": [
          "bell",
          "pepper"
        ],
        "name": "bell_pepper",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sag7hte9.png",
        "icon": "🫑",
        "shortcode": ":bell_pepper:",
        "unicodes": [
          "u1fad1"
        ]
      },
      {
        "search": [
          "cucumber"
        ],
        "name": "cucumber",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/npf1m5gg.png",
        "icon": "🥒",
        "shortcode": ":cucumber:",
        "unicodes": [
          "u1f952"
        ]
      },
      {
        "search": [
          "leafy",
          "green"
        ],
        "name": "leafy_green",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yro7itik.png",
        "icon": "🥬",
        "shortcode": ":leafy_green:",
        "unicodes": [
          "u1f96c"
        ]
      },
      {
        "search": [
          "broccoli"
        ],
        "name": "broccoli",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/40ukkz6o.png",
        "icon": "🥦",
        "shortcode": ":broccoli:",
        "unicodes": [
          "u1f966"
        ]
      },
      {
        "search": [
          "garlic"
        ],
        "name": "garlic",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/t2l4gd50.png",
        "icon": "🧄",
        "shortcode": ":garlic:",
        "unicodes": [
          "u1f9c4"
        ]
      },
      {
        "search": [
          "onion"
        ],
        "name": "onion",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g51hneww.png",
        "icon": "🧅",
        "shortcode": ":onion:",
        "unicodes": [
          "u1f9c5"
        ]
      },
      {
        "search": [
          "peanuts"
        ],
        "name": "peanuts",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wkxfdhk1.png",
        "icon": "🥜",
        "shortcode": ":peanuts:",
        "unicodes": [
          "u1f95c"
        ]
      },
      {
        "search": [
          "beans"
        ],
        "name": "beans",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mwkanj9b.png",
        "icon": "🫘",
        "shortcode": ":beans:",
        "unicodes": [
          "u1fad8"
        ]
      },
      {
        "search": [
          "chestnut"
        ],
        "name": "chestnut",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/azjfeyev.png",
        "icon": "🌰",
        "shortcode": ":chestnut:",
        "unicodes": [
          "u1f330"
        ]
      },
      {
        "search": [
          "bread"
        ],
        "name": "bread",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iyiqxxz0.png",
        "icon": "🍞",
        "shortcode": ":bread:",
        "unicodes": [
          "u1f35e"
        ]
      },
      {
        "search": [
          "croissant"
        ],
        "name": "croissant",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9qfsk1ty.png",
        "icon": "🥐",
        "shortcode": ":croissant:",
        "unicodes": [
          "u1f950"
        ]
      },
      {
        "search": [
          "baguette",
          "bread"
        ],
        "name": "baguette_bread",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vku4rvvy.png",
        "icon": "🥖",
        "shortcode": ":baguette_bread:",
        "unicodes": [
          "u1f956"
        ]
      },
      {
        "search": [
          "flatbread"
        ],
        "name": "flatbread",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1b2oy1nj.png",
        "icon": "🫓",
        "shortcode": ":flatbread:",
        "unicodes": [
          "u1fad3"
        ]
      },
      {
        "search": [
          "pretzel"
        ],
        "name": "pretzel",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d53xp5as.png",
        "icon": "🥨",
        "shortcode": ":pretzel:",
        "unicodes": [
          "u1f968"
        ]
      },
      {
        "search": [
          "bagel"
        ],
        "name": "bagel",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cmfa982x.png",
        "icon": "🥯",
        "shortcode": ":bagel:",
        "unicodes": [
          "u1f96f"
        ]
      },
      {
        "search": [
          "pancakes"
        ],
        "name": "pancakes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/x5ses6aj.png",
        "icon": "🥞",
        "shortcode": ":pancakes:",
        "unicodes": [
          "u1f95e"
        ]
      },
      {
        "search": [
          "waffle"
        ],
        "name": "waffle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1i2m6m7n.png",
        "icon": "🧇",
        "shortcode": ":waffle:",
        "unicodes": [
          "u1f9c7"
        ]
      },
      {
        "search": [
          "cheese",
          "wedge"
        ],
        "name": "cheese_wedge",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ltcjgm3c.png",
        "icon": "🧀",
        "shortcode": ":cheese_wedge:",
        "unicodes": [
          "u1f9c0"
        ]
      },
      {
        "search": [
          "meat",
          "on",
          "bone"
        ],
        "name": "meat_on_bone",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nznn2so4.png",
        "icon": "🍖",
        "shortcode": ":meat_on_bone:",
        "unicodes": [
          "u1f356"
        ]
      },
      {
        "search": [
          "poultry",
          "leg"
        ],
        "name": "poultry_leg",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kqxrqwip.png",
        "icon": "🍗",
        "shortcode": ":poultry_leg:",
        "unicodes": [
          "u1f357"
        ]
      },
      {
        "search": [
          "cut",
          "of",
          "meat"
        ],
        "name": "cut_of_meat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dvagaqw0.png",
        "icon": "🥩",
        "shortcode": ":cut_of_meat:",
        "unicodes": [
          "u1f969"
        ]
      },
      {
        "search": [
          "bacon"
        ],
        "name": "bacon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jn6u7r8h.png",
        "icon": "🥓",
        "shortcode": ":bacon:",
        "unicodes": [
          "u1f953"
        ]
      },
      {
        "search": [
          "hamburger"
        ],
        "name": "hamburger",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0imv810c.png",
        "icon": "🍔",
        "shortcode": ":hamburger:",
        "unicodes": [
          "u1f354"
        ]
      },
      {
        "search": [
          "french",
          "fries"
        ],
        "name": "french_fries",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v3l0slb8.png",
        "icon": "🍟",
        "shortcode": ":french_fries:",
        "unicodes": [
          "u1f35f"
        ]
      },
      {
        "search": [
          "pizza"
        ],
        "name": "pizza",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7ggkst9d.png",
        "icon": "🍕",
        "shortcode": ":pizza:",
        "unicodes": [
          "u1f355"
        ]
      },
      {
        "search": [
          "hot",
          "dog"
        ],
        "name": "hot_dog",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/t67ey2tg.png",
        "icon": "🌭",
        "shortcode": ":hot_dog:",
        "unicodes": [
          "u1f32d"
        ]
      },
      {
        "search": [
          "sandwich"
        ],
        "name": "sandwich",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qemkt5tq.png",
        "icon": "🥪",
        "shortcode": ":sandwich:",
        "unicodes": [
          "u1f96a"
        ]
      },
      {
        "search": [
          "taco"
        ],
        "name": "taco",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0ifd87n8.png",
        "icon": "🌮",
        "shortcode": ":taco:",
        "unicodes": [
          "u1f32e"
        ]
      },
      {
        "search": [
          "burrito"
        ],
        "name": "burrito",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nc5n3qte.png",
        "icon": "🌯",
        "shortcode": ":burrito:",
        "unicodes": [
          "u1f32f"
        ]
      },
      {
        "search": [
          "tamale"
        ],
        "name": "tamale",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/t4iuxtfl.png",
        "icon": "🫔",
        "shortcode": ":tamale:",
        "unicodes": [
          "u1fad4"
        ]
      },
      {
        "search": [
          "stuffed",
          "flatbread"
        ],
        "name": "stuffed_flatbread",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pxqkfovk.png",
        "icon": "🥙",
        "shortcode": ":stuffed_flatbread:",
        "unicodes": [
          "u1f959"
        ]
      },
      {
        "search": [
          "falafel"
        ],
        "name": "falafel",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/r9d186vl.png",
        "icon": "🧆",
        "shortcode": ":falafel:",
        "unicodes": [
          "u1f9c6"
        ]
      },
      {
        "search": [
          "egg"
        ],
        "name": "egg",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mg8hlmu7.png",
        "icon": "🥚",
        "shortcode": ":egg:",
        "unicodes": [
          "u1f95a"
        ]
      },
      {
        "search": [
          "cooking"
        ],
        "name": "cooking",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0ma0u2o1.png",
        "icon": "🍳",
        "shortcode": ":cooking:",
        "unicodes": [
          "u1f373"
        ]
      },
      {
        "search": [
          "shallow",
          "pan",
          "of",
          "food"
        ],
        "name": "shallow_pan_of_food",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ocapr2lg.png",
        "icon": "🥘",
        "shortcode": ":shallow_pan_of_food:",
        "unicodes": [
          "u1f958"
        ]
      },
      {
        "search": [
          "pot",
          "of",
          "food"
        ],
        "name": "pot_of_food",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ae4qnk21.png",
        "icon": "🍲",
        "shortcode": ":pot_of_food:",
        "unicodes": [
          "u1f372"
        ]
      },
      {
        "search": [
          "fondue"
        ],
        "name": "fondue",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9mhlzy3j.png",
        "icon": "🫕",
        "shortcode": ":fondue:",
        "unicodes": [
          "u1fad5"
        ]
      },
      {
        "search": [
          "bowl",
          "with",
          "spoon"
        ],
        "name": "bowl_with_spoon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cbihrly6.png",
        "icon": "🥣",
        "shortcode": ":bowl_with_spoon:",
        "unicodes": [
          "u1f963"
        ]
      },
      {
        "search": [
          "green",
          "salad"
        ],
        "name": "green_salad",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g4d27ima.png",
        "icon": "🥗",
        "shortcode": ":green_salad:",
        "unicodes": [
          "u1f957"
        ]
      },
      {
        "search": [
          "popcorn"
        ],
        "name": "popcorn",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5qtsc3xa.png",
        "icon": "🍿",
        "shortcode": ":popcorn:",
        "unicodes": [
          "u1f37f"
        ]
      },
      {
        "search": [
          "butter"
        ],
        "name": "butter",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1bevkj1h.png",
        "icon": "🧈",
        "shortcode": ":butter:",
        "unicodes": [
          "u1f9c8"
        ]
      },
      {
        "search": [
          "salt"
        ],
        "name": "salt",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5uvdudcn.png",
        "icon": "🧂",
        "shortcode": ":salt:",
        "unicodes": [
          "u1f9c2"
        ]
      },
      {
        "search": [
          "canned",
          "food"
        ],
        "name": "canned_food",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g2i8a7w0.png",
        "icon": "🥫",
        "shortcode": ":canned_food:",
        "unicodes": [
          "u1f96b"
        ]
      },
      {
        "search": [
          "bento",
          "box"
        ],
        "name": "bento_box",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9z4kkh6w.png",
        "icon": "🍱",
        "shortcode": ":bento_box:",
        "unicodes": [
          "u1f371"
        ]
      },
      {
        "search": [
          "rice",
          "cracker"
        ],
        "name": "rice_cracker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lpofc6cz.png",
        "icon": "🍘",
        "shortcode": ":rice_cracker:",
        "unicodes": [
          "u1f358"
        ]
      },
      {
        "search": [
          "rice",
          "ball"
        ],
        "name": "rice_ball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0tfu03l0.png",
        "icon": "🍙",
        "shortcode": ":rice_ball:",
        "unicodes": [
          "u1f359"
        ]
      },
      {
        "search": [
          "cooked",
          "rice"
        ],
        "name": "cooked_rice",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hy38zer9.png",
        "icon": "🍚",
        "shortcode": ":cooked_rice:",
        "unicodes": [
          "u1f35a"
        ]
      },
      {
        "search": [
          "curry",
          "rice"
        ],
        "name": "curry_rice",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hjg0t1dn.png",
        "icon": "🍛",
        "shortcode": ":curry_rice:",
        "unicodes": [
          "u1f35b"
        ]
      },
      {
        "search": [
          "steaming",
          "bowl"
        ],
        "name": "steaming_bowl",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bqcctaon.png",
        "icon": "🍜",
        "shortcode": ":steaming_bowl:",
        "unicodes": [
          "u1f35c"
        ]
      },
      {
        "search": [
          "spaghetti"
        ],
        "name": "spaghetti",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7vg6cewd.png",
        "icon": "🍝",
        "shortcode": ":spaghetti:",
        "unicodes": [
          "u1f35d"
        ]
      },
      {
        "search": [
          "roasted",
          "sweet",
          "potato"
        ],
        "name": "roasted_sweet_potato",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fmgu6mk8.png",
        "icon": "🍠",
        "shortcode": ":roasted_sweet_potato:",
        "unicodes": [
          "u1f360"
        ]
      },
      {
        "search": [
          "oden"
        ],
        "name": "oden",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9v1ae1dd.png",
        "icon": "🍢",
        "shortcode": ":oden:",
        "unicodes": [
          "u1f362"
        ]
      },
      {
        "search": [
          "sushi"
        ],
        "name": "sushi",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cj5bxyx4.png",
        "icon": "🍣",
        "shortcode": ":sushi:",
        "unicodes": [
          "u1f363"
        ]
      },
      {
        "search": [
          "fried",
          "shrimp"
        ],
        "name": "fried_shrimp",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vb93adm2.png",
        "icon": "🍤",
        "shortcode": ":fried_shrimp:",
        "unicodes": [
          "u1f364"
        ]
      },
      {
        "search": [
          "fish",
          "cake",
          "with",
          "swirl"
        ],
        "name": "fish_cake_with_swirl",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/udxm3wvr.png",
        "icon": "🍥",
        "shortcode": ":fish_cake_with_swirl:",
        "unicodes": [
          "u1f365"
        ]
      },
      {
        "search": [
          "moon",
          "cake"
        ],
        "name": "moon_cake",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1kf3vpkt.png",
        "icon": "🥮",
        "shortcode": ":moon_cake:",
        "unicodes": [
          "u1f96e"
        ]
      },
      {
        "search": [
          "dango"
        ],
        "name": "dango",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sws05phe.png",
        "icon": "🍡",
        "shortcode": ":dango:",
        "unicodes": [
          "u1f361"
        ]
      },
      {
        "search": [
          "dumpling"
        ],
        "name": "dumpling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6mjxolny.png",
        "icon": "🥟",
        "shortcode": ":dumpling:",
        "unicodes": [
          "u1f95f"
        ]
      },
      {
        "search": [
          "fortune",
          "cookie"
        ],
        "name": "fortune_cookie",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xz9pawds.png",
        "icon": "🥠",
        "shortcode": ":fortune_cookie:",
        "unicodes": [
          "u1f960"
        ]
      },
      {
        "search": [
          "takeout",
          "box"
        ],
        "name": "takeout_box",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mnx05cby.png",
        "icon": "🥡",
        "shortcode": ":takeout_box:",
        "unicodes": [
          "u1f961"
        ]
      },
      {
        "search": [
          "crab"
        ],
        "name": "crab",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wimkhcty.png",
        "icon": "🦀",
        "shortcode": ":crab:",
        "unicodes": [
          "u1f980"
        ]
      },
      {
        "search": [
          "lobster"
        ],
        "name": "lobster",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uqexllzg.png",
        "icon": "🦞",
        "shortcode": ":lobster:",
        "unicodes": [
          "u1f99e"
        ]
      },
      {
        "search": [
          "shrimp"
        ],
        "name": "shrimp",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hyw6fntl.png",
        "icon": "🦐",
        "shortcode": ":shrimp:",
        "unicodes": [
          "u1f990"
        ]
      },
      {
        "search": [
          "squid"
        ],
        "name": "squid",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xc2jfm1c.png",
        "icon": "🦑",
        "shortcode": ":squid:",
        "unicodes": [
          "u1f991"
        ]
      },
      {
        "search": [
          "oyster"
        ],
        "name": "oyster",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/loemjrgv.png",
        "icon": "🦪",
        "shortcode": ":oyster:",
        "unicodes": [
          "u1f9aa"
        ]
      },
      {
        "search": [
          "soft",
          "ice",
          "cream"
        ],
        "name": "soft_ice_cream",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/z3l6j8pc.png",
        "icon": "🍦",
        "shortcode": ":soft_ice_cream:",
        "unicodes": [
          "u1f366"
        ]
      },
      {
        "search": [
          "shaved",
          "ice"
        ],
        "name": "shaved_ice",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6lm7lb2j.png",
        "icon": "🍧",
        "shortcode": ":shaved_ice:",
        "unicodes": [
          "u1f367"
        ]
      },
      {
        "search": [
          "ice",
          "cream"
        ],
        "name": "ice_cream",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/oar8jwzf.png",
        "icon": "🍨",
        "shortcode": ":ice_cream:",
        "unicodes": [
          "u1f368"
        ]
      },
      {
        "search": [
          "doughnut"
        ],
        "name": "doughnut",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bcxwq5w2.png",
        "icon": "🍩",
        "shortcode": ":doughnut:",
        "unicodes": [
          "u1f369"
        ]
      },
      {
        "search": [
          "cookie"
        ],
        "name": "cookie",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2u9gntwh.png",
        "icon": "🍪",
        "shortcode": ":cookie:",
        "unicodes": [
          "u1f36a"
        ]
      },
      {
        "search": [
          "birthday",
          "cake"
        ],
        "name": "birthday_cake",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dc4hmriv.png",
        "icon": "🎂",
        "shortcode": ":birthday_cake:",
        "unicodes": [
          "u1f382"
        ]
      },
      {
        "search": [
          "shortcake"
        ],
        "name": "shortcake",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xjn68ca3.png",
        "icon": "🍰",
        "shortcode": ":shortcake:",
        "unicodes": [
          "u1f370"
        ]
      },
      {
        "search": [
          "cupcake"
        ],
        "name": "cupcake",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6zksomlx.png",
        "icon": "🧁",
        "shortcode": ":cupcake:",
        "unicodes": [
          "u1f9c1"
        ]
      },
      {
        "search": [
          "pie"
        ],
        "name": "pie",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/74eo6m85.png",
        "icon": "🥧",
        "shortcode": ":pie:",
        "unicodes": [
          "u1f967"
        ]
      },
      {
        "search": [
          "chocolate",
          "bar"
        ],
        "name": "chocolate_bar",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h1f2joty.png",
        "icon": "🍫",
        "shortcode": ":chocolate_bar:",
        "unicodes": [
          "u1f36b"
        ]
      },
      {
        "search": [
          "candy"
        ],
        "name": "candy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sfcrd8cu.png",
        "icon": "🍬",
        "shortcode": ":candy:",
        "unicodes": [
          "u1f36c"
        ]
      },
      {
        "search": [
          "lollipop"
        ],
        "name": "lollipop",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zoezbugn.png",
        "icon": "🍭",
        "shortcode": ":lollipop:",
        "unicodes": [
          "u1f36d"
        ]
      },
      {
        "search": [
          "custard"
        ],
        "name": "custard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/86lnu6is.png",
        "icon": "🍮",
        "shortcode": ":custard:",
        "unicodes": [
          "u1f36e"
        ]
      },
      {
        "search": [
          "honey",
          "pot"
        ],
        "name": "honey_pot",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2fgr0nou.png",
        "icon": "🍯",
        "shortcode": ":honey_pot:",
        "unicodes": [
          "u1f36f"
        ]
      },
      {
        "search": [
          "baby",
          "bottle"
        ],
        "name": "baby_bottle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/peo4q2w7.png",
        "icon": "🍼",
        "shortcode": ":baby_bottle:",
        "unicodes": [
          "u1f37c"
        ]
      },
      {
        "search": [
          "glass",
          "of",
          "milk"
        ],
        "name": "glass_of_milk",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hhrlx1x3.png",
        "icon": "🥛",
        "shortcode": ":glass_of_milk:",
        "unicodes": [
          "u1f95b"
        ]
      },
      {
        "search": [
          "hot",
          "beverage"
        ],
        "name": "hot_beverage",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xnd548d9.png",
        "icon": "☕",
        "shortcode": ":hot_beverage:",
        "unicodes": [
          "u2615"
        ]
      },
      {
        "search": [
          "teapot"
        ],
        "name": "teapot",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8s6yxaao.png",
        "icon": "🫖",
        "shortcode": ":teapot:",
        "unicodes": [
          "u1fad6"
        ]
      },
      {
        "search": [
          "teacup",
          "without",
          "handle"
        ],
        "name": "teacup_without_handle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/oa603bt1.png",
        "icon": "🍵",
        "shortcode": ":teacup_without_handle:",
        "unicodes": [
          "u1f375"
        ]
      },
      {
        "search": [
          "sake"
        ],
        "name": "sake",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gxsyya4f.png",
        "icon": "🍶",
        "shortcode": ":sake:",
        "unicodes": [
          "u1f376"
        ]
      },
      {
        "search": [
          "bottle",
          "with",
          "popping",
          "cork"
        ],
        "name": "bottle_with_popping_cork",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fm0l286z.png",
        "icon": "🍾",
        "shortcode": ":bottle_with_popping_cork:",
        "unicodes": [
          "u1f37e"
        ]
      },
      {
        "search": [
          "wine",
          "glass"
        ],
        "name": "wine_glass",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zvvud64j.png",
        "icon": "🍷",
        "shortcode": ":wine_glass:",
        "unicodes": [
          "u1f377"
        ]
      },
      {
        "search": [
          "cocktail",
          "glass"
        ],
        "name": "cocktail_glass",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/aep7a33z.png",
        "icon": "🍸",
        "shortcode": ":cocktail_glass:",
        "unicodes": [
          "u1f378"
        ]
      },
      {
        "search": [
          "tropical",
          "drink"
        ],
        "name": "tropical_drink",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i51o7vbk.png",
        "icon": "🍹",
        "shortcode": ":tropical_drink:",
        "unicodes": [
          "u1f379"
        ]
      },
      {
        "search": [
          "beer",
          "mug"
        ],
        "name": "beer_mug",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e7b94ydy.png",
        "icon": "🍺",
        "shortcode": ":beer_mug:",
        "unicodes": [
          "u1f37a"
        ]
      },
      {
        "search": [
          "clinking",
          "beer",
          "mugs"
        ],
        "name": "clinking_beer_mugs",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w3xmnvch.png",
        "icon": "🍻",
        "shortcode": ":clinking_beer_mugs:",
        "unicodes": [
          "u1f37b"
        ]
      },
      {
        "search": [
          "clinking",
          "glasses"
        ],
        "name": "clinking_glasses",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4kltqhzs.png",
        "icon": "🥂",
        "shortcode": ":clinking_glasses:",
        "unicodes": [
          "u1f942"
        ]
      },
      {
        "search": [
          "tumbler",
          "glass"
        ],
        "name": "tumbler_glass",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8itovzfl.png",
        "icon": "🥃",
        "shortcode": ":tumbler_glass:",
        "unicodes": [
          "u1f943"
        ]
      },
      {
        "search": [
          "pouring",
          "liquid"
        ],
        "name": "pouring_liquid",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v2ullsyl.png",
        "icon": "🫗",
        "shortcode": ":pouring_liquid:",
        "unicodes": [
          "u1fad7"
        ]
      },
      {
        "search": [
          "cup",
          "with",
          "straw"
        ],
        "name": "cup_with_straw",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4ajhhvp3.png",
        "icon": "🥤",
        "shortcode": ":cup_with_straw:",
        "unicodes": [
          "u1f964"
        ]
      },
      {
        "search": [
          "bubble",
          "tea"
        ],
        "name": "bubble_tea",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0app0fc8.png",
        "icon": "🧋",
        "shortcode": ":bubble_tea:",
        "unicodes": [
          "u1f9cb"
        ]
      },
      {
        "search": [
          "beverage",
          "box"
        ],
        "name": "beverage_box",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/z8vfxewa.png",
        "icon": "🧃",
        "shortcode": ":beverage_box:",
        "unicodes": [
          "u1f9c3"
        ]
      },
      {
        "search": [
          "mate"
        ],
        "name": "mate",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kmemvnzo.png",
        "icon": "🧉",
        "shortcode": ":mate:",
        "unicodes": [
          "u1f9c9"
        ]
      },
      {
        "search": [
          "ice"
        ],
        "name": "ice",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zi1c24jt.png",
        "icon": "🧊",
        "shortcode": ":ice:",
        "unicodes": [
          "u1f9ca"
        ]
      },
      {
        "search": [
          "chopsticks"
        ],
        "name": "chopsticks",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4m07x09f.png",
        "icon": "🥢",
        "shortcode": ":chopsticks:",
        "unicodes": [
          "u1f962"
        ]
      },
      {
        "search": [
          "fork",
          "and",
          "knife",
          "with",
          "plate"
        ],
        "name": "fork_and_knife_with_plate",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hmt91sr9.png",
        "icon": "🍽",
        "shortcode": ":fork_and_knife_with_plate:",
        "unicodes": [
          "u1f37d"
        ]
      },
      {
        "search": [
          "fork",
          "and",
          "knife"
        ],
        "name": "fork_and_knife",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ak96g3lv.png",
        "icon": "🍴",
        "shortcode": ":fork_and_knife:",
        "unicodes": [
          "u1f374"
        ]
      },
      {
        "search": [
          "spoon"
        ],
        "name": "spoon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w01oy39s.png",
        "icon": "🥄",
        "shortcode": ":spoon:",
        "unicodes": [
          "u1f944"
        ]
      },
      {
        "search": [
          "kitchen",
          "knife"
        ],
        "name": "kitchen_knife",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/o387us9g.png",
        "icon": "🔪",
        "shortcode": ":kitchen_knife:",
        "unicodes": [
          "u1f52a"
        ]
      },
      {
        "search": [
          "jar"
        ],
        "name": "jar",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2sy7fl4y.png",
        "icon": "🫙",
        "shortcode": ":jar:",
        "unicodes": [
          "u1fad9"
        ]
      },
      {
        "search": [
          "amphora"
        ],
        "name": "amphora",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ilujl0ze.png",
        "icon": "🏺",
        "shortcode": ":amphora:",
        "unicodes": [
          "u1f3fa"
        ]
      }
    ]
  },
  {
    "category": "travel_and_places",
    "text": "Travel & Places",
    "icons": [
      {
        "search": [
          "globe",
          "showing",
          "Europe_Africa"
        ],
        "name": "globe_showing_Europe_Africa",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hwwiusqs.png",
        "icon": "🌍",
        "shortcode": ":globe_showing_europe_africa:",
        "unicodes": [
          "u1f30d"
        ]
      },
      {
        "search": [
          "globe",
          "showing",
          "Americas"
        ],
        "name": "globe_showing_Americas",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ifootlop.png",
        "icon": "🌎",
        "shortcode": ":globe_showing_americas:",
        "unicodes": [
          "u1f30e"
        ]
      },
      {
        "search": [
          "globe",
          "showing",
          "Asia_Australia"
        ],
        "name": "globe_showing_Asia_Australia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tynm4u7b.png",
        "icon": "🌏",
        "shortcode": ":globe_showing_asia_australia:",
        "unicodes": [
          "u1f30f"
        ]
      },
      {
        "search": [
          "globe",
          "with",
          "meridians"
        ],
        "name": "globe_with_meridians",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v8xy2fh6.png",
        "icon": "🌐",
        "shortcode": ":globe_with_meridians:",
        "unicodes": [
          "u1f310"
        ]
      },
      {
        "search": [
          "world",
          "map"
        ],
        "name": "world_map",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b1q3mc22.png",
        "icon": "🗺",
        "shortcode": ":world_map:",
        "unicodes": [
          "u1f5fa"
        ]
      },
      {
        "search": [
          "map",
          "of",
          "Japan"
        ],
        "name": "map_of_Japan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kfwwia8k.png",
        "icon": "🗾",
        "shortcode": ":map_of_japan:",
        "unicodes": [
          "u1f5fe"
        ]
      },
      {
        "search": [
          "compass"
        ],
        "name": "compass",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w72d32r7.png",
        "icon": "🧭",
        "shortcode": ":compass:",
        "unicodes": [
          "u1f9ed"
        ]
      },
      {
        "search": [
          "snow_capped",
          "mountain"
        ],
        "name": "snow_capped_mountain",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h5yd3du5.png",
        "icon": "🏔",
        "shortcode": ":snow_capped_mountain:",
        "unicodes": [
          "u1f3d4"
        ]
      },
      {
        "search": [
          "mountain"
        ],
        "name": "mountain",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nh1xa619.png",
        "icon": "⛰",
        "shortcode": ":mountain:",
        "unicodes": [
          "u26f0"
        ]
      },
      {
        "search": [
          "volcano"
        ],
        "name": "volcano",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dlwn22ld.png",
        "icon": "🌋",
        "shortcode": ":volcano:",
        "unicodes": [
          "u1f30b"
        ]
      },
      {
        "search": [
          "mount",
          "fuji"
        ],
        "name": "mount_fuji",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kw0rijwd.png",
        "icon": "🗻",
        "shortcode": ":mount_fuji:",
        "unicodes": [
          "u1f5fb"
        ]
      },
      {
        "search": [
          "camping"
        ],
        "name": "camping",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dck56ie9.png",
        "icon": "🏕",
        "shortcode": ":camping:",
        "unicodes": [
          "u1f3d5"
        ]
      },
      {
        "search": [
          "beach",
          "with",
          "umbrella"
        ],
        "name": "beach_with_umbrella",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hpqm581j.png",
        "icon": "🏖",
        "shortcode": ":beach_with_umbrella:",
        "unicodes": [
          "u1f3d6"
        ]
      },
      {
        "search": [
          "desert"
        ],
        "name": "desert",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/njb5n1u5.png",
        "icon": "🏜",
        "shortcode": ":desert:",
        "unicodes": [
          "u1f3dc"
        ]
      },
      {
        "search": [
          "desert",
          "island"
        ],
        "name": "desert_island",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/luoi5xo4.png",
        "icon": "🏝",
        "shortcode": ":desert_island:",
        "unicodes": [
          "u1f3dd"
        ]
      },
      {
        "search": [
          "national",
          "park"
        ],
        "name": "national_park",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/t0fn2hc8.png",
        "icon": "🏞",
        "shortcode": ":national_park:",
        "unicodes": [
          "u1f3de"
        ]
      },
      {
        "search": [
          "stadium"
        ],
        "name": "stadium",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tmmem95n.png",
        "icon": "🏟",
        "shortcode": ":stadium:",
        "unicodes": [
          "u1f3df"
        ]
      },
      {
        "search": [
          "classical",
          "building"
        ],
        "name": "classical_building",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qye5dmq9.png",
        "icon": "🏛",
        "shortcode": ":classical_building:",
        "unicodes": [
          "u1f3db"
        ]
      },
      {
        "search": [
          "building",
          "construction"
        ],
        "name": "building_construction",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g75xuor7.png",
        "icon": "🏗",
        "shortcode": ":building_construction:",
        "unicodes": [
          "u1f3d7"
        ]
      },
      {
        "search": [
          "brick"
        ],
        "name": "brick",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4fdxqv5o.png",
        "icon": "🧱",
        "shortcode": ":brick:",
        "unicodes": [
          "u1f9f1"
        ]
      },
      {
        "search": [
          "rock"
        ],
        "name": "rock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9w3t5v4u.png",
        "icon": "🪨",
        "shortcode": ":rock:",
        "unicodes": [
          "u1faa8"
        ]
      },
      {
        "search": [
          "wood"
        ],
        "name": "wood",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qi53vikm.png",
        "icon": "🪵",
        "shortcode": ":wood:",
        "unicodes": [
          "u1fab5"
        ]
      },
      {
        "search": [
          "hut"
        ],
        "name": "hut",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zme03yqp.png",
        "icon": "🛖",
        "shortcode": ":hut:",
        "unicodes": [
          "u1f6d6"
        ]
      },
      {
        "search": [
          "houses"
        ],
        "name": "houses",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f31h7md0.png",
        "icon": "🏘",
        "shortcode": ":houses:",
        "unicodes": [
          "u1f3d8"
        ]
      },
      {
        "search": [
          "derelict",
          "house"
        ],
        "name": "derelict_house",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h27hsj3n.png",
        "icon": "🏚",
        "shortcode": ":derelict_house:",
        "unicodes": [
          "u1f3da"
        ]
      },
      {
        "search": [
          "house"
        ],
        "name": "house",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0h559sht.png",
        "icon": "🏠",
        "shortcode": ":house:",
        "unicodes": [
          "u1f3e0"
        ]
      },
      {
        "search": [
          "house",
          "with",
          "garden"
        ],
        "name": "house_with_garden",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ptljdpnb.png",
        "icon": "🏡",
        "shortcode": ":house_with_garden:",
        "unicodes": [
          "u1f3e1"
        ]
      },
      {
        "search": [
          "office",
          "building"
        ],
        "name": "office_building",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nbdjjx9u.png",
        "icon": "🏢",
        "shortcode": ":office_building:",
        "unicodes": [
          "u1f3e2"
        ]
      },
      {
        "search": [
          "Japanese",
          "post",
          "office"
        ],
        "name": "Japanese_post_office",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/txss06qx.png",
        "icon": "🏣",
        "shortcode": ":japanese_post_office:",
        "unicodes": [
          "u1f3e3"
        ]
      },
      {
        "search": [
          "post",
          "office"
        ],
        "name": "post_office",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cirkz1ol.png",
        "icon": "🏤",
        "shortcode": ":post_office:",
        "unicodes": [
          "u1f3e4"
        ]
      },
      {
        "search": [
          "hospital"
        ],
        "name": "hospital",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gyk3mpf7.png",
        "icon": "🏥",
        "shortcode": ":hospital:",
        "unicodes": [
          "u1f3e5"
        ]
      },
      {
        "search": [
          "bank"
        ],
        "name": "bank",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/60x8ahoj.png",
        "icon": "🏦",
        "shortcode": ":bank:",
        "unicodes": [
          "u1f3e6"
        ]
      },
      {
        "search": [
          "hotel"
        ],
        "name": "hotel",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0rrfuk9y.png",
        "icon": "🏨",
        "shortcode": ":hotel:",
        "unicodes": [
          "u1f3e8"
        ]
      },
      {
        "search": [
          "love",
          "hotel"
        ],
        "name": "love_hotel",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9o4m5i0i.png",
        "icon": "🏩",
        "shortcode": ":love_hotel:",
        "unicodes": [
          "u1f3e9"
        ]
      },
      {
        "search": [
          "convenience",
          "store"
        ],
        "name": "convenience_store",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/r2ikdmrh.png",
        "icon": "🏪",
        "shortcode": ":convenience_store:",
        "unicodes": [
          "u1f3ea"
        ]
      },
      {
        "search": [
          "school"
        ],
        "name": "school",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/70mom9b1.png",
        "icon": "🏫",
        "shortcode": ":school:",
        "unicodes": [
          "u1f3eb"
        ]
      },
      {
        "search": [
          "department",
          "store"
        ],
        "name": "department_store",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/p6v41hk9.png",
        "icon": "🏬",
        "shortcode": ":department_store:",
        "unicodes": [
          "u1f3ec"
        ]
      },
      {
        "search": [
          "factory"
        ],
        "name": "factory",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1yn3dc7u.png",
        "icon": "🏭",
        "shortcode": ":factory:",
        "unicodes": [
          "u1f3ed"
        ]
      },
      {
        "search": [
          "Japanese",
          "castle"
        ],
        "name": "Japanese_castle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gsqkbiu0.png",
        "icon": "🏯",
        "shortcode": ":japanese_castle:",
        "unicodes": [
          "u1f3ef"
        ]
      },
      {
        "search": [
          "castle"
        ],
        "name": "castle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vbmgt3r3.png",
        "icon": "🏰",
        "shortcode": ":castle:",
        "unicodes": [
          "u1f3f0"
        ]
      },
      {
        "search": [
          "wedding"
        ],
        "name": "wedding",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1z2172fy.png",
        "icon": "💒",
        "shortcode": ":wedding:",
        "unicodes": [
          "u1f492"
        ]
      },
      {
        "search": [
          "Tokyo",
          "tower"
        ],
        "name": "Tokyo_tower",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0z3pofn1.png",
        "icon": "🗼",
        "shortcode": ":tokyo_tower:",
        "unicodes": [
          "u1f5fc"
        ]
      },
      {
        "search": [
          "Statue",
          "of",
          "Liberty"
        ],
        "name": "Statue_of_Liberty",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bjiqndfq.png",
        "icon": "🗽",
        "shortcode": ":statue_of_liberty:",
        "unicodes": [
          "u1f5fd"
        ]
      },
      {
        "search": [
          "church"
        ],
        "name": "church",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0tr55lno.png",
        "icon": "⛪",
        "shortcode": ":church:",
        "unicodes": [
          "u26ea"
        ]
      },
      {
        "search": [
          "mosque"
        ],
        "name": "mosque",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b3r5ya0t.png",
        "icon": "🕌",
        "shortcode": ":mosque:",
        "unicodes": [
          "u1f54c"
        ]
      },
      {
        "search": [
          "hindu",
          "temple"
        ],
        "name": "hindu_temple",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uyw8zqxj.png",
        "icon": "🛕",
        "shortcode": ":hindu_temple:",
        "unicodes": [
          "u1f6d5"
        ]
      },
      {
        "search": [
          "synagogue"
        ],
        "name": "synagogue",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s1ghf4ju.png",
        "icon": "🕍",
        "shortcode": ":synagogue:",
        "unicodes": [
          "u1f54d"
        ]
      },
      {
        "search": [
          "shinto",
          "shrine"
        ],
        "name": "shinto_shrine",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qbstr3bl.png",
        "icon": "⛩",
        "shortcode": ":shinto_shrine:",
        "unicodes": [
          "u26e9"
        ]
      },
      {
        "search": [
          "kaaba"
        ],
        "name": "kaaba",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/oao2396w.png",
        "icon": "🕋",
        "shortcode": ":kaaba:",
        "unicodes": [
          "u1f54b"
        ]
      },
      {
        "search": [
          "fountain"
        ],
        "name": "fountain",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i3m4svhe.png",
        "icon": "⛲",
        "shortcode": ":fountain:",
        "unicodes": [
          "u26f2"
        ]
      },
      {
        "search": [
          "tent"
        ],
        "name": "tent",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0fq7mkxu.png",
        "icon": "⛺",
        "shortcode": ":tent:",
        "unicodes": [
          "u26fa"
        ]
      },
      {
        "search": [
          "foggy"
        ],
        "name": "foggy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2gn2a6gl.png",
        "icon": "🌁",
        "shortcode": ":foggy:",
        "unicodes": [
          "u1f301"
        ]
      },
      {
        "search": [
          "night",
          "with",
          "stars"
        ],
        "name": "night_with_stars",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2ggowv3h.png",
        "icon": "🌃",
        "shortcode": ":night_with_stars:",
        "unicodes": [
          "u1f303"
        ]
      },
      {
        "search": [
          "cityscape"
        ],
        "name": "cityscape",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zmrnuuud.png",
        "icon": "🏙",
        "shortcode": ":cityscape:",
        "unicodes": [
          "u1f3d9"
        ]
      },
      {
        "search": [
          "sunrise",
          "over",
          "mountains"
        ],
        "name": "sunrise_over_mountains",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ycn4nk6n.png",
        "icon": "🌄",
        "shortcode": ":sunrise_over_mountains:",
        "unicodes": [
          "u1f304"
        ]
      },
      {
        "search": [
          "sunrise"
        ],
        "name": "sunrise",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/67u27qn0.png",
        "icon": "🌅",
        "shortcode": ":sunrise:",
        "unicodes": [
          "u1f305"
        ]
      },
      {
        "search": [
          "cityscape",
          "at",
          "dusk"
        ],
        "name": "cityscape_at_dusk",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b8gainls.png",
        "icon": "🌆",
        "shortcode": ":cityscape_at_dusk:",
        "unicodes": [
          "u1f306"
        ]
      },
      {
        "search": [
          "sunset"
        ],
        "name": "sunset",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xp29of1m.png",
        "icon": "🌇",
        "shortcode": ":sunset:",
        "unicodes": [
          "u1f307"
        ]
      },
      {
        "search": [
          "bridge",
          "at",
          "night"
        ],
        "name": "bridge_at_night",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/794geo93.png",
        "icon": "🌉",
        "shortcode": ":bridge_at_night:",
        "unicodes": [
          "u1f309"
        ]
      },
      {
        "search": [
          "hot",
          "springs"
        ],
        "name": "hot_springs",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wopn2oqj.png",
        "icon": "♨",
        "shortcode": ":hot_springs:",
        "unicodes": [
          "u2668"
        ]
      },
      {
        "search": [
          "carousel",
          "horse"
        ],
        "name": "carousel_horse",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/r24z5alp.png",
        "icon": "🎠",
        "shortcode": ":carousel_horse:",
        "unicodes": [
          "u1f3a0"
        ]
      },
      {
        "search": [
          "playground",
          "slide"
        ],
        "name": "playground_slide",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ogeo0rf1.png",
        "icon": "🛝",
        "shortcode": ":playground_slide:",
        "unicodes": [
          "u1f6dd"
        ]
      },
      {
        "search": [
          "ferris",
          "wheel"
        ],
        "name": "ferris_wheel",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fj368kjy.png",
        "icon": "🎡",
        "shortcode": ":ferris_wheel:",
        "unicodes": [
          "u1f3a1"
        ]
      },
      {
        "search": [
          "roller",
          "coaster"
        ],
        "name": "roller_coaster",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uzq1wr9c.png",
        "icon": "🎢",
        "shortcode": ":roller_coaster:",
        "unicodes": [
          "u1f3a2"
        ]
      },
      {
        "search": [
          "barber",
          "pole"
        ],
        "name": "barber_pole",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fk7mulyh.png",
        "icon": "💈",
        "shortcode": ":barber_pole:",
        "unicodes": [
          "u1f488"
        ]
      },
      {
        "search": [
          "circus",
          "tent"
        ],
        "name": "circus_tent",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/24f9uycf.png",
        "icon": "🎪",
        "shortcode": ":circus_tent:",
        "unicodes": [
          "u1f3aa"
        ]
      },
      {
        "search": [
          "locomotive"
        ],
        "name": "locomotive",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0zvjpz8y.png",
        "icon": "🚂",
        "shortcode": ":locomotive:",
        "unicodes": [
          "u1f682"
        ]
      },
      {
        "search": [
          "railway",
          "car"
        ],
        "name": "railway_car",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gvv88380.png",
        "icon": "🚃",
        "shortcode": ":railway_car:",
        "unicodes": [
          "u1f683"
        ]
      },
      {
        "search": [
          "high_speed",
          "train"
        ],
        "name": "high_speed_train",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1pi7smd9.png",
        "icon": "🚄",
        "shortcode": ":high_speed_train:",
        "unicodes": [
          "u1f684"
        ]
      },
      {
        "search": [
          "bullet",
          "train"
        ],
        "name": "bullet_train",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6ypbbbgg.png",
        "icon": "🚅",
        "shortcode": ":bullet_train:",
        "unicodes": [
          "u1f685"
        ]
      },
      {
        "search": [
          "train"
        ],
        "name": "train",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4btqlpkc.png",
        "icon": "🚆",
        "shortcode": ":train:",
        "unicodes": [
          "u1f686"
        ]
      },
      {
        "search": [
          "metro"
        ],
        "name": "metro",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1e1crrzy.png",
        "icon": "🚇",
        "shortcode": ":metro:",
        "unicodes": [
          "u1f687"
        ]
      },
      {
        "search": [
          "light",
          "rail"
        ],
        "name": "light_rail",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kd025ll4.png",
        "icon": "🚈",
        "shortcode": ":light_rail:",
        "unicodes": [
          "u1f688"
        ]
      },
      {
        "search": [
          "station"
        ],
        "name": "station",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cmidirww.png",
        "icon": "🚉",
        "shortcode": ":station:",
        "unicodes": [
          "u1f689"
        ]
      },
      {
        "search": [
          "tram"
        ],
        "name": "tram",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cplj76ek.png",
        "icon": "🚊",
        "shortcode": ":tram:",
        "unicodes": [
          "u1f68a"
        ]
      },
      {
        "search": [
          "monorail"
        ],
        "name": "monorail",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2c8ojw4t.png",
        "icon": "🚝",
        "shortcode": ":monorail:",
        "unicodes": [
          "u1f69d"
        ]
      },
      {
        "search": [
          "mountain",
          "railway"
        ],
        "name": "mountain_railway",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qb1daui9.png",
        "icon": "🚞",
        "shortcode": ":mountain_railway:",
        "unicodes": [
          "u1f69e"
        ]
      },
      {
        "search": [
          "tram",
          "car"
        ],
        "name": "tram_car",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/01xmrez9.png",
        "icon": "🚋",
        "shortcode": ":tram_car:",
        "unicodes": [
          "u1f68b"
        ]
      },
      {
        "search": [
          "bus"
        ],
        "name": "bus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7xiq973p.png",
        "icon": "🚌",
        "shortcode": ":bus:",
        "unicodes": [
          "u1f68c"
        ]
      },
      {
        "search": [
          "oncoming",
          "bus"
        ],
        "name": "oncoming_bus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qh0oyif0.png",
        "icon": "🚍",
        "shortcode": ":oncoming_bus:",
        "unicodes": [
          "u1f68d"
        ]
      },
      {
        "search": [
          "trolleybus"
        ],
        "name": "trolleybus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kcji6ygf.png",
        "icon": "🚎",
        "shortcode": ":trolleybus:",
        "unicodes": [
          "u1f68e"
        ]
      },
      {
        "search": [
          "minibus"
        ],
        "name": "minibus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2xbcsba6.png",
        "icon": "🚐",
        "shortcode": ":minibus:",
        "unicodes": [
          "u1f690"
        ]
      },
      {
        "search": [
          "ambulance"
        ],
        "name": "ambulance",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3jz6zosa.png",
        "icon": "🚑",
        "shortcode": ":ambulance:",
        "unicodes": [
          "u1f691"
        ]
      },
      {
        "search": [
          "fire",
          "engine"
        ],
        "name": "fire_engine",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/862bop14.png",
        "icon": "🚒",
        "shortcode": ":fire_engine:",
        "unicodes": [
          "u1f692"
        ]
      },
      {
        "search": [
          "police",
          "car"
        ],
        "name": "police_car",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/r055dx5x.png",
        "icon": "🚓",
        "shortcode": ":police_car:",
        "unicodes": [
          "u1f693"
        ]
      },
      {
        "search": [
          "oncoming",
          "police",
          "car"
        ],
        "name": "oncoming_police_car",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9by70et5.png",
        "icon": "🚔",
        "shortcode": ":oncoming_police_car:",
        "unicodes": [
          "u1f694"
        ]
      },
      {
        "search": [
          "taxi"
        ],
        "name": "taxi",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8ler0t2k.png",
        "icon": "🚕",
        "shortcode": ":taxi:",
        "unicodes": [
          "u1f695"
        ]
      },
      {
        "search": [
          "oncoming",
          "taxi"
        ],
        "name": "oncoming_taxi",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4203w7bj.png",
        "icon": "🚖",
        "shortcode": ":oncoming_taxi:",
        "unicodes": [
          "u1f696"
        ]
      },
      {
        "search": [
          "automobile"
        ],
        "name": "automobile",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/165hujbo.png",
        "icon": "🚗",
        "shortcode": ":automobile:",
        "unicodes": [
          "u1f697"
        ]
      },
      {
        "search": [
          "oncoming",
          "automobile"
        ],
        "name": "oncoming_automobile",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w8vzw5dc.png",
        "icon": "🚘",
        "shortcode": ":oncoming_automobile:",
        "unicodes": [
          "u1f698"
        ]
      },
      {
        "search": [
          "sport",
          "utility",
          "vehicle"
        ],
        "name": "sport_utility_vehicle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/twsy8nha.png",
        "icon": "🚙",
        "shortcode": ":sport_utility_vehicle:",
        "unicodes": [
          "u1f699"
        ]
      },
      {
        "search": [
          "pickup",
          "truck"
        ],
        "name": "pickup_truck",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/eixy2gi5.png",
        "icon": "🛻",
        "shortcode": ":pickup_truck:",
        "unicodes": [
          "u1f6fb"
        ]
      },
      {
        "search": [
          "delivery",
          "truck"
        ],
        "name": "delivery_truck",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ea4kvqxq.png",
        "icon": "🚚",
        "shortcode": ":delivery_truck:",
        "unicodes": [
          "u1f69a"
        ]
      },
      {
        "search": [
          "articulated",
          "lorry"
        ],
        "name": "articulated_lorry",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7j7gc8ch.png",
        "icon": "🚛",
        "shortcode": ":articulated_lorry:",
        "unicodes": [
          "u1f69b"
        ]
      },
      {
        "search": [
          "tractor"
        ],
        "name": "tractor",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gbji7rfs.png",
        "icon": "🚜",
        "shortcode": ":tractor:",
        "unicodes": [
          "u1f69c"
        ]
      },
      {
        "search": [
          "racing",
          "car"
        ],
        "name": "racing_car",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wiuas6kl.png",
        "icon": "🏎",
        "shortcode": ":racing_car:",
        "unicodes": [
          "u1f3ce"
        ]
      },
      {
        "search": [
          "motorcycle"
        ],
        "name": "motorcycle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/eyxs6ch3.png",
        "icon": "🏍",
        "shortcode": ":motorcycle:",
        "unicodes": [
          "u1f3cd"
        ]
      },
      {
        "search": [
          "motor",
          "scooter"
        ],
        "name": "motor_scooter",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/eq4jvoqi.png",
        "icon": "🛵",
        "shortcode": ":motor_scooter:",
        "unicodes": [
          "u1f6f5"
        ]
      },
      {
        "search": [
          "manual",
          "wheelchair"
        ],
        "name": "manual_wheelchair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gli5ha8m.png",
        "icon": "🦽",
        "shortcode": ":manual_wheelchair:",
        "unicodes": [
          "u1f9bd"
        ]
      },
      {
        "search": [
          "motorized",
          "wheelchair"
        ],
        "name": "motorized_wheelchair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g9yztf4p.png",
        "icon": "🦼",
        "shortcode": ":motorized_wheelchair:",
        "unicodes": [
          "u1f9bc"
        ]
      },
      {
        "search": [
          "auto",
          "rickshaw"
        ],
        "name": "auto_rickshaw",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jk3tx99k.png",
        "icon": "🛺",
        "shortcode": ":auto_rickshaw:",
        "unicodes": [
          "u1f6fa"
        ]
      },
      {
        "search": [
          "bicycle"
        ],
        "name": "bicycle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f542isn2.png",
        "icon": "🚲",
        "shortcode": ":bicycle:",
        "unicodes": [
          "u1f6b2"
        ]
      },
      {
        "search": [
          "kick",
          "scooter"
        ],
        "name": "kick_scooter",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/98xh6gci.png",
        "icon": "🛴",
        "shortcode": ":kick_scooter:",
        "unicodes": [
          "u1f6f4"
        ]
      },
      {
        "search": [
          "skateboard"
        ],
        "name": "skateboard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6zfwk7fy.png",
        "icon": "🛹",
        "shortcode": ":skateboard:",
        "unicodes": [
          "u1f6f9"
        ]
      },
      {
        "search": [
          "roller",
          "skate"
        ],
        "name": "roller_skate",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ee12fp39.png",
        "icon": "🛼",
        "shortcode": ":roller_skate:",
        "unicodes": [
          "u1f6fc"
        ]
      },
      {
        "search": [
          "bus",
          "stop"
        ],
        "name": "bus_stop",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qfh9zmko.png",
        "icon": "🚏",
        "shortcode": ":bus_stop:",
        "unicodes": [
          "u1f68f"
        ]
      },
      {
        "search": [
          "motorway"
        ],
        "name": "motorway",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/spvlnuda.png",
        "icon": "🛣",
        "shortcode": ":motorway:",
        "unicodes": [
          "u1f6e3"
        ]
      },
      {
        "search": [
          "railway",
          "track"
        ],
        "name": "railway_track",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uaz58i4m.png",
        "icon": "🛤",
        "shortcode": ":railway_track:",
        "unicodes": [
          "u1f6e4"
        ]
      },
      {
        "search": [
          "oil",
          "drum"
        ],
        "name": "oil_drum",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7n169bgm.png",
        "icon": "🛢",
        "shortcode": ":oil_drum:",
        "unicodes": [
          "u1f6e2"
        ]
      },
      {
        "search": [
          "fuel",
          "pump"
        ],
        "name": "fuel_pump",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0g404law.png",
        "icon": "⛽",
        "shortcode": ":fuel_pump:",
        "unicodes": [
          "u26fd"
        ]
      },
      {
        "search": [
          "wheel"
        ],
        "name": "wheel",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j2vwdpa3.png",
        "icon": "🛞",
        "shortcode": ":wheel:",
        "unicodes": [
          "u1f6de"
        ]
      },
      {
        "search": [
          "police",
          "car",
          "light"
        ],
        "name": "police_car_light",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tjmh743z.png",
        "icon": "🚨",
        "shortcode": ":police_car_light:",
        "unicodes": [
          "u1f6a8"
        ]
      },
      {
        "search": [
          "horizontal",
          "traffic",
          "light"
        ],
        "name": "horizontal_traffic_light",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/60ze9q1r.png",
        "icon": "🚥",
        "shortcode": ":horizontal_traffic_light:",
        "unicodes": [
          "u1f6a5"
        ]
      },
      {
        "search": [
          "vertical",
          "traffic",
          "light"
        ],
        "name": "vertical_traffic_light",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fppaf6kb.png",
        "icon": "🚦",
        "shortcode": ":vertical_traffic_light:",
        "unicodes": [
          "u1f6a6"
        ]
      },
      {
        "search": [
          "stop",
          "sign"
        ],
        "name": "stop_sign",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yqvp6qe1.png",
        "icon": "🛑",
        "shortcode": ":stop_sign:",
        "unicodes": [
          "u1f6d1"
        ]
      },
      {
        "search": [
          "construction"
        ],
        "name": "construction",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0rdsjsqw.png",
        "icon": "🚧",
        "shortcode": ":construction:",
        "unicodes": [
          "u1f6a7"
        ]
      },
      {
        "search": [
          "anchor"
        ],
        "name": "anchor",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qsnfbfet.png",
        "icon": "⚓",
        "shortcode": ":anchor:",
        "unicodes": [
          "u2693"
        ]
      },
      {
        "search": [
          "ring",
          "buoy"
        ],
        "name": "ring_buoy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/t117uias.png",
        "icon": "🛟",
        "shortcode": ":ring_buoy:",
        "unicodes": [
          "u1f6df"
        ]
      },
      {
        "search": [
          "sailboat"
        ],
        "name": "sailboat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ae290syf.png",
        "icon": "⛵",
        "shortcode": ":sailboat:",
        "unicodes": [
          "u26f5"
        ]
      },
      {
        "search": [
          "canoe"
        ],
        "name": "canoe",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6ko32t51.png",
        "icon": "🛶",
        "shortcode": ":canoe:",
        "unicodes": [
          "u1f6f6"
        ]
      },
      {
        "search": [
          "speedboat"
        ],
        "name": "speedboat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/064p1h6r.png",
        "icon": "🚤",
        "shortcode": ":speedboat:",
        "unicodes": [
          "u1f6a4"
        ]
      },
      {
        "search": [
          "passenger",
          "ship"
        ],
        "name": "passenger_ship",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ednrxpuq.png",
        "icon": "🛳",
        "shortcode": ":passenger_ship:",
        "unicodes": [
          "u1f6f3"
        ]
      },
      {
        "search": [
          "ferry"
        ],
        "name": "ferry",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h1a92v00.png",
        "icon": "⛴",
        "shortcode": ":ferry:",
        "unicodes": [
          "u26f4"
        ]
      },
      {
        "search": [
          "motor",
          "boat"
        ],
        "name": "motor_boat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1zqk2on5.png",
        "icon": "🛥",
        "shortcode": ":motor_boat:",
        "unicodes": [
          "u1f6e5"
        ]
      },
      {
        "search": [
          "ship"
        ],
        "name": "ship",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/k7o01mt5.png",
        "icon": "🚢",
        "shortcode": ":ship:",
        "unicodes": [
          "u1f6a2"
        ]
      },
      {
        "search": [
          "airplane"
        ],
        "name": "airplane",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yafhagoy.png",
        "icon": "✈",
        "shortcode": ":airplane:",
        "unicodes": [
          "u2708"
        ]
      },
      {
        "search": [
          "small",
          "airplane"
        ],
        "name": "small_airplane",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5e9v1nqu.png",
        "icon": "🛩",
        "shortcode": ":small_airplane:",
        "unicodes": [
          "u1f6e9"
        ]
      },
      {
        "search": [
          "airplane",
          "departure"
        ],
        "name": "airplane_departure",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jochhfro.png",
        "icon": "🛫",
        "shortcode": ":airplane_departure:",
        "unicodes": [
          "u1f6eb"
        ]
      },
      {
        "search": [
          "airplane",
          "arrival"
        ],
        "name": "airplane_arrival",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6ach5p06.png",
        "icon": "🛬",
        "shortcode": ":airplane_arrival:",
        "unicodes": [
          "u1f6ec"
        ]
      },
      {
        "search": [
          "parachute"
        ],
        "name": "parachute",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xqv196on.png",
        "icon": "🪂",
        "shortcode": ":parachute:",
        "unicodes": [
          "u1fa82"
        ]
      },
      {
        "search": [
          "seat"
        ],
        "name": "seat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/spjk66hr.png",
        "icon": "💺",
        "shortcode": ":seat:",
        "unicodes": [
          "u1f4ba"
        ]
      },
      {
        "search": [
          "helicopter"
        ],
        "name": "helicopter",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/n77gr2i3.png",
        "icon": "🚁",
        "shortcode": ":helicopter:",
        "unicodes": [
          "u1f681"
        ]
      },
      {
        "search": [
          "suspension",
          "railway"
        ],
        "name": "suspension_railway",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/19o0118o.png",
        "icon": "🚟",
        "shortcode": ":suspension_railway:",
        "unicodes": [
          "u1f69f"
        ]
      },
      {
        "search": [
          "mountain",
          "cableway"
        ],
        "name": "mountain_cableway",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kh7z2bxu.png",
        "icon": "🚠",
        "shortcode": ":mountain_cableway:",
        "unicodes": [
          "u1f6a0"
        ]
      },
      {
        "search": [
          "aerial",
          "tramway"
        ],
        "name": "aerial_tramway",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6zffgafa.png",
        "icon": "🚡",
        "shortcode": ":aerial_tramway:",
        "unicodes": [
          "u1f6a1"
        ]
      },
      {
        "search": [
          "satellite"
        ],
        "name": "satellite",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/o50egtpi.png",
        "icon": "🛰",
        "shortcode": ":satellite:",
        "unicodes": [
          "u1f6f0"
        ]
      },
      {
        "search": [
          "rocket"
        ],
        "name": "rocket",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1ail3xhq.png",
        "icon": "🚀",
        "shortcode": ":rocket:",
        "unicodes": [
          "u1f680"
        ]
      },
      {
        "search": [
          "flying",
          "saucer"
        ],
        "name": "flying_saucer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zd25qkow.png",
        "icon": "🛸",
        "shortcode": ":flying_saucer:",
        "unicodes": [
          "u1f6f8"
        ]
      },
      {
        "search": [
          "luggage"
        ],
        "name": "luggage",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wo0ffhq5.png",
        "icon": "🧳",
        "shortcode": ":luggage:",
        "unicodes": [
          "u1f9f3"
        ]
      },
      {
        "search": [
          "hourglass",
          "done"
        ],
        "name": "hourglass_done",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zd3n3y07.png",
        "icon": "⌛",
        "shortcode": ":hourglass_done:",
        "unicodes": [
          "u231b"
        ]
      },
      {
        "search": [
          "hourglass",
          "not",
          "done"
        ],
        "name": "hourglass_not_done",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wgomovie.png",
        "icon": "⏳",
        "shortcode": ":hourglass_not_done:",
        "unicodes": [
          "u23f3"
        ]
      },
      {
        "search": [
          "watch"
        ],
        "name": "watch",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rn0zmnzc.png",
        "icon": "⌚",
        "shortcode": ":watch:",
        "unicodes": [
          "u231a"
        ]
      },
      {
        "search": [
          "alarm",
          "clock"
        ],
        "name": "alarm_clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kgmvi1tq.png",
        "icon": "⏰",
        "shortcode": ":alarm_clock:",
        "unicodes": [
          "u23f0"
        ]
      },
      {
        "search": [
          "stopwatch"
        ],
        "name": "stopwatch",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/o8lg3c8b.png",
        "icon": "⏱",
        "shortcode": ":stopwatch:",
        "unicodes": [
          "u23f1"
        ]
      },
      {
        "search": [
          "timer",
          "clock"
        ],
        "name": "timer_clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/o71yrbv7.png",
        "icon": "⏲",
        "shortcode": ":timer_clock:",
        "unicodes": [
          "u23f2"
        ]
      },
      {
        "search": [
          "mantelpiece",
          "clock"
        ],
        "name": "mantelpiece_clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0hkkabpd.png",
        "icon": "🕰",
        "shortcode": ":mantelpiece_clock:",
        "unicodes": [
          "u1f570"
        ]
      },
      {
        "search": [
          "twelve",
          "o’clock"
        ],
        "name": "twelve_o’clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/atz4tdcj.png",
        "icon": "🕛",
        "shortcode": ":twelve_oclock:",
        "unicodes": [
          "u1f55b"
        ]
      },
      {
        "search": [
          "twelve_thirty"
        ],
        "name": "twelve_thirty",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/y4j14f35.png",
        "icon": "🕧",
        "shortcode": ":twelve_thirty:",
        "unicodes": [
          "u1f567"
        ]
      },
      {
        "search": [
          "one",
          "o’clock"
        ],
        "name": "one_o’clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uknm51yo.png",
        "icon": "🕐",
        "shortcode": ":one_oclock:",
        "unicodes": [
          "u1f550"
        ]
      },
      {
        "search": [
          "one_thirty"
        ],
        "name": "one_thirty",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rbcpksyo.png",
        "icon": "🕜",
        "shortcode": ":one_thirty:",
        "unicodes": [
          "u1f55c"
        ]
      },
      {
        "search": [
          "two",
          "o’clock"
        ],
        "name": "two_o’clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g49598e7.png",
        "icon": "🕑",
        "shortcode": ":two_oclock:",
        "unicodes": [
          "u1f551"
        ]
      },
      {
        "search": [
          "two_thirty"
        ],
        "name": "two_thirty",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/u7knf0t3.png",
        "icon": "🕝",
        "shortcode": ":two_thirty:",
        "unicodes": [
          "u1f55d"
        ]
      },
      {
        "search": [
          "three",
          "o’clock"
        ],
        "name": "three_o’clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qs9kpr7f.png",
        "icon": "🕒",
        "shortcode": ":three_oclock:",
        "unicodes": [
          "u1f552"
        ]
      },
      {
        "search": [
          "three_thirty"
        ],
        "name": "three_thirty",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cyeisnsn.png",
        "icon": "🕞",
        "shortcode": ":three_thirty:",
        "unicodes": [
          "u1f55e"
        ]
      },
      {
        "search": [
          "four",
          "o’clock"
        ],
        "name": "four_o’clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ac42v29r.png",
        "icon": "🕓",
        "shortcode": ":four_oclock:",
        "unicodes": [
          "u1f553"
        ]
      },
      {
        "search": [
          "four_thirty"
        ],
        "name": "four_thirty",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tvu8c404.png",
        "icon": "🕟",
        "shortcode": ":four_thirty:",
        "unicodes": [
          "u1f55f"
        ]
      },
      {
        "search": [
          "five",
          "o’clock"
        ],
        "name": "five_o’clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/x9ilj6fj.png",
        "icon": "🕔",
        "shortcode": ":five_oclock:",
        "unicodes": [
          "u1f554"
        ]
      },
      {
        "search": [
          "five_thirty"
        ],
        "name": "five_thirty",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/58s1hkvg.png",
        "icon": "🕠",
        "shortcode": ":five_thirty:",
        "unicodes": [
          "u1f560"
        ]
      },
      {
        "search": [
          "six",
          "o’clock"
        ],
        "name": "six_o’clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vteun1y7.png",
        "icon": "🕕",
        "shortcode": ":six_oclock:",
        "unicodes": [
          "u1f555"
        ]
      },
      {
        "search": [
          "six_thirty"
        ],
        "name": "six_thirty",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4ttluz19.png",
        "icon": "🕡",
        "shortcode": ":six_thirty:",
        "unicodes": [
          "u1f561"
        ]
      },
      {
        "search": [
          "seven",
          "o’clock"
        ],
        "name": "seven_o’clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ornz9env.png",
        "icon": "🕖",
        "shortcode": ":seven_oclock:",
        "unicodes": [
          "u1f556"
        ]
      },
      {
        "search": [
          "seven_thirty"
        ],
        "name": "seven_thirty",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/x8a6jes7.png",
        "icon": "🕢",
        "shortcode": ":seven_thirty:",
        "unicodes": [
          "u1f562"
        ]
      },
      {
        "search": [
          "eight",
          "o’clock"
        ],
        "name": "eight_o’clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e5kmauin.png",
        "icon": "🕗",
        "shortcode": ":eight_oclock:",
        "unicodes": [
          "u1f557"
        ]
      },
      {
        "search": [
          "eight_thirty"
        ],
        "name": "eight_thirty",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1j083tao.png",
        "icon": "🕣",
        "shortcode": ":eight_thirty:",
        "unicodes": [
          "u1f563"
        ]
      },
      {
        "search": [
          "nine",
          "o’clock"
        ],
        "name": "nine_o’clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/x4vys6fn.png",
        "icon": "🕘",
        "shortcode": ":nine_oclock:",
        "unicodes": [
          "u1f558"
        ]
      },
      {
        "search": [
          "nine_thirty"
        ],
        "name": "nine_thirty",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vw2rdng2.png",
        "icon": "🕤",
        "shortcode": ":nine_thirty:",
        "unicodes": [
          "u1f564"
        ]
      },
      {
        "search": [
          "ten",
          "o’clock"
        ],
        "name": "ten_o’clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ib3rjgt2.png",
        "icon": "🕙",
        "shortcode": ":ten_oclock:",
        "unicodes": [
          "u1f559"
        ]
      },
      {
        "search": [
          "ten_thirty"
        ],
        "name": "ten_thirty",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/34161xsq.png",
        "icon": "🕥",
        "shortcode": ":ten_thirty:",
        "unicodes": [
          "u1f565"
        ]
      },
      {
        "search": [
          "eleven",
          "o’clock"
        ],
        "name": "eleven_o’clock",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rlz3l13m.png",
        "icon": "🕚",
        "shortcode": ":eleven_oclock:",
        "unicodes": [
          "u1f55a"
        ]
      },
      {
        "search": [
          "eleven_thirty"
        ],
        "name": "eleven_thirty",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9hce2dci.png",
        "icon": "🕦",
        "shortcode": ":eleven_thirty:",
        "unicodes": [
          "u1f566"
        ]
      },
      {
        "search": [
          "new",
          "moon"
        ],
        "name": "new_moon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/00zwrhl6.png",
        "icon": "🌑",
        "shortcode": ":new_moon:",
        "unicodes": [
          "u1f311"
        ]
      },
      {
        "search": [
          "waxing",
          "crescent",
          "moon"
        ],
        "name": "waxing_crescent_moon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bdr9rpev.png",
        "icon": "🌒",
        "shortcode": ":waxing_crescent_moon:",
        "unicodes": [
          "u1f312"
        ]
      },
      {
        "search": [
          "first",
          "quarter",
          "moon"
        ],
        "name": "first_quarter_moon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fazjfv0x.png",
        "icon": "🌓",
        "shortcode": ":first_quarter_moon:",
        "unicodes": [
          "u1f313"
        ]
      },
      {
        "search": [
          "waxing",
          "gibbous",
          "moon"
        ],
        "name": "waxing_gibbous_moon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yf3ikx8a.png",
        "icon": "🌔",
        "shortcode": ":waxing_gibbous_moon:",
        "unicodes": [
          "u1f314"
        ]
      },
      {
        "search": [
          "full",
          "moon"
        ],
        "name": "full_moon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/srn8hxx0.png",
        "icon": "🌕",
        "shortcode": ":full_moon:",
        "unicodes": [
          "u1f315"
        ]
      },
      {
        "search": [
          "waning",
          "gibbous",
          "moon"
        ],
        "name": "waning_gibbous_moon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5wkej16j.png",
        "icon": "🌖",
        "shortcode": ":waning_gibbous_moon:",
        "unicodes": [
          "u1f316"
        ]
      },
      {
        "search": [
          "last",
          "quarter",
          "moon"
        ],
        "name": "last_quarter_moon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tqau4qkc.png",
        "icon": "🌗",
        "shortcode": ":last_quarter_moon:",
        "unicodes": [
          "u1f317"
        ]
      },
      {
        "search": [
          "waning",
          "crescent",
          "moon"
        ],
        "name": "waning_crescent_moon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/r8bz0xzc.png",
        "icon": "🌘",
        "shortcode": ":waning_crescent_moon:",
        "unicodes": [
          "u1f318"
        ]
      },
      {
        "search": [
          "crescent",
          "moon"
        ],
        "name": "crescent_moon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9vccoj9h.png",
        "icon": "🌙",
        "shortcode": ":crescent_moon:",
        "unicodes": [
          "u1f319"
        ]
      },
      {
        "search": [
          "new",
          "moon",
          "face"
        ],
        "name": "new_moon_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ib18c06z.png",
        "icon": "🌚",
        "shortcode": ":new_moon_face:",
        "unicodes": [
          "u1f31a"
        ]
      },
      {
        "search": [
          "first",
          "quarter",
          "moon",
          "face"
        ],
        "name": "first_quarter_moon_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/r6t5yihn.png",
        "icon": "🌛",
        "shortcode": ":first_quarter_moon_face:",
        "unicodes": [
          "u1f31b"
        ]
      },
      {
        "search": [
          "last",
          "quarter",
          "moon",
          "face"
        ],
        "name": "last_quarter_moon_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/69j1qzr3.png",
        "icon": "🌜",
        "shortcode": ":last_quarter_moon_face:",
        "unicodes": [
          "u1f31c"
        ]
      },
      {
        "search": [
          "thermometer"
        ],
        "name": "thermometer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2iwb2bd4.png",
        "icon": "🌡",
        "shortcode": ":thermometer:",
        "unicodes": [
          "u1f321"
        ]
      },
      {
        "search": [
          "sun"
        ],
        "name": "sun",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/l43czf5e.png",
        "icon": "☀",
        "shortcode": ":sun:",
        "unicodes": [
          "u2600"
        ]
      },
      {
        "search": [
          "full",
          "moon",
          "face"
        ],
        "name": "full_moon_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wt6juq8o.png",
        "icon": "🌝",
        "shortcode": ":full_moon_face:",
        "unicodes": [
          "u1f31d"
        ]
      },
      {
        "search": [
          "sun",
          "with",
          "face"
        ],
        "name": "sun_with_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wvoixry2.png",
        "icon": "🌞",
        "shortcode": ":sun_with_face:",
        "unicodes": [
          "u1f31e"
        ]
      },
      {
        "search": [
          "ringed",
          "planet"
        ],
        "name": "ringed_planet",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nffcsg7q.png",
        "icon": "🪐",
        "shortcode": ":ringed_planet:",
        "unicodes": [
          "u1fa90"
        ]
      },
      {
        "search": [
          "star"
        ],
        "name": "star",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0n7ylojj.png",
        "icon": "⭐",
        "shortcode": ":star:",
        "unicodes": [
          "u2b50"
        ]
      },
      {
        "search": [
          "glowing",
          "star"
        ],
        "name": "glowing_star",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f7z9k3q0.png",
        "icon": "🌟",
        "shortcode": ":glowing_star:",
        "unicodes": [
          "u1f31f"
        ]
      },
      {
        "search": [
          "shooting",
          "star"
        ],
        "name": "shooting_star",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/l55izym3.png",
        "icon": "🌠",
        "shortcode": ":shooting_star:",
        "unicodes": [
          "u1f320"
        ]
      },
      {
        "search": [
          "milky",
          "way"
        ],
        "name": "milky_way",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qcrkoswu.png",
        "icon": "🌌",
        "shortcode": ":milky_way:",
        "unicodes": [
          "u1f30c"
        ]
      },
      {
        "search": [
          "cloud"
        ],
        "name": "cloud",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xdyrapf6.png",
        "icon": "☁",
        "shortcode": ":cloud:",
        "unicodes": [
          "u2601"
        ]
      },
      {
        "search": [
          "sun",
          "behind",
          "cloud"
        ],
        "name": "sun_behind_cloud",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1sd75nzf.png",
        "icon": "⛅",
        "shortcode": ":sun_behind_cloud:",
        "unicodes": [
          "u26c5"
        ]
      },
      {
        "search": [
          "cloud",
          "with",
          "lightning",
          "and",
          "rain"
        ],
        "name": "cloud_with_lightning_and_rain",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8pwa08e5.png",
        "icon": "⛈",
        "shortcode": ":cloud_with_lightning_and_rain:",
        "unicodes": [
          "u26c8"
        ]
      },
      {
        "search": [
          "sun",
          "behind",
          "small",
          "cloud"
        ],
        "name": "sun_behind_small_cloud",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s39ddo37.png",
        "icon": "🌤",
        "shortcode": ":sun_behind_small_cloud:",
        "unicodes": [
          "u1f324"
        ]
      },
      {
        "search": [
          "sun",
          "behind",
          "large",
          "cloud"
        ],
        "name": "sun_behind_large_cloud",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8658wwuc.png",
        "icon": "🌥",
        "shortcode": ":sun_behind_large_cloud:",
        "unicodes": [
          "u1f325"
        ]
      },
      {
        "search": [
          "sun",
          "behind",
          "rain",
          "cloud"
        ],
        "name": "sun_behind_rain_cloud",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gebfla79.png",
        "icon": "🌦",
        "shortcode": ":sun_behind_rain_cloud:",
        "unicodes": [
          "u1f326"
        ]
      },
      {
        "search": [
          "cloud",
          "with",
          "rain"
        ],
        "name": "cloud_with_rain",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/02t2d4ix.png",
        "icon": "🌧",
        "shortcode": ":cloud_with_rain:",
        "unicodes": [
          "u1f327"
        ]
      },
      {
        "search": [
          "cloud",
          "with",
          "snow"
        ],
        "name": "cloud_with_snow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/07gp027e.png",
        "icon": "🌨",
        "shortcode": ":cloud_with_snow:",
        "unicodes": [
          "u1f328"
        ]
      },
      {
        "search": [
          "cloud",
          "with",
          "lightning"
        ],
        "name": "cloud_with_lightning",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/206fx23u.png",
        "icon": "🌩",
        "shortcode": ":cloud_with_lightning:",
        "unicodes": [
          "u1f329"
        ]
      },
      {
        "search": [
          "tornado"
        ],
        "name": "tornado",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/17z230ib.png",
        "icon": "🌪",
        "shortcode": ":tornado:",
        "unicodes": [
          "u1f32a"
        ]
      },
      {
        "search": [
          "fog"
        ],
        "name": "fog",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a35waq25.png",
        "icon": "🌫",
        "shortcode": ":fog:",
        "unicodes": [
          "u1f32b"
        ]
      },
      {
        "search": [
          "wind",
          "face"
        ],
        "name": "wind_face",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v8u5iqhk.png",
        "icon": "🌬",
        "shortcode": ":wind_face:",
        "unicodes": [
          "u1f32c"
        ]
      },
      {
        "search": [
          "cyclone"
        ],
        "name": "cyclone",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6h1pe8cq.png",
        "icon": "🌀",
        "shortcode": ":cyclone:",
        "unicodes": [
          "u1f300"
        ]
      },
      {
        "search": [
          "rainbow"
        ],
        "name": "rainbow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xvsc74uq.png",
        "icon": "🌈",
        "shortcode": ":rainbow:",
        "unicodes": [
          "u1f308"
        ]
      },
      {
        "search": [
          "closed",
          "umbrella"
        ],
        "name": "closed_umbrella",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9h6v1ubc.png",
        "icon": "🌂",
        "shortcode": ":closed_umbrella:",
        "unicodes": [
          "u1f302"
        ]
      },
      {
        "search": [
          "umbrella"
        ],
        "name": "umbrella",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wsfojp2v.png",
        "icon": "☂",
        "shortcode": ":umbrella:",
        "unicodes": [
          "u2602"
        ]
      },
      {
        "search": [
          "umbrella",
          "with",
          "rain",
          "drops"
        ],
        "name": "umbrella_with_rain_drops",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/893slmdv.png",
        "icon": "☔",
        "shortcode": ":umbrella_with_rain_drops:",
        "unicodes": [
          "u2614"
        ]
      },
      {
        "search": [
          "umbrella",
          "on",
          "ground"
        ],
        "name": "umbrella_on_ground",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j2cebbwq.png",
        "icon": "⛱",
        "shortcode": ":umbrella_on_ground:",
        "unicodes": [
          "u26f1"
        ]
      },
      {
        "search": [
          "high",
          "voltage"
        ],
        "name": "high_voltage",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/q1nezrin.png",
        "icon": "⚡",
        "shortcode": ":high_voltage:",
        "unicodes": [
          "u26a1"
        ]
      },
      {
        "search": [
          "snowflake"
        ],
        "name": "snowflake",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wm4xu7y8.png",
        "icon": "❄",
        "shortcode": ":snowflake:",
        "unicodes": [
          "u2744"
        ]
      },
      {
        "search": [
          "snowman"
        ],
        "name": "snowman",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8nxt1pwh.png",
        "icon": "☃",
        "shortcode": ":snowman:",
        "unicodes": [
          "u2603"
        ]
      },
      {
        "search": [
          "snowman",
          "without",
          "snow"
        ],
        "name": "snowman_without_snow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5g9zp9np.png",
        "icon": "⛄",
        "shortcode": ":snowman_without_snow:",
        "unicodes": [
          "u26c4"
        ]
      },
      {
        "search": [
          "comet"
        ],
        "name": "comet",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/m8xuglrc.png",
        "icon": "☄",
        "shortcode": ":comet:",
        "unicodes": [
          "u2604"
        ]
      },
      {
        "search": [
          "fire"
        ],
        "name": "fire",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mrfnt78d.png",
        "icon": "🔥",
        "shortcode": ":fire:",
        "unicodes": [
          "u1f525"
        ]
      },
      {
        "search": [
          "droplet"
        ],
        "name": "droplet",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lrhqdrkp.png",
        "icon": "💧",
        "shortcode": ":droplet:",
        "unicodes": [
          "u1f4a7"
        ]
      },
      {
        "search": [
          "water",
          "wave"
        ],
        "name": "water_wave",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/r8lqklpw.png",
        "icon": "🌊",
        "shortcode": ":water_wave:",
        "unicodes": [
          "u1f30a"
        ]
      }
    ]
  },
  {
    "category": "activities",
    "text": "Activities",
    "icons": [
      {
        "search": [
          "jack_o_lantern"
        ],
        "name": "jack_o_lantern",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d02v7l6a.png",
        "icon": "🎃",
        "shortcode": ":jack_o_lantern:",
        "unicodes": [
          "u1f383"
        ]
      },
      {
        "search": [
          "Christmas",
          "tree"
        ],
        "name": "Christmas_tree",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2xa2zxdb.png",
        "icon": "🎄",
        "shortcode": ":christmas_tree:",
        "unicodes": [
          "u1f384"
        ]
      },
      {
        "search": [
          "fireworks"
        ],
        "name": "fireworks",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/231urcw3.png",
        "icon": "🎆",
        "shortcode": ":fireworks:",
        "unicodes": [
          "u1f386"
        ]
      },
      {
        "search": [
          "sparkler"
        ],
        "name": "sparkler",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ot4vnkjf.png",
        "icon": "🎇",
        "shortcode": ":sparkler:",
        "unicodes": [
          "u1f387"
        ]
      },
      {
        "search": [
          "firecracker"
        ],
        "name": "firecracker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pq10uu3m.png",
        "icon": "🧨",
        "shortcode": ":firecracker:",
        "unicodes": [
          "u1f9e8"
        ]
      },
      {
        "search": [
          "sparkles"
        ],
        "name": "sparkles",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/m3o2so5i.png",
        "icon": "✨",
        "shortcode": ":sparkles:",
        "unicodes": [
          "u2728"
        ]
      },
      {
        "search": [
          "balloon"
        ],
        "name": "balloon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7gnvxqlk.png",
        "icon": "🎈",
        "shortcode": ":balloon:",
        "unicodes": [
          "u1f388"
        ]
      },
      {
        "search": [
          "party",
          "popper"
        ],
        "name": "party_popper",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/22zy5opj.png",
        "icon": "🎉",
        "shortcode": ":party_popper:",
        "unicodes": [
          "u1f389"
        ]
      },
      {
        "search": [
          "confetti",
          "ball"
        ],
        "name": "confetti_ball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9jjcnhis.png",
        "icon": "🎊",
        "shortcode": ":confetti_ball:",
        "unicodes": [
          "u1f38a"
        ]
      },
      {
        "search": [
          "tanabata",
          "tree"
        ],
        "name": "tanabata_tree",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h43dlpj9.png",
        "icon": "🎋",
        "shortcode": ":tanabata_tree:",
        "unicodes": [
          "u1f38b"
        ]
      },
      {
        "search": [
          "pine",
          "decoration"
        ],
        "name": "pine_decoration",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5duszibs.png",
        "icon": "🎍",
        "shortcode": ":pine_decoration:",
        "unicodes": [
          "u1f38d"
        ]
      },
      {
        "search": [
          "Japanese",
          "dolls"
        ],
        "name": "Japanese_dolls",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h6iv5689.png",
        "icon": "🎎",
        "shortcode": ":japanese_dolls:",
        "unicodes": [
          "u1f38e"
        ]
      },
      {
        "search": [
          "carp",
          "streamer"
        ],
        "name": "carp_streamer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/y4tz5mhb.png",
        "icon": "🎏",
        "shortcode": ":carp_streamer:",
        "unicodes": [
          "u1f38f"
        ]
      },
      {
        "search": [
          "wind",
          "chime"
        ],
        "name": "wind_chime",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nqf75pba.png",
        "icon": "🎐",
        "shortcode": ":wind_chime:",
        "unicodes": [
          "u1f390"
        ]
      },
      {
        "search": [
          "moon",
          "viewing",
          "ceremony"
        ],
        "name": "moon_viewing_ceremony",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4c7gl3pg.png",
        "icon": "🎑",
        "shortcode": ":moon_viewing_ceremony:",
        "unicodes": [
          "u1f391"
        ]
      },
      {
        "search": [
          "red",
          "envelope"
        ],
        "name": "red_envelope",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pem7bn56.png",
        "icon": "🧧",
        "shortcode": ":red_envelope:",
        "unicodes": [
          "u1f9e7"
        ]
      },
      {
        "search": [
          "ribbon"
        ],
        "name": "ribbon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yhd2t695.png",
        "icon": "🎀",
        "shortcode": ":ribbon:",
        "unicodes": [
          "u1f380"
        ]
      },
      {
        "search": [
          "wrapped",
          "gift"
        ],
        "name": "wrapped_gift",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4cw0ejh9.png",
        "icon": "🎁",
        "shortcode": ":wrapped_gift:",
        "unicodes": [
          "u1f381"
        ]
      },
      {
        "search": [
          "reminder",
          "ribbon"
        ],
        "name": "reminder_ribbon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ey0bbnq1.png",
        "icon": "🎗",
        "shortcode": ":reminder_ribbon:",
        "unicodes": [
          "u1f397"
        ]
      },
      {
        "search": [
          "admission",
          "tickets"
        ],
        "name": "admission_tickets",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hxuhnte4.png",
        "icon": "🎟",
        "shortcode": ":admission_tickets:",
        "unicodes": [
          "u1f39f"
        ]
      },
      {
        "search": [
          "ticket"
        ],
        "name": "ticket",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/crb3n9e2.png",
        "icon": "🎫",
        "shortcode": ":ticket:",
        "unicodes": [
          "u1f3ab"
        ]
      },
      {
        "search": [
          "military",
          "medal"
        ],
        "name": "military_medal",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2rsvbqio.png",
        "icon": "🎖",
        "shortcode": ":military_medal:",
        "unicodes": [
          "u1f396"
        ]
      },
      {
        "search": [
          "trophy"
        ],
        "name": "trophy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/osoiuok2.png",
        "icon": "🏆",
        "shortcode": ":trophy:",
        "unicodes": [
          "u1f3c6"
        ]
      },
      {
        "search": [
          "sports",
          "medal"
        ],
        "name": "sports_medal",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0sryzsbv.png",
        "icon": "🏅",
        "shortcode": ":sports_medal:",
        "unicodes": [
          "u1f3c5"
        ]
      },
      {
        "search": [
          "1st",
          "place",
          "medal"
        ],
        "name": "1st_place_medal",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j57vhyn1.png",
        "icon": "🥇",
        "shortcode": ":1st_place_medal:",
        "unicodes": [
          "u1f947"
        ]
      },
      {
        "search": [
          "2nd",
          "place",
          "medal"
        ],
        "name": "2nd_place_medal",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fjuhbkc7.png",
        "icon": "🥈",
        "shortcode": ":2nd_place_medal:",
        "unicodes": [
          "u1f948"
        ]
      },
      {
        "search": [
          "3rd",
          "place",
          "medal"
        ],
        "name": "3rd_place_medal",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6tb4jjqu.png",
        "icon": "🥉",
        "shortcode": ":3rd_place_medal:",
        "unicodes": [
          "u1f949"
        ]
      },
      {
        "search": [
          "soccer",
          "ball"
        ],
        "name": "soccer_ball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/klyzbjrm.png",
        "icon": "⚽",
        "shortcode": ":soccer_ball:",
        "unicodes": [
          "u26bd"
        ]
      },
      {
        "search": [
          "baseball"
        ],
        "name": "baseball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/amtwrz2i.png",
        "icon": "⚾",
        "shortcode": ":baseball:",
        "unicodes": [
          "u26be"
        ]
      },
      {
        "search": [
          "softball"
        ],
        "name": "softball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lz14xe0d.png",
        "icon": "🥎",
        "shortcode": ":softball:",
        "unicodes": [
          "u1f94e"
        ]
      },
      {
        "search": [
          "basketball"
        ],
        "name": "basketball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/24420wzx.png",
        "icon": "🏀",
        "shortcode": ":basketball:",
        "unicodes": [
          "u1f3c0"
        ]
      },
      {
        "search": [
          "volleyball"
        ],
        "name": "volleyball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a427bou8.png",
        "icon": "🏐",
        "shortcode": ":volleyball:",
        "unicodes": [
          "u1f3d0"
        ]
      },
      {
        "search": [
          "american",
          "football"
        ],
        "name": "american_football",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pgomjht6.png",
        "icon": "🏈",
        "shortcode": ":american_football:",
        "unicodes": [
          "u1f3c8"
        ]
      },
      {
        "search": [
          "rugby",
          "football"
        ],
        "name": "rugby_football",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zi12xzn0.png",
        "icon": "🏉",
        "shortcode": ":rugby_football:",
        "unicodes": [
          "u1f3c9"
        ]
      },
      {
        "search": [
          "tennis"
        ],
        "name": "tennis",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/c24psxzq.png",
        "icon": "🎾",
        "shortcode": ":tennis:",
        "unicodes": [
          "u1f3be"
        ]
      },
      {
        "search": [
          "flying",
          "disc"
        ],
        "name": "flying_disc",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hninpyct.png",
        "icon": "🥏",
        "shortcode": ":flying_disc:",
        "unicodes": [
          "u1f94f"
        ]
      },
      {
        "search": [
          "bowling"
        ],
        "name": "bowling",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lgqev517.png",
        "icon": "🎳",
        "shortcode": ":bowling:",
        "unicodes": [
          "u1f3b3"
        ]
      },
      {
        "search": [
          "cricket",
          "game"
        ],
        "name": "cricket_game",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/18wl83vd.png",
        "icon": "🏏",
        "shortcode": ":cricket_game:",
        "unicodes": [
          "u1f3cf"
        ]
      },
      {
        "search": [
          "field",
          "hockey"
        ],
        "name": "field_hockey",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jfsvxwbd.png",
        "icon": "🏑",
        "shortcode": ":field_hockey:",
        "unicodes": [
          "u1f3d1"
        ]
      },
      {
        "search": [
          "ice",
          "hockey"
        ],
        "name": "ice_hockey",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3pss3ij9.png",
        "icon": "🏒",
        "shortcode": ":ice_hockey:",
        "unicodes": [
          "u1f3d2"
        ]
      },
      {
        "search": [
          "lacrosse"
        ],
        "name": "lacrosse",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sjhm6iru.png",
        "icon": "🥍",
        "shortcode": ":lacrosse:",
        "unicodes": [
          "u1f94d"
        ]
      },
      {
        "search": [
          "ping",
          "pong"
        ],
        "name": "ping_pong",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tu6irj4y.png",
        "icon": "🏓",
        "shortcode": ":ping_pong:",
        "unicodes": [
          "u1f3d3"
        ]
      },
      {
        "search": [
          "badminton"
        ],
        "name": "badminton",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6yhgz8f1.png",
        "icon": "🏸",
        "shortcode": ":badminton:",
        "unicodes": [
          "u1f3f8"
        ]
      },
      {
        "search": [
          "boxing",
          "glove"
        ],
        "name": "boxing_glove",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xa6rmsmb.png",
        "icon": "🥊",
        "shortcode": ":boxing_glove:",
        "unicodes": [
          "u1f94a"
        ]
      },
      {
        "search": [
          "martial",
          "arts",
          "uniform"
        ],
        "name": "martial_arts_uniform",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4b78m4hj.png",
        "icon": "🥋",
        "shortcode": ":martial_arts_uniform:",
        "unicodes": [
          "u1f94b"
        ]
      },
      {
        "search": [
          "goal",
          "net"
        ],
        "name": "goal_net",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bpdhv0my.png",
        "icon": "🥅",
        "shortcode": ":goal_net:",
        "unicodes": [
          "u1f945"
        ]
      },
      {
        "search": [
          "flag",
          "in",
          "hole"
        ],
        "name": "flag_in_hole",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ukiqktoe.png",
        "icon": "⛳",
        "shortcode": ":flag_in_hole:",
        "unicodes": [
          "u26f3"
        ]
      },
      {
        "search": [
          "ice",
          "skate"
        ],
        "name": "ice_skate",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8m3bm3gg.png",
        "icon": "⛸",
        "shortcode": ":ice_skate:",
        "unicodes": [
          "u26f8"
        ]
      },
      {
        "search": [
          "fishing",
          "pole"
        ],
        "name": "fishing_pole",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0tkl7am7.png",
        "icon": "🎣",
        "shortcode": ":fishing_pole:",
        "unicodes": [
          "u1f3a3"
        ]
      },
      {
        "search": [
          "diving",
          "mask"
        ],
        "name": "diving_mask",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d0csvxf5.png",
        "icon": "🤿",
        "shortcode": ":diving_mask:",
        "unicodes": [
          "u1f93f"
        ]
      },
      {
        "search": [
          "running",
          "shirt"
        ],
        "name": "running_shirt",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ml198buc.png",
        "icon": "🎽",
        "shortcode": ":running_shirt:",
        "unicodes": [
          "u1f3bd"
        ]
      },
      {
        "search": [
          "skis"
        ],
        "name": "skis",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wjqwzxs9.png",
        "icon": "🎿",
        "shortcode": ":skis:",
        "unicodes": [
          "u1f3bf"
        ]
      },
      {
        "search": [
          "sled"
        ],
        "name": "sled",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ly9oj3dg.png",
        "icon": "🛷",
        "shortcode": ":sled:",
        "unicodes": [
          "u1f6f7"
        ]
      },
      {
        "search": [
          "curling",
          "stone"
        ],
        "name": "curling_stone",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/se7vcfq7.png",
        "icon": "🥌",
        "shortcode": ":curling_stone:",
        "unicodes": [
          "u1f94c"
        ]
      },
      {
        "search": [
          "bullseye"
        ],
        "name": "bullseye",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/237tth1s.png",
        "icon": "🎯",
        "shortcode": ":bullseye:",
        "unicodes": [
          "u1f3af"
        ]
      },
      {
        "search": [
          "yo_yo"
        ],
        "name": "yo_yo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ftg8xcl7.png",
        "icon": "🪀",
        "shortcode": ":yo_yo:",
        "unicodes": [
          "u1fa80"
        ]
      },
      {
        "search": [
          "kite"
        ],
        "name": "kite",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7xh7jogk.png",
        "icon": "🪁",
        "shortcode": ":kite:",
        "unicodes": [
          "u1fa81"
        ]
      },
      {
        "search": [
          "water",
          "pistol"
        ],
        "name": "water_pistol",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2qu5y1pb.png",
        "icon": "🔫",
        "shortcode": ":water_pistol:",
        "unicodes": [
          "u1f52b"
        ]
      },
      {
        "search": [
          "pool",
          "8",
          "ball"
        ],
        "name": "pool_8_ball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/x3os2pb0.png",
        "icon": "🎱",
        "shortcode": ":pool_8_ball:",
        "unicodes": [
          "u1f3b1"
        ]
      },
      {
        "search": [
          "crystal",
          "ball"
        ],
        "name": "crystal_ball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/q7ufap8e.png",
        "icon": "🔮",
        "shortcode": ":crystal_ball:",
        "unicodes": [
          "u1f52e"
        ]
      },
      {
        "search": [
          "magic",
          "wand"
        ],
        "name": "magic_wand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2cvkbgff.png",
        "icon": "🪄",
        "shortcode": ":magic_wand:",
        "unicodes": [
          "u1fa84"
        ]
      },
      {
        "search": [
          "video",
          "game"
        ],
        "name": "video_game",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tuhdi0u0.png",
        "icon": "🎮",
        "shortcode": ":video_game:",
        "unicodes": [
          "u1f3ae"
        ]
      },
      {
        "search": [
          "joystick"
        ],
        "name": "joystick",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/122m05qy.png",
        "icon": "🕹",
        "shortcode": ":joystick:",
        "unicodes": [
          "u1f579"
        ]
      },
      {
        "search": [
          "slot",
          "machine"
        ],
        "name": "slot_machine",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iiw8hdb8.png",
        "icon": "🎰",
        "shortcode": ":slot_machine:",
        "unicodes": [
          "u1f3b0"
        ]
      },
      {
        "search": [
          "game",
          "die"
        ],
        "name": "game_die",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ye1rkv1n.png",
        "icon": "🎲",
        "shortcode": ":game_die:",
        "unicodes": [
          "u1f3b2"
        ]
      },
      {
        "search": [
          "puzzle",
          "piece"
        ],
        "name": "puzzle_piece",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8to80bxd.png",
        "icon": "🧩",
        "shortcode": ":puzzle_piece:",
        "unicodes": [
          "u1f9e9"
        ]
      },
      {
        "search": [
          "teddy",
          "bear"
        ],
        "name": "teddy_bear",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/khhl2v7t.png",
        "icon": "🧸",
        "shortcode": ":teddy_bear:",
        "unicodes": [
          "u1f9f8"
        ]
      },
      {
        "search": [
          "piñata"
        ],
        "name": "piñata",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1xjhksrr.png",
        "icon": "🪅",
        "shortcode": ":piñata:",
        "unicodes": [
          "u1fa85"
        ]
      },
      {
        "search": [
          "mirror",
          "ball"
        ],
        "name": "mirror_ball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2jkw6qx2.png",
        "icon": "🪩",
        "shortcode": ":mirror_ball:",
        "unicodes": [
          "u1faa9"
        ]
      },
      {
        "search": [
          "nesting",
          "dolls"
        ],
        "name": "nesting_dolls",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/evsvwtjj.png",
        "icon": "🪆",
        "shortcode": ":nesting_dolls:",
        "unicodes": [
          "u1fa86"
        ]
      },
      {
        "search": [
          "spade",
          "suit"
        ],
        "name": "spade_suit",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a5tj64mg.png",
        "icon": "♠",
        "shortcode": ":spade_suit:",
        "unicodes": [
          "u2660"
        ]
      },
      {
        "search": [
          "heart",
          "suit"
        ],
        "name": "heart_suit",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w3b0aps5.png",
        "icon": "♥",
        "shortcode": ":heart_suit:",
        "unicodes": [
          "u2665"
        ]
      },
      {
        "search": [
          "diamond",
          "suit"
        ],
        "name": "diamond_suit",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h96ydt1h.png",
        "icon": "♦",
        "shortcode": ":diamond_suit:",
        "unicodes": [
          "u2666"
        ]
      },
      {
        "search": [
          "club",
          "suit"
        ],
        "name": "club_suit",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wpwcuz5i.png",
        "icon": "♣",
        "shortcode": ":club_suit:",
        "unicodes": [
          "u2663"
        ]
      },
      {
        "search": [
          "chess",
          "pawn"
        ],
        "name": "chess_pawn",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6eqp14b2.png",
        "icon": "♟",
        "shortcode": ":chess_pawn:",
        "unicodes": [
          "u265f"
        ]
      },
      {
        "search": [
          "joker"
        ],
        "name": "joker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4ikjm2ps.png",
        "icon": "🃏",
        "shortcode": ":joker:",
        "unicodes": [
          "u1f0cf"
        ]
      },
      {
        "search": [
          "mahjong",
          "red",
          "dragon"
        ],
        "name": "mahjong_red_dragon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a5516ts2.png",
        "icon": "🀄",
        "shortcode": ":mahjong_red_dragon:",
        "unicodes": [
          "u1f004"
        ]
      },
      {
        "search": [
          "flower",
          "playing",
          "cards"
        ],
        "name": "flower_playing_cards",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tg8rxlg6.png",
        "icon": "🎴",
        "shortcode": ":flower_playing_cards:",
        "unicodes": [
          "u1f3b4"
        ]
      },
      {
        "search": [
          "performing",
          "arts"
        ],
        "name": "performing_arts",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sxibgwds.png",
        "icon": "🎭",
        "shortcode": ":performing_arts:",
        "unicodes": [
          "u1f3ad"
        ]
      },
      {
        "search": [
          "framed",
          "picture"
        ],
        "name": "framed_picture",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s428qbzk.png",
        "icon": "🖼",
        "shortcode": ":framed_picture:",
        "unicodes": [
          "u1f5bc"
        ]
      },
      {
        "search": [
          "artist",
          "palette"
        ],
        "name": "artist_palette",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uacnyyh2.png",
        "icon": "🎨",
        "shortcode": ":artist_palette:",
        "unicodes": [
          "u1f3a8"
        ]
      },
      {
        "search": [
          "thread"
        ],
        "name": "thread",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lfhymn12.png",
        "icon": "🧵",
        "shortcode": ":thread:",
        "unicodes": [
          "u1f9f5"
        ]
      },
      {
        "search": [
          "sewing",
          "needle"
        ],
        "name": "sewing_needle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3o05f59y.png",
        "icon": "🪡",
        "shortcode": ":sewing_needle:",
        "unicodes": [
          "u1faa1"
        ]
      },
      {
        "search": [
          "yarn"
        ],
        "name": "yarn",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nfny8ktx.png",
        "icon": "🧶",
        "shortcode": ":yarn:",
        "unicodes": [
          "u1f9f6"
        ]
      },
      {
        "search": [
          "knot"
        ],
        "name": "knot",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fur1n4zh.png",
        "icon": "🪢",
        "shortcode": ":knot:",
        "unicodes": [
          "u1faa2"
        ]
      }
    ]
  },
  {
    "category": "objects",
    "text": "Objects",
    "icons": [
      {
        "search": [
          "glasses"
        ],
        "name": "glasses",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9m31lgb2.png",
        "icon": "👓",
        "shortcode": ":glasses:",
        "unicodes": [
          "u1f453"
        ]
      },
      {
        "search": [
          "sunglasses"
        ],
        "name": "sunglasses",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uncsla3i.png",
        "icon": "🕶",
        "shortcode": ":sunglasses:",
        "unicodes": [
          "u1f576"
        ]
      },
      {
        "search": [
          "goggles"
        ],
        "name": "goggles",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sdusjh3x.png",
        "icon": "🥽",
        "shortcode": ":goggles:",
        "unicodes": [
          "u1f97d"
        ]
      },
      {
        "search": [
          "lab",
          "coat"
        ],
        "name": "lab_coat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/joex14b2.png",
        "icon": "🥼",
        "shortcode": ":lab_coat:",
        "unicodes": [
          "u1f97c"
        ]
      },
      {
        "search": [
          "safety",
          "vest"
        ],
        "name": "safety_vest",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ufc1022s.png",
        "icon": "🦺",
        "shortcode": ":safety_vest:",
        "unicodes": [
          "u1f9ba"
        ]
      },
      {
        "search": [
          "necktie"
        ],
        "name": "necktie",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8su30gs8.png",
        "icon": "👔",
        "shortcode": ":necktie:",
        "unicodes": [
          "u1f454"
        ]
      },
      {
        "search": [
          "t_shirt"
        ],
        "name": "t_shirt",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/65qpoq3s.png",
        "icon": "👕",
        "shortcode": ":t_shirt:",
        "unicodes": [
          "u1f455"
        ]
      },
      {
        "search": [
          "jeans"
        ],
        "name": "jeans",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/c5jyu6ss.png",
        "icon": "👖",
        "shortcode": ":jeans:",
        "unicodes": [
          "u1f456"
        ]
      },
      {
        "search": [
          "scarf"
        ],
        "name": "scarf",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5v05wair.png",
        "icon": "🧣",
        "shortcode": ":scarf:",
        "unicodes": [
          "u1f9e3"
        ]
      },
      {
        "search": [
          "gloves"
        ],
        "name": "gloves",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zgrmsgii.png",
        "icon": "🧤",
        "shortcode": ":gloves:",
        "unicodes": [
          "u1f9e4"
        ]
      },
      {
        "search": [
          "coat"
        ],
        "name": "coat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8frfllql.png",
        "icon": "🧥",
        "shortcode": ":coat:",
        "unicodes": [
          "u1f9e5"
        ]
      },
      {
        "search": [
          "socks"
        ],
        "name": "socks",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gvmpc214.png",
        "icon": "🧦",
        "shortcode": ":socks:",
        "unicodes": [
          "u1f9e6"
        ]
      },
      {
        "search": [
          "dress"
        ],
        "name": "dress",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zd8la17b.png",
        "icon": "👗",
        "shortcode": ":dress:",
        "unicodes": [
          "u1f457"
        ]
      },
      {
        "search": [
          "kimono"
        ],
        "name": "kimono",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3n4nsnzc.png",
        "icon": "👘",
        "shortcode": ":kimono:",
        "unicodes": [
          "u1f458"
        ]
      },
      {
        "search": [
          "sari"
        ],
        "name": "sari",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0gh1ut97.png",
        "icon": "🥻",
        "shortcode": ":sari:",
        "unicodes": [
          "u1f97b"
        ]
      },
      {
        "search": [
          "one_piece",
          "swimsuit"
        ],
        "name": "one_piece_swimsuit",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v610uts6.png",
        "icon": "🩱",
        "shortcode": ":one_piece_swimsuit:",
        "unicodes": [
          "u1fa71"
        ]
      },
      {
        "search": [
          "briefs"
        ],
        "name": "briefs",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/p5ieanaj.png",
        "icon": "🩲",
        "shortcode": ":briefs:",
        "unicodes": [
          "u1fa72"
        ]
      },
      {
        "search": [
          "shorts"
        ],
        "name": "shorts",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/y5c3ofon.png",
        "icon": "🩳",
        "shortcode": ":shorts:",
        "unicodes": [
          "u1fa73"
        ]
      },
      {
        "search": [
          "bikini"
        ],
        "name": "bikini",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wxbtren2.png",
        "icon": "👙",
        "shortcode": ":bikini:",
        "unicodes": [
          "u1f459"
        ]
      },
      {
        "search": [
          "woman’s",
          "clothes"
        ],
        "name": "woman’s_clothes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9rxspmq0.png",
        "icon": "👚",
        "shortcode": ":womans_clothes:",
        "unicodes": [
          "u1f45a"
        ]
      },
      {
        "search": [
          "purse"
        ],
        "name": "purse",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tz04i7fv.png",
        "icon": "👛",
        "shortcode": ":purse:",
        "unicodes": [
          "u1f45b"
        ]
      },
      {
        "search": [
          "handbag"
        ],
        "name": "handbag",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/m8dmy4k9.png",
        "icon": "👜",
        "shortcode": ":handbag:",
        "unicodes": [
          "u1f45c"
        ]
      },
      {
        "search": [
          "clutch",
          "bag"
        ],
        "name": "clutch_bag",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fzakd9q3.png",
        "icon": "👝",
        "shortcode": ":clutch_bag:",
        "unicodes": [
          "u1f45d"
        ]
      },
      {
        "search": [
          "shopping",
          "bags"
        ],
        "name": "shopping_bags",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pt6godei.png",
        "icon": "🛍",
        "shortcode": ":shopping_bags:",
        "unicodes": [
          "u1f6cd"
        ]
      },
      {
        "search": [
          "backpack"
        ],
        "name": "backpack",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4mkaunln.png",
        "icon": "🎒",
        "shortcode": ":backpack:",
        "unicodes": [
          "u1f392"
        ]
      },
      {
        "search": [
          "thong",
          "sandal"
        ],
        "name": "thong_sandal",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/imdgbhlx.png",
        "icon": "🩴",
        "shortcode": ":thong_sandal:",
        "unicodes": [
          "u1fa74"
        ]
      },
      {
        "search": [
          "man’s",
          "shoe"
        ],
        "name": "man’s_shoe",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rmeomoei.png",
        "icon": "👞",
        "shortcode": ":mans_shoe:",
        "unicodes": [
          "u1f45e"
        ]
      },
      {
        "search": [
          "running",
          "shoe"
        ],
        "name": "running_shoe",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ogsxhc45.png",
        "icon": "👟",
        "shortcode": ":running_shoe:",
        "unicodes": [
          "u1f45f"
        ]
      },
      {
        "search": [
          "hiking",
          "boot"
        ],
        "name": "hiking_boot",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pfcn61gs.png",
        "icon": "🥾",
        "shortcode": ":hiking_boot:",
        "unicodes": [
          "u1f97e"
        ]
      },
      {
        "search": [
          "flat",
          "shoe"
        ],
        "name": "flat_shoe",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bghu0gfl.png",
        "icon": "🥿",
        "shortcode": ":flat_shoe:",
        "unicodes": [
          "u1f97f"
        ]
      },
      {
        "search": [
          "high_heeled",
          "shoe"
        ],
        "name": "high_heeled_shoe",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/y4lxb6nu.png",
        "icon": "👠",
        "shortcode": ":high_heeled_shoe:",
        "unicodes": [
          "u1f460"
        ]
      },
      {
        "search": [
          "woman’s",
          "sandal"
        ],
        "name": "woman’s_sandal",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rmnx0d5h.png",
        "icon": "👡",
        "shortcode": ":womans_sandal:",
        "unicodes": [
          "u1f461"
        ]
      },
      {
        "search": [
          "ballet",
          "shoes"
        ],
        "name": "ballet_shoes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/45ab90j2.png",
        "icon": "🩰",
        "shortcode": ":ballet_shoes:",
        "unicodes": [
          "u1fa70"
        ]
      },
      {
        "search": [
          "woman’s",
          "boot"
        ],
        "name": "woman’s_boot",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sjxcd2h3.png",
        "icon": "👢",
        "shortcode": ":womans_boot:",
        "unicodes": [
          "u1f462"
        ]
      },
      {
        "search": [
          "crown"
        ],
        "name": "crown",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/in3b0oem.png",
        "icon": "👑",
        "shortcode": ":crown:",
        "unicodes": [
          "u1f451"
        ]
      },
      {
        "search": [
          "woman’s",
          "hat"
        ],
        "name": "woman’s_hat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ee6uhhv3.png",
        "icon": "👒",
        "shortcode": ":womans_hat:",
        "unicodes": [
          "u1f452"
        ]
      },
      {
        "search": [
          "top",
          "hat"
        ],
        "name": "top_hat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/acviz680.png",
        "icon": "🎩",
        "shortcode": ":top_hat:",
        "unicodes": [
          "u1f3a9"
        ]
      },
      {
        "search": [
          "graduation",
          "cap"
        ],
        "name": "graduation_cap",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8i4noyhc.png",
        "icon": "🎓",
        "shortcode": ":graduation_cap:",
        "unicodes": [
          "u1f393"
        ]
      },
      {
        "search": [
          "billed",
          "cap"
        ],
        "name": "billed_cap",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ozdj732q.png",
        "icon": "🧢",
        "shortcode": ":billed_cap:",
        "unicodes": [
          "u1f9e2"
        ]
      },
      {
        "search": [
          "military",
          "helmet"
        ],
        "name": "military_helmet",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0gb7ydk8.png",
        "icon": "🪖",
        "shortcode": ":military_helmet:",
        "unicodes": [
          "u1fa96"
        ]
      },
      {
        "search": [
          "rescue",
          "worker’s",
          "helmet"
        ],
        "name": "rescue_worker’s_helmet",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/peahumf0.png",
        "icon": "⛑",
        "shortcode": ":rescue_workers_helmet:",
        "unicodes": [
          "u26d1"
        ]
      },
      {
        "search": [
          "prayer",
          "beads"
        ],
        "name": "prayer_beads",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h9tn3i8c.png",
        "icon": "📿",
        "shortcode": ":prayer_beads:",
        "unicodes": [
          "u1f4ff"
        ]
      },
      {
        "search": [
          "lipstick"
        ],
        "name": "lipstick",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ro34ezte.png",
        "icon": "💄",
        "shortcode": ":lipstick:",
        "unicodes": [
          "u1f484"
        ]
      },
      {
        "search": [
          "ring"
        ],
        "name": "ring",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/za89s94z.png",
        "icon": "💍",
        "shortcode": ":ring:",
        "unicodes": [
          "u1f48d"
        ]
      },
      {
        "search": [
          "gem",
          "stone"
        ],
        "name": "gem_stone",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wwhy6m9o.png",
        "icon": "💎",
        "shortcode": ":gem_stone:",
        "unicodes": [
          "u1f48e"
        ]
      },
      {
        "search": [
          "muted",
          "speaker"
        ],
        "name": "muted_speaker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/drfsaawt.png",
        "icon": "🔇",
        "shortcode": ":muted_speaker:",
        "unicodes": [
          "u1f507"
        ]
      },
      {
        "search": [
          "speaker",
          "low",
          "volume"
        ],
        "name": "speaker_low_volume",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ko0nz27f.png",
        "icon": "🔈",
        "shortcode": ":speaker_low_volume:",
        "unicodes": [
          "u1f508"
        ]
      },
      {
        "search": [
          "speaker",
          "medium",
          "volume"
        ],
        "name": "speaker_medium_volume",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iar73pd7.png",
        "icon": "🔉",
        "shortcode": ":speaker_medium_volume:",
        "unicodes": [
          "u1f509"
        ]
      },
      {
        "search": [
          "speaker",
          "high",
          "volume"
        ],
        "name": "speaker_high_volume",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vkqnq00k.png",
        "icon": "🔊",
        "shortcode": ":speaker_high_volume:",
        "unicodes": [
          "u1f50a"
        ]
      },
      {
        "search": [
          "loudspeaker"
        ],
        "name": "loudspeaker",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/p63ug0lu.png",
        "icon": "📢",
        "shortcode": ":loudspeaker:",
        "unicodes": [
          "u1f4e2"
        ]
      },
      {
        "search": [
          "megaphone"
        ],
        "name": "megaphone",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hhafhivg.png",
        "icon": "📣",
        "shortcode": ":megaphone:",
        "unicodes": [
          "u1f4e3"
        ]
      },
      {
        "search": [
          "postal",
          "horn"
        ],
        "name": "postal_horn",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ozkcmapg.png",
        "icon": "📯",
        "shortcode": ":postal_horn:",
        "unicodes": [
          "u1f4ef"
        ]
      },
      {
        "search": [
          "bell"
        ],
        "name": "bell",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/76ixakdp.png",
        "icon": "🔔",
        "shortcode": ":bell:",
        "unicodes": [
          "u1f514"
        ]
      },
      {
        "search": [
          "bell",
          "with",
          "slash"
        ],
        "name": "bell_with_slash",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/75c4w7ok.png",
        "icon": "🔕",
        "shortcode": ":bell_with_slash:",
        "unicodes": [
          "u1f515"
        ]
      },
      {
        "search": [
          "musical",
          "score"
        ],
        "name": "musical_score",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/z4r9q4l4.png",
        "icon": "🎼",
        "shortcode": ":musical_score:",
        "unicodes": [
          "u1f3bc"
        ]
      },
      {
        "search": [
          "musical",
          "note"
        ],
        "name": "musical_note",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/r7h2j7aa.png",
        "icon": "🎵",
        "shortcode": ":musical_note:",
        "unicodes": [
          "u1f3b5"
        ]
      },
      {
        "search": [
          "musical",
          "notes"
        ],
        "name": "musical_notes",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nrl1qv45.png",
        "icon": "🎶",
        "shortcode": ":musical_notes:",
        "unicodes": [
          "u1f3b6"
        ]
      },
      {
        "search": [
          "studio",
          "microphone"
        ],
        "name": "studio_microphone",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yt0yicf5.png",
        "icon": "🎙",
        "shortcode": ":studio_microphone:",
        "unicodes": [
          "u1f399"
        ]
      },
      {
        "search": [
          "level",
          "slider"
        ],
        "name": "level_slider",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gq7jwctc.png",
        "icon": "🎚",
        "shortcode": ":level_slider:",
        "unicodes": [
          "u1f39a"
        ]
      },
      {
        "search": [
          "control",
          "knobs"
        ],
        "name": "control_knobs",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/20oa54bj.png",
        "icon": "🎛",
        "shortcode": ":control_knobs:",
        "unicodes": [
          "u1f39b"
        ]
      },
      {
        "search": [
          "microphone"
        ],
        "name": "microphone",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pxbytupn.png",
        "icon": "🎤",
        "shortcode": ":microphone:",
        "unicodes": [
          "u1f3a4"
        ]
      },
      {
        "search": [
          "headphone"
        ],
        "name": "headphone",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ip5y0vod.png",
        "icon": "🎧",
        "shortcode": ":headphone:",
        "unicodes": [
          "u1f3a7"
        ]
      },
      {
        "search": [
          "radio"
        ],
        "name": "radio",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fssuoyna.png",
        "icon": "📻",
        "shortcode": ":radio:",
        "unicodes": [
          "u1f4fb"
        ]
      },
      {
        "search": [
          "saxophone"
        ],
        "name": "saxophone",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gi4r5t0k.png",
        "icon": "🎷",
        "shortcode": ":saxophone:",
        "unicodes": [
          "u1f3b7"
        ]
      },
      {
        "search": [
          "accordion"
        ],
        "name": "accordion",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g3jy8sgo.png",
        "icon": "🪗",
        "shortcode": ":accordion:",
        "unicodes": [
          "u1fa97"
        ]
      },
      {
        "search": [
          "guitar"
        ],
        "name": "guitar",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5zmommco.png",
        "icon": "🎸",
        "shortcode": ":guitar:",
        "unicodes": [
          "u1f3b8"
        ]
      },
      {
        "search": [
          "musical",
          "keyboard"
        ],
        "name": "musical_keyboard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/izwsgt3n.png",
        "icon": "🎹",
        "shortcode": ":musical_keyboard:",
        "unicodes": [
          "u1f3b9"
        ]
      },
      {
        "search": [
          "trumpet"
        ],
        "name": "trumpet",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v5qqyyol.png",
        "icon": "🎺",
        "shortcode": ":trumpet:",
        "unicodes": [
          "u1f3ba"
        ]
      },
      {
        "search": [
          "violin"
        ],
        "name": "violin",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4pudd0e8.png",
        "icon": "🎻",
        "shortcode": ":violin:",
        "unicodes": [
          "u1f3bb"
        ]
      },
      {
        "search": [
          "banjo"
        ],
        "name": "banjo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0fb2rwes.png",
        "icon": "🪕",
        "shortcode": ":banjo:",
        "unicodes": [
          "u1fa95"
        ]
      },
      {
        "search": [
          "drum"
        ],
        "name": "drum",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2utbq9ui.png",
        "icon": "🥁",
        "shortcode": ":drum:",
        "unicodes": [
          "u1f941"
        ]
      },
      {
        "search": [
          "long",
          "drum"
        ],
        "name": "long_drum",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/agf1hrcu.png",
        "icon": "🪘",
        "shortcode": ":long_drum:",
        "unicodes": [
          "u1fa98"
        ]
      },
      {
        "search": [
          "mobile",
          "phone"
        ],
        "name": "mobile_phone",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lglwhydx.png",
        "icon": "📱",
        "shortcode": ":mobile_phone:",
        "unicodes": [
          "u1f4f1"
        ]
      },
      {
        "search": [
          "mobile",
          "phone",
          "with",
          "arrow"
        ],
        "name": "mobile_phone_with_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/610y0zc7.png",
        "icon": "📲",
        "shortcode": ":mobile_phone_with_arrow:",
        "unicodes": [
          "u1f4f2"
        ]
      },
      {
        "search": [
          "telephone"
        ],
        "name": "telephone",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sm3d7mbk.png",
        "icon": "☎",
        "shortcode": ":telephone:",
        "unicodes": [
          "u260e"
        ]
      },
      {
        "search": [
          "telephone",
          "receiver"
        ],
        "name": "telephone_receiver",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sfbcucvu.png",
        "icon": "📞",
        "shortcode": ":telephone_receiver:",
        "unicodes": [
          "u1f4de"
        ]
      },
      {
        "search": [
          "pager"
        ],
        "name": "pager",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zfp7x8x4.png",
        "icon": "📟",
        "shortcode": ":pager:",
        "unicodes": [
          "u1f4df"
        ]
      },
      {
        "search": [
          "fax",
          "machine"
        ],
        "name": "fax_machine",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/59px4vap.png",
        "icon": "📠",
        "shortcode": ":fax_machine:",
        "unicodes": [
          "u1f4e0"
        ]
      },
      {
        "search": [
          "battery"
        ],
        "name": "battery",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uyj95dgs.png",
        "icon": "🔋",
        "shortcode": ":battery:",
        "unicodes": [
          "u1f50b"
        ]
      },
      {
        "search": [
          "low",
          "battery"
        ],
        "name": "low_battery",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/r2alec6i.png",
        "icon": "🪫",
        "shortcode": ":low_battery:",
        "unicodes": [
          "u1faab"
        ]
      },
      {
        "search": [
          "electric",
          "plug"
        ],
        "name": "electric_plug",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lzenbl7b.png",
        "icon": "🔌",
        "shortcode": ":electric_plug:",
        "unicodes": [
          "u1f50c"
        ]
      },
      {
        "search": [
          "laptop"
        ],
        "name": "laptop",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fc0eq8x9.png",
        "icon": "💻",
        "shortcode": ":laptop:",
        "unicodes": [
          "u1f4bb"
        ]
      },
      {
        "search": [
          "desktop",
          "computer"
        ],
        "name": "desktop_computer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/djgk8wtu.png",
        "icon": "🖥",
        "shortcode": ":desktop_computer:",
        "unicodes": [
          "u1f5a5"
        ]
      },
      {
        "search": [
          "printer"
        ],
        "name": "printer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h9u1na5p.png",
        "icon": "🖨",
        "shortcode": ":printer:",
        "unicodes": [
          "u1f5a8"
        ]
      },
      {
        "search": [
          "keyboard"
        ],
        "name": "keyboard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xd3ra5i5.png",
        "icon": "⌨",
        "shortcode": ":keyboard:",
        "unicodes": [
          "u2328"
        ]
      },
      {
        "search": [
          "computer",
          "mouse"
        ],
        "name": "computer_mouse",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s16lexnc.png",
        "icon": "🖱",
        "shortcode": ":computer_mouse:",
        "unicodes": [
          "u1f5b1"
        ]
      },
      {
        "search": [
          "trackball"
        ],
        "name": "trackball",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iusmqyh1.png",
        "icon": "🖲",
        "shortcode": ":trackball:",
        "unicodes": [
          "u1f5b2"
        ]
      },
      {
        "search": [
          "computer",
          "disk"
        ],
        "name": "computer_disk",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/io3c6635.png",
        "icon": "💽",
        "shortcode": ":computer_disk:",
        "unicodes": [
          "u1f4bd"
        ]
      },
      {
        "search": [
          "floppy",
          "disk"
        ],
        "name": "floppy_disk",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1z48yr0e.png",
        "icon": "💾",
        "shortcode": ":floppy_disk:",
        "unicodes": [
          "u1f4be"
        ]
      },
      {
        "search": [
          "optical",
          "disk"
        ],
        "name": "optical_disk",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5wyg0du7.png",
        "icon": "💿",
        "shortcode": ":optical_disk:",
        "unicodes": [
          "u1f4bf"
        ]
      },
      {
        "search": [
          "dvd"
        ],
        "name": "dvd",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hojobtbv.png",
        "icon": "📀",
        "shortcode": ":dvd:",
        "unicodes": [
          "u1f4c0"
        ]
      },
      {
        "search": [
          "abacus"
        ],
        "name": "abacus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8kjghi8a.png",
        "icon": "🧮",
        "shortcode": ":abacus:",
        "unicodes": [
          "u1f9ee"
        ]
      },
      {
        "search": [
          "movie",
          "camera"
        ],
        "name": "movie_camera",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zwury8vq.png",
        "icon": "🎥",
        "shortcode": ":movie_camera:",
        "unicodes": [
          "u1f3a5"
        ]
      },
      {
        "search": [
          "film",
          "frames"
        ],
        "name": "film_frames",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/llvxbvd5.png",
        "icon": "🎞",
        "shortcode": ":film_frames:",
        "unicodes": [
          "u1f39e"
        ]
      },
      {
        "search": [
          "film",
          "projector"
        ],
        "name": "film_projector",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dqwi7w2m.png",
        "icon": "📽",
        "shortcode": ":film_projector:",
        "unicodes": [
          "u1f4fd"
        ]
      },
      {
        "search": [
          "clapper",
          "board"
        ],
        "name": "clapper_board",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4sxsykno.png",
        "icon": "🎬",
        "shortcode": ":clapper_board:",
        "unicodes": [
          "u1f3ac"
        ]
      },
      {
        "search": [
          "television"
        ],
        "name": "television",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uez42tbd.png",
        "icon": "📺",
        "shortcode": ":television:",
        "unicodes": [
          "u1f4fa"
        ]
      },
      {
        "search": [
          "camera"
        ],
        "name": "camera",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/55lxvukk.png",
        "icon": "📷",
        "shortcode": ":camera:",
        "unicodes": [
          "u1f4f7"
        ]
      },
      {
        "search": [
          "camera",
          "with",
          "flash"
        ],
        "name": "camera_with_flash",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gi1r8u9w.png",
        "icon": "📸",
        "shortcode": ":camera_with_flash:",
        "unicodes": [
          "u1f4f8"
        ]
      },
      {
        "search": [
          "video",
          "camera"
        ],
        "name": "video_camera",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vfmf1b4h.png",
        "icon": "📹",
        "shortcode": ":video_camera:",
        "unicodes": [
          "u1f4f9"
        ]
      },
      {
        "search": [
          "videocassette"
        ],
        "name": "videocassette",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ra13jn43.png",
        "icon": "📼",
        "shortcode": ":videocassette:",
        "unicodes": [
          "u1f4fc"
        ]
      },
      {
        "search": [
          "magnifying",
          "glass",
          "tilted",
          "left"
        ],
        "name": "magnifying_glass_tilted_left",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/23sz7w15.png",
        "icon": "🔍",
        "shortcode": ":magnifying_glass_tilted_left:",
        "unicodes": [
          "u1f50d"
        ]
      },
      {
        "search": [
          "magnifying",
          "glass",
          "tilted",
          "right"
        ],
        "name": "magnifying_glass_tilted_right",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qd38lp6g.png",
        "icon": "🔎",
        "shortcode": ":magnifying_glass_tilted_right:",
        "unicodes": [
          "u1f50e"
        ]
      },
      {
        "search": [
          "candle"
        ],
        "name": "candle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7lfolafq.png",
        "icon": "🕯",
        "shortcode": ":candle:",
        "unicodes": [
          "u1f56f"
        ]
      },
      {
        "search": [
          "light",
          "bulb"
        ],
        "name": "light_bulb",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i2zcki90.png",
        "icon": "💡",
        "shortcode": ":light_bulb:",
        "unicodes": [
          "u1f4a1"
        ]
      },
      {
        "search": [
          "flashlight"
        ],
        "name": "flashlight",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ujlplwbn.png",
        "icon": "🔦",
        "shortcode": ":flashlight:",
        "unicodes": [
          "u1f526"
        ]
      },
      {
        "search": [
          "red",
          "paper",
          "lantern"
        ],
        "name": "red_paper_lantern",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6hcnznrb.png",
        "icon": "🏮",
        "shortcode": ":red_paper_lantern:",
        "unicodes": [
          "u1f3ee"
        ]
      },
      {
        "search": [
          "diya",
          "lamp"
        ],
        "name": "diya_lamp",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e3pz9l5c.png",
        "icon": "🪔",
        "shortcode": ":diya_lamp:",
        "unicodes": [
          "u1fa94"
        ]
      },
      {
        "search": [
          "notebook",
          "with",
          "decorative",
          "cover"
        ],
        "name": "notebook_with_decorative_cover",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/laofukeg.png",
        "icon": "📔",
        "shortcode": ":notebook_with_decorative_cover:",
        "unicodes": [
          "u1f4d4"
        ]
      },
      {
        "search": [
          "closed",
          "book"
        ],
        "name": "closed_book",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dj5lsy83.png",
        "icon": "📕",
        "shortcode": ":closed_book:",
        "unicodes": [
          "u1f4d5"
        ]
      },
      {
        "search": [
          "open",
          "book"
        ],
        "name": "open_book",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wvmhk7v5.png",
        "icon": "📖",
        "shortcode": ":open_book:",
        "unicodes": [
          "u1f4d6"
        ]
      },
      {
        "search": [
          "green",
          "book"
        ],
        "name": "green_book",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/p5kglk3r.png",
        "icon": "📗",
        "shortcode": ":green_book:",
        "unicodes": [
          "u1f4d7"
        ]
      },
      {
        "search": [
          "blue",
          "book"
        ],
        "name": "blue_book",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qy86u3sr.png",
        "icon": "📘",
        "shortcode": ":blue_book:",
        "unicodes": [
          "u1f4d8"
        ]
      },
      {
        "search": [
          "orange",
          "book"
        ],
        "name": "orange_book",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b7jyqmdj.png",
        "icon": "📙",
        "shortcode": ":orange_book:",
        "unicodes": [
          "u1f4d9"
        ]
      },
      {
        "search": [
          "books"
        ],
        "name": "books",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/19p7e8ys.png",
        "icon": "📚",
        "shortcode": ":books:",
        "unicodes": [
          "u1f4da"
        ]
      },
      {
        "search": [
          "notebook"
        ],
        "name": "notebook",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jlyhogxj.png",
        "icon": "📓",
        "shortcode": ":notebook:",
        "unicodes": [
          "u1f4d3"
        ]
      },
      {
        "search": [
          "ledger"
        ],
        "name": "ledger",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9mmxue32.png",
        "icon": "📒",
        "shortcode": ":ledger:",
        "unicodes": [
          "u1f4d2"
        ]
      },
      {
        "search": [
          "page",
          "with",
          "curl"
        ],
        "name": "page_with_curl",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/n26jdv6z.png",
        "icon": "📃",
        "shortcode": ":page_with_curl:",
        "unicodes": [
          "u1f4c3"
        ]
      },
      {
        "search": [
          "scroll"
        ],
        "name": "scroll",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4zzs1zob.png",
        "icon": "📜",
        "shortcode": ":scroll:",
        "unicodes": [
          "u1f4dc"
        ]
      },
      {
        "search": [
          "page",
          "facing",
          "up"
        ],
        "name": "page_facing_up",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/om6ese8d.png",
        "icon": "📄",
        "shortcode": ":page_facing_up:",
        "unicodes": [
          "u1f4c4"
        ]
      },
      {
        "search": [
          "newspaper"
        ],
        "name": "newspaper",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b2cmfk2b.png",
        "icon": "📰",
        "shortcode": ":newspaper:",
        "unicodes": [
          "u1f4f0"
        ]
      },
      {
        "search": [
          "rolled_up",
          "newspaper"
        ],
        "name": "rolled_up_newspaper",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xlunopmj.png",
        "icon": "🗞",
        "shortcode": ":rolled_up_newspaper:",
        "unicodes": [
          "u1f5de"
        ]
      },
      {
        "search": [
          "bookmark",
          "tabs"
        ],
        "name": "bookmark_tabs",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2jsrxaxl.png",
        "icon": "📑",
        "shortcode": ":bookmark_tabs:",
        "unicodes": [
          "u1f4d1"
        ]
      },
      {
        "search": [
          "bookmark"
        ],
        "name": "bookmark",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/be43t7af.png",
        "icon": "🔖",
        "shortcode": ":bookmark:",
        "unicodes": [
          "u1f516"
        ]
      },
      {
        "search": [
          "label"
        ],
        "name": "label",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2fyj8j7b.png",
        "icon": "🏷",
        "shortcode": ":label:",
        "unicodes": [
          "u1f3f7"
        ]
      },
      {
        "search": [
          "money",
          "bag"
        ],
        "name": "money_bag",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/43ua6cx5.png",
        "icon": "💰",
        "shortcode": ":money_bag:",
        "unicodes": [
          "u1f4b0"
        ]
      },
      {
        "search": [
          "coin"
        ],
        "name": "coin",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zbzqi162.png",
        "icon": "🪙",
        "shortcode": ":coin:",
        "unicodes": [
          "u1fa99"
        ]
      },
      {
        "search": [
          "yen",
          "banknote"
        ],
        "name": "yen_banknote",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gcv42nm0.png",
        "icon": "💴",
        "shortcode": ":yen_banknote:",
        "unicodes": [
          "u1f4b4"
        ]
      },
      {
        "search": [
          "dollar",
          "banknote"
        ],
        "name": "dollar_banknote",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jb2jlxy5.png",
        "icon": "💵",
        "shortcode": ":dollar_banknote:",
        "unicodes": [
          "u1f4b5"
        ]
      },
      {
        "search": [
          "euro",
          "banknote"
        ],
        "name": "euro_banknote",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vq0cbvpo.png",
        "icon": "💶",
        "shortcode": ":euro_banknote:",
        "unicodes": [
          "u1f4b6"
        ]
      },
      {
        "search": [
          "pound",
          "banknote"
        ],
        "name": "pound_banknote",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/du9fby1x.png",
        "icon": "💷",
        "shortcode": ":pound_banknote:",
        "unicodes": [
          "u1f4b7"
        ]
      },
      {
        "search": [
          "money",
          "with",
          "wings"
        ],
        "name": "money_with_wings",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f0agxv5f.png",
        "icon": "💸",
        "shortcode": ":money_with_wings:",
        "unicodes": [
          "u1f4b8"
        ]
      },
      {
        "search": [
          "credit",
          "card"
        ],
        "name": "credit_card",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w5o5l2j1.png",
        "icon": "💳",
        "shortcode": ":credit_card:",
        "unicodes": [
          "u1f4b3"
        ]
      },
      {
        "search": [
          "receipt"
        ],
        "name": "receipt",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/r4x4949h.png",
        "icon": "🧾",
        "shortcode": ":receipt:",
        "unicodes": [
          "u1f9fe"
        ]
      },
      {
        "search": [
          "chart",
          "increasing",
          "with",
          "yen"
        ],
        "name": "chart_increasing_with_yen",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5hr329h6.png",
        "icon": "💹",
        "shortcode": ":chart_increasing_with_yen:",
        "unicodes": [
          "u1f4b9"
        ]
      },
      {
        "search": [
          "envelope"
        ],
        "name": "envelope",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sw0i91sy.png",
        "icon": "✉",
        "shortcode": ":envelope:",
        "unicodes": [
          "u2709"
        ]
      },
      {
        "search": [
          "e_mail"
        ],
        "name": "e_mail",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4wvpb3cr.png",
        "icon": "📧",
        "shortcode": ":e_mail:",
        "unicodes": [
          "u1f4e7"
        ]
      },
      {
        "search": [
          "incoming",
          "envelope"
        ],
        "name": "incoming_envelope",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/see83dua.png",
        "icon": "📨",
        "shortcode": ":incoming_envelope:",
        "unicodes": [
          "u1f4e8"
        ]
      },
      {
        "search": [
          "envelope",
          "with",
          "arrow"
        ],
        "name": "envelope_with_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5uk9c5i4.png",
        "icon": "📩",
        "shortcode": ":envelope_with_arrow:",
        "unicodes": [
          "u1f4e9"
        ]
      },
      {
        "search": [
          "outbox",
          "tray"
        ],
        "name": "outbox_tray",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/z7629yd7.png",
        "icon": "📤",
        "shortcode": ":outbox_tray:",
        "unicodes": [
          "u1f4e4"
        ]
      },
      {
        "search": [
          "inbox",
          "tray"
        ],
        "name": "inbox_tray",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/c6csuztv.png",
        "icon": "📥",
        "shortcode": ":inbox_tray:",
        "unicodes": [
          "u1f4e5"
        ]
      },
      {
        "search": [
          "package"
        ],
        "name": "package",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5wtwelx5.png",
        "icon": "📦",
        "shortcode": ":package:",
        "unicodes": [
          "u1f4e6"
        ]
      },
      {
        "search": [
          "closed",
          "mailbox",
          "with",
          "raised",
          "flag"
        ],
        "name": "closed_mailbox_with_raised_flag",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w8fpd1yv.png",
        "icon": "📫",
        "shortcode": ":closed_mailbox_with_raised_flag:",
        "unicodes": [
          "u1f4eb"
        ]
      },
      {
        "search": [
          "closed",
          "mailbox",
          "with",
          "lowered",
          "flag"
        ],
        "name": "closed_mailbox_with_lowered_flag",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gqnu2llg.png",
        "icon": "📪",
        "shortcode": ":closed_mailbox_with_lowered_flag:",
        "unicodes": [
          "u1f4ea"
        ]
      },
      {
        "search": [
          "open",
          "mailbox",
          "with",
          "raised",
          "flag"
        ],
        "name": "open_mailbox_with_raised_flag",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nz8ngti8.png",
        "icon": "📬",
        "shortcode": ":open_mailbox_with_raised_flag:",
        "unicodes": [
          "u1f4ec"
        ]
      },
      {
        "search": [
          "open",
          "mailbox",
          "with",
          "lowered",
          "flag"
        ],
        "name": "open_mailbox_with_lowered_flag",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/90267lua.png",
        "icon": "📭",
        "shortcode": ":open_mailbox_with_lowered_flag:",
        "unicodes": [
          "u1f4ed"
        ]
      },
      {
        "search": [
          "postbox"
        ],
        "name": "postbox",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yvthvq7d.png",
        "icon": "📮",
        "shortcode": ":postbox:",
        "unicodes": [
          "u1f4ee"
        ]
      },
      {
        "search": [
          "ballot",
          "box",
          "with",
          "ballot"
        ],
        "name": "ballot_box_with_ballot",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1fxknf9k.png",
        "icon": "🗳",
        "shortcode": ":ballot_box_with_ballot:",
        "unicodes": [
          "u1f5f3"
        ]
      },
      {
        "search": [
          "pencil"
        ],
        "name": "pencil",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b1b0p7z9.png",
        "icon": "✏",
        "shortcode": ":pencil:",
        "unicodes": [
          "u270f"
        ]
      },
      {
        "search": [
          "black",
          "nib"
        ],
        "name": "black_nib",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ggui7xgc.png",
        "icon": "✒",
        "shortcode": ":black_nib:",
        "unicodes": [
          "u2712"
        ]
      },
      {
        "search": [
          "fountain",
          "pen"
        ],
        "name": "fountain_pen",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vwr3j8mr.png",
        "icon": "🖋",
        "shortcode": ":fountain_pen:",
        "unicodes": [
          "u1f58b"
        ]
      },
      {
        "search": [
          "pen"
        ],
        "name": "pen",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7syz1209.png",
        "icon": "🖊",
        "shortcode": ":pen:",
        "unicodes": [
          "u1f58a"
        ]
      },
      {
        "search": [
          "paintbrush"
        ],
        "name": "paintbrush",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j6u0vu8f.png",
        "icon": "🖌",
        "shortcode": ":paintbrush:",
        "unicodes": [
          "u1f58c"
        ]
      },
      {
        "search": [
          "crayon"
        ],
        "name": "crayon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ezt6tn85.png",
        "icon": "🖍",
        "shortcode": ":crayon:",
        "unicodes": [
          "u1f58d"
        ]
      },
      {
        "search": [
          "memo"
        ],
        "name": "memo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rsub6u3z.png",
        "icon": "📝",
        "shortcode": ":memo:",
        "unicodes": [
          "u1f4dd"
        ]
      },
      {
        "search": [
          "briefcase"
        ],
        "name": "briefcase",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e74qp0r8.png",
        "icon": "💼",
        "shortcode": ":briefcase:",
        "unicodes": [
          "u1f4bc"
        ]
      },
      {
        "search": [
          "file",
          "folder"
        ],
        "name": "file_folder",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2qwhiaji.png",
        "icon": "📁",
        "shortcode": ":file_folder:",
        "unicodes": [
          "u1f4c1"
        ]
      },
      {
        "search": [
          "open",
          "file",
          "folder"
        ],
        "name": "open_file_folder",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/k4ietzkl.png",
        "icon": "📂",
        "shortcode": ":open_file_folder:",
        "unicodes": [
          "u1f4c2"
        ]
      },
      {
        "search": [
          "card",
          "index",
          "dividers"
        ],
        "name": "card_index_dividers",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g0nz44ht.png",
        "icon": "🗂",
        "shortcode": ":card_index_dividers:",
        "unicodes": [
          "u1f5c2"
        ]
      },
      {
        "search": [
          "calendar"
        ],
        "name": "calendar",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lj6re86w.png",
        "icon": "📅",
        "shortcode": ":calendar:",
        "unicodes": [
          "u1f4c5"
        ]
      },
      {
        "search": [
          "tear_off",
          "calendar"
        ],
        "name": "tear_off_calendar",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xeq022ip.png",
        "icon": "📆",
        "shortcode": ":tear_off_calendar:",
        "unicodes": [
          "u1f4c6"
        ]
      },
      {
        "search": [
          "spiral",
          "notepad"
        ],
        "name": "spiral_notepad",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/26y1w654.png",
        "icon": "🗒",
        "shortcode": ":spiral_notepad:",
        "unicodes": [
          "u1f5d2"
        ]
      },
      {
        "search": [
          "spiral",
          "calendar"
        ],
        "name": "spiral_calendar",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/r7xrmprp.png",
        "icon": "🗓",
        "shortcode": ":spiral_calendar:",
        "unicodes": [
          "u1f5d3"
        ]
      },
      {
        "search": [
          "card",
          "index"
        ],
        "name": "card_index",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/srv15fkz.png",
        "icon": "📇",
        "shortcode": ":card_index:",
        "unicodes": [
          "u1f4c7"
        ]
      },
      {
        "search": [
          "chart",
          "increasing"
        ],
        "name": "chart_increasing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f76owv9k.png",
        "icon": "📈",
        "shortcode": ":chart_increasing:",
        "unicodes": [
          "u1f4c8"
        ]
      },
      {
        "search": [
          "chart",
          "decreasing"
        ],
        "name": "chart_decreasing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nw43nrs7.png",
        "icon": "📉",
        "shortcode": ":chart_decreasing:",
        "unicodes": [
          "u1f4c9"
        ]
      },
      {
        "search": [
          "bar",
          "chart"
        ],
        "name": "bar_chart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/aqirp32h.png",
        "icon": "📊",
        "shortcode": ":bar_chart:",
        "unicodes": [
          "u1f4ca"
        ]
      },
      {
        "search": [
          "clipboard"
        ],
        "name": "clipboard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b97g8svq.png",
        "icon": "📋",
        "shortcode": ":clipboard:",
        "unicodes": [
          "u1f4cb"
        ]
      },
      {
        "search": [
          "pushpin"
        ],
        "name": "pushpin",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5x0zfhy5.png",
        "icon": "📌",
        "shortcode": ":pushpin:",
        "unicodes": [
          "u1f4cc"
        ]
      },
      {
        "search": [
          "round",
          "pushpin"
        ],
        "name": "round_pushpin",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yrx7a3vh.png",
        "icon": "📍",
        "shortcode": ":round_pushpin:",
        "unicodes": [
          "u1f4cd"
        ]
      },
      {
        "search": [
          "paperclip"
        ],
        "name": "paperclip",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f0nrn3sa.png",
        "icon": "📎",
        "shortcode": ":paperclip:",
        "unicodes": [
          "u1f4ce"
        ]
      },
      {
        "search": [
          "linked",
          "paperclips"
        ],
        "name": "linked_paperclips",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9wvsmz2o.png",
        "icon": "🖇",
        "shortcode": ":linked_paperclips:",
        "unicodes": [
          "u1f587"
        ]
      },
      {
        "search": [
          "straight",
          "ruler"
        ],
        "name": "straight_ruler",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/y3l1da9c.png",
        "icon": "📏",
        "shortcode": ":straight_ruler:",
        "unicodes": [
          "u1f4cf"
        ]
      },
      {
        "search": [
          "triangular",
          "ruler"
        ],
        "name": "triangular_ruler",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/m05bxqcj.png",
        "icon": "📐",
        "shortcode": ":triangular_ruler:",
        "unicodes": [
          "u1f4d0"
        ]
      },
      {
        "search": [
          "scissors"
        ],
        "name": "scissors",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yno1gxup.png",
        "icon": "✂",
        "shortcode": ":scissors:",
        "unicodes": [
          "u2702"
        ]
      },
      {
        "search": [
          "card",
          "file",
          "box"
        ],
        "name": "card_file_box",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xz7nydlr.png",
        "icon": "🗃",
        "shortcode": ":card_file_box:",
        "unicodes": [
          "u1f5c3"
        ]
      },
      {
        "search": [
          "file",
          "cabinet"
        ],
        "name": "file_cabinet",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/p1rmuhhp.png",
        "icon": "🗄",
        "shortcode": ":file_cabinet:",
        "unicodes": [
          "u1f5c4"
        ]
      },
      {
        "search": [
          "wastebasket"
        ],
        "name": "wastebasket",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g2r57xdr.png",
        "icon": "🗑",
        "shortcode": ":wastebasket:",
        "unicodes": [
          "u1f5d1"
        ]
      },
      {
        "search": [
          "locked"
        ],
        "name": "locked",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2bfmbizv.png",
        "icon": "🔒",
        "shortcode": ":locked:",
        "unicodes": [
          "u1f512"
        ]
      },
      {
        "search": [
          "unlocked"
        ],
        "name": "unlocked",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ux9xuord.png",
        "icon": "🔓",
        "shortcode": ":unlocked:",
        "unicodes": [
          "u1f513"
        ]
      },
      {
        "search": [
          "locked",
          "with",
          "pen"
        ],
        "name": "locked_with_pen",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/z8u24p6r.png",
        "icon": "🔏",
        "shortcode": ":locked_with_pen:",
        "unicodes": [
          "u1f50f"
        ]
      },
      {
        "search": [
          "locked",
          "with",
          "key"
        ],
        "name": "locked_with_key",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yzgkrbq5.png",
        "icon": "🔐",
        "shortcode": ":locked_with_key:",
        "unicodes": [
          "u1f510"
        ]
      },
      {
        "search": [
          "key"
        ],
        "name": "key",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rmqdl0lo.png",
        "icon": "🔑",
        "shortcode": ":key:",
        "unicodes": [
          "u1f511"
        ]
      },
      {
        "search": [
          "old",
          "key"
        ],
        "name": "old_key",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ialp1p53.png",
        "icon": "🗝",
        "shortcode": ":old_key:",
        "unicodes": [
          "u1f5dd"
        ]
      },
      {
        "search": [
          "hammer"
        ],
        "name": "hammer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lkvfr2ki.png",
        "icon": "🔨",
        "shortcode": ":hammer:",
        "unicodes": [
          "u1f528"
        ]
      },
      {
        "search": [
          "axe"
        ],
        "name": "axe",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yzonhtgy.png",
        "icon": "🪓",
        "shortcode": ":axe:",
        "unicodes": [
          "u1fa93"
        ]
      },
      {
        "search": [
          "pick"
        ],
        "name": "pick",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1bxr5j7i.png",
        "icon": "⛏",
        "shortcode": ":pick:",
        "unicodes": [
          "u26cf"
        ]
      },
      {
        "search": [
          "hammer",
          "and",
          "pick"
        ],
        "name": "hammer_and_pick",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yykqi4hm.png",
        "icon": "⚒",
        "shortcode": ":hammer_and_pick:",
        "unicodes": [
          "u2692"
        ]
      },
      {
        "search": [
          "hammer",
          "and",
          "wrench"
        ],
        "name": "hammer_and_wrench",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hg1uy9ka.png",
        "icon": "🛠",
        "shortcode": ":hammer_and_wrench:",
        "unicodes": [
          "u1f6e0"
        ]
      },
      {
        "search": [
          "dagger"
        ],
        "name": "dagger",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rx04ozcn.png",
        "icon": "🗡",
        "shortcode": ":dagger:",
        "unicodes": [
          "u1f5e1"
        ]
      },
      {
        "search": [
          "crossed",
          "swords"
        ],
        "name": "crossed_swords",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2vwttrqk.png",
        "icon": "⚔",
        "shortcode": ":crossed_swords:",
        "unicodes": [
          "u2694"
        ]
      },
      {
        "search": [
          "bomb"
        ],
        "name": "bomb",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qk7i04jn.png",
        "icon": "💣",
        "shortcode": ":bomb:",
        "unicodes": [
          "u1f4a3"
        ]
      },
      {
        "search": [
          "boomerang"
        ],
        "name": "boomerang",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7rq60bp8.png",
        "icon": "🪃",
        "shortcode": ":boomerang:",
        "unicodes": [
          "u1fa83"
        ]
      },
      {
        "search": [
          "bow",
          "and",
          "arrow"
        ],
        "name": "bow_and_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8kyzkq3h.png",
        "icon": "🏹",
        "shortcode": ":bow_and_arrow:",
        "unicodes": [
          "u1f3f9"
        ]
      },
      {
        "search": [
          "shield"
        ],
        "name": "shield",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mlkf5bpo.png",
        "icon": "🛡",
        "shortcode": ":shield:",
        "unicodes": [
          "u1f6e1"
        ]
      },
      {
        "search": [
          "carpentry",
          "saw"
        ],
        "name": "carpentry_saw",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mu8pjlcs.png",
        "icon": "🪚",
        "shortcode": ":carpentry_saw:",
        "unicodes": [
          "u1fa9a"
        ]
      },
      {
        "search": [
          "wrench"
        ],
        "name": "wrench",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2mt6llw3.png",
        "icon": "🔧",
        "shortcode": ":wrench:",
        "unicodes": [
          "u1f527"
        ]
      },
      {
        "search": [
          "screwdriver"
        ],
        "name": "screwdriver",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qvqa65m8.png",
        "icon": "🪛",
        "shortcode": ":screwdriver:",
        "unicodes": [
          "u1fa9b"
        ]
      },
      {
        "search": [
          "nut",
          "and",
          "bolt"
        ],
        "name": "nut_and_bolt",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jr0ujsk2.png",
        "icon": "🔩",
        "shortcode": ":nut_and_bolt:",
        "unicodes": [
          "u1f529"
        ]
      },
      {
        "search": [
          "gear"
        ],
        "name": "gear",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b7l0tsq0.png",
        "icon": "⚙",
        "shortcode": ":gear:",
        "unicodes": [
          "u2699"
        ]
      },
      {
        "search": [
          "clamp"
        ],
        "name": "clamp",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dja1735m.png",
        "icon": "🗜",
        "shortcode": ":clamp:",
        "unicodes": [
          "u1f5dc"
        ]
      },
      {
        "search": [
          "balance",
          "scale"
        ],
        "name": "balance_scale",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bpkubxhf.png",
        "icon": "⚖",
        "shortcode": ":balance_scale:",
        "unicodes": [
          "u2696"
        ]
      },
      {
        "search": [
          "white",
          "cane"
        ],
        "name": "white_cane",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/isd8lwrl.png",
        "icon": "🦯",
        "shortcode": ":white_cane:",
        "unicodes": [
          "u1f9af"
        ]
      },
      {
        "search": [
          "link"
        ],
        "name": "link",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vbro3imd.png",
        "icon": "🔗",
        "shortcode": ":link:",
        "unicodes": [
          "u1f517"
        ]
      },
      {
        "search": [
          "chains"
        ],
        "name": "chains",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2oe65ojp.png",
        "icon": "⛓",
        "shortcode": ":chains:",
        "unicodes": [
          "u26d3"
        ]
      },
      {
        "search": [
          "hook"
        ],
        "name": "hook",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/27tbm9w9.png",
        "icon": "🪝",
        "shortcode": ":hook:",
        "unicodes": [
          "u1fa9d"
        ]
      },
      {
        "search": [
          "toolbox"
        ],
        "name": "toolbox",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/q9skf89h.png",
        "icon": "🧰",
        "shortcode": ":toolbox:",
        "unicodes": [
          "u1f9f0"
        ]
      },
      {
        "search": [
          "magnet"
        ],
        "name": "magnet",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/djblarkn.png",
        "icon": "🧲",
        "shortcode": ":magnet:",
        "unicodes": [
          "u1f9f2"
        ]
      },
      {
        "search": [
          "ladder"
        ],
        "name": "ladder",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/owzn8n8m.png",
        "icon": "🪜",
        "shortcode": ":ladder:",
        "unicodes": [
          "u1fa9c"
        ]
      },
      {
        "search": [
          "alembic"
        ],
        "name": "alembic",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1igkdld7.png",
        "icon": "⚗",
        "shortcode": ":alembic:",
        "unicodes": [
          "u2697"
        ]
      },
      {
        "search": [
          "test",
          "tube"
        ],
        "name": "test_tube",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7laqzz51.png",
        "icon": "🧪",
        "shortcode": ":test_tube:",
        "unicodes": [
          "u1f9ea"
        ]
      },
      {
        "search": [
          "petri",
          "dish"
        ],
        "name": "petri_dish",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dxj3dfog.png",
        "icon": "🧫",
        "shortcode": ":petri_dish:",
        "unicodes": [
          "u1f9eb"
        ]
      },
      {
        "search": [
          "dna"
        ],
        "name": "dna",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/48r5xxjt.png",
        "icon": "🧬",
        "shortcode": ":dna:",
        "unicodes": [
          "u1f9ec"
        ]
      },
      {
        "search": [
          "microscope"
        ],
        "name": "microscope",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/halevc9w.png",
        "icon": "🔬",
        "shortcode": ":microscope:",
        "unicodes": [
          "u1f52c"
        ]
      },
      {
        "search": [
          "telescope"
        ],
        "name": "telescope",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0m3ve2at.png",
        "icon": "🔭",
        "shortcode": ":telescope:",
        "unicodes": [
          "u1f52d"
        ]
      },
      {
        "search": [
          "satellite",
          "antenna"
        ],
        "name": "satellite_antenna",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/k7iqmj0c.png",
        "icon": "📡",
        "shortcode": ":satellite_antenna:",
        "unicodes": [
          "u1f4e1"
        ]
      },
      {
        "search": [
          "syringe"
        ],
        "name": "syringe",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pn1b9yta.png",
        "icon": "💉",
        "shortcode": ":syringe:",
        "unicodes": [
          "u1f489"
        ]
      },
      {
        "search": [
          "drop",
          "of",
          "blood"
        ],
        "name": "drop_of_blood",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6lgbwsa2.png",
        "icon": "🩸",
        "shortcode": ":drop_of_blood:",
        "unicodes": [
          "u1fa78"
        ]
      },
      {
        "search": [
          "pill"
        ],
        "name": "pill",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/228x8wr2.png",
        "icon": "💊",
        "shortcode": ":pill:",
        "unicodes": [
          "u1f48a"
        ]
      },
      {
        "search": [
          "adhesive",
          "bandage"
        ],
        "name": "adhesive_bandage",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/icjj26br.png",
        "icon": "🩹",
        "shortcode": ":adhesive_bandage:",
        "unicodes": [
          "u1fa79"
        ]
      },
      {
        "search": [
          "crutch"
        ],
        "name": "crutch",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uwy2oyt6.png",
        "icon": "🩼",
        "shortcode": ":crutch:",
        "unicodes": [
          "u1fa7c"
        ]
      },
      {
        "search": [
          "stethoscope"
        ],
        "name": "stethoscope",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0ze2lttt.png",
        "icon": "🩺",
        "shortcode": ":stethoscope:",
        "unicodes": [
          "u1fa7a"
        ]
      },
      {
        "search": [
          "x_ray"
        ],
        "name": "x_ray",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rcv3mm7o.png",
        "icon": "🩻",
        "shortcode": ":x_ray:",
        "unicodes": [
          "u1fa7b"
        ]
      },
      {
        "search": [
          "door"
        ],
        "name": "door",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cjlnfw5u.png",
        "icon": "🚪",
        "shortcode": ":door:",
        "unicodes": [
          "u1f6aa"
        ]
      },
      {
        "search": [
          "elevator"
        ],
        "name": "elevator",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/huckbhe8.png",
        "icon": "🛗",
        "shortcode": ":elevator:",
        "unicodes": [
          "u1f6d7"
        ]
      },
      {
        "search": [
          "mirror"
        ],
        "name": "mirror",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pyoi69cr.png",
        "icon": "🪞",
        "shortcode": ":mirror:",
        "unicodes": [
          "u1fa9e"
        ]
      },
      {
        "search": [
          "window"
        ],
        "name": "window",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/oxb7bgxd.png",
        "icon": "🪟",
        "shortcode": ":window:",
        "unicodes": [
          "u1fa9f"
        ]
      },
      {
        "search": [
          "bed"
        ],
        "name": "bed",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8lgxehs9.png",
        "icon": "🛏",
        "shortcode": ":bed:",
        "unicodes": [
          "u1f6cf"
        ]
      },
      {
        "search": [
          "couch",
          "and",
          "lamp"
        ],
        "name": "couch_and_lamp",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7h6pmeao.png",
        "icon": "🛋",
        "shortcode": ":couch_and_lamp:",
        "unicodes": [
          "u1f6cb"
        ]
      },
      {
        "search": [
          "chair"
        ],
        "name": "chair",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v7083hyh.png",
        "icon": "🪑",
        "shortcode": ":chair:",
        "unicodes": [
          "u1fa91"
        ]
      },
      {
        "search": [
          "toilet"
        ],
        "name": "toilet",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/av552nxh.png",
        "icon": "🚽",
        "shortcode": ":toilet:",
        "unicodes": [
          "u1f6bd"
        ]
      },
      {
        "search": [
          "plunger"
        ],
        "name": "plunger",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5xn415zb.png",
        "icon": "🪠",
        "shortcode": ":plunger:",
        "unicodes": [
          "u1faa0"
        ]
      },
      {
        "search": [
          "shower"
        ],
        "name": "shower",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/x5xfb4io.png",
        "icon": "🚿",
        "shortcode": ":shower:",
        "unicodes": [
          "u1f6bf"
        ]
      },
      {
        "search": [
          "bathtub"
        ],
        "name": "bathtub",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wl3t6wza.png",
        "icon": "🛁",
        "shortcode": ":bathtub:",
        "unicodes": [
          "u1f6c1"
        ]
      },
      {
        "search": [
          "mouse",
          "trap"
        ],
        "name": "mouse_trap",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wk6czkrh.png",
        "icon": "🪤",
        "shortcode": ":mouse_trap:",
        "unicodes": [
          "u1faa4"
        ]
      },
      {
        "search": [
          "razor"
        ],
        "name": "razor",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ou8whg25.png",
        "icon": "🪒",
        "shortcode": ":razor:",
        "unicodes": [
          "u1fa92"
        ]
      },
      {
        "search": [
          "lotion",
          "bottle"
        ],
        "name": "lotion_bottle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/40pvecio.png",
        "icon": "🧴",
        "shortcode": ":lotion_bottle:",
        "unicodes": [
          "u1f9f4"
        ]
      },
      {
        "search": [
          "safety",
          "pin"
        ],
        "name": "safety_pin",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3l5441vq.png",
        "icon": "🧷",
        "shortcode": ":safety_pin:",
        "unicodes": [
          "u1f9f7"
        ]
      },
      {
        "search": [
          "broom"
        ],
        "name": "broom",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6oqmbucl.png",
        "icon": "🧹",
        "shortcode": ":broom:",
        "unicodes": [
          "u1f9f9"
        ]
      },
      {
        "search": [
          "basket"
        ],
        "name": "basket",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fs8zveh0.png",
        "icon": "🧺",
        "shortcode": ":basket:",
        "unicodes": [
          "u1f9fa"
        ]
      },
      {
        "search": [
          "roll",
          "of",
          "paper"
        ],
        "name": "roll_of_paper",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hc7fj72z.png",
        "icon": "🧻",
        "shortcode": ":roll_of_paper:",
        "unicodes": [
          "u1f9fb"
        ]
      },
      {
        "search": [
          "bucket"
        ],
        "name": "bucket",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/adxw93zs.png",
        "icon": "🪣",
        "shortcode": ":bucket:",
        "unicodes": [
          "u1faa3"
        ]
      },
      {
        "search": [
          "soap"
        ],
        "name": "soap",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/n1c0ko66.png",
        "icon": "🧼",
        "shortcode": ":soap:",
        "unicodes": [
          "u1f9fc"
        ]
      },
      {
        "search": [
          "bubbles"
        ],
        "name": "bubbles",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w8dodebt.png",
        "icon": "🫧",
        "shortcode": ":bubbles:",
        "unicodes": [
          "u1fae7"
        ]
      },
      {
        "search": [
          "toothbrush"
        ],
        "name": "toothbrush",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jd85puhp.png",
        "icon": "🪥",
        "shortcode": ":toothbrush:",
        "unicodes": [
          "u1faa5"
        ]
      },
      {
        "search": [
          "sponge"
        ],
        "name": "sponge",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/54u8ubqy.png",
        "icon": "🧽",
        "shortcode": ":sponge:",
        "unicodes": [
          "u1f9fd"
        ]
      },
      {
        "search": [
          "fire",
          "extinguisher"
        ],
        "name": "fire_extinguisher",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sc1b3edb.png",
        "icon": "🧯",
        "shortcode": ":fire_extinguisher:",
        "unicodes": [
          "u1f9ef"
        ]
      },
      {
        "search": [
          "shopping",
          "cart"
        ],
        "name": "shopping_cart",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lfszglva.png",
        "icon": "🛒",
        "shortcode": ":shopping_cart:",
        "unicodes": [
          "u1f6d2"
        ]
      },
      {
        "search": [
          "cigarette"
        ],
        "name": "cigarette",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/n0rjthxz.png",
        "icon": "🚬",
        "shortcode": ":cigarette:",
        "unicodes": [
          "u1f6ac"
        ]
      },
      {
        "search": [
          "coffin"
        ],
        "name": "coffin",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qniz2mfi.png",
        "icon": "⚰",
        "shortcode": ":coffin:",
        "unicodes": [
          "u26b0"
        ]
      },
      {
        "search": [
          "headstone"
        ],
        "name": "headstone",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zsrolt29.png",
        "icon": "🪦",
        "shortcode": ":headstone:",
        "unicodes": [
          "u1faa6"
        ]
      },
      {
        "search": [
          "funeral",
          "urn"
        ],
        "name": "funeral_urn",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/laqh4hwc.png",
        "icon": "⚱",
        "shortcode": ":funeral_urn:",
        "unicodes": [
          "u26b1"
        ]
      },
      {
        "search": [
          "nazar",
          "amulet"
        ],
        "name": "nazar_amulet",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iqyqlm3v.png",
        "icon": "🧿",
        "shortcode": ":nazar_amulet:",
        "unicodes": [
          "u1f9ff"
        ]
      },
      {
        "search": [
          "hamsa"
        ],
        "name": "hamsa",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/y035ntpq.png",
        "icon": "🪬",
        "shortcode": ":hamsa:",
        "unicodes": [
          "u1faac"
        ]
      },
      {
        "search": [
          "moai"
        ],
        "name": "moai",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tzduak2j.png",
        "icon": "🗿",
        "shortcode": ":moai:",
        "unicodes": [
          "u1f5ff"
        ]
      },
      {
        "search": [
          "placard"
        ],
        "name": "placard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4i5a7v37.png",
        "icon": "🪧",
        "shortcode": ":placard:",
        "unicodes": [
          "u1faa7"
        ]
      },
      {
        "search": [
          "identification",
          "card"
        ],
        "name": "identification_card",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/exmle15m.png",
        "icon": "🪧",
        "shortcode": ":identification_card:",
        "unicodes": [
          "u1faaa"
        ]
      }
    ]
  },
  {
    "category": "symbols",
    "text": "Symbols",
    "icons": [
      {
        "search": [
          "ATM",
          "sign"
        ],
        "name": "ATM_sign",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ifr6dx0e.png",
        "icon": "🏧",
        "shortcode": ":atm_sign:",
        "unicodes": [
          "u1f3e7"
        ]
      },
      {
        "search": [
          "litter",
          "in",
          "bin",
          "sign"
        ],
        "name": "litter_in_bin_sign",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5n17qbjk.png",
        "icon": "🚮",
        "shortcode": ":litter_in_bin_sign:",
        "unicodes": [
          "u1f6ae"
        ]
      },
      {
        "search": [
          "potable",
          "water"
        ],
        "name": "potable_water",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j29acvfi.png",
        "icon": "🚰",
        "shortcode": ":potable_water:",
        "unicodes": [
          "u1f6b0"
        ]
      },
      {
        "search": [
          "wheelchair",
          "symbol"
        ],
        "name": "wheelchair_symbol",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jvq7tsm2.png",
        "icon": "♿",
        "shortcode": ":wheelchair_symbol:",
        "unicodes": [
          "u267f"
        ]
      },
      {
        "search": [
          "men’s",
          "room"
        ],
        "name": "men’s_room",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dikw4qwd.png",
        "icon": "🚹",
        "shortcode": ":mens_room:",
        "unicodes": [
          "u1f6b9"
        ]
      },
      {
        "search": [
          "women’s",
          "room"
        ],
        "name": "women’s_room",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bmh993xh.png",
        "icon": "🚺",
        "shortcode": ":womens_room:",
        "unicodes": [
          "u1f6ba"
        ]
      },
      {
        "search": [
          "restroom"
        ],
        "name": "restroom",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/aqvllxqf.png",
        "icon": "🚻",
        "shortcode": ":restroom:",
        "unicodes": [
          "u1f6bb"
        ]
      },
      {
        "search": [
          "baby",
          "symbol"
        ],
        "name": "baby_symbol",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/90zzxotz.png",
        "icon": "🚼",
        "shortcode": ":baby_symbol:",
        "unicodes": [
          "u1f6bc"
        ]
      },
      {
        "search": [
          "water",
          "closet"
        ],
        "name": "water_closet",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j9po4h1z.png",
        "icon": "🚾",
        "shortcode": ":water_closet:",
        "unicodes": [
          "u1f6be"
        ]
      },
      {
        "search": [
          "passport",
          "control"
        ],
        "name": "passport_control",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yomnt1ni.png",
        "icon": "🛂",
        "shortcode": ":passport_control:",
        "unicodes": [
          "u1f6c2"
        ]
      },
      {
        "search": [
          "customs"
        ],
        "name": "customs",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4s4mh7r4.png",
        "icon": "🛃",
        "shortcode": ":customs:",
        "unicodes": [
          "u1f6c3"
        ]
      },
      {
        "search": [
          "baggage",
          "claim"
        ],
        "name": "baggage_claim",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5phwktju.png",
        "icon": "🛄",
        "shortcode": ":baggage_claim:",
        "unicodes": [
          "u1f6c4"
        ]
      },
      {
        "search": [
          "left",
          "luggage"
        ],
        "name": "left_luggage",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xdjrx42y.png",
        "icon": "🛅",
        "shortcode": ":left_luggage:",
        "unicodes": [
          "u1f6c5"
        ]
      },
      {
        "search": [
          "warning"
        ],
        "name": "warning",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b2viyfrz.png",
        "icon": "⚠",
        "shortcode": ":warning:",
        "unicodes": [
          "u26a0"
        ]
      },
      {
        "search": [
          "children",
          "crossing"
        ],
        "name": "children_crossing",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bq9bte1h.png",
        "icon": "🚸",
        "shortcode": ":children_crossing:",
        "unicodes": [
          "u1f6b8"
        ]
      },
      {
        "search": [
          "no",
          "entry"
        ],
        "name": "no_entry",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/umxqjqal.png",
        "icon": "⛔",
        "shortcode": ":no_entry:",
        "unicodes": [
          "u26d4"
        ]
      },
      {
        "search": [
          "prohibited"
        ],
        "name": "prohibited",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/enxkgyoo.png",
        "icon": "🚫",
        "shortcode": ":prohibited:",
        "unicodes": [
          "u1f6ab"
        ]
      },
      {
        "search": [
          "no",
          "bicycles"
        ],
        "name": "no_bicycles",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1sh0sf99.png",
        "icon": "🚳",
        "shortcode": ":no_bicycles:",
        "unicodes": [
          "u1f6b3"
        ]
      },
      {
        "search": [
          "no",
          "smoking"
        ],
        "name": "no_smoking",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bnip9kkn.png",
        "icon": "🚭",
        "shortcode": ":no_smoking:",
        "unicodes": [
          "u1f6ad"
        ]
      },
      {
        "search": [
          "no",
          "littering"
        ],
        "name": "no_littering",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2wooa2yz.png",
        "icon": "🚯",
        "shortcode": ":no_littering:",
        "unicodes": [
          "u1f6af"
        ]
      },
      {
        "search": [
          "non_potable",
          "water"
        ],
        "name": "non_potable_water",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jythvlh7.png",
        "icon": "🚱",
        "shortcode": ":non_potable_water:",
        "unicodes": [
          "u1f6b1"
        ]
      },
      {
        "search": [
          "no",
          "pedestrians"
        ],
        "name": "no_pedestrians",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xbl8keau.png",
        "icon": "🚷",
        "shortcode": ":no_pedestrians:",
        "unicodes": [
          "u1f6b7"
        ]
      },
      {
        "search": [
          "no",
          "mobile",
          "phones"
        ],
        "name": "no_mobile_phones",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i5k03g93.png",
        "icon": "📵",
        "shortcode": ":no_mobile_phones:",
        "unicodes": [
          "u1f4f5"
        ]
      },
      {
        "search": [
          "no",
          "one",
          "under",
          "eighteen"
        ],
        "name": "no_one_under_eighteen",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hgr9aav6.png",
        "icon": "🔞",
        "shortcode": ":no_one_under_eighteen:",
        "unicodes": [
          "u1f51e"
        ]
      },
      {
        "search": [
          "radioactive"
        ],
        "name": "radioactive",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fv187h2n.png",
        "icon": "☢",
        "shortcode": ":radioactive:",
        "unicodes": [
          "u2622"
        ]
      },
      {
        "search": [
          "biohazard"
        ],
        "name": "biohazard",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mg9i6e4n.png",
        "icon": "☣",
        "shortcode": ":biohazard:",
        "unicodes": [
          "u2623"
        ]
      },
      {
        "search": [
          "up",
          "arrow"
        ],
        "name": "up_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/53b8b79r.png",
        "icon": "⬆",
        "shortcode": ":up_arrow:",
        "unicodes": [
          "u2b06"
        ]
      },
      {
        "search": [
          "up_right",
          "arrow"
        ],
        "name": "up_right_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w8kpx4jq.png",
        "icon": "↗",
        "shortcode": ":up_right_arrow:",
        "unicodes": [
          "u2197"
        ]
      },
      {
        "search": [
          "right",
          "arrow"
        ],
        "name": "right_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xjg4747t.png",
        "icon": "➡",
        "shortcode": ":right_arrow:",
        "unicodes": [
          "u27a1"
        ]
      },
      {
        "search": [
          "down_right",
          "arrow"
        ],
        "name": "down_right_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xj2ksgbw.png",
        "icon": "↘",
        "shortcode": ":down_right_arrow:",
        "unicodes": [
          "u2198"
        ]
      },
      {
        "search": [
          "down",
          "arrow"
        ],
        "name": "down_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/emaye7ai.png",
        "icon": "⬇",
        "shortcode": ":down_arrow:",
        "unicodes": [
          "u2b07"
        ]
      },
      {
        "search": [
          "down_left",
          "arrow"
        ],
        "name": "down_left_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/83io23tc.png",
        "icon": "↙",
        "shortcode": ":down_left_arrow:",
        "unicodes": [
          "u2199"
        ]
      },
      {
        "search": [
          "left",
          "arrow"
        ],
        "name": "left_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b2jh66sa.png",
        "icon": "⬅",
        "shortcode": ":left_arrow:",
        "unicodes": [
          "u2b05"
        ]
      },
      {
        "search": [
          "up_left",
          "arrow"
        ],
        "name": "up_left_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pgur566q.png",
        "icon": "↖",
        "shortcode": ":up_left_arrow:",
        "unicodes": [
          "u2196"
        ]
      },
      {
        "search": [
          "up_down",
          "arrow"
        ],
        "name": "up_down_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9f8nf65d.png",
        "icon": "↕",
        "shortcode": ":up_down_arrow:",
        "unicodes": [
          "u2195"
        ]
      },
      {
        "search": [
          "left_right",
          "arrow"
        ],
        "name": "left_right_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/njt1br8d.png",
        "icon": "↔",
        "shortcode": ":left_right_arrow:",
        "unicodes": [
          "u2194"
        ]
      },
      {
        "search": [
          "right",
          "arrow",
          "curving",
          "left"
        ],
        "name": "right_arrow_curving_left",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wh57rvf7.png",
        "icon": "↩",
        "shortcode": ":right_arrow_curving_left:",
        "unicodes": [
          "u21a9"
        ]
      },
      {
        "search": [
          "left",
          "arrow",
          "curving",
          "right"
        ],
        "name": "left_arrow_curving_right",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s8uegha5.png",
        "icon": "↪",
        "shortcode": ":left_arrow_curving_right:",
        "unicodes": [
          "u21aa"
        ]
      },
      {
        "search": [
          "right",
          "arrow",
          "curving",
          "up"
        ],
        "name": "right_arrow_curving_up",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hfj9cp8n.png",
        "icon": "⤴",
        "shortcode": ":right_arrow_curving_up:",
        "unicodes": [
          "u2934"
        ]
      },
      {
        "search": [
          "right",
          "arrow",
          "curving",
          "down"
        ],
        "name": "right_arrow_curving_down",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ce60k45c.png",
        "icon": "⤵",
        "shortcode": ":right_arrow_curving_down:",
        "unicodes": [
          "u2935"
        ]
      },
      {
        "search": [
          "clockwise",
          "vertical",
          "arrows"
        ],
        "name": "clockwise_vertical_arrows",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d3qfna0g.png",
        "icon": "🔃",
        "shortcode": ":clockwise_vertical_arrows:",
        "unicodes": [
          "u1f503"
        ]
      },
      {
        "search": [
          "counterclockwise",
          "arrows",
          "button"
        ],
        "name": "counterclockwise_arrows_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qi0v2h82.png",
        "icon": "🔄",
        "shortcode": ":counterclockwise_arrows_button:",
        "unicodes": [
          "u1f504"
        ]
      },
      {
        "search": [
          "BACK",
          "arrow"
        ],
        "name": "BACK_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5qz9azn8.png",
        "icon": "🔙",
        "shortcode": ":back_arrow:",
        "unicodes": [
          "u1f519"
        ]
      },
      {
        "search": [
          "END",
          "arrow"
        ],
        "name": "END_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mvke2mc3.png",
        "icon": "🔚",
        "shortcode": ":end_arrow:",
        "unicodes": [
          "u1f51a"
        ]
      },
      {
        "search": [
          "ON",
          "!",
          "arrow"
        ],
        "name": "ON_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/im9oynms.png",
        "icon": "🔛",
        "shortcode": ":on_arrow:",
        "unicodes": [
          "u1f51b"
        ]
      },
      {
        "search": [
          "SOON",
          "arrow"
        ],
        "name": "SOON_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sfiaoc9c.png",
        "icon": "🔜",
        "shortcode": ":soon_arrow:",
        "unicodes": [
          "u1f51c"
        ]
      },
      {
        "search": [
          "TOP",
          "arrow"
        ],
        "name": "TOP_arrow",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0ps6r0bl.png",
        "icon": "🔝",
        "shortcode": ":top_arrow:",
        "unicodes": [
          "u1f51d"
        ]
      },
      {
        "search": [
          "place",
          "of",
          "worship"
        ],
        "name": "place_of_worship",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ht0jf0qb.png",
        "icon": "🛐",
        "shortcode": ":place_of_worship:",
        "unicodes": [
          "u1f6d0"
        ]
      },
      {
        "search": [
          "atom",
          "symbol"
        ],
        "name": "atom_symbol",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j237h0ss.png",
        "icon": "⚛",
        "shortcode": ":atom_symbol:",
        "unicodes": [
          "u269b"
        ]
      },
      {
        "search": [
          "om"
        ],
        "name": "om",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2xwcdpc1.png",
        "icon": "🕉",
        "shortcode": ":om:",
        "unicodes": [
          "u1f549"
        ]
      },
      {
        "search": [
          "star",
          "of",
          "David"
        ],
        "name": "star_of_David",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ocb9zb2f.png",
        "icon": "✡",
        "shortcode": ":star_of_david:",
        "unicodes": [
          "u2721"
        ]
      },
      {
        "search": [
          "wheel",
          "of",
          "dharma"
        ],
        "name": "wheel_of_dharma",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4h725kj0.png",
        "icon": "☸",
        "shortcode": ":wheel_of_dharma:",
        "unicodes": [
          "u2638"
        ]
      },
      {
        "search": [
          "yin",
          "yang"
        ],
        "name": "yin_yang",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/x2w58d14.png",
        "icon": "☯",
        "shortcode": ":yin_yang:",
        "unicodes": [
          "u262f"
        ]
      },
      {
        "search": [
          "latin",
          "cross"
        ],
        "name": "latin_cross",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3wgswsxs.png",
        "icon": "✝",
        "shortcode": ":latin_cross:",
        "unicodes": [
          "u271d"
        ]
      },
      {
        "search": [
          "orthodox",
          "cross"
        ],
        "name": "orthodox_cross",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tr27mmb1.png",
        "icon": "☦",
        "shortcode": ":orthodox_cross:",
        "unicodes": [
          "u2626"
        ]
      },
      {
        "search": [
          "star",
          "and",
          "crescent"
        ],
        "name": "star_and_crescent",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qxawy8tv.png",
        "icon": "☪",
        "shortcode": ":star_and_crescent:",
        "unicodes": [
          "u262a"
        ]
      },
      {
        "search": [
          "peace",
          "symbol"
        ],
        "name": "peace_symbol",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i83d0kk4.png",
        "icon": "☮",
        "shortcode": ":peace_symbol:",
        "unicodes": [
          "u262e"
        ]
      },
      {
        "search": [
          "menorah"
        ],
        "name": "menorah",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ebwlzh0f.png",
        "icon": "🕎",
        "shortcode": ":menorah:",
        "unicodes": [
          "u1f54e"
        ]
      },
      {
        "search": [
          "dotted",
          "six_pointed",
          "star"
        ],
        "name": "dotted_six_pointed_star",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g276orvz.png",
        "icon": "🪯",
        "shortcode": ":dotted_six_pointed_star:",
        "unicodes": [
          "u1f52f"
        ]
      },
      {
        "search": [
          "Aries"
        ],
        "name": "Aries",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/64otu2sb.png",
        "icon": "♈",
        "shortcode": ":aries:",
        "unicodes": [
          "u2648"
        ]
      },
      {
        "search": [
          "Taurus"
        ],
        "name": "Taurus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e56lxoz1.png",
        "icon": "♉",
        "shortcode": ":taurus:",
        "unicodes": [
          "u2649"
        ]
      },
      {
        "search": [
          "Gemini"
        ],
        "name": "Gemini",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/plz79xfy.png",
        "icon": "♊",
        "shortcode": ":gemini:",
        "unicodes": [
          "u264a"
        ]
      },
      {
        "search": [
          "Cancer"
        ],
        "name": "Cancer",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sxlaqxz5.png",
        "icon": "♋",
        "shortcode": ":cancer:",
        "unicodes": [
          "u264b"
        ]
      },
      {
        "search": [
          "Leo"
        ],
        "name": "Leo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/eg6bktls.png",
        "icon": "♌",
        "shortcode": ":leo:",
        "unicodes": [
          "u264c"
        ]
      },
      {
        "search": [
          "Virgo"
        ],
        "name": "Virgo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a7dcrvxw.png",
        "icon": "♍",
        "shortcode": ":virgo:",
        "unicodes": [
          "u264d"
        ]
      },
      {
        "search": [
          "Libra"
        ],
        "name": "Libra",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d850vhcr.png",
        "icon": "♎",
        "shortcode": ":libra:",
        "unicodes": [
          "u264e"
        ]
      },
      {
        "search": [
          "Scorpio"
        ],
        "name": "Scorpio",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v9boobut.png",
        "icon": "♏",
        "shortcode": ":scorpio:",
        "unicodes": [
          "u264f"
        ]
      },
      {
        "search": [
          "Sagittarius"
        ],
        "name": "Sagittarius",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lnf0ikwf.png",
        "icon": "♐",
        "shortcode": ":sagittarius:",
        "unicodes": [
          "u2650"
        ]
      },
      {
        "search": [
          "Capricorn"
        ],
        "name": "Capricorn",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ms5vu325.png",
        "icon": "♑",
        "shortcode": ":capricorn:",
        "unicodes": [
          "u2651"
        ]
      },
      {
        "search": [
          "Aquarius"
        ],
        "name": "Aquarius",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1k2p05ph.png",
        "icon": "♒",
        "shortcode": ":aquarius:",
        "unicodes": [
          "u2652"
        ]
      },
      {
        "search": [
          "Pisces"
        ],
        "name": "Pisces",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mgrg8f8n.png",
        "icon": "♓",
        "shortcode": ":pisces:",
        "unicodes": [
          "u2653"
        ]
      },
      {
        "search": [
          "Ophiuchus"
        ],
        "name": "Ophiuchus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/npj5ogdg.png",
        "icon": "⛎",
        "shortcode": ":ophiuchus:",
        "unicodes": [
          "u26ce"
        ]
      },
      {
        "search": [
          "shuffle",
          "tracks",
          "button"
        ],
        "name": "shuffle_tracks_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dvzx4loi.png",
        "icon": "🔀",
        "shortcode": ":shuffle_tracks_button:",
        "unicodes": [
          "u1f500"
        ]
      },
      {
        "search": [
          "repeat",
          "button"
        ],
        "name": "repeat_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4apt4c0j.png",
        "icon": "🔁",
        "shortcode": ":repeat_button:",
        "unicodes": [
          "u1f501"
        ]
      },
      {
        "search": [
          "repeat",
          "single",
          "button"
        ],
        "name": "repeat_single_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fk4srzp5.png",
        "icon": "🔂",
        "shortcode": ":repeat_single_button:",
        "unicodes": [
          "u1f502"
        ]
      },
      {
        "search": [
          "play",
          "button"
        ],
        "name": "play_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3ioi96ii.png",
        "icon": "▶",
        "shortcode": ":play_button:",
        "unicodes": [
          "u25b6"
        ]
      },
      {
        "search": [
          "fast_forward",
          "button"
        ],
        "name": "fast_forward_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a7ov42ln.png",
        "icon": "⏩",
        "shortcode": ":fast_forward_button:",
        "unicodes": [
          "u23e9"
        ]
      },
      {
        "search": [
          "next",
          "track",
          "button"
        ],
        "name": "next_track_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/drzr1ma4.png",
        "icon": "⏭",
        "shortcode": ":next_track_button:",
        "unicodes": [
          "u23ed"
        ]
      },
      {
        "search": [
          "play",
          "or",
          "pause",
          "button"
        ],
        "name": "play_or_pause_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sjhj31sr.png",
        "icon": "⏯",
        "shortcode": ":play_or_pause_button:",
        "unicodes": [
          "u23ef"
        ]
      },
      {
        "search": [
          "reverse",
          "button"
        ],
        "name": "reverse_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s6i27bvl.png",
        "icon": "◀",
        "shortcode": ":reverse_button:",
        "unicodes": [
          "u25c0"
        ]
      },
      {
        "search": [
          "fast",
          "reverse",
          "button"
        ],
        "name": "fast_reverse_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2p7eav4c.png",
        "icon": "⏪",
        "shortcode": ":fast_reverse_button:",
        "unicodes": [
          "u23ea"
        ]
      },
      {
        "search": [
          "last",
          "track",
          "button"
        ],
        "name": "last_track_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tomiuhla.png",
        "icon": "⏮",
        "shortcode": ":last_track_button:",
        "unicodes": [
          "u23ee"
        ]
      },
      {
        "search": [
          "upwards",
          "button"
        ],
        "name": "upwards_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h3slgvuj.png",
        "icon": "🔼",
        "shortcode": ":upwards_button:",
        "unicodes": [
          "u1f53c"
        ]
      },
      {
        "search": [
          "fast",
          "up",
          "button"
        ],
        "name": "fast_up_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/m5o8tp9p.png",
        "icon": "⏫",
        "shortcode": ":fast_up_button:",
        "unicodes": [
          "u23eb"
        ]
      },
      {
        "search": [
          "downwards",
          "button"
        ],
        "name": "downwards_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cwfp11gv.png",
        "icon": "🔽",
        "shortcode": ":downwards_button:",
        "unicodes": [
          "u1f53d"
        ]
      },
      {
        "search": [
          "fast",
          "down",
          "button"
        ],
        "name": "fast_down_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6u43jlfw.png",
        "icon": "⏬",
        "shortcode": ":fast_down_button:",
        "unicodes": [
          "u23ec"
        ]
      },
      {
        "search": [
          "pause",
          "button"
        ],
        "name": "pause_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/n7x2uibf.png",
        "icon": "⏸",
        "shortcode": ":pause_button:",
        "unicodes": [
          "u23f8"
        ]
      },
      {
        "search": [
          "stop",
          "button"
        ],
        "name": "stop_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rsbsgwrp.png",
        "icon": "⏹",
        "shortcode": ":stop_button:",
        "unicodes": [
          "u23f9"
        ]
      },
      {
        "search": [
          "record",
          "button"
        ],
        "name": "record_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/76gova3e.png",
        "icon": "⏺",
        "shortcode": ":record_button:",
        "unicodes": [
          "u23fa"
        ]
      },
      {
        "search": [
          "eject",
          "button"
        ],
        "name": "eject_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yveqo2qr.png",
        "icon": "⏏",
        "shortcode": ":eject_button:",
        "unicodes": [
          "u23cf"
        ]
      },
      {
        "search": [
          "cinema"
        ],
        "name": "cinema",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a9uhq5x9.png",
        "icon": "🎦",
        "shortcode": ":cinema:",
        "unicodes": [
          "u1f3a6"
        ]
      },
      {
        "search": [
          "dim",
          "button"
        ],
        "name": "dim_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tnmo9p04.png",
        "icon": "🔅",
        "shortcode": ":dim_button:",
        "unicodes": [
          "u1f505"
        ]
      },
      {
        "search": [
          "bright",
          "button"
        ],
        "name": "bright_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ssaoupeu.png",
        "icon": "🔆",
        "shortcode": ":bright_button:",
        "unicodes": [
          "u1f506"
        ]
      },
      {
        "search": [
          "antenna",
          "bars"
        ],
        "name": "antenna_bars",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vecietvx.png",
        "icon": "📶",
        "shortcode": ":antenna_bars:",
        "unicodes": [
          "u1f4f6"
        ]
      },
      {
        "search": [
          "vibration",
          "mode"
        ],
        "name": "vibration_mode",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/p5mx4cdn.png",
        "icon": "📳",
        "shortcode": ":vibration_mode:",
        "unicodes": [
          "u1f4f3"
        ]
      },
      {
        "search": [
          "mobile",
          "phone",
          "off"
        ],
        "name": "mobile_phone_off",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4uaftrrc.png",
        "icon": "📴",
        "shortcode": ":mobile_phone_off:",
        "unicodes": [
          "u1f4f4"
        ]
      },
      {
        "search": [
          "female",
          "sign"
        ],
        "name": "female_sign",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9o6v4je2.png",
        "icon": "♀",
        "shortcode": ":female_sign:",
        "unicodes": [
          "u2640"
        ]
      },
      {
        "search": [
          "male",
          "sign"
        ],
        "name": "male_sign",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6jg37rpp.png",
        "icon": "♂",
        "shortcode": ":male_sign:",
        "unicodes": [
          "u2642"
        ]
      },
      {
        "search": [
          "transgender",
          "symbol"
        ],
        "name": "transgender_symbol",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/v2bkmhwq.png",
        "icon": "⚧",
        "shortcode": ":transgender_symbol:",
        "unicodes": [
          "u26a7"
        ]
      },
      {
        "search": [
          "multiply"
        ],
        "name": "multiply",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wjki4s9p.png",
        "icon": "✖",
        "shortcode": ":multiply:",
        "unicodes": [
          "u2716"
        ]
      },
      {
        "search": [
          "plus"
        ],
        "name": "plus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/o8duphxx.png",
        "icon": "➕",
        "shortcode": ":plus:",
        "unicodes": [
          "u2795"
        ]
      },
      {
        "search": [
          "minus"
        ],
        "name": "minus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/974xfum8.png",
        "icon": "➖",
        "shortcode": ":minus:",
        "unicodes": [
          "u2796"
        ]
      },
      {
        "search": [
          "divide"
        ],
        "name": "divide",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0nklmzt6.png",
        "icon": "➗",
        "shortcode": ":divide:",
        "unicodes": [
          "u2797"
        ]
      },
      {
        "search": [
          "heavy",
          "equals",
          "sign"
        ],
        "name": "heavy_equals_sign",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f769gabq.png",
        "icon": "🟰",
        "shortcode": ":heavy_equals_sign:",
        "unicodes": [
          "u1f7f0"
        ]
      },
      {
        "search": [
          "infinity"
        ],
        "name": "infinity",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d7h0uqn2.png",
        "icon": "♾",
        "shortcode": ":infinity:",
        "unicodes": [
          "u267e"
        ]
      },
      {
        "search": [
          "double",
          "exclamation",
          "mark"
        ],
        "name": "double_exclamation_mark",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ow4mspm6.png",
        "icon": "‼",
        "shortcode": ":double_exclamation_mark:",
        "unicodes": [
          "u203c"
        ]
      },
      {
        "search": [
          "exclamation",
          "question",
          "mark"
        ],
        "name": "exclamation_question_mark",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/otdjjn6j.png",
        "icon": "⁉",
        "shortcode": ":exclamation_question_mark:",
        "unicodes": [
          "u2049"
        ]
      },
      {
        "search": [
          "red",
          "question",
          "mark"
        ],
        "name": "red_question_mark",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/40l05y1b.png",
        "icon": "❓",
        "shortcode": ":red_question_mark:",
        "unicodes": [
          "u2753"
        ]
      },
      {
        "search": [
          "white",
          "question",
          "mark"
        ],
        "name": "white_question_mark",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tkd540qd.png",
        "icon": "❔",
        "shortcode": ":white_question_mark:",
        "unicodes": [
          "u2754"
        ]
      },
      {
        "search": [
          "white",
          "exclamation",
          "mark"
        ],
        "name": "white_exclamation_mark",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lxesrrlt.png",
        "icon": "❕",
        "shortcode": ":white_exclamation_mark:",
        "unicodes": [
          "u2755"
        ]
      },
      {
        "search": [
          "red",
          "exclamation",
          "mark"
        ],
        "name": "red_exclamation_mark",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6k9lxthc.png",
        "icon": "❗",
        "shortcode": ":red_exclamation_mark:",
        "unicodes": [
          "u2757"
        ]
      },
      {
        "search": [
          "wavy",
          "dash"
        ],
        "name": "wavy_dash",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7kc0bpbs.png",
        "icon": "〰",
        "shortcode": ":wavy_dash:",
        "unicodes": [
          "u3030"
        ]
      },
      {
        "search": [
          "currency",
          "exchange"
        ],
        "name": "currency_exchange",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mcs62sru.png",
        "icon": "💱",
        "shortcode": ":currency_exchange:",
        "unicodes": [
          "u1f4b1"
        ]
      },
      {
        "search": [
          "heavy",
          "dollar",
          "sign"
        ],
        "name": "heavy_dollar_sign",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/toep7sw4.png",
        "icon": "💲",
        "shortcode": ":heavy_dollar_sign:",
        "unicodes": [
          "u1f4b2"
        ]
      },
      {
        "search": [
          "medical",
          "symbol"
        ],
        "name": "medical_symbol",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5yx8u9h7.png",
        "icon": "⚕",
        "shortcode": ":medical_symbol:",
        "unicodes": [
          "u2695"
        ]
      },
      {
        "search": [
          "recycling",
          "symbol"
        ],
        "name": "recycling_symbol",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pydto0lz.png",
        "icon": "♻",
        "shortcode": ":recycling_symbol:",
        "unicodes": [
          "u267b"
        ]
      },
      {
        "search": [
          "fleur_de_lis"
        ],
        "name": "fleur_de_lis",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jzz6mtge.png",
        "icon": "⚜",
        "shortcode": ":fleur_de_lis:",
        "unicodes": [
          "u269c"
        ]
      },
      {
        "search": [
          "trident",
          "emblem"
        ],
        "name": "trident_emblem",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/z5gc40rn.png",
        "icon": "🔱",
        "shortcode": ":trident_emblem:",
        "unicodes": [
          "u1f531"
        ]
      },
      {
        "search": [
          "name",
          "badge"
        ],
        "name": "name_badge",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ogzuch19.png",
        "icon": "📛",
        "shortcode": ":name_badge:",
        "unicodes": [
          "u1f4db"
        ]
      },
      {
        "search": [
          "Japanese",
          "symbol",
          "for",
          "beginner"
        ],
        "name": "Japanese_symbol_for_beginner",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8vsij49t.png",
        "icon": "🔰",
        "shortcode": ":japanese_symbol_for_beginner:",
        "unicodes": [
          "u1f530"
        ]
      },
      {
        "search": [
          "hollow",
          "red",
          "circle"
        ],
        "name": "hollow_red_circle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3sygwhpt.png",
        "icon": "⭕",
        "shortcode": ":hollow_red_circle:",
        "unicodes": [
          "u2b55"
        ]
      },
      {
        "search": [
          "check",
          "mark",
          "button"
        ],
        "name": "check_mark_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bt48p7s2.png",
        "icon": "✅",
        "shortcode": ":check_mark_button:",
        "unicodes": [
          "u2705"
        ]
      },
      {
        "search": [
          "check",
          "box",
          "with",
          "check"
        ],
        "name": "check_box_with_check",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/se5geptm.png",
        "icon": "☑",
        "shortcode": ":check_box_with_check:",
        "unicodes": [
          "u2611"
        ]
      },
      {
        "search": [
          "check",
          "mark"
        ],
        "name": "check_mark",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8csv0lgn.png",
        "icon": "✔",
        "shortcode": ":check_mark:",
        "unicodes": [
          "u2714"
        ]
      },
      {
        "search": [
          "cross",
          "mark"
        ],
        "name": "cross_mark",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jju0piqt.png",
        "icon": "❌",
        "shortcode": ":cross_mark:",
        "unicodes": [
          "u274c"
        ]
      },
      {
        "search": [
          "cross",
          "mark",
          "button"
        ],
        "name": "cross_mark_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iibmto08.png",
        "icon": "❎",
        "shortcode": ":cross_mark_button:",
        "unicodes": [
          "u274e"
        ]
      },
      {
        "search": [
          "curly",
          "loop"
        ],
        "name": "curly_loop",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gaikmfqx.png",
        "icon": "➰",
        "shortcode": ":curly_loop:",
        "unicodes": [
          "u27b0"
        ]
      },
      {
        "search": [
          "double",
          "curly",
          "loop"
        ],
        "name": "double_curly_loop",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qil3lsw3.png",
        "icon": "➿",
        "shortcode": ":double_curly_loop:",
        "unicodes": [
          "u27bf"
        ]
      },
      {
        "search": [
          "part",
          "alternation",
          "mark"
        ],
        "name": "part_alternation_mark",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/u9d8ku7q.png",
        "icon": "〽",
        "shortcode": ":part_alternation_mark:",
        "unicodes": [
          "u303d"
        ]
      },
      {
        "search": [
          "eight_spoked",
          "asterisk"
        ],
        "name": "eight_spoked_asterisk",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1865n5x7.png",
        "icon": "✳",
        "shortcode": ":eight_spoked_asterisk:",
        "unicodes": [
          "u2733"
        ]
      },
      {
        "search": [
          "eight_pointed",
          "star"
        ],
        "name": "eight_pointed_star",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pgihrzj4.png",
        "icon": "✴",
        "shortcode": ":eight_pointed_star:",
        "unicodes": [
          "u2734"
        ]
      },
      {
        "search": [
          "sparkle"
        ],
        "name": "sparkle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s09lwoyi.png",
        "icon": "❇",
        "shortcode": ":sparkle:",
        "unicodes": [
          "u2747"
        ]
      },
      {
        "search": [
          "copyright"
        ],
        "name": "copyright",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/13mq0srp.png",
        "icon": "©",
        "shortcode": ":copyright:",
        "unicodes": [
          "u00a9"
        ]
      },
      {
        "search": [
          "registered"
        ],
        "name": "registered",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8yr0kmgt.png",
        "icon": "®",
        "shortcode": ":registered:",
        "unicodes": [
          "u00ae"
        ]
      },
      {
        "search": [
          "trade",
          "mark"
        ],
        "name": "trade_mark",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ghvy5uqz.png",
        "icon": "™",
        "shortcode": ":trade_mark:",
        "unicodes": [
          "u2122"
        ]
      },
      {
        "search": [
          "keycap",
          "#"
        ],
        "name": "keycap_hash",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/thunuyo9.png",
        "icon": "#️⃣",
        "shortcode": ":keycap_hash:",
        "unicodes": [
          "u0023",
          "ufe0f",
          "u20e3"
        ]
      },
      {
        "search": [
          "keycap",
          "*"
        ],
        "name": "keycap_asterisk",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/u8na72n8.png",
        "icon": "*️⃣",
        "shortcode": ":keycap_asterisk:",
        "unicodes": [
          "u002a",
          "ufe0f",
          "u20e3"
        ]
      },
      {
        "search": [
          "keycap",
          "0"
        ],
        "name": "keycap_zero",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sg14eyl0.png",
        "icon": "0️⃣",
        "shortcode": ":keycap_zero:",
        "unicodes": [
          "u0030",
          "ufe0f",
          "u20e3"
        ]
      },
      {
        "search": [
          "keycap",
          "1"
        ],
        "name": "keycap_1",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1dk11xyi.png",
        "icon": "1️⃣",
        "shortcode": ":keycap_1:",
        "unicodes": [
          "u0031",
          "ufe0f",
          "u20e3"
        ]
      },
      {
        "search": [
          "keycap",
          "2"
        ],
        "name": "keycap_2",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bnhohubk.png",
        "icon": "2️⃣",
        "shortcode": ":keycap_2:",
        "unicodes": [
          "u0032",
          "ufe0f",
          "u20e3"
        ]
      },
      {
        "search": [
          "keycap",
          "3"
        ],
        "name": "keycap_3",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/he5y3xb3.png",
        "icon": "3️⃣",
        "shortcode": ":keycap_3:",
        "unicodes": [
          "u0033",
          "ufe0f",
          "u20e3"
        ]
      },
      {
        "search": [
          "keycap",
          "4"
        ],
        "name": "keycap_4",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cm4xigv6.png",
        "icon": "4️⃣",
        "shortcode": ":keycap_4:",
        "unicodes": [
          "u0034",
          "ufe0f",
          "u20e3"
        ]
      },
      {
        "search": [
          "keycap",
          "5"
        ],
        "name": "keycap_5",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f3ikj2lq.png",
        "icon": "5️⃣",
        "shortcode": ":keycap_5:",
        "unicodes": [
          "u0035",
          "ufe0f",
          "u20e3"
        ]
      },
      {
        "search": [
          "keycap",
          "6"
        ],
        "name": "keycap_6",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9gmssvn3.png",
        "icon": "6️⃣",
        "shortcode": ":keycap_6:",
        "unicodes": [
          "u0036",
          "ufe0f",
          "u20e3"
        ]
      },
      {
        "search": [
          "keycap",
          "7"
        ],
        "name": "keycap_7",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sqpvgf98.png",
        "icon": "7️⃣",
        "shortcode": ":keycap_7:",
        "unicodes": [
          "u0037",
          "ufe0f",
          "u20e3"
        ]
      },
      {
        "search": [
          "keycap",
          "8"
        ],
        "name": "keycap_8",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i0tqf327.png",
        "icon": "8️⃣",
        "shortcode": ":keycap_8:",
        "unicodes": [
          "u0038",
          "ufe0f",
          "u20e3"
        ]
      },
      {
        "search": [
          "keycap",
          "9"
        ],
        "name": "keycap_9",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0y72uedz.png",
        "icon": "9️⃣",
        "shortcode": ":keycap_9:",
        "unicodes": [
          "u0039",
          "ufe0f",
          "u20e3"
        ]
      },
      {
        "search": [
          "keycap",
          "10"
        ],
        "name": "keycap_10",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9b29iw08.png",
        "icon": "🔟",
        "shortcode": ":keycap_10:",
        "unicodes": [
          "u1f51f"
        ]
      },
      {
        "search": [
          "input",
          "latin",
          "uppercase"
        ],
        "name": "input_latin_uppercase",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uvoridex.png",
        "icon": "🔠",
        "shortcode": ":input_latin_uppercase:",
        "unicodes": [
          "u1f520"
        ]
      },
      {
        "search": [
          "input",
          "latin",
          "lowercase"
        ],
        "name": "input_latin_lowercase",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j15sccnw.png",
        "icon": "🔡",
        "shortcode": ":input_latin_lowercase:",
        "unicodes": [
          "u1f521"
        ]
      },
      {
        "search": [
          "input",
          "numbers"
        ],
        "name": "input_numbers",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yz2jcifn.png",
        "icon": "🔢",
        "shortcode": ":input_numbers:",
        "unicodes": [
          "u1f522"
        ]
      },
      {
        "search": [
          "input",
          "symbols"
        ],
        "name": "input_symbols",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lisk48me.png",
        "icon": "🔣",
        "shortcode": ":input_symbols:",
        "unicodes": [
          "u1f523"
        ]
      },
      {
        "search": [
          "input",
          "latin",
          "letters"
        ],
        "name": "input_latin_letters",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rhswckyt.png",
        "icon": "🔤",
        "shortcode": ":input_latin_letters:",
        "unicodes": [
          "u1f524"
        ]
      },
      {
        "search": [
          "A",
          "button",
          "blood",
          "type"
        ],
        "name": "A_button_blood_type",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/n8iwz0ox.png",
        "icon": "🅰",
        "shortcode": ":a_button_blood_type:",
        "unicodes": [
          "u1f170"
        ]
      },
      {
        "search": [
          "AB",
          "button",
          "blood",
          "type"
        ],
        "name": "AB_button_blood_type",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/m0j7v4nl.png",
        "icon": "🆎",
        "shortcode": ":ab_button_blood_type:",
        "unicodes": [
          "u1f18e"
        ]
      },
      {
        "search": [
          "B",
          "button",
          "blood",
          "type"
        ],
        "name": "B_button_blood_type",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lz46xdfz.png",
        "icon": "🅱",
        "shortcode": ":b_button_blood_type:",
        "unicodes": [
          "u1f171"
        ]
      },
      {
        "search": [
          "CL",
          "button"
        ],
        "name": "CL_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ni2qgpxt.png",
        "icon": "🆑",
        "shortcode": ":cl_button:",
        "unicodes": [
          "u1f191"
        ]
      },
      {
        "search": [
          "COOL",
          "button"
        ],
        "name": "COOL_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jtt651pv.png",
        "icon": "🆒",
        "shortcode": ":cool_button:",
        "unicodes": [
          "u1f192"
        ]
      },
      {
        "search": [
          "FREE",
          "button"
        ],
        "name": "FREE_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/serhs1uw.png",
        "icon": "🆓",
        "shortcode": ":free_button:",
        "unicodes": [
          "u1f193"
        ]
      },
      {
        "search": [
          "information"
        ],
        "name": "information",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/npi8kx3m.png",
        "icon": "ℹ",
        "shortcode": ":information:",
        "unicodes": [
          "u2139"
        ]
      },
      {
        "search": [
          "ID",
          "button"
        ],
        "name": "ID_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2sesnu45.png",
        "icon": "🆔",
        "shortcode": ":id_button:",
        "unicodes": [
          "u1f194"
        ]
      },
      {
        "search": [
          "circled",
          "M"
        ],
        "name": "circled_M",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i20e6oms.png",
        "icon": "Ⓜ",
        "shortcode": ":circled_m:",
        "unicodes": [
          "u24c2"
        ]
      },
      {
        "search": [
          "NEW",
          "button"
        ],
        "name": "NEW_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0xrhgwi7.png",
        "icon": "🆕",
        "shortcode": ":new_button:",
        "unicodes": [
          "u1f195"
        ]
      },
      {
        "search": [
          "NG",
          "button"
        ],
        "name": "NG_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1qyeqdga.png",
        "icon": "🆖",
        "shortcode": ":ng_button:",
        "unicodes": [
          "u1f196"
        ]
      },
      {
        "search": [
          "O",
          "button",
          "blood",
          "type"
        ],
        "name": "O_button_blood_type",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dl481fip.png",
        "icon": "🅾",
        "shortcode": ":o_button_blood_type:",
        "unicodes": [
          "u1f17e"
        ]
      },
      {
        "search": [
          "ok",
          "button"
        ],
        "name": "OK_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uffnkhti.png",
        "icon": "🆗",
        "shortcode": ":ok_button:",
        "unicodes": [
          "u1f197"
        ]
      },
      {
        "search": [
          "P",
          "button"
        ],
        "name": "P_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5dhryjee.png",
        "icon": "🅿",
        "shortcode": ":p_button:",
        "unicodes": [
          "u1f17f"
        ]
      },
      {
        "search": [
          "SOS",
          "button"
        ],
        "name": "SOS_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9a9uyqdx.png",
        "icon": "🆘",
        "shortcode": ":sos_button:",
        "unicodes": [
          "u1f198"
        ]
      },
      {
        "search": [
          "UP",
          "button"
        ],
        "name": "UP_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2o0684kn.png",
        "icon": "🆙",
        "shortcode": ":up_button:",
        "unicodes": [
          "u1f199"
        ]
      },
      {
        "search": [
          "VS",
          "button"
        ],
        "name": "VS_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/z2fl9ltt.png",
        "icon": "🆚",
        "shortcode": ":vs_button:",
        "unicodes": [
          "u1f19a"
        ]
      },
      {
        "search": [
          "Japanese",
          "“here”",
          "button"
        ],
        "name": "Japanese_“here”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/635ew061.png",
        "icon": "🈁",
        "shortcode": ":japanese_here_button:",
        "unicodes": [
          "u1f201"
        ]
      },
      {
        "search": [
          "Japanese",
          "“service",
          "charge”",
          "button"
        ],
        "name": "Japanese_“service_charge”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ffwkj4yz.png",
        "icon": "🈂",
        "shortcode": ":japanese_service_charge_button:",
        "unicodes": [
          "u1f202"
        ]
      },
      {
        "search": [
          "Japanese",
          "“monthly",
          "amount”",
          "button"
        ],
        "name": "Japanese_“monthly_amount”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1wk5vhtn.png",
        "icon": "🈷",
        "shortcode": ":japanese_monthly_amount_button:",
        "unicodes": [
          "u1f237"
        ]
      },
      {
        "search": [
          "Japanese",
          "“not",
          "free",
          "of",
          "charge”",
          "button"
        ],
        "name": "Japanese_“not_free_of_charge”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cgg7cu8h.png",
        "icon": "🈶",
        "shortcode": ":japanese_not_free_of_charge_button:",
        "unicodes": [
          "u1f236"
        ]
      },
      {
        "search": [
          "Japanese",
          "“reserved”",
          "button"
        ],
        "name": "Japanese_“reserved”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/md22qptq.png",
        "icon": "🈯",
        "shortcode": ":japanese_reserved_button:",
        "unicodes": [
          "u1f22f"
        ]
      },
      {
        "search": [
          "Japanese",
          "“bargain”",
          "button"
        ],
        "name": "Japanese_“bargain”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2g4gzvhs.png",
        "icon": "🉐",
        "shortcode": ":japanese_bargain_button:",
        "unicodes": [
          "u1f250"
        ]
      },
      {
        "search": [
          "Japanese",
          "“discount”",
          "button"
        ],
        "name": "Japanese_“discount”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6sddcpr5.png",
        "icon": "🈹",
        "shortcode": ":japanese_discount_button:",
        "unicodes": [
          "u1f239"
        ]
      },
      {
        "search": [
          "Japanese",
          "“free",
          "of",
          "charge”",
          "button"
        ],
        "name": "Japanese_“free_of_charge”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gyble82t.png",
        "icon": "🈚",
        "shortcode": ":japanese_free_of_charge_button:",
        "unicodes": [
          "u1f21a"
        ]
      },
      {
        "search": [
          "Japanese",
          "“prohibited”",
          "button"
        ],
        "name": "Japanese_“prohibited”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3t85niwj.png",
        "icon": "🈲",
        "shortcode": ":japanese_prohibited_button:",
        "unicodes": [
          "u1f232"
        ]
      },
      {
        "search": [
          "Japanese",
          "“acceptable”",
          "button"
        ],
        "name": "Japanese_“acceptable”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ge6uikfk.png",
        "icon": "🉑",
        "shortcode": ":japanese_acceptable_button:",
        "unicodes": [
          "u1f251"
        ]
      },
      {
        "search": [
          "Japanese",
          "“application”",
          "button"
        ],
        "name": "Japanese_“application”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8cs0isy3.png",
        "icon": "🈸",
        "shortcode": ":japanese_application_button:",
        "unicodes": [
          "u1f238"
        ]
      },
      {
        "search": [
          "Japanese",
          "“passing",
          "grade”",
          "button"
        ],
        "name": "Japanese_“passing_grade”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j9t749vp.png",
        "icon": "🈴",
        "shortcode": ":japanese_passing_grade_button:",
        "unicodes": [
          "u1f234"
        ]
      },
      {
        "search": [
          "Japanese",
          "“vacancy”",
          "button"
        ],
        "name": "Japanese_“vacancy”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nxatt1kn.png",
        "icon": "🈳",
        "shortcode": ":japanese_vacancy_button:",
        "unicodes": [
          "u1f233"
        ]
      },
      {
        "search": [
          "Japanese",
          "“congratulations”",
          "button"
        ],
        "name": "Japanese_“congratulations”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j9sbgkul.png",
        "icon": "㊗",
        "shortcode": ":japanese_congratulations_button:",
        "unicodes": [
          "u3297"
        ]
      },
      {
        "search": [
          "Japanese",
          "“secret”",
          "button"
        ],
        "name": "Japanese_“secret”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xxvg0ez1.png",
        "icon": "㊙",
        "shortcode": ":japanese_secret_button:",
        "unicodes": [
          "u3299"
        ]
      },
      {
        "search": [
          "Japanese",
          "“open",
          "for",
          "business”",
          "button"
        ],
        "name": "Japanese_“open_for_business”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/p0zu6223.png",
        "icon": "🈺",
        "shortcode": ":japanese_open_for_business_button:",
        "unicodes": [
          "u1f23a"
        ]
      },
      {
        "search": [
          "Japanese",
          "“no",
          "vacancy”",
          "button"
        ],
        "name": "Japanese_“no_vacancy”_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1b5f6g65.png",
        "icon": "🈵",
        "shortcode": ":japanese_no_vacancy_button:",
        "unicodes": [
          "u1f235"
        ]
      },
      {
        "search": [
          "red",
          "circle"
        ],
        "name": "red_circle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wkpdney0.png",
        "icon": "🔴",
        "shortcode": ":red_circle:",
        "unicodes": [
          "u1f534"
        ]
      },
      {
        "search": [
          "orange",
          "circle"
        ],
        "name": "orange_circle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pxv6xd07.png",
        "icon": "🟠",
        "shortcode": ":orange_circle:",
        "unicodes": [
          "u1f7e0"
        ]
      },
      {
        "search": [
          "yellow",
          "circle"
        ],
        "name": "yellow_circle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i69cxa7j.png",
        "icon": "🟡",
        "shortcode": ":yellow_circle:",
        "unicodes": [
          "u1f7e1"
        ]
      },
      {
        "search": [
          "green",
          "circle"
        ],
        "name": "green_circle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/czo432j8.png",
        "icon": "🟢",
        "shortcode": ":green_circle:",
        "unicodes": [
          "u1f7e2"
        ]
      },
      {
        "search": [
          "blue",
          "circle"
        ],
        "name": "blue_circle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4m4f832c.png",
        "icon": "🔵",
        "shortcode": ":blue_circle:",
        "unicodes": [
          "u1f535"
        ]
      },
      {
        "search": [
          "purple",
          "circle"
        ],
        "name": "purple_circle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zicpwlj9.png",
        "icon": "🟣",
        "shortcode": ":purple_circle:",
        "unicodes": [
          "u1f7e3"
        ]
      },
      {
        "search": [
          "brown",
          "circle"
        ],
        "name": "brown_circle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dj42dgqh.png",
        "icon": "🟤",
        "shortcode": ":brown_circle:",
        "unicodes": [
          "u1f7e4"
        ]
      },
      {
        "search": [
          "black",
          "circle"
        ],
        "name": "black_circle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mvmmdasx.png",
        "icon": "⚫",
        "shortcode": ":black_circle:",
        "unicodes": [
          "u26ab"
        ]
      },
      {
        "search": [
          "white",
          "circle"
        ],
        "name": "white_circle",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iuv4khsl.png",
        "icon": "⚪",
        "shortcode": ":white_circle:",
        "unicodes": [
          "u26aa"
        ]
      },
      {
        "search": [
          "red",
          "square"
        ],
        "name": "red_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/32n6kis5.png",
        "icon": "🟥",
        "shortcode": ":red_square:",
        "unicodes": [
          "u1f7e5"
        ]
      },
      {
        "search": [
          "orange",
          "square"
        ],
        "name": "orange_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hooc173n.png",
        "icon": "🟧",
        "shortcode": ":orange_square:",
        "unicodes": [
          "u1f7e7"
        ]
      },
      {
        "search": [
          "yellow",
          "square"
        ],
        "name": "yellow_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/t63e3006.png",
        "icon": "🟨",
        "shortcode": ":yellow_square:",
        "unicodes": [
          "u1f7e8"
        ]
      },
      {
        "search": [
          "green",
          "square"
        ],
        "name": "green_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e27v4866.png",
        "icon": "🟩",
        "shortcode": ":green_square:",
        "unicodes": [
          "u1f7e9"
        ]
      },
      {
        "search": [
          "blue",
          "square"
        ],
        "name": "blue_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gof1m99l.png",
        "icon": "🟦",
        "shortcode": ":blue_square:",
        "unicodes": [
          "u1f7e6"
        ]
      },
      {
        "search": [
          "purple",
          "square"
        ],
        "name": "purple_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0tfz92l5.png",
        "icon": "🟪",
        "shortcode": ":purple_square:",
        "unicodes": [
          "u1f7ea"
        ]
      },
      {
        "search": [
          "brown",
          "square"
        ],
        "name": "brown_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2bgqhapn.png",
        "icon": "🟫",
        "shortcode": ":brown_square:",
        "unicodes": [
          "u1f7eb"
        ]
      },
      {
        "search": [
          "black",
          "large",
          "square"
        ],
        "name": "black_large_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tsxdola0.png",
        "icon": "⬛",
        "shortcode": ":black_large_square:",
        "unicodes": [
          "u2b1b"
        ]
      },
      {
        "search": [
          "white",
          "large",
          "square"
        ],
        "name": "white_large_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2pi1vtdp.png",
        "icon": "⬜",
        "shortcode": ":white_large_square:",
        "unicodes": [
          "u2b1c"
        ]
      },
      {
        "search": [
          "black",
          "medium",
          "square"
        ],
        "name": "black_medium_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cbe31a9e.png",
        "icon": "◼",
        "shortcode": ":black_medium_square:",
        "unicodes": [
          "u25fc"
        ]
      },
      {
        "search": [
          "white",
          "medium",
          "square"
        ],
        "name": "white_medium_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zd72tfc0.png",
        "icon": "◻",
        "shortcode": ":white_medium_square:",
        "unicodes": [
          "u25fb"
        ]
      },
      {
        "search": [
          "black",
          "medium_small",
          "square"
        ],
        "name": "black_medium_small_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lhox62os.png",
        "icon": "◾",
        "shortcode": ":black_medium_small_square:",
        "unicodes": [
          "u25fe"
        ]
      },
      {
        "search": [
          "white",
          "medium_small",
          "square"
        ],
        "name": "white_medium_small_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yytnkv2i.png",
        "icon": "◽",
        "shortcode": ":white_medium_small_square:",
        "unicodes": [
          "u25fd"
        ]
      },
      {
        "search": [
          "black",
          "small",
          "square"
        ],
        "name": "black_small_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lx5wxut1.png",
        "icon": "▪",
        "shortcode": ":black_small_square:",
        "unicodes": [
          "u25aa"
        ]
      },
      {
        "search": [
          "white",
          "small",
          "square"
        ],
        "name": "white_small_square",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8jtk84bz.png",
        "icon": "▫",
        "shortcode": ":white_small_square:",
        "unicodes": [
          "u25ab"
        ]
      },
      {
        "search": [
          "large",
          "orange",
          "diamond"
        ],
        "name": "large_orange_diamond",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ka4rhxns.png",
        "icon": "🔶",
        "shortcode": ":large_orange_diamond:",
        "unicodes": [
          "u1f536"
        ]
      },
      {
        "search": [
          "large",
          "blue",
          "diamond"
        ],
        "name": "large_blue_diamond",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ipc3o0wu.png",
        "icon": "🔷",
        "shortcode": ":large_blue_diamond:",
        "unicodes": [
          "u1f537"
        ]
      },
      {
        "search": [
          "small",
          "orange",
          "diamond"
        ],
        "name": "small_orange_diamond",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/c3s18qg7.png",
        "icon": "🔸",
        "shortcode": ":small_orange_diamond:",
        "unicodes": [
          "u1f538"
        ]
      },
      {
        "search": [
          "small",
          "blue",
          "diamond"
        ],
        "name": "small_blue_diamond",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wgduwj0c.png",
        "icon": "🔹",
        "shortcode": ":small_blue_diamond:",
        "unicodes": [
          "u1f539"
        ]
      },
      {
        "search": [
          "red",
          "triangle",
          "pointed",
          "up"
        ],
        "name": "red_triangle_pointed_up",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hpk1am3d.png",
        "icon": "🔺",
        "shortcode": ":red_triangle_pointed_up:",
        "unicodes": [
          "u1f53a"
        ]
      },
      {
        "search": [
          "red",
          "triangle",
          "pointed",
          "down"
        ],
        "name": "red_triangle_pointed_down",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hsi4mdnn.png",
        "icon": "🔻",
        "shortcode": ":red_triangle_pointed_down:",
        "unicodes": [
          "u1f53b"
        ]
      },
      {
        "search": [
          "diamond",
          "with",
          "a",
          "dot"
        ],
        "name": "diamond_with_a_dot",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ev0mxmfh.png",
        "icon": "💠",
        "shortcode": ":diamond_with_a_dot:",
        "unicodes": [
          "u1f4a0"
        ]
      },
      {
        "search": [
          "radio",
          "button"
        ],
        "name": "radio_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/opaugljz.png",
        "icon": "🔘",
        "shortcode": ":radio_button:",
        "unicodes": [
          "u1f518"
        ]
      },
      {
        "search": [
          "white",
          "square",
          "button"
        ],
        "name": "white_square_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tbuxeacf.png",
        "icon": "🔳",
        "shortcode": ":white_square_button:",
        "unicodes": [
          "u1f533"
        ]
      },
      {
        "search": [
          "black",
          "square",
          "button"
        ],
        "name": "black_square_button",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/axlfkhdn.png",
        "icon": "🔲",
        "shortcode": ":black_square_button:",
        "unicodes": [
          "u1f532"
        ]
      }
    ]
  },
  {
    "category": "flags",
    "text": "Flags",
    "icons": [
      {
        "search": [
          "chequered",
          "flag"
        ],
        "name": "chequered_flag",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/u1dvjr0q.png",
        "icon": "🏁",
        "shortcode": ":chequered_flag:",
        "unicodes": [
          "u1f3c1"
        ]
      },
      {
        "search": [
          "triangular",
          "flag"
        ],
        "name": "triangular_flag",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pze7x36a.png",
        "icon": "🚩",
        "shortcode": ":triangular_flag:",
        "unicodes": [
          "u1f6a9"
        ]
      },
      {
        "search": [
          "crossed",
          "flags"
        ],
        "name": "crossed_flags",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fm96grsh.png",
        "icon": "🎌",
        "shortcode": ":crossed_flags:",
        "unicodes": [
          "u1f38c"
        ]
      },
      {
        "search": [
          "black",
          "flag"
        ],
        "name": "black_flag",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2tke7rfs.png",
        "icon": "🏴",
        "shortcode": ":black_flag:",
        "unicodes": [
          "u1f3f4"
        ]
      },
      {
        "search": [
          "white",
          "flag"
        ],
        "name": "white_flag",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kksif2zq.png",
        "icon": "🏳",
        "shortcode": ":white_flag:",
        "unicodes": [
          "u1f3f3"
        ]
      },
      {
        "search": [
          "rainbow",
          "flag"
        ],
        "name": "rainbow_flag",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/39utsc8g.png",
        "icon": "🏳️‍🌈",
        "shortcode": ":rainbow_flag:",
        "unicodes": [
          "u1f3f3",
          "ufe0f",
          "u200d",
          "u1f308"
        ]
      },
      {
        "search": [
          "transgender",
          "flag"
        ],
        "name": "transgender_flag",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/259bftkg.png",
        "icon": "🏳️‍⚧️",
        "shortcode": ":transgender_flag:",
        "unicodes": [
          "u1f3f3",
          "ufe0f",
          "u200d",
          "u26a7",
          "ufe0f"
        ]
      },
      {
        "search": [
          "pirate",
          "flag"
        ],
        "name": "pirate_flag",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/eh7nyybq.png",
        "icon": "🏴‍☠️",
        "shortcode": ":pirate_flag:",
        "unicodes": [
          "u1f3f4",
          "u200d",
          "u2620",
          "ufe0f"
        ]
      },
      {
        "search": [
          "flag",
          "ascension",
          "island"
        ],
        "name": "flag_ascension_island",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3ird6df3.png",
        "icon": "🇦🇨",
        "shortcode": ":flag_ascension_island:",
        "unicodes": [
          "u1f1e6",
          "u1f1e8"
        ]
      },
      {
        "search": [
          "flag",
          "andorra"
        ],
        "name": "flag_andorra",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fh7upree.png",
        "icon": "🇦🇩",
        "shortcode": ":flag_andorra:",
        "unicodes": [
          "u1f1e6",
          "u1f1e9"
        ]
      },
      {
        "search": [
          "flag",
          "united",
          "arab",
          "emirates"
        ],
        "name": "flag_united_arab_emirates",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tvx78f6c.png",
        "icon": "🇦🇪",
        "shortcode": ":flag_united_arab_emirates:",
        "unicodes": [
          "u1f1e6",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "afghanistan"
        ],
        "name": "flag_afghanistan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2s2asqv6.png",
        "icon": "🇦🇫",
        "shortcode": ":flag_afghanistan:",
        "unicodes": [
          "u1f1e6",
          "u1f1eb"
        ]
      },
      {
        "search": [
          "flag",
          "antigua",
          "&",
          "barbuda"
        ],
        "name": "flag_antigua_barbuda",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zqise208.png",
        "icon": "🇦🇬",
        "shortcode": ":flag_antigua_barbuda:",
        "unicodes": [
          "u1f1e6",
          "u1f1ec"
        ]
      },
      {
        "search": [
          "flag",
          "anguilla"
        ],
        "name": "flag_anguilla",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j523hueg.png",
        "icon": "🇦🇮",
        "shortcode": ":flag_anguilla:",
        "unicodes": [
          "u1f1e6",
          "u1f1ee"
        ]
      },
      {
        "search": [
          "flag",
          "albania"
        ],
        "name": "flag_albania",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s164o674.png",
        "icon": "🇦🇱",
        "shortcode": ":flag_albania:",
        "unicodes": [
          "u1f1e6",
          "u1f1f1"
        ]
      },
      {
        "search": [
          "flag",
          "armenia"
        ],
        "name": "flag_armenia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vpffsmfn.png",
        "icon": "🇦🇲",
        "shortcode": ":flag_armenia:",
        "unicodes": [
          "u1f1e6",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "angola"
        ],
        "name": "flag_angola",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/5l8hycbj.png",
        "icon": "🇦🇴",
        "shortcode": ":flag_angola:",
        "unicodes": [
          "u1f1e6",
          "u1f1f4"
        ]
      },
      {
        "search": [
          "flag",
          "antarctica"
        ],
        "name": "flag_antarctica",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bi0dngbz.png",
        "icon": "🇦🇶",
        "shortcode": ":flag_antarctica:",
        "unicodes": [
          "u1f1e6",
          "u1f1f6"
        ]
      },
      {
        "search": [
          "flag",
          "argentina"
        ],
        "name": "flag_argentina",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/n6xamokd.png",
        "icon": "🇦🇷",
        "shortcode": ":flag_argentina:",
        "unicodes": [
          "u1f1e6",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "american",
          "samoa"
        ],
        "name": "flag_american_samoa",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/z8o7gsxr.png",
        "icon": "🇦🇸",
        "shortcode": ":flag_american_samoa:",
        "unicodes": [
          "u1f1e6",
          "u1f1f8"
        ]
      },
      {
        "search": [
          "flag",
          "austria"
        ],
        "name": "flag_austria",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zmz437ar.png",
        "icon": "🇦🇹",
        "shortcode": ":flag_austria:",
        "unicodes": [
          "u1f1e6",
          "u1f1f9"
        ]
      },
      {
        "search": [
          "flag",
          "australia"
        ],
        "name": "flag_australia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2anq1kbg.png",
        "icon": "🇦🇺",
        "shortcode": ":flag_australia:",
        "unicodes": [
          "u1f1e6",
          "u1f1fa"
        ]
      },
      {
        "search": [
          "flag",
          "aruba"
        ],
        "name": "flag_aruba",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lwmig5x4.png",
        "icon": "🇦🇼",
        "shortcode": ":flag_aruba:",
        "unicodes": [
          "u1f1e6",
          "u1f1fc"
        ]
      },
      {
        "search": [
          "flag",
          "åland",
          "islands"
        ],
        "name": "flag_åland_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0t0gx4p2.png",
        "icon": "🇦🇽",
        "shortcode": ":flag_åland_islands:",
        "unicodes": [
          "u1f1e6",
          "u1f1fd"
        ]
      },
      {
        "search": [
          "flag",
          "azerbaijan"
        ],
        "name": "flag_azerbaijan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mvrwkzk0.png",
        "icon": "🇦🇿",
        "shortcode": ":flag_azerbaijan:",
        "unicodes": [
          "u1f1e6",
          "u1f1ff"
        ]
      },
      {
        "search": [
          "flag",
          "bosnia",
          "&",
          "herzegovina"
        ],
        "name": "flag_bosnia_herzegovina",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bzcmb1bf.png",
        "icon": "🇧🇦",
        "shortcode": ":flag_bosnia_herzegovina:",
        "unicodes": [
          "u1f1e7",
          "u1f1e6"
        ]
      },
      {
        "search": [
          "flag",
          "barbados"
        ],
        "name": "flag_barbados",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/43ly5ck5.png",
        "icon": "🇧🇧",
        "shortcode": ":flag_barbados:",
        "unicodes": [
          "u1f1e7",
          "u1f1e7"
        ]
      },
      {
        "search": [
          "flag",
          "bangladesh"
        ],
        "name": "flag_bangladesh",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zgmx8j45.png",
        "icon": "🇧🇩",
        "shortcode": ":flag_bangladesh:",
        "unicodes": [
          "u1f1e7",
          "u1f1e9"
        ]
      },
      {
        "search": [
          "flag",
          "belgium"
        ],
        "name": "flag_belgium",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yymsedxa.png",
        "icon": "🇧🇪",
        "shortcode": ":flag_belgium:",
        "unicodes": [
          "u1f1e7",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "burkina",
          "faso"
        ],
        "name": "flag_burkina_faso",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ayodkg5r.png",
        "icon": "🇧🇫",
        "shortcode": ":flag_burkina_faso:",
        "unicodes": [
          "u1f1e7",
          "u1f1eb"
        ]
      },
      {
        "search": [
          "flag",
          "bulgaria"
        ],
        "name": "flag_bulgaria",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ghtv61cx.png",
        "icon": "🇧🇬",
        "shortcode": ":flag_bulgaria:",
        "unicodes": [
          "u1f1e7",
          "u1f1ec"
        ]
      },
      {
        "search": [
          "flag",
          "bahrain"
        ],
        "name": "flag_bahrain",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lsk2qj4g.png",
        "icon": "🇧🇭",
        "shortcode": ":flag_bahrain:",
        "unicodes": [
          "u1f1e7",
          "u1f1ed"
        ]
      },
      {
        "search": [
          "flag",
          "burundi"
        ],
        "name": "flag_burundi",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ctkzum3p.png",
        "icon": "🇧🇮",
        "shortcode": ":flag_burundi:",
        "unicodes": [
          "u1f1e7",
          "u1f1ee"
        ]
      },
      {
        "search": [
          "flag",
          "benin"
        ],
        "name": "flag_benin",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3d3yli4v.png",
        "icon": "🇧🇯",
        "shortcode": ":flag_benin:",
        "unicodes": [
          "u1f1e7",
          "u1f1ef"
        ]
      },
      {
        "search": [
          "flag",
          "st.",
          "barthélemy"
        ],
        "name": "flag_st._barthélemy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yk3co5ss.png",
        "icon": "🇧🇱",
        "shortcode": ":flag_st._barthélemy:",
        "unicodes": [
          "u1f1e7",
          "u1f1f1"
        ]
      },
      {
        "search": [
          "flag",
          "bermuda"
        ],
        "name": "flag_bermuda",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sgw1cbb0.png",
        "icon": "🇧🇲",
        "shortcode": ":flag_bermuda:",
        "unicodes": [
          "u1f1e7",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "brunei"
        ],
        "name": "flag_brunei",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/8638hfe6.png",
        "icon": "🇧🇳",
        "shortcode": ":flag_brunei:",
        "unicodes": [
          "u1f1e7",
          "u1f1f3"
        ]
      },
      {
        "search": [
          "flag",
          "bolivia"
        ],
        "name": "flag_bolivia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nwhanpoz.png",
        "icon": "🇧🇴",
        "shortcode": ":flag_bolivia:",
        "unicodes": [
          "u1f1e7",
          "u1f1f4"
        ]
      },
      {
        "search": [
          "flag",
          "caribbean",
          "netherlands"
        ],
        "name": "flag_caribbean_netherlands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/l9a81h7h.png",
        "icon": "🇧🇶",
        "shortcode": ":flag_caribbean_netherlands:",
        "unicodes": [
          "u1f1e7",
          "u1f1f6"
        ]
      },
      {
        "search": [
          "flag",
          "brazil"
        ],
        "name": "flag_brazil",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6rngd3rj.png",
        "icon": "🇧🇷",
        "shortcode": ":flag_brazil:",
        "unicodes": [
          "u1f1e7",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "bahamas"
        ],
        "name": "flag_bahamas",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ms43l7kv.png",
        "icon": "🇧🇸",
        "shortcode": ":flag_bahamas:",
        "unicodes": [
          "u1f1e7",
          "u1f1f8"
        ]
      },
      {
        "search": [
          "flag",
          "bhutan"
        ],
        "name": "flag_bhutan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7l57sibm.png",
        "icon": "🇧🇹",
        "shortcode": ":flag_bhutan:",
        "unicodes": [
          "u1f1e7",
          "u1f1f9"
        ]
      },
      {
        "search": [
          "flag",
          "bouvet",
          "island"
        ],
        "name": "flag_bouvet_island",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b8zmctyj.png",
        "icon": "🇧🇻",
        "shortcode": ":flag_bouvet_island:",
        "unicodes": [
          "u1f1e7",
          "u1f1fb"
        ]
      },
      {
        "search": [
          "flag",
          "botswana"
        ],
        "name": "flag_botswana",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hrmc5kmv.png",
        "icon": "🇧🇼",
        "shortcode": ":flag_botswana:",
        "unicodes": [
          "u1f1e7",
          "u1f1fc"
        ]
      },
      {
        "search": [
          "flag",
          "belarus"
        ],
        "name": "flag_belarus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6ayszixu.png",
        "icon": "🇧🇾",
        "shortcode": ":flag_belarus:",
        "unicodes": [
          "u1f1e7",
          "u1f1fe"
        ]
      },
      {
        "search": [
          "flag",
          "belize"
        ],
        "name": "flag_belize",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i6d6o5vy.png",
        "icon": "🇧🇿",
        "shortcode": ":flag_belize:",
        "unicodes": [
          "u1f1e7",
          "u1f1ff"
        ]
      },
      {
        "search": [
          "canada"
        ],
        "name": "flag_canada",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wu2p4jr5.png",
        "icon": "🇨🇦",
        "shortcode": ":flag_canada:",
        "unicodes": [
          "u1f1e8",
          "u1f1e6"
        ]
      },
      {
        "search": [
          "flag",
          "cocos",
          "keeling",
          "islands"
        ],
        "name": "flag_cocos_keeling_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uinmvj6w.png",
        "icon": "🇨🇨",
        "shortcode": ":flag_cocos_keeling_islands:",
        "unicodes": [
          "u1f1e8",
          "u1f1e8"
        ]
      },
      {
        "search": [
          "flag",
          "congo",
          "_",
          "kinshasa"
        ],
        "name": "flag_congo_kinshasa",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/40ucr8do.png",
        "icon": "🇨🇩",
        "shortcode": ":flag_congo_kinshasa:",
        "unicodes": [
          "u1f1e8",
          "u1f1e9"
        ]
      },
      {
        "search": [
          "flag",
          "central",
          "african",
          "republic"
        ],
        "name": "flag_central_african_republic",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fm5a1ehl.png",
        "icon": "🇨🇫",
        "shortcode": ":flag_central_african_republic:",
        "unicodes": [
          "u1f1e8",
          "u1f1eb"
        ]
      },
      {
        "search": [
          "flag",
          "congo",
          "_",
          "brazzaville"
        ],
        "name": "flag_congo___brazzaville",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6q087wq3.png",
        "icon": "🇨🇬",
        "shortcode": ":flag_congo_brazzaville:",
        "unicodes": [
          "u1f1e8",
          "u1f1ec"
        ]
      },
      {
        "search": [
          "flag",
          "switzerland"
        ],
        "name": "flag_switzerland",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bk3p1kve.png",
        "icon": "🇨🇭",
        "shortcode": ":flag_switzerland:",
        "unicodes": [
          "u1f1e8",
          "u1f1ed"
        ]
      },
      {
        "search": [
          "flag",
          "côte",
          "d’ivoire"
        ],
        "name": "flag_côte_divoire",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bty9npo7.png",
        "icon": "🇨🇮",
        "shortcode": ":flag_côte_divoire:",
        "unicodes": [
          "u1f1e8",
          "u1f1ee"
        ]
      },
      {
        "search": [
          "flag",
          "cook",
          "islands"
        ],
        "name": "flag_cook_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vgnfzy2w.png",
        "icon": "🇨🇰",
        "shortcode": ":flag_cook_islands:",
        "unicodes": [
          "u1f1e8",
          "u1f1f0"
        ]
      },
      {
        "search": [
          "flag",
          "chile"
        ],
        "name": "flag_chile",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6ax0me0u.png",
        "icon": "🇨🇱",
        "shortcode": ":flag_chile:",
        "unicodes": [
          "u1f1e8",
          "u1f1f1"
        ]
      },
      {
        "search": [
          "flag",
          "cameroon"
        ],
        "name": "flag_cameroon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/az23eys3.png",
        "icon": "🇨🇲",
        "shortcode": ":flag_cameroon:",
        "unicodes": [
          "u1f1e8",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "china"
        ],
        "name": "flag_china",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/03qbxiuo.png",
        "icon": "🇨🇳",
        "shortcode": ":flag_china:",
        "unicodes": [
          "u1f1e8",
          "u1f1f3"
        ]
      },
      {
        "search": [
          "flag",
          "colombia"
        ],
        "name": "flag_colombia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g5iqveut.png",
        "icon": "🇨🇴",
        "shortcode": ":flag_colombia:",
        "unicodes": [
          "u1f1e8",
          "u1f1f4"
        ]
      },
      {
        "search": [
          "flag",
          "clipperton",
          "island"
        ],
        "name": "flag_clipperton_island",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jx6uc4nm.png",
        "icon": "🇨🇵",
        "shortcode": ":flag_clipperton_island:",
        "unicodes": [
          "u1f1e8",
          "u1f1f5"
        ]
      },
      {
        "search": [
          "flag",
          "costa",
          "rica"
        ],
        "name": "flag_costa_rica",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zkn5yeko.png",
        "icon": "🇨🇷",
        "shortcode": ":flag_costa_rica:",
        "unicodes": [
          "u1f1e8",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "cuba"
        ],
        "name": "flag_cuba",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kp6b0qp2.png",
        "icon": "🇨🇺",
        "shortcode": ":flag_cuba:",
        "unicodes": [
          "u1f1e8",
          "u1f1fa"
        ]
      },
      {
        "search": [
          "flag",
          "cape",
          "verde"
        ],
        "name": "flag_cape_verde",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/muwo0zor.png",
        "icon": "🇨🇻",
        "shortcode": ":flag_cape_verde:",
        "unicodes": [
          "u1f1e8",
          "u1f1fb"
        ]
      },
      {
        "search": [
          "flag",
          "curaçao"
        ],
        "name": "flag_curaçao",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sxxdm0t8.png",
        "icon": "🇨🇼",
        "shortcode": ":flag_curaçao:",
        "unicodes": [
          "u1f1e8",
          "u1f1fc"
        ]
      },
      {
        "search": [
          "flag",
          "christmas",
          "island"
        ],
        "name": "flag_christmas_island",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0vshyavu.png",
        "icon": "🇨🇽",
        "shortcode": ":flag_christmas_island:",
        "unicodes": [
          "u1f1e8",
          "u1f1fd"
        ]
      },
      {
        "search": [
          "flag",
          "cyprus"
        ],
        "name": "flag_cyprus",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/q9zl2ehd.png",
        "icon": "🇨🇾",
        "shortcode": ":flag_cyprus:",
        "unicodes": [
          "u1f1e8",
          "u1f1fe"
        ]
      },
      {
        "search": [
          "flag",
          "czechia"
        ],
        "name": "flag_czechia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3vt4w6b7.png",
        "icon": "🇨🇿",
        "shortcode": ":flag_czechia:",
        "unicodes": [
          "u1f1e8",
          "u1f1ff"
        ]
      },
      {
        "search": [
          "flag",
          "germany"
        ],
        "name": "flag_germany",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4t0texnu.png",
        "icon": "🇩🇪",
        "shortcode": ":flag_germany:",
        "unicodes": [
          "u1f1e9",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "diego",
          "garcia"
        ],
        "name": "flag_diego_garcia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/o9ga9lna.png",
        "icon": "🇩🇬",
        "shortcode": ":flag_diego_garcia:",
        "unicodes": [
          "u1f1e9",
          "u1f1ec"
        ]
      },
      {
        "search": [
          "flag",
          "djibouti"
        ],
        "name": "flag_djibouti",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4klabnon.png",
        "icon": "🇩🇯",
        "shortcode": ":flag_djibouti:",
        "unicodes": [
          "u1f1e9",
          "u1f1ef"
        ]
      },
      {
        "search": [
          "flag",
          "denmark"
        ],
        "name": "flag_denmark",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iny70jeq.png",
        "icon": "🇩🇰",
        "shortcode": ":flag_denmark:",
        "unicodes": [
          "u1f1e9",
          "u1f1f0"
        ]
      },
      {
        "search": [
          "flag",
          "dominica"
        ],
        "name": "flag_dominica",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/np82e3q4.png",
        "icon": "🇩🇲",
        "shortcode": ":flag_dominica:",
        "unicodes": [
          "u1f1e9",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "dominican",
          "republic"
        ],
        "name": "flag_dominican_republic",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/uix3ih26.png",
        "icon": "🇩🇴",
        "shortcode": ":flag_dominican_republic:",
        "unicodes": [
          "u1f1e9",
          "u1f1f4"
        ]
      },
      {
        "search": [
          "flag",
          "algeria"
        ],
        "name": "flag_algeria",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0nghdp92.png",
        "icon": "🇩🇿",
        "shortcode": ":flag_algeria:",
        "unicodes": [
          "u1f1e9",
          "u1f1ff"
        ]
      },
      {
        "search": [
          "flag",
          "ceuta",
          "&",
          "melilla"
        ],
        "name": "flag_ceuta_melilla",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dih08lco.png",
        "icon": "🇪🇦",
        "shortcode": ":flag_ceuta_melilla:",
        "unicodes": [
          "u1f1ea",
          "u1f1e6"
        ]
      },
      {
        "search": [
          "flag",
          "ecuador"
        ],
        "name": "flag_ecuador",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e1ztfkyv.png",
        "icon": "🇪🇨",
        "shortcode": ":flag_ecuador:",
        "unicodes": [
          "u1f1ea",
          "u1f1e8"
        ]
      },
      {
        "search": [
          "flag",
          "estonia"
        ],
        "name": "flag_estonia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qcykv7cm.png",
        "icon": "🇪🇪",
        "shortcode": ":flag_estonia:",
        "unicodes": [
          "u1f1ea",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "egypt"
        ],
        "name": "flag_egypt",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ermi12z1.png",
        "icon": "🇪🇬",
        "shortcode": ":flag_egypt:",
        "unicodes": [
          "u1f1ea",
          "u1f1ec"
        ]
      },
      {
        "search": [
          "flag",
          "western",
          "sahara"
        ],
        "name": "flag_western_sahara",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0lu4oqbp.png",
        "icon": "🇪🇭",
        "shortcode": ":flag_western_sahara:",
        "unicodes": [
          "u1f1ea",
          "u1f1ed"
        ]
      },
      {
        "search": [
          "flag",
          "eritrea"
        ],
        "name": "flag_eritrea",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/629npc8f.png",
        "icon": "🇪🇷",
        "shortcode": ":flag_eritrea:",
        "unicodes": [
          "u1f1ea",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "spain"
        ],
        "name": "flag_spain",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6jcj0kgs.png",
        "icon": "🇪🇸",
        "shortcode": ":flag_spain:",
        "unicodes": [
          "u1f1ea",
          "u1f1f8"
        ]
      },
      {
        "search": [
          "flag",
          "ethiopia"
        ],
        "name": "flag_ethiopia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e4ahgjl2.png",
        "icon": "🇪🇹",
        "shortcode": ":flag_ethiopia:",
        "unicodes": [
          "u1f1ea",
          "u1f1f9"
        ]
      },
      {
        "search": [
          "flag",
          "european",
          "union"
        ],
        "name": "flag_european_union",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0zb6lcm7.png",
        "icon": "🇪🇺",
        "shortcode": ":flag_european_union:",
        "unicodes": [
          "u1f1ea",
          "u1f1fa"
        ]
      },
      {
        "search": [
          "flag",
          "finland"
        ],
        "name": "flag_finland",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1k0shrkq.png",
        "icon": "🇫🇮",
        "shortcode": ":flag_finland:",
        "unicodes": [
          "u1f1eb",
          "u1f1ee"
        ]
      },
      {
        "search": [
          "flag",
          "fiji"
        ],
        "name": "flag_fiji",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dfhlzskc.png",
        "icon": "🇫🇯",
        "shortcode": ":flag_fiji:",
        "unicodes": [
          "u1f1eb",
          "u1f1ef"
        ]
      },
      {
        "search": [
          "flag",
          "falkland",
          "islands"
        ],
        "name": "flag_falkland_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/986m6fv2.png",
        "icon": "🇫🇰",
        "shortcode": ":flag_falkland_islands:",
        "unicodes": [
          "u1f1eb",
          "u1f1f0"
        ]
      },
      {
        "search": [
          "flag",
          "micronesia"
        ],
        "name": "flag_micronesia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1eihuti0.png",
        "icon": "🇫🇲",
        "shortcode": ":flag_micronesia:",
        "unicodes": [
          "u1f1eb",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "faroe",
          "islands"
        ],
        "name": "flag_faroe_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fiba4ril.png",
        "icon": "🇫🇴",
        "shortcode": ":flag_faroe_islands:",
        "unicodes": [
          "u1f1eb",
          "u1f1f4"
        ]
      },
      {
        "search": [
          "flag",
          "france"
        ],
        "name": "flag_france",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qspci6la.png",
        "icon": "🇫🇷",
        "shortcode": ":flag_france:",
        "unicodes": [
          "u1f1eb",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "gabon"
        ],
        "name": "flag_gabon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jfucngh3.png",
        "icon": "🇬🇦",
        "shortcode": ":flag_gabon:",
        "unicodes": [
          "u1f1ec",
          "u1f1e6"
        ]
      },
      {
        "search": [
          "flag",
          "united",
          "kingdom"
        ],
        "name": "flag_united_kingdom",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0guqh967.png",
        "icon": "🇬🇧",
        "shortcode": ":flag_united_kingdom:",
        "unicodes": [
          "u1f1ec",
          "u1f1e7"
        ]
      },
      {
        "search": [
          "flag",
          "grenada"
        ],
        "name": "flag_grenada",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/c7zdpk44.png",
        "icon": "🇬🇩",
        "shortcode": ":flag_grenada:",
        "unicodes": [
          "u1f1ec",
          "u1f1e9"
        ]
      },
      {
        "search": [
          "flag",
          "georgia"
        ],
        "name": "flag_georgia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ur7mcrg9.png",
        "icon": "🇬🇪",
        "shortcode": ":flag_georgia:",
        "unicodes": [
          "u1f1ec",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "french",
          "guiana"
        ],
        "name": "flag_french_guiana",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zl924p0p.png",
        "icon": "🇬🇫",
        "shortcode": ":flag_french_guiana:",
        "unicodes": [
          "u1f1ec",
          "u1f1eb"
        ]
      },
      {
        "search": [
          "flag",
          "guernsey"
        ],
        "name": "flag_guernsey",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/02erjy51.png",
        "icon": "🇬🇬",
        "shortcode": ":flag_guernsey:",
        "unicodes": [
          "u1f1ec",
          "u1f1ec"
        ]
      },
      {
        "search": [
          "flag",
          "ghana"
        ],
        "name": "flag_ghana",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/o0tj011p.png",
        "icon": "🇬🇭",
        "shortcode": ":flag_ghana:",
        "unicodes": [
          "u1f1ec",
          "u1f1ed"
        ]
      },
      {
        "search": [
          "flag",
          "gibraltar"
        ],
        "name": "flag_gibraltar",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sxz7kifl.png",
        "icon": "🇬🇮",
        "shortcode": ":flag_gibraltar:",
        "unicodes": [
          "u1f1ec",
          "u1f1ee"
        ]
      },
      {
        "search": [
          "flag",
          "greenland"
        ],
        "name": "flag_greenland",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0lwqi40y.png",
        "icon": "🇬🇱",
        "shortcode": ":flag_greenland:",
        "unicodes": [
          "u1f1ec",
          "u1f1f1"
        ]
      },
      {
        "search": [
          "flag",
          "gambia"
        ],
        "name": "flag_gambia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/81h7bqkv.png",
        "icon": "🇬🇲",
        "shortcode": ":flag_gambia:",
        "unicodes": [
          "u1f1ec",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "guinea"
        ],
        "name": "flag_guinea",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ltbguoqp.png",
        "icon": "🇬🇳",
        "shortcode": ":flag_guinea:",
        "unicodes": [
          "u1f1ec",
          "u1f1f3"
        ]
      },
      {
        "search": [
          "flag",
          "guadeloupe"
        ],
        "name": "flag_guadeloupe",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/g1igxh2z.png",
        "icon": "🇬🇵",
        "shortcode": ":flag_guadeloupe:",
        "unicodes": [
          "u1f1ec",
          "u1f1f5"
        ]
      },
      {
        "search": [
          "flag",
          "equatorial",
          "guinea"
        ],
        "name": "flag_equatorial_guinea",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/m0v6nafy.png",
        "icon": "🇬🇶",
        "shortcode": ":flag_equatorial_guinea:",
        "unicodes": [
          "u1f1ec",
          "u1f1f6"
        ]
      },
      {
        "search": [
          "flag",
          "greece"
        ],
        "name": "flag_greece",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jury34f1.png",
        "icon": "🇬🇷",
        "shortcode": ":flag_greece:",
        "unicodes": [
          "u1f1ec",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "south",
          "georgia",
          "&",
          "south",
          "sandwich",
          "islands"
        ],
        "name": "flag_south_georgia_south_sandwich_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/q4rlh5z3.png",
        "icon": "🇬🇸",
        "shortcode": ":flag_south_georgia_south_sandwich_islands:",
        "unicodes": [
          "u1f1ec",
          "u1f1f8"
        ]
      },
      {
        "search": [
          "flag",
          "guatemala"
        ],
        "name": "flag_guatemala",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f0jkeg5i.png",
        "icon": "🇬🇹",
        "shortcode": ":flag_guatemala:",
        "unicodes": [
          "u1f1ec",
          "u1f1f9"
        ]
      },
      {
        "search": [
          "flag",
          "guam"
        ],
        "name": "flag_guam",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/300r1vhm.png",
        "icon": "🇬🇺",
        "shortcode": ":flag_guam:",
        "unicodes": [
          "u1f1ec",
          "u1f1fa"
        ]
      },
      {
        "search": [
          "flag",
          "guinea_bissau"
        ],
        "name": "flag_guinea_bissau",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/t4ka1wb2.png",
        "icon": "🇬🇼",
        "shortcode": ":flag_guinea_bissau:",
        "unicodes": [
          "u1f1ec",
          "u1f1fc"
        ]
      },
      {
        "search": [
          "flag",
          "guyana"
        ],
        "name": "flag_guyana",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/349q9nqv.png",
        "icon": "🇬🇾",
        "shortcode": ":flag_guyana:",
        "unicodes": [
          "u1f1ec",
          "u1f1fe"
        ]
      },
      {
        "search": [
          "flag",
          "hong",
          "kong",
          "sar",
          "china"
        ],
        "name": "flag_hong_kong_sar_china",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xho0efb7.png",
        "icon": "🇭🇰",
        "shortcode": ":flag_hong_kong_sar_china:",
        "unicodes": [
          "u1f1ed",
          "u1f1f0"
        ]
      },
      {
        "search": [
          "flag",
          "heard",
          "&",
          "mcdonald",
          "islands"
        ],
        "name": "flag_heard_mcdonald_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/29rpb0tz.png",
        "icon": "🇭🇲",
        "shortcode": ":flag_heard_mcdonald_islands:",
        "unicodes": [
          "u1f1ed",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "honduras"
        ],
        "name": "flag_honduras",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/keju78fy.png",
        "icon": "🇭🇳",
        "shortcode": ":flag_honduras:",
        "unicodes": [
          "u1f1ed",
          "u1f1f3"
        ]
      },
      {
        "search": [
          "flag",
          "croatia"
        ],
        "name": "flag_croatia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jr4b2o4p.png",
        "icon": "🇭🇷",
        "shortcode": ":flag_croatia:",
        "unicodes": [
          "u1f1ed",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "haiti"
        ],
        "name": "flag_haiti",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ka6n8plq.png",
        "icon": "🇭🇹",
        "shortcode": ":flag_haiti:",
        "unicodes": [
          "u1f1ed",
          "u1f1f9"
        ]
      },
      {
        "search": [
          "flag",
          "hungary"
        ],
        "name": "flag_hungary",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/utj0dua0.png",
        "icon": "🇭🇺",
        "shortcode": ":flag_hungary:",
        "unicodes": [
          "u1f1ed",
          "u1f1fa"
        ]
      },
      {
        "search": [
          "flag",
          "canary",
          "islands"
        ],
        "name": "flag_canary_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e3fu6cer.png",
        "icon": "🇮🇨",
        "shortcode": ":flag_canary_islands:",
        "unicodes": [
          "u1f1ee",
          "u1f1e8"
        ]
      },
      {
        "search": [
          "flag",
          "indonesia"
        ],
        "name": "flag_indonesia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zfc71wfg.png",
        "icon": "🇮🇩",
        "shortcode": ":flag_indonesia:",
        "unicodes": [
          "u1f1ee",
          "u1f1e9"
        ]
      },
      {
        "search": [
          "flag",
          "ireland"
        ],
        "name": "flag_ireland",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ubdprbpi.png",
        "icon": "🇮🇪",
        "shortcode": ":flag_ireland:",
        "unicodes": [
          "u1f1ee",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "israel"
        ],
        "name": "flag_israel",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/fbbebpzn.png",
        "icon": "🇮🇱",
        "shortcode": ":flag_israel:",
        "unicodes": [
          "u1f1ee",
          "u1f1f1"
        ]
      },
      {
        "search": [
          "flag",
          "isle",
          "of",
          "man"
        ],
        "name": "flag_isle_of_man",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/d64i0syb.png",
        "icon": "🇮🇲",
        "shortcode": ":flag_isle_of_man:",
        "unicodes": [
          "u1f1ee",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "india"
        ],
        "name": "flag_india",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/t57bukgf.png",
        "icon": "🇮🇳",
        "shortcode": ":flag_india:",
        "unicodes": [
          "u1f1ee",
          "u1f1f3"
        ]
      },
      {
        "search": [
          "flag",
          "british",
          "indian",
          "ocean",
          "territory"
        ],
        "name": "flag_british_indian_ocean_territory",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h977eo0e.png",
        "icon": "🇮🇴",
        "shortcode": ":flag_british_indian_ocean_territory:",
        "unicodes": [
          "u1f1ee",
          "u1f1f4"
        ]
      },
      {
        "search": [
          "flag",
          "iraq"
        ],
        "name": "flag_iraq",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3bxffgzc.png",
        "icon": "🇮🇶",
        "shortcode": ":flag_iraq:",
        "unicodes": [
          "u1f1ee",
          "u1f1f6"
        ]
      },
      {
        "search": [
          "flag",
          "iran"
        ],
        "name": "flag_iran",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3ko1ro7c.png",
        "icon": "🇮🇷",
        "shortcode": ":flag_iran:",
        "unicodes": [
          "u1f1ee",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "iceland"
        ],
        "name": "flag_iceland",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pokq0t9p.png",
        "icon": "🇮🇸",
        "shortcode": ":flag_iceland:",
        "unicodes": [
          "u1f1ee",
          "u1f1f8"
        ]
      },
      {
        "search": [
          "flag",
          "italy"
        ],
        "name": "flag_italy",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/q39rdk5o.png",
        "icon": "🇮🇹",
        "shortcode": ":flag_italy:",
        "unicodes": [
          "u1f1ee",
          "u1f1f9"
        ]
      },
      {
        "search": [
          "flag",
          "jersey"
        ],
        "name": "flag_jersey",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/skx5r8af.png",
        "icon": "🇯🇪",
        "shortcode": ":flag_jersey:",
        "unicodes": [
          "u1f1ef",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "jamaica"
        ],
        "name": "flag_jamaica",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4za9xi0u.png",
        "icon": "🇯🇲",
        "shortcode": ":flag_jamaica:",
        "unicodes": [
          "u1f1ef",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "jordan"
        ],
        "name": "flag_jordan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/onb6v2fk.png",
        "icon": "🇯🇴",
        "shortcode": ":flag_jordan:",
        "unicodes": [
          "u1f1ef",
          "u1f1f4"
        ]
      },
      {
        "search": [
          "flag",
          "japan"
        ],
        "name": "flag_japan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e0kvy2gb.png",
        "icon": "🇯🇵",
        "shortcode": ":flag_japan:",
        "unicodes": [
          "u1f1ef",
          "u1f1f5"
        ]
      },
      {
        "search": [
          "flag",
          "kenya"
        ],
        "name": "flag_kenya",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ctamen7m.png",
        "icon": "🇰🇪",
        "shortcode": ":flag_kenya:",
        "unicodes": [
          "u1f1f0",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "kyrgyzstan"
        ],
        "name": "flag_kyrgyzstan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jsb85alw.png",
        "icon": "🇰🇬",
        "shortcode": ":flag_kyrgyzstan:",
        "unicodes": [
          "u1f1f0",
          "u1f1ec"
        ]
      },
      {
        "search": [
          "flag",
          "cambodia"
        ],
        "name": "flag_cambodia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1z34h9k8.png",
        "icon": "🇰🇭",
        "shortcode": ":flag_cambodia:",
        "unicodes": [
          "u1f1f0",
          "u1f1ed"
        ]
      },
      {
        "search": [
          "flag",
          "kiribati"
        ],
        "name": "flag_kiribati",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/1402ry9p.png",
        "icon": "🇰🇮",
        "shortcode": ":flag_kiribati:",
        "unicodes": [
          "u1f1f0",
          "u1f1ee"
        ]
      },
      {
        "search": [
          "flag",
          "comoros"
        ],
        "name": "flag_comoros",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mbcdsedh.png",
        "icon": "🇰🇲",
        "shortcode": ":flag_comoros:",
        "unicodes": [
          "u1f1f0",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "st.",
          "kitts",
          "&",
          "nevis"
        ],
        "name": "flag_st._kitts_nevis",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dt4n2atr.png",
        "icon": "🇰🇳",
        "shortcode": ":flag_st._kitts_nevis:",
        "unicodes": [
          "u1f1f0",
          "u1f1f3"
        ]
      },
      {
        "search": [
          "flag",
          "north",
          "korea"
        ],
        "name": "flag_north_korea",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/db1a59vx.png",
        "icon": "🇰🇵",
        "shortcode": ":flag_north_korea:",
        "unicodes": [
          "u1f1f0",
          "u1f1f5"
        ]
      },
      {
        "search": [
          "flag",
          "south",
          "korea"
        ],
        "name": "flag_south_korea",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9xdvhunu.png",
        "icon": "🇰🇷",
        "shortcode": ":flag_south_korea:",
        "unicodes": [
          "u1f1f0",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "kuwait"
        ],
        "name": "flag_kuwait",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s3tylk2f.png",
        "icon": "🇰🇼",
        "shortcode": ":flag_kuwait:",
        "unicodes": [
          "u1f1f0",
          "u1f1fc"
        ]
      },
      {
        "search": [
          "flag",
          "cayman",
          "islands"
        ],
        "name": "flag_cayman_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/zaga5i1e.png",
        "icon": "🇰🇾",
        "shortcode": ":flag_cayman_islands:",
        "unicodes": [
          "u1f1f0",
          "u1f1fe"
        ]
      },
      {
        "search": [
          "flag",
          "kazakhstan"
        ],
        "name": "flag_kazakhstan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kwkylpur.png",
        "icon": "🇰🇿",
        "shortcode": ":flag_kazakhstan:",
        "unicodes": [
          "u1f1f0",
          "u1f1ff"
        ]
      },
      {
        "search": [
          "flag",
          "laos"
        ],
        "name": "flag_laos",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/01rbnnmq.png",
        "icon": "🇱🇦",
        "shortcode": ":flag_laos:",
        "unicodes": [
          "u1f1f1",
          "u1f1e6"
        ]
      },
      {
        "search": [
          "flag",
          "lebanon"
        ],
        "name": "flag_lebanon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rjn3dmlr.png",
        "icon": "🇱🇧",
        "shortcode": ":flag_lebanon:",
        "unicodes": [
          "u1f1f1",
          "u1f1e7"
        ]
      },
      {
        "search": [
          "flag",
          "st.",
          "lucia"
        ],
        "name": "flag_st._lucia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/y8vjd8g1.png",
        "icon": "🇱🇨",
        "shortcode": ":flag_st._lucia:",
        "unicodes": [
          "u1f1f1",
          "u1f1e8"
        ]
      },
      {
        "search": [
          "flag",
          "liechtenstein"
        ],
        "name": "flag_liechtenstein",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hy47icmx.png",
        "icon": "🇱🇮",
        "shortcode": ":flag_liechtenstein:",
        "unicodes": [
          "u1f1f1",
          "u1f1ee"
        ]
      },
      {
        "search": [
          "flag",
          "sri",
          "lanka"
        ],
        "name": "flag_sri_lanka",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7wvqk6cp.png",
        "icon": "🇱🇰",
        "shortcode": ":flag_sri_lanka:",
        "unicodes": [
          "u1f1f1",
          "u1f1f0"
        ]
      },
      {
        "search": [
          "flag",
          "liberia"
        ],
        "name": "flag_liberia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/21tdk10r.png",
        "icon": "🇱🇷",
        "shortcode": ":flag_liberia:",
        "unicodes": [
          "u1f1f1",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "lesotho"
        ],
        "name": "flag_lesotho",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/pkhzjr2p.png",
        "icon": "🇱🇸",
        "shortcode": ":flag_lesotho:",
        "unicodes": [
          "u1f1f1",
          "u1f1f8"
        ]
      },
      {
        "search": [
          "flag",
          "lithuania"
        ],
        "name": "flag_lithuania",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cky2i838.png",
        "icon": "🇱🇹",
        "shortcode": ":flag_lithuania:",
        "unicodes": [
          "u1f1f1",
          "u1f1f9"
        ]
      },
      {
        "search": [
          "flag",
          "luxembourg"
        ],
        "name": "flag_luxembourg",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/u05wcnhl.png",
        "icon": "🇱🇺",
        "shortcode": ":flag_luxembourg:",
        "unicodes": [
          "u1f1f1",
          "u1f1fa"
        ]
      },
      {
        "search": [
          "flag",
          "latvia"
        ],
        "name": "flag_latvia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/erha23er.png",
        "icon": "🇱🇻",
        "shortcode": ":flag_latvia:",
        "unicodes": [
          "u1f1f1",
          "u1f1fb"
        ]
      },
      {
        "search": [
          "flag",
          "libya"
        ],
        "name": "flag_libya",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ng33n91u.png",
        "icon": "🇱🇾",
        "shortcode": ":flag_libya:",
        "unicodes": [
          "u1f1f1",
          "u1f1fe"
        ]
      },
      {
        "search": [
          "flag",
          "morocco"
        ],
        "name": "flag_morocco",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/n7jwmvd5.png",
        "icon": "🇲🇦",
        "shortcode": ":flag_morocco:",
        "unicodes": [
          "u1f1f2",
          "u1f1e6"
        ]
      },
      {
        "search": [
          "flag",
          "monaco"
        ],
        "name": "flag_monaco",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xwbd5r1n.png",
        "icon": "🇲🇨",
        "shortcode": ":flag_monaco:",
        "unicodes": [
          "u1f1f2",
          "u1f1e8"
        ]
      },
      {
        "search": [
          "flag",
          "moldova"
        ],
        "name": "flag_moldova",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/md80rxb1.png",
        "icon": "🇲🇩",
        "shortcode": ":flag_moldova:",
        "unicodes": [
          "u1f1f2",
          "u1f1e9"
        ]
      },
      {
        "search": [
          "flag",
          "montenegro"
        ],
        "name": "flag_montenegro",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/l6ujgyww.png",
        "icon": "🇲🇪",
        "shortcode": ":flag_montenegro:",
        "unicodes": [
          "u1f1f2",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "st.",
          "martin"
        ],
        "name": "flag_st._martin",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/4j2nlo97.png",
        "icon": "🇲🇫",
        "shortcode": ":flag_st._martin:",
        "unicodes": [
          "u1f1f2",
          "u1f1eb"
        ]
      },
      {
        "search": [
          "flag",
          "madagascar"
        ],
        "name": "flag_madagascar",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wedjzlpf.png",
        "icon": "🇲🇬",
        "shortcode": ":flag_madagascar:",
        "unicodes": [
          "u1f1f2",
          "u1f1ec"
        ]
      },
      {
        "search": [
          "flag",
          "marshall",
          "islands"
        ],
        "name": "flag_marshall_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9wdl86j5.png",
        "icon": "🇲🇭",
        "shortcode": ":flag_marshall_islands:",
        "unicodes": [
          "u1f1f2",
          "u1f1ed"
        ]
      },
      {
        "search": [
          "flag",
          "north",
          "macedonia"
        ],
        "name": "flag_north_macedonia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3jls9ob3.png",
        "icon": "🇲🇰",
        "shortcode": ":flag_north_macedonia:",
        "unicodes": [
          "u1f1f2",
          "u1f1f0"
        ]
      },
      {
        "search": [
          "flag",
          "mali"
        ],
        "name": "flag_mali",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ms7bb8kt.png",
        "icon": "🇲🇱",
        "shortcode": ":flag_mali:",
        "unicodes": [
          "u1f1f2",
          "u1f1f1"
        ]
      },
      {
        "search": [
          "flag",
          "myanmar",
          "burma"
        ],
        "name": "flag_myanmar_burma",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2qszcvwx.png",
        "icon": "🇲🇲",
        "shortcode": ":flag_myanmar_burma:",
        "unicodes": [
          "u1f1f2",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "mongolia"
        ],
        "name": "flag_mongolia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xpg57ejz.png",
        "icon": "🇲🇳",
        "shortcode": ":flag_mongolia:",
        "unicodes": [
          "u1f1f2",
          "u1f1f3"
        ]
      },
      {
        "search": [
          "flag",
          "macao",
          "sar",
          "china"
        ],
        "name": "flag_macao_sar_china",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f0db6w2b.png",
        "icon": "🇲🇴",
        "shortcode": ":flag_macao_sar_china:",
        "unicodes": [
          "u1f1f2",
          "u1f1f4"
        ]
      },
      {
        "search": [
          "flag",
          "northern",
          "mariana",
          "islands"
        ],
        "name": "flag_northern_mariana_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/h9gxtjzx.png",
        "icon": "🇲🇵",
        "shortcode": ":flag_northern_mariana_islands:",
        "unicodes": [
          "u1f1f2",
          "u1f1f5"
        ]
      },
      {
        "search": [
          "flag",
          "martinique"
        ],
        "name": "flag_martinique",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/agel9th7.png",
        "icon": "🇲🇶",
        "shortcode": ":flag_martinique:",
        "unicodes": [
          "u1f1f2",
          "u1f1f6"
        ]
      },
      {
        "search": [
          "flag",
          "mauritania"
        ],
        "name": "flag_mauritania",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/57gp8kqo.png",
        "icon": "🇲🇷",
        "shortcode": ":flag_mauritania:",
        "unicodes": [
          "u1f1f2",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "montserrat"
        ],
        "name": "flag_montserrat",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tkrscs2g.png",
        "icon": "🇲🇸",
        "shortcode": ":flag_montserrat:",
        "unicodes": [
          "u1f1f2",
          "u1f1f8"
        ]
      },
      {
        "search": [
          "flag",
          "malta"
        ],
        "name": "flag_malta",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ngfulthl.png",
        "icon": "🇲🇹",
        "shortcode": ":flag_malta:",
        "unicodes": [
          "u1f1f2",
          "u1f1f9"
        ]
      },
      {
        "search": [
          "flag",
          "mauritius"
        ],
        "name": "flag_mauritius",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f2kq4fir.png",
        "icon": "🇲🇺",
        "shortcode": ":flag_mauritius:",
        "unicodes": [
          "u1f1f2",
          "u1f1fa"
        ]
      },
      {
        "search": [
          "flag",
          "maldives"
        ],
        "name": "flag_maldives",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/dpk7l5ij.png",
        "icon": "🇲🇻",
        "shortcode": ":flag_maldives:",
        "unicodes": [
          "u1f1f2",
          "u1f1fb"
        ]
      },
      {
        "search": [
          "flag",
          "malawi"
        ],
        "name": "flag_malawi",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a5mcafp4.png",
        "icon": "🇲🇼",
        "shortcode": ":flag_malawi:",
        "unicodes": [
          "u1f1f2",
          "u1f1fc"
        ]
      },
      {
        "search": [
          "flag",
          "mexico"
        ],
        "name": "flag_mexico",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cwagyg6p.png",
        "icon": "🇲🇽",
        "shortcode": ":flag_mexico:",
        "unicodes": [
          "u1f1f2",
          "u1f1fd"
        ]
      },
      {
        "search": [
          "flag",
          "malaysia"
        ],
        "name": "flag_malaysia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3re2wu9v.png",
        "icon": "🇲🇾",
        "shortcode": ":flag_malaysia:",
        "unicodes": [
          "u1f1f2",
          "u1f1fe"
        ]
      },
      {
        "search": [
          "flag",
          "mozambique"
        ],
        "name": "flag_mozambique",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xb2x9lvb.png",
        "icon": "🇲🇿",
        "shortcode": ":flag_mozambique:",
        "unicodes": [
          "u1f1f2",
          "u1f1ff"
        ]
      },
      {
        "search": [
          "flag",
          "namibia"
        ],
        "name": "flag_namibia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/skwc7ua4.png",
        "icon": "🇳🇦",
        "shortcode": ":flag_namibia:",
        "unicodes": [
          "u1f1f3",
          "u1f1e6"
        ]
      },
      {
        "search": [
          "flag",
          "new",
          "caledonia"
        ],
        "name": "flag_new_caledonia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/e1qy4bpc.png",
        "icon": "🇳🇨",
        "shortcode": ":flag_new_caledonia:",
        "unicodes": [
          "u1f1f3",
          "u1f1e8"
        ]
      },
      {
        "search": [
          "flag",
          "niger"
        ],
        "name": "flag_niger",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hw3xoj9x.png",
        "icon": "🇳🇪",
        "shortcode": ":flag_niger:",
        "unicodes": [
          "u1f1f3",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "norfolk",
          "island"
        ],
        "name": "flag_norfolk_island",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tl2v0nwz.png",
        "icon": "🇳🇫",
        "shortcode": ":flag_norfolk_island:",
        "unicodes": [
          "u1f1f3",
          "u1f1eb"
        ]
      },
      {
        "search": [
          "flag",
          "nigeria"
        ],
        "name": "flag_nigeria",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/18gxqe3l.png",
        "icon": "🇳🇬",
        "shortcode": ":flag_nigeria:",
        "unicodes": [
          "u1f1f3",
          "u1f1ec"
        ]
      },
      {
        "search": [
          "flag",
          "nicaragua"
        ],
        "name": "flag_nicaragua",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/thk2imkf.png",
        "icon": "🇳🇮",
        "shortcode": ":flag_nicaragua:",
        "unicodes": [
          "u1f1f3",
          "u1f1ee"
        ]
      },
      {
        "search": [
          "flag",
          "netherlands"
        ],
        "name": "flag_netherlands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/em2hbh42.png",
        "icon": "🇳🇱",
        "shortcode": ":flag_netherlands:",
        "unicodes": [
          "u1f1f3",
          "u1f1f1"
        ]
      },
      {
        "search": [
          "flag",
          "norway"
        ],
        "name": "flag_norway",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ra2tfb07.png",
        "icon": "🇳🇴",
        "shortcode": ":flag_norway:",
        "unicodes": [
          "u1f1f3",
          "u1f1f4"
        ]
      },
      {
        "search": [
          "flag",
          "nepal"
        ],
        "name": "flag_nepal",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xktkfb83.png",
        "icon": "🇳🇵",
        "shortcode": ":flag_nepal:",
        "unicodes": [
          "u1f1f3",
          "u1f1f5"
        ]
      },
      {
        "search": [
          "flag",
          "nauru"
        ],
        "name": "flag_nauru",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ecg0cxf6.png",
        "icon": "🇳🇷",
        "shortcode": ":flag_nauru:",
        "unicodes": [
          "u1f1f3",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "niue"
        ],
        "name": "flag_niue",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ey50l9c9.png",
        "icon": "🇳🇺",
        "shortcode": ":flag_niue:",
        "unicodes": [
          "u1f1f3",
          "u1f1fa"
        ]
      },
      {
        "search": [
          "flag",
          "new",
          "zealand"
        ],
        "name": "flag_new_zealand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/oyn4x42s.png",
        "icon": "🇳🇿",
        "shortcode": ":flag_new_zealand:",
        "unicodes": [
          "u1f1f3",
          "u1f1ff"
        ]
      },
      {
        "search": [
          "flag",
          "oman"
        ],
        "name": "flag_oman",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/l2xr0ryn.png",
        "icon": "🇴🇲",
        "shortcode": ":flag_oman:",
        "unicodes": [
          "u1f1f4",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "panama"
        ],
        "name": "flag_panama",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s1kavvsl.png",
        "icon": "🇵🇦",
        "shortcode": ":flag_panama:",
        "unicodes": [
          "u1f1f5",
          "u1f1e6"
        ]
      },
      {
        "search": [
          "flag",
          "peru"
        ],
        "name": "flag_peru",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i839e2xt.png",
        "icon": "🇵🇪",
        "shortcode": ":flag_peru:",
        "unicodes": [
          "u1f1f5",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "french",
          "polynesia"
        ],
        "name": "flag_french_polynesia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/veg2bptl.png",
        "icon": "🇵🇫",
        "shortcode": ":flag_french_polynesia:",
        "unicodes": [
          "u1f1f5",
          "u1f1eb"
        ]
      },
      {
        "search": [
          "flag",
          "papua",
          "new",
          "guinea"
        ],
        "name": "flag_papua_new_guinea",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/t02hgiaa.png",
        "icon": "🇵🇬",
        "shortcode": ":flag_papua_new_guinea:",
        "unicodes": [
          "u1f1f5",
          "u1f1ec"
        ]
      },
      {
        "search": [
          "flag",
          "philippines"
        ],
        "name": "flag_philippines",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3dmjlb3u.png",
        "icon": "🇵🇭",
        "shortcode": ":flag_philippines:",
        "unicodes": [
          "u1f1f5",
          "u1f1ed"
        ]
      },
      {
        "search": [
          "flag",
          "pakistan"
        ],
        "name": "flag_pakistan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/tq5jvn6m.png",
        "icon": "🇵🇰",
        "shortcode": ":flag_pakistan:",
        "unicodes": [
          "u1f1f5",
          "u1f1f0"
        ]
      },
      {
        "search": [
          "flag",
          "poland"
        ],
        "name": "flag_poland",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ubf6dudn.png",
        "icon": "🇵🇱",
        "shortcode": ":flag_poland:",
        "unicodes": [
          "u1f1f5",
          "u1f1f1"
        ]
      },
      {
        "search": [
          "flag",
          "st.",
          "pierre",
          "&",
          "miquelon"
        ],
        "name": "flag_st._pierre_miquelon",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2y40x38e.png",
        "icon": "🇵🇲",
        "shortcode": ":flag_st._pierre_miquelon:",
        "unicodes": [
          "u1f1f5",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "pitcairn",
          "islands"
        ],
        "name": "flag_pitcairn_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/w4ztudt9.png",
        "icon": "🇵🇳",
        "shortcode": ":flag_pitcairn_islands:",
        "unicodes": [
          "u1f1f5",
          "u1f1f3"
        ]
      },
      {
        "search": [
          "flag",
          "puerto",
          "rico"
        ],
        "name": "flag_puerto_rico",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/35fosijf.png",
        "icon": "🇵🇷",
        "shortcode": ":flag_puerto_rico:",
        "unicodes": [
          "u1f1f5",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "palestinian",
          "territories"
        ],
        "name": "flag_palestinian_territories",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2pm529kr.png",
        "icon": "🇵🇸",
        "shortcode": ":flag_palestinian_territories:",
        "unicodes": [
          "u1f1f5",
          "u1f1f8"
        ]
      },
      {
        "search": [
          "flag",
          "portugal"
        ],
        "name": "flag_portugal",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j9jragyk.png",
        "icon": "🇵🇹",
        "shortcode": ":flag_portugal:",
        "unicodes": [
          "u1f1f5",
          "u1f1f9"
        ]
      },
      {
        "search": [
          "flag",
          "palau"
        ],
        "name": "flag_palau",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/j8lhej0n.png",
        "icon": "🇵🇼",
        "shortcode": ":flag_palau:",
        "unicodes": [
          "u1f1f5",
          "u1f1fc"
        ]
      },
      {
        "search": [
          "flag",
          "paraguay"
        ],
        "name": "flag_paraguay",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jntrk5k2.png",
        "icon": "🇵🇾",
        "shortcode": ":flag_paraguay:",
        "unicodes": [
          "u1f1f5",
          "u1f1fe"
        ]
      },
      {
        "search": [
          "flag",
          "qatar"
        ],
        "name": "flag_qatar",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/a98ozn77.png",
        "icon": "🇶🇦",
        "shortcode": ":flag_qatar:",
        "unicodes": [
          "u1f1f6",
          "u1f1e6"
        ]
      },
      {
        "search": [
          "flag",
          "réunion"
        ],
        "name": "flag_réunion",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6ckbhgpf.png",
        "icon": "🇷🇪",
        "shortcode": ":flag_réunion:",
        "unicodes": [
          "u1f1f7",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "romania"
        ],
        "name": "flag_romania",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jl300ynb.png",
        "icon": "🇷🇴",
        "shortcode": ":flag_romania:",
        "unicodes": [
          "u1f1f7",
          "u1f1f4"
        ]
      },
      {
        "search": [
          "flag",
          "serbia"
        ],
        "name": "flag_serbia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/u109898x.png",
        "icon": "🇷🇸",
        "shortcode": ":flag_serbia:",
        "unicodes": [
          "u1f1f7",
          "u1f1f8"
        ]
      },
      {
        "search": [
          "flag",
          "russia"
        ],
        "name": "flag_russia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/lg0mmv2b.png",
        "icon": "🇷🇺",
        "shortcode": ":flag_russia:",
        "unicodes": [
          "u1f1f7",
          "u1f1fa"
        ]
      },
      {
        "search": [
          "flag",
          "rwanda"
        ],
        "name": "flag_rwanda",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mp9vm8yz.png",
        "icon": "🇷🇼",
        "shortcode": ":flag_rwanda:",
        "unicodes": [
          "u1f1f7",
          "u1f1fc"
        ]
      },
      {
        "search": [
          "flag",
          "saudi",
          "arabia"
        ],
        "name": "flag_saudi_arabia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/kneu78dw.png",
        "icon": "🇸🇦",
        "shortcode": ":flag_saudi_arabia:",
        "unicodes": [
          "u1f1f8",
          "u1f1e6"
        ]
      },
      {
        "search": [
          "flag",
          "solomon",
          "islands"
        ],
        "name": "flag_solomon_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/x0459rja.png",
        "icon": "🇸🇧",
        "shortcode": ":flag_solomon_islands:",
        "unicodes": [
          "u1f1f8",
          "u1f1e7"
        ]
      },
      {
        "search": [
          "flag",
          "seychelles"
        ],
        "name": "flag_seychelles",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/15ic5vjh.png",
        "icon": "🇸🇨",
        "shortcode": ":flag_seychelles:",
        "unicodes": [
          "u1f1f8",
          "u1f1e8"
        ]
      },
      {
        "search": [
          "flag",
          "sudan"
        ],
        "name": "flag_sudan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/324ke4nz.png",
        "icon": "🇸🇩",
        "shortcode": ":flag_sudan:",
        "unicodes": [
          "u1f1f8",
          "u1f1e9"
        ]
      },
      {
        "search": [
          "flag",
          "sweden"
        ],
        "name": "flag_sweden",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ryfyb7w8.png",
        "icon": "🇸🇪",
        "shortcode": ":flag_sweden:",
        "unicodes": [
          "u1f1f8",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "singapore"
        ],
        "name": "flag_singapore",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/12891ok3.png",
        "icon": "🇸🇬",
        "shortcode": ":flag_singapore:",
        "unicodes": [
          "u1f1f8",
          "u1f1ec"
        ]
      },
      {
        "search": [
          "flag",
          "st.",
          "helena"
        ],
        "name": "flag_st._helena",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vond56sg.png",
        "icon": "🇸🇭",
        "shortcode": ":flag_st._helena:",
        "unicodes": [
          "u1f1f8",
          "u1f1ed"
        ]
      },
      {
        "search": [
          "flag",
          "slovenia"
        ],
        "name": "flag_slovenia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cug6q22j.png",
        "icon": "🇸🇮",
        "shortcode": ":flag_slovenia:",
        "unicodes": [
          "u1f1f8",
          "u1f1ee"
        ]
      },
      {
        "search": [
          "flag",
          "svalbard",
          "&",
          "jan",
          "mayen"
        ],
        "name": "flag_svalbard_jan_mayen",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/n40h78mw.png",
        "icon": "🇸🇯",
        "shortcode": ":flag_svalbard_jan_mayen:",
        "unicodes": [
          "u1f1f8",
          "u1f1ef"
        ]
      },
      {
        "search": [
          "flag",
          "slovakia"
        ],
        "name": "flag_slovakia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2a72jaac.png",
        "icon": "🇸🇰",
        "shortcode": ":flag_slovakia:",
        "unicodes": [
          "u1f1f8",
          "u1f1f0"
        ]
      },
      {
        "search": [
          "flag",
          "sierra",
          "leone"
        ],
        "name": "flag_sierra_leone",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qbl6xg9p.png",
        "icon": "🇸🇱",
        "shortcode": ":flag_sierra_leone:",
        "unicodes": [
          "u1f1f8",
          "u1f1f1"
        ]
      },
      {
        "search": [
          "flag",
          "san",
          "marino"
        ],
        "name": "flag_san_marino",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3uebu63k.png",
        "icon": "🇸🇲",
        "shortcode": ":flag_san_marino:",
        "unicodes": [
          "u1f1f8",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "senegal"
        ],
        "name": "flag_senegal",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6rc304hb.png",
        "icon": "🇸🇳",
        "shortcode": ":flag_senegal:",
        "unicodes": [
          "u1f1f8",
          "u1f1f3"
        ]
      },
      {
        "search": [
          "flag",
          "somalia"
        ],
        "name": "flag_somalia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/45d8dled.png",
        "icon": "🇸🇴",
        "shortcode": ":flag_somalia:",
        "unicodes": [
          "u1f1f8",
          "u1f1f4"
        ]
      },
      {
        "search": [
          "flag",
          "suriname"
        ],
        "name": "flag_suriname",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/0fb70lvk.png",
        "icon": "🇸🇷",
        "shortcode": ":flag_suriname:",
        "unicodes": [
          "u1f1f8",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "south",
          "sudan"
        ],
        "name": "flag_south_sudan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2ay0urvx.png",
        "icon": "🇸🇸",
        "shortcode": ":flag_south_sudan:",
        "unicodes": [
          "u1f1f8",
          "u1f1f8"
        ]
      },
      {
        "search": [
          "flag",
          "são",
          "tomé",
          "&",
          "príncipe"
        ],
        "name": "flag_são_tomé_príncipe",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/nnl8xu79.png",
        "icon": "🇸🇹",
        "shortcode": ":flag_são_tomé_príncipe:",
        "unicodes": [
          "u1f1f8",
          "u1f1f9"
        ]
      },
      {
        "search": [
          "flag",
          "el",
          "salvador"
        ],
        "name": "flag_el_salvador",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/ksabtquv.png",
        "icon": "🇸🇻",
        "shortcode": ":flag_el_salvador:",
        "unicodes": [
          "u1f1f8",
          "u1f1fb"
        ]
      },
      {
        "search": [
          "flag",
          "sint",
          "maarten"
        ],
        "name": "flag_sint_maarten",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/grzgisec.png",
        "icon": "🇸🇽",
        "shortcode": ":flag_sint_maarten:",
        "unicodes": [
          "u1f1f8",
          "u1f1fd"
        ]
      },
      {
        "search": [
          "flag",
          "syria"
        ],
        "name": "flag_syria",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/f95ptt5c.png",
        "icon": "🇸🇾",
        "shortcode": ":flag_syria:",
        "unicodes": [
          "u1f1f8",
          "u1f1fe"
        ]
      },
      {
        "search": [
          "flag",
          "eswatini"
        ],
        "name": "flag_eswatini",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/39oxn6p3.png",
        "icon": "🇸🇿",
        "shortcode": ":flag_eswatini:",
        "unicodes": [
          "u1f1f8",
          "u1f1ff"
        ]
      },
      {
        "search": [
          "flag",
          "tristan",
          "da",
          "cunha"
        ],
        "name": "flag_tristan_da_cunha",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/jbz4be5g.png",
        "icon": "🇹🇦",
        "shortcode": ":flag_tristan_da_cunha:",
        "unicodes": [
          "u1f1f9",
          "u1f1e6"
        ]
      },
      {
        "search": [
          "flag",
          "turks",
          "&",
          "caicos",
          "islands"
        ],
        "name": "flag_turks_caicos_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/iuukb86n.png",
        "icon": "🇹🇨",
        "shortcode": ":flag_turks_caicos_islands:",
        "unicodes": [
          "u1f1f9",
          "u1f1e8"
        ]
      },
      {
        "search": [
          "flag",
          "chad"
        ],
        "name": "flag_chad",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3r6iexlb.png",
        "icon": "🇹🇩",
        "shortcode": ":flag_chad:",
        "unicodes": [
          "u1f1f9",
          "u1f1e9"
        ]
      },
      {
        "search": [
          "flag",
          "french",
          "southern",
          "territories"
        ],
        "name": "flag_french_southern_territories",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/3t5lznuq.png",
        "icon": "🇹🇫",
        "shortcode": ":flag_french_southern_territories:",
        "unicodes": [
          "u1f1f9",
          "u1f1eb"
        ]
      },
      {
        "search": [
          "flag",
          "togo"
        ],
        "name": "flag_togo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/linig9f2.png",
        "icon": "🇹🇬",
        "shortcode": ":flag_togo:",
        "unicodes": [
          "u1f1f9",
          "u1f1ec"
        ]
      },
      {
        "search": [
          "flag",
          "thailand"
        ],
        "name": "flag_thailand",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/muhm2x94.png",
        "icon": "🇹🇭",
        "shortcode": ":flag_thailand:",
        "unicodes": [
          "u1f1f9",
          "u1f1ed"
        ]
      },
      {
        "search": [
          "flag",
          "tajikistan"
        ],
        "name": "flag_tajikistan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/gkllq8hd.png",
        "icon": "🇹🇯",
        "shortcode": ":flag_tajikistan:",
        "unicodes": [
          "u1f1f9",
          "u1f1ef"
        ]
      },
      {
        "search": [
          "flag",
          "tokelau"
        ],
        "name": "flag_tokelau",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6gnp2u1u.png",
        "icon": "🇹🇰",
        "shortcode": ":flag_tokelau:",
        "unicodes": [
          "u1f1f9",
          "u1f1f0"
        ]
      },
      {
        "search": [
          "flag",
          "timor_leste"
        ],
        "name": "flag_timor_leste",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qu1mvjy1.png",
        "icon": "🇹🇱",
        "shortcode": ":flag_timor_leste:",
        "unicodes": [
          "u1f1f9",
          "u1f1f1"
        ]
      },
      {
        "search": [
          "flag",
          "turkmenistan"
        ],
        "name": "flag_turkmenistan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wakbugyg.png",
        "icon": "🇹🇲",
        "shortcode": ":flag_turkmenistan:",
        "unicodes": [
          "u1f1f9",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "tunisia"
        ],
        "name": "flag_tunisia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/yjhldnnf.png",
        "icon": "🇹🇳",
        "shortcode": ":flag_tunisia:",
        "unicodes": [
          "u1f1f9",
          "u1f1f3"
        ]
      },
      {
        "search": [
          "flag",
          "tonga"
        ],
        "name": "flag_tonga",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qysnz78y.png",
        "icon": "🇹🇴",
        "shortcode": ":flag_tonga:",
        "unicodes": [
          "u1f1f9",
          "u1f1f4"
        ]
      },
      {
        "search": [
          "flag",
          "turkey"
        ],
        "name": "flag_turkey",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rtbul07b.png",
        "icon": "🇹🇷",
        "shortcode": ":flag_turkey:",
        "unicodes": [
          "u1f1f9",
          "u1f1f7"
        ]
      },
      {
        "search": [
          "flag",
          "trinidad",
          "&",
          "tobago"
        ],
        "name": "flag_trinidad_tobago",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/su2hpuum.png",
        "icon": "🇹🇹",
        "shortcode": ":flag_trinidad_tobago:",
        "unicodes": [
          "u1f1f9",
          "u1f1f9"
        ]
      },
      {
        "search": [
          "flag",
          "tuvalu"
        ],
        "name": "flag_tuvalu",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/mhy91p3j.png",
        "icon": "🇹🇻",
        "shortcode": ":flag_tuvalu:",
        "unicodes": [
          "u1f1f9",
          "u1f1fb"
        ]
      },
      {
        "search": [
          "flag",
          "taiwan"
        ],
        "name": "flag_taiwan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/01dna3gi.png",
        "icon": "🇹🇼",
        "shortcode": ":flag_taiwan:",
        "unicodes": [
          "u1f1f9",
          "u1f1fc"
        ]
      },
      {
        "search": [
          "flag",
          "tanzania"
        ],
        "name": "flag_tanzania",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/rd0bos7n.png",
        "icon": "🇹🇿",
        "shortcode": ":flag_tanzania:",
        "unicodes": [
          "u1f1f9",
          "u1f1ff"
        ]
      },
      {
        "search": [
          "flag",
          "ukraine"
        ],
        "name": "flag_ukraine",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/2pl27fon.png",
        "icon": "🇺🇦",
        "shortcode": ":flag_ukraine:",
        "unicodes": [
          "u1f1fa",
          "u1f1e6"
        ]
      },
      {
        "search": [
          "flag",
          "uganda"
        ],
        "name": "flag_uganda",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bcktj50p.png",
        "icon": "🇺🇬",
        "shortcode": ":flag_uganda:",
        "unicodes": [
          "u1f1fa",
          "u1f1ec"
        ]
      },
      {
        "search": [
          "flag",
          "u.s.",
          "outlying",
          "islands"
        ],
        "name": "flag_u.s._outlying_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9eusbcdt.png",
        "icon": "🇺🇲",
        "shortcode": ":flag_u.s._outlying_islands:",
        "unicodes": [
          "u1f1fa",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "united",
          "nations"
        ],
        "name": "flag_united_nations",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9tximaxx.png",
        "icon": "🇺🇳",
        "shortcode": ":flag_united_nations:",
        "unicodes": [
          "u1f1fa",
          "u1f1f3"
        ]
      },
      {
        "search": [
          "flag",
          "united",
          "states"
        ],
        "name": "flag_united_states",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bomg3pjj.png",
        "icon": "🇺🇸",
        "shortcode": ":flag_united_states:",
        "unicodes": [
          "u1f1fa",
          "u1f1f8"
        ]
      },
      {
        "search": [
          "flag",
          "uruguay"
        ],
        "name": "flag_uruguay",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/diu6inpr.png",
        "icon": "🇺🇾",
        "shortcode": ":flag_uruguay:",
        "unicodes": [
          "u1f1fa",
          "u1f1fe"
        ]
      },
      {
        "search": [
          "flag",
          "uzbekistan"
        ],
        "name": "flag_uzbekistan",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vke0nodq.png",
        "icon": "🇺🇿",
        "shortcode": ":flag_uzbekistan:",
        "unicodes": [
          "u1f1fa",
          "u1f1ff"
        ]
      },
      {
        "search": [
          "flag",
          "vatican",
          "city"
        ],
        "name": "flag_vatican_city",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/oesd6nu2.png",
        "icon": "🇻🇦",
        "shortcode": ":flag_vatican_city:",
        "unicodes": [
          "u1f1fb",
          "u1f1e6"
        ]
      },
      {
        "search": [
          "flag",
          "st.",
          "vincent",
          "&",
          "grenadines"
        ],
        "name": "flag_st._vincent_grenadines",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6hvk548m.png",
        "icon": "🇻🇨",
        "shortcode": ":flag_st._vincent_grenadines:",
        "unicodes": [
          "u1f1fb",
          "u1f1e8"
        ]
      },
      {
        "search": [
          "flag",
          "venezuela"
        ],
        "name": "flag_venezuela",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/i0qao4x4.png",
        "icon": "🇻🇪",
        "shortcode": ":flag_venezuela:",
        "unicodes": [
          "u1f1fb",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "british",
          "virgin",
          "islands"
        ],
        "name": "flag_british_virgin_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/hy5cnoc7.png",
        "icon": "🇻🇬",
        "shortcode": ":flag_british_virgin_islands:",
        "unicodes": [
          "u1f1fb",
          "u1f1ec"
        ]
      },
      {
        "search": [
          "flag",
          "u.s.",
          "virgin",
          "islands"
        ],
        "name": "flag_u.s._virgin_islands",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/9ay1cidr.png",
        "icon": "🇻🇮",
        "shortcode": ":flag_u.s._virgin_islands:",
        "unicodes": [
          "u1f1fb",
          "u1f1ee"
        ]
      },
      {
        "search": [
          "flag",
          "vietnam"
        ],
        "name": "flag_vietnam",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xjpx4g12.png",
        "icon": "🇻🇳",
        "shortcode": ":flag_vietnam:",
        "unicodes": [
          "u1f1fb",
          "u1f1f3"
        ]
      },
      {
        "search": [
          "flag",
          "vanuatu"
        ],
        "name": "flag_vanuatu",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/sc9wn6yv.png",
        "icon": "🇻🇺",
        "shortcode": ":flag_vanuatu:",
        "unicodes": [
          "u1f1fb",
          "u1f1fa"
        ]
      },
      {
        "search": [
          "flag",
          "wallis",
          "&",
          "futuna"
        ],
        "name": "flag_wallis_futuna",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/s3o8yu2f.png",
        "icon": "🇼🇫",
        "shortcode": ":flag_wallis_futuna:",
        "unicodes": [
          "u1f1fc",
          "u1f1eb"
        ]
      },
      {
        "search": [
          "flag",
          "samoa"
        ],
        "name": "flag_samoa",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/cldkdrli.png",
        "icon": "🇼🇸",
        "shortcode": ":flag_samoa:",
        "unicodes": [
          "u1f1fc",
          "u1f1f8"
        ]
      },
      {
        "search": [
          "flag",
          "kosovo"
        ],
        "name": "flag_kosovo",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/vym4s83v.png",
        "icon": "🇽🇰",
        "shortcode": ":flag_kosovo:",
        "unicodes": [
          "u1f1fd",
          "u1f1f0"
        ]
      },
      {
        "search": [
          "flag",
          "yemen"
        ],
        "name": "flag_yemen",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/6ti0d2l1.png",
        "icon": "🇾🇪",
        "shortcode": ":flag_yemen:",
        "unicodes": [
          "u1f1fe",
          "u1f1ea"
        ]
      },
      {
        "search": [
          "flag",
          "mayotte"
        ],
        "name": "flag_mayotte",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b7l21xs5.png",
        "icon": "🇾🇹",
        "shortcode": ":flag_mayotte:",
        "unicodes": [
          "u1f1fe",
          "u1f1f9"
        ]
      },
      {
        "search": [
          "flag",
          "south",
          "africa"
        ],
        "name": "flag_south_africa",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/qnbcerqn.png",
        "icon": "🇿🇦",
        "shortcode": ":flag_south_africa:",
        "unicodes": [
          "u1f1ff",
          "u1f1e6"
        ]
      },
      {
        "search": [
          "flag",
          "zambia"
        ],
        "name": "flag_zambia",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/bdpcehvq.png",
        "icon": "🇿🇲",
        "shortcode": ":flag_zambia:",
        "unicodes": [
          "u1f1ff",
          "u1f1f2"
        ]
      },
      {
        "search": [
          "flag",
          "zimbabwe"
        ],
        "name": "flag_zimbabwe",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/xu6xaer5.png",
        "icon": "🇿🇼",
        "shortcode": ":flag_zimbabwe:",
        "unicodes": [
          "u1f1ff",
          "u1f1fc"
        ]
      },
      {
        "search": [
          "flag",
          "england"
        ],
        "name": "flag_england",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/7kr9m3zv.png",
        "icon": "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
        "shortcode": ":flag_england:",
        "unicodes": [
          "u1f3f4",
          "ue0067",
          "ue0062",
          "ue0065",
          "ue006e",
          "ue0067",
          "ue007f"
        ]
      },
      {
        "search": [
          "flag",
          "scotland"
        ],
        "name": "flag_scotland",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/wt403j8s.png",
        "icon": "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
        "shortcode": ":flag_scotland:",
        "unicodes": [
          "u1f3f4",
          "ue0067",
          "ue0062",
          "ue0073",
          "ue0063",
          "ue0074",
          "ue007f"
        ]
      },
      {
        "search": [
          "flag",
          "wales"
        ],
        "name": "flag_wales",
        "image":"https://cdn.jsdelivr.net/gh/Slanfan/TolariaEmojis/emojis/b3na37nu.png",
        "icon": "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
        "shortcode": ":flag_wales:",
        "unicodes": [
          "u1f3f4",
          "ue0067",
          "ue0062",
          "ue0077",
          "ue006c",
          "ue0073",
          "ue007f"
        ]
      }
    ]
  }
]

<div class="modal-header maintenance-header">
  <div class="title">System Maintenance</div>
  <div class="date-time">
    <app-timestamp-clock [timestamp]="release.downtime.from" displayType="full" [seconds]="true"></app-timestamp-clock>
  </div>
  <div class="stripes"></div>
</div>
<div class="modal-body maintenance-body">
  <div class="description" [innerHtml]="release.downtime.description"></div>
  <div class="logo">
    <fa-icon [icon]="appLogo" [fixedWidth]="true"></fa-icon>
  </div>
</div>



<app-dialogs *ngIf="dialogs.showModalDialog"></app-dialogs>

<ng-container *ngIf="(auth.isLoggedIn$ | async) && (auth.user$ | async) as user">
  <app-top-bar [user]="user" *ngIf="!globals.isMatchRoom && !globals.isDraftRoom"></app-top-bar>
</ng-container>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>



<router-outlet></router-outlet>
<router-outlet name="match-room"></router-outlet>




<div class="isBusy" *ngIf="globals.isBusy.status">
  <div class="loader-wrapper" [style.width.rem]="globals.isBusy.loaderWidth">
    <div class="loader">
      <app-busy-spinner></app-busy-spinner>
      <!-- <div class="lds-ripple"><div></div><div></div></div> -->
    </div>
    <div class="message" [innerHtml]="globals.isBusy.message"></div>
  </div>
</div>

<ng-template #nonChromeBrowser let-modal>
  <div class="modal-header">
    <div class="modal-title" id="modal-basic-title">Browser Recommendation</div>
    <div class="btn-close float-end text-secondary" aria-label="Close" (click)="modal.dismiss('cancel')">
      <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
    </div>
  </div>
  <div class="modal-body text-center">
    <fa-icon [fixedWidth]="true" class="text-xl-center" [icon]="faTimes"></fa-icon>
    We recommend using Tolaria.app with Chrome browser.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary w-100" (click)="modal.close('cancel')">OK</button>
  </div>
</ng-template>

import Quill from 'quill'
import { canSendMessage } from '../../tolaria-wysiwyg-helper'

const Module: any = Quill.import('core/module')

export class TolariaKeyboardModule extends Module {

  constructor(quill: any, options: any) {
    super(quill, options)

    this.quill = quill
    this.onSend = options.onSend
    this.onFormatToggle = options.onFormatToggle

    // add default binding for ENTER before Quills default behavior
    quill.keyboard.addBinding({
        key: 13,
    }, (range: any, context: any) => {
        if (context.format.list) {
          return true
        }
        if (context.format.code) {
          return true
        }
        if (context.format['code-block']) {
          return true
        }
        if (context.format.blockquote) {
          return true
        }
        if (this.mentionOpen) {
          return true
        }

        if (canSendMessage(this.quill)) {
          this.onSend && this.onSend()
        }
        return false
    })
    quill.keyboard.bindings[13].unshift(quill.keyboard.bindings[13].pop())

    // remove default binding for bold text toggling and add a new one
    quill.keyboard.addBinding({
      key: 66,
      shortKey: true,
    }, (range: any, context: any) => {
        this.onFormatToggle && this.onFormatToggle('bold', context)
        return false
    })
    quill.keyboard.bindings[66].unshift(quill.keyboard.bindings[13].pop())

    // remove default binding for italic text toggling and add a new one
    quill.keyboard.addBinding({
      key: 73,
      shortKey: true,
    }, (range: any, context: any) => {
        this.onFormatToggle && this.onFormatToggle('italic', context)
        return false
    })
    quill.keyboard.bindings[66].unshift(quill.keyboard.bindings[73].pop())

    // strike-through
    quill.keyboard.addBinding({
      key: 'x',  // key X
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('strike', context))
    quill.keyboard.addBinding({
      key: 'X',  // key X
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('strike', context))
    quill.keyboard.addBinding({
      key: 88,  // key X
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('strike', context))

    // ordered list
    quill.keyboard.addBinding({
      key: '7', // numpad 7
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('list', context, 'ordered'))
    quill.keyboard.addBinding({
      key: 55, // numpad 7
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('list', context, 'ordered'))
    quill.keyboard.addBinding({
      key: 103, // numpad 7
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('list', context, 'ordered'))

    // unordered list
    quill.keyboard.addBinding({
      key: '8',
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('list', context, 'bullet'))
    quill.keyboard.addBinding({
      key: 56,
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('list', context, 'bullet'))
    quill.keyboard.addBinding({
      key: 104,
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('list', context, 'bullet'))

    // blockquote
    quill.keyboard.addBinding({
      key: '9',
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('blockquote', context))
    quill.keyboard.addBinding({
      key: 57,
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('blockquote', context))
    quill.keyboard.addBinding({
      key: 105,
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('blockquote', context))

    // code
    quill.keyboard.addBinding({
      key: 'c',
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('code', context))
    quill.keyboard.addBinding({
      key: 'C',
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('code', context))
    quill.keyboard.addBinding({
      key: 67,
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('code', context))

    // code block
    quill.keyboard.addBinding({
      key: 'c',
      altKey: true,
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('code-block', context))
    quill.keyboard.addBinding({
      key: 'C',
      altKey: true,
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('code-block', context))
    quill.keyboard.addBinding({
      key: 67,
      altKey: true,
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('code-block', context))

    // insert link
    quill.keyboard.addBinding({
      key: 'u',
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('link', context))
    quill.keyboard.addBinding({
      key: 'U',
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('link', context))
    quill.keyboard.addBinding({
      key: 85,
      shiftKey: true,
      shortKey: true,
    }, (range: any, context: any) => this.onFormatToggle && this.onFormatToggle('link', context))

  }
}




import { IMatchData } from './../../../../../../tolaria-cloud-functions/src/_interfaces';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { faCircle, faEllipsisH, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { IPlayerLife } from './../../../../services/match.service';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalsService } from 'src/app/services';

@Component({
  selector: 'app-life-tracker',
  templateUrl: './life-tracker.component.html',
  styleUrls: ['./life-tracker.component.css']
})
export class LifeTrackerComponent implements OnInit, OnDestroy {
  @Input() matchDocId: string;
  @Input() playerIs: string;
  @Input() matchWinsOnTop: boolean;
  @Input() isSpectator?: boolean;
  @Input() isPlayersLife: boolean;

  faCircleFilled = faCircle;
  faCheckCircle = faCheckCircle;
  faEllipsisH = faEllipsisH;

  public playerLife$: BehaviorSubject<IPlayerLife> = new BehaviorSubject<IPlayerLife>(null)
  private componentWasDestroyed$ = new Subject<boolean>();

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.globals.hotkeysEnabled && this.isPlayersLife) {
      if (event.key === 'ArrowDown' && event.shiftKey) {
        this.playerLife.current = this.playerLife.current - 5;
      }
      else if (event.key === 'ArrowDown') {
        this.playerLife.current = this.playerLife.current - 1;
      }
      else if (event.key === 'ArrowUp' && event.shiftKey) {
        this.playerLife.current = this.playerLife.current + 5;
      }
      else if (event.key === 'ArrowUp') {
        this.playerLife.current = this.playerLife.current + 1;
      }
    }
  }

  constructor(
    private globals: GlobalsService,
    private readonly firestore: AngularFirestore,
  ) {
  }

  ngOnInit(): void {

    const matchDoc$ = this.firestore.collection('matches').doc<IMatchData>(this.matchDocId).valueChanges()
    const playersInfo$ = this.firestore.collection('matches').doc(this.matchDocId).collection('match-room').doc<any>('players-info').valueChanges()

    combineLatest([matchDoc$, playersInfo$])
      .pipe(takeUntil(this.componentWasDestroyed$))
      .subscribe(([matchDoc, playersInfo]) => {
        if (matchDoc && playersInfo) {
          const playerLife: IPlayerLife = {
            current: playersInfo[this.playerIs].lifePoints[0],
            history: playersInfo[this.playerIs].lifePoints,
            gameWins: matchDoc[this.playerIs].wins,
          }
          // this.playerLife = playerLife
          this.playerLife$.next(playerLife)
        }
      })

  }

  ngOnDestroy(): void {
    this.componentWasDestroyed$.next(true);
  }

  public get playerLife(): IPlayerLife {
    if (this.playerLife$.getValue() === null) {
      return {
        current: 20,
        history: [20],
        gameWins: 0
      }
    }

    return this.playerLife$.getValue()
  }

}

<ng-container *ngIf="formatsLoaded$ | async as formats">

  <div class="modal-header d-flex align-items-center">
    <h5 class="text-larger flex-grow-1">{{ isEdit ? 'Edit' : 'Create new' }} event</h5>
    <div class="btn btn-sm btn-blank" (click)="destroySelf()">
      <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
    </div>
  </div>


  <div #editorWrapper class="modal-body d-flex p-0" [ngClass]="{ 'flex-column' : globals.isMobile, 'scrollable' : !globals.isMobile }"
    style="min-height: 75vh;" *ngIf="eventData !== undefined">


    <div class="p-3 flex-grow-1 main-section h-100" [ngClass]="{ 'mr-3' : !globals.isMobile }">
      <!-- SECTION: details -->
      <div class="h5">
        <div class="d-inline me-3 tab-selector" (click)="showDetails = true; showBannerEdit = false;"
          [ngClass]="{ 'selected' : showDetails }">Details</div>
        <div class="d-inline ms-3 me-3 tab-selector" (click)="showDetails = false; showBannerEdit = false;"
          [ngClass]="{ 'selected' : !showDetails && !showBannerEdit }">Description</div>
        <div class="d-inline ms-3 tab-selector" (click)="showDetails = false; showBannerEdit = true;"
          [ngClass]="{ 'selected' : !showDetails && showBannerEdit }" *ngIf="editMode">Banner</div>
      </div>

      <ng-container *ngIf="showDetails">
        <div class="form-group">
          <!-- NAME -->
          <div class="row mt-3">
            <div class="col">
              <div class="form-label">
                Name
                <fa-icon class="float-end {{ eventData.name.length >= 5 ? 'text-greenyellow' : 'text-secondary' }}"
                  [fixedWidth]="true" [icon]="faCheckCircle"></fa-icon>
              </div>
              <input #eventName type="text" class="form-control" [(ngModel)]="eventData.name">
            </div>
          </div>
          <!-- LOCATION AND DATE & TIME -->
          <div class="row mt-3">
            <div class="{{ globals.isMobile ? 'col-12 mb-3' : 'col' }}">
              <div class="form-label">
                Location
                <fa-icon
                  class="float-end {{ eventData.location.name.length >= 5 ? 'text-greenyellow' : 'text-secondary' }}"
                  [fixedWidth]="true" [icon]="faCheckCircle"></fa-icon>
              </div>
              <div class="input-group">
                <input type="text" class="form-control" [(ngModel)]="eventData.location.name">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary btn-sm"
                    (click)="showLocationExtra = !showLocationExtra; showDatePicker = false">
                    <fa-icon [fixedWidth]="true" [icon]="faEllipsisH"></fa-icon>
                  </button>
                </div>
              </div>
              <ng-container *ngIf="showLocationExtra">
                <div class="row">
                  <div class="col">
                    <div class="row mt-3">
                      <div class="col">
                        <div class="form-label">Address</div>
                        <input type="text" class="form-control" [(ngModel)]="eventData.location.address">
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-4">
                        <div class="form-label">Postal Code</div>
                        <input type="text" class="form-control" [(ngModel)]="eventData.location.postalCode">
                      </div>
                      <div class="col-8">
                        <div class="form-label">Postal Area</div>
                        <input type="text" class="form-control" [(ngModel)]="eventData.location.postalArea">
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col">
                        <div class="form-label">Website</div>
                        <input type="text" class="form-control" [(ngModel)]="eventData.location.url">
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="col">
              <div class="form-label">
                Date & Time
                <fa-icon
                  class="float-end {{ !dateAndTimeInvalid && checkDatePattern(eventData.datetime) ? 'text-greenyellow' : 'text-secondary' }}"
                  [fixedWidth]="true" [icon]="faCheckCircle"></fa-icon>
              </div>
              <!-- <input type="datetime-local" class="form-control" [(ngModel)]="eventData.datetime"
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                      placement="left" ngbTooltip="Please match format yyyy-mm-ddThh:mm (e.g. 2020-12-24T15:00)"> -->
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Select date and time"
                  [(ngModel)]="eventData.datetime">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary btn-sm">UTC: {{ eventData.GMT_offset }}</button>
                </div>
                <div class="input-group-append">
                  <button #dateButton class="btn btn-outline-secondary btn-sm"
                    (click)="showDatePicker = true; showLocationExtra = false">
                    <fa-icon [fixedWidth]="true" [icon]="faCalendarAlt"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="showDatePicker">
            <div class="row">
              <div class="col pt-3">
                <!-- DAILY EVENT -->
                <ngb-datepicker class="w-100" [(ngModel)]="dateSelection.fromDate" [displayMonths]="monthsToDisplay"
                  [showWeekNumbers]="true" navigation="arrows" outsideDays="collapsed"
                  (dateSelect)="onDateSelection($event, eventData.isMultiDay)" *ngIf="!eventData.isMultiDay">
                </ngb-datepicker>
                <!-- PERIODIC EVENT -->
                <ngb-datepicker #dp class="w-100" [displayMonths]="monthsToDisplay" [showWeekNumbers]="true"
                  [dayTemplate]="t" navigation="arrows" outsideDays="collapsed"
                  (dateSelect)="onDateSelection($event, eventData.isMultiDay)" *ngIf="eventData.isMultiDay">
                </ngb-datepicker>
                <ng-template #t let-date let-focused="focused">
                  <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                  </span>
                </ng-template>

                <div class="row mt-3">
                  <div class="col">
                    <div class="form-label">{{ eventData.isMultiDay ? 'From time' : 'Starting Time' }}</div>
                    <ngb-timepicker [(ngModel)]="timeSelection.fromTime" [meridian]="!auth.user.settings.clock24">
                    </ngb-timepicker>
                  </div>
                  <div class="col" *ngIf="eventData.isMultiDay">
                    <div class="form-label">To Time</div>
                    <ngb-timepicker [(ngModel)]="timeSelection.toTime" [meridian]="!auth.user.settings.clock24">
                    </ngb-timepicker>
                  </div>
                  <div class="col">
                    <div class="form-label">UTC/GMT offset</div>
                    <div ngbDropdown class="d-inline-block" style="margin-top: 2.25rem;">
                      <button class="btn btn-outline-secondary" id="dropdownUTC" ngbDropdownToggle>{{
                        eventData.GMT_offset }}</button>
                      <div ngbDropdownMenu aria-labelledby="dropdownUTC" class="drop-down-panel"
                        style="max-height: 10rem;">
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '+12:00'">+12:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '+11:00'">+11:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '+10:00'">+10:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '+09:00'">+09:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '+08:00'">+08:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '+07:00'">+07:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '+06:00'">+06:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '+05:00'">+05:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '+04:00'">+04:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '+03:00'">+03:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '+02:00'">+02:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '+01:00'">+01:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '+00:00'">+00:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '-01:00'">-01:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '-02:00'">-02:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '-03:00'">-03:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '-04:00'">-04:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '-05:00'">-05:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '-06:00'">-06:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '-07:00'">-07:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '-08:00'">-08:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '-09:00'">-09:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '-10:00'">-10:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '-11:00'">-11:00</button>
                        <button ngbDropdownItem class="drop-down-item"
                          (click)="eventData.GMT_offset = '-12:00'">-12:00</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col">
                    <div class="btn btn-sm">
                      <div class="form-check form-switch d-flex justify-content-end">
                        <input type="checkbox" class="form-check-input" id="isDailyEvent"
                          [(ngModel)]="eventData.isMultiDay">
                        <label class="form-check-label btn-blank"
                          [ngClass]="{ 'text-secondary': !eventData.isMultiDay }"
                          for="isDailyEvent">The event will be run over a period of time.</label>
                      </div>
                    </div>
                  </div>
                  <div class="col d-flex align-items-center justify-content-end">
                    <button class="btn btn-sm me-2 btn-blank"
                      ngbTooltip="Set today as date and the next full 15 min as time" placement="top"
                      (click)="setDateTimeAsToday(this.eventData.isMultiDay)">Set today and now</button>
                    <button class="btn btn-sm btn-outline-main" (click)="setDateTime(this.eventData.isMultiDay)"
                      [disabled]="dateAndTimeInvalid">Set date & time</button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- SECTION: structure -->
        <div class="form-group">
          <div class="row mt-3">
            <div class="{{ globals.isMobile ? 'col-12 mb-3' : 'col' }}">
              <div class="form-label">
                Type
                <fa-icon class="float-end {{ eventData.type.length > 0 ? 'text-greenyellow' : 'text-secondary' }}"
                  [fixedWidth]="true" [icon]="faCheckCircle"></fa-icon>
              </div>
              <select class="custom-select" [(ngModel)]="eventData.type">
                <option value="Constructed">Constructed</option>
                <option value="Limited">Limited</option>
              </select>
            </div>
            <div class="col">
              <div class="form-label">
                Format
                <fa-icon class="float-end {{ eventData.format.length > 0 ? 'text-greenyellow' : 'text-secondary' }}"
                  [fixedWidth]="true" [icon]="faCheckCircle"></fa-icon>
              </div>
              <select class="custom-select" [(ngModel)]="eventData.format">
                <option *ngFor="let format of formats" [value]="format.name">{{ format.name }}</option>
              </select>
              <!-- ONLY SHOW IF EVENT IS CONSTRUCTED -->
              <div class="row mt-3" *ngIf="eventData.type === 'Constructed'">
                <ng-container *ngFor="let format of formats">
                  <div class="col" *ngIf="format.name === eventData.format && format.ruleSets.length > 0">
                    <div class="form-label">
                      Ruleset
                      <fa-icon class="float-end {{ rulesetOk ? 'text-greenyellow' : 'text-secondary' }}"
                        [fixedWidth]="true" [icon]="faCheckCircle"></fa-icon>
                    </div>
                    <select class="custom-select" [(ngModel)]="eventData.ruleset" [compareWith]="byName">
                      <option *ngFor="let ruleset of format.ruleSets" [ngValue]="ruleset">{{ ruleset.name }}</option>
                    </select>
                  </div>
                </ng-container>
                <ng-container *ngFor="let format of formats">
                  <div class="col" *ngIf="format.name === eventData.format && format.reprintPolicies.length > 0">
                    <div class="form-label">
                      Reprint Policy
                      <fa-icon class="float-end {{ reprintPolicyOk ? 'text-greenyellow' : 'text-secondary' }}"
                        [fixedWidth]="true" [icon]="faCheckCircle"></fa-icon>
                    </div>
                    <select class="custom-select" [(ngModel)]="eventData.reprintPolicy" [compareWith]="byName">
                      <option *ngFor="let reprintPolicy of format.reprintPolicies" [ngValue]="reprintPolicy">
                        {{ reprintPolicy.name }}</option>
                    </select>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="form-group mt-3">
            <fieldset [disabled]="eventDoc && eventDoc.statusCode > 0">
              <div class="form-label">
                Structure
                <fa-icon class="float-end {{ structureOk ? 'text-greenyellow' : 'text-secondary' }}" [fixedWidth]="true"
                  [icon]="faCheckCircle"></fa-icon>
              </div>
              <div class="row">
                <div class="{{ globals.isMobile ? 'col-4' : 'col' }}">
                  <button type="button"
                    class="btn w-100 h-100 {{ eventData.structure.isSwiss ? 'btn-main' : 'btn-secondary' }}"
                    (click)="resetButtons(); eventData.structure.isSwiss = true;">Swiss</button>
                </div>
                <div class="{{ globals.isMobile ? 'col-4' : 'col' }}">
                  <button type="button"
                    class="btn w-100 h-100 {{ eventData.structure.isBracket ? 'btn-main' : 'btn-secondary' }}"
                    (click)="resetButtons(); eventData.structure.isBracket = true;" [disabled]="true">Bracket</button>
                </div>
                <div class="{{ globals.isMobile ? 'col-4' : 'col' }}">
                  <button type="button"
                    class="btn w-100 h-100 {{ eventData.structure.isGroup ? 'btn-main' : 'btn-secondary' }}"
                    (click)="resetButtons(); eventData.structure.isGroup = true;" [disabled]="false">Group</button>
                </div>
                <div class="{{ globals.isMobile ? 'col-6 mt-3' : 'col' }}">
                  <button type="button"
                    class="btn w-100 h-100 {{ eventData.structure.isRoundRobin ? 'btn-main' : 'btn-secondary' }}"
                    (click)="resetButtons(); eventData.structure.isRoundRobin = true;">Round Robin</button>
                </div>
                <div class="{{ globals.isMobile ? 'col-6 mt-3' : 'col' }}">
                  <button type="button"
                    class="btn w-100 h-100 {{ eventData.structure.isBatch ? 'btn-main' : 'btn-secondary' }}"
                    (click)="resetButtons(); eventData.structure.isBatch = true;" [disabled]="false">Batch</button>
                </div>
              </div>
            </fieldset>
          </div>
          <!-- SWISS -->
          <fieldset [disabled]="eventDoc && eventDoc.statusCode > 0">
            <div class="form-group" *ngIf="eventData.structure.isSwiss">
              <div class="custom-form-check"
                (click)="eventData.structure.swiss.hasBracketAfterSwiss = !eventData.structure.swiss.hasBracketAfterSwiss">
                <div class="form-label">
                  <fa-icon [fixedWidth]="true" [icon]="eventData.structure.swiss.hasBracketAfterSwiss ? faCheckSquare : faSquare"></fa-icon>
                  Playoffs after swiss?
                </div>
              </div>
              <small class="text-normal text-normal text-secondary">Playoff bracket can be added/removed/changed at a later
                stage if
                needed</small>
            </div>
            <div class="form-group" *ngIf="eventData.structure.isSwiss">
              <div class="custom-form-check"
                (click)="eventData.structure.swiss.teams = !eventData.structure.swiss.teams">
                <div class="form-label">
                  <fa-icon [fixedWidth]="true" [icon]="eventData.structure.swiss.teams ? faCheckSquare : faSquare"></fa-icon>
                  Teams
                </div>
              </div>
              <small class="text-normal text-normal text-secondary">
                You will instead of attending individual players create a teams.
                Teams contains three players that are assigned to a specific seat (A/B/C).
              </small>
            </div>
            <div class="form-group"
              *ngIf="eventData.structure.swiss.hasBracketAfterSwiss && eventData.structure.isSwiss">
              <div class="row">
                <div class="col" *ngFor="let size of es.bracketSizes">
                  <button type="button"
                    class="btn w-100 h-100 {{ eventData.structure.swiss.bracketSize === size ? 'btn-main' : 'btn-secondary' }}"
                    (click)="eventData.structure.swiss.bracketSize = size;">Top {{ size }}</button>
                </div>
              </div>
            </div>
          </fieldset>
          <!-- BRACKET -->
          <fieldset [disabled]="eventDoc && eventDoc.statusCode > 0">
            <div class="form-group" *ngIf="eventData.structure.isBracket">
              <div class="row mt-3">
                <div class="col">
                  <button type="button"
                    class="btn w-100 h-100 {{ eventData.structure.bracket.singleElimination ? 'btn-main' : 'btn-secondary' }}"
                    (click)="eventData.structure.bracket.singleElimination = true; eventData.structure.bracket.doubleElimination = false;">Single
                    Elimination</button>
                </div>
                <div class="col">
                  <button type="button"
                    class="btn w-100 h-100 {{ eventData.structure.bracket.doubleElimination ? 'btn-main' : 'btn-secondary' }}"
                    (click)="eventData.structure.bracket.singleElimination = false; eventData.structure.bracket.doubleElimination = true;">Double
                    Elimination</button>
                </div>
              </div>
            </div>
          </fieldset>
          <!-- GROUP -->
          <fieldset [disabled]="eventDoc && eventDoc.statusCode > 0">
            <div class="form-group" *ngIf="eventData.structure.isGroup">
              <small class="text-normal text-secondary">When starting the event you will be able to set the number groups,
                number of matches per group and adjust the players within the groups. You will also be able to add a
                playoff bracket if needed and when that is initiated, you select who will participate in the
                playoffs.</small>
            </div>
          </fieldset>
          <!-- ROUND ROBIN -->
          <fieldset [disabled]="eventDoc && eventDoc.statusCode > 0">
            <div class="form-group" *ngIf="eventData.structure.isRoundRobin">
              <small class="text-normal text-secondary">Each player plays every other player once.</small>
            </div>
          </fieldset>
          <!-- BATCH -->
          <fieldset [disabled]="eventDoc && eventDoc.statusCode > 0">
            <div class="form-group" *ngIf="eventData.structure.isBatch">
              <small class="text-normal text-secondary">In a batch event you are able to create batches with pairings either
                by random pairing or by standings.</small>
              <small class="text-normal text-secondary">A batch can have both random and standings based pairings if
                needed.</small>
              <small class="text-normal text-secondary">Playoff bracket can be added/removed at a later stage if
                needed</small>
            </div>
          </fieldset>
        </div>
      </ng-container>

      <ng-container *ngIf="!showDetails && !showBannerEdit">
        <div class="form-group mt-3 h-100">
          <ckeditor #editor [config]="ckeConfig" [(ngModel)]="eventData.description" id="description" name="description"
            type="divarea">
          </ckeditor>
        </div>
      </ng-container>

      <ng-container *ngIf="!showDetails && showBannerEdit">
        <ng-container *ngIf="!change">
          <div class="row mt-3">
            <div class="col-10">
              <input type="file" id="customAvatar" name="file" #fileInput (change)="fileChangeEvent($event)">
            </div>
            <div class="col-2">
              <button class="btn btn-secondary w-100" [disabled]="saveDisabled" (click)="saveImage()">Upload</button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <div class="text-secondary text-italic" width="100%" *ngIf="!showImagePreview">Select an image to upload as
                the event banner.</div>
              <image-cropper class="image-cropper" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                [aspectRatio]="2 / 1" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" *ngIf="showImagePreview">
              </image-cropper>
            </div>
            <div class="col-6">
              <img class="upload-preview" width="100%" [src]="croppedImage">
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="change">
          <div class="row">
            <div class="col-10">
              <div class="banner-image-wrapper">
                <!-- <div class="edit-overalay" (click)="change = false">
                      <fa-icon [fixedWidth]="true" [icon]="faEdit"></fa-icon><span class="ms-3">Change</span>
                    </div> -->
                <img [src]="eventDoc.bannerUrl ? eventDoc.bannerUrl : ''">
              </div>
            </div>
            <div class="col-2">
              <button class="btn btn-sm btn-secondary float-end" (click)="change = false">
                <fa-icon [fixedWidth]="true" [icon]="faEdit"></fa-icon> Change
              </button>
              <button class="btn btn-sm btn-danger float-end mt-2" (click)="removeBannerImage()">
                <fa-icon [fixedWidth]="true" [icon]="faTrash"></fa-icon> Delete
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>

    <div class="p-3 max-height-100 flex-grow-0 extra-section d-flex flex-column ms-3 me-3" [ngClass]="{ 'ml-3 me-3 overflow-hidden overflow-y-auto' : !globals.isMobile }" [style.maxWidth]="globals.isMobile ? 'auto' : '18rem'">
      <!-- SECTION: extra -->
      <div class="h5 mb-3">Extra</div>

      <div class="row align-items-center fixed-height-3"
        ngbTooltip="Toggle this OFF if you want to set a date and time when registration will open. NOTE: Registration will automatically open at the given time!"
        placement="top-left" container="body">
        <div class="col" [ngClass]="{ 'text-secondary': dateSelection.fromDate === null }">Registration Open</div>
        <div class="col-4">
          <div class="form-check form-switch d-flex justify-content-end">
            <input type="checkbox" class="form-check-input" id="startEventWithRegistrationClosed"
              [(ngModel)]="eventData.registrationOpen" [disabled]="dateSelection.fromDate === null">
            <label class="form-check-label" for="startEventWithRegistrationClosed"></label>
          </div>
        </div>
      </div>

      <div class="row align-items-center justify-content-end mb-3 border-bottom border-secondary px-3 pb-3" *ngIf="!eventData.registrationOpen">
        <div class="d-flex flex-column align-items-center">
          <div class="text-secondary mb-2">Date registration opens</div>
          <ngb-datepicker class="w-100" [(ngModel)]="dateSelectionRegistrationOpens.fromDate" [displayMonths]="1"
            [showWeekNumbers]="true" navigation="arrows" outsideDays="collapsed" [maxDate]="dateSelection.fromDate">
          </ngb-datepicker>
          <div class="text-secondary my-2">Time registration opens</div>
          <ngb-timepicker [(ngModel)]="timeSelectionRegistrationOpens.fromTime"
            [meridian]="!auth.user.settings.clock24">
          </ngb-timepicker>
        </div>
      </div>

      <ng-container id="payment-wrapper">

        <div class="row align-items-center fixed-height-3" [ngbTooltip]="registrationFeeTooltip" placement="top-left"
          container="body">
          <div class="col" [ngClass]="{ 'text-secondary': !showPaymentOptions }">Registration Fee</div>
          <div class="col-4">
            <div class="form-check form-switch d-flex justify-content-end">
              <input type="checkbox" class="form-check-input" id="registrationFeeActive"
                [(ngModel)]="eventData.registrationFee.active" [disabled]="!showPaymentOptions">
              <label class="form-check-label" for="registrationFeeActive"></label>
            </div>
          </div>
        </div>

        <div class="ps-3 registration-fee-settings" *ngIf="eventData.registrationFee.active">

          <div class="row align-items-center fixed-height-3"
            ngbTooltip="The extra amount will be transferred to the tournament organizer and will not be refundable"
            placement="top-left" container="body">
            <div class="col">Allow players to add extra</div>
            <div class="col-4">
              <div class="form-check form-switch d-flex justify-content-end">
                <input type="checkbox" class="form-check-input" id="registrationFeeCharityExtra"
                  [(ngModel)]="eventData.registrationFee.charityExtra">
                <label class="form-check-label" for="registrationFeeCharityExtra"></label>
              </div>
            </div>
          </div>

          <div class="row align-items-center fixed-height-3" ngbTooltip="" placement="top-left" container="body">
            <div class="col">Payment through Tolaria</div>
            <div class="col-4">
              <div class="form-check form-switch d-flex justify-content-end">
                <input type="checkbox" class="form-check-input" id="registrationFeeTolaria"
                  [(ngModel)]="eventData.registrationFee.tolariaPayment">
                <label class="form-check-label" for="registrationFeeTolaria"></label>
              </div>
            </div>
          </div>

          <ng-container *ngIf="exchangeRatesLoaded$ | async">
            <div class="row align-items-center fixed-height-3" ngbTooltip="" placement="top-left" container="body">
              <div class="col">Amount</div>
              <div class="col-4"
                ngbTooltip="{{ minRegFeeMet ? 'All good' : 'Minimum registration fee for currency = ' + minRegFee }}"
                container="body">
                <input type="number" class="form-control {{ minRegFeeMet ? '' : 'is-invalid' }}"
                  [min]="minRegFee" [(ngModel)]="eventData.registrationFee.amount">
              </div>
            </div>

            <div class="row align-items-center fixed-height-3" ngbTooltip="" placement="top-left" container="body">
              <div class="col">Currency</div>
              <div class="col-4">
                <button class="btn btn-sm btn-outline-secondary w-100" [disabled]="true">
                  {{ eventData.registrationFee.currency.toUpperCase() }}
                </button>
              </div>
            </div>
          </ng-container>

        </div>

      </ng-container>

      <div class="row align-items-center fixed-height-3"
        ngbTooltip="This will enable webcam matches to be played with a webcam interface here @ Tolaria. (not applicable for team events)"
        placement="top-left" container="body">
        <div class="col" [ngClass]="{ 'text-secondary' : eventData.structure.swiss.teams }">
          Online
        </div>
        <div class="col-4">
          <div class="form-check form-switch d-flex justify-content-end">
            <input type="checkbox" class="form-check-input" id="isOnlineTournament"
              [(ngModel)]="eventData.isOnlineTournament" [disabled]="eventData.structure.swiss.teams">
            <label class="form-check-label" for="isOnlineTournament"></label>
          </div>
        </div>
      </div>

      <div class="row align-items-center fixed-height-3"
        ngbTooltip="Anyone with a player profile will be able to sign up for a public event. For non-public, you will be able to invite players or manually sign them up."
        placement="top-left" container="body">
        <div class="col">
          Public
        </div>
        <div class="col-4">
          <div class="form-check form-switch d-flex justify-content-end">
            <input type="checkbox" class="form-check-input" id="isPublic" [(ngModel)]="eventData.isPublic"
              (change)="eventData.isPublic ? eventData.isPubliclyVisible = true : null">
            <label class="form-check-label" for="isPublic"></label>
          </div>
        </div>
      </div>

      <div class="row align-items-center fixed-height-3"
        ngbTooltip="A publicly visible event will be shown in the event list even if the event is non-public."
        placement="top-left" container="body">
        <div class="col {{ eventData.isPublic ? 'text-secondary' : '' }}">
          Publicly visible
        </div>
        <div class="col-4">
          <div class="form-check form-switch d-flex justify-content-end">
            <input type="checkbox" class="form-check-input" id="isPubliclyVisible"
              [(ngModel)]="eventData.isPubliclyVisible" [disabled]="eventData.isPublic">
            <label class="form-check-label" for="isPubliclyVisible"></label>
          </div>
        </div>
      </div>

      <div class="row align-items-center fixed-height-3"
        ngbTooltip="Players will need to upload a photo of their deck to be part of the event. (only registered players here @ tolaria.app will be able to attend an the event)"
        placement="top-left" container="body">
        <div class="col">
          Deck Photo
        </div>
        <div class="col-4">
          <div class="form-check form-switch d-flex justify-content-end">
            <input type="checkbox" class="form-check-input" id="deckPhoto" [(ngModel)]="eventData.deckPhoto">
            <label class="form-check-label" for="deckPhoto"></label>
          </div>
        </div>
      </div>

      <div class="row align-items-center fixed-height-3"
        ngbTooltip="Players will need to add their deck list in the built in deck list interface. (only registered players here @ tolaria.app will be able to attend an the event)"
        placement="top-left" container="body">
        <div class="col">
          Deck List
        </div>
        <div class="col-4">
          <div class="form-check form-switch d-flex justify-content-end">
            <input type="checkbox" class="form-check-input" id="deckList" [(ngModel)]="eventData.deckList">
            <label class="form-check-label" for="deckList"></label>
          </div>
        </div>
      </div>

      <div class="row align-items-center fixed-height-3"
        ngbTooltip="If you do not set a cap, unlimited number of players may attend the event." placement="top-left"
        container="body">
        <div class="col">
          Attendee Cap
        </div>
        <div class="col-4">
          <div class="form-check form-switch d-flex justify-content-end">
            <input type="checkbox" class="form-check-input" id="hasAttendeeCap"
              [(ngModel)]="eventData.hasAttendeeCap">
            <label class="form-check-label" for="hasAttendeeCap"></label>
          </div>
        </div>
      </div>

      <div class="row align-items-center fixed-height-3"
        ngbTooltip="Number of minutes to play each round, set to 0 (zero) for untimed rounds." placement="top-left"
        container="body">
        <div class="col {{ eventData.hasAttendeeCap ? '' : 'text-secondary' }}">Cap</div>
        <div class="col-4">
          <input type="number" class="form-control" [disabled]="!eventData.hasAttendeeCap"
            [(ngModel)]="eventData.attendeeCap">
        </div>
      </div>

      <div class="row align-items-center fixed-height-3"
        ngbTooltip="Number of minutes to play each round, set to 0 (zero) for untimed rounds." placement="top-left"
        container="body">
        <div class="col">Round Timer</div>
        <div class="col-4">
          <input type="number" class="form-control" [(ngModel)]="eventData.roundTimer">
        </div>
      </div>

      <div class="row align-items-center fixed-height-3"
        ngbTooltip="Can be used if you run multiple tournaments in one room with different table sections."
        placement="top-left" container="body">
        <div class="col">Starting Table</div>
        <div class="col-4">
          <input type="number" class="form-control" [(ngModel)]="eventData.startingTable">
        </div>
      </div>

    </div>


  </div>


  <div class="modal-footer">
    <div class="text-red">{{ formWarning }}</div>
    <button type="button" class="btn btn-main" (click)="createNewEvent()" *ngIf="!editMode"
      [disabled]="disableFormSubmit">Create</button>
    <button type="button" class="btn text-red" (click)="destroySelf()" *ngIf="editMode">Cancel</button>
    <button type="button" class="btn btn-main" (click)="updateEvent()" *ngIf="editMode"
      [disabled]="disableFormSubmit">Update</button>
  </div>
</ng-container>

<ng-template #tooltipper>
  <span class="text-secondary">Press <span class="badge text-bg-dark text-monospaced">Esc</span> to close</span>
</ng-template>
<div class="modal-wrapper"
  #tooltip="ngbTooltip"
  [ngbTooltip]="tooltipper"
  [autoClose]="false"
  container="body"
  triggers="manual"
  (mouseenter)="tooltip.open()">

  <div class="modal-header d-flex align-items-center">
    <span class="text-larger flex-grow-1">Purchase History</span>
    <div class="btn btn-sm btn-blank text-secondary" (click)="showPurchases = !showPurchases; ">{{ showPurchases ? 'Manage subscriptions' : 'Purchase history' }}</div>
  </div>

  <div class="modal-body p-0 d-flex flex-column">

    <ng-container *ngIf="showPurchases">
      <ng-container *ngIf="charges$ | async as charges; else loading">

        <ng-container *ngIf="charges.length > 0; else noCharges">

          <div class="purchase d-flex align-items-center px-3" *ngFor="let charge of charges">
            <div class="pe-2 py-2 text-monospace text-secondary">
              <app-timestamp-clock [timestamp]="charge.date" [seconds]="true"></app-timestamp-clock>
            </div>
            <div class="pe-2 py-2 text-monospace text-secondary me-2" [style.minWidth.rem]="10">{{ charge.productType }}</div>
            <div class="pe-2 py-2 flex-grow-1">{{ charge.productText }}</div>


            <div class="px-3" *ngIf="charge.refunded">
              <div class="badge text-bg-danger text-monospace text-uppercase">
                <span class="me-1">Refunded</span><fa-icon [icon]="refundedIcon" [fixedWidth]="true"></fa-icon>
              </div>
            </div>

            <div class="px-3" *ngIf="charge.partialRefund">
              <div class="badge text-bg-primary text-monospace text-uppercase" ngbTooltip="{{charge.amountRefunded}}{{charge.currency}} refund was initiated" container="body">
                <span class="me-1">Partial refund</span><fa-icon [icon]="infoIcon" [fixedWidth]="true"></fa-icon>
              </div>
            </div>


            <div class="pe-1 py-2">{{ charge.amount }}</div>
            <div class="pe-2 py-2">{{ charge.currency }}</div>
            <div class="pe-2 py-2">
              <a [href]="charge.receipt" target="_blank">
                <fa-icon [icon]="receiptIcon" [fixedWidth]="true" *ngIf="charge.hasReceipt"></fa-icon>
              </a>
            </div>
          </div>

        </ng-container>

        <ng-template #noCharges>
          <div class="p-3 text-secondary">You have no purchase history</div>
        </ng-template>

      </ng-container>
    </ng-container>

    <ng-container *ngIf="!showPurchases">
      <ng-container *ngIf="subscriptions$ | async as subscriptions; else loading">

        <ng-container *ngIf="subscriptions.length > 0; else noSubscriptions">

          <table>

            <thead>
              <tr class="border-bottom border-secondary">
                <td class="p-2">Subscription</td>
                <td class="p-2">Status</td>
                <td class="p-2">Amount</td>
                <td class="p-2">Started</td>
                <td class="p-2"></td>
              </tr>
            </thead>

            <tbody>
              <tr class="subscription-row" *ngFor="let sub of subscriptions">
                <td class="p-2">
                  <span class="text-secondary text-small me-1">Platform Support:</span>
                  <span class="flex-grow-1">{{ sub.tierName }}</span>
                </td>
                <td class="p-2">
                  <div class="badge text-monospace text-uppercase" [ngClass]="{ 'text-bg-secondary': !sub.isActive, 'text-bg-success': sub.isActive }">
                    {{ sub.status }}
                  </div>
                  <ng-container *ngIf="sub.isPaused">
                    <ng-template #pausTooltip>
                      Paused until <app-timestamp-clock [timestamp]="sub.pausedUntil" [seconds]="true"></app-timestamp-clock>
                    </ng-template>
                    <div class="ms-1 badge text-bg-secondary text-monospace text-uppercase" [ngbTooltip]="pausTooltip" container="body">
                      <fa-icon [icon]="pauseIcon" [fixedWidth]="true"></fa-icon>
                      <span class="ms-1">Paused</span>
                    </div>
                  </ng-container>
                </td>
                <td class="p-2">
                  <span>{{ sub.tierAmount }}</span>
                  <span class="text-secondary ms-1">{{ sub.tierCurrency }}</span>
                  <span class="text-secondary text-small">/{{ sub.tierInterval }}</span>
                </td>
                <td class="p-2 text-small text-secondary">
                  <app-timestamp-clock [timestamp]="sub.startDate" [seconds]="true"></app-timestamp-clock>
                </td>
                <td class="p-2 subscription-action">
                  <div ngbDropdown container="body">
                    <button class="btn btn-sm btn-blank" id="subActionMenu" ngbDropdownToggle>
                      <fa-icon [icon]="actionIcon" [fixedWidth]="true"></fa-icon>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="subActionMenu">
                      <button ngbDropdownItem (click)="resumeSubscription(sub.id)" *ngIf="sub.isPaused">Resume</button>
                      <button ngbDropdownItem (click)="pauseSubscription(sub.id)" *ngIf="!sub.isPaused">Pause</button>
                      <div class="my-1 border-bottom border-secondary"></div>
                      <button ngbDropdownItem (click)="cancelSubscription(sub.id)" class="text-danger">Cancel</button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

          </table>

        </ng-container>

        <ng-template #noSubscriptions>
          <div class="p-3 text-secondary">You have no ongoing subscriptions</div>
        </ng-template>

      </ng-container>
    </ng-container>


    <ng-template #loading>
      <div class="d-flex align-items-center p-5 justify-content-center">
        <app-busy-spinner></app-busy-spinner>
      </div>
    </ng-template>

  </div>

  <div class="modal-footer">
    <div class="btn btn-sm btn-blank" (click)="close()">Close</div>
  </div>

</div>

<ng-template let-modal #pauseUntilSelector>
  <div class="modal-body justify-content-center text-center">
    <div class="text-large mb-2">Pause until?</div>
    <ngb-datepicker class="no-border" [(ngModel)]="pauseUntilmodel"></ngb-datepicker>
  </div>
  <div class="modal-footer p-2">
    <div class="btn btn-sm btn-blank text-secondary" (click)="modal.dismiss()">Cancel</div>
    <div class="btn btn-sm btn-blank" (click)="modal.close()">Pause</div>
  </div>
</ng-template>
<ng-template let-modal #cancelConfirmation>
  <div class="modal-header">Cancel Subscription?</div>
  <div class="modal-body">
    You are about to cancel your subscription, are you sure?
  </div>
  <div class="modal-footer p-2 d-flex align-items-center">
    <div class="btn btn-sm btn-blank text-secondary" (click)="modal.close()">Cancel Subscription</div>
    <div class="flex-grow-1"></div>
    <div class="btn btn-sm btn-blank" (click)="modal.dismiss()">No</div>
  </div>
</ng-template>

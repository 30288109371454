import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TolariaWysiwygComponent } from '../tolaria-wysiwyg/tolaria-wysiwyg.component';
import { TolariaWysiwygMention, TolariaWysiwygOutput } from '../tolaria-wysiwyg/tolaria-wysiwyg.interfaces';
import { MessageSenderService } from '../../services/message-sender.service';
import { MessageGroupsService } from '../../services/message-groups.service';
import { MessageReplyToService } from './message-reply-to.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tolaria-message-form',
  standalone: true,
  imports: [
    CommonModule,
    TolariaWysiwygComponent,
  ],
  template: `
  <tolaria-wysiwyg
    (deltaComplete)="onDeltaCompleted($event)"
    [replyTo$]="replyTo.current$"
    [mentionList]="mentionList"
    [messageGroupDocId]="messageGroupId"
    [composer]="true"
    [richText]="extendedFormat"
    [debug]="true"
  ></tolaria-wysiwyg>
  `,
  styleUrls: ['./tolaria-message-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TolariaMessageFormComponent {
  @Input() spectatorMode: boolean = false
  @Input() matchChat: boolean = false
  @Input() matchDocId: string = ''
  @Input() extendedFormat: boolean = false
  @Input() set messageGroupDocId(id: string) {
    console.log('TolariaMessageFormComponent:: messageGroupDocId emitted ->', id)
    this.messageGroupId = id
    this.updateMentionList()
  }

  public messageGroupId: string
  public mentionList: TolariaWysiwygMention[] = []


  constructor(
    private readonly sender: MessageSenderService,
    private readonly groupService: MessageGroupsService,
    public readonly replyTo: MessageReplyToService,
  ) {}

  private async updateMentionList() {
    this.mentionList = await this.groupService.getMentionList(this.messageGroupId)
    console.log('TolariaMessageFormComponent:: updateMentionList -> mention list ->', this.mentionList)
  }

  public onDeltaCompleted(output: TolariaWysiwygOutput): void {
    let options = {
      messageGroupDocId: this.messageGroupId,
      html: output.html,
      message: output.message,
      delta: output.delta,
      whisperMode: output.whisperMode,
      mentionedPlayerDocIds: null,
      images: output.images,
      spectatorMode: this.spectatorMode,
      matchChat: this.matchChat,
      matchDocId: this.matchDocId,
      replyTo: output.replyTo,
    }

    if (output.mentionedPlayers.length > 0) {
      options.mentionedPlayerDocIds = output.mentionedPlayers
    }
    this.sender.send(options)
  }

}

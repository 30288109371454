import { firstValueFrom, BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Component, Input, OnInit } from '@angular/core';
import { IEventDetails, IEventLog } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-event-to-log',
  templateUrl: './event-to-log.component.html',
  styleUrls: ['./event-to-log.component.css']
})
export class EventToLogComponent implements OnInit {
  @Input() eventDocId: string

  eventLog$: BehaviorSubject<IEventLog[]> = new BehaviorSubject<IEventLog[]>(null)

  constructor (
    private firestore: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.getLog()
  }

  private async getLog() {

    const eventDoc = await firstValueFrom(this.firestore.collection('events').doc<IEventDetails>(this.eventDocId).get())

    const doc = eventDoc.data()

    const eventLog = await firstValueFrom(this.firestore.collection('events').doc(this.eventDocId).collection('log').get())

    let log = []
    if (!eventLog.empty) {
      log = eventLog.docs.map(i => i.data())
    }

    const totalLog: IEventLog[] = [...doc.log, ...log].sort((a, b) => b.timestamp - a.timestamp)

    this.eventLog$.next(totalLog)

  }

}

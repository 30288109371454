<div class="w-100 mb-3 p-2" *ngIf="globals.isMobile">
  <div class="btn btn-sm btn-main w-100" routerLink="deckster">Launch Deckster</div>
</div>

<div class="card-group" style="max-height: 100%; overflow-y: auto;" *ngIf="decks$ | async as decks">
  <div class="card deck-photo m-1" *ngFor="let deck of decks">
    <div class="card-header p-0">
      <img [src]="deck.deckPhotoUrl">
    </div>
    <div class="card-body">
      {{ deck.rating }}
    </div>
  </div>
</div>

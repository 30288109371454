import { MatchService } from 'src/app/services/match.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IEventDetails, IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, Input } from '@angular/core';
import { faPlus, faMinus, faSquare, faCheckSquare, faTimes, faVideo, faCopy } from '@fortawesome/free-solid-svg-icons';

import { DialogsService, GlobalsService } from 'src/app/services';
import { EventService } from 'src/app/services';
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces';

@Component({
  selector: 'app-event-report-slip',
  templateUrl: './event-report-slip.component.html',
  styleUrls: ['./event-report-slip.component.css']
})
export class EventReportSlipComponent {
  @Input() selectedRound: string;
  @Input() activeRound: string;
  @Input() matchDoc: IMatchData;
  @Input() public event: IEventDetails;

  faPlus = faPlus;
  faMinus = faMinus;
  faSquare = faSquare;
  faCheckSquare = faCheckSquare;
  faTimes = faTimes;
  faVideo = faVideo;
  faCopy = faCopy;

  isWaitingResponse = false;

  constructor(
    public es: EventService,
    private dialogs: DialogsService,
    public globals: GlobalsService,
    private activeModal: NgbActiveModal,
    private matchService: MatchService,
  ) { }

  openVideoRoom() {
    this.matchService.openMatchRoom(this.matchDoc);
  }
  setMatchResult(
    isDraw: boolean,
    player1wins: boolean,
    player2wins: boolean,
    player1gamewins: number,
    player2gamewins: number,
    drawnGames: number,
    player1drop: boolean,
    player2drop: boolean
  ) {
    if (!this.matchDoc.isByeMatch && !this.matchDoc.isLossMatch) {
      this.matchDoc.isDraw = isDraw;

      this.matchDoc.player1.isWinner = player1wins;
      this.matchDoc.player2.isWinner = player2wins;

      this.matchDoc.player1.wins = player1gamewins;
      this.matchDoc.player2.wins = player2gamewins;

      this.matchDoc.player1.losses = player2gamewins;
      this.matchDoc.player2.losses = player1gamewins;

      this.matchDoc.player1.draws = drawnGames;
      this.matchDoc.player2.draws = drawnGames;

      this.matchDoc.player1.drop = player1drop;
      this.matchDoc.player2.drop = player2drop;
    }
  }

  changeDrawnGames(operator: string) {
    if (!this.matchDoc.isByeMatch && !this.matchDoc.isLossMatch) {
      if (operator === 'decrease') {
        if (this.matchDoc.player1.draws > 0) {
          this.matchDoc.player1.draws = this.matchDoc.player1.draws - 1;
          this.matchDoc.player2.draws = this.matchDoc.player2.draws - 1;
        }
      }
      if (operator === 'increase') {
        this.matchDoc.player1.draws = this.matchDoc.player1.draws + 1;
        this.matchDoc.player2.draws = this.matchDoc.player2.draws + 1;
      }
    }
  }

  reportMatchResult(update: boolean = false) {
    console.log('update, ', update + ' selected: ' + this.selectedRound + ' active:' + this.activeRound);
    if (update && this.selectedRound < this.activeRound) {
      this.dialogs.openDialog(
        'Update Result?',
        'You are about to update a result from a previous round, are you sure? \n(this will also initiate a stats recalculation for all players)',
        'ConfirmCancel'
      ).then((dialogResponse: any) => {
        console.log('Dialog Response, ', dialogResponse);
        if (dialogResponse.pressed !== 'cancel') {
          console.log('reporting');
          this.isWaitingResponse = true;
          this.es.reportMatchResult(this.matchDoc)
            .then((response: IPromiseResponse) => {
              this.isWaitingResponse = false;
              this.es.calculateStats(this.event).then((respons: IPromiseResponse) => {
                this.globals.isBusy.message = respons.text;
                // show message, error message persist longer
                if (respons.status) {
                  setTimeout(() => { this.globals.isBusy.status = false; }, 1000);
                }
                else {
                  setTimeout(() => { this.globals.isBusy.status = false; }, 3000);
                }
              });
              if (response.status) {
                this.activeModal.close();
              }
              else {
                // input message toast here
                console.log(response.text);
              }
            });
        }
      });
    }
    else {

      console.log('reporting');
      this.isWaitingResponse = true;
      this.es.reportMatchResult(this.matchDoc)
        .then((response: IPromiseResponse) => {

          // if group match, calculate the stats
          if (this.matchDoc.isType === 'group') {
            this.es.calculateStats(this.event, true).then((res: IPromiseResponse) => {
              console.log(res);
              console.log('status calculated after reported group match result');
            });
          }

          this.isWaitingResponse = false;
          if (response.status) {
            this.activeModal.close();
          }
          else {
            // input message toast here
            console.log(response.text);
          }
        });
    }
  }

  resetMatchData() {
    this.matchDoc.isReported = false;
    this.matchDoc.isDraw = false;
    this.matchDoc.player1.isWinner = false;
    this.matchDoc.player1.wins = 0;
    this.matchDoc.player1.losses = 0;
    this.matchDoc.player1.draws = 0;
    this.matchDoc.player1.drop = false;
    this.matchDoc.player2.isWinner = false;
    this.matchDoc.player2.wins = 0;
    this.matchDoc.player2.losses = 0;
    this.matchDoc.player2.draws = 0;
    this.matchDoc.player2.drop = false;
    this.es.resetMatchResult(this.matchDoc);
  }

  invalidReportSlip(): boolean {
    // check if we have a winner
    if (
      this.matchDoc.isDraw === false &&
      this.matchDoc.player1.isWinner === false &&
      this.matchDoc.player2.isWinner === false
    ) {
      return true;
    }

    // check if results are reported
    if (
      this.matchDoc.player1.wins === 0 &&
      this.matchDoc.player1.losses === 0 &&
      this.matchDoc.isDraw === false
    ) {
      return true;
    }

    // all seems good form is NOT invalid
    return false;

  }

  public get showDropOption(): boolean {
    return this.matchDoc.isType === 'swiss' || this.matchDoc.isType === 'batch';
  }

}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import Card from 'scryfall-client/dist/models/card';
import { CardSearchService } from 'src/app/services/card-search.service';

@Component({
  selector: 'app-card-search-select',
  templateUrl: './card-search-select.component.html',
  styleUrls: ['./card-search-select.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ]
})
export class CardSearchSelectComponent {
  @Input() styleClass: string = ''
  @Input() modal: boolean = false
  @Input() showResponseSelector: boolean = false
  @Output() cardSelected = new EventEmitter<Card>()

  public searchString: string = ''
  public searchResult$ = new BehaviorSubject<Card[]>([])
  public insertRules: boolean = false
  private selectedCard: Card

  constructor(
    private readonly cardSearch: CardSearchService,
    private readonly modalService: NgbActiveModal,
  ) { }

  public async search() {

    let result = await this.cardSearch.getCardsByString(this.searchString)
    console.log('CardSearchSelectComponent:: result from card search ->', result)
    result = result
      .filter(i => !i.digital && !i.oversized && i.image_uris && i.image_uris.art_crop !== undefined)
      .sort((a, b) => a.released_at.localeCompare(b.released_at))
      .sort((a, b) => a.name.localeCompare(b.name))
    this.searchResult$.next(result)

  }

  public onCardSelected(card: Card): void {
    this.selectedCard = card
    this.cardSelected.emit(card)
    let response: CardResponse = { card: card, rules: this.insertRules }
    this.modalService.close(response)
  }

  public onCancelPress(): void {
    this.modalService.dismiss()
  }

  public onConfirmPress(): void {
    let response: CardResponse = { card: this.selectedCard, rules: this.insertRules }
    this.modalService.close(response)
  }

  public get hasResults(): boolean {
    return this.searchResult$.getValue().length > 0
  }

  
}

export interface CardResponse {
  card: Card
  rules: boolean
}

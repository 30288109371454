import { take, takeUntil } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { faTrophy, faEye, faEyeSlash, faSearch, faTimes, faUsersSlash, faUsers, faExpand } from '@fortawesome/free-solid-svg-icons';
import { AuthService, GlobalsService } from 'src/app/services';
import { IEventDetails, IEventPlayerDetails, IMatchData } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-event-main-view',
  templateUrl: './event-main-view.component.html',
  styleUrls: ['./event-main-view.component.css']
})
export class EventMainViewComponent implements OnInit, OnDestroy {
  @Input() eventDoc$: Observable<IEventDetails>;
  @Input() eventPlayers$: Observable<IEventPlayerDetails[]>;
  @Input() eventMatches$: Observable<IMatchData[]>;
  @Input() eventDocId: string;
  @Input() isOrganizer: boolean;

  localEventDoc: IEventDetails;
  showStandings = false;
  showDroppedPlayers = false;
  showBracket = false;
  showReportedMatches = true;
  showTeamPlayerMatches = true;
  extendSearchBar = false;
  selectedRound: number;
  selectedGroup: number;
  pairingsFilter = '';
  showPairingsView = false;

  faTrophy = faTrophy;
  faEyeSlash = faEyeSlash;
  faEye = faEye;
  faSearch = faSearch;
  faTimes = faTimes;
  faTeamsGroup = faUsersSlash
  faTeamsUngroup = faUsers
  faExpand = faExpand

  isGroupEvent$ = new BehaviorSubject<boolean>(false);

  public eventDocument$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  public componentDestroyed$: Subject<boolean> = new Subject<boolean>()

  public closePairingsView(value: boolean): void {
    this.showPairingsView = value
    document.exitFullscreen()
  }

  groupNames: Array<string> = '-ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  constructor(public auth: AuthService, public globals: GlobalsService) {}

  ngOnInit() {
    this.eventDoc$.pipe(takeUntil(this.componentDestroyed$)).subscribe(event => {
      this.isGroupEvent$.next(event.details.structure.isGroup);
      this.eventDocument$.next(event)

      if (!this.localEventDoc) {
        this.selectedGroup = 1;
        this.selectedRound = event.activeRound;
      }
      if (event.statusCode === 7) {
        this.showBracket = true;
      }

    });
  }

  ngOnDestroy(): void {
      this.componentDestroyed$.next(true)
  }

  newArray(num: number): Array<number> {
    const res = [];
    for (let i = 1; i <= num; i++) {
      res.push(i);
    }

    return res;
  }

  public get isAdmin(): boolean {
    return this.auth.user.role === 'admin';
  }

  public get isSwissTeamEvent(): boolean {
    return this.eventDocument$.getValue().details.structure.swiss?.teams
  }

}

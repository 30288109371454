<ng-container *ngIf="modal; else info">

  <div class="modal-header d-flex align-items-center">
    <div class="text-large flex-grow-1">Stripe connected account for organizers</div>
    <button class="btn-close" (click)="onClose()"></button>
  </div>

  <div class="modal-body d-flex flex-column rounded rounded-4 gap-3">
  
    <div class="pe-4">
      <ng-container [ngTemplateOutlet]="info"></ng-container>
    </div>
  
  </div>

  <div class="modal-footer"></div>

</ng-container>


<ng-template #info>

  <div class="h4">Accept Payments</div>
  <p>
    Tolaria.app is integrated with the <a href="https://stripe.com/en-se" target="_blank"><span
        class="text-bold">stripe</span></a> payment infrastructure and
    has a so-called <i>platform account</i>.<br>
    As an organizer, you can sign up for a <i>connected account</i> and accept payments for your services, such as
    registration fees.
  </p>
  
  <div class="h4 mt-3">Connected Account</div>
  <p>
    With a connected account, you unlock features such as accepting registration fees directly through
    Tolaria.app<br>
    You can either use the integrated checkout system for card payments, or manually <i>handle</i> them externally.
  </p>
  <p>
    After your connected account has successfully been created and onboarded, payments will be routed directly to
    your <span class="text-bold">stripe</span> connected
    account and payouts to your bank account will follow automatically. Any <i>fees</i> connected to the payments
    will be subtracted before the funds are
    moved to your connected account.
  </p>
  
  <div class="h4 mt-3">Handle Payments</div>
  <p>
    Payments can be handled automatically with the built-in <span class="text-bold">stripe</span> checkout system,
    or you can manually handle them.
    For manual handling, you simply <i>mark</i> registration fees and such as paid with a set of predefined methods.
    They will appear as paid and you will be able to <i>issue refunds</i>.
  </p>
  
  <div class="h4 mt-3">Fees</div>
  <p>
    Unfortunately, not many things are free and <span class="text-bold">stripe</span> charges a fee for every card
    transaction, just as any other payment solution out there.
    In addition to fees for charging debit and credit cards, there is also a currency exchange rate when payments
    are handled in other currencies.
    With Tolaria Payment I will try my very best to offer a fee-less solution in the end. However, I need to start
    somewhere and unfortunately that means charging a fee in the beginning.
  </p>
  <p>
    For the sake of simplicity, I will add a <span class="text-orange">fixed fee of 5%</span>. This will cover the
    basic costs of the stripe payment system, as well as losses after refunds are issued.
  </p>
  <p class="m-4">
    <img class="w-100" src="assets/payment-flow-01.png">
  </p>
  <p>
    For the latest updated prices that <b>stripe</b> charges the Tolaria <i>platform account</i>, see <a
      href="https://stripe.com/en-se/pricing" target="_blank">https://stripe.com/en-se/pricing#pricing-details</a>
  </p>
  
  <div class="h4 mt-3">Issue Refunds</div>
  <p>
    Issuing refunds are as simple as accepting payments. If the user has paid with <b>Tolaria Payment</b> (stripe
    integrated checkout) there will be an automated
    flow. When the refund has successfully completed all steps, the payment will be marked as refunded.<br>
    For manual handling, you simply <i>mark</i> the payment as refunded as you manually need to handle this with
    your desired external methods.
  </p>
  <p class="m-4">
    <img class="w-100" src="assets/payment-flow-02.png">
  </p>
  <p>
    <b class="text-red">NOTE: Fees connected to the payment will still apply for the Tolaria <i>platform
        account</i>.</b>
  </p>

</ng-template>
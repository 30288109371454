import { NgbDate } from '@ng-bootstrap/ng-bootstrap'
import { IMatchAppointment, IMatchAvailabilityMeta } from 'tolaria-cloud-functions/src/_interfaces'

export interface ITimeSlot {
    text: string
    date: NgbDate
    from: Date
    to: Date
    timestampFrom: number
    timestampTo: number
    selected: boolean
    status: 'Free' | 'Busy' | 'Tentiative'
    bothHave?: boolean
}

export function mapAvailabilityToTimeSlots(availabilityMeta: IMatchAvailabilityMeta[], appointments: IMatchAppointment[]): ITimeSlot[] {

    // first, get new availability range taking the appointments into consideration
    // const availabilities = availabilityMeta.map(i => splitAvailability(i, appointments.filter(i => !i.isCancelled)).flat()).flat()

    // create the return array
    const appointmentTimeSlots: ITimeSlot[] = []

    // loop through all the appointments and create the time slots needed
    for (const appointment of appointments.filter(i => !i.isCancelled)) {

        // Convert timestamps to Date objects
        const from = new Date(appointment.timestampFrom * 1000)
        const to = new Date(appointment.timestampTo * 1000)

        // Initialize the current time slot start time as the availability start time
        let currentSlotStart = new Date(from)

        // Initialize the current time slot start time as the availability start time
        let currentSlotEnd = new Date(to)

        // Format time slots text as 'HH:mm - HH:mm'
        const startTime = currentSlotStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        const endTime = currentSlotEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        const timeSlotText = `${startTime} - ${endTime}`

        // Create a time slot with formatted text
        const timeSlot: ITimeSlot = {
            text: timeSlotText,
            date: new NgbDate(currentSlotStart.getFullYear(), currentSlotStart.getMonth() + 1, currentSlotStart.getDate()),
            from: new Date(currentSlotStart),
            to: new Date(currentSlotEnd),
            timestampFrom: appointment.timestampFrom,
            timestampTo: appointment.timestampTo,
            selected: false,
            status: appointment.isCancelled || appointment.isRejected
                ? 'Free'
                : appointment.isAccepted && !appointment.isCancelled
                    ? 'Busy'
                    : 'Tentiative',
        }

        appointmentTimeSlots.push(timeSlot)

    }

    const availabilityTimeSlots: ITimeSlot[] = []

    // loop through all availabilities to create time slots without any overlap
    for (const availability of availabilityMeta) {

        // Convert timestamps to Date objects
        const from = new Date(availability.timestampFrom * 1000)
        const to = new Date(availability.timestampTo * 1000)
    
        // Initialize the current time slot start time as the availability start time
        let currentSlotStart = new Date(from)
    
        // Calculate the slot duration in milliseconds (60 minutes)
        const slotDuration = 60 * 60 * 1000
    
        // Create time slots at 15-minute intervals within the availability range
        while ((currentSlotStart.getTime() + (60 * 60 * 1000)) <= to.getTime()) {
            
            const currentSlotEnd = new Date(+currentSlotStart + slotDuration)
    
            // ensure the current slot does not exceed the availability end time
            if (currentSlotEnd > to) {
                currentSlotEnd.setTime(to.getTime())
            }
    
            // format time slots text as 'HH:mm - HH:mm'
            const startTime = currentSlotStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            const endTime = currentSlotEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            const timeSlotText = `${startTime} - ${endTime}`
    
            // create a time slot with formatted text
            const timeSlot: ITimeSlot = {
                text: timeSlotText,
                date: new NgbDate(currentSlotStart.getFullYear(), currentSlotStart.getMonth() + 1, currentSlotStart.getDate()),
                from: new Date(currentSlotStart),
                to: new Date(currentSlotEnd),
                timestampFrom: currentSlotStart.getTime(),
                timestampTo: currentSlotEnd.getTime(),
                selected: false,
                status: 'Free',
            }

            // initialize a flag to check for overlap
            let overlaps = false

            // check if time slot conflicts with an existing one
            for (const slot of appointmentTimeSlots) {
                // check if start time overlaps
                if (currentSlotStart.getTime() > slot.from.getTime() && currentSlotStart.getTime() < slot.to.getTime()) {
                    overlaps = true
                    break
                }
                // check if end time overlaps
                if (currentSlotEnd.getTime() > slot.from.getTime() && currentSlotEnd.getTime() < slot.to.getTime()) {
                    overlaps = true
                    break
                }
                // check if matching time slot
                if (currentSlotStart.getTime() === slot.from.getTime() && currentSlotEnd.getTime() === slot.to.getTime()) {
                    overlaps = true
                    break
                }
            }
    
            // Add the time slot to the array
            if (!overlaps) {
                availabilityTimeSlots.push(timeSlot)
            }
    
            // Move to the next time slot start time (15-minute interval)
            currentSlotStart.setTime(currentSlotStart.getTime() + 15 * 60 * 1000)
        }
    
    }
        
    return [...appointmentTimeSlots, ...availabilityTimeSlots]

}


function splitAvailability(availability: IMatchAvailabilityMeta, appointments: IMatchAppointment[]): IMatchAvailabilityMeta[] {
    const result: IMatchAvailabilityMeta[] = []

    for (const appointment of appointments) {
        if (appointment.timestampFrom >= availability.timestampTo || appointment.timestampTo <= availability.timestampFrom) {
            // Appointment does not overlap with availability, skip it
            continue
        }
        // Create a new availability before the appointment
        if (appointment.timestampFrom > availability.timestampFrom) {
            let newAvailability = JSON.parse(JSON.stringify(availability))
            newAvailability.timestampFrom = availability.timestampFrom
            newAvailability.timestampTo = appointment.timestampFrom
            newAvailability.split = true
            result.push(newAvailability)
        }
        // Create a new availability after the appointment
        if (appointment.timestampTo < availability.timestampTo) {
            let newAvailability = JSON.parse(JSON.stringify(availability))
            newAvailability.timestampFrom = appointment.timestampTo
            newAvailability.timestampTo = availability.timestampTo
            newAvailability.split = true
            result.push(newAvailability)
        }
        availability.split = true
    }

    // If the original availability was not split by any appointment, add it to the result
    if (!availability.split) {
        availability.split = false
        result.push(availability)
    }

    return result

}


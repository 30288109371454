import { AuthService } from '../../../../../services/auth.service';
import { tiDoubleElimination } from '../../../../../../assets/font-icons/tolaria-double-elimination';
import { tiSingleElimination } from '../../../../../../assets/font-icons/tolaria-single-elimination';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, finalize, Subject, takeUntil } from 'rxjs';
import { IEventDetails, IFormat, IReprintPolicy, IRuleset } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, Input, OnInit, EventEmitter, Output, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { tiDateTime } from 'src/assets/font-icons/date-time';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DateTimePickerComponent } from '../date-time-picker/date-time-picker.component';
import { IStructure, TournamentConfigService } from '../../services/tournament-config.service';
import { faVideo, faEnvelopeOpenText, faEye, faCamera, faListAlt, faUserSlash, faCheckDouble,
  faHashtag, faStopwatch, faChair, IconDefinition, faUsers, faCoins, faHandHoldingHeart, faTag, faSitemap, faTrophy, faTv, 
  faCashRegister,
  faUtensils} from '@fortawesome/free-solid-svg-icons';
import { tiUserNumber } from 'src/assets/font-icons/user-number';
import { applicationIcon } from 'src/assets/font-icons/tolaria-icon';
import { ProductType } from 'tolaria-cloud-functions/src/_interfaces';
import { PaymentService } from 'src/app/payment/payment.service';
import { ConfigService } from 'src/app/services/config.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { StripeConnectedAccountInfoComponent } from 'src/app/payment/stripe-connected-account/stripe-connected-account-info/stripe-connected-account-info.component';

interface IEventSetting {
  icon: IconDefinition
  title: string
  description: string
  property: string
  value: boolean | number | string
  dataType: 'number' | 'boolean'
  enabled: boolean
  inverted?: boolean
  min?: number
  max?: number
}

@Component({
  selector: 'app-event-document-form',
  templateUrl: './event-document-form.component.html',
  styleUrls: ['./event-document-form.component.css']
})
export class EventDocumentFormComponent implements OnInit, OnDestroy {
  @Input() showValidationInForm: boolean = false
  @Input() set eventDocument(event: IEventDetails) {

    console.log('[EventDocumentFormComponent] --> emitted new eventDocument')

    const clientTimezoneOffset = this.datePipe.transform(new Date(), 'OOOO').replace('GMT', '')

    const rawEvent: IEventDetails = JSON.parse(JSON.stringify(event))

    this.showTimezoneOffsetSelection = rawEvent.details.GMT_offset !== clientTimezoneOffset
    this.overrideTimezoneOffset = rawEvent.details.GMT_offset !== clientTimezoneOffset

    this.selectedStructureName = this.tournamentConfig.getEventStructure(rawEvent.details.structure).name
    this.selectedStructure = this.tournamentConfig.getEventStructure(rawEvent.details.structure)


    // check for missing properties
    if (!rawEvent.details?.registrationFee) {
      rawEvent.details.registrationFee = {
        active: false,
        tolariaPayment: true,
        forcePaymentWhenAttending: true,
        charityExtra: true,
        amount: null,
        currency: this.auth.user?.stripe?.default_currency ? this.auth.user.stripe.default_currency : null,
        productId: `registrationFee_${rawEvent.docId}`,
        productType: ProductType.REGISTRATION_FEE,
      }
    }
    if (!rawEvent.details?.registrationFee?.currency === null && this.auth.user?.stripe?.default_currency) {
      rawEvent.details.registrationFee.currency = this.auth.user.stripe.default_currency
    }
    if (rawEvent.details.provideFood === undefined) {
      rawEvent.details.provideFood = false
    }

    // initialize settings
    this.initSettings(rawEvent)

    // update event document observer
    this.event$.next(rawEvent)

    // init currency conversion and minimum registration fee in not already present
    if (this.exchangeRatesLoaded$.getValue() === null) {
      this._initPayment(rawEvent)
    }

  }
  @Output() documentChanged = new EventEmitter

  @ViewChild('eventStart', { static: false }) eventStartInput: ElementRef<any>
  @ViewChild('eventEnd', { static: false }) eventEndInput: ElementRef<any>
  @ViewChild('eventRegistrationOpen', { static: false }) eventRegistrationOpenInput: ElementRef<any>
  @ViewChild('eventRegistrationClose', { static: false }) eventRegistrationCloseInput: ElementRef<any>

  public event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  public settings$: BehaviorSubject<IEventSetting[]> = new BehaviorSubject<IEventSetting[]>(null)

  public calendar = tiDateTime
  public playoff = faSitemap
  public standings = faTrophy
  public teams = faUsers
  public singleElim = tiSingleElimination
  public doubleElim = tiDoubleElimination
  public amount = faTag
  public coins = faCoins
  public charity = faHandHoldingHeart
  public tolaria = applicationIcon
  public utensils = faUtensils

  public dateReference = new Date()

  public showLocationExtra: boolean = false

  public showTimezoneOffsetSelection: boolean = false
  public overrideTimezoneOffset: boolean = false

  public selectedStructureName: 'Swiss' | 'Group' | 'Batch' | 'Bracket' | 'Round Robin' = null
  public selectedStructure: IStructure = null

  private destroyed$ = new Subject<boolean>()

  private exchangeRatesLoaded$ = new BehaviorSubject<boolean>(null)
  private minRegEUR = 10
  public minRegFee = null

  public ckeConfig = JSON.parse(JSON.stringify(this.config.ckeConfig))
  public imageToUpload: string = null
  public editBanner: boolean = false

  constructor(
    private readonly datePipe: DatePipe,
    private readonly modalService: NgbModal,
    private readonly tournamentConfig: TournamentConfigService,
    private readonly auth: AuthService,
    private readonly payment: PaymentService,
    private readonly config: ConfigService,
    private readonly storage: AngularFireStorage,
  ) { }

  ngOnInit(): void {
    console.log('[EventDocumentFormComponent.init] --> showValidationInForm: ', this.showValidationInForm)
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  private _initPayment(rawEvent: IEventDetails): void {
    // get exchange rates as these are needed for the registration fee conversion
    this.payment.exchangeRates$.pipe(takeUntil(this.destroyed$)).subscribe((rates) => {
      if (rates !== null) {
        const currency = rawEvent.details?.registrationFee?.currency
          ? rawEvent.details.registrationFee.currency.toUpperCase()
          : 'SEK'
        this.minRegFee = Math.ceil(this.minRegEUR * rates[currency])
        this.exchangeRatesLoaded$.next(true)

        console.log(`[EventDocumentFormComponent._initPayment] --> default currency ${currency}`)
        console.log(`[EventDocumentFormComponent._initPayment] --> exchange rate ${rates[currency]}`)
        console.log(`[EventDocumentFormComponent._initPayment] --> ${currency} > EUR = ${rawEvent.details.registrationFee.amount / rates[currency]}`)
        console.log(`[EventDocumentFormComponent._initPayment] --> min registration fee ${this.minRegFee} ${currency}`)
      }
    })
  }

  private onEmitForm(): void {
    if (this.event$.getValue() !== null) {
      const event = this.event$.getValue()
      event.eventFormValid = this.validationCheck.valid
      this.documentChanged.emit(event)
    }
  }

  public async onImageCropped(base64: string) {
    console.log('[EventDocumentFormComponent.onImageCropped] --> emitted', base64)
    this.imageToUpload = base64
  }

  public get showImagePreview(): boolean {
    return this.imageToUpload !== null
  }

  public async onUploadEventBanner() {

    const __imageToDataUri = (base64: string, width: number, height: number) => {
      // Create and initialize two canvas
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const canvasCopy = document.createElement('canvas')
      const copyContext = canvasCopy.getContext('2d')

      // Create original image
      const img = new Image()
      img.src = base64

      // Draw original image in second canvas
      canvasCopy.width = img.width
      canvasCopy.height = img.height
      copyContext.drawImage(img, 0, 0)

      // Copy and resize second canvas to first canvas
      canvas.width = width
      canvas.height = height
      ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height)

      return canvas.toDataURL()
    }

    const filePath = 'event-banners/' + this.event$.getValue().docId + '.png'
    console.log('create filePath', filePath)
    const ref = this.storage.ref(filePath)
    console.log('create ref', ref)
    const file = __imageToDataUri(this.imageToUpload, 600, 300)

    // clear temporary image and edit banner flag
    this.imageToUpload = null
    this.editBanner = false

    console.log('create file', file)
    const task = ref.putString(file, 'data_url')
    console.log('crate task', task)
    const done$: Subject<boolean> = new Subject<boolean>()
    task
      .snapshotChanges()
      .pipe(
        takeUntil(done$),
        finalize(() => {
          console.log('task finalized, getting download url')
          const downloadUrl = ref.getDownloadURL()
          // store the download url as the avatar link for both user and player doc.
          downloadUrl.subscribe(async (url) => {
            console.log('downlaod url emitted')

            const event = this.event$.getValue()
            event.bannerUrl = url
            this.event$.next(event)
            this.onEmitForm()

            setTimeout(() => {
              done$.next(true)
            }, 1500)

          })
        })
      ).subscribe()

  }

  public onPropertyChanged(property: string): void {
    console.log(`[TournamentManagementConfigureComponent] --> Property ${property} changed, call delayedUpdate`)

    // check if timezone offset changed
    if (property === 'details.GMT_offset') {
      this.updateDateOnTimezoneChanged()
      return
    }

    // check if timezone overrid was turned off
    if (property === 'override-timezone-offset-off') {
      const event = this.event$.getValue()
      event.details.GMT_offset = this.datePipe.transform(this.dateReference, 'OOOO').substring(3)
      this.updateDateOnTimezoneChanged()
      return
    }

    // output the update event document
    this.onEmitForm()
  }

  private updateDateOnTimezoneChanged(): void {

    const event = this.event$.getValue()

    // update the date strings
    event.details.datetime = (this.datePipe.transform(event.details.datestampFrom, 'YYYY-MM-ddTHH:mm')) + `${event.details.GMT_offset}`
    event.details.datetimeFrom = (this.datePipe.transform(event.details.datestampFrom, this.datePipeFormat)) + ` GMT${event.details.GMT_offset}`
    event.details.datetimeTo = (this.datePipe.transform(event.details.datestampTo, this.datePipeFormat)) + ` GMT${event.details.GMT_offset}`

    // set the new data
    this.event$.next(event)

    // output the update event document
    this.onEmitForm()

  }

  public onDateTimePickerSelect(target: 'eventStart' | 'eventEnd' | 'eventRegistrationStart' | 'eventRegistrationClose'): void {
    // deselect all the inputs using this method to not cause an
    // infinite loop that will open the dialog when closed
    this.eventStartInput.nativeElement.blur()
    this.eventEndInput.nativeElement.blur()
    this.eventRegistrationOpenInput.nativeElement.blur()
    this.eventRegistrationCloseInput.nativeElement.blur()

    // define the options for the dialog to show the date and time picker
    const options: NgbModalOptions = {
      centered: true,
      windowClass: 'tolaria-date-picker'
    }
    const modalRef = this.modalService.open(DateTimePickerComponent, options)

    // get the value depending on the target
    switch (target) {
      case 'eventStart':
        modalRef.componentInstance.timestamp = this.event$.getValue().details.datestampFrom
        break
      case 'eventEnd':
        modalRef.componentInstance.timestamp = this.event$.getValue().details.datestampTo
        break
      case 'eventRegistrationStart':
        modalRef.componentInstance.timestamp = this.event$.getValue().details.registrationOpensTimestamp
        break
      case 'eventRegistrationClose':
        modalRef.componentInstance.timestamp = this.event$.getValue().details.registrationClosesTimestamp
        break
    }
    modalRef.componentInstance.return = 'milliseconds'
    modalRef.componentInstance.displayAs = 'modal'
    modalRef.componentInstance.title = 'Select starting date and time'

    // handle the dialog response
    modalRef.result.then(
      (timestamp: number) => {
        const event = this.event$.getValue()
        switch (target) {
          case 'eventStart':
            event.details.datetime = (this.datePipe.transform(timestamp, 'YYYY-MM-ddTHH:mm')) + `${event.details.GMT_offset}`
            event.details.datestampFrom = timestamp
            event.details.datetimeFrom = this.datePipe.transform(new Date(timestamp).getTime(), this.datePipeFormat) + this.timezoneOffset
            break
          case 'eventEnd':
            event.details.datestampTo = timestamp
            event.details.datetimeTo = this.datePipe.transform(new Date(timestamp).getTime(), this.datePipeFormat) + this.timezoneOffset
            break
          case 'eventRegistrationStart':
            event.details.registrationOpensTimestamp = timestamp
            break
          case 'eventRegistrationClose':
            event.details.registrationClosesTimestamp = timestamp
            break
        }

        // update isMultiDay flag
        if (
          event.details.datestampFrom !== undefined &&
          event.details.datestampFrom !== null &&
          event.details.datestampTo !== undefined &&
          event.details.datestampTo !== null
        ) {
          event.details.isMultiDay = event.details.datestampTo - event.details.datestampFrom > 86400000
        }

        this.event$.next(event)
        // output the update event document
        this.onEmitForm()
      },
      () => {
        console.log('dismissed')
      }
    )
  }

  public onStructureChanged(): void {
    this.selectedStructure = this.tournamentConfig.structures.find(i => i.name === this.selectedStructureName)
    const event = this.event$.getValue()
    event.details.structure.isSwiss = this.selectedStructureName === 'Swiss'
    event.details.structure.isBatch = this.selectedStructureName === 'Batch'
    event.details.structure.isGroup = this.selectedStructureName === 'Group'
    event.details.structure.isBracket = this.selectedStructureName === 'Bracket'
    event.details.structure.isRoundRobin = this.selectedStructureName === 'Round Robin'
    this.event$.next(event)
    this.onEmitForm()
  }

  public onSettingChanged(setting: IEventSetting): void {

    console.log('onSettingChanged -> ', setting)

    // get the event data
    const event = this.event$.getValue()

    // update the property
    event.details[setting.property] = setting.inverted ? !setting.value : setting.value

    // update isOnlineTournament
    event.isOnlineTournament = event.details.isOnlineTournament

    // update event observer
    this.event$.next(event)

    // update settings values
    this.initSettings(event)

    // call the delayed update method
    // output the update event document
    this.onEmitForm()

  }

  public byName(data1: IRuleset | IReprintPolicy | IStructure, data2: IRuleset | IReprintPolicy | IStructure ) {
    if (data1 === undefined || data2 === undefined || data1 === null || data2 === null) return false
    if (data1.name === undefined || data2.name === undefined || data1.name === null || data2.name === null) return false
    return data1.name === data2.name
  }

  public showStripeInfo(): void {
    const modalRef = this.modalService.open(StripeConnectedAccountInfoComponent, {
      centered: false,
      size: 'lg',
    })
    modalRef.componentInstance.modal = true
  }

  public get structures(): string[] {
    return this.tournamentConfig.structureNames
  }

  public get formats(): IFormat[] {
    return this.tournamentConfig.formats
  }

  public get rulesets(): IRuleset[] {
    if (this.event$.getValue() === null) {
      return []
    }
    const selectedFormat = this.formats.find(i => i.name === this.event$.getValue().details.format)
    if (!selectedFormat) {
      return []
    }
    return selectedFormat.ruleSets
  }

  public get reprintPolicies(): IRuleset[] {
    if (this.event$.getValue() === null) {
      return []
    }
    const selectedFormat = this.formats.find(i => i.name === this.event$.getValue().details.format)
    if (!selectedFormat) {
      return []
    }
    return selectedFormat.reprintPolicies
  }

  public get datePipeFormat(): string {
    return 'd MMM, YYYY @ HH:mm'
  }

  public get timezoneOffset(): string {
    if (this.event$.getValue() === null) {
      return ''
    }
    return ` GMT${this.event$.getValue().details.GMT_offset}`
  }

  public get timezoneOffsetList(): string[] {
    return [
      '-11:00',
      '-10:00',
      '-09:00',
      '-08:00',
      '-07:00',
      '-06:00',
      '-05:00',
      '-04:30',
      '-04:00',
      '-03:30',
      '-03:00',
      '-02:00',
      '-01:00',
      '+00:00',
      '+01:00',
      '+02:00',
      '+03:00',
      '+04:00',
      '+04:30',
      '+05:00',
      '+05:30',
      '+05:45',
      '+06:00',
      '+06:30',
      '+07:00',
      '+08:00',
      '+09:00',
      '+09:30',
      '+10:00',
      '+11:00',
      '+12:00',
      '+13:00',
    ]
  }

  public get disabledAfterStart(): boolean {
    if (this.event$.getValue() === null) {
      return true
    }
    return this.event$.getValue().statusCode > 0
  }

  public get validationCheck(): IFormValidation {

    if (this.event$.getValue() !== null) {

      const event = this.event$.getValue()
      const details = event.details
      const now = new Date().getTime()

      // event name
      if (details.name.length < 5) {
        return {
          valid: false,
          failedAt: 'event.details.name',
          validationText: 'Enter a name with at least five (5) characters.'
        }
      }

      // location name
      if (details.location.name.length < 5) {
        return {
          valid: false,
          failedAt: 'event.details.location.name',
          validationText: 'Enter a location with at least five (5) characters.'
        }
      }

      // starting
      if (details.datestampFrom < now) {
        return {
          valid: false,
          failedAt: 'event.details.datestampFrom',
          validationText: 'Startig date and time needs to be in the future.'
        }
      }

      // ending
      if (details.datestampTo < now) {
        return {
          valid: false,
          failedAt: 'event.details.datestampTo',
          validationText: 'Ending date and time needs to be in the future.'
        }
      }
      if (details.datestampTo < details.datestampFrom) {
        return {
          valid: false,
          failedAt: 'event.details.datestampTo',
          validationText: 'Ending date and time needs to be after the starting date and time'
        }
      }

      // registration opens
      if (details.registrationOpensTimestamp > details.datestampFrom) {
        return {
          valid: false,
          failedAt: 'event.details.registrationOpensTimestamp',
          validationText: `The registration can't open after the event has started.`
        }
      }

      // registration closes
      if (details.registrationClosesTimestamp > details.datestampFrom) {
        return {
          valid: false,
          failedAt: 'event.details.registrationClosesTimestamp',
          validationText: `The registration can't close after the event has started.`
        }
      }
      if (details.registrationClosesTimestamp < details.registrationOpensTimestamp) {
        return {
          valid: false,
          failedAt: 'event.details.registrationClosesTimestamp',
          validationText: `The registration can't close before it has opened.`
        }
      }


      // registration fee amount
      if (this.minRegFee !== null) {
        if (details.registrationFee.active && details.registrationFee.amount < this.minRegFee) {
          return {
            valid: false,
            failedAt: 'event.registrationFee.amount',
            validationText: `The registration fee needs to be set to a minimum ${this.minRegFee}${details.registrationFee.currency.toUpperCase()}`
          }
        }
      }

      // type
      if (details.type === '') {
        return {
          valid: false,
          failedAt: 'event.details.type',
          validationText: `Select a valid tournament type. Either "Constructed" or "Limited".`
        }
      }

      // format
      if (details.format === '') {
        return {
          valid: false,
          failedAt: 'event.details.format',
          validationText: `Select a valid tournament format.`
        }
      }

      // ruleset
      if (this.rulesets.length > 0 && details.ruleset.name === '') {
        return {
          valid: false,
          failedAt: 'event.details.ruleset.name',
          validationText: `The selected tournament format demands that you select a ruleset.`
        }
      }

      // reprint policy
      if (this.reprintPolicies.length > 0 && details.reprintPolicy.name === '') {
        return {
          valid: false,
          failedAt: 'event.details.reprintPolicy.name',
          validationText: `The selected tournament format demands that you select a reprint policy.`
        }
      }

      // attendee cap
      if (details.attendeeCap < 4 && details.hasAttendeeCap) {
        return {
          valid: false,
          failedAt: 'event.details.attendeeCap',
          validationText: `With attende cap enabled, you need to specify a minimum number of players. At least four (4) attendees is needed to run an avent.`
        }
      }



    }


    return {
      valid: true,
      failedAt: 'None',
      validationText: 'All good, you are ready to create the tournament event'
    }

  }

  public get validationText(): string {
    return this.validationCheck.validationText
  }

  public get showRegistrationFeeForm(): boolean {
    return this.auth.user?.stripe
      ? this.auth.user.stripe.charges_enabled && this.auth.user.stripe.payouts_enabled
      : false
  }

  public get cashRegister(): IconDefinition {
    return faCashRegister
  }

  private initSettings(event: IEventDetails): void {

    const settings: IEventSetting[] = [
      {
        icon: faVideo,
        title: 'Online',
        description: `Enables the online match interface where players can use their web camera to play out the match.`,
        property: 'isOnlineTournament',
        value: event.details.isOnlineTournament,
        dataType: 'boolean',
        enabled: true,
      },
      {
        icon: faTv,
        title: 'Allow watcher',
        description: `Allow other players to watch ongoing matches being played in Tolaria match rooms.`,
        property: 'allowSpectators',
        value: event.details.isOnlineTournament ? event.details.allowSpectators : false,
        dataType: 'boolean',
        enabled: event.details.isOnlineTournament,
      },
      {
        icon: faEnvelopeOpenText,
        title: 'Invitation only',
        description: `Player will not be able to attend unless invited.`,
        property: 'isPublic',
        value: !event.details.isPublic,
        dataType: 'boolean',
        enabled: true,
        inverted: true,
      },
      {
        icon: faEye,
        title: 'Publicly visible',
        description: `The event will be listed among all other event. If Invitation only, attending the event will only be possible if invited.`,
        property: 'isPubliclyVisible',
        value: event.details.isPubliclyVisible,
        dataType: 'boolean',
        enabled: true,
      },
      {
        icon: faCamera,
        title: 'Deck photo registration',
        description: `Mandatory deck photo registration. This will show an action for the player to perform.`,
        property: 'deckPhoto',
        value: event.details.deckPhoto,
        dataType: 'boolean',
        enabled: true,
      },
      {
        icon: faListAlt,
        title: 'Deck list registration',
        description: `Mandatory deck list registration. This will show an action for the player to perform.`,
        property: 'deckList',
        value: event.details.deckList,
        dataType: 'boolean',
        enabled: true,
      },
      {
        icon: faUserSlash,
        title: 'Drop players on start',
        description: `When event is starts, players without a registered deck will be automatically dropped.`,
        property: 'dropPlayersWithoutSubmittedDeckOnStart',
        value: (event.details.deckList || event.details.deckPhoto) ? event.details.dropPlayersWithoutSubmittedDeckOnStart : false,
        dataType: 'boolean',
        enabled: (event.details.deckList || event.details.deckPhoto) && event.statusCode === 0,
      },
      {
        icon: faEye,
        title: 'Public decks',
        description: `All decks will be public to everyone at all time.`,
        property: 'deckInfoIsPublic',
        value: (event.details.deckList || event.details.deckPhoto) ? event.details.deckInfoIsPublic : false,
        dataType: 'boolean',
        enabled: event.details.deckList || event.details.deckPhoto,
      },
      {
        icon: faCheckDouble,
        title: 'Publish decks when ended',
        description: `At event end, all decks connected to the event will be marked as public.`,
        property: 'publishDecksOnEventEnd',
        value: (event.details.deckList || event.details.deckPhoto)
          ? event.details?.publishDecksOnEventEnd
            ? event.details.publishDecksOnEventEnd
            : false
          : false,
        dataType: 'boolean',
        enabled: event.details.deckList || event.details.deckPhoto,
      },
      {
        icon: tiUserNumber,
        title: 'Attendee cap',
        description: `Restrict the number of player able to attend the event.`,
        property: 'hasAttendeeCap',
        value: event.details.hasAttendeeCap,
        dataType: 'boolean',
        enabled: event.statusCode === 0,
      },
      {
        icon: faHashtag,
        title: 'Cap',
        description: `The maximum number of players that can attend the event.`,
        property: 'attendeeCap',
        value: event.details.attendeeCap,
        dataType: 'number',
        enabled: event.statusCode === 0 && event.details.hasAttendeeCap ? true : false,
        min: 4,
      },
      {
        icon: faStopwatch,
        title: 'Round timer',
        description: `The length of a round. Really only applicable for timed structures such as swiss.`,
        property: 'roundTimer',
        value: event.details.roundTimer,
        dataType: 'number',
        enabled: true,
        min: 0,
      },
      {
        icon: faChair,
        title: 'Starting table',
        description: `Seating and pairings will be starting from this number.`,
        property: 'startingTable',
        value: event.details.startingTable,
        dataType: 'number',
        enabled: true,
        min: 1,
      },
    ]

    this.settings$.next(settings)
  }

  public trackSettingsBy(index: number, item: IEventSetting): string {
    return item.property
  }
}


interface IFormValidation {
  valid: boolean
  failedAt: string
  validationText: string
}

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const pricePodiumIcon: IconDefinition = {
  prefix: 'fac',
  iconName: 'price-podium',
  icon: [
    500, // SVG view box width
    500, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M151.8,468.2c-0.1-1.7-0.2-3.3-0.2-5c0-72,0-144.1,0-216.1c0-12.9,4.6-17.5,17.6-17.5c44,0,88.1,0,132.1,0 c9.9,0,15.3,5.3,15.3,15.2c0,73.6,0,147.1,0,220.7c0,0.9-0.1,1.8-0.2,2.7C261.5,468.2,206.7,468.2,151.8,468.2z M247.8,338.2 c0-5.2,0.1-10.4,0-15.5c-0.2-7.9-5.9-13.7-13.5-13.8c-7.8-0.1-13.8,5.8-13.9,13.9c-0.1,10.2-0.1,20.4,0,30.6 c0.1,7.8,6.1,13.8,13.5,13.9c7.5,0.1,13.7-6,13.9-14C247.9,348.3,247.8,343.2,247.8,338.2z M0,458.1c2.2,4.5,5.5,7.9,10.1,10.1 c38.1,0,76.2,0,114.3,0c-0.1-2-0.2-3.9-0.2-5.9c0-51.2,0-102.4,0-153.6c0-1.8,0-3.5,0-5.8c-2.4,0-4.2,0-6,0 c-33.5,0-67.1,0.1-100.6-0.1C9.1,302.8,3,305.5,0,313.6C0,361.8,0,409.9,0,458.1z M458.2,468.2c7.9-3.7,10.4-9.9,10.2-18.4 c-0.4-19-0.1-38.1-0.1-57.1c0-11-5.1-16.1-16.2-16.1c-34.3,0-68.6,0-102.8,0c-1.6,0-3.2,0.2-5.1,0.3c0,9.3,0,18.1,0,27 c-0.1,21.5-0.1,42.9-0.2,64.4C382,468.2,420.1,468.2,458.2,468.2z M0,468.2c3.4,0,6.7,0,10.1,0C5.5,466,2.2,462.6,0,458.1 C0,461.5,0,464.8,0,468.2z M289,201.9c-36.9,0-73.1,0-109.4,0c-1-11.7-0.6-22.6,8.8-31.3c9.2-8.5,20.6-6.8,31.8-7 c0-11.3,0-22.2,0-33.4c-12-2.9-22.8-8.3-31.6-17.1c-2.6-2.6-5.3-5.2-7.2-8.3c-2.1-3.4-4.5-4.4-8.5-4.4 c-32.9-0.1-59.2-26.3-59.4-59.1c0-2.9,0-5.8,0-8.7c0.2-8.6,5.8-14.3,14.4-14.3c11.9-0.1,23.8,0,35.7,0c1.5,0,3,0,4.8,0 c0-1.8,0-3.2,0-4.5c0.3-8,6-13.7,14.1-13.8C216.8,0,251.2,0,285.7,0c8.4,0,14,5.7,14.3,14c0,1.2,0,2.4,0,4.3c1.8,0,3.4,0,5,0 c11.7,0,23.5-0.1,35.2,0c8.9,0.1,14.8,5.7,14.5,14.6c-0.3,8.2-0.3,16.6-2.4,24.4c-6.8,25.7-30.1,43.1-56.8,43.2 c-3.8,0-6.2,0.9-8.6,4.2c-9,12.8-19.2,19.2-38.9,25.7c0,10.8,0,21.7,0,33.2c4.5,0,8.9,0,13.2,0c15.6,0.2,27.4,11.8,27.7,27.4 C289,194.6,289,198.1,289,201.9z M141.6,45.8c1.1,13.6,14.8,27,26.4,26.2c0-8.7,0-17.5,0-26.2C159,45.8,150.5,45.8,141.6,45.8z M326.7,46.1c-9,0-17.7,0-26.6,0c0,8.9,0,17.6,0,26.2C312.7,72,325.2,59.7,326.7,46.1z'
  ],
} as any;


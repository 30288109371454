import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { map, take } from 'rxjs/operators';
import { GlobalsService } from '.';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private globals: GlobalsService,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const status = await this.getLoginStatus();

    if (!status) {
      if (state.url.includes('about')) {
        return false;
      }
      else {
        if (!state.url.includes('auth/sign-in')) {
          this.globals.returnUrl = {
            has: true,
            url: next.url[0].path,
            params: next.queryParams,
          }
        }
        this.router.navigate(['/auth/sign-in']);
        return false;
      }
    }
    else {
      // if (state.url.includes('match-room')) {
      //   this.globals.isMatchRoom = true;
      // }
      // else {
      //   this.globals.isMatchRoom = false;
      // }
      return true;
    }

  }

  getLoginStatus(): Promise<boolean> {
    return new Promise((resolve) => {
      this.auth.user$.pipe(
        take(1),
        map(user => !!user)
      ).subscribe((loginStatus: boolean) => {
          if (loginStatus) {
            resolve(true);
          }
          else {
            resolve(false);
          }
        });
    });
  }
}


import { Component } from '@angular/core';
import { faComment, faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { Observable } from 'rxjs';
import { CardFactoryHelperService } from '../card-factory-helper.service';
import { ICardConfig } from '../card-factory.model';

@Component({
  selector: 'app-my-created-card',
  templateUrl: './my-created-card.component.html',
  styleUrls: ['./my-created-card.component.css']
})
export class MyCreatedCardComponent {

  public myCards$: Observable<ICardConfig[]> = this.cfHelper.getMyCards()

  public icons = {
    like: faThumbsUp,
    dislike: faThumbsDown,
    comment: faComment,
  }

  constructor(private cfHelper: CardFactoryHelperService) {}

  public tracker(index: number, item: ICardConfig): string {
    return item.docId
  }

  public deleteCard(cardDocId: string): void {
    this.cfHelper.deleteCard(cardDocId)
  }

}


<div class="p-3">
  <div class="row">
    <div class="col-3">
      <label>Theme</label>
    </div>
    <div class="col">
      <div class="form-inline">
        <div class="btn btn-sm ms-2 {{ !darkmode ? 'btn-main' : 'btn-secondary' }}" (click)="darkmode = false; changeSetting()">
          Light
        </div>
        <div class="btn btn-sm ms-2 {{ darkmode ? 'btn-main' : 'btn-secondary' }}" (click)="darkmode = true; changeSetting()">
          Dark
        </div>
      </div>
      <!-- <div class="form-check form-switch">
        <input type="checkbox" class="form-check-input" id="darkmodeSwitch" [(ngModel)]="darkmode" (change)="changeSetting('darkMode', darkmode)">
        <label class="form-check-label" for="darkmodeSwitch"></label>
      </div> -->
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-3">
      <label>Clock</label>
    </div>
    <div class="col">
      <div class="form-inline">
        <div class="btn btn-sm ms-2 {{ !clock24 ? 'btn-main' : 'btn-secondary' }}" (click)="clock24 = false; changeSetting()">
          12h
        </div>
        <div class="btn btn-sm ms-2 {{ clock24 ? 'btn-main' : 'btn-secondary' }}" (click)="clock24 = true; changeSetting()">
          24h
        </div>
      </div>
      <!-- <div class="form-check form-switch">
        <input type="checkbox" class="form-check-input" id="clockSwitch" [(ngModel)]="clock24" (change)="changeSetting('clock24', clock24)">
        <label class="form-check-label" for="clockSwitch"></label>
      </div> -->
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-3">
      <label>Text size zoom</label>
    </div>
    <div class="col">
      <div class="form-inline">
        <div class="btn btn-sm ms-2 {{ textSize === 'smaller' ? 'btn-main' : 'btn-secondary' }}" (click)="textSize = 'smaller'; changeSetting()">
          <span>75%</span>
        </div>
        <div class="btn btn-sm ms-2 {{ textSize === 'small' ? 'btn-main' : 'btn-secondary' }}" (click)="textSize = 'small'; changeSetting()">
          <span>80%</span>
        </div>
        <div class="btn btn-sm ms-2 {{ textSize === 'medium' ? 'btn-main' : 'btn-secondary' }}" (click)="textSize = 'medium'; changeSetting()">
          <span>90%</span>
        </div>
        <div class="btn btn-sm ms-2 {{ textSize === 'normal' ? 'btn-main' : 'btn-secondary' }}" (click)="textSize = 'normal'; changeSetting()">
          <span>100%</span>
        </div>
        <div class="btn btn-sm ms-2 {{ textSize === 'large' ? 'btn-main' : 'btn-secondary' }}" (click)="textSize = 'large'; changeSetting()">
          <span>125%</span>
        </div>
      </div>
    </div>
  </div>
</div>

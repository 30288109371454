<div class="card" style="min-width: 100%;">

  <div class="card-header d-flex align-items-center">
    <span class="flex-grow-1">Batch Settings</span>
    <div class="btn-close text-secondary" (click)="closeModal()">
      <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
    </div>
  </div>
  <div class="card-body no-hover">

    <div class="form-group row mt-2 mb-3">
      <label for="numberOfMatches" class="col-sm-9 col-form-label">Number of matches in batch</label>
      <div class="col-sm">
        <input type="number" class="form-control form-control-sm" id="numberOfMatches"
          [(ngModel)]="batchConfig.numberOfMatches">
      </div>
    </div>

    <div class="form-group row mb-3">
      <label for="numberOfRandom" class="col-sm-9 col-form-label">Random based matches</label>
      <div class="col-sm">
        <input type="number" class="form-control form-control-sm" id="numberOfRandom"
          [(ngModel)]="batchConfig.numberOfRandomMatches">
      </div>
    </div>

    <div class="form-group row mb-3">
      <label for="numberOfStandings" class="col-sm-9 col-form-label">Standings based matches</label>
      <div class="col-sm">
        <input type="number" class="form-control form-control-sm" id="numberOfStandings"
          [(ngModel)]="batchConfig.numberOfStandingsMatches">
      </div>
    </div>

    <div class="alert alert-warning text-small mb-3 p-1" role="alert" *ngIf="disableGenerateBatchPairings">
      Random based and Standings based matches need to add up to the number of matches in the batch
    </div>

  </div>

  <div class="card-footer d-flex justify-content-end">
      <button [disabled]="disableGenerateBatchPairings" class="btn btn-sm btn-secondary"
        (click)="addBatch()">Generate Pairings</button>
  </div>

</div>

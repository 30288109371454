<ng-container *ngIf="player$ | async as data">


    <div class="d-flex align-items-center py-2 px-3 gap-3 hover-highligt">

        <!-- SELECT BUTTON -->
        <ng-container *ngIf="editModeActive">
            <input class="form-check-input" type="checkbox" [(ngModel)]="data.selected">
        </ng-container>


        <!-- AVATAR -->
        <app-player-avatar [playerDocId]="playerDocId"
            class="flex-shrink-0 rounded d-none d-md-block"></app-player-avatar>


        <div class="pb-2 mb-0 small lh-sm border-bottom border-secondary border-opacity-50 flex-grow-1 d-flex flex-wrap align-items-center gap-2" [ngClass]="{ 'border-bottom-0' : last }">


            <!-- NAME and COUNTRY -->
            <div class="flex-grow-1 d-flex flex-column" (click)="$event.preventDefault(); $event.stopPropagation(); data.selected = editModeActive ? !data.selected : data.selected">

                <app-player-display-name class="flex-grow-1 text-large" [playerDocId]="playerDocId" [name]="name">
                </app-player-display-name>

                <div class="text-small text-secondary">
                    {{ countryName }}
                </div>

                <div class="text-small text-secondary">
                    {{ emailAddress }}
                </div>

            </div>


            <span class="badge rounded-pill text-bg-danger text-medium" *ngIf="hasDropped">DROPPED</span>

            <ng-container *ngIf="isTeamTournament">

                <div *ngIf="data.info.inTeam; else addToTeamButton" class="d-flex align-items-center gap-2 text-medium alert alert-info p-0 px-2 py-1 m-0">
                    <fa-icon [icon]="teamIcon"></fa-icon>
                    <span class="">{{ data.teamName }}</span>
                </div>

                <ng-template #addToTeamButton>
                    <button *ngIf="!data.eventPlayer.dropped" class="btn btn-sm btn-outline-primary" (click)="action.openTeamConfig()">Assign to a team</button>
                </ng-template>


            </ng-container>



            <!-- FLAGS: Decksubmission, Payment, Check-in, Warning -->
            <div class="d-flex align-items-center justify-content-end flex-grow-1 flex-md-grow-0 ms-3">


                <button *ngFor="let flag of (flags$ | async)" class="btn btn-blank {{ flag.class }}"
                    [ngbTooltip]="flag.tooltip" container="body" (click)="flag.action()">

                    <fa-icon [icon]="flag.icon"></fa-icon>

                </button>

                <!-- ACTIONS -->
                <div ngbDropdown container="body">

                    <button type="button" class="ms-3 btn btn-blank no-arrow" ngbDropdownToggle>
                        <fa-icon [icon]="actions"></fa-icon>
                    </button>

                    <div ngbDropdownMenu>

                        <button ngbDropdownItem class="py-2"
                            [disabled]="eventStarted"
                            (click)="action.unattend()">
                            Unattend
                        </button>

                        <button ngbDropdownItem class="py-2"
                            [disabled]="eventStarted"
                            (click)="action.moveToWaitList()">
                            Move to wait list
                        </button>

                        <button ngbDropdownItem class="py-2" [disabled]="disableDrop"
                            (click)="action.toggleDropped()">
                            {{ hasDropped ? 'Undrop' : 'Drop' }}
                        </button>

                        <button ngbDropdownItem class="py-2"
                            (click)="action.toggleCheckIn()">
                            {{ hasCheckedIn ? 'Clear check-in' : 'Check-in' }}
                        </button>


                        <div class="border-bottom border-secondary pt-2 mb-2"></div>


                        <button ngbDropdownItem class="py-2"
                            [disabled]="!paymentActive || data.info.hasPaidFee || data.info.hasPaidTicket"
                            (click)="action.registerManualPayment()">
                            Register manual payment
                        </button>

                        <button ngbDropdownItem
                            class="py-2"
                            [disabled]="!paymentActive || paymentDetails === null"
                            (click)="action.paymentDetails()">
                            Payment details
                        </button>

                        <button ngbDropdownItem
                            class="py-2"
                            [disabled]="data.info.hasRefundedFee || data.info.hasRefundedTicket"
                            (click)="action.issueRefund()">
                            Issue refund
                        </button>


                        <div class="border-bottom border-secondary pt-2 mb-2"></div>


                        <button ngbDropdownItem
                            class="py-2"
                            [disabled]="!data.info.hasSubmittedDeckList"
                            (click)="action.showDeckList()">
                            Show deck list
                        </button>

                        <button ngbDropdownItem
                            class="py-2"
                            [disabled]="!data.info.hasSubmittedDeckPhoto"
                            (click)="action.showDeckPhoto()">
                            Show deck photo
                        </button>


                        <div class="border-bottom border-secondary pt-2 mb-2"></div>


                        <button ngbDropdownItem
                            class="py-2 text-warning"
                            [disabled]="!eventStarted"
                            (click)="action.addWarning()">
                            Add warning
                        </button>

                    </div>
                </div>

            </div>




        </div>

    </div>

</ng-container>
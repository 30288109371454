import { IInvitedPlayer, IMatchData } from './event.interfaces'


export interface IMessageGroupDocument {
  docId: string;
  name?: string;
  description?: string
  createdByUid: string;
  createdDate: number; // timestamp
  latestMessage: number; // timestamp
  latestMessagePreview: string;
  latestMessageDocId?: string
  latestMessageByPlayerId?: string
  playerDocIds: Array<string>;
  isSingle: boolean;
  playersLastVisit?: { [playerDocId: string]: number };
  attachmentCount?: number
  attachments?: IMessageGroupAttachment[]
  pinnedMessages?: string[]
}
export interface IMessageGroupAttachment {
  filename: string
  contentType: string
  mediaLink: string
  createdBy: string
  createdAt: string
}
export interface IMessageDocument {
  docId?: string;
  edited?: string;
  cardName?: string;
  content?: IMessageContent;
  message: string;
  html?: string;
  delta?: any;
  images?: IMessageImage[];
  messageGroupDocId: string;
  playerDocId: string;
  playerUid: string;
  matchChat: boolean;
  spectatorMode: boolean; // only available if the message is connected to a match chat
  matchDocId: string; // only available if the message is connected to a match chat
  timestamp: number;
  type: IMessageType
  whisperMode?: string;
  mentionedPlayerDocIds?: Array<string>;
  invitedPlayer?: IInvitedPlayer;
  replyToMeta?: IReplyToMeta;
  reactions?: IMessageReaction;
  archived: boolean
  isPinned?: boolean
  pinnedBy?: string
}

export type IMessageType =
  'chat-message' |
  'match-room-action' |
  'scryfall-image' |
  'match-appointment' |
  'event-invitation' |
  'event-announcement' |
  'match-invitation' |
  'scryfall-rules'
export interface IMessageReaction {
  '👍'?: string[];
  '❤️'?: string[];
  '😃'?: string[];
  '😲'?: string[];
  '😞'?: string[];
  '😡'?: string[];
}

export interface IReplyToMeta {
  messageDocId: string;
  playerDocId: string;
  message: string;
}
export interface IMessageImage {
  guid: string;
  base64?: string;
  downloadUrl: string;
}
export interface IMessageContent {
  cardName?: string;
  setCode?: string;
  setName?: string;
  imageUrl?: string;
  rulings?: any;
  typeLine?: string;
  manaCost?: string;
  oracleText?: string;
  flavorText?: string;
  scryfallUri?: string;
  power?: string;
  toughness?: string;
  loyalty?: string;
  matchDoc?: IMatchData;
  appointmentDocId?: string;
}
export interface IMessageSender {
  name: string;
  icon: string;
}

<div class="graphs-wrapper d-flex align-items-center min-width-100 max-width-100 p-3 overflow-hidden flex-wrap" [style.width.%]="graphWidth">

  <div class="graph-wrapper" [style.minHeight.rem]="graphHeight" [style.maxHeight.rem]="graphHeight">
    <div class="text-large">Card Types</div>
    <ngx-charts-pie-chart
      *ngIf="typeDataReady$ | async"
      [scheme]="colorScheme.type"
      [results]="data.type"
      [gradient]="gradient"
      [legend]="showLegend"
      [legendPosition]="legendPosition"
      [labels]="showLabels"
      [doughnut]="isDoughnut"
      [trimLabels]="false">
    </ngx-charts-pie-chart>
  </div>

  <div class="graph-wrapper" [style.minHeight.rem]="graphHeight" [style.maxHeight.rem]="graphHeight">
    <div class="text-large">Colors</div>
    <ngx-charts-pie-chart
      *ngIf="colorDataReady$ | async"
      [scheme]="colorScheme.color"
      [results]="data.color"
      [gradient]="gradient"
      [legend]="showLegend"
      [legendPosition]="legendPosition"
      [labels]="showLabels"
      [doughnut]="isDoughnut"
      [trimLabels]="false">
    </ngx-charts-pie-chart>
  </div>

  <div class="graph-wrapper" [style.minHeight.rem]="graphHeight" [style.maxHeight.rem]="graphHeight">
    <div class="text-large">Converted Mana Cost</div>
    <ngx-charts-bar-vertical
      *ngIf="cmcDataReady$ | async"
      [scheme]="colorScheme.cmc"
      [results]="data.cmc"
      [yAxis]="true"
      [xAxis]="true"
      [gradient]="gradient"
      [barPadding]="48">
    </ngx-charts-bar-vertical>
  </div>

</div>

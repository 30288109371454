<div class="card"style="min-width: 100%;">

    <div class="card-header">Player Check-In</div>

    <div class="card-body no-hover">
        <div class="mb-2">
        The organizer has started the event and needs you to check-in before any pairings can be created. Please
        tell the organizer you are ready by confirming you are active.
        </div>
        <div class="text-secondary mb-2">Players who have not checked in when the organizer continues will be dropped.</div>
    </div>

    <div class="card-footer d-flex align-items-center">
        <button class="btn btn-main w-100" (click)="playerCheckIn()">Check-In</button>
    </div>

</div>

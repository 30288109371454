<div class="px-3">

  <div class="d-flex flex-column mb-3 position-relative" [style.minHeight.rem]="1"
    [ngClass]="{ 'info-visible':showInfo }">
    <button class="btn btn-sm btn-blank text-secondary info-toggle-button" (click)="showInfo = !showInfo">
      <fa-icon [icon]="iconInfo" [fixedWidth]="true"></fa-icon>
    </button>

    <div class="info-container d-flex flex-column" *ngIf="showInfo">
      
      <app-stripe-connected-account-info></app-stripe-connected-account-info>

    </div>
  </div>


  <ng-container *ngIf="user$ | async as user">

    <div class="row align-items-center">


      <div class="col flex-grow-1 py-3" [ngClass]="{ 'text-secondary' : !countryVerifiedByUser }">
        <span class="me-1" [ngClass]="{ 'text-secondary' : !countryVerifiedByUser }">
          <fa-icon [icon]="countryVerifiedByUser ? taskDone : taskOpen" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="flex-grow-1 me-2">Tolaria: Verify your country of residence</span>
        <ng-container *ngIf="playerDoc$ | async as playerDoc; else fetchingPlayerDoc">
          <span class="flex-grow-0 text-normal">{{ playerDoc.country.name }} ({{ playerDoc.country.code }})</span>
        </ng-container>
        <ng-template #fetchingPlayerDoc>
          <span class="flex-grow-0 d-flex align-items-center">
            <app-busy-spinner></app-busy-spinner>
            <span class="text-secondary ms-2">fetching country</span>
          </span>
        </ng-template>
      </div>

      <div class="col fit-content d-flex align-items-center justify-content-end">

        <ng-container *ngIf="!countryVerifiedByUser; else countryVerified">
          <button class="btn btn-sm btn-blank text-start me-3" (click)="updateCountry()">Update User Settings</button>
          <button class="btn btn-sm btn-outline-main text-start" (click)="verifyCountry()"
            [style.minWidth.rem]="9">Verify Country</button>
        </ng-container>

        <ng-template #countryVerified>
          <ng-container *ngIf="playerDoc$ | async as playerDoc">
            <span class="text-secondary text-monospace">{{ playerDoc.country.name }} ({{ playerDoc.country.code }})</span>
          </ng-container>
        </ng-template>

      </div>

    </div>

    <div class="row align-items-center">


      <div class="col flex-grow-1 py-3" [ngClass]="{ 'text-secondary' : !hasStripeId }">
        <span class="me-1" [ngClass]="{ 'text-secondary' : !hasStripeId }">
          <fa-icon [icon]="hasStripeId ? taskDone : taskOpen" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="flex-grow-1">Stripe: Create Account</span>
      </div>

      <div class="col fit-content d-flex align-items-center justify-content-end">

        <ng-container *ngIf="!hasStripeId; else accountCreated">
          <div class="spinner d-flex align-items-center me-3" *ngIf="isBusy.accountCreate">
            <span class="text-secondary me-2">Creating your connected account</span>
            <app-busy-spinner></app-busy-spinner>
          </div>
          <button class="btn btn-sm {{ disableCreateAccount ? 'btn-outline-secondary' : 'btn-outline-main' }} text-start"
            (click)="createAccount()" [style.minWidth.rem]="9" [disabled]="disableCreateAccount">Create Account</button>
        </ng-container>

        <ng-template #accountCreated>
          <span class="text-secondary text-monospace">{{ hasStripeId ? user.stripe.id : '' }}</span>
        </ng-template>

      </div>

    </div>


    <div class="row align-items-center">


      <div class="col flex-grow-1 py-3" [ngClass]="{ 'text-secondary' : !accountOnboarded }">
        <span class="me-1" [ngClass]="{ 'text-secondary' : !accountOnboarded }">
          <fa-icon [icon]="accountOnboarded ? taskDone : taskOpen" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="flex-grow-1">Stripe: Onboard Account</span>
      </div>

      <div class="col fit-content d-flex align-items-center justify-content-end">

        <ng-container *ngIf="!accountOnboarded; else accountIsOnboarded">
          <div class="spinner me-3 d-flex align-items-center">
            <span class="text-secondary me-2"
              *ngIf="isBusy.accountOnboarding && !isBusy.accountOnboardingRedirecting">Fetching onboarding links</span>
            <span class="text-secondary me-2" *ngIf="isBusy.accountOnboardingRedirecting">Redirecting</span>
            <app-busy-spinner *ngIf="isBusy.accountOnboarding"></app-busy-spinner>
          </div>
          <button [style.minWidth.rem]="9"
            class="btn btn-sm {{ disableStartOnboarding ? 'btn-outline-secondary' : 'btn-outline-main' }} text-start"
            (click)="onboardAccount()" [disabled]="disableStartOnboarding" *ngIf="!accountLinksPresent">Start
            Onboarding</button>
          <button [style.minWidth.rem]="9" class="btn btn-sm btn-outline-main text-start" (click)="onboardAccount()"
            *ngIf="accountLinksPresent">Continue Onboarding</button>
        </ng-container>

        <ng-template #accountIsOnboarded>
          <span class="text-secondary text-monospace">onboarded</span>
        </ng-template>

      </div>

    </div>

    <ng-container *ngIf="accountOnboarded">


      <div class="d-flex flex-column mt-5">

        <div class="text-larger mb-2">Properties</div>

        <div class="list-wrapper">
          <div class="list-item d-flex justify-content-between align-items-center">
            Charges
            <span class="badge rounded-pill">
              <fa-icon [icon]="user?.stripe?.charges_enabled ? enabled : disabled" [fixedWidth]="true"></fa-icon>
            </span>
          </div>
          <div class="list-item d-flex justify-content-between align-items-center">
            Payouts
            <span class="badge rounded-pill">
              <fa-icon [icon]="user?.stripe?.payouts_enabled ? enabled : disabled" [fixedWidth]="true"></fa-icon>
            </span>
          </div>
          <div class="list-item d-flex justify-content-between align-items-center">
            Default Currency
            <span class="badge rounded-pill">
              {{ defaultCurrency }}
            </span>
          </div>
        </div>

      </div>

      <div class="d-flex flex-column mt-5">

        <div class="text-larger mb-2">Capabilities</div>

        <div class="list-wrapper">
          <div class="list-item d-flex justify-content-between align-items-center"
            *ngFor="let capability of capabilities">
            {{ capability.text }}
            <span class="badge rounded-pill {{ capability.value === 'active' ? 'text-bg-success' : 'text-bg-danger' }}">
              {{ capability.value }}
            </span>
          </div>
        </div>

      </div>

      <div class="d-flex flex-column mt-5">

        <div class="mb-2 d-flex align-items-center">
          <div class="text-larger flex-grow-1">Balance</div>
          <button
              class="btn btn-sm btn-blank text-secondary d-flex align-items-center"
              ngbTooltip="Sync balance with stripe"
              container="body"
              (click)="getBalance()" [disabled]="isBusy.fetchingBalance">
              <fa-icon [icon]="iconSync" [fixedWidth]="true" *ngIf="!isBusy.fetchingBalance"></fa-icon>
              <app-busy-spinner *ngIf="isBusy.fetchingBalance"></app-busy-spinner>
            </button>
        </div>

        <div class="row mb-2" *ngIf="balance$ | async as balance; else balanceLoader">

          <div class="col">
            <div class="text-large mt-3">Total</div>
            <div class="text-monospaced text-secondary" *ngFor="let data of balance.total">{{ data }}</div>
          </div>

          <div class="col">
            <div class="text-large mt-3">Available to pay out</div>
            <div class="text-monospaced text-secondary" *ngFor="let data of balance.available">{{ data }}</div>
          </div>

          <div class="col">
            <div class="text-large mt-3">Available soon</div>
            <div class="text-monospaced text-secondary" *ngFor="let data of balance.pending">{{ data }}</div>
          </div>

          <div class="col">
            <div class="text-large mt-3">In transit to bank</div>
            <div class="text-monospaced text-secondary" *ngFor="let data of balance.inTransit">{{ data }}</div>
          </div>

        </div>
        <ng-template #balanceLoader>
          <div class="d-flex align-items-center justify-content-center p-5">
            <app-busy-spinner></app-busy-spinner>
          </div>
        </ng-template>

      </div>

      <div class="d-flex flex-column mt-5">

        <div class="text-larger mb-2">Stripe Dashboard</div>

        <div class="row align-items-center mb-2">

          <div class="col py-3 d-flex align-items-center">

            <span class="flex-grow-1 text-secondary">See all transaction and more in your stripe dashboard</span>

            <button class="ms-2 btn btn-sm btn-main text-start d-flex align-items-center" (click)="loginToStripe()"
              [disabled]="isBusy.fetchingAccountLoginLinks">
              To Dashboard
              <div class="spinner ms-3" *ngIf="isBusy.fetchingAccountLoginLinks">
                <app-busy-spinner></app-busy-spinner>
              </div>
            </button>

          </div>

        </div>

      </div>


    </ng-container>


  </ng-container>

</div>

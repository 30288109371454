import { Pipe, PipeTransform } from '@angular/core';
import { ITicket } from 'tolaria-cloud-functions/src/_interfaces';

@Pipe({
  name: 'ticketFilter'
})
export class TicketsPipe implements PipeTransform {

  transform(items: ITicket[], filter: string): ITicket[] {
    if (items === null) {
      return []
    }
    if (filter === '' || filter.length < 3) {
      return items
    }

    return items.filter(i => JSON.stringify(i).toLowerCase().includes(filter.toLowerCase()))

  }

}

import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.css']
})
export class MobileComponent {

  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router
  ) {
    if (!this.deviceService.isMobile() || !this.deviceService.isTablet()) {
      this.router.navigate(['dashboard']);
    }
  }

}

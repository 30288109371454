import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatchListingService } from '../../services/match-listing.service';
import { faChartLine, faFilter, faListUl } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-matches-filter-bar',
  templateUrl: './matches-filter-bar.component.html',
  styleUrls: ['./matches-filter-bar.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    NgbModule,
    RouterLink,
    RouterModule,
    NgbTooltipModule,
  ]
})
export class MatchesFilterBarComponent {
  @Input() rounded: boolean = false

  constructor(
    public matchListing: MatchListingService,
    private router: Router,
  ) { }

  public icon = {
    filter: faFilter,
    calendar: faCalendar,
    statistics: faChartLine,
    availability: faCalendarCheck,
    list: faListUl,
  }

  public get route(): 'list' | 'cal' | 'stats' | 'avail' {
    if (this.router.url.includes('list')) {
      return 'list'
    }
    if (this.router.url.includes('calendar')) {
      return 'cal'
    }
    if (this.router.url.includes('statistics')) {
      return 'stats'
    }
  }
}

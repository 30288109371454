<div class="d-flex flex-row align-items-center w-100 pt-2 pb-2" *ngIf="hidePlayer">

  <app-user-status class="pe-2 flex-grow-0" [playerUid]="player.playerUid"></app-user-status>

  <span class="flex-grow-1">
    <ng-container *ngIf="player.playerDocId.includes('temp__'); else playerLink"><span>{{ player.name }}</span></ng-container>
    <ng-template #playerLink>
      <app-player-display-name [playerDocId]="player.playerDocId" [name]="player.name" (click)="$event.stopPropagation(); $event.preventDefault()"></app-player-display-name>
    </ng-template>
  </span>

  <span class="drop-wrapper flex-grow-0 mx-2" *ngIf="player.dropped">dropped</span>

  <ng-container *ngIf="eventStarted && eventIsGroup">
    <span class="badge text-bg-GROUP-{{ groupLetter }} mx-2" [innerHtml]="groupLetter"></span>
  </ng-container>

  <ng-container *ngIf="isMultiDay">
    <button class="btn btn-sm btn-blank">
      <fa-icon class="{{ availableDuringEvent ? 'text-greenyellow' : 'text-secondary' }} text-secondary flex-grow-0"
        [fixedWidth]="true" [icon]="faCalendar"
        [ngbTooltip]="availableDuringEvent ? 'Availability added during the event period' : 'No availability found during the event period'"
        container="body">
      </fa-icon>
    </button>
  </ng-container>

  <ng-container *ngIf="registrationFee">

    <!-- PAID BY TICKET PURCHASE -->
    <button class="btn btn-sm btn-blank" *ngIf="paidByTicket">
      <fa-icon class="text-secondary flex-grow-0 text-greenyellow"
        [fixedWidth]="true" [icon]="faTicket"
        ngbTooltip="Purchased a ticket that covers this event"
        container="body">
      </fa-icon>
    </button>

    <ng-template #registrationFeeTooltip>
      <!-- NOT PAID -->
      <div *ngIf="!hasBeenRefunded && !hasPaidRegistrationFee">
        Player needs to pay the registration fee
      </div>
      <!-- PAID -->
      <div *ngIf="!hasBeenRefunded && hasPaidRegistrationFee">
        <div class="" *ngIf="paidWithProvider === 'TOLARIA'">
          The player has paid the registration fee with Tolaria payment
        </div>
        <div class="" *ngIf="paidWithProvider !== 'TOLARIA'">
          <div class="border-bottom border-secondary py-2 mb-2">Paid externally with {{ paidWithProvider }}</div>
          <div class="mt-2 text-italic text-secondary" [innerHtml]="externalPaymentComment"></div>
        </div>
      </div>
      <!-- REFUNDED or PENDING REFUND -->
      <div *ngIf="hasBeenRefunded">
        <div class="" *ngIf="paidWithProvider === 'TOLARIA'">
          {{ refundPending ? 'Refund of registration fee with Tolaria payment pending' : 'Registration fee has been refunded with Tolaria payment' }}
        </div>
        <div class="" *ngIf="paidWithProvider !== 'TOLARIA'">
          <div class="border-bottom border-secondary py-2 mb-2">Refunded externally with {{ paidWithProvider }}</div>
          <div class="mt-2 text-italic text-secondary" [innerHtml]="externalPaymentComment"></div>
        </div>
      </div>
    </ng-template>

    <!-- PAID OR UNPAID -->
    <button class="btn btn-sm btn-blank" *ngIf="!hasBeenRefunded && !refundPending">
      <fa-icon class="text-secondary flex-grow-0" [ngClass]="{ 'text-greenyellow' : hasPaidRegistrationFee }"
        [fixedWidth]="true" [icon]="faCoins"
        [ngbTooltip]="registrationFeeTooltip"
        container="body">
      </fa-icon>
    </button>

    <!-- REFUNDED or PENDING REFUND -->
    <button class="btn btn-sm btn-blank" *ngIf="hasBeenRefunded">
      <fa-icon class="flex-grow-0 {{ refundPending ? 'text-warning' : 'text-red' }}"
        [fixedWidth]="true" [icon]="refundPending ? iconPending : iconRefunded"
        [ngbTooltip]="registrationFeeTooltip"
        container="body">
      </fa-icon>
    </button>
  </ng-container>

  <ng-container *ngIf="deckList">
    <button class="btn btn-sm btn-blank">
      <fa-icon class="{{ hasSubmittedDeckList ? 'text-greenyellow' : 'text-secondary' }} flex-grow-0"
        [fixedWidth]="true" [icon]="faFileAlt"
        [ngbTooltip]="hasSubmittedDeckList ? 'Deck list submitted' : 'Deck list NOT submitted'"
        container="body">
      </fa-icon>
    </button>
  </ng-container>

  <ng-container *ngIf="deckPhoto">
    <button class="btn btn-sm btn-blank">
      <fa-icon class="{{ hasSubmittedDeckPhoto ? 'text-greenyellow' : 'text-secondary' }} text-secondary flex-grow-0"
        [fixedWidth]="true" [icon]="faCamera"
        [ngbTooltip]="hasSubmittedDeckPhoto ? 'Deck photo submitted' : 'Deck photo NOT submitted'"
        container="body">
      </fa-icon>
    </button>
  </ng-container>

  <button class="btn btn-sm btn-blank" (click)="eventStarted ? null : toggleCheckIn(!player.hasCheckedIn)">
    <fa-icon class="{{ player.hasCheckedIn ? 'text-greenyellow' : 'text-secondary' }} text-secondary flex-grow-0"
      [fixedWidth]="true" [icon]="faCheckedIn"
      [ngbTooltip]="player.hasCheckedIn ? 'Player is checked in' : 'Player is not checked in'"
      container="body">
    </fa-icon>
  </button>

  <div ngbDropdown class="d-inline-block" container="body">
    <button class="btn btn-sm btn-blank" id="playerAction" ngbDropdownToggle>
      <fa-icon class="text-secondary chevron flex-grow-0" [fixedWidth]="true" [icon]="faEllipsisH"></fa-icon>
    </button>
    <div ngbDropdownMenu aria-labelledby="playerAction">
      <button ngbDropdownItem (click)="unattend()" [disabled]="eventStarted">Unattend</button>
      <button ngbDropdownItem (click)="toggleCheckIn(!player.hasCheckedIn)" [disabled]="eventStarted">{{ player.hasCheckedIn ? 'Clear check-in' : 'Check-in' }}</button>
      <button ngbDropdownItem (click)="drop()" *ngIf="eventStarted" [disabled]="player.dropped">Drop</button>
      <button ngbDropdownItem (click)="unDrop()" *ngIf="eventStarted" [disabled]="!player.dropped">Undrop</button>
      <div class="dropdown-divider"></div>
      <button ngbDropdownItem [disabled]="disablePayment" (click)="registerPayment()">Register External Payment</button>
      <button ngbDropdownItem [disabled]="disableRefund || disableRefunding" (click)="issueRefund()">Issue Refund</button>
      <div class="dropdown-divider"></div>
      <button ngbDropdownItem [disabled]="!hasSubmittedDeckList" (click)="openDeckList()">Show Deck List</button>
      <button ngbDropdownItem [disabled]="!hasSubmittedDeckPhoto" (click)="openDeckPhoto()">Show Deck Photo</button>
      <div class="dropdown-divider"></div>
      <button ngbDropdownItem [disabled]="true">Add Warning</button>
    </div>
  </div>
</div>


<ng-template #unattendConfirmationModal let-modal>
  <div class="modal-header text-large">Unattend Player?</div>
  <div class="modal-body">
    <ng-container *ngIf="hasPaidRegistrationFee; else noPayment">
      <div class="d-flex flex-column my-3 text-orange text-center">
        <div class="text-mega">
          <fa-icon [icon]="faExclamationTriangle" [fixedWidth]="true"></fa-icon>
        </div>
        <div class="py-2">
          You are about to unattend <span class="text-bold" [innerHtml]="player.name"></span> from the event. Be aware that the registration fee has been paid for!
        </div>
        <div class="py-2 d-flex align-items-center justify-content-center">
          <button class="btn btn-sm btn-main" (click)="issueRefund()">Issue Refund</button>
        </div>
      </div>
    </ng-container>

    <ng-template #noPayment>
      You are about to unattend <span class="text-bold" [innerHtml]="player.name"></span>, are you sure?
    </ng-template>

  </div>
  <div class="modal-footer">
    <div type="button" class="btn btn-sm text-secondary" (click)="modal.dismiss()">Cancel</div>
    <div type="button" class="btn btn-sm btn-blank" (click)="modal.close()">Unattend</div>
  </div>
</ng-template>

<ng-template #confirmRefunding let-modal>
  <div class="modal-header text-large">Issue Refund?</div>
  <div class="modal-body">
    <p>You are about to issue a refund of the registration fee paid by <span class="text-bold" [innerHtml]="player.name"></span>.</p>
    <p>Payment registered through: <span class="text-large">{{ paidWithProvider }}</span></p>
    <p class="btn btn-outline-main text-start" *ngIf="paidWithTolaria">
      Be aware that any fees connected to this payment will still apply to the tolaria.app platform account<br>
    </p>
    <ng-container *ngIf="!paidWithTolaria">
      <p class="btn btn-outline-main mb-3 text-start">
        This registration fee has been paid with an external payment method and you need to handle the refund manually as there is no automated process and you need to notify the player when you have solved the refund externally.
      </p>
      <div class="row mb-2">
        <div class="col-6">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="form-check-input" id="registrationFeeRefund" [(ngModel)]="refundRegistrationFee">
            <label class="form-check-label" for="registrationFeeRefund">Registration fee</label>
          </div>
        </div>
        <div class="col-4">{{ registrationFeeAmountString }} {{ paidByInfo.currency.toUpperCase() }}</div>
      </div>
      <div class="row mb-2" *ngIf="paidByInfo.charityAmount > 0">
        <div class="col-6">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="form-check-input" id="charityRefund" [(ngModel)]="refundCharityAmount">
            <label class="form-check-label" for="charityRefund">Charity extra</label>
          </div>
        </div>
        <div class="col-4">{{ charityAmountString }} {{ paidByInfo.currency.toUpperCase() }}</div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer px-3 py-2 d-flex flex-row align-items-center">
    <div class="custom-control custom-checkbox flex-grow-1 text-start">
      <input type="checkbox" class="form-check-input" id="confirmRefundCheckBox" [(ngModel)]="confirmRefundCheckBox">
      <label class="form-check-label" for="confirmRefundCheckBox">Confirm Refunding</label>
    </div>
    <div type="button" class="btn btn-sm text-secondary" (click)="modal.dismiss()">Cancel</div>
    <button type="button" class="btn btn-sm btn-blank" (click)="paidWithTolaria ? modal.close() : modal.close(refundAmount)" [disabled]="disableRefundButton">Refund</button>
  </div>
</ng-template>

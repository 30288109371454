import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { BehaviorSubject, map, Observable, Subscription, take } from 'rxjs'
import { PlayerNameService } from 'src/app/services/players/player-name.service'
import * as firestore from 'firebase/firestore'

type QuestionType = 'name' | 'type' | 'artist' | 'set' | 'manaValue' | 'manaCost' | 'color'

export interface Question {
  type: QuestionType
  imageUrl: string
  question: string
  card: Card
  correctAnswer: string
  options: string[]
}

interface Card {
  name: string
  artist: string
  cmc: number
  colors: string[]
  image_uris: {
    art_crop: string
    normal: string
  }
  mana_cost: string
  set_name: string
  set: string
  type_line: string
  type: string
  released_at: string
  reprint: boolean
}

interface Answers {
  options: string[]
  correct: string
}

export interface QuizResults {
  quizCount: number
  yearMonth: string,
  result: {
    [key: string]: number
  }
}

export interface QuizResult {
  highscore: boolean
  correctAnswers: number
}

export interface QuizRank {
  playerDocId: string
  result: number
}

export interface QuizStats {
  lastMonth: {
    count: number
    uniqueUsers: number
  }
  thisMonth: {
    count: number
    uniqueUsers: number
  }
  overall: {
    count: number
    uniqueUsers: number
  }
}

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  private baseUrl = 'https://api.scryfall.com/cards/search?q=f:os&unique=prints'
  private _cards: Card[] = []
  private cards$ = new BehaviorSubject<Card[]>(null)
  private names$ = new BehaviorSubject<string[]>(null)
  private artists$ = new BehaviorSubject<string[]>(null)
  private manaValues$ = new BehaviorSubject<number[]>(null)
  private manaCosts$ = new BehaviorSubject<string[]>(null)
  private sets$ = new BehaviorSubject<string[]>(null)
  private colors$ = new BehaviorSubject<string[][]>(null)
  private answeredQuestions$ = new BehaviorSubject<Question[]>([])
  private thisMonthKey: string
  private lastMonthKey: string

  public serviceReady$ = new BehaviorSubject<boolean>(false)
  public results$ = new BehaviorSubject<QuizResults[]>(null)
  public highScoreThisMonth$ = new BehaviorSubject<QuizRank[]>(null)
  public highScoreLastMonth$ = new BehaviorSubject<QuizRank[]>(null)
  public highScoreOverAll$ = new BehaviorSubject<QuizRank[]>(null)
  public stats$ = new BehaviorSubject<QuizStats>(null)


  private cardTypes = [
    'Artifact',
    'Creature',
    'Enchantment',
    'Instant',
    'Land',
    'Sorcery',
  ]
  private setNames = [
    'Limited Edition Alpha',
    'Limited Edition Beta',
    'Arabian Nights',
    'Antiquities',
    'Legends',
    'The Dark',
    'Fallen Empires'
  ]

  constructor(
    private http: HttpClient,
    private readonly firestore: AngularFirestore,
    private readonly playerNames: PlayerNameService,
  ) {
    this.init()
  }

  public getQuestion(): Question {
    let card: Card
    let question: Question

    do {
      card = this.getRandomCard()
      question = this.createQuestionFromCard(card)
    }
    while (question === undefined || this.questionHasBeenAsked(question))

    return question
  }

  public getTimer() {

  }

  public storeCorrectAnswer(question: Question): void {
    const answered = this.answeredQuestions$.getValue()
    answered.push(question)
    this.answeredQuestions$.next(answered)
  }

  public storeResultAndReset(): QuizResult {
    const results: Question[] = JSON.parse(JSON.stringify(this.answeredQuestions$.getValue()))
    this.answeredQuestions$.next([])
    const result = this.storeResults(results)
    return result
  }

  public get quizTakenThisMonth(): number {
    if (this.thisMonthKey) {
      const docs = this.results$.getValue()
      if (docs) {
        const doc = docs.find(i => i.yearMonth === this.thisMonthKey)
        if (doc) {
          return doc.quizCount
        }
      }
    }
    return null
  }

  public get quizTakenLastMonth(): number {
    if (this.lastMonthKey) {
      const docs = this.results$.getValue()
      if (docs) {
        const doc = docs.find(i => i.yearMonth === this.thisMonthKey)
        if (doc) {
          return doc.quizCount
        }
      }
    }
    return null
  }

  public get quizTakenOverall(): number {
    const docs = this.results$.getValue()
    if (docs !== null) {
      const counts = docs.map(i => i.quizCount).reduce((a, b) => a + b, 0)
      return counts
    }
    return null
  }

  private updateQuizStats(): void {

    // create stats object
    const stats: QuizStats = {
      thisMonth: {
        count: null,
        uniqueUsers: null,
      },
      lastMonth: {
        count: null,
        uniqueUsers: null,
      },
      overall: {
        count: null,
        uniqueUsers: null,
      },
    }

    // get all documents
    const docs = this.results$.getValue()

    // check if docs exists
    if (docs === null) { return }

    // get stats for current month
    const thisMonthDoc = docs.find(i => i.yearMonth === this.thisMonthKey)
    if (thisMonthDoc) {
      stats.thisMonth.uniqueUsers = Object.keys(thisMonthDoc.result).length
      stats.thisMonth.count = thisMonthDoc.quizCount
    }

    // get stats for last month
    const lastMonthDoc = docs.find(i => i.yearMonth === this.lastMonthKey)
    if (lastMonthDoc) {
      stats.lastMonth.uniqueUsers = Object.keys(lastMonthDoc.result).length
      stats.lastMonth.count = lastMonthDoc.quizCount
    }

    // get stats for overall
    let uniqueUsers = []
    let count = 0
    for (const doc of docs) {
      const users = [...uniqueUsers, ...Object.keys(doc.result)]
      uniqueUsers = [...new Set(users)]
      count = count + doc.quizCount
    }
    stats.overall.uniqueUsers = uniqueUsers.length
    stats.overall.count = count

    // update the subject
    this.stats$.next(stats)

  }

  private updateHighScoreThisMonth(): void {
    const results = this.results$.getValue()
    if (results !== null) {
      const result = results.find(i => i.yearMonth === this.thisMonthKey)
      if (result) {
        const rank: QuizRank[] = Object.entries(result.result).map(i => {
          return {
            playerDocId: i[0],
            result: i[1],
          }
        }).sort((a, b) => b.result - a.result)
        this.highScoreThisMonth$.next(rank.slice(0, 10))
        return
      }
    }
    this.highScoreThisMonth$.next(null)
  }

  private updateHighScoreLastMonth(): void {
    const results = this.results$.getValue()
    if (results !== null) {
      const result = results.find(i => i.yearMonth === this.lastMonthKey)
      if (result) {
        const rank: QuizRank[] = Object.entries(result.result).map(i => {
          return {
            playerDocId: i[0],
            result: i[1],
          }
        }).sort((a, b) => b.result - a.result)
        this.highScoreLastMonth$.next(rank.slice(0, 10))
        return
      }
    }
    this.highScoreLastMonth$.next(null)
  }

  private updateHighScoreOverall(): void {
    const results = this.results$.getValue()
    const highscore: QuizRank[] = []
    if (results !== null) {
      for (const result of results) {
        for (const score of Object.entries(result.result)) {
          const playerDocId = score[0]
          const result = score[1]
          const prevRecord = highscore.find(i => i.playerDocId === playerDocId)
          if (prevRecord && prevRecord.result < result) {
            prevRecord.result = result
          }
          else {
            highscore.push({
              playerDocId,
              result,
            })
          }
        }
      }

      highscore.sort((a, b) => b.result - a.result)
      this.highScoreOverAll$.next(highscore.slice(0, 10))
      return
    }
    this.highScoreOverAll$.next(null)
  }

  private storeResults(result: Question[]): QuizResult {
    const today = new Date()
    const year = today.getFullYear()
    const month = (today.getMonth() + 1).toString()
    const key = `${year}-${month.padStart(2, '0')}`
    const results = this.results$.getValue()
    let update
    let highscore
    // check if result record exist
    const record = results.find(i => i.yearMonth === key)
    if (record) {
      highscore = result.length > this.highScoreThisMonth$.getValue()[0].result
      const playerScore = record.result[this.playerNames.currentPlayersMini.id]
      if (playerScore === undefined || playerScore < result.length) {
        update = {
          yearMont: key,
          quizCount: firestore.increment(1),
          [`result.${this.playerNames.currentPlayersMini.id}`]: result.length,
        }
      }

      if (update === undefined) {
        update = {
          yearMonth: key,
          quizCount: firestore.increment(1),
        }
      }
      this.firestore.collection('quiz-result').doc(key).update(update)
    }
    else {
      highscore = true
      this.firestore.collection('quiz-result').doc(key).set({
        yearMonth: key,
        quizCount: 1,
        result: {
          [`${this.playerNames.currentPlayersMini.id}`]: result.length
        }
      })
    }

    return {
      highscore,
      correctAnswers: result.length
    }

  }

  private fetchAllOldSchoolCards(url: string = this.baseUrl): Observable<Subscription> {
    return this.http
      .get<any>(url)
      .pipe(
        map((response) => {
          response.data.forEach((card: any) => {
            const cardData: Card = {
              name: card.name,
              artist: card.artist,
              cmc: card.cmc,
              colors: this.mapCardColors(card.colors),
              image_uris: {
                art_crop: card.image_uris.art_crop,
                normal: card.image_uris.normal,
              },
              mana_cost: card.mana_cost === '' ? 'None' : card.mana_cost,
              set: card.set,
              set_name: card.set_name,
              type: this.getCardType(card.type_line),
              type_line: card.type_line,
              released_at: card.released_at,
              reprint: card.reprint,
            }
            this._cards.push(cardData)
          })
          if (response.has_more) {
            return this.fetchAllOldSchoolCards(response.next_page).pipe(take(1)).subscribe()
          }
          else {
            this.mapCardData()
          }
        })
      )
  }

  private init() {
    let getter = this.fetchAllOldSchoolCards()
    getter.pipe(take(1)).subscribe()

    const current = new Date()
    const last = new Date()
    last.setMonth(current.getMonth() - 1)

    const currentYear = current.getFullYear()
    const currentMonth = (current.getMonth() + 1).toString()
    const currentKey = `${currentYear}-${currentMonth.padStart(2, '0')}`

    const lastYear = last.getFullYear()
    const lastMonth = (last.getMonth() + 1).toString()
    const lastKey = `${lastYear}-${lastMonth.padStart(2, '0')}`

    this.thisMonthKey = currentKey
    this.lastMonthKey = lastKey

    this.firestore
      .collection<QuizResults>('quiz-result')
      .valueChanges()
      .subscribe(data => this.results$.next(data))

    this.results$.subscribe(() => {
      this.updateHighScoreThisMonth()
      this.updateHighScoreLastMonth()
      this.updateHighScoreOverall()
      this.updateQuizStats()
    })

  }

  private mapCardColors(colors: string[]): string[] {
    const colorNames = {
      'W': '{W}',
      'U': '{U}',
      'B': '{B}',
      'R': '{R}',
      'G': '{G}',
    }
    return colors.length === 0
      ? ['{C}']
      : colors.map(color => color === '' ? '{C}' : colorNames[color])
  }

  private mapCardData(): void {
    const cards = this._cards.sort((a, b) => a.released_at.localeCompare(b.released_at))
    const unique = this._cards.filter(i => !i.reprint)
    this.cards$.next(unique)
    const names = [...new Set(cards.map(i => i.name))].sort((a, b) => a.localeCompare(b))
    this.names$.next(names)
    const artists = [...new Set(cards.map(i => i.artist))].sort((a, b) => a.localeCompare(b))
    this.artists$.next(artists)
    const manaValues = [...new Set(cards.map(i => i.cmc))].sort((a, b) => a - b)
    this.manaValues$.next(manaValues)
    const manaCosts = [...new Set(cards.map(i => i.mana_cost))].sort((a, b) => a.localeCompare(b))
    this.manaCosts$.next(manaCosts)
    // const setNames = [...new Set(cards.map(i => i.set_name))].sort((a, b) => a.localeCompare(b))
    // this.sets$.next(setNames)
    this.sets$.next(this.setNames)
    const colors = [...new Set(cards.map(i => i.colors.join(',')))].sort((a, b) => a.localeCompare(b)).map(i => i.split(','))
    this.colors$.next(colors)
    const released = [...new Set(cards.map(i => i.released_at))].map(released_at => {
      const set_name = cards.find(x => x.released_at === released_at).set_name
      return {
        set_name,
        released_at
      }
    }).sort((a, b) => a.released_at.localeCompare(b.released_at))
    this.colors$.next(colors)

    console.log('[QuizService] -> card data mapped -> ', { unique, names, artists, manaValues, manaCosts, setNames: this.setNames, colors, released, })
    this.serviceReady$.next(true)
  }

  private similarityScore(string1: string, string2: string): number {
    let common = 0
    for (let char of string1) {
      if (string2.includes(char)) {
        common++
      }
    }
    return common / Math.max(string1.length, string2.length)
  }

  private getCardType(typeLine: string): string {

    // Get the first part of type_line
    const types = typeLine.split(' — ')[0]

    // Split by space to handle multi-type cards
    const typeArray = types.split(' ')

    for (const type of typeArray) {
      // Check if the type is one of our predefined types and add it to the set
      if (this.cardTypes.includes(type)) {
        return type
      }
    }

  }

  private getRandomQuestionType(): QuestionType {
    const questionTypes: QuestionType[] = ['name', 'type', 'artist', 'set', 'manaValue', 'manaCost', 'color']
    const randomIndex = Math.floor(Math.random() * questionTypes.length)
    return questionTypes[randomIndex]
  }

  private getRandomCard(): Card {
    const randomIndex = Math.floor(Math.random() * this.cards$.getValue().length)
    return this.cards$.getValue()[randomIndex]
  }

  private createQuestionFromCard(card: Card, type: QuestionType = null): Question {
    const questionType = type ? type : this.getRandomQuestionType()
    const questions = {
      name: 'What is the name of this card?',
      type: 'What is the type of this card?',
      artist: 'Who illustrated this card?',
      set: 'Which set was this card originally printed in?',
      manaValue: 'What is the total mana value of this card?',
      manaCost: 'What is the mana cost for this card?',
      color: 'What are the color identities of this card?',
    }
    const answers = this.getIncorrectOptions(card, questionType)
    const question: Question = {
      type: questionType,
      imageUrl: card.image_uris.art_crop,
      question: questions[questionType],
      card: card,
      correctAnswer: answers.correct,
      options: answers.options,
    }
    return question
  }

  private questionHasBeenAsked(question: Question): boolean {
    const answered = this.answeredQuestions$.getValue()
    return answered.find(i => i.type === question.type && i.card.name === question.card.name) !== undefined
  }

  private getIncorrectOptions(correctOption: Card, questionType: QuestionType): Answers {
    let options: string[]
    let correct: string
    switch (questionType) {
      case 'name':
        options = this.cards$.getValue().filter(option => option.name !== correctOption.name).map(i => i.name)
        correct = correctOption.name
        break
      case 'type':
        options = this.cardTypes.filter(i => i !== correctOption.type)
        correct = correctOption.type
        break
      case 'artist':
        options = this.artists$.getValue().filter(option => option !== correctOption.artist)
        correct = correctOption.artist
        break
      case 'set':
        options = this.sets$.getValue().filter(option => option !== correctOption.set_name)
        correct = correctOption.set_name
        break
      case 'manaValue':
        options = this.manaValues$.getValue().filter(option => option !== correctOption.cmc).map(i => i.toString())
        correct = correctOption.cmc.toString()
        break
      case 'manaCost':
        options = this.manaCosts$.getValue().filter(option => option !== correctOption.mana_cost).map(i => i.toString())
        // options = correctOption.mana_cost === 'None'
        //   ? this.manaCosts$.getValue().filter(option => option !== correctOption.mana_cost).map(i => i.toString())
        //   : this.manaCosts$.getValue()
        //                    .filter(option => option !== correctOption.mana_cost)
        //                    .map(string => ({ string, score: this.similarityScore(correctOption.mana_cost, string)}))
        //                    .sort((a, b) => b.score - a.score)
        //                    .map(item => item.string)
        correct = correctOption.mana_cost
        break
      case 'color':
        options = this.colors$.getValue().filter(option => option !== correctOption.colors).map(i => i.join(' '))
        correct = correctOption.colors.join(' ')
        break
    }

    const incorrectOptions = this.shuffleOptions(options).slice(0, 3)
    const allOptions = this.shuffleOptions([...incorrectOptions, correct])
    return {
      options: allOptions,
      correct,
    }
  }

  private shuffleOptions(options: string[]): string[] {
    return options.sort(() => Math.random() - 0.5)
  }


}

<div class="card card-xxxlarge" *ngIf="club" style="max-height: 80vh; min-height: 80vh;">
  <div class="card-header">
    {{ club.name }}
  </div>
  <div class="card-body no-hover scrollable d-flex flex-column p-0">

    <div class="d-flex flex-row align-items-center p-3 tab-bar">
      <div class="btn btn-sm {{ view.details ? 'btn-main' : 'btn-blank text-secondary' }} me-1"
        (click)="clearViews(); view.details = true;">Details</div>
      <div class="btn btn-sm {{ view.players ? 'btn-main' : 'btn-blank text-secondary' }} me-1"
        (click)="clearViews(); view.players = true;">Players</div>
      <div class="btn btn-sm {{ view.images ? 'btn-main' : 'btn-blank text-secondary' }} me-1"
        (click)="clearViews(); view.images = true;">Logo</div>
      <div class="btn btn-sm {{ view.admin ? 'btn-main' : 'btn-blank text-secondary' }} me-1"
        (click)="clearViews(); view.admin = true;" *ngIf="isAdmin">Admin</div>
    </div>

    <ng-container *ngIf="view.details">
      <div class="outer-wrapper p-3">
        <div class="row mb-1">
          <div class="col">
            <div class="form-group w-100">
              <label for="info">Info</label>
              <textarea type="text" class="form-control form-control-sm" id="info" [(ngModel)]="club.info"
                rows="7"></textarea>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col">
            <div class="form-group w-100">
              <label for="websiteUrl">Website</label>
              <input type="text" class="form-control form-control-sm" id="websiteUrl" [(ngModel)]="club.websiteUrl">
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col">
            <div class="form-group w-100">
              <label for="region">Region / Area / Location</label>
              <input type="text" class="form-control form-control-sm" id="region" [(ngModel)]="club.region">
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col">
            <div class="form-group w-100">
              <label for="country">Country</label>
              <input type="text" class="form-control form-control-sm" id="country" [(ngModel)]="club.country">
            </div>
          </div>
          <div class="col">
            <div class="form-group w-100">
              <label for="city">City</label>
              <input type="text" class="form-control form-control-sm" id="city" [(ngModel)]="club.city">
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col">
            <div class="form-group w-100">
              <label for="longitude">Longitude</label>
              <input type="text" class="form-control form-control-sm" id="longitude" [(ngModel)]="club.longitude">
            </div>
          </div>
          <div class="col">
            <div class="form-group w-100">
              <label for="latitude">Latitude</label>
              <input type="text" class="form-control form-control-sm" id="latitude" [(ngModel)]="club.latitude">
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="view.players">
      <div class="outer-wrapper d-flex flex-column flex-grow-1">

        <div class="top-wrapper flex-grow-0 pt-3 ps-3 pe-3">
          <app-tolaria-player-search (playerDocId)="addTolariaPlayer($event)"></app-tolaria-player-search>
        </div>

        <div class="bottom-wrapper flex-grow-1 player-list-wrapper ps-2 pe-2 pt-2 pb-3">

          <div class="player-item m-2 d-inline-block" *ngFor="let playerDocId of club.memberDocIds">
            <app-player-avatar [playerDocId]="playerDocId" position="left"></app-player-avatar>
            <div class="actions-wrapper">
              <div class="btn btn-sm btn-danger w-100" (click)="deletePlayer(playerDocId)">Delete</div>
            </div>
          </div>

        </div>

      </div>
    </ng-container>

    <ng-container *ngIf="view.images">
      <div class="outer-wrapper p-3">
        <div class="row mb-3">
          <div class="col d-flex flex-row align-items-center">
            <input type="file" class="w-100 flex-grow-1 me-2" (change)="fileChangeEvent($event)" />
            <!-- <button class="btn btn-sm {{ uploadEnabled ? 'btn-main' : 'btn-secondary'}} text-nowrap" [disabled]="!uploadEnabled" (click)="uploadImages()">Upload Images</button> -->
          </div>
        </div>
        <div class="d-flex align-items-center mb-3">
          <div class="text-secondary">Aspect Ratio</div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-secondary btn-sm m-1" id="ratioSelection" ngbDropdownToggle>{{
              selectedAspectRatioString }}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem (click)="selectedAspectRatio = 1 / 1">1:1</button>
              <button ngbDropdownItem (click)="selectedAspectRatio = 2 / 1">2:1</button>
              <button ngbDropdownItem (click)="selectedAspectRatio = 4 / 3">4:3</button>
              <button ngbDropdownItem (click)="selectedAspectRatio = 16 / 9">16:9</button>
            </div>
          </div>
          <div class="text-secondary ms-3 me-1">Maintain Aspect Ratio</div>
          <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input" id="maintainAspectRatio"
              [(ngModel)]="maintainAspectRatio">
            <label class="form-check-label text-secondary" for="maintainAspectRatio"> </label>
          </div>
        </div>

        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="maintainAspectRatio"
          [aspectRatio]="selectedAspectRatio" format="png" (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
        </image-cropper>

        <div class="preview-outer-wrapper d-flex align-items-start mt-3 mb-3" *ngIf="uploadEnabled; else storedImages">
          <div class="preview-wrapper large">
            <img [src]="croppedImage.large" width="256" />
          </div>
          <div class="preview-wrapper normal">
            <img [src]="croppedImage.normal" width="128" />
          </div>
          <div class="preview-wrapper small">
            <img [src]="croppedImage.small" width="64" />
          </div>
          <div class="preview-wrapper thumb">
            <img [src]="croppedImage.thumb" width="32" />
          </div>
        </div>

        <ng-template #storedImages>
          <div class="preview-outer-wrapper d-flex align-items-start mt-3 mb-3">
            <div class="preview-wrapper large">
              <img [src]="club.imageUris.large" width="256" />
            </div>
            <div class="preview-wrapper normal">
              <img [src]="club.imageUris.normal" width="128" />
            </div>
            <div class="preview-wrapper small">
              <img [src]="club.imageUris.small" width="64" />
            </div>
            <div class="preview-wrapper thumb">
              <img [src]="club.imageUris.thumb" width="32" />
            </div>
          </div>
        </ng-template>

      </div>
    </ng-container>

    <ng-container *ngIf="view.admin">
      <div class="outer-wrapper d-flex flex-column flex-grow-1 gap-3 p-3">

        <div class="d-flex flex-column gap-2">
          <label for="clubName" class="form-label">Club name</label>
          <input type="text" class="form-control" id="clubName" [(ngModel)]="club.name">
        </div>


        <div class="top-wrapper flex-grow-0">
          <div class="h3">Responsible Players</div>
          <app-tolaria-player-search (playerDocId)="addResponsibleTolariaPlayer($event)"></app-tolaria-player-search>
        </div>

        <div class="bottom-wrapper flex-grow-1 player-list-wrapper">

          <div class="player-item m-2 d-inline-block" *ngFor="let playerDocId of club.responsiblePlayerDocIds">
            <app-player-avatar [playerDocId]="playerDocId" position="left"></app-player-avatar>
            <div class="actions-wrapper">
              <div class="btn btn-sm btn-danger w-100" (click)="deleteResponsiblePlayer(playerDocId)">Delete</div>
            </div>
          </div>

        </div>

      </div>
    </ng-container>

  </div>
  <div class="card-footer d-flex flex-row align-items-center">
    <div class="flex-sm-grow-1"></div>
    <div class="btn btn-sm btn-blank text-red" (click)="closeModal()">Cancel</div>
    <button class="btn btn-sm {{ formChanged ? 'btn-main' : 'btn-blank' }} ms-2" [disabled]="!formChanged"
      (click)="saveClubData()">Save</button>
  </div>
</div>

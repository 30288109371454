import { GlobalsService } from 'src/app/services/globals.service';
import { IDeckList, DecksService } from 'src/app/services/decks.service';
import { Component, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-deck-pic-editor',
  templateUrl: './deck-pic-editor.component.html',
  styleUrls: ['./deck-pic-editor.component.css']
})
export class DeckPicEditorComponent implements OnDestroy {
  @ViewChild('deleteConfirmation') deleteConfirmation: ElementRef;
  @Input()
  set deckList(deck: IDeckList) {
    this.deckOriginString = JSON.stringify(deck);
    if (deck.deckPhotoUrl === '' || deck.deckPhotoUrl === null || deck.deckPhotoUrl === undefined) {
      this.editMode = true;
    }
    this.deck = deck;
  }

  public faEdit = faEdit;
  public faTrash = faTrash;

  public deck: IDeckList;
  private deckOriginString: string;

  public editMode: boolean = false;

  private imageHasChanged = false;
  public enableDeckPhotoUpload = true;
  private downloadUrl: Observable<string>;
  public deckListParsed: IDeckList;
  public imageChangedEvent: any = '';
  private croppedImage: any = '';
  public saveDisabled = true;

  constructor(
    private modalService: NgbModal,
    private globals: GlobalsService,
    private storage: AngularFireStorage,
    private deckService: DecksService,
  ) { }

  componentDestroyed$: Subject<boolean> = new Subject<boolean>();

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
  }

  // IMAGE UPLOAD
  fileChangeEvent(event: any): void {
    this.imageHasChanged = true;
    console.log('imageHasChanged:' + this.imageHasChanged);
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(): void {
    // show cropper
    console.log('imageLoaded');
    this.saveDisabled = false;
  }
  cropperReady(): void {
    // cropper ready
    console.log('cropperReady');
  }
  loadImageFailed(): void {
    // show message
    console.log('loadImageFailed');
  }
  async saveImage() {
    console.log('imageHasChanged:' + this.imageHasChanged);
    if (this.imageHasChanged) {
      this.globals.isBusy.message = 'Uploading your image, please wait';
      this.globals.isBusy.status = true;
      this.globals.isBusy.showMessage = true;

      const filePath = 'decks/' + this.deck.docId + '.jpg';
      const ref = this.storage.ref(filePath);
      const task = ref.putString(this.croppedImage, 'data_url');
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            console.log('task finalized, getting download url');
            this.downloadUrl = ref.getDownloadURL();
            // store the download url as the avatar link for both user and player doc.
            this.downloadUrl
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe(async (url) => {
                // set the photo url
                this.deckService.updateDeckPhotoUrl(this.deck.docId, url);
                this.croppedImage = '';
                this.imageChangedEvent = '';
                this.saveDisabled = true;
                this.editMode = false;
                this.globals.isBusy.showMessage = false;
                this.globals.isBusy.status = false;
              });
          }),
          takeUntil(this.componentDestroyed$)
        ).subscribe();
    }
  }

  public deletePicture(): void {
    const content = this.deleteConfirmation;
    this.modalService
      .open(content, { centered: true, keyboard: false, size: 'sm' }).result
      .then(() => {
        // cancelled
      }, (reason) => {
        if (reason === 'delete') {
          const filePath = 'decks/' + this.deck.docId + '.jpg';
          const ref = this.storage.ref(filePath);
          ref.delete();
          this.deckService.updateDeckPhotoUrl(this.deck.docId, '');
        }
      });
  }

  public get changesMade(): boolean {
    return JSON.stringify(this.deck) !== this.deckOriginString;
  }

  public get showCancel(): boolean {
    return this.editMode && this.deck.deckPhotoUrl !== null && this.deck.deckPhotoUrl !== undefined && this.deck.deckPhotoUrl !== '';
  }

  public get showDelete(): boolean {
    return this.deck.deckPhotoUrl !== null && this.deck.deckPhotoUrl !== undefined && this.deck.deckPhotoUrl !== '';
  }

  public get showChange(): boolean {
    return this.deck.deckPhotoUrl !== null && this.deck.deckPhotoUrl !== undefined && this.deck.deckPhotoUrl !== '';
  }


}

import { ModalReportSlipComponent } from './modal-report-slip/modal-report-slip.component'
import { IMatchRoomKnock, IMessageDocument } from 'tolaria-cloud-functions/src/_interfaces'
import { MessagesService } from 'src/app/services/messages.service'
import { MatchService } from 'src/app/services/match.service'
import { ToastService } from 'src/app/services/toast.service'
import { GlobalsService } from 'src/app/services/globals.service'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database'
import { IMatchData, IMatchReportSlip } from 'tolaria-cloud-functions/src/_interfaces'
import { Observable, Subject, BehaviorSubject, combineLatest } from 'rxjs'
import { filter, map, take, tap, takeUntil, distinctUntilChanged } from 'rxjs/operators'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService, EventService } from 'src/app/services'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore'
import { Component, OnInit, HostListener, ViewChild, ElementRef, OnDestroy } from '@angular/core'
import {
  faComment, faTimes, faVolumeMute, faVolumeOff, faPhone, faPhoneSlash, faHandPaper, faWindowMinimize,
  faWindowMaximize, faRedoAlt, faQuestion, faInfinity, faShareAlt, faWindowClose, faCog
} from '@fortawesome/free-solid-svg-icons'
import * as firestore from 'firebase/firestore'
import { MediaStreamsService } from 'src/app/services/media-streams.service'
import { WebRtcHelperService, WebRtcCallerRole, WebRtcDataCommands } from './webrtc-helper.service'
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { DeviceSettingsComponent } from './device-settings/device-settings.component'
import { PlayerNameService } from 'src/app/services/players/player-name.service'
import { MessageModalService } from '../../modals/message-modal/message-modal.service'
import { PlayerMetaService } from 'src/app/services/players/player-meta.service'

export interface ILocalDeviceSettings {
  audioInputDeviceId: string
  audioOutputDeviceId: string
  videoInputDeviceId: string
}
export interface IOnlineUser {
  online: boolean
  playerDocId: string
  playerUid: string
  timestamp: any
  webRtcPeerId: string
  callerRole: WebRtcCallerRole
  displayName?: string
}
export interface IICEMeta {
  active: boolean
  iceServer: IICEServer
}
export interface IICEServer {
  credential?: string
  username?: string
  urls: Array<string>
}

@Component({
  selector: 'app-match-room',
  templateUrl: './match-room.component.html',
  styleUrls: ['./match-room.component.css']
})
export class MatchRoomComponent implements OnInit, OnDestroy {
  @ViewChild('localVideo') public localVideo: ElementRef
  @ViewChild('remoteVideo') public remoteVideo: ElementRef
  @ViewChild('organizerVideo') public organizerVideo: ElementRef
  @ViewChild('confirmSpectate', { static: false }) confirmSpectate: ElementRef

  public isMinimized: boolean = false

  // url parameters
  eventDocId: string
  matchDocId: string
  player1DocId: string
  player2DocId: string
  createdByUid: string

  // template checkers
  moreActionsFloating = false
  showChatPanel = true
  matchDeleted = false
  focusOpponent = false
  spectatorMutePlayers = false
  showReportSlip = false
  reportSlip: IMatchReportSlip = null
  resizeTrigger$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  opponentIsHero$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  featureMinimized = false
  featureInfoOpen = true
  featureIframRefreshing = false

  // Organizer togglers
  organizerPlayerChatActive = true
  holdPlayers = false
  callingPlayers = false

  // Firebase Observables
  firebaseInitialized$ = new BehaviorSubject<boolean>(false)
  localMatchDocument: IMatchData
  private matchDocumentRef: AngularFirestoreDocument<IMatchData>
  matchDocument$: Observable<IMatchData>
  onlineUsers$: Observable<IOnlineUser[]>

  // ICONS
  faComment = faComment
  faTimes = faTimes
  faVolumeMute = faVolumeMute
  faVolumeOff = faVolumeOff
  faPhone = faPhone
  faPhoneSlash = faPhoneSlash
  faHandPaper = faHandPaper
  faMinimize = faWindowMinimize
  faMaximize = faWindowMaximize
  faClose = faWindowClose
  faRedoAlt = faRedoAlt
  faHelp = faQuestion
  faInfinity = faInfinity
  faShareAlt = faShareAlt
  faSettings = faCog

  // User MEDIA Observables
  public connectedMediaDevices$: Observable<MediaDeviceInfo[]> = this.streamService.connectedMediaDevices$
  public selectedVideoInputDeviceId$: Observable<string> = this.streamService.selectedVideoInputDeviceId$
  public selectedAudioInputDeviceId$: Observable<string> = this.streamService.selectedAudioInputDeviceId$
  public selectedAudioOutputDeviceId$: Observable<string> = this.streamService.selectedAudioOutputDeviceId$
  public localStream$: Observable<MediaStream> = this.streamService.localStream$.pipe(
    filter((stream: MediaStream) => stream !== undefined)
  )
  public playerCamOn$ = this.streamService.videoEnabled$
  public playerMicOn$ = this.streamService.audioInputEnabled$
  public audioOutputMuted$ = this.streamService.audioOutputMuted$

  // WebRTC
  private matchRole: WebRtcCallerRole
  private organizerHangUpCall$ = new Subject()
  private webRtcInitialized$: Observable<boolean> = this.webRtcHelper.initialized$.pipe(
    filter((isInitialized) => isInitialized),
    tap(() => console.log('MatchRoomComponent > WebRTC is INITIALIZED'))
  )

  // Video streams
  organizerStream$: Observable<MediaStream> = this.webRtcHelper.organizerStream$
  player1Stream$: Observable<MediaStream> = this.webRtcHelper.player1Stream$
  player2Stream$: Observable<MediaStream> = this.webRtcHelper.player2Stream$

  // Database triggers
  dbRef: AngularFireList<IOnlineUser>
  private userJoinedRoom$ = new Subject<IOnlineUser>()
  private userLeftRoom$ = new Subject<IOnlineUser>()

  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private readonly webRtcHelper: WebRtcHelperService,
    public auth: AuthService,
    private route: ActivatedRoute,
    private afDb: AngularFireDatabase,
    private readonly streamService: MediaStreamsService,
    public globals: GlobalsService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private matchService: MatchService,
    private messagesService: MessagesService,
    public eventService: EventService,
    private readonly router: Router,
    private readonly playerNames: PlayerNameService,
    private readonly playerMeta: PlayerMetaService,
    private readonly message: MessageModalService,
  ) {
    console.log('MatchRoomComponent > route snapshot', this.route.snapshot)
    this.eventDocId = this.route.snapshot.params.eventDocId
    this.matchDocId = this.route.snapshot.params.matchDocId
    this.player1DocId = this.route.snapshot.params.player1DocId
    this.player2DocId = this.route.snapshot.params.player2DocId
    this.createdByUid = this.route.snapshot.params.createdByUid
  }

  public get playerBackground(): string {
    return this.playerNames.currentPlayersMatchRoomBackgroundUrl === ''
      ? this.webRtcHelper.opponentBackgroundUrl === null
        ? ''
        : this.webRtcHelper.opponentBackgroundUrl
      : this.playerNames.currentPlayersMatchRoomBackgroundUrl
  }

  private componentWasDestroyed$ = new Subject<boolean>()

  @HostListener('window:unload')
  async ngOnDestroy() {
    this.webRtcHelper.destroy()
    this.componentWasDestroyed$.next(true)
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.globals.hotkeysEnabled) {
      if (event.key === 'm') {
        this.toggleChatPanel()
      }
      if (event.key === 'f') {
        this.toggleFocusOpponent()
      }
    }
  }

  async ngOnInit() {

    combineLatest([this.webRtcHelper.dataConnectionEstablished$, this.playerNames.currentUserPlayerDoc$])
      .pipe(takeUntil(this.componentWasDestroyed$))
      .subscribe(([dataConnection, playerDoc]) => {
        console.log('MatchRoomComponent > matchRoomBackgroundUrl', { dataConnection, playerDoc })
        if (dataConnection && playerDoc.matchRoomBackgroundUrl !== undefined && playerDoc.matchRoomBackgroundUrl !== '') {
          this.webRtcHelper.sendData({
            command: WebRtcDataCommands.MYBACKGROUND,
            value: playerDoc.matchRoomBackgroundUrl
          })
        }
      })


    // start observing the match document to get updates on life totals, round timer, etc
    this.initFirestoreObservables()
    this.initDatabaseObservable()
    this.matchRole = await this.myWebRtcCallerRole()

    if (this.isPlayer || this.isOrganizer) {
      this.initPlayerOrOrganizer()
    }
    if (this.isSpectator) {
      this.initSpectator()
    }

    console.log(`MatchRoomComponent > Event Room init: User is ${this.matchRole}`)

    this.matchDocument$.pipe(takeUntil(this.componentWasDestroyed$)).subscribe(doc => {

      if (doc.reportSlip && doc.reportSlip.showSlip && !this.modalService.hasOpenModals()) {
        // create the modal for the report slip
        const modalOptions: NgbModalOptions = {
          centered: true,
          animation: true,
          backdrop: 'static',
          keyboard: false,
          windowClass: 'modal-report-slip'
        }
        // open the report slip
        const modalRef: NgbModalRef = this.modalService.open(ModalReportSlipComponent, modalOptions)
        modalRef.componentInstance.matchData$ = this.matchDocument$
      }
      else {
        this.modalService.dismissAll
      }
    })
  }
  public openSettings(): void {
    const modalRef = this.modalService.open(DeviceSettingsComponent, {
      backdrop: 'static',
      keyboard: true,
      size: 'lg',
    })
    modalRef.result.then(
      () => console.log('MatchRoomComponent > settings > closed'),
      () => console.log('MatchRoomComponent > settings > dismiss')
    )
  }
  public close(): void {
    this.webRtcHelper.destroy()
    this.streamService.killLocalStream()
    this.componentWasDestroyed$.next(true)
    this.router.navigate([{ outlets: { 'match-room': null } }])
  }
  public refreshFeatureIframe() {
    this.featureIframRefreshing = true
    setTimeout(() => {
      this.featureIframRefreshing = false
    }, 50)
  }
  private initSpectator = () => {
    this.webRtcInitialized$.pipe(takeUntil(this.componentWasDestroyed$)).subscribe(() => {
      this.getOnline()
    })

    this.showSpectatorConfirmDialog()
  }
  private initPlayerOrOrganizer = () => {
    combineLatest([this.webRtcInitialized$, this.localStream$]).pipe(
      take(1)
    ).subscribe(() => {
      console.log('MatchRoomComponent > WebRTC is INITIALIZED and MediaStream is PRESENT. Lets GO!')
      if (this.isPlayer) {
        this.setupWebRtcEventHandlersForPlayer()
        this.getOpponentHeroStatus()
      }
      if (this.isOrganizer) {
        console.log('MatchRoomComponent > SETTING organizerStream$ to streamService.localStream$')
        this.organizerStream$ = this.streamService.localStream$
      }
      this.getOnline()
    })
    this.streamService.initUserMedia()
  }
  private getOnline = () => {
    // get online and subscribe to disconnection <--- ALL USERS WILL ACTIVATE THIS
    console.log('MatchRoomComponent > Get online and make your Presence known to the world!')
    this.updateUserStatusOnline()
    this.updateUserStatusOnDisconnect().subscribe()
  }
  private getOpponentHeroStatus() {
    this.playerMeta.getPlayerMeta(this.opponentDocId)
      .then(meta => this.opponentIsHero$.next(meta.isHero))
      .catch((error) => console.log('MatchRoomComponent > getOpponentHeroStatus > ', error))
  }
  // Initializations
  private initDatabaseObservable = () => {
    console.log('MatchRoomComponent > init database observables')
    this.dbRef = this.afDb.list(`webrtc/${this.matchDocId}/onlineUsers`)
    // Start Observing the online users to be able to view a list of spectators
    this.onlineUsers$ = this.dbRef.valueChanges()
    // EventListener: Player is entering the room
    this.dbRef
      .stateChanges(['child_added'])
      .pipe(takeUntil(this.componentWasDestroyed$))
      .subscribe(action => {
        const user = action.payload.val()
        this.userJoinedRoom$.next(user)
        this.toastService.show(`${this.playerNames.getPlayerById(user.playerDocId).name.display} started to watch`, { classname: 'success-toast', delay: 3000 })
      })
    // EventListener: Player is leaving the room
    this.dbRef
      .stateChanges(['child_removed'])
      .pipe(takeUntil(this.componentWasDestroyed$))
      .subscribe(action => this.onDidLeaveTheRoom(action))
  }
  private async initFirestoreObservables() {
    console.log('MatchRoomComponent > init FIRESTORE observables')
    // MATCH DOCUMENT CHANGES
    this.matchDocumentRef = this.afs.collection('matches').doc<IMatchData>(this.matchDocId)
    this.matchDocument$ = this.matchDocumentRef.valueChanges()
    this.matchDocument$
      .pipe(
        takeUntil(this.componentWasDestroyed$),
        distinctUntilChanged((prev: IMatchData, next: IMatchData) => {
          if (!next) {
            return false
          }
          return prev.reportSlip === next.reportSlip ||
            prev.holdPlayers === next.holdPlayers ||
            prev.callingPlayers === next.callingPlayers ||
            prev.isFeatured === next.isFeatured
        })
      )
      .subscribe((matchDoc) => {
        console.log('MatchRoomComponent > MatchDoc emitted new value', matchDoc)
        if (!matchDoc) {
          this.matchDeleted = true
          return
        }
        this.firebaseInitialized$.next(true)
      })

    if (this.isPlayer) {

      const messagesObserver: Observable<IMessageDocument[]> = this.messagesService.getMessagesObservableForMatchChatByMessageGroupDocId(this.matchDocId)
      messagesObserver.pipe(takeUntil(this.componentWasDestroyed$)).subscribe((messages) => {
        this.showChatPanel = true
      })

      // KNOCKING
      const knockingObserver: Observable<IMatchRoomKnock[]> = this.afs.collection<IMatchRoomKnock>('matchRoomKnocking', ref => ref.where('matchDocId', '==', this.matchDocId)).valueChanges()
      knockingObserver.pipe(takeUntil(this.componentWasDestroyed$)).subscribe((docs) => {

        console.log('MatchRoomComponent > Knocking > emitted new docs', docs)

        const knocks = docs.filter(i => ![...i.allowedBy, ...i.deniedBy].includes(this.auth.user.playerId))

        if (knocks.length > 0) {
          console.log('MatchRoomComponent > Knocking > should answer the knock:', knocks[0])
          // get player knocking
          const knocking = this.playerNames.getPlayerById(knocks[0].knockingPlayerDocId)
          if (knocking) {
            this.message.closeAll()
            this.message.open({
              type: 'info',
              title: 'Allow player to watch?',
              message: `${knocking.name.display} would like to watch this game.`,
              buttons: [
                {
                  type: 'close',
                  text: 'Deny',
                  value: 'deny',
                  class: 'btn-danger',
                },
                {
                  type: 'close',
                  text: 'Allow',
                  value: 'allow',
                  class: 'btn-success',
                }
              ]
            })
            .then(res => {
              if (res === 'allow') {
                this.afs.collection('matchRoomKnocking').doc(knocks[0].docId).update({
                  allowedBy: firestore.arrayUnion(this.auth.user.playerId)
                })
              }
              if (res === 'deny') {
                this.afs.collection('matchRoomKnocking').doc(knocks[0].docId).update({
                  deniedBy: firestore.arrayUnion(this.auth.user.playerId)
                })
              }
            })
          }
        }

      })

    }
  }
  private onDidLeaveTheRoom(action: any) {
    console.log('MatchRoomComponent > onDidLeaveTheRoom')
    const user = action.payload.val() as IOnlineUser
    console.log('MatchRoomComponent > onDidLeaveTheRoom > user:', user)
    console.log('MatchRoomComponent > onDidLeaveTheRoom > update userLeftRoom$')
    this.userLeftRoom$.next(user)
    console.log('MatchRoomComponent > onDidLeaveTheRoom > call webRtcHelper.hangupOnCall')
    this.webRtcHelper.hangupOnCall(user.webRtcPeerId)
    // stop hold or call from organizer if organizer leaves
    // its not optimal that both players do this but it solves the issue if organizer just leaves with the hold active.
    if (user.callerRole === WebRtcCallerRole.ORGANIZER && this.isPlayer) {
      this.playerStopOrganizerHoldOrCallOnLeave()
    }
    this.toastService.show(`${this.playerNames.getPlayerById(user.playerDocId).name.display} stopped watching`, { classname: 'error-toast', delay: 3000 })
  }
  // Realtime Database function for presence
  private updateUserStatusOnline() {
    // set the userOnline object
    const userOnlineStatus = {
      online: true,
      timestamp: this.timestamp,
      playerDocId: this.auth.user.playerId,
      playerUid: this.auth.user.uid,
      webRtcPeerId: this.webRtcHelper.peerId,
      callerRole: this.matchRole
    }

    const userRef = this.auth.user.playerId
    return this.afDb
      .object(`webrtc/${this.matchDocId}/onlineUsers/${userRef}`)
      .update(userOnlineStatus)
  }
  private updateUserStatusOnDisconnect() {
    const userRef = this.auth.user.playerId
    return this.afAuth.authState.pipe(
      tap((user) => {
        if (user) {
          this.afDb
            .object(`webrtc/${this.matchDocId}/onlineUsers/${userRef}`)
            .query
            .ref
            .onDisconnect()
            .remove()
        }
      })
    )
  }
  private setupWebRtcEventHandlersForPlayer = () => {
    // Call any users that was already online in the room. (not player 1 or myself)
    this.onlineUsers$.pipe(
      map((users) => users.filter((u) => u.online && u.playerDocId !== this.auth.user.playerId && u.playerDocId !== this.player1DocId)),
      tap((users) => console.log('MatchRoomComponent > Online users: ', users)),
      take(1)
    ).subscribe((users) => {
      users.forEach((u) => {
        this.webRtcHelper.callUserWithId(u.webRtcPeerId, this.matchRole, u.callerRole)
      })
    })

    // When Organizer is calling...
    // this.organizerStream$ = this.webRtcHelper.organizerStream$

    if (this.isPlayer1) {
      console.log('MatchRoomComponent > Setting player1stream$ to streamService.localStream$')
      this.player1Stream$ = this.streamService.localStream$
    }
    else if (this.isPlayer2) {
      console.log('MatchRoomComponent > Setting player2stream$ to streamService.localStream$')
      this.player2Stream$ = this.streamService.localStream$
    }

    // Whenever a user joins the room (that is not us), call them up
    this.userJoinedRoom$
      .pipe(
        filter((user) => user.playerDocId !== this.auth.user.playerId),
        filter((user) => { // user that joined is a Spectator and I'm player 1
          return user.callerRole === WebRtcCallerRole.SPECTATOR ||
            user.callerRole === WebRtcCallerRole.PLAYER_2 ||
            user.callerRole === WebRtcCallerRole.ORGANIZER
        }),
        takeUntil(this.componentWasDestroyed$)
      )
      .subscribe((user) => {
        console.log('MatchRoomComponent > Another User Joined the room', user.callerRole)
        this.webRtcHelper.callUserWithId(user.webRtcPeerId, this.matchRole, user.callerRole)
      })
  }
  // Organizer actions
  public organizerStopPlayers() {
    this.holdPlayers = !this.holdPlayers
    this.matchDocumentRef.update({
      holdPlayers: this.holdPlayers
    })
  }
  public organizerCallPlayers() {
    this.callingPlayers = !this.callingPlayers
    if (this.callingPlayers) {
      console.log('MatchRoomComponent > organizer > should call the players!')
      this.matchDocumentRef.update({
        callingPlayers: this.callingPlayers
      })
        .then(() => {
          // get peerIds
          this.onlineUsers$
            .pipe(
              map((users) => {
                return users.filter(u => u.playerDocId === this.player1DocId || u.playerDocId === this.player2DocId)
              }),
              take(1)
            )
            .subscribe((users) => {
              const calls = []
              users.forEach((user) => {
                calls.push(this.webRtcHelper.callUserWithId(user.webRtcPeerId, WebRtcCallerRole.ORGANIZER, user.callerRole))
              })
              this.organizerHangUpCall$
                .pipe(take(1))
                .subscribe(() => {
                  calls.forEach((call) => {
                    console.log('MatchRoomComponent > organizer > Hanging up on call:', call.metadata.responderRole)
                    this.webRtcHelper.hangupOnCall(call.metadata.responderPeerId)
                  })
                })
            })
        })
    }
    else {
      console.log('MatchRoomComponent > should hang up!')
      this.matchDocumentRef.update({
        callingPlayers: this.callingPlayers
      })
      this.organizerHangUpCall$.next(true)
    }
  }
  // Actions when organizer leave
  public playerStopOrganizerHoldOrCallOnLeave() {
    this.matchDocumentRef.update({
      callingPlayers: false,
      holdPlayers: false
    })
  }
  // Triggered when hovering media controls, to detect any new devices connected after page load
  public updateConnectedMediaDevices = () => {
    this.streamService.getConnectedMediaDevices()
  }
  public setAudioInputDevice = (deviceId: string) => {
    this.streamService.setAudioInputDevice(deviceId)
  }
  public setAudioOutputDevice = (deviceId: string) => {
    this.streamService.setAudioOutputDevice(deviceId)
    this.remoteVideo.nativeElement.setSinkId(deviceId)
      .then(() => {
        console.log('MatchRoomComponent > setAudioOutput > speaker changed to device id: ', deviceId)
      })
      .catch((error: any) => {
        console.log('MatchRoomComponent > setAudioOutput', error)
      })
  }
  public setVideoDevice = (deviceId: string) => {
    this.streamService.setVideoDevice(deviceId)
  }
  public toggleVideo() {
    this.streamService.toggleVideoEnabled()
  }
  public toggleAudioInput() {
    this.streamService.toggleAudioInputEnabled()
  }
  public toggleFocusOpponent() {
    this.focusOpponent = !this.focusOpponent
    this.resizeTrigger$.next(!this.resizeTrigger$.getValue())
  }
  public toggleChatPanel() {
    this.eventService.eventViewSettings.matchRoomMessages = !this.eventService.eventViewSettings.matchRoomMessages
    this.resizeTrigger$.next(!this.resizeTrigger$.getValue())
  }
  private showSpectatorConfirmDialog = () => {
    this.modalService
      .open(this.confirmSpectate, { centered: true, keyboard: false }).result
      .then((result) => {
        this.modalService.dismissAll()
        console.log('MatchRoomComponent > spectator confirm >', result)
      }, (reason) => {
        this.modalService.dismissAll()
        console.log('MatchRoomComponent > spectator confirm >', reason)
      })
  }
  private get timestamp() {
    return firestore.Timestamp.now().seconds
  }
  public updateMatchData(matchData: IMatchData) {
    if (this.isPlayer) {
      this.matchDocumentRef.set(matchData)
        .catch((error) => {
          console.log('MatchRoomComponent > update match data:', error)
        })
    }
  }
  public shareMatchUrl(): void {
    // path: 'match-room/:eventDocId/:matchDocId/:player1DocId/:player2DocId/:createdByUid',
    const matchRoomLink = `match-room/${this.eventDocId}/${this.matchDocId}/${this.player1DocId}/${this.player2DocId}/${this.createdByUid}`
    this.matchService.createMatchRoomShortUrl(this.matchDocId, matchRoomLink)
  }
  // Getters
  public get isPlayer() {
    return this.isPlayer1 || this.isPlayer2
  }
  public get isPlayer1() {
    return this.auth.user.playerId === this.player1DocId
  }
  public get isPlayer2() {
    return this.auth.user.playerId === this.player2DocId
  }
  public get isOrganizer() {
    return this.matchRole === WebRtcCallerRole.ORGANIZER
  }
  public get isSpectator() {
    return !this.isOrganizer && !this.isPlayer
  }
  public get playerIs() {
    if (this.isPlayer1) {
      return 'player1'
    }
    if (this.isPlayer2) {
      return 'player2'
    }
  }
  public get opponentIs() {
    if (this.isPlayer1) {
      return 'player2'
    }
    if (this.isPlayer2) {
      return 'player1'
    }
  }
  public get opponentDocId() {
    if (this.auth.user.playerId === this.player1DocId) {
      return this.player2DocId
    }
    if (this.auth.user.playerId === this.player2DocId) {
      return this.player1DocId
    }
  }
  public get parsedMessageGroupDocId() {
    if (this.eventDocId === 'casual-match') {
      return 'casualMatch[' + this.matchDocId + ']'
    }
    else {
      return 'eventChatFor[' + this.eventDocId + ']'
    }
  }
  public get chatMode() {
    if (this.isPlayer || this.isPlayer && this.isOrganizer) {
      return false
    }
    else if (this.isOrganizer && this.organizerPlayerChatActive) {
      return false
    }
    else if (this.isOrganizer && !this.organizerPlayerChatActive) {
      return true
    }
    else if (this.isSpectator) {
      return true
    }
  }
  public myWebRtcCallerRole = async () => {
    return await this.matchService.getPlayerWebRtcRoleForMatchWithId(this.matchDocId)
  }

}

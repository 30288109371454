import { AuthService } from 'src/app/services/auth.service'
import { Component, Input, OnInit } from '@angular/core'
import { ITicket } from 'tolaria-cloud-functions/src/_interfaces'
import { BehaviorSubject } from 'rxjs'
import { TicketService } from 'src/app/services/ticket.service'
@Component({
  selector: 'app-event-tickets',
  templateUrl: './event-tickets.component.html',
  styleUrls: ['./event-tickets.component.css']
})
export class EventTicketsComponent implements OnInit {
  @Input() eventDocId: string

  tickets$: BehaviorSubject<ITicket[]> = new BehaviorSubject<ITicket[]>(null)

  constructor(
    private ticketService: TicketService,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.initTickets()
  }

  private async initTickets() {
    // console.log(`initTickets --> ${this.eventDocId}`)
    const tickets = await this.ticketService.getTicketsForEvent(this.eventDocId)
    this.tickets$.next(tickets)
  }

  public buyTicket(ticket: ITicket) {
    this.ticketService.buyTicket(ticket)
  }

}

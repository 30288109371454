import { Observable } from 'rxjs'
import { EventService, IEventListItem } from 'src/app/services'
import { faClock, faClone, faFileAlt } from '@fortawesome/free-regular-svg-icons'
import { faCalendarAlt, faMapMarkerAlt, faHatWizard, faChessRook, faScroll, faGavel, faTrophy, faCamera, faVideo, faUsers, faGlobe, faCoins } from '@fortawesome/free-solid-svg-icons'
import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.css']
})
export class EventInfoComponent implements OnInit {
  @Input() eventDocId: string
  @Input() showTickets: boolean = true

  faCalendarAlt = faCalendarAlt
  faMapMarkerAlt = faMapMarkerAlt
  faHatWizard = faHatWizard
  faClock = faClock
  faChessRook = faChessRook
  faScroll = faScroll
  faGavel = faGavel
  faClone = faClone
  faTrophy = faTrophy
  faVideo = faVideo
  faCamera = faCamera
  faFileAlt = faFileAlt
  faUsers = faUsers
  faGlobe = faGlobe
  faCoins = faCoins

  public eventListItem$: Observable<IEventListItem>

  constructor(
    private es: EventService
  ) {}

  ngOnInit(): void {
    this.eventListItem$ = this.es.getEventListItem(this.eventDocId)
  }
}

<div class="modal-header d-flex align-items-center">
    <div class="modal-title flex-grow-1 h5" id="modal-basic-title">Drop Player?</div>
    <div class="btn-close" aria-label="Close" (click)="modal.dismiss('cancel')"></div>
</div>


<div class="modal-body scrollable">

    <ng-container *ngIf="playerDropping$ | async as player">
        
        <ng-container [ngSwitch]="eventType">
    
    
            <ng-container *ngSwitchCase="'group'">
    
                <div class="">You are about to drop a <span class="text-bold">{{ player.name }}</span> from this ongoing
                    Group event, dropping can't be <b>UNDONE!</b></div>
                <div class="mb-3">Please choose how you would like to resolve this drop.</div>
    
                <form class="d-flex flex-column gap-3">
                    <div class="d-flex flex-column gap-2">
                        <div class="text-large font-weight-500 mb-2">
                            <div class="custom-control custom-radio d-flex align-items-center gap-2">
                                <input type="radio" id="radioDropJustDrop" name="radioDropType" class="form-check-input m-0"
                                    [(ngModel)]="exchangePlayer" [value]="false">
                                <label class="form-check-label" for="radioDropJustDrop">Just drop</label>
                            </div>
                        </div>
                        <div class="text-secondary">Dropping the player will mark the player as dropped and all
                            matches will be
                            inacivated (inactive matches are ignored when calculating standings).</div>
                    </div>
    
                    <div class="d-flex flex-column gap-2">
                        <div class="text-large font-weight-500 mb-2">
                            <div class="custom-control custom-radio d-flex align-items-center gap-2">
                                <input type="radio" id="radioDropExchange" name="radioDropType" class="form-check-input m-0"
                                    [(ngModel)]="exchangePlayer" [value]="true">
                                <label class="form-check-label" for="radioDropExchange">Exchange</label>
                            </div>
                        </div>
                        <div class="text-secondary">
                            Exchanging player will change all the matches and the player being dropped will be replaced with
                            the player
                            being added.<br>
                            Any results reported on matches will be reset during this action.
                        </div>
                    </div>
                </form>
    
                <div class="d-flex flex-column mt-3" *ngIf="exchangePlayer">
    
                    <app-player-search (output)="onPlayerSearch($event)" [manualEntry]="true"></app-player-search>
    
    
                    <div class="d-flex flex-row align-items-center mt-3 gap-3">

                        <div class="card rounded rounded-3 d-flex flex-column p-3 gap-3 align-items-center">
                            <span class="fs-5">Player dropping</span>
                            <app-player-avatar [playerDocId]="player.playerDocId"></app-player-avatar>
                            <app-player-display-name [playerDocId]="player.playerDocId" [name]="player.name"></app-player-display-name>
                        </div>

                        <div *ngIf="playerEntering$ | async as player"
                            class="card rounded rounded-3 d-flex flex-column p-3 gap-3 align-items-center"
                            [ngClass]="{ 'border border-danger' : playerEnteringAlreadyAttending }">
                            <span class="fs-5">Player entering</span>
                            <app-player-avatar [playerDocId]="player.id"></app-player-avatar>
                            <app-player-display-name [playerDocId]="player.id" [name]="player.name.display"></app-player-display-name>
                            <span *ngIf="playerEnteringAlreadyAttending" class="badge text-bg-danger">Player already enrolled in the event!</span>
                        </div>
    

                        
    
                    </div>
                </div>
            </ng-container>
    
            <ng-container *ngSwitchDefault>
                You are about to drop <span class="bold">{{ player.name }}</span>, are you sure?
            </ng-container>
    
        </ng-container>
        
    </ng-container>

</div>


<div class="modal-footer">

    <button class="btn btn-sm text-red"
        (click)="modal.dismiss('cancel')">
        Cancel
    </button>

    <button class="btn btn-sm btn-blank"
        (click)="close()"
        [disabled]="disableForm">
        OK
    </button>
</div>
<ng-container *ngIf="eventListItem$ | async as event">

  <!-- TODO: Fix this -->
  <!-- <app-event-tickets [eventDocId]="event.docId" *ngIf="event.registrationFee && showTickets"></app-event-tickets> -->

  <div class="row">
    <div class="col">
      <h6 class="card-subtitle mb-2" container="body" placement="top-left" ngbTooltip="Location">
        <fa-icon [fixedWidth]="true" [icon]="faMapMarkerAlt" class="me-1"></fa-icon>{{ event.locationName }}
      </h6>
    </div>
  </div>

  <div class="row {{ event.isOnline ? '' : 'mb-2' }}">
    <div class="col">
      <h6 class="card-subtitle organizer mb-2" container="body" placement="top-left" ngbTooltip="Organizer">
        <fa-icon [fixedWidth]="true" [icon]="faHatWizard" class="me-1"></fa-icon>
        <app-player-display-name [playerUid]="event.organizerUid" position="right"></app-player-display-name>
      </h6>
    </div>
  </div>

  <div class="row mb-2" *ngIf="event.isOnline">
    <div class="col">
      <h6 class="card-subtitle organizer mb-2" container="body" placement="top-left"
        ngbTooltip="Online tournament played over Webcam">
        <fa-icon [fixedWidth]="true" [icon]="faVideo" class="me-1"></fa-icon>Webcam Tournament
      </h6>
    </div>
  </div>

  <ng-container *ngIf="!event.isMultiDay">
    <div class="row">
      <div class="col-6">
        <h6 class="card-subtitle mb-2 text-secondary" container="body" placement="top-left"
          ngbTooltip="Event starting date">
          <fa-icon [fixedWidth]="true" [icon]="faCalendarAlt" class="me-1"></fa-icon>{{ event.datetimeFrom | date:
          'yyyy-MM-dd' }}
        </h6>
      </div>
      <div class="col-6">
        <h6 class="card-subtitle mb-2 text-secondary" container="body" placement="top-left"
          ngbTooltip="Event starting time (your time)">
          <fa-icon [fixedWidth]="true" [icon]="faClock" class="me-1"></fa-icon>{{ event.datetimeFrom | date: 'HH:mm' }}
        </h6>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="event.isMultiDay">
    <div class="row">
      <div class="col-6">
        <h6 class="card-subtitle mb-2 text-secondary" container="body" placement="top-left"
          ngbTooltip="Event starting date">
          <fa-icon [fixedWidth]="true" [icon]="faCalendarAlt" class="me-1"></fa-icon>{{ event.datetimeFrom | date:
          'yyyy-MM-dd' }}
        </h6>
      </div>
      <div class="col-6">
        <h6 class="card-subtitle mb-2 text-secondary" container="body" placement="top-left" ngbTooltip="Event ending date">
          {{ event.datetimeTo | date: 'yyyy-MM-dd' }}</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <h6 class="card-subtitle mb-2 text-secondary" container="body" placement="top-left"
          ngbTooltip="Event starting time (your time)">
          <fa-icon [fixedWidth]="true" [icon]="faClock" class="me-1"></fa-icon>{{ event.datetimeFrom | date: 'HH:mm' }}
        </h6>
      </div>
      <div class="col-6">
        <h6 class="card-subtitle mb-2 text-secondary" container="body" placement="top-left" ngbTooltip="Event ending time (your time)">
          {{ event.datetimeTo | date: 'HH:mm' }}
        </h6>
      </div>
    </div>
  </ng-container>

  <div class="row" *ngIf="event.GMT_offset !== ''">
    <div class="col">
      <h6 class="card-subtitle mb-2 text-secondary">
        <fa-icon [fixedWidth]="true" [icon]="faGlobe" class="me-1"></fa-icon>{{ event.datetimeFrom | date : 'yyyy-MM-dd HH:mm' : event.GMT_offset }} UTC: {{ event.GMT_offset }}
      </h6>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <h6 class="card-subtitle mb-2 text-secondary" container="body" placement="top-left" ngbTooltip="Event type">
        <fa-icon [fixedWidth]="true" [icon]="faChessRook" class="me-1"></fa-icon>{{ event.type }}
      </h6>
    </div>
    <div class="col">
      <h6 class="card-subtitle mb-2 text-secondary" container="body" placement="top-left" ngbTooltip="Format">
        <fa-icon [fixedWidth]="true" [icon]="faScroll" class="me-1"></fa-icon>{{ event.format }}
      </h6>
    </div>
  </div>

  <div class="row">
    <div class="col" *ngIf="event.rulesetName !== ''">
      <h6 class="card-subtitle mb-2 text-secondary" container="body" placement="top-left" ngbTooltip="Ruleset">
        <fa-icon [fixedWidth]="true" [icon]="faGavel" class="me-1"></fa-icon>{{ event.rulesetName }}
      </h6>
    </div>
    <div class="col" *ngIf="event.reprintPolicyName !== ''">
      <h6 class="card-subtitle mb-2 text-secondary" container="body" placement="top-left" ngbTooltip="Reprint Policy">
        <fa-icon [fixedWidth]="true" [icon]="faClone" class="me-1"></fa-icon>{{ event.reprintPolicyName }}
      </h6>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <h6 class="card-subtitle text-secondary mb-2" container="body" placement="top-left" ngbTooltip="Structure">
        <fa-icon [fixedWidth]="true" [icon]="faTrophy" class="me-1"></fa-icon>{{ event.structure }}
      </h6>
    </div>
    <div class="col" *ngIf="event.registrationFee">
      <h6 class="card-subtitle text-secondary mb-2" container="body" placement="top-left" ngbTooltip="Registration fee">
        <fa-icon [fixedWidth]="true" [icon]="faCoins" class="me-1"></fa-icon>{{ event.registrationFeeAmount }}
      </h6>
    </div>
  </div>

  <div class="row" *ngIf="event.deckPhoto || event.deckList">
    <div class="col" *ngIf="event.deckPhoto">
      <h6 class="card-subtitle mb-2 text-secondary" container="body" placement="top" ngbTooltip="Deck Photo Mandatory">
        <fa-icon [fixedWidth]="true" [icon]="faCamera" class="me-1"></fa-icon>Deck Photo
      </h6>
    </div>
    <div class="col" *ngIf="event.deckList">
      <h6 class="card-subtitle mb-2 text-secondary" container="body" placement="top-left" ngbTooltip="Deck List Mandatory">
        <fa-icon [fixedWidth]="true" [icon]="faFileAlt" class="me-1"></fa-icon>Deck List
      </h6>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <h6 class="card-subtitle text-secondary mb-2" container="body" placement="top-left" ngbTooltip="Attending players">
        <fa-icon [fixedWidth]="true" [icon]="faUsers" class="me-1"></fa-icon>{{ event.registrationOpen ? event.playerDocIds.length : 0 }} / {{
        event.playerCap === 0 ? '∞' : event.playerCap }}
      </h6>
    </div>
  </div>

</ng-container>

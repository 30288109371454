export interface IDeckList {
    name: string;
    description: string;
    docId: string;
    playerDocId: string;
    playerUid: string;
    timestampCreated: number;
    main: Array<ICardMeta>;
    sideboard: Array<ICardMeta>;
    maybeboard: Array<ICardMeta>;
    textList: string | null;
    deckPhotoUrl: string | null;
    imageUris: {
        small: string,
        medium: string,
        large: string,
        origin: string,
    }
    eventDocIds: Array<string>;
    cardIds: Array<string>; // scryfall uuid
    ratedByPlayerDocIds?: Array<string>;
    colors: Array<string>;
    isPrivate: boolean;
    isLocked: boolean;
    deckFolderDocId?: string;
    cardZoomLevel?: number;
    rating?: number;
    folderName?: string;
    versions?: IDeckVersion[];
    draftDocId?: string;
    sharingId?: string
}
export interface IDeckVersion {
    versionDocId: string;
    versionName: string;
}
export interface IDeckListVersion {
    deckDocId: string;
    docId: string;
    name: string;
    cardZoomLevel: number;
    isLocked: boolean;
    playerDocId: string;
    playerUid: string;
    timestampCreated: number;
    eventDocIds: Array<string>;
    colors: Array<string>;
    deckPhotoUrl: string | null;
    cardIds: Array<string>; // scryfall uuid
    main: Array<ICardMeta>;
    sideboard: Array<ICardMeta>;
    maybeboard: Array<ICardMeta>;
    sharingId?: string
}
export interface ICardMeta {
    cmc: number;
    name: string;
    scryfallId: string;
    typeLine: string;
    imageUrl: string;
    imageUris: ImageUris;
    metaUuid: string;
    setCode: string;
    colors: string[];
    coords?: {
        x: number;
        y: number;
    }
    cardId?: string;
}
export declare type ImageUris = {
    png: string;
    border_crop: string;
    art_crop: string;
    large: string;
    normal: string;
    small: string;
}
export interface Card {
    name: string
    scryfallId: string
    quantity: number
}
export interface DeckLibraryDoc {
    id: string
    base64: string
    name: string
    description: string
    playerDocId: string
    archetype: string[]
    tournamentId: string
    tournamentName: string
    rank: number | null
    publishedAt: number
    format: string[]
    metadata: {
        [key: string]: string
    }
    decklist: {
        main: Card[]
        sideboard: Card[]
    }
    deckListId: string
    deckVersionId: string
}

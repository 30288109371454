import { Subject } from 'rxjs';
import { CardSearchService, IMagicCardSet } from './../../../../services/card-search.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card-printings',
  templateUrl: './card-printings.component.html',
  styleUrls: ['./card-printings.component.css']
})
export class CardPrintingsComponent implements OnInit {
  @Input() id: string;
  @Output() updateCardId = new EventEmitter();

  public setList: Array<IMagicCardSet>;
  public infoLoaded$ = new Subject<boolean>();

  constructor(
    private cardSearch: CardSearchService
  ) {}

  ngOnInit(): void {
    this.cardSearch.getPrints(this.id).then(setItems => {
      if (setItems !== null) {
        this.setList = setItems;
      }
      this.infoLoaded$.next(true);
    });
  }

  doUpdateCardId(id: string) {
    this.updateCardId.emit(id);
  }

}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { MessageGroupItem, MessageGroupsService } from '../../social/messages/services/message-groups.service';
import { EventPlayer } from '../../tournament/classes/event-player.class';
import { EventMatch } from '../../tournament/services/helpers/utilities/match';
import { v4 as uuidv4 } from 'node_modules/uuid';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MessageSenderService, MessageType } from '../../social/messages/services/message-sender.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-casual-match-button',
  standalone: true,
  imports: [
    CommonModule,
  ],
  template: `<button [ngClass]="styleClass" (click)="onClick()">Casual game</button>`,
  styleUrls: ['./casual-match-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CasualMatchButtonComponent {
  @Input() styleClass: string = 'btn btn-sm btn-outline-secondary'
  @Input() playerId: string

  constructor(
    private readonly messageGroups: MessageGroupsService,
    private readonly playerNames: PlayerNameService,
    private readonly firestore: AngularFirestore,
    private readonly message: MessageSenderService,
    private readonly toast: ToastService,
  ) {

  }

  async onClick() {

    const opponent = this.playerNames.getPlayerById(this.playerId)

    const player1 = new EventPlayer(
      'casual-match',
      this.playerNames.currentPlayersMini.uid,
      this.playerNames.currentPlayersMini.id,
      this.playerNames.currentPlayersMini.name.display,
    )

    const player2 = new EventPlayer(
      'casual-match',
      opponent.uid,
      opponent.id,
      opponent.name.display,
    )

    const match = new EventMatch(
      'casual-match',
      this.playerNames.currentPlayersMini.uid,
      1,
      null,
      null,
      'casual-match',
      player1.doc,
      player2.doc,
      null,
      uuidv4(),
    )

    this.firestore
      .collection('matches')
      .doc(match.document.docId)
      .set(match.document)
      .then(async () => {

        // get message group
        const group = await this.messageGroups.getGroupByPlayers(match.document.playerDocIds) as any

        this.message.send({
          message: 'match-invitation',
          whisperMode: false,
          messageGroupDocId: group.id ? group.id : group.data ? group.data.id : '',
          replyTo: null,
          images: [],
          matchChat: false,
          matchDocId: match.document.docId,
          spectatorMode: false,
          type: MessageType.MATCH_INVITATION,
          matchDoc: match.document,
        })

      })
      .catch((e) => {
        console.error(e)
        this.toast.show(e, { classname: 'error-toast' })
      })
  }

}

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-scryfall-image-view',
  template: `
  <ng-container *ngIf="image$ | async as image">
    <img [src]="image">
  </ng-container>
  `,
  styles: [
    `
      :host {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
      }
    `
  ],
  standalone: true,
  imports: [
    CommonModule,
  ]
})
export class ScryfallImageViewComponent implements OnInit {
  @Input() scryfallId: string

  public image$ = new BehaviorSubject<string | null>(null)

  constructor() { }

  ngOnInit() {
    // Fetch card details from Scryfall API
    fetch(`https://api.scryfall.com/cards/${this.scryfallId}`)
    .then(response => response.json())
    .then(card => {
        this.image$.next(card.image_uris.large)
    })
    .catch(error => console.error('Error fetching card:', error))
  }

}

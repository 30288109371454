<ng-container *ngIf="matchDoc$ | async as matchDoc" style="min-width: 100%;">

  <div class="d-flex h-100" style="min-height: 90vh; max-height: 90vh;">

    <div class="card card-auto flex-grow-1">

      <div class="card-header d-flex align-items-center">
        <div class="flex-grow-1">Match Config</div>
        <app-online-users [onlineUsers$]="onlineUsers$"></app-online-users>
      </div>

      <div class="card-body scrollable no-hover ">

        <div class="d-flex align-items-center">
          <div class="h6 flex-grow-1">Toggle Feature Match Active</div>
          <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input" id="cardPrints" [(ngModel)]="matchDoc.isFeatured"
              (click)="toggleFeatureMatch(matchDoc)">
            <label class="form-check-label" for="cardPrints"></label>
          </div>
        </div>

        <div class="players d-flex align-items-center mt-5 mb-3">
          <div class="player-name h4 flex-grow-1 text-start" [innerText]="matchDoc.player1.displayName"></div>
          <span class="text-secondary h6 text-center">vs</span>
          <div class="player-name h4 flex-grow-1 text-end" [innerText]="matchDoc.player2.displayName"></div>
        </div>
        <div class="player-lifes d-flex align-items-center mb-5">
          <div class="player-life flex-grow-1 text-center p-3" [innerText]="matchDoc.player1.lifePoints[0]"></div>
          <div class="badge rounded-pill text-bg-secondary ms-3 game-wins" [innerText]="matchDoc.player1.wins"></div>
          <div class="game-wins-filler flex-grow-1"></div>
          <div class="badge rounded-pill text-bg-secondary me-3 game-wins" [innerText]="matchDoc.player2.wins"></div>
          <div class="player-life flex-grow-1 text-center p-3" [innerText]="matchDoc.player2.lifePoints[0]"></div>
        </div>

        <ng-container *ngIf="matchDoc?.featureUris && matchDoc.featureUris">
          <div class="text-large text-secondary">Feature match URLs</div>
          <div class="text-secondary d-flex align-items-center" [ngbTooltip]="matchDoc.featureUris.player1" container="body"
            placement="top">
            <div class="flex-grow-0 text-normal me-2 player-url-name">Player 1:</div>
            <div class="flex-grow-1 streaming-url text-secondary text-smaller" [innerText]="matchDoc.featureUris.player1">
            </div>
            <div class="btn-sm btn-blank btn me-2" ngbTooltip="Copy url" container="body" placement="top"
              (click)="copyText(matchDoc.featureUris.player1)">
              <fa-icon [icon]="faLink" [fixedWidth]="true"></fa-icon>
            </div>
          </div>
          <div class="text-secondary d-flex align-items-center" [ngbTooltip]="matchDoc.featureUris.player2" container="body"
            placement="top">
            <div class="flex-grow-0 text-normal me-2 player-url-name">Player 2:</div>
            <div class="flex-grow-1 streaming-url text-secondary text-smaller" [innerText]="matchDoc.featureUris.player2">
            </div>
            <div class="btn-sm btn-blank btn me-2" ngbTooltip="Copy url" container="body" placement="top"
              (click)="copyText(matchDoc.featureUris.player2)">
              <fa-icon [icon]="faLink" [fixedWidth]="true"></fa-icon>
            </div>
          </div>
        </ng-container>

        <div class="form-wrapper w-100 mt-3">
          <input type="text" class="form-control mb-2" [(ngModel)]="featureUris.player1"
            placeholder="Player 1 stream url">
          <input type="text" class="form-control mb-2" [(ngModel)]="featureUris.player2"
            placeholder="Player 2 stream url">
          <!-- <button class="btn btn-sm w-100 {{ matchDoc.isFeatured ? 'btn-success' : 'btn-secondary' }}" (click)="toggleFeatureMatch(matchDoc.featureUris ? matchDoc.featureUris : null)">{{ matchDoc.isFeatured ? 'Unset' : 'Set' }} as Feature Match</button> -->
        </div>

      </div>

      <div class="card-footer">
        <button class="btn btn-sm w-100 btn-secondary" (click)="updateFeatureMatchUris(matchDoc.docId)">Update Feature
          Match Urls</button>
      </div>

    </div>

    <div class="card flex-grow-0" [style.minWidth.rem]="30" style="margin-left: -1px;">
      <div class="card-header d-flex align-items-center justify-content-between">
        <span class="flex-grow-1">Match Chat with Players</span>
        <button (click)="modal.dismiss()" class="btn-close btn-sm"></button>
      </div>
      <div class="card-body p-0 no-hover d-flex flex-column" style="min-height: calc(90vh - 4rem);">
        <app-message-list
          class="flex-grow-1"
          [messageGroupDocId]="'eventChatFor[' + matchDoc.eventDocId + ']'"
          [matchChat]="true"
          [spectatorMode]="false"
          [matchDocId]="matchDocId">
        </app-message-list>
        <!-- <div #chatWrapper class="message-list-wrapper flex-grow-1" [scrollTop]="chatWrapper.scrollHeight">
        </div> -->

        <div class="message-form-wrapper p-2">
          <tolaria-message-form class="w-100"
            [messageGroupDocId]="'eventChatFor[' + matchDoc.eventDocId + ']'"
            [matchChat]="true"
            [spectatorMode]="false"
            >
          </tolaria-message-form>
        </div>
      </div>
    </div>
  </div>

</ng-container>

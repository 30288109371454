import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const tiskelionIcon: IconDefinition = {
  prefix: 'fac',
  iconName: 'triskelion',
  icon: [
    640, // SVG view box width
    414.248, // SVG view box height
    [],
    '', // probably not important for SVG and JS approach
    'M68.5,426.2l7.4-137.8l15-22.5l11.1-16.6l19.6,22l40,45l85.6-86.1l81.9,73.1l41-57.7l61.4,68.9l67-68.9l65.2,81.9l19.2-23.3 l32.9-39.9l24.2,162H111.3 M449.6,108.4c0-17.9-14.6-32.5-32.5-32.5c-17.9,0-32.5,14.6-32.5,32.5c0,17.9,14.6,32.5,32.5,32.5 C435,140.9,449.6,126.4,449.6,108.4z M309,108.4c0-17.9-14.6-32.5-32.5-32.5c-17.9,0-32.5,14.6-32.5,32.5 c0,17.9,14.6,32.5,32.5,32.5C294.4,140.9,309,126.4,309,108.4z M582.9,271.5l-19.2,23.3L498.5,213l-67,68.9L370.1,213l-41,57.7 l-81.9-73.1l-85.6,86.1l-40-45L139,63.2c77.6-79.9,354.1-88.4,423.8,0L582.9,271.5z M324.9,108.4c0-26.7-21.7-48.4-48.4-48.4 s-48.4,21.7-48.4,48.4c0,26.7,21.7,48.4,48.4,48.4S324.9,135.2,324.9,108.4z M465.5,108.4c0-26.7-21.7-48.4-48.4-48.4 c-26.7,0-48.4,21.7-48.4,48.4c0,26.7,21.7,48.4,48.4,48.4C443.8,156.8,465.5,135.2,465.5,108.4z M111.3,409.1H68.5 M53.3,425.2 l7.4-155.8L93.1,223L63.3,57.4C49.3,48.5,9.3,63.8,0,77.6l27.1,347.7H53.3z'
  ],
} as any;

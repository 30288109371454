<div class="modal-header d-flex align-items-center">
  <span class="text-large flex-grow-1">Points Breakdown</span>
  <span class="text-large">{{ player.leaguePoints }}p</span>
</div>
<div class="modal-body">
  <ng-container *ngFor="let point of player.leaguePointsInfo">
    <div class="points-wrapper d-flex flex-row align-items-center py-2">
      <div class="point-wrapper" [style.minWidth.rem]="4">
        <div class="badge text-bg-dark rounded-pill text-large">{{ point.points }}p</div>
      </div>

      <div class="info-wrapper flex-grow-1">
        {{ point.comment }}
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer d-flex justify-content-start">
  <span class="flex-grow-1">
    <app-player-display-name [playerDocId]="player.playerDocId"></app-player-display-name>
  </span>
  <div class="btn btn-sm btn-blank" (click)="modal.close()">Close</div>
</div>

<div class="card-group p-2">

    <ng-container *ngIf="myCards$ | async as cards">

        <ng-container *ngFor="let card of cards; trackBy: tracker">

            <div class="card cursor-pointer" ngbTooltip="Click to edit card" container="body"
                [routerLink]="['../edit', card.docId]">
                <img [src]="card.generatedPic" class="card-img">
                <div class="card-img-overlay d-flex flex-column">
                    <div class="card-title text-xlarge text-center flex-grow-1">{{ card.name }} </div>
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <div class="btn btn-sm btn-light">
                            <fa-icon [icon]="icons.like" [fixedWidth]="true" class="me-2"></fa-icon>{{ card.likedByPlayerDocId.length }}
                        </div>
                        <div class="btn btn-sm btn-light">
                            <fa-icon [icon]="icons.dislike" [fixedWidth]="true" class="me-2"></fa-icon>{{ card.dislikedByPlayerDocId.length }}
                        </div>
                        <div class="btn btn-sm btn-light">
                            <fa-icon [icon]="icons.comment" [fixedWidth]="true" class="me-2"></fa-icon>{{ card.comments }}
                        </div>
                    </div>
                    <div class="btn btn-sm btn-danger" (click)="$event.stopPropagation(); $event.preventDefault(); deleteCard(card.docId)">Delete</div>
                </div>
            </div>

        </ng-container>

    </ng-container>

</div>
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { PaymentService } from 'src/app/payment/payment.service';
import { EventService } from 'src/app/services/event/event.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IRegistrationFeeMeta, ProductType, PlatformFee, PaymentFactor } from 'tolaria-cloud-functions/src/_interfaces';
import { IEventDetails, IPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { PlayerNameService } from 'src/app/services/players/player-name.service';

@Component({
  selector: 'app-pay-registration-fee',
  templateUrl: './pay-registration-fee.component.html',
  styleUrls: ['./pay-registration-fee.component.css']
})
export class PayRegistrationFeeComponent implements OnInit, OnDestroy {

  private registrationFeeMeta: IRegistrationFeeMeta
  private event: IEventDetails
  private player: IPlayerDetails
  private destroyed$ = new Subject<boolean>()

  public donationAmount: number
  public charityAmount: number
  public creatingCheckout: boolean = false

  public init$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(
    private eventService: EventService,
    private readonly playerNames: PlayerNameService,
    private payment: PaymentService,
    private router: Router,
    private modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.playerNames.serviceReady$.pipe(takeUntil(this.destroyed$)).subscribe((ready) => ready ? this.init() : null)
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  private async init() {
    const params = this.router.url.split('/')
    const eventDocId = params[2]

    // get event document
    this.event = await this.eventService.getEventByIdPromise(eventDocId)
    console.log(this.event)

    // get player document
    this.player = this.playerNames.currentUserPlayerDoc$.getValue()
    console.log(this.player)

    // create registration fee meta
    this.registrationFeeMeta = {
      productType: ProductType.REGISTRATION_FEE,
      currency: this.event.details.registrationFee.currency.toUpperCase(),
      registrationFeeAmount: this.event.details.registrationFee.amount * PaymentFactor,
      donationAmount: 0,
      charityAmount: 0,
      platformCostAmount: (this.event.details.registrationFee.amount * PaymentFactor) * PlatformFee,
      platformFee: PlatformFee,
      eventDocId: this.event.docId,
      eventCreatedByUid: this.event.createdByUid,
      playerDocId: this.player.docId,
      playerUid: this.player.uid,
      playerEmail: this.player.email,
    }
    console.log(this.registrationFeeMeta)

    this.init$.next(true)

  }

  public proceedToCheckout(): void {
    // set busy on the checkout button
    this.creatingCheckout = true
    // add donation amount to the meta if any
    this.donationAmount !== undefined ? this.registrationFeeMeta.donationAmount = (this.donationAmount * PaymentFactor) : 0
    // add charity amount to the meta if any
    this.charityAmount !== undefined ? this.registrationFeeMeta.charityAmount = (this.charityAmount * PaymentFactor) : 0
    // update the platform cost as this might be changed due to added charity amount
    this.registrationFeeMeta.platformCostAmount = ((this.event.details.registrationFee.amount * PaymentFactor) + this.registrationFeeMeta.charityAmount) * PlatformFee

    // call service to create checkout session
    this.payment.createCheckoutSession.REGISTRATION_FEE(
      this.currency,
      this.amount,
      this.productName,
      this.registrationFeeMeta,
      this.donationAmount === undefined ? 0 : this.donationAmount,
      this.charityAmount === undefined ? 0 : this.charityAmount)
  }

  public close(): void {
    this.modal.close()
  }

  public get tolariaPayment(): boolean {
    return this.event.details.registrationFee.tolariaPayment
  }

  public get amount(): number {
    return this.event.details.registrationFee.amount
  }

  public get currency(): string {
    return this.event.details.registrationFee.currency.toUpperCase()
  }

  public get registrationFee(): string {
    return `${this.amount} ${this.currency}`
  }

  public get hasStripeAccount(): boolean {
    return this.player.stripeCustomerId ? true : false
  }

  public get stripeAccountNumber(): string {
    return this.hasStripeAccount ? this.player.stripeCustomerId : ''
  }

  public get totalAmount(): number {
    return this.amount + (this.charityAmount === undefined ? 0 : this.charityAmount) + (this.donationAmount === undefined ? 0 : this.donationAmount)
  }

  private get productName(): string {
    return `REGISTRATION_FEE: ${this.event.details.name}`
  }

}

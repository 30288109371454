import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { marked } from 'marked'

@Pipe({
  name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, args?: any[]): any {
    if (value && value.length > 0) {
      return this.sanitizer.bypassSecurityTrustHtml(marked(value))
    }
    return this.sanitizer.bypassSecurityTrustHtml(value)
  }
}
import { ToastService } from 'src/app/services/toast.service';
import { MatchService } from 'src/app/services/match.service';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { IEventDetails, IEventPlayerDetails, IMatchData, ISingleBracketMatrixMatch } from 'tolaria-cloud-functions/src/_interfaces';
import { Component, Input, OnInit } from '@angular/core';
import { faBroadcastTower, faCamera, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { AuthService, EventService } from 'src/app/services';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DeckListComponent } from 'src/app/components/decks/deck-list/deck-list.component';
import { DeckPhotoComponent } from 'src/app/components/decks/deck-photo/deck-photo.component';

@Component({
  selector: 'app-event-bracket-match',
  templateUrl: './event-bracket-match.component.html',
  styleUrls: ['./event-bracket-match.component.css']
})
export class EventBracketMatchComponent implements OnInit {
  @Input() matchInfo: ISingleBracketMatrixMatch;
  @Input() eventDocId: string;
  @Input() isOrganizer: boolean;
  @Input() isOnlineTournament: boolean;
  @Input() pathToVictory: ISingleBracketMatrixMatch;

  public icons = {
    deckPhoto: faCamera,
    deckList: faFileAlt,
    stream: faBroadcastTower,
    menu: faEllipsisH,
  }

  private event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)
  public player1$: BehaviorSubject<IEventPlayerDetails> = new BehaviorSubject<IEventPlayerDetails>(null)
  public player2$: BehaviorSubject<IEventPlayerDetails> = new BehaviorSubject<IEventPlayerDetails>(null)

  matchDoc$: Observable<IMatchData>;

  constructor(
    private matchService: MatchService,
    private eventService: EventService,
    private modalService: NgbModal,
    private auth: AuthService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    if (this.auth.user.role === 'admin') { this.isOrganizer = true }
    if (this.matchInfo.matchDocId !== null) {
      this.matchDoc$ = this.matchService.getMatchDocById(this.matchInfo.matchDocId);
      this.matchDoc$.pipe(take(1)).subscribe(async (match) => {
        this.event$.next(await this.eventService.getEventByIdPromise(this.eventDocId))
        const eventPlayers = await this.eventService.getEventPlayersByEventIdPromise(this.eventDocId)
        this.player1$.next(eventPlayers.find(i => i.playerDocId === match.player1.playerDocId))
        this.player2$.next(eventPlayers.find(i => i.playerDocId === match.player2.playerDocId))
      })
    }
  }

  public get deckPhoto(): boolean {
    if (this.event$.getValue() === null) { return false }
    return this.event$.getValue().details.deckPhoto
  }

  public get deckList(): boolean {
    if (this.event$.getValue() === null) { return false }
    return this.event$.getValue().details.deckList
  }

  public get deckInfoPublic(): boolean {
    if (this.event$.getValue() === null) { return false }
    return this.event$.getValue().details.deckInfoIsPublic
  }

  public get pathToHereSelected(): boolean {
    return this.pathToVictory === null
      ? false
      : this.matchInfo.matchDocId === this.pathToVictory.feedsMatchDocId
  }

  public get pathToHerePlayerSlot(): string {
    return this.pathToHereSelected
      ? this.pathToVictory.feedsPlayerSlot
      : ''
  }

  openDeck(player: 'player1' | 'player2', type: 'photo' | 'list'): void {

    let selectedPlayer
    switch (player) {
      case 'player1':
        selectedPlayer = this.player1$.getValue()
        break
      case 'player2':
        selectedPlayer = this.player2$.getValue()
        break
    }


    switch (type) {
      case 'list':
        const modalOptionsList: NgbModalOptions = {
          centered: false,
          animation: true,
          backdrop: true,
          keyboard: true,
          size: 'xl',
        }
        const modalRefList: NgbModalRef = this.modalService.open(DeckListComponent, modalOptionsList);
        modalRefList.componentInstance.wrapperClass = 'p-3';
        modalRefList.componentInstance.deckListDocId = selectedPlayer.deckSubmission.deckListDocId
        modalRefList.componentInstance.deckListVersionDocId = selectedPlayer.deckSubmission.deckVersionDocId

      case 'photo':
        const modalOptionsPhoto: NgbModalOptions = {
          centered: false,
          animation: true,
          backdrop: true,
          keyboard: true,
          size: 'xl',
        }
        const modalRefPhoto: NgbModalRef = this.modalService.open(DeckPhotoComponent, modalOptionsPhoto)
        modalRefPhoto.componentInstance.deckListDocId = selectedPlayer.deckSubmission.deckListDocId
        modalRefPhoto.componentInstance.deckListVersionDocId = selectedPlayer.deckSubmission.deckVersionDocId
    }

  }

  openReportSlip(matchDoc: IMatchData) {
    console.log('about to open report slip for match with id: ', matchDoc.docId);
    this.matchService.openReportSlip(matchDoc, {});
  }

  openFeatureMatchConfig(matchDocId: string): void {
    this.matchService.openFeatureMatchConfig(matchDocId);
  }

  copyMatchId(matchDocId: string): void {
    const el = document.createElement('textarea')
    el.value = matchDocId
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    this.toastService.show('Match document id copied to clipboard', { classname: 'standard-toast', delay: 2000 })
  }
}

import { DonationType } from 'tolaria-cloud-functions/src/_interfaces';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { PaymentService, IPurchaseMeta } from 'src/app/payment/payment.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { faCcAmazonPay, faCcAmex, faCcApplePay, faCcDinersClub, faCcDiscover, faCcJcb, faCcMastercard, faCcPaypal, faCcStripe, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-purchase-receipt',
  templateUrl: './purchase-receipt.component.html',
  styleUrls: ['./purchase-receipt.component.css']
})
export class PurchaseReceiptComponent implements OnInit, OnDestroy {

  public icons = {
    visa: faCcVisa,
    paypal: faCcPaypal,
    mastercard: faCcMastercard,
    jcb: faCcJcb,
    diners: faCcDinersClub,
    discover: faCcDiscover,
    applepay: faCcApplePay,
    amex: faCcAmex,
    amazonpay: faCcAmazonPay,
    stripe: faCcStripe,
  }
  public charge$: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  public error: boolean = false
  private componentDestroyed$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private payment: PaymentService,
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.componentDestroyed$)).subscribe(q => {
      if (q.session_id) {
        this.getReceipt(q.session_id)
      }
      else {
        this.getReceipt()
      }
    })
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
  }

  private async getReceipt(checkoutSessionId: string = null) {
    this.payment.getLatestCharge(null, 1, checkoutSessionId).then(res => {
      if (res === null) {
        this.error = true
      }
      this.charge$.next(res)
    })
  }

  public close(): void {
    this.activeModal.close();
  }

  public get receiptUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.charge$.getValue().receipt as string)
  }

  public get donation(): boolean {
    const charge = this.charge$.getValue()
    return charge === null
      ? false
      : charge.metadata?.productType
       ? charge.metadata.productType === 'DONATION'
       : charge.metadata.type === DonationType.SUBSCRIPTION || charge.metadata.type === DonationType.ONE_TIME
        ? true
        : false
  }

}

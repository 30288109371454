import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { NgbModule, NgbPopover, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
import { TolariaEmojiPickerComponent } from '../tolaria-emoji-picker.component'
import { EmojiItem } from '../emojis'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { Options } from '@popperjs/core'
import QuillType from 'quill'
import { tolReaction } from '../../tolaria-wysiwyg/assets/tol-reaction.icon'

type Placement = 'top' | 'top-start' | 'top-left' | 'top-end' | 'top-right' | 'bottom' | 'bottom-start' |
  'bottom-left' | 'bottom-end' | 'bottom-right' | 'start' | 'left' | 'start-top' | 'left-top' | 'start-bottom' |
  'left-bottom' | 'end' | 'right' | 'end-top' | 'right-top' | 'end-bottom' | 'right-bottom' | 'auto'

@Component({
  selector: 'tolaria-emoji-picker-button',
  templateUrl: './tolaria-emoji-picker-button.component.html',
  styleUrls: ['./tolaria-emoji-picker-button.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    NgbModule,
    TolariaEmojiPickerComponent,
    FontAwesomeModule,
    NgbPopoverModule,
    NgbTooltipModule,
  ],
})
export class TolariaEmojiPickerButtonComponent {
  @ViewChild('popover', { static: true }) popover: NgbPopover
  @Output() onEmojiSelected = new EventEmitter<EmojiItem>()
  @Input() placement: Placement = 'auto'
  @Input() buttonStyle: string = 'btn btn-sm btn-outline-primary'
  @Input() buttonHoverStyle: string = ''
  @Input() tooltipText: string = 'Insert emoji'
  @Input() tooltipDisabled: boolean = false
  @Input() iconStyle: string = ''
  @Input() showText: boolean = false
  @Input() buttonText: string = 'Pick emoji'
  @Input() showIcon: boolean = true
  @Input() buttonIcon: IconDefinition = tolReaction
  @Input() buttonHoverIcon: IconDefinition = tolReaction
  @Input() editor: QuillType = null

  public isHovered: boolean = false
  public popperOptions = (options: Partial<Options>) => {
    if (this.editor !== null && this.editor !== undefined) {
      // customize modifiers
      for (const modifier of options.modifiers || []) {
        // disable flip
        if (modifier.name === 'flip') {
          modifier.enabled = false
        }
        // customize offset
        if (modifier.name === 'offset' && modifier.options) {
          let editorWidth = (this.editor as any).container.getBoundingClientRect().width
          let editorheight = (this.editor as any).container.getBoundingClientRect().height
          let selection = this.editor.getSelection(true)
          let bounds = this.editor.getBounds(selection.index, selection.length)
          let xAxis = (bounds.left - 8) + 64
          let yAxis = ((-1 * bounds.top) + 16 + editorheight)
          let range: any
          if ((xAxis + 364) > (editorWidth + 64)) {
            xAxis = (editorWidth - 364) + 64
          }
          if (editorWidth < 364) {
            range = [0, yAxis]
          }
          else {
            range = [xAxis, yAxis]
          }
          modifier.options['offset'] = () => range
        }
      }
    }
    return options
  }

  constructor() { }
  
  public onEmojiSelect(emoji: EmojiItem) {
    this.onEmojiSelected.emit(emoji)
    this.popover.close()
  }

}

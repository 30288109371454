import { Component, Input } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces';
import { IEventDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { Observable } from 'rxjs';
import { EventService, GlobalsService } from 'src/app/services';
import { EventToBatchConfigComponent } from '../event-to-batch-config/event-to-batch-config.component';

@Component({
  selector: 'app-more-actions-batch',
  templateUrl: './more-actions-batch.component.html',
  styleUrls: ['./more-actions-batch.component.css']
})
export class MoreActionsBatchComponent {
  @Input() eventDoc$: Observable<IEventDetails>;

  faChevronRight = faChevronRight

  public submenuOpen = false

  constructor(
    private es: EventService,
    private globals: GlobalsService,
    private modalService: NgbModal
  ) { }

  openNewBacthModal(event: IEventDetails): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'large',
      // windowClass: 'modal-full-size'
    };
    const modalRef: NgbModalRef = this.modalService.open(EventToBatchConfigComponent, modalOptions);
    modalRef.componentInstance.event = event;
  }

  addPlayoffBracket(event: IEventDetails) {
    this.es.addPlayoffBracket(event);
  }

  async removePlayoffBracket(event: IEventDetails) {
    console.log('removePlayoffBracket');
    if (event.statusCode === 7) {
      // need to remove the matches before removing the playoff
      console.log('... remove matches');
      await this.es.deleteAllBracketMatches(event.docId)
    }
    await this.es.removePlayoffBracket(event)
    this.es.eventUpdateStatus(event, 12, false);
  }

  deleteActiveBatch(event: IEventDetails) {
    console.log('delete active batch');
    // get the status before deleting the batch as this also updates the active round.
    const status = event.activeRound === 1 ? 1 : 4;
    // set app busy and show info text
    this.globals.isBusy.status = true;
    this.globals.isBusy.message = 'Deleting all pairings for current round and updating event status';
    // call event service to execute the unpairing
    this.es.eventDeleteActiveBatch(event)
      .then((response: IPromiseResponse) => {
        // returning from event service
        console.log(response);
        if (response.status) {
          // update event status to unpaired
          this.globals.isBusy.message = response.text;
          // set the status, if it's the first round that is deleted, then status should be 1,
          // else status should be 12 cause the previous round is already finished
          this.es.eventUpdateStatus(event, status, false).then((lowerResponse: IPromiseResponse) => {
            if (lowerResponse.status) {
              this.globals.isBusy.message = lowerResponse.text;
              // set app to not busy
              setTimeout(() => {
                this.globals.isBusy.status = false;
              }, 1000);
            }
            else {
              this.globals.isBusy.message = lowerResponse.text;
              // set app to not busy
              setTimeout(() => {
                this.globals.isBusy.status = false;
              }, 3000);
            }
          });
        }
        else {
          this.globals.isBusy.message = 'Error deleting paired matches for active round...';
          console.log(response);
          setTimeout(() => {
            this.globals.isBusy.status = false;
          }, 3000);
        }
      });
  }

  manualPairingMode(event: IEventDetails) {
    this.es.eventUpdateStatus(event, 11);
  }

}

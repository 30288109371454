<div class="team-list-item p-2 px-3">
  <div class="team-header d-flex align-items-center">
    <div class="team-title flex-grow-1">{{ team.name }}</div>

    <button class="btn btn-sm btn-blank edit-button text-secondary" (click)="editTeam(team)">
      <fa-icon class="flex-grow-0" [fixedWidth]="true" [icon]="editIcon"></fa-icon>
    </button>

    <div ngbDropdown class="d-inline-block" container="body">
      <button class="btn btn-sm btn-blank text-secondary" id="playerAction" ngbDropdownToggle>
        <fa-icon class="chevron flex-grow-0" [fixedWidth]="true" [icon]="faEllipsisH"></fa-icon>
      </button>
      <div ngbDropdownMenu aria-labelledby="playerAction">
        <button ngbDropdownItem (click)="unattend()" [disabled]="eventStarted">Unattend</button>
        <button ngbDropdownItem (click)="drop()" *ngIf="eventStarted" [disabled]="team.dropped">Drop</button>
        <button ngbDropdownItem (click)="undrop()" *ngIf="eventStarted" [disabled]="!team.dropped">Undrop</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem [disabled]="true">Add Warning</button>
      </div>
    </div>

  </div>
  <div class="player-list">
    <div class="player event-player-list pt-2 pb-2 d-flex align-items-center" *ngFor="let player of teamPlayers">
      <div class="badge text-bg-secondary me-2 text-uppercase">{{ player.teamSeat }}</div>
      <app-event-to-attending-player [event]="event" [player]="player"></app-event-to-attending-player>
    </div>
  </div>
</div>

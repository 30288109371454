import { ITimeZone } from 'tolaria-cloud-functions/src/_interfaces';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor() { }

  countryList: Array<ICountry> = [
    {
      name: 'Afghanistan',
      code: 'AF',
      callingCodes: [
        '93'
      ],
      region: 'Asia',
      subregion: 'Southern Asia'
    },
    {
      name: 'Åland Islands',
      code: 'AX',
      callingCodes: [
        '358'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'Albania',
      code: 'AL',
      callingCodes: [
        '355'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Algeria',
      code: 'DZ',
      callingCodes: [
        '213'
      ],
      region: 'Africa',
      subregion: 'Northern Africa'
    },
    {
      name: 'American Samoa',
      code: 'AS',
      callingCodes: [
        '1684'
      ],
      region: 'Oceania',
      subregion: 'Polynesia'
    },
    {
      name: 'Andorra',
      code: 'AD',
      callingCodes: [
        '376'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Angola',
      code: 'AO',
      callingCodes: [
        '244'
      ],
      region: 'Africa',
      subregion: 'Middle Africa'
    },
    {
      name: 'Anguilla',
      code: 'AI',
      callingCodes: [
        '1264'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Antarctica',
      code: 'AQ',
      callingCodes: [
        '672'
      ],
      region: 'Polar',
      subregion: ''
    },
    {
      name: 'Antigua and Barbuda',
      code: 'AG',
      callingCodes: [
        '1268'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Argentina',
      code: 'AR',
      callingCodes: [
        '54'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'Armenia',
      code: 'AM',
      callingCodes: [
        '374'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Aruba',
      code: 'AW',
      callingCodes: [
        '297'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Australia',
      code: 'AU',
      callingCodes: [
        '61'
      ],
      region: 'Oceania',
      subregion: 'Australia and New Zealand'
    },
    {
      name: 'Austria',
      code: 'AT',
      callingCodes: [
        '43'
      ],
      region: 'Europe',
      subregion: 'Western Europe'
    },
    {
      name: 'Azerbaijan',
      code: 'AZ',
      callingCodes: [
        '994'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Bahamas',
      code: 'BS',
      callingCodes: [
        '1242'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Bahrain',
      code: 'BH',
      callingCodes: [
        '973'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Bangladesh',
      code: 'BD',
      callingCodes: [
        '880'
      ],
      region: 'Asia',
      subregion: 'Southern Asia'
    },
    {
      name: 'Barbados',
      code: 'BB',
      callingCodes: [
        '1246'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Belarus',
      code: 'BY',
      callingCodes: [
        '375'
      ],
      region: 'Europe',
      subregion: 'Eastern Europe'
    },
    {
      name: 'Belgium',
      code: 'BE',
      callingCodes: [
        '32'
      ],
      region: 'Europe',
      subregion: 'Western Europe'
    },
    {
      name: 'Belize',
      code: 'BZ',
      callingCodes: [
        '501'
      ],
      region: 'Americas',
      subregion: 'Central America'
    },
    {
      name: 'Benin',
      code: 'BJ',
      callingCodes: [
        '229'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Bermuda',
      code: 'BM',
      callingCodes: [
        '1441'
      ],
      region: 'Americas',
      subregion: 'Northern America'
    },
    {
      name: 'Bhutan',
      code: 'BT',
      callingCodes: [
        '975'
      ],
      region: 'Asia',
      subregion: 'Southern Asia'
    },
    {
      name: 'Bolivia (Plurinational State of)',
      code: 'BO',
      callingCodes: [
        '591'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'Bonaire, Sint Eustatius and Saba',
      code: 'BQ',
      callingCodes: [
        '5997'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Bosnia and Herzegovina',
      code: 'BA',
      callingCodes: [
        '387'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Botswana',
      code: 'BW',
      callingCodes: [
        '267'
      ],
      region: 'Africa',
      subregion: 'Southern Africa'
    },
    {
      name: 'Bouvet Island',
      code: 'BV',
      callingCodes: [
        ''
      ],
      region: '',
      subregion: ''
    },
    {
      name: 'Brazil',
      code: 'BR',
      callingCodes: [
        '55'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'British Indian Ocean Territory',
      code: 'IO',
      callingCodes: [
        '246'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'United States Minor Outlying Islands',
      code: 'UM',
      callingCodes: [
        ''
      ],
      region: 'Americas',
      subregion: 'Northern America'
    },
    {
      name: 'Virgin Islands (British)',
      code: 'VG',
      callingCodes: [
        '1284'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Virgin Islands (U.S.)',
      code: 'VI',
      callingCodes: [
        '1 340'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Brunei Darussalam',
      code: 'BN',
      callingCodes: [
        '673'
      ],
      region: 'Asia',
      subregion: 'South-Eastern Asia'
    },
    {
      name: 'Bulgaria',
      code: 'BG',
      callingCodes: [
        '359'
      ],
      region: 'Europe',
      subregion: 'Eastern Europe'
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
      callingCodes: [
        '226'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Burundi',
      code: 'BI',
      callingCodes: [
        '257'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Cambodia',
      code: 'KH',
      callingCodes: [
        '855'
      ],
      region: 'Asia',
      subregion: 'South-Eastern Asia'
    },
    {
      name: 'Cameroon',
      code: 'CM',
      callingCodes: [
        '237'
      ],
      region: 'Africa',
      subregion: 'Middle Africa'
    },
    {
      name: 'Canada',
      code: 'CA',
      callingCodes: [
        '1'
      ],
      region: 'Americas',
      subregion: 'Northern America'
    },
    {
      name: 'Cabo Verde',
      code: 'CV',
      callingCodes: [
        '238'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Cayman Islands',
      code: 'KY',
      callingCodes: [
        '1345'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Central African Republic',
      code: 'CF',
      callingCodes: [
        '236'
      ],
      region: 'Africa',
      subregion: 'Middle Africa'
    },
    {
      name: 'Chad',
      code: 'TD',
      callingCodes: [
        '235'
      ],
      region: 'Africa',
      subregion: 'Middle Africa'
    },
    {
      name: 'Chile',
      code: 'CL',
      callingCodes: [
        '56'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'China',
      code: 'CN',
      callingCodes: [
        '86'
      ],
      region: 'Asia',
      subregion: 'Eastern Asia'
    },
    {
      name: 'Christmas Island',
      code: 'CX',
      callingCodes: [
        '61'
      ],
      region: 'Oceania',
      subregion: 'Australia and New Zealand'
    },
    {
      name: 'Cocos (Keeling) Islands',
      code: 'CC',
      callingCodes: [
        '61'
      ],
      region: 'Oceania',
      subregion: 'Australia and New Zealand'
    },
    {
      name: 'Colombia',
      code: 'CO',
      callingCodes: [
        '57'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'Comoros',
      code: 'KM',
      callingCodes: [
        '269'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Congo',
      code: 'CG',
      callingCodes: [
        '242'
      ],
      region: 'Africa',
      subregion: 'Middle Africa'
    },
    {
      name: 'Congo (Democratic Republic of the)',
      code: 'CD',
      callingCodes: [
        '243'
      ],
      region: 'Africa',
      subregion: 'Middle Africa'
    },
    {
      name: 'Cook Islands',
      code: 'CK',
      callingCodes: [
        '682'
      ],
      region: 'Oceania',
      subregion: 'Polynesia'
    },
    {
      name: 'Costa Rica',
      code: 'CR',
      callingCodes: [
        '506'
      ],
      region: 'Americas',
      subregion: 'Central America'
    },
    {
      name: 'Croatia',
      code: 'HR',
      callingCodes: [
        '385'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Cuba',
      code: 'CU',
      callingCodes: [
        '53'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Curaçao',
      code: 'CW',
      callingCodes: [
        '599'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Cyprus',
      code: 'CY',
      callingCodes: [
        '357'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Czech Republic',
      code: 'CZ',
      callingCodes: [
        '420'
      ],
      region: 'Europe',
      subregion: 'Eastern Europe'
    },
    {
      name: 'Denmark',
      code: 'DK',
      callingCodes: [
        '45'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'Djibouti',
      code: 'DJ',
      callingCodes: [
        '253'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Dominica',
      code: 'DM',
      callingCodes: [
        '1767'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Dominican Republic',
      code: 'DO',
      callingCodes: [
        '1809',
        '1829',
        '1849'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Ecuador',
      code: 'EC',
      callingCodes: [
        '593'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'Egypt',
      code: 'EG',
      callingCodes: [
        '20'
      ],
      region: 'Africa',
      subregion: 'Northern Africa'
    },
    {
      name: 'El Salvador',
      code: 'SV',
      callingCodes: [
        '503'
      ],
      region: 'Americas',
      subregion: 'Central America'
    },
    {
      name: 'Equatorial Guinea',
      code: 'GQ',
      callingCodes: [
        '240'
      ],
      region: 'Africa',
      subregion: 'Middle Africa'
    },
    {
      name: 'Eritrea',
      code: 'ER',
      callingCodes: [
        '291'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Estonia',
      code: 'EE',
      callingCodes: [
        '372'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'Ethiopia',
      code: 'ET',
      callingCodes: [
        '251'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Falkland Islands (Malvinas)',
      code: 'FK',
      callingCodes: [
        '500'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'Faroe Islands',
      code: 'FO',
      callingCodes: [
        '298'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'Fiji',
      code: 'FJ',
      callingCodes: [
        '679'
      ],
      region: 'Oceania',
      subregion: 'Melanesia'
    },
    {
      name: 'Finland',
      code: 'FI',
      callingCodes: [
        '358'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'France',
      code: 'FR',
      callingCodes: [
        '33'
      ],
      region: 'Europe',
      subregion: 'Western Europe'
    },
    {
      name: 'French Guiana',
      code: 'GF',
      callingCodes: [
        '594'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'French Polynesia',
      code: 'PF',
      callingCodes: [
        '689'
      ],
      region: 'Oceania',
      subregion: 'Polynesia'
    },
    {
      name: 'French Southern Territories',
      code: 'TF',
      callingCodes: [
        ''
      ],
      region: 'Africa',
      subregion: 'Southern Africa'
    },
    {
      name: 'Gabon',
      code: 'GA',
      callingCodes: [
        '241'
      ],
      region: 'Africa',
      subregion: 'Middle Africa'
    },
    {
      name: 'Gambia',
      code: 'GM',
      callingCodes: [
        '220'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Georgia',
      code: 'GE',
      callingCodes: [
        '995'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Germany',
      code: 'DE',
      callingCodes: [
        '49'
      ],
      region: 'Europe',
      subregion: 'Western Europe'
    },
    {
      name: 'Ghana',
      code: 'GH',
      callingCodes: [
        '233'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Gibraltar',
      code: 'GI',
      callingCodes: [
        '350'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Greece',
      code: 'GR',
      callingCodes: [
        '30'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Greenland',
      code: 'GL',
      callingCodes: [
        '299'
      ],
      region: 'Americas',
      subregion: 'Northern America'
    },
    {
      name: 'Grenada',
      code: 'GD',
      callingCodes: [
        '1473'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Guadeloupe',
      code: 'GP',
      callingCodes: [
        '590'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Guam',
      code: 'GU',
      callingCodes: [
        '1671'
      ],
      region: 'Oceania',
      subregion: 'Micronesia'
    },
    {
      name: 'Guatemala',
      code: 'GT',
      callingCodes: [
        '502'
      ],
      region: 'Americas',
      subregion: 'Central America'
    },
    {
      name: 'Guernsey',
      code: 'GG',
      callingCodes: [
        '44'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'Guinea',
      code: 'GN',
      callingCodes: [
        '224'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Guinea-Bissau',
      code: 'GW',
      callingCodes: [
        '245'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Guyana',
      code: 'GY',
      callingCodes: [
        '592'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'Haiti',
      code: 'HT',
      callingCodes: [
        '509'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Heard Island and McDonald Islands',
      code: 'HM',
      callingCodes: [
        ''
      ],
      region: '',
      subregion: ''
    },
    {
      name: 'Holy See',
      code: 'VA',
      callingCodes: [
        '379'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Honduras',
      code: 'HN',
      callingCodes: [
        '504'
      ],
      region: 'Americas',
      subregion: 'Central America'
    },
    {
      name: 'Hong Kong',
      code: 'HK',
      callingCodes: [
        '852'
      ],
      region: 'Asia',
      subregion: 'Eastern Asia'
    },
    {
      name: 'Hungary',
      code: 'HU',
      callingCodes: [
        '36'
      ],
      region: 'Europe',
      subregion: 'Eastern Europe'
    },
    {
      name: 'Iceland',
      code: 'IS',
      callingCodes: [
        '354'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'India',
      code: 'IN',
      callingCodes: [
        '91'
      ],
      region: 'Asia',
      subregion: 'Southern Asia'
    },
    {
      name: 'Indonesia',
      code: 'ID',
      callingCodes: [
        '62'
      ],
      region: 'Asia',
      subregion: 'South-Eastern Asia'
    },
    {
      name: 'Côte d\'Ivoire',
      code: 'CI',
      callingCodes: [
        '225'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Iran (Islamic Republic of)',
      code: 'IR',
      callingCodes: [
        '98'
      ],
      region: 'Asia',
      subregion: 'Southern Asia'
    },
    {
      name: 'Iraq',
      code: 'IQ',
      callingCodes: [
        '964'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Ireland',
      code: 'IE',
      callingCodes: [
        '353'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'Isle of Man',
      code: 'IM',
      callingCodes: [
        '44'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'Israel',
      code: 'IL',
      callingCodes: [
        '972'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Italy',
      code: 'IT',
      callingCodes: [
        '39'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Jamaica',
      code: 'JM',
      callingCodes: [
        '1876'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Japan',
      code: 'JP',
      callingCodes: [
        '81'
      ],
      region: 'Asia',
      subregion: 'Eastern Asia'
    },
    {
      name: 'Jersey',
      code: 'JE',
      callingCodes: [
        '44'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'Jordan',
      code: 'JO',
      callingCodes: [
        '962'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Kazakhstan',
      code: 'KZ',
      callingCodes: [
        '76',
        '77'
      ],
      region: 'Asia',
      subregion: 'Central Asia'
    },
    {
      name: 'Kenya',
      code: 'KE',
      callingCodes: [
        '254'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Kiribati',
      code: 'KI',
      callingCodes: [
        '686'
      ],
      region: 'Oceania',
      subregion: 'Micronesia'
    },
    {
      name: 'Kuwait',
      code: 'KW',
      callingCodes: [
        '965'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Kyrgyzstan',
      code: 'KG',
      callingCodes: [
        '996'
      ],
      region: 'Asia',
      subregion: 'Central Asia'
    },
    {
      name: 'Lao People\'s Democratic Republic',
      code: 'LA',
      callingCodes: [
        '856'
      ],
      region: 'Asia',
      subregion: 'South-Eastern Asia'
    },
    {
      name: 'Latvia',
      code: 'LV',
      callingCodes: [
        '371'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'Lebanon',
      code: 'LB',
      callingCodes: [
        '961'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Lesotho',
      code: 'LS',
      callingCodes: [
        '266'
      ],
      region: 'Africa',
      subregion: 'Southern Africa'
    },
    {
      name: 'Liberia',
      code: 'LR',
      callingCodes: [
        '231'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Libya',
      code: 'LY',
      callingCodes: [
        '218'
      ],
      region: 'Africa',
      subregion: 'Northern Africa'
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
      callingCodes: [
        '423'
      ],
      region: 'Europe',
      subregion: 'Western Europe'
    },
    {
      name: 'Lithuania',
      code: 'LT',
      callingCodes: [
        '370'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'Luxembourg',
      code: 'LU',
      callingCodes: [
        '352'
      ],
      region: 'Europe',
      subregion: 'Western Europe'
    },
    {
      name: 'Macao',
      code: 'MO',
      callingCodes: [
        '853'
      ],
      region: 'Asia',
      subregion: 'Eastern Asia'
    },
    {
      name: 'Macedonia (the former Yugoslav Republic of)',
      code: 'MK',
      callingCodes: [
        '389'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Madagascar',
      code: 'MG',
      callingCodes: [
        '261'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Malawi',
      code: 'MW',
      callingCodes: [
        '265'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Malaysia',
      code: 'MY',
      callingCodes: [
        '60'
      ],
      region: 'Asia',
      subregion: 'South-Eastern Asia'
    },
    {
      name: 'Maldives',
      code: 'MV',
      callingCodes: [
        '960'
      ],
      region: 'Asia',
      subregion: 'Southern Asia'
    },
    {
      name: 'Mali',
      code: 'ML',
      callingCodes: [
        '223'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Malta',
      code: 'MT',
      callingCodes: [
        '356'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Marshall Islands',
      code: 'MH',
      callingCodes: [
        '692'
      ],
      region: 'Oceania',
      subregion: 'Micronesia'
    },
    {
      name: 'Martinique',
      code: 'MQ',
      callingCodes: [
        '596'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Mauritania',
      code: 'MR',
      callingCodes: [
        '222'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Mauritius',
      code: 'MU',
      callingCodes: [
        '230'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Mayotte',
      code: 'YT',
      callingCodes: [
        '262'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Mexico',
      code: 'MX',
      callingCodes: [
        '52'
      ],
      region: 'Americas',
      subregion: 'Central America'
    },
    {
      name: 'Micronesia (Federated States of)',
      code: 'FM',
      callingCodes: [
        '691'
      ],
      region: 'Oceania',
      subregion: 'Micronesia'
    },
    {
      name: 'Moldova (Republic of)',
      code: 'MD',
      callingCodes: [
        '373'
      ],
      region: 'Europe',
      subregion: 'Eastern Europe'
    },
    {
      name: 'Monaco',
      code: 'MC',
      callingCodes: [
        '377'
      ],
      region: 'Europe',
      subregion: 'Western Europe'
    },
    {
      name: 'Mongolia',
      code: 'MN',
      callingCodes: [
        '976'
      ],
      region: 'Asia',
      subregion: 'Eastern Asia'
    },
    {
      name: 'Montenegro',
      code: 'ME',
      callingCodes: [
        '382'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Montserrat',
      code: 'MS',
      callingCodes: [
        '1664'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Morocco',
      code: 'MA',
      callingCodes: [
        '212'
      ],
      region: 'Africa',
      subregion: 'Northern Africa'
    },
    {
      name: 'Mozambique',
      code: 'MZ',
      callingCodes: [
        '258'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Myanmar',
      code: 'MM',
      callingCodes: [
        '95'
      ],
      region: 'Asia',
      subregion: 'South-Eastern Asia'
    },
    {
      name: 'Namibia',
      code: 'NA',
      callingCodes: [
        '264'
      ],
      region: 'Africa',
      subregion: 'Southern Africa'
    },
    {
      name: 'Nauru',
      code: 'NR',
      callingCodes: [
        '674'
      ],
      region: 'Oceania',
      subregion: 'Micronesia'
    },
    {
      name: 'Nepal',
      code: 'NP',
      callingCodes: [
        '977'
      ],
      region: 'Asia',
      subregion: 'Southern Asia'
    },
    {
      name: 'Netherlands',
      code: 'NL',
      callingCodes: [
        '31'
      ],
      region: 'Europe',
      subregion: 'Western Europe'
    },
    {
      name: 'New Caledonia',
      code: 'NC',
      callingCodes: [
        '687'
      ],
      region: 'Oceania',
      subregion: 'Melanesia'
    },
    {
      name: 'New Zealand',
      code: 'NZ',
      callingCodes: [
        '64'
      ],
      region: 'Oceania',
      subregion: 'Australia and New Zealand'
    },
    {
      name: 'Nicaragua',
      code: 'NI',
      callingCodes: [
        '505'
      ],
      region: 'Americas',
      subregion: 'Central America'
    },
    {
      name: 'Niger',
      code: 'NE',
      callingCodes: [
        '227'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Nigeria',
      code: 'NG',
      callingCodes: [
        '234'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Niue',
      code: 'NU',
      callingCodes: [
        '683'
      ],
      region: 'Oceania',
      subregion: 'Polynesia'
    },
    {
      name: 'Norfolk Island',
      code: 'NF',
      callingCodes: [
        '672'
      ],
      region: 'Oceania',
      subregion: 'Australia and New Zealand'
    },
    {
      name: 'Korea (Democratic People\'s Republic of)',
      code: 'KP',
      callingCodes: [
        '850'
      ],
      region: 'Asia',
      subregion: 'Eastern Asia'
    },
    {
      name: 'Northern Mariana Islands',
      code: 'MP',
      callingCodes: [
        '1670'
      ],
      region: 'Oceania',
      subregion: 'Micronesia'
    },
    {
      name: 'Norway',
      code: 'NO',
      callingCodes: [
        '47'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'Oman',
      code: 'OM',
      callingCodes: [
        '968'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Pakistan',
      code: 'PK',
      callingCodes: [
        '92'
      ],
      region: 'Asia',
      subregion: 'Southern Asia'
    },
    {
      name: 'Palau',
      code: 'PW',
      callingCodes: [
        '680'
      ],
      region: 'Oceania',
      subregion: 'Micronesia'
    },
    {
      name: 'Palestine, State of',
      code: 'PS',
      callingCodes: [
        '970'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Panama',
      code: 'PA',
      callingCodes: [
        '507'
      ],
      region: 'Americas',
      subregion: 'Central America'
    },
    {
      name: 'Papua New Guinea',
      code: 'PG',
      callingCodes: [
        '675'
      ],
      region: 'Oceania',
      subregion: 'Melanesia'
    },
    {
      name: 'Paraguay',
      code: 'PY',
      callingCodes: [
        '595'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'Peru',
      code: 'PE',
      callingCodes: [
        '51'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'Philippines',
      code: 'PH',
      callingCodes: [
        '63'
      ],
      region: 'Asia',
      subregion: 'South-Eastern Asia'
    },
    {
      name: 'Pitcairn',
      code: 'PN',
      callingCodes: [
        '64'
      ],
      region: 'Oceania',
      subregion: 'Polynesia'
    },
    {
      name: 'Poland',
      code: 'PL',
      callingCodes: [
        '48'
      ],
      region: 'Europe',
      subregion: 'Eastern Europe'
    },
    {
      name: 'Portugal',
      code: 'PT',
      callingCodes: [
        '351'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Puerto Rico',
      code: 'PR',
      callingCodes: [
        '1787',
        '1939'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Qatar',
      code: 'QA',
      callingCodes: [
        '974'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Republic of Kosovo',
      code: 'XK',
      callingCodes: [
        '383'
      ],
      region: 'Europe',
      subregion: 'Eastern Europe'
    },
    {
      name: 'Réunion',
      code: 'RE',
      callingCodes: [
        '262'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Romania',
      code: 'RO',
      callingCodes: [
        '40'
      ],
      region: 'Europe',
      subregion: 'Eastern Europe'
    },
    {
      name: 'Russian Federation',
      code: 'RU',
      callingCodes: [
        '7'
      ],
      region: 'Europe',
      subregion: 'Eastern Europe'
    },
    {
      name: 'Rwanda',
      code: 'RW',
      callingCodes: [
        '250'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Saint Barthélemy',
      code: 'BL',
      callingCodes: [
        '590'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      code: 'SH',
      callingCodes: [
        '290'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Saint Kitts and Nevis',
      code: 'KN',
      callingCodes: [
        '1869'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Saint Lucia',
      code: 'LC',
      callingCodes: [
        '1758'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Saint Martin (French part)',
      code: 'MF',
      callingCodes: [
        '590'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Saint Pierre and Miquelon',
      code: 'PM',
      callingCodes: [
        '508'
      ],
      region: 'Americas',
      subregion: 'Northern America'
    },
    {
      name: 'Saint Vincent and the Grenadines',
      code: 'VC',
      callingCodes: [
        '1784'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Samoa',
      code: 'WS',
      callingCodes: [
        '685'
      ],
      region: 'Oceania',
      subregion: 'Polynesia'
    },
    {
      name: 'San Marino',
      code: 'SM',
      callingCodes: [
        '378'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Sao Tome and Principe',
      code: 'ST',
      callingCodes: [
        '239'
      ],
      region: 'Africa',
      subregion: 'Middle Africa'
    },
    {
      name: 'Saudi Arabia',
      code: 'SA',
      callingCodes: [
        '966'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Senegal',
      code: 'SN',
      callingCodes: [
        '221'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Serbia',
      code: 'RS',
      callingCodes: [
        '381'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Seychelles',
      code: 'SC',
      callingCodes: [
        '248'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
      callingCodes: [
        '232'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Singapore',
      code: 'SG',
      callingCodes: [
        '65'
      ],
      region: 'Asia',
      subregion: 'South-Eastern Asia'
    },
    {
      name: 'Sint Maarten (Dutch part)',
      code: 'SX',
      callingCodes: [
        '1721'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Slovakia',
      code: 'SK',
      callingCodes: [
        '421'
      ],
      region: 'Europe',
      subregion: 'Eastern Europe'
    },
    {
      name: 'Slovenia',
      code: 'SI',
      callingCodes: [
        '386'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Solomon Islands',
      code: 'SB',
      callingCodes: [
        '677'
      ],
      region: 'Oceania',
      subregion: 'Melanesia'
    },
    {
      name: 'Somalia',
      code: 'SO',
      callingCodes: [
        '252'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'South Africa',
      code: 'ZA',
      callingCodes: [
        '27'
      ],
      region: 'Africa',
      subregion: 'Southern Africa'
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      code: 'GS',
      callingCodes: [
        '500'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'Korea (Republic of)',
      code: 'KR',
      callingCodes: [
        '82'
      ],
      region: 'Asia',
      subregion: 'Eastern Asia'
    },
    {
      name: 'South Sudan',
      code: 'SS',
      callingCodes: [
        '211'
      ],
      region: 'Africa',
      subregion: 'Middle Africa'
    },
    {
      name: 'Spain',
      code: 'ES',
      callingCodes: [
        '34'
      ],
      region: 'Europe',
      subregion: 'Southern Europe'
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
      callingCodes: [
        '94'
      ],
      region: 'Asia',
      subregion: 'Southern Asia'
    },
    {
      name: 'Sudan',
      code: 'SD',
      callingCodes: [
        '249'
      ],
      region: 'Africa',
      subregion: 'Northern Africa'
    },
    {
      name: 'Suriname',
      code: 'SR',
      callingCodes: [
        '597'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'Svalbard and Jan Mayen',
      code: 'SJ',
      callingCodes: [
        '4779'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'Swaziland',
      code: 'SZ',
      callingCodes: [
        '268'
      ],
      region: 'Africa',
      subregion: 'Southern Africa'
    },
    {
      name: 'Sweden',
      code: 'SE',
      callingCodes: [
        '46'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'Switzerland',
      code: 'CH',
      callingCodes: [
        '41'
      ],
      region: 'Europe',
      subregion: 'Western Europe'
    },
    {
      name: 'Syrian Arab Republic',
      code: 'SY',
      callingCodes: [
        '963'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Taiwan',
      code: 'TW',
      callingCodes: [
        '886'
      ],
      region: 'Asia',
      subregion: 'Eastern Asia'
    },
    {
      name: 'Tajikistan',
      code: 'TJ',
      callingCodes: [
        '992'
      ],
      region: 'Asia',
      subregion: 'Central Asia'
    },
    {
      name: 'Tanzania, United Republic of',
      code: 'TZ',
      callingCodes: [
        '255'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Thailand',
      code: 'TH',
      callingCodes: [
        '66'
      ],
      region: 'Asia',
      subregion: 'South-Eastern Asia'
    },
    {
      name: 'Timor-Leste',
      code: 'TL',
      callingCodes: [
        '670'
      ],
      region: 'Asia',
      subregion: 'South-Eastern Asia'
    },
    {
      name: 'Togo',
      code: 'TG',
      callingCodes: [
        '228'
      ],
      region: 'Africa',
      subregion: 'Western Africa'
    },
    {
      name: 'Tokelau',
      code: 'TK',
      callingCodes: [
        '690'
      ],
      region: 'Oceania',
      subregion: 'Polynesia'
    },
    {
      name: 'Tonga',
      code: 'TO',
      callingCodes: [
        '676'
      ],
      region: 'Oceania',
      subregion: 'Polynesia'
    },
    {
      name: 'Trinidad and Tobago',
      code: 'TT',
      callingCodes: [
        '1868'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Tunisia',
      code: 'TN',
      callingCodes: [
        '216'
      ],
      region: 'Africa',
      subregion: 'Northern Africa'
    },
    {
      name: 'Turkey',
      code: 'TR',
      callingCodes: [
        '90'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Turkmenistan',
      code: 'TM',
      callingCodes: [
        '993'
      ],
      region: 'Asia',
      subregion: 'Central Asia'
    },
    {
      name: 'Turks and Caicos Islands',
      code: 'TC',
      callingCodes: [
        '1649'
      ],
      region: 'Americas',
      subregion: 'Caribbean'
    },
    {
      name: 'Tuvalu',
      code: 'TV',
      callingCodes: [
        '688'
      ],
      region: 'Oceania',
      subregion: 'Polynesia'
    },
    {
      name: 'Uganda',
      code: 'UG',
      callingCodes: [
        '256'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Ukraine',
      code: 'UA',
      callingCodes: [
        '380'
      ],
      region: 'Europe',
      subregion: 'Eastern Europe'
    },
    {
      name: 'United Arab Emirates',
      code: 'AE',
      callingCodes: [
        '971'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'United Kingdom of Great Britain and Northern Ireland',
      code: 'GB',
      callingCodes: [
        '44'
      ],
      region: 'Europe',
      subregion: 'Northern Europe'
    },
    {
      name: 'United States of America',
      code: 'US',
      callingCodes: [
        '1'
      ],
      region: 'Americas',
      subregion: 'Northern America'
    },
    {
      name: 'Uruguay',
      code: 'UY',
      callingCodes: [
        '598'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'Uzbekistan',
      code: 'UZ',
      callingCodes: [
        '998'
      ],
      region: 'Asia',
      subregion: 'Central Asia'
    },
    {
      name: 'Vanuatu',
      code: 'VU',
      callingCodes: [
        '678'
      ],
      region: 'Oceania',
      subregion: 'Melanesia'
    },
    {
      name: 'Venezuela (Bolivarian Republic of)',
      code: 'VE',
      callingCodes: [
        '58'
      ],
      region: 'Americas',
      subregion: 'South America'
    },
    {
      name: 'Viet Nam',
      code: 'VN',
      callingCodes: [
        '84'
      ],
      region: 'Asia',
      subregion: 'South-Eastern Asia'
    },
    {
      name: 'Wallis and Futuna',
      code: 'WF',
      callingCodes: [
        '681'
      ],
      region: 'Oceania',
      subregion: 'Polynesia'
    },
    {
      name: 'Western Sahara',
      code: 'EH',
      callingCodes: [
        '212'
      ],
      region: 'Africa',
      subregion: 'Northern Africa'
    },
    {
      name: 'Yemen',
      code: 'YE',
      callingCodes: [
        '967'
      ],
      region: 'Asia',
      subregion: 'Western Asia'
    },
    {
      name: 'Zambia',
      code: 'ZM',
      callingCodes: [
        '260'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
      callingCodes: [
        '263'
      ],
      region: 'Africa',
      subregion: 'Eastern Africa'
    }
  ];
  timeZoneList: Array<ITimeZone> = [
    {
      countryCode: 'AF',
      countryName: 'Afghanistan',
      olson: 'Asia/Kabul',
      UTC: '+04:30',
      DST: '+04:30',
      zoneCode: 'AFT',
      zoneName: 'Afghanistan Time'
    },
    {
      countryCode: 'AX',
      countryName: 'Åland',
      olson: 'Europe/Mariehamn',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'AL',
      countryName: 'Albania',
      olson: 'Europe/Tirane',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'DZ',
      countryName: 'Algeria',
      olson: 'Africa/Algiers',
      UTC: '+01:00',
      DST: '+01:00',
      zoneCode: 'CET',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'AS',
      countryName: 'American Samoa',
      olson: 'Pacific/Pago Pago',
      UTC: '-11:00',
      DST: '-11:00',
      zoneCode: 'SST',
      zoneName: 'Samoa Standard Time'
    },
    {
      countryCode: 'AS',
      countryName: 'American Samoa',
      olson: 'Pacific/Samoa',
      UTC: '+11:00',
      DST: '+11:00',
      zoneCode: 'SST',
      zoneName: 'American Samoa'
    },
    {
      countryCode: 'AD',
      countryName: 'Andorra',
      olson: 'Europe/Andorra',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'AO',
      countryName: 'Angola',
      olson: 'Africa/Luanda',
      UTC: '+01:00',
      DST: '+01:00',
      zoneCode: 'WAT',
      zoneName: 'West Africa Time'
    },
    {
      countryCode: 'AI',
      countryName: 'Anguilla',
      olson: 'America/Anguilla',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'AQ',
      countryName: 'Antarctica',
      olson: 'Antarctica/Casey',
      UTC: '+11:00',
      DST: '+08:00',
      zoneCode: '',
      zoneName: 'Casey Station, Bailey Peninsula'
    },
    {
      countryCode: 'AQ',
      countryName: 'Antarctica',
      olson: 'Antarctica/Davis',
      UTC: '+05:00',
      DST: '+07:00',
      zoneCode: '',
      zoneName: 'Davis Station, Vestfold Hills'
    },
    {
      countryCode: 'AQ',
      countryName: 'Antarctica',
      olson: 'Antarctica/Macquarie',
      UTC: '+11:00',
      DST: '+11:00',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'AQ',
      countryName: 'Antarctica',
      olson: 'Antarctica/Mawson',
      UTC: '+05:00',
      DST: '+05:00',
      zoneCode: '',
      zoneName: 'Mawson Station, Holme Bay'
    },
    {
      countryCode: 'AQ',
      countryName: 'Antarctica',
      olson: 'Antarctica/Palmer',
      UTC: '-04:00',
      DST: '-03:00',
      zoneCode: '',
      zoneName: 'Palmer Station, Anvers Island'
    },
    {
      countryCode: 'AQ',
      countryName: 'Antarctica',
      olson: 'Antarctica/Rothera',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: '',
      zoneName: 'Rothera Station, Adelaide Island'
    },
    {
      countryCode: 'AQ',
      countryName: 'Antarctica',
      olson: 'Antarctica/South Pole',
      UTC: '+12:00',
      DST: '+13:00',
      zoneCode: '',
      zoneName: 'Amundsen-Scott Station, South Pole'
    },
    {
      countryCode: 'AQ',
      countryName: 'Antarctica',
      olson: 'Antarctica/Syowa',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: '',
      zoneName: 'Syowa Station, E Ongul I'
    },
    {
      countryCode: 'AQ',
      countryName: 'Antarctica',
      olson: 'Antarctica/Troll',
      UTC: '+00:00',
      DST: '+02:00',
      zoneCode: '',
      zoneName: 'Troll Station, Queen Maud Land'
    },
    {
      countryCode: 'AQ',
      countryName: 'Antarctica',
      olson: 'Antarctica/Vostok',
      UTC: '+06:00',
      DST: '+06:00',
      zoneCode: '',
      zoneName: 'Vostok Station, Lake Vostok'
    },
    {
      countryCode: 'AG',
      countryName: 'Antigua and Barbuda',
      olson: 'America/Antigua',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'AR',
      countryName: 'Argentina',
      olson: 'America/Argentina/Buenos Aires',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'ART',
      zoneName: 'Argentina Time'
    },
    {
      countryCode: 'AR',
      countryName: 'Argentina',
      olson: 'America/Argentina/Catamarca',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'ART',
      zoneName: 'Argentina Time'
    },
    {
      countryCode: 'AR',
      countryName: 'Argentina',
      olson: 'America/Argentina/Cordoba',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'ART',
      zoneName: 'Argentina Time'
    },
    {
      countryCode: 'AR',
      countryName: 'Argentina',
      olson: 'America/Argentina/Jujuy',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'ART',
      zoneName: 'Argentina Time'
    },
    {
      countryCode: 'AR',
      countryName: 'Argentina',
      olson: 'America/Argentina/La Rioja',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'ART',
      zoneName: 'Argentina Time'
    },
    {
      countryCode: 'AR',
      countryName: 'Argentina',
      olson: 'America/Argentina/Mendoza',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'ART',
      zoneName: 'Argentina Time'
    },
    {
      countryCode: 'AR',
      countryName: 'Argentina',
      olson: 'America/Argentina/Rio Gallegos',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'ART',
      zoneName: 'Argentina Time'
    },
    {
      countryCode: 'AR',
      countryName: 'Argentina',
      olson: 'America/Argentina/Salta',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'ART',
      zoneName: 'Argentina Time'
    },
    {
      countryCode: 'AR',
      countryName: 'Argentina',
      olson: 'America/Argentina/San Juan',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'ART',
      zoneName: 'Argentina Time'
    },
    {
      countryCode: 'AR',
      countryName: 'Argentina',
      olson: 'America/Argentina/San Luis',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'ART',
      zoneName: 'Argentina Time'
    },
    {
      countryCode: 'AR',
      countryName: 'Argentina',
      olson: 'America/Argentina/Tucuman',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'ART',
      zoneName: 'Argentina Time'
    },
    {
      countryCode: 'AR',
      countryName: 'Argentina',
      olson: 'America/Argentina/Ushuaia',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'ART',
      zoneName: 'Argentina Time'
    },
    {
      countryCode: 'AM',
      countryName: 'Armenia',
      olson: 'Asia/Yerevan',
      UTC: '+04:00',
      DST: '+04:00',
      zoneCode: 'AMT',
      zoneName: 'Armenia Time'
    },
    {
      countryCode: 'AW',
      countryName: 'Aruba',
      olson: 'America/Aruba',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/ACT',
      UTC: '+10:00',
      DST: '+11:00',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Adelaide',
      UTC: '+09:30',
      DST: '+10:30',
      zoneCode: 'ACST',
      zoneName: 'Australian Central Standard Time'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Brisbane',
      UTC: '+10:00',
      DST: '+10:00',
      zoneCode: 'AEST',
      zoneName: 'Australian Eastern Standard Time'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Broken Hill',
      UTC: '+09:30',
      DST: '+10:30',
      zoneCode: 'ACST',
      zoneName: 'Australian Central Standard Time'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Canberra',
      UTC: '+10:00',
      DST: '+11:00',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Currie',
      UTC: '+10:00',
      DST: '+11:00',
      zoneCode: 'AEST',
      zoneName: 'Australian Eastern Standard Time'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Darwin',
      UTC: '+09:30',
      DST: '+09:30',
      zoneCode: 'ACST',
      zoneName: 'Australian Central Standard Time'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Eucla',
      UTC: '+08:45',
      DST: '+08:45',
      zoneCode: 'CWST',
      zoneName: 'Central Western Australia Time'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Hobart',
      UTC: '+10:00',
      DST: '+11:00',
      zoneCode: 'AEST',
      zoneName: 'Australian Eastern Standard Time'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/LHI',
      UTC: '+10:30',
      DST: '+11:00',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Lindeman',
      UTC: '+10:00',
      DST: '+10:00',
      zoneCode: 'AEST',
      zoneName: 'Australian Eastern Standard Time'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Lord Howe',
      UTC: '+10:30',
      DST: '+11:00',
      zoneCode: 'LHST',
      zoneName: 'Lord Howe Time'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Melbourne',
      UTC: '+10:00',
      DST: '+11:00',
      zoneCode: 'AEST',
      zoneName: 'Australian Eastern Standard Time'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/North',
      UTC: '+09:30',
      DST: '+09:30',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/NSW',
      UTC: '+10:00',
      DST: '+11:00',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Perth',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'AWST',
      zoneName: 'Australian Western Standard Time'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Queensland',
      UTC: '+10:00',
      DST: '+10:00',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/South',
      UTC: '+09:30',
      DST: '+10:30',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Sydney',
      UTC: '+10:00',
      DST: '+11:00',
      zoneCode: 'AEST',
      zoneName: 'Australian Eastern Standard Time'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Tasmania',
      UTC: '+10:00',
      DST: '+11:00',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Victoria',
      UTC: '+10:00',
      DST: '+11:00',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/West',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'AU',
      countryName: 'Australia',
      olson: 'Australia/Yancowinna',
      UTC: '+09:30',
      DST: '+10:30',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'AT',
      countryName: 'Austria',
      olson: 'Europe/Vienna',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'AZ',
      countryName: 'Azerbaijan',
      olson: 'Asia/Baku',
      UTC: '+04:00',
      DST: '+05:00',
      zoneCode: 'AZT/AZST',
      zoneName: 'Azerbaijan Time'
    },
    {
      countryCode: 'BS',
      countryName: 'Bahamas',
      olson: 'America/Nassau',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'BH',
      countryName: 'Bahrain',
      olson: 'Asia/Bahrain',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'AST',
      zoneName: 'Arabic Standard Time'
    },
    {
      countryCode: 'BD',
      countryName: 'Bangladesh',
      olson: 'Asia/Dhaka',
      UTC: '+06:00',
      DST: '+06:00',
      zoneCode: 'BDST',
      zoneName: 'Bangladesh Standard Time'
    },
    {
      countryCode: 'BB',
      countryName: 'Barbados',
      olson: 'America/Barbados',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'BY',
      countryName: 'Belarus',
      olson: 'Europe/Minsk',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'BE',
      countryName: 'Belgium',
      olson: 'Europe/Brussels',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'BZ',
      countryName: 'Belize',
      olson: 'America/Belize',
      UTC: '-06:00',
      DST: '-06:00',
      zoneCode: 'CST',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'BJ',
      countryName: 'Benin',
      olson: 'Africa/Porto-Novo',
      UTC: '+01:00',
      DST: '+01:00',
      zoneCode: 'WAT',
      zoneName: 'West Africa Time'
    },
    {
      countryCode: 'BM',
      countryName: 'Bermuda',
      olson: 'Atlantic/Bermuda',
      UTC: '-04:00',
      DST: '-03:00',
      zoneCode: 'AST/ADT',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'BT',
      countryName: 'Bhutan',
      olson: 'Asia/Thimphu',
      UTC: '+06:00',
      DST: '+06:00',
      zoneCode: 'BTT',
      zoneName: 'Bhutan Time'
    },
    {
      countryCode: 'BO',
      countryName: 'Bolivia',
      olson: 'America/La Paz',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'BOT',
      zoneName: 'Bolivia Time'
    },
    {
      countryCode: 'BA',
      countryName: 'Bosnia-Herzegovina',
      olson: 'Europe/Sarajevo',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'BW',
      countryName: 'Botswana',
      olson: 'Africa/Gaborone',
      UTC: '+02:00',
      DST: '+02:00',
      zoneCode: 'CAT',
      zoneName: 'Central Africa Time'
    },
    {
      countryCode: 'BV',
      countryName: 'Bouvet Island',
      olson: 'Antarctica/Bouvet',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'BVIS',
      zoneName: 'Bouvet Island'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Araguaina',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'BRT',
      zoneName: 'Brasilia Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Bahia',
      UTC: '-03:00',
      DST: '-02:00',
      zoneCode: 'BRT',
      zoneName: 'Brasilia Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Belem',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'BRT',
      zoneName: 'Brasilia Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Boa Vista',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AMT',
      zoneName: 'Amazon Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Campo Grande',
      UTC: '-04:00',
      DST: '-03:00',
      zoneCode: 'AMT/AMST',
      zoneName: 'Amazon Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Cuiaba',
      UTC: '-04:00',
      DST: '-03:00',
      zoneCode: 'AMT/AMST',
      zoneName: 'Amazon Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Eirunepe',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AMT',
      zoneName: 'Amazon Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Fortaleza',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'BRT',
      zoneName: 'Brasilia Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Maceio',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'BRT',
      zoneName: 'Brasilia Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Manaus',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AMT',
      zoneName: 'Amazon Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Noronha',
      UTC: '-02:00',
      DST: '-02:00',
      zoneCode: 'FNT',
      zoneName: 'Fernando de Noronha Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Porto Velho',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AMT',
      zoneName: 'Amazon Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Recife',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'BRT',
      zoneName: 'Brasilia Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Rio Branco',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AMT',
      zoneName: 'Amazon Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Santarem',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'AMT',
      zoneName: 'Amazon Time'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil',
      olson: 'America/Sao Paulo',
      UTC: '-03:00',
      DST: '-02:00',
      zoneCode: 'BRT/BRST',
      zoneName: 'Brasilia Time'
    },
    {
      countryCode: 'IO',
      countryName: 'British Indian Ocean Terr.',
      olson: 'Indian/Chagos',
      UTC: '+06:00',
      DST: '+06:00',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'VG',
      countryName: 'British Virgin Islands',
      olson: 'America/Tortola',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'BN',
      countryName: 'Brunei',
      olson: 'Asia/Brunei',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'BNT',
      zoneName: 'Brunei Time'
    },
    {
      countryCode: 'BG',
      countryName: 'Bulgaria',
      olson: 'Europe/Sofia',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'BF',
      countryName: 'Burkina Faso',
      olson: 'Africa/Ouagadougou',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'MM',
      countryName: 'Burma',
      olson: 'Asia/Rangoon',
      UTC: '+06:30',
      DST: '+06:30',
      zoneCode: 'MMT',
      zoneName: 'Myanmar Standard Time'
    },
    {
      countryCode: 'BI',
      countryName: 'Burundi',
      olson: 'Africa/Bujumbura',
      UTC: '+02:00',
      DST: '+02:00',
      zoneCode: 'CAT',
      zoneName: 'Central Africa Time'
    },
    {
      countryCode: 'KH',
      countryName: 'Cambodia',
      olson: 'Asia/Phnom Penh',
      UTC: '+07:00',
      DST: '+07:00',
      zoneCode: 'ICT',
      zoneName: 'SE Asia Standard Time'
    },
    {
      countryCode: 'CM',
      countryName: 'Cameroon',
      olson: 'Africa/Douala',
      UTC: '+01:00',
      DST: '+01:00',
      zoneCode: 'WAT',
      zoneName: 'West Africa Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Atikokan',
      UTC: '-05:00',
      DST: '-05:00',
      zoneCode: 'EST',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Blanc-Sablon',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Cambridge Bay',
      UTC: '-07:00',
      DST: '-06:00',
      zoneCode: 'MST/MDT',
      zoneName: 'Mountain Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Coral Harbour',
      UTC: '-05:00',
      DST: '-05:00',
      zoneCode: 'EST',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Dawson',
      UTC: '-08:00',
      DST: '-07:00',
      zoneCode: 'PST/PDT',
      zoneName: 'Pacific Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Dawson Creek',
      UTC: '-07:00',
      DST: '-07:00',
      zoneCode: 'MST',
      zoneName: 'Mountain Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Edmonton',
      UTC: '-07:00',
      DST: '-06:00',
      zoneCode: 'MST/MDT',
      zoneName: 'Mountain Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Glace Bay',
      UTC: '-04:00',
      DST: '-03:00',
      zoneCode: 'AST/ADT',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Goose Bay',
      UTC: '-04:00',
      DST: '-03:00',
      zoneCode: 'AST/ADT',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Halifax',
      UTC: '-04:00',
      DST: '-03:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Inuvik',
      UTC: '-07:00',
      DST: '-06:00',
      zoneCode: 'MST/MDT',
      zoneName: 'Mountain Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Iqaluit',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Moncton',
      UTC: '-04:00',
      DST: '-03:00',
      zoneCode: 'AST/ADT',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Montreal',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Nipigon',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Pangnirtung',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Rainy River',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Rankin Inlet',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Regina',
      UTC: '-06:00',
      DST: '-06:00',
      zoneCode: 'CST',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Resolute',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/St Johns',
      UTC: '-03:30',
      DST: '-02:30',
      zoneCode: 'NST/NDT',
      zoneName: 'Newfoundland Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Swift Current',
      UTC: '-06:00',
      DST: '-06:00',
      zoneCode: 'CST',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Thunder Bay',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Toronto',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Vancouver',
      UTC: '-08:00',
      DST: '-07:00',
      zoneCode: 'PST/PDT',
      zoneName: 'Pacific Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Whitehorse',
      UTC: '-08:00',
      DST: '-07:00',
      zoneCode: 'PST/PDT',
      zoneName: 'Pacific Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Winnipeg',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada',
      olson: 'America/Yellowknife',
      UTC: '-07:00',
      DST: '-06:00',
      zoneCode: 'MST/MDT',
      zoneName: 'Mountain Standard Time'
    },
    {
      countryCode: 'CV',
      countryName: 'Cape Verde',
      olson: 'Atlantic/Cape Verde',
      UTC: '-01:00',
      DST: '-01:00',
      zoneCode: 'CVT',
      zoneName: 'Cape Verde Time'
    },
    {
      countryCode: 'BQ',
      countryName: 'Caribbean Netherlands',
      olson: 'America/Bonaire',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Caribbean Netherlands'
    },
    {
      countryCode: 'KY',
      countryName: 'Cayman Islands',
      olson: 'America/Cayman',
      UTC: '-05:00',
      DST: '-05:00',
      zoneCode: 'EST',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'CF',
      countryName: 'Central African Rep.',
      olson: 'Africa/Bangui',
      UTC: '+01:00',
      DST: '+01:00',
      zoneCode: 'WAT',
      zoneName: 'West Africa Time'
    },
    {
      countryCode: 'TD',
      countryName: 'Chad',
      olson: 'Africa/Ndjamena',
      UTC: '+01:00',
      DST: '+01:00',
      zoneCode: 'WAT',
      zoneName: 'West Africa Time'
    },
    {
      countryCode: 'CL',
      countryName: 'Chile',
      olson: 'America/Santiago',
      UTC: '-04:00',
      DST: '-03:00',
      zoneCode: 'CLT/CLST',
      zoneName: 'Chile Standard Time'
    },
    {
      countryCode: 'CL',
      countryName: 'Chile',
      olson: 'Pacific/Easter',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'EAST/EASST',
      zoneName: 'Easter Island Standard Time'
    },
    {
      countryCode: 'CN',
      countryName: 'China',
      olson: 'Asia/Chongqing',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'CST',
      zoneName: 'China Standard Time'
    },
    {
      countryCode: 'CN',
      countryName: 'China',
      olson: 'Asia/Harbin',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'CST',
      zoneName: 'China Standard Time'
    },
    {
      countryCode: 'CN',
      countryName: 'China',
      olson: 'Asia/Kashgar',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'CST',
      zoneName: 'China Standard Time'
    },
    {
      countryCode: 'CN',
      countryName: 'China',
      olson: 'Asia/Shanghai',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'CST',
      zoneName: 'China Standard Time'
    },
    {
      countryCode: 'CN',
      countryName: 'China',
      olson: 'Asia/Urumqi',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'CST',
      zoneName: 'China Standard Time'
    },
    {
      countryCode: 'CX',
      countryName: 'Christmas Island',
      olson: 'Indian/Christmas',
      UTC: '+07:00',
      DST: '+07:00',
      zoneCode: 'LINT',
      zoneName: 'Line Islands Time'
    },
    {
      countryCode: 'CC',
      countryName: 'Cocos (Keeling) Islands',
      olson: 'Indian/Cocos',
      UTC: '+06:30',
      DST: '+06:30',
      zoneCode: 'CCT',
      zoneName: 'Cocos Islands Time'
    },
    {
      countryCode: 'CO',
      countryName: 'Colombia',
      olson: 'America/Bogota',
      UTC: '-05:00',
      DST: '-05:00',
      zoneCode: 'COT',
      zoneName: 'Colombia Time'
    },
    {
      countryCode: 'KM',
      countryName: 'Comoros',
      olson: 'Indian/Comoro',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'EAT',
      zoneName: 'East Africa Time'
    },
    {
      countryCode: 'CG',
      countryName: 'Congo',
      olson: 'Africa/Brazzaville',
      UTC: '+01:00',
      DST: '+01:00',
      zoneCode: 'WAT',
      zoneName: 'West Africa Time'
    },
    {
      countryCode: 'CD',
      countryName: 'Congo D.R.',
      olson: 'Africa/Kinshasa',
      UTC: '+01:00',
      DST: '+01:00',
      zoneCode: 'WAT',
      zoneName: 'West Africa Time'
    },
    {
      countryCode: 'CD',
      countryName: 'Congo D.R.',
      olson: 'Africa/Lubumbashi',
      UTC: '+02:00',
      DST: '+02:00',
      zoneCode: 'CAT',
      zoneName: 'Central Africa Time'
    },
    {
      countryCode: 'CK',
      countryName: 'Cook Islands',
      olson: 'Pacific/Rarotonga',
      UTC: '-10:00',
      DST: '-10:00',
      zoneCode: 'HAST',
      zoneName: 'Hawaii Standard Time'
    },
    {
      countryCode: 'CR',
      countryName: 'Costa Rica',
      olson: 'America/Costa Rica',
      UTC: '-06:00',
      DST: '-06:00',
      zoneCode: 'CST',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'CI',
      countryName: 'Côte d\'Ivoire',
      olson: 'Africa/Abidjan',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'HR',
      countryName: 'Croatia',
      olson: 'Europe/Zagreb',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'CU',
      countryName: 'Cuba',
      olson: 'America/Havana',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'CW',
      countryName: 'Curaçao',
      olson: 'America/Curacao',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'CY',
      countryName: 'Cyprus',
      olson: 'Asia/Nicosia',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'CZ',
      countryName: 'Czech Republic',
      olson: 'Europe/Prague',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'DK',
      countryName: 'Denmark',
      olson: 'Europe/Copenhagen',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'DJ',
      countryName: 'Djibouti',
      olson: 'Africa/Djibouti',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'EAT',
      zoneName: 'East Africa Time'
    },
    {
      countryCode: 'DM',
      countryName: 'Dominica',
      olson: 'America/Dominica',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'DO',
      countryName: 'Dominican Republic',
      olson: 'America/Santo Domingo',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'EC',
      countryName: 'Ecuador',
      olson: 'America/Guayaquil',
      UTC: '-05:00',
      DST: '-05:00',
      zoneCode: 'ECT',
      zoneName: 'Ecuador Time'
    },
    {
      countryCode: 'EC',
      countryName: 'Ecuador',
      olson: 'Pacific/Galapagos',
      UTC: '-06:00',
      DST: '-06:00',
      zoneCode: 'GALT',
      zoneName: 'Galápagos Time'
    },
    {
      countryCode: 'EG',
      countryName: 'Egypt',
      olson: 'Africa/Cairo',
      UTC: '+02:00',
      DST: '+02:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'SV',
      countryName: 'El Salvador',
      olson: 'America/El Salvador',
      UTC: '-06:00',
      DST: '-06:00',
      zoneCode: 'CST',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'GQ',
      countryName: 'Equatorial Guinea',
      olson: 'Africa/Malabo',
      UTC: '+01:00',
      DST: '+01:00',
      zoneCode: 'WAT',
      zoneName: 'West Africa Time'
    },
    {
      countryCode: 'ER',
      countryName: 'Eritrea',
      olson: 'Africa/Asmara',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'EAT',
      zoneName: 'East Africa Time'
    },
    {
      countryCode: 'EE',
      countryName: 'Estonia',
      olson: 'Europe/Tallinn',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'ET',
      countryName: 'Ethiopia',
      olson: 'Africa/Addis Ababa',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'EAT',
      zoneName: 'East Africa Time'
    },
    {
      countryCode: 'FK',
      countryName: 'Falkland Islands',
      olson: 'Atlantic/Stanley',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'FKT/FKST',
      zoneName: 'Falkland Islands Time'
    },
    {
      countryCode: 'FO',
      countryName: 'Faroe Islands',
      olson: 'Atlantic/Faroe',
      UTC: '+00:00',
      DST: '+01:00',
      zoneCode: 'WET/WEST',
      zoneName: 'Western European Time'
    },
    {
      countryCode: 'FJ',
      countryName: 'Fiji',
      olson: 'Pacific/Fiji',
      UTC: '+12:00',
      DST: '+13:00',
      zoneCode: 'FJT',
      zoneName: 'Fiji Time'
    },
    {
      countryCode: 'FI',
      countryName: 'Finland',
      olson: 'Europe/Helsinki',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'FR',
      countryName: 'France',
      olson: 'Europe/Paris',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'GF',
      countryName: 'French Guiana',
      olson: 'America/Cayenne',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'GFT',
      zoneName: 'French Guiana Time'
    },
    {
      countryCode: 'PF',
      countryName: 'French Polynesia',
      olson: 'Pacific/Gambier',
      UTC: '-09:00',
      DST: '-09:00',
      zoneCode: 'GAMT',
      zoneName: 'Gambier Time'
    },
    {
      countryCode: 'PF',
      countryName: 'French Polynesia',
      olson: 'Pacific/Marquesas',
      UTC: '-09:30',
      DST: '-09:30',
      zoneCode: 'MART',
      zoneName: 'Marquesas Time'
    },
    {
      countryCode: 'PF',
      countryName: 'French Polynesia',
      olson: 'Pacific/Tahiti',
      UTC: '-10:00',
      DST: '-10:00',
      zoneCode: 'TAHT',
      zoneName: 'Tahiti Time'
    },
    {
      countryCode: 'TF',
      countryName: 'French Southern Territories',
      olson: 'Indian/Kerguelen',
      UTC: '+05:00',
      DST: '+05:00',
      zoneCode: 'TFT',
      zoneName: 'French Southern and Antarctic Time'
    },
    {
      countryCode: 'GA',
      countryName: 'Gabon',
      olson: 'Africa/Libreville',
      UTC: '+01:00',
      DST: '+01:00',
      zoneCode: 'WAT',
      zoneName: 'West Africa Time'
    },
    {
      countryCode: 'GM',
      countryName: 'Gambia',
      olson: 'Africa/Banjul',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'PS',
      countryName: 'Gaza Strip',
      olson: 'Asia/Gaza',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'GE',
      countryName: 'Georgia',
      olson: 'Asia/Tbilisi',
      UTC: '+04:00',
      DST: '+04:00',
      zoneCode: 'GET',
      zoneName: 'Georgia Time'
    },
    {
      countryCode: 'DE',
      countryName: 'Germany',
      olson: 'Europe/Berlin',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'GH',
      countryName: 'Ghana',
      olson: 'Africa/Accra',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'GI',
      countryName: 'Gibraltar',
      olson: 'Europe/Gibraltar',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'GR',
      countryName: 'Greece',
      olson: 'Europe/Athens',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'GL',
      countryName: 'Greenland',
      olson: 'America/Danmarkshavn',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'GL',
      countryName: 'Greenland',
      olson: 'America/Godthab',
      UTC: '-03:00',
      DST: '-02:00',
      zoneCode: 'WGT/WGST',
      zoneName: 'Central Greenland'
    },
    {
      countryCode: 'GL',
      countryName: 'Greenland',
      olson: 'America/Scoresbysund',
      UTC: '-01:00',
      DST: '+00:00',
      zoneCode: 'EGT/EGST',
      zoneName: 'Greenland Time'
    },
    {
      countryCode: 'GL',
      countryName: 'Greenland',
      olson: 'America/Thule',
      UTC: '-04:00',
      DST: '-03:00',
      zoneCode: 'AST/ADT',
      zoneName: 'Northwestern Greenland'
    },
    {
      countryCode: 'GD',
      countryName: 'Grenada',
      olson: 'America/Grenada',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'GP',
      countryName: 'Guadeloupe',
      olson: 'America/Guadeloupe',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'GU',
      countryName: 'Guam',
      olson: 'Pacific/Guam',
      UTC: '+10:00',
      DST: '+10:00',
      zoneCode: 'ChST',
      zoneName: 'Chamorro Standard Time'
    },
    {
      countryCode: 'GT',
      countryName: 'Guatemala',
      olson: 'America/Guatemala',
      UTC: '-06:00',
      DST: '-06:00',
      zoneCode: 'CST',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'GG',
      countryName: 'Guernsey',
      olson: 'Europe/Guernsey',
      UTC: '+00:00',
      DST: '+01:00',
      zoneCode: 'GMT/BST',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'GN',
      countryName: 'Guinea',
      olson: 'Africa/Conakry',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'GW',
      countryName: 'Guinea-Bissau',
      olson: 'Africa/Bissau',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'GY',
      countryName: 'Guyana',
      olson: 'America/Guyana',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'GYT',
      zoneName: 'Guyana Time'
    },
    {
      countryCode: 'HT',
      countryName: 'Haiti',
      olson: 'America/Port-au-Prince',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'HM',
      countryName: 'Heard & McDonald Islands',
      olson: 'Australia/Heard and McDonald',
      UTC: '+05:00',
      DST: '+05:00',
      zoneCode: 'HIMI',
      zoneName: 'Heard and McDonald Islands'
    },
    {
      countryCode: 'HN',
      countryName: 'Honduras',
      olson: 'America/Tegucigalpa',
      UTC: '-06:00',
      DST: '-06:00',
      zoneCode: 'CST',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'HK',
      countryName: 'Hong Kong',
      olson: 'Asia/Hong Kong',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'HKT',
      zoneName: 'Hong Kong Time'
    },
    {
      countryCode: 'HU',
      countryName: 'Hungary',
      olson: 'Europe/Budapest',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'IS',
      countryName: 'Iceland',
      olson: 'Atlantic/Reykjavik',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'IN',
      countryName: 'India',
      olson: 'Asia/Kolkata',
      UTC: '+05:30',
      DST: '+05:30',
      zoneCode: 'IST',
      zoneName: 'Indian Standard Time'
    },
    {
      countryCode: 'ID',
      countryName: 'Indonesia',
      olson: 'Asia/Jakarta',
      UTC: '+07:00',
      DST: '+07:00',
      zoneCode: 'WIB',
      zoneName: 'Western Indonesian Time'
    },
    {
      countryCode: 'ID',
      countryName: 'Indonesia',
      olson: 'Asia/Jayapura',
      UTC: '+09:00',
      DST: '+09:00',
      zoneCode: 'EIT',
      zoneName: 'Eastern Indonesian Time'
    },
    {
      countryCode: 'ID',
      countryName: 'Indonesia',
      olson: 'Asia/Makassar',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'CIT',
      zoneName: 'Central Indonesian Time'
    },
    {
      countryCode: 'ID',
      countryName: 'Indonesia',
      olson: 'Asia/Pontianak',
      UTC: '+07:00',
      DST: '+07:00',
      zoneCode: 'WIB',
      zoneName: 'Western Indonesian Time'
    },
    {
      countryCode: 'IR',
      countryName: 'Iran',
      olson: 'Asia/Tehran',
      UTC: '+03:30',
      DST: '+04:30',
      zoneCode: 'IRST',
      zoneName: 'Iran Standard Time'
    },
    {
      countryCode: 'IQ',
      countryName: 'Iraq',
      olson: 'Asia/Baghdad',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'AST',
      zoneName: 'Arabic Standard Time'
    },
    {
      countryCode: 'IE',
      countryName: 'Ireland',
      olson: 'Europe/Dublin',
      UTC: '+00:00',
      DST: '+01:00',
      zoneCode: 'GMT/IST',
      zoneName: 'Western European Time'
    },
    {
      countryCode: 'IM',
      countryName: 'Isle of Man',
      olson: 'Europe/Isle of Man',
      UTC: '+00:00',
      DST: '+01:00',
      zoneCode: 'GMT/BST',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'IL',
      countryName: 'Israel',
      olson: 'Asia/Jerusalem',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'IST/IDT',
      zoneName: 'Israel Standard Time'
    },
    {
      countryCode: 'IT',
      countryName: 'Italy',
      olson: 'Europe/Rome',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'JM',
      countryName: 'Jamaica',
      olson: 'America/Jamaica',
      UTC: '-05:00',
      DST: '-05:00',
      zoneCode: 'EST',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'JP',
      countryName: 'Japan',
      olson: 'Asia/Tokyo',
      UTC: '+09:00',
      DST: '+09:00',
      zoneCode: 'JST',
      zoneName: 'Japan Standard Time'
    },
    {
      countryCode: 'JE',
      countryName: 'Jersey',
      olson: 'Europe/Jersey',
      UTC: '+00:00',
      DST: '+01:00',
      zoneCode: 'GMT/BST',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'JO',
      countryName: 'Jordan',
      olson: 'Asia/Amman',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'AST',
      zoneName: 'Arabia Standard Time'
    },
    {
      countryCode: 'KZ',
      countryName: 'Kazakhstan',
      olson: 'Asia/Almaty',
      UTC: '+06:00',
      DST: '+06:00',
      zoneCode: 'ALMT',
      zoneName: 'Eastern Kazakhstan Time'
    },
    {
      countryCode: 'KZ',
      countryName: 'Kazakhstan',
      olson: 'Asia/Aqtau',
      UTC: '+05:00',
      DST: '+05:00',
      zoneCode: 'AQTT',
      zoneName: 'Western Kazakhstan Time'
    },
    {
      countryCode: 'KZ',
      countryName: 'Kazakhstan',
      olson: 'Asia/Aqtobe',
      UTC: '+05:00',
      DST: '+05:00',
      zoneCode: 'AQTT',
      zoneName: 'Western Kazakhstan Time'
    },
    {
      countryCode: 'KZ',
      countryName: 'Kazakhstan',
      olson: 'Asia/Oral',
      UTC: '+05:00',
      DST: '+05:00',
      zoneCode: 'ORAT',
      zoneName: 'Western Kazakhstan Time'
    },
    {
      countryCode: 'KZ',
      countryName: 'Kazakhstan',
      olson: 'Asia/Qyzylorda',
      UTC: '+06:00',
      DST: '+06:00',
      zoneCode: 'QYZT',
      zoneName: 'Eastern Kazakhstan Time'
    },
    {
      countryCode: 'KE',
      countryName: 'Kenya',
      olson: 'Africa/Nairobi',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'EAT',
      zoneName: 'East Africa Time'
    },
    {
      countryCode: 'KI',
      countryName: 'Kiribati',
      olson: 'Pacific/Enderbury',
      UTC: '+13:00',
      DST: '+13:00',
      zoneCode: 'PHOT',
      zoneName: 'Phoenix Island Time'
    },
    {
      countryCode: 'KI',
      countryName: 'Kiribati',
      olson: 'Pacific/Kiritimati',
      UTC: '+14:00',
      DST: '+14:00',
      zoneCode: 'LINT',
      zoneName: 'Line Islands Time'
    },
    {
      countryCode: 'KI',
      countryName: 'Kiribati',
      olson: 'Pacific/Tarawa',
      UTC: '+12:00',
      DST: '+12:00',
      zoneCode: 'GILT',
      zoneName: 'Gilbert Island Time'
    },
    {
      countryCode: 'XK',
      countryName: 'Kosovo',
      olson: 'Europe/Pristina',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'KW',
      countryName: 'Kuwait',
      olson: 'Asia/Kuwait',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'AST',
      zoneName: 'Arabic Standard Time'
    },
    {
      countryCode: 'KG',
      countryName: 'Kyrgyzstan',
      olson: 'Asia/Bishkek',
      UTC: '+06:00',
      DST: '+06:00',
      zoneCode: 'KGT',
      zoneName: 'Kyrgyzstan Time'
    },
    {
      countryCode: 'LA',
      countryName: 'Laos',
      olson: 'Asia/Vientiane',
      UTC: '+07:00',
      DST: '+07:00',
      zoneCode: 'ICT',
      zoneName: 'SE Asia Standard Time'
    },
    {
      countryCode: 'LV',
      countryName: 'Latvia',
      olson: 'Europe/Riga',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'LB',
      countryName: 'Lebanon',
      olson: 'Asia/Beirut',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'LS',
      countryName: 'Lesotho',
      olson: 'Africa/Maseru',
      UTC: '+02:00',
      DST: '+02:00',
      zoneCode: 'SAST',
      zoneName: 'South African Standard Time'
    },
    {
      countryCode: 'LR',
      countryName: 'Liberia',
      olson: 'Africa/Monrovia',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'LY',
      countryName: 'Libya',
      olson: 'Africa/Tripoli',
      UTC: '+02:00',
      DST: '+02:00',
      zoneCode: 'EET',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'LI',
      countryName: 'Liechtenstein',
      olson: 'Europe/Vaduz',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'LT',
      countryName: 'Lithuania',
      olson: 'Europe/Vilnius',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'LU',
      countryName: 'Luxembourg',
      olson: 'Europe/Luxembourg',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'MO',
      countryName: 'Macau',
      olson: 'Asia/Macau',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'CT',
      zoneName: 'Macau Time'
    },
    {
      countryCode: 'MK',
      countryName: 'Macedonia',
      olson: 'Europe/Skopje',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'MG',
      countryName: 'Madagascar',
      olson: 'Indian/Antananarivo',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'EAT',
      zoneName: 'East Africa Time'
    },
    {
      countryCode: 'MW',
      countryName: 'Malawi',
      olson: 'Africa/Blantyre',
      UTC: '+02:00',
      DST: '+02:00',
      zoneCode: 'CAT',
      zoneName: 'Central Africa Time'
    },
    {
      countryCode: 'MY',
      countryName: 'Malaysia',
      olson: 'Asia/Kuala Lumpur',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'MYT',
      zoneName: 'Malaysian Standard Time'
    },
    {
      countryCode: 'MY',
      countryName: 'Malaysia',
      olson: 'Asia/Kuching',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'MYT',
      zoneName: 'Malaysian Standard Time'
    },
    {
      countryCode: 'MV',
      countryName: 'Maldives',
      olson: 'Indian/Maldives',
      UTC: '+05:00',
      DST: '+05:00',
      zoneCode: 'MVT',
      zoneName: 'Maldives Time'
    },
    {
      countryCode: 'ML',
      countryName: 'Mali',
      olson: 'Africa/Bamako',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'MT',
      countryName: 'Malta',
      olson: 'Europe/Malta',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'MH',
      countryName: 'Marshall Islands',
      olson: 'Pacific/Kwajalein',
      UTC: '+12:00',
      DST: '+12:00',
      zoneCode: 'MHT',
      zoneName: 'Marshall Time'
    },
    {
      countryCode: 'MH',
      countryName: 'Marshall Islands',
      olson: 'Pacific/Majuro',
      UTC: '+12:00',
      DST: '+12:00',
      zoneCode: 'MHT',
      zoneName: 'Marshall Time'
    },
    {
      countryCode: 'MQ',
      countryName: 'Martinique',
      olson: 'America/Martinique',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'MR',
      countryName: 'Mauritania',
      olson: 'Africa/Nouakchott',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'MU',
      countryName: 'Mauritius',
      olson: 'Indian/Mauritius',
      UTC: '+04:00',
      DST: '+04:00',
      zoneCode: 'MUT',
      zoneName: 'Mauritius Time'
    },
    {
      countryCode: 'YT',
      countryName: 'Mayotte',
      olson: 'Indian/Mayotte',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'EAT',
      zoneName: 'East Africa Time'
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      olson: 'America/Bahia Banderas',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Zone'
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      olson: 'America/Cancun',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Southeast Zone'
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      olson: 'America/Chihuahua',
      UTC: '-07:00',
      DST: '-06:00',
      zoneCode: 'MST/MDT',
      zoneName: 'Pacific Zone'
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      olson: 'America/Hermosillo',
      UTC: '-07:00',
      DST: '-07:00',
      zoneCode: 'MST',
      zoneName: 'Pacific Zone'
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      olson: 'America/Matamoros',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Zone'
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      olson: 'America/Mazatlan',
      UTC: '-07:00',
      DST: '-06:00',
      zoneCode: 'MST/MDT',
      zoneName: 'Pacific Zone'
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      olson: 'America/Merida',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Zone'
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      olson: 'America/Mexico City',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Zone'
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      olson: 'America/Monterrey',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Zone'
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      olson: 'America/Ojinaga',
      UTC: '-07:00',
      DST: '-06:00',
      zoneCode: 'MST/MDT',
      zoneName: 'Pacific Zone'
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      olson: 'America/Santa Isabel',
      UTC: '-08:00',
      DST: '-07:00',
      zoneCode: 'PST/PDT',
      zoneName: 'Northwest Zone'
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico',
      olson: 'America/Tijuana',
      UTC: '-08:00',
      DST: '-07:00',
      zoneCode: 'PST/PDT',
      zoneName: 'Northwest Zone'
    },
    {
      countryCode: 'FM',
      countryName: 'Micronesia',
      olson: 'Pacific/Chuuk',
      UTC: '+10:00',
      DST: '+10:00',
      zoneCode: 'ChST',
      zoneName: 'Chamorro Standard Time'
    },
    {
      countryCode: 'FM',
      countryName: 'Micronesia',
      olson: 'Pacific/Kosrae',
      UTC: '+11:00',
      DST: '+11:00',
      zoneCode: 'KOST',
      zoneName: 'Kosrae Time'
    },
    {
      countryCode: 'FM',
      countryName: 'Micronesia',
      olson: 'Pacific/Pohnpei',
      UTC: '+11:00',
      DST: '+11:00',
      zoneCode: 'KOST',
      zoneName: 'Kosrae Time'
    },
    {
      countryCode: 'UM',
      countryName: 'Minor Outlying Islands',
      olson: 'Pacific/Johnston',
      UTC: '-10:00',
      DST: '-10:00',
      zoneCode: 'HAST',
      zoneName: 'Hawaii Standard Time'
    },
    {
      countryCode: 'UM',
      countryName: 'Minor Outlying Islands',
      olson: 'Pacific/Midway',
      UTC: '-11:00',
      DST: '-11:00',
      zoneCode: 'SST',
      zoneName: 'Samoa Standard Time'
    },
    {
      countryCode: 'UM',
      countryName: 'Minor Outlying Islands',
      olson: 'Pacific/Wake',
      UTC: '+12:00',
      DST: '+12:00',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'MD',
      countryName: 'Moldova',
      olson: 'Europe/Chisinau',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'MC',
      countryName: 'Monaco',
      olson: 'Europe/Monaco',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'MN',
      countryName: 'Mongolia',
      olson: 'Asia/Choibalsan',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'CHOT/CHOST',
      zoneName: 'Choibalsan Standard Time'
    },
    {
      countryCode: 'MN',
      countryName: 'Mongolia',
      olson: 'Asia/Hovd',
      UTC: '+07:00',
      DST: '+07:00',
      zoneCode: 'HOVT/HOVST',
      zoneName: 'Hovd Standard Time'
    },
    {
      countryCode: 'MN',
      countryName: 'Mongolia',
      olson: 'Asia/Ulaanbaatar',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'ULAT/ULAST',
      zoneName: 'Ulaanbaatar Time'
    },
    {
      countryCode: 'ME',
      countryName: 'Montenegro',
      olson: 'Europe/Podgorica',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'MS',
      countryName: 'Montserrat',
      olson: 'America/Montserrat',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'MA',
      countryName: 'Morocco',
      olson: 'Africa/Casablanca',
      UTC: '+00:00',
      DST: '+01:00',
      zoneCode: 'WET',
      zoneName: 'Western European Time'
    },
    {
      countryCode: 'MZ',
      countryName: 'Mozambique',
      olson: 'Africa/Maputo',
      UTC: '+02:00',
      DST: '+02:00',
      zoneCode: 'CAT',
      zoneName: 'Central Africa Time'
    },
    {
      countryCode: 'NA',
      countryName: 'Namibia',
      olson: 'Africa/Windhoek',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'WAT/WAST',
      zoneName: 'West Africa Time'
    },
    {
      countryCode: 'NR',
      countryName: 'Nauru',
      olson: 'Pacific/Nauru',
      UTC: '+12:00',
      DST: '+12:00',
      zoneCode: 'NRT',
      zoneName: 'Nauru Time'
    },
    {
      countryCode: 'NP',
      countryName: 'Nepal',
      olson: 'Asia/Kathmandu',
      UTC: '+05:45',
      DST: '+05:45',
      zoneCode: 'NPT',
      zoneName: 'Nepal Time'
    },
    {
      countryCode: 'NL',
      countryName: 'Netherlands',
      olson: 'Europe/Amsterdam',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'NC',
      countryName: 'New Caledonia',
      olson: 'Pacific/Noumea',
      UTC: '+11:00',
      DST: '+11:00',
      zoneCode: 'NCT',
      zoneName: 'New Caledonia Time'
    },
    {
      countryCode: 'NZ',
      countryName: 'New Zealand',
      olson: 'Pacific/Auckland',
      UTC: '+12:00',
      DST: '+13:00',
      zoneCode: 'NZST/NZDT',
      zoneName: 'New Zealand Standard Time'
    },
    {
      countryCode: 'NZ',
      countryName: 'New Zealand',
      olson: 'Pacific/Chatham',
      UTC: '+12:45',
      DST: '+13:45',
      zoneCode: 'CHAST/CHAD',
      zoneName: 'Chatham Standard Time'
    },
    {
      countryCode: 'NI',
      countryName: 'Nicaragua',
      olson: 'America/Managua',
      UTC: '-06:00',
      DST: '-06:00',
      zoneCode: 'CST',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'NE',
      countryName: 'Niger',
      olson: 'Africa/Niamey',
      UTC: '+01:00',
      DST: '+01:00',
      zoneCode: 'WAT',
      zoneName: 'West Africa Time'
    },
    {
      countryCode: 'NG',
      countryName: 'Nigeria',
      olson: 'Africa/Lagos',
      UTC: '+01:00',
      DST: '+01:00',
      zoneCode: 'WAT',
      zoneName: 'West Africa Time'
    },
    {
      countryCode: 'NU',
      countryName: 'Niue',
      olson: 'Pacific/Niue',
      UTC: '-11:00',
      DST: '-11:00',
      zoneCode: 'NUT',
      zoneName: 'Niue Time'
    },
    {
      countryCode: 'NF',
      countryName: 'Norfolk Island',
      olson: 'Pacific/Norfolk',
      UTC: '+11:30',
      DST: '+11:30',
      zoneCode: 'NFT',
      zoneName: 'Norfolk Time'
    },
    {
      countryCode: 'KP',
      countryName: 'North Korea',
      olson: 'Asia/Pyongyang',
      UTC: '+09:00',
      DST: '+09:00',
      zoneCode: 'KST',
      zoneName: 'Korea Standard Time'
    },
    {
      countryCode: 'MP',
      countryName: 'Northern Mariana Islands',
      olson: 'Pacific/Saipan',
      UTC: '+10:00',
      DST: '+10:00',
      zoneCode: 'ChST',
      zoneName: 'Chamorro Standard Time'
    },
    {
      countryCode: 'NO',
      countryName: 'Norway',
      olson: 'Europe/Oslo',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'OM',
      countryName: 'Oman',
      olson: 'Asia/Muscat',
      UTC: '+04:00',
      DST: '+04:00',
      zoneCode: 'GST',
      zoneName: 'Arabian Standard Time'
    },
    {
      countryCode: 'PK',
      countryName: 'Pakistan',
      olson: 'Asia/Karachi',
      UTC: '+05:00',
      DST: '+05:00',
      zoneCode: 'PKT/PKST',
      zoneName: 'Pakistan Standard Time'
    },
    {
      countryCode: 'PW',
      countryName: 'Palau',
      olson: 'Pacific/Palau',
      UTC: '+09:00',
      DST: '+09:00',
      zoneCode: 'PWT',
      zoneName: 'Palau Time'
    },
    {
      countryCode: 'PA',
      countryName: 'Panama',
      olson: 'America/Panama',
      UTC: '-05:00',
      DST: '-05:00',
      zoneCode: 'EST',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'PG',
      countryName: 'Papua New Guinea',
      olson: 'Pacific/Port Moresby',
      UTC: '+10:00',
      DST: '+10:00',
      zoneCode: 'PGT',
      zoneName: 'Papua New Guinea Time'
    },
    {
      countryCode: 'PY',
      countryName: 'Paraguay',
      olson: 'America/Asuncion',
      UTC: '-04:00',
      DST: '-03:00',
      zoneCode: 'PYT/PYST',
      zoneName: 'Paraguay Standard Time'
    },
    {
      countryCode: 'PE',
      countryName: 'Peru',
      olson: 'America/Lima',
      UTC: '-05:00',
      DST: '-05:00',
      zoneCode: 'PET',
      zoneName: 'Peru Time'
    },
    {
      countryCode: 'PH',
      countryName: 'Philippines',
      olson: 'Asia/Manila',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'PHT',
      zoneName: 'Philippines Time'
    },
    {
      countryCode: 'PN',
      countryName: 'Pitcairn Islands',
      olson: 'Pacific/Pitcairn',
      UTC: '-08:00',
      DST: '-08:00',
      zoneCode: 'PST',
      zoneName: 'Pitcairn Standard Time'
    },
    {
      countryCode: 'PL',
      countryName: 'Poland',
      olson: 'Europe/Warsaw',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'PT',
      countryName: 'Portugal',
      olson: 'Atlantic/Azores',
      UTC: '-01:00',
      DST: '+00:00',
      zoneCode: 'AZOT/AZOST',
      zoneName: 'Azores Standard Time'
    },
    {
      countryCode: 'PT',
      countryName: 'Portugal',
      olson: 'Atlantic/Madeira',
      UTC: '+00:00',
      DST: '+01:00',
      zoneCode: 'WET/WEST',
      zoneName: 'Azores Standard Time'
    },
    {
      countryCode: 'PT',
      countryName: 'Portugal',
      olson: 'Europe/Lisbon',
      UTC: '+00:00',
      DST: '+01:00',
      zoneCode: 'WET/WEST',
      zoneName: 'Western European Time'
    },
    {
      countryCode: 'PR',
      countryName: 'Puerto Rico',
      olson: 'America/Puerto Rico',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'QA',
      countryName: 'Qatar',
      olson: 'Asia/Qatar',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'AST',
      zoneName: 'Arabic Standard Time'
    },
    {
      countryCode: 'RE',
      countryName: 'Réunion',
      olson: 'Indian/Reunion',
      UTC: '+04:00',
      DST: '+04:00',
      zoneCode: 'RET',
      zoneName: 'Reunion Time'
    },
    {
      countryCode: 'RO',
      countryName: 'Romania',
      olson: 'Europe/Bucharest',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Asia/Anadyr',
      UTC: '+12:00',
      DST: '+12:00',
      zoneCode: 'ANAT/ANAST',
      zoneName: 'Anadyr Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Asia/Irkutsk',
      UTC: '+09:00',
      DST: '+09:00',
      zoneCode: 'IRKT/IRKST',
      zoneName: 'Irkutsk Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Asia/Kamchatka',
      UTC: '+12:00',
      DST: '+12:00',
      zoneCode: 'PETT/PETST',
      zoneName: 'Kamchatka Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Asia/Krasnoyarsk',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'KRAT/KRAST',
      zoneName: 'Krasnoyarsk Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Asia/Magadan',
      UTC: '+12:00',
      DST: '+12:00',
      zoneCode: 'MAGT/MAGST',
      zoneName: 'Magadan Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Asia/Novokuznetsk',
      UTC: '+07:00',
      DST: '+07:00',
      zoneCode: 'NOVT/NOVST',
      zoneName: 'Novosibirsk Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Asia/Novosibirsk',
      UTC: '+07:00',
      DST: '+07:00',
      zoneCode: 'NOVT/NOVST',
      zoneName: 'Novosibirsk Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Asia/Omsk',
      UTC: '+07:00',
      DST: '+07:00',
      zoneCode: 'OMST/OMSST',
      zoneName: 'Omsk Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Asia/Sakhalin',
      UTC: '+11:00',
      DST: '+11:00',
      zoneCode: 'SAKT/SAKST',
      zoneName: 'Sakhalin Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Asia/Vladivostok',
      UTC: '+11:00',
      DST: '+11:00',
      zoneCode: 'VLAT/VLAST',
      zoneName: 'Vladivostok Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Asia/Yakutsk',
      UTC: '+10:00',
      DST: '+10:00',
      zoneCode: 'YAKT/YAKST',
      zoneName: 'Yakutsk Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Asia/Yekaterinburg',
      UTC: '+06:00',
      DST: '+06:00',
      zoneCode: 'YEKT/YEKST',
      zoneName: 'Yekaterinburg Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Europe/Kaliningrad',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Europe/Moscow',
      UTC: '+04:00',
      DST: '+04:00',
      zoneCode: 'MSK/MSD',
      zoneName: 'Moscow Standard Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Europe/Samara',
      UTC: '+04:00',
      DST: '+04:00',
      zoneCode: 'SAMT/SAMST',
      zoneName: 'Samara Time'
    },
    {
      countryCode: 'RU',
      countryName: 'Russia',
      olson: 'Europe/Volgograd',
      UTC: '+04:00',
      DST: '+04:00',
      zoneCode: 'VOLT/VOLST',
      zoneName: 'Moscow Standard Time'
    },
    {
      countryCode: 'RW',
      countryName: 'Rwanda',
      olson: 'Africa/Kigali',
      UTC: '+02:00',
      DST: '+02:00',
      zoneCode: 'CAT',
      zoneName: 'Central Africa Time'
    },
    {
      countryCode: 'BL',
      countryName: 'Saint Barthélemy',
      olson: 'America/St Barthelemy',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'SH',
      countryName: 'Saint Helena',
      olson: 'Atlantic/St Helena',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'KN',
      countryName: 'Saint Kitts and Nevis',
      olson: 'America/St Kitts',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'LC',
      countryName: 'Saint Lucia',
      olson: 'America/St Lucia',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'MF',
      countryName: 'Saint Martin',
      olson: 'America/Marigot',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'PM',
      countryName: 'Saint Pierre & Miquelon',
      olson: 'America/Miquelon',
      UTC: '-03:00',
      DST: '-02:00',
      zoneCode: 'PMST/PMDT',
      zoneName: 'St Pierre and Miquelon Time'
    },
    {
      countryCode: 'WS',
      countryName: 'Samoa',
      olson: 'Pacific/Apia',
      UTC: '+13:00',
      DST: '+14:00',
      zoneCode: 'WST',
      zoneName: 'Samoa Time'
    },
    {
      countryCode: 'SM',
      countryName: 'San Marino',
      olson: 'Europe/San Marino',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'ST',
      countryName: 'Sao Tome and Principe',
      olson: 'Africa/Sao Tome',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'SA',
      countryName: 'Saudi Arabia',
      olson: 'Asia/Riyadh',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'AST',
      zoneName: 'Arabic Standard Time'
    },
    {
      countryCode: 'SN',
      countryName: 'Senegal',
      olson: 'Africa/Dakar',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'RS',
      countryName: 'Serbia',
      olson: 'Europe/Belgrade',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'SC',
      countryName: 'Seychelles',
      olson: 'Indian/Mahe',
      UTC: '+04:00',
      DST: '+04:00',
      zoneCode: 'SCT',
      zoneName: 'Seychelles Time'
    },
    {
      countryCode: 'SL',
      countryName: 'Sierra Leone',
      olson: 'Africa/Freetown',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'SG',
      countryName: 'Singapore',
      olson: 'Asia/Singapore',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'SGT',
      zoneName: 'Singapore Standard Time'
    },
    {
      countryCode: 'SX',
      countryName: 'Sint Maarten',
      olson: 'America/Sint-Maarten',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Sint-Maarten'
    },
    {
      countryCode: 'SK',
      countryName: 'Slovakia',
      olson: 'Europe/Bratislava',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'SI',
      countryName: 'Slovenia',
      olson: 'Europe/Ljubljana',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'SB',
      countryName: 'Solomon Islands',
      olson: 'Pacific/Guadalcanal',
      UTC: '+11:00',
      DST: '+11:00',
      zoneCode: 'SBT',
      zoneName: 'Solomon Islands Time'
    },
    {
      countryCode: 'SO',
      countryName: 'Somalia',
      olson: 'Africa/Mogadishu',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'EAT',
      zoneName: 'East Africa Time'
    },
    {
      countryCode: 'ZA',
      countryName: 'South Africa',
      olson: 'Africa/Johannesburg',
      UTC: '+02:00',
      DST: '+02:00',
      zoneCode: 'SAST',
      zoneName: 'South African Standard Time'
    },
    {
      countryCode: 'GS',
      countryName: 'South Georgia & Sandwich',
      olson: 'Atlantic/South Georgia',
      UTC: '-02:00',
      DST: '-02:00',
      zoneCode: '',
      zoneName: ''
    },
    {
      countryCode: 'KR',
      countryName: 'South Korea',
      olson: 'Asia/Seoul',
      UTC: '+09:00',
      DST: '+09:00',
      zoneCode: 'KST',
      zoneName: 'Korea Standard Time'
    },
    {
      countryCode: 'SS',
      countryName: 'South Sudan',
      olson: 'Africa/Juba',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'EAT',
      zoneName: 'East Africa Time'
    },
    {
      countryCode: 'ES',
      countryName: 'Spain',
      olson: 'Africa/Ceuta',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'ES',
      countryName: 'Spain',
      olson: 'Atlantic/Canary',
      UTC: '+00:00',
      DST: '+01:00',
      zoneCode: 'WET/WEST',
      zoneName: 'Western European Time'
    },
    {
      countryCode: 'ES',
      countryName: 'Spain',
      olson: 'Europe/Madrid',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'LK',
      countryName: 'Sri Lanka',
      olson: 'Asia/Colombo',
      UTC: '+05:30',
      DST: '+05:30',
      zoneCode: 'LKT',
      zoneName: 'Sri Lanka Time'
    },
    {
      countryCode: 'VC',
      countryName: 'St Vincent & the Grenadines',
      olson: 'America/St Vincent',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'SD',
      countryName: 'Sudan',
      olson: 'Africa/Khartoum',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'EAT',
      zoneName: 'East Africa Time'
    },
    {
      countryCode: 'SR',
      countryName: 'Suriname',
      olson: 'America/Paramaribo',
      UTC: '-03:00',
      DST: '-03:00',
      zoneCode: 'SRT',
      zoneName: 'Suriname Time'
    },
    {
      countryCode: 'SJ',
      countryName: 'Svalbard and Jan Mayen',
      olson: 'Arctic/Longyearbyen',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'SZ',
      countryName: 'Swaziland',
      olson: 'Africa/Mbabane',
      UTC: '+02:00',
      DST: '+02:00',
      zoneCode: 'SAST',
      zoneName: 'South African Standard Time'
    },
    {
      countryCode: 'SE',
      countryName: 'Sweden',
      olson: 'Europe/Stockholm',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'CH',
      countryName: 'Switzerland',
      olson: 'Europe/Zurich',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'SY',
      countryName: 'Syria',
      olson: 'Asia/Damascus',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'TW',
      countryName: 'Taiwan',
      olson: 'Asia/Taipei',
      UTC: '+08:00',
      DST: '+08:00',
      zoneCode: 'CST',
      zoneName: 'China Standard Time'
    },
    {
      countryCode: 'TJ',
      countryName: 'Tajikistan',
      olson: 'Asia/Dushanbe',
      UTC: '+05:00',
      DST: '+05:00',
      zoneCode: 'TJT',
      zoneName: 'Tajikistan Time'
    },
    {
      countryCode: 'TZ',
      countryName: 'Tanzania',
      olson: 'Africa/Dar es Salaam',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'EAT',
      zoneName: 'East Africa Time'
    },
    {
      countryCode: 'TH',
      countryName: 'Thailand',
      olson: 'Asia/Bangkok',
      UTC: '+07:00',
      DST: '+07:00',
      zoneCode: 'ICT',
      zoneName: 'SE Asia Standard Time'
    },
    {
      countryCode: 'TL',
      countryName: 'Timor-Leste',
      olson: 'Asia/Dili',
      UTC: '+09:00',
      DST: '+09:00',
      zoneCode: 'TLT',
      zoneName: 'Timor-Leste Time'
    },
    {
      countryCode: 'TG',
      countryName: 'Togo',
      olson: 'Africa/Lome',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'TK',
      countryName: 'Tokelau',
      olson: 'Pacific/Fakaofo',
      UTC: '+14:00',
      DST: '+14:00',
      zoneCode: 'TKT',
      zoneName: 'Tokelau Time'
    },
    {
      countryCode: 'TO',
      countryName: 'Tonga',
      olson: 'Pacific/Tongatapu',
      UTC: '+13:00',
      DST: '+13:00',
      zoneCode: 'TOT',
      zoneName: 'Tonga Time'
    },
    {
      countryCode: 'TT',
      countryName: 'Trinidad and Tobago',
      olson: 'America/Port of Spain',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'TN',
      countryName: 'Tunisia',
      olson: 'Africa/Tunis',
      UTC: '+01:00',
      DST: '+01:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'TR',
      countryName: 'Turkey',
      olson: 'Europe/Istanbul',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'TM',
      countryName: 'Turkmenistan',
      olson: 'Asia/Ashgabat',
      UTC: '+05:00',
      DST: '+05:00',
      zoneCode: 'TMT',
      zoneName: 'Turkmenistan Time'
    },
    {
      countryCode: 'TC',
      countryName: 'Turks and Caicos Islands',
      olson: 'America/Grand Turk',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'TV',
      countryName: 'Tuvalu',
      olson: 'Pacific/Funafuti',
      UTC: '+12:00',
      DST: '+12:00',
      zoneCode: 'TVT',
      zoneName: 'Tuvalu Time'
    },
    {
      countryCode: 'VI',
      countryName: 'U.S. Virgin Islands',
      olson: 'America/St Thomas',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'AST',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'UG',
      countryName: 'Uganda',
      olson: 'Africa/Kampala',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'EAT',
      zoneName: 'East Africa Time'
    },
    {
      countryCode: 'UA',
      countryName: 'Ukraine',
      olson: 'Europe/Kiev',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'UA',
      countryName: 'Ukraine',
      olson: 'Europe/Simferopol',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'UA',
      countryName: 'Ukraine',
      olson: 'Europe/Uzhgorod',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'UA',
      countryName: 'Ukraine',
      olson: 'Europe/Zaporozhye',
      UTC: '+02:00',
      DST: '+03:00',
      zoneCode: 'EET/EEST',
      zoneName: 'Eastern European Time'
    },
    {
      countryCode: 'AE',
      countryName: 'United Arab Emirates',
      olson: 'Asia/Dubai',
      UTC: '+04:00',
      DST: '+04:00',
      zoneCode: 'GST',
      zoneName: 'Arabian Standard Time'
    },
    {
      countryCode: 'GB',
      countryName: 'United Kingdom',
      olson: 'Europe/London',
      UTC: '+00:00',
      DST: '+01:00',
      zoneCode: 'GMT/BST',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Adak',
      UTC: '-10:00',
      DST: '-09:00',
      zoneCode: 'HAST/HADT',
      zoneName: 'Hawaii Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Anchorage',
      UTC: '-09:00',
      DST: '-08:00',
      zoneCode: 'AKST/AKDT',
      zoneName: 'Alaska Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Boise',
      UTC: '-07:00',
      DST: '-06:00',
      zoneCode: 'MST/MDT',
      zoneName: 'Mountain Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Chicago',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Denver',
      UTC: '-07:00',
      DST: '-06:00',
      zoneCode: 'MST/MDT',
      zoneName: 'Mountain Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Detroit',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Indiana/Indianapolis',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Indiana/Knox',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Indiana/Marengo',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Indiana/Petersburg',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Indiana/Tell City',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Indiana/Vevay',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Indiana/Vincennes',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Indiana/Winamac',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Juneau',
      UTC: '-09:00',
      DST: '-08:00',
      zoneCode: 'AKST/AKDT',
      zoneName: 'Alaska Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Kentucky/Louisville',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Kentucky/Monticello',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Los Angeles',
      UTC: '-08:00',
      DST: '-07:00',
      zoneCode: 'PST/PDT',
      zoneName: 'Pacific Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Menominee',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/New York',
      UTC: '-05:00',
      DST: '-04:00',
      zoneCode: 'EST/EDT',
      zoneName: 'Eastern Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Nome',
      UTC: '-09:00',
      DST: '-08:00',
      zoneCode: 'AKST/AKDT',
      zoneName: 'Alaska Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/North Dakota/Beulah',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/North Dakota/Center',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/North Dakota/New Salem',
      UTC: '-06:00',
      DST: '-05:00',
      zoneCode: 'CST/CDT',
      zoneName: 'Central Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Phoenix',
      UTC: '-07:00',
      DST: '-07:00',
      zoneCode: 'MST',
      zoneName: 'Mountain Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'America/Yakutat',
      UTC: '-09:00',
      DST: '-08:00',
      zoneCode: 'AKST/AKDT',
      zoneName: 'Alaska Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'Atlantic',
      UTC: '-04:00',
      DST: '-04:00',
      zoneCode: 'ATL',
      zoneName: 'Atlantic Standard Time'
    },
    {
      countryCode: 'US',
      countryName: 'United States',
      olson: 'Pacific/Honolulu',
      UTC: '-10:00',
      DST: '-10:00',
      zoneCode: 'HAST',
      zoneName: 'Hawaii Standard Time'
    },
    {
      countryCode: 'UY',
      countryName: 'Uruguay',
      olson: 'America/Montevideo',
      UTC: '-03:00',
      DST: '-02:00',
      zoneCode: 'UYT/UYST',
      zoneName: 'Uruguay Standard Time'
    },
    {
      countryCode: 'UZ',
      countryName: 'Uzbekistan',
      olson: 'Asia/Samarkand',
      UTC: '+05:00',
      DST: '+05:00',
      zoneCode: 'UZT',
      zoneName: 'Uzbekistan Time'
    },
    {
      countryCode: 'UZ',
      countryName: 'Uzbekistan',
      olson: 'Asia/Tashkent',
      UTC: '+05:00',
      DST: '+05:00',
      zoneCode: 'UZT',
      zoneName: 'Uzbekistan Time'
    },
    {
      countryCode: 'VU',
      countryName: 'Vanuatu',
      olson: 'Pacific/Efate',
      UTC: '+11:00',
      DST: '+11:00',
      zoneCode: 'VUT',
      zoneName: 'Vanuatu Time'
    },
    {
      countryCode: 'VA',
      countryName: 'Vatican',
      olson: 'Europe/Vatican',
      UTC: '+01:00',
      DST: '+02:00',
      zoneCode: 'CET/CEST',
      zoneName: 'Central European Time'
    },
    {
      countryCode: 'VE',
      countryName: 'Venezuela',
      olson: 'America/Caracas',
      UTC: '-04:30',
      DST: '-04:30',
      zoneCode: 'VET',
      zoneName: 'Venezuelan Standard Time'
    },
    {
      countryCode: 'VN',
      countryName: 'Vietnam',
      olson: 'Asia/Ho Chi Minh',
      UTC: '+07:00',
      DST: '+07:00',
      zoneCode: 'ICT',
      zoneName: 'SE Asia Standard Time'
    },
    {
      countryCode: 'WF',
      countryName: 'Wallis and Futuna',
      olson: 'Pacific/Wallis',
      UTC: '+12:00',
      DST: '+12:00',
      zoneCode: 'WFT',
      zoneName: 'Wallis et Futuna Time'
    },
    {
      countryCode: 'EH',
      countryName: 'Western Sahara',
      olson: 'Africa/El Aaiun',
      UTC: '+00:00',
      DST: '+00:00',
      zoneCode: 'GMT',
      zoneName: 'Greenwich Mean Time'
    },
    {
      countryCode: 'YE',
      countryName: 'Yemen',
      olson: 'Asia/Aden',
      UTC: '+03:00',
      DST: '+03:00',
      zoneCode: 'AST',

      zoneName: 'Arabic Standard Time'
    },
    {
      countryCode: 'ZM',
      countryName: 'Zambia',
      olson: 'Africa/Lusaka',
      UTC: '+02:00',
      DST: '+02:00',
      zoneCode: 'CAT',
      zoneName: 'Central Africa Time'
    },
    {
      countryCode: 'ZW',
      countryName: 'Zimbabwe',
      olson: 'Africa/Harare',
      UTC: '+02:00',
      DST: '+02:00',
      zoneCode: 'CAT',
      zoneName: 'Central Africa Time'
    }
  ];

  getCountries(): Array<ICountry> {
    return this.countryList;
  }
  getTimeZones(): Array<ITimeZone> {
    this.timeZoneList.sort((a, b) => (a.olson < b.olson) ? -1 : ((b.olson < a.olson) ? 1 : 0));
    this.timeZoneList.sort((a, b) => (a.UTC < b.UTC) ? -1 : ((b.UTC < a.UTC) ? 1 : 0));
    // this.timeZoneList.sort((a, b) => (a.timeZone < b.timeZone) ? -1 : ((b.timeZone < a.timeZone) ? 1 : 0));
    // this.timeZoneList.sort((a, b) => (a.GMT offset < b.GMT offset) ? -1 : ((b.GMT offset < a.GMT offset) ? 1 : 0));
    return this.timeZoneList;
  }
  getFirstTimeOlsonZoneByOffset(offset: string) {
    const timeZone = this.timeZoneList.find(i => i.UTC === offset)
    if (timeZone) {
      return timeZone.olson.replace(/ /g, '-')
    }
    return null
  }

}

export interface ICountry {
  name: string;
  code: string;
  callingCodes: Array<string>;
  region: string;
  subregion: string;
}

// export interface ITimeZone {
//   countryCode: string;
//   countryName: string;
//   timeZone?: string;
//   GMT offset?: string;
//   zoneCode?: string;
//   zoneName?: string;
//   olson?: string;
//   UTC?: string;
//   DST?: string;
// }

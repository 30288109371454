import { IInvitedPlayer, IWaitListPlayer } from 'tolaria-cloud-functions/src/_interfaces';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { IEventPlayerMeta, IEventTeamMeta, TournamentDataHelperService } from './tournament-data-helper.service';
interface AttendingPipeConfig {
  enabled: boolean
  value: boolean
  property: string
  text: {
    title: string
    on: string
    off: string
  }
}

interface AttendingPlayersFilter {
  descriptionWidth: number
  filter: string
  panelWidth: number
  showFilterButton: boolean
  editMode: boolean
  clear: Function
  filters: AttendingPipeConfig[]
}

@Injectable({
  providedIn: 'root'
})
export class TournamentManagementFilteringService {

  public attending: AttendingPlayersFilter = {
    descriptionWidth: 4,
    panelWidth: 30,
    showFilterButton: true,
    filter: '',
    editMode: false,
    clear: () => this.__clearAttendingPlayersFilters(),
    filters: [
      {
        enabled: false,
        value: false,
        property: 'hasPaid',
        text: {
          title: 'Payment status',
          on: 'Paid',
          off: 'Not paid',
        },
      },
      {
        enabled: false,
        value: false,
        property: 'hasRefund',
        text: {
          title: 'Payment refund status',
          on: 'Refunded',
          off: 'Not refunded',
        },
      },
      {
        enabled: false,
        value: false,
        property: 'hasSubmittedDeckList',
        text: {
          title: 'Deck list submitted',
          on: 'Yes',
          off: 'No',
        },
      },
      {
        enabled: false,
        value: false,
        property: 'hasSubmittedDeckPhoto',
        text: {
          title: 'Deck photo submitted',
          on: 'Yes',
          off: 'No',
        },
      },
      {
        enabled: false,
        value: false,
        property: 'hasCheckedIn',
        text: {
          title: 'Check-in status',
          on: 'Checked-in',
          off: 'NOT checked-in',
        }
      },
      {
        enabled: false,
        value: false,
        property: 'hasDropped',
        text: {
          title: 'Dropped status',
          on: 'Dropped',
          off: 'Active',
        }
      },
    ]
  }

  public invited: AttendingPlayersFilter = {
    descriptionWidth: 2,
    panelWidth: 30,
    showFilterButton: true,
    filter: '',
    editMode: false,
    clear: () => this.__clearInvitedPlayersFilters(),
    filters: [
      {
        enabled: false,
        value: false,
        property: 'accepted',
        text: {
          title: 'Accepted',
          on: 'Yes',
          off: 'No',
        }
      },
      {
        enabled: false,
        value: false,
        property: 'declined',
        text: {
          title: 'Declined',
          on: 'Yes',
          off: 'No',
        }
      },
      {
        enabled: false,
        value: false,
        property: 'sendEmail',
        text: {
          title: 'Email invitation',
          on: 'Yes',
          off: 'No',
        }
      },
      {
        enabled: false,
        value: false,
        property: 'sendMessage',
        text: {
          title: 'Tolaria message invitation',
          on: 'Yes',
          off: 'No',
        }
      },
      {
        enabled: false,
        value: false,
        property: 'isRegistered',
        text: {
          title: 'Tolaria account',
          on: 'Yes',
          off: 'No',
        }
      },
    ]
  }

  public 'waiting-list': AttendingPlayersFilter = {
    descriptionWidth: 4,
    panelWidth: 30,
    showFilterButton: false,
    filter: '',
    editMode: false,
    clear: () => this.__clearWaitListFilters(),
    filters: [
    ],
  }

  public teams: AttendingPlayersFilter = {
    filter: '',
    showFilterButton: true,
    descriptionWidth: 4,
    panelWidth: 40,
    editMode: false,
    clear: () => this.__clearTeamFilters(),
    filters: [
      {
        enabled: false,
        value: false,
        property: 'empty',
        text: {
          title: 'No players assigned',
          on: 'Yes',
          off: 'No',
        },
      },
      {
        enabled: false,
        value: false,
        property: 'partial',
        text: {
          title: 'Some players assigned',
          on: 'Yes',
          off: 'No',
        },
      },
      {
        enabled: false,
        value: false,
        property: 'hasEmptySlots',
        text: {
          title: 'Unassigned player slots',
          on: 'Yes',
          off: 'No',
        },
      },
      {
        enabled: false,
        value: false,
        property: 'hasEmptySeats',
        text: {
          title: 'Unoccupied team seats',
          on: 'Yes',
          off: 'No',
        },
      },
      {
        enabled: false,
        value: false,
        property: 'hasErrors',
        text: {
          title: 'Errors prescent',
          on: 'Yes',
          off: 'No',
        },
      },
    ],
  }


  constructor(
    private helper: TournamentDataHelperService,
  ) {
    if (this.helper.isTeamTournament) {
      this.attending.filters.push({
        enabled: false,
        value: false,
        property: 'inTeam',
        text: {
          title: 'Assigned to a team',
          on: 'Yes',
          off: 'No',
        }
      })
    }
  }


  private __clearAttendingPlayersFilters(): void {
    this.attending.filter = ''
    this.attending.filters.forEach(i => {
      i.enabled = false
      i.value = false
    })
  }

  private __clearInvitedPlayersFilters(): void {
    this.invited.filter = ''
    this.invited.filters.forEach(i => {
      i.enabled = false
      i.value = false
    })
  }

  private __clearTeamFilters(): void {
    this.teams.filter = ''
    this.teams.filters.forEach(i => {
      i.enabled = false
      i.value = false
    })
  }

  private __clearWaitListFilters(): void {
    this['waiting-list'].filter = ''
    this['waiting-list'].filters.forEach(i => {
      i.enabled = false
      i.value = false
    })
  }


}



@Pipe({
  name: 'attendingPlayers',
  pure: false,
})
export class AttendingPlayersPipe implements PipeTransform {

  transform(items: IEventPlayerMeta[], filter: string, config: AttendingPipeConfig[]): IEventPlayerMeta[] {

    const filters = []

    config.filter(i => i.enabled).forEach(c => {
      filters.push({
        property: c.property,
        value: c.value,
      })

    })

    
    // filter on string
    if (filter !== '') {
      items = items.filter(i => {
        const strings = []
        if (i.eventPlayer.name) { strings.push(i.eventPlayer.name.toLowerCase()) }
        if (i.playerDoc && i.playerDoc.name) {
          if (i.playerDoc.name.display) { strings.push(i.playerDoc.name.display.toLowerCase()) }
          if (i.playerDoc.name.first) { strings.push(i.playerDoc.name.first.toLowerCase()) }
          if (i.playerDoc.name.last) { strings.push(i.playerDoc.name.last.toLowerCase()) }
          if (i.playerDoc.name.nick) { strings.push(i.playerDoc.name.nick.toLowerCase()) }
        }
        if (i.playerDoc && i.playerDoc.country) {
          if (i.playerDoc.country.name) { strings.push(i.playerDoc.country.name.toLowerCase()) }
          if (i.playerDoc.country.region) { strings.push(i.playerDoc.country.region.toLowerCase()) }
          if (i.playerDoc.country.subRegion) { strings.push(i.playerDoc.country.subRegion.toLowerCase()) }
        }
        if (i.teamName !== '') { strings.push(i.teamName.toLowerCase()) }
        return strings.join(' ').includes(filter.toLowerCase())
      })
      // items  = items.filter(i => i.eventPlayer.name.toLowerCase().includes(filter.toLowerCase()) || i.playerDoc !== null && i.playerDoc.country.name.toLowerCase().includes(filter.toLowerCase()))
    }

    if (filters.length > 0) {
      items = items.filter(i => filters.every(({ property, value }) => i.info[property] === value))
    }

    return items.sort((a, b) => a.eventPlayer.name.localeCompare(b.eventPlayer.name))

  }

}

@Pipe({
  name: 'invitedPlayers',
  pure: false,
})
export class InvitedPlayersPipe implements PipeTransform {

  transform(items: IInvitedPlayer[], filter: string, config: AttendingPipeConfig[]): IInvitedPlayer[] {

    const filters = []

    config.filter(i => i.enabled).forEach(c => {
      filters.push({
        property: c.property,
        value: c.value,
      })

    })

    // filter on string
    if (filter !== '') {
      items  = items.filter(i => i.email.toLowerCase().includes(filter.toLowerCase()))
    }

    if (filters.length > 0) {
      items = items.filter(i => filters.every(({ property, value }) => i[property] === value))
    }

    return items.sort((a, b) => a.email.localeCompare(b.email))

  }

}

@Pipe({
  name: 'waitListPlayers',
  pure: false,
})
export class WaitingListPlayersPipe implements PipeTransform {

  transform(items: IWaitListPlayer[], filter: string): IWaitListPlayer[] {

    // filter on string
    if (filter !== '') {
      items  = items.filter(i => i.name.toLowerCase().includes(filter.toLowerCase()))
    }

    return items.sort((a, b) => a.added - b.added)

  }

}

@Pipe({
  name: 'attendingTeams',
  pure: false,
})
export class AttendingTeamsPipe implements PipeTransform {

  transform(items: IEventTeamMeta[], filter: string, config: AttendingPipeConfig[]): IEventTeamMeta[] {

    const filters = []

    config.filter(i => i.enabled).forEach(c => {
      filters.push({
        property: c.property,
        value: c.value,
      })

    })

    // filter on string
    if (filter !== '') {
      items  = items.filter(i => i.name.toLowerCase().includes(filter.toLowerCase()) || i.players.map(i => i.name).join(' ').toLowerCase().includes(filter.toLowerCase()))
    }

    if (filters.length > 0) {
      items = items.filter(i => filters.every(({ property, value }) => i.extra[property] === value))
    }

    return items.sort((a, b) => a.name.localeCompare(b.name))

  }

}

@Pipe({
  name: 'checkInPlayers',
  pure: false,
})
export class CheckInPlayersPipe implements PipeTransform {

  transform(items: IEventPlayerMeta[], filter: string, hideCheckedIn: boolean): ICheckInPlayer[] {
    if (filter !== undefined && filter !== '') {
      items  = items.filter(i => i.eventPlayer.name.toLowerCase().includes(filter.toLowerCase()) || (i.playerDoc && i.playerDoc.email.toLowerCase().includes(filter.toLowerCase())) || (i.playerDoc && i.playerDoc.country.name.toLowerCase().includes(filter.toLowerCase())))
    }
    if (hideCheckedIn) {
      return items.filter(i => !i.eventPlayer.hasCheckedIn).map(i => {
        return {
          id: i.eventPlayer.playerDocId,
          name: i.eventPlayer.name,
          email: i.playerDoc ? i.playerDoc.email : '',
          checkedIn: i.eventPlayer.hasCheckedIn,
          country: i.playerDoc ? i.playerDoc.country.name : 'UNKNOWN',
        }
      })
    }
    else {
      return items.map(i => {
        return {
          id: i.eventPlayer.playerDocId,
          name: i.eventPlayer.name,
          email: i.playerDoc ? i.playerDoc.email : '',
          checkedIn: i.eventPlayer.hasCheckedIn,
          country: i.playerDoc ? i.playerDoc.country.name : 'UNKNOWN',
        }
      })
    }
  }

}

export interface ICheckInPlayer {
  name: string
  id: string
  email: string
  checkedIn: boolean
  country: string
}

import * as admin from 'firebase-admin'

export enum TaskWorker {
  matchReminderEmail = 'matchReminderEmail',
  eventReminderEmail = 'eventReminderEmail',
  eventRegistrationOpener = 'eventRegistrationOpener',
  accountLinkExpired = 'accountLinkExpired',
}

export enum TaskWorkerStatus {
  scheduled = 'scheduled',
  complete = 'complete',
  error = 'error',
}

export interface IScheduledTask {
  performAt: admin.firestore.Timestamp;
  status: string;
  worker: string;
  options: {
    [key: string]: any
  };
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { IPub } from '../pubs.service';

@Component({
  selector: 'app-new-pub',
  templateUrl: './new-pub.component.html',
  styleUrls: ['./new-pub.component.css']
})
export class NewPubComponent implements OnInit {
  @Input() pub: IPub

  public initDone$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public banner$: BehaviorSubject<string> = new BehaviorSubject<string>(null)

  constructor(
    private modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    if (this.pub) {
      this.initDone$.next(true)
    }
  }

  public cancel(): void {
    this.modal.dismiss()
  }

  public create(): void {
    console.log(this.pub)
    this.modal.close(this.pub)
  }

  public invitePlayer(playerDocId: string): void {
    this.pub.invitedPlayerDocIds.push(playerDocId);
  }

  public removePlayer(playerDocId: string): void {
    this.pub.invitedPlayerDocIds.splice(this.pub.invitedPlayerDocIds.indexOf(playerDocId), 1)
  }

  public croppedImage(base64: string): void {
    this.resizeImage(base64)
  }

  private resizeImage(base64: string): void {
    
    const img = new Image
    
    const resize = () => {
      const newImage = this.imageToDataUri(img, 318, 192)
      this.banner$.next(newImage)
      this.pub.imageUri = newImage
    }
    
    img.onload = resize
    img.src = base64

  }

  private imageToDataUri(img: any, width: number, height: number): string {

    // create an off-screen canvas
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    // set its dimension to target size
    canvas.width = width
    canvas.height = height

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height)

    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL()
}

  public get canCreate(): boolean {
    return this.pub.name.length > 5 && this.pub.imageUri !== null && this.pub.imageUri.includes('base64')
  }

  
}

<!-- NAME, TEXT, TYPES, FLAVOR, ARTIST,  -->
<div class="{{ singleCol ? 'wrapper' : 'col-6' }}">
  <!-- CARD_NAME -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Card Name</span>
    </div>
    <div class="col d-flex align-items-center">
      <input type="text" class="form-control-sm w-68" placeholder="Any words in the name, e.g. “Shark”"
        [(ngModel)]="cardSearch.advanced.name" (keydown.enter)="cardSearch.searchCards()">
    </div>
  </div>
  <!-- CARD_TEXT -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Text</span>
    </div>
    <div class="col d-flex align-items-center">
      <input type="text" class="form-control-sm w-68 me-2" placeholder="Any text, e.g. “discard a card”"
        [(ngModel)]="cardSearch.advanced.text" (keydown.enter)="cardSearch.searchCards()">
      <div ngbDropdown>
        <button class="btn btn-outline-secondary btn-sm me-2" id="dropDownTextSymbols" ngbDropdownToggle>
          <fa-icon [icon]="faPlus" [fixedWidth]="true" class="me-2"></fa-icon>Add symbol
        </button>
        <div ngbDropdownMenu aria-labelledby="dropDownTextSymbols" class="drop-down-panel p-2">
          <ng-container *ngFor="let symbol of cardSearch.advanced.otherSymbols">
            <div ngbDropdownItem class="btn btn-sm drop-down-item drop-down-item-mana-symbol"
              (click)="cardSearch.addManaSymbolToText(symbol)"><i class="ms ms-{{ symbol }} ms-cost"></i>
            </div>
          </ng-container>
          <ng-container *ngFor="let symbol of cardSearch.advanced.manaSymbols">
            <div ngbDropdownItem class="btn btn-sm drop-down-item drop-down-item-mana-symbol"
              (click)="cardSearch.addManaSymbolToText(symbol)"><i class="ms ms-{{ symbol }} ms-cost"></i>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <!-- TYPE_LINE -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Type Line</span>
    </div>
    <div class="col d-flex align-items-center">
      <input type="text" class="form-control-sm w-68" placeholder="Enter a type or choose from the list"
        [(ngModel)]="cardSearch.advanced.type" (focus)="cardSearch.advanced.showCardTypesPanel = true"
        (blur)="cardSearch.advanced.showCardTypesPanel = false" (keydown.enter)="cardSearch.searchCards()">
      <div class="card-type-helper" *ngIf="cardSearch.advanced.type !== '' || cardSearch.advanced.showCardTypesPanel">
        <div class="drop-down-panel">
          <ng-container *ngFor="let typeHeader of cardSearch.typesList | cardTypesFilter: cardSearch.advanced.type">
            <div class="drop-down-item no-hover card-type ps-3 mt-3 mb-1 text-bold">{{ typeHeader.name}}</div>
            <ng-container *ngFor="let type of typeHeader.list">
              <button ngbDropdownItem class="pt-1 pb-1 drop-down-item" (mousedown)="cardSearch.addCardType($event, type)"
                [innerHTML]="type"></button>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3" *ngIf="cardSearch.advanced.selectedCardTypes.length > 0">
    <div class="col-3"></div>
    <div class="col card-type-list">
      <ng-container *ngFor="let cardType of cardSearch.advanced.selectedCardTypes; let i = index">
        <div class="card-type-item mb-2">
          <fa-icon [icon]="faTimesCircle" [fixedWidth]="true" class="me-2" (click)="cardSearch.removeCardType(i)">
          </fa-icon>
          <span class="btn btn-sm me-2 {{ cardType.is ? 'btn-success' : 'btn-danger' }}"
            (click)="cardType.is = !cardType.is">{{ cardType.is ? 'IS' : 'NOT' }}</span>
          <span class="">{{ cardType.name }}</span>
        </div>
      </ng-container>
    </div>
  </div>
  <!-- CARD_FLAVOR -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Flavor</span>
    </div>
    <div class="col d-flex align-items-center">
      <input type="text" class="form-control-sm w-68" placeholder="Any flavor text, e.g. “Nicol Bolas”"
        [(ngModel)]="cardSearch.advanced.flavor" (keydown.enter)="cardSearch.searchCards()">
    </div>
  </div>
  <!-- CARD_ARTIST -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Artist</span>
    </div>
    <div class="col d-flex align-items-center">
      <input type="text" class="form-control-sm w-68" placeholder="Any artist name, e.g. “Fay Jones"
        [(ngModel)]="cardSearch.advanced.artist" (keydown.enter)="cardSearch.searchCards()">
    </div>
  </div>
  <!-- CARD_SET -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Sets</span>
    </div>
    <div class="col d-flex align-items-center">
      <input type="text" class="form-control-sm w-68" placeholder="Enter a set name or choose from the list"
        [(ngModel)]="cardSearch.advanced.sets" (focus)="cardSearch.advanced.showSetsPanel= true"
        (blur)="cardSearch.advanced.showSetsPanel = false" (keydown.enter)="cardSearch.searchCards()">
      <!-- advanced.sets !== '' || advanced.showSetsPanel -->
      <div class="card-type-helper" *ngIf="cardSearch.advanced.sets !== '' || cardSearch.advanced.showSetsPanel">
        <div class="drop-down-panel">
          <ng-container *ngFor="let setHeader of cardSearch.setList | cardSetsFilter: cardSearch.advanced.sets">
            <div class="drop-down-item no-hover card-type ps-3 mt-3 mb-1 text-bold" [innerHTML]="setHeader.name"></div>
            <ng-container *ngFor="let set of setHeader.list">
              <button ngbDropdownItem class="pt-1 pb-1 drop-down-item d-flex flex-row align-items-center"
                (mousedown)="cardSearch.addSet($event, set.code, set.name)">
                <!-- <i class="mtg-set mtg-set-{{ set.code }} me-2"></i> -->
                <svg class="set-icon">
                  <use [attr.xlink:href]="'#sets-' + set.icon_code + '-svg'"></use>
                </svg>
                <span class="ms-3" [innerText]="set.name + ' (' + set.code.toUpperCase() + ')'"></span>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3" *ngIf="cardSearch.advanced.selectedSets.length > 0">
    <div class="col-3"></div>
    <div class="col card-type-list">
      <ng-container *ngFor="let set of cardSearch.advanced.selectedSets; let i = index">
        <div class="card-type-item mb-2">
          <fa-icon [icon]="faTimesCircle" [fixedWidth]="true" class="me-2" (click)="cardSearch.removeSet(i)">
          </fa-icon>
          <span class="" [innerText]="set.name"></span>
        </div>
      </ng-container>
    </div>
  </div>
  <!-- CARD_BLOCKS -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Blocks</span>
    </div>
    <div class="col d-flex align-items-center">
      <input type="text" class="form-control-sm w-68" placeholder="Enter a block name or choose from the list"
        [(ngModel)]="cardSearch.advanced.blocks" (focus)="cardSearch.advanced.showBlocksPanel = true"
        (blur)="cardSearch.advanced.showBlocksPanel = false" (keydown.enter)="cardSearch.searchCards()">
      <div class="card-type-helper" *ngIf="cardSearch.advanced.blocks !== '' || cardSearch.advanced.showBlocksPanel">
        <div class="drop-down-panel">
          <ng-container *ngFor="let block of cardSearch.blockList | cardBlocksFilter: cardSearch.advanced.blocks">
            <button ngbDropdownItem class="pt-1 pb-1 drop-down-item"
              (mousedown)="cardSearch.addBlock($event, block.code, block.name)" [innerHTML]="block.name"></button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3" *ngIf="cardSearch.advanced.selectedBlocks.length > 0">
    <div class="col-3"></div>
    <div class="col card-type-list">
      <ng-container *ngFor="let block of cardSearch.advanced.selectedBlocks; let i = index">
        <div class="card-type-item mb-2">
          <fa-icon [icon]="faTimesCircle" [fixedWidth]="true" class="me-2" (click)="cardSearch.removeBlock(i)"></fa-icon>
          <span class="" [innerText]="block.name"></span>
        </div>
      </ng-container>
    </div>
  </div>
  <!-- FORMAT -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Format</span>
    </div>
    <div class="col d-flex align-items-center">
      <input type="text" class="form-control-sm w-68" placeholder="Enter a format name or choose from the list"
        [(ngModel)]="cardSearch.advanced.formats" (focus)="cardSearch.advanced.showFormatsPanel = true"
        (blur)="cardSearch.advanced.showFormatsPanel = false" (keydown.enter)="cardSearch.searchCards()">
      <div class="card-type-helper" *ngIf="cardSearch.advanced.formats !== '' || cardSearch.advanced.showFormatsPanel">
        <div class="drop-down-panel">
          <ng-container *ngFor="let format of cardSearch.formatList | cardBlocksFilter: cardSearch.advanced.formats">
            <button ngbDropdownItem class="pt-1 pb-1 drop-down-item" (mousedown)="cardSearch.addFormat($event, format)"
              [innerHTML]="format.name"></button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3" *ngIf="cardSearch.advanced.selectedFormats.length > 0">
    <div class="col-3"></div>
    <div class="col card-type-list">
      <ng-container *ngFor="let format of cardSearch.advanced.selectedFormats; let i = index">
        <div class="card-type-item mb-2">
          <fa-icon [icon]="faTimesCircle" [fixedWidth]="true" class="me-2" (click)="cardSearch.removeFormat(i)"></fa-icon>
          <span class="ms-2 btn btn-sm {{ format.isLegal ? 'btn-success' : 'btn-secondary' }}"
            (click)="format.isLegal = true; format.isRestricted = false; format.isBanned = false">LEGAL</span>
          <span class="ms-2 btn btn-sm {{ format.isRestricted ? 'btn-success' : 'btn-secondary' }}"
            (click)="format.isLegal = false; format.isRestricted = true; format.isBanned = false">RESTRICTED</span>
          <span class="ms-2 btn btn-sm {{ format.isBanned ? 'btn-success' : 'btn-secondary' }}"
            (click)="format.isLegal = false; format.isRestricted = false; format.isBanned = true">BANNED</span>
          <span class="ms-2" [innerText]="format.name"></span>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- COLORS, MANA COST, CMC, P/T, LOYALTY & RARITY -->
<div class="{{ singleCol ? 'wrapper' : 'col-6' }}">
  <!-- COLORS -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Colors</span>
    </div>
    <div class="col d-flex align-items-center">
      <div class="btn btn-sm {{ cardSearch.advanced.color.w ? 'btn-main' : 'btn-outline-secondary' }} me-2"
        (click)="cardSearch.advanced.color.w = !cardSearch.advanced.color.w">
        <i class="ms ms-w"></i>
      </div>
      <div class="btn btn-sm {{ cardSearch.advanced.color.u ? 'btn-main' : 'btn-outline-secondary' }} me-2"
        (click)="cardSearch.advanced.color.u = !cardSearch.advanced.color.u">
        <i class="ms ms-u"></i>
      </div>
      <div class="btn btn-sm {{ cardSearch.advanced.color.b ? 'btn-main' : 'btn-outline-secondary' }} me-2"
        (click)="cardSearch.advanced.color.b = !cardSearch.advanced.color.b">
        <i class="ms ms-b"></i>
      </div>
      <div class="btn btn-sm {{ cardSearch.advanced.color.r ? 'btn-main' : 'btn-outline-secondary' }} me-2"
        (click)="cardSearch.advanced.color.r = !cardSearch.advanced.color.r">
        <i class="ms ms-r"></i>
      </div>
      <div class="btn btn-sm {{ cardSearch.advanced.color.g ? 'btn-main' : 'btn-outline-secondary' }} me-2"
        (click)="cardSearch.advanced.color.g = !cardSearch.advanced.color.g">
        <i class="ms ms-g"></i>
      </div>
      <div class="btn btn-sm {{ cardSearch.advanced.color.c ? 'btn-main' : 'btn-outline-secondary' }}y me-2"
        (click)="cardSearch.advanced.color.c = !cardSearch.advanced.color.c">
        <i class="ms ms-c"></i>
      </div>
      <div ngbDropdown>
        <button class="btn btn-outline-secondary btn-sm me-2" id="dropDownColors" ngbDropdownToggle>{{
          cardSearch.advanced.selectedColorsOperator }}</button>
        <div ngbDropdownMenu aria-labelledby="dropDownColors" class="drop-down-panel">
          <ng-container *ngFor="let operator of cardSearch.advanced.colorsOperator">
            <button ngbDropdownItem class="pt-1 pb-1 drop-down-item"
              (click)="cardSearch.advanced.selectedColorsOperator = operator">{{ operator }}</button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <!-- MANA COST -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Mana Cost</span>
    </div>
    <div class="col d-flex align-items-center">
      <input type="text" class="form-control-sm me-2 me-2 w-68" placeholder="Any mana symbols, e.g. “{B}{B}{B}{B}”"
        [(ngModel)]="cardSearch.advanced.manaCost" (keydown.enter)="cardSearch.searchCards()">
      <div ngbDropdown>
        <button class="btn btn-outline-secondary btn-sm me-2" id="dropDownManaSymbols" ngbDropdownToggle>
          <fa-icon [icon]="faPlus" [fixedWidth]="true" class="me-2"></fa-icon>Add symbol
        </button>
        <div ngbDropdownMenu aria-labelledby="dropDownManaSymbols" class="drop-down-panel p-2">
          <ng-container *ngFor="let symbol of cardSearch.advanced.manaSymbols">
            <div ngbDropdownItem class="btn btn-sm drop-down-item drop-down-item-mana-symbol"
              (click)="cardSearch.addManaSymbol(symbol)"><i class="ms ms-{{ symbol }} ms-cost"></i></div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <!-- CMC -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">CMC</span>
    </div>
    <div class="col-2">
      <div class="text-secondary">{{ cardSearch.advanced.cmc.min }} to {{ cardSearch.advanced.cmc.max }}</div>
    </div>
    <div class="col d-flex align-items-center">
      <npn-slider hide-tooltip hide-values [step]="1" [multiRange]="true" [minSelected]="cardSearch.advanced.cmc.min"
        [maxSelected]="cardSearch.advanced.cmc.max" [min]="0" [max]="20" (onChange)="cardSearch.onChangeCMC($event)"
        class="w-100">
      </npn-slider>
    </div>
  </div>
  <!-- POWER -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Power</span>
    </div>
    <div class="col-2">
      <div class="text-secondary">{{ cardSearch.advanced.power.min }} to {{ cardSearch.advanced.power.max }}</div>
    </div>
    <div class="col d-flex align-items-center">
      <npn-slider hide-tooltip hide-values [step]="1" [multiRange]="true" [minSelected]="cardSearch.advanced.power.min"
        [maxSelected]="cardSearch.advanced.power.max" [min]="0" [max]="20" (onChange)="cardSearch.onChangePower($event)"
        class="w-100">
      </npn-slider>
    </div>
  </div>
  <!-- TOUGHNESS -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Toughness</span>
    </div>
    <div class="col-2">
      <div class="text-secondary">{{ cardSearch.advanced.toughness.min }} to {{ cardSearch.advanced.toughness.max
        }}</div>
    </div>
    <div class="col d-flex align-items-center">
      <npn-slider hide-tooltip hide-values [step]="1" [multiRange]="true"
        [minSelected]="cardSearch.advanced.toughness.min" [maxSelected]="cardSearch.advanced.toughness.max" [min]="0"
        [max]="20" (onChange)="cardSearch.onChangeToughness($event)" class="w-100"></npn-slider>
    </div>
  </div>
  <!-- LOAYALTY -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Loyalty</span>
    </div>
    <div class="col-2">
      <div class="text-secondary">{{ cardSearch.advanced.loyalty.min }} to {{ cardSearch.advanced.loyalty.max }}
      </div>
    </div>
    <div class="col d-flex align-items-center">
      <npn-slider hide-tooltip hide-values [step]="1" [multiRange]="true" [minSelected]="cardSearch.advanced.loyalty.min"
        [maxSelected]="cardSearch.advanced.loyalty.max" [min]="0" [max]="20"
        (onChange)="cardSearch.onChangeLoyalty($event)" class="w-100">
      </npn-slider>
    </div>
  </div>
  <!-- RARITY -->
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Rarity</span>
    </div>
    <div class="col d-flex align-items-center">
      <div class="btn {{ cardSearch.advanced.rarity.c ? 'btn-main'  : 'btn-outline-secondary' }} btn-sm me-2"
        (click)="cardSearch.advanced.rarity.c  = !cardSearch.advanced.rarity.c">Common</div>
      <div class="btn {{ cardSearch.advanced.rarity.u ? 'btn-main'  : 'btn-outline-secondary' }} btn-sm me-2"
        (click)="cardSearch.advanced.rarity.u  = !cardSearch.advanced.rarity.u">Uncommon</div>
      <div class="btn {{ cardSearch.advanced.rarity.r ? 'btn-main'  : 'btn-outline-secondary' }} btn-sm me-2"
        (click)="cardSearch.advanced.rarity.r  = !cardSearch.advanced.rarity.r">Rare</div>
      <div class="btn {{ cardSearch.advanced.rarity.mr ? 'btn-main' : 'btn-outline-secondary' }} btn-sm me-2"
        (click)="cardSearch.advanced.rarity.mr = !cardSearch.advanced.rarity.mr">Mythic Rare</div>
    </div>
  </div>
  <!-- PREFERENCES -->
  <div class="row">
    <div class="col-3 d-flex align-items-center">
      <span class="text-secondary">Preferences</span>
    </div>
    <div class="col d-flex align-items-center">
      <div class="form-check form-switch">
        <input type="checkbox" class="form-check-input" id="cardPrints"
          [(ngModel)]="cardSearch.advanced.showAllPrints">
        <label class="form-check-label" for="cardPrints">Show all card prints</label>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-3 d-flex align-items-center"></div>
    <div class="col d-flex align-items-center">
      <div class="form-check form-switch">
        <input type="checkbox" class="form-check-input" id="includeExtras"
          [(ngModel)]="cardSearch.advanced.includeExtras">
        <label class="form-check-label" for="includeExtras">Include extra cards (tokens, planes, schemes,
          etc)</label>
      </div>
    </div>
  </div>
</div>

import { Observable } from 'rxjs';
import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IDeckList } from 'src/app/services/decks.service';
import { GlobalsService } from 'src/app/services';

@Component({
  selector: 'app-deck-photos',
  templateUrl: './deck-photos.component.html',
  styleUrls: ['./deck-photos.component.css']
})
export class DeckPhotosComponent {

  public decks$: Observable<IDeckList[]>;

  constructor(
    private afs: AngularFirestore,
    public globals: GlobalsService
  ) {
    const decksCollection = this.afs.collection<IDeckList>('decks', ref => ref
      // .where('deckPhotoUrl', '!=', '')
      .where('rating', '>', 0)
      .orderBy('rating', 'desc')
      .limit(30));
    this.decks$ = decksCollection.valueChanges();
  }

}

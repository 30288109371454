<div *ngIf="data$ | async"
    class="p-4 mx-3 border {{ hasAnswered ? 'border-secondary' : 'border-primary' }} rounded rounded-3"
    [attr.message-id]="data.docId">
    <div class="d-flex flex-column gap-3">

        <div class="title text-larger">Tournament Invitation</div>

        <div class="recipient text-bold d-flex align-items-center gap-2">
            <span>Dear</span>
            <app-player-display-name [playerDocId]="data.message.invitedPlayer.playerDocId"></app-player-display-name>
        </div>
        <div class="message">You have been summoned to participate in this event!</div>
        
        <div class="message-content d-flex flex-wrap gap-2">
            <ng-container
                *ngIf="!hasAnswered || changeResponse; else hasResponded">
                <div class="text-secondary text-small flex-grow-1">To answer the invitation, please use the action buttons below</div>
                <div class="w-100 d-flex gap-3">
                    <button class="btn btn-success pe-3 d-flex align-items-center gap-2"
                        (click)="answer(true)">
                        <fa-icon [fixedWidth]="true" [icon]="icon.accept"></fa-icon>
                        Accept
                    </button>
                    <button class="btn btn-danger pe-3 d-flex align-items-center gap-2"
                        (click)="answer(false)">
                        <fa-icon [fixedWidth]="true" [icon]="icon.decline"></fa-icon>
                        Decline
                    </button>
                </div>
            </ng-container>
            <ng-template #hasResponded>
                <div class="d-flex align-items-center justify-content-end gap-3">
                    <div class="text-success" *ngIf="data.message.invitedPlayer.accepted">Accepted</div>
                    <div class="text-danger" *ngIf="data.message.invitedPlayer.declined">Declined</div>
                    <button class="ms-3 text-warning btn btn-sm btn-blank"
                        (click)="changeResponse = true">
                        Change response
                    </button>
                </div>
            </ng-template>
        </div>

    </div>
</div>
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PublicFooterComponent } from 'src/app/public/public-footer/public-footer.component';
import { PublicHeaderComponent } from 'src/app/public/public-header/public-header.component';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
  standalone: true,
  imports: [
    FontAwesomeModule,
    CommonModule,
    PublicHeaderComponent,
    PublicFooterComponent,
  ]
})
export class PrivacyPolicyComponent {
  @Input() modal = false

  faTimes = faTimes;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

}

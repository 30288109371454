import { AuthService } from 'src/app/services/auth.service';
import { IBoosterCardDocument } from './../services/draft.service';
import { Pipe, PipeTransform } from '@angular/core';

export interface IDraftRotisseriePipeConfig {
  search: string;
  textSearch: boolean;
  colors: {
    white: boolean;
    blue: boolean;
    black: boolean;
    red: boolean;
    green: boolean;
    colorless: boolean;
  },
  types: {
    sorcery: boolean;
    instant: boolean;
    land: boolean;
    enchantment: boolean;
    artifact: boolean;
    creature: boolean;
  },
  isPicked: boolean;
  onlyShowSaved: boolean;
}

@Pipe({
  name: 'draftRotisserieCards'
})
export class DraftRotisserieCardsPipe implements PipeTransform {

  constructor(
    private auth: AuthService
  ) {}

  transform(items: IBoosterCardDocument[], filter: IDraftRotisseriePipeConfig, refresh: number): IBoosterCardDocument[] {

    console.log(items);
    // if (items[0].card.keyruneCode.toLowerCase() === 'xspc') {
    //   return items;
    // }

    // search string
    if (!filter.textSearch && filter.search !== '') {
      items = items.filter((i) => i.card.name.toLowerCase().includes(filter.search.toLowerCase()));
    }
    if (filter.textSearch && filter.search !== '') {
      items = items.filter((i) => i.card.text.toLowerCase().includes(filter.search.toLowerCase()));
    }

    // colors
    const colorFilter: IBoosterCardDocument[] = [];
    for (const i of items) {
      if (filter.colors.white && i.card.colors.includes('White') || filter.colors.white && i.card.colors.includes('W')) {
        colorFilter.push(i);
        continue;
      }
      if (filter.colors.blue && i.card.colors.includes('Blue') || filter.colors.blue && i.card.colors.includes('U')) {
        colorFilter.push(i);
        continue;
      }
      if (filter.colors.black && i.card.colors.includes('Black') || filter.colors.black && i.card.colors.includes('B')) {
        colorFilter.push(i);
        continue;
      }
      if (filter.colors.red && i.card.colors.includes('Red') || filter.colors.red && i.card.colors.includes('R')) {
        colorFilter.push(i);
        continue;
      }
      if (filter.colors.green && i.card.colors.includes('Green') || filter.colors.green && i.card.colors.includes('G')) {
        colorFilter.push(i);
        continue;
      }
      if (filter.colors.colorless && i.card.colors.length === 0) {
        colorFilter.push(i);
        continue;
      }
    }

    items = colorFilter;

    // types
    const typeFilter: IBoosterCardDocument[] = [];
    for (const i of items) {
      if (filter.types.sorcery && i.card.typeSymbol === 'sorcery') {
        typeFilter.push(i);
        continue;
      }
      if (filter.types.instant && i.card.typeSymbol === 'instant') {
        typeFilter.push(i);
        continue;
      }
      if (filter.types.land && i.card.typeSymbol === 'land') {
        typeFilter.push(i);
        continue;
      }
      if (filter.types.enchantment && i.card.typeSymbol === 'enchantment') {
        typeFilter.push(i);
        continue;
      }
      if (filter.types.artifact && i.card.typeSymbol === 'artifact') {
        typeFilter.push(i);
        continue;
      }
      if (filter.types.creature && i.card.typeSymbol === 'creature') {
        typeFilter.push(i);
        continue;
      }
    }

    items = typeFilter;

    // hide picked cards
    if (!filter.isPicked) {
      items = items.filter((i) => !i.card.isPicked);
    }

    // only show saved
    if (filter.onlyShowSaved) {
      return items.filter((i) => i.savedByPlayerDocIds.includes(this.auth.user.playerId)).sort((a, b) => a.card.name > b.card.name ? 1 : -1);
    }

    return items.sort((a, b) => a.card.name > b.card.name ? 1 : -1);

  }

}

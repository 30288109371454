import { LeaguePointsComponent } from './../league-points/league-points.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { LeaguesService, ILeagueData } from 'src/app/services/leagues.service';
import { Component, OnInit } from '@angular/core';
import { ILeaguePlayer } from 'tolaria-cloud-functions/src/_interfaces';
import { faSync } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-league-ranking',
  templateUrl: './league-ranking.component.html',
  styleUrls: ['./league-ranking.component.css']
})
export class LeagueRankingComponent implements OnInit {

  private leagueDocId: string;
  private leagueData: ILeagueData;
  public players$: BehaviorSubject<ILeaguePlayer[]> = new BehaviorSubject<ILeaguePlayer[]>(null);
  public icons = {
    faSync: faSync
  }

  constructor(
    private leagueService: LeaguesService,
    private router: Router,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.leagueDocId = this.router.url.split('/')[2];

    this.refreshPlayerList();
  }

  public refreshPlayerList(): void {
    this.leagueService.getLeagueByDocId(this.leagueDocId).pipe(take(1)).subscribe((leagueData: ILeagueData) => {
      this.leagueData = leagueData;
      this.leagueService.getLeaguePlayers(this.leagueData).then(players => {
        this.players$.next(players as ILeaguePlayer[]);
      });
    });
  }

  public showPointsBreakdown(player: ILeaguePlayer): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
    };
    const modalRef: NgbModalRef = this.modalService.open(LeaguePointsComponent, modalOptions);
    modalRef.componentInstance.player = player;
  }

}

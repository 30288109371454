import { faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { applicationIcon } from 'src/assets/font-icons/tolaria-icon';
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { IEventDetails, IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces';
import { BehaviorSubject, Subject } from 'rxjs';
import { EventService } from 'src/app/services/event/event.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-event-check-in-kiosk',
  templateUrl: './event-check-in-kiosk.component.html',
  styleUrls: ['./event-check-in-kiosk.component.css']
})
export class EventCheckInKioskComponent implements OnInit, OnDestroy {

  private timeout = 10000

  public appIcon = applicationIcon
  public error = faExclamationCircle
  public success = faCheckCircle

  private readTimer: any
  private infoTimer: any
  private reader: string = ''
  private destroyed$: Subject<boolean> = new Subject<boolean>()

  public nothingFoundOnScan: boolean = false
  public showWelcomeWrapper: boolean = false
  public showWelcomeBackWrapper: boolean = false
  public checkInSuccess: boolean = false
  public checkInFailed: boolean = false
  public showAnimation: boolean = true

  public playerCheckingIn$: BehaviorSubject<IEventPlayerDetails> = new BehaviorSubject<IEventPlayerDetails>(null)
  public players$: BehaviorSubject<IEventPlayerDetails[]> = new BehaviorSubject<IEventPlayerDetails[]>(null)
  public event$: BehaviorSubject<IEventDetails> = new BehaviorSubject<IEventDetails>(null)

  constructor(
    private eventService: EventService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.eventService.getEventPlayersByEventId(this.eventDocId).pipe(takeUntil(this.destroyed$)).subscribe(p => this.players$.next(p))
    this.eventService.getEventById(this.eventDocId).pipe(takeUntil(this.destroyed$)).subscribe(e => this.event$.next(e))
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true)
  }

  public get isPortrait(): boolean {
    return window.innerHeight > window.innerWidth
  }
  public get isLandscape(): boolean {
    return window.innerWidth > window.innerHeight
  }

  public get animationImageUrl(): string {
    return this.isPortrait
      ? 'assets/video/check-in_1000x1400.gif'
      : 'assets/video/check-in_2000x1400.gif'
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {

    const char = event.code === 'Minus'
      ? '-'
      : event.key
    this._startInput(char)

  }
  private _startInput(char: string): void {

    if (this.readTimer) { window.clearTimeout(this.readTimer) }

    if (char === 'Shift') { return }

    if (char === 'Enter') {

      // hide animation
      this.showAnimation = false

      const data = this.reader.split('.')
      const property = data[0]
      const value = data[1]

      if (property === 'playerDocId') {

        if (value.length === 36) {

          this._resetAll()

          this._checkIn(value)
        }
        else {
          this._showError()
        }

      }

    }

    this.readTimer = setTimeout(() => {
      this.reader = ''
    }, 100)

    this.reader += char

  }
  private _checkIn(playerDocId: string): void {

    const player = this.players$.getValue().find(p => p.playerDocId === playerDocId)

    if (player) {

      this._resetAll()

      // store the player in the local variable
      this.playerCheckingIn$.next(player)

      // if player checked in, notify the player
      if (player.hasCheckedIn) {

        this.showWelcomeBackWrapper = true

      }

      // else, perform checkin
      else {
        this.eventService.checkInPlayer(this.eventDocId, player.playerDocId)
          .then(() => this.checkInSuccess = true)
          .catch(() => this.checkInFailed = true)

        this.showWelcomeWrapper = true

      }

      this._startInfoTimer()

    }

    else {

      this._showError()

    }

  }
  private _showError(): void {

    this._resetAll()

    this.nothingFoundOnScan = true

    this._startInfoTimer()

  }
  private _resetAll(): void {
    this.playerCheckingIn$.next(null)
    this.nothingFoundOnScan = false
    this.showWelcomeWrapper = false
    this.checkInSuccess = false
    this.checkInFailed = false
  }
  private _startInfoTimer(): void {
    if (this.infoTimer) { window.clearTimeout(this.infoTimer) }
    this.infoTimer = setTimeout(() => {
      this._resetAll()
      this.showAnimation = true
    }, this.timeout)
  }

  public get eventDocId(): string {
    return this.router.url.split('/')[2]
  }


}